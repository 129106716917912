export default interface AccountActionSource {
    id: number;
    name: string;
    foreign: string;
    english: string;
    isPrinting: boolean;
    toPrinter: boolean;
    isCanAccountCont: boolean;
    deleted: boolean;
    inEdit: boolean;
}

export const accountActionSourceDefaultProps: AccountActionSource = {
    id: 0,    
    name: '',
    foreign: '',
    english: '',
    isPrinting: false,
    toPrinter: false,
    isCanAccountCont: false,
    deleted: false,
    inEdit: false,
}