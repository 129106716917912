export default interface InvoiceFlowStatus {
  id: number;
  name: string;
  foreign: string;
  english: string;
  deleted: boolean;
  dropDown: boolean;
  dialog: boolean;
}

export const invoiceFlowStatusDefaultProps: InvoiceFlowStatus = {
  id: 0,
  name: "",
  foreign: "",
  english: "",
  deleted: false,
  dropDown: false,
  dialog: false,
};
