
























import dtStore, { DocumentTemplateStore } from "@/modules/documentTemplate/services/DocumentTemplateStore";
import FlowDocumentTemplateRelation from "@/modules/flowDocumentRelation/models/FlowDocumentTemplateRelation";
import InvoiceDetails from "@/modules/generalIngoingInvoice/models/InvoiceDetails";
import eventBus from "@/services/eventBus";
import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import relStore, { FlowDocumentRelationStore } from "@/modules/flowDocumentRelation/services/FlowDocumentRelationStore";

interface Data {
  store: InvoiceFlowStore;
  relStore: FlowDocumentRelationStore;
  dtStore: DocumentTemplateStore;
}

interface Methods {
  templateChanged(template: string, init?: boolean, payload?: number): void;
  newGeniEvent(payload: any): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  data: () => ({
    store,
    relStore,
    dtStore,
  }),

  beforeDestroy() {
    eventBus.$off("flow-doc-template:new-geni", this.newGeniEvent);
  },

  async beforeMount() {
    eventBus.$on("flow-doc-template:new-geni", this.newGeniEvent);

    this.relStore.flowDocumentRelations = [];
    const invoiceId = Number(this.$route.params.invoiceId);

    await this.dtStore.loadDocumentTemplatesWithTechnicalRecord();

    if (invoiceId) 
    {
      await this.dtStore.loadDocumentTemplateRelation(invoiceId);
    }
    else
    {
      this.templateChanged("-", true);
    }
  },

  watch: {
    partnerDetails(value: InvoiceDetails) {
      if (value.partnerDocumentTemplate && value.partnerDocumentTemplate != "-" && this.dtStore.template == "-") {
        this.templateChanged(value.partnerDocumentTemplate, this.store.invoice.id == 0);
      }
    },

    documentTemplateRelation(value: FlowDocumentTemplateRelation) {
      if (!value) return;

      const docId = value?.docTemplateId ?? 1;
      const template = this.dtStore.documentTemplates.find((x) => x.id == docId)?.name ?? "-";
      const partnerTemplate =
        this.dtStore.documentTemplates.find((x) => x.name == this.store.invoiceDetails.partnerDocumentTemplate) ?? 0;
      this.templateChanged(template, this.store.invoice.id == 0 || partnerTemplate != docId);
    },
  },

  computed: {
    partnerDetails() {
      return this.store.invoiceDetails;
    },

    documentTemplateRelation() {
      return this.dtStore.documentTemplateRelation;
    },
  },

  methods: {
    async newGeniEvent(payload){
      await this.templateChanged(payload.template, payload.init, payload.invoice);
    },

    async templateChanged(template, init = false, invoice = 0) {
      if (invoice > 1){
        this.store.invoice.id = invoice;
      }

      if (this.store.invoice.id == 0) init = true;

      if (!template || template == "") return;
      this.dtStore.template = template;

      const templateId = this.dtStore.documentTemplates.find((x: any) => x.name == template)?.id ?? 0;
      if (templateId == 0) return;

      if (this.dtStore.documentTemplateRelation) this.dtStore.documentTemplateRelation.docTemplateId = templateId;
      if (!init) {
        await this.dtStore.updateDocumentTemplateRelation(this.store.invoice.id, templateId);
      }

      eventBus.$emit("flow-doc-template:updated", template);
    },
  },
});
