import http, { handleApiResponse, checkResponse } from "@/services/http";
import City from "../models/City";
import ApiResponse from "@/models/ApiResponse";

export default class CityApi {
  static async getAll(): Promise<City[]> {
    return http.get<ApiResponse<City[]>>(`city`).then(handleApiResponse);
  }

  static async getByPostCode(postCode: number): Promise<City> {
    return http.get<ApiResponse<City>>(`city/postcode/${postCode}`).then(handleApiResponse);
  }

  static async getCitiesByName(name: string): Promise<City[]> {
    return http.get<ApiResponse<City[]>>(`city/name/${name}`).then(handleApiResponse);
  }
}
