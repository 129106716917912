












































































import Vue from "vue";
import IconButton from "@/components/IconButton.vue";
import { SalesAndOpportunityInput } from "../models";
import * as api from "../services/api";
import store, { InvoiceItemStore } from '../services/store';
import eventBus from "@/services/eventBus";
import TextInput from "@/components/TextInput.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";


interface Data {
  searchText: string;
  store: InvoiceItemStore;
  sales: SalesAndOpportunityInput[];
  configuration: AppConfiguration;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    IconButton,
    TextInput,
    MoneyInput,
    MoneyDisplay
  },

  data: () => ({
    searchText: "",
    store,
    sales: [],

    configuration
  }),

  mounted() {
    const salesAddHandler = () => (this.sales = []);
    eventBus.$on('invoice-item:add', salesAddHandler)
  },

  methods: {
    async search(e: KeyboardEvent) {
      if (e.key !== "Enter") {
        return;
      }

      const opportunties = (await api.searchSales(this.searchText)).filter(o => o.currency === this.store.currency);
      this.sales = opportunties.map(o => ({...o, name: o.salesOrderNo, price: 0.0 }));
    },

    clear() {
      this.searchText = "";
      this.sales = [];
    },

    async add(sales: SalesAndOpportunityInput) {
      await store.addSales(sales)
    }
  },
});
