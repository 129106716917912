export default interface PaymentModeDisplay {
  id: number;
  name: string;
  foreign: string;
  english: string;
  deleted: boolean;
  promptPay: boolean;
  noCurrentAccount: boolean;
  creditCheck: boolean;
  sapCode: string;
  webPayMode: string;
  isConstr: boolean;
  partnerAccountCode: string;
}

export const paymentModeDisplayDefaultProps: PaymentModeDisplay = {
  id: 0,
  name: "-",
  foreign: "-",
  english: "-",
  deleted: false,
  promptPay: false,
  noCurrentAccount: false,
  creditCheck: false,
  sapCode: "-",
  webPayMode: "-",
  isConstr: false,
  partnerAccountCode: "-",
};

export const paymentModeDisplayTechProps: PaymentModeDisplay = {
  id: 1,
  name: "-",
  foreign: "-",
  english: "-",
  deleted: false,
  promptPay: false,
  noCurrentAccount: false,
  creditCheck: false,
  sapCode: "-",
  webPayMode: "-",
  isConstr: false,
  partnerAccountCode: "-",
};
