




































































































































































































































import CustomTab from "@/components/CustomTab.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";
import User, { monogram } from "@/modules/user/models/User";
import UserApi from "@/modules/user/services/UserApi";
import auth from "@/services/auth";
import eventBus from "@/services/eventBus";
import Vue from "vue";
import { geniChatDefaultProps } from "../models/GeniChat";
import Message, { messageDefaultProps } from "../models/Message";
import MessagedGroupResponse, { messagedGroupResponseDefaultProps } from "../models/MessagedGroupResponse";
import MessagedUserResponse, { messagedUserResponseDefaultProps } from "../models/MessagedUserResponse";
import store, { ChatStore } from "../services/ChatStore";

interface Data {
  configuration: AppConfiguration;
  store: ChatStore;
  users: User[];
  messagedUsersLoaded: boolean;
  messagedGroupsLoaded: boolean;
  selectedUsers: User[];
  text: string;
  geniChatVisible: boolean;
}

interface Methods {
  scrollToBottom(): void;
  reloadGroups(groupId: string): void;
  monogram(name: string): void;
  loadGeniMessages(invoiceId: number): void;
  loadMessages(toId: number): void;
  loadGroupMessages(groupId: string): void;
  geniChatClicked(invoiceId: number): void;
  userClicked(user: MessagedUserResponse): void;
  groupClicked(group: MessagedGroupResponse): void;
  newChat(): void;
  newGroupChat(): void;
  sendMessage(): void;
  addToGroup(): void;
  leaveGroup(): void;
}

interface Computed {
  loggedIn: boolean;
}

interface Props {
  $chatHub?: any;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CustomTab },

  data: () => ({
    configuration,
    store,
    users: [],
    messagedUsersLoaded: false,
    messagedGroupsLoaded: false,
    selectedUsers: [],
    text: "",
    geniChatVisible: false,
  }),

  async beforeMount() {
    this.store.messagedUsers = [];
    this.store.messages = [];
    this.store.selectedMessagedUser = { ...messagedUserResponseDefaultProps };
    this.store.selectedMessagedGroup = { ...messagedGroupResponseDefaultProps };

    await Promise.all([
      this.store.loadCurrentUser(),
      this.store.loadMessagedUsers().then(() => {
        this.messagedUsersLoaded = true;
      }),
      this.store.loadMessagedGroups().then(() => {
        this.messagedGroupsLoaded = true;
      }),
      UserApi.getUsers().then((result) => {
        this.users = result;
      }),
    ]);

    if (
      configuration.invoiceFlow.enabled &&
      this.$route.path.includes(
        "/general-incoming-invoice/flow/edit/"
      ) /* && (currentUser.isInvContr || currentUser.isInvAccept) */
    ) {
      this.geniChatVisible = true;
      this.store.geniChat = { ...geniChatDefaultProps, invoiceId: Number(this.$route.params.invoiceId) };
    }
  },

  created() {
    eventBus.$on("receive-message", (message: Message) => {
      this.store.recieve(message).then((isChatOpen) => {
        if (isChatOpen) this.scrollToBottom();
      });
    });

    eventBus.$on("user-left-group", (groupId: string) => {
      if (!this.store.messagedGroups.map((x) => x.groupId).includes(groupId)) return;
      this.reloadGroups(groupId);
    });

    eventBus.$on("user-added-to-group", (o: any) => {
      if (this.store.messagedGroups.map((x) => x.groupId).includes(o.groupId) || o.userId == this.store.currentUser) {
        this.reloadGroups(o.groupId);
      }
    });
  },

  methods: {
    scrollToBottom() {
      (this.$refs.messageList as any).scrollTo(0, 9999);
    },

    async reloadGroups(groupId) {
      await this.store.loadMessagedGroups().then(() => {
        if (this.store.selectedMessagedGroup.groupId != groupId || !this.store.selectedMessagedGroup.selected) return;

        const group = this.store.messagedGroups.find((x) => x.groupId == groupId);
        if (group) this.groupClicked(group);
      });
    },

    monogram(name) {
      return monogram(name);
    },

    async loadGeniMessages(invoiceId) {
      await this.store.loadGeniMessages(invoiceId).then(() => {
        this.scrollToBottom();
      });
    },

    async loadMessages(toId) {
      await this.store.loadMessages(toId).then(() => {
        this.scrollToBottom();
      });
    },

    async loadGroupMessages(groupId) {
      await this.store.loadGroupMessages(groupId).then(() => {
        this.scrollToBottom();
      });
    },

    geniChatClicked(invoiceId) {
      this.store.messagedGroups.forEach((x) => (x.selected = false));
      this.store.messagedUsers.forEach((x) => (x.selected = false));
      this.store.geniChat.selected = true;

      this.loadGeniMessages(invoiceId);
    },

    userClicked(user) {
      if (user.user.id == this.store.selectedMessagedUser.user.id && user.selected) return;
      // TODO: change tab

      if (user.unreadMessageCount > 0) this.store.messageCount -= Math.min(0, user.unreadMessageCount);
      this.store.messagedUsers.forEach((x) => (x.selected = false));
      this.store.messagedGroups.forEach((x) => (x.selected = false));
      this.store.geniChat.selected = false;

      const i = this.store.messagedUsers.findIndex((x) => x.user.id == user.user.id);
      this.store.messagedUsers[i].selected = true;
      this.store.messagedUsers[i].unreadMessageCount = 0;

      user.selected = true;
      user.unreadMessageCount = 0;
      this.store.selectedMessagedUser = user;

      this.loadMessages(user.user.id);
    },

    groupClicked(group) {
      if (group.groupId == this.store.selectedMessagedGroup.groupId && group.selected) return;
      // TODO: change tab

      this.store.messagedGroups.forEach((x) => (x.selected = false));
      this.store.messagedUsers.forEach((x) => (x.selected = false));
      this.store.geniChat.selected = false;

      const i = this.store.messagedGroups.findIndex((x) => x.groupId == group.groupId);
      this.store.messagedGroups[i].selected = true;

      group.selected = true;
      this.store.selectedMessagedGroup = group;

      this.loadGroupMessages(group.groupId);
    },

    newChat() {
      if (this.selectedUsers == undefined || this.selectedUsers.length == 0) return;
      if (this.selectedUsers[0].id == this.store.currentUser && this.selectedUsers.length == 1) return;

      // more users -> group
      if (this.selectedUsers.length > 1) {
        this.newGroupChat();
        return;
      }

      const selectedUser = this.selectedUsers[0];
      const selectedUserR: MessagedUserResponse = { ...messagedUserResponseDefaultProps, user: selectedUser };
      if (this.store.messagedUsers.map((x) => x.user.id).includes(selectedUser.id)) {
        this.userClicked(selectedUserR);
        return;
      }

      this.store.messagedUsers = [selectedUserR, ...this.store.messagedUsers];
      this.userClicked(selectedUserR);
    },

    async newGroupChat() {
      let selectedIds = this.selectedUsers.map((x) => x.id);
      if (!selectedIds.includes(this.store.currentUser)) {
        selectedIds = [...selectedIds, this.store.currentUser];
      }

      // TODO: fix group duplicates?
      const newGroup: MessagedGroupResponse = { ...messagedGroupResponseDefaultProps, users: this.selectedUsers };

      await this.store.createGroup(newGroup).then((result) => {
        const res = { ...result, selected: false };
        this.store.messagedGroups = [res, ...this.store.messagedGroups];
        this.groupClicked(newGroup);
      });
    },

    async sendMessage() {
      if (this.text.trim() == "") return;

      const gr = this.store.selectedMessagedGroup;
      const u = this.store.selectedMessagedUser;
      const gc = this.store.geniChat;

      const newMessage: Message = {
        ...messageDefaultProps,
        fromId: this.store.currentUser,
        toId: u.selected ? u.user.id : 1,
        content: this.text,
        groupId: gr.selected ? gr.groupId : "",
        topic: gc.selected ? `GENI:${gc.invoiceId}` : "",
      };

      await this.store.sendMessage(newMessage).then((result) => {
        this.$chatHub.send("SendMessage", result);
        this.scrollToBottom();
        this.text = "";
      });
    },

    addToGroup() {
      if (this.selectedUsers == undefined || this.selectedUsers.length == 0) return;

      const groupId = this.store.selectedMessagedGroup.groupId;

      this.selectedUsers.forEach(async (user) => {
        const userId = user.id;
        await this.store.addToGroup(groupId, userId).then(() => {
          this.$chatHub.send("AddToGroup", groupId, userId);
          this.reloadGroups(groupId);
        });
      });
    },

    leaveGroup() {
      this.store.leaveGroup().then((groupId) => {
        this.$chatHub.send("LeaveGroup", groupId);
      });
    },
  },

  watch: {
    loggedIn(a) {
      if (!a) {
        this.store.messagedUsers = [];
        this.store.messages = [];
      }
    },

    selectedUsers(val: any[]) {
      const lastItem = val.length > 1 ? val[val.length - 1] : val[0];
      if ((lastItem && lastItem !== Object(lastItem)) || val.length > 5) {
        this.$nextTick(() => this.selectedUsers.pop());
      }
    },
  },

  computed: {
    loggedIn() {
      return !configuration.operel && auth.isLoggedIn;
    },
  },
});
