import http, { checkResponse, handleApiResponse } from "@/services/http";
import WriteOfDebtLock from "../models/WriteOffDebtLock";
import InvoiceId from "../models/InvoiceId";
import PossibleWriteOffDebtPreFilter from "../models/PossibleWriteOffDebtPreFilter";
import ApiResponse from "@/models/ApiResponse";
import PartnerPossibleWriteOffData from "../models/PartnerPossibleWrtireOffDebt";
import PossibleWriteOffDebt from "../models/PossibleWriteOffDebt";

export class WriteOffDebtApi {
  //Lekérdezi 1 tételre, hogy van-e lock
  GetLock(id: InvoiceId) {
    return http
    .post<ApiResponse<WriteOfDebtLock>>("writeoffdebt/GetLocks", { invoices: [ id ] })
    .then(handleApiResponse);
  }

  AddInvoicesToWriteOffDebtToTmp(ids: InvoiceId[]) {
    return http
    .post("writeoffdebt/AddInvoicesToWriteOffDebtToTmp", { invoices: ids })
    .then(checkResponse);
  }

  RemoveInvoicesToWriteOffDebtToTmp(ids: InvoiceId[]) {
    return http
    .post("writeoffdebt/RemoveInvoicesToWriteOffDebtToTmp", { invoices: ids })
    .then(checkResponse);
  }

  GetAllPartnerWriteOffDebtTmp(filter: PossibleWriteOffDebtPreFilter) {
    return http
    .post<ApiResponse<PartnerPossibleWriteOffData[]>>("writeoffdebt/GetAllPartnerWriteOffDebtTmp", { filter })
    .then(handleApiResponse);
  }

  WriteOffSmallIncome(note: string, items: PossibleWriteOffDebt[]) {
    return http
      .post("writeoffdebt/WriteOffSmallIncome", {note: note, items: items})
      .then(checkResponse);
  }

  WriteOffSmallOutgoing(note: string, items: PossibleWriteOffDebt[]) {
    return http
      .post("writeoffdebt/WriteOffSmallOutgoing", {note: note, items: items})
      .then(checkResponse);
  }

  WriteOffIncome(note: string, items: PossibleWriteOffDebt[]) {
    return http
      .post("writeoffdebt/WriteOffIncome", {note: note, items: items})
      .then(checkResponse);
  }

  WriteOffOutgoing(note: string, items: PossibleWriteOffDebt[]) {
    return http
      .post("writeoffdebt/WriteOffOutgoing", {note: note, items: items})
      .then(checkResponse);
  }
}

const api = new WriteOffDebtApi()
export default api;