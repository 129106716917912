































































































import Vue from "vue";
import Fragment from "./Fragment.vue";

interface Props {
  closeOnClick: boolean;
  multiple: boolean;
  title: string;
  //eslint-disable-next-line
  items: any[];
  nameProp: string;
  selectProp: string;
  cssClass: string;
}

export default Vue.extend<{}, {}, {}, Props>({
  components: { Fragment },
  props: {
    closeOnClick: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    title: { type: String, default: "-" },
    //eslint-disable-next-line
    items: {
      type: Array as () => any[],
      default() {
        return [];
      },
    },
    nameProp: { type: String, default: "name" },
    selectProp: { type: String, default: "selected" },
    cssClass: { type: String, default: '' }
  },

  methods: {
    //eslint-disable-next-line
    selected(item: any) {
      item[this.selectProp] = !item[this.selectProp];
      this.$emit("selected", item);
    },
  },

  computed: {
    selectable() {
      return this.selectProp != "";
    },

    customItems() {
      return !!this.$slots.customItems;
    },
  },
});
