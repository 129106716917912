export default interface Attachment {
    id: number;
    file: any;
    link: string;
    note: string;
    name: string;
    fileBase64: string;
    size?: number;
    mimeType?: string;
    webkitRelativePath?: string;
    lastModified?: number;
}

export const attachmentDefaultProps: Attachment = {
    id: 0,
    file: '',
    link: '',
    note: '',
    name: '',
    fileBase64: '',
    size: 0,
    mimeType: '',
    webkitRelativePath: '',
    lastModified: 0
}