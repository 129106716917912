import api from "./GpartsEditApi";
import GPart from "../models/GPart";
import GPartType from "../models/GPartType";
import PartGroup from "../models/PartGroup";
import UnitType from "../models/UnitType";
import Tax from "../models/Tax";
import TaxItem from "../models/TaxItem";
import CarCostType from "../models/CarCostType";

export class GpartsEditStore {
    gpart = new GPart();
    types: GPartType[] = []; 
    partGroups: PartGroup[] = [];
    currencies: string[] = [];
    unitTypes: UnitType[] = [];
    taxes: Tax[] = [];
    taxItems: TaxItem[] = [];
    carCostTypes: CarCostType[] = [];

    async loadType() {
        this.types = await api.getTypes();
    }

    async loadPartGroups() {
        this.partGroups = await api.getPartGroups();
    }

    async loadCurrencies() {
        this.currencies = await api.getCurrencies();
    }

    async loadUnitTypes() {
        this.unitTypes = await api.getUnitTypes();
    }

    async loadTaxes() {
        this.taxes = await api.getTaxes();
    }

    async loadTaxItems(){
        this.taxItems = await api.getTaxItems(this.gpart.tax);
    }

    async loadCarCostTypes(){
        this.carCostTypes = await api.getCarCostTypes();
    }

    async loadGpart(id: number){
        this.gpart = await api.getGpart(id);
    }

    async updateGpart(){
        this.gpart.id = await api.updateGpart(this.gpart);
    }
}

const store = new GpartsEditStore();
export default store;