import { render, staticRenderFns } from "./NavItemsRelations.vue?vue&type=template&id=07a14dc4&scoped=true&"
import script from "./NavItemsRelations.vue?vue&type=script&lang=ts&"
export * from "./NavItemsRelations.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a14dc4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VSimpleTable})
