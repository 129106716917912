import ApiResponse from "@/models/ApiResponse";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import InvoiceMigratonDate from "../models/InvoiceMigrationDate";

export default class InvoiceMigrationApi{
    static async GetMigrationItemIds(dateRange: InvoiceMigratonDate): Promise<number[]>{
        return await http.post<ApiResponse<number[]>>(`invoice-migration`,dateRange)
        .then(handleApiResponse)
    }

    static async MigrateInvoices(invoiceIds: number[]){
        return await http.post(`invoice-migration/migrate`,invoiceIds)
        .then(checkResponse)
    }
}