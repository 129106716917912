import { IncomingInvoiceTimelineDescriptionItem, IncomingInvoiceTimelineItem } from "../../actionLog/models/index";
import dayjs, { Dayjs } from "dayjs";

export default class IncomingInvoiceRemark implements IncomingInvoiceTimelineItem {
  invId = 0;
  statusId = 1;
  status = "-";
  userName = "-";
  date: string | Date = new Date();
  remark = "";

  constructor(params?: Partial<IncomingInvoiceRemark>) {
    const data = { ...params };
    Object.assign(this, data);
  }

  /*
   * IncomingInvoiceTimelineItem implementation for IncomingInvoiceTimeline.vue
   */
  get timestamp(): Dayjs {
    return dayjs(this.date);
  }

  get title(): string {
    return `Megjegyzés`;
  }

  get subtitle(): string {
    return this.userName;
  }

  get description(): string | IncomingInvoiceTimelineDescriptionItem[] | undefined {
    return [{ label: `${this.status}:`, value: `${this.remark}` }];
  }

  get icon(): string {
    return `fa-comment`;
  }

  get color(): string {
    return `lighten-2 orange`;
  }
}
