








































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, { BankStatementStore } from "../services/BankStatementStore";
import BankStatement, {
  bankStatementDefaultProps,
} from "../models/BankStatement";
import pStore, {
  CashPointStore,
} from "../../cashPoint/services/CashPointStore";
import BankStatementHeaders from "./BankStatementHeaders.vue";
import VStepper from "../../invoiceFlow/components/VStepper.vue";
import VStepperStep from "../../invoiceFlow/components/VStepperStep.vue";
import dayjs from "dayjs";

interface Data {
  store: BankStatementStore;
  pStore: CashPointStore;
}

interface Methods {
  setStep(step: number): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    Page,
    VStepper,
    VStepperStep,
    BankStatementHeaders,
  },

  data() {
    return {
      store,
      pStore,
    };
  },

  async beforeMount() {
    const step = this.$route.path.endsWith("head")
      ? 1
      : this.$route.path.endsWith("item")
        ? 2 : 3;
    this.store.step = step;

    window.scrollTo(0, 0);
    this.store.step = 1;

    if (Number(this.$route.params.id) == 1) {
      this.store.bankStatement = { ...bankStatementDefaultProps };
      this.store.bankDocuments = [];
    } else {
      // await this.store.loadBankStatement(Number(this.$route.params.id));
      await this.store.loadBankStatement(Number(this.$route.params.id))
      await this.store.loadBankDocumentsByStatementId(
        Number(this.$route.params.id)
      );
    }

    await this.store.loadOwnPartBanks(this.store.bankStatement.bank);
  },

  methods: {
    setStep(step: number) {
      this.store.step = step;
      if (step == 3) {
        this.$router.push(
          `/bank-statement/create/${this.$route.params.id}/approval`
        );
      } else if (step == 2) {
        this.$router.push(
          `/bank-statement/create/${this.$route.params.id}/item`
        );
      } else if (step == 1) {
        this.$router.push(
          `/bank-statement/create/${this.$route.params.id}/head`
        );
      }
    },
  },
});
