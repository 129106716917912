import { render, staticRenderFns } from "./InvoiceFlowSettingsPageTemplate.vue?vue&type=template&id=193f31f8&scoped=true&"
import script from "./InvoiceFlowSettingsPageTemplate.vue?vue&type=script&lang=ts&"
export * from "./InvoiceFlowSettingsPageTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./InvoiceFlowSettingsPageTemplate.vue?vue&type=style&index=0&id=193f31f8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "193f31f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCol,VDivider,VExpandTransition,VIcon,VListItem,VListItemContent,VListItemTitle,VRow,VSheet,VSwitch,VTextField})
