export default interface Glim {
  editable: boolean;
  select: boolean;
  id: number;
  year: number;
  accno: string;
  national: string;
  foreign: string;
  english: string;
  nationalLong: string;
  note: string;
  isAccum: boolean;
  thisLevelCharno: number;
  downLevelCharno: number;
  mainType: string;
  detailedType: string;
  isManualAcc: boolean;
  isFromDms: boolean;
  status: string;
  dim1: string;
  dim2: string;
  dim3: string;
  dim4: string;
  dim5: string;
  dim6: string;
  dim7: string;
  dim8: string;
  dim9: string;
  currStat: boolean;
  currAccno: number;
  insDate: Date;
  user: string;
  partner: string;
  isAcross: boolean;
  acrossAccno: string;
  isOpen: boolean;
  openAccno: string;
  isClose: boolean;
  closeAccno: string;
  isPOpenClose: boolean;
  isAccounting: boolean;
}

export const DefaultPropsGlim: Glim = {
  editable: true,
  select: false,
  id: 1,
  year: 1,
  accno: "",
  national: "",
  foreign: "",
  english: "",
  nationalLong: "",
  note: "",
  isAccum: false,
  thisLevelCharno: 1,
  downLevelCharno: 1,
  mainType: "",
  detailedType: "",
  isManualAcc: false,
  isFromDms: false,
  status: "",
  dim1: "",
  dim2: "",
  dim3: "",
  dim4: "",
  dim5: "",
  dim6: "",
  dim7: "",
  dim8: "",
  dim9: "",
  currStat: false,
  currAccno: 1,
  insDate: new Date(),
  user: "",
  partner: "",
  isAcross: false,
  acrossAccno: "",
  isOpen: false,
  openAccno: "",
  isClose: false,
  closeAccno: "",
  isPOpenClose: false,
  isAccounting: false,
};
