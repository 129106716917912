



























































import IconButton from "@/components/IconButton.vue";
import PageListView from "@/components/PageListView.vue";
import Vue from "vue";
import { ActionLog } from "../models";
import * as api from "../services/api";

interface Data {
  logs: ActionLog[];
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { PageListView, IconButton },

  data: () => ({
    logs: [],
  }),

  async beforeMount() {
    await api.getAll().then((result) => {
      this.logs = result?.map((x: ActionLog) => new ActionLog(x));
    });
  },

  methods: {
    async reload() {
      await api.getAll().then((result) => {
        this.logs = result?.map((x: ActionLog) => new ActionLog(x));
      });
    },
  },

  computed: {
    columns: () => [
      { width: 55, cell: "detailsButtonTemplate", sortable: false, filterable: false },
      {
        field: "tableName",
        title: "Érintett tábla neve",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "recordID",
        title: "Azonosító",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "actionName",
        title: "Akció",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "insertUserName",
        title: "Akciót végrehajtotta",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "date",
        type: "date",
        format: "{0: yyyy. MM. dd. HH:mm:ss}",
        title: "Dátum",
        headerClassName: "center_text adjust_center",
        className: "center_text adjust_center",
      },
    ],
  },
});
