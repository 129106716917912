import { render, staticRenderFns } from "./BankExtractImportItemDetails.vue?vue&type=template&id=021cfe0d&scoped=true&"
import script from "./BankExtractImportItemDetails.vue?vue&type=script&lang=ts&"
export * from "./BankExtractImportItemDetails.vue?vue&type=script&lang=ts&"
import style0 from "./BankExtractImportItemDetails.vue?vue&type=style&index=0&id=021cfe0d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "021cfe0d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
installComponents(component, {VChip,VCol,VDivider,VExpandTransition,VRow,VSimpleTable,VTab,VTabItem})
