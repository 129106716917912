


















































































































































import Vue from "vue";
import { LetterOfCreditStore } from "@/modules/letterOfCredit/letterOfCreditApi";
import InputWithLabel from "@/components/InputWithLabel.vue";
import DocumentAttach from "@/components/DocumentAttach/DocumentAttach.vue";

export default Vue.extend({
  components: { InputWithLabel, DocumentAttach },
  data() {
    return {
      store: new LetterOfCreditStore(),
      amount: "",
      cost: "",
    };
  },
  async beforeMount() {
    const id = Number(this.$route.params.id);
    await this.store.setItem(id);
    this.amount = this.store.item.current.amount.toString();
    this.cost = this.store.item.current.cost.toString();
  },
  methods: {
    async save() {
      const goBack = this.store.item.current.id > 1;
      await this.store.save();

      if (goBack) this.back();
    },
    back() {
      this.$router.push("/letters-of-credit");
    },
  },
  watch: {
    amount(newa, olda) {
      this.store.item.current.amount = Number(newa);
    },
    cost(newa, olda) {
      this.store.item.current.cost = Number(newa);
    },
  },
  computed: {
    statusName(): string {
      return (
        this.store.item.statusses.find(
          (x) => x.id == this.store.item.current.status
        )?.name ?? ""
      );
    },
  },
});
