import CashPointDisplay from "../models/CashPointDisplay"
import CashPointEdit,  { cashPointEditDefaultProps } from "../models/CashPointEdit"
import AccountNoDetailedType from "../models/AccountNoDetailedType"
import CashRegType from "../models/CashRegType"
import PartBank from "../models/PartBank"
import Printer from "../models/Printer"
import Depot from "../models/Depot"
import Country from "../models/Country"
import CashPointApi from "./CashPointApi"
import busyIndication from "../../../utils/busyIndication";
import CashpointType from "../models/CashpointType"
import http, { handleApiResponse } from "@/services/http"
import User from "@/modules/invoiceGroup/models/User"
import ApiResponse from "@/models/ApiResponse"


export class CashPointStore {
    cashPoints: CashPointDisplay[] = []
    cashPoint: CashPointEdit = { ...cashPointEditDefaultProps }
    typedCashpoints: CashPointDisplay[] = [];
    accountDetailedTypes: AccountNoDetailedType[] = []
    cashRegTypes: CashRegType[] = []
    partBanks: PartBank[] = []
    printers: Printer[] = []
    depots: Depot[] = []
    currencies: Country[] = []
    partBanksIsAlive = true;
    cashpointTypes: CashpointType[] = [];

    @busyIndication()
    async loadCashPoints() {
        this.cashPoints = (await CashPointApi.getAll()).map((i) => ({ ...i, deleting: false }))
    }

    async loadAccountNoDetailedTypes() {
        this.accountDetailedTypes = await CashPointApi.getAccountNoDetailedTypes()
    }

    async loadCashRegTypes() {
        this.cashRegTypes = await CashPointApi.getCashRegTypes()
    }

    async loadPartBanks() {
        this.partBanks = await CashPointApi.getPartBanks(this.partBanksIsAlive)
    }

    async loadPrinters() {
        this.printers = await CashPointApi.getPrinters()
    }

    async loadDepots() {
        this.depots = await CashPointApi.getDepots()
    }

    async loadCurrencies() {
        this.currencies = await CashPointApi.getCurrencies()
    }

    async loadCashpointTypes()
    {
        this.cashpointTypes = await CashPointApi.getCashpointTypes();
    }

    async loadCashpointsByType(type: number){
        await this.loadCashPoints();
        this.typedCashpoints = this.cashPoints.filter(x=>x.cashpointType===type);
    }

    @busyIndication()
    async loadCashPoint(id: number) {
        this.cashPoint = id > 1 ?  await CashPointApi.get(id) : await CashPointApi.getNew()
    }

    async getEmployeesWithCashpoint(){
        return await http.get<ApiResponse<User[]>>("employees/has-cashpoint").then(handleApiResponse);
    }

    async update() {
        if(!this.cashPoint) {
            return
        }

        try {
            if (this.cashPoint.id > 1){
                await CashPointApi.update(this.cashPoint)
            }
            else{
                await CashPointApi.create(this.cashPoint)
            }
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async updateCashiers(){
        if(!this.cashPoint) {
            return
        }

        try {
            await CashPointApi.updateCashiers(this.cashPoint.cashUsers, this.cashPoint.id)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async updateTaxGroups(){
        if(!this.cashPoint) {
            return
        }

        try {
            await CashPointApi.updateTaxGroups(this.cashPoint.taxGroups, this.cashPoint.id)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async delete(id: number) {
        try {
            await CashPointApi.delete(id)
            this.cashPoints = this.cashPoints.filter(b => b.id !== id)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    setDeleting(item: CashPointDisplay) {
        item.deleting = !item.deleting;
        this.cashPoints = [...this.cashPoints];    
    }
}

const store = new CashPointStore()
export default store