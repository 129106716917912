export default interface Login {
    name: string;
    password: string;
    code: string;
}

export const loginDefaultProps: Login = {
    name: '',
    password: '',
    code: '',
}