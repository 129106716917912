




import Vue from "vue";
import PowerBIApi from "../services/PowerBIApi";

interface Data {
  url: string;
  reportId: string;
}

interface Computed {
  routeParamId: string;
}

interface Method {
  getReport(): void;
}

export default Vue.extend<Data, Method, Computed, {}>({
  methods: {
    async getReport() {
      const existingReport = document.getElementsByTagName("script").item(0);
      existingReport?.remove();
      this.reportId = this.$route.params.id;

      if (document.getElementById("my-datatable")) {
        return;
      }
      const scriptTag = document.createElement("script");
      scriptTag.src =
        "https://cdnjs.cloudflare.com/ajax/libs/powerbi-client/2.18.7/powerbi.min.js";
      scriptTag.id = "my-datatable";
      document.getElementsByTagName("head")[0].appendChild(scriptTag);

      const model = await PowerBIApi.get(this.reportId);

      const reportContainer = document.getElementById("embedContainer");
      this.url = model.embedReports[0].embedUrl;

      const accessToken = model.embedToken.token;
      const embedUrl = model.embedReports[0].embedUrl;
      const embedReportId = model.embedReports[0].reportId;
      const tokenExpiry = model.embedToken.expiration;

      const models = ((window as any["powerbi-client"]) as any).models;

      const config = {
        type: "report",
        tokenType: 1,
        accessToken: accessToken,
        embedUrl: embedUrl,
        id: embedReportId,
        permissions: 7,
        settings: {
          // Enable this setting to remove gray shoulders from embedded report
          background: 1,
          filterPaneEnabled: true,
          navContentPaneEnabled: true,
        },
      };
      const report = (window as any).powerbi.embed(reportContainer, config);
    },
  },
  data() {
    return {
      url: "",
      reportId: "",
    };
  },

  computed: {
    routeParamId() {
      return this.$route.params.id;
    },
  },

  watch: {
    routeParamId() {
      this.getReport();
      console.log(this.routeParamId);
    },
  },

  async mounted() {
    this.reportId = this.$route.params.id;
    console.log(this.reportId);
    console.log("mounted");
    this.getReport();
  },
});
