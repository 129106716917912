import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import GeneralDenom from "../models/GeneralDenom";
import CashStatementDenom from "@/modules/cashStatement/models/CashStatementDenom";

export default class DenominationApi {
  static getGeneralDenoms(): Promise<GeneralDenom[]> {
    return http.get<ApiResponse<GeneralDenom[]>>(`denomination/general`).then(handleApiResponse);
  }

  static createStatementDenoms(statementDenoms: CashStatementDenom[]): Promise<boolean> {
    return http.post(`denomination/statement`, { statementDenoms: statementDenoms }).then(checkResponse);
  }
}
