import { ValidationRules } from "@/models/Validation";

export default interface Bank {
  id: number;
  name: string;
  code: string;
  defFilePath: string;
  defFilePathRem: string;
  extCode: string;
  partnerID: number;
  cardCost: number;
  address: string;
  isBankCardPay: boolean;
  isBank: boolean;
  deleted: boolean;
  deleting: boolean;
  inEdit: boolean;
}

export const rules: ValidationRules = {
  code: [
    (v: string) => (!v || v.length <= 2 || v == '-') || "A kód nem lehet hosszabb mint 2 karakter",
  ],
  extCode: [
    (v: string) => (!v || v.length <= 3) || "A külső kód nem lehet hosszabb mint 3 karakter",
  ],
  name: [(v: string) => !!v || "Megnevezés megadása kötelező"],
};

export const bankDefaultProps: Bank = {
  id: 0,
  name: "",
  code: "",
  defFilePath: "",
  defFilePathRem: "",
  extCode: "",
  partnerID: 0,
  cardCost: 0,
  address: "",
  isBankCardPay: false,
  isBank: true,
  deleted: false,
  deleting: false,
  inEdit: false,
};
