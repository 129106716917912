
























import Vue from "vue";
import IconButton from "../../../components/IconButton.vue";
import OtherItemsTable from "./OtherItemsTable.vue";

import * as api from "../services/api";
import eventBus from "../../../services/eventBus";
import { OtherItem } from "../models";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  searchText: string;
  otherItems: OtherItem[];
  flowStore: InvoiceFlowStore;
  configuration: AppConfiguration;
}

interface Methods {
    search(e: KeyboardEvent): void;
    clear(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
    components: {
        IconButton,
        OtherItemsTable,
    },

    data: () => ({
        searchText: "",
        otherItems: [],
        flowStore,
        configuration,
    }),

    mounted() {
        const otherItemItemAddHandler = () => (this.otherItems = []);
        eventBus.$on("invoice-item:add", otherItemItemAddHandler);
    },

    methods: {
        search(e: KeyboardEvent) {
            if (e.key != "Enter") {
                return;
            }

            if (this.searchText) {
                return api.searchRectifyWscontParts(this.searchText).then((data) => (this.otherItems = data));
            }
        },

        clear() {
            this.searchText = "";
            this.otherItems = [];
        },
    }
})
