













































import Vue from 'vue';
import store, { KanriInvoiceStore } from "../services/KanriInvoiceStore";
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';
import { FilteringModel } from '@/services/filtering';
import ApiSearchPage from '@/components/apiSearch/components/ApiSearchPage.vue';
import IconButton from "@/components/IconButton.vue";
import GenoExperimentalInvoice from '../models/GenoExperimentalInvoice';
import eventBus from '@/services/eventBus';

interface Data {
    store: KanriInvoiceStore;
    url: string;
}

export default Vue.extend<Data, {}, {}, {}>({ 
  components: {
    ApiSearchPage,
    IconButton
  },

  data() {
    return {
        url: "geno/experimental-invoices",
        store,
    }
  }, 
  
  methods: {
    showDetails(head: GenoExperimentalInvoice) {
        head.selected = !head.selected;
    },

    editGeno(head: GenoExperimentalInvoice) {
        this.$router.push(`geno-experimental-invoices/${head.id}`);
    },

    async deleteGeno(head: GenoExperimentalInvoice){
        await this.store.deleteGeno(head.id);
        eventBus.$emit("genoExperimentalInvoices:reload");
    }
  },

    computed: {
        columns: () => [
            {
                cell: "selectButton",
                filterable: false,
            },
            {
                field: "id",
                title: "Azonosító",
                width: 80
            },
            {
                field: "partnerName",
                title: "Partner",
            },
            {
                field: "bankAccountName",
                title: "Bankszámla szám",
            },
            {   field: "pmodeName", title: "Fizetési mód" },
            // {
            //     field: "statusName",
            //     title: "Státusz"
            // },
            {
                field: "invTypeName",
                title: "Számla típusa",
            },
            {
                field: "currency",
                title: "Pénznem",
            },
            {
                field: "invDate",
                title: "Számla dátuma",
                type: ColumnType.Date,
            },
            {   field: "car", title: "Gépjármű", type: ColumnType.Right },
            {
                cell: "editButton",
                filterable: false,
            },
        ]
    },
})
