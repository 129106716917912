
























import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import PartBank, { partBankDefaultProps } from "../../cashPoint/models/PartBank";
import BankAccountPicker from "../../invoiceFlow/components/BankAccountPicker.vue";
import dayjs from "dayjs";
import { bankRemitAndItemsDefaultProps } from "../models/BankRemitAndItems";

interface Data {
  store: BankRemitStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    BankAccountPicker,
  },

  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadPartBanks();
    this.store.partBankItem = { ...partBankDefaultProps };
    if (window.localStorage.getItem("previousPage") == "list") {
      this.store.bankRemit = { ...bankRemitAndItemsDefaultProps };
    }
  },

  methods: {
    cancelCreate() {
      this.$router.push("bank-remits");
    },

    bankAccountSelected(partBank: PartBank) {
      this.store.partBankItem = { ...partBank };

      this.store.bankRemit.bank = this.store.partBankItem.id;
      this.store.bankRemit.bankAccount = this.store.partBankItem.accountNo;
      this.store.bankRemit.bankName = this.store.partBankItem.bank;
      this.store.bankRemit.currency = this.store.partBankItem.currency;
      this.store.bankRemit.path = this.store.partBankItem.path;

      if (this.store.bankRemit.id < 2) {
        this.store.bankRemit.remitDate = dayjs().format("YYYY-MM-DD");
      }

      this.$nextTick(() => this.$router.push(`bank-remits/create/${this.store.bankRemit.id}/head`));
    },
  },
});
