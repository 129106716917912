

























































import Vue from "vue";
import store, {
  InvoiceFlowStore,
} from "../../invoiceFlow/services/InvoiceFlowStore";
import permStore, {
  PermissionStore,
  InvoicePermissions,
} from "@/services/permissions";
import cStore, { CustomsInvoiceStore } from "../services/CustomsInvoiceStore";
import { attachmentDefaultProps } from "../../generalIngoingInvoice/models/Attachment";
import eventBus from "@/services/eventBus";

interface Data {
  permStore: PermissionStore;
  store: InvoiceFlowStore;
  cStore: CustomsInvoiceStore;
  fileNote: string;
  page: number;
}

interface Methods {
  addFile(): void;
  openInNewTab(): void;
}

interface Computed {
  isAttachmentEmpty: boolean;
}

interface Props {
  invoiceId: number;
  isEditingEnabled: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    invoiceId: Number,
    isEditingEnabled: Boolean,
  },

  data() {
    return {
      permStore,
      store,
      cStore,
      fileNote: "",
      page: 1,
    };
  },

  async beforeMount() {
    const invoiceId = Number(this.$route.params.invoiceId);
    if (invoiceId > 1) {
      await this.store.loadGENIPicture(invoiceId);
    } else {
      this.store.attachment = { ...attachmentDefaultProps };
    }
  },

  beforeDestroy() {
    this.store.attachment = { ...attachmentDefaultProps };
  },

  methods: {
    openInNewTab() {
      window.open(this.store.attachment.link, "_blank");
    },

    async addFile() {
      //   if (
      //     !this.permStore.has([InvoicePermissions.Recording]) &&
      //     !this.permStore.hasInvoice([InvoicePermissions.Controlling])
      //   ) {
      //     console.log("jog");

      //     eventBus.$emit("error", "Nincs jogosultsága a számla kép feltöltéshez!");
      //     return;
      //   }

      const file: File = (this.$refs.file as any).files[0];
      if (!file) return;

      console.log(file.size);
      if (file.size > 50 * 1024 * 1024) {
        eventBus.$error("A számla kép mérete nem lehet nagyobb mint 50MB.");
        return;
      }

      this.store.attachment = {
        id: 0,
        file: file,
        link: "",
        note: this.fileNote,
        name: file.name,
        fileBase64: "",
        size: file.size,
      };

      if (this.cStore.invoice.id == 0) {
        // eventBus.$emit("geni-picture:new");
        console.log("new");
      } else {
        await this.store.uploadGENIPicture(this.cStore.invoice.id);
        await this.store.loadGENIPicture(this.cStore.invoice.id);
      }
    },
  },

  computed: {
    isAttachmentEmpty() {
      return (
        this.store.attachment != undefined &&
        this.store.attachment.id == 0 &&
        this.store.attachment.file == ""
      );
    },
  },
});
