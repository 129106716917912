


























































































































import Vue from "vue";
import store, { BankStore } from "../services/BankStore";
import Bank from "../models/Bank";
import setAttribute from "../../../utils/dom";
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor,
} from "@progress/kendo-data-query";

interface Data {
  store: BankStore;
  sort: SortDescriptor[];
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "8");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    confirmation: confirmationTemplate,
  },

  data(): Data {
    return {
      store,
      sort: [],
    };
  },

  async beforeMount() {
    await this.store.loadBanks();
  },

  computed: {
    columns: () => [
      { field: "name", title: "Bank neve" },
      { field: "extCode", title: "Külső kód" },
      {
        field: "isBank",
        title: "Posta/Bank",
        editor: "boolean",
        cell: "isBankTemplate",
      },
      { field: "code", title: "Bank kód" },
      { field: "address", title: "Cím" },
      {
        field: "cardCost",
        title: "Kártyás fizetés klts",
      },
      {
        field: "isBankCardPay",
        title: "Bankkártyás fiz.?",
        editor: "boolean",
        cell: "isBankCardPayTemplate",
      },
      { cell: "commandTemplate", width: "120px" },
      { width: 0 },
    ],

    items() {
      return orderBy(this.store.banks, this.sort);
    },
  },

  methods: {
    createBank() {
      this.$router.push(`/banks/create`);
    },

    deleteBank(item: Bank) {
      this.store.deleteBank(item.id);
    },

    updateBank(item: Bank) {
      this.$router.push(`/bank/${item.id}/edit`);
    },

    deleteItem(item: Bank) {
      this.store.setDeleting(item);
    },

    confirmDelete(item: Bank) {
      item.deleting = false;
      this.store.deleteBank(item.id);
    },

    cancelDelete(item: Bank) {
      item.deleting = false;
    },

    editItem(item: Bank) {
      this.store.setEditing(item);
    },

    itemChange({ dataItem, value, field }: { dataItem: Bank; value: any; field: string }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      this.store.save(item, update);
    },

    cancelEdit(item: Bank) {
      item.inEdit = false;
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    }
  },
});
