
































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import Vue from "vue";
import store, { InvoiceSubmissionStore } from "../services/store";
import pStore, {
  PaymentModeStore,
} from "@/modules/paymentMode/services/PaymentModeStore";
import CheckBox from "@/components/CheckBox.vue";
import InvoiceSubmitModel from "../models/InvoiceSubmitModel";
import eventBus from "@/services/eventBus";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";

export default Vue.extend({
  async beforeMount() {
    await this.store.loadAccountStatuses();
    await this.pStore.loadPaymentModesWithoutBusy();
  },

  data() {
    return {
      store,
      pStore,
      selectedItems: new Array<InvoiceSubmitModel>(),
      showedItems: new Array<InvoiceSubmitModel>(),
    };
  },

  methods: {
    async preAccounting(): Promise<void> {
      const result = await http
        .post(`ledger-submissions/pre-accounting`, this.selectedItems)
        .then(checkResponse);

        if(result){
          eventBus.$emit("Sikeres újrakontírozás!")
          this.selectedItems = [];
        }

        eventBus.$emit("invoice-ledgers:reload")
    },

    async postAccounting(): Promise<void> {
      const result = await http
        .post(`ledger-submissions/post-accounting`, this.selectedItems)
        .then(checkResponse);

        if(result){
          eventBus.$emit("Sikeresen feladva főkönyvbe!")
          this.selectedItems = [];
        }

        eventBus.$emit("invoice-ledgers:reload")
    },

    setShowedItems(items: InvoiceSubmitModel[]): void {
      this.showedItems = items;
    },

    toggleAllSelected(): void {
      eventBus.$emit("invoice-ledgers:get-items");

      setTimeout(() => {
        if (this.showedItems.some((item) => !this.isItemSelected(item))) {
          const notSelectedItems = this.showedItems.filter(
            (item) => !this.isItemSelected(item)
          );
          notSelectedItems.forEach((item) => this.selectedItems.push(item));
          return;
        }

        this.selectedItems = [];
      }, 100);
    },

    isItemSelected(item: InvoiceSubmitModel): boolean {
      return this.selectedItems.filter((x) => x.id === item.id).length > 0;
    },

    toggleItemSelected(item: InvoiceSubmitModel): void {
      if (this.isItemSelected(item)) {
        this.selectedItems = this.selectedItems.filter((x) => x !== item);
        return;
      }

      this.selectedItems.push(item);
    },
  },

  components: { ApiSearchPage, CheckBox },

  computed: {
    isAllSelected(): boolean {
      return !this.showedItems.some((item) => !this.isItemSelected(item));
    },

    url(): string {
      return `ledger-submissions/search`;
    },

    columns: (): ApiSearchColumn[] => [
      {
        field: "id",
        cell: "selector",
        fieldClass: "text-center",
        header: "selectorHeader",
        sortable: false,
      },
      {
        title: "Számla azonosító",
        field: "id",
      },
      {
        title: "Prefix",
        field: "prefix",
      },
      {
        title: "Típus",
        field: "typeName",
      },
      {
        title: "Típus kód",
        field: "typeCode",
      },
      {
        title: "Számla száma",
        field: "externalInvNo",
      },
      {
        title: "Partner",
        field: "partner",
        cell: "partner",
      },
      {
        title: "Könyvelési státusz",
        field: "accStatus",
        cell: "accStatus",
        multiselect: true,
        multiselectField: "selectedAccStatuses",
        multiselectOptions: store.accountingStatuses.map((x) => ({
          text: x.name,
          value: x.id,
        })),
      },
      {
        title: "Nettó",
        field: "netto",
        type: ColumnType.Money,
      },
      {
        title: "Bruttó",
        field: "total",
        type: ColumnType.Money,
      },
      {
        title: "Áfa",
        field: "vat",
        type: ColumnType.Money,
      },
      {
        title: "Fizetési mód",
        field: "pmode",
        cell: "pmode",
        multiselect: true,
        multiselectField: "pmodes",
        multiselectOptions: pStore.paymentModes.map((x) => ({
          text: x.name,
          value: x.id,
        })),
        multiselectSelect: pStore.paymentModes
          .filter((x) => x.name === "Készpénz")
          .map((x) => x.id)[0],
      },
      {
        title: "Teljesítés dátuma",
        field: "shipDate",
        type: ColumnType.Date,
        range: true,
      },
      {
        title: "Számla kelte",
        field: "invDate",
        type: ColumnType.Date,
      },
      {
        title: "Költséghely",
        field: "accGroup",
        cell: "accGroup",
      },
    ],
  },
});
