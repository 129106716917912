


















































import Vue from "vue";
import UserAccountNo from "../models/UserAccountNo";
import store, { UserAccountNoStore } from "../services/UserAccountNoStore";
import uStore, { UserStore } from "../../user/services/UserStore";

interface Data {
  store: UserAccountNoStore;
  uStore: UserStore;
  valid: boolean;
}

const CREATE_TITLE = "Főkönyvi számla létrehozása";
const MODIFY_TITLE = "Főkönyvi számla adatok módosítása";

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
      uStore,
      valid: true,
    };
  },

  async beforeMount() {
    await this.store.loadUserAccountNo(Number(this.$route.params.accountId));
    await this.uStore.loadUsers();
  },

  computed: {
    title() {
      return this.store.userAccountNo?.id > 0 ? MODIFY_TITLE : CREATE_TITLE;
    },
  },

  methods: {
    cancel() {
      this.$router.push("/user-acc-no");
    },

    async save() {
      await this.store.save();
      this.$router.push("/user-acc-no");
    },
  },
});
