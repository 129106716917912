var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.noticeStore.noticeItems.length > 0)?_c('grid',{staticStyle:{"max-width":"100%"},attrs:{"data-items":_vm.noticeStore.noticeItems,"columns":[
      { field: 'name', title: 'Megnevezés', width: 200 },
      {
        field: 'quantity',
        title: 'Mennyiség',
        cell: 'quantityTemplate',
        width: 170,
      },
      {
        field: 'currUnitPrice',
        title: 'Egységár (dev)',
        cell: 'currUnitPriceTemplate',
        width: 170,
        headerClassName: 'right_text'
      },
      {
        field: 'currBrutto',
        title: 'Bruttó (dev)',
        cell: 'currBruttoTemplate',
        width: 170,
        headerClassName: 'right_text'
      },
      {
        field: 'currNetto',
        title: 'Nettó (dev)',
        cell: 'currNettoTemplate',
        headerClassName: 'right_text'
      },
      {
        field: 'currVat',
        title: 'ÁFA (dev)',
        cell: 'currVatTemplate',
        headerClassName: 'right_text'
      },
      {
        field: 'unitPrice',
        title: 'Egységár',
        cell: 'unitPriceTemplate',
        headerClassName: 'right_text'
      },
      {
        field: 'brutto',
        title: 'Bruttó',
        cell: 'bruttoTemplate',
        headerClassName: 'right_text'
      },
      {
        field: 'netto',
        title: 'Nettó',
        cell: 'nettoTemplate',
        headerClassName: 'right_text'
      },
      {
        field: 'vat',
        title: 'ÁFA',
        cell: 'vatTemplate',
        headerClassName: 'right_text'
      },
      { cell: 'commandTemplate', width: '55px' } ]},scopedSlots:_vm._u([{key:"quantityTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-input',{staticStyle:{"margin-top":"6px"},attrs:{"currency":props.dataItem.displayedUnit,"disabled":_vm.disabled},on:{"input":function($event){return _vm.quantityChanged(props.dataItem)}},model:{value:(props.dataItem.quantity),callback:function ($$v) {_vm.$set(props.dataItem, "quantity", _vm._n($$v))},expression:"props.dataItem.quantity"}})],1)]}},{key:"currUnitPriceTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-input',{staticStyle:{"margin-top":"6px"},attrs:{"currency":_vm.$props.invCurrency,"disabled":_vm.disabled},on:{"input":function($event){return _vm.unitPriceChanged(props.dataItem)}},model:{value:(props.dataItem.currUnitPrice),callback:function ($$v) {_vm.$set(props.dataItem, "currUnitPrice", _vm._n($$v))},expression:"props.dataItem.currUnitPrice"}})],1)]}},{key:"currBruttoTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-input',{staticStyle:{"margin-top":"6px"},attrs:{"currency":_vm.$props.invCurrency,"disabled":_vm.disabled},on:{"input":function($event){return _vm.bruttoChanged(props.dataItem)}},model:{value:(props.dataItem.currBrutto),callback:function ($$v) {_vm.$set(props.dataItem, "currBrutto", _vm._n($$v))},expression:"props.dataItem.currBrutto"}})],1)]}},{key:"currNettoTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{staticStyle:{"margin-top":"6px"},attrs:{"value":props.dataItem.currNetto,"currency":_vm.$props.invCurrency,"disabled":_vm.disabled}})],1)]}},{key:"currVatTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{staticStyle:{"margin-top":"6px"},attrs:{"value":props.dataItem.currVat,"currency":_vm.$props.invCurrency,"disabled":_vm.disabled}})],1)]}},{key:"unitPriceTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{staticStyle:{"margin-top":"6px"},attrs:{"value":props.dataItem.unitPrice,"currency":_vm.$props.currency,"disabled":_vm.disabled}})],1)]}},{key:"bruttoTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{staticStyle:{"margin-top":"6px"},attrs:{"value":props.dataItem.brutto,"currency":_vm.$props.currency,"disabled":_vm.disabled}})],1)]}},{key:"nettoTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{staticStyle:{"margin-top":"6px"},attrs:{"value":props.dataItem.netto,"currency":_vm.$props.currency,"disabled":_vm.disabled}})],1)]}},{key:"vatTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{staticStyle:{"margin-top":"6px"},attrs:{"value":props.dataItem.vat,"currency":_vm.$props.currency,"disabled":_vm.disabled}})],1)]}},{key:"commandTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}}],null,false,3716453154)}):_c('label',{staticStyle:{"font-style":"italic"}},[_vm._v("Nincs hozzárendelt tétel")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }