export default interface PartSupplier {
    id: number;
    partner: number;
    suplType: string;
    ptermDay: number;
    defCurrency: string;
    isCashAccounting: boolean;
    payMode: string;
    deleted: boolean;
    inEdit: boolean;
}

export const partSupplierDefaultProps: PartSupplier = {
    id: 0,
    partner: 0,
    suplType: '-',
    ptermDay: 0,
    defCurrency: '-',
    isCashAccounting: false,
    payMode: '-',
    deleted: false,
    inEdit: false,
}