var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid',{staticClass:"item-grid",attrs:{"columns":_vm.columns,"data-items":_vm.gStore.invoiceItemMinEditModelsByInvId,"detail":'detailsTemplate',"expand-field":'expanded'},scopedSlots:_vm._u([{key:"addInvoiceGroupTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[(_vm.store.invoice.rectify && (_vm.store.invoice.type == 9 || props.dataItem.currNetto == 0 || props.dataItem.isDpaymRect) && _vm.store.step != 6)?_c('div',[_c('v-chip',{staticClass:"green white--text",staticStyle:{"font-size":"11px","font-weight":"500","height":"20px"},attrs:{"label":""}},[_vm._v("Helyesbítés")])],1):(props.dataItem.isStockRelated && !props.dataItem.isService && _vm.store.step != 6)?_c('div',[_c('v-chip',{staticClass:"green white--text",staticStyle:{"font-size":"11px","font-weight":"500","height":"20px"},attrs:{"label":""}},[_vm._v("Készlet")])],1):(props.dataItem.isService && _vm.store.step != 6)?_c('div',[_c('v-chip',{staticClass:"green white--text",staticStyle:{"font-size":"11px","font-weight":"500","height":"20px"},attrs:{"label":""}},[_vm._v("Megrendelés")])],1):(props.dataItem.isCar && _vm.store.step != 6)?_c('div',[_c('v-chip',{staticClass:"green white--text",staticStyle:{"font-size":"11px","font-weight":"500","height":"20px"},attrs:{"label":""}},[_vm._v("Gépjármű")])],1):(_vm.store.invoice.dpaym && !_vm.configuration.sysParams.dpaymInvoiceGroupsMandatory && _vm.store.step != 6)?_c('div',[_c('v-chip',{staticClass:"green white--text",staticStyle:{"font-size":"11px","font-weight":"500","height":"20px"},attrs:{"label":""}},[_vm._v("Előleg")])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{
                selected: props.dataItem.selected,
              },staticStyle:{"border-right":"0px !important"},attrs:{"outlined":"","color":"primary","disabled":props.dataItem.isStockRelated},on:{"click":function($event){return _vm.showDimensions(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-bars")])],1)]}}],null,true)},[_c('span',[_vm._v("Dimenzió információk")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"infoButton ",class:{
                selected: props.dataItem.selected,
              },attrs:{"outlined":"","color":"primary","disabled":_vm.isPageDisabled || (_vm.accepting && !_vm.store.isEditingEnabled) || _vm.childCreating || props.dataItem.note == 'Sztornó'},on:{"click":function($event){return _vm.addInvoiceGroup(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Költséghely hozzáadása")])])],1)])]}},{key:"selectionTemplate",fn:function(ref){
              var props = ref.props;
return [(_vm.store.step != 6 && !_vm.isEditAfterVatConfession)?_c('td',[_c('div',{staticClass:"row flex-nowrap justify-center align-center"},[(_vm.store.isInvoiceItemApproved(props.dataItem.id) || (_vm.store.invoice.dpaym && !_vm.configuration.sysParams.dpaymInvoiceGroupsMandatory))?_c('v-icon',{staticClass:"white--text",attrs:{"color":"green"}},[_vm._v("fa-check-circle")]):_c('v-icon',[_vm._v("fa-check-circle")])],1)]):_c('td')]}},{key:"nameTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(props.dataItem.name),expression:"props.dataItem.name"}],staticClass:"k-textbox pa-2 mr-1",staticStyle:{"width":"100%"},attrs:{"disabled":!props.dataItem.editing},domProps:{"value":(props.dataItem.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(props.dataItem, "name", $event.target.value)}}})])]}},{key:"quantityTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('MoneyDisplay',{attrs:{"value":props.dataItem.quantity,"currency":props.dataItem.unit,"decimals":_vm.getPrecision}})],1)]}},{key:"unitPriceTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('MoneyDisplay',{attrs:{"value":props.dataItem.unitPrice,"currency":'',"decimals":_vm.getPrecision}})],1)]}},{key:"currNettoTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('MoneyDisplay',{attrs:{"value":props.dataItem.currNetto,"currency":'',"decimals":_vm.getPrecision}})],1)]}},{key:"currVatTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('MoneyDisplay',{attrs:{"value":props.dataItem.currVat,"currency":'',"decimals":_vm.getPrecision}})],1)]}},{key:"currBruttoTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('MoneyDisplay',{attrs:{"value":props.dataItem.currBrutto,"currency":'',"decimals":_vm.getPrecision}})],1)]}},{key:"remainingTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('MoneyDisplay',{attrs:{"value":props.dataItem.remaining,"currency":'',"decimals":_vm.getPrecision}})],1)]}},{key:"noteTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{staticStyle:{"text-align":"center !important","padding-right":"0px !important"}},[_c('v-text-field',{staticClass:"remove-bottom",attrs:{"outlined":"","single-line":"","hide-details":"auto"},on:{"keyup":function($event){return _vm.saveComment(props.dataItem, $event)}},scopedSlots:_vm._u([(props.dataItem.savingComment)?{key:"append-outer",fn:function(){return [_c('v-icon',{staticStyle:{"vertical-align":"middle","text-align":"center","margin-top":"-7px","margin-left":"-5px"},attrs:{"x-small":"","color":"primary"}},[_vm._v(" fa-refresh fa-spin ")])]},proxy:true}:null],null,true),model:{value:(props.dataItem.note),callback:function ($$v) {_vm.$set(props.dataItem, "note", $$v)},expression:"props.dataItem.note"}})],1)]}},{key:"detailsTemplate",fn:function(ref){
              var props = ref.props;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"default","flex-direction":"column","align-items":"center","justify-content":"center"}},[_c('InvoiceItemDimensionView',{attrs:{"isPageDisabled":_vm.isPageDisabled,"isStornoItem":props.dataItem.note == 'Sztornó',"invItem":props.dataItem.id,"netto":props.dataItem.currNetto,"isAfterVatConfession":_vm.isEditAfterVatConfession},on:{"refreshAll":_vm.refreshAll,"parent-childCreating":_vm.setChildCreating,"showMessageInParent":_vm.showMessageInParent}}),(props.dataItem.dim5edit)?_c('InvoiceItemFreeDimension',{staticStyle:{"width":"100%","border-top":"1px solid #dddddd !important","border-bottom":"1px solid #dddddd !important"},attrs:{"invItem":props.dataItem.id,"netto":props.dataItem.currNetto},on:{"cancelUpdate":function($event){return _vm.cancelFreeDimUpdate(props.dataItem)}}}):_vm._e()],1)]}},{key:"editSwitchTemplate",fn:function(ref){
              var props = ref.props;
return [(_vm.store.step === 6)?_c('td',{class:props.className},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{
              selected: props.dataItem.selected,
            },attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.editSwitch(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-bars")])],1)]}}],null,true)},[_c('span',[_vm._v("Szabad dimenzió")])])],1):_vm._e(),_c('td',{class:props.className})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }