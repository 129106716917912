
















































































































































































































































import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import BankRemitItem, {
  bankRemitItemDefaultProps,
} from "../models/BankRemitItem";
import BankRemitAnalitics from "./BankRemitAnalitcsView.vue";
import BankRemitItemSearchView from "./BankRemitItemSearchView.vue";
import BankOverOutItemSearchView from "./BankOverOutItemSearchView.vue";
import PaymentScheduleItemSearchView from "./PaymentScheduleItemSearchView.vue";
import CustomTab from "../../../components/CustomTab.vue";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import setAttribute from "../../../utils/dom";
import IconButton from "@/components/IconButton.vue";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import iStore, {
  InvoiceFlowStore,
} from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import Attachment, {
  attachmentDefaultProps,
} from "@/modules/generalIngoingInvoice/models/Attachment";
import iApi from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import { formatDate } from "@/utils/formatDates";
import auth from "@/services/auth";
import CheckBox from "@/components/CheckBox.vue";
import eventBus from "@/services/eventBus";
import configuration, { AppConfiguration } from "@/models/Configuration";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";

const confirmationTemplate = {
  render(h: any) {
    return h();
  },
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "8");
  },
};

class InvoiceDetails {
  approval: InvoiceApproval[] = [];
  showImage = false;
  imageLink = "";
}

class InvoiceApproval {
  approval = "-";
  approvalState = "-";
  approvalDate = new Date(1990,1,1);
  user = "-";
}

interface Data {
  store: BankRemitStore;
  createItem: boolean;
  tab: number | null;
  invoiceDetails: InvoiceDetails;
  iStore: InvoiceFlowStore;
  selectedItems: BankRemitItem[];
}

interface Props {
  canCreate: boolean;
  remitID: number;
  checkInvoicePaid: boolean;
}

export default Vue.extend<Data, {}, {}, Props>({
  components: {
    BankRemitAnalitics,
    BankRemitItemSearchView,
    BankOverOutItemSearchView,
    PaymentScheduleItemSearchView,
    CustomTab,
    MoneyDisplay,
    confirmation: confirmationTemplate,
    IconButton,
    CheckBox,
    ValidationError
  },

  data() {
    return {
      store,
      createItem: false,
      tab: null,
      invoiceDetails: new InvoiceDetails(),
      iStore,
      selectedItems: []
    };
  },

  async beforeMount() {
    this.store.step = 2;

    if (this.remitID <2 && this.store.bankRemit?.id != Number(this.$route.params.id)) {
      await this.store.loadBankRemit(Number(this.$route.params.id));
    }
    else{
      await this.store.loadBankRemit(Number(this.remitID))
    }
  },

  props: {
    canCreate: {type: Boolean, default: true},
    remitID: {type: Number, default: 1},
    checkInvoicePaid: {type: Boolean, default: false}
  },

  methods: {
    setSelected(item: BankRemitItem): void {
      item.selected = !item.selected;
      eventBus.$emit("remit-item-selected", item)
    },

    async setShowInvoiceItem(item: BankRemitItem) {
      this.store.bankRemit.items.forEach((x) => {
        if (x.itemId != item.itemId) {
          x.expanded = false;
          x.deleting = false;
          x.showInvoice = false;
        }
      });
      this.invoiceDetails = new InvoiceDetails();

      item.expanded = !item.showInvoice;
      item.showInvoice = !item.showInvoice;

      this.invoiceDetails.showImage = false;
      this.store.bankRemit.items = [...this.store.bankRemit.items];

      if (item.showInvoice) {
        this.invoiceDetails.approval = await http
          .get<ApiResponse<InvoiceApproval[]>>(
            `bank-remit/invoice-details/${item.invoice}`
          )
          .then(handleApiResponse)
          .then((x) => {
            return x;
          });
        await iStore.loadGENIPicture(item.invoice).then(() => {
          this.invoiceDetails.imageLink = this.iStore.attachment.link;
        });
      }
    },

    rowRenderColor(h: any, trElement: any, defaultsSlots: any, props: any) {
      const hasDueAmount = props.dataItem.dueAmount != 0;
      const trProps = { style: hasDueAmount ? "backgroundColor: #e6e6e6" : "" };
      return h("tr", trProps, defaultsSlots);
    },

    async create() {
      await this.store.saveItem();
    },

    setCreateItem() {
      this.createItem = true;
      this.store.bankRemitItem = { ...bankRemitItemDefaultProps };
    },

    setDeleteItem(item: BankRemitItem) {
      item.deleting = true;
      item.expanded = true;
      this.store.bankRemit.items = [...this.store.bankRemit.items];
    },

    async confirmDelete(item: BankRemitItem) {
      item.deleting = false;
      item.expanded = false;
      await this.store.deleteItem(item.itemId);
      await this.store.loadBankRemit(Number(this.$route.params.id));
    },

    cancelDelete(item: BankRemitItem) {
      item.deleting = false;
      item.expanded = false;
      this.store.bankRemit.items = [...this.store.bankRemit.items];
    },
  },

  computed: {
    itemColumns: () => [
      { field: "prefix", title: "Prefix", width: 60 },
      { field: "invoice", title: "Számla" },
      { field: "invNo", title: "Bizonylat szám" },
      {
        field: "partnerName",
        title: "Partner",
      },
      {
        field: "amount",
        title: "Számla összege",
        cell: "amountTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        field: "dueAmount",
        title: "Esedékes",
        cell: "dueAmountTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        field: "exchangeRate",
        title: "Árfolyamszorzó",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        title: "Utalási határidő",
        field: "pterm",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
        width: 150,
      },
      configuration.sysParams.bankDefrayerEnabled ? {
        title: "Bank költségviselő típusa",
        filed: "bankDefrType",
        cell: "bankDefrType"
      } : { width: "0px" },
      {
        cell: "commandTemplate",
        width: 120,
      },
      { width: 0, filterable: false },
    ],
    
    isAcceptor(){
      if(this.store.allApproverUser.find(x=>x.id==this.store.bankRemitApproval.acceptorId)?.name == auth.userName){
        
        return true;
      }
      else return false;
    }
  },
});
