




















































import Vue from 'vue';
import ApiSearchPageForLedger from '@/components/apiSearch/components/ApiSearchPageForLedger.vue';
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';
import TransactionItems from "@/modules/transactions/views/TransactionItems.vue";
import http, { handleApiResponse } from '@/services/http';
import ApiResponse from '@/models/ApiResponse';
import Country from '@/modules/cashPoint/models/Country';
import { FilteringModel } from '@/services/filtering';
import eventBus from '@/services/eventBus';
import CheckBox from "@/components/CheckBox.vue";

interface Data {
  transStats: any[];
  currencies: Country[];
  periods: any[];
  bookTypes: any[];
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    ApiSearchPageForLedger,
    TransactionItems,
    CheckBox
  },

  data() {
    return {
      transStats: [],
      currencies: [],
      periods: [],
      bookTypes: [],
    }
  },

  async beforeMount() {
    this.transStats = await http.get<ApiResponse<any[]>>("ledger/detail/trans-stats").then(handleApiResponse);
    this.currencies = await http.get<ApiResponse<Country[]>>("currency").then(handleApiResponse);
    //this.periods = await http.get<ApiResponse<any[]>>("ledger/head/periods").then(handleApiResponse);
    this.bookTypes = await http.get<ApiResponse<any[]>>("ledger/detail/book-types").then(handleApiResponse);
  },

  methods: {
    create() {
      this.$router.push(`/transactions/create/1`);
    },

    emitSetSelected(item: any) {
      eventBus.$emit("ledger-transactions:setSelected", item);
    }
  },

  computed: {
    columns: () => [
      {
        filterable: false,
        cell: "detailTemplate"
      },
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "partnerName",
        title: "Partner neve"
      },
      {
        field: "accEvent",
        title: "Gazdasági esemény"
      },
      {
        field: "accDate",
        title: "Könyvelés dátuma",
        type: ColumnType.Date
      },
      {
        field: "storno",
        title: "Sztornó?",
        cell: "stornoTemplate",
        filterable: false
      },
      {
        field: "transSt",
        title: "Sztornózott tranz."
      },
      {
        field: "periodName",
        title: "Periódus"
      },
      {
        field: "statusName",
        title: "Állapot"
      },
      {
        field: "typeName",
        title: "Tr típus"
      },
      {
        field: "remark",
        title: "Megjegyzés"
      },
      {
        cell: "editTemplate",
        filterable: false,
      },
      {
        filterable: false,
        width: 0
      }
    ],
  },
})
