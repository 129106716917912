import CashDocumentDisplay, { cashDocumentDisplayDefaultProps } from "./CashDocumentDisplay";
import CashStatement from "./CashStatement";

export default interface CashStatementDisplay {
  cashDocuments: CashDocumentDisplay[];
  statement: CashStatement;
  bankName: string;
  bankNumber: string;
  id: number;
  cashPoint: string;
  extractDate: string;
  extractTime: string;
  note: string | null;
  cashier: string;
  ownIdentifier: string;
  status: string;
  accNo: number;
  accStatus: string;
  currency: string;
  openBal: number;
  closeBal: number;
  openRate: number;
  closeRate: number;
  selected: boolean;
}

export const cashStatementDisplayDefaultProps: CashStatementDisplay = {
  cashDocuments: [],
  statement: new CashStatement({}),
  bankName: "",
  bankNumber: "",
  id: 0,
  cashPoint: "",
  extractDate: "",
  extractTime: "",
  note: "",
  cashier: "",
  ownIdentifier: "",
  status: "",
  accNo: 0,
  accStatus: "",
  currency: "HUF",
  openBal: 0,
  closeBal: 0,
  openRate: 0,
  closeRate: 0,
  selected: false,
};
