














































import Vue from "vue";
import IconButton from "@/components/IconButton.vue";
import CreditCardStore from "../services/CreditCardStore";
import Fragment from "@/components/Fragment.vue";
import Page from "../../../components/Page.vue";

interface Data {
  store: CreditCardStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { Fragment, IconButton, Page },
  data() {
    return {
      store: new CreditCardStore(),
    };
  },
  async beforeMount() {
    await this.store.set();
  },
  methods: {
    openEditor(id: number) {
      this.$router.push(`credit-card/${id}`);
    },
  },
});
