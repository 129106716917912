export default interface ProjectItem {
    id: number;
    head: number;
    headType: number;
    headID: number;
    insDate: string;
    insTime: string;
    insUserID: number;
    status: number;
    quantity: number;
    amount: number;
    prefix: string;
    currency: string;
    remark: string;
    poID: number;
    manpowerDate: string | null;
    isManual: boolean;
    costAlloc: number;
    deleted: boolean;
    readonly: boolean;
}

export const projectItemDefaultProps: ProjectItem = {
    id: 0,
    head: 1,
    headType: 1,
    headID: 1,
    insDate: '',
    insTime: '',
    insUserID: 1,
    status: 1,
    quantity: 0,
    amount: 0,
    prefix: '',
    currency: '',
    remark: '',
    poID: 1,
    manpowerDate: null,
    isManual: false,
    costAlloc: 0,
    deleted: false,
    readonly: false
}