

























































































import setAttribute from "../../../utils/dom";
import Vue from "vue";
import store, { BankStatementStore } from "../services/BankStatementStore";
import BankDocument from "../models/BankDocument";

interface Data {
  store: BankStatementStore;
  selectedDocument: BankDocument;
  filteredDocItems: any[];
  docItemsCount: number;
  activeDocumentTab: number;

  currentPageSize: number;
  currentPageNumber: number;
  firstPageNumberButtonVisible: boolean;
  firstPageNumberButtonValue: number;
  secondPageNumberButtonVisible: boolean;
  secondPageNumberButtonValue: number;
  thirdPageNumberButtonVisible: boolean;
  thirdPageNumberButtonValue: number;
  fourthPageNumberButtonVisible: boolean;
  fourthPageNumberButtonValue: number;
  fifthPageNumberButtonVisible: boolean;
  fifthPageNumberButtonValue: number;
}

interface Methods {
  changeActiveDocumentTab(id: number): void;
  setCountValue(): void;
  setCurrentPageNumber(pageNumber: number): void;
  setCurrentPageSize(pageSize: number): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  props: { documentID: Number, statementID: Number },
  data() {
    return {
      store,
      selectedDocument: new BankDocument(),
      filteredDocItems: [],
      docItemsCount: 0,
      activeDocumentTab: 0,

      currentPageSize: 5,
      currentPageNumber: 1,
      firstPageNumberButtonVisible: true,
      firstPageNumberButtonValue: 1,
      secondPageNumberButtonVisible: true,
      secondPageNumberButtonValue: 2,
      thirdPageNumberButtonVisible: true,
      thirdPageNumberButtonValue: 3,
      fourthPageNumberButtonVisible: true,
      fourthPageNumberButtonValue: 4,
      fifthPageNumberButtonVisible: true,
      fifthPageNumberButtonValue: 5,
    };
  },

  async beforeMount() {
    this.changeActiveDocumentTab(0);
    await this.store.loadBankDocumentsByStatementId(this.$props.statementID);

    this.selectedDocument = this.store.bankDocuments.find(
      (d: BankDocument) => d.bankStatementItem.id === this.$props.documentID
    ) ?? new BankDocument();

    this.filteredDocItems?.sort((a, b) => a.id - b.id);
  },

  mounted() {
    setAttribute(".documents-grid-incomes .k-detail-cell", "colspan", "9");
    setAttribute(".documents-grid-expenses .k-detail-cell", "colspan", "9");
  },

  methods: {
    changeActiveDocumentTab(id: number) {
      this.setCurrentPageNumber(1);
      this.activeDocumentTab = id;
      if (id > 0) {
        //this.filteredDocItems = this.store.bankDocItems; //?
        this.setCountValue();
      }
    },

    setCurrentPageNumber(pageNumber: number) {
      this.currentPageNumber = pageNumber;
    },

    setCountValue() {
      this.docItemsCount = this.filteredDocItems.length;
    },

    setCurrentPageSize(pageSize: number) {
      this.setCurrentPageNumber(1);
      this.currentPageSize = pageSize;
    },
  },
});
