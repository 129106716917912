





































import Vue from "vue";
import http, { handleApiResponse } from "@/services/http";
import eventBus from "../../../services/eventBus";
import store from "@/modules/invoiceItems/services/store";
import * as api from "@/modules/invoiceItems/services/api";
import invoiceflowStore, {
  InvoiceFlowStore,
} from "@/modules/invoiceFlow/services/InvoiceFlowStore";

interface Data {
  file: File | null;
  selectedIdentifier: string | null;
  selectedTitle: string | null;
  invoiceNo: string | null;
  invStore: InvoiceFlowStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      file: null,
      selectedIdentifier: null,
      selectedTitle: null,
      offset: true,
      items: ["Telefonszám", "Rendszám"],
      invStore: invoiceflowStore,
      invoiceNo: null,
    };
  },
  computed: {
    canUpload(): boolean {
      if (!!this.file && !!this.selectedIdentifier) return true;
      else return false;
    },
  },
  methods: {
    filesChanged(file: File): void {
      this.file = file;
    },
    setIdentifier(): void {
      if (this.selectedTitle == "Rendszám") {
        this.selectedIdentifier = "CarFuelBill";
      } else {
        this.selectedIdentifier = "PhoneBill";
      }
    },
    async uploadFile(file: File | null, identifier: string | Blob) {
      if (!file || !identifier) {
        return;
      }
      this.invoiceNo = this.invStore.invoice.invoiceNo;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("idType", identifier);
      formData.append("invoiceNo", this.invoiceNo);
      await http
        .post(`uploadExcelOld`, formData, {
          headers: {
            "Content-Type": "multipart/mixed",
          },
        })
        .then(handleApiResponse)
        .then(() => eventBus.$emit("success", "Excel feldolgozva!"))
        .then(function updateItems() {
          api.getInvoiceItems(store.invoiceID).then((invoiceItems) =>
            store.update({
              invoiceItems,
            })
          );
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
  },
});
