
























































































































































































































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import CurrentAccountFilter from "@/modules/currentAccount/components/CurrentAccountFilter.vue";
import CurrentAccountPanel from "@/modules/currentAccount/components/CurrentAccountPanel.vue";
import Vue from "vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import eventBus from "@/services/eventBus";
import store, {
  GeneralIngoingInvoiceEditModelStore,
} from "../generalIngoingInvoiceEditModel/services/GeneralIngoingInvoiceEditModelStore";

interface Data {
  toggleOne: number;
  selectedPartnerCount: number;
  headers: { text: string; value: string }[]; // Típusinformáció a headers tömb elemeiről
  headers2: { text: string; value: string }[]; // Típusinformáció a headers2 tömb elemeiről
  editStore: GeneralIngoingInvoiceEditModelStore;
}

interface Methods {
  handlePartnerSelectChanged(count: number): void;
  showInvImage(item: any): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  beforeMount() {
    // eventBus.$on(
    //   "multipartner-select-changed",
    //   this.handlePartnerSelectChanged
    // );
  },

  mounted(){
    (this.$refs.apiSearch as any).toggleExtraFilterVisibility();
  },

  data() {
    return {
      toggleOne: 0,
      selectedPartnerCount: 0,
      editStore: store,
      headers: [
        { text: "", value: "paymentSource", width: "200px" },
        { text: "", value: "paymentPartner", width: "200px" },
        { text: "", value: "paymentId", width: "100px" },
        { text: "", value: "payDate", width: "120px", align: "center" },
        { text: "", value: "amount", width: "200px", align: "end" },
        { text: "", value: "", width: "1px" },
      ],
      headers2: [
        { text: "", value: "paymentSource", width: "200px" },
        { text: "", value: "paymentPartner", width: "180px" },
        { text: "", value: "paymentId", width: "130px" },
        { text: "", value: "payDate", width: "130px", align: "center" },
        { text: "", value: "amountInCurrency", width: "180px", align: "end" },
        { text: "", value: "currency", width: "135px" },
        { text: "", value: "exchangeRate", width: "130px", align: "end" },
        { text: "", value: "amount", width: "200px", align: "end" },
        { text: "", value: "" },
      ],
    };
  },
  methods: {
    handlePartnerSelectChanged(count: number) {
      this.selectedPartnerCount = count;
    },

    showInvImage(item){
      this.editStore.openInvoiceImage(item.invNo, item.code);
    }
  },
  components: {
    ApiSearchPage,
    CurrentAccountFilter,
    CurrentAccountPanel,
    IconButton,
    Tooltip,
  },
  computed: {
    columns() {
      const cols: ApiSearchColumn[] = [
        {
          title: "Prefix",
          field: "code",
          fieldClass: "akarmi padding0",
          cell: "code",
          headerClass: "sticky"
        },
        {
          title: "Belső számla szám",
          field: "invNo",
          fieldClass: "akarmi padding0",
          cell: "invNo",
          headerClass: "sticky"
        },
        {
          title: "Külső számla szám",
          field: "outerInvNo",
          fieldClass: "akarmi",
          headerClass: "sticky"
        },
        {
          title: "Vevő/szállító",
          field: "customerOrSupplier",
          fieldClass: "akarmi padding0",
          headerClass: "sticky"
        },
        {
          title: "Bizonylat kiállítás dátuma",
          field: "issueDate",
          type: ColumnType.Date,
          fieldClass: "akarmi",
          headerClass: "sticky"
        },
        {
          title: "Bizonylat fizetési határideje",
          field: "payTerm",
          type: ColumnType.Date,
          fieldClass: "akarmi",
          headerClass: "sticky"
        },
      ];

      //if (this.selectedPartnerCount > 1) {
      cols.splice(3, 0, {
        title: "Partner",
        field: "partner",
        fieldClass: "akarmi",
        headerClass: "sticky"
      });
      //}

      if (this.$data.toggleOne === 0) {
        cols.push({
          title: "Bizonylat nettó forintban",
          field: "netto",
          type: ColumnType.Money,
          fieldClass: "akarmi align-right",
          headerClass: "sticky"
        });

        cols.push({
          title: "Bizonylat bruttó forintban",
          field: "total",
          type: ColumnType.Money,
          fieldClass: "akarmi align-right",
          headerClass: "sticky"
        });

        cols.push({
          title: "Bizonylat egyenlege forintban",
          field: "displayBalance",
          type: ColumnType.Money,
          fieldClass: "akarmi align-right",
          headerClass: "sticky"
        });
      }

      if (this.$data.toggleOne === 1) {
        cols.push({
          title: "Bizonylat nettó összege forintban",
          field: "netto",
          type: ColumnType.Money,
          fieldClass: "akarmi align-right",
          headerClass: "sticky"
        });

        cols.push({
          title: "Bizonylat áfa összege forintban",
          field: "vat",
          type: ColumnType.Money,
          fieldClass: "akarmi align-right",
          headerClass: "sticky"
        });

        cols.push({
          title: "Bizonylat összege forintban",
          field: "displayTotal",
          type: ColumnType.Money,
          fieldClass: "akarmi align-right",
          headerClass: "sticky"
        });
      }

      if (this.$data.toggleOne === 2) {
        const cols1: ApiSearchColumn[] = [
          {
            title: "Bizonylat nettó devizában",
            field: "currNetto",
            type: ColumnType.Money,
            fieldClass: "akarmi align-right",
            headerClass: "sticky"
          },
          {
            title: "Bizonylat összege devizában",
            field: "displayTotalInCurrency",
            type: ColumnType.Money,
            fieldClass: "akarmi align-right",
            headerClass: "sticky"
          },
          {
            title: "Bizonylat devizaneme",
            field: "currency",
            fieldClass: "akarmi",
            headerClass: "sticky"
          },
          {
            title: "Bizonylat nettó forintban",
            field: "netto",
            type: ColumnType.Money,
            fieldClass: "akarmi align-right",
            headerClass: "sticky",
          },
          {
            title: "Bizonylat összege forintban",
            field: "displayTotal",
            type: ColumnType.Money,
            fieldClass: "akarmi align-right",
            headerClass: "sticky"
          },
        ];

        cols.push(...cols1);
      }

      return cols;
    },
  },
});
