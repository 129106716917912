


































































































































































import { ConfirmationMixin } from "@/mixins/confirmation";

import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import SetIncorrectInvoice from "@/modules/invoiceFlow/components/SetIncorrectInvoice.vue";
import CurrencyPicker from "@/modules/invoiceFlow/components/CurrencyPicker.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import StockCorrectionStoreInSearch from "../components/StockCorrectionStoreInSearch.vue";
import StockCorrectionItems from "../components/StockCorrectionItems.vue";
import StockCorrectionMaskGenerate from "../components/StockCorrectionMaskGenerate.vue";
import InvoiceAccounting from "@/modules/invoiceFlow/views/InvoiceAccounting.vue";
import Confirmation from "@/components/Confirmation.vue";

import eventBus from "@/services/eventBus";
import dayjs from "dayjs";
import { debounce } from "debounce";
import { formatDateISO, newDate } from "@/utils/formatDates";
import store, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import permStore, { PermissionStore } from "@/services/permissions";
import partnerStore, { PartnerStore } from "@/modules/partner/services/PartnerStore";
import correctionStore, { UninvoicedStockCorrectionStore } from "../services/store";
import Partner from "@/modules/partner/models/Partner";

interface Data {
  store: InvoiceFlowStore;
  permStore: PermissionStore;
  partnerStore: PartnerStore;
  correctionStore: UninvoicedStockCorrectionStore;
  accountingVisible: boolean;
  statusColorMap: Record<string, string>;
}

interface Methods {
  partnerSelected(partner: Partner): void;
  cancel(): void;
  save(): void;
  approve(): void;
  accountingToggled(): void;
}

interface Computed {
  disabled: boolean;
  mainCodeWasUpdated: boolean;
}

export default ConfirmationMixin.extend<Data, Methods, Computed, {}>({
  components: {
    PartnerSearch,
    SetIncorrectInvoice,
    CurrencyPicker,
    MoneyInput,
    StockCorrectionStoreInSearch,
    StockCorrectionItems,
    StockCorrectionMaskGenerate,
    InvoiceAccounting,
    Confirmation,
  },

  mixins: [ConfirmationMixin],

  data: () => ({
    store,
    permStore,
    partnerStore,
    correctionStore,
    accountingVisible: false,
    statusColorMap: {
      default: "primary white--text",
      4: "orange white--text",
      5: "green white--text",
      8: "green white--text",
      6: "red darken-2 white--text",
      7: "red darken-2 white--text",
    },
  }),

  created() {
    this.accountingToggled = debounce(this.accountingToggled, 200);
  },

  async beforeMount() {
    this.store.reset();
    this.correctionStore.reset();

    const invoiceID = Number(this.$route.params.invoiceId) || 0;
    if (invoiceID > 1) this.correctionStore.items = [];

    await this.store.loadInvoice(invoiceID);

    this.store.setIsEditingEnabled();

    const partnerID = Number(this.$route.params.partnerId) || this.store.invoice.partner;

    await Promise.all([
      this.store.loadInvoiceDetails(partnerID),
      this.store.loadInvoiceDisplay(invoiceID),
      this.store.loadUninvoicedStockCorrectionSubtypes(),
      this.correctionStore.loadMainCode(invoiceID, partnerID),
    ]);

    // altípus - Készletérték módosítás típusa
    const subtype = this.store.subtypes.find((x) => x.id == this.store.invoice.subType);
    this.store.selectedSubtype = subtype ?? this.store.subtypes.find((x) => x.id > 1) ?? this.store.subtypes[0];

    // init
    if (this.store.invoice.id == 0) {
      this.store.invoice.partner = partnerID;
      const today = new Date();
      this.store.invoice.invoiceDate = formatDateISO(today);
      this.store.invoice.payTerm = dayjs(this.store.invoiceDetails.partnerPaymentDeadlineDate).format("YYYY-MM-DD");

      this.store.invoice.shipDate = formatDateISO(today);
      this.store.invoice.regDate = formatDateISO(today);
      this.store.invoice.arrivDate = formatDateISO(today);
      this.store.invoice.accDate = formatDateISO(today);
      this.store.invoice.insertDate = formatDateISO(today);
      this.store.invoice.insertTime = newDate(today);
      this.store.invoice.vatDate = formatDateISO(today);
    }

    this.store.setIsEditingEnabled();
  },

  methods: {
    async partnerSelected(partner: Partner) {
      const oldId = Number(this.$route.params.partnerId) || 0;
      this.store.invoice.partner = partner.id;

      await this.store.loadInvoiceDetails(partner.id);

      if (this.store.invoice.subType > 1) {
        eventBus.$emit("partner-changed");
      }

      if (oldId > 1 && oldId != partner.id) {
        this.$router.push(`/uninvoiced-stock-correction/create/${partner.id}`);
        this.correctionStore.items = [];
      }
    },

    cancel() {
      this.$router.push(`/uninvoiced-stock-correction`);
    },

    async save() {
      await this.store.saveUninvoicedStockCorrection(this.correctionStore.items).then(async (id) => {
        if (this.store.invoice.id == 0) {
          this.store.invoice.id = id;
          this.$router.push(`/uninvoiced-stock-correction/edit/${id}`);
        }

        if (this.mainCodeWasUpdated) {
          await this.correctionStore.saveMainCode(
            this.store.invoice.id,
            this.store.invoice.partner,
            this.store.selectedSubtype?.id ?? 1,
            this.correctionStore.mainCode
          );
        }

        eventBus.$emit("success", "Sikeres mentés!");
      });
    },

    async approve() {
      const confirmed = await this.confirm("Biztosan jóvá akarja hagyni?");
      if (!confirmed) return;

      await this.store
        .acceptUninvoicedStockCorrection(this.correctionStore.items)
        .then(async () => {
          if (this.mainCodeWasUpdated) {
            await this.correctionStore.saveMainCode(
              this.store.invoice.id,
              this.store.invoice.partner,
              this.store.selectedSubtype?.id ?? 1,
              this.correctionStore.mainCode
            );
          }
          eventBus.$emit("success", "Sikeres jóváhagyás!");
        })
        .then(async () => {
          const invoiceID = Number(this.$route.params.invoiceId) || 0;
          await Promise.all([this.store.loadInvoice(invoiceID), this.store.loadInvoiceDisplay(invoiceID)]);
          this.store.setIsEditingEnabled();
        });
    },

    accountingToggled() {
      if (!this.accountingVisible) return;
      this.$vuetify.goTo(document.body.scrollHeight, { duration: 1000 });
    },
  },

  computed: {
    disabled() {
      return this.store.invoice.id <= 1 || !this.store.isEditingEnabled;
    },

    mainCodeWasUpdated() {
      return (
        // new mainCode
        this.correctionStore.mainCode.id == 0 &&
        // subtype is selected
        this.correctionStore.mainCode.type > 1 &&
        this.store.invoice.subType > 1 &&
        // generated wasn't overwritten
        this.correctionStore.mainCode.generated == this.store.invoice.invoiceNo
      );
    },
  },
});
