var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" Költséghelyek ")]),_c('v-col',{staticClass:"text-right",attrs:{"md":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createInvoiceGroup}},[_vm._v(" Új költséghely ")])],1)],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns,"detail":"deletingTemplate","expand-field":'deleting'},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateInvoiceGroup(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}},{key:"deletingTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"yellowBg"},[_c('confirmation'),_c('div',{staticClass:"auth"},[_c('p',{staticClass:"conf"},[_vm._v(" Biztos hogy törli a következő elemet: "),_c('strong',[_vm._v(_vm._s(props.dataItem.name))]),_vm._v(" ? ")])]),_c('div',{staticClass:"btns"},[_c('v-btn',{staticClass:"con_btn",on:{"click":function($event){return _vm.confirmDelete(props.dataItem)}}},[_vm._v(" Megerősítés ")]),_c('v-btn',{staticClass:"canc_btn",on:{"click":function($event){return _vm.cancelDelete(props.dataItem)}}},[_vm._v(" Mégsem ")])],1)],1)]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }