import SerPartEditModel, {serPartEditModelDefaultProps} from "../models/SerPartEditModel";
import SerPartApi from "./SerPartApi";

export class SerPartStore{
    serPartEditModels: SerPartEditModel[]=[]
    serPartEditModel: SerPartEditModel = {...serPartEditModelDefaultProps}

    async loadSerPartEditModels(name: string){
        this.serPartEditModels = await SerPartApi.getAll(name);
    }
}

const store = new SerPartStore()
export default store