


















































































































































































































































import configuration from "../../../models/Configuration";
import Vue from "vue";
import fStore, { InvoiceFlowStatusStore } from "../../invoiceFlowStatus/services/InvoiceFlowStatusStore";
import rStore, { FlowDocumentRelationStore } from "../../flowDocumentRelation/services/FlowDocumentRelationStore";
import dtStore, { DocumentTemplateStore } from "../../documentTemplate/services/DocumentTemplateStore";
import invTypeStore, { IncomingInvoiceTypeStore } from "../../incomingInvoice/services/IncomingInvoiceTypeStore";
import invSubTypeStore, {
  IncomingInvoiceSubtypeStore,
} from "../../incomingInvoice/services/IncomingInvoiceSubtypeStore";
import tStore, { DocumentTypeStore } from "../../documentType/services/DocumentTypeStore";
import InvoiceFlowSettingsPageTemplate from "./InvoiceFlowSettingsPageTemplate.vue";
import { documentTemplateDefaultProps } from "../../documentTemplate/models/DocumentTemplate";
import eventBus from "../../../services/eventBus";

interface Data {
  configuration: any;
  isDocumentCardExpanded: boolean;
  //variable = false;
  fStore: InvoiceFlowStatusStore;
  rStore: FlowDocumentRelationStore;
  dtStore: DocumentTemplateStore;
  tStore: DocumentTypeStore;

  template: string;
  createDialog: boolean;
  invTypeStore: IncomingInvoiceTypeStore;
  invSubTypeStore: IncomingInvoiceSubtypeStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    InvoiceFlowSettingsPageTemplate,
  },

  data() {
    return {
      configuration,
      isDocumentCardExpanded: true,
      fStore,
      rStore,
      dtStore,
      tStore,
      template: "",
      createDialog: false,
      invTypeStore,
      invSubTypeStore,
    };
  },

  async beforeMount() {
    this.rStore.flowDocumentRelations = [];
    await this.fStore.loadInvoiceFlowStatusesWithoutBusy();

    await this.dtStore.loadDocumentTemplatesWithoutBusy();
    await this.invTypeStore.loadInvoiceTypesWithoutBusy();
    await this.invSubTypeStore.loadSubtypes();

    await this.tStore.loadDocumentTypes();
  },

  methods: {
    clearDocumentTemplateItem() {
      for (let index = 2; index < 7; index++) {
        eventBus.$emit("DocumentTemplateItemClearing" + index);
      }
    },

    addDocumentTemplateItem() {
      for (let index = 2; index < 7; index++) {
        eventBus.$emit("DocumentTemplateItemCreating" + index);
      }
    },

    async deleteFlowDocumentRelations(documentRelationId: number) {
      await this.rStore.delete(documentRelationId);
    },

    async templateChanged() {
      if (this.template) {
        const detailId = this.dtStore.documentTemplates.find((x: any) => x.name == this.template)?.id ?? 0;

        await this.rStore.loadFlowDocumentRelationsByHeadId(detailId);
      }
    },

    async deleteTemplate() {
      if (this.template) {
        const templateId = this.dtStore.documentTemplates.find((x: any) => x.name == this.template)?.id ?? 0;

        await this.dtStore.deleteTemplate(templateId);
        await this.dtStore.loadDocumentTemplates();
        this.rStore.flowDocumentRelations = [];
        this.template = "-";
      }
    },

    createTemplate() {
      this.createDialog = true;
      this.dtStore.documentTemplate = { ...documentTemplateDefaultProps };
    },

    cancelCreateTemplate() {
      this.createDialog = false;
    },

    async saveCreateTemplate() {
      if (this.dtStore.documentTemplate.name.trim() != "") {
        this.template = (await this.dtStore.createDocumentTemplate()) ?? "";
        this.createDialog = false;
      }
    },

    invTypeChangeHandler(event: any) {
      if (this.invTypeStore.invoiceTypes) {
        this.dtStore.documentTemplate.invTypeId =
          this.invTypeStore.invoiceTypes.find((x: any) => x.name == event)?.id ?? 1;
      }
    },

    invSubTypeChangeHandler(event: any) {
      if (this.invSubTypeStore.subtypes) {
        this.dtStore.documentTemplate.invSubTypeId =
          this.invSubTypeStore.subtypes.find((x: any) => x.name == event)?.id ?? 1;
      }
    },
  },
});
