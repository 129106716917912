import { render, staticRenderFns } from "./BankExtractImportItemsEdit.vue?vue&type=template&id=3d9d7bf5&scoped=true&"
import script from "./BankExtractImportItemsEdit.vue?vue&type=script&lang=ts&"
export * from "./BankExtractImportItemsEdit.vue?vue&type=script&lang=ts&"
import style0 from "./BankExtractImportItemsEdit.vue?vue&type=style&index=0&id=3d9d7bf5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d9d7bf5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VExpandTransition,VIcon,VSelect,VSimpleTable})
