import http, { handleApiResponse, checkResponse } from '@/services/http'
import FlowDocumentRelation from "@/modules/flowDocumentRelation/models/FlowDocumentRelation"
import ApiResponse from '@/models/ApiResponse'

export default class FlowDocumentRelationApi {
    static getAll(): Promise<FlowDocumentRelation[]> {
        return http
            .get<ApiResponse<FlowDocumentRelation[]>>(`flow-document-relation`)
            .then(handleApiResponse)
    }
    
    static getAllByHeadId(headId: number): Promise<FlowDocumentRelation[]> {
        return http
            .get<ApiResponse<FlowDocumentRelation[]>>(`flow-document-relation/head/${headId}`)
            .then(handleApiResponse)
    }

    static getAllByHeadAndInvoiceId(headId: number, invoiceId: number): Promise<FlowDocumentRelation[]> {
        return http
            .get<ApiResponse<FlowDocumentRelation[]>>(`flow-document-relation/head/${headId}/invoice/${invoiceId}`)
            .then(handleApiResponse)
    }

    static getAllByInvoiceId(invoiceId: number): Promise<FlowDocumentRelation[]>{
        return http
        .get<ApiResponse<FlowDocumentRelation[]>>(`flow-document-relation/invoice/${invoiceId}`)
        .then(handleApiResponse)
    }

    static getOtherDocumentsByInvoiceId(invoiceId: number): Promise<FlowDocumentRelation[]>{
        return http
        .get<ApiResponse<FlowDocumentRelation[]>>(`flow-document-relation/invoice/other/${invoiceId}`)
        .then(handleApiResponse)
    }

    static getAllByPartnerId(partnerId: number): Promise<FlowDocumentRelation[]>{
        return http
        .get<ApiResponse<FlowDocumentRelation[]>>(`flow-document-relation/partner/${partnerId}`)
        .then(handleApiResponse)
    }

    static get(flowDocumentRelationId: number): Promise<FlowDocumentRelation> {
        return http
            .get<ApiResponse<FlowDocumentRelation>>(`flow-document-relation/${flowDocumentRelationId}`)
            .then(handleApiResponse)
    }

    static delete(flowDocumentRelationId: number): Promise<boolean> {
        return http
            .delete(`flow-document-relation/${flowDocumentRelationId}`)
            .then(checkResponse)
    }

    static update(flowDocumentRelation: FlowDocumentRelation): Promise<boolean> {
        return http
            .put(`flow-document-relation/${flowDocumentRelation.id}`, flowDocumentRelation)
            .then(checkResponse)
    }

    static create(flowDocumentRelation: FlowDocumentRelation): Promise<number> {
        return http
            .post<ApiResponse<number>>(`flow-document-relation`, flowDocumentRelation)
            .then(handleApiResponse)
    }
    
    static generateDocumentDownLoadLink(documentId: number) {
        return http.defaults.baseURL + `document/download/${documentId}`;
    }

    static generateFinancialDocumentDownLoadLink(id: number) {
        return http.defaults.baseURL + `document/download/${id}`;
    }
}
