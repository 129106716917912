





























































import Vue from "vue";
import IconButton from "../../../components/IconButton.vue";
import noticeStore, { NoticeStore } from "@/modules/notice/services/NoticeStore";
import GPartsDisplay from "../models/GPartsDisplay";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";

interface Data {
  nameSearch: string | null;
  noticeStore: NoticeStore;
}

interface Methods {
  clear(): void;
  refreshApiSearch(): void;
  addItem(item: GPartsDisplay): void;
}

export default Vue.extend<Data, Methods, {}>({
  components: {
    IconButton,
    ApiSearchPage,
  },

  data: () => ({
    nameSearch: null,
    noticeStore,
  }),

  computed: {
    url() {
      // ha null-t/ures sztringet kuldunk vissza az inputbol a backendre, egy ures listat fog visszaadni, es az ApiSearch :hideOnEmpty="true" attr miatt eltunik a grid
      if (this.nameSearch != null) {
        return `gparts/searchbyname/${this.nameSearch}`;
      }

      return `gparts/searchbyname/`;
    },

    columns: (): ApiSearchColumn[] => [
      { field: "entityName", title: "Név", cell: "entityNameTemplate" },
      { field: "entityCode", title: "Külső cikkszám", cell: "entityCodeTemplate" },
      { field: "entityMaincode", title: "Belső cikkszám", cell: "entityMaincodeTemplate" },
      {
        field: "entityService",
        title: "Szolgáltatás",
        cell: "entityServiceTemplate",
      },
      { field: "vatName", title: "ÁFA" },
      {
        field: "entitySetUnitPrice",
        title: "Megáll.beszer.ár",
        cell: "entitySetUnitPriceTemplate",
      },
      { field: "entityDealerRabat", title: "Listaár árrése", cell: "entityDealerRabatTemplate" },
      { cell: "commandTemplate" },
    ],
  },

  methods: {
    clear() {
      this.nameSearch = null;
      this.refreshApiSearch();
    },

    addItem(item: GPartsDisplay) {
      const unitPrice = Math.round(-1 * (item.entity.setUnitPrice / (item.entity.dealerRabat / 100 - 1)));
      const brutto = Math.round(unitPrice * (1 + item.vatDegree / 100));
      const vat = brutto - unitPrice;

      this.noticeStore.noticeItems.push({
        id: 0,
        name: item.entity.name,
        gParts: item.entity.id,
        unitPrice: unitPrice,
        quantity: 1,
        unit: item.entity.unitType,
        statCode: item.entity.statCode1,
        netto: unitPrice,
        tax: item.entity.tax,
        vat: vat,
        brutto: unitPrice + vat,
        car: 1,
        currUnitPrice: unitPrice,
        currNetto: unitPrice,
        currVat: vat,
        currBrutto: unitPrice + vat,
        displayedUnit: item.unitType,
        displayedVat: item.vatName,
        unitVat: vat,
        vatDegree: item.vatDegree,
      });

      this.noticeStore.calculatePrices();
    },

    refreshApiSearch() {
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },
  },
});
