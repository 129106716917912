







import Vue from "vue";
import store, { InvoiceItemStore } from "../services/store";
import {
  ExtOrder,
  ExtOrderItem,
  InvoiceRelation,
  InvoiceRelationType,
  Stock,
  StoreIn,
} from "../models";

import IconButton from "../../../components/IconButton.vue";

const isAttachedToInvoice = (
  store: InvoiceItemStore,
  item: StoreIn | Stock | ExtOrder | ExtOrderItem
) => {
  let relations: InvoiceRelation[] = [];
  store.itemList.items.forEach((i) => {
    relations = [...relations, ...i.relations];
  });

  const itemData = item as any;
  if (itemData.stocks) {
    const storeIn = item as StoreIn;
    return (
      storeIn.stocks.length > 0 &&
      storeIn.stocks.every((s) =>
        relations.some(
          (i) =>
            i.type === InvoiceRelationType.Stock && s.stockID == i.relatedItemID
        )
      )
    );
  }

  if (itemData.storeInID && !itemData.stock) {
    const stock = item as Stock;
    return relations.some(
      (i) =>
        i.type === InvoiceRelationType.Stock &&
        i.relatedItemID === stock.stockID
    );
  }

  if (itemData.orderItems) {
    const order = item as ExtOrder;
    return (
      order.orderItems.length > 0 &&
      order.orderItems.every((o) =>
        relations.some(
          (i) =>
            i.type === InvoiceRelationType.ExtOrderItem &&
            o.id === i.relatedItemID
        )
      )
    );
  }

  if (itemData.orderID) {
    const orderItem = item as ExtOrderItem;
    return relations.some(
      (i) =>
        i.type === InvoiceRelationType.ExtOrderItem &&
        i.relatedItemID === orderItem.id
    );
  }
};

export default Vue.extend({
  components: {
    IconButton,
  },

  data: () => ({
    store,
  }),

  props: {
    item: Object as () => StoreIn | Stock | ExtOrder | ExtOrderItem,
  },

  computed: {
    isAttachedToInvoice() {
      return isAttachedToInvoice(this.store, this.item);
    },
  },
});
