var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('grid',_vm._g(_vm._b({staticClass:"filtered-grid",attrs:{"scrollable":"none","selected-field":"selected","filterable":"","filter":_vm.store.filter,"sortable":{ mode: 'multiple' },"sort":_vm.store.sort,"columns":_vm.compColumns,"data-items":_vm.compDataItems},on:{"rowclick":_vm.rowClick,"filterchange":_vm.filterChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"commandHeaderTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"adjust-header",class:props.headerClassName},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-check-box",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.selectAll()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.selected ? "fa-check-square-o" : "fa-square-o")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Összes kijelölése ")])])],1)]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className,staticStyle:{"display":"flex"}},[(_vm.selectable)?_c('CheckBox',{staticClass:"mr-1",attrs:{"selected":props.dataItem.selected}}):_vm._e()],1)]}},{key:"geniTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.isStockSelected)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"mr-1"},'div',attrs,false),on),[_c('a',_vm._g(_vm._b({on:{"click":function($event){return _vm.editInvoice(props.dataItem)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(props.dataItem.entity.id)+" ")]),_c('v-icon',_vm._g(_vm._b({staticStyle:{"vertical-align":"baseline"},attrs:{"color":"black","x-small":""}},'v-icon',attrs,false),on),[_vm._v(" fa-archive ")])],1)]}}],null,true)},[_c('span',[_vm._v("Készletkapcsolatos")])]):_c('a',{on:{"click":function($event){return _vm.editInvoice(props.dataItem)}}},[_vm._v(" "+_vm._s(props.dataItem.entity.id)+" ")])],1)]}},{key:"relatedInvoiceFilterTemplate",fn:function(){return [_c('DropDownList',{attrs:{"data-items":[
          { value: true, name: 'Hozzám tartozik' },
          { value: false, name: 'Máshoz tartozik' } ],"data-item-key":"value","text-field":"name","default-item":{ value: null, name: 'Összes' }},model:{value:(_vm.store.relatedInvoiceFilter),callback:function ($$v) {_vm.$set(_vm.store, "relatedInvoiceFilter", $$v)},expression:"store.relatedInvoiceFilter"}})]},proxy:true},{key:"relatedInvoiceTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.relatedInvoice)?_c('div',{staticClass:"badge teal lighten-4 black--text justify-center"},[_vm._v(" Hozzám tartozik ")]):_c('div',{staticClass:"badge red lighten-4 black--text justify-center"},[_vm._v(" Máshoz tartozik ")])])]}}])},'grid',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }