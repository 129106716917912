














































































































































































































































































































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";

import Vue from "vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import { FilteringModel } from "@/services/filtering";
import CashInView from "@/modules/cashStatement/CashInView/models/CashInView";
import CheckBox from "@/components/CheckBox.vue";
import cashStatementStore, {
  CashStatementStore,
} from "@/modules/cashStatement/services/store";
import eventBus from "@/services/eventBus";
import cashDocByReceiptsStore, {
  CashDocByReceiptsStore,
} from "../../receipt/services/CashDocByreceiptsStore";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import pStore, {
  PaymentModeStore,
} from "@/modules/paymentMode/services/PaymentModeStore";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import MoneyInput from "@/components/MoneyInput.vue";

interface Data {
  filter: FilteringModel;
  sum: number;
  items: CashInView[];
  selectedItems: CashInView[];
  cashDocByReceiptsStore: CashDocByReceiptsStore;
  cashStatementStore: CashStatementStore;
  pStore: PaymentModeStore;
  addAll: boolean;
}

interface Props {
  extraFilter: FilteringModel;
  partnerId: number;
  currency: string;
  cashDocByReceipts: boolean;
  isCreditCardPay: boolean;
  title: string;
  inStatement: boolean;
  invPrefix: string;
  invNo: number;
  isFullPage: boolean;
  noPadding: boolean;
  noActionsColor: boolean;
}

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
  okButtonName: string;
  sumAmount: number;
}

interface Methods {
  selected(item: CashInView): void;
  showDetails(item: CashInView): void;
  addToSummary(dataItem: CashInView): void;
  setItems(items: CashInView[]): void;
  addItems(): void;
  amountChanged(): void;
  selectAllItems(): void;
  refresh(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ApiSearchPage,
    CheckBox,
    MoneyDisplay,
    ValidationError,
    MoneyInput,
  },

  props: {
    extraFilter: { type: Object as () => FilteringModel },
    partnerId: { type: Number, default: 1 },
    currency: { type: String, default: "" },
    cashDocByReceipts: { type: Boolean, default: false },
    title: {
      type: String,
      default: "Számlaválogató",
    },
    isCreditCardPay: { type: Boolean, default: false },
    inStatement: {type: Boolean, default: false},
    invPrefix: { type: String, default: "" },
    invNo: { type: Number, default: 1 },
    isFullPage: {type: Boolean, default: false},
    noPadding: {type: Boolean, default: false},
    noActionsColor: {type: Boolean, default: false},
  },

  data: () => ({
    filter: new FilteringModel(),
    sum: 0,
    items: [],
    selectedItems: [],
    cashDocByReceiptsStore,
    cashStatementStore,
    pStore,
    addAll: false,
  }),

  async beforeMount() {
    this.cashDocByReceiptsStore.isCreditCardPay=this.isCreditCardPay;
    if (this.pStore.paymentModes.length < 2) {
      await this.pStore.loadPaymentModesWithoutBusy();
    }

    if (this.$props.extraFilter)
      this.filter = this.filter.fromFilter(this.$props.extraFilter.toObject());
  },

  methods: {
    refresh(){
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },
    
    selectAllItems(){
      this.selectedItems = [];
      this.addAll = !this.addAll;

      if (this.addAll) {
        this.items.forEach(x => {
          if (x.lockAction.length == 0) {
            x.added = false;
            this.addToSummary(x);
          }
        });
      } else {
        this.items.forEach(x => {
          x.added = false;
        });
      }

      this.amountChanged();
    },

    async addItems() {
      try {
        this.$emit("add-selected-items", this.selectedItems);
      } catch (ex) {
        console.log(ex);
        this.sum = 0;
        eventBus.$emit("cash-in-view:get-items");
        setTimeout(() => {
          this.items = this.items.map((x) => {
            return { ...x, added: false };
          });
          eventBus.$emit("cash-in-view:set-items", this.items);
          this.selectedItems = [];
        }, 100);
      }
    },

    selected(item: CashInView) {
      this.$emit("selected", item);
    },

    showDetails(item: CashInView) {
      item.selected = !item.selected;
    },

    addToSummary(dataItem: CashInView) {
      eventBus.$emit("cash-in-view:get-items");
      if (!dataItem.added) {
        this.sum += dataItem.paymAmount;
        dataItem.added = true;
      } else {
        this.sum -= dataItem.paymAmount;
        dataItem.added = false;
        this.selectedItems = this.selectedItems.filter(
          (x) => x.invNo !== dataItem.invNo
        );
      }
      this.$emit("update-sum", this.sum);
    },

    amountChanged(){
      this.sum = 0;
      this.selectedItems.forEach(x => this.sum += x.paymAmount);
      this.$emit("update-sum", this.sum);
    },

    setItems(items) {
      this.items = items;
      const newSelected = items.filter((x) => x.added);
      newSelected.forEach((element) => {
        if (!this.selectedItems.includes(element)) {
          this.selectedItems.push(element);
        }
      });
      
      this.amountChanged();
    },
  },

  computed: {
    sumAmount() {
      return this.sum;
    },

    okButtonName() {
      return this.cashDocByReceiptsStore.isCreditCardPay
        ? "Fizetés"
        : "Létrehozás";
    },

    columns: function() {
      const ret = this.$props.cashDocByReceipts
        ? [
            {
              cell: "detailsButton",
              filterable: false,
              header: 'allSelect'
            },
            //{ field: "prefix", title: "Prefix", headerClass: "prefix-column" },
            { field: "maskedInvNo", title: "Számlaszám", cell: "invNo" },
            {
              field: "pmode",
              title: "Fizetési mód",
              multiselect: true,
              multiselectField: "pmodes",
              multiselectOptions: this.pStore.paymentModes.map((x) => ({
                text: x.name,
                value: x.id,
              })),
              multiselectSelect: /*this.$props.isCreditCardPay
                ? this.pStore.paymentModes
                    .filter((x) => x.name === "Bankkártya")
                    .map((x) => x.id)[0]
                : */this.pStore.paymentModes
                    .filter((x) => x.name === "Készpénz" || x.name === "Bankkártya")
                    .map((x) => x.id),
            },
            { field: "partner", title: "Partner" },
            { field: "pmode", title: "Fizetési mód" },
            {
              cell: "total",
              field: "total",
              title: "Összeg",
              type: ColumnType.Money,
            },
            {
              cell: "toPayment",
              field: "toPayment",
              title: "Szla. nyitott rész",
              type: ColumnType.Money,
              filterable: false, 
              sortable: false
            },
            { field: "remark", title: "Megjegyzés", fieldClass: "note-column" },
          ]
        : [
            {
              cell: "detailsButton",
              filterable: false,
              header: 'allSelect'
            },
            //{ field: "prefix", title: "Prefix", headerClass: "prefix-column" },
            { field: "invNo", title: "Számlaszám", cell: "invNo" },
            {
              field: "pmode",
              title: "Fizetési mód",
              multiselect: true,
              multiselectField: "pmodes",
              multiselectOptions: this.pStore.paymentModes.map((x) => ({
                text: x.name,
                value: x.id,
              })),
            },
            { field: "partner", title: "Partner" },
            { field: "pmode", title: "Fizetési mód" },
            {
              cell: "total",
              field: "total",
              title: "Összeg",
              type: ColumnType.Money,
            },
            {
              cell: "toPayment",
              field: "toPayment",
              title: "Szla. nyitott rész",
              type: ColumnType.Money,
              filterable: false,
              sortable: false
            },
            { field: "remark", title: "Megjegyzés", fieldClass:"note-column" },
          ];

          if (this.$props.isCreditCardPay){
            ret.splice(7, 0, {
              cell: "paymAmountRight",
              field: "",
              title: "Fizetendő",
              type: ColumnType.Money,
              filterable: false, 
              sortable: false
            });
          }

          return ret;
    },

    url() {
      if(this.$props.cashDocByReceipts && this.$props.isCreditCardPay && !this.$props.currency){
        if (this.$props.invPrefix && this.$props.invNo > 1){
          return `cash-flow/in/dto/search/cashdocbyreceipts/credit-card/${this.$props.invPrefix}/${this.$props.invNo}`
        }

        return `cash-flow/in/dto/search/cashdocbyreceipts/credit-card`
      }

      return this.$props.cashDocByReceipts
        ? this.$props.isCreditCardPay
          ? `cash-flow/in/dto/search/cashdocbyreceipts/${
              this.$props.currency
            }/${true}`
          : `cash-flow/in/dto/search/cashdocbyreceipts/${
              this.$props.currency
            }/${false}`
        : `cash-flow/in/dto/search/${this.$props.partnerId}/${this.$props.currency}`;
    },
  },
});
