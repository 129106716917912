export default class TypedCashPoint{
    originalCashDoc= 1;
    selectedCashPoint= 1;
    amount= 0;
    docNo= "";
    recipient= 1;

    constructor(originalCashDoc: number, selectedCashPoint: number, amount: number, docNo: string, recipient: number){
        this.originalCashDoc = originalCashDoc;
        this.selectedCashPoint=selectedCashPoint;
        this.amount = amount;
        this.docNo = docNo;
        this.recipient = recipient;
    }
}