import Country from "@/modules/cashPoint/models/Country";
import PartBank, {
  partBankDefaultProps,
} from "@/modules/cashPoint/models/PartBank";
import CashPointApi from "@/modules/cashPoint/services/CashPointApi";
import User, { userDefaultProps } from "@/modules/invoiceGroup/models/User";
import PartnerApi from "@/modules/partner/services/PartnerApi";
import eventBus from "@/services/eventBus";
import { handleApiResponse } from "@/services/http";
import dayjs from "dayjs";
import busyIndication from "../../../utils/busyIndication";
import BankRemitAndItems, {
  bankRemitAndItemsDefaultProps,
  BankRemitResponse,
} from "../models/BankRemitAndItems";
import BankRemitApproval, {
  bankRemitApprovalDefaultProps,
} from "../models/BankRemitApproval";
import BankRemitItem, {
  bankRemitItemDefaultProps,
} from "../models/BankRemitItem";
import BankRemitStatus from "../models/BankRemitStatus";
import BankRemitApi from "./BankRemitApi";

export class BankRemitStore {
  bankRemits: BankRemitAndItems[] = [];
  bankRemit: BankRemitAndItems = { ...bankRemitAndItemsDefaultProps };
  bankRemitItem: BankRemitItem = { ...bankRemitItemDefaultProps };
  partBankItem: PartBank = { ...partBankDefaultProps };
  bankRemitApproval: BankRemitApproval = { ...bankRemitApprovalDefaultProps };
  bankRemitsCount = 0;
  step = 1;

  bankRemitImportFile: File | null = null;

  selectedBankRemitStatus: BankRemitStatus[] = [];
  selectedBankRemitStatusId: number[] = [];
  bankRemitStatuses: BankRemitStatus[] = [];
  partBanks: PartBank[] = [];
  currencies: Country[] = [];
  allApproverUser: User[] = [];
  bankDefrTypes: string[] = [];

  @busyIndication()
  async loadBankRemits(
    pageNumber: number,
    pageSize: number,
    searchText: string
  ) {
    const result: BankRemitResponse = await BankRemitApi.getAll(
      pageNumber,
      pageSize,
      searchText
    );
    this.bankRemits = result.bankRemits.map((x) => ({
      ...x,
      expanded: false,
      historyExpanded: false,
      exportExpanded: false,
    }));
    this.bankRemitsCount = result.count;
  }

  async loadBankRemit(remitId: number) {
    if(remitId>1){
    this.bankRemit = await BankRemitApi.get(remitId);
    await this.loadApproval(this.bankRemit.id);

    this.bankRemit.items.map((i) => ({
      ...i,
      deleting: false,
      isSelected: false,
    }));
  }
  else 
  {
  this.bankRemit = bankRemitAndItemsDefaultProps;
  this.bankRemit.items.map((i)=>({...i,deleting: false, isSelected: false, selected: false}))
  this.bankRemitApproval = bankRemitApprovalDefaultProps;
  }
  }

  async loadAllBankRemitAcceptorUser(): Promise<User[]> {
    return await BankRemitApi.getAllApproverUser().then((x) => {
      let arr: User[] = [];
      if (x) {
        arr = x.map((x)=>({id: x.id, name: x.name}))
      }
      this.allApproverUser = arr;
      return arr;
    });
  }

  async save() {
    if (this.bankRemit.id === 1) {
      const ret = Number(await this.createBankRemit());
      await this.loadBankRemit(ret);
      return ret;
    } else {
      await this.updateBankRemit();
    }
  }

  async createBankRemit() {
    try {
      const ret = await BankRemitApi.create(this.bankRemit)
      .then(async (x)=>{
        const res = await BankRemitApi.createBankRemitApproval(this.bankRemitApproval);
        if(res){
          eventBus.$emit("success","Sikeres mentés!");
        }
        else eventBus.$emit("error","Hiba történt mentés közben!");
        return x;
      });
      return ret;
    } catch (error) {
      console.log(error);
    }
  }

  async updateBankRemit() {
    try {
      const res = await BankRemitApi.update(this.bankRemit)
      .then(async (x)=>{
        if(this.bankRemitApproval.id>1){
        await BankRemitApi.updateApproval(this.bankRemitApproval);}
        else BankRemitApi.createBankRemitApproval(this.bankRemitApproval)
        return x;
      });
      if (res) {
        eventBus.$emit("success", "Sikeres mentés!");
      } else {
        eventBus.$emit("error", "Nem sikerült a mentés!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async loadBankRemitStatuses() {
    this.bankRemitStatuses = await BankRemitApi.getBankRemitStatuses();
  }

  async loadPartBanks() {
    this.partBanks = await BankRemitApi.getPartBanks();
  }

  async loadCurrencies() {
    this.currencies = await CashPointApi.getCurrencies();
  }

  async loadBankDefrTypes() {
    this.bankDefrTypes = await BankRemitApi.getBankDefrTypes();
  }

  //item
  async saveItem() {
    if (this.bankRemitItem.itemId === 1) {
      const ret = Number(await this.createBankRemitItem());
      return ret;
    } else {
      await this.updateBankRemitItem();
    }
  }

  async createBankRemitItem() {
    try {
      const ret = await BankRemitApi.createItem(this.bankRemitItem);
      return ret;
    } catch (error) {
      console.log(error);
    }
  }

  async updateBankRemitItem() {
    try {
      await BankRemitApi.updateItem(this.bankRemitItem);
    } catch (error) {
      console.log(error);
    }
  }

  async deleteItem(itemId: number) {
    try {
      await BankRemitApi.deleteItem(itemId);
    } catch (error) {
      console.log(error);
    }
  }

  //approval
  async loadApproval(remitId: number) {
    this.bankRemitApproval = await BankRemitApi.getApproval(remitId).then((x)=>{
      if(x){
        this.bankRemitApproval.acceptorName = this.allApproverUser.find((z)=>{z.id==x.acceptorId})?.name ?? "";
        return x;
      } else return bankRemitApprovalDefaultProps;
    });
  }

  async approve() {
    try {
      const result = await BankRemitApi.approve(this.bankRemitApproval.id);
      if(result){
        eventBus.$emit("success","Sikeres jóváhagyás!");
      }
      else eventBus.$emit("error","Hiba a jóváhagyás közben!");
    } catch (error) {
      console.error(error);
    }
  }
}

const store = new BankRemitStore();
export default store;
