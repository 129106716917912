var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(_vm.hasData)?_c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"filler"}),_c('th',[_vm._v("Termék neve")]),_c('th',{staticClass:"right_text"},[_vm._v("Egységár")]),_c('th',{staticClass:"right_text"},[_vm._v("Mennyiség")]),_c('th',{staticClass:"right_text"},[_vm._v("Rendelési érték")]),_c('th',{staticClass:"right_text"},[_vm._v("Nettó előleg érték")]),_c('th',{staticClass:"right_text",staticStyle:{"width":"8rem"}},[_vm._v("Előleg %")]),_c('th',{staticClass:"action-cell"}),_c('th',{staticClass:"filler"})])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('fragment',{key:item.id},[_c('tr',[_c('td',{staticClass:"filler"}),_c('td',[_vm._v(_vm._s(item.productName))]),_c('td',{staticClass:"right_text"},[_vm._v(" "+_vm._s(_vm._f("moneyFormat")(item.unitPrice))+" "+_vm._s(item.currency)+" ")]),_c('td',{staticClass:"right_text"},[_vm._v(" "+_vm._s(_vm._f("moneyFormat")(item.quantity))+" "+_vm._s(item.unit)+" ")]),_c('td',{staticClass:"right_text"},[_vm._v(" "+_vm._s(_vm._f("moneyFormat")((item.unitPrice * item.quantity)))+" "+_vm._s(item.currency)+" ")]),_c('td',{staticClass:"right_text my-2"},[(
                  _vm.edit &&
                    _vm.findPreDownPaymentElement(item) &&
                    (_vm.findPreDownPaymentElement(item).type == 2 || _vm.findPreDownPaymentElement(item).id > 1)
                )?_c('fragment',[(_vm.findPreDownPaymentElement(item).type != 2)?_c('label',[_vm._v(" "+_vm._s(_vm._f("moneyFormat")(item.finalAmount))+" "+_vm._s(item.currency)+" ")]):_c('label',[_vm._v("-")])]):_c('money-input',{staticClass:"remove-bottom",attrs:{"precision":_vm.configuration.operel ? 2 : undefined,"disabled":item.hasDownPayment,"currency":item.currency},on:{"input":function($event){return _vm.amountChanged(item)}},model:{value:(item.finalAmount),callback:function ($$v) {_vm.$set(item, "finalAmount", _vm._n($$v))},expression:"item.finalAmount"}})],1),_c('td',{staticClass:"right_text my-2"},[(
                  _vm.edit &&
                    _vm.findPreDownPaymentElement(item) &&
                    (_vm.findPreDownPaymentElement(item).type == 2 || _vm.findPreDownPaymentElement(item).id > 1)
                )?_c('label',[_vm._v(" "+_vm._s(_vm.findPreDownPaymentElement(item).type == 2 ? "-" : item.percent + " %")+" ")]):_c('money-input',{staticClass:"remove-bottom",attrs:{"disabled":item.hasDownPayment,"currency":"%"},on:{"input":function($event){return _vm.percentChanged(item)}},model:{value:(item.percent),callback:function ($$v) {_vm.$set(item, "percent", _vm._n($$v))},expression:"item.percent"}})],1),_c('td',{staticClass:"action-cell"},[(!_vm.edit)?_c('icon-button',{attrs:{"disabled":item.hasDownPayment || _vm.isAdded(item),"tooltip":"Megrendelés tétel kiválasztása","icon":item.hasDownPayment || _vm.isAdded(item) ? 'fa-check' : 'fa-plus'},on:{"click":function($event){return _vm.select(item)}}}):_c('icon-button',{attrs:{"disabled":item.hasDownPayment ||
                    (_vm.edit && (_vm.findPreDownPaymentElement(item).type != 3 || _vm.findPreDownPaymentElement(item).id > 0)),"tooltip":"Megrendelés tétel törlése","icon":"fa-times"},on:{"click":function($event){return _vm.remove(item)}}})],1),_c('td',{staticClass:"filler"})])])}),1)])],1):_c('div',[_vm._v("Nincs megrendelés tétel")])])}
var staticRenderFns = []

export { render, staticRenderFns }