



































































import Fragment from "@/components/Fragment.vue";
import { GridColumnProps } from "@progress/kendo-vue-grid";
import Vue from "vue";
import TaskTabGrid from "./TaskTabGrid.vue";

interface Props {
  relatedCheck: boolean;
  columns: GridColumnProps[];
  title: string;
  firstTitle: string;
  secondTitle: string;
  firstList: object[];
  secondList: object[];
  downPaymentRequest: boolean;
  stockRecording: boolean;
  accepting: boolean;
}

export default Vue.extend<{}, {}, {}, Props>({
  components: { Fragment, TaskTabGrid },

  props: {
    relatedCheck: { type: Boolean, default: false },
    columns: { type: Array as () => GridColumnProps[] },
    title: String,
    firstTitle: String,
    secondTitle: String,
    firstList: { type: Array as () => object[] },
    secondList: { type: Array as () => object[] },
    downPaymentRequest: { type: Boolean, default: false },
    stockRecording: { type: Boolean, default: false },
    accepting: { type: Boolean, default: false },
  },

  data: () => ({
    firstVisible: true,
    secondVisible: true,
  }),
});
