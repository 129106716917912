

























































































































































































































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import PaymentInfoDisplay from "../models/PaymentInfoDisplay";
import GeneralIngoingInvoiceEditModel from "@/modules/generalIngoingInvoiceEditModel/models/GeneralIngoingInvoiceEditModel";
import FlowPaymentInfoApprovalsView from "./FlowPaymentInfoApprovalsView.vue";
import setAttribute from "@/utils/dom";
import InvoiceFlowApi from "../services/InvoiceFlowApi";
import Fragment from "@/components/Fragment.vue";
import { InvoiceItem, InvoiceRelationType, Stock } from "@/modules/invoiceItems/models";

interface Data {
  store: InvoiceFlowStore;
  loadedVal: boolean;
  invoiceFlowStep: PaymentInfoDisplay[];
}

interface Props {
  invoice: any;
}

export default Vue.extend<Data, {}, {}, Props>({
  components: {
    FlowPaymentInfoApprovalsView,
    Fragment,
  },

  props: {
    invoice: Object,
  },

  data() {
    return {
      store,
      loadedVal: false,
      invoiceFlowStep: [],
    };
  },

  async beforeMount() {
    await InvoiceFlowApi.getPaymentInfos(this.invoice.id).then((result) => {
      this.invoiceFlowStep = result;
      this.loadedVal = true;
    });
  },

  mounted() {
    setAttribute(".k-detail-cell", "colspan", "15");
  },

  computed: {
    loaded() {
      return this.loadedVal;
    },
  },

  methods: {
    stockDeliveryNumber(item: InvoiceItem) {
      const relation = item.relations.find((r) => r.type === InvoiceRelationType.Stock);
      if (!relation) {
        return "-";
      }

      const stock = relation.relatedItem as Stock;
      return stock.deliveryNumber;
    },

    editInvoice(partner: number, id: number, flowStatus: number) {
      this.$router.push(`/general-incoming-invoice/flow/edit/${id}/${flowStatus}`);
    },

    setColor(flowStatus: number) {
      if (flowStatus == 2) return "#01579b";
      else if (flowStatus == 3) return "black";
      else if (flowStatus == 4) return "#607d8b";
      else if (flowStatus == 5) return "#009688";
      else if (flowStatus == 6) return "#d32f2f";
      else return "primary";
    },
  },
});
