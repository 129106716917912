





































import Vue from "vue";
import IconButton from "../../../components/IconButton.vue";
import CarItemsTable from "./CarItemsTable.vue";
import * as api from "../services/CarNetApi";
import CarItem from "../models/CarItem";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import CarPreCalcInfo from "../components/CarPreCalcInfo.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  searchText: string;
  newCars: CarItem[];
  flowStore: InvoiceFlowStore;
  carSearchVisible: boolean;
  configuration: AppConfiguration;
}

interface Methods {
  showCarSearch(): void;
  search(e: KeyboardEvent): void;
  clear(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    CarItemsTable,
    IconButton,
    CarPreCalcInfo,
  },

  data() {
    return {
      flowStore,
      searchText: "",
      newCars: [],
      carSearchVisible: false,
      configuration,
    };
  },

  methods: {
    showCarSearch() {
      this.carSearchVisible = !this.carSearchVisible;
    },

    search(e: KeyboardEvent) {
      if (e.key !== "Enter") {
        return;
      }
      return api.searchNewCars(this.searchText, this.flowStore.invoice.partner).then((data) => (this.newCars = data));
    },

    clear() {
      this.searchText = "";
      this.newCars = [];
    },
  },
});
