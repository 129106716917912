var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[_c('v-row',{staticClass:"stock-modifier-genis"},[_c('InvoiceStockModifierGenisView')],1),_c('v-row',{staticClass:"geni-header"},[_c('v-col',{attrs:{"md":"6"}},[_c('h4',{staticStyle:{"color":"#394263","padding-top":"10px"}},[_vm._v("Bejövő számlák")])]),_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"primary","data-test":"new-button"},on:{"click":_vm.createInvoice}},[_vm._v(" Új bejövő számla ")])],1)],1),_c('v-divider',{staticClass:"mt-2 mb-4 mx-3 geni-divider"}),_c('v-sheet',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-heading"},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"sm":"12"}},[_c('v-btn',{staticClass:"src-btn",attrs:{"outlined":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-search")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterText),expression:"filterText"}],staticClass:"k-textbox py-2 px-2",staticStyle:{"z-index":"1"},attrs:{"type":"text","placeholder":"Számla keresése"},domProps:{"value":(_vm.filterText)},on:{"keyup":_vm.filterTextChanged,"input":function($event){if($event.target.composing){ return; }_vm.filterText=$event.target.value}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-button",staticStyle:{"height":"32px !important"},attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.filterShow = !_vm.filterShow}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-filter")])],1)]}}])},[_c('span',[_vm._v("Szűrés")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'filter-button': true, active: _vm.isListView },staticStyle:{"height":"32px !important"},attrs:{"outlined":"","color":"primary"},on:{"click":_vm.setListView}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-list")])],1)]}}])},[_c('span',[_vm._v("Lista nézet")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'filter-button': true, active: _vm.isTableView },staticStyle:{"height":"32px !important"},attrs:{"outlined":"","color":"primary"},on:{"click":_vm.setTableView}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-table")])],1)]}}])},[_c('span',[_vm._v("Táblázatos nézet")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-button",staticStyle:{"height":"32px !important"},attrs:{"outlined":"","color":"primary"},on:{"click":_vm.reload}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-refresh")])],1)]}}])},[_c('span',[_vm._v("Lista frissítése")])])],1)],1)],1),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterShow),expression:"filterShow"}],staticClass:"panel-heading"},[_c('incoming-invoice-page-filter',{attrs:{"excelExportVisible":_vm.configuration.operel ? true : false}})],1)]),_c('div',{staticClass:"panel-content footer"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"padding-left":"11px"}},[_c('v-combobox',{staticStyle:{"width":"135px"},attrs:{"items":[10, 25, 50, 100],"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.currentPageSize),callback:function ($$v) {_vm.currentPageSize=$$v},expression:"currentPageSize"}})],1),_c('div',[_c('span',{staticStyle:{"padding-top":"8px","padding-left":"10px","padding-right":"10px","display":"block"}},[_c('strong',[_vm._v(_vm._s(_vm.footerText))])])])]),_c('div',[(_vm.currentPageNumber > 1)?_c('v-btn',{staticClass:"pageNumberButton",attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":_vm.decrementPageNumber}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-caret-left")])],1):_vm._e(),(_vm.firstPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.firstPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.firstPageNumberButtonValue)}}},[_vm._v(_vm._s(_vm.firstPageNumberButtonValue))]):_vm._e(),(_vm.secondPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.secondPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.secondPageNumberButtonValue)}}},[_vm._v(_vm._s(_vm.secondPageNumberButtonValue))]):_vm._e(),(_vm.thirdPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.thirdPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.thirdPageNumberButtonValue)}}},[_vm._v(_vm._s(_vm.thirdPageNumberButtonValue))]):_vm._e(),(_vm.fourthPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.fourthPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.fourthPageNumberButtonValue)}}},[_vm._v(_vm._s(_vm.fourthPageNumberButtonValue))]):_vm._e(),(_vm.fifthPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.fifthPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.fifthPageNumberButtonValue)}}},[_vm._v(_vm._s(_vm.fifthPageNumberButtonValue))]):_vm._e(),(_vm.currentPageNumber < _vm.maxPageNumber)?_c('v-btn',{staticClass:"pageNumberButton",attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":_vm.incrementPageNumber}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-caret-right")])],1):_vm._e(),_c('span',{staticStyle:{"margin":"5px"}})],1)]),_c('v-row')],1),_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTableView),expression:"isTableView"}],staticClass:"panel panel-default"},[_c('grid',{staticClass:"filtered-grid",attrs:{"data-items":_vm.items,"columns":_vm.columns,"scrollable":"none","detail":'detailsTemplate',"expand-field":'expanded',"sortable":"","sort":_vm.sort,"filterable":"","filter":_vm.filter},on:{"sortchange":_vm.sortChange,"filterchange":_vm.filterChange},scopedSlots:_vm._u([{key:"geniTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{class:props.className},[_c('a',{on:{"click":function($event){return _vm.updateInvoice(props.dataItem)}}},[_vm._v(_vm._s(props.dataItem.incomingInvoice.id))])])]}},{key:"incomingInvoiceInvoiceNoTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(" "+_vm._s(props.dataItem.incomingInvoice.invoiceNo)+" ")])]}},{key:"partnerTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{class:props.className},[_c('a',{on:{"click":function($event){return _vm.partnerEdit(props.dataItem)}}},[_vm._v(_vm._s(props.dataItem.partnerName))])])]}},{key:"incomingInvoicePayTermTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(" "+_vm._s(_vm.convertToDate(props.dataItem.incomingInvoice.payTerm))+" ")])]}},{key:"detailsTemplate",fn:function(ref){
          var props = ref.props;
return [(!props.dataItem.isDuty)?_c('GeneralIngoingInvoiceDetails',{attrs:{"item":props.dataItem}}):_vm._e(),(props.dataItem.isDuty)?_c('CustomsInvoiceDetails',{attrs:{"item":props.dataItem}}):_vm._e()]}},{key:"displayStatusTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(" "+_vm._s(props.dataItem.displayStatus)+" ")])]}},{key:"actualStatusTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{staticClass:"badge round-sm justify-center px-2",class:{
              undefined: props.dataItem.workflowStatus == '-',
              iktatas: props.dataItem.workflowStatus == 'Iktatás',
              penzugy: props.dataItem.workflowStatus == 'Pénzügyi rögzítés',
              keszlet: props.dataItem.workflowStatus == 'Készletkapcsolatok megadása',
              ellenorzes: props.dataItem.workflowStatus == 'Ellenőrzés, jóváhagyás',
              konyveles: props.dataItem.workflowStatus == 'Könyvelés, rögzítés',
            }},[_vm._v(" "+_vm._s(props.dataItem.workflowStatus)+" ")])])]}},{key:"accStatusTemplate",fn:function(ref){
            var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{class:{ toAccounting: props.dataItem.accStatus == 'Fők. rendsz. feladva' }},[_vm._v(" "+_vm._s(props.dataItem.accStatus)+" ")])])]}},{key:"incomingInvoiceCurrNettoTemplate",fn:function(ref){
            var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(" "+_vm._s(props.dataItem.incomingInvoice.currNetto)+" "+_vm._s(props.dataItem.incomingInvoice.invCurrency)+" ")])]}},{key:"incomingInvoiceCurrVatTemplate",fn:function(ref){
            var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(" "+_vm._s(props.dataItem.incomingInvoice.currVat)+" "+_vm._s(props.dataItem.incomingInvoice.invCurrency)+" ")])]}},{key:"incomingInvoiceInvVatTemplate",fn:function(ref){
            var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(_vm._s(props.dataItem.incomingInvoice.invVat)+" HUF")])]}},{key:"incomingInvoiceCurrTotalTemplate",fn:function(ref){
            var props = ref.props;
return [_c('td',{class:props.className},[_vm._v(" "+_vm._s(props.dataItem.incomingInvoice.currTotal)+" "+_vm._s(props.dataItem.incomingInvoice.invCurrency)+" ")])]}},{key:"commandTemplate",fn:function(ref){
            var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateInvoice(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])])],1)]}},{key:"displayStatusFilterTemplate",fn:function(){return [_c('k-dropdown-list',{attrs:{"name":"displayStatus","data-items":_vm.displayStatuses,"data-item-key":"id","text-field":"name"},on:{"change":_vm.dropdownFilterChange},model:{value:(_vm.defaultDisplayStatus),callback:function ($$v) {_vm.defaultDisplayStatus=$$v},expression:"defaultDisplayStatus"}})]},proxy:true},{key:"workflowStatusFilterTemplate",fn:function(){return [_c('k-dropdown-list',{attrs:{"name":"workflowStatus","data-items":_vm.workflowStatuses,"data-item-key":"id","text-field":"name"},on:{"change":_vm.dropdownFilterChange},model:{value:(_vm.defaultWorkflowStatus),callback:function ($$v) {_vm.defaultWorkflowStatus=$$v},expression:"defaultWorkflowStatus"}})]},proxy:true},{key:"accStatusFilterTemplate",fn:function(){return [_c('k-dropdown-list',{attrs:{"name":"accStatus","data-items":_vm.accountStatuses,"data-item-key":"id","text-field":"name"},on:{"change":_vm.dropdownFilterChange},model:{value:(_vm.defaultAccStatus),callback:function ($$v) {_vm.defaultAccStatus=$$v},expression:"defaultAccStatus"}})]},proxy:true}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isListView),expression:"isListView"}],staticClass:"list-group"},_vm._l((_vm.invoices),function(item,index){return _c('div',{key:index},[(!item.isDuty)?_c('GeneralIngoingInvoiceDetails',{attrs:{"item":item}}):_vm._e(),(item.isDuty)?_c('CustomsInvoiceDetails',{attrs:{"item":item}}):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }