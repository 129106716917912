













































































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import { InvoiceAcceptRequest, invoiceAcceptRequestDefaultProps } from "../models/InvoiceRequest";
import User from "../../partner/models/User";
import { setAcceptorsViewAttribute } from "../../../utils/dom";
import auth from "@/services/auth";

interface Data {
  invoiceId: number;
  store: InvoiceFlowStore;
}

interface Props {
  fulFillRequest: boolean;
}

const colspanFixer = {
  render(h: any) {
    return h();
  },
  mounted() {
    setAcceptorsViewAttribute(".k-detail-cell", "colspan", "4");
  },
};

export default Vue.extend<Data, {}, {}, Props>({
  components: {
    colspanFixer: colspanFixer,
  },

  props: {
    fulFillRequest: { type: Boolean, default: false },
  },

  data: () => ({
    invoiceId: 0,
    store,
  }),

  async beforeMount() {
    this.invoiceId = Number(this.$route.params.invoiceId);
    if (this.store.users.length == 0) await this.store.loadAccContrUsers();
    await this.store.loadAcceptorRequests(this.invoiceId);
  },

  computed: {
    acceptors() {
      return this.fulFillRequest ? this.store.flowUsers.fullfilmentApproval : this.store.flowUsers.invoiceApproval;
    },

    items() {
      return this.store.acceptorRequests.filter((x) => x.isFullFillReq == this.fulFillRequest);
    },

    columns: () => [
      { title: "Név", field: "name", className: "center_text" },
      { title: "Kérelem típusa", className: "center_text", cell: "requestTypeTemplate" },
      { title: "Megjegyzés", field: "remark", className: "center_text", cell: "remarkTemplate" },
      { width: 60, cell: "commandTemplate" },
    ],
  },

  methods: {
    async updateDelegateRequests() {
      const mine = this.store.acceptorRequests.find(
        (x) => x.name == auth.userName && x.isFullFillReq == this.fulFillRequest
      );
      if (mine && this.store.acceptorRequests.filter((x) => x.isFullFillReq == this.fulFillRequest).length > 1) {
        await this.store.deleteAcceptorRequest(mine.id);
        this.store.acceptorRequests = this.store.acceptorRequests.filter((x) => x.id != mine.id);
      }

      await this.store.updateAcceptorRequests().then(async () => {
        await this.store.loadAcceptState();
        this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
      });

      this.$emit("closeView");
    },

    selectedAcceptorChanged(user: User) {
      this.store.invoice.acceptor = user?.id ?? 1;

      if (user?.id > 1) {
        const requestAlreadyExists = this.store.acceptorRequests.some(
          (x) => x.acceptorId == user.id && x.isFullFillReq == this.fulFillRequest
        );
        if (requestAlreadyExists) return;

        const newRequest: InvoiceAcceptRequest = {
          ...invoiceAcceptRequestDefaultProps,
          invId: this.store.invoice.id,
          acceptorId: user.id,
          name: user.name,
          isFullFillReq: this.fulFillRequest,
        };
        this.store.acceptorRequests = [newRequest, ...this.store.acceptorRequests];
      }
    },

    setAcceptorDeleting(item: InvoiceAcceptRequest) {
      if (item.id == 0) {
        const index = this.store.acceptorRequests.indexOf(item);
        if (index > -1) {
          this.store.acceptorRequests.splice(index, 1);
        }
        return;
      }

      item.deleting = !item.deleting;
    },

    async confirmDelete(item: InvoiceAcceptRequest) {
      item.deleting = !item.deleting;
      await this.store.deleteAcceptorRequest(item.id);
      this.store.acceptorRequests = this.store.acceptorRequests.filter((x) => x.id != item.id);
      await this.store.loadAcceptState();
    },

    cancelDelete(item: InvoiceAcceptRequest) {
      item.deleting = !item.deleting;
    },

    closeAcceptorsView() {
      this.$emit("closeView");
    },
  },
});
