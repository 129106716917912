import ApiResponse from "@/models/ApiResponse";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import Glim from "../models/generalLedgerInvoiceMirror";
import {
  DetailedType,
  DimActivity,
  DimCodeType,
  DimSort,
  MainType,
} from "../models/TypesForEdit";
import { Period } from "../models/PeriodModell";
import { StatusChanged } from "../models/StatusChange";
import { Year } from "../models/YearModell";
import { Dimension } from "../models/Dimension";
import AccountNumberTreeNode from "../models/invoiceMirrorTreeNode";
import { SelectChange } from "../models/TypesForEdit";
import { TransItemDimension } from "../models/generalLedgerTransactionItem";

export default class GeneralLedgerInvoiceMirrorApi {

  static async GetGeneralLedgerInvoiceMirrors(): Promise<Glim[]> {
    return http
      .get<ApiResponse<Glim[]>>(`generalledger/invoicemirror/0`)
      .then(handleApiResponse);
  }

  static async GetDimensions(itemId: number): Promise<TransItemDimension[]> {
    return await http
      .get<ApiResponse<TransItemDimension[]>>(`general-ledger-transaction/dimension/${itemId}`)
      .then(handleApiResponse)
  }

  static async UpdateGlim(invMirror: Glim): Promise<boolean> {
    return http
      .put<ApiResponse<boolean>>(
        `generalledger/invoicemirror/updateinvmirror`,
        invMirror
      )
      .then(checkResponse);
  }

  static async CreateInvMirror(invMirror: Glim): Promise<string> {
    return http
      .post<ApiResponse<string>>(
        `generalledger/invoicemirror/createinvmirror`,
        invMirror
      )
      .then(handleApiResponse);
  }

  static async GenerateDimensionsByYearId(yearId: number): Promise<boolean> {
    return http
      .post<ApiResponse<boolean>>(
        `generalledger/dimensioncode/generatedimensionsbyyearid/${yearId}`
      )
      .then(checkResponse);
  }

  static async DeleteDimensions(dimensionIds: number[]): Promise<boolean> {
    return http
      .post<ApiResponse<boolean>>(
        `generalledger/dimensioncode/deletedimensions`,
        dimensionIds
      )
      .then(checkResponse);
  }

  static async GetGeneralLedgerYears(): Promise<Year[]> {
    return http
      .get<ApiResponse<Year[]>>(`generalledger/yearperiod/years`)
      .then(handleApiResponse);
  }
  static async GetPeriods(yearId: number): Promise<Period[]> {
    return http
      .get<ApiResponse<Period[]>>(`generalledger/yearperiod/periods/${yearId}`)
      .then(handleApiResponse);
  }

  static async GetStatuses(): Promise<string[]> {
    return http
      .get<ApiResponse<string[]>>(`generalledger/yearperiod/statuses`)
      .then(handleApiResponse);
  }

  static async GetDimActivities(): Promise<DimActivity[]> {
    return http
      .get<ApiResponse<DimActivity[]>>(
        `generalledger/dimensioncode/dimactivities`
      )
      .then(handleApiResponse);
  }

  static async GetDimCodeTypes(): Promise<DimCodeType[]> {
    return http
      .get<ApiResponse<DimCodeType[]>>(
        `generalledger/dimensioncode/dimcodetypes`
      )
      .then(handleApiResponse);
  }

  static async SaveYear(year: Year) {
    return await http
      .post(`generalledger/yearperiod/saveyear`, year)
      .then(handleApiResponse);
  }

  static SaveStatus(statusChange: StatusChanged[]) {
    return http
      .put(`generalledger/yearperiod/savestatus`, statusChange)
      .then(handleApiResponse);
  }

  static GetInvMirrorTree(yearId: number): Promise<AccountNumberTreeNode[]> {
    return http
      .get<ApiResponse<AccountNumberTreeNode[]>>(
        `generalledger/invoicemirror/getinvoicemirrortree/${yearId}`
      )
      .then(handleApiResponse);
  }
  static async CreateInvMirrors(thisYear: number): Promise<string> {
    return await http
      .post<ApiResponse<string>>(
        `generalledger/invoicemirror/generateinvmirror/${thisYear}`
      )
      .then(handleApiResponse);
  }
  static async SetInvMirrorsSelected(
    allItemsSelected: boolean,
    yearId: number
  ): Promise<AccountNumberTreeNode[]> {
    const d: SelectChange = {
      allItemsSelected: allItemsSelected,
      yearId: yearId,
    };
    return http
      .post<ApiResponse<AccountNumberTreeNode[]>>(
        `generalledger/invoicemirror/setinvmirrortreeselect`,
        d
      )
      .then(handleApiResponse);
  }
  static CreateGeneratedGlims(generatedGlim: Glim[]) {
    return http
      .post(`generalledger/creategeneratedglims`, generatedGlim)
      .then(handleApiResponse);
  }

  static async GetMainTypes(): Promise<MainType[]> {
    return await http
      .get<ApiResponse<MainType[]>>(`generalledger/yearperiod/maintypes`)
      .then(handleApiResponse);
  }

  static async GetDetailedTypes(): Promise<DetailedType[]> {
    return await http
      .get<ApiResponse<DetailedType[]>>(
        `generalledger/yearperiod/detailedtypes`
      )
      .then(handleApiResponse);
  }
  static async GetDimSortTypes(): Promise<DimSort[]> {
    return await http
      .get<ApiResponse<DimSort[]>>(`generalledger/dimensioncode/dimsorttypes`)
      .then(handleApiResponse);
  }

  static async DeleteGlims(glims: number[]): Promise<boolean> {
    return await http
      .post(`generalledger/invoicemirror/deleteinvmirrors`, glims)
      .then(checkResponse);
  }
  static async SavePeriodsStatusChanges(periods: Period[]): Promise<boolean> {
    return await http
      .put(`generalledger/yearperiod/updateperiods`, periods)
      .then(checkResponse);
  }

  static async UpdateDim(dim: Dimension): Promise<boolean> {
    return await http
      .put<ApiResponse<boolean>>(`generalledger/dimensioncode/updatedim`, dim)
      .then(handleApiResponse);
  }
  static async UpdateDimStatuses(changedDimStatuses: Dimension[]) {
    return await http
      .put<ApiResponse<boolean>>(
        `generalledger/dimensioncode/updatedimstatus`,
        changedDimStatuses
      )
      .then(handleApiResponse);
  }
  static async UpdateGlimStatuses(glims: Glim[]) {
    return await http
      .put<ApiResponse<boolean>>(
        `generalledger/invoicemirror/updateinvmirrorstatuses`,
        glims
      )
      .then(checkResponse);
  }

  static getOpenTreeItems(yearId: number): Promise<number[]> {
    return http
      .get<ApiResponse<number[]>>(
        `generalledger/invoicemirror/getopentreeitems/${yearId}`
      )
      .then(handleApiResponse);
  }
}
