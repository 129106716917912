import InvoiceItemOtherDimension, { invoiceItemOtherDimensionDefaultProps } from "./InvoiceItemOtherDImension";

export default interface InvoiceItemDimension {
    id: number;
    invItem: number;
    rate: number;
    invGroup: number;
    invGroupNet: number;
    invGroupName: string;
    invGroupType: number;
    invGroupCode: string;
    sectionType: number;
    otherDimensions: InvoiceItemOtherDimension[];

    deleting: boolean;
    inEdit: boolean;
    otherDimensionDetail: boolean;
    otherDimensionDetailOrDelete: boolean;
}

export const invoiceItemDimensionDefaultProps: InvoiceItemDimension = {
    id: 0,
    invItem: 0,
    rate: 0,
    invGroup: 1,
    invGroupNet: 1,
    invGroupName: "-",
    invGroupType: 1,
    invGroupCode: "",
    sectionType: 1,
    otherDimensions: [],

    deleting: false,
    inEdit: false,
    otherDimensionDetail: false,
    otherDimensionDetailOrDelete: false,
}