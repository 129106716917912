

























import Vue from 'vue';
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";

interface Data {
    store: InvoiceFlowStore;
}

interface Methods {
    partnerRelationChanged(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
    data() {
        return {
            store,
        }
    },

    async beforeMount() {
        await this.store.getPartnerRelationsWithSelectedPartnerRelation(this.store.invoice.partner, this.store.invoice.id);
    },

    computed: {
        selectedPartnerRelationComputed() {
            return this.store.selectedPartnerRelation;
        }
    },
})
