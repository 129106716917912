















































import Vue from 'vue';
import ApiSearchPage from '@/components/apiSearch/components/ApiSearchPage.vue';
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';
import IconButton from "@/components/IconButton.vue";
import DownloadableInvoiceItemsView from "./DownloadableInvoiceItemsView.vue";
import http, { handleApiResponse } from '@/services/http';
import ApiResponse from '@/models/ApiResponse';
import eventBus from '@/services/eventBus';

export default {
  components: {
    ApiSearchPage,
    IconButton,
    DownloadableInvoiceItemsView
  },

  methods: {
    showDetails(i: any) {
      i.selected = !i.selected;
    },

    async download(i: number) {
      console.log(i);
      const ret = await http.post<ApiResponse<boolean>>(`downloadable-invoices/download/${i}`).then(handleApiResponse);
      if (ret) {
        eventBus.$emit("success", "Sikeres betöltés!")
      }
      else {
        eventBus.$emit("error", "Sikertelen betöltés!")
      }
    }
  },

  computed: {
    columns: () => [
      {
        cell: "selectButton",
        filterable: false,
      },
      {
        field: "id",
        title: "Azonosító",
      },
      {
        field: "prefix",
        title: "Prefix",
        width: "100"
      },
      {
        field: "invNo",
        title: "Számlaszám",
      },
      {
        field: "sellerName",
        title: "Partner név",
      },
      {
        field: "buyerName",
        title: "Vevő név",
      },
      // {
      //   field: "status",
      //   title: "Státusz",
      // },
      {
        field: "invTypeName",
        title: "Számla típus",
      },
      {
        field: "payMode",
        title: "Fizetési mód",
      },
      {
        field: "netto",
        title: "Nettó",
        type: ColumnType.Money,
        filterable: false
      },
      {
        field: "vat",
        title: "ÁFA",
        type: ColumnType.Money,
        filterable: false
      },
      {
        field: "brutto",
        title: "Bruttó",
        type: ColumnType.Money,
        filterable: false
      },
      {
        field: "currency",
        title: "Pénznem",
      },
      {
        field: "k2dId",
        title: "K2D ID",
      },
      // {
      //   filterable: false,
      //   cell: "downloadTemplate"
      // },
    ]
  },
}
