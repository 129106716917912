import {
  InvoiceItemType,
  OtherItem,
  Unit,
} from "@/modules/invoiceItems/models";
import { roundMoney } from "./../../../services/prices";
import StatusColorMap from "@/models/StatusColorMap";
import busyIndication from "@/utils/busyIndication";
import {
  DownPayment,
  DownPaymentExtOrder,
  DownPaymentList,
  PreDownPayment,
  PreDownPaymentList,
} from "../models";
import * as api from "./api";
import Tax, {
  taxDefaultProps,
} from "@/modules/generalIngoingInvoice/models/Tax";
import GeneralIngoingInvoiceApi from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import * as InvoiceItemApi from "@/modules/invoiceItems/services/api";
import eventBus from "@/services/eventBus";
import AcceptRequest from "../../invoiceFlow/models/InvoiceAcceptState";
import { InvoiceAcceptRequest } from "@/modules/invoiceFlow/models/InvoiceRequest";
import User from "@/modules/user/models/User";
import Attachment, {
  attachmentDefaultProps,
} from "@/modules/generalIngoingInvoice/models/Attachment";
import configuration from "@/models/Configuration";
import { DownPaymentItem } from "../models";
import { formatDateISO } from "@/utils/formatDates";
import TaxAndVatItem from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";

export class DownPaymentValidator {
  constructor(
    protected dp: DownPayment,
    protected pdp: PreDownPayment[] | null,
    protected taxes: Tax[]
  ) {}

  error() {
    if (!this.dp.invoiceNo || !this.dp.invoiceNo.trim()) {
      console.log(this.dp.invoiceNo);
      return "Bizonylat szám megadása kötelező!";
    }

    if (!this.dp.invoiceDate) {
      return "Hibás Kibocsájtás kelte!";
    }

    if (!this.dp.payTerm) {
      return "Hibás Fizetési határidő!";
    }

    /* console.log(this.pdp);
    if (
      (this.pdp?.length ?? 0) > 0 &&
      this.pdp?.reduce((acc, object) => {
        return acc + object.amount;
      }, 0) != this.dp.netto
    ) {
      return "A kiválasztott előjegyzések és az előlegbekérő Nettó értéke nem egyezik meg.";
    } */

    if (
      this.dp.invoiceItems.length > 0 &&
      this.dp.invoiceItems.reduce((acc, object) => {
        return acc + object.netto;
      }, 0) != this.dp.netto
    ) {
      return "A tételek és az előlegbekérő Nettó értéke nem egyezik meg.";
    }
    const tax = this.taxes.find((x) => x.id === this.dp.taxID);

    if (
      tax &&
      this.dp.invoiceItems.length > 0 &&
      this.dp.invoiceItems.reduce((acc, object) => {
        return acc + object.brutto;
      }, 0) !=
        roundMoney(
          this.dp.total,
          this.dp.currency,
          configuration.operel
            ? 2
            : configuration.getCurrDigits(this.dp.currency)?.geniHeadDigits ?? 0
        )
    ) {
      return "A tételek és az előlegbekérő bruttó értéke nem egyezik meg.";
    }

    return "";
  }

  warning() {
    return "";
  }
}

export class DownPaymentStore {
  downPayment = new DownPayment();
  preDownPayment: PreDownPayment | null = null;
  downPaymentList = new DownPaymentList();
  preDownPaymentList = new PreDownPaymentList();
  extOrders: DownPaymentExtOrder[] = [];
  extOrder: DownPaymentExtOrder | null = null;
  taxAndVatItems: TaxAndVatItem[] = [];

  extOrderList: DownPaymentExtOrder[] = [];

  selectedPreDownPayments: PreDownPayment[] = [];

  acceptorRequests: InvoiceAcceptRequest[] = [];
  downPaymentAcceptRequests: AcceptRequest[] = [];

  attachments: Attachment[] = [];
  attachment: Attachment = { ...attachmentDefaultProps };

  acceptorUsers: User[] = [];

  preStatusColors = new StatusColorMap({
    1: "grey",
    2: "grey",
    6: "red darken-1",
    8: "green",
  });
  permitStatusColors = new StatusColorMap({
    default: "grey",
    3: "#b89525",
    4: "#04850d",
    5: "green",
  });
  pdpTypeColors = new StatusColorMap({
    default: "grey",
    1: "indigo",
    2: "blue darken-1",
    3: "teal darken-1",
  });
  taxes: Tax[] = [];
  units: Unit[] = [];
  searchResult: OtherItem[] = [];

  isFullPage = false;

  async loadTaxAndVatItems(){
    await api.getAllTaxAndVatItem().then((data) => {
      this.taxAndVatItems = data;
    })
  }

  async loadTaxes() {
    await GeneralIngoingInvoiceApi.getAllTaxes().then((data) => {
      this.taxes = data.find((x) => x.id == 1)
        ? data
        : [{ ...taxDefaultProps, id: 1, name: "--" }, ...data];
    });
  }

  async loadUnits() {
    await InvoiceItemApi.getUnits().then((data) => {
      this.units = data.find((x) => x.id == 1)
        ? data
        : [{ id: 1, name: "-" }, ...data];
    });
  }

  toDecimal(number: number, decimals = -1) {
    return roundMoney(
      number,
      this.downPayment.currency ?? "HUF",
      decimals > -1 ? decimals : undefined
    );
  }

  private setCalculatedVATs() {
    this.downPayment.vat = this.toDecimal(
      this.downPayment.total - this.downPayment.netto
    );
  }

  bruttoChanged() {
    this.downPayment.total = this.toDecimal(this.downPayment.total);

    this.setCalculatedVATs();
  }

  nettoChanged() {
    const degree =
      1 +
      (this.taxes.find((x) => x.id == this.downPayment.taxID)?.degree ?? 0) /
        100;
    this.downPayment.total = 
      this.downPayment.netto * degree

     
    this.downPayment.vat = 
      this.downPayment.total - this.downPayment.netto

    console.log(this.downPayment.total);
  }

  init() {
    this.downPayment = new DownPayment();
    this.preDownPayment = null;
    this.preDownPaymentList.items = [];
    this.extOrders = [];
    this.extOrder = null;
  }

  // Előleg értesítés előjegyzés
  @busyIndication()
  async loadPreDownPayments(
    partnerID: number,
    searchText = "",
    currency: string
  ) {
    await api
      .getAllPreByPartner(partnerID, searchText, currency)
      .then((list) => {
        this.preDownPaymentList.initialize(list);
      });
  }

  //régi 1 előjegyzés
  async loadPreDownPayment(partnerID: number, invoiceID: number) {
    await api.getPreByInvoice(partnerID, invoiceID).then((pdp) => {
      this.preDownPayment = pdp;
    });
  }

  //új több előjegyzés
  async loadExtOrdersData(partnerID: number, invoiceID: number) {
    await api.loadExtOrdersData(partnerID, invoiceID).then((pdp) => {
      this.selectedPreDownPayments = [...pdp];
    });
  }

  //már hozzáadott előjegyzés törlése
  async deletePreDownpayment(pdpId: number) {
    const res = await api.deletePreDownpayment(pdpId);
    if (res) {
      eventBus.$emit("success", "A kijelölt előlegbekérő előjegyzés törölve!");
    } else
      eventBus.$emit(
        "error",
        "A kijelölt előlegbekérő előjegyzést nem sikerült törölni!"
      );
  }

  // Megrendelés
  @busyIndication()
  async loadExtOrders(partnerID: number, searchText: string) {
    await api.getExtOrders(partnerID, searchText).then((list) => {
      this.extOrders = list;
    });
  }

  async loadExtOrderData() {
    if (this.extOrder) return;

    const id = this.preDownPayment?.id;
    if (!id || id < 1) return;

    await api.getExtOrderData(id).then((extOrder) => {
      this.extOrder = extOrder;
    });
  }

  // Előlegbekérő GENI
  async loadDownPayment(invoiceID: number) {
    await api.get(invoiceID).then((data: DownPayment) => {
      const items = data.invoiceItems;
      this.downPayment = {
        ...data,
        invoiceItems: items.map((x) => {
          const nw = new DownPaymentItem(x);
          nw.isFreeWordItem =
            nw.productName === InvoiceItemType.Rounding ||
            nw.serviceName === InvoiceItemType.Rounding;
          return nw;
        }),
      };
    });
    this.attachments = this.downPayment.attachments;
    this.attachment = this.attachments[this.attachments.length - 1];
    this.attachments.forEach((element) => {
      if ((element.name !== null ? element.name.length : 0) > 0) {
        element.link = GeneralIngoingInvoiceApi.generateFileDownLoadLink(
          element.id
        );
      }
    });
  }

  toBase64(attachment: Attachment) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(attachment.file);
      reader.onload = () => {
        attachment.fileBase64 = reader.result?.toString() ?? "";
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  async loadGENIPicture(downPaymentId: number) {
    await GeneralIngoingInvoiceApi.getFile(downPaymentId).then((result) => {
      if (result == null) {
        this.attachment = attachmentDefaultProps;
        return;
      }
      this.attachment = result;
      this.attachment.link = GeneralIngoingInvoiceApi.generateFileDownLoadLink(
        result.id
      );
    });
  }

  async uploadFiles(id: number) {
    if (this.attachments && this.attachments.length > 0) {
      const reads = this.attachments
        .filter((x) => !!x.file)
        .map((x) => this.toBase64(x));
      await Promise.all(reads);

      await GeneralIngoingInvoiceApi.uploadFiles(this.attachments, id);
    }
  }

  async uploadGENIPicture(id: number) {
    if (this.attachment) {
      this.attachments = [this.attachment];
      await this.uploadFiles(id);
    }
  }

  @busyIndication()
  async loadDownPayments() {
    await api.getAll().then((list) => {
      this.downPaymentList.initialize(list);
    });
  }

  async createOrUpdate() {
    let id = this.downPayment.id;
    console.log(this.selectedPreDownPayments);
    if (id > 1) {
      id = await api.update(this.downPayment, this.selectedPreDownPayments);
    } else {
      id = await api.create(this.downPayment, this.selectedPreDownPayments);
    }
    this.downPayment.id = id;
    return id;
  }

  async generatePayment() {
    await api.generatePayment(this.downPayment.id);
  }

  async setIncorrect(deletePreDownPayment: boolean) {
    await api.setIncorrect(this.downPayment.id, deletePreDownPayment);
  }

  async setApproved() {
    await api.setApproved(this.downPayment.id);
  }

  // Előlegbekérő Szolgáltatás / Egyéb tétel keresés
  async searchOtherItems(searchText: string) {
    this.searchResult = await api.getServices(searchText);
    this.searchResult = this.searchResult.slice(0, 10);
  }

  //Jóváhagyás
  async loadDownPaymentAcceptRequests(invoiceID: number) {
    await api.getDownPaymentAcceptRequests(invoiceID).then((list) => {
      this.downPaymentAcceptRequests = list;
    });
  }

  //Jóváhagyók
  async loadAcceptorRequests(invoiceId: number) {
    this.acceptorRequests = (await api.getAcceptRequests(invoiceId)).map(
      (x) => ({
        ...x,
        deleting: false,
      })
    );
    console.log(this.acceptorRequests);

    // this.currentUsersApproval = await InvoiceFlowApi.getCurrentUsersApproval(invoiceId);
    // if (this.currentUsersApproval) {
    //   this.dimensionSelection.load(this.currentUsersApproval);
    // }
  }

  async updateAcceptRequests(invId: number) {
    await api.updateAcceptRequests(this.acceptorRequests, invId);

    this.loadDownPaymentAcceptRequests(this.downPayment.id);
  }

  async loadAcceptorsList(invoiceId: number) {
    this.acceptorUsers = await api.getDownPaymentAcceptorsList(invoiceId);
  }

  async addDownPaymentAcceptor(invId: number) {
    await api.addDownPaymentAcceptor(invId);
  }

  async approveDownPayment(invId: number) {
    await api.approveDownPayment(invId);
  }
}

const store = new DownPaymentStore();
export default store;
