

















































































import Vue from "vue";
import eventBus from "@/services/eventBus";
import paginate from "jw-paginate";
import createPagerText from "@/utils/pagerText";
import { PaginatedPager } from "@/models/Pager";

interface Data {
  pager: PaginatedPager;
}

interface Methods {
  setPage(page: number, size?: number): void;
  setPageSize(size: number): void;
}

interface Props {
  baseSizes: number[];
  totalItems: number;
}

//
//!  Usage -> pl. ApiSearch.vue
//
//  @change - returns the pager object with the currentPage, pageSize
//
//? <template>
//    <ApiPaginator @change="pagerChanged"
//                  :totalItems="totalItems"
//                  :baseSizes="baseSizes" />
//

export default Vue.extend<Data, Methods, {}, Props>({
  props: {
    baseSizes: {
      type: Array as () => number[],
      default: () => [10, 25, 50, 100],
    },

    totalItems: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    pager: new PaginatedPager(),
  }),

  created() {
    this.pager = new PaginatedPager({ totalItems: this.totalItems, currentPage: 1 });
    eventBus.$on("api-pager:set-page", this.setPage);
  },

  beforeMount() {
    this.setPageSize(this.baseSizes[0]);
  },

  beforeDestroy() {
    eventBus.$off("api-pager:set-page", this.setPage);
  },

  methods: {
    setPage(page, size) {
      this.pager = size
        ? paginate(this.totalItems, page, size, 5)
        : paginate(this.totalItems, page, this.pager.pageSize, 5);

      this.$emit("change", this.pager);
    },

    setPageSize(size) {
      this.pager.pageSize = size;
      this.setPage(1, size);
    },
  },

  watch: {
    totalItems() {
      if (this.pager.currentPage > 1 && this.pager.pageSize !== this.baseSizes[0]) {
        this.setPageSize(this.baseSizes[0]);
      } else {
        this.setPage(1);
      }
    },
  },

  computed: {
    footerText() {
      return createPagerText(this.totalItems, this.pager.currentPage, this.pager.pageSize);
    },
  },
});
