import http, { handleApiResponse, checkResponse } from '@/services/http'
import InvoiceGroup from "@/modules/invoiceGroup/models/InvoiceGroup"
import InvoiceGroupDisplay from "@/modules/invoiceGroup/models/InvoiceGroupDisplay"
import InvoiceGroupType from "@/modules/invoiceGroup/models/InvoiceGroupType"
import DivisionItem from "@/modules/invoiceGroup/models/DivisionItem"
import User from "@/modules/invoiceGroup/models/User"
import ApiResponse from '@/models/ApiResponse'

export default class InvoiceGroupApi {
    static getAll(): Promise<InvoiceGroupDisplay[]> {
        return http
            .get<ApiResponse<InvoiceGroupDisplay[]>>(`invoice-group`)
            .then(handleApiResponse)
    }

    static getUsers(): Promise<User[]> {
        return http
            .get<ApiResponse<User[]>>(`users`)
            .then(handleApiResponse)
    }

    static getUsersByCashStatementRelation(cashStatementID: number): Promise<User[]> {
        return http
            .get<ApiResponse<User[]>>(`users/cash-statement/${cashStatementID}`)
            .then(handleApiResponse)
    }

    static getInvoiceGroupTypes(): Promise<InvoiceGroupType[]> {
        return http
            .get<ApiResponse<InvoiceGroupType[]>>(`invoice-group-type`)
            .then(handleApiResponse)
    }

    static getDivisionItems(): Promise<DivisionItem[]> {
        return http
            .get<ApiResponse<DivisionItem[]>>(`division-item`)
            .then(handleApiResponse)
    }

    static get(invoiceGroupId: number): Promise<InvoiceGroup> {
        return http
            .get<ApiResponse<InvoiceGroup>>(`invoice-group/${invoiceGroupId}`)
            .then(handleApiResponse)
    }

    static create(invoiceGroup: InvoiceGroup): Promise<InvoiceGroup> {
        return http
            .post<ApiResponse<InvoiceGroup>>(`invoice-group`, invoiceGroup)
            .then(handleApiResponse)
    }

    static update(invoiceGroup: InvoiceGroup): Promise<boolean> {
        return http
            .put(`invoice-group/${invoiceGroup.id}`, invoiceGroup)
            .then(checkResponse)
    }

    static delete(invoiceGroupId: number): Promise<boolean> {
        return http
            .delete(`invoice-group/${invoiceGroupId}`)
            .then(checkResponse)
    }
}