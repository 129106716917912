





























































































































































































































































































































































































































































import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import BankRemitApi from "../services/BankRemitApi";
import {
  CompositeFilterDescriptor,
  filterBy,
  groupBy,
  orderBy,
  SortDescriptor,
} from "@progress/kendo-data-query";
import InvoiceBankRemitView from "../models/InvoiceBankRemitView";
import Paginator from "../../../components/Paginator.vue";
import Pager from "../../../models/Pager";
import GeneralIngoingInvoiceApi from "../../generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import PaymentModeDisplay from "../../paymentMode/models/PaymentModeDisplay";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import MoneyInput from "../../../components/MoneyInput.vue";
import DateInput from "../../../components/DateInput.vue";
import { formatDate } from "../../../utils/formatDates";
import PartBank from "../../cashPoint/models/PartBank";
import BankAccountSelectorView from "./BankAccountSelectorView.vue";
import AccountNumberAndClaimRelation from "@/modules/customsInvoice/models/AccountNumberAndClaimRelation";
import CustomsInvoiceDetailsView from "../components/CustomsInvoiceDetailsView.vue";
import ButtonCheckBox from "@/components/ButtonCheckBox.vue";
import eventBus from "@/services/eventBus";
import configuration, { AppConfiguration } from "@/models/Configuration";
import { bankRemitItemDefaultProps } from "../models/BankRemitItem";

class AggregateValuesByPartner {
  partnerName = "";
  totalAmount = 0;

  constructor(partnerName: string, totalAmount: number) {
    this.partnerName = partnerName;
    this.totalAmount = totalAmount;
  }
}

interface InvoiceTypes {
  name: string;
  value: string;
}

interface Data {
  store: BankRemitStore;
  bankAcc: string;
  partner: string;
  currency: string;
  invoices: InvoiceBankRemitView[];
  paymentModes: PaymentModeDisplay[];
  bankAccountNames: PartBank[];
  invoiceTypes: InvoiceTypes[];
  invoiceTypeFilters: string[];
  showAggregatesByPartners: boolean;
  aggregatesByPartners: AggregateValuesByPartner[];

  pager: Pager;
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];

  customFilter: CompositeFilterDescriptor | null;
  customSort: SortDescriptor[] | null;

  vatAccounts: AccountNumberAndClaimRelation[];
  dutyAccounts: AccountNumberAndClaimRelation[];

  selectedAddItems: number[];
  allItemsSelected: boolean;

  // filters
  invDateMin: string;
  invDateMax: string;
  ptermMin: string;
  ptermMax: string;
  payModeFilters: number[];
  outSiderFilter: boolean;
  inSiderFilter: boolean;
  geniFilter: boolean;
  genoFilter: boolean;
  invFilter: boolean;
  vamFilter: boolean;
  sameBank: boolean;
  bankId: number;

  arrivedFilter: boolean;
  controlledFilter: boolean;
  appovedFilter: boolean;
  remittanceFilter: boolean;

  configuration: AppConfiguration;
}

interface Methods {
  clear(): void;
  search(e: Event): void;
  pagerChanged(p: Pager): void;
  filterChange(e: { filter: CompositeFilterDescriptor }): void;
  sortChange(e: { sort: SortDescriptor[] }): void;
  addItem(item: InvoiceBankRemitView): void;
  showDetails(item: InvoiceBankRemitView): void;
  formatDate(date: string): string;
  loadBankAccounts(partner: number): void;
  bankAccountChanged(value: string): void;
  invFilterChanged(): void;
  filterRender(
    h: Function,
    defaultRendering: any,
    props: object | null,
    change: Event
  ): any;
  setSelectedAddItems(s: InvoiceBankRemitView): void;
  addAllItem(): void;
  /*   GroupBy(arr: InvoiceBankRemitView[], key: (i: InvoiceBankRemitView) => string): Record<string, InvoiceBankRemitView[]>;
   */
}

interface Computed {
  activeItems: object[];
  columns: any;
  activeFilter: CompositeFilterDescriptor;
  activeSort: SortDescriptor[];
  selectedItemsAggregateValue: number;
  selectedItems: InvoiceBankRemitView[];
}

export default Vue.extend<Data, Methods, Computed, {}>({
  watch: {
    showAggregatesByPartners() {
      const aggregatesByPartners: AggregateValuesByPartner[] = [];

      const checkedPartner: string[] = [];
      this.selectedItems.forEach((e) => {
        if (!checkedPartner.includes(e.partnerName)) {
          let aggregateNum = 0;
          this.selectedItems.forEach((c) => {
            if (c.partnerName === e.partnerName) {
              aggregateNum += c.currTotal;
            }
          });
          aggregatesByPartners.push(
            new AggregateValuesByPartner(e.partnerName, aggregateNum)
          );

          checkedPartner.push(e.partnerName);
        }
      });
      this.aggregatesByPartners = aggregatesByPartners;
    },

    allItemsSelected() {
      if (this.allItemsSelected) {
        this.invoices.forEach((x) => {
          x.isSelected = true;
          this.selectedAddItems.push(x.invoice);
        });
      } else {
        this.invoices.forEach((x) => {
          x.isSelected = false;
          this.selectedAddItems = [];
        });
      }
    },
  },

  components: {
    ButtonCheckBox,
    Paginator,
    DateInput,
    MoneyDisplay,
    MoneyInput,
    BankAccountSelectorView,
    CustomsInvoiceDetailsView,
  },

  data() {
    return {
      store,
      bankAcc: "",
      partner: "",
      currency: "HUF",
      invoices: [],
      paymentModes: [],
      bankAccountNames: [],
      selectedAddItems: [],
      showAggregatesByPartners: false,
      aggregatesByPartners: [],
      allItemsSelected: false,
      invoiceTypes: [
        { value: "invFilter", name: "Számlák" },
        { value: "vamFilter", name: "Vámhatározatok" },
        { value: "geniFilter", name: "Bejövő számlák" },
        { value: "genoFilter", name: "Kimenő negatív számlák" },
        { value: "sameBank", name: "Csak egyező számlák" },
      ],
      invoiceTypeFilters: [],

      vatAccounts: [],
      dutyAccounts: [],

      pager: new Pager(),
      filter: { logic: "and", filters: [] },
      sort: [],

      customFilter: null,
      customSort: null,

      //filters
      invDateMin: "2000. 01. 01.",
      invDateMax: "2999. 12. 31.",
      ptermMin: "2000. 01. 01.",
      ptermMax: "2999. 12. 31.",
      payModeFilters: [3],
      outSiderFilter: true,
      inSiderFilter: true,
      geniFilter: true,
      genoFilter: true,
      invFilter: true,
      vamFilter: true,
      sameBank: false,
      bankId: 1,

      arrivedFilter: true,
      controlledFilter: true,
      appovedFilter: true,
      remittanceFilter: true,

      configuration
    };
  },

  async beforeMount() {
    this.invoiceTypeFilters = this.invoiceTypes.map((x) => x.value);
    this.invoiceTypeFilters.pop();

    await GeneralIngoingInvoiceApi.getPaymentModes().then(
      (data) => (this.paymentModes = data)
    );

    this.currency = this.store.bankRemit.currency;
    this.bankId = this.store.bankRemit.bank;
  },

  methods: {
    async addAllItem() {
      this.selectedAddItems.forEach(async (x) => {
        const k =
          this.invoices.find((z) => z.invoice == x) ??
          new InvoiceBankRemitView();
        const res = await BankRemitApi.getPartBanksByPartner(k.partner);
        k.bankAccount = res[0].id;
        k.bankAccountName = res[0]?.accountNo ?? "-";
        this.bankAccountChanged(k.bankAccountName);
        this.addItem(k);
      });
    },
    /* 
    GroupBy(arr, key)
    {
          return arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
      }, {} as Record<string, InvoiceBankRemitView[]>);
    }, */

    setSelectedAddItems(s: InvoiceBankRemitView) {
      if (!this.selectedAddItems.includes(s.invoice)) {
        this.selectedAddItems.push(s.invoice);
      } else {
        this.selectedAddItems = this.selectedAddItems.filter(
          (x) => x !== s.invoice
        );
      }
    },

    filterRender(
      h: Function,
      defaultRendering,
      props: object | null,
      change: Event
    ) {
      if (
        defaultRendering.children[0].context._props.field === "currTotal" ||
        defaultRendering.children[0].context._props.field ===
        "currTotalPositive"
      ) {
        const k = defaultRendering;
        k.data.class = k.data.class + " float_right";
        return k;
      }
      return defaultRendering;
    },

    invFilterChanged() {
      if (!this.invFilter) {
        this.geniFilter = false;
        this.genoFilter = false;
      }
    },

    bankAccountChanged(value: string) {
      this.store.bankRemitItem.bankAccountName = value;
    },

    async loadBankAccounts(partner: number) {
      await BankRemitApi.getPartBanksByPartner(partner).then(
        (data) => (this.bankAccountNames = data)
      );
    },

    formatDate(date: string) {
      return formatDate(date);
    },

    showDetails(item: InvoiceBankRemitView) {
      item.selected = !item.selected;
      item.vatOrDutyDetails = false;
    },

    async addItem(item: InvoiceBankRemitView) {
      if (item.vatOrDuty === "V" || item.vatOrDuty === "D") {
        item.selected = true;
        item.vatOrDutyDetails = true;
        return;
      }

      /*if (this.store.bankRemitItem.bankAccountName === "") {
        eventBus.$emit(
          "error",
          "A kiválasztott hozzáadandó elemnek nincs bankszámlája kiválasztva!"
        );
        return;
      }*/

      this.store.bankRemitItem = {...bankRemitItemDefaultProps};

      this.store.bankRemitItem.bankRemit = this.store.bankRemit.id;
      this.store.bankRemitItem.prefix = item.code;
      this.store.bankRemitItem.invoice = item.invoice;
      this.store.bankRemitItem.amount = item.currTotalPositive;
      this.store.bankRemitItem.partner = item.partner;
      this.store.bankRemitItem.partnerName = item.partnerName;
      this.store.bankRemitItem.invCurrency = item.invCurrency;
      this.store.bankRemitItem.currAmount = item.currTotalPositive;
      this.store.bankRemitItem.invNo = item.invNo;
      //this.store.bankRemitItem.exchangeRate = ;
      this.store.bankRemitItem.isTransfered = false;
      this.store.bankRemitItem.pterm = item.pterm;
      this.store.bankRemitItem.bankDefrType = item.bankDefrType;
      this.store.bankRemitItem.itemId = 1;

      const id = Number(await this.store.saveItem());
      if (id > 1) {
        this.store.bankRemitItem.itemId = id;
        this.store.loadBankRemit(this.store.bankRemit.id);
      }
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      if (this.customFilter) {
        this.$emit("filterChange", e.filter);
      } else {
        this.filter = e.filter;
      }
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      if (this.customSort) {
        this.$emit("sortChange", e.sort);
      } else {
        this.sort = e.sort;
      }
    },

    pagerChanged(p: Pager) {
      this.pager = p;
    },

    clear() {
      this.invoices = [];
      this.bankAcc = "";
      this.partner = "";
    },

    async search(e: Event) {
      e.preventDefault();
      this.invFilter = this.invoiceTypeFilters.includes("invFilter");
      this.vamFilter = this.invoiceTypeFilters.includes("vamFilter");
      this.geniFilter = this.invoiceTypeFilters.includes("geniFilter");
      this.genoFilter = this.invoiceTypeFilters.includes("genoFilter");
      this.sameBank = this.invoiceTypeFilters.includes("sameBank");
      BankRemitApi.getInvoiceBankRemitViews(
        this.bankAcc,
        this.partner,
        this.currency,
        this.invDateMin ?? "2000. 01. 01.",
        this.invDateMax ?? "2999. 12. 31.",
        this.ptermMin ?? "2000. 01. 01.",
        this.ptermMax ?? "2999. 12. 31.",
        this.payModeFilters,
        this.outSiderFilter ?? true,
        this.inSiderFilter ?? true,
        this.geniFilter ?? true,
        this.genoFilter ?? true,
        this.invFilter ?? true,
        this.vamFilter ?? true,
        this.arrivedFilter ?? true,
        this.controlledFilter ?? true,
        this.appovedFilter ?? true,
        this.remittanceFilter ?? true,
        this.sameBank ?? true,
        this.bankId ?? 1
      ).then((invoices) => (this.invoices = invoices)).then(() => console.log(this.invoices));
      
      this.$nextTick(() =>
        this.invoices.forEach((element: InvoiceBankRemitView) => {
          element.selected = false;
          element.vatOrDutyDetails = false;
        })
      );
    },
  },

  computed: {
    selectedItems() {
      return this.invoices.filter((x) =>
        this.selectedAddItems.includes(x.invoice)
      );
    },

    selectedItemsAggregateValue() {
      let aggregate = 0;
      this.selectedItems.forEach((x) => {
        aggregate += x.currTotal;
      });
      return aggregate;
    },

    activeFilter() {
      return this.customFilter ? this.customFilter : this.filter;
    },

    activeSort() {
      return this.customSort ? this.customSort : this.sort;
    },

    activeItems() {
      let items: object[] = this.invoices;
      items = filterBy(items, this.activeFilter);
      items = orderBy(items, this.activeSort);
      return items;
    },

    columns: () => [
      { cell: "detailsButtonTemplate", width: 50, filterable: false },
      {
        field: "code",
        title: "Prefix",
        width: 150,
      },
      {
        field: "invoice",
        title: "Azonosító",
        width: 150,
      },
      {
        field: "partnerName",
        title: "Partner",
      },
      {
        title: "Számla",
        field: "currTotal",
        cell: "currTotalTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        title: "Fizetendő",
        field: "currTotalPositive",
        cell: "currTotalPositiveTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        field: "pterm",
        title: "Fizetési határidő",
        cell: "ptermTemplate",
        width: 150,
        filterable: true,
      },
      {
        title: "Számlaszám",
        filed: "bankAccountName",
        cell: "bankAccountNameTemplate",
      },
      configuration.sysParams.bankDefrayerEnabled ? {
        title: "Bank költségviselő típusa",
        filed: "bankDefrType",
        cell: "bankDefrType"
      } : { width: "0px" },
      { cell: "commandTemplate", width: "60px", filterable: false },
      {
        cell: "selectButtonTemplate",
        filterCell: "selectButtonHeaderTemplate",
        filterable: true,
        width: "60px",
      },
      { width: 0, filterable: false },
    ],
  },
});
