











































import Fragment from "@/components/Fragment.vue";
import Vue from "vue";

interface Props {
  title: string;
  firstTooltip: string;
  secondTooltip: string;
  firstList: object[];
  secondList: object[];
}

export default Vue.extend<{}, {}, {}, Props>({
  components: { Fragment },

  props: {
    title: String,
    firstTooltip: String,
    secondTooltip: String,
    firstList: { type: Array as () => object[] },
    secondList: { type: Array as () => object[] },
  },
});
