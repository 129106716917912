






















import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import InvoiceItemTemplate from '../models/InvoiceItemTemplate';
import FlowDocumentRelation from '@/modules/flowDocumentRelation/models/FlowDocumentRelation';
import GeneralIngoingInvoiceItemMinEditModel from '@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItemMinEditModel';
import InvoiceDimensionTemplate from '../models/InvoiceDimensionTemplate';
import InvItemAndDimensionsById from '../models/InvItemAndDimensionsById';
import gStore, { GeneralIngoingInvoiceStore } from "../../generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import iStore, { InvoiceItemDimensionStore } from "../../invoiceItemDimension/services/InvoiceItemDimensionStore";
import relStore, { FlowDocumentRelationStore } from "../../flowDocumentRelation/services/FlowDocumentRelationStore";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
    store: InvoiceFlowStore;
    relStore: FlowDocumentRelationStore;
    gStore: GeneralIngoingInvoiceStore;
    iStore: InvoiceItemDimensionStore;
    configuration: AppConfiguration;
}

interface Methods {
    saveTemplate(): void;
    getDimensions(itemId: number): InvoiceDimensionTemplate[];
}

export default Vue.extend<Data, Methods, {}, {}>({
    data() {
        return {
            store,
            relStore,
            gStore,
            iStore,
            configuration
        }
    },

    methods: {
        async saveTemplate() {
            //head
            this.store.invoiceFlowTemplate.partner = this.store.invoice.partner;
            this.store.invoiceFlowTemplate.template.payMode = this.store.invoice.paymode;
            this.store.invoiceFlowTemplate.template.accountNo = this.store.invoice.bankAccount;

            //acceptors
            this.store.acceptState.approvalRequests.forEach((element: any) => {
                this.store.invoiceFlowTemplate.template.acceptorIds.push({
                toUserID: element.toUserID,
                ffReq: element.isFFReq,
                });
            });

            //controllers
            this.store.acceptState.controllRequests.forEach((element: any) => {
                this.store.invoiceFlowTemplate.template.controllerIds.push({
                toUserID: element.toUserID,
                ffReq: element.isFFReq,
                });
            });

            //egyéb dokumentum -> szerződés
            await this.relStore.loadFlowDocumentRelationsByInvoiceIdWithoutBusy(Number(this.$route.params.invoiceId));
            this.relStore.otherDocumentRelations.forEach((element: FlowDocumentRelation) => {
                if (element.documentType == "SZERZŐDÉS") {
                    this.store.invoiceFlowTemplate.template.documentIds.push(element.id);
                }
            });

            //items
            await this.gStore.loadGeneralIngoingInvoiceItemMinEditModelsByInvId(Number(this.$route.params.invoiceId), true);
            await this.iStore.loadInvItemAndDimensionsById(Number(this.$route.params.invoiceId));

            this.gStore.invoiceItemMinEditModelsByInvId.forEach((i: GeneralIngoingInvoiceItemMinEditModel) => {
                const m: InvoiceItemTemplate = {
                    invNo: 1,
                    invItemName: i.item.name,
                    taxId: i.item.tax,
                    unitId: i.item.unit,
                    gparts: i.item.gparts,
                    parts: i.item.parts,
                    service: i.item.serviceId,
                    stock: i.item.stock,
                    car: i.item.car,
                    prcar: i.item.prcar,
                    postcalc: i.item.postCalc,
                    invatcount: i.item.inVatCount,
                    dimensions: this.getDimensions(i.item.id),
                };
                this.store.invoiceFlowTemplate.template.items.push(m);
            });
            await this.store.createInvoiceFlowTemplate();
            this.$emit("closeView");
        },

        //dims
        getDimensions(itemId: number) {
            const dims: InvoiceDimensionTemplate[] = [];
            if (configuration.invGroupsSaved.enabled) {
                this.iStore.invItemAndDimensionsById.forEach((item: InvItemAndDimensionsById) => {
                    if (item.id == itemId) {
                        const dim: InvoiceDimensionTemplate = {
                            invItemNo: 1,
                            invItemName: item.name,
                            invGroupName: item.invGroupName,
                            stationName: item.stationName,
                            stationGroupName: item.stationGroupName,
                            toolName: item.toolName,
                            userName: item.userName,
                            carName: item.carRegNum,
                            projectName: item.projectName,
                            partName: item.partName,
                            eleventhDimensionName: item.eleventhDimensionName,
                            twelfthDimensionName: item.twelfthDimensionName,
                        };
                        dims.push(dim);
                    }
                });
            }
            return dims;
        },
    },
})
