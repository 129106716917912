export default interface Message {
  id: number;
  fromId: number;
  toId: number;
  fromName: string;
  toName: string;
  dateString: string;
  content: string;
  isRead: boolean;
  groupId: string;
  topic: string;
  invoiceId: string;
}

export const messageDefaultProps: Message = {
  id: 0,
  fromId: 1,
  toId: 1,
  fromName: "",
  toName: "",
  dateString: "2000-01-01T00:00:00",
  content: "",
  isRead: false,
  groupId: "",
  topic: "",
  invoiceId: "",
};
