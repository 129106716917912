










































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import moneyFormat from "@/filters/money-format";
import Vue from "vue";

interface Computed{
    url: string;
    columns: ApiSearchColumn[];
}

interface Props{
    partnerId: number;
}

export default Vue.extend<{}, {}, Computed, Props>({
    components:{
        ApiSearchPage
    },

    props: {
        partnerId: Number
    },

    computed: {
        url(){
            return `emails/search/${this.partnerId}`;
        },

        columns: (): ApiSearchColumn[] => [
        { field: "email", title: "Email" },
        { field: "success", title: "Sikeres", cell: 'success' },
        { field: "error", title: "Hiba", cell: 'error' },
        { field: "insUser", title: "Kiküldő felhasználó" },
        { title: "Kiküldés dátuma", field: "insDate", filterable: false, type: ColumnType.Date },
        { cell: 'statementDetailsButtonTemplate', title: '', field: 'id', headerClass: "detailsColumn", filterable: false, sortable: false}
    ],
    }
})
