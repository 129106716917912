export class SysParam {
  key = "";
  value: string | number | boolean | null = null;

  constructor(paramName: string, param: string | number | boolean | null) {
    this.key = paramName;
    this.value = param;
  }
}

export enum SystemParameters {
  GeniSendEmail = "GeniSendEmail",
  GeniFulFillTolerance = "GeniFulFillTolerance",
  GeniMaxRound = "GeniMaxRound",
  GeniTaskReminderDays = "GeniTaskReminderDays",
  IsGeniHUFItemPrecise = "IsGeniHUFItemPrecise",
  IsGeniExchangeRateEditEnabled = "IsGeniExchangeRateEditEnabled",
}
