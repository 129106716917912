























import auth from "@/services/auth";
import eventBus from "@/services/eventBus";
import configuration, { AppConfiguration } from "../../../models/Configuration";
import Vue from "vue";
import Message from "../models/Message";
import store, { ChatStore } from "../services/ChatStore";
import ChatView from "./ChatView.vue";

interface Data {
  store: ChatStore;
  showChat: boolean;
  configuration: AppConfiguration;
}

interface Methods {
  loadNewMessageCount(): void;
}

interface Computed {
  loggedIn: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: { ChatView },

  data: () => ({
    store,
    configuration,
    showChat: false,
  }),

  beforeMount() {
    this.loadNewMessageCount();
  },

  created() {
    eventBus.$on("receive-message", (message: Message) => {
      if (message.toId != 1 && message.groupId == "") this.store.messageCount++;
    });
  },

  methods: {
    async loadNewMessageCount() {
      if (!this.loggedIn) {
        return;
      }
      await this.store.loadNewMessageCount();
    },
  },

  watch: {
    loggedIn(a) {
      this.loadNewMessageCount();
      if (!a) {
        this.showChat = false;
      }
    },
  },

  computed: {
    loggedIn() {
      return !configuration.operel && auth.isLoggedIn;
    },
  },
});
