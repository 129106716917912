import InvoiceBgView from "../models/InvoiceBgView";
import InvoiceBgViewApi from "./InvoiceBgViewApi";

export class InvoiceBgViewStore {
  invoicesBgView: InvoiceBgView[] = [];

  async loadInvoicesBgViewAny() {
    const response = await InvoiceBgViewApi.getInvoicesBgViewAny();
    return response.any;
  }

  async loadInvoicesBgView() {
    const response = await InvoiceBgViewApi.getInvoicesBgView();
    this.invoicesBgView = response.invoicesView;
  }
}

const invoiceBgViewStore = new InvoiceBgViewStore();
export default invoiceBgViewStore;
