import { render, staticRenderFns } from "./InvoiceFee.vue?vue&type=template&id=b0930bec&scoped=true&"
import script from "./InvoiceFee.vue?vue&type=script&lang=ts&"
export * from "./InvoiceFee.vue?vue&type=script&lang=ts&"
import style0 from "./InvoiceFee.vue?vue&type=style&index=0&id=b0930bec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0930bec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSelect,VSimpleTable})
