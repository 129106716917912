









import Vue from 'vue'
import { PartsAttribute } from '../models'

export default Vue.extend({
    props: {
        attributes: {
            type: Array as () => PartsAttribute[]
        }
    }
})
