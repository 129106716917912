import CarItem from "@/modules/canNet/models/CarItem"
import CarItemResponse from "./CarItemResponse"
import http, { handleApiResponse } from "@/services/http"
import ApiResponse from "@/models/ApiResponse"

export default class CarApi {
    static get(id: number): Promise<CarItem> {
        return http.get<ApiResponse<CarItem>>(`car/search/${id}`).then(handleApiResponse);
      }

    static getAll(pageNumber: number, pageSize: number, searchText?: string): Promise<CarItemResponse> {
        return http
          .get<ApiResponse<CarItemResponse>>(`car/search/all`,
          {
            params: {
              searchText: searchText,
              pageNumber: pageNumber,
              pageSize: pageSize,
              onlyCar: true
            },
          })
          .then(handleApiResponse);
      }
}