































import Vue from "vue";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  flowStore: InvoiceFlowStore;
  configuration: AppConfiguration;
}

export default Vue.extend<Data, {}, {}, {}>({
  props: {
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      configuration,
      flowStore,
      dropDownContent: [
        { id: 1, name: "-" },
        { id: 9, name: "Alkatrész beszerzés" },
        { id: 2, name: "Új gépjármű beszerzés" },
        { id: 3, name: "Használt gépjármű beszerzés" },
      ],
    };
  },

  methods: {
    setIsComponentEnabled() {
      this.flowStore.isComponent = true;
      this.flowStore.isCar = false;
      this.flowStore.isSubcontractor = false;
    },

    setIsCarEnabled() {
      this.flowStore.isCar = true;
      this.flowStore.isSubcontractor = false;
      this.flowStore.isComponent = false;
    },

    setIsSubcontractorEnabled() {
      this.flowStore.isSubcontractor = true;
      this.flowStore.isCar = false;
      this.flowStore.isComponent = false;
    },
  },
});
