





























































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, {
  AccountVoucherTypeStore,
} from "../services/AccountVoucherTypeStore";
import AccountVoucherType, {
  accountVoucherTypeDefaultProps,
} from "../models/AccountVoucherType";

interface Data {
  store: AccountVoucherTypeStore;
  opened: boolean;
  valid: boolean;
}

const CREATE_TITLE = "Bizonylatkönyv típus létrehozása";
const MODIFY_TITLE = "Bizonylatkönyv típus adatok módosítása";

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      valid: true,
      opened: false,
    };
  },

  computed: {
    title() {
      return this.store.accountVoucherType?.id > 0
        ? MODIFY_TITLE
        : CREATE_TITLE;
    },
  },

  async beforeMount() {
    const id = Number(this.$route.params.accountVoucherTypeId);
    if (id) {
      await this.store.loadAccountVoucherType(id);
    } else {
      this.store.accountVoucherType = { ...accountVoucherTypeDefaultProps };
    }
  },

  methods: {
    async save() {
      await this.store.save();
      this.$router.push("/account-voucher-types");
    },
  },
});
