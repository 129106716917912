interface PaginatedPagerData {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

export class PaginatedPager implements PaginatedPagerData {
  totalItems = 0;
  currentPage = 1;
  pageSize = 0;
  totalPages = 0;
  startPage = 0;
  endPage = 0;
  startIndex = 0;
  endIndex = 0;
  pages: number[] = [];

  constructor(params?: Partial<PaginatedPager>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

/**
 * Api Pager
 */
interface ApiPagerData {
  pager: PaginatedPagerData;
  currentPage: number;
  pageSize: number;
  totalItems: number;
}

export class ApiPager implements ApiPagerData {
  pager = new PaginatedPager();
  currentPage = 1;
  pageSize = 0;
  totalItems = 0;

  constructor(params?: Partial<ApiPagerData>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

/**
 * Pager
 */
interface PagerData {
  pager: PaginatedPagerData;
  // initial list to slice up
  list: object[];
  // returned paged list
  sliced: object[];
  currentPage: number;
  pageSize: number;
}

export default class Pager implements PagerData {
  pager = new PaginatedPager();
  list: object[] = [];
  sliced: object[] = [];
  currentPage = 1;
  pageSize = 0;

  constructor(params?: Partial<PagerData>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}
