










import Vue from "vue";

interface Props {
  show: boolean;
  name: string | number;
  title: string;
  icon: string;
  styles: string;
  nameClasses: string | object;
}

export default Vue.extend<{}, {}, {}, Props>({
  props: {
    show: { type: Boolean, default: false },
    name: [String, Number],
    title: String,
    icon: String,
    styles: String,
    nameClasses: [String, Object],
  },

  computed: {
    display() {
      return this.name?.toString().trim() != "" ?? false;
    },
  },
});
