<template>
  <div style="display: flex">
    <label class="mt-2 mr-2 input-label">
      Limit{{ "(max:" + cap + "):" }}
    </label>
    <money-input
      :precision="0"
      :value="limit"
      v-bind="$attrs"
      :valueRange="{min: 0, max: cap}"
      @change="change"
    ></money-input>
  </div>
</template>

<script>
import { defaultPossibleWriteOffDebtPreFilter } from "../models/PossibleWriteOffDebtPreFilter";
import ApiSearch from "@/components/apiSearch/models/ApiSearch";
import configuration from '@/models/Configuration';
import { getLimitFromStore, setLimitToStore } from '../store/writeOffDebtStore';
import MoneyInput from '@/components/MoneyInput.vue';

export default {
  beforeMount() {
    this.storeLimit = getLimitFromStore(this.direction);
    if (this.apiSearch?.extraFilter) this.filters = this.apiSearch.extraFilter;
    else this.apiSearch.extraFilter = this.filters;

    if(this.storeLimit > 0 && this.storeLimit <= this.cap)
      this.apiSearch.extraFilter.limit = this.storeLimit;
    else
      this.apiSearch.extraFilter.limit = this.cap;
    
    setLimitToStore(this.direction, this.filters.limit);
  },

  components: { MoneyInput },

  props: { apiSearch: ApiSearch, direction: Boolean },

  data() {
    return {
      filters: defaultPossibleWriteOffDebtPreFilter,
      cap: configuration.writeOffDebtMaxSmallAmount,
      storeLimit: 0,
    };
  },

  computed: {
    limit() {
      return this.filters.limit;
    }
  },
  
  methods: {
    change(limit) {
      console.log(limit)
      const oldLimit = this.filters.limit;
      if (limit) {
        if(limit > this.cap)
          this.filters.limit = this.cap;
        else
          this.filters.limit = limit
      }
      else
        this.filters.limit = 0;

      if(oldLimit != this.filters.limit)
        this.$emit("change", this.filters.limit);

      this.apiSearch.extraFilter = this.filters;
      setLimitToStore(this.direction, this.filters.limit);
    }
  },
};
</script>

<style scoped>
/**overwrite bottom padding of v-text-input, 
because hide-details="auto" is only partialy work*/
::v-deep .v-input__control {
  padding-bottom: 0px !important;
}
</style>
