import BankInView, { bankInViewDefaultProps } from "../models/BankInView"
import BankInViewApi from "../services/BankInViewApi";
import busyIndication from "../../../utils/busyIndication";

export class BankInViewStore {
    bankInViews: BankInView[] = []
    bankInView: BankInView = { ...bankInViewDefaultProps }

    //@busyIndication()
    async loadBankInViews(partnerID: number) {
        this.bankInViews = (await BankInViewApi.getAll(partnerID)).map((i) => ({ ...i, selected: false, sorted: false, added: false, addable: false  }));
    }
}

const store = new BankInViewStore()
export default store