import InvoiceMigrationDate from "../models/InvoiceMigrationDate";
import api from "./api";

export class InvoiceMigrationStore{
    migrationItemIds = async (dateRange: InvoiceMigrationDate) => (await api.GetMigrationItemIds(dateRange))

    async MigrateInvoices(invoiceIds: number[]){
        return await api.MigrateInvoices(invoiceIds);
    }
}

const store = new InvoiceMigrationStore()
export default store;