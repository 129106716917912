


























































































import Vue from "vue";
import store, { GeneralLedgerStore } from "../services/store";
import dayjs from "dayjs";
import { Period } from "../models/PeriodModell";
import api from "../services/api";
import eventBus from "@/services/eventBus";
import KendoGridCols from "@/models/KendoGridCols";

interface Data {
  store: GeneralLedgerStore;
  periodsThatHaveChangedStatus: Period[];
}

interface Computed {
  periods: Period[];
  periodsCols: KendoGridCols[];
  yearBeingWatched: number;
}

export default Vue.extend<Data, {}, Computed, {}>({
  data(): Data {
    return {
      store,
      periodsThatHaveChangedStatus: [],
    };
  },
  computed: {
    periods() {
      return store.periods;
    },
    periodsCols: () => [
      { field: "period", title: "Periódus" },
      { field: "startDate", title: "Kezdő dátum", cell: "startDateTemp" },
      { field: "endDate", title: "Vég dátum", cell: "endDateTemp" },
      {
        field: "mustClose",
        title: "Zárás köteles",
        cell: "mustCloseTemp",
      },
      { field: "accDate", title: "Könyvelés kezdete", cell: "accDateTemp" },
      { field: "status", title: "Állapot", cell: "statusTemp", width:200 },
      { field: "createdOn", title: "Létreh. dátum", cell: "createdOnTemp" },
      { field: "creator", title: "Létreh. felh" },
      { field: "closeDate", title: "Zárás dátuma", cell: "closeDateTemp" },
    ],
    yearBeingWatched(){
      return this.store.yearBeingChecked;
    }
  },
  watch: {
    yearBeingWatched(){
      this.store.setPeriods(this.yearBeingWatched)
    }
  },

  methods: {
    toDate(item: string) {
      return dayjs(item)
        .format("YYYY-MM-DD")
        .replaceAll("-", ".");
    },
    formatDate(date: Date) {
      return dayjs(date)
        .format("YYYY-MM-DD-HH:mm")
        .replace("-", ".")
        .replace("-", ".")
        .replace("-", " ");
    },
    isCloseDateSet(item: string) {
      return item === "1901.01.01" ? false : true;
    },
    async savePeriodsStatusChanges() {
      const result = await api.SavePeriodsStatusChanges(
        this.periodsThatHaveChangedStatus
      );
      if (result) {
        eventBus.$emit("success", "Sikeres mentés!");
        this.periodsThatHaveChangedStatus=[]
      } else {
        eventBus.$emit("error", "Sikertelen volt a mentés!");
      }
    },
  },
});
