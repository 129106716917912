














import eventBus from "@/services/eventBus";
import Vue from "vue";
import BankRemitAndItems, {
  bankRemitAndItemsDefaultProps,
} from "../models/BankRemitAndItems";
import api from "../services/BankRemitApi";
import store, { BankRemitStore } from "../services/BankRemitStore";

interface Props {
  remit: BankRemitAndItems;
}

interface Data {
  exportTypes: string[];
  store: BankRemitStore;
  selExportType: string;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    remit: {
      default: bankRemitAndItemsDefaultProps,
    },
  },

  data() {
    return {
      exportTypes: [],
      store,
      selExportType: "",
    };
  },

  methods: {
    async exportRemit() {
      const x = await api
        .generatePackage(this.remit, this.selExportType)
        .then(async (f) => {
          if (f) {
            this.$emit("reloadRemits");
          }
          return f;
        });
      if (x) {
        if (
          this.selExportType
            .trim()
            .toUpperCase()
            .includes("API")
        ) {
          eventBus.$emit("success","Sikeresen továbbítva a bank felé!");
        } else {
          eventBus.$emit("success", "Sikeres fájlgenerálás!");
        }
        window.localStorage.setItem(this.remit.bankName.toString(),this.selExportType)
      }
    },
  },

  async beforeMount() {
    this.exportTypes = await api.getExportTypes(this.remit.id).then((x) => {
      const bankExpTp = window.localStorage.getItem(this.remit.bankName.toString());

      this.selExportType = bankExpTp !== null && bankExpTp.trim() !== "" ? bankExpTp : x[0];
      return x;
    });
  },
});
