import { InverseVatSummaryItem, TariffNumber } from "./../models/index";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";

export default class VatApi {
  static getInverseVatSummary(invoiceID: number): Promise<InverseVatSummaryItem[]> {
    return http
      .get<ApiResponse<InverseVatSummaryItem[]>>(`vat/inverse/summary/geni/${invoiceID}`)
      .then(handleApiResponse);
  }

  static postInverseVatSummary(invoiceID: number, items: InverseVatSummaryItem[]): Promise<boolean> {
    return http
      .post(`vat/inverse/summary/geni`, {
        invoiceID: invoiceID,
        items: items,
      })
      .then(checkResponse);
  }

  static putInverseVatSummary(invoiceID: number): Promise<boolean> {
    return http
      .put(`vat/inverse/summary/geni`, {
        invoiceID: invoiceID,
      })
      .then(checkResponse);
  }

  static getTariffNumbers(): Promise<TariffNumber[]> {
    return http.get<ApiResponse<TariffNumber[]>>(`vat/tariff-numbers`).then(handleApiResponse);
  }
}
