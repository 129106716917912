








































import Vue from "vue";
import store, { RoleStore } from "@/modules/roles/services/roleStore";
import DeniedRelation from "../models/DeniedRelation";

interface Data {
  store: RoleStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data: () => ({
    store,
  }),

  async beforeMount() {
    await this.store.loadUsers();
    await this.store.loadDeniedRelations();
  },

  methods: {
    async save(relation: DeniedRelation) {
      await this.store.saveDeniedRelations(relation);
    },
  },

  computed: {
    columns: () => [
      { field: "feature", title: "Funkció" },
      { field: "users", title: "Felhasználók", cell: "userTemplate" },
    ],
  },
});
