import dayjs from "dayjs";
import BankExtractImportItem from "./BankExtractImportItem";

export default class BankExtractImport {
  id = 0;
  bankName = "";
  bankAccountNo = "";
  invoiceNo = "";
  statusID = 1;
  status = "";
  fileName = "";
  extractDate: string | Date = dayjs().format("YYYY-MM-DD");
  importDate: string | Date = dayjs().format("YYYY-MM-DD");
  openBal = 0;
  closeBal = 0;
  currency = "";
  items: BankExtractImportItem[] = [];
  cannotEdit = true;

  extractID = "";
  extract = "";
  debitTotal = 0;
  creditTotal = 0;
  debitCount = 0;
  creditCount = 0;

  constructor(params?: Partial<BankExtractImport>) {
    const data = { ...params };
    Object.assign(this, data);

    this.extractDate = dayjs(params?.extractDate).format("YYYY-MM-DD");
    this.importDate = dayjs(params?.importDate).format("YYYY-MM-DD");
  }
}
