import ProjectHead, { projectHeadDefaultProps } from "../models/ProjectHead";
import ProjectHeadEditModel, { projectHeadEditModelDefaultProps } from "../models/ProjectHeadEditModel";
import ProjectHeadApi from "./ProjectHeadApi";

export class ProjectHeadStore {
  projectHeads: ProjectHead[] = [];
  projectHead: ProjectHead = { ...projectHeadDefaultProps };

  projectHeadEditModels: ProjectHeadEditModel[] = [];
  projectHeadEditModel: ProjectHeadEditModel = { ...projectHeadEditModelDefaultProps };

  async loadProjectHeads() {
    this.projectHeads = await ProjectHeadApi.getAll();
  }

  async loadProjectHeadEditModels() {
    this.projectHeadEditModels = await ProjectHeadApi.getAllEditModels();
  }
}

const store = new ProjectHeadStore();
export default store;
