















import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";

const monogram = (name: string) =>
  name
    .split(" ")
    .map((i) => i[0])
    .join("")
    .toUpperCase();

const getApprovals = (store: InvoiceFlowStore, dimensionID: number) => {
  return store.acceptorRequests
    .filter((r) =>
      r.dimensionApprovals.some((a) => a.dimensionID === dimensionID)
    )
    .map((r) => ({
      monogram: monogram(r.name || ""),
      userID: r.acceptorId,
      username: r.name
    }));
};

export default Vue.extend({
  props: {
    dimension: {
      type: Object,
    },

    tick: {
      type: Date,
    },
  },

  data: () => ({
    store,
  }),

  computed: {
    approvals() {
      return getApprovals(this.store, this.dimension.id);
    }
  },
});
