export default interface Dimension {
    resourceDimID: number;
    carDimID: number;
    projectDimID: number;
    carTotal: number;
    resourceTotal: number;
    projectTotal: number;
    dimCodeType: string;
    project: number;
    userID: number;
    stationID: number;
    toolID: number;
    carID: number;

    inEdit: boolean;
    resourceType: string;
  }
  
  export const dimensionDefaultProps: Dimension = {
    resourceDimID: 0,
    carDimID: 0,
    projectDimID: 0,
    carTotal: 0,
    resourceTotal: 0,
    projectTotal: 0,
    dimCodeType: '',
    project: 0,
    userID: 0,
    stationID: 0,
    toolID: 0,
    carID: 0,

    inEdit: false,
    resourceType: ''
  };
  