import DimensionGroup from "./DimensionGroup";

export default interface IncomingInvoiceAccItem {
  id: number;
  accCode: string;
  accCodeDemand: string;
  detailedType: string;
  detailedTypeShort: string;
  detailedTypeID: number;
  total: number | null;
  currTotal: number | null;
  totalDemand: number | null;
  currTotalDemand: number | null;
  stockID: number;
  purchaseOrderID: number;
  currency: string;
  invCurrency: string;
  exchangeRate: null | number;
  orderNo: string;
  currencyDigits: number;
  accNo: string;
  accDate: Date | string;
  dimCode1: string;
  dimCode2: string;
  dimCode3: string;
  dimensions: DimensionGroup[];
  stock: number;
  invItem: number;
  invItemName: string;
  groupWith: number;
  note: string;
  car: number | null;
  isCaro: boolean;

  groupClass: string;
  colorLeft: string;
  colorRight: string;
  inEdit: boolean;
  expanded: boolean;
  dimensionExpanded: boolean;
  dim1Edit: boolean;
  isDemand: boolean;
  head?: boolean;
  group?: string;
}

export const incomingInvoiceAccItemDefaultProps: IncomingInvoiceAccItem = {
  id: 0,
  accCode: "",
  accCodeDemand: "",
  detailedType: "",
  detailedTypeShort: "",
  detailedTypeID: 1,
  total: 0,
  currTotal: null,
  totalDemand: 0,
  currTotalDemand: null,
  purchaseOrderID: 1,
  stockID: 1,
  currency: "HUF",
  exchangeRate: null,
  orderNo: "",
  currencyDigits: 0,
  accNo: "",
  accDate: "",
  dimCode1: "",
  dimCode2: "",
  dimCode3: "",
  dimensions: [],
  stock: 1,
  invItem: 1,
  invItemName: "",
  invCurrency: "HUF",
  groupWith: 1,
  note: "",
  car: null,
  isCaro: false,

  groupClass: "",
  colorLeft: "",
  colorRight: "",
  inEdit: false,
  expanded: false,
  dimensionExpanded: false,
  dim1Edit: false,
  isDemand: false,
};
