



























































import Vue from "vue";
import flowStore, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import store, { InvoiceItemStore } from "../services/store";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import { OtherCostShareOption, OtherItem, ValueDivisionMode } from "../models";
import DateInput from "@/components/DateInput.vue";

interface Data {
  store: InvoiceItemStore;
  flowStore: InvoiceFlowStore;
  generalRectItems: OtherItem[];
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    DateInput,
  },

  data() {
    return {
      store,
      flowStore,
      generalRectItems: [],
    };
  },

  async beforeMount() {
    this.generalRectItems = await http
      .get<ApiResponse<any[]>>("gparts/general-rect-items")
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            gpartID: i.gPartId ?? 1,
            serviceID: i.serviceID ?? 1,
            itemName: i.name,
            quantity: -1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: 0,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            originalIncreasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: i.orderNumber,
            inVatCount: true,
            isPostCalc: false,
            isRegTax: false,
            postCalcType: 1,
            isCarRectItem: false,
            isInclCarPrice: false,
            isService: false,
          };
          return item;
        })
      );
    console.log(this.flowStore);
  },

  methods: {
    add(item: OtherItem) {
      let invNo = "";
      if (this.store.itemList.items.length > 0) {
        invNo = this.store.itemList.items.find((x) => x.generalRectItemDetails.id > 1)?.generalRectItemDetails.rectInvNo ?? "";
      }
      this.store.addGeneralRectItem(item, invNo);
    },
  },
});
