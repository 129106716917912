export default interface BankStatementEntity {
    id: number;
    bank: number;
    extract: string;
    edate: Date;
    openBalance: number;
    closeBalance: number;
    status: number;
    accountStatus: number;
    currency: string;
    openRate: number;
    closeRate: number;
    isReopen: boolean;    

    inEdit: boolean;
    selected: boolean;
}

export class BankStatementDTO {
    id = 0;
    /**
     * Bankszámla azonosító
     */
    bank = '';

    /**
     * Bankszámla szám
     */
    bankCode = '';

    /**
     * Bankszámla kivonat száma
     */
    extract = '';

    /**
     * A bankszámla kivonat dátuma
     */
    edate = new Date();

    /**
     * Nyitó egyenleg
     */
    openBalance = 0;

    /**
     * Záró egyenleg
     */
    closeBalance = 0;

    /**
     * A bankkivonat státusza
     */
    status = '';

    /**
     * A bankkivonat státusza
     */
    statusID = 0;

    /**
     * A kivonat könyvelési állapota
     */
    accountStatus = '';

    /**
     * Pénznem
     */
    currency = 'HUF';

    /**
     * Nyitó átlagárfolyam
     */
    openRate = 0;

    /**
     * Záró átlagárfolyam
     */
    closeRate = 0;

    inEdit= false;
    selected= false;
}

export interface BankStatementResponse {
    bankStatements: BankStatementEntity[];
    count: number;
}

export const bankStatementDefaultProps: BankStatementEntity = {
    id: 0,
    bank: 1,
    extract: '',
    edate: new Date,
    openBalance: 0,
    closeBalance: 0,
    status: 2,
    accountStatus: 2,
    currency: '',
    openRate: 0,
    closeRate: 0,
    inEdit: false,
    selected: false,
    isReopen: false,
}