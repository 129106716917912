<template>
  <v-container fluid class="pt-1">
    <v-row class="mx-0">
      <h3 class="ml-3">Funkciók</h3>
    </v-row>

    <v-row class="justify-start my-2 ml-3">
      <v-btn outlined class="src-btn">
        <v-icon x-small>fa-search</v-icon>
      </v-btn>

      <input
        type="text"
        placeholder="Központi keresés..."
        class="k-textbox py-2 px-2"
        v-model="search"
        style="z-index: 1"
      />

      <v-btn outlined class="infobtn">
        <v-icon x-small style="color: blue" class="mr-0">fa-info-circle</v-icon>
      </v-btn>
    </v-row>

    <v-divider class="mt-3"></v-divider>

    <v-row class="pt-3">
      <v-col cols="3">
        <div min-width="150px" class="settingsMenu">
          <router-link tag="div" to="/settings" class="link">
            <v-row class="pt-3">
              <v-col class="align-center justify-center ">
                <h3 class="mb-1">Pénzügy</h3>
                <p style="font-weight: normal">
                  Pénznemek, árfolyamok beállítása
                </p>
              </v-col>
            </v-row>
          </router-link>
        </div>
        <div v-for="item in filteredMenus" :key="item.title" class="mb-0">
          <MenuItem :item="item" />
        </div>
      </v-col>

      <v-col cols="9">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import configuration from "@/models/Configuration";
import MenuItem from "./Components/MenuItem.vue";

function filtered(settingsChildren) {
  return settingsChildren.filter((x) =>
    [
      "/settings/banks",
      "/approval-matrix",
      "/document-templates",
      "/user-acc-no",
      "/account-codes/list",
      "/parameters",
      "/roles",
      "/tax",
      "/taxes",
      "/generalLedgerInvoiceMirror",
      "/invoice-parameter",
      "/general-ledger/transaction-items",
    ].includes(x.url.toLocaleLowerCase())
  );
}

export default {
  name: "SettingsPage",

  components: {
    MenuItem,
  },

  data: () => ({
    configuration,
    search: "",
    menuItems: [
      {
        title: "Bankok",
        url: "/settings/banks",
      },
      {
        title: "Folyószámla műveletek",
        url: "/settings/account/action-source",
      },
      {
        title: "Fizetési módok",
        url: "/settings/payment-mode",
      },
      {
        title: "Folyószámla tétel csoportok",
        url: "/settings/account/account-type",
      },
      {
        title: "Költséghelyek",
        url: "/settings/invoice-group",
      },
      {
        title: "Indirekt költséghely jóváhagyások",
        url: "/settings/invoice-group-approvals",
      },
      {
        title: "Pénztárak",
        url: "/settings/cash-points",
      },
      {
        title: "Értesítő típusok",
        url: "/invoice-types",
      },
      {
        title: "Árfolyam adatok",
        url: "/currency-rates",
      },
      {
        title: "Főkönyvi számlák",
        url: "/user-acc-no",
      },
      {
        title: "Partnerhez rendelt jóváhagyók",
        url: "/approval-matrix",
      },
      {
        title: "Dokumentum sablonok",
        url: "/document-templates",
      },
      {
        title: "Bejövő számla típusok",
        url: "/incoming-invoice-type",
      },
      {
        title: "Kimenő számla típusok",
        url: "/outgoing-invoice/type",
      },
      {
        title: "Bizonylatkönyv típusok",
        url: "/account-voucher-types",
      },
      {
        title: "Könyvelési főkönyvi számok",
        url: "/account-codes/list",
      },
      {
        title: "Rendszer paraméterek",
        url: "/parameters",
      },
      {
        title: "Könyvelési dimenziókód típusok",
        url: "/dim-code-types",
      },
      {
        title: "Jogosultság szerepkörök",
        url: "/roles",
      },
      {
        title: "ÁFA kulcsok",
        url: "/tax",
      },
      {
        title: "Számlatükör és dimenzió kódok",
        url: "/generalLedgerPeriods",
      },
      {
        title: "Számlatípusok paraméterezése",
        url: "/invoice-parameter",
      },
      {
        title: "Bankimport mozgáskódok",
        url: "/bank-extract-import-account-type",
      },
      {
        title: "Tárgyi eszköz csoportok",
        url: "/fix-asset/groups",
      },
      {
        title: "Számla migráció",
        url: "/invoice/migration",
      },
      {
        title: "Bankkártyák",
        url: "/credit-cards",
      },
      {
        title: "Egyéb cikkek/szolgáltatások karbantartó",
        url: "/gparts/edit-list",
      },
    ],
  }),

  computed: {
    filteredMenus() {
      const menuItems = process.env.VUE_APP_SETTINGS_HIDDEN === "true" ? filtered(this.menuItems) : this.menuItems;
      return menuItems.filter((item) => {
        return item.title.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.settingsMenu {
  background-color: white;
  border: thin solid #ddd;
  cursor: pointer;
}

.settingsMenu .router-link-exact-active:hover {
  background-color: var(--primaryColorHover);
}

.settingsMenu:not(.router-link-exact-active):hover {
  background-color: #eee;
}

.src-btn {
  border-left: thin solid #ddd;
  border-top: thin solid #ddd;
  border-bottom: thin solid #ddd;
  border-right: none !important;
  background: rgb(233, 233, 233);
}

.infobtn {
  border-right: thin solid #ddd;
  border-top: thin solid #ddd;
  border-bottom: thin solid #ddd;
  border-left: none !important;
}

.card {
  height: 50px;
}
</style>
