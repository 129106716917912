import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import BankInView from '../models/BankInView'

export default class BankInViewApi {
    static getAll(partnerID: number): Promise<BankInView[]> {
        return http
            .get<ApiResponse<BankInView[]>>(`bankin/${partnerID}`)
            .then(handleApiResponse)
    }
}
