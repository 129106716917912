export default interface CashPoint {
  id: number;
  name: string;
  bankNo: number;
  partner: number;
  cashier: number;
  site: number;
  hufOnly: boolean;
  detailedType: number;
  accCode: string;
  dimCode1: string;
  dimCode3: string;
  accNo: number;
  inDocNo: number;
  outDocNo: number;
  docPrefix: string;
  noCurrAcc: boolean;
  currency: string;
  isCashReg: boolean;
  cashRegType: number;
  printer: number;
  isDivItem: boolean;
  isNegative: boolean;
  cashRegPort: string;
  cashRegSpeed: string;
  cashRegPar1: string;
  cashRegPar2: string;
  cashRegPar3: string;
  isDocDateShip: boolean;
  isAutoClose: boolean;
  isAutoPrintDoc: boolean;
  isMainCashPoint: boolean;
  isDay: boolean;
}

export const cashPointDefaultProps: CashPoint = {
  id: 0,
  name: "",
  bankNo: 0,
  partner: 0,
  cashier: 0,
  site: 0,
  hufOnly: false,
  detailedType: 0,
  accCode: "",
  dimCode1: "",
  dimCode3: "",
  accNo: 0,
  inDocNo: 0,
  outDocNo: 0,
  docPrefix: "",
  noCurrAcc: false,
  currency: "",
  isCashReg: false,
  cashRegType: 0,
  printer: 0,
  isDivItem: false,
  isNegative: false,
  cashRegPort: "",
  cashRegSpeed: "",
  cashRegPar1: "",
  cashRegPar2: "",
  cashRegPar3: "",
  isDocDateShip: false,
  isAutoClose: false,
  isAutoPrintDoc: false,
  isMainCashPoint: false,
  isDay: false,
};
