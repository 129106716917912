export default interface NotiDownPayEditModel {
    id: number;
    //noti
/*     notiId: number; */
    notiInv: number;
    notiPaym: number[];
    invoice: number;
    
    //mminigeninvoice
    geniId: number;
    preDownPaym: boolean;
    dpaym: boolean;
    invNo: string;
    
    //mmaccgenipaym
    paymId: number[];
    crediting: boolean;
    invCurrency: string;
    currAmount: number;
    netto: number;
    paymentInsDate: Date | string;
    paymPdate: Date | string;

    selected: boolean;
    expanded: boolean;
}

export const notiDownPayEditModelDefaultProps: NotiDownPayEditModel = {
    id: 1,
    //noti
    /* notiId: 1, */
    notiInv: 1,
    notiPaym: [],
    invoice: 1,
    
    //mminigeninvoice
    geniId: 1,
    preDownPaym: false,
    dpaym: false,
    invNo: "",
    
    //mmaccgenipaym
    paymId: [],
    crediting: false,
    invCurrency: "",
    currAmount: 1,
    paymentInsDate: "1980. 01. 01.",
    paymPdate: "1980. 01. 01.",
    netto: 0,

    selected: false,
    expanded: false
}