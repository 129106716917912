export default interface NoticeDetails {
  name: string;
  address: string;
  taxNumber: string;
  euTaxNumber: string;
  partnerName: string;
  partnerAddress: string;
  partnerTaxNumber: string;
  partnerEuTaxNumber: string;
  bankAccount2: string;
  bankName2: string;
  outsider: boolean;
  specTaxId: number;
  euTaxAccount: string;
  payterm: number;
  native: boolean;
}

export const noticeDetailsDefaultProps: NoticeDetails = {
  name: "",
  address: "",
  taxNumber: "",
  euTaxNumber: "",
  partnerName: "",
  partnerAddress: "",
  partnerTaxNumber: "",
  partnerEuTaxNumber: "",
  bankAccount2: "",
  bankName2: "",
  outsider: true,
  specTaxId: 1,
  euTaxAccount: "",
  payterm: 0,
  native: true,
};
