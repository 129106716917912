











































































































import Vue from "vue";
import store, { LoginStore } from "../services/LoginStore";
import auth, { Authentication } from "../../../services/auth";
import http, { setConnection } from "../../../services/http";

interface ConnectionList {
  useDealerCode: boolean;
  connections: ConnectionItem[];
}

interface ConnectionItem {
  key: string;
  dealerCode: string;
}

interface Data {
  store: LoginStore;
  useDealerCode: boolean;
  connections: ConnectionItem[];
  filteredConnections: ConnectionItem[];
  selectedConnection: ConnectionItem;
  auth: Authentication;
  dealerCodeNotSelected: boolean;
}

interface Methods {
  loadConnections(): Promise<void>;
  login(): void;
  dealerCodeChangedHandler(): void;
}

const LAST_CONNECTION_ID_KEY = "trufinance.lastConnectionID";
const LAST_CONNECTION_DEALERCODE = "trufinance.lastConnectionDealerCode";

export default Vue.extend<Data, Methods, {}, {}>({
  components: {},

  data(): Data {
    return {
      store,
      useDealerCode: false,
      connections: [],
      filteredConnections: [],
      auth,
      selectedConnection: { key: "", dealerCode: ""},
      dealerCodeNotSelected: false,
    };
  },

  beforeMount() {
    this.loadConnections();
    if (auth.isLoggedIn) this.$router.push("/");
  },

  methods: {
    dealerCodeChangedHandler() {
      if (this.useDealerCode) {
        this.selectedConnection = { key: "", dealerCode: ""};
        if (!this.store.login.code) {
          this.filteredConnections = [];
        }
        else {
          this.filteredConnections = [...this.connections.filter((x => x.dealerCode.toUpperCase() === this.store.login.code.toUpperCase()))];
          this.dealerCodeNotSelected = false;
        }
      }
    },

    async loadConnections() {
      const response = await http.get<ConnectionList>(`connections`);
      if (response.data) {
        this.useDealerCode = response.data.useDealerCode;
        this.connections = response.data.connections;

        if (!this.useDealerCode) {
          this.filteredConnections = [...this.connections];
        }
      }

      const lastConnectionID = window.localStorage.getItem(LAST_CONNECTION_ID_KEY);
      const lastConnectionDealerCode = window.localStorage.getItem(LAST_CONNECTION_DEALERCODE);

      if (lastConnectionID && this.connections.map(x => x.key).includes(lastConnectionID)) {
        this.selectedConnection.key = lastConnectionID;

        if (this.useDealerCode) {
          this.selectedConnection.dealerCode = lastConnectionDealerCode ?? "";
        }
      }
    },

    login() {
      if (this.useDealerCode && !this.store.login.code) {
        this.dealerCodeNotSelected = true;
        return;
      }

      this.store.doLogin().then(async () => {
        if (this.store.loginResult.success) {
          await auth.setToken(
            this.store.loginResult.token,
            this.store.loginResult.userName,
            this.store.loginResult.userID
          );

          const redirect = this.$route.query.redirect || "/";
          this.$router.push(`${redirect}`);
          this.dealerCodeNotSelected = false;
        }
      });
    },
  },

  watch: {
    selectedConnection(conn: ConnectionItem) {
      setConnection(conn.key);
      window.localStorage.setItem(LAST_CONNECTION_ID_KEY, conn.key);
      if (this.useDealerCode) {
        window.localStorage.setItem(LAST_CONNECTION_DEALERCODE, conn.dealerCode);
      }
      else {
        window.localStorage.setItem(LAST_CONNECTION_DEALERCODE, "");
      }
    },
  },
});
