




























































































































import Vue from "vue";
import store, { PaymentModeStore } from "../services/PaymentModeStore";
import Page from "../../../components/Page.vue";
import PaymentMode from "../models/PaymentMode";
import setAttribute from "../../../utils/dom";

interface Data {
  store: PaymentModeStore;
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "9");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    confirmation: confirmationTemplate,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadPaymentModes();
  },

  computed: {
    columns: () => [
      { field: "name", title: "Megnevezés" },
      {
        field: "promptPay",
        title: "Azonnali pü.i telj.",
        cell: "promptPayTemplate",
      },
      {
        field: "noCurrentAccount",
        title: "Folysz. nem átfutó",
        cell: "noCurrentAccountTemplate",
      },
      {
        field: "creditCheck",
        title: "Hitelk. figyelés",
        cell: "creditCheckTemplate",
      },
      { field: "sapCode", title: "SAP kód" },
      { field: "webPayMode", title: "Webes fiz. mód." },
      { field: "isConstr", title: "Szerz.szerinti", cell: "isConstrTemplate" },
      { field: "partnerAccountCode", title: "Partner fők. szám" },
      { cell: "commandTemplate", width: "120px" },
      { width: 0 },
    ],

    items() {
      return this.store.paymentModes;
    },
  },

  methods: {
    createPaymentMode() {
      this.$router.push(`/payment-mode/create`);
    },

    updatePaymentMode(item: PaymentMode) {
      this.$router.push(`/payment-mode/${item.id}/edit`);
    },

    deleteItem(item: PaymentMode) {
      this.store.setDeleting(item);
    },

    confirmDelete(item: PaymentMode) {
      item.deleting = false;
      this.store.deletePaymentMode(item.id);
    },

    cancelDelete(item: PaymentMode) {
      item.deleting = false;
    },
  },
});
