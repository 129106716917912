import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import GeneralIngoinInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import GeneralIngoingInvoiceAndItems from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceAndItems";
import GeneralIngoingInvoiceItem from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItem";
import CustomsInvoiceDetails from "@/modules/customsInvoice/models/CustomsInvoiceDetails";
import Tax from "@/modules/generalIngoingInvoice/models/Tax";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import GeniForCustomSelection from "@/modules/customsInvoice/models/GeniForCustomSelection";
import StockRelationRequest from "../models/StockRelationRequest";

export default class CustomsInvoiceApi {
  static get(id: number): Promise<GeneralIngoingInvoiceAndItems> {
    return http.get<ApiResponse<GeneralIngoingInvoiceAndItems>>(`customs-invoice/${id}`).then(handleApiResponse);
  }

  static getAll(): Promise<GeneralIngoinInvoiceDisplay[]> {
    return http.get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(`customs-invoice`).then(handleApiResponse);
  }

  static getAllByInvDate(startDate: string, endDate: string): Promise<GeneralIngoinInvoiceDisplay[]> {
    return http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(`customs-invoice/invDate/${startDate}/${endDate}`)
      .then(handleApiResponse);
  }

  static getAllPayDate(startDate: string, endDate: string): Promise<GeneralIngoinInvoiceDisplay[]> {
    return http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(`customs-invoice/payDate/${startDate}/${endDate}`)
      .then(handleApiResponse);
  }

  static getAllInvAndPayDate(
    invStartDate: string,
    invEndDate: string,
    payStartDate: string,
    payEndDate: string
  ): Promise<GeneralIngoinInvoiceDisplay[]> {
    return http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(
        `customs-invoice/${invStartDate}/${invEndDate}/${payStartDate}/${payEndDate}`
      )
      .then(handleApiResponse);
  }

  static create(
    invoice: GeneralIngoinInvoice,
    items: GeneralIngoingInvoiceItem[],
    genisInRelation: GeniForCustomSelection[],
    relations: StockRelationRequest[]
  ): Promise<number> {
    return http
      .post<ApiResponse<number>>(`customs-invoice`, {
        invoice: invoice,
        items: items,
        genis: genisInRelation,
        relations: relations,
      })
      .then(handleApiResponse);
  }

  static update(
    invoice: GeneralIngoinInvoice,
    items: GeneralIngoingInvoiceItem[],
    genisInRelation: GeniForCustomSelection[],
    relations: StockRelationRequest[]
  ) {
    return http
      .put(`customs-invoice`, {
        invoice: invoice,
        items: items,
        genis: genisInRelation,
        relations: relations,
      })
      .then(checkResponse);
  }

  static accept(
    invoice: GeneralIngoinInvoice,
    items: GeneralIngoingInvoiceItem[],
    genisInRelation: GeniForCustomSelection[],
    relations: StockRelationRequest[]
  ) {
    return http
      .put(`customs-invoice/accept`, {
        invoice: invoice,
        items: items,
        genis: genisInRelation,
        relations: relations,
      })
      .then(checkResponse);
  }

  static setIncorrect(invoiceID: number): Promise<boolean> {
    return http.put(`customs-invoice/incorrect`, { invoiceID: invoiceID }).then(checkResponse);
  }

  static getDetails(partnerId: number, invoiceId: number): Promise<CustomsInvoiceDetails> {
    return http
      .get<ApiResponse<CustomsInvoiceDetails>>(`customs-invoice/details/${partnerId}/${invoiceId}`)
      .then(handleApiResponse);
  }

  static getGenis(searchText: string, invoiceId: number): Promise<GeniForCustomSelection[]> {
    return http
      .get<ApiResponse<GeniForCustomSelection[]>>(`customs-invoice/geni/${searchText}/${invoiceId}`)
      .then(handleApiResponse);
  }

  static getTaxes(): Promise<Tax[]> {
    return http.get<ApiResponse<Tax[]>>(`tax`).then(handleApiResponse);
  }
}
