var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(_vm.showDocumentTypes)?_c('v-col',{attrs:{"align":"left"}},[_c('v-select',{staticClass:"comboBox",attrs:{"items":_vm.dStore.documentTypes,"outlined":"","dense":"","item-text":"name","item-value":"name","return-object":false},on:{"change":_vm.createDocumentRelation},model:{value:(_vm.newDocumentRelationItem.documentType),callback:function ($$v) {_vm.$set(_vm.newDocumentRelationItem, "documentType", $$v)},expression:"newDocumentRelationItem.documentType"}})],1):_vm._e(),_c('v-col',{attrs:{"align":"right"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"infoButton",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.showDocumentType}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]}}])},[_c('span',[_vm._v("Új dokumentum igény")])])],1)],1),_vm._l((_vm.relStore.otherDocumentRelations),function(relation){return _c('div',{key:relation.id,staticClass:"mx-3 mb-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"vertical-align":"baseline"},attrs:{"color":relation.hasFile ? 'var(--primaryColor)' : 'error',"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(relation.hasFile ? "fa-check" : "fa-circle-o")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(relation.hasFile ? "Feltöltve" : "Nincs feltöltve")+" ")])]),_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"title":'Dokumentum feltöltése'},on:{"click":function($event){return _vm.showFile(relation)}}},[_vm._v(" "+_vm._s(relation.documentType)+" ")]),_c('v-expand-transition',[(relation.uploading)?_c('div',{staticClass:"px-2",staticStyle:{"background-color":"#f5f5f5"}},[_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(relation.fileName != ''),expression:"relation.fileName != ''"}]},[_c('v-expand-transition',[_c('label',[_vm._v(" "+_vm._s(relation.fileName)+" ")])])],1)]),_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-right":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!relation.hasFile || !_vm.store.isEditingEnabled || _vm.store.invoice.id == 0},on:{"click":function($event){return _vm.downloadFile(relation.link)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-download ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Fájl letöltése ")])])],1),_c('v-col',[_c('v-file-input',{ref:"file",refInFor:true,attrs:{"dense":"","outlined":"","hide-details":"auto","clear-icon":"fa-times","prepend-icon":"","accept":"image/jpeg,image/png,application/pdf,text/plain","id":("file-" + (relation.statusId) + "-" + (relation.id) + "-" + (relation.documentTypeId)),"disabled":!_vm.store.isEditingEnabled || _vm.store.invoice.id == 0},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('label',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-plus ")])]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!_vm.store.isEditingEnabled || _vm.store.invoice.id == 0},on:{"click":function($event){return _vm.uploadFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl feltöltése")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!relation.hasFile || !_vm.store.isEditingEnabled || _vm.store.invoice.id == 0},on:{"click":function($event){return _vm.deleteFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-trash-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl törlése")])])],1)],1)],1):_vm._e()])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }