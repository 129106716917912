



















































import UpDownIcon from "../../../components/UpDownIcon.vue";
import StatusChip from "../../../components/StatusChip.vue";

import Vue from "vue";
import BankExtractImportApi from "../services/BankExtractImportApi";
import store from "../services/BankExtractImportStore";
import BankExtractImport from "../models/BankExtractImport";

export default Vue.extend({
  components: { UpDownIcon, StatusChip },

  props: {
    head: { type: Object as () => BankExtractImport },
    /** Show editable columns - @default false */
    edit: { type: Boolean, default: false },
  },

  data: () => ({
    store,
    loaded: false,
  }),

  async beforeMount() {
    if (!this.edit && (!this.head.items || this.head.items.length == 0)) {
      this.head.items = await BankExtractImportApi.getItems(this.head.id);
    }

    this.loaded = true;
  },
});
