import { formatMoney } from "./../../../filters/money-format";
import dayjs, { Dayjs } from "dayjs";
import { PaymentSchedule } from "./PaymentSchedule";
import PartBank from "@/modules/cashPoint/models/PartBank";

export class InvoiceDeadline {
  headID = 0;
  paymentSchedule: PaymentSchedule = new PaymentSchedule();
  invoiceID = 0;
  invoiceNo = "";
  prefix = "";
  brutto = 0;
  currency = "";
  bruttoDisplay = "";
  invoiceDate: Dayjs = dayjs("1970-01-01");
  deadline: Dayjs = dayjs("1970-01-01");
  invoiceDateDisplay = "";
  deadlineDisplay = "";
  expanded = false;
  partner = 0;
  partBanks: PartBank[] = [];

  constructor(params?: Partial<InvoiceDeadline>) {
    const data: Partial<InvoiceDeadline> = { ...params };
    data.bruttoDisplay = `${formatMoney(data.brutto ?? 0)} ${data.currency}`;
    data.invoiceDate = params?.invoiceDate ? dayjs(params?.invoiceDate) : this.invoiceDate;
    data.deadline = params?.deadline ? dayjs(params?.deadline) : this.deadline;
    data.invoiceDateDisplay = data.invoiceDate.format("YYYY. MM. DD.");
    data.deadlineDisplay = data.deadline.format("YYYY. MM. DD.");
    Object.assign(this, data);
  }
}
