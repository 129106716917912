




























































































































































import Vue from 'vue';
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';
import { FilteringModel } from '@/services/filtering';
import IconButton from "@/components/IconButton.vue";
import store, { CurrentAccountStore } from "./services/CurrentAccountStore";
import CurrentAccountArrangement from "./models/CurrentAccountArrangement";
import Tabs from "../../components/Tabs.vue";
import DateInput from "@/components/DateInput.vue";
import MoneyDisplay from '@/components/MoneyDisplay.vue';
import MoneyInput from '@/components/MoneyInput.vue';
import CheckBox from "@/components/CheckBox.vue";
import eventBus from '@/services/eventBus';
import Confirmation from "@/components/Confirmation.vue";
import { ConfirmationMixin } from "@/mixins/confirmation";

interface Data {
  filter: FilteringModel;
  items: any[];
  store: CurrentAccountStore;
  comment: string;
  checked: boolean;
  title: string;
  selectedInvoices: any[];
  selectedPartners: any[];
  accDate: string;
  maxValue: number;
}

interface Methods {
  calculateRemaining(oriAmount: number): number;
  addPartnerToSelected(partner: any): void;
  addInvoiceToSelected(invoice: any): void;
  saveArrangement(invoice: CurrentAccountArrangement): void;
  cancelArrangement(): void;
  showDetails(item: CurrentAccountArrangement): void;
  setItems(items: CurrentAccountArrangement[]): void;
}

export default ConfirmationMixin.extend<Data, Methods, {}, {}>({
  components: {
    ApiSearchPage,
    IconButton,
    Tabs,
    DateInput,
    MoneyDisplay,
    MoneyInput,
    CheckBox,
    Confirmation,
  },

    mixins: [ConfirmationMixin],

    data() {
      return {
        filter: new FilteringModel(),
        url: "currentaccount/arrangement",
        items: [],
        store,
        title: "Folyószámla rendezés",
        comment: "",
        checked: true,
        selectedInvoices: [],
        selectedPartners: [],
        accDate: new Date().toLocaleDateString(),
        maxValue: 0,
      }
    },

    methods: {
      calculateRemaining(oriAmount: number): number{
        return oriAmount - this.selectedInvoices.reduce((a, b) => a + b.amount, 0) - this.selectedPartners.reduce((a, b) => a + b.amount, 0);
      },

      addPartnerToSelected(partner: any){
        if (partner.amount == 0) {
          eventBus.$emit("error", "Nem adott meg összeget!")
          return;
        }

        if (partner.amount + this.selectedPartners.reduce((a, b) => (a + b.amount), 0) > this.maxValue) {
          eventBus.$emit("error", "A kiválasztott számlák összege meghaladja a payment értékét!")
          return;
        }

        if (partner.isSelected) {
          this.selectedPartners = this.selectedPartners.filter((x) => x.id != partner.id);
        }
        else {
          this.selectedPartners.push(partner);
        }
        partner.isSelected = !partner.isSelected;
        console.log(this.selectedPartners);
      },

      addInvoiceToSelected(invoice: any){
        if (invoice.amount == 0) {
          eventBus.$emit("error", "Nem adott meg összeget!")
          return;
        }

        if (invoice.isSelected) {
          this.selectedInvoices = this.selectedInvoices.filter((x) => x.id != invoice.id);
        }
        else {
          if (invoice.amount + this.selectedInvoices.reduce((a, b) => (a + b.amount), 0) > this.maxValue) {
            eventBus.$emit("error", "A kiválasztott számlák összege meghaladja a payment értékét!")
            return;
          }
          this.selectedInvoices.push(invoice);
        }
        invoice.isSelected = !invoice.isSelected;
        console.log(this.selectedInvoices);
      },

      async saveArrangement(invoice: CurrentAccountArrangement){
        console.log(invoice);
        
        if (new Date(this.accDate) < new Date(invoice.accDate)) {
          eventBus.$emit("error", `A könyvelés dátuma nem lehet korábbi, mint a törölni kívánt fizetési tétel létrehozásának könyvelési dátuma, ami: ${invoice.accDate.toLocaleString().split('T')[0].replaceAll("-", ". ")}.`);
          return;
        }

        if (this.selectedInvoices.length == 0 && invoice.code != "OVER") {
          const confirmed = await this.confirm("Nem adott meg cél számlá(ka)t és/vagy partner(eke)t, így a kiválasztott fizetési tétel teljes összegéből túlfizetés generálódik, folytatja?");
          if (!confirmed) {
            return;
          }
        }

        if (this.selectedInvoices.some((x) => this.accDate < x.shipDate)) {
          const confirmed = await this.confirm("A kiválasztott számlák között van olyan, melynek teljesítési dátuma későbbi, mint a megadott könyvelés dátum! Folytatja?");
          if (!confirmed) {
            return;
          }
        }

        const ret = await this.store.checkAccDate(invoice.accDate.toString());
        if (ret.code == -1) {
          eventBus.$emit("error", `${ret.err}`);
          return;
        }
        if (ret.code == -2) {
          const confirmed = await this.confirm(`${ret.err}`);
          if (!confirmed) {
            return;
          }
        }

        const payload = {
          invoiceId: invoice.id,
          code: invoice.code,
          accDate: this.accDate,
          comment: this.comment,
          checked: this.checked,
          remaining: this.calculateRemaining(invoice.oriAmount),
          selectedInvoices: this.selectedInvoices,
          selectedPartners: this.selectedPartners
        };
        const res = await this.store.saveArrangement(payload);
        if (res) {
          eventBus.$emit("success", "Sikeres rendezés!");
          eventBus.$emit("refreshCurrentAccountArrangement");
          this.cancelArrangement();
        }
        else {
          eventBus.$emit("error", "Hiba történt!");
        }
      },

      cancelArrangement(){
        this.selectedInvoices = [];
        this.selectedPartners = [];
        eventBus.$emit("currentAccountArrangementInvoiceSearch:reload");
      },

      showDetails(item: CurrentAccountArrangement){
        item.selected = !item.selected;
        this.selectedInvoices = [];
        this.selectedPartners = [];
        this.maxValue = item.oriAmount;
      },

      setItems(items: CurrentAccountArrangement[]) {
        this.items = items;
      },
    },

    computed: {
      columns: () => [
        {
          cell: "selectButton",
          filterable: false,
          width: "80px"
        },
        { 
          field: "id",
          title: "ID",
          width: 80,
        },
        { 
          field: "code",
          title: "Prefix"
        },
        {
          title: "Belső ID",
          field: "invoice",
        },
        {
          title: "Számlaszám",
          field: "bgInvNo",
        },
        { 
          field: "partnerName",
          title: "Partner"
        },
        {
          field: "insDate",
          title: "Létrehozás dátuma",
          type: ColumnType.Date
        },
        {
          field: "accDate",
          title: "Könyvelés dátuma",
          type: ColumnType.Date
        },
        {
          field: "oriAmount",
          title: "Eredeti összeg",
          type: ColumnType.Money,
          cell: "oriAmountTemplate",
          filterable: false,
        },
        {
          field: "currTotal",
          title: "Számla totál",
          type: ColumnType.Money,
          cell: "currTotalTemplate",
          filterable: false,
        },
        {
          field: "invTotal",
          title: "Totál számla pénznemében",
          type: ColumnType.Money,
          cell: "invPaymTotalTemplate",
          filterable: false,
        },
        {
          field: "actionTypeName",
          title: "Akciótípus",
        },
        {
          field: "accStatusName",
          title: "Könyvelési státusz",
        },
        {
          field: "invStatusName",
          title: "Számla státusz",
        },
      ],
      invoiceColumns: () => [
        {
          cell: "selectButton",
          filterable: false,
          width: "80px"
        },
        { 
          field: "code",
          title: "Prefix"
        },
        {
          title: "Belső ID",
          field: "id",
        },
        {
          title: "Számlaszám",
          field: "bgInvNo",
        },
        { 
          field: "partnerName",
          title: "Partner"
        },
        {
          field: "total",
          title: "Bruttó",
          type: ColumnType.Money,
          cell: "totalTemplate",
          filterable: false,
        },
        {
          field: "paid",
          title: "Fizetve",
          type: ColumnType.Money,
          cell: "paidTemplate",
          filterable: false,
        },
        {
          field: "amount",
          title: "Összeg",
          cell: "amountTemplate",
          className: "right_text", 
          filterable: false,
        },
        {
          field: "currTotal",
          title: "Totál",
          type: ColumnType.Money,
          cell: "currTotalTemplate",
          filterable: false,
        },
        {
          field: "invDate",
          title: "Számla dátuma",
          type: ColumnType.Date
        },
        {
          field: "shipDate",
          title: "Teljesítés dátuma",
          type: ColumnType.Date
        },
        {
          field: "statusName",
          title: "Státusz",
        },
      ],
      partnerColumns: () => [
        {
          cell: "selectButton",
          filterable: false,
          width: "80px"
        },
        { 
          field: "id",
          title: "Azonosító"
        },
        {
          field: "name",
          title: "Név",
        },
        // {
        //     field: "nicName",
        //     title: "Rövid név",
        // },
        {
          field: "amount",
          title: "Összeg",
          cell: "amountTemplate",
          className: "right_text", 
          filterable: false,
        },
        { 
          field: "partnerTypeName",
          title: "Partner típus"
        },
        { 
          field: "companyFormName",
          title: "Vállalkozási forma"
        },
        { 
          field: "taxAccount",
          title: "Adószám"
        },
        { 
          field: "invCityName",
          title: "Számlázási cím"
        },
        {
          field: "invAddress",
          title: "Pontos számlázási cím",
        },
        {
          field: "corCityName",
          title: "Levelezési cím",
        },
        {
          field: "corAddress",
          title: "Pontos levelezési cím",
        },
        {
          field: "email",
          title: "Email",
        },
        {
          field: "payModeName",
          title: "Fizetési mód",
        },
      ],
    },

    beforeDestroy() {
      this.selectedInvoices = [];
      this.selectedPartners = [];
    },
})
