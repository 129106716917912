import http, { handleApiResponse } from "@/services/http";
import Employee from "../models/Employee";
import ApiResponse from "@/models/ApiResponse";

export class EmployeeStore{
    employees: Employee[] = [];

    async loadEmployees(){
        this.employees = await http.get<ApiResponse<Employee[]>>("employees").then(handleApiResponse);
    }
}

const employeeStore = new EmployeeStore();
export default employeeStore;