import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";

/** Get a text orientation class by ColumnType */
export function getTypeClasses(type?: ColumnType): string {
  switch (type) {
    case ColumnType.Money:
      return "right_text";
    case ColumnType.Right:
      return "right_text";
    case ColumnType.Date:
    case ColumnType.Center:
      return "center_text";
    default:
      return "";
  }
}

export function getClasses(
  base: string,
  type?: ColumnType,
  classNames?: string
): string {
  const classes = classNames ? classNames : "";
  return [base, getTypeClasses(type), classes].filter((s) => !!s).join(" ");
}

export function getHeaderClasses(column: ApiSearchColumn): string {
  return getClasses("header", column.type, column?.headerClass);
}

export function getFieldClasses(column: ApiSearchColumn): string {
  return getClasses("field", column.type, column?.fieldClass);
}

/** Add a class to change even row's bg color */
export function getAltRow(index: number): string {
  return index % 2 === 1 ? " alt" : "";
}
