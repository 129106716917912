



















import Vue from "vue";
import setAttribute from "../../../utils/dom";

export default Vue.extend<{}, {}, {}, {}>({
  props: { item: Object },
  data() {
    return {};
  },

  // mounted() {
  //   setAttribute(".k-detail-cell", "colspan", "14");
  // },

  methods: {
    response(item: boolean) {
      if (item) {
        this.$emit("confirm");
      } else {
        this.$emit("cancel");
      }
    },
  },
});
