






import Vue from "vue";

export default Vue.extend({
  props: {
    /** The arrow's direcction - @default true -> up */
    isUp: { type: Boolean, default: true },
    icon: { type: String, default: "arrow" },

    up: { type: String, default: "up" },
    down: { type: String, default: "down" },

    upColor: { type: String, default: "green" },
    downColor: { type: String, default: "red" },
  },
});
