import CashDocItemDisplay from "./CashDocItemDisplay";
import CashDocument from "./CashDocument";

export default interface CashDocumentDisplay {
  cashDocItems: CashDocItemDisplay[];
  document: CashDocument;
  id: number;
  cashAccount: number;
  uniqueNo: number;
  partnerName: string;
  income: boolean;
  currAmount: number;
  invCurrency: string;
  amount: number;
  currency: string;
  exchangeRate: number;
  rateDate: string;
  status: string;
  partner: string;
  comment: string | null;
  annexNo: number | null;
  closeDate: string;
  minCloseDate: string;
  amoutText: string;
  user: string;
  insDate: string;
  insTime: string;
  selected: boolean;
}

export const cashDocumentDisplayDefaultProps: CashDocumentDisplay = {
  cashDocItems: [],
  document: new CashDocument(),
  id: 0,
  cashAccount: 0,
  uniqueNo: 0,
  partnerName: "",
  income: false,
  currAmount: 0,
  invCurrency: "",
  amount: 0,
  currency: "",
  exchangeRate: 0,
  rateDate: "",
  status: "",
  partner: "",
  comment: "",
  annexNo: null,
  closeDate: "",
  minCloseDate: "",
  amoutText: "",
  user: "",
  insDate: "",
  insTime: "",
  selected: false,
};
