




























































import Vue from "vue";
import { feeTypes, priceModifyOptions, StoreIn } from "../models";

import IconButton from "../../../components/IconButton.vue";
import store from "../services/store";
import MoneyInput from "../../../components/MoneyInput.vue";
import configuration from "@/models/Configuration";


export default Vue.extend({
  components: { IconButton, MoneyInput },

  props: {
    storeIn: {
      type: Object as () => StoreIn,
    },
  },

  data: () => ({
    store,
    feeTypes,
    priceModifyOptions,
    configuration
  }),

  methods:{
    getGeniItemDigits(currency: string){
      if(configuration.operel) return 2;
      return configuration.currencyDigits.filter((x)=>x.currency===store.currency).length>0 ? configuration.currencyDigits.filter((x)=>x.currency===store.currency)[0].geniItemDigits : 0
    }
  }
});
