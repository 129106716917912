























































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import HeaderItem from "../../../components/HeaderItem.vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import VStepper from "../../invoiceFlow/components/VStepper.vue";
import VStepperStep from "../../invoiceFlow/components/VStepperStep.vue";
import { BankRemitStatusEnum } from "./BankRemitHeadView.vue";
import dayjs from "dayjs";

interface Data {
  store: BankRemitStore;
}

interface Methods {
  setStep(step: number): void;
  getRemit(): Promise<boolean>;
  getApproval(): Promise<boolean>;
}

interface Computed {
  importOrExportable: boolean;
  isApproved: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    Page,
    HeaderItem,
    VStepper,
    VStepperStep,
  },

  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    const id = Number(this.$route.params.id);

    await this.store.loadAllBankRemitAcceptorUser();

    if (id > 1) {
      await this.getRemit();
      await this.getApproval();
    }

    await this.store.loadBankRemitStatuses()
    await this.store.loadBankDefrTypes()

  },

  methods: {
    async getApproval(): Promise<boolean>{
      return await this.store.loadApproval(this.store.bankRemit.id).then(async () =>{
        return true;
      });
    }, 

    async getRemit(): Promise<boolean> {
      return await this.store.loadBankRemit(Number(this.$route.params.id)).then(()=>{
        return true;
      });
    },

    setStep(step: number) {
      if (this.store.step != step) {
        this.store.step = step;
        if (step == 3) {
          this.$router.push(`/bank-remits/create/${this.$route.params.id}/approval`);
        } else if (step == 2) {
          this.$router.push(`/bank-remits/create/${this.$route.params.id}/item`);
        } else if (step == 1) {
          this.$router.push(`/bank-remits/create/${this.$route.params.id}/head`);
        } else if (step == 4) {
          this.$router.push(`/bank-remits/create/${this.$route.params.id}/forward`);
        }
      }
    },
  },

  computed: {
    isApproved() {
      if (
        new Date(dayjs(this.store.bankRemitApproval.accDate).toDate()).getTime() >
        new Date("2000-01-01T00:00:00").getTime()
      ) {
        return true;
      }
      return false;
    },

    importOrExportable() {
      return (
        this.store.bankRemit.status == BankRemitStatusEnum.forwardable ||
        this.store.bankRemit.status == BankRemitStatusEnum.forwarded
      );
    },
  },
});
