export default interface InvoiceItemOtherDimension {
    id: number;
    invItem: number;
    projectId: number;
    projectName: string;
    projectCode: string;
    type: string;
    stationId: number;
    stationName: string;
    stationGroupId: number;
    stationGroupName: string;
    toolId: number;
    toolName: string;
    userId: number;
    userName: string;
    carId: number;
    carRegNum: string;
    carNet: number;
    stationNet: number;
    projectNet: number;
    invGroup: number;
    partId: number;
    partName: string;
    partNet: number;
    eleventhDimensionId: number;
    eleventhDimensionName: string;
    eleventhDimensionDimCode: string;
    eleventhDimensionNet: number;
    twelfthDimensionId: number;
    twelfthDimensionName: string;
    twelfthDimensionDimCode: string;
    twelfthDimensionNet: number;

    deleting: boolean;
    inEdit: boolean;
}

export const invoiceItemOtherDimensionDefaultProps: InvoiceItemOtherDimension = {
    id: 0,
    invItem: 0,
    projectId: 1,
    projectName: "-",
    projectCode: "-",
    type: "-",
    stationId: 1,
    stationName: "--",
    stationGroupId: 1,
    stationGroupName: "-",
    toolId: 1,
    toolName: "--",
    userId: 1,
    userName: "-",
    carId: 1,
    carRegNum: "-",
    carNet: 0,
    stationNet: 0,
    projectNet: 0,
    invGroup: 1,
    partId: 0,
    partName: "-",
    partNet: 0,
    eleventhDimensionId: 0,
    eleventhDimensionName: "",
    eleventhDimensionDimCode: "",
    eleventhDimensionNet: 0,
    twelfthDimensionId: 0,
    twelfthDimensionName: "",
    twelfthDimensionDimCode: "",
    twelfthDimensionNet: 0,

    deleting: false,
    inEdit: false,
}