


















import Vue from "vue";
import auth from "../services/auth";
import configuration from "../models/Configuration";
import AppHeader from "./AppHeader.vue";
import Localization from "./Localization.vue";
import ErrorHandler from "./ErrorHandler.vue";
import BusyIndicator from "./BusyIndicator.vue";
import ChatFloatingButton from "@/modules/chat/views/ChatFloatingButton.vue";

export default Vue.extend({
  components: {
    Localization,
    AppHeader,
    ErrorHandler,
    BusyIndicator,
    ChatFloatingButton,
  },

  data: () => ({
    configuration,
    auth,
  }),

  computed: {
    headerVisible() {
      return !configuration.operel && auth.isLoggedIn;
    },

    hostedInOperel() {
      return configuration.operel;
    },
  },
});
