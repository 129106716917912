

















































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, { AccountCodeEditModelStore } from "../services/AccountCodeEditModelStore";
import { accountCodeEditModelDefaultProps } from "../models/AccountCodeEditModel";
import { ValidationRules } from "../../../models/Validation";
import { rules } from "../models/AccountCodeEditModel";

interface Data {
  store: AccountCodeEditModelStore;
  rules: ValidationRules;
  valid: boolean;
}

const CREATE_TITLE = "Főkönyvi szám létrehozása";
const MODIFY_TITLE = "Főkönyvi szám módosítása";

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data() {
    return {
      store,
      rules,
      valid: true,
    };
  },

  computed: {
    title() {
      return this.store.editModel?.accountCodeId > 0 ? MODIFY_TITLE : CREATE_TITLE;
    },
  },

  async beforeMount() {
    const accountCodeId = Number(this.$route.params.accountCodeId);
    const columnDictionaryId = Number(this.$route.params.columnDictionaryId);

    if (accountCodeId && columnDictionaryId) {
      await this.store.loadAccountCodeEditModel(accountCodeId, columnDictionaryId);
    } else {
      this.store.editModel = { ...accountCodeEditModelDefaultProps };
    }
  },

  // methods: {
  //   async save() {
  //     await this.store.save();
  //     this.$router.push("/account-codes/editmodels");
  //   },
  // },
});
