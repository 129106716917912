




















import Vue from "vue";
import IconButton from "./IconButton.vue";

const noop = () => {
  console.log("noop");
};

export default Vue.extend({
  props: {
    slotClass: {
      type: String,
      default: "",
    },

    confirmationClass: {
      type: String,
      default: "",
    },

    confirmButtonClass: {
      type: String,
      default: "mx-2",
    },

    confirmText: {
      type: String,
      default: "Igen",
    },

    cancelText: {
      type: String,
      default: "Mégsem",
    },
  },

  data: () => ({
    visible: false,
    confirmationText: "",
    confirmed: noop,
    canceled: noop,
  }),

  components: {
    IconButton,
  },

  methods: {
    show(confirmationText: string) {
      this.confirmationText = confirmationText;
      this.visible = true;

      return new Promise((resolve) => {
        this.confirmed = () => resolve(true);
        this.canceled = () => resolve(false);
      });
    },

    confirm() {
      this.confirmed();
      this.$nextTick(() => (this.visible = false));
    },

    cancel() {
      this.canceled();
      this.$nextTick(() => (this.visible = false));
    },
  },
});
