














import Vue from 'vue'
import { SortingModel } from '@/services/filtering'

export default Vue.extend({
    props: {
        sorting: {
            type: Object as () => SortingModel
        }
    }
})
