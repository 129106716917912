








































































































































































































































































import Vue from "vue";
import { validate } from "../../../services/validators/accountNo";
import store, { PartnerStore } from "../services/PartnerStore";
import bankStore, { BankStore } from "@/modules/bank/services/BankStore";
import DetailsOfCharges from "@/modules/bank/models/DetailsOfCharges";
import PartBank, { partBankDefaultProps } from "../../cashPoint/models/PartBank";

import { NumericTextBox } from "@progress/kendo-vue-inputs";

interface Data {
  store: PartnerStore;
  bankStore: BankStore;
  bankAccFirst: string;
  bankAccSecond: string;
  bankAccThird: string;
  detailsOfCharges: DetailsOfCharges;

  rules: any;
  accountNoError: boolean;
}

interface Methods {
  validate(accountNo: string): void;
  findBank(currentField: string): void;
  jumpToNext(currItem: string): void;
  toCreateBank(): void;
  save(): void;
  cancel(): void;
  isNativeChanged(isNative: boolean): void;
}

interface Computed {
  form: Vue & { validate: () => boolean };
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    NumericTextBox,
  },

  props: {
    partnerId: { type: Number, default: 1 },
  },

  data: () => ({
    store,
    bankStore,
    bankAccFirst: "",
    bankAccSecond: "",
    bankAccThird: "",
    detailsOfCharges: new DetailsOfCharges(),

    rules: {
      required: (value: any) => !!value || "Kötelező",
      counter: (value: any) => value.length <= 8 || "Maximum 8 karakter",
    },
    accountNoError: false,
  }),

  async beforeMount() {
    await Promise.all([this.store.loadCurrencies(), this.store.loadBanks(), this.bankStore.loadDetailsOfCharges()]);
    this.store.partBank = { ...partBankDefaultProps };
    this.store.partBank.partner = this.store.partner.id;

    const partnerId = Number(this.$route.params.partnerId);
    if (partnerId > 0) this.store.partBank.partner = partnerId;
  },

  methods: {
    validate(accountNo: string) {
      validate(accountNo);
    },

    findBank(currentField: string) {
      if (currentField != "bankAccFirst") return;
      if (this.bankAccFirst.length < 3) return;

      const newBank = this.store.banks.find((x) => x.extCode == this.bankAccFirst.substring(0, 3));
      if (!newBank) return;

      this.store.partBank.bank = newBank.name;
    },

    isNativeChanged(isNative: boolean) {
      if (!isNative) return;

      this.detailsOfCharges = new DetailsOfCharges();
      this.store.partBank.detailsOfCharges = this.detailsOfCharges.id;
    },

    jumpToNext(currItem: string) {
      this.findBank(currItem);

      if (currItem == "bankAccFirst" && this.bankAccFirst.length == 8) {
        (this.$refs["bankAccSecond"] as any).focus();
      } else if (currItem == "bankAccSecond" && this.bankAccSecond.length == 8) {
        (this.$refs["bankAccThird"] as any).focus();
      }
    },

    async save() {
      if (!this.form.validate()) return;

      if (this.store.partBank.isNative) {
        if (this.bankAccThird.length == 0) {
          this.bankAccThird = "00000000";
        }

        this.store.partBank.accountNo = `${this.bankAccFirst}-${this.bankAccSecond}-${this.bankAccThird}`;
      }

      if (this.store.partBank.partner == 0) {
        this.store.partBank.partner = this.$props.partnerId;
      }

      this.accountNoError =
        this.store.partBank.accountNo.trim() === "" ||
        (this.store.partBank.isNative && !validate(this.store.partBank.accountNo));

      if (this.accountNoError) return;

      this.$emit("save");
      this.$emit("partBankCreating");
      await this.store.createPartBank();
      await this.store.loadPartBanks(this.store.partner.id);
    },

    cancel() {
      this.store.partBank = { ...partBankDefaultProps };
      this.$emit("cancel");
      this.$emit("partBankCreating");
    },

    toCreateBank() {
      console.log("tobank");
      this.$router.push("/banks/create");
      // this.$router.go(0);
    },
  },

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
  },
});
