



































import Vue from "vue";
import store, { CashStatementStore } from "../services/store";
import VStepper from "../../invoiceFlow/components/VStepper.vue";
import VStepperStep from "../../invoiceFlow/components/VStepperStep.vue";

interface Data {
  store: CashStatementStore;
}

interface Method{
    setStep(step: number): void;
}

export default Vue.extend<Data, Method, {}, {}>({
  components:{
    VStepper,
    VStepperStep
  },

  data: () => ({
    store,
  }),

  methods: {
    setStep(step){
        this.store.step = step;
    }
  }

});
