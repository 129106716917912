import http, { handleApiResponse, checkResponse } from '@/services/http'
import AccountVoucherType from "@/modules/accountVoucherType/models/AccountVoucherType"
import ApiResponse from '@/models/ApiResponse'

export default class AccountVoucherTypeApi {
    static getAll(): Promise<AccountVoucherType[]> {
        return http
            .get<ApiResponse<AccountVoucherType[]>>(`account-voucher-types`)
            .then(handleApiResponse)
    }

    static get(accountVoucherType: number): Promise<AccountVoucherType> {
        return http
            .get<ApiResponse<AccountVoucherType>>(`account-voucher-types/${accountVoucherType}`)
            .then(handleApiResponse)
    }

    static delete(id: number): Promise<boolean> {
        return http
            .delete(`account-voucher-types/${id}`)
            .then(checkResponse)
    }

    static update(source: AccountVoucherType): Promise<boolean> {
        return http
            .put(`account-voucher-types/${source.id}`, source)
            .then(checkResponse)
    }

    static create(accountVoucherType: AccountVoucherType): Promise<AccountVoucherType> {
        return http
            .post<ApiResponse<AccountVoucherType>>(`account-voucher-types`, accountVoucherType)
            .then(handleApiResponse)
    }
}