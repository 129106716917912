




















































































import DateInput from "@/components/DateInput.vue";
import Vue from "vue";
import store from "../services/store";
import InvoiceMigrationDate, {
  InvoiceMigrationDateProps,
} from "../models/InvoiceMigrationDate";
import Page from "@/components/Page.vue";
import eventBus from "@/services/eventBus";

export default Vue.extend({
  data() {
    return {
      dateRange: InvoiceMigrationDateProps,
      itemIds: Array<number>(),
      loading: false,
      hasResult: false,
      migrated: false
    };
  },


  methods: {
    async getMigrationIds() {
      this.migrated = false;
      this.loading = true;
      this.itemIds = await store.migrationItemIds(this.dateRange).then((x) => {
        this.loading = false;
        this.hasResult=true;
        return x;
      });
      console.log(this.itemIds);
    },

    async migrate(){
      const result = await store.MigrateInvoices(this.itemIds);
      if(result){
        eventBus.$emit("success","Számla sikeresen migrálva!")
        this.migrated = true;
      }
      else eventBus.$emit("error","A számla migrálása sikertelen volt!")
    }
  },

  components: {
    DateInput,
    Page,
  },
});
