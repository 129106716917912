import Vue from "vue";

/*
 * Usage:
 * <div v-sticky="48"> ... </div>
 */

export default Vue.directive("sticky", {
  bind: function(el, binding) {
    el.style.top = (binding.value > 0 ? binding.value : 48) + "px";
    el.style.position = "sticky";
    el.style.zIndex = "5";
    el.classList.add("sticky-header");
  },
});
