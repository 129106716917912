

























































































































































































































































































































































































































































































































































// Models
import Vue from "vue";
import Page from "@/components/Page.vue";
import eventBus from "@/services/eventBus";
import store, { CashStatementStore } from "../services/store";
import pStore, { PartnerStore } from "@/modules/partner/services/PartnerStore";
import invoiceBgViewStore, {
  InvoiceBgViewStore,
} from "@/modules/invoiceBgView/services/InvoiceBgViewStore";
import User, { userDefaultProps } from "@/modules/invoiceGroup/models/User";
import CashStatementDenom from "../models/CashStatementDenom";
import GeneralDenom from "@/modules/denomination/models/GeneralDenom";
import experimentalInvoiceStore, {
  ExperimentalInvoiceStore,
} from "../receipt/experimentInvoice/services/store";

// Components
import CashDocumentCreatePage from "./CashDocumentCreatePage.vue";
import InvoiceBgView from "../../invoiceBgView/views/InvoiceBgView.vue";
import DenominationTable from "../../denomination/views/DenominationTable.vue";
import CashDocuments from "./CashDocuments.vue";
import MenuButton from "@/components/MenuButton.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import IconButton from "@/components/IconButton.vue";
import PdfViewer from "@/components/PdfViewer.vue";
import ExperimentalInvoiceReportViewer from "@/modules/cashStatement/receipt/experimentInvoice/components/ExperimentalInvoiceReportViewer.vue";

import configuration, { AppConfiguration } from "@/models/Configuration";
import http from "@/services/http";
import ApiResponse from "@/models/ApiResponse";

interface Data {
  creatingDocument: boolean;
  importingGENI: boolean;
  importingGENO: boolean;
  store: CashStatementStore;
  pStore: PartnerStore;
  experimentalInvoiceStore: ExperimentalInvoiceStore;
  invoiceBgViewStore: InvoiceBgViewStore;
  selectedCashier: User | undefined;
  openingCashPoint: boolean;
  openingAmount: number;
  showingQuestions: boolean;
  showInvoicesBgViewQuestion: boolean;
  showInvoicesBgView: boolean;
  showDenomPrintingQuestion: boolean;
  showDenomPrinting: boolean;
  valid: boolean;
  configuration: AppConfiguration;
  printing: boolean;

  showDenomReport: boolean;
  denomReportuInt8Arr: Uint8Array;
  showSecondary: boolean;
  showSummary: boolean;
}

interface Methods {
  creatingDocumentChanged(value: number | void): void;
  checkBeforeSave(): boolean;
  selectedCashierChanged(): void;
  openCashPoint(): void;
  closeValidations(): Promise<boolean>;
  closeStatement(): void;
  printDenoms(denoms: GeneralDenom[]): void;
  close(): void;
  dispatch(): void;
  sendToLedger(): void;
  save(back?: boolean): void;
  cancel(): void;
  setWrong(): void;
  exitStatementBalanceReport(): void;
  exitDenomReport(): void;
}

interface Computed {
  isFirstStatement: boolean;
  extractDateTime: string;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    Page,
    CashDocumentCreatePage,
    InvoiceBgView,
    DenominationTable,
    CashDocuments,
    MenuButton,
    MoneyInput,
    IconButton,
    PdfViewer,
    ExperimentalInvoiceReportViewer,
    // InvoicePicker,
  },

  data: () => ({
    creatingDocument: false,
    importingGENI: false,
    importingGENO: false,
    store,
    pStore,
    invoiceBgViewStore,
    experimentalInvoiceStore,
    selectedCashier: { ...userDefaultProps },
    openingCashPoint: false,
    openingAmount: 0,
    showingQuestions: false,
    showInvoicesBgViewQuestion: false,
    showInvoicesBgView: false,
    showDenomPrintingQuestion: false,
    showDenomPrinting: false,
    valid: true,

    configuration,
    printing: false,

    denomReportuInt8Arr: new Uint8Array(),
    showDenomReport: false,
    showSecondary: true,
    showSummary: false,
  }),

  async beforeMount() {
    this.selectedCashier =
      this.store.cashiers.find(
        (x) => x.id === this.store.cashStatement.cashier
      ) || this.store.cashiers[0];

    const isHUF =
      this.store.cashStatement.currency.toUpperCase().trim() === "HUF";
    this.store.isDifferentCurrency = isHUF ? false : true;

    this.store.setIsStatementCashierTheCurrentUser();
    this.store.setIsStatementStatusWIP();
    this.store.setIsOwnIdentifierReadOnly();
    this.store.setIsOpenCashPointReadOnly();

    this.showSecondary = !this.store.isFullPage;

    if (this.store.cashPointStatements.length < 1) {
      await this.store.loadCashStatementsByCashPoint(
        this.store.cashStatement.cashPoint
      );
    }
  },

  methods: {
    exitDenomReport() {
      this.denomReportuInt8Arr = new Uint8Array();
    },

    exitStatementBalanceReport() {
      store.statementBalanceReportSrc = new Uint8Array();
    },

    async setWrong() {
      await this.store.setCashStatementWrong().then(() => {
        this.$router.push(`/cash-statement`);
      });
    },

    async sendToLedger() {
      const result = await this.store.sendStatementToLedger();
      if (result) {
        eventBus.$emit("success", "Sikeresen feladva!");
        this.store.cashStatement.accStatus = 6;
      } else {
        this.store.cashStatement.accStatus = 7;
      }
    },

    async creatingDocumentChanged(value) {
      this.creatingDocument = false;
      if (Number(value) <= 0 || !value) return;

      const cashStatementId = Number(this.$route.params.cashStatementId);
      await this.store.loadCashDocumentsByStatementId(cashStatementId);
      this.store.setIsOwnIdentifierReadOnly();
      this.store.setIsOpenCashPointReadOnly();

      this.$router.push(`${cashStatementId}/${value}`);
    },

    checkBeforeSave() {
      if (
        this.store.cashStatement.extractDateTime == null ||
        this.store.cashStatement.extractDateTime == ""
      )
        return false;
      return true;
    },

    selectedCashierChanged() {
      this.store.cashStatement.cashier = this.selectedCashier?.id ?? 1;
    },

    async openCashPoint() {
      if (this.openingAmount <= 0) {
        eventBus.$emit(
          "error",
          "Nem lehet nulla, vagy negatív a nyitó egyenleg!"
        );
        return;
      }

      await this.store.openCashPoint(this.openingAmount).then(async () => {
        this.openingCashPoint = false;
        this.store.cashPoint.inDocNo++;
        this.store.cashStatement.closeBal = this.openingAmount;

        const cashStatementId = Number(this.$route.params.cashStatementId);
        await this.store.loadCashDocumentsByStatementId(cashStatementId);

        this.store.setIsOwnIdentifierReadOnly();
        this.store.setIsOpenCashPointReadOnly(true);
      });

      // TODO: nyomtatás
    },

    async closeValidations() {
      const isClosable = await this.store.checkStatementClosable();
      if(!isClosable) return false;

      if (
        this.store.sysFile.isCashAccitem &&
        (!this.store.cashPoint.accCode ||
          ["", "-", "--"].includes(this.store.cashPoint.accCode))
      ) {
        eventBus.$emit(
          "error",
          "A pénztárnak nincs kitöltve a főkönyvi száma!"
        );
        return false;
      }

      if (
        this.store.cashDocuments.some((x) => [2, 3].includes(x.document.status))
      ) {
        eventBus.$emit("error", "A kivonatnak még vannak nyitott bizonylatai!");
        return false;
      }

      if (
        !this.store.cashPoint.isNegative &&
        (this.store.cashStatement.openBal < 0 ||
          this.store.cashStatement.closeBal < 0)
      ) {
        eventBus.$emit(
          "error",
          "Nem lehet lezárni, mert negatív egyenlege van!"
        );
        return false;
      }

      return true;
    },

    async closeStatement() {
      if (this.showingQuestions) {
        this.showingQuestions = false;
        this.showInvoicesBgViewQuestion = false;
        this.showInvoicesBgView = false;
        this.showDenomPrintingQuestion = false;
        this.showDenomPrinting = false;
        return;
      }

      if (!(await this.closeValidations())) return;

      const any = await this.invoiceBgViewStore.loadInvoicesBgViewAny();
      this.showingQuestions = true;

      if (any) {
        this.showInvoicesBgViewQuestion = true;
      } else {
        this.showDenomPrintingQuestion = true;
      }

      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    },

    async printDenoms(denoms: GeneralDenom[]) {
      const statementDenoms: CashStatementDenom[] = [];
      denoms.forEach((denom) => {
        statementDenoms.push({
          id: 0,
          denom: denom.id,
          cashAccount: this.store.cashStatement.id,
          quantity: denom.quantity,
        });
      });

      // TODO: nyomtatás

      await this.store.createStatementDenoms(statementDenoms);
      /* const link = http.defaults.baseURL + `cash-statement/${this.store.cashStatement.id}/print-denoms`;
      window.open(link) */

      try {
        this.printing = true;
        this.showingQuestions = false;
        this.showDenomPrinting = false;
        this.denomReportuInt8Arr = await this.store.printStatementDenom();
      } catch (ex) {
        console.log(ex);
        eventBus.$emit("error", ex);
      } finally {
        this.printing = false;
      }
      // ...

      this.close();
    },

    async close() {
      this.showingQuestions = false;
      this.showDenomPrintingQuestion = false;
      this.denomReportuInt8Arr = new Uint8Array();

      // TODO: nyomtatás

      this.store.cashStatement.status = 3;

      await this.store.update(false);

      // TODO: RELOAD DTO:
      /* location.reload(); */
      this.store.setIsStatementStatusWIP();
      this.store.setIsOwnIdentifierReadOnly();
      this.store.setIsOpenCashPointReadOnly();
      await this.store.loadCashStatement(this.store.cashStatement.id);
      await this.store.loadCashDocumentsByStatementId(
        this.store.cashStatement.id
      );

      // ...

      // TODO: acc_status -> update / checks
      // this.store.cashStatement.accStatus = 5;
      try {
        this.printing = true;
        await this.store.setStatementBalanceReport();
      } catch (ex) {
        console.log(ex);
        eventBus.$emit("error", 'Sikertelen riport generálás.');
      } finally {
        this.printing = false;
      }
    },

    async dispatch() {
      this.store.cashStatement.accStatus = 3;
      // TODO: RELOAD DTO:
      // TODO: könyvelési állapot feladható, stb.
      await this.store.update();
    },

    async save(back = false) {
      this.valid = this.checkBeforeSave();
      if (!this.valid) return;
      await this.store.update();
      this.store.setIsStatementCashierTheCurrentUser();
      if (back) this.$router.push(`/cash-statement`);
    },

    cancel() {
      this.$router.push(`/cash-statement`);
    },
  },

  watch: {
    "store.isFullPage": function(value: boolean) {
      if (!this.store.isFullPage) {
        setTimeout(() => {
          this.showSecondary = !this.store.isFullPage;
        }, 200);
      } else this.showSecondary = !this.store.isFullPage;
    },
  },

  computed: {
    isFirstStatement() {
      const sorted = this.store.cashPointStatements;
      sorted.sort((x, y) => x.id - y.id);
      return sorted[0].id == this.store.cashStatement.cashPoint;
    },

    extractDateTime() {
      return new Date(this.store.cashStatement.extractDateTime).toLocaleString(
        "hu"
      );
    },
  },
});
