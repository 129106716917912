









































import Vue from "vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import IncomingInvoiceAccItem from "../../models/IncomingInvoiceAccItem";
import eventBus from "@/services/eventBus";

export default Vue.extend({
  components: {
    MoneyDisplay,
  },

  data: () => ({
    groupByAccCode: false,
  }),

  props: {
    items: {
      type: Array as () => IncomingInvoiceAccItem[],
    },
  },

  beforeMount() {
    eventBus.$on(
      "FlowAccountingPage:ledgerSummaryView:changed",
      this.groupingChanged
    );
  },

  beforeDestroy() {
    eventBus.$off(
      "FlowAccountingPage:ledgerSummaryView:changed",
      this.groupingChanged
    );
  },

  methods: {
    groupingChanged(ledgerSummaryView: boolean) {
      this.groupByAccCode = ledgerSummaryView;
    },
  },

  computed: {
    groupColumnHeader() {
      return this.groupByAccCode ? "Főkönyvi szám" : "Főkönyvi tétel típus";
    },

    tableItems() {
      const grouper = (item: IncomingInvoiceAccItem) =>
        this.groupByAccCode
          ? item.accCodeDemand || item.accCode
          : item.detailedType;

      const items = [...this.items];
      items.sort((a, b) => grouper(a).localeCompare(grouper(b)));

      const groups = [...new Set(this.items.map(grouper))];
      const result = [];

      for (const g of groups) {
        const summary = {
          group: g,
          income: items
            .filter((i) => grouper(i) === g)
            .map((i) => i?.total ?? 0.00)
            .reduce((acc, i) => i + acc, 0.00),

          demand: items
            .filter((i) => grouper(i) === g)
            .map((i) => i?.totalDemand ?? 0.00)
            .reduce((acc, i) => i + acc, 0.00),
        };

        result.push(summary);
      }

      result.sort((a, _) => (a.income == 0 ? 1 : 0));

      const sumItem = {
        group: "Összesen",
        income: Number(result.reduce((acc, i) => acc + i.income, 0.00).toFixed(2)),
        demand: Number(result.reduce((acc, i) => acc + i.demand, 0.00).toFixed(2)),
        mismatch: false,
      };
      console.log(sumItem.income)
      console.log(sumItem.demand)
      sumItem.mismatch = sumItem.income !== sumItem.demand;

      result.push(sumItem);
      return result;
    },
  },
});
