var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-6"},[_c('v-card-title',[_c('v-row',[_c('h3',{staticStyle:{"font-size":"15px"}},[_vm._v("Pénzügyi dokumentumok")])])],1),_c('v-card-text',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"sm":"4","align":"right"}},[_c('label',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.financialDocumentNameString))])]),_c('v-col',{staticClass:"pr-0",attrs:{"sm":"3"}},[_c('v-file-input',{ref:"newFile",attrs:{"dense":"","outlined":"","hide-details":"auto","clear-icon":"fa-times","prepend-icon":"","accept":"image/jpeg,image/png,application/pdf,text/plain","id":"newFile","truncate-length":"50"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('label',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-plus ")])]},proxy:true}])})],1),_c('v-col',{staticClass:"pl-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":""},on:{"click":_vm.uploadNewFile}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-upload ")])],1)]}}])},[_c('span',[_vm._v("Fájl feltöltése")])])],1)],1)],1),_vm._l((_vm.relStore.financialDocumentRelations),function(relation){return _c('div',{key:relation.id,staticClass:"mx-3 mb-2"},[[_c('v-icon',{staticStyle:{"vertical-align":"baseline"},attrs:{"color":"var(--primaryColor)","x-small":""}},[_vm._v("fa-check ")])],_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"title":'Dokumentum letöltése'},on:{"click":function($event){return _vm.showFile(relation)}}},[_vm._v(" "+_vm._s(relation.typeName)+" ")]),(relation.uploading)?_c('div',{staticClass:"px-2",staticStyle:{"background-color":"#f5f5f5"}},[_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(relation.fileName != ''),expression:"relation.fileName != ''"}]},[_c('v-expand-transition',[_c('label',[_vm._v(" "+_vm._s(relation.fileName)+" ")])])],1)]),_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-right":"-1px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.downloadFile(relation.link)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-download ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Fájl letöltése ")])])],1),_c('v-col',[_c('v-file-input',{ref:"file",refInFor:true,attrs:{"dense":"","outlined":"","hide-details":"auto","clear-icon":"fa-times","prepend-icon":"","accept":"image/jpeg,image/png,application/pdf,text/plain","id":"file"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('label',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-plus ")])]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.uploadFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl feltöltése")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.deleteFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-trash-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl törlése")])])],1)],1)],1):_vm._e()],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }