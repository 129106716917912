// @selector: string, pl. .my-class
// @name: string, attribútum neve
// @value: string, attribútum értéke

export default function setAttribute(selector: string, name: string, value: string) {
  const el = document.querySelectorAll(selector);
  if (el) {
    el.forEach(element => {
      element.setAttribute(name, value);
    });
  }
}

export function setAcceptorsViewAttribute(selector: string, name: string, value: string) {
  const el = document.querySelectorAll(selector);
  if (el) {
    el.forEach((element: any) => {
      if (element.colSpan == 3) {
        element.setAttribute(name, value);
      }
    });
  }
}
