import eventBus from "@/services/eventBus";
import flowStore from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import TaxAndVatItem from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";
import { Stock, InvoiceRelationType, Unit } from "./../../invoiceItems/models/index";
import busyIndication from "@/utils/busyIndication";
import UninvoicedStockCorrectionApi from "./api";
import { InvoiceItem, StoreIn } from "@/modules/invoiceItems/models";
import UninvoicedStockCorrection from "../models/UninvoicedStockCorrection";
import MainCode from "../models/MainCode";
import { roundMoney } from "@/services/prices";

export class UninvoicedStockCorrectionStore {
  invoices: UninvoicedStockCorrection[] = [];
  items: InvoiceItem[] = [];
  storeIns: StoreIn[] = [];
  mainCode = new MainCode();

  reset() {
    this.storeIns = [];
    this.mainCode = new MainCode();
  }

  @busyIndication()
  async loadAll() {
    this.invoices = await UninvoicedStockCorrectionApi.getAll();
  }

  @busyIndication()
  async loadAllStoreIns(searchText: string, start = "", end = "") {
    this.storeIns = await UninvoicedStockCorrectionApi.getAllStoreIns(searchText, start, end);
  }

  @busyIndication()
  async loadStoreIns(supplierID: number, searchText: string, start = "", end = "") {
    this.storeIns = await UninvoicedStockCorrectionApi.getStoreIns(supplierID, searchText, start, end);
  }

  async loadMainCode(invoiceID: number, partnerID: number) {
    if (invoiceID <= 1 && partnerID <= 1) {
      this.mainCode = new MainCode();
      return;
    }
    await UninvoicedStockCorrectionApi.getMainCode(invoiceID, partnerID).then(
      (result) => (this.mainCode = new MainCode(result))
    );
  }

  async saveMainCode(invoiceID: number, partnerID: number, subtypeID: number, mainCode: MainCode) {
    this.mainCode.id = await UninvoicedStockCorrectionApi.createMainCode(invoiceID, partnerID, subtypeID, mainCode);
  }

  /**
   * Invoice Items
   */
  units: Unit[] = [];
  taxAndVatItems: TaxAndVatItem[] = [];

  private findTax(taxID: number): TaxAndVatItem {
    const taxes = this.taxAndVatItems;
    const useSpecialTax = flowStore.invoice && flowStore.invoice.specTax && flowStore.invoice.specTaxType > 1;
    const specTaxID = useSpecialTax
      ? flowStore.specTaxes.find((st) => st.id === flowStore.invoice.specTaxType)?.tax
      : undefined;

    return (
      taxes.find((t) => t.id === specTaxID) ??
      taxes.find((t) => t.id === taxID) ??
      taxes.find((t) => t.degree === 27 && t.vatItemID === 1) ??
      taxes[0]
    );
  }

  addStoreIn = (storeIn: StoreIn) => {
    storeIn.stocks.forEach((s) => this.addStock(s, s.quantity, storeIn.note));
  };

  addStock = (stock: Stock, quantity: number, note: string) => {
    const relation = {
      id: 0,
      invoiceID: flowStore.invoice.id,
      invoiceItemID: 1,
      type: InvoiceRelationType.Stock,
      relatedItem: { ...stock },
      relatedItemID: stock.stockID,
      quantity,
      selectedForDivision: true,
    };

    const itemPrice = roundMoney(stock.unitPrice * relation.quantity, flowStore.invoice.invCurrency);

    const invoiceItem: Partial<InvoiceItem> = {
      productName: stock.productName,
      productID: stock.productID,
      quantity: quantity,
      description: note,
      currency: flowStore.invoice.invCurrency,
      nettoValue: itemPrice,
      unit: this.units.find((u) => u.id === stock.unitID) ?? this.units[0],
      tax: this.findTax(stock.vatID),
      relations: [relation],
    };

    const newItem = new InvoiceItem(invoiceItem);
    this.items = [...this.items, newItem];
    eventBus.$emit("uninvoiced-stock-correction-item:add", stock);
  };
}

const store = new UninvoicedStockCorrectionStore();
export default store;
