





































import Vue from "vue";

import { CreateInvoicePaymentInput } from "../models/InvoicePayment";
import GeneralIngoinInvoiceDisplay from "../models/GeneralIngoinInvoiceDisplay";

import TextInput from "../../../components/TextInput.vue";
import MoneyInput from "../../../components/MoneyInput.vue";
import DateInput from "../../../components/DateInput.vue";
import IconButton from "../../../components/IconButton.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  payment: CreateInvoicePaymentInput | null;

  configuration: AppConfiguration;
}

interface Methods {
  submit(): void;
  reset(): void;
  cancel(): void;
  getGeniDigits(currency: string): number;
}

interface Computed {
  isValid: boolean;
}

const validate = (payment: CreateInvoicePaymentInput) => {
  return payment.amount !== 0 && !!payment.paymentNo && payment.invoiceID > 1;
};

interface Props {
  invoice: GeneralIngoinInvoiceDisplay;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    TextInput,
    MoneyInput,
    DateInput,
    IconButton,
  },

  props: {
    invoice: {
      type: Object as () => GeneralIngoinInvoiceDisplay,
    },
  },

  data: () => ({
    payment: null,
    configuration,
  }),

  beforeMount() {
    this.reset();
  },

  computed: {
    isValid() {
      return !!this.payment && validate(this.payment);
    },
  },

  methods: {
    getGeniDigits(currency) {
      return configuration.operel
        ? 2
        : configuration.getCurrDigits(currency) !== undefined
        ? configuration.getCurrDigits(currency)?.geniDigits ?? 2
        : 2;
    },

    submit() {
      if (this.payment) {
        this.$emit("submit", { ...this.payment });
        this.reset();
      }
    },

    cancel() {
      this.reset();
      this.$emit("cancel");
    },

    reset() {
      const { incomingInvoice: invoice }: GeneralIngoinInvoiceDisplay = this.invoice;
      const payment: CreateInvoicePaymentInput = {
        invoiceID: invoice.id,
        paymentDate: new Date(),
        paymentNo: "",
        amount: this.invoice.openPrice,
      };

      this.payment = payment;
    },
  },
});
