



































































































































































import MoneyDisplay from "@/components/MoneyDisplay.vue";
import Paginator from "@/components/Paginator.vue";
import { formatDate, formatTime } from "@/utils/formatDates";
import Vue from "vue";
import CashDocumentDisplay, { cashDocumentDisplayDefaultProps } from "../models/CashDocumentDisplay";
import Pager from "../../../models/Pager";

interface Data {
  activeTab: number;
  tabButtons: object;
  selectedDocument: CashDocumentDisplay;
  baseSizes: number[];
  pager: Pager;
}

interface Methods {
  setTab(activeTab: number): void;
  pagerChanged(pager: Pager): void;
}

interface Computed {
  columns: object;
  items: object[];
  insDateTime: string;
  rateDate: string;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: { Paginator, MoneyDisplay },

  props: { document: {} },

  data: () => ({
    activeTab: 0,
    tabButtons: [
      { id: 0, name: "Információk" },
      { id: 1, name: "Tételek" },
    ],
    selectedDocument: { ...cashDocumentDisplayDefaultProps },
    baseSizes: [5, 10, 15, 20, 25],
    pager: new Pager(),
  }),

  async beforeMount() {
    this.selectedDocument = this.$props.document ?? { ...cashDocumentDisplayDefaultProps };
    this.selectedDocument.cashDocItems.sort((a, b) => a.id - b.id);

    this.pager = new Pager({ list: this.items });
  },

  methods: {
    setTab(newTab: number) {
      this.activeTab = newTab;

      console.log(
        this.selectedDocument.id,
        ` DocumentDetails setTab(${newTab}): selectedDocument:`,
        this.selectedDocument
      );

      if (newTab === 0) return;
    },

    pagerChanged(pager) {
      this.pager = pager;
    },
  },

  computed: {
    columns: () => [
      { field: "cashProcType", title: "Mozgás típus" },
      { field: "cashSubType", title: "Mozgás altípus" },
      { field: "invPrefix", title: "Prefix" },
      { field: "invNo", title: "Számlaszám" },
      {
        field: "amount",
        title: "Összeg",
        cell: "amountTemplate",
        className: "right_text",
        headerClassName: "right_text",
      },
      { field: "note", title: "Megjegyzés" },
    ],

    items() {
      return this.selectedDocument.cashDocItems;
    },

    insDateTime() {
      return (
        formatDate(new Date(this.selectedDocument.insDate)) + " " + formatTime(new Date(this.selectedDocument.insTime))
      );
    },

    rateDate() {
      return formatDate(new Date(this.selectedDocument.rateDate));
    },
  },
});
