import ApiResponse from "@/models/ApiResponse";
import axios, { AxiosRequestConfig, AxiosResponse, Cancel } from "axios";
import eventBus from "./eventBus";

export const baseURL = process.env.VUE_APP_API_URL || "http://localhost:3000/";

const http = axios.create({
  baseURL: baseURL,
});

export function setConnection(connectionID: string) {
  window.localStorage.setItem("trufinance.connectionID", connectionID);
  http.defaults.headers["X-TruFinance-ConnectionID"] = connectionID;
  http.defaults.headers["TruFinance-Version"] = process.env.VUE_APP_VERSION;
}

const connectionID = window.localStorage.getItem("trufinance.connectionID");

if (connectionID) {
  setConnection(connectionID);
}

export function catchCancelRequest(reason: Cancel | any) {
  if (axios.isCancel(reason)) {
    console.log("Request Canceled:", { message: reason.message });
  }
}

export function handleApiResponse<T>(response: AxiosResponse<ApiResponse<T>>) {
  return new Promise<T>((resolve, reject) => {
    if (!response.data) {
      eventBus.$emit("response-unavailable", response.statusText);
      reject(`Response unavailable - status: ${response.statusText}`);
    }

    const { error, data } = response.data;

    if (error) {
      eventBus.$emit("error", error);
      reject(`Server error: ${error}`);
    }

    resolve(data);
  });
}

export function checkResponse(response: AxiosResponse<ApiResponse<{}>>): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (!response.data) {
      eventBus.$emit("response-unavailable", response.statusText);
      reject(`Response unavailable - status: ${response.statusText}`);
    }

    const { error } = response.data;

    if (error) {
      eventBus.$emit("error", error);
      reject(`Server error: ${error}`);
    }

    resolve(true);
  });
}

export function fetchData<T>(url: string, config: AxiosRequestConfig | undefined = undefined): Promise<T> {
  return http.get(url, config).then((res) => handleApiResponse(res));
}

export function postData<T>(url: string, data: T): Promise<boolean> {
  return http.post(url, data).then(checkResponse);
}

export default http;
