






















































// Models
import Vue from "vue";
import store, { InvoiceFlowStore } from "../../services/InvoiceFlowStore";
import { IncomingInvoiceTimelineDescriptionItem } from "@/modules/actionLog/models";

// Components
import Fragment from "@/components/Fragment.vue";

interface Data {
  store: InvoiceFlowStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { Fragment },

  data: () => ({
    store,
  }),

  async beforeMount() {
    const invoiceID = Number(this.$route.params.invoiceId);
    await Promise.all([
      this.store.loadFlowHistory(invoiceID),
      this.store.loadRemarks(invoiceID),
      this.store.loadFlowActionLogs(invoiceID),
    ]);
  },

  methods: {
    isDescriptionString(description: string | IncomingInvoiceTimelineDescriptionItem[]) {
      return typeof description === String.name.toLowerCase();
    },
  },

  computed: {
    items() {
      return [...this.store.flowHistory, ...this.store.flowRemarks, ...this.store.actionLogs].sort((a, b) =>
        b.timestamp.isAfter(a.timestamp) ? 1 : -1
      );
    },
  },
});
