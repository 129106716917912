








































































import Vue from "vue";
import store, { GeneralIngoingInvoiceStore } from "../services/GeneralIngoingInvoiceStore";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import configuration from "../../../models/Configuration";
import GeneralIngoingInvoice from "../models/GeneralIngoinInvoice";

interface Data {
  store: GeneralIngoingInvoiceStore;

  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;
}

interface Methods {
  redirectToInvoce(item: GeneralIngoingInvoice): void;

  sortChange(e: { sort: SortDescriptor[] }): void;
  filterChange(e: { filter: CompositeFilterDescriptor }): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    MoneyDisplay,
  },

  data() {
    return {
      store,

      sort: [],
      filter: {
        logic: "and",
        filters: [],
      },
    };
  },

  async beforeMount() {
    await this.store.loadFullfilmentTolerances();
  },

  methods: {
    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      this.filter = e.filter;
    },

    redirectToInvoce(item: GeneralIngoingInvoice) {
      const path = configuration.invoiceFlow.enabled
        ? `/general-incoming-invoice/flow/edit/${item.id}/4`
        : `/general-incoming-invoice/edit/${item.partner}/${item.id}`;

      this.$router.push(path);
    },
  },

  computed: {
    items() {
      return orderBy(filterBy(this.store.fullfilmentTolerances, this.filter), this.sort);
    },

    columns: () => [
      {
        field: "incomingInvoice.id",
        title: "GENI",
        cell: "geniTemplate",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "incomingInvoice.invoiceNo",
        title: "Bizonylat szám",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "partnerName",
        title: "Partner",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "incomingInvoice.invoiceDate",
        type: "date",
        format: "{0: yyyy-MM-dd}",
        title: "Számla dátuma",
        headerClassName: "center_text adjust_center",
        className: "center_text adjust_center",
      },
      {
        field: "incomingInvoice.currTotal",
        title: "Bruttó",
        cell: "currTotalTemplate",
        headerClassName: "right_text adjust_right",
        className: "right_text adjust_right",
      },
      {
        field: "incomingInvoice.currNetto",
        title: "Nettó",
        cell: "currNettoTemplate",
        headerClassName: "right_text adjust_right",
        className: "right_text adjust_right",
      },
      {
        field: "incomingInvoice.currVat",
        title: "Áfa",
        cell: "currVatTemplate",
        headerClassName: "right_text adjust_right",
        className: "right_text adjust_right",
      },
      {
        width: 0,
        filterable: false,
      },
    ],
  },
});
