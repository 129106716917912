








import Vue from 'vue'
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-vue-intl'

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/hu/numbers.json';
import currencies from 'cldr-numbers-full/main/hu/currencies.json';
import caGregorian from 'cldr-dates-full/main/hu/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/hu/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/hu/timeZoneNames.json';

const messages = {
    "datepicker": {
        "toggleCalendar": "Naptár ki/bekapcsolása"
    },
    "calendar": {
        "today": "Ma"
    },
    "dateinput": {
        "increment": "Növelés",
        "decrement": "Csökkentés"
    },
    "numerictextbox": {
        "increment": "Növelés",
        "decrement": "Csökkentés"
    },
    "grid": {
        "groupPanelEmpty": "Csoportosításhoz fogd meg és dobd ide az oszlop fejlécét",
        "noRecords": "Nincs adat",
        "pagerFirstPage": "Első oldal",
        "pagerPreviousPage": "Előző oldal",
        "pagerNextPage": "Következő oldal",
        "pagerLastPage": "Utolsó oldal",
        "pagerPage": "Oldal",
        "pagerOf": "Össz",
        "pagerItems": "Elemek",
        "pagerInfo": "{0} - {1} {2}",
        "pagerItemsPerPage": "Elem / oldal",
        "sortAscending": "Növekvő rendezés",
        "sortDescending": "Csökkenő rendezés",
        "filterEqOperator": "Egyenlő",
        "filterNotEqOperator": "Nem egyenlő",
        "filterIsNullOperator": "Null",
        "filterIsNotNullOperator": "Nem null",
        "filterIsEmptyOperator": "Üres",
        "filterIsNotEmptyOperator": "Nem üres",
        "filterStartsWithOperator": "Kezdődik",
        "filterContainsOperator": "Tartalmazza",
        "filterNotContainsOperator": "Nem tartalmazza",
        "filterEndsWithOperator": "Végződik",
        "filterGteOperator": "Nagyobb egyenlő",
        "filterGtOperator": "Nagyobb",
        "filterLteOperator": "Kisebb egyenlő",
        "filterLtOperator": "Kisebb",
        "filterIsTrue": "Igaz",
        "filterIsFalse": "Hamis",
        "filterBooleanAll": "Összes",
        "filterAfterOrEqualOperator": "Előző vagy egyenlő",
        "filterAfterOperator": "Következő vagy egyenlő",
        "filterBeforeOperator": "Előző",
        "filterBeforeOrEqualOperator": "Előző vagy egyenlő",
        "filterFilterButton": "Szűrés",
        "filterClearButton": "Szűrés törlése",
        "filterAndLogic": "ÉS",
        "filterOrLogic": "VAGY"
    }
};

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
)

loadMessages(messages, 'hu-HU')

export default Vue.extend({
    components: {
        'localization': LocalizationProvider,
        'intl': IntlProvider
    }
})
