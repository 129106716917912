import { Prices, roundMoney, PrecisionType } from "@/services/prices";
import configuration, { loadConfiguration } from "@/models/Configuration";
import {
  InvoiceItemType,
  RoundingItem,
  Unit,
} from "@/modules/invoiceItems/models";
import Tax from "@/modules/generalIngoingInvoice/models/Tax";
import dayjs from "dayjs";
import Attachment from "@/modules/generalIngoingInvoice/models/Attachment";
import TaxAndVatItem, { TaxAndVatItemItemDefaultProps } from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";

/** Előlegbekérő előjegyzés */
export class PreDownPayment {
  id = 0;
  invoiceNo = 1;
  orderName = "";
  productName = "";
  currency = "HUF";
  amount = 0;

  order: DownPaymentExtOrder | null = null;
  orderItems: DownPaymentExtOrderItem[] = [];
  expanded = false;
  typeName = "";
  type = 0;
  percent = 0;
  netto = 0;

  selected = false;

  constructor(params?: Partial<PreDownPayment>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

export class PreDownPaymentList {
  items: PreDownPayment[] = [];

  initialize(items: PreDownPayment[]) {
    this.items = items.map((x) => ({
      ...x,
      selected: false,
    }));
  }
}

export interface DownPaymentItemData {
  id: number;
  invoiceID: number;
  productID: number;
  gpartsID: number;
  isFreeWordItem: boolean;
  productName: string;
  serviceName: string;
  currency: string;
  exchangeRate: number;
  netto: number;
  brutto: number;
  vat: number;
  quantity: number;
  tax: Tax | undefined;
  unitPrice: number;
  unit: Unit | undefined;
  isRoundingItem: boolean;
  vatItem: number;
  taxAndVatItem: TaxAndVatItem;
}

/** Előlegbekérő számla tétel */
export class DownPaymentItem implements DownPaymentItemData {
  id = 0;
  invoiceID = 1;
  productID = 1;
  gpartsID = 1;
  isFreeWordItem = true;
  productName = "";
  serviceName = "";
  currency = "";
  exchangeRate = 1;
  netto = 0;
  quantity = 1;
  tax: Tax | undefined;
  unit: Unit | undefined;
  isRoundingItem = false;
  brutto = 0;
  vat = 0;
  taxAndVatItem: TaxAndVatItem = { ...TaxAndVatItemItemDefaultProps }
  vatItem = 1;

  constructor(params: Partial<DownPaymentItemData>) {
    const data = { ...params };
    delete data.unitPrice;
    Object.assign(this, data);
  }

  get itemName() {
    return this.productID >= this.gpartsID
      ? this.productName
      : this.serviceName;
  }

  private get prices() {
    return new Prices({
      currNetto: this.netto,
      quantity: this.quantity,
      taxDegree: this.tax?.degree ?? 1,
      exchangeRate: this.exchangeRate,
      currency: this.currency,
      unitPrice: this.unitPrice,
    });
  }

  get unitPrice(): number {
    if (
      !Object.keys(configuration.roundingUnitPrecisions).includes(this.currency)
    ) {
      return roundMoney(this.quantity != 0 ? (this.netto / this.quantity) : this.netto, this.currency, undefined, PrecisionType.geniUnit);
    }
    return roundMoney(this.quantity != 0 ? (this.netto / this.quantity) : this.netto, this.currency);
  }
}

/** Előlegbekérő */
export class DownPayment {
  id = 0;
  invoiceNo = "";
  partner = 1;
  partnerName = "-";
  invoiceDate: Date | string = dayjs().format("YYYY-MM-DD");
  payTerm: Date | string = dayjs().format("YYYY-MM-DD");
  netto = 0;
  total = 0;
  vat = 0;
  taxID = 1;
  currency = "HUF";
  exchangeRate = 1;
  description = "";
  paymentMode = 1;
  bankAccount = 1;
  hasStockRelation = false;
  isPayable = false;
  isIncorrect = false;
  isApproved = false;
  hasPayment = false;
  paidAmount = 0;
  tax: TaxAndVatItem = { ...TaxAndVatItemItemDefaultProps };
  vatItem = 1;

  permitStatus = "";
  permitStatusID = 0;
  preStatus = "";
  preStatusID = 0;

  invoiceItems: DownPaymentItem[] = [];
  attachments: Attachment[] = [];

  constructor(params?: Partial<DownPayment>) {
    const data = { ...params };
    Object.assign(this, data);
  }

}

export class DownPaymentList {
  items: DownPayment[] = [];

  initialize(items: DownPayment[]) {
    this.items = items;
  }
}

/** Előlegbekérőhöz tartozó megrendelés tétel */
export class DownPaymentExtOrderItem {
  id = 0;
  hasDownPayment = false;
  orderID = 0;
  orderNumber = "";
  productID = 0;
  productName = "";
  unitPrice = 0;
  unit = "";
  quantity = 0;
  currency = "";
  amount = 0;
  finalAmount = 0;
  percent = 0;

  constructor(params?: Partial<DownPaymentExtOrderItem>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

/** Előlegbekérőhöz tartozó megrendelés */
export class DownPaymentExtOrder {
  id = 0;
  orderItems: DownPaymentExtOrderItem[] = [];
  hasDownPayment = false;
  orderNumber = "";
  orderDate: Date | null = new Date();
  currency = "";
  amount = 0;
  finalAmount = 0;
  percent = 0;
  expanded = false;

  constructor(params?: Partial<DownPaymentExtOrder>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}
