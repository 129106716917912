import { render, staticRenderFns } from "./CurrentAccountsPage.vue?vue&type=template&id=a8937896&scoped=true&"
import script from "./CurrentAccountsPage.vue?vue&type=script&lang=ts&"
export * from "./CurrentAccountsPage.vue?vue&type=script&lang=ts&"
import style0 from "./CurrentAccountsPage.vue?vue&type=style&index=0&id=a8937896&scoped=true&lang=css&"
import style1 from "./CurrentAccountsPage.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8937896",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VBtnToggle,VIcon,VSimpleTable,VTooltip})
