export default interface BankRemitAnalitics {
    id: number;
    statusName: string;
    startValue: number;
    currentValue: number;
    itemsSum: number;
    currency: string;
}

export const bankRemitAnaliticsDefaultProps: BankRemitAnalitics = {
    id: 1,
    statusName: "",
    startValue: 0,
    currentValue: 0,
    itemsSum: 0,
    currency: "",
}