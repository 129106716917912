






























































































































































































import Vue from "vue";
import debounce from "debounce";
import { ComboBox } from "@progress/kendo-vue-dropdowns";
import CarApi from "./CarApi";
import CarItem from "@/modules/canNet/models/CarItem";
import CarItemResponse from "./CarItemResponse"
import createPagerText from "@/utils/pagerText";
import configuration, { loadConfiguration } from "@/models/Configuration";
import type { AppConfiguration } from "@/models/Configuration";
import cuid from "cuid";

const focusNextElement = (el: Element) => {
  const universe = document.querySelectorAll("input, select, textarea");
  const list = Array.prototype.filter.call(universe, function(item) {
    return item.tabIndex >= "0";
  });
  const index = list.indexOf(el);
  const element = list[index + 1] || list[0];
  element.focus();
};

interface Data {
  cars: CarItem[];
  carsCount: number;
  item: any;
  currentPageNumber: number;
  currentPageSize: number;
  filter: string;
  firstPageNumberButtonVisible: boolean;
  firstPageNumberButtonValue: number;
  secondPageNumberButtonVisible: boolean;
  secondPageNumberButtonValue: number;
  thirdPageNumberButtonVisible: boolean;
  thirdPageNumberButtonValue: number;
  fourthPageNumberButtonVisible: boolean;
  fourthPageNumberButtonValue: number;
  fifthPageNumberButtonVisible: boolean;
  fifthPageNumberButtonValue: number;
  observer: any;
  opened: boolean;
  tempSelected: any;
  configuration: AppConfiguration;
  cuid: string;
}

interface Methods {
  load(pageNumber: number, setPageNumbers?: boolean): void;
  loadCars(pageNumber: number, setPageNumbers?: boolean): void;
  loadCarsByRegNum(
    regNum: string,
    pageNumber: number,
    setPageNumbers?: boolean
  ): void;
  handleCarItemResponse(result: CarItemResponse, setPageNumbers: boolean): void;
  onClose(): void;
  searchChanged(event: any): void;
  searchClick(item: object): void;
  setCurrentPageNumber(pageNumber: number): void;
  setCurrentPageSize(pageSize: number): void;
  decrementPageNumber(): void;
  incrementPageNumber(): void;
  setPageNumbers(): void;
  setCar(): void;
  afterClose(e: any): void;
}

interface Props {
  carId: number;
  firstPage: any;
}

export default Vue.extend<Data, Methods, {}, Props>({
  props: {
    carId: Number,
    firstPage: Object,
  },

  components: { "kendo-combobox": ComboBox },

  data: () => ({
    cars: [],
    carsCount: 0,
    item: "",
    currentPageNumber: 0,
    currentPageSize: 10,
    filter: "",
    firstPageNumberButtonVisible: true,
    firstPageNumberButtonValue: 1,
    secondPageNumberButtonVisible: true,
    secondPageNumberButtonValue: 2,
    thirdPageNumberButtonVisible: true,
    thirdPageNumberButtonValue: 3,
    fourthPageNumberButtonVisible: true,
    fourthPageNumberButtonValue: 4,
    fifthPageNumberButtonVisible: true,
    fifthPageNumberButtonValue: 5,
    observer: null,
    opened: false,
    tempSelected: "",
    configuration,
    cuid: cuid()
  }),

  created() {
    this.load = debounce(this.load, 400);
  },

  mounted() {
  const wrap = document.getElementById(this.cuid)?.parentElement?.parentElement;
  wrap?.addEventListener("focusin", () => {
    this.opened = true;
  });

  wrap?.addEventListener("focusout", () => {
    this.opened = false;
    wrap?.classList.remove("k-state-focused");
    focusNextElement(document.activeElement ?? new Element());
  });

  const select = wrap?.getElementsByClassName(
    "k-select"
  )[0] as HTMLSpanElement;
  select.addEventListener("click", () => {
    this.opened = !this.opened;
  });

  const trs = document.getElementById(this.cuid)?.getElementsByClassName("tr-r") as HTMLCollectionOf<
    HTMLDivElement
  >;
  for (let i = 0; i < trs.length; i++) {
    const tr = trs[i];
    tr.addEventListener("click", () => {
      setTimeout(() => {
        this.opened = false;
        document.body.focus();
        this.item = this.tempSelected;
      }, 1000);
    });
  }
},

  async beforeMount() {
    if(!configuration.isLoaded){
      loadConfiguration();
    }
    if (this.firstPage) {
      this.cars = this.firstPage.cars;
      this.carsCount = this.firstPage.count;
      this.currentPageNumber = 1;
    }

    if (this.carId && this.carId > 1) {
      await CarApi.get(this.carId).then((car) => {
        if (this.cars.length > 0) {
          if (this.cars.length < this.currentPageSize) {
            this.cars.push(car);
          } else {
            this.cars[0] = car;
          }
        } else {
          this.cars.push(car);
        }

        this.item = car;
      });
    }

    if (this.item) {
      this.currentPageNumber = 1;
    } else if (!this.firstPage) {
      this.loadCars(1, true);
    }
  },

  watch: {
    currentPageNumber() {
      this.setPageNumbers();
    },

    carId() {
      this.setCar();
    },
  },

  computed: {
    items() {
      return this.cars;
    },

    footerText() {
      return createPagerText(
        this.carsCount,
        this.currentPageNumber,
        this.currentPageSize
      );
    },

    maxPageNumber() {
      return Math.ceil(this.carsCount / this.currentPageSize);
    },
  },

  methods: {
    afterClose(e) {
      setTimeout(() => {
        if (e?.value ?? null !== null) this.item = this.tempSelected;
        else {
          this.opened = true;
          this.item = { car: 1, regNum: "-" };
          this.$emit("update:carId", this.item.car);
          this.$emit("value", this.item);
        }
      }, 10);
    },

    load(pageNumber, setPageNumbers = false) {
      if (this.filter) {
        this.loadCarsByRegNum(this.filter, pageNumber, setPageNumbers);
      } else {
        this.loadCars(pageNumber, setPageNumbers);
      }
    },

    async loadCars(pageNumber, setPageNumbers = false) {
      await CarApi.getAll(
        pageNumber,
        this.currentPageSize
      ).then((result) => this.handleCarItemResponse(result, setPageNumbers));
    },

    async loadCarsByRegNum(regNum, pageNumber, setPageNumbers = false) {
      await CarApi.getAll(
        pageNumber,
        this.currentPageSize,
        regNum
      ).then((result) => this.handleCarItemResponse(result, setPageNumbers));
    },

    handleCarItemResponse(result, setPageNumbers) {
      this.cars = result.cars;
      this.carsCount = result.count;

      if (setPageNumbers) {
        this.currentPageNumber = 1;
        this.setPageNumbers();
      }
    },

    setCar() {
      if (!this.carId || this.carId <= 0) return;

      this.item = this.cars.find((x) => x.car == this.carId);
      if (this.item) return;

      CarApi.get(this.carId).then((car) => {
        if (this.cars.length > 0) {
          if (this.cars.length < this.currentPageSize) {
            this.cars.push(car);
          } else {
            this.cars[0] = car;
          }
        } else {
          this.cars.push(car);
        }

        this.item = car;
      });
    },

    onClose() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },

    searchChanged(event) {
      this.filter = event.filter.value;

      this.load(1, true);
    },

    async searchClick(item: object) {
      this.item = item;
      if (item) {
        this.tempSelected = item;
      }

      this.$emit("update:carId", this.item.car);
      this.$emit("value", this.item);

      focusNextElement(document.activeElement??new Element());

      this.opened = false;
    },

    setCurrentPageNumber(pageNumber) {
      if (this.currentPageNumber == pageNumber) return;

      this.currentPageNumber = pageNumber;

      this.load(this.currentPageNumber);
    },

    setCurrentPageSize(pageSize) {
      if (this.currentPageSize == pageSize) return;

      this.currentPageSize = pageSize;

      this.load(1, true);
    },

    decrementPageNumber() {
      if (this.currentPageNumber == 1) return;

      this.currentPageNumber--;

      this.load(this.currentPageNumber);
    },

    incrementPageNumber() {
      const maxPageNumber = Math.ceil(
        this.carsCount / this.currentPageSize
      );
      if (this.currentPageNumber == maxPageNumber) return;

      this.currentPageNumber++;

      this.load(this.currentPageNumber);
    },

    setPageNumbers() {
      if (this.currentPageNumber == 0) return;

      const maxPageNumber = Math.ceil(
        this.carsCount / this.currentPageSize
      );
      if (maxPageNumber <= 5) {
        this.firstPageNumberButtonVisible = false;
        this.secondPageNumberButtonVisible = false;
        this.thirdPageNumberButtonVisible = false;
        this.fourthPageNumberButtonVisible = false;
        this.fifthPageNumberButtonVisible = false;

        if (maxPageNumber >= 1) {
          this.firstPageNumberButtonVisible = true;
          this.firstPageNumberButtonValue = 1;
        }

        if (maxPageNumber >= 2) {
          this.secondPageNumberButtonVisible = true;
          this.secondPageNumberButtonValue = 2;
        }

        if (maxPageNumber >= 3) {
          this.thirdPageNumberButtonVisible = true;
          this.thirdPageNumberButtonValue = 3;
        }

        if (maxPageNumber >= 4) {
          this.fourthPageNumberButtonVisible = true;
          this.fourthPageNumberButtonValue = 4;
        }

        if (maxPageNumber == 5) {
          this.fifthPageNumberButtonVisible = true;
          this.fifthPageNumberButtonValue = 5;
        }
      } else {
        this.firstPageNumberButtonVisible = true;
        this.secondPageNumberButtonVisible = true;
        this.thirdPageNumberButtonVisible = true;
        this.fourthPageNumberButtonVisible = true;
        this.fifthPageNumberButtonVisible = true;

        if (maxPageNumber - this.currentPageNumber < 5) {
          this.firstPageNumberButtonValue = maxPageNumber - 4;
          this.secondPageNumberButtonValue = maxPageNumber - 3;
          this.thirdPageNumberButtonValue = maxPageNumber - 2;
          this.fourthPageNumberButtonValue = maxPageNumber - 1;
          this.fifthPageNumberButtonValue = maxPageNumber;
        } else {
          if (this.currentPageNumber < 5) {
            this.firstPageNumberButtonValue = 1;
            this.secondPageNumberButtonValue = 2;
            this.thirdPageNumberButtonValue = 3;
            this.fourthPageNumberButtonValue = 4;
            this.fifthPageNumberButtonValue = 5;
          } else {
            this.firstPageNumberButtonValue = this.currentPageNumber - 2;
            this.secondPageNumberButtonValue = this.currentPageNumber - 1;
            this.thirdPageNumberButtonValue = this.currentPageNumber;
            this.fourthPageNumberButtonValue = this.currentPageNumber + 1;
            this.fifthPageNumberButtonValue = this.currentPageNumber + 2;
          }
        }
      }
    },
  },
});
