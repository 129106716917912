import Feature from "./Feature";
import store from "../services/roleStore"

export default interface Role {
    id: number;
    themeName: string;
    whoamiName: string;
    features: Feature[];
}

export const roleDefaultProps: Role = {
    id: 0,
    themeName:'',
    whoamiName:'',
    features:[]
}
