export default interface IncomingInvoiceSubtype {
    id: number;
    type: number;
    name: string;
    isDefault: boolean;
    stockOnAccept: boolean;
}

export const incomingInvoiceSubtypeDisplayDefaultProps: IncomingInvoiceSubtype = {
    id: 0,
    type: 0,
    name: '',
    isDefault: false,
    stockOnAccept: false
}