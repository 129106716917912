import Vue from "vue";

import * as api from "./api";
import eventBus from "@/services/eventBus";

import {
  StoreIn,
  InvoiceRelationType,
  InvoiceItemList,
  ExtOrder,
  ExtOrderItem,
  Stock,
  InvoiceItem,
  RoundingItem,
  InvoiceRelationState,
  Unit,
  Tax,
  OtherItem,
  ValueDivisionMode,
  getDivisionValue,
  RelatedInvoice,
  InvoiceItemType,
  StoreInFee,
  StoreInFeeType,
  ConsumptionRecord,
  SalesAndOpportunityInput,
  GeneralRectItemDetails,
  generalRectItemDetailsDefaultProps,
} from "../models";
import configuration from "@/models/Configuration";
import GeneralIngoingInvoiceItemDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItemDisplay";
import flowStore from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import TaxAndVatItem, {
  TaxAndVatItemItemDefaultProps,
} from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";
import { PrecisionType, roundMoney } from "@/services/prices";
import Decimal from "decimal.js-light";
import GeneralIngoingInvoiceItem from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItem";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import busyIndication from "@/utils/busyIndication";
import CarItem from "@/modules/canNet/models/CarItem";

interface InvoiceItemStoreParams {
  invoiceID: number;
  supplierID: number;
  defaultTaxID: number;
  currency: string;
  exchangeRate?: number;
  units: Unit[];
  taxes: Tax[];
  taxAndVatItems: TaxAndVatItem[];
  invoiceItems: InvoiceItem[];
  storeInFeeTypes: StoreInFeeType[];
  manualPrecision?: number | undefined;
}

type InvoiceItemStoreUpdateParams = Partial<InvoiceItemStoreParams>;

const relationStateOptions: InvoiceRelationState[] = [
  "Nincs számla kapcsolat" as InvoiceRelationState.None,
  "Részben számlához rendelve" as InvoiceRelationState.Partial,
  "Számlához rendelve" as InvoiceRelationState.Full,
];

export class InvoiceItemStore {
  invoiceID = 0;
  supplierID = 0;
  defaultTaxID = 0;
  currency = "";
  exchangeRate = 1.0;
  storeInFeeTypes: StoreInFeeType[] = [];
  relationStateOptions: InvoiceRelationState[] = relationStateOptions;
  selectedStateOption: InvoiceRelationState = relationStateOptions[0];

  valueDivisionModes: ValueDivisionMode[] = [];

  units: Unit[] = [];
  taxAndVatItems: TaxAndVatItem[] = [];

  targetItem: OtherItem | null = null;
  itemList: InvoiceItemList;

  trumesServices: OtherItem[] = [];
  creditingStoreins: StoreIn[] = [];

  rectItemsToCreate: GeneralIngoingInvoiceItemDisplay[] = [];
  supplComplItemsToCreate: GeneralIngoingInvoiceItemDisplay[] = [];
  correctionGpartsToCreate: GeneralIngoingInvoiceItemDisplay[] = [];

  constructor() {
    this.itemList = new InvoiceItemList();
  }

  get hasItems() {
    return (this.itemList?.items ?? []).length > 0;
  }

  init(params: InvoiceItemStoreParams) {
    this.valueDivisionModes = !configuration.incomingInvoice
      .otherItemStockRelationsEnabled
      ? [ValueDivisionMode.ByItemRate]
      : [
        ValueDivisionMode.ByItemRate,
        ValueDivisionMode.ByManualRate,
        ValueDivisionMode.ByWeightedRate,
      ];

    Object.assign(this, params);
    this.itemList.initialize(
      params.invoiceItems,
      this.exchangeRate,
      this.currency,
      params.manualPrecision
    );

    const storeItemAddHandler = () => (this.targetItem = null);
    eventBus.$on("invoice-item:add", storeItemAddHandler);
  }

  update(params: InvoiceItemStoreUpdateParams) {
    if (params.invoiceID) {
      this.invoiceID = params.invoiceID;
    }

    if (params.supplierID) {
      this.supplierID = params.supplierID;
    }

    if (params.currency) {
      this.currency = params.currency;
      this.itemList.items.forEach((i) => (i.currency = this.currency));
    }

    if (params.exchangeRate) {
      this.exchangeRate = params.exchangeRate;
      this.itemList.items.forEach((i) => (i.exchangeRate = this.exchangeRate));
    }

    if (params.invoiceItems) {
      this.itemList.initialize(
        params.invoiceItems,
        this.exchangeRate,
        this.currency,
        params.manualPrecision
      );
    }

    if (params.manualPrecision && this.itemList) {
      this.itemList.manualPrecision = params.manualPrecision;
    }
  }

  async createCorrectionItem(invNo: number) {
    await api.createCorrectionItem(this.correctionGpartsToCreate, invNo);
  }

  async reloadItems() {
    const invoiceItems = await api.getInvoiceItems(this.invoiceID);
    this.update({
      invoiceItems,
      manualPrecision: this.itemList.manualPrecision,
    });
  }

  addStoreIn = (storeIn: StoreIn) => {
    storeIn.stocks.forEach((s) => this.addStock(s, s.quantity, storeIn.note, storeIn.currency));
  };

  deleteEndInvoiceDownpaymRelation = (relId: number) => {
    http
      .delete(`invoice-dpaym/end-invoice-down-rel/${relId}`)
      .then(handleApiResponse)
      .then(async (x) => {
        this.reloadItems();
        return x;
      });
  };

  searchReturnOrder = async (storeIn: StoreIn) => {
    const stocks: number[] = [];
    storeIn.stocks.forEach(s => {
      if ((s as any).supplierComplaintDetails.isRet) {
        stocks.push(s.stockID);
      }
    });

    const returnOrders = await api.searchReturnOrder(stocks);
    if (returnOrders != null) {
      returnOrders.forEach((t) => {
        this.addExtOrder(t);
      });
    }
  };

  searchTrumesServices = async (storeIn: StoreIn) => {
    const stocks: number[] = [];
    storeIn.stocks.forEach((e) => {
      stocks.push(e.orderID);
    });
    this.trumesServices = await api.searchTrumesServices(stocks);

    if (this.trumesServices != null) {
      this.trumesServices.forEach((t) => {
        if (this.itemList.items.every((x) => x.serviceID != t.serviceID)) {
          this.addOtherItem(t);
        }
      });
    }
  };

  searchCreditingStorein = async (storeIn: StoreIn) => {
    const orderins: number[] = [];
    storeIn.stocks.forEach((e) => {
      if (!(e as any).supplierComplaintDetails.isRet) {
        orderins.push(e.orderID);
      }
    });
    this.creditingStoreins = await api.searchCreditingStorein(orderins);

    if (this.creditingStoreins != null) {
      this.creditingStoreins.forEach((t) => {
        this.addStoreIn(t);
      });
    }
  };

  searchTrumesServicesByExtOrder = async (extOrder: ExtOrder) => {
    this.trumesServices = await api.searchTrumesServices([extOrder.id]);
    if (this.trumesServices != null) {
      this.trumesServices.forEach((t) => {
        this.addOtherItem(t);
      });
    }
  };

  addStock = (stock: Stock, quantity: number, note: string, curency?: string) => {
    const relation = {
      id: 0,
      invoiceID: this.invoiceID,
      invoiceItemID: 1,
      type: InvoiceRelationType.Stock,
      relatedItem: { ...stock },
      relatedItemID: stock.stockID,
      quantity,
      selectedForDivision: true,
    };

    if (this.targetItem) {
      this.targetItem.relations.push(relation);
      return;
    }

    const itemPrice = roundMoney(
      stock.unitPrice * relation.quantity,
      curency ?? this.currency,
      undefined,
      PrecisionType.geniItem
    );

    const invoiceItem: Partial<InvoiceItem> = {
      productName: stock.productName,
      productID: stock.productID,
      quantity: quantity,
      description: note,
      currency: curency ?? this.currency,
      nettoValue: itemPrice,
      unit: this.units.find((u) => u.name === stock.unit) ?? this.units.find((u) => u.id === stock.unitID) ?? this.units[0],
      tax: this.findTax(stock.vatID),
      relations: [relation],
      canSetFixAsset: stock.canSetFixAsset,
      toleranceCheckNeeded: !stock.isInSupplierComplaint,
      partNo: stock.productCode,
      isInSupplierComplaint: stock.isInSupplierComplaint,
      supplierComplaintDetails: (stock as any).supplierComplaintDetails,
    };

    this.itemList.add(invoiceItem);
  };

  addExtOrder = (extOrder: ExtOrder) => {
    if (extOrder.orderItems != null) {
      extOrder.orderItems.forEach((i) => this.addExtOrderItem(i, i.quantity, extOrder.type));
    }
  };

  addExtOrderItem = (
    extOrderItem: ExtOrderItem,
    quantity: number,
    type: any,
    unitPrice?: number
  ) => {
    const relation = {
      id: 0,
      invoiceID: this.invoiceID,
      invoiceItemID: 1,
      type: InvoiceRelationType.ExtOrderItem,
      relatedItemID: extOrderItem.id,
      relatedItem: { ...extOrderItem },
      quantity,
      selectedForDivision: true,
    };

    if (this.targetItem) {
      this.targetItem.relations.push(relation);
      return;
    }

    const invoiceItem: Partial<InvoiceItem> = {
      serviceName: extOrderItem.productName,
      productName: extOrderItem.productName,
      currency: this.currency,
      nettoValue: (unitPrice ?? extOrderItem.unitPrice) * relation.quantity,
      productID: extOrderItem.productID,
      quantity,
      unit:
        this.units.find((u) => u.id === extOrderItem.unitID) ?? this.units[0],
      tax: this.findTax(extOrderItem.vatID),
      relations: [relation],
      serviceID: extOrderItem.serviceID,
      orderService: extOrderItem.serviceID,
      gpartID: relation.relatedItem.gPartId ?? 1,
      isIndirect: type?.isIndirect ?? false,
      toleranceCheckNeeded: true,
      joinedService: extOrderItem.serviceID,
      orderNumber: extOrderItem.orderNumber,
      isService: false,
    };
    this.itemList.add(invoiceItem);
  };

  addOtherItem(item: OtherItem) {
    console.log(item);

    /*const relation = {
      invoiceID: this.invoiceID,
      invoiceItemID: 1,
      id: 0,
      type: InvoiceRelationType.ExtOrderItem,
      relatedItemID: item.serviceID,
      relatedItem: { ...item },
      quantity: item.quantity,
    }; */

    // if (this.targetItem) {
    //   this.targetItem.relations.push(relation);
    //   return;
    // }
    if (item.unitPrice < 0) {
      item.unitPrice = -item.unitPrice;
      item.quantity = -item.quantity;
    }

    const invoiceItem: Partial<InvoiceItem> = {
      productName: `${item.code} ${item.itemName ?? ""}`,
      serviceName: `${item.code} ${item.itemName ?? ""}`,
      nettoValue: item.unitPrice * item.quantity,
      serviceID: item.serviceID,
      gpartID: item.gpartID,
      productID: item.productID,
      currency: this.currency,
      unit: this.units.find((u) => u.name === item.unit) ?? this.units[0],
      tax: this.findTax(item.vatID),
      quantity: item.quantity,
      relations: item.relations,
      increasesStockPrice: item.increasesStockPrice,
      rawOtherItem: item,
      orderNumber: item.orderNumber,
      joinedService: item.serviceID,
      isRegTax: item.isRegTax,
      inVatCount: item.inVatCount,
      isCarRectItem: item.isCarRectItem,
      isInclCarPrice: item.isInclCarPrice,
      isService: item.isService,
    };
    this.itemList.add(invoiceItem);
    console.log(invoiceItem)
  }

  addNewCarItem(item: CarItem) {
    const invoiceItem: Partial<InvoiceItem> = {
      car: item.car,
      prCar: item.prCar,
      inVatCount: true,
      postCalc: item.postCalc,
      gpartID: item.gpart,
      increasesStockPrice: false,

      productName: item.gpartName,
      serviceName: item.gpartName,
      serviceID: 1,
      unit: this.units.find((x) => x.name == "DB") ?? this.units[0],
      nettoValue: item.netto,
      bruttoValue: item.brutto,
      taxValue: item.vat,
      tax: this.findTax(item.tax),
      quantity: 1,
      relations: [],
      carDetails: {
        model: item.modelName,
        bodyNo: item.bodyNo,
        regNum: item.regNum,
      }
    };
    this.itemList.add(invoiceItem);
  }

  addGeneralRectItem(item: OtherItem, invNo: string) {
    console.log(item.quantity);
    const rect = { ...generalRectItemDetailsDefaultProps };
    rect.rectInvNo = invNo;

    const invoiceItem: Partial<InvoiceItem> = {
      productName: `${item.itemName ?? ""}`,
      serviceName: `${item.itemName ?? ""}`,
      nettoValue: 0,
      serviceID: item.serviceID,
      gpartID: item.gpartID,
      productID: item.productID,
      currency: this.currency,
      unit: this.units.find((u) => u.name === item.unit) ?? this.units[0],
      tax: this.findTax(item.vatID),
      quantity: item.quantity,
      increasesStockPrice: item.increasesStockPrice,
      rawOtherItem: item,
      rectifiedInvNo: 1,
      rectifiedItem: 1,
      rectifiedType: "R",
      isGeneralRectItem: true,
      generalRectItemDetails: rect,
    };
    this.itemList.add(invoiceItem);
  }

  removeOtherItem(item: OtherItem) {
    const itemToRemove = this.itemList.items.find((x) =>
      this.invoiceItemToOtherItemComparer(x.rawOtherItem, item)
    );
    if (itemToRemove != null) {
      this.itemList.remove(itemToRemove);
    }
  }

  invoiceItemToOtherItemComparer(x: OtherItem | null, item: OtherItem) {
    if (x == null || item == null) {
      return false;
    }
    if (JSON.stringify(x) == JSON.stringify(item)) {
      return true;
    }
    return false;
  }

  addFreeWordItem(
    name: string,
    unitPrice: number,
    quantity: number,
    unit: Unit,
    vat: TaxAndVatItem
  ) {
    const invoiceItem: Partial<InvoiceItem> = {
      productName: name,
      serviceName: name,
      currency: this.currency,
      nettoValue: unitPrice * quantity,
      serviceID: 1,
      gpartID: 1,
      productID: 1,
      unit: unit,
      tax: vat,
      quantity: quantity,
      relations: [],
    };

    this.itemList.add(invoiceItem);
  }

  isIntrastatFilled(invItemId: number): Promise<boolean> {
    const t = http
      .get<ApiResponse<boolean>>(
        `incoming-invoice-items/${invItemId}/intrastat/filled`
      )
      .then(handleApiResponse);
    return t;
  }

  addRoundingItem(netto: number, brutto: number, taxID: number) {
    const roundingItem: Partial<RoundingItem> = {
      isRoundingItem: true,
      productName: InvoiceItemType.Rounding,
      serviceName: InvoiceItemType.Rounding,
      currency: this.currency,
      nettoValue: netto,
      bruttoValue: brutto,
      serviceID: 1,
      productID: 1,
      gpartID: 1,
      unit: {
        id: 1,
        name: "-",
      },
      tax: this.findTax(taxID),
      quantity: netto > 0 ? 1 : (-1),
      relations: [],
      unitPrice: Math.abs(netto),
      inVatCount: false,
    };

    this.itemList.addItem(new RoundingItem(roundingItem));
  }

  addStoreInFee(fee: StoreInFee) {
    fee.invoiceID = this.invoiceID;

    const invoiceItem: Partial<InvoiceItem> = {
      productName: fee.name,
      serviceName: fee.name,
      nettoValue: fee.price,
      currency: this.currency,
      serviceID: 1,
      gpartID: 1,
      productID: 1,
      unit: {
        id: 1,
        name: "-",
      },
      tax: TaxAndVatItemItemDefaultProps,
      quantity: 1,
      relations: [],
      feeIncluded: fee.inStockPrice,
      feeType: {
        text: "",
        value: fee.type,
      },
    };

    this.itemList.add(invoiceItem);
  }

  removeItem = (item: InvoiceItem) => {
    console.log(item);

    if (!configuration.operel && item.fixAsset != null) {
      eventBus.$emit("error", `A ${item.itemName} tételhez ${item.fixAsset.status?.toUpperCase()} státuszú tárgyi eszköz kapcsolódik: ${item.fixAsset.name}`);
      return;
    }

    if (!configuration.operel && configuration.onlyRectItem && item.rectifiedType == "R") {
      const stornoItem = this.itemList.items.find((x) => x.rectifiedType == "S" && x.rectifiedItem == item.rectifiedItem && x.invoiceItemID == (item.invoiceItemID - 1));
      if (stornoItem) {
        this.itemList.remove(stornoItem);
      }
    }

    if (!configuration.operel && item.rectSumItem && item.rectifiedType == "R") {
      const stornoItem = this.itemList.items.find((x) => x.rectifiedType == "S" && x.rectifiedItem == item.rectifiedItem && !item.visible && x.invoiceItemID == (item.invoiceItemID - 1));
      if (stornoItem) {
        this.itemList.remove(stornoItem);
      }
    }

    if (!configuration.operel) {
      this.autoRemoveForReturnOrder(item);
    }
    this.itemList.remove(item);

    if (item.orderNumber && !configuration.operel) {
      //Vissza kell állítani a megrendelés státuszát megrendelvére, mert lehet, hogy vissza lett nyitva a számla
      http.delete(`ext-orders/${item.orderNumber.replace('/', '-')}`)
        .then(handleApiResponse);
    }
  };

  autoRemoveForReturnOrder = (item: InvoiceItem) => {
    if (item.type == InvoiceItemType.Stocked && item.relations.find((x) => x.relatedItem.originalStock > 1 && x.type == InvoiceRelationType.ExtOrderItem)) {
      console.log("1");

      const temp = item.relations.find((x) => x.relatedItem.originalStock > 1 && x.type == InvoiceRelationType.ExtOrderItem)?.relatedItem;
      if (temp) {
        const found = this.itemList.items.filter((x) => x.relations.find((y) => y.relatedItem.stockID == temp.originalStock));
        if (found) {
          found.forEach(e => {
            this.itemList.remove(e);
          });
        }
      }
    }

    if (item.type == InvoiceItemType.Stocked && item.relations.find((x) => x.relatedItem.stockID > 1 && x.type == InvoiceRelationType.Stock)) {
      console.log("2");
      const temp = item.relations.find((x) => x.relatedItem.stockID > 1 && x.type == InvoiceRelationType.Stock)?.relatedItem;
      if (temp) {
        const found = this.itemList.items.filter((x) => x.relations.find((y) => y.relatedItem.originalStock == temp.stockID));
        if (found) {
          found.forEach(e => {
            this.itemList.remove(e);
          });
        }
      }
    }
  }

  removeRelation = (relation: RelatedInvoice) => {
    const item = this.itemList.items.find((i) =>
      i.relations.some(
        (r) =>
          r.relatedItemID === relation.relatedItemID && r.type === relation.type
      )
    );
    if (item) {
      this.itemList.remove(item);
      eventBus.$emit("invoice-relation:remove", relation);
    }
  };

  toggleOtherItem(item: OtherItem) {
    this.targetItem = item;
    const relationProps = { id: 0, invoiceItemID: 0, selectedForDivision: true };

    if (!item.stockRelated) {
      item.relations = [];
      return;
    }

    item.relations = this.itemList.items
      .filter((i) => i.type == InvoiceItemType.Stocked || i.type == InvoiceItemType.Indirect)
      .flatMap((i) => i.relations)
      .map((r) => ({ ...r, ...relationProps }));

    this.calculateStockDivisionValues(item);
  }

  calculateStockDivisionValues(item: OtherItem) {
    let sumValue = 0;
    const selected = item.relations.filter((x) => x.selectedForDivision);
    const notSelected = item.relations.filter((x) => !x.selectedForDivision).map((x) => x?.divisionValue ?? 0).reduce((acc, i) => i + acc, 0.0);

    selected.forEach((e, i) => {
      if (i == (selected.length - 1)) {
        const allValue = Number(((item.unitPrice * item.quantity) - notSelected).toFixed(2));
        e.divisionValue = Number((allValue - sumValue).toFixed(2));
      } else {
        e.divisionValue = getDivisionValue(item, item.relations, e);
        sumValue += e.divisionValue ?? 0;
      }
      e.selectedForDivision = true;
    });
  }

  calculateInvoiceStockDivision(
    otherItem: OtherItem,
    selection: Array<{
      id: number;
      priceDivision: number;
      item: GeneralIngoingInvoiceItem;
    }>
  ) {
    if (otherItem.valueDivisionMode !== ValueDivisionMode.ByItemRate) {
      return selection;
    }

    const price = otherItem.unitPrice;

    if (price <= 0) {
      return selection;
    }

    const valueGetters = {
      [ValueDivisionMode.ByManualRate]: (_: GeneralIngoingInvoiceItem) => 0.0,
      [ValueDivisionMode.ByItemRate]: (item: GeneralIngoingInvoiceItem) =>
        item.currNetto,
      [ValueDivisionMode.ByWeightedRate]: (item: GeneralIngoingInvoiceItem) =>
        item.totalWeight,
    };

    const getValue = valueGetters[otherItem.valueDivisionMode];

    const result = selection.map((i) => ({ ...i }));
    const total = selection
      .map((i) => getValue(i.item))
      .reduce((acc, i) => i + acc, 0.0);

    if (total === 0) {
      return selection;
    }

    let dividedPrice = 0;
    let maximumDivision = 0;

    for (const state of result) {
      const priceDivision = roundMoney(
        new Decimal(getValue(state.item))
          .div(total)
          .mul(price)
          .toNumber(),
        this.currency, 2
      );

      dividedPrice += priceDivision;
      state.priceDivision = priceDivision;

      if (priceDivision > maximumDivision) {
        maximumDivision = priceDivision;
      }
    }

    if (dividedPrice > price) {
      const diff = dividedPrice - price;
      const division = result.find((x) => x.priceDivision === maximumDivision);

      if (division) {
        division.priceDivision -= diff;
      }
    }

    return result;
  }

  calculateOrderStockDivision(
    otherItem: OtherItem,
    selection: Array<{ id: number; priceDivision: number; item: any }>
  ) {
    if (otherItem.valueDivisionMode !== ValueDivisionMode.ByItemRate) {
      return selection;
    }

    const price = otherItem.unitPrice;

    if (price <= 0) {
      return selection;
    }

    const valueGetters = {
      [ValueDivisionMode.ByManualRate]: (_: GeneralIngoingInvoiceItem) => 0.0,
      [ValueDivisionMode.ByItemRate]: (item: GeneralIngoingInvoiceItem) =>
        item.netto,
      [ValueDivisionMode.ByWeightedRate]: (item: GeneralIngoingInvoiceItem) =>
        item.totalWeight,
    };

    const getValue = valueGetters[otherItem.valueDivisionMode];

    const result = selection.map((i) => ({ ...i }));
    const total = selection
      .map((i) => getValue(i.item))
      .reduce((acc, i) => i + acc, 0.0);

    if (total === 0) {
      return selection;
    }

    let dividedPrice = 0;
    let maximumDivision = 0;

    for (const state of result) {
      const priceDivision = roundMoney(
        new Decimal(getValue(state.item))
          .div(total)
          .mul(price)
          .toNumber(),
        this.currency
      );

      dividedPrice += priceDivision;
      state.priceDivision = priceDivision;

      if (priceDivision > maximumDivision) {
        maximumDivision = priceDivision;
      }
    }

    if (dividedPrice > price) {
      const diff = dividedPrice - price;
      const division = result.find((x) => x.priceDivision === maximumDivision);

      if (division) {
        division.priceDivision -= diff;
      }
    }

    return result;
  }

  addRectifyItem(
    invoiceItem: GeneralIngoingInvoiceItemDisplay,
    newNetto: number
  ) {
    const item = invoiceItem.incomingInvoiceItem;

    const minusItem = {
      nettoValue: -item.netto,
      tax: this.findTax(item.tax),
      description: item.note,
      invoiceItemID: item.id,
      productID: item.parts,
      productName: invoiceItem.code || item.name,
      quantity: item.quantity,
      relations: [],
      gpartsID: item.gparts,
      currency: this.currency,
      serviceName: invoiceItem.gPartsName || item.name,
      taxValue: item.vat,
      unitPrice: item.unitPrice,
      unit: this.units.find((u) => u.id === item.unit) ?? this.units[0],
    };

    const plusItem = {
      nettoValue: newNetto,
      tax: this.findTax(item.tax),
      description: item.note,
      invoiceItemID: item.id,
      productID: item.parts,
      productName: invoiceItem.code || item.name,
      quantity: item.quantity,
      relations: [],
      gpartsID: item.gparts,
      currency: this.currency,
      serviceName: invoiceItem.gPartsName || item.name,
      taxValue: item.vat,
      unitPrice: item.unitPrice,
      unit: this.units.find((u) => u.id === item.unit) ?? this.units[0],
    };

    this.itemList.add(minusItem);
    this.itemList.add(plusItem);
  }

  async addConsignerConsumptionItem(record: ConsumptionRecord) {
    const invoiceID = this.invoiceID;
    const taxID = this.findTax(record.vatID)?.id ?? 1;
    const success = await api.createConsignerConsumptionInvoiceItem(
      invoiceID,
      taxID,
      record
    );
    return success;
  }

  async addSales(opportunity: SalesAndOpportunityInput) {
    const item: Partial<InvoiceItem> = {
      productName: opportunity.name,
      serviceName: opportunity.name,
      nettoValue: opportunity.price,
      currency: opportunity.currency,
      exchangeRate: this.exchangeRate,
      productID: 1,
      quantity: 1,
      tax: {
        id: 1,
        name: "-",
        degree: 0.0,
        vatItemID: 1,
        vatItemName: "-",
        vatDisplayName: "-",
      },
      unit: this.units[0],
      relations: [
        {
          invoiceID: this.invoiceID,
          invoiceItemID: 1,
          quantity: 1,
          id: 1,
          relatedItem: opportunity,
          relatedItemID: opportunity.salesOrderID,
          type: InvoiceRelationType.SalesOrder,
          selectedForDivision: true,
        },
      ],
    };

    this.itemList.add(item);
  }

  private isSpecialTaxSelected() {
    return (
      flowStore.invoice &&
      flowStore.invoice.specTax &&
      flowStore.invoice.specTaxType > 1
    );
  }

  private findTax(taxID: number): TaxAndVatItem {
    const taxes = this.taxAndVatItems;
    const useSpecialTax = this.isSpecialTaxSelected();
    const specTaxID = useSpecialTax
      ? flowStore.specTaxes.find(
        (st) => st.id === flowStore.invoice.specTaxType
      )?.tax
      : undefined;

    return (
      taxes.find((t) => t.id === specTaxID) ??
      taxes.find((t) => t.id === taxID) ??
      taxes.find((t) => t.degree === 27 && t.vatItemID === 1) ??
      taxes[0]
    );
  }

  async updateTax(gpartId: number, partId: number, taxId: number) {
    try {
      await api.updateTax(gpartId, partId, taxId);
    } catch (error) {
      console.error(error);
    }
  }

  async loadDownPaymGParts() {
    return await api.getDownPaymGParts();
  }

  public save = async () => {
    const success = await api.postInvoiceItems(
      this.invoiceID,
      this.itemList.items
    );

    if (success) {
      const invoiceItems = await api.getInvoiceItems(this.invoiceID);
      if (invoiceItems !== undefined) {
        this.update({
          invoiceItems,
          manualPrecision: this.itemList.manualPrecision,
        });

        eventBus.$emit("success", "Sikeres mentés!");
        eventBus.$emit("invoice-item:save");
      } else {
        eventBus.$emit("error", "Hiba történt a mentés közben!");
        console.error(
          "Hiba történt a mentés közben!: Nem lehett elhozni a számla tételeket."
        );
      }
    } else
      eventBus.$emit(
        "error",
        "A mentés közben hiba lépett fel, próbálja újra!"
      );
    return success;
  };

  @busyIndication()
  async updateInvGroupDimensions(invNo: number) {
    return await api.updateInvGroupDimensions(invNo);
  }

  async generateRegTax(id: number) {
    const ret = await api.generateRegTax(id);
    this.addOtherItem(ret);
  }

  async sendEmail(
    nettoValue: number,
    bruttoValue: number,
    taxValue: number,
    originalNettoValue: number,
    originalBruttoValue: number,
    originalVatValue: number,
    invoiceId: number
  ) {
    const success = await api.sendEmail(
      nettoValue,
      bruttoValue,
      taxValue,
      originalNettoValue,
      originalBruttoValue,
      originalVatValue,
      invoiceId
    );

    if (success) {
      eventBus.$emit("success", "Értesítő sikeresen kiküldve!");
    } else {
      eventBus.$emit("error", "Sikertelen kiküldés!");
    }
  }
}

const store = Vue.observable(new InvoiceItemStore());
export default store;
