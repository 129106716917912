export default interface InvoiceGroup {
    id: number;
    name: string;
    foreign: string;
    english: string;
    dimCodeIn: string;
    dimCodeOut: string;
    controlPerson: number;
    user: number;
    defDivItem: number;
    contrType: number;
    deleted: boolean;
    deleting: boolean;
}

export const invoiceGroupDefaultProps: InvoiceGroup = {
    id: 0,
    name: '',
    foreign: '',
    english: '',
    dimCodeIn: '',
    dimCodeOut: '',
    controlPerson: 0,
    user: 0,
    defDivItem: 0,
    contrType: 0,
    deleted: false,
    deleting: false,
}
