export default class InvRiport {
    financial = 0;
    pmode = 0;
    sPmode = '';
    vevo = 0;
    simple = 0;
    balanceDate: Date|null = null;
    overPaym = false;
    allPartner = false;
    include = false;
    bbreak = false;
    date: Date|null = new Date();
    to: Date|null = new Date();
    from: Date|null = new Date();
    ptermFrom: Date|null = new Date();
    ptermTo: Date|null = new Date();
    shipDateFrom: Date|null = new Date();
    shipDateTo: Date|null = new Date();
    accDateFrom: Date|null = new Date();
    accDateTo: Date|null = new Date();
    accStatus = 0;
    present = "-";
    code: string[] = [];
    partnerRel = "--------------------";
    partner: number[] = [];
    depot = false;
    sDepot = 'Mind';
    nullDepot = false;
    lDepot: number[] = [];
    open = false;
    dpaym = 0;
    insDate = '-';
    bank = '-';
    cash = '-';
    other = '-';
    delivered = false;
    sechandIn = false;
    text = "";
    nulla = false;
    ok = false;
}