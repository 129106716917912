var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApiSearchPage',{attrs:{"title":"Főkönyvi tranzakció tételek","url":"general-ledger-transaction/search","columns":_vm.columns,"search":false,"detailsField":"showDimensions"},scopedSlots:_vm._u([{key:"accSignTemp",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.accSign))])]}},{key:"accDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(_vm.setDate(item.accDate))+" ")])]}},{key:"bookingDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(_vm.setDate(item.bookingDate))+" ")])]}},{key:"dimensionsChk",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('ButtonCheckBox',{attrs:{"tooltip":"Dimneziók és könyvelés","icon":"fa fa-bars","disabled":!item.isHasDimensions},model:{value:(item.showDimensions),callback:function ($$v) {_vm.$set(item, "showDimensions", $$v)},expression:"item.showDimensions"}})],1)]}},{key:"bookingUser",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.bookingUser)+" ")])]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('TransactionsDimensions',{attrs:{"item":item}})]}},{key:"currTotal",fn:function(ref){
var item = ref.item;
return [(item.currency != 'HUF')?_c('MoneyDisplay',{attrs:{"currency":item.currency,"value":item.currTotal}}):_c('div',[_vm._v("-")])]}},{key:"total",fn:function(ref){
var item = ref.item;
return [(item.currency == 'HUF')?_c('MoneyDisplay',{attrs:{"currency":"HUF","value":item.total}}):_vm._e()]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"currency":item.currency,"value":item.balance}})]}},{key:"exchangeRate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.exchangeRate)+" *")])]}},{key:"productionId",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s((item.productionId.toString().length > 0 ? item.productionId : '-')))])]}},{key:"taskCard",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s((Number(item.taskCard) !== 1 ? item.taskCard : '-')))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }