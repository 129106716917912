<template>
  <div style="display: flex;">
    <check-box
      style="border-radius: 6px !important; width: 30px"
      :selected="false"
      @change="click()"
    ></check-box>
    <check-box
      style="border-radius: 6px !important; width: 30px"
      :selected="true"
      @change="click2()"
    ></check-box>
  </div>
</template>

<script>
import CheckBox from "./CheckBox.vue";

export default {
  components: { CheckBox },
  props: ["items", "selectedProperty", "disabledProperty"],
  methods: {
    click() {
      this.uncheckClick();
    },
    click2() {
      this.checkClick();   
    },
    checkClick() {
      this.items.forEach((element) => {
        if(this.disabledProperty)
        {
          if(element[this.disabledProperty] != true) {
            element[this.selectedProperty] = true;
          }
        }
        else
        {
          element[this.selectedProperty] = true;
        }
      });
    },
    uncheckClick() {
      this.items.forEach((element) => {
        if(this.disabledProperty)
        {
          if(element[this.disabledProperty] != true) {
            element[this.selectedProperty] = false;
          }
        }
        else
        {
          element[this.selectedProperty] = false;
        }
      });
    },
  },
  computed: {
    allChecked() {
      return this.items.every((x) => x[this.selectedProperty] === true);
    },
  },
};
</script>

<style></style>
