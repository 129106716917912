var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_vm._v("Tárgyi eszköz csoportok")]),_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createNew}},[_vm._v("Új dokumentum igény")])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('label',{staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v(_vm._s(_vm.store.fixAssetGroup.name)+" csoport")])])],1),_c('grid',{attrs:{"data-items":_vm.store.fixAssetGroup.documentTypes,"columns":_vm.columns},scopedSlots:_vm._u([{key:"isRequiredTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"dense":"","readonly":props.dataItem.readOnly},model:{value:(props.dataItem.isRequired),callback:function ($$v) {_vm.$set(props.dataItem, "isRequired", $$v)},expression:"props.dataItem.isRequired"}})],1)]}},{key:"documentTypeNameTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","items":_vm.dStore.documentTypes,"item-text":"name","item-value":"name","return-object":false,"hide-details":"auto","disabled":props.dataItem.readOnly},model:{value:(props.dataItem.documentTypeName),callback:function ($$v) {_vm.$set(props.dataItem, "documentTypeName", $$v)},expression:"props.dataItem.documentTypeName"}})],1)]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[(!props.dataItem.readOnly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary","disabled":props.dataItem.documentTypeName == ''},on:{"click":function($event){return _vm.save(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Mentés")])]):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }