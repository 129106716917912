
































































































import Vue from "vue";
import PartnerCustoms from "../models/PartnerCustoms";
import store, { PartnerStore } from "../services/PartnerStore";

interface Data {
  store: PartnerStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadPartnerCustoms(Number(this.$route.params.partnerId));
  },

  methods: {
    setAuthority() {
      if (this.store.partnerCustoms != null) {
        const newItem: PartnerCustoms = {
          id: 0,
          partner: Number(this.$route.params.partnerId),
          dutyAccCode: "",
          dutyVatAccCode: "",
          editing: true,
          deleting: false,
        };
        this.store.partnerCustoms.push(newItem);
      }
    },

    itemChange({ dataItem, value, field }: { dataItem: PartnerCustoms; value: any; field: string }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      this.store.setPartnerCustoms(item, update);
    },

    cancelEdit(item: PartnerCustoms) {
      item.editing = false;
      this.store.partnerCustoms = [...this.store.partnerCustoms];
      this.store.savePartnerCustoms();
    },

    setUpdatePartnerCustoms(item: PartnerCustoms) {
      item.editing = true;
      this.store.partnerCustoms = [...this.store.partnerCustoms];
    },

    deletePartnerCustoms(item: PartnerCustoms) {
      item.deleting = true;
      this.store.partnerCustoms = [...this.store.partnerCustoms];
    },

    confirmDelete(item: PartnerCustoms) {
      item.deleting = false;
      this.store.partnerCustoms = this.store.partnerCustoms.filter((x) => x.id != item.id);
      this.store.deletePartnerCustoms(item.id);
    },

    cancelDelete(item: PartnerCustoms) {
      item.deleting = false;
      this.store.partnerCustoms = [...this.store.partnerCustoms];
    },
  },

  computed: {
    columns: () => [
      {
        field: "dutyAccCode",
        title: "Vám főkönyvi szám",
      },
      {
        field: "dutyVatAccCode",
        title: "Vám ÁFA főkönyvi szám",
      },
      { cell: "commandTemplate", width: "100px" },
      { width: 0 },
    ],
  },
});
