

























import MoneyDisplay from "@/components/MoneyDisplay.vue";
import Vue from "vue";
import store, { InvoiceItemStore } from "../services/store";

interface Data {
  store: InvoiceItemStore;
}

interface Props {
  currency: string;
}

interface Computed {
  itemNetto: number;
  itemTax: number;
  itemBrutto: number;
}

export default Vue.extend<Data, {}, Computed, Props>({
  components: {
    MoneyDisplay,
  },

  data: () => ({
    store,
  }),

  props: {
    currency: {
      type: String,
    },
  },

  computed: {
    itemNetto() {
      return this.store.itemList.netto;
    },

    itemTax() {
      return this.store.itemList.tax;
    },

    itemBrutto() {
      return this.store.itemList.brutto;
    },
  },
});
