





























































































import Vue from "vue";
import store, { DebitPaymentStore } from "../services/DebitPaymentStore";
import IconButton from "@/components/IconButton.vue";
import Tabs from "@/components/Tabs.vue";

interface Data {
  store: DebitPaymentStore;
  searchText: string;
  orderSearchText: string;
}

interface Methods {
  clear(): void;
  search(): void;
  cancel(): void;

  clearOrder(): void;
  searchOrder(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    IconButton,
    Tabs,
  },

  data() {
    return {
      store,
      searchText: "",
      orderSearchText: "",
    };
  },

  async beforeMount() {
    await this.clear();
    //await this.clearOrder();
  },

  methods: {
    clearOrder() {
      this.orderSearchText = "";
      this.store.loadExtOrders(Number(this.$route.params.partnerId), "");
    },

    searchOrder() {
      this.store.loadExtOrders(Number(this.$route.params.partnerId), this.searchText);
    },

    cancel() {
      this.$router.push(`/debit-payment`);
    },

    clear() {
      this.searchText = "";
      this.store.loadDebitItems(Number(this.$route.params.partnerId), "");
    },

    search() {
      this.store.loadDebitItems(Number(this.$route.params.partnerId), this.searchText);
    },
  },

  computed: {
    columns: () => [
      {
        field: "incomingInvoice.id",
        title: "Rendelés száma",
        headerClassName: "center_text",
        className: "center_text",
      },
      { field: "partnerName", title: "Termék neve", headerClassName: "center_text", className: "center_text" },
      {
        field: "incomingInvoice.total",
        title: "Bruttó",
        format: "{0:n}",
        headerClassName: "right_text",
        className: "right_text",
      },
      { width: 70, className: "right_text", cell: "commandTemplate", sortable: false, filterable: false },
    ],

    orderColumns: () => [
      {
        field: "orderNumber",
        title: "Rendelés száma",
        headerClassName: "center_text",
        className: "center_text",
      },
      {
        field: "orderDate",
        title: "Rendelés dátuma",
        headerClassName: "center_text",
        className: "center_text",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
      },
      {
        field: "plannedArrival",
        title: "Várható beérkezés",
        headerClassName: "center_text",
        className: "center_text",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
      },
      {
        field: "",
        title: "Nettó érték",
        format: "{0:n}",
        headerClassName: "right_text",
        className: "right_text",
      },
      { width: 70, className: "right_text", cell: "commandTemplate", sortable: false, filterable: false },
    ],
  },
});
