











































import MoneyInput from "@/components/MoneyInput.vue";

import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";

interface Data {
  store: InvoiceFlowStore;
}

export default Vue.extend({
  components: { MoneyInput },

  props: {
    /** Every component should be disabled - @default true */
    isPageDisabled: { type: Boolean, default: true },
    /** exchangeRate input (money-input) should be disabled - @default false */
    inputDisabled: { type: Boolean, default: false },
    /** v-select styles - @default "max-width: 7rem"  */
    selectStyles: { type: String, default: "max-width: 7rem" },
    /** money-input styles - @default "max-width: 7rem"  */
    numericStyles: { type: String, default: "max-width: 7rem" },
    tabidx: { type: Number, default: undefined },
  },

  data: (): Data => ({
    store,
  }),

  async mounted() {
    await this.store.loadCurrencies();

    this.store.setSelectedCurrency();
    if (this.store.invoice.currency != "HUF") {
      await this.store.selectedCurrencyChanged();
    }
  },

  methods: {
    async onCurrencyChange() {
      if (!(this.store.invoice.invCurrency) || !(this.store.selectedCurrency?.currency)) return;
      await this.store.selectedCurrencyChanged();
    },

    onExchangeRateChange(rate: number) {
      this.store.invoice.exchangeRate = rate;
      this.store.nettoChanged();
    },
  },

  computed: {
    disabled() {
      return (
        this.store.invoice.preStatus == 8 ||
        this.store.invoice.preStatus == 6 ||
        !this.store.isEditingEnabled ||
        this.isPageDisabled
      );
    },
  },
});
