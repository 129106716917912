import CashUser from "./CashUser";
import CashTax from "./CashTax";
import Country from "./Country";
import { ValidationRules } from "@/models/Validation";
import CashStatement from "@/modules/cashStatement/models/CashStatement";
import CashDocument from "@/modules/cashStatement/models/CashDocument";
import CashPoint, { cashPointDefaultProps } from "./CashPoint";

export default interface CashPointEdit {
  lastStatement: CashStatement;
  statementCount: number;
  lastDocument: CashDocument;
  entity: CashPoint;
  id: number;
  name: string;
  partner: string;
  site: string;
  siteId: number;
  bank: string;
  bankNo: number;
  accCode: string;
  detailedType: number;
  dimCode1: string;
  dimCode3: string;
  deleted: boolean;
  currency: string;
  docPrefix: string;
  noCurAcc: boolean;
  isCashReg: boolean;
  cashRegType: number;
  printer: number;
  cashRegPort: string | null;
  cashRegPar1: string | null;
  cashRegPar3: string | null;
  isAutoClose: boolean;
  isAutoPrintDoc: boolean;
  isNegative: boolean;
  cashRegSpeed: string | null;
  cashRegPar2: string | null;
  isDivItem: boolean;
  isDay: boolean;
  isMonth: boolean;
  isDocDateShip: boolean;
  isMainCashPoint: boolean;
  cashUsers: CashUser[];
  taxGroups: CashTax[];
  isMainCashpointEnabled: boolean;
  cashier: number;
  accNo: number;
  inDocNo: number;
  outDocNo: number;
  hufOnly: boolean;
  partnerId: number;
  cashpointType: number;
  employee: number;
}

export const rules: ValidationRules = {
  name: [(v: string) => !!v || "Megnevezés megadása kötelező"],
  currency: [(v: Country) => !!v.currency || "Pénznem megadása kötelező"],
};

export const cashPointEditDefaultProps: CashPointEdit = {
  lastStatement: new CashStatement({}),
  statementCount: 0,
  lastDocument: new CashDocument(),
  entity: { ...cashPointDefaultProps },
  id: 0,
  name: "",
  partner: "",
  site: "",
  siteId: 0,
  bank: "",
  bankNo: 0,
  accCode: "",
  detailedType: 0,
  dimCode1: "",
  dimCode3: "",
  deleted: false,
  currency: "",
  docPrefix: "",
  noCurAcc: false,
  isCashReg: false,
  cashRegType: 0,
  printer: 0,
  cashRegPort: "",
  cashRegPar1: "",
  cashRegPar3: "",
  isAutoClose: false,
  isAutoPrintDoc: false,
  isNegative: false,
  cashRegSpeed: "",
  cashRegPar2: "",
  isDivItem: false,
  isDay: false,
  isMonth: false,
  isDocDateShip: false,
  isMainCashPoint: false,
  cashUsers: [],
  taxGroups: [],
  isMainCashpointEnabled: false,
  cashier: 0,
  accNo: 0,
  inDocNo: 0,
  outDocNo: 0,
  hufOnly: false,
  partnerId: 0,
  cashpointType: 1,
  employee: 1
};
