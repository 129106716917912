
































































import Vue from 'vue';
import Page from '@/components/Page.vue'
import store, {InvoiceParamStore} from '../services/store';
import InvoiceParams from '../models/sysinvoice';


interface Data{
  store: InvoiceParamStore;
}

interface Computed{
  invoiceParams: InvoiceParams[];
  invoiceParamCols: any[];
}

export default Vue.extend<Data, {},Computed,{}>({
  components:{Page},


  data() {
    return {
      store,
    }
  },


  methods:{
    editInvoiceParam(invoiceParamToEditID: number){
        this.$router.push("/invoice-parameter/"+invoiceParamToEditID)
    }
  },


  computed: {
    invoiceParams() {
      return this.store.invoiceParams;
    },
    invoiceParamCols() {
      return [
        {
          title: "Belső kód",
          field: "code",
        },
        {
          title: "Prefix",
          field: "prefix",
        },
        {
          title: "Számlatípus",
          field: "name",
        },
        {
          title: "Név id. nyelven",
          field: "foreign",
        },
        {
          title: "Név angolul",
          field: "english",
        },
        {
          title: "Előleg elhat. fők. sz.",
          field: "separAccCode",
        },
        {
          title: "Áfa fők. szám",
          field: "vatAccCode",
        },
        {
          title: "SCALA szla.szám",
          field: "scalaAccInvNo",
        },
        {
          title: "F.szla-n átfutó",
          field: "isNoCuracc",
          type:"boolean",
          cell: "isNoCuraccTemp"
        },
        {
          title: "Alapé. fsz. nem átf.",
          field: "defNoCuracc",
          type: "boolean",
          cell: "defNoCuraccTemp"
        },
        {
          title: "Árbevétel kontír?",
          field: "isAccCont",
          type: "boolean",
          cell: "isAccContTemp"
        },
        {
          title: "Élő?",
          field: "deleted",
          type: "boolean",
          cell: "deletedTemp"
        },
        {
          cell: "editTemp", 
          width:50
        }

      ]
    },
  },


  beforeMount(){
    this.store.setInvoiceParams();
  },



})

