export function toBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function processBase64(str: string) {
  const endOfString = str.substr(-5)
  let equalSignsCount = 0

  for (let i = 0; i < endOfString.length; i++) {
    if (endOfString.charAt(i) === '=') {
      equalSignsCount = (5 - i)
      break
    }
  }

  return str.substring(0, str.length - equalSignsCount)
}

export const convertToBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(processBase64(btoa(reader.result as string)));
  };
  reader.onerror = (error) => reject(error);
})

