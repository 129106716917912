




























import Vue from "vue";
import Fragment from "./Fragment.vue";

export default Vue.extend<{}, {}, {}, {}>({
  components: { Fragment },

  props: {
    /** Displayed tooltip text - @default "" */
    tooltip: { type: String, default: "" },

    /** Display the on top - @default false*/
    top: { type: Boolean, default: false },

    /** Show the tooltip - @default true */
    show: { type: Boolean, default: true },
  },

  computed: {
    customTooltip() {
      return !!this.$slots.tooltip || this.$scopedSlots.tooltip;
    },
  },
});
