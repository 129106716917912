

































































































































import IconButton from "@/components/IconButton.vue";
import Vue from "vue";
import CheckBox from "@/components/CheckBox.vue";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import * as api from "../../invoiceItems/services/api";
import itemStore, { InvoiceItemStore } from "@/modules/invoiceItems/services/store";

interface Data {
  flowStore: InvoiceFlowStore;
  itemStore: InvoiceItemStore;
  newWscontRectItemRelations: any[];
  wscontRectItemRelation: any;
  searchText: string;
  selectedWscontRectItem: any;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { IconButton, MoneyDisplay, CheckBox },
  props: {
    invItemId: Number,
    invItemGpartId: Number,
    invItemNetto: Number,
    isPageDisabled: Boolean,
  },

  data() {
    return {
      flowStore,
      itemStore,
      newWscontRectItemRelations: [],
      wscontRectItemRelation: {},
      searchText: "",

      selectedWscontRectItem: undefined,
    };
  },

  async beforeMount() {
    this.selectedWscontRectItem = await api.getCurrentWscontRectItemRelation(Number(this.$props.invItemId));
  },

  methods: {
    addGeneralWscontRectItem() {
      const item = this.itemStore.itemList.items.find((x) => x.invoiceItemID == this.$props.invItemId);
      if (item != null) {
        item.isGeneralRectItem = true;
        item.generalRectItemDetails.id = 0;
        item.generalRectItemDetails.item = this.$props.invItemId;
        item.generalRectItemDetails.invoice = this.flowStore.invoice.id;
        item.generalRectItemDetails.rectInvDate = new Date();
        item.generalRectItemDetails.rectShipDate = new Date();
        item.rectifiedType = "R";
      }
    },

    clear() {
      this.newWscontRectItemRelations = [];
      this.searchText = "";
    },

    async search(e: KeyboardEvent) {
      if (e.key != "Enter") {
        return;
      }
      this.newWscontRectItemRelations = (
        await api.searchWscontRectItemRelations(this.searchText, this.flowStore.invoice.partner)
      ).map((x: any) => ({ ...x, isSelected: false }));
    },

    selectItem(item: any) {
      if (!item.isSelected) {
        this.newWscontRectItemRelations.forEach((e: any) => {
          e.isSelected = false;
        });
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    },

    async addItem() {
      this.selectedWscontRectItem = this.newWscontRectItemRelations.find((x: any) => x.isSelected);
      if (this.selectedWscontRectItem == undefined) {
        return;
      }

      await api.addWscontRectItemRelation(
        this.selectedWscontRectItem,
        Number(this.$route.params.invoiceId),
        Number(this.$props.invItemId)
      );

      this.newWscontRectItemRelations = [];

      await this.itemStore.reloadItems();
      this.selectedWscontRectItem = [];
    },

    async deleteItem() {
      this.selectedWscontRectItem = undefined;
      await api.deleteWscontRectItemRelation(this.$props.invItemId);
    },
  },

  computed: {
    columns: () => [
      { field: "id", title: "GENI szám" },
      { field: "wscont", title: "Közvetített szolgáltatás", },
      { field: "workSheet", title: "Munkalap" },
      //{ field: "facSheetCoop", title: "" },
      { field: "facSheetBr", title: "Alvállalkozó" },
      { field: "netto", title: "Nettó", cell: "nettoTemplate", headerClassName: "right_text" },
      { field: "vat", title: "ÁFA", cell: "vatTemplate", headerClassName: "right_text" },
      { field: "brutto", title: "Bruttó", cell: "bruttoTemplate", headerClassName: "right_text" },
      { cell: "commandTemplate", width: "60" },
      { width: 0 },
    ],
  },
});
