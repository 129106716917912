var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kendo-combobox',{ref:"comboBox",attrs:{"data-test":"partner-combobox","data-items":_vm.items,"text-field":"name","data-item-key":"id","filterable":"","allow-custom":"","item-render":"items","opened":_vm.opened,"header":"header","footer":"footer","list-no-data-render":"noData","popup-settings":{ width: '1000px', height: '99999px' },"className":'partnerSearchComboBox',"id":_vm.cuid,"clearButton":true},on:{"filterchange":_vm.searchChanged,"close":_vm.onClose,"change":function (e) { return _vm.afterClose(e); }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-head",staticStyle:{"min-width":"1000px","height":"40px"}},[_c('div',{staticClass:"td",staticStyle:{"height":"40px"}},[_vm._v("ID")]),_c('div',{staticClass:"td",staticStyle:{"height":"40px"}},[_vm._v("Név")]),_c('div',{staticClass:"td",staticStyle:{"height":"40px"}},[_vm._v("Rövid kód")]),_c('div',{staticClass:"td",staticStyle:{"height":"40px"}},[_vm._v("Számlázási cím")]),_c('div',{staticClass:"td",staticStyle:{"height":"40px"}},[_vm._v("E-mail cím")]),_c('div',{staticClass:"td",staticStyle:{"height":"40px"}},[_vm._v("Telefonszám")]),_c('div',{staticClass:"td",staticStyle:{"height":"40px"}},[_vm._v("Külső kód")]),_c('div',{staticClass:"td",staticStyle:{"height":"40px"}},[_vm._v("Adószám")])])]},proxy:true},{key:"items",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"tr",staticStyle:{"min-width":"1000px","height":"35px"},on:{"click":function($event){return _vm.searchClick(props.dataItem)}}},[_c('div',{staticClass:"td",attrs:{"data-test":"partner-id"}},[_vm._v(_vm._s(props.dataItem.id))]),_c('div',{staticClass:"td",attrs:{"data-test":"partner-name"}},[_vm._v(_vm._s(props.dataItem.name))]),_c('div',{staticClass:"td"},[_vm._v(_vm._s(props.dataItem.nicName))]),_c('div',{staticClass:"td"},[_vm._v(_vm._s(props.dataItem.invoiceAddress)+" "+_vm._s(props.dataItem.invoiceCity))]),_c('div',{staticClass:"td"},[_vm._v(_vm._s(props.dataItem.email))]),_c('div',{staticClass:"td"},[_vm._v(_vm._s(props.dataItem.phoneNumber))]),_c('div',{staticClass:"td"},[_vm._v(_vm._s(props.dataItem.extCode))]),_c('div',{staticClass:"td"},[_vm._v(_vm._s(props.dataItem.taxAccount !== null ? props.dataItem.taxAccount : "-"))])])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"table-footer"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"padding-left":"11px"}},[_c('v-btn',{class:{ pageSizeButton: true, selected: _vm.currentPageSize == 10 },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageSize(10)}}},[_vm._v(" 10 ")]),_c('v-btn',{class:{ pageSizeButton: true, selected: _vm.currentPageSize == 25 },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageSize(25)}}},[_vm._v("25")]),_c('v-btn',{class:{ pageSizeButton: true, selected: _vm.currentPageSize == 50 },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageSize(50)}}},[_vm._v(" 50 ")]),_c('v-btn',{class:{
              pageSizeButton: true,
              selected: _vm.currentPageSize == 100,
            },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageSize(100)}}},[_vm._v(" 100 ")])],1),_c('div',[_c('span',{staticStyle:{"padding-top":"11px","padding-left":"10px","padding-right":"10px","display":"block"}},[_vm._v(" "+_vm._s(_vm.footerText)+" ")])])]),_c('div',[(_vm.configuration.sysParams.isKanriEnabled)?_c('v-btn',{staticStyle:{"margin-right":"1em","margin-top":"0.3em"},attrs:{"height":"31px","max-height":"31px","outlined":"","color":"primary"},on:{"click":_vm.partnerSync}},[_vm._v(" Partnerek szinkronizálása ")]):_vm._e(),(!_vm.configuration.operel)?_c('v-btn',{staticStyle:{"margin-right":"1em","margin-top":"0.3em"},attrs:{"height":"31px","max-height":"31px","outlined":"","color":"primary"},on:{"click":_vm.openNewPartnerInNewPage}},[_vm._v(" Új partner ")]):_vm._e(),(_vm.currentPageNumber > 1)?_c('v-btn',{staticClass:"pageNumberButton",attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":_vm.decrementPageNumber}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-caret-left ")])],1):_vm._e(),(_vm.firstPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.firstPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.firstPageNumberButtonValue)}}},[_vm._v(" "+_vm._s(_vm.firstPageNumberButtonValue)+" ")]):_vm._e(),(_vm.secondPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.secondPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.secondPageNumberButtonValue)}}},[_vm._v(" "+_vm._s(_vm.secondPageNumberButtonValue)+" ")]):_vm._e(),(_vm.thirdPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.thirdPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.thirdPageNumberButtonValue)}}},[_vm._v(" "+_vm._s(_vm.thirdPageNumberButtonValue)+" ")]):_vm._e(),(_vm.fourthPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.fourthPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.fourthPageNumberButtonValue)}}},[_vm._v(" "+_vm._s(_vm.fourthPageNumberButtonValue)+" ")]):_vm._e(),(_vm.fifthPageNumberButtonVisible)?_c('v-btn',{class:{
            pageNumberButton: true,
            selected: _vm.currentPageNumber == _vm.fifthPageNumberButtonValue,
          },attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setCurrentPageNumber(_vm.fifthPageNumberButtonValue)}}},[_vm._v(" "+_vm._s(_vm.fifthPageNumberButtonValue)+" ")]):_vm._e(),(_vm.currentPageNumber < _vm.maxPageNumber)?_c('v-btn',{staticClass:"pageNumberButton",attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":_vm.incrementPageNumber}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-caret-right ")])],1):_vm._e(),_c('span',{staticStyle:{"margin":"5px"}})],1)])]},proxy:true},{key:"noData",fn:function(){return [_c('div',{staticClass:"tr"},[_c('div',{staticClass:"noData"},[_vm._v(" Nincs adat ")])])]},proxy:true}]),model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})}
var staticRenderFns = []

export { render, staticRenderFns }