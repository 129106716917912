









































































































import UpDownIcon from "../../../components/UpDownIcon.vue";
import StatusChip from "../../../components/StatusChip.vue";
import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import IconButton from "@/components/IconButton.vue";
import Fragment from "@/components/Fragment.vue";
import BankExtractImportItemDetails from "./BankExtractImportItemDetails.vue";

import Vue from "vue";
import BankExtractImportApi from "../services/BankExtractImportApi";
import store, { BankExtractImportStore } from "../services/BankExtractImportStore";
import BankExtractImport from "../models/BankExtractImport";
import { getAltRow } from "@/utils/classes";
import Partner, { PartnerResponse } from "@/modules/partner/models/Partner";
import PartnerApi from "@/modules/partner/services/PartnerApi";
import BankExtractImportItem from "../models/BankExtractImportItem";
import AccountSubtype from "@/modules/accountType/models/AccountSubtype";

interface Data {
  store: BankExtractImportStore;
  loaded: boolean;
  partnerSearchFirstPage: PartnerResponse | null;
}

export default Vue.extend({
  components: {
    UpDownIcon,
    StatusChip,
    PartnerSearch,
    IconButton,
    Fragment,
    BankExtractImportItemDetails,
  },

  props: {
    head: { type: Object as () => BankExtractImport },
    edit: Boolean,
  },

  data: (): Data => ({
    store,
    loaded: false,
    partnerSearchFirstPage: null,
  }),

  async beforeMount() {
    if (this.edit) {
      this.partnerSearchFirstPage = await PartnerApi.getAllGeneral(1, 10, false);
    }

    if (!this.edit && (!this.head.items || this.head.items.length == 0)) {
      this.head.items = await BankExtractImportApi.getItems(this.head.id);
    }

    this.loaded = true;
  },

  methods: {
    partnerChanged(item: BankExtractImportItem, partner: Partner) {
      item.partnerAddress = partner.postCode + " " + partner.invoiceCity + ", " + partner.invoiceAddress;
      item.partnerTaxAccount = partner.taxAccount;
    },

    typeChanged(item: BankExtractImportItem): void {
      item.subtypeID = this.getSubtypes(item).filter((x) => x.id > 1)[0]?.id ?? 1;
    },

    getSubtypes(item: BankExtractImportItem): AccountSubtype[] {
      return this.store.itemSubtypes.filter((x) => x.id === 1 || x.type === item.typeID);
    },

    getAltRow,
  },
});
