import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import GeneralIngoingInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import { InvoiceItem, StoreIn, storeInMapper } from "@/modules/invoiceItems/models";
import MainCode from "../models/MainCode";
import UninvoicedStockCorrection from "../models/UninvoicedStockCorrection";
import AccountMixItem from "@/modules/invoiceFlow/models/AccountMixItem";

export default class UninvoicedStockCorrectionApi {
  static getAll(): Promise<UninvoicedStockCorrection[]> {
    return http.get<ApiResponse<UninvoicedStockCorrection[]>>(`uninvoiced-stock-correction`).then(handleApiResponse);
  }

  static getAllStoreIns(searchText: string, start = "", end = ""): Promise<StoreIn[]> {
    return http
      .get<ApiResponse<StoreIn[]>>(`uninvoiced-stock-correction/store-in/create`, {
        params: {
          product: searchText,
          start: new Date(start),
          end: new Date(end),
        },
      })
      .then(handleApiResponse)
      .then((data) => data.map(storeInMapper));
  }

  static getStoreIns(supplierID: number, productSearchText: string, start = "", end = ""): Promise<StoreIn[]> {
    return http
      .get<ApiResponse<StoreIn[]>>(`uninvoiced-stock-correction/store-in`, {
        params: {
          supplier: supplierID,
          product: productSearchText,
          start: new Date(start),
          end: new Date(end),
        },
      })
      .then(handleApiResponse)
      .then((data) => data.map(storeInMapper));
  }

  static async create(invoice: GeneralIngoingInvoice, items: InvoiceItem[]): Promise<number> {
    const itemDTOs = await Promise.all(items.map((i) => i.toDto()));
    return http
      .post<ApiResponse<number>>(`uninvoiced-stock-correction`, {
        invoice: invoice,
        items: itemDTOs,
      })
      .then(handleApiResponse);
  }

  static async update(invoice: GeneralIngoingInvoice, items: InvoiceItem[]): Promise<number> {
    const itemDTOs = await Promise.all(items.map((i) => i.toDto()));
    return http
      .put<ApiResponse<number>>(`uninvoiced-stock-correction`, { invoice: invoice, items: itemDTOs })
      .then(handleApiResponse);
  }

  static async accept(invoice: GeneralIngoingInvoice, items: InvoiceItem[]): Promise<number> {
    const itemDTOs = await Promise.all(items.map((i) => i.toDto()));
    return http
      .put<ApiResponse<number>>(`uninvoiced-stock-correction/accept`, { invoice: invoice, items: itemDTOs })
      .then(handleApiResponse);
  }

  static generateMainCode(invoiceID: number, partnerID: number, subtypeID: number): Promise<MainCode> {
    return http
      .get<ApiResponse<MainCode>>(`uninvoiced-stock-correction/invoice-no-mask/generate`, {
        params: {
          invoiceID: invoiceID,
          partnerID: partnerID,
          subtypeID: subtypeID,
        },
      })
      .then(handleApiResponse);
  }

  // TODO: use in flow
  static getMainCode(invoiceID: number, partnerID: number): Promise<MainCode> {
    return http
      .get<ApiResponse<MainCode>>(`uninvoiced-stock-correction/invoice-no-mask`, {
        params: {
          invoiceID: invoiceID,
          partnerID: partnerID,
        },
      })
      .then(handleApiResponse);
  }

  static createMainCode(invoiceID: number, partnerID: number, subtypeID: number, mainCode: MainCode): Promise<number> {
    return http
      .post<ApiResponse<number>>(`uninvoiced-stock-correction/invoice-no-mask`, mainCode.toDto(), {
        params: {
          invoiceID: invoiceID,
          partnerID: partnerID,
          subtypeID: subtypeID,
        },
      })
      .then(handleApiResponse);
  }

  static getUninvoicedDelivery(stock: number): Promise<AccountMixItem[]> {
    return http.get<ApiResponse<AccountMixItem[]>>(`uninvoiced-stock-correction/uninvoiced-deliveries/${stock}`).then(handleApiResponse);
  }
}
