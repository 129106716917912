export default class AccountType {
  id = 0;
  name = "";
  foreign = "";
  english = "";
  deleted = false;
  mustPayment = false;
  default = false;
  isIn = false;
  isOut = false;
  isInvoice = false;
  isCred = false;
  isOver = false;
  isTech = false;
  isDeb = false;
  isGeni = false;
  inEdit = false;

  constructor(params?: Partial<AccountType>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}
