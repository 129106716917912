export default interface PartnerRelation {
  id: number;
  name: string;
  foreign: string | null;
  english: string | null;
  partnerRel: number;
  invoiceType: number;
  isSelected: boolean;
  isDefault: boolean;
}

export const partnerRelationDefaultProps: PartnerRelation = {
  id: 0,
  name: "",
  foreign: "",
  english: "",
  partnerRel: 0,
  invoiceType: 0,
  isSelected: false,
  isDefault: false,
};
