













































































































































































































































import IconButton from "@/components/IconButton.vue";
import Vue from "vue";
import CarItemRelation from "../models/CarItemRelation";
import * as api from "../services/CarNetApi";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import itemStore, { InvoiceItemStore } from "@/modules/invoiceItems/services/store";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import CarItem from "../models/CarItem";
import CheckBox from "@/components/CheckBox.vue";
import CarPreCalcInfo from "@/modules/canNet/components/CarPreCalcInfo.vue";
import eventBus from "@/services/eventBus";
import { InvoiceItem } from "@/modules/invoiceItems/models";

interface Data {
  flowStore: InvoiceFlowStore;
  itemStore: InvoiceItemStore;
  newCarItemRelations: CarItem[];
  searchText: string;

  selectedItems: CarItem[];
  loaded: boolean;
  carItemRelations: CarItemRelation[];
}

interface Methods {
  saveGroup(): void;
  search(e: KeyboardEvent): void;
  clear(): void;
  updateItem(item: CarItemRelation): void;
  deleteItem(item: CarItemRelation): void;
  selectItem(item: CarItem): void;
  addItems(): void;
  showDetails(item: CarItemRelation): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: { IconButton, MoneyDisplay, CheckBox, MoneyInput, CarPreCalcInfo },
  props: {
    invItemId: Number,
    isPageDisabled: Boolean,
    item: InvoiceItem,
  },

  data() {
    return {
      flowStore,
      itemStore,
      newCarItemRelations: [],
      searchText: "",

      selectedItems: [],
      loaded: false,
      carItemRelations: [],
    };
  },

  async beforeMount() {
    console.log(this.$props.invItemId);
    
    this.carItemRelations = (
      await api.getCurrentCarItemRelations(Number(this.$props.invItemId))
    ).map((x: CarItemRelation) => ({ ...x, details: false }));
    this.loaded = true;
  },

  async created() {
    const eventHandlerName = "AutoSaveCarRelations" + this.$props.invItemId;
    eventBus.$on(eventHandlerName, await this.saveGroup);
  },

  async beforeDestroy() {
    const eventHandlerName = "AutoSaveCarRelations" + this.$props.invItemId;
    eventBus.$off(eventHandlerName, await this.saveGroup);
  },

  methods: {
    async saveGroup() {
      await this.flowStore.updateCarRelationItems(this.carItemRelations);
    },

    async search(e: KeyboardEvent) {
      if (e.key != "Enter") {
        return;
      }
      this.newCarItemRelations = (
        await api.searchCarItemRelations(this.searchText, this.flowStore.invoice.partner)
      ).map((x: CarItem) => ({ ...x, isSelected: false }));
    },

    clear() {
      this.newCarItemRelations = [];
      this.searchText = "";
    },

    async updateItem(item: CarItemRelation) {
      const ret = await api.updateCarItemRelation(item);
      if (ret) {
        eventBus.$emit("success", "Sikeres mentés!");
      }
    },

    async deleteItem(item: CarItemRelation) {
      this.carItemRelations = this.carItemRelations.filter((x) => x.id != item.id);
      await api.deleteCarItemRelation(item.id);
    },

    selectItem(item: CarItem) {
      if (!item.isSelected) {
        item.isSelected = true;
        this.selectedItems.push(item);
      } else {
        item.isSelected = false;
        this.selectedItems = this.selectedItems.filter((x) => x.id != item.id);
      }
    },

    async addItems() {
      if (this.selectedItems.length == 0) {
        return;
      }
      await api.addCarItemRelation(
        this.selectedItems,
        Number(this.$route.params.invoiceId),
        Number(this.$props.invItemId)
      );

      this.newCarItemRelations = [];
      this.carItemRelations = await api.getCurrentCarItemRelations(Number(this.$props.invItemId));

      this.selectedItems = [];
    },

    async showDetails(item: CarItemRelation) {
      item.details = !item.details;
    },
  },

  computed: {
    carIdComputed() {
      if (this.carItemRelations.length == 0 || this.carItemRelations == null || this.carItemRelations == undefined) {
        return 1;
      }
      return Number((this.carItemRelations[0] as CarItemRelation).car);
    },

    columns: () => [
      {
        cell: "detailsButtonTemplate",
        filterable: false,
        width: "50px",
      },
      { field: "car", title: "Gépjármű", width: "80px" },
      { field: "prCar", title: "Gépjármű rendelés", width: "80px" },
      { field: "postCalc", title: "Utókalkuláció", width: "100px" },
      { field: "netto", title: "Nettó", cell: "nettoTemplate" },
      { field: "vat", title: "Gépjárműre felosztandó ÁFA", cell: "vatTemplate" },
      { field: "total", title: "Gépjárműre felosztandó", cell: "totalTemplate" },
      { cell: "commandTemplate", width: "110px" },
      { width: 0 },
    ],

    newColumns: () => [
      { field: "car", title: "Gépjármű" },
      { field: "prCar", title: "Gépjármű rendelés" },
      { field: "carBrandName", title: "Gyártó", width: "140px" },
      { field: "modelName", title: "Modell" },
      { field: "bodyNo", title: "Alvázszám", width: "150px" },
      { field: "engineNo", title: "Motorszám" },
      { field: "regNum", title: "Rendszám" },
      { field: "colorName", title: "Szín" },
      { field: "postCalc", title: "Utókalkuláció" },
      { cell: "commandTemplate", width: "70px" },
      { width: 0 },
    ],
  },
});
