












import ApiSearch from "@/components/apiSearch/components/ApiSearch.vue";

import Vue from "vue";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import PartDTO from "@/modules/part/models/PartDTO";

export default Vue.extend({
  components: { ApiSearch },

  props: {
    initId: Number,
    invGroupId: { type: Number, default: 1 },
  },

  methods: {
    selected(part: PartDTO): void {
      this.$emit("change", part);
    },
  },

  computed: {
    id(): number {
      return this.initId ?? 1;
    },

    columns: (): ApiSearchColumn[] => [
      { field: "code", title: "Cikkszám" },
      { field: "name", title: "Megnevezés" },
      { field: "group", title: "Cikkcsoport" },
      { field: "mainGroup", title: "Főcsoport" },
      { field: "subGroup", title: "Alcsoport" },
      { field: "extCode", title: "Külső kód" },
    ],
  },
});
