import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import GeneralIngoingInvoiceEditModel from "../models/GeneralIngoingInvoiceEditModel";
import PermitStatus from "@/modules/generalIngoingInvoice/models/PermitStatus";
import AccountStatus from "@/modules/notice/models/AccountStatus";
import PreInvoiceStatus from "@/modules/notice/models/PreInvoiceStatus";
import InvoiceFlowStatus from "@/modules/invoiceFlowStatus/model/InvoiceFlowStatus";
import Attachment from "@/modules/generalIngoingInvoice/models/Attachment";

export default class GeneralIngoingInvoiceEditModelApi {
  static getAll(): Promise<GeneralIngoingInvoiceEditModel[]> {
    return http
      .get<ApiResponse<GeneralIngoingInvoiceEditModel[]>>(`general-incoming-invoice-editmodel`)
      .then(handleApiResponse);
  }

  static getPermitAuth(): Promise<boolean> {
    return http.get<ApiResponse<boolean>>(`general-incoming-invoice-editmodel/permit-auth`).then(handleApiResponse);
  }

  // currently unused
  static getPermitStatuses(): Promise<PermitStatus[]> {
    return http
      .get<ApiResponse<PermitStatus[]>>(`general-incoming-invoice-editmodel/permit-statuses`)
      .then(handleApiResponse);
  }

  static updatePermitStatuses(invoiceIds: number[], permitStatus: number): Promise<boolean> {
    return http
      .put(`general-incoming-invoice-editmodel/invoice-permit-statuses`, {
        invoiceIds: invoiceIds,
        permitStatus: permitStatus,
      })
      .then(checkResponse);
  }

  static getAccountStatuses(): Promise<AccountStatus[]> {
    return http.get<ApiResponse<AccountStatus[]>>(`account-status`).then(handleApiResponse);
  }

  static getStatusNames(): Promise<PreInvoiceStatus[]> {
    return http
      .get<ApiResponse<PreInvoiceStatus[]>>(`general-incoming-invoice-editmodel/pre-invoice-status`)
      .then(handleApiResponse);
  }

  static getFlowStatuses(): Promise<InvoiceFlowStatus[]> {
    return http.get<ApiResponse<InvoiceFlowStatus[]>>(`invoice-flow-status`).then(handleApiResponse);
  }

  static async getGENIFile(invoiceId: number, token = ""): Promise<Attachment> {
    return await http
      .get<ApiResponse<Attachment>>(`general-incoming-invoice/geni-picture/${invoiceId}`, {
        params: {
          token,
        },
      })
      .then(handleApiResponse);
  }

  static generateFileDownLoadLink(fileId: number): string {
    return (
      http.defaults.baseURL +
      `general-incoming-invoice/file/${fileId}?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`
    );
  }

  static async getFileFromKozpontiSzamlazo(invoiceId: number, prefix: string): Promise<any> {
    return await http
      .get<ApiResponse<any>>(`experimental-invoices/report/final-invoice/no-modification/${invoiceId}/${prefix}`)
      .then(handleApiResponse);
  }
}
