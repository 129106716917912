























import Vue from "vue";

const noop = () => {
  console.log("noop");
};

export default Vue.extend({
  props: {
    slotClass: {
      type: String,
      default: "",
    },

    confirmationClass: {
      type: String,
      default: "yellowBg",
    },

    confirm: { type: Boolean, default: true },
    confirmText: {
      type: String,
      default: "Igen",
    },

    cancel: { type: Boolean, default: false },
    cancelText: {
      type: String,
      default: "Nem",
    },
  },

  data: () => ({
    visible: false,
    confirmationText: "",
    confirmed: noop,
    canceled: noop,
  }),

  computed: {
    classes() {
      const final = this.confirmationClass ? this.confirmationClass : "";
      return final;
    },
  },

  methods: {
    show(confirmationText: string) {
      this.confirmationText = confirmationText;
      this.visible = true;

      return new Promise((resolve) => {
        this.confirmed = () => resolve(true);
        this.canceled = () => resolve(false);
      });
    },

    confirmClick() {
      this.confirmed();
      this.$nextTick(() => (this.visible = false));
    },

    cancelClick() {
      this.canceled();
      this.$nextTick(() => (this.visible = false));
    },
  },
});
