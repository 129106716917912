





































import Vue from "vue";
import GeneralIngoingInvoiceAndItems from "../../generalIngoingInvoice/models/GeneralIngoingInvoiceAndItems";

export default Vue.extend({
  props: {
    invoices: {
      type: Array as () => GeneralIngoingInvoiceAndItems[],
    },
  },
});
