import DetailsOfCharges from "./../models/DetailsOfCharges";
import Bank, { bankDefaultProps } from "../models/Bank";
import BankApi from "./BankApi";
import busyIndication from "../../../utils/busyIndication";
import BankCode from "../models/BankCode";

export class BankStore {
  banks: Bank[] = [];
  bank: Bank = { ...bankDefaultProps };
  bankCodes: string[] = ["-"];
  detailsOfCharges: DetailsOfCharges[] = [];
  k2dBankCodes: BankCode[] = [];

  @busyIndication()
  async loadBanks() {
    this.banks = (await BankApi.getAll()).map((i) => ({
      ...i,
      deleting: false,
      inEdit: false,
    }));
  }

  @busyIndication()
  async loadBank(bankId: number) {
    this.bank = bankId > 1 ? await BankApi.get(bankId) : { ...bankDefaultProps };
  }

  async loadK2dBankCodes() {
    this.k2dBankCodes = await BankApi.getBankCodes();
  }

  async loadDetailsOfCharges() {
    this.detailsOfCharges = await BankApi.getDetailsOfCharges();
  }

  async deleteBank(bankId: number) {
    try {
      await BankApi.delete(bankId);
      this.banks = this.banks.filter((b) => b.id !== bankId);
    } catch (err) {
      // TODO: notification
      console.error(err);
    }
  }

  async save(item: Bank, update: Partial<Bank>) {
    Object.assign(item, update);
    this.bank = item;
    if (item.id === 0) {
      await this.createBank();
    } else {
      await this.updateBank();
    }
  }

  private async createBank() {
    try {
      const created = await BankApi.create(this.bank);
      this.banks = [...this.banks, created];
    } catch (err) {
      // TODO: notification
      console.error(err);
    }
  }

  private async updateBank() {
    try {
      await BankApi.update(this.bank);
    } catch (err) {
      // TODO: notification
      console.error(err);
    }
  }

  setEditing(item: Bank) {
    item.inEdit = !item.inEdit;
    this.banks = [...this.banks];
  }

  setDeleting(item: Bank) {
    item.deleting = !item.deleting;
    this.banks = [...this.banks];
  }

  async setBankCodes() {
    if (this.banks.length === 0) {
      await this.loadBanks();
    }

    this.banks.map((bank) => {
      if (bank.code && bank.code.trim().length > 0 && this.bankCodes.indexOf(bank.code) === -1) {
        this.bankCodes.unshift(bank.code);
      }
    });
    this.bankCodes.sort();
  }
}

const store = new BankStore();
export default store;
