




















































































































































































































































































































































































































  import Vue from "vue";  
  import CarCostType from "../models/CarCostType";
import GPart from "../models/GPart";
  import GPartType from "../models/GPartType";
  import PartGroup from "../models/PartGroup";
  import Tax from "../models/Tax";
  import TaxItem from "../models/TaxItem";
  import UnitType from "../models/UnitType";
  import store, {GpartsEditStore} from "../services/GpartsEditStore"
  
  interface Data {
    store: GpartsEditStore;
    partsID: number;
    postCalcType: CarCostType;
    gPartsType: GPartType;
    partGroup: PartGroup;
    unitType: UnitType;
    taxEntity: Tax;
    taxItem: TaxItem;
  }

  
  interface Methods {
    save(): void;
    taxchanged(): void;
  }

  interface Computed {
    tax: number;
  }

  interface Props {
    isNew: boolean;
  }

  export default Vue.extend<Data, Methods, Computed, Props>({
    props: {
        isNew: { type: Boolean, default: false },
    },

    data() {
      return {
        store,
        partsID: 0,
        postCalcType: new CarCostType(),
        gPartsType: new GPartType(),
        partGroup: new PartGroup(),
        unitType: new UnitType(),
        taxEntity: new Tax(),
        taxItem: new TaxItem(),
      };
    },
  
    async beforeMount() {
        this.partsID = Number(this.$route.params["partsID"]);
        
        await Promise.all([this.store.loadType(),
            this.store.loadPartGroups(),
            this.store.loadCurrencies(),
            this.store.loadUnitTypes(),
            this.store.loadTaxes(),
            this.store.loadCarCostTypes()]);

        //After Gpart loaded

        if (this.partsID > 1){
            await this.store.loadGpart(this.partsID);
        } else {
            this.store.gpart = new GPart();
            if (this.isNew){
                this.store.gpart.isPostCalc = false;
                this.store.gpart.otherSale = true;
            }
        }

        await this.store.loadTaxItems();

        this.postCalcType = this.store.carCostTypes.find(x => x.id == this.store.gpart.postCalcType) ?? new CarCostType();
        this.gPartsType = this.store.types.find(x => x.id == this.store.gpart.gpartsType) ?? new GPartType();
        this.partGroup = this.store.partGroups.find(x => x.id == this.store.gpart.partGroup) ?? new PartGroup();
        this.unitType = this.store.unitTypes.find(x => x.id == this.store.gpart.unitType) ?? new UnitType();
        this.taxEntity = this.store.taxes.find(x => x.id == this.store.gpart.tax) ?? new Tax();
        this.taxItem = this.store.taxItems.find(x => x.id == this.store.gpart.taxItem) ?? new TaxItem();
    },
  
    methods: {
        save(){
            console.log('save');
        },

        taxchanged(){
            this.store.loadTaxItems();
        }
    },

    computed: {
        tax() {
            return this.store.gpart.tax;
        },
    },

    watch: {
        tax() {
            this.taxchanged();
        },

        postCalcType(){
            this.store.gpart.postCalcType = this.postCalcType?.id ?? 1;
        },

        gPartsType(){
            this.store.gpart.gpartsType = this.gPartsType?.id ?? 1;
        },

        partGroup(){
            this.store.gpart.partGroup = this.partGroup?.id ?? 1;
        },

        unitType(){
            this.store.gpart.unitType = this.unitType?.id ?? 1;
        },

        taxEntity() {
            this.store.gpart.tax = this.taxEntity?.id ?? 1;
        },

        taxItem(){
            this.store.gpart.taxItem = this.taxItem?.id ?? 1;
        }
    }
  });
  