








import Vue from "vue";
import DocumentBrowser from "@/modules/document/views/DocumentBrowser.vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";

interface Data {
  store: InvoiceFlowStore;
}

export default Vue.extend<Data, {}, {}>({
  components: {
    DocumentBrowser,
  },

  data() {
    return {
      store,
    };
  },

  computed: {
    partnerId() {
      return this.store.invoice.partner;
    },
  },
});
