import http, { handleApiResponse, checkResponse } from '@/services/http'
import InvoiceType from "@/modules/invoiceType/models/InvoiceType"
import ApiResponse from '@/models/ApiResponse'
import InvoiceSetting from '../models/InvoiceSetting'

export default class InvoiceTypeApi {
    static getAll(): Promise<InvoiceType[]> {
        return http
            .get<ApiResponse<InvoiceType[]>>(`invoice-types`)
            .then(handleApiResponse)
    }

    static get(invoiceTypeId: number): Promise<InvoiceType> {
        return http
            .get<ApiResponse<InvoiceType>>(`invoice-types/${invoiceTypeId}`)
            .then(handleApiResponse)
    }

    static create(invoiceType: InvoiceType): Promise<InvoiceType> {
        return http
            .post<ApiResponse<InvoiceType>>(`invoice-types`, invoiceType)
            .then(handleApiResponse)
    }

    static update(invoiceType: InvoiceType): Promise<boolean> {
        return http
            .put(`invoice-types/${invoiceType.id}`, invoiceType)
            .then(checkResponse)
    }

    static delete(invoiceTypeId: number): Promise<boolean> {
        return http
            .delete(`invoice-types/${invoiceTypeId}`)
            .then(checkResponse)
    }

    static getInvoiceSetting(): Promise<InvoiceSetting[]> {
        return http
            .get<ApiResponse<InvoiceSetting[]>>(`invoice-setting`)
            .then(handleApiResponse)
    }
}