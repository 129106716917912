export default interface User {
  id: number;
  name: string;
  employment: string;
  email: string;
  phone: string;
  address: string;
  city: number;
  isInvAccept: boolean;
  isInvContr: boolean;
}

export const userDefaultProps: User = {
  id: 0,
  name: "",
  employment: "",
  email: "",
  phone: "",
  address: "",
  city: 0,
  isInvAccept: false,
  isInvContr: false,
};

export const userTechProps: User = {
  id: 1,
  name: "-",
  employment: "-",
  email: "-",
  phone: "-",
  address: "-",
  city: 0,
  isInvAccept: false,
  isInvContr: false,
};
