






























import Vue from "vue";
import relStore, { FlowDocumentRelationStore } from "@/modules/flowDocumentRelation/services/FlowDocumentRelationStore";
import dtStore, { DocumentTemplateStore } from "../../documentTemplate/services/DocumentTemplateStore";
import FlowDocumentRelation, {
  flowDocumentRelationDefaultProps,
} from "../../flowDocumentRelation/models/FlowDocumentRelation";
import flowStore, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import http from "@/services/http";

interface Data {
  flowStore: InvoiceFlowStore;
  relStore: FlowDocumentRelationStore;
  dtStore: DocumentTemplateStore;
  newDocumentRelationItem: FlowDocumentRelation;
}

interface Methods {
  downloadFile(link: any): void;
  showFile(relation: FlowDocumentRelation): void;
  createOtherDocumentRelation(relation: FlowDocumentRelation): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  data: () => ({
    flowStore,
    relStore,
    dtStore,
    newDocumentRelationItem: { ...flowDocumentRelationDefaultProps },
  }),

  async beforeMount() {
    await this.relStore.loadFlowDocumentRelationsByPartnerId(this.flowStore.invoice.partner);
    await this.dtStore.loadDocumentTemplateRelation(Number(this.$route.params.invoiceId));
  },

  methods: {
    async createOtherDocumentRelation(relation: FlowDocumentRelation) {
      this.newDocumentRelationItem.invId = Number(this.$route.params.invoiceId);
      this.newDocumentRelationItem.headId = 1;
      this.newDocumentRelationItem.statusId = 1;
      this.newDocumentRelationItem.required = false;
      this.newDocumentRelationItem.documentType = "Általános pénzügyi dokumentum";
      this.relStore.flowDocumentRelation = { ...this.newDocumentRelationItem };

      const docType = await this.dtStore.getDocumentTypeByTypeName(this.newDocumentRelationItem.documentType);

      const file = await fetch(relation.link)
        .then((res) => res.blob())
        .then((blob) => {
          const myFile = new File([blob], `${relation.fileName}${relation.fileExtension}`);
          this.relStore.create();
          this.dtStore.uploadDocumentTemplateItem(this.dtStore.documentTemplateRelation.id, docType, myFile);
        })
        .finally(() => this.relStore.loadFlowDocumentRelationsByInvoiceId(Number(this.$route.params.invoiceId)));
    },

    downloadFile(link: any) {
      window.open(link + `?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    },

    async showFile(relation) {
      relation.uploading = !relation.uploading;
    },
  },
});
