




















































































































































































import Vue from "vue";
import PartCust, { partCustDefaultProps } from "../models/PartCust";
import store, { PartnerStore } from "../services/PartnerStore";
import cashPointStore, { CashPointStore } from "../../cashPoint/services/CashPointStore";

interface Data {
  store: PartnerStore;
  cashPointStore: CashPointStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
      cashPointStore,
    };
  },

  async beforeMount() {
    window.scrollTo(0, 0);

    await Promise.all([
      this.store.loadCurrencies(),
      this.store.loadUsers(),
      this.store.loadPayModes(),
      this.cashPointStore.loadPartBanks(),
      this.store.loadCustTypes(),
    ]);
    this.store.partCust = { ...partCustDefaultProps };
  },

  methods: {
    async savePartCust() {
      console.log(this.$route.params);
      
      this.store.partCust.partner = Number(this.$route.params.partnerId);
      await this.store.createPartCust(this.store.partCust);
      this.$router.push(`/partners/${this.$route.params.partnerId}/${this.$route.params.source != undefined ? this.$route.params.source : "" }`);
    },

    cancelCreatePartCust() {
      this.store.partCust = { ...partCustDefaultProps };
      this.$router.push(`/partners/${this.$route.params.partnerId}/${this.$route.params.source != undefined ? this.$route.params.source : "" }`);
    },
  },
});
