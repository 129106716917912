
















































































































// Models
import Vue from "vue";
import Pager from "../../../models/Pager";
import store, { CashStatementStore } from "../services/store";
import CashDocumentDisplay from "../models/CashDocumentDisplay";
import CashDocument from "../models/CashDocument";
import { GridColumnProps } from "@progress/kendo-vue-grid";

// Components
import Paginator from "@/components/Paginator.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import IconButton from "@/components/IconButton.vue";
import CashDocByReceipts from "../receipt/views/CashDocByReceipts.vue";
import eventBus from "@/services/eventBus";

interface Data {
  store: CashStatementStore;
  pager: Pager;
  importingIncomingInvoice: boolean;
  importingOutgoingInvoice: boolean;
  isStatementCashierTheCurrentUser: boolean;
  creatingCashDocByReceipts: boolean;
  loader: boolean;
}

interface Methods {
  pagerChanged(pager: Pager): void;
  newDocument(): void;
  importIncomingInvoice(): void;
  importOutgoingInvoice(): void;
  editItem(item: CashDocumentDisplay): void;
}

interface Computed {
  columns: GridColumnProps[];
  items: CashDocumentDisplay[];
}

const DEFAULT_MIN_DATE = "2000-01-01";

export default Vue.extend<Data, Methods, Computed, {}>({
  components: { Paginator, MoneyDisplay, IconButton, CashDocByReceipts },

  props: { disabled: Boolean },

  data: () => ({
    store,
    pager: new Pager(),
    importingIncomingInvoice: false,
    importingOutgoingInvoice: false,
    isStatementCashierTheCurrentUser: false,
    creatingCashDocByReceipts: false,
    loader: false,
    minCloseDate: DEFAULT_MIN_DATE,
    maxCloseDate: "",
  }),

  created() {
    this.pager = new Pager({ list: this.items });
  },

  beforeMount() {
    this.isStatementCashierTheCurrentUser =
      this.store.currentUser.id == this.store.cashStatement.cashier;

      eventBus.$on("cash-documents:load",(value)=>{
        this.loader = value;
      })

      eventBus.$on("cachdoccreatedfromviri", () =>{
        this.store.loadCashDocumentsByStatementId(store.cashStatement.id)
        this.pager.list = this.items;
      })
      
  },

  methods: {
    pagerChanged(pager) {
      this.pager = pager;
    },

    newDocument() {
      this.store.cashDocument = new CashDocument();
      const cashStatementId = Number(this.$route.params.cashStatementId) || 0;
      this.$router.push(`/cash-statement/edit/${cashStatementId}/0`);
    },

    importIncomingInvoice() {
      this.$emit("importGENI");
    },

    importOutgoingInvoice() {
      this.$emit("importGENO");
    },

    editItem(item) {
      const cashStatementId = Number(this.$route.params.cashStatementId) || 0;
      this.$router.push(`/cash-statement/edit/${cashStatementId}/${item.id}`);
    },
  },

  computed: {
    columns: () => [
    { width: 60, cell: "commandTemplate" },
      { field: "uniqueNo", title: "Sorszám", cell: "uniqueNoTemplate" },
      { field: "partnerName", title: "Befizető" },
      {
        field: "currAmount",
        title: "Összeg",
        cell: "amountTemplate",
        className: "right_text",
        headerClassName: "right_text",
      },
      { field: "status", title: "Állapot" },
      {
        field: "closeDate",
        title: "Bizonylat dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        field: "insDate",
        title: "Rögzítés dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      { field: "comment", title: "Megjegyzés" },
    ],

    items() {
      return this.store.cashDocuments;
    },
  },
});
