







import Vue from "vue";
import { RelatedInvoice } from "../models";

import store from "../services/store";

export default Vue.extend({
  data: () => ({
    store,
    invoiceNo: "",
  }),

  props: {
    relation: {
      type: Object as () => RelatedInvoice,
    },

    unit: {
      type: String,
    },
  },
});
