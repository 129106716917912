var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_c('v-col',[_vm._v(" Funkció letiltások ")])],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.store.deniedRelations,"columns":_vm.columns},scopedSlots:_vm._u([{key:"userTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"pr-10"},[_c('v-autocomplete',{attrs:{"items":_vm.store.users,"small-chips":"","item-text":"name","item-value":"id","multiple":""},on:{"change":function($event){return _vm.save(props.dataItem)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"ma-2"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true),model:{value:(props.dataItem.users),callback:function ($$v) {_vm.$set(props.dataItem, "users", $$v)},expression:"props.dataItem.users"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }