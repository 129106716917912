export default interface Tax {
  id: number;
  name: string;
  degree: number;
  specTax: boolean;
  isInverseTax: boolean;
}

export const taxDefaultProps: Tax = {
  id: 0,
  name: "",
  degree: 0,
  specTax: false,
  isInverseTax: false,
};
