<script>
import { VStepper } from "vuetify/lib";
export default {
  // eslint-disable-next-line
  name: "v-stepper",
  extends: VStepper,
  methods: {
    register(item) {
      if (item.$options.name === "v-stepper-step") {
        this.steps.push(item);
      } else if (item.$options.name === "v-stepper-content") {
        item.isVertical = this.vertical;
        this.content.push(item);
      }
    },

    unregister(item) {
      if (item.$options.name === "v-stepper-step") {
        this.steps = this.steps.filter((i) => i !== item);
      } else if (item.$options.name === "v-stepper-content") {
        item.isVertical = this.vertical;
        this.content = this.content.filter((i) => i !== item);
      }
    },
  },
};
</script>