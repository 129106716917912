










































































































































































































































































































































































import Vue from "vue";
import auth from "../services/auth";
import configuration from "@/models/Configuration";
import permStore from "@/services/permissions";

export default Vue.extend({
  data: () => ({
    permStore,
    configuration,
    menuOpened: false,
  }),

  beforeMount() {
    if (
      localStorage.getItem("primaryColor") &&
      localStorage.getItem("primaryColorHover") &&
      localStorage.getItem("textColor")
    ) {
      this.$vuetify.theme.themes.light.primary = localStorage.getItem("primaryColor") ?? "#f67a1c";

      document.documentElement.style.setProperty("--primaryColor", localStorage.getItem("primaryColor"));

      document.documentElement.style.setProperty("--primaryColorHover", localStorage.getItem("primaryColorHover"));

      document.documentElement.style.setProperty("--textColor", localStorage.getItem("textColor"));
    } else {
      this.setTheme("#f67a1c", "#be5606", "#FFFFFF");
    }
  },

  methods: {
    changePassword() {
      this.$router.push("/change-password");
    },

    navigate(to: string) {
      if (this.$route.path != to) {
        this.$router.push(to);
      }
    },

    logout() {
      auth.logout();
    },

    setTheme(primaryColor: string, primaryColorHover: string, textColor: string) {
      this.$vuetify.theme.themes.light.primary = primaryColor;
      document.documentElement.style.setProperty("--primaryColor", primaryColor);
      document.documentElement.style.setProperty("--primaryColorHover", primaryColorHover);
      document.documentElement.style.setProperty("--textColor", textColor);

      localStorage.setItem("primaryColor", primaryColor);
      localStorage.setItem("primaryColorHover", primaryColorHover);
      localStorage.setItem("textColor", textColor);

      // console.log(localStorage.getItem("primaryColor"));
      // console.log(localStorage.getItem("primaryColorHover"));
      // console.log(localStorage.getItem("textColor"));
    },

    checkPermission(name: string): boolean {
      const menuPerm = auth.menuPermissions.find(x => x.name == name);
      return menuPerm?.isGranted ?? false;
    }
  },

  computed: {
    userName() {
      return auth.userName;
    },

    database() {
      return window.localStorage.getItem("trufinance.connectionID");
    },

    canEditDeadlines() {
      return this.permStore.hasPermitting() || this.permStore.hasPermitting();
    },
  },
});
