export default interface PublicProjectItem {
    id: number;
    projectID: number;
    itemType: number;
    itemID: number;
    itemDate: string;
    quantity: number;
    unitPrice: number;
    amount: number;
    unitType: number;
    currency: string;
    insertDate: string;
    insertTime: string;
    insertUserID: number;
    note: string;
    deleted: boolean;
    readonly: boolean;

}

export const publicProjectItemDefaultProps: PublicProjectItem = {
    id: 0,
    projectID: 1,
    itemType: 1,
    itemID: 1,
    itemDate: '',
    quantity: 0,
    unitPrice: 0,
    amount: 0,
    unitType: 1,
    currency: '',
    insertDate: '',
    insertTime: '',
    insertUserID: 1,
    note: '',
    deleted: false,
    readonly: false
}