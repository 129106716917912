




































































































































































































































































































































































































import Vue from "vue";
import CashDocumentDisplay from "../models/CashDocumentDisplay";
import store, { CashStatementStore } from "../services/store";
import CashDocumentDetails from "../components/CashDocumentDetails.vue";
import { formatDate, formatTime } from "@/utils/formatDates";
import CashStatementDisplay, { cashStatementDisplayDefaultProps } from "../models/CashStatementDisplay";
import Pager from "../../../models/Pager";
import createPagerText from "@/utils/pagerText";
import Paginator from "@/components/Paginator.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Data {
  store: CashStatementStore;
  selectedStatement: CashStatementDisplay;
  activeStatementTab: number;
  tabButtons: object;
  documentTitle: string;
  isDifferentCurrency: boolean;
  baseSizes: Array<number>;
  mixedPager: Pager;
  incomesPager: Pager;
  expensesPager: Pager;
}

interface Methods {
  showDetails(dataItem: CashDocumentDisplay): void;
  editItem(item: CashDocumentDisplay): void;
  setTab(activeTab: number, title: string): void;
  resetSelected(income: boolean): void;
  totalInCurrency(income: boolean): number;
  incomesPagerChanged(pager: Pager): void;
  expensesPagerChanged(pager: Pager): void;
  mixedPagerChanged(pager: Pager): void;
}

interface Computed {
  columns: object;
  incomes: CashDocumentDisplay[];
  incomesPagerText: string;
  expenses: CashDocumentDisplay[];
  expensesPagerText: string;
  mixedColumns: object;
  mixedItems: object[];
  mixedPagerText: string;
  extractDateTime: string;
  totalIncomeInCurr: number;
  totalExpenseInCurr: number;
  totalDifferenceInCurr: number;
  openInHUF: number;
  closeInHUF: number;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  props: { statement: {} },

  components: {
    CashDocumentDetails,
    Paginator,
    MoneyDisplay,
  },

  data() {
    return {
      store,
      selectedStatement: { ...cashStatementDisplayDefaultProps },
      activeStatementTab: 0,
      tabButtons: [
        { id: 0, name: "Információk" },
        { id: 3, name: "Mind", title: "Bizonylatok" },
        { id: 4, name: "Mozgások", title: "Bizonylat mozgások" },
        { id: 1, name: "Bevételek", title: "Bevételi bizonylatok" },
        { id: 2, name: "Kiadások", title: "Kiadási bizonylatok" },
      ],
      documentTitle: "",
      isDifferentCurrency: false,
      baseSizes: [5, 10, 15, 20, 25],
      mixedPager: new Pager(),
      incomesPager: new Pager(),
      expensesPager: new Pager(),
    };
  },

  created() {
    this.mixedPager = new Pager({ list: this.mixedItems });
    this.incomesPager = new Pager({ list: this.incomes });
    this.expensesPager = new Pager({ list: this.expenses });
  },

  async beforeMount() {
    this.selectedStatement = this.$props.statement ?? {
      ...cashStatementDisplayDefaultProps,
    };

    if (this.selectedStatement.currency.toUpperCase() !== "HUF") {
      this.isDifferentCurrency = true;
    }
  },

  methods: {
    async showDetails(dataItem: CashDocumentDisplay) {
      if (!dataItem.selected) {
        await this.store.loadCashDocItemsByDocumentId(dataItem.id);
        dataItem.cashDocItems = this.store.cashDocItems;

        console.warn(dataItem.id, "StatementDetails showDetails(): dataItem:", JSON.parse(JSON.stringify(dataItem)));
      }
      dataItem.selected = !dataItem.selected;
    },

    editItem(item: CashDocumentDisplay) {
      this.$router.push(`/cash-statement/edit/${item.cashAccount}/${item.id}`);
    },

    setTab(newTab: number, title: string) {
      this.activeStatementTab = newTab;

      console.log(
        this.selectedStatement.id,
        `StatementDetails setTab(${newTab}): selectedStatement:`,
        JSON.parse(JSON.stringify(this.selectedStatement))
      );

      if (newTab === 0) return;

      this.documentTitle = title;

      this.resetSelected(true);
      this.resetSelected(false);
    },

    resetSelected(income: boolean) {
      this.selectedStatement.cashDocuments.forEach((d, i) => {
        if (d.selected && d.income === income) {
          this.selectedStatement.cashDocuments[i].selected = false;
        }
      });
    },

    totalInCurrency(income: boolean) {
      const statusFilter = ["Fizetve", "Nyomtatva"];

      return this.selectedStatement.cashDocuments
        .filter((d) => d.income === income)
        .filter((d) => statusFilter.length === 0 || statusFilter.includes(d.status))
        .reduce((acc, current) => {
          const temp =
            current.currAmount === current.amount ? current.currAmount / current.exchangeRate : current.currAmount;
          return acc + temp;
        }, 0);
    },

    incomesPagerChanged(pager: Pager) {
      this.incomesPager = pager;
      this.incomes.forEach((x) => (x.selected = false));
    },

    expensesPagerChanged(pager: Pager) {
      this.expensesPager = pager;
      this.expenses.forEach((x) => (x.selected = false));
    },

    mixedPagerChanged(pager: Pager) {
      this.mixedPager = pager;
    },
  },

  computed: {
    columns: () => [
      { width: 50, cell: "documentDetailsButtonTemplate" },
      { width: 90, field: "uniqueNo", title: "Sorszám" },
      { field: "partnerName", title: "Befizető" },
      {
        field: "amount",
        title: "Összeg",
        cell: "amountTemplate",
        className: "right_text",
        headerClassName: "right_text",
      },
      { field: "status", title: "Állapot" },
      {
        field: "closeDate",
        title: "Bizonylat dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        field: "insDate",
        title: "Rögzítés dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      { width: 60, cell: "commandTemplate" },
      { width: 0 },
    ],

    incomes() {
      return this.selectedStatement.cashDocuments
        .filter((d) => d.income === true)
        .sort((a, b) => new Date(b.closeDate ?? 0).getTime() - new Date(a.closeDate ?? 0).getTime());
    },

    incomesPagerText() {
      return createPagerText(this.incomes.length, this.incomesPager.currentPage, this.incomesPager.pageSize);
    },

    expenses() {
      return this.selectedStatement.cashDocuments
        .filter((d) => d.income === false)
        .sort((a, b) => new Date(b.closeDate ?? 0).getTime() - new Date(a.closeDate ?? 0).getTime());
    },

    expensesPagerText() {
      return createPagerText(this.expenses.length, this.expensesPager.currentPage, this.expensesPager.pageSize);
    },

    mixedColumns: () => [
      { width: 50 },
      { field: "uniqueNo", title: "Sorszám" },
      { field: "partnerName", title: "Befizető" },
      {
        field: "incomesMixed",
        title: "Bevételek",
        cell: "incomesTemplate",
        className: "custom-incomes right_text",
        headerClassName: "right_text",
      },
      {
        field: "expensesMixed",
        title: "Kiadások",
        cell: "expensesTemplate",
        className: "custom-expenses right_text",
        headerClassName: "right_text",
      },
      {
        field: "status",
        title: "Státusz",
        // headerClassName: "center_text",
        // cell: "statusTemplate",
      },
      {
        field: "closeDate",
        title: "Bizonylat dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        field: "insDate",
        title: "Rögzítés dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      { width: 60, cell: "commandTemplate" },
      { width: 0 },
    ],

    mixedItems() {
      return this.selectedStatement.cashDocuments
        .map((d: CashDocumentDisplay) => ({
          uniqueNo: d.uniqueNo,
          partnerName: d.partnerName,
          incomesMixed: d.income ? "+ " + d.amount.toLocaleString() : "",
          expensesMixed: !d.income ? "- " + d.amount.toLocaleString() : "",
          currency: d.invCurrency,
          status: d.status,
          insDate: d.insDate,
          closeDate: d.closeDate,
          id: d.id,
          cashAccount: d.cashAccount,
          document: d.document,
        }))
        .sort((a, b) => new Date(b.closeDate ?? 0).getTime() - new Date(a.closeDate ?? 0).getTime());
    },

    mixedPagerText() {
      return createPagerText(this.mixedItems.length, this.mixedPager.currentPage, this.mixedPager.pageSize);
    },

    totalIncomeInCurr() {
      return this.totalInCurrency(true);
    },

    totalExpenseInCurr() {
      return this.totalInCurrency(false);
    },

    totalDifferenceInCurr() {
      return this.totalIncomeInCurr - this.totalExpenseInCurr;
    },

    openInHUF() {
      return this.selectedStatement.openBal * this.selectedStatement.openRate;
    },

    closeInHUF() {
      return this.selectedStatement.closeBal * this.selectedStatement.closeRate;
    },

    extractDateTime() {
      return (
        formatDate(new Date(this.selectedStatement.extractDate)) +
        " " +
        formatTime(new Date(this.selectedStatement.extractTime))
      );
    },
  },
});
