














































































































































































































































import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";
import permStore, { PermissionStore } from "@/services/permissions";
import IconButton from "@/components/IconButton.vue";
import CustomTab from "@/components/CustomTab.vue";
import dStore, { DocumentTypeStore } from "@/modules/documentType/services/DocumentTypeStore";
import FlowDocumentRelation, {
  flowDocumentRelationDefaultProps,
} from "@/modules/flowDocumentRelation/models/FlowDocumentRelation";
import relStore, { FlowDocumentRelationStore } from "@/modules/flowDocumentRelation/services/FlowDocumentRelationStore";
import DocumentTemplateApi from "@/modules/documentTemplate/services/DocumentTemplateApi";
import dtStore, { DocumentTemplateStore } from "@/modules/documentTemplate/services/DocumentTemplateStore";
import FlowDocumentRelationApi from "@/modules/flowDocumentRelation/services/FlowDocumentRelationApi";
import http from "@/services/http";

interface Data {
  invoiceDetailsVisible: boolean;
  store: DownPaymentStore;
  permStore: PermissionStore;
  fileNote: string;
  showDocumentTypes: boolean;
  dStore: DocumentTypeStore;
  newDocumentRelationItem: FlowDocumentRelation;
  relStore: FlowDocumentRelationStore;
  dtStore: DocumentTemplateStore;
}

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default Vue.extend<Data, {}, {}, {}>({
  data: () => ({
    invoiceDetailsVisible: true,
    store,
    permStore,
    fileNote: "",
    showDocumentTypes: false,
    dStore,
    relStore,
    newDocumentRelationItem: { ...flowDocumentRelationDefaultProps },
    dtStore,
  }),

  computed: {
    isEditingEnabled() {
      return !store.downPayment.isPayable && !store.downPayment.isApproved && !store.downPayment.isIncorrect;
    },

    isAttachmentEmpty() {
      return this.store.attachment != undefined && this.store.attachment.id == 0 && this.store.attachment.file == "";
    },
  },

  methods: {
    async deleteFile(relation: FlowDocumentRelation) {
      if (relation.link == "" || !relation.hasFile || relation.fileName == "") return;

      await DocumentTemplateApi.deletePreviousDocument(relation.documentId).then(() => {
        relation.link = "";
        relation.hasFile = false;
        relation.fileName = "";
      });
    },

    async uploadFile(relation: FlowDocumentRelation) {
      const file = (this.$refs.file as any).find((x: any) => {
        if (x?.id == `file-${relation.statusId}-${relation.id}-${relation.documentTypeId}`) return true;
        else return false;
      }).$refs.input.files[0];
      if (!file) return;

      // delete previous file
      if (relation.hasFile) {
        if (relation.link == "" || !relation.hasFile || relation.fileName == "") return;

        await DocumentTemplateApi.deletePreviousDocument(relation.documentId).then(() => {
          relation.link = "";
          relation.hasFile = false;
          relation.fileName = "";
        });
      }

      await this.dtStore.uploadOtherDocumentItem(relation.id, relation.documentTypeId, file).then((result: any) => {
        relation.documentId = result.id;
        relation.link = FlowDocumentRelationApi.generateDocumentDownLoadLink(result.id);
        relation.hasFile = relation.link != "";
        relation.fileName = result.fileName + result.fileExtension;
      });
    },

    downloadFile(link: any) {
      window.open(link + `?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    },

    showFile(relation: FlowDocumentRelation) {
      relation.uploading = !relation.uploading;
    },

    async showDocumentType() {
      if (!this.showDocumentTypes) {
        await this.dStore.loadDocumentTypes();
      }
      this.showDocumentTypes = !this.showDocumentTypes;
    },

    async createDocumentRelation() {
      this.newDocumentRelationItem.invId = Number(this.$route.params.invoiceId);
      this.newDocumentRelationItem.headId = 1;
      this.newDocumentRelationItem.statusId = 1;
      this.newDocumentRelationItem.required = false;
      this.relStore.flowDocumentRelation = { ...this.newDocumentRelationItem };

      await this.relStore.create();
      await this.relStore.loadFlowDocumentRelationsByInvoiceId(Number(this.store.downPayment.id));
    },

    openInNewTab() {
      window.open(this.store.attachment.link, "_blank");
    },

    async addFile() {
      const file: File = (this.$refs.file as any).files[0];
      if (!file) return;

      this.store.attachment = {
        id: 0,
        file: file,
        link: "",
        note: this.fileNote,
        name: file.name,
        fileBase64: "",
        size: file.size,
      };

      const base64 = (await toBase64((this.$refs.file as any).files[0] as File)) as ArrayBuffer;
      store.attachment.link = base64.toString();

      if (this.store.downPayment.id > 1) {
        await this.store.uploadGENIPicture(this.store.downPayment.id);
        await this.store.loadGENIPicture(this.store.downPayment.id);
      }
    },
  },

  components: {
    IconButton,
    CustomTab,
  },
});
