import { DimCodeType } from "../../generalLedger/models/TypesForEdit";
import DimCodeTypeApi from "../services/DimCodeTypeApi"
import busyIndication from "../../../utils/busyIndication";

export class DimCodeTypeStore {
    dimCodeTypes: DimCodeType[] = []
    dimCodeType: DimCodeType = {
        id: 0,
        national: "",
        english: "",
        foreign: "",
        deleted: false,
        editing: false,
        required: false,
    }

    isStationTypeVisible = true;
    isCarTypeVisible = true;
    isProjectTypeVisible = true;
    isPartTypeVisible = true;
    isEleventhDimensionVisible = true;
    isTwelfthDimensionVisible = true;

    @busyIndication()
    async loadDimCodeTypes() {
        this.dimCodeTypes = (await DimCodeTypeApi.GetAllDimCodeTypes()).map((i) => ({ ...i, editing: false }))
    }

    async loadDimCodeTypesWithoutBusy() {
        this.dimCodeTypes = (await DimCodeTypeApi.GetAllDimCodeTypes()).map((i) => ({ ...i, editing: false }));

        this.isPartTypeVisible = this.dimCodeTypes[5].deleted;
        this.isCarTypeVisible = this.dimCodeTypes[6].deleted;
        this.isStationTypeVisible = this.dimCodeTypes[7].deleted;
        this.isProjectTypeVisible = this.dimCodeTypes[8].deleted;
        this.isEleventhDimensionVisible = this.dimCodeTypes[10].deleted;
        this.isTwelfthDimensionVisible = this.dimCodeTypes[11].deleted;
    }

    async itemChange(item: DimCodeType, update: Partial<DimCodeType>) {
        Object.assign(item, update);
        this.dimCodeType = { ...item };
    }

    async save() {
        if (this.dimCodeType.id == 0) {
            return;
        }

        try {
            await DimCodeTypeApi.update(this.dimCodeType);
            this.loadDimCodeTypes();
        } catch (err) {
            console.error(err);
        }
    }
}

const store = new DimCodeTypeStore()
export default store