var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.periods.length > 0)?_c('v-card-text',[_c('grid',{staticClass:"periods",attrs:{"data-items":_vm.periods,"columns":_vm.periodsCols},scopedSlots:_vm._u([{key:"startDateTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(_vm.toDate(props.dataItem.startDate))+" ")])]}},{key:"endDateTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(_vm.toDate(props.dataItem.endDate))+" ")])]}},{key:"user",fn:function(ref){
var dataItem = ref.props;
return [_c('td',[_c('div',{staticClass:"name"},[_vm._v(_vm._s(dataItem.user))])])]}},{key:"mustCloseTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"readonly":"","color":"primary"},model:{value:(props.dataItem.mustClose),callback:function ($$v) {_vm.$set(props.dataItem, "mustClose", $$v)},expression:"props.dataItem.mustClose"}})],1)]}},{key:"accDateTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[(_vm.isCloseDateSet(_vm.toDate(props.dataItem.accDate)))?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(props.dataItem.accDate))+" ")]):_c('div',[_vm._v(" - ")])])]}},{key:"statusTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('div',{},[_c('v-select',{attrs:{"single-line":"","outlined":"","dense":"","disabled":props.dataItem.status === 'Végleg lezárt',"item-text":"name","return-object":"","items":_vm.store.statusesMin},on:{"change":function($event){return _vm.periodsThatHaveChangedStatus.push(props.dataItem)}},model:{value:(props.dataItem.status),callback:function ($$v) {_vm.$set(props.dataItem, "status", $$v)},expression:"props.dataItem.status"}})],1)])]}},{key:"createdOnTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[(_vm.isCloseDateSet(_vm.toDate(props.dataItem.createdOn)))?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(props.dataItem.createdOn))+" ")]):_c('div',[_vm._v(" - ")])])]}},{key:"closeDateTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[(_vm.isCloseDateSet(_vm.toDate(props.dataItem.closeDate)))?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(props.dataItem.closeDate))+" ")]):_c('div',[_vm._v(" - ")])])]}}],null,false,838798531)}),(this.periodsThatHaveChangedStatus.length > 0)?_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"1em"},attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.savePeriodsStatusChanges()}}},[_vm._v("Mentés")])],1)],1):_vm._e()],1):_vm._e(),(this.store.yearBeingChecked < 1)?_c('div',[_c('v-card-text',[_vm._v("Nincs kiválasztva év.")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }