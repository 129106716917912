export type Comparer<T> = (a: T, b: T) => boolean;

export function distinctBy<T>(arr: T[], comparer: Comparer<T>) {
    const result: T[] = []
    for(const item of arr) {
        if(!result.some(v => comparer(item, v))) {
            result.push(item)
        }
    }

    return result
}

export function groupBy<T>(xs: T[], key: string) {
    return xs.reduce(function(rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }