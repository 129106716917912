export default interface IncomingInvoiceSubtype {
  id: number;
  type: number;
  name: string;
  foreign: string;
  english: string;
  isDefault: boolean;
  deleted: boolean;
  isMustAccGroup: boolean;
  owingVatAccCode: string | null;
  technAccVatCode: string | null;
  postCalc: boolean;
  isInvPermit: boolean;
  isWorksheet: boolean;
  isStock: boolean;
  stockOnAccept: boolean;
  readOnly: boolean;
  isUninvoicedStockCorrection: boolean;

  isAlive: boolean;
}

export const incomingInvoiceSubtypeDefaultProps: IncomingInvoiceSubtype = {
  id: 0,
  type: 1,
  name: "-",
  foreign: "-",
  english: "-",
  isDefault: false,
  deleted: false,
  isMustAccGroup: false,
  owingVatAccCode: "-",
  technAccVatCode: "-",
  postCalc: false,
  isInvPermit: false,
  isWorksheet: false,
  isStock: false,
  stockOnAccept: false,
  readOnly: false,
  isUninvoicedStockCorrection: false,

  isAlive: true,
};
