import configuration, { loadConfiguration } from "@/models/Configuration";
import GeneralIngoingInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import Decimal from "decimal.js-light";

export interface InvoicePriceParams {
  currNetto?: number;
  quantity?: number;
  taxDegree?: number;
  exchangeRate?: number;
  currency?: string;
  manualPrecision?: number | undefined;
  unitPrice: number;
}

export enum PrecisionType {
  "default" = "default",
  "head" = "head",
  "geniHead" = "geniHead",
  "geniItem" = "geniItem",
  "geniUnit" = "geniUnit",
}

const DEFAULT_CURRENCY = "HUF";

export const getDecimalPlaces = (
  currency: string,
  type = PrecisionType.default
) => {
  const currDigits = configuration.getCurrDigits(currency);
  if (currDigits) {
    switch (type) {
      case PrecisionType.head:
        return currDigits.headDigits;
      case PrecisionType.geniItem:
        return currDigits.geniItemDigits;
      case PrecisionType.geniHead:
        return currDigits.geniHeadDigits;
      case PrecisionType.geniUnit:
        return currDigits.geniUnitDigits;
      default:
        return currDigits.digits ?? 0;
    }
  } else return 0;
};

export const roundMoney = (
  num: number,
  currency: string,
  decimals?: number,
  precisionType?: PrecisionType
) => {
  const roundingMode = configuration.operel
    ? Decimal.ROUND_DOWN
    : Decimal.ROUND_HALF_CEIL;
  const precision = getDecimalPlaces(currency, precisionType);
  return new Decimal(num).toDecimalPlaces(decimals ?? precision, roundingMode).toNumber();
};

/**
 * Általános költség számítás
 */
export class Prices {
  currNetto = 0;
  taxRate = 1.0;
  exchangeRate = 1.0;
  quantity = 1.0;
  currency = "";
  originalUnitPrice: number;
  manualPrecision?: number | undefined;

  constructor({
    currNetto,
    quantity,
    taxDegree,
    exchangeRate,
    currency,
    manualPrecision,
    unitPrice,
  }: InvoicePriceParams) {
    this.currNetto = currNetto ?? 1.0;
    this.quantity = quantity ?? 1.0;
    this.taxRate = 1.0 + (taxDegree ?? 1.0) / 100.0;
    this.exchangeRate = exchangeRate ?? 1.0;
    this.currency = currency ?? DEFAULT_CURRENCY;
    this.manualPrecision = manualPrecision;
    this.originalUnitPrice = unitPrice;
  }

  get currUnitPrice() {
    if (
      !Object.keys(configuration.roundingUnitPrecisions).includes(this.currency)
    ) {
      return roundMoney(this.quantity != 0 ? (this.currNetto / this.quantity) : this.originalUnitPrice, this.currency, undefined, PrecisionType.geniUnit);
    }
    return roundMoney(this.quantity != 0 ? (this.currNetto / this.quantity) : this.originalUnitPrice, this.currency);
  }

  // Az ÁFA-t kerekítjük
  get currVat() {
    return roundMoney(
      this.currBrutto - this.currNetto,
      this.currency
    );
  }

  get currBrutto() {
    return roundMoney(
      this.currNetto * this.taxRate,
      this.currency
    );
  }

  get unitPrice(): number {
    return roundMoney(this.currUnitPrice * this.exchangeRate, this.currency, undefined, PrecisionType.geniUnit);
  }

  get netto(): number {
    return roundMoney(
      this.currNetto * this.exchangeRate,
      DEFAULT_CURRENCY
    );
  }

  get vat(): number {
    return this.brutto - this.netto;
  }

  get brutto(): number {
    return roundMoney(
      this.netto * this.taxRate,
      DEFAULT_CURRENCY
    );
  }

  applyOn(invoice: GeneralIngoingInvoice) {
    invoice.netto = this.netto;
    invoice.currNetto = this.currNetto;
    invoice.total = this.brutto;
    invoice.currTotal = this.currBrutto;
    invoice.vat = this.vat;
    invoice.currVat = this.currVat;
  }
}
