




import Vue from "vue";
import Layout from "./components/Layout.vue";
import Sticky from "./directives/Sticky";

const App = Vue.extend({
  components: { Layout },
});

Vue.directive("sticky", Sticky);

export default App;
