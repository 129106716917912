import PaymentMode, { paymentModeDefaultProps } from "../models/PaymentMode"
import PaymentModeDisplay from "../models/PaymentModeDisplay"
import WebPayMode from "../models/WebPayMode"
import PaymentModeApi from "./PaymentModeApi"
import busyIndication from "../../../utils/busyIndication";

export class PaymentModeStore {
    paymentModes: PaymentModeDisplay[] = []
    webPayModes: WebPayMode[] = []
    paymentMode: PaymentMode = { ...paymentModeDefaultProps }

    @busyIndication()
    async loadPaymentModes() {
        this.paymentModes = (await PaymentModeApi.getAll()).map((i) => ({ ...i, deleting: false }))
    }

    async loadPaymentModesWithoutBusy(){
        this.paymentModes = (await PaymentModeApi.getAll()).map((i) => ({ ...i, deleting: false }))
    }

    @busyIndication()
    async loadPaymentMode(paymentModeId: number) {
        this.paymentMode = paymentModeId > 1 ? await PaymentModeApi.get(paymentModeId) : { ...paymentModeDefaultProps }
    }

    async loadwebPayModes() {
        this.webPayModes = await PaymentModeApi.getWebPayModes()
    }

    async deletePaymentMode(paymentModeId: number) {
        try {
            await PaymentModeApi.delete(paymentModeId)
            await this.loadPaymentModes()
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async save() {
        if(!this.paymentMode) {
            return
        }

        if(this.paymentMode.id === 0) {
            await this.createPaymentMode()
        } else {
            await this.updatePaymentMode()
        }
    }

    private async createPaymentMode() {
        try {
            await this.loadPaymentModes()
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    private async updatePaymentMode() {
        try {
            await PaymentModeApi.update(this.paymentMode)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    setDeleting(item: PaymentMode) {
        item.deleting = !item.deleting;
        this.paymentModes = [...this.paymentModes];    
    }
}

const store = new PaymentModeStore()
export default store