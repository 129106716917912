var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"align-self":"center"}},[_c('h5',{staticClass:"section-title"},[_vm._v("Terhelési értesítők")])])],1)],1),_c('v-card-text',[_c('grid',{staticClass:"debit-grid",attrs:{"scrollable":"none","columns":_vm.columns,"data-items":_vm.store.debits},scopedSlots:_vm._u([{key:"isRetTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isRet),callback:function ($$v) {_vm.$set(props.dataItem, "isRet", $$v)},expression:"props.dataItem.isRet"}})],1)]}},{key:"originalPriceTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.originalPrice,"currency":props.dataItem.currency}})],1)]}},{key:"modPriceTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.modPrice,"currency":props.dataItem.currency}})],1)]}},{key:"debitPriceTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.debitPrice,"currency":props.dataItem.currency}})],1)]}},{key:"descriptionTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"noWrap"},[_c('label',[_vm._v(_vm._s(props.dataItem.description))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }