import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import BankExtractImportAccountType from "../models/BankExtractImportAccountType";

export default class BankExtractImportAccountTypeApi {
  static getAll(): Promise<BankExtractImportAccountType[]> {
    return http
      .get<ApiResponse<BankExtractImportAccountType[]>>(`bank-extract-import-account-type`)
      .then(handleApiResponse);
  }

  static put(items: BankExtractImportAccountType[]): Promise<boolean> {
    return http.put(`bank-extract-import-account-type`, { items: items }).then(checkResponse);
  }
}
