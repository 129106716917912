var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'list-group-item': true,
    'status-progress': _vm.item.incomingInvoice.status == 1 || _vm.item.incomingInvoice.status == 2,
    'status-issued': _vm.item.incomingInvoice.status == 3,
    'status-partially': _vm.item.incomingInvoice.status == 4,
    'status-paid': _vm.item.incomingInvoice.status == 5 || _vm.item.incomingInvoice.status == 7,
    'status-overpaid': _vm.item.incomingInvoice.status == 6,
  }},[_c('div',{staticClass:"list-group-item-heading"},[_c('div',{staticStyle:{"padding-top":"5px"}},[_c('h5',[_c('a',{on:{"click":function($event){_vm.item.isItemsVisible = !_vm.item.isItemsVisible}}},[_vm._v(_vm._s(_vm.item.incomingInvoice.invoiceNo))]),(_vm.item.invoicePreStatusName)?_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(_vm._s(_vm.item.invoicePreStatusName))]):_vm._e(),(_vm.item.incomingInvoice.isPayed)?_c('v-chip',{staticClass:"mx-1 white--text",attrs:{"label":"","small":"","color":"green"}},[_vm._v("Fizetve")]):_vm._e(),(_vm.item.isPartiallyPayed)?_c('v-chip',{staticClass:"mx-1 white--text",attrs:{"label":"","small":"","color":"green"}},[_vm._v("Részben fizetve")]):_vm._e()],1),_c('div',{staticClass:"grey--text mb-2"},[_vm._v("ID: "+_vm._s(_vm.item.incomingInvoice.id))])]),_c('div',[_c('incoming-invoice-list-item-actions',{attrs:{"invoice":_vm.item}})],1)]),_c('div',{staticClass:"list-group-item-text"},[_c('v-row',[_c('v-col',{staticClass:"address",attrs:{"sm":"3"}},[_c('a',{on:{"click":function($event){return _vm.partnerEdit(_vm.item)}}},[_c('strong',[_vm._v(_vm._s(_vm.item.partnerName))])]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.item.partnerCity))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.item.partnerAddress))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.item.partnerTaxNo))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.item.partnerEuTax))])]),_c('v-col',{attrs:{"sm":"8"}},[_c('grid',{staticClass:"list-group-table border-top",staticStyle:{"padding-top":"10px","width":"100%"},attrs:{"data-items":[_vm.item],"columns":[
            {
              field: 'invoiceDate',
              title: 'Számla dátuma',
              className: 'center_text',
              headerClassName: 'center_text',
              width: 120,
            },
            {
              field: 'incomingInvoice.payTerm',
              title: 'Fizetési határidő',
              headerClassName: 'center_text',
              cell: function (h, _, props) { return h(
                  'td',
                  { class: 'center_text' },
                  !props.dataItem.incomingInvoice.payTerm
                    ? '-'
                    : new Date(props.dataItem.incomingInvoice.payTerm).toLocaleDateString('hu').replace(' ', '')
                ); },
              width: 120,
            },
            {
              field: 'incomingInvoice.regDate',
              title: 'Iktatva',
              headerClassName: 'center_text',
              cell: function (h, _, props) { return h(
                  'td',
                  { class: 'center_text' },
                  new Date(props.dataItem.incomingInvoice.regDate).toLocaleDateString('hu').replace(' ', '')
                ); },
              width: 120,
            },
            {
              field: 'incomingInvoice.accDate',
              title: 'Teljesítve',
              headerClassName: 'center_text',
              cell: function (h, _, props) { return h(
                  'td',
                  { class: 'center_text' },
                  !props.dataItem.incomingInvoice.shipDate
                    ? '-'
                    : new Date(props.dataItem.incomingInvoice.shipDate).toLocaleDateString('hu').replace(' ', '')
                ); },
              width: 120,
            },
            {
              field: 'incomingInvoice.payDate',
              title: 'Fizetve',
              headerClassName: 'center_text',
              cell: function (h, _, props) { return h(
                  'td',
                  { class: 'center_text' },
                  !props.dataItem.incomingInvoice.payDate
                    ? '-'
                    : new Date(props.dataItem.incomingInvoice.payDate).toLocaleDateString('hu').replace(' ', '')
                ); },
              width: 120,
            },
            {
              title: 'Nettó',
              className: 'right_text',
              headerClassName: 'right_text',
              cell: function (h, _, props) {
                var invoice = props.dataItem.incomingInvoice;
                var text = (invoice.currNetto.toLocaleString('hu')) + " " + (invoice.invCurrency);
                return h('td', { class: 'right_text' }, text);
              },
              width: 100,
            },
            {
              title: 'ÁFA',
              cell: function (h, _, props) {
                var invoice = props.dataItem.incomingInvoice;
                var text = (invoice.currVat.toLocaleString('hu')) + " " + (invoice.invCurrency);
                return h('td', { class: 'right_text' }, text);
              },
              headerClassName: 'right_text',
              width: 100,
            },
            {
              title: 'Fizetendő',
              cell: function (h, _, props) {
                var invoice = props.dataItem.incomingInvoice;
                var text = (invoice.currTotal.toLocaleString('hu')) + " " + (invoice.invCurrency);
                return h('td', { class: 'right_text' }, text);
              },
              headerClassName: 'right_text',
              width: 100,
            },
            {
              title: 'Nyitott egyenleg',
              className: 'right_text',
              headerClassName: 'right_text',
              cell: function (h, _, props) {
                var invoice = props.dataItem;
                var text = (invoice.openPrice.toLocaleString('hu')) + " HUF";
                return h('td', { class: 'right_text' }, text);
              },
              width: 120,
            } ],"scrollable":"none"}})],1)],1),_c('v-row',[_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.isItemsVisible),expression:"item.isItemsVisible"}],staticStyle:{"width":"100%"}},[_c('div',{staticClass:"title-bar"},[_c('a',{staticClass:"title a"},[_vm._v("Tételek")])]),_c('div',[_c('grid',{staticClass:"items-grid list-group-table",attrs:{"data-items":_vm.item.items,"columns":[
                { field: 'incomingInvoiceItem.name', title: 'Megnevezés' },
                { field: 'partNo', title: 'Cikkszám' },
                {
                  field: 'incomingInvoiceItem.quantity',
                  title: 'Mennyiség',
                  format: '{0:n}',
                  className: 'right_text',
                  headerClassName: 'right_text',
                },
                {
                  field: 'incomingInvoiceItem.unitPrice',
                  title: 'Egységár',
                  format: '{0:n}',
                  className: 'right_text',
                  headerClassName: 'right_text',
                },
                {
                  field: 'incomingInvoiceItem.netto',
                  title: 'Nettó',
                  format: '{0:n}',
                  className: 'right_text',
                  headerClassName: 'right_text',
                },
                {
                  field: 'incomingInvoiceItem.vat',
                  title: 'ÁFA',
                  format: '{0:n}',
                  className: 'right_text',
                  headerClassName: 'right_text',
                } ],"scrollable":"none"}})],1)])])],1)],1),(_vm.item.typeName.length > 1)?_c('v-chip',{staticClass:"white--text",attrs:{"label":"","x-small":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.item.typeName))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }