












































































































































































































































































































import Vue from "vue";
import store, { CustomsInvoiceStore } from "../services/CustomsInvoiceStore";
import Page from "../../../components/Page.vue";
import GeneralIngoinInvoiceDisplay from "../../generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import CustomsInvoiceDetails from "./CustomsInvoiceDetails.vue";

interface Data {
  store: CustomsInvoiceStore;
  filterShow: boolean;
  currentPageNumber: number;
  currentPageSize: number;
  firstPageNumberButtonVisible: boolean;
  firstPageNumberButtonValue: number;
  secondPageNumberButtonVisible: boolean;
  secondPageNumberButtonValue: number;
  thirdPageNumberButtonVisible: boolean;
  thirdPageNumberButtonValue: number;
  fourthPageNumberButtonVisible: boolean;
  fourthPageNumberButtonValue: number;
  fifthPageNumberButtonVisible: boolean;
  fifthPageNumberButtonValue: number;
  isTableView: boolean;
  isListView: boolean;
  filterText: string;
  invDateStart: string;
  invDateEnd: string;
  payDateStart: string;
  payDateEnd: string;
  invoiceInRelation: boolean;
}

interface Methods {
  createInvoice(): void;
  updateInvoice(item: GeneralIngoinInvoiceDisplay): void;
  setPageNumbers(): void;
  setCurrentPageNumber(pageNumber: number): void;
  decrementPageNumber(): void;
  incrementPageNumber(): void;
  setTableView(): void;
  setListView(): void;
  partnerEdit(item: GeneralIngoinInvoiceDisplay): void;
  filterTextChanged(): void;
  reloadByPayDate(): void;
  reloadByInvDate(): void;
  reload(): void;
}

interface Computed {
  columns: any;
  items: any;
  footerText: any;
  maxPageNumber: any;
  invoices: any;
  invoiceCount: any;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    Page,
    CustomsInvoiceDetails,
  },

  data(): Data {
    return {
      store,
      filterShow: false,
      currentPageNumber: 1,
      currentPageSize: 10,
      firstPageNumberButtonVisible: true,
      firstPageNumberButtonValue: 1,
      secondPageNumberButtonVisible: true,
      secondPageNumberButtonValue: 2,
      thirdPageNumberButtonVisible: true,
      thirdPageNumberButtonValue: 3,
      fourthPageNumberButtonVisible: true,
      fourthPageNumberButtonValue: 4,
      fifthPageNumberButtonVisible: true,
      fifthPageNumberButtonValue: 5,
      isTableView: false,
      isListView: true,
      filterText: '',
      invDateStart: '',
      invDateEnd: '',
      payDateStart: '',
      payDateEnd: '',
      invoiceInRelation: false
    };
  },

  async beforeMount() {
    await this.store.loadInvoices();
  },

  computed: {
    columns: () => [
      {
        field: "incomingInvoice.invoiceNo",
        title: "Vámhatározat száma",
        cell: "invoiceNoTemplate",
      },
      { field: "partnerName", title: "Vámhivatal", cell: "partnerTemplate" },
      { field: "invoiceStatusName", title: "Státusz" },
      { field: "incomingInvoice.total", title: "Vámhatározat összege" },
      { field: "invoiceDate", title: "Vámhatározat dátuma" },
      { field: "paymentDate", title: "Pénzügyi esedékesség" },
    ],

    invoiceCount() {
      return this.invoices.length;
    },

    invoices(){
      let ret = this.store.invoices;
       
      if (this.filterText){
          ret = ret.filter(x => x.incomingInvoice.invoiceNo
                                  .toUpperCase()
                                  .startsWith(this.filterText.toUpperCase())
                                  || x.partnerName
                                    .toUpperCase()
                                    .startsWith(this.filterText.toUpperCase())
                                  || x.incomingInvoice.id.toString()
                                    .toUpperCase() ==
                                    this.filterText.toUpperCase());
      }
      
      if (this.invoiceInRelation){
        ret = ret.filter(x => x.isInRelation);
      }
      
      return ret;
    },

    items() {
      this.setPageNumbers();
      return this.invoices.slice(
        (this.currentPageNumber - 1) * this.currentPageSize,
        (this.currentPageNumber - 1) * this.currentPageSize +
          this.currentPageSize
      );
    },

    footerText() {
      return `${Math.max(
        (this.currentPageNumber - 1) * this.currentPageSize + 1,
        0
      )} - ${Math.min(
        this.currentPageNumber * this.currentPageSize,
        this.invoices.length
      )} / ${this.invoices.length}`;
    },

    maxPageNumber() {
      return Math.ceil(this.invoices.length / this.currentPageSize);
    },
  },

  methods: {
    filterTextChanged() {
      this.currentPageNumber = 1;
    },

    createInvoice() {
      this.$router.push(`/customs-invoice/edit/0`);
    },

    updateInvoice(item: GeneralIngoinInvoiceDisplay) {
      this.$router.push(`/customs-invoice/edit/${item.incomingInvoice.id}`);
    },

    setPageNumbers() {
      if (this.currentPageNumber == 0) {
        return;
      }

      const maxPageNumber = Math.ceil(this.invoiceCount / this.currentPageSize);
      if (maxPageNumber <= 5) {
        this.firstPageNumberButtonVisible = false;
        this.secondPageNumberButtonVisible = false;
        this.thirdPageNumberButtonVisible = false;
        this.fourthPageNumberButtonVisible = false;
        this.fifthPageNumberButtonVisible = false;

        if (maxPageNumber >= 1) {
          this.firstPageNumberButtonVisible = true;
          this.firstPageNumberButtonValue = 1;
        }

        if (maxPageNumber >= 2) {
          this.secondPageNumberButtonVisible = true;
          this.secondPageNumberButtonValue = 2;
        }

        if (maxPageNumber >= 3) {
          this.thirdPageNumberButtonVisible = true;
          this.thirdPageNumberButtonValue = 3;
        }

        if (maxPageNumber >= 4) {
          this.fourthPageNumberButtonVisible = true;
          this.fourthPageNumberButtonValue = 4;
        }

        if (maxPageNumber == 5) {
          this.fifthPageNumberButtonVisible = true;
          this.fifthPageNumberButtonValue = 5;
        }
      } else {
        this.firstPageNumberButtonVisible = true;
        this.secondPageNumberButtonVisible = true;
        this.thirdPageNumberButtonVisible = true;
        this.fourthPageNumberButtonVisible = true;
        this.fifthPageNumberButtonVisible = true;

        if (maxPageNumber - this.currentPageNumber < 5) {
          this.firstPageNumberButtonValue = maxPageNumber - 4;
          this.secondPageNumberButtonValue = maxPageNumber - 3;
          this.thirdPageNumberButtonValue = maxPageNumber - 2;
          this.fourthPageNumberButtonValue = maxPageNumber - 1;
          this.fifthPageNumberButtonValue = maxPageNumber;
        } else {
          if (this.currentPageNumber < 5) {
            this.firstPageNumberButtonValue = 1;
            this.secondPageNumberButtonValue = 2;
            this.thirdPageNumberButtonValue = 3;
            this.fourthPageNumberButtonValue = 4;
            this.fifthPageNumberButtonValue = 5;
          } else {
            this.firstPageNumberButtonValue = this.currentPageNumber - 2;
            this.secondPageNumberButtonValue = this.currentPageNumber - 1;
            this.thirdPageNumberButtonValue = this.currentPageNumber;
            this.fourthPageNumberButtonValue = this.currentPageNumber + 1;
            this.fifthPageNumberButtonValue = this.currentPageNumber + 2;
          }
        }
      }
    },

    setCurrentPageNumber(pageNumber: number) {
      this.currentPageNumber = pageNumber;
    },

    decrementPageNumber() {
      if (this.currentPageNumber == 1) {
        return;
      }

      this.currentPageNumber--;
    },

    incrementPageNumber() {
      const maxPageNumber = Math.ceil(this.invoiceCount / this.currentPageSize);
      if (this.currentPageNumber == maxPageNumber) {
        return;
      }

      this.currentPageNumber++;
    },

    setTableView() {
      this.isTableView = true;
      this.isListView = false;
    },

    setListView() {
      this.isTableView = false;
      this.isListView = true;
    },

    partnerEdit(item: GeneralIngoinInvoiceDisplay) {
      this.$router.push(`/partners/${item.incomingInvoice.partner}`);
    },

    reloadByPayDate() {
      if (this.store.payDateStart && this.store.payDateEnd) {
        this.store.loadInvoices().then((_) => (this.currentPageNumber = 1));
      }
    },

    reloadByInvDate() {
      if (this.store.invDateStart && this.store.invDateEnd) {
        this.store.loadInvoices().then((_) => (this.currentPageNumber = 1));
      }
    },

    reload() {
      this.store.loadInvoices();
    },
  },

  watch: {
    filterText: function() {
      this.filterTextChanged();
    },

    invoiceInRelation: function(){
      this.filterTextChanged();
    },

    invDateStart: function(){
      this.store.invDateStart = this.invDateStart;
      this.reloadByInvDate();
    },

    invDateEnd: function() {
      this.store.invDateEnd = this.invDateEnd;
      this.reloadByInvDate();
    },

    payDateStart: function() {
      this.store.payDateStart = this.payDateStart;
      this.reloadByPayDate();
    },

    payDateEnd: function() {
      this.store.payDateEnd = this.payDateEnd;
      this.reloadByPayDate();
    },
  },
});
