

























































































import Vue from "vue";
import CashStatementStore from "../../services/store";
import CashDocByReceiptsStore from "../services/CashDocByreceiptsStore";
import CashInViewApiSearchPage from "@/modules/cashStatement/cashInView/views/CashInViewApiSearchPage.vue";
import CashOutViewApiSearchPage from "@/modules/cashStatement/cashOutView/views/CashOutViewApiSearchPage.vue";
import BankFlowView from "../models/BankFlowView";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import CashStatement from "../../models/CashStatement";
import CashDocument from "../../models/CashDocument";
import eventBus from "@/services/eventBus";
import { FilteringModel } from "@/services/filtering";
import GeneratedViroPdfViewer from "../components/GeneratedViroPdfViewer.vue";
import base64toUInt8Array from "@/utils/fileConvert";

export default Vue.extend({
  data: () => {
    return {
      CashStatementStore,
      CashDocByReceiptsStore,
      showMultipePartnerQuestion: false,
      items: new Array<BankFlowView>(),
      filter: new FilteringModel(),
      isPrinting: false,
    };
  },

  created() {
    if (Number(this.$props.invoiceIDFilter) > 1) {
      this.filter = new FilteringModel().value(
        "invNo",
        this.$props.invoiceIDFilter
      );
    }
  },

  props: ["invoiceIDFilter"],

  methods: {
    async payInvoices(itemArray: BankFlowView[]) {
      try {
        this.isPrinting = true;
        this.items = itemArray.map((x) => {
          return { ...x, inOut: CashDocByReceiptsStore.inOut };
        });
        const result = await http
          .post<ApiResponse<number[]>>(`open-invoices/pay-invoices`, {
            invoices: this.items,
          })
          .then(handleApiResponse);

        if (result.length > 0) {
          eventBus.$emit("success", "Sikeres bankkártyás fizetés!");
          eventBus.$emit("cash-in-view:reload");
        }
        console.log(result);
        await this.printViros(result);
      } finally {
        this.isPrinting = false;
      }
    },

    async printViros(viros: number[]) {
      this.CashDocByReceiptsStore.generatedViroPdfs = await http
        .post<ApiResponse<string[]>>(`open-invoices/print-viros`, { viros })
        .then(handleApiResponse)
        .then((x) => {
          return x.map((y) => {
            return base64toUInt8Array(y);
          });
        });
      console.log(this.CashDocByReceiptsStore.generatedViroPdfs);
    },

    async generate(forEachPartner: boolean) {
      this.$emit("cancel");
      await http
        .post<ApiResponse<number>>(
          `cash-doc-item/${CashStatementStore.cashStatement.id}/${forEachPartner}/generate-cash-doc`,
          this.items
        )
        .then((x) => {
          CashStatementStore.loadCashDocumentsByStatementId(
            CashStatementStore.cashStatement.id
          );
          if (typeof x.data.data === "number")
            this.$router.push(
              `/cash-statement/edit/${this.CashStatementStore.cashStatement.id}/${x.data.data}`
            );
        });
    },

    async createDocumentByItems(itemArray: BankFlowView[]) {
      try {
        eventBus.$emit("cash-documents:load", true);
        this.items = itemArray.map((x) => {
          return { ...x, inOut: CashDocByReceiptsStore.inOut };
        });

        if (itemArray.some((x) => x.partner !== itemArray[0].partner)) {
          this.showMultipePartnerQuestion = true;
          window.scrollTo(0, 0);
        } else {
          this.$emit("cancel");
          await http
            .post<ApiResponse<number>>(
              `cash-doc-item/${CashStatementStore.cashStatement.id}/generate-cash-doc`,
              this.items
            )
            .then((x) => {
              CashStatementStore.loadCashDocumentsByStatementId(
                CashStatementStore.cashStatement.id
              );
              if (typeof x.data.data === "number")
                this.$router.push(
                  `/cash-statement/edit/${this.CashStatementStore.cashStatement.id}/${x.data.data}`
                );
            });
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        eventBus.$emit("cash-documents:load", false);
      }
    },
  },

  computed: {
    comp() {
      return CashDocByReceiptsStore.inOut
        ? CashInViewApiSearchPage
        : CashOutViewApiSearchPage;
    },
  },

  components: {
    CashInViewApiSearchPage,
    CashOutViewApiSearchPage,
    GeneratedViroPdfViewer,
  },
});
