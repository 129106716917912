// @listLength: number,
// @currentPage: number,
// @pageSize: number,
//
// returns: `83 / 16 - 20`

export default function createPagerText(listLength: number, currentPage: number, pageSize: number): string {
  return `${listLength} / ${Math.max((currentPage - 1) * pageSize + 1, 0)} - ${Math.min(
    currentPage * pageSize,
    listLength
  )}`;
}
