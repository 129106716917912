import { ValidationRules } from "@/models/Validation";

export interface TaxType{
    id: number;
    name: string;
}

export const taxTypeDefaultProps: TaxType = {
    id: 0,
    name:''
}

export default interface Tax {
    id: number;
    name: string;
    degree: number;
    foreign: string;
    english: string;
    deleted: boolean;
    isInverseTax: boolean;
    readOnly: boolean;
    specTax: boolean;
    diffTax: boolean;
    maxDegree: boolean;
    office: number;
    bankAccount: string;
    toDate: Date;
    fromDate: Date;
    taxType: number;
    remark: string;
    withoutVat: boolean;
    isRealInvItem: boolean;
    deleting: boolean;
}

export const taxDefaultProps: Tax = {
    id: 0,
    name: '',
    degree:0,
    english:'',
    bankAccount:'',
    deleted: false,
    withoutVat:false,
    isInverseTax: false,
    fromDate:new Date('1990, 0, 1'),
    readOnly: false,
    remark:'',
    foreign:'',
    specTax: false,
    isRealInvItem:false,
    taxType: 0,
    diffTax: false,
    maxDegree: false,
    office:0,
    toDate:new Date('1990, 0, 1'),
    deleting:false
}

export const rules: ValidationRules = {
    name: [
        (v: string) => !!v || 'Név megadása kötelező'
    ],
    degree: [
        (v: string) => !!v || 'Áfa százalék megadása kötelező'
    ],
}