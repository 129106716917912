import GeneralIngoingInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import { InvoiceItemList } from "@/modules/invoiceItems/models";
import Decimal from "decimal.js-light";
import configuration from "@/models/Configuration";

export class IncomingInvoiceValidator {
  constructor(
    protected invoice: GeneralIngoingInvoice,
    protected itemList: InvoiceItemList
  ) { }

  validateItems() {
    if (this.itemList.items.length === 0) {
      return "A számlának nincsen tétele!";
    }
    return this.errorValidationBody();
  }

  errorValidationBody() {
    if (this.invoice.dpaymIncl) {
      const nettoDiff = new Decimal(this.invoice.currNetto)
        .minus(
          this.itemList.items
            .filter((x) => !x.dpaymItem)
            .reduce((a, b) => a + b.nettoValue, 0) -
          this.itemList.items
            .filter((x) => x.dpaymItem)
            .reduce((a, b) => a + b.nettoValue, 0) -
          this.itemList.dpaymNettos
        )
        .abs()
        .toNumber();

      const bruttoDiff = new Decimal(this.invoice.currTotal)
        .minus(
          this.itemList.items
            .filter((x) => !x.dpaymItem)
            .reduce((a, b) => a + b.bruttoValue, 0) -
          this.itemList.items
            .filter((x) => x.dpaymItem)
            .reduce((a, b) => a + b.bruttoValue, 0) -
          this.itemList.dpaymBruttos
        )
        .abs()
        .toNumber();

      if (Math.max(Number(nettoDiff), Number(bruttoDiff)) >= 1) {
        return "A számla és a tételek összege nem egyezik meg!";
      }
    } else {
      if (!configuration.onlyRectItem.enabled) {
        const nettoDiff = Number(Math.abs(this.invoice.currNetto - this.itemList.netto));
        const bruttoDiff = Number(Math.abs(this.invoice.currTotal - this.itemList.brutto));

        if (Math.max(nettoDiff, bruttoDiff) >= 1) {
          return "A számla és a tételek összege nem egyezik meg!";
        }
      }
      else {
        const nettoDiff = Number(Math.abs(this.invoice.currNetto - this.itemList.onlyRectNetto));
        const bruttoDiff = Number(Math.abs(this.invoice.currTotal - this.itemList.onlyRectBrutto));

        if (Math.max(nettoDiff, bruttoDiff) >= 1) {
          return "A számla és a tételek összege nem egyezik meg!! ";
        }
      }
    }
    return "";
  }

  validate() {
    if (!this.invoice.invoiceNo) {
      return "A számlaszám megadása kötelező!";
    }

    if (this.invoice.currNetto === 0) {
      return "A számla nettó összege nem lehet 0 !";
    }

    if (this.invoice.currTotal === 0) {
      return "A számla bruttó összege nem lehet 0 !";
    }

    if (this.itemList.items.length === 0) {
      return "Legalább 1 számla tétel megadása kötelező!";
    }

    const invoiceItemError = this.validateItems();
    if (invoiceItemError) {
      return invoiceItemError;
    }

    return "";
  }

  canSave() {
    return (
      !!this.invoice.invoiceNo
    );
  }

  canApprove() {
    const validationError = this.validate();
    return !validationError;
  }
}

export class IncomingInvoiceWarningValidator {
  constructor(
    protected invoice: GeneralIngoingInvoice,
    protected itemList: InvoiceItemList
  ) { }

  validate() {
    return this.warningValidationBody();
  }

  warningValidationBody() {
    if (this.invoice.dpaymIncl) {
      const nettoDiff = new Decimal(this.invoice.currNetto)
        .minus(
          this.itemList.items
            .filter((x) => !x.dpaymItem)
            .reduce((a, b) => a + b.nettoValue, 0) -
          this.itemList.items
            .filter((x) => x.dpaymItem)
            .reduce((a, b) => a + b.nettoValue, 0) -
          this.itemList.dpaymNettos
        )
        .abs()
        .toNumber();

      const bruttoDiff = new Decimal(this.invoice.currTotal)
        .minus(
          this.itemList.items
            .filter((x) => !x.dpaymItem)
            .reduce((a, b) => a + b.bruttoValue, 0) -
          this.itemList.items
            .filter((x) => x.dpaymItem)
            .reduce((a, b) => a + b.bruttoValue, 0) -
          this.itemList.dpaymBruttos
        )
        .abs()
        .toNumber();

      if (Math.max(Number(nettoDiff), Number(bruttoDiff)) >= 1) {
        return "A számla és a tételek összege nem egyezik meg!";
      }
    } else {
      if (!configuration.onlyRectItem.enabled) {
        const nettoDiff = Number(Math.abs(this.invoice.currNetto - this.itemList.netto));
        const bruttoDiff = Number(Math.abs(this.invoice.currTotal - this.itemList.brutto));

        if (Math.max(nettoDiff, bruttoDiff) >= 1) {
          return "A számla és a tételek összege nem egyezik meg!";
        }
      }
      else {
        const nettoDiff = Number(Math.abs(this.invoice.currNetto - this.itemList.onlyRectNetto));
        const bruttoDiff = Number(Math.abs(this.invoice.currTotal - this.itemList.onlyRectBrutto));

        if (Math.max(nettoDiff, bruttoDiff) >= 1) {
          return "A számla és a tételek összege nem egyezik meg!";
        }
      }
    }
    return "";
  }
}

export class OperelIncomingInvoiceValidator extends IncomingInvoiceValidator {
  invoiceDisplay?: GeneralIngoinInvoiceDisplay;

  constructor(
    invoice: GeneralIngoingInvoice,
    itemList: InvoiceItemList,
    invoiceDisplay: GeneralIngoinInvoiceDisplay | undefined = undefined
  ) {
    super(invoice, itemList);
    this.invoiceDisplay = invoiceDisplay;
  }

  validate() {
    if (!this.invoice.invoiceNo) {
      return "A számlaszám megadása kötelező!";
    }

    // if (this.invoice.currNetto === 0) {
    //   return "A számla nettó összege nem lehet 0 !";
    // }

    // if (this.invoice.currTotal === 0) {
    //   return "A számla bruttó összege nem lehet 0 !";
    // }

    if (this.itemList.items.length === 0) {
      return "Legalább 1 számla tétel megadása kötelező!";
    }

    if (this.invoiceDisplay?.isStockRelated) {
      return "";
    }

    const sumStockNetto = this.itemList.sumNettoStockValue;

    if (!sumStockNetto) {
      return this.validateItems();
    }

    return "";
  }

  shouldConfirmInvoiceValues() {
    const sumNettoStockValue = this.itemList.sumNettoStockValue;
    const itemValueMismatch = this.invoice.currNetto !== this.itemList.netto;
    return (
      itemValueMismatch &&
      sumNettoStockValue &&
      this.invoice.currNetto > sumNettoStockValue
    );
  }

  shouldConfirmInvoiceNetto() {
    return (
      this.invoice.currNetto == 0 || this.invoice.currTotal == 0
    );
  }
}
