export default interface GeneralIngoingInvoiceItem {
  cuid: string;
  id: number;
  name: string;
  invNo: number;
  gparts: number;
  unitPrice: number;
  quantity: number;
  unit: number;
  netto: number;
  tax: number;
  vat: number;
  brutto: number;
  accGroup: number;
  accCode: string;
  note: string;
  normalQ: number;
  outQ: number;
  separQ: number;
  inVatCount: boolean;
  isRegTax: boolean;
  car: number;
  prcar: number;
  currUnitPrice: number;
  currNetto: number;
  currVat: number;
  currBrutto: number;
  fixassets: boolean;
  accGroupTempl: number;
  dimcode2: string;
  postCalc: number;
  purchreqItem: number;
  purchNetto: number;
  purchCurrNetto: number;
  isProject: boolean;
  parts: number;
  stock: number;
  dutyPercentage: number;
  statCode: string;
  claim: number;
  dutyBase: number;
  vatBase: number;
  editing: boolean;
  selectedField: boolean;
  expanded: boolean;
  weightPerUnit: number;
  totalWeight: number;
  weightUnit: string;
  service: number;
  serviceId: number;
  originalItemId: number;
}

export const generalIngoingInvoiceItemDefaultProps: GeneralIngoingInvoiceItem = {
  cuid: "",
  id: 0,
  name: "",
  invNo: 1,
  gparts: 1,
  unitPrice: 0,
  quantity: 0,
  unit: 1,
  netto: 0,
  tax: 1,
  vat: 0,
  brutto: 0,
  accGroup: 1,
  accCode: "",
  note: "",
  normalQ: 0,
  outQ: 0,
  separQ: 0,
  inVatCount: true,
  isRegTax: false,
  car: 1,
  prcar: 1,
  currUnitPrice: 0,
  currNetto: 0,
  currVat: 0,
  currBrutto: 0,
  fixassets: false,
  accGroupTempl: 1,
  dimcode2: "",
  postCalc: 1,
  purchreqItem: 1,
  purchNetto: 0,
  purchCurrNetto: 0,
  isProject: false,
  parts: 1,
  stock: 1,
  dutyPercentage: 0,
  statCode: "",
  claim: 1,
  dutyBase: 0,
  vatBase: 0,
  editing: true,
  selectedField: false,
  expanded: false,
  weightPerUnit: 0.0,
  totalWeight: 0.0,
  weightUnit: '',
  service: 1,
  serviceId: 1,
  originalItemId: 1,
};
