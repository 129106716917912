export default interface BankRemitApproval {
    id: number;
    bankRemit: number;
    userId: number;
    userName: string;
    insDate: Date | string;
    accDate: Date | string;
    acceptorId: number;
    acceptorName: string;
    remark: string;
}

export const bankRemitApprovalDefaultProps: BankRemitApproval ={
    id: 1,
    bankRemit: 1,
    userId: 1,
    userName: "",
    insDate: "2000. 01. 01",
    accDate: "2000. 01. 01",
    acceptorId: 1,
    acceptorName: "",
    remark: "",
}