




























import Vue from "vue";
import eventBus from "../../../services/eventBus";
import http, { handleApiResponse } from "../../../services/http";
import Attachment from "../models/Attachment";
import { convertToBase64 } from "../../../utils/fileFunctions";
import { getOperelToken } from "@/services/auth";

interface Data {
  browsing: boolean;
  file: File | null;
}

interface Props {
  invoiceID: number;
  supplierID: number;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    invoiceID: Number,
    supplierID: Number,
  },

  data: () => ({
    browsing: false,
    file: null,
  }),

  computed: {
    canUpload() {
      return !!this.file;
    },
  },

  methods: {
    async upload() {
      const file: File | null = this.file;
      if (!file) {
        return;
      }

      const fileContents = await convertToBase64(file);

      console.log(file.size);
      if (file.size > 50 * 1024 * 1024) {
        eventBus.$error("A számla kép nem lehet nagyobb mint 50MB.");
        return;
      }

      const attachment: Attachment = {
        id: this.invoiceID,
        file: fileContents,
        note: file.name,
        size: file.size,
        name: file.name,
        link: "",
        fileBase64: fileContents,
        mimeType: file.type,
        webkitRelativePath: "",
        lastModified: file.lastModified,
      };

      const data = {
        id: this.invoiceID,
        operelToken: getOperelToken(),
        supplierID: this.supplierID,
        files: [attachment],
      };

      this.browsing = false;
      eventBus.$emit("busy-message", true);

      http
        .post(`general-incoming-invoice/files`, data)
        .then(handleApiResponse)
        .then(() => {
          eventBus.$emit("busy-message", false);
          eventBus.$emit("success", "Sikeres számlakép feltöltés!");
        })
        .catch(() => eventBus.$emit("busy-message", false));
    },

    browse() {
      this.browsing = true;
    },

    filesChanged(file: File) {
      this.file = file;
    },
  },
});
