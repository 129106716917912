






import Vue from "vue";
import InvoiceLedgersTable from "./InvoiceLedgersTable.vue";

export default Vue.extend({
  components: { InvoiceLedgersTable },
});
