
































































import Vue from "vue";
import store, { FixAssetGroupStore } from "../services/FixAssetGroupStore";
import dStore, { DocumentTypeStore } from "../../documentType/services/DocumentTypeStore";
import FixAssetDocumentTemplate from "../models/FixAssetDocumentTemplate";

interface Data {
  store: FixAssetGroupStore;
  dStore: DocumentTypeStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
      dStore,
    };
  },

  async beforeMount() {
    await this.dStore.loadDocumentTypes();
    await this.store.loadFixAssetGroup(Number(this.$route.params.fixAssetGroupId));
  },

  methods: {
    createNew() {
      const newItem: FixAssetDocumentTemplate = {
        id: 1,
        documentType: 1,
        documentTypeName: "",
        toolGroup: this.store.fixAssetGroup.id,
        readOnly: false,
        isRequired: false,
        documentId: null,
      };
      this.store.fixAssetGroup.documentTypes.push(newItem);
    },

    async save(item: FixAssetDocumentTemplate) {
      if (item.documentTypeName != "") {
        await this.store.save(item);
      }

      this.store.fixAssetGroup.documentTypes = this.store.fixAssetGroup.documentTypes.filter(
        (x: FixAssetDocumentTemplate) => x.id != 1 && x.documentTypeName != item.documentTypeName
      );

      await this.store.loadFixAssetGroup(Number(this.$route.params.fixAssetGroupId));
    },
  },

  computed: {
    columns: () => [
      {
        field: "documentTypeName",
        title: "Név",
        cell: "documentTypeNameTemplate",
      },
      {
        field: "isRequired",
        title: "Kötelező",
        cell: "isRequiredTemplate",
      },
      {
        width: 50,
        cell: "commandTemplate",
      },
    ],
  },
});
