export default interface InvoiceGroupApproval {
    partnerId: number;
    partnerName: string;
    invId: number;
    invNo: string;
    id: number;
    regCode: string;
    orderId: number;
    partsId: number;
    partsName: string;
    partCode: string;
    invGroupId: number;
    invGroupName: string;
    netto: number;
    currency: string;
    recordId: number;
    approvalTypeId: number;
    approvalTypeName: string;
    typeName: string;
    modUser: string;
    daysSpentInStatus: number;
    apprType: string;
}

export const invoiceGroupApprovalDefaultProps: InvoiceGroupApproval = {
    partnerId: 0,
    partnerName: '',
    invId: 0,
    invNo: '',
    id: 0,
    regCode: '',
    orderId: 0,
    partsId: 0,
    partsName: '',
    partCode: '',
    invGroupId: 0,
    invGroupName: '',
    netto: 0,
    currency: '',
    recordId: 0,
    approvalTypeId: 0,
    approvalTypeName: '',
    typeName: '',
    modUser: '',
    daysSpentInStatus: 0,
    apprType: '',
}
