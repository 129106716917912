


































import PreDownPaymentTab from "../components/PreDownPaymentTab.vue";

import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";

interface Data {
  store: DownPaymentStore;
}

export default Vue.extend({
  components: { PreDownPaymentTab },

  data: (): Data => ({
    store,
  }),

  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
});
