<template>
  <div>
    <div v-if="isBusy" class="busy-overlay">
      <i class="fa fa-refresh fa-spin busy-spinner"></i>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Vue from "vue";
import eventBus from "../services/eventBus";

export default Vue.extend({
  data() {
    return {
      isBusy: false,
    };
  },

  created() {
    eventBus.$on("busy-message", this.handleBusyMessage);
  },

  beforeDestroy() {
    eventBus.$off("busy-message", this.handleBusyMessage)
  },

  methods: {
    handleBusyMessage(busy) {
      this.isBusy = busy;
    },
  },
});
</script>

<style scoped>
.busy-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(187, 187, 187, 0.9);
}

.busy-spinner {
  font-size: 60px;
  color: #d3d4db;
  display: block;
  margin-top: -150px;
}
</style>
