<template>
  <div>
    <div class="align-center justify-center ml-6 menuItem">
      <router-link tag="div" :to="item.url" class="link">
        <div outlined tile min-width="110px" class="pl-1 my-0 subMenuItem">
          <v-row class="py-3">
            <v-col class="align-center justify-center">
              <h3>{{ item.title }}</h3>
            </v-col>
          </v-row>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
  },
};
</script>

<style scoped>
.menuItem {
  background-color: white;
}

.subMenuItem {
  cursor: pointer;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.link.router-link-exact-active .subMenuItem:hover {
  background-color: var(--primaryColorHover);
}

.link:not(.router-link-exact-active) .subMenuItem:hover {
  background-color: #eee;
}
</style>
