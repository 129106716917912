









































































import Vue from "vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import CashPointEdit from "@/modules/cashPoint/models/CashPointEdit";
import store, { CashStatementStore } from "../services/store";
import eventBus from "@/services/eventBus";

enum StatementStatusEnum{
  "-"=1,
  "Feldolgozás alatt" = 2,
  "Nem módosítható" = 3
}

interface Data {
  store: CashStatementStore;
  toCashPointId: number;
  amount: number;
  docNo: string;
  partnerName: string;
}

interface Methods {
  save(): void;
  cancel(): void;
  cashPointSelected(cashPoint: CashPointEdit): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: { NumericTextBox },

  data() {
    return {
      store,
      toCashPointId: 0,
      amount: 0,
      docNo: "",
      partnerName: "",
    };
  },

  async beforeMount() {
    await this.store.loadCashPointsShort();
    this.partnerName = this.store.cashPoint.partner;
  },

  methods: {
    save() {
      if (this.toCashPointId == 0) {
        eventBus.$emit(
          "error",
          "A pénztár helytelenül, vagy nem lett megadva!"
        );
        return;
      }
      if (this.amount <= 0) {
        eventBus.$emit("error", "Az összeg nem lehet nulla, vagy negatív!");
        return;
      }
      if (
        this.store.cashStatement.closeBal - this.amount < 0 &&
        !this.store.cashPoint.isNegative
      ) {
        eventBus.$emit("error", "Az egyenleg nem mehet negatívba!");
        return;
      }

      this.$emit("save", {
        toCashPointId: this.toCashPointId,
        amount: this.amount,
        docNo: this.docNo,
        partnerName: this.partnerName,
      });
    },

    cancel() {
      this.$emit("cancel");
    },

    cashPointSelected(cashPoint: CashPointEdit) {
      if (cashPoint?.id) {
        this.toCashPointId = cashPoint.id;
        return;
      }
      this.toCashPointId = 0;
    },
  },

  computed: {
    items() {
      const ret = this.store.cashPoints
        .filter((x) => x?.lastStatement?.status??StatementStatusEnum["Feldolgozás alatt"] === StatementStatusEnum["Feldolgozás alatt"]) 
        .filter((x) => x.currency == this.store.cashPoint.currency)


      ret.sort((a, b) => a.name.localeCompare(b.name));
      return ret;
    },
  },
});


