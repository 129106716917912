



















import Vue from "vue";
import Page from "../../../components/Page.vue";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import Partner from "../../partner/models/Partner";
import configuration, {AppConfiguration} from "@/models/Configuration";
import GeneralIngoingInvoiceApi from "../services/GeneralIngoingInvoiceApi";

interface Methods {
  partnerSelected(partner: Partner): void;
  cancel(): void;
}

export default Vue.extend<{}, Methods, {}, {}>({
  data() {
    return { };
  },

  components: {
    Page,
    PartnerSearch,
  },

  methods: {
    partnerSelected(partner: Partner) {
      const meta = this.$route.meta.isPictureRequired;
      const currentUrl = window.location.href;

      if (configuration.invoiceFlow.enabled) {
        if (currentUrl.includes("diff-vat")) {
          this.$router.push(`/general-incoming-invoice/flow/create/diff-vat/${partner.id}`);
        }
        else{
          this.$router.push(`/general-incoming-invoice/flow/create/${partner.id}`);
        }
        this.$route.meta.isPictureRequired = meta;
      } else {
        this.$router.push(`/general-incoming-invoice/create/${partner.id}`);
        this.$route.meta.isPictureRequired = meta;
      }
    },

    cancel() {
      this.$router.go(-1);
    }
  },
  
});
