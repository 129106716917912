
























// Components
import PageListView from "@/components/PageListView.vue";

// Models
import Vue from "vue";
import UninvoicedStockCorrection from "../models/UninvoicedStockCorrection";
import store, { UninvoicedStockCorrectionStore } from "../services/store";

interface Data {
  store: UninvoicedStockCorrectionStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { PageListView },

  data: () => ({
    store,
  }),

  async beforeMount() {
    await this.store.loadAll();
  },

  methods: {
    editItem(item: UninvoicedStockCorrection) {
      this.$router.push(`/uninvoiced-stock-correction/edit/${item.id}`);
    },

    create() {
      this.$router.push(`/uninvoiced-stock-correction/create`);
    },

    async refresh() {
      await this.store.loadAll();
    },
  },

  computed: {
    columns: () => [
      {
        field: "id",
        title: "Azonosító",
        className: "adjust_left",
        headerClassName: "adjust_left",
        cell: "idTemplate",
      },
      {
        field: "partnerName",
        title: "Szállító",
        className: "adjust_left",
        headerClassName: "adjust_left",
      },
      {
        field: "invoiceNo",
        title: "Bizonylat szám",
        className: "adjust_left",
        headerClassName: "adjust_left",
      },
      {
        field: "subtypeName",
        title: "Indoklás",
        className: "adjust_left",
        headerClassName: "adjust_left",
      },
      {
        field: "preStatusName",
        title: "Számla státusz",
        headerClassName: "center_text",
        cell: (h: Function, _: any, props: any) =>
          h("td", { class: "center_text" }, [
            h(
              "span",
              {
                class: {
                  "badge justify-center": props.dataItem.preStatus > 2,
                  orange: props.dataItem.preStatus == 3,
                  "deep-orange": props.dataItem.preStatus == 4,
                  green: props.dataItem.preStatus == 5 || props.dataItem.preStatus == 8,
                  "red darken-2": props.dataItem.preStatus == 6 || props.dataItem.preStatus == 7,
                },
              },
              props.dataItem.preStatusName
            ),
          ]),
      },
      {
        field: "total",
        title: "Bruttó",
        className: "right_text adjust_right",
        headerClassName: "right_text adjust_right",
        cell: (h: Function, _: any, props: any) =>
          h(
            "td",
            { class: "right_text adjust_right" },
            `${props.dataItem.total.toLocaleString()} ${props.dataItem.invCurrency}`
          ),
      },
      {
        field: "insertDate",
        title: "Rögzítés dátuma",
        className: "center_text adjust_center",
        headerClassName: "center_text adjust_center",
      },
    ],

    invoices() {
      return this.store.invoices;
    },
  },
});
