








































import Vue from "vue";
import wizardStore, {
  InvoiceWizardStep,
  InvoiceWizardStore,
} from "@/modules/invoiceFlowTask/services/InvoiceTaskWizard";
import flowStore, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import dpaymStore, { DownPaymentStore } from "@/modules/downPayment/services/store";
import permStore, { PermissionStore } from "@/services/permissions";
import GeneralIngoingInvoiceEditModelApi from "@/modules/generalIngoingInvoiceEditModel/services/GeneralIngoingInvoiceEditModelApi";
import { PermitStatuses } from "@/modules/generalIngoingInvoice/models/PermitStatus";
import eventBus from "@/services/eventBus";
import IconButton from "@/components/IconButton.vue";

interface Data {
  wizardStore: InvoiceWizardStore;
  flowStore: InvoiceFlowStore;
  dpaymStore: DownPaymentStore;
  permStore: PermissionStore;
}

interface Methods {
  taskPage(): void;
  restart(): void;
  move(): void;
  prev(): void;
  next(): void;
  close(): void;
  currentProgress(list: InvoiceWizardStep[] | null | undefined): string;
  updatePermitStatus(): void;
}

interface Computed {
  currentList: string;
  progress: string;
  wizardVisible: boolean | null;
  wizardDisabled: boolean;
  permitVisible: boolean;
  permitDisabled: boolean;
  isFlow: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: { IconButton },

  data: () => ({
    wizardStore,
    flowStore,
    dpaymStore,
    permStore,
  }),

  mounted() {
    const invoiceID = Number(this.$route.params.invoiceId);

    if (!this.wizardStore.wizard) {
      this.wizardStore.get();
    }

    if (this.wizardStore.wizard?.hasItems) {
      this.wizardStore.setCurrentIndex(invoiceID);
    }
  },

  methods: {
    taskPage() {
      // TODO: be legyen zárva?
      // this.close();
      this.$router.push(`/invoice-flow-task`);
    },

    move() {
      this.wizardStore.save();
      const path = this.wizardStore.current?.path;
      if (!path) return;
      this.$router.push(path, () => location.reload());
    },

    restart() {
      this.wizardStore.wizard?.restart();
      this.move();
    },

    prev() {
      this.wizardStore.wizard?.prev();
      this.move();
    },

    next() {
      this.wizardStore.wizard?.next();
      this.move();
    },

    close() {
      this.wizardStore.remove();
    },

    currentProgress(list: InvoiceWizardStep[] | null | undefined) {
      const c = this.wizardStore.current;
      return list && c ? `(${list.findIndex((i) => i.invoiceID == c.invoiceID) + 1}/${list.length})` : "";
    },

    async updatePermitStatus() {
      const c = this.wizardStore.current;
      if (!c || c.invoiceID < 1) return;

      await GeneralIngoingInvoiceEditModelApi.updatePermitStatuses([c.invoiceID], PermitStatuses.Payable);
      eventBus.$emit("success", "Sikeres Utalványozás státusz állítás!");
      if (this.isFlow) this.flowStore.invoice.permitStatus = PermitStatuses.Payable;
      else this.dpaymStore.downPayment.isPayable = true;

      this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
    },
  },

  computed: {
    currentList() {
      return this.wizardStore.currentList;
    },

    progress() {
      return this.wizardStore.progress;
    },

    wizardVisible() {
      const connectionID = localStorage.getItem("trufinance.connectionID");

      return (
        (this.wizardStore.wizard &&
          this.wizardStore.wizard.hasItems &&
          this.wizardStore.wizard.connectionID == connectionID) ??
        false
      );
    },

    wizardDisabled() {
      const invoiceID = Number(this.$route.params.invoiceId);
      return !this.wizardStore.hasInvoice(invoiceID);
    },

    permitVisible() {
      return (this.wizardStore.current?.isPermit && this.permStore.hasPermitting()) ?? false;
    },

    isFlow() {
      const step = Number(this.$route.params.step);
      return step > 1;
    },

    permitDisabled() {
      return this.wizardDisabled || this.isFlow
        ? this.flowStore.invoice.permitStatus == PermitStatuses.Payable
        : this.dpaymStore.downPayment.isPayable;
    },
  },
});
