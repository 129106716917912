var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" Könyvelési főkönyvi számok ")])],1),_c('v-card-text',[_c('ApiSearchPage',{attrs:{"url":_vm.url,"columns":_vm.columns,"groupColumn":'tableNameDisplay',"searchTextOnEnter":true,"autoHideFilterSortButtons":true},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var item = ref.item;
return [(!item.inEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.cancelEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok mentése")])])]}},{key:"accCodeTemplate",fn:function(ref){
var item = ref.item;
return [(!item.inEdit)?_c('span',[_vm._v(" "+_vm._s(item.accCode)+" ")]):_c('v-text-field',{staticStyle:{"padding-top":"10px"},attrs:{"outlined":"","single-line":"","type":"text","hide-details":"auto"},model:{value:(item.accCode),callback:function ($$v) {_vm.$set(item, "accCode", $$v)},expression:"item.accCode"}})]}}])})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }