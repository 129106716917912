











































































































































































































































































import Vue from "vue";
import store, { GeneralLedgerStore } from "../services/store";
import Page from "@/components/Page.vue";
import { Year } from "../models/YearModell";
import dayjs from "dayjs";
import auth from "@/services/auth";
import istore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import { StatusChanged } from "../models/StatusChange";
import Paginator from "@/components/Paginator.vue";
import Pager from "@/models/Pager";
import { SelectablePeriod } from "../models/PeriodModell";
import InvoiceMirrorTable from "../views/InvoiceMirrorTable.vue";
import dimensionsTable from "../components/DimensionsTable.vue";
import PeriodTable from "../components/PeriodTable.vue";
import eventBus from "@/services/eventBus";
import CustomTab from "@/components/CustomTab.vue";

interface VDataHeader {
  text: string;
  value: string;
}

interface Data {
  store: GeneralLedgerStore;
  iStore: InvoiceFlowStore;
  yearsHeaders: VDataHeader[];
  search: string;
  currPage: number;
  itemsPerPage: number;
  addingRecord: boolean;
  pageSizes: number[];

  cstartDate: string;
  cendDate: string;

  pager: Pager;

  tab: number;
  watchingStatus: string | undefined;

  changedStatusItems: Year[];
}
interface Computed {
  periodsSelected: SelectablePeriod;
  items: Year[];
  totalRecords: number;
  pageCount: number;
}

export default Vue.extend<Data, {}, Computed, {}>({
  components: {
    Page,
    Paginator,
    PeriodTable,
    InvoiceMirrorTable,
    dimensionsTable,
    CustomTab,
  },
  data(): Data {
    return {
      tab: 0,
      pager: new Pager(),

      iStore: istore,
      addingRecord: false,
      store: store,
      pageSizes: [4, 8, 16, 50],

      watchingStatus: "",
      cstartDate: "2020-12-12",
      cendDate: "2020-12-12",

      changedStatusItems: [],

      yearsHeaders: [
        { text: "Év", value: "year" },
        { text: "Pénznem", value: "currency" },
        { text: "Kezdő dátum", value: "startDate" },
        { text: "Vég dátum", value: "endDate" },
        { text: "Létreh. felh.", value: "creator" },
        { text: "Periódus szám", value: "periods" },
        { text: "Létreh. dátum", value: "accYear" },
        { text: "Állapot", value: "status" },
        { text: "Zárás dátuma", value: "closeDate" },
      ],

      itemsPerPage: 4,
      search: "",
      currPage: 1,
    };
  },

  watch: {},

  async beforeMount() {
    await this.store.setYears();
    await this.iStore.loadCurrencies();
    await this.store.GetDimSortTypes();
    await this.store.SetMainTypes();
    await this.store.SetDetailedTypes();
  },

  computed: {
    periodsSelected() {
      return this.store.createdYear.periods;
    },
    items() {
      return this.store.years;
    },

    totalRecords() {
      return this.store.years.length;
    },
    pageCount(): number {
      return Number((this.store.years.length / this.itemsPerPage).toFixed());
    },
  },

  methods: {
    formatDate(date: Date) {
      return dayjs(date)
        .format("YYYY-MM-DD-HH:mm")
        .replace("-", ".")
        .replace("-", ".")
        .replace("-", " ");
    },
    rowColor(item: Year) {
      return item.id % 2 === 0 ? "greyRow" : "whiteRow";
    },
    toDate(item: string) {
      return dayjs(item)
        .format("YYYY-MM-DD")
        .replaceAll("-", ".");
    },
    YearThatIsBeingCHecked() {
      return this.store.years.find((x) => x.id == this.store.yearBeingChecked);
    },
    setItemBeingChecked(item: Year) {
      this.store.yearBeingChecked = item.id;
      this.store.setPeriods(this.store.yearBeingChecked);
      eventBus.$emit("api-search-page:refresh");
    },
    newRecord() {
      this.addingRecord = !this.addingRecord;
      this.store.createdYear.periods = this.store.periodSelectables[2];
      this.store.createdYear.creator = auth.userName;
      const f: number[] = [];
      this.store.years.forEach((element) => {
        f.push(Number(element.year));
      });
      this.store.createdYear.startDate = new Date(
        (
          f.sort(function(a, b) {
            return b - a;
          })[0] + 1
        ).toString() + "-01-01"
      );
      this.store.createdYear.name = this.store.createdYear.startDate.getFullYear().toString();
      this.store.createdYear.year = this.store.createdYear.startDate.getFullYear();

      const d = new Date(this.store.createdYear.startDate);
      this.cendDate = d.getFullYear().toString() + "-" + "12-31";

      this.cstartDate =
        this.store.createdYear.startDate.getFullYear() +
        "-" +
        (Number(this.store.createdYear.startDate.getMonth()) + 1 < 10
          ? "0" + (Number(this.store.createdYear.startDate.getMonth()) + 1).toString()
          : (Number(this.store.createdYear.startDate.getMonth()) + 1).toString()) +
        "-" +
        (Number(this.store.createdYear.startDate.getDate()) < 10
          ? "0" + Number(this.store.createdYear.startDate.getDate()).toLocaleString()
          : Number(this.store.createdYear.startDate.getDate()).toString());
    },
    setName() {
      this.store.createdYear.name = this.store.createdYear.year.toString();
    },
    setEndDate() {
      const d = this.cstartDate;
      const dYear = Number(d.split("-")[0]);
      const dMonth = Number(d.split("-")[1]);
      const dDay = Number(d.split("-")[2]);
      const f = new Date(dYear + 1, 0, 0);

      if (dMonth === 1 && dDay === 1) {
        this.cendDate = f.getFullYear().toString() + "-" + (Number(f.getMonth()) + 1).toString() + "-" + f.getDate();
      } else if (dDay === 1 && dMonth != 1) {
        this.cendDate =
          (Number(f.getFullYear()) + 1).toString() +
          "-" +
          (Number(dMonth) - 1 < 10 ? "0" + (Number(dMonth) - 1).toString() : (Number(dMonth) - 1).toString()) +
          "-" +
          new Date(Number(f.getFullYear()) + 1, Number(dMonth) - 1, 0).getDate();
      } else {
        this.cendDate =
          (Number(dYear) + 1).toString() +
          "-" +
          (Number(dMonth) < 10 ? "0" + Number(dMonth).toString() : Number(dMonth).toString()) +
          "-" +
          (Number(dDay) - 1 < 10 ? "0" + (Number(dDay) - 1).toString() : (Number(dDay) - 1).toString());
      }
    },

    async addYear() {
      this.store.createdYear.startDate = new Date(this.cstartDate);
      this.store.createdYear.endDate = new Date(this.cendDate);
      this.addingRecord = false;
      await this.store
        .saveYear()
        .then(async function setyears() {
          await store.setYears();
        })
        .then(function setyearbeingwatched() {
          store.yearBeingChecked = store.years[0].id;
        });
    },

    async saveStatus() {
      const d: StatusChanged[] = [];
      for (const element of this.changedStatusItems) {
        d.push({ id: element.id, status: element.status });
      }
      await this.store.saveStatus(d).then(function reload() {
        store.setPeriods(store.yearBeingChecked);
      });
    },

    onPageChange(page: number) {
      this.currPage = page;
    },
    isCloseDateSet(item: string) {
      const f = dayjs(item)
        .format("YYYY-MM-DD")
        .replaceAll("-", ".");
      return f.toString() === "1901.01.01" ? false : true;
    },
    isCloseTimeSet(item: string) {
      return item != "00:00" ? false : true;
    },
    pagerChanged(obj: any) {
      this.pager = obj;
    },
  },
});
