






















import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import Vue from "vue";
import { SelectableInvoiceMirror } from "../models/TypesForEdit";

interface Data {
  items: SelectableInvoiceMirror[];
  selected: string;
}

interface Props{
    value: string;
    lastItem: boolean;
}

export default Vue.extend<Data, {}, {}, Props>({
  data: () => ({
    items: [],
    selected: ""
  }),

  async beforeMount() {
    this.items = await http
      .get<ApiResponse<SelectableInvoiceMirror[]>>(
        `general-ledger-transaction/selectable-general-ledger-numbers`
      )
      .then(handleApiResponse);
/* 
    this.value = this.lastItem ? this.items[this.items.length].accno : this.items[0].accno;       */
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    lastItem: {
        type: Boolean,
        default: false
    }
  },

  methods: {
    handleChange() {
      this.$emit("input", this.selected);
    },
  }
});
