export default class CreditCard {
  id = 0;
  holder = 1;
  bankAccount = 1;
  cardNumber = "";
  cvc = "";
  status = 2;
  expirationMonth = "";
  expirationYear = "";
  holderName?: string = "";
  bankAccountNumber?: string = "";
  bank?: string = "";
 
  get active() {
    return this.status === 2
  }
  set active(value: boolean) {
    this.status = value ? 2 : 3;
  }

  constructor(dto: Partial<CreditCard> | undefined = undefined) {
    if(dto) {
      Object.assign(this, dto)
    }
  }
}
