



























































































































































































































import Vue from "vue";
import store, { AccountTypeStore } from "../services/AccountTypeStore";
import Page from "../../../components/Page.vue";
import AccountType from "../models/AccountType";

interface Data {
  store: AccountTypeStore;
}
export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadAccountTypes();
  },

  computed: {
    columns: () => [
      { field: "name", title: "Megnevezés" },
      {
        field: "default",
        title: "Alapért.",
        cell: "defaultTemplate",
        editor: "boolean",
      },
      {
        field: "isIn",
        title: "Jóváírás lehet",
        cell: "isInTemplate",
        editor: "boolean",
      },
      {
        field: "isOut",
        title: "Terhelés lehet",
        cell: "isOutTemplate",
        editor: "boolean",
      },
      {
        field: "isInvoice",
        title: "Szla.kiegy.",
        cell: "isInvoiceTemplate",
        editor: "boolean",
      },
      {
        field: "isCred",
        title: "Hitelátvezetés",
        cell: "isCredTemplate",
        editor: "boolean",
      },
      {
        field: "isOver",
        title: "Túlfiz. rend.",
        cell: "isOverTemplate",
        editor: "boolean",
      },
      {
        field: "isTech",
        title: "Tech. fiz. átvezetés",
        cell: "isTechTemplate",
        editor: "boolean",
      },
      {
        field: "isDeb",
        title: "Beszed.megb.típusú ",
        cell: "isDebTemplate",
        editor: "boolean",
      },
      {
        field: "isGeni",
        title: "Beszed.megb. - GENI",
        cell: "isGeniTemplate",
        editor: "boolean",
      },
      { cell: "commandTemplate", width: "100px" },
    ],

    items() {
      return this.store.accountTypes;
    },
  },

  methods: {
    deleteAccountType(item: AccountType) {
      this.store.deleteAccountType(item.id);
    },

    showDetails(item: AccountType) {
      this.$router.push(`/account/account-type/${item.id}/details`);
    },

    editItem(item: AccountType) {
      this.store.setEditing(item);
    },

    itemChange({
      dataItem,
      value,
      field,
    }: {
      dataItem: AccountType;
      value: any;
      field: string;
    }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      this.store.save(item, update);
    },

    cancelEdit(item: AccountType) {
      item.inEdit = false;
      this.store.saveAccountType(this.store.accountType);
    },
  },
});
