

























































import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import AccountNumberAndClaimRelation from "@/modules/customsInvoice/models/AccountNumberAndClaimRelation";
import DateInput from "../../../components/DateInput.vue";
import MoneyInput from "../../../components/MoneyInput.vue";
import InvoiceBankRemitView, { invoiceBankRemitViewDefaultProps } from "../models/InvoiceBankRemitView";
import BankRemitItem, { bankRemitItemDefaultProps } from "../models/BankRemitItem";
import BankRemitApi from "../services/BankRemitApi";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: BankRemitStore;
  bankAccountName: string;

  vatAccounts: AccountNumberAndClaimRelation[];
  dutyAccounts: AccountNumberAndClaimRelation[];

  bankRemitItem: BankRemitItem;
  props: InvoiceBankRemitView;

  errorIndication: boolean;
  errorMessage: string;

  configuration: AppConfiguration;
}

interface Methods {
  getVatAccounts(items: AccountNumberAndClaimRelation[]): void;
  getDutyAccounts(items: AccountNumberAndClaimRelation[]): void;
  bankAccountChanged(value: any): void;
  amountChanged(value: any): void;
  addVatOrDuty(item: InvoiceBankRemitView): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  props: {
    items: Array as () => AccountNumberAndClaimRelation[],
    title: String,
    source: String,
    originalProps: Object as () => InvoiceBankRemitView,
  },

  components: {
    MoneyInput,
    DateInput,
    ValidationError,
  },

  data() {
    return {
      store,
      bankAccountName: "",

      vatAccounts: [],
      dutyAccounts: [],

      bankRemitItem: { ...bankRemitItemDefaultProps },

      props: { ...invoiceBankRemitViewDefaultProps },
      errorIndication: true,
      errorMessage: "Bankszámla kiválasztása kötelező!",
      configuration,
    };
  },

  beforeMount() {
    this.props = { ...this.$props.originalProps };
  },

  mounted() {
    this.getVatAccounts(this.$props.items);
    this.getDutyAccounts(this.$props.items);
  },

  methods: {
    getVatAccounts(items: AccountNumberAndClaimRelation[]) {
      this.vatAccounts = items.filter((x) => x.claimId == 3);
    },

    getDutyAccounts(items: AccountNumberAndClaimRelation[]) {
      this.dutyAccounts = items.filter((x) => x.claimId == 2);
    },

    bankAccountChanged(value: any) {
      this.bankRemitItem.bankAccountName = value;
      this.errorIndication = false;
      this.errorMessage = "";
    },

    amountChanged(value: any) {
      if (Number(value) > this.$props.originalProps.currTotal) {
        this.errorIndication = true;
        this.errorMessage = "Nem haladhatja meg az eredeti összeget!";
      } else {
        if (this.bankRemitItem.bankAccountName != "") {
          this.errorIndication = false;
          this.errorMessage = "Nem haladhatja meg az eredeti összeget!";
        } else {
          this.errorMessage = "Bankszámla kiválasztása kötelező!";
        }
      }
    },

    async addVatOrDuty(item: InvoiceBankRemitView) {
      this.bankRemitItem.bankRemit = this.store.bankRemit.id;
      this.bankRemitItem.prefix = item.code;
      this.bankRemitItem.invoice = item.invoice;
      this.bankRemitItem.amount = item.currTotal;
      this.bankRemitItem.partner = item.partner;
      this.bankRemitItem.partnerName = item.partnerName;
      this.bankRemitItem.invCurrency = item.invCurrency;
      this.bankRemitItem.currAmount = item.currTotal;
      this.bankRemitItem.invNo = item.invNo;
      //this.bankRemitItem.exchangeRate = ;
      this.bankRemitItem.isTransfered = false;
      this.bankRemitItem.pterm = item.pterm;

      const id = Number(await BankRemitApi.createItem(this.bankRemitItem));
      if (id > 1) {
        this.bankRemitItem.itemId = id;
        this.store.bankRemit.items.unshift(this.bankRemitItem);
      }
    },
  },
});
