var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('api-search-page',{ref:"asref",attrs:{"url":'currentAccount/storno-summary',"columns":_vm.columns,"delay":1000,"create":"Kijelöltek sztornó összevezetése"},on:{"create":function($event){return _vm.selectButtonClickHandler()},"before-refresh":function($event){return _vm.beforeRefreshHandler()}},scopedSlots:_vm._u([{key:"selectTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"display":"flex"}},[_c('v-btn',{staticClass:"whiteBtn custom-check-box",class:item.selected ? 'selected' : '',on:{"click":function($event){return _vm.selectChangeHandler(item)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item.selected ? "fa-check-square-o" : "fa-square-o")+" ")])],1)],1)]}},{key:"invDateTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("dateFormatHU")(item.invDate)))])])]}},{key:"stornoDateTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("dateFormatHU")(item.stornoDate)))])])]}},{key:"shipDateTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("dateFormatHU")(item.shipDate)))])])]}},{key:"accDateTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('date-input',{staticStyle:{"height":"31px !important"},model:{value:(item.accDate),callback:function ($$v) {_vm.$set(item, "accDate", $$v)},expression:"item.accDate"}})],1)]}},{key:"currTotalTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.currTotal,item.invCurrency)))])])]}},{key:"currNettoTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.currNetto,item.invCurrency)))])])]}},{key:"currVatTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.currVat,item.invCurrency)))])])]}},{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.total,item.currency)))])])]}},{key:"nettoTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.netto,item.currency)))])])]}},{key:"vatTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.vat,item.currency)))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }