import { ValidationRules } from "@/models/Validation";
import { formatDateISO } from "@/utils/formatDates";
import dayjs from "dayjs";

export class CurrencyRate {
  rateDate = dayjs().format("YYYY-MM-DD");
  centralRate = 1;
  mnbCentralRate = 1;
  currency = "HUF";
  localCurrency = "HUF";
  unit = 1;

  constructor(params?: Partial<CurrencyRate>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

export const rules: ValidationRules = {
  currency: [
    (v: string) => !!v || "Pénznem megadása közelező",
    (v: string) => !(parseFloat(v) >= 0) || "Pénznem nem tartalmazhat számot",
  ],
  centralRate: [
    (v: number) => !!v || "Középárfolyam megadása közelező",
    (v: number) => v.valueOf() > 0 || "Középárfolyam nem lehet nulla, vagy alatti érték",
  ],
  rateDate: [
    (v: number) => !!v || "Dátum megadása kötelező",
    (v: Date | string) => new Date(v) < new Date() || "Jövőbeli dátumot nem lehet megadni",
  ],
  unit: [
    (v: number) => !!v || "Egység megadása közelező",
    (v: number) => v > 0 || "Egység nem lehet nulla, vagy alatti érték",
  ],
};

export const currencyRateDefaultProps: CurrencyRate = {
  rateDate: formatDateISO(new Date()),
  centralRate: 0,
  mnbCentralRate: 1,
  currency: "HUF",
  localCurrency: "",
  unit: 1,
};
