
































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import Vue from "vue";
import store from "../services/store";
import AccComplaint from "../models/AccComplaint";

export default Vue.extend({
  async beforeMount() {
    this.loading = true;
    await store.loadStatuses();
    this.loading = false;
  },

  methods: {
    getGeniLink(geni: string): string {
      return `general-incoming-invoice/flow/edit/${geni}/2`;
    },

    getStatusName(status: number): string {
      return store.statuses.find((x) => x.id == status)?.name ?? "";
    },

    getType(item: AccComplaint): string {
      return item.reQuantity > 0 || item.wasteQuantity > 0
        ? "Mennyiségi"
        : "Érték";
    },
  },

  components: {
    ApiSearchPage,
  },

  data: () => {
    return {
      loading: false,
    };
  },

  computed: {
    url() {
      return "acc-complaints/search";
    },

    columns: (): ApiSearchColumn[] => [
      {
        title: "Partner",
        field: "partner",
      },
      {
        title: "Cikk",
        field: "parts",
      },
      {
        title: "Reklamáció fej",
        field: "complaintHeadID",
      },
      {
        title: "Reklamáció tétel",
        field: "complainedItemID"
      },
      {
        title: "Terhelési értesítő",
        field: "complaintID",
      },
      {
        title: "Előzmény terhelési értesítő",
        cell: "prevComplaint",
        field: "prevDebitNotiId",
      },
      {
        title: "Típus",
        field: "complaintID",
        filterable: false,
        sortable: false,
        cell: "type",
      },
      {
        title: "Státusz",
        field: "status",
        cell: "status",
        multiselect: true,
        multiselectField: "selectedStatuses",
        multiselectOptions: store.statuses.map((x) => ({
          text: x.name,
          value: x.id,
        })),
      },
      {
        title: "Megrendelés",
        field: "order",
      },
      {
        title: "Szállítólevél",
        field: "storeIn",
      },
      {
        title: "Készlet",
        field: "stockID",
      },
      {
        title: "GENI",
        field: "geni",
        cell: "geni",
      },
      {
        title: "Helyesbítő számla?",
        field: "isFixingInv",
        cell: "isFixingInv",
        headerClass: "text-center",
        fieldClass: "text-center"
      },
      {
        title: "Visszáruzott mennyiség",
        field: "reQuantity",
      },
      {
        title: "Selejtezett mennyiség",
        field: "wasteQuantity",
      },
      {
        title: "Értékhelyesbített mennyiség",
        field: "fixedQuantity",
      },
      {
        title: "Helyesbítés összege",
        field: "fixedPrice",
        type: ColumnType.Money,
      },
      {
        title: "Rendezett összeg",
        field: "settledAmount",
        type: ColumnType.Money,
      },
    ],
  },
});
