var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"search-row"},[_c('v-col',{staticClass:"pr-0",attrs:{"sm":"11"}},[_c('v-text-field',{attrs:{"outlined":"","single-line":"","hide-details":"auto","placeholder":"Számla keresése..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"sm":"1"}},[_c('IconButton',{attrs:{"tooltip":"Találatok törlése","icon":"fa-times"},on:{"click":_vm.clear}})],1)],1),_c('grid',{staticClass:"debit-grid",attrs:{"columns":_vm.columns,"data-items":_vm.store.debits},scopedSlots:_vm._u([{key:"isRetTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isRet),callback:function ($$v) {_vm.$set(props.dataItem, "isRet", $$v)},expression:"props.dataItem.isRet"}})],1)]}},{key:"originalPriceTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.originalPrice,"currency":props.dataItem.currency,"decimals":_vm.configuration.currencyDigits.filter(function (x) { return x.currency === props.dataItem.currency; }).length > 0
              ? _vm.configuration.currencyDigits.filter(function (x) { return x.currency === props.dataItem.currency; })[0].geniItemDigits
              : 0}})],1)]}},{key:"modPriceTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.modPrice,"currency":props.dataItem.currency,"decimals":_vm.configuration.currencyDigits.filter(function (x) { return x.currency === props.dataItem.currency; }).length > 0
              ? _vm.configuration.currencyDigits.filter(function (x) { return x.currency === props.dataItem.currency; })[0].geniItemDigits
              : 0}})],1)]}},{key:"debitPriceTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.debitPrice,"currency":props.dataItem.currency,"decimals":_vm.configuration.currencyDigits.filter(function (x) { return x.currency === props.dataItem.currency; }).length > 0
              ? _vm.configuration.currencyDigits.filter(function (x) { return x.currency === props.dataItem.currency; })[0].geniItemDigits
              : 0}})],1)]}},{key:"descriptionTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{staticClass:"noWrap"},[_c('label',[_vm._v(_vm._s(props.dataItem.description))])])]}},{key:"addTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.add(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Kiválasztás")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }