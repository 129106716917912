export default class LetterOfCredit {
  id = 0;
  deleted = false;
  readonly = false;
  bank = 0;
  amount = 0;
  cost = 0;
  currency = "";
  payTerm: Date | null = null;
  submitEvent = 0;
  status = 0;
  partner = 0;
  insDate = new Date();
  insTime = new Date();
  businessProcess = 0;
  submitRequirement = 0;
  payTermDays = 0;
  requirementOkDate: Date | null = null;
}

export class LetterOfCreditDto {
  current = new LetterOfCredit();

  id = 0;
  amount = 0;
  cost = 0;
  currency = "";
  payTerm: Date | null = null;
  requirementOkDate: Date | null = null;
  partnerName = "";
  bankName = "";
  submitEventName = "";
  statusName = "";
  banks: IdNameDto[] = [];
  submitEvents: IdNameDto[] = [];
  statusses: IdNameDto[] = [];
  currencies: string[] = [];
  custOrders: IdNameDto[] = [];
  orders: IdNameDto[] = [];
  businessProcesses: IdNameDto[] = [];
  submitRequirements: IdNameDto[] = [];
  businessProcessName = "";
  submitRequirementName = "";
}

export class IdNameDto {
  id = 0;
  name = "";
}
