
































































































import Vue from "vue";
import ChangePasswordModel from "../models/PasswordChangeModel";
import api from "../services/api";
import eventBus from "@/services/eventBus";
import auth from "@/services/auth";
import { Authentication } from "@/services/auth";

interface Data {
  errorMessage: string | null;
  successMessage: string | null;
  changePasswordModel: ChangePasswordModel;
  auth: Authentication;
}

interface Method {
  postNewPassword(): void;
  isPasswordsOk(): boolean;
  pushToMainPage(): void;
}

export default Vue.extend<Data, Method, {}, {}>({
  data: () => ({
    changePasswordModel: new ChangePasswordModel(),
    errorMessage: null,
    successMessage: null,
    auth
  }),

  methods: {
    pushToMainPage() {
      this.$router.push("/");
    },

    async postNewPassword() {
      this.errorMessage = null;
      this.successMessage = null;
      if (!this.isPasswordsOk()) return;

      const result = await api.PostNewPassword(this.changePasswordModel);
      if (result.length > 0) {
        this.errorMessage = result;
      } else {
        this.errorMessage = null;
        this.changePasswordModel = new ChangePasswordModel();
        this.successMessage = "Jelszó sikeresen megváltoztatva!";
        auth.passwordChangeRequired = false;
        setTimeout(() => {
          this.successMessage = null;
        }, 5000);
      }
    },

    isPasswordsOk() {
      if (
        this.changePasswordModel.newPassword !==
        this.changePasswordModel.newPasswordAgain
      ) {
        this.errorMessage =
          'Az "új jelszó" és az "új jelszó újra" mező értéke nem egyezik!';
        return false;
      }
      if (
        this.changePasswordModel.currentPassword ===
        this.changePasswordModel.newPassword
      ) {
        this.errorMessage = "Az új jelszó megegyezik a jelenlegi jelszóval!";
        return false;
      }
      if (
        this.changePasswordModel.currentPassword.length === 0 ||
        this.changePasswordModel.newPassword.length === 0 ||
        this.changePasswordModel.newPasswordAgain.length === 0
      ) {
        this.errorMessage = "Minden mező kitöltése szükséges!";
        return false;
      }

      return true;
    },
  },
});
