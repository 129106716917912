import Partner, { partnerDefaultProps } from "@/modules/partner/models/Partner";
import {
  TechnicalInvoiceVIRO,
  TechnicalInvoiceVIRI,
  technicalInvoiceVIRODefaultProps,
  technicalInvoiceVIRIDefaultProps,
} from "@/modules/technicalInvoice/models/TechnicalInvoiceModel";
import TechnicalInvoicePmode from "@/modules/technicalInvoice/models/TechnicalInvoicePmodeModel";
import TechnicalInvoiceStatus from "@/modules/technicalInvoice/models/TechnicalInvoiceStatusModel";
import TechnicalInvoiceAccGroup from "@/modules/technicalInvoice/models/TechnicalInvoiceAccGroupModel";
import TechnicalInvoiceAccStatus from "@/modules/technicalInvoice/models/TechnicalInvoiceAccStatusModel";
import TechnicalInvoicePartnerRel from "@/modules/technicalInvoice/models/TechnicalInvoicePartnerRelModel";
import TechnicalInvoiceDivitem from "@/modules/technicalInvoice/models/TechnicalInvoiceDivitemModel";
import TechnicalInvoiceCurrency from "@/modules/technicalInvoice/models/TechnicalInvoiceCurrency";
import {
  TechnicalInvoiceVIROType,
  TechnicalInvoiceVIRIType,
} from "@/modules/technicalInvoice/models/TechnicalInvoiceTypeModel";
import TechnicalInvoiceCreatorUser, {
  technicalInvoiceCreatorUserDefaultProps,
} from "@/modules/technicalInvoice/models/TechnicalInvoiceCreatorUserModel";

import VIRIApi from "./VIRIApi";
import eventBus from "@/services/eventBus";
import { TechnicalInvoiceItemVIRI } from "@/modules/technicalInvoice/models/TechnicalInvoiceItemModel";
import { IdNameDto } from "@/modules/letterOfCredit/letterOfCredit";

export class VIRIStore {
  tInvoiceVIRI: TechnicalInvoiceVIRI = { ...technicalInvoiceVIRIDefaultProps };
  tInvoiceVIRIItems: TechnicalInvoiceItemVIRI[] = [];
  tInvoicePartner: Partner = { ...partnerDefaultProps };

  minDate: Date = new Date("1980-01-01");
  minDateString: string = this.minDate.toISOString().split("T")[0];

  pmodes: TechnicalInvoicePmode[] = [];
  statuses: TechnicalInvoiceStatus[] = [];
  accGroups: TechnicalInvoiceAccGroup[] = [];
  accStatuses: TechnicalInvoiceAccStatus[] = [];
  partnerRels: TechnicalInvoicePartnerRel[] = [];
  divitems: TechnicalInvoiceDivitem[] = [];
  currencies: TechnicalInvoiceCurrency[] = [];
  VIRIInvTypes: TechnicalInvoiceVIRIType[] = [];
  creatorUser: TechnicalInvoiceCreatorUser = { ...technicalInvoiceCreatorUserDefaultProps };
  checkTypes: IdNameDto[] = [];

  // TODO
  // invoiceRects: any[] = []; // helyesbítő számla lista, ez egy nagyobb falat lesz

  resetTInvoice() {
    this.tInvoiceVIRI = { ...technicalInvoiceVIRIDefaultProps };
  }

  resetTInvoicePartner() {
    this.tInvoicePartner = { ...partnerDefaultProps };
  }

  resetTInvoiceItems() {
    this.tInvoiceVIRIItems = [];
  }

  async removeItem(tInvoiceItemId: number) {
    return await VIRIApi.removeItem(tInvoiceItemId);
  }

  typeNyitoBejovoIsValid() {
    let viriIsValid = true;

    if (viriIsValid) {
      if (this.tInvoicePartner.id === 1 || this.tInvoicePartner.name.trim().length === 0) {
        eventBus.$emit("error", "Válasszon ki egy partnert!");
        return (viriIsValid = false);
      }
    }

    if (viriIsValid) {
      if (this.tInvoiceVIRI.invDate < this.minDate) {
        eventBus.$emit("error", `A számla dátuma nem érvényes dátum!`);
        viriIsValid = false;
      }

      if (this.tInvoiceVIRI.pterm < this.minDate) {
        eventBus.$emit("error", `A számla fizetési határideje nem érvényes dátum!`);
        viriIsValid = false;
      }

      if (this.tInvoiceVIRI.shipDate !== null && this.tInvoiceVIRI.shipDate < this.minDate) {
        eventBus.$emit("error", `A számla teljesítés dátuma nem érvényes dátum!`);
        viriIsValid = false;
        this.tInvoiceVIRI.shipDate = technicalInvoiceVIRODefaultProps.shipDate; // vissza a default értékre
      }

      if (this.tInvoiceVIRI.pterm < this.tInvoiceVIRI.invDate) {
        eventBus.$emit("error", `A fizetési határidő nem lehet korábban mint a számla dátuma!`);
        viriIsValid = false;
      }

      if (this.tInvoiceVIRI.invType < 2) {
        eventBus.$emit("error", `A Hatósági csekk számla típus nem került beállításra!`);
        viriIsValid = false;
      }
    }

    return viriIsValid;
  }

  async saveNewTInvoice(tInvoice: TechnicalInvoiceVIRI) {
      // megkapjuk a válaszba a frissen mentett technikai számla adatait, vagy errort
      const result = await VIRIApi.create(tInvoice);
      return result;
  }

  async saveNewTInvoiceItem(tInvoiceItem: TechnicalInvoiceItemVIRI) {
      // megkapjuk a válaszba a frissen mentett technikai számla adatait, vagy errort
      const result = await VIRIApi.createItem(tInvoiceItem)
      return result;
  }

  async getNewViri()
  {
    this.tInvoiceVIRI = await VIRIApi.getNewVIRI();
    this.tInvoicePartner = await VIRIApi.getPartner(this.tInvoiceVIRI.partner);
    this.tInvoiceVIRIItems = [];
  }

  async getViri(invoiceId: number) {
    this.tInvoiceVIRI = await VIRIApi.getViriInvoice(invoiceId);
    this.tInvoicePartner = await VIRIApi.getPartner(this.tInvoiceVIRI.partner);
    this.tInvoiceVIRIItems = [];
  }

  async getViriItems(invoiceId: number) {
    this.tInvoiceVIRIItems = await VIRIApi.getViriItems(invoiceId);
  }

  async getVIRICheckPartnerFromSysfile2() {
    const VIRICheckPartner = await VIRIApi.getViriCheckPartner();
    this.tInvoicePartner = await VIRIApi.getPartner(VIRICheckPartner);
  }

  async loadPmodes() {
    this.pmodes = await VIRIApi.getPmodes();
  }

  async loadStatuses() {
    this.statuses = await VIRIApi.getStatuses();
  }

  async loadVirCheckTypes() {
    this.checkTypes = await VIRIApi.getVirCheckTypes();
  }

  async loadaccGroups() {
    this.accGroups = await VIRIApi.getAccGroups();
  }

  async loadaccStatuses() {
    this.accStatuses = await VIRIApi.getAccStatuses();
  }

  async loadPartnerRels() {
    this.partnerRels = await VIRIApi.getPartnerRels();
  }

  async loadDivitems() {
    this.divitems = await VIRIApi.getDivitems();
  }

  async loadCurrencies() {
    this.currencies = await VIRIApi.getCurrencies();
  }

  async loadAllInvTypes() {
    this.VIRIInvTypes = await VIRIApi.getVIRIInvoiceTypes();
  }

  async loadOpenerInvTypes() {
    this.VIRIInvTypes = await VIRIApi.getOpenerVIRIInvoiceTypes();
  }

  async loadCreatorUser(userId: number) {
    this.creatorUser = await VIRIApi.getCreatorUser(userId);
  }
}

export const store = new VIRIStore();
