var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"my-5"},[_c('v-card-title',{staticClass:"mb-0"},[_vm._v(" Számla információk "),_c('v-spacer'),_c('icon-button',{attrs:{"top":"","btnClasses":"ml-2","icon":"fa-bars"},on:{"click":function($event){_vm.invoiceDetailsVisible = !_vm.invoiceDetailsVisible}}})],1),_c('v-expand-transition',[_c('CustomTab',[_c('v-tab',[_vm._v("Számla kép")]),_c('v-tab-item',[_c('v-expand-transition',[(_vm.invoiceDetailsVisible)?_c('div',{staticClass:"mt-3"},[_c('v-card-text',[_c('div',[_c('div',{staticClass:"iframe-container"},[_c('v-expand-transition',[(
                          _vm.store.attachment.link != '' &&
                            (_vm.store.attachment.name !== null ? _vm.store.attachment.name.length : 0) > 0
                        )?_c('div',[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.openInNewTab()}}},[_vm._v(" Számla kép megnyitás új lapon ")]),_c('iframe',{staticStyle:{"border":"thin solid #ccc","height":"500px"},attrs:{"src":_vm.store.attachment.link,"width":"100%"}})],1):_vm._e()])],1),_c('v-divider',{staticClass:"mt-2 mb-3"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('input',{ref:"file",staticStyle:{"font-size":"12px"},attrs:{"accept":"image/jpeg,image/png,application/pdf","type":"file","id":"file","disabled":!_vm.isEditingEnabled}})])],1),_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":!_vm.isEditingEnabled,"color":"primary"},on:{"click":_vm.addFile}},[_vm._v(" "+_vm._s(_vm.isAttachmentEmpty ? "Feltöltés" : "Csere")+" ")])],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","single-line":"","hide-details":"auto","placeholder":"Megjegyzés"},model:{value:(_vm.fileNote),callback:function ($$v) {_vm.fileNote=$$v},expression:"fileNote"}})],1)],1)],1)])],1):_vm._e()])],1),_c('v-tab',[_vm._v("Egyéb dokumentumok")]),_c('v-tab-item',[_c('v-expand-transition',[(_vm.invoiceDetailsVisible)?_c('div',{staticClass:"mt-3"},[_c('div',[_c('v-row',[(_vm.showDocumentTypes)?_c('v-col',{attrs:{"align":"left"}},[_c('v-select',{staticClass:"comboBox",attrs:{"items":_vm.dStore.documentTypes,"outlined":"","dense":"","item-text":"name","item-value":"name","return-object":false},on:{"change":_vm.createDocumentRelation},model:{value:(_vm.newDocumentRelationItem.documentType),callback:function ($$v) {_vm.$set(_vm.newDocumentRelationItem, "documentType", $$v)},expression:"newDocumentRelationItem.documentType"}})],1):_vm._e(),_c('v-col',{attrs:{"align":"right"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"infoButton",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.showDocumentType}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]}}],null,false,1467412927)},[_c('span',[_vm._v("Új dokumentum igény")])])],1)],1),_vm._l((_vm.relStore.otherDocumentRelations),function(relation){return _c('div',{key:relation.id,staticClass:"mx-3 mb-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"vertical-align":"baseline"},attrs:{"color":relation.hasFile ? 'var(--primaryColor)' : 'error',"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(relation.hasFile ? "fa-check" : "fa-circle-o")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(relation.hasFile ? "Feltöltve" : "Nincs feltöltve")+" ")])]),_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"title":'Dokumentum feltöltése'},on:{"click":function($event){return _vm.showFile(relation)}}},[_vm._v(" "+_vm._s(relation.documentType)+" ")]),_c('v-expand-transition',[(relation.uploading)?_c('div',{staticClass:"px-2",staticStyle:{"background-color":"#f5f5f5"}},[_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(relation.fileName != ''),expression:"relation.fileName != ''"}]},[_c('v-expand-transition',[_c('label',[_vm._v(" "+_vm._s(relation.fileName)+" ")])])],1)]),_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var attrs = ref.attrs;
                        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-right":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!_vm.isEditingEnabled},on:{"click":function($event){return _vm.downloadFile(relation.link)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-download ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Fájl letöltése ")])])],1),_c('v-col',[_c('v-file-input',{ref:"file",refInFor:true,attrs:{"dense":"","outlined":"","hide-details":"auto","clear-icon":"fa-times","prepend-icon":"","accept":"image/jpeg,image/png,application/pdf,text/plain","id":("file-" + (relation.statusId) + "-" + (relation.id) + "-" + (relation.documentTypeId)),"disabled":!_vm.isEditingEnabled},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                        var message = ref.message;
return [_c('label',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-plus ")])]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var attrs = ref.attrs;
                        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!_vm.isEditingEnabled},on:{"click":function($event){return _vm.uploadFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl feltöltése")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var attrs = ref.attrs;
                        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!_vm.isEditingEnabled},on:{"click":function($event){return _vm.deleteFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-trash-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl törlése")])])],1)],1)],1):_vm._e()])],1)})],2)]):_vm._e()])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }