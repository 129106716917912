





































































































import IconButton from "@/components/IconButton.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import ApiResponse from "@/models/ApiResponse";
import Partner from "@/modules/partner/models/Partner";
import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import eventBus from "@/services/eventBus";
import http, { handleApiResponse } from "@/services/http";
import Vue from "vue";
import { InvoiceItem } from "../models";
import OverBillingInvItem from "../models/OverBillingInvItem";

interface Data {
  addingItem: OverBillingInvItem;
  overBillingItems: OverBillingInvItem[];
  tempItemForRemove: OverBillingInvItem | null;
}

interface Props {
  item: InvoiceItem;
  partnerId: number;
}

interface Methods {
  removeOverBilling(): void;
  checkQuantityInput(overBilling: OverBillingInvItem): void;
  setPartner(overBilling: OverBillingInvItem, partner: Partner): void;
  checkRemoveIfAble(item: OverBillingInvItem): void;
  createNewOverBilling(): void;
  loadAddedItems(): Promise<void>;
  saveOverBilling(item: OverBillingInvItem): void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  data: () => {
    return {
      addingItem: new OverBillingInvItem(),
      overBillingItems: new Array<OverBillingInvItem>(),
      tempItemForRemove: null,
    };
  },

  components: {
    MoneyInput,
    IconButton,
    PartnerSearch,
  },

  props: {
    item: InvoiceItem,
    partnerId: { type: Number, default: 1 },
  },

  async beforeMount() {
    await this.loadAddedItems();
  },

  methods: {
    async removeOverBilling() {
      try {
        await http.delete(`over-billings/${this.tempItemForRemove?.id??1}`)
        .then(handleApiResponse)


        if (
          this.overBillingItems.includes(
            this.tempItemForRemove ?? new OverBillingInvItem()
          )
        ) {
          this.overBillingItems = this.overBillingItems.filter(
            (x) => x !== this.tempItemForRemove
          );
        }
        /* SEND API REQUEST TO DELETE FROM DB */
      } finally {
        this.tempItemForRemove = null;
      }
    },

    checkQuantityInput(overBilling: OverBillingInvItem) {
      const sum = this.overBillingItems.reduce((x, y) => {
        return x + y.quantity;
      }, 0);

      if (overBilling.quantity > this.$props.item.outQ) {
        //overBilling.quantity = this.$props.item.outQ - (sum - overBilling.quantity);
        eventBus.$emit(
          "error",
          "A továbbszámlázandó tételek összes mennyisége nem lehet több, mint a továbbszámlázandó mennyiség!"
        );
      }
    },

    setPartner(overBilling: OverBillingInvItem, partner: Partner) {
      overBilling.partner = partner.id;

      if (partner.id == this.$props.partnerId) {
        eventBus.$emit("warn", "Ugyanazt a partnert válaszotta ki, mint akitől az eredeti számlát kapta!")
      }
    },

    checkRemoveIfAble(item: OverBillingInvItem) {
      if (item.id > 1) {
        this.tempItemForRemove = item;
        return;
      }

      if (this.overBillingItems.includes(item)) {
        this.overBillingItems = this.overBillingItems.filter((x) => x !== item);
      }
    },

    async saveOverBilling(item: OverBillingInvItem) {
      const invoiceID = Number(this.$route.params.invoiceId);
      await http
        .post<ApiResponse<OverBillingInvItem>>(
          `general-incoming-invoice/${invoiceID}/items/${this.$props.item.invoiceItemID}/over-billing-items/create/${this.$props.item.outQ}`, item
        )
        .then(handleApiResponse);

      await this.loadAddedItems();
    },

    createNewOverBilling() {
      if (
        this.overBillingItems.some(
          (x) => x.id < 2 && x.partner === 1 && x.quantity < 1
        )
      ) {
        //Már van új, kitöltetlen rögzítve
        return;
      }

      const newOverBilling = new OverBillingInvItem();
      newOverBilling.new = true;
      newOverBilling.name = this.$props.item.itemName;
      newOverBilling.invNoItem = this.$props.item.invoiceItemID;
      this.overBillingItems.push(newOverBilling);
    },

    async loadAddedItems() {
      const invoiceID = Number(this.$route.params.invoiceId);
      this.overBillingItems = await http
        .get<ApiResponse<OverBillingInvItem[]>>(
          `general-incoming-invoice/${invoiceID}/items/${this.$props.item.invoiceItemID}/over-billing-items`
        )
        .then(handleApiResponse);
    },
  },
});
