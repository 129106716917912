





































































































































































import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import { formatDate } from "../../../utils/formatDates";
import BankRemitAnalitics from "./BankRemitAnalitcsView.vue";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import auth from "@/services/auth";
import BankRemitRemark from "./BankRemitRemark.vue";
import BankRemitItem from "../models/BankRemitItem";
import dayjs from "dayjs";

interface Data {
  store: BankRemitStore;
}

interface Methods {
  formatDate(date: string): string;
  approve(): void;
  createApproval(): void;
  setDeleteItem(item: BankRemitItem): void;
  cancelDelete(item: BankRemitItem): void;
  confirmDelete(item: BankRemitItem): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    BankRemitAnalitics,
    MoneyDisplay,
    BankRemitRemark,
  },

  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    this.store.step = 3;
  },

  methods: {
    async confirmDelete(item: BankRemitItem) {
      item.deleting = false;
      item.expanded = false;
      await this.store.deleteItem(item.itemId);
      await this.store.loadBankRemit(Number(this.$route.params.id));
    },

    cancelDelete(item: BankRemitItem) {
      item.deleting = false;
      item.expanded = false;
      this.store.bankRemit.items = [...this.store.bankRemit.items];
    },

    setDeleteItem(item: BankRemitItem) {
      item.deleting = true;
      item.expanded = true;
      this.store.bankRemit.items = [...this.store.bankRemit.items];
    },

    async createApproval() {
      await this.store.loadApproval(Number(this.$route.params.id));
    },

    async approve() {
      await this.store.approve();
      await this.store.loadApproval(Number(this.$route.params.id));
    },

    formatDate(date: string) {
      return formatDate(date);
    },
  },

  computed: {
    isApproved() {
      if (
        new Date(dayjs(this.store.bankRemitApproval.accDate).toDate()).getTime() >
        new Date("2000-01-01T00:00:00").getTime()
      ) {
        return true;
      }
      return false;
    },

    isApprover() {
      if (this.store.bankRemitApproval) {
        const x = this.store.allApproverUser.find(
          (x) => x.id == this.store.bankRemitApproval.acceptorId
        );
        if (x && x.name == auth.userName) {
          return true;
        } else return false;
      } else return false;
    },

    itemColumns: () => [
      { field: "prefix", title: "Prefix", width: 60 },
      { field: "invoice", title: "Számla" },
      { field: "invNo", title: "Bizonylat szám" },
      {
        field: "partnerName",
        title: "Partner",
      },
      {
        field: "amount",
        title: "Számla összege",
        cell: "amountTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        field: "exchangeRate",
        title: "Árfolyamszorzó",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        title: "Utalási határidő",
        field: "pterm",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
        width: 150,
      },
      {
        cell: "commandTemplate",
        width: 60,
      },
      { width: 0, filterable: false },
    ],
  },
});
