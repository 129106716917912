import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import LetterOfCredit, { LetterOfCreditDto } from "./letterOfCredit";

export default class LetterOfCreditApi {
  static getAllLiveLettersOfCredit(): Promise<LetterOfCreditDto[]> {
    return http
      .get<ApiResponse<LetterOfCreditDto[]>>("letterofcredit/getalllive")
      .then(handleApiResponse);
  }

  static getLetterOfCredit(id: number): Promise<LetterOfCreditDto> {
    return http
      .get<ApiResponse<LetterOfCreditDto>>(`letterofcredit/${id}`)
      .then(handleApiResponse);
  }

  static getLetterOfCreditDetails(id: number): Promise<LetterOfCreditDto> {
    return http
      .get<ApiResponse<LetterOfCreditDto>>(`letterofcredit/details/${id}`)
      .then(handleApiResponse);
  }

  static save(p: LetterOfCredit): Promise<number> {
    return http
      .put<ApiResponse<number>>("letterofcredit", p)
      .then(handleApiResponse);
  }
}

export class LetterOfCreditStore {
  items: LetterOfCreditDto[] = [];
  item = new LetterOfCreditDto();

  async set() {
    this.items = await LetterOfCreditApi.getAllLiveLettersOfCredit();
  }

  async setItem(id: number) {
    if (id == 0) {
      this.item = await LetterOfCreditApi.getLetterOfCredit(1);
      this.item.current.id = 0;
      this.item.current.deleted = false;
      this.item.current.readonly = false;
    } else {
      this.item = await LetterOfCreditApi.getLetterOfCredit(id);
    }
  }

  async save() {
    const id = await LetterOfCreditApi.save(this.item.current);
    await this.setItem(id);
  }

  async getLetterOfCreditForInvoice(loc: number){
    this.item = await LetterOfCreditApi.getLetterOfCreditDetails(loc);
  }
}
