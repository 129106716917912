var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Partnerhez rendelt jóváhagyók ")]),_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{staticClass:"src-btn",attrs:{"outlined":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-search")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"search k-textbox",attrs:{"type":"text","placeholder":"Szállító neve..."},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Mentés ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.addHead}},[_vm._v(" Új jóváhagyó feltétel ")])],1)],1),_c('v-card-text',[_c('grid',{staticStyle:{"max-height":"650px"},attrs:{"columns":_vm.columns,"data-items":_vm.items,"expand-field":"expanded","selected-field":"expanded","detail":"detailsTemplate"},scopedSlots:_vm._u([{key:"detailsButtonHeaderTemplate",fn:function(){return [_c('div',{staticStyle:{"text-align":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":"","width":"15px"},on:{"click":_vm.hideDetails}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-minus ")])],1)]}}])},[_c('span',[_vm._v(" Felhasználók elrejtése ")])])],1)]},proxy:true},{key:"detailsButtonTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"forceTop pl-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.showDetails(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-users ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(props.dataItem.apprType == 5 ? "Ellenőrzők" : "Jóváhagyók")+" megtekintése ")])])],1)]}},{key:"detailsTemplate",fn:function(ref){
var props = ref.props;
return [(props.dataItem.expanded)?_c('div',{staticClass:"pb-4"},[_c('ApprovalMatrixUsersView',{attrs:{"head":props.dataItem,"fullfill":""}})],1):_vm._e()]}},{key:"partnerTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('PartnerSearch',{staticStyle:{"width":"100%"},attrs:{"firstPage":_vm.partnerSearchFirstPage,"partnerId":props.dataItem.partner},on:{"update:partnerId":function($event){return _vm.$set(props.dataItem, "partner", $event)},"update:partner-id":function($event){return _vm.$set(props.dataItem, "partner", $event)},"value":function (ev) {
                  _vm.partnerChanged(props.dataItem, ev);
                }}})],1)]}},{key:"valueIndependentTemplate",fn:function(ref){
                var props = ref.props;
return [_c('td',{staticClass:"forceTop pt-3"},[_c('div',{staticClass:"flex_center"},[_c('v-switch',{attrs:{"dense":""},model:{value:(props.dataItem.isValueIndependent),callback:function ($$v) {_vm.$set(props.dataItem, "isValueIndependent", $$v)},expression:"props.dataItem.isValueIndependent"}})],1)])]}},{key:"minAmountTemplate",fn:function(ref){
                var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('NumericTextBox',{staticStyle:{"width":"100%"},attrs:{"disabled":props.dataItem.isValueIndependent,"min":0},model:{value:(props.dataItem.minAmount),callback:function ($$v) {_vm.$set(props.dataItem, "minAmount", _vm._n($$v))},expression:"props.dataItem.minAmount"}})],1)]}},{key:"maxAmountTemplate",fn:function(ref){
                var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('NumericTextBox',{staticStyle:{"width":"100%"},attrs:{"disabled":props.dataItem.isValueIndependent,"min":props.dataItem.minAmount},model:{value:(props.dataItem.maxAmount),callback:function ($$v) {_vm.$set(props.dataItem, "maxAmount", _vm._n($$v))},expression:"props.dataItem.maxAmount"}})],1)]}},{key:"currenciesTemplate",fn:function(ref){
                var props = ref.props;
return [_c('td',[_c('v-select',{attrs:{"single-line":"","disabled":props.dataItem.currencyDisabled,"dense":"","outlined":"","items":_vm.store.currencies,"item-text":"currency","item-value":"currency"},model:{value:(props.dataItem.currency),callback:function ($$v) {_vm.$set(props.dataItem, "currency", $$v)},expression:"props.dataItem.currency"}})],1)]}},{key:"commandTemplate",fn:function(ref){
                var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('confirmation',{ref:'confirmation-' + props.dataItem.partner + '-' + props.dataItem.groupId,attrs:{"confirmText":"Megerősítés","confirmButtonClass":"mr-2"}},[_c('div',{staticStyle:{"display":"table-cell"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteHead(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-trash-o ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Törlés ")])])],1)])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }