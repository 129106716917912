



































































import Vue from "vue";
import setAttribute from "../../../utils/dom";
import UserAccountNo from "../models/UserAccountNo";
import store, { UserAccountNoStore } from "../services/UserAccountNoStore";

interface Data {
  store: UserAccountNoStore;
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "3");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    confirmation: confirmationTemplate,
  },

  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadUserAccountNos();
  },

  computed: {
    columns: () => [
      { field: "userName", title: "Név" },
      { field: "accNo", title: "Kód" },
      { cell: "commandTemplate", width: "120px" },
      { width: 0 },
    ],

    items() {
      return this.store.userAccountNos;
    },
  },

  methods: {
    create() {
      this.$router.push(`/user-acc-no/create`);
    },

    update(item: UserAccountNo) {
      this.$router.push(`/user-acc-no/${item.id}/edit`);
    },

    deleteItem(item: UserAccountNo) {
      this.store.setDeleting(item);
    },

    confirmDelete(item: UserAccountNo) {
      item.deleting = false;
      this.store.delete(item.id);
    },

    cancelDelete(item: UserAccountNo) {
      item.deleting = false;
    },
  },
});
