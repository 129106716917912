import http, { handleApiResponse, checkResponse } from "@/services/http";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import GeneralIngoinInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import GeneralIngoingInvoiceAndItems from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceAndItems";
import GeneralIngoingInvoiceItem from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItem";
import PartnerTax from "@/modules/generalIngoingInvoice/models/PartnerTax";
import Tax from "@/modules/generalIngoingInvoice/models/Tax";
import SelectableParts from "@/modules/generalIngoingInvoice/models/IngoingInvoiceSelectableItem";
import InvoiceDetails from "@/modules/generalIngoingInvoice/models/InvoiceDetails";
import IncomingInvoiceSubtype from "@/modules/generalIngoingInvoice/models/IncomingInvoiceSubtype";
import PaymentMode from "@/modules/paymentMode/models/PaymentModeDisplay";
import Country from "../../cashPoint/models/Country";
import PartBank from "@/modules/cashPoint/models/PartBank";
import ProjectHeadDisplay from "@/modules/generalIngoingInvoice/models/ProjectHeadDisplay";
import LogicalStorage from "@/modules/generalIngoingInvoice/models/LogicalStorage";
import ApiResponse from "@/models/ApiResponse";
import GeneralIngoingInvoiceItemDisplay from "../models/GeneralIngoingInvoiceItemDisplay";
import ProjectData from "../models/ProjectData";
import Attachment from "../models/Attachment";
import ProjectItem from "../models/ProjectItem";
import GeneralIngoingInvoiceItemMinEditModel from "../models/GeneralIngoingInvoiceItemMinEditModel";
import { Unit } from "@/modules/invoiceItems/models";
import configuration from "@/models/Configuration";
import { getCookie } from "@/services/cookies";
import eventBus from "@/services/eventBus";
import GeneralIngoingInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import busyIndication from "@/utils/busyIndication";
import { CreateInvoicePaymentInput, InvoicePayment } from "../models/InvoicePayment";
import dayjs from "dayjs";
import NotiDownPayEditModel from "../models/NotiDownPayEditModel";
import auth, { getOperelToken } from "@/services/auth";
import { limit } from "@progress/kendo-data-query/dist/npm/array.operators";
import GeneralIngoinInvoicePager from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoicePager";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import AccStatus from "../models/AccStatus";
import WorkflowStatus from "../models/WorkflowStatus";
import GeneralIngoingInvoiceType from "../models/GeneralIngoingInvoiceType";
import DisplayStatus from "../models/DisplayStatus";
import User from "@/modules/user/models/User";
import InvoiceImport from "../models/InvoiceImport";

export default class GeneralIngoingInvoiceApi {
  static async searchForAll(
    pageLimit: number,
    pageOffset: number,
    filterText: string,
    filter: any, // ez az intervallumszűrő
    sort: any,
    gridFilter: any // ez a grid felett lévő oszlopszűrő
  ): Promise<GeneralIngoinInvoicePager> {
    const offset = pageLimit * (pageOffset - 1);

    return await http
      .post<ApiResponse<GeneralIngoinInvoicePager>>(`general-incoming-invoice/searchforall`, {
        limit: pageLimit,
        offset: offset,
        searchText: filterText,
        filter: filter,
        sort: sort,
        gridFilter: gridFilter,
      })
      .then(handleApiResponse);
  }

  static syncInvoices(isAll: boolean, taxNo: string, invoiceNo: string, startDate: string, endDate: string): Promise<any> {
    return http
      .get(`general-incoming-invoice/oninv-sync`, {
        params: {
          isAll: isAll,
          invoiceNo: invoiceNo,
          taxNo: taxNo,
          startDate: startDate,
          endDate: endDate
        }

      })
      .then(checkResponse);
  }

  static importInvoices(importIds: any): Promise<any> {
    return http
      .post(`general-incoming-invoice/import-oninv-sync`, {
        ImportIDs: importIds
      })
      .then(checkResponse);
  }

  static getInvoiceImports(): Promise<InvoiceImport> {
    return http
      .get<ApiResponse<InvoiceImport>>(`general-incoming-invoice/get-oninv-sync`)
      .then(handleApiResponse);
  }

  static getInvoiceImportItems(ids: number[]): Promise<InvoiceImport[]> {
    return http
      .post<ApiResponse<InvoiceImport[]>>(`general-incoming-invoice/import-items-oninv-sync`, {
        ImportIDs: ids
      })
      .then(handleApiResponse);
  }

  static getInvoiceDetails(partnerId: number, invoiceDate?: string): Promise<InvoiceDetails> {
    return http
      .get<ApiResponse<InvoiceDetails>>(`general-incoming-invoice/details/${partnerId}`, {
        params: { invoiceDate: invoiceDate },
      })
      .then(handleApiResponse);
  }

  static get(invoiceId: number): Promise<GeneralIngoingInvoiceAndItems> {
    return http
      .get<ApiResponse<GeneralIngoingInvoiceAndItems>>(`general-incoming-invoice/${invoiceId}`)
      .then(handleApiResponse);
  }

  static getProjectData(invoiceId: number): Promise<ProjectData> {
    return http.get<ApiResponse<ProjectData>>(`general-incoming-invoice/project/${invoiceId}`).then(handleApiResponse);
  }

  static getIncomingSubtypes(): Promise<IncomingInvoiceSubtype[]> {
    return http.get<ApiResponse<IncomingInvoiceSubtype[]>>(`incoming-invoice-subtype`).then(handleApiResponse);
  }

  static getPartnerTaxes(): Promise<PartnerTax[]> {
    return http.get<ApiResponse<PartnerTax[]>>(`partner-tax`).then(handleApiResponse);
  }

  static getPaymentModes(): Promise<PaymentMode[]> {
    return http.get<ApiResponse<PaymentMode[]>>(`payment-mode`).then(handleApiResponse);
  }

  static loadOperelPaymodeForPartner(partnerID: number): Promise<PaymentMode> {
    return http.get<ApiResponse<PaymentMode>>(`payment-mode/operel/partner/${partnerID}`).then(handleApiResponse);
  }

  static getCurrencies(): Promise<Country[]> {
    return http.get<ApiResponse<Country[]>>(`currency`).then(handleApiResponse);
  }

  static getTaxes(partnerId: number): Promise<Tax[]> {
    return http.get<ApiResponse<Tax[]>>(`tax/${partnerId}`).then(handleApiResponse);
  }

  static getAllTaxes(): Promise<Tax[]> {
    return http.get<ApiResponse<Tax[]>>(`tax`).then(handleApiResponse);
  }

  static getAllUnits(): Promise<Unit[]> {
    return http.get<ApiResponse<Unit[]>>(`units`).then(handleApiResponse);
  }

  static getPartBanks(isAlive: boolean, partnerId: number): Promise<PartBank[]> {
    return http.get<ApiResponse<PartBank[]>>(`partBank/${isAlive}/${partnerId}`).then(handleApiResponse);
  }

  static getItems(name: string, isDpaym = false): Promise<SelectableParts[]> {
    return http.get<ApiResponse<SelectableParts[]>>(`general-incoming-invoice/items/${name}/${isDpaym}`).then(handleApiResponse);
  }

  static getProjectHeads(partnerID: number): Promise<ProjectHeadDisplay[]> {
    return http.get<ApiResponse<ProjectHeadDisplay[]>>(`project-head/${partnerID}`).then(handleApiResponse);
  }

  static getProjectByHeadId(headID: number): Promise<ProjectItem> {
    return http.get<ApiResponse<ProjectItem>>(`project-head/invoice/${headID}`).then(handleApiResponse);
  }

  static getLogicalStorages(): Promise<LogicalStorage[]> {
    return http.get<ApiResponse<LogicalStorage[]>>(`logical-storage`).then(handleApiResponse);
  }

  static getInvoiceItemsByInvId(invoiceId: number): Promise<GeneralIngoingInvoiceItem[]> {
    return http
      .get<ApiResponse<GeneralIngoingInvoiceItem[]>>(`general-incoming-invoice/items-by-invid/${invoiceId}`)
      .then(handleApiResponse);
  }

  static getInvoiceItemMinEditModelsByInvId(invoiceId: number, isCurr: boolean): Promise<GeneralIngoingInvoiceItemMinEditModel[]> {
    return http
      .get<ApiResponse<GeneralIngoingInvoiceItemMinEditModel[]>>(
        `general-incoming-invoice/items-by-invid/editmodels/${invoiceId}/${isCurr}`
      )
      .then(handleApiResponse);
  }

  static createItem(item: GeneralIngoingInvoiceItem): Promise<GeneralIngoingInvoiceItem> {
    return http
      .post<ApiResponse<GeneralIngoingInvoiceItem>>(`general-incoming-invoice/items-by-invid`, item)
      .then(handleApiResponse);
  }

  static createInvoiceItemMinEditModel(
    item: GeneralIngoingInvoiceItemMinEditModel
  ): Promise<GeneralIngoingInvoiceItemMinEditModel> {
    return http
      .post<ApiResponse<GeneralIngoingInvoiceItemMinEditModel>>(
        `general-incoming-invoice/items-by-invid/editmodels`,
        item
      )
      .then(handleApiResponse);
  }

  static create(
    invoice: GeneralIngoinInvoice,
    items: GeneralIngoingInvoiceItem[],
    autoApprovalConfirmed = false
  ): Promise<number> {
    return http
      .post<ApiResponse<number>>(`general-incoming-invoice`, {
        invoice: invoice,
        items: items,
        relations: [],
        genis: [],
        projectInfo: {},
        isAutomaticApprovalConfirmed: autoApprovalConfirmed,
      })
      .then(handleApiResponse);
  }

  static update(
    invoice: GeneralIngoinInvoice,
    items: GeneralIngoingInvoiceItem[],
    autoApprovalConfirmed = false
  ): Promise<any> {
    return http
      .put(`general-incoming-invoice`, {
        invoice: invoice,
        items: items,
        projectInfo: {},
        isAutomaticApprovalConfirmed: autoApprovalConfirmed,
      })
      .then(handleApiResponse);
  }

  static setIncorrect(invoice: GeneralIngoinInvoice): Promise<any> {
    return http
      .put(`general-incoming-invoice/incorrect`, {
        invoice: invoice,
      })
      .then(checkResponse);
  }

  static reopen(invoiceID: number, invoice: GeneralIngoinInvoice): Promise<boolean> {
    return http.put(`general-incoming-invoice/reopen`, { incomingInvoiceID: invoiceID, invoice: invoice }).then(checkResponse);
  }

  static copy(invoiceID: number): Promise<number> {
    return http.post<ApiResponse<number>>(`general-incoming-invoice/copy`, { id: invoiceID }).then(handleApiResponse);
  }

  static reopenOperel(invoiceID: number): Promise<boolean> {
    return http.put(`general-incoming-invoice/reopen-operel`, { incomingInvoiceID: invoiceID }).then(checkResponse);
  }

  static accept(
    invoice: GeneralIngoinInvoice,
    items: GeneralIngoingInvoiceItemDisplay[],
    projectData: ProjectData | null = null
  ): Promise<any> {
    return http
      .put(`general-incoming-invoice/accept`, {
        invoice: invoice,
        items: items,
      })
      .then(checkResponse);
  }

  static getRate(currency: string, date: string): Promise<number> {
    return http.get<ApiResponse<number>>(`currency/rate/${currency}/${date}`).then(handleApiResponse);
  }

  static getFile(invoiceId: number, token = ""): Promise<Attachment> {
    return http
      .get<ApiResponse<Attachment>>(`general-incoming-invoice/geni-picture/${invoiceId}`, {
        params: {
          token,
        },
      })
      .then(handleApiResponse);
  }

  static getFiles(invoiceId: number): Promise<Attachment[]> {
    return http.get<ApiResponse<Attachment[]>>(`general-incoming-invoice/files/${invoiceId}`).then(handleApiResponse);
  }

  // static getPreviousRate(currency: string): Promise<CurrencyRate> {
  //     return http
  //         .get<ApiResponse<CurrencyRate>>(`currency/prev/rate/${currency}`)
  //         .then(handleApiResponse)
  // }

  static uploadFiles(files: Attachment[], id: number) {
    const data = {
      Id: id,
      Files: files.map((x) => ({
        Id: x.id,
        File: x.fileBase64,
        Link: x.link,
        Note: x.note,
        Name: x.name,
      })),
    };
    return http.post(`general-incoming-invoice/files`, data).then(handleApiResponse);
  }

  static generateFileDownLoadLink(fileId: number) {
    return (
      http.defaults.baseURL +
      `general-incoming-invoice/file/${fileId}?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`
    );
  }

  static updateStockPrices(invoiceID: number): Promise<any> {
    if (!configuration.operel) {
      return Promise.resolve(0);
    }

    const operelToken = getOperelToken();

    return http
      .post("general-incoming-invoice/operel/stock-prices", {
        invoiceID,
        token: operelToken,
      })
      .then(handleApiResponse);
  }

  @busyIndication()
  static async openInvoicePicture(invoiceID: number) {
    const token = getOperelToken();
    const picture = await GeneralIngoingInvoiceApi.getFile(invoiceID, token);
    if (picture?.link) {
      window.open(picture.link + `?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    } else {
      eventBus.$emit("error", "A számlakép nem található!");
    }
  }

  static setPayed(invoiceID: number, payment: CreateInvoicePaymentInput): Promise<boolean> {
    return http.put(`general-incoming-invoice/payed/${invoiceID}`, payment).then(checkResponse);
  }

  static setUnPayed(invoiceID: number): Promise<boolean> {
    return http.delete(`general-incoming-invoice/payed/${invoiceID}`).then(checkResponse);
  }

  static getInvoicePayments(invoiceID: number) {
    return http.get(`incoming-invoice/${invoiceID}/payments`).then<{ payments: InvoicePayment[] }>(handleApiResponse);
  }

  static async createInvoicePayment(invoiceID: number, input: CreateInvoicePaymentInput) {
    const response = await http.post(`incoming-invoice/${invoiceID}/payments`, input);
    const success = await checkResponse(response);
    if (success) {
      eventBus.$emit("invoice-payment:created");
    }
    return success;
  }

  static filterInvoices(filter: any, sort: any): Promise<GeneralIngoinInvoicePager> {
    // console.log(filter);
    return http
      .post<ApiResponse<GeneralIngoinInvoicePager>>("general-incoming-invoice/filter", {
        filter,
        sort,
      })
      .then(handleApiResponse);
  }

  static getAllByPartnerId(partnerId: number): Promise<NotiDownPayEditModel[]> {
    return http.get<ApiResponse<NotiDownPayEditModel[]>>(`noti/${partnerId}`).then(handleApiResponse);
  }

  static createNoti(newNoti: NotiDownPayEditModel): Promise<any> {
    return http.post<ApiResponse<number>>(`noti`, newNoti).then(handleApiResponse);
  }
  static deleteNoti(newNoti: NotiDownPayEditModel): Promise<any> {
    return http.delete<ApiResponse<boolean>>(`noti/${newNoti.id}`).then(checkResponse);
  }

  static validateInvoiceParams(params: {
    invoiceNo: string;
    partnerID: number;
  }): Promise<{ errors: Record<string, string> }> {
    return http
      .post<ApiResponse<{ errors: Record<string, string> }>>("general-incoming-invoice/validate", params)
      .then(handleApiResponse);
  }

  static rectifyInvoiceItem(invoiceID: number, targetInvoiceItemId: any[]): Promise<boolean> {
    const params = {
      invoiceID,
      targetInvoiceItemId
    };
    console.log(params);
    return http.post(`incoming-invoice-items/create-rectify-items`, params).then(checkResponse);
  }

  static addSupplierComplaintRectifyItem(params: { invoiceID: number; targetInvoiceItemId: any[] }): Promise<boolean> {
    return http.post(`incoming-invoice-items/create/supplier-complaint-items`, params).then(checkResponse);
  }

  static loadFullfilmentTolerances(): Promise<GeneralIngoinInvoiceDisplay[]> {
    return http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(`general-incoming-invoice/fullfilment/tolerance-level`)
      .then(handleApiResponse);
  }

  static async loadAccStatuses(): Promise<AccStatus[]> {
    return await http.get<ApiResponse<AccStatus[]>>("account-status").then(handleApiResponse);
  }

  static async loadWorkflowStatuses(): Promise<WorkflowStatus[]> {
    return http.get<ApiResponse<WorkflowStatus[]>>("invoice-flow-status").then(handleApiResponse);
  }

  static async loadDisplayStatuses(): Promise<DisplayStatus[]> {
    return http
      .get<ApiResponse<DisplayStatus[]>>("general-incoming-invoice-editmodel/pre-invoice-status")
      .then(handleApiResponse);
  }

  static async getTypes(): Promise<GeneralIngoingInvoiceType[]> {
    return http.get<ApiResponse<GeneralIngoingInvoiceType[]>>("incoming-invoice-type").then(handleApiResponse);
  }

  static async getAcceptorAndControlUsers(): Promise<User[]> {
    return http.get<ApiResponse<User[]>>(`users/accept-control`).then(handleApiResponse);
  }
}
