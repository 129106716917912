import InvoiceSetting from "../models/InvoiceSetting"
import InvoiceType, { invoiceTypeDefaultProps } from "../models/InvoiceType"
import InvoiceTypeApi from "./InvoiceTypeApi"
import busyIndication from "../../../utils/busyIndication";


export class InvoiceTypeStore {
    invoiceTypes: InvoiceType[] = []
    invoiceType: InvoiceType = { ...invoiceTypeDefaultProps }
    invoiceSettings: InvoiceSetting[] = []

    async loadInvoiceSetting(){
        this.invoiceSettings = await InvoiceTypeApi.getInvoiceSetting(); 
    }

    @busyIndication()
    async loadInvoiceTypes() {
        this.invoiceTypes = (await InvoiceTypeApi.getAll()).map((i) => ({...i, deleting: false}))        
    }

    @busyIndication()
    async loadInvoiceType(invoiceTypeId: number) {
        this.invoiceType = invoiceTypeId > 1 ? await InvoiceTypeApi.get(invoiceTypeId) : { ...invoiceTypeDefaultProps }
    }

    async deleteInvoiceType(invoiceTypeId: number) {
        try {
            await InvoiceTypeApi.delete(invoiceTypeId)
            this.invoiceTypes = this.invoiceTypes.filter(b => b.id !== invoiceTypeId)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async save() {
        if(!this.invoiceType) {
            return
        }
        await this.updateInvoiceType()

        // if(this.invoiceType.invoiceType.id === 0) {
        //     await this.createInvoiceType()
        // } else {
        //     await this.updateInvoiceType()
        // }
    }

    private async createInvoiceType() {
        try {
            const created = await InvoiceTypeApi.create(this.invoiceType)
            this.invoiceTypes = [...this.invoiceTypes, created]
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    private async updateInvoiceType() {
        try {
            await InvoiceTypeApi.update(this.invoiceType)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    setDeleting(item: InvoiceType) {
        item.deleting = !item.deleting;
        this.invoiceTypes = [...this.invoiceTypes];
    }
}

const store = new InvoiceTypeStore()
export default store