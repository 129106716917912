






import Vue from "vue";

export default Vue.extend({
  props: {
    value: Number,

    currency: {
      type: String,
      default: "HUF",
    },

    textalign: {
      type: String,
      default: "d-flex justify-end text-right",
    },

    decimals: {
      default: undefined
    }
  },
});
