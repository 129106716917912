import api from "../api/WriteOffDebtApi";
import PossibleWriteOffDebtPreFilter from "../models/PossibleWriteOffDebtPreFilter";
import {defaultPossibleWriteOffDebtPreFilter} from "../models/PossibleWriteOffDebtPreFilter"; 
import PartnerPossibleWriteOffData from "../models/PartnerPossibleWrtireOffDebt";
import { calcExpected } from "../models/PartnerPossibleWrtireOffDebt";
import PossibleWriteOffDebt from "../models/PossibleWriteOffDebt";

export class WriteOffDebtStore {
  /**
   * direction: 
   * false = incoming invoice, 
   * true = outgoing invoice,
   * limit > 0:
   * small amount write off/kis összegű a kivezetés
   */
  constructor(name: string, direction: boolean, limit: number | undefined = undefined) {
    this.name = name;
    this.filter.direction = direction;
    this.filter.limit = limit;
    if(direction)
    {
      this.isIn = false;
      if(limit != undefined)
        this.writeOffMethod = api.WriteOffSmallOutgoing;
      else 
        this.writeOffMethod = api.WriteOffOutgoing;
    }
    else
    {
      this.isIn = true;
      if(limit != undefined)
        this.writeOffMethod = api.WriteOffSmallIncome;
      else 
        this.writeOffMethod = api.WriteOffIncome;
    }
  }

  isIn = false;
  writeOffMethod: Function | null = null;
  name= "";
  items: PartnerPossibleWriteOffData[] = [];
  filter: PossibleWriteOffDebtPreFilter = {...defaultPossibleWriteOffDebtPreFilter};

  async loadItems() {
    this.items = [];
    const items = await api.GetAllPartnerWriteOffDebtTmp(this.filter);
    items.forEach(x => x.expectedAccount = calcExpected(x.possibleWriteOffDebts, x.currentAccount));
    this.items = items;
  }

  async writeOffSelecteds(item: PartnerPossibleWriteOffData)
  {
      const selectedItems = item.possibleWriteOffDebts.filter(x => x.isSelected);
      if(this.writeOffMethod != null)
        await this.writeOffMethod(item.note, selectedItems);
  }

  async sendToApiRemoveInvoiceToWriteOffDebtTemp(items: PossibleWriteOffDebt[]) {
    await api.RemoveInvoicesToWriteOffDebtToTmp(items);
  }
}

const limilessIn = new WriteOffDebtStore("limilessIn", false);
const limilessOut = new WriteOffDebtStore("limilessOut", true);
const smallAmountIn = new WriteOffDebtStore("smallAmountIn",false, 0);
const smallAmountOut = new WriteOffDebtStore("smallAmountOut",true, 0);

export function getStore(direction: boolean, limit: boolean): WriteOffDebtStore {
  let store = smallAmountOut;
  if(direction) {
      if(limit)
        store = smallAmountOut;
      else
        store = limilessOut;
  }
  else {
    if(limit)
      store = smallAmountIn;
    else   
      store = limilessIn
  }

  return store;
}

export function getLimitFromStore(direction: boolean): number | undefined {
  let store = smallAmountOut;
  if(direction)
    store = smallAmountOut;
  else
    store = smallAmountIn;

  return store.filter.limit;
}

export function setLimitToStore(direction: boolean, limit: number) {
  if(direction)
    smallAmountOut.filter.limit = limit;
  else
    smallAmountIn.filter.limit = limit;
}

export default WriteOffDebtStore;

