




























































































































import IconButton from "../../../components/IconButton.vue";
import InvoiceRectItem from "./InvoiceRectItem.vue";
import ColspanFixer from "@/components/ColspanFixer.vue";

import Vue from "vue";
import * as api from "../services/api";
import eventBus from "../../../services/eventBus";
import flowStore, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import store, { InvoiceItemStore } from "../../invoiceItems/services/store";
import GeneralIngoingInvoiceAndItems from "../../generalIngoingInvoice/models/GeneralIngoingInvoiceAndItems";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import GeneralIngoingInvoiceApi from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  searchText: string;
  invoices: GeneralIngoinInvoiceDisplay[];
  flowStore: InvoiceFlowStore;
  store: InvoiceItemStore;
  configuration: AppConfiguration;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    IconButton,
    InvoiceRectItem,
    ColspanFixer,
    MoneyDisplay,
  },

  data: () => ({
    flowStore,
    store,
    searchText: "",
    invoices: [],
    configuration,
  }),

  mounted() {
    const rectifyItemAddHandler = () => (this.invoices = []);
    eventBus.$on("invoice-item:add", rectifyItemAddHandler);
    this.store.rectItemsToCreate = [];
  },

  destroyed() {
    this.store.rectItemsToCreate = [];
    this.store.supplComplItemsToCreate = [];
  },

  methods: {
    isCreditingStoreInDetails(items: any[]) {
      return items.some((x) => x.creditingStoreInDetails != null && x.creditingStoreInDetails.creditingStoreinId > 1);
    },

    async addInvoiceRectItems() {
      let success = false;
      console.log(this.store.rectItemsToCreate);
      
      if (this.store.rectItemsToCreate.length > 0) {
        success = await GeneralIngoingInvoiceApi.rectifyInvoiceItem(this.flowStore.invoice.id, this.store.rectItemsToCreate).then((x) => {
          return x;
        });
      }
      this.store.rectItemsToCreate = [];

      if (this.store.supplComplItemsToCreate.length > 0) {
        success = await GeneralIngoingInvoiceApi.addSupplierComplaintRectifyItem({
          invoiceID: this.flowStore.invoice.id,
          targetInvoiceItemId: this.store.supplComplItemsToCreate,
        }).then((x) => {
          return x;
        });
      }
      this.store.supplComplItemsToCreate = [];

      if (success) {
        if (this.store.correctionGpartsToCreate.length > 0) {
          await this.store.createCorrectionItem(Number(this.$route.params.invoiceId));
          this.store.correctionGpartsToCreate = [];
        }
        eventBus.$emit("invoice-item:add");
        await this.store.reloadItems();
        await this.flowStore.loadInvoiceWithoutBusy(Number(this.$route.params.invoiceId));
      }
    },

    showDetails(item: GeneralIngoingInvoiceAndItems) {
      item.details = !item.details;
      this.invoices = [...this.invoices];
    },

    clear() {
      this.invoices = [];
      this.searchText = "";
      this.store.rectItemsToCreate = [];
    },

    async search() {
      await api
        .getInvoicesForRectify(this.searchText, Number(this.$route.params.invoiceId), Number(this.flowStore.invoice.partner))
        .then(
          (invoices: GeneralIngoinInvoiceDisplay[]) => (this.invoices = invoices.map((i) => ({ ...i, details: false })))
        );
    },
  },

  computed: {
    columns: () => [
      { cell: "itemsTemplate", width: 50 },
      { cell: "idTemplate", field: "incomingInvoice.id", title: "GENI szám" },
      { field: "incomingInvoice.invoiceNo", title: "Bizonylat szám" },
      {
        field: "incomingInvoice.invoiceDate",
        title: "Számla kelt",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
      },
      {
        cell: "totalTemplate",
        title: "Bruttó összeg",
        headerCell: "totalHeaderTemplate",
      },
      { width: 0 },
    ],

    items() {
      return this.invoices;
    },
  },
});
