import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import FixAssetGroup from '../models/FixAssetGroup'
import FixAssetDocumentTemplate from '../models/FixAssetDocumentTemplate'
import FixAssetMainGroup from '../models/FixAssetMainGroup'

export default class DimCodeTypeApi {
    static async getAll(): Promise<FixAssetGroup[]>{
        return http
        .get<ApiResponse<FixAssetGroup[]>>(`fix-asset/groups`)
        .then(handleApiResponse)
    }

    static async getAllByMainGroup(id: number): Promise<FixAssetGroup[]>{
        return http
        .get<ApiResponse<FixAssetGroup[]>>(`fix-asset/groups/main/${id}`)
        .then(handleApiResponse)
    }

    static async getAllMainGroups(): Promise<FixAssetMainGroup[]>{
        return http
        .get<ApiResponse<FixAssetMainGroup[]>>(`fix-asset/main-groups`)
        .then(handleApiResponse)
    }

    static async get(id: number): Promise<FixAssetGroup>{
        return http
        .get<ApiResponse<FixAssetGroup>>(`fix-asset/groups/${id}`)
        .then(handleApiResponse)
    }

    static create(item: FixAssetDocumentTemplate): Promise<number> {
        return http.post<ApiResponse<number>>(`fix-asset/groups/create`, item).then(handleApiResponse);
    }
    
    static update(item: FixAssetDocumentTemplate): Promise<boolean> {
        return http.put(`fix-asset/groups/update/${item.id}`, item).then(checkResponse);
    }

    static async getFiles(id: number): Promise<FixAssetGroup>{
        return http
        .get<ApiResponse<FixAssetGroup>>(`fix-asset/groups/${id}/files`)
        .then(handleApiResponse)
    }

    static createDefaultDocuments(toolGroupId: number, assetId: number): Promise<number> {
        return http.post<ApiResponse<number>>(`fix-asset/document/create/${toolGroupId}/${assetId}`).then(handleApiResponse);
    }
}