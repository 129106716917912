





















import Vue from "vue";
import store, { InvoiceItemDimensionStore } from "../../invoiceItemDimension/services/InvoiceItemDimensionStore";
import InvItemAndDimensionsById from "../models/InvItemAndDimensionsById";

interface Data {
  store: InvoiceItemDimensionStore;
  firstRows: any[];
  firstColumns: any[];
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
      firstRows: [
        {
          height: 20,
          cells: [
            {
              value: "GENI szám",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Tétel azonosító",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Név",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Megjegyzés",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Egységár",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Mennyiség",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Mennyiségi egység",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "ÁFA kulcs",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Nettó a számla pénznemében",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Bruttó a számla pénznemében",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "ÁFA a számla pénznemében",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Fennmaradó",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Költséghely",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Költséghely nettó összeg",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Munkaállomás",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Munkaállomás csoport",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Szerszám",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Felhasználó",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Erőforrás nettó összeg",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Rendszám",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Gépjármű nettó összeg",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Projekt",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Projekt nettó összeg",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Cikk",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "Cikk nettó összeg",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "XI. dimenzió",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "XI. dimenzió nettó összeg",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "XII. dimenzió",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
            {
              value: "XII. dimenzió nettó összeg",
              background: "rgb(167,214,255)",
              textAlign: "center",
            },
          ],
        },
      ],
      firstColumns: [
        {
          width: 100,
        },
        {
          width: 100,
        },
        {
          width: 100,
        },
        {
          width: 100,
        },
        {
          width: 100,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 100,
        },
        {
          width: 200,
        },
        {
          width: 200,
        },
        {
          width: 200,
        },
        {
          width: 100,
        },
        {
          width: 200,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 150,
        },
        {
          width: 200,
        },
        {
          width: 250,
        },
        {
          width: 200,
        },
        {
          width: 250,
        },
      ],
    };
  },

  beforeMount() {
    this.store.invItemAndDimensionsById.forEach((element: InvItemAndDimensionsById) => {
      const cells = [
        {
          value: element.invNo,
          textAlign: "center",
        },
        {
          value: element.id,
          textAlign: "center",
        },
        {
          value: element.name,
          textAlign: "center",
        },
        {
          value: element.note,
          textAlign: "center",
        },
        {
          value: element.unitPrice.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.quantity.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.unit,
          textAlign: "center",
        },
        {
          value: element.tax,
          textAlign: "center",
        },
        {
          value: element.currNetto.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.currVat.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.currBrutto.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.remaining.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.invGroupName,
          textAlign: "center",
        },
        {
          value: element.invGroupNet.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.stationName,
          textAlign: "center",
        },
        {
          value: element.stationGroupName,
          textAlign: "center",
        },
        {
          value: element.toolName,
          textAlign: "center",
        },
        {
          value: element.userName,
          textAlign: "center",
        },
        {
          value: element.stationNet.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.carRegNum,
          textAlign: "center",
        },
        {
          value: element.carNet.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.projectName,
          textAlign: "center",
        },
        {
          value: element.projectNet.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.partName,
          textAlign: "center",
        },
        {
          value: element.partNet.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.eleventhDimensionName,
          textAlign: "center",
        },
        {
          value: element.eleventhDimensionNet.toLocaleString(),
          textAlign: "right",
        },
        {
          value: element.twelfthDimensionName,
          textAlign: "center",
        },
        {
          value: element.twelfthDimensionNet.toLocaleString(),
          textAlign: "right",
        },
      ];
      const obj: object = {
        cells,
        height: 20,
      };
      this.firstRows.push(obj);
    });
  },
});
