





















import Vue from "vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import DocumentHandlerApi from "../services/DocumentHandlerApi";

interface Data {
  loading: boolean;
}

interface Methods {
  createDocument(): void;
  openDocument(data: any): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    ApiSearchPage,
  },
  props: {
    urlFilterTable: { type: String },
    urlFilterId: { type: Number },
    browserTitle: { type: String },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    createDocument() {
      this.$router.push(`/documents/create`);
    },

    async openDocument(item) {
      const ret = DocumentHandlerApi.generateDocumentDownLoadLink(item.id);
      window.open(ret);
      console.log(ret);
    },
  },

  computed: {
    apiSearchUrl() {
      if (this.$props.urlFilterId && this.$props.urlFilterTable) {
        return `documents/apisearch/${this.$props.urlFilterTable}/${this.$props.urlFilterId}`;
      }
      return "documents/apisearch";
    },

    createButtonText() {
      return "Új dokumentum feltöltése";
    },

    columns: (): ApiSearchColumn[] => [
      {
        field: "id",
        title: "Iktatószám",
        // filterable: false,
      },
      {
        field: "typeName",
        title: "Fájl típusa",
      },
      {
        field: "created",
        title: "Létrehozás dátuma",
        type: ColumnType.Date,
      },
      {
        field: "createdBy",
        title: "Létrehozó",
      },
      {
        field: "fileName",
        title: "Fájl neve",
      },
      {
        field: "fileExtension",
        title: "Fájl kiterjesztése",
      },
      {
        field: "description",
        title: "Megjegyzés",
      },
      {
        field: "version",
        title: "Verzió",
      },
      { cell: "commandTemplate", filterable: false },
    ],
  },
});
