import IncomingInvoiceSubtype from "../models/IncomingInvoiceSubtype";
import IncomingInvoiceSubtypeApi from "./IncomingInvoiceSubtypeApi";

export class IncomingInvoiceSubtypeStore {
  subtypes: IncomingInvoiceSubtype[] = [];
  subtypesByType: IncomingInvoiceSubtype[] = [];
  selectedSubtype: IncomingInvoiceSubtype | undefined = undefined;

  async loadSubtypes() {
    this.subtypes = await IncomingInvoiceSubtypeApi.getAll();
  }

  async loadSubtypesByType(type: number) {
    this.subtypesByType = await IncomingInvoiceSubtypeApi.getAllByType(type);
  }

  async loadUninvoicedStockCorrectionSubtypes() {
    this.subtypes = await IncomingInvoiceSubtypeApi.getUninvoicedStockCorrectionSubtypes();
  }
}

const store = new IncomingInvoiceSubtypeStore();
export default store;
