















































































































































































































































































































































































































































































































































































































import Vue from "vue";
import http, { checkResponse, handleApiResponse } from "../../../services/http";

import MoneyInput from "@/components/MoneyInput.vue";
import eventBus from "@/services/eventBus";
import DateInput from "@/components/DateInput.vue";
import ApiResponse from "@/models/ApiResponse";
import IconButton from "@/components/IconButton.vue";
import dayjs from "dayjs";
import configuration, { AppConfiguration } from "@/models/Configuration";
import { InvoiceItem } from "../models";
import GeneralIngoingInvoice, { generalIngoingInvoiceDefaultProps } from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import { IntrastatData, intrastatDefaultProps, PreviousIntrastatData, previousIntrastatDataDefaultProps } from "../models/IntrastatData";

const endpoint = (invoiceID: number, invoiceItemID: number) =>
  `general-incoming-invoice/${invoiceID}/items/${invoiceItemID}/intrastat`;

class Incoterms {
  id = 0;
  name = "";
  parityType = "";
}

interface TransactionCodeData {
  id: number;
  name: string;
  code: string;
  remark: string;
}

interface CombinatedNomenclature {
  id: number;
  combinatedNomenclatureName: string;
  combinatedNomenclatureCode: string;
  expUnit: string;
  formattedName: string;
}

interface Country {
  id: number;
  code: string;
  name: string;
  foreign: string;
  english: string;
  currency: string;
  intrastatCode: string;
}

interface Data {
  intrastat: IntrastatData;
  originalProductID: number;
  combinatedNomenclaturesEntries: CombinatedNomenclature[];
  descriptionLimit: number;
  nomenclatureSearch: string;
  transSearch: string;
  isNomenclaturesLoading: boolean;
  transactionCodes: TransactionCodeData[];
  euCountryList: Country[];
  countryList: Country[];
  incotermsList: Incoterms[];
  filteredIncotermsList: Incoterms[];
  originalQuantity: number;
  originalWeight: number;
  filteredTransCodes: TransactionCodeData[];
  senderCountrySearch: string;
  filteredSenderCountries: Country[];
  filteredOriginCountries: Country[];
  originCountrySearch: string;
  incotermsSearch: string;
  tempData: number;

  showPrevTransaction: boolean;
  previousIntrastat: PreviousIntrastatData;

  configuration: AppConfiguration;
}

interface Props {
  invoiceID: number;
  invoiceItemID: number;
  invoiceItem: InvoiceItem;
  invoice: GeneralIngoingInvoice;
  isIndirectNoServ: boolean;
  isCustomDisabled: boolean;
}

interface Computed {
  hasSupplimentarUnit: boolean;

  combinatedNomenclatures: CombinatedNomenclature[];
  combinatedNomenclatureName: string;
  prevCombinatedNomenclatureName: string;
  isExpUnitDisabled: boolean;

  cols: number;
}

interface Methods {
  loadIntrastatData(): void;
  loadTransactionCodes(): void;
  loadEUCountryList(): void;
  loadCountryList(): void;
  submit(): void;
  getSuppUnit(): void;
  prodBlur(): void;
  getPreviousIntrastat(): void;
  redirectToPreviousInvoice(): void;
  loadIncotermsList(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    IconButton,
    MoneyInput,
    DateInput,
  },

  props: {
    isCustomDisabled: {
      type:Boolean,
      default: false
    },

    invoiceID: {
      type: Number,
    },

    invoiceItemID: {
      type: Number,
    },

    invoiceItem: {
      type: InvoiceItem
    },

    invoice: {
      default: generalIngoingInvoiceDefaultProps
    },
    
    isIndirectNoServ: {
      type: Boolean,
    },
  },

  data: () => ({
    intrastat: { ...intrastatDefaultProps },
    originalProductID: 1,
    combinatedNomenclaturesEntries: [],
    descriptionLimit: 60,
    nomenclatureSearch: "",
    transSearch: "",
    isNomenclaturesLoading: false,
    transactionCodes: [],
    euCountryList: [],
    countryList: [],
    incotermsList: [],
    filteredIncotermsList: [],
    originalQuantity: 0,
    originalWeight: 0,
    senderCountrySearch: "",
    originCountrySearch: "",
    incotermsSearch: "",
    filteredSenderCountries: [],
    filteredTransCodes: [],
    filteredOriginCountries: [],
    tempData: 0,
    isWeightDisabled: false,

    showPrevTransaction: false,
    previousIntrastat: { ...previousIntrastatDataDefaultProps },

    configuration,
  }),

  computed: {
    isExpUnitDisabled() {
      const supp = this.intrastat?.supplimentarUnit?.trim() ?? "-";
      return supp === "-";
    },

    cols() {
      return this.showPrevTransaction ? 7 : 12;
    },

    combinatedNomenclatures() {
      return this.combinatedNomenclaturesEntries.map((entry) => {
        const descript =
          entry.combinatedNomenclatureName.length > this.descriptionLimit
            ? entry.combinatedNomenclatureName.slice(0, this.descriptionLimit) +
              "..."
            : entry.combinatedNomenclatureName;

        return Object.assign({}, entry, { descript });
      });
    },

    combinatedNomenclatureName() {
      const f = this.combinatedNomenclatures.find(
        (x) => x.id == this.intrastat.productID
      );
      if (f !== undefined) {
        return f.combinatedNomenclatureName;
      } else return "-";
    },
    prevCombinatedNomenclatureName() {
      const f = this.combinatedNomenclatures.find(
        (x) => x.id == this.previousIntrastat.productID
      );
      if (f !== undefined) {
        return f.combinatedNomenclatureName;
      } else return "-";
    },

    hasSupplimentarUnit() {
      const x =
        this.intrastat.supplimentarUnit.trim().includes("-") ||
        this.intrastat.supplimentarUnit.trim() === "";
      return !x;
    },
  },

  async beforeMount() {
    Promise.all([
      this.loadTransactionCodes(),
      this.loadEUCountryList(),
      this.loadCountryList(),
      this.loadIncotermsList(),
      this.loadIntrastatData(),
    ]);

    console.log(this.intrastat);
    this.intrastat = { ...this.intrastat, quantity: 1};

    this.originalProductID = this.intrastat.productID;
  },
  methods: {
    async loadIncotermsList() {
      this.filteredIncotermsList = this.incotermsList = await http
        .get<ApiResponse<Incoterms[]>>(
          "general-incoming-invoice/intrastat/incoterms"
        )
        .then(handleApiResponse);
    },

    redirectToPreviousInvoice() {
      window.open(
        window.location.origin +
          `/general-incoming-invoice/flow/edit/${this.previousIntrastat.geni}/4`
      );
    },

    async getPreviousIntrastat() {
      this.showPrevTransaction = !this.showPrevTransaction;

      if (this.showPrevTransaction) {
        this.previousIntrastat = await http
          .get<ApiResponse<PreviousIntrastatData>>(
            endpoint(this.invoiceID, this.invoiceItemID) + "/previous"
          )
          .then(handleApiResponse);
      }
    },

    async loadIntrastatData() {
      await http
        .get<ApiResponse<IntrastatData>>(
          endpoint(this.invoiceID, this.invoiceItemID)
        )
        .then(handleApiResponse)
        .then((data) => {
          this.intrastat = data;
          this.intrastat.isIndirectNoServ = this.isIndirectNoServ;
        })
        .then(async () => {
          this.originalQuantity = Number(this.intrastat.quantity);
          this.originalWeight = Number(this.intrastat.weight);
          this.isNomenclaturesLoading = true;
          const search = this.intrastat.productID;
          await http
            .get<ApiResponse<CombinatedNomenclature[]>>(
              `general-incoming-invoice/${this.invoiceID}/items/${this.invoiceItemID}/intrastat/combinatednomenclatures/byid/${search}`
            )
            .then(handleApiResponse)
            .then((res) => {
              this.combinatedNomenclaturesEntries = res;
            })
            .finally(() => (this.isNomenclaturesLoading = false));
        });
    },

    prodBlur() {
      if (this.intrastat.productID < 1)
        this.intrastat.productID = this.tempData;
      if (this.intrastat.transCodeID < 1)
        this.intrastat.transCodeID = this.tempData;
      if (this.intrastat.senderCountry < 1)
        this.intrastat.senderCountry = this.tempData;
      if (this.intrastat.originCountry < 1)
        this.intrastat.originCountry = this.tempData;
    },

    async getSuppUnit() {
      const search = this.intrastat.productID;

      const suppUnit = await http
        .get<ApiResponse<string>>(
          `general-incoming-invoice/${this.invoiceID}/items/intrastat/supplimentarunit/byid/${search}`
        )
        .then((x) => {
          return x;
        })
        .then(handleApiResponse);

      if (suppUnit.trim() === "-") {
        this.intrastat.weightUnit = "KG";
        this.intrastat.weight = await http
          .get<ApiResponse<number>>(
            `general-incoming-invoice/${this.invoiceID}/${this.invoiceItemID}/items/intrastat/weightandunit/${this.intrastat.id}`
          )
          .then((x) => {
            return x;
          })
          .then(handleApiResponse);
      }

      this.intrastat.supplimentarUnit = suppUnit;
    },

    async loadTransactionCodes() {
      await http
        .get<ApiResponse<TransactionCodeData[]>>(
          `general-incoming-invoice/${this.invoiceID}/items/intrastat/transactioncodes`
        )
        .then(handleApiResponse)
        .then((data) => {
          this.transactionCodes = data;
        })
        .then(() => {
          this.filteredTransCodes = this.transactionCodes;
        });
    },

    async submit() {
      const response = await http.post(
        endpoint(this.invoiceID, this.invoiceItemID),
        {
          ...this.intrastat,
          invoiceItemID: this.invoiceItemID,
        }
      );

      const success = await checkResponse(response);
      if (success) {
        this.loadIntrastatData();
        eventBus.$emit("success", "Intrastat adatok mentve!");
        this.invoiceItem.hasExistingIntrastatData = true;
      }

      const isFilled =
        (dayjs(this.intrastat.arrivalDate).toDate() >
          dayjs("1990-01-01").toDate()
          || dayjs(this.intrastat.deliveryDate).toDate() >
          dayjs("1990-01-01").toDate()) &&
        this.intrastat.invoicedValue !== 0 &&
        this.intrastat.originCountry > 1 &&
        this.intrastat.productID > 1 &&
        this.intrastat.senderCountry > 1 &&
        (this.intrastat.weight !== 0 || (this.intrastat?.quantity ?? 0) !== 0) &&
        this.intrastat.transCodeID > 1;

      this.$emit("isFilled", isFilled);
    },
    async loadEUCountryList() {
      this.euCountryList = await http
        .get<ApiResponse<Country[]>>(
          `general-incoming-invoice/${this.invoiceID}/items/intrastat/eucountries`
        )
        .then(handleApiResponse)
        .then((data) => (this.filteredSenderCountries = data));
    },
    async loadCountryList() {
      this.countryList = await http
        .get<ApiResponse<Country[]>>(
          `general-incoming-invoice/${this.invoiceID}/items/intrastat/countries`
        )
        .then(handleApiResponse)
        .then((data) => {
          this.filteredOriginCountries = data;
          return data;
        });
    },
  },
  watch: {
    async nomenclatureSearch(value: string) {
      //Items have already been loaded
      //items have already been requested
      if (this.isNomenclaturesLoading) return;
      if (value) {
        if (value.length > 0) {
          this.isNomenclaturesLoading = true;
          const search = value;
          //Lazily load input items
          await http
            .get<ApiResponse<CombinatedNomenclature[]>>(
              `general-incoming-invoice/${this.invoiceID}/items/${this.invoiceItemID}/intrastat/combinatednomenclatures/${search}`
            )
            .then(handleApiResponse)
            .then((res) => {
              this.combinatedNomenclaturesEntries = res;
            })
            .finally(() => (this.isNomenclaturesLoading = false));
        }
      }
    },
    transSearch(value: string) {
      if (value) {
        if (value.length > 1) {
          if (isNaN(Number(value))) {
            this.filteredTransCodes = this.transactionCodes.filter((x) =>
              x.name.toLowerCase().includes(value.toLowerCase())
            );
          } else {
            this.filteredTransCodes = this.transactionCodes.filter((x) =>
              x.code.includes(value)
            );
          }
        } else this.filteredTransCodes = this.transactionCodes;
      }
    },
    incotermsSearch(value: string) {
      if (value) {
        if (value.length > 1) {
          this.filteredIncotermsList = this.incotermsList.filter(
            (x) =>
              x.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
              x.parityType
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase())
          );
        }
      } else this.filteredIncotermsList = this.incotermsList;
    },

    senderCountrySearch(value: string) {
      if (value) {
        if (value.length > 2) {
          this.filteredSenderCountries = this.euCountryList.filter((x) =>
            x.name.toLowerCase().includes(value.toLowerCase())
          );
        } else {
          this.filteredSenderCountries = this.euCountryList.filter(
            (x) => x.intrastatCode == value.toUpperCase()
          );
        }
      } else {
        this.filteredSenderCountries = this.euCountryList;
      }
    },
    originCountrySearch(value: string) {
      if (value) {
        if (value.length > 2) {
          this.filteredOriginCountries = this.countryList.filter((x) =>
            x.name.toLowerCase().includes(value.toLowerCase())
          );
        } else {
          this.filteredOriginCountries = this.countryList.filter(
            (x) => x.intrastatCode == value.toUpperCase()
          );
        }
      } else {
        this.filteredOriginCountries = this.countryList;
      }
    },
  },
});
