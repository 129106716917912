import GeneralIngoingInvoiceEditModel from "@/modules/generalIngoingInvoiceEditModel/models/GeneralIngoingInvoiceEditModel";

export class InvoiceFlowTaskResponse {
  recordableInvoices: null | GeneralIngoingInvoiceEditModel[] = null;
  financiallyRecordableInvoices: null | GeneralIngoingInvoiceEditModel[] = null;
  ffControllableInvoices: null | GeneralIngoingInvoiceEditModel[] = null;
  ffAcceptableInvoices: null | GeneralIngoingInvoiceEditModel[] = null;
  controllableInvoices: null | GeneralIngoingInvoiceEditModel[] = null;
  acceptableInvoices: null | GeneralIngoingInvoiceEditModel[] = null;
  permittableInvoices: null | GeneralIngoingInvoiceEditModel[] = null;
  downPaymentRequests: null | GeneralIngoingInvoiceEditModel[] = null;
  payableInvoices: null | GeneralIngoingInvoiceEditModel[] = null;
}

export class SelectedInvoiceFlowTasks {
  rec: number[];
  fin: number[];
  ffcontr: number[];
  ffacc: number[];
  contr: number[];
  acc: number[];
  perm: number[];
  dpaymr: number[];
  pay: number[];

  constructor(i?: InvoiceFlowTaskResponse) {
    this.rec = i?.recordableInvoices?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
    this.fin =
      i?.financiallyRecordableInvoices?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
    this.ffcontr =
      i?.ffControllableInvoices?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
    this.ffacc = i?.ffAcceptableInvoices?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
    this.contr = i?.controllableInvoices?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
    this.acc = i?.acceptableInvoices?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
    this.perm = i?.permittableInvoices?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
    this.dpaymr = i?.downPaymentRequests?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
    this.pay = i?.payableInvoices?.filter((x) => x.selected && x.entity.id > 0)?.map((x) => x.entity.id) ?? [];
  }

  loadSelectedItems(i: InvoiceFlowTaskResponse) {
    i.recordableInvoices?.forEach((x) => (x.selected = this.rec?.includes(x.entity.id)));
    i.financiallyRecordableInvoices?.forEach((x) => (x.selected = this.fin?.includes(x.entity.id)));
    i.ffControllableInvoices?.forEach((x) => (x.selected = this.ffcontr?.includes(x.entity.id)));
    i.ffAcceptableInvoices?.forEach((x) => (x.selected = this.ffacc?.includes(x.entity.id)));
    i.controllableInvoices?.forEach((x) => (x.selected = this.contr?.includes(x.entity.id)));
    i.acceptableInvoices?.forEach((x) => (x.selected = this.acc?.includes(x.entity.id)));
    i.permittableInvoices?.forEach((x) => (x.selected = this.perm?.includes(x.entity.id)));
    i.payableInvoices?.forEach((x) => (x.selected = this.pay?.includes(x.entity.id)));
    return i;
  }

  anySelected() {
    return (
      this.rec.length > 0 ||
      this.fin.length > 0 ||
      this.ffcontr.length > 0 ||
      this.ffacc.length > 0 ||
      this.contr.length > 0 ||
      this.acc.length > 0 ||
      this.perm.length > 0 ||
      this.pay.length > 0
    );
  }
}
