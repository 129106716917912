











































































































































import Vue from "vue";
import { getCurrentAccount } from "@/modules/currentAccount/api/CurrentAccountApi";
import ApiSearch from "@/components/apiSearch/models/ApiSearch";
import CurrentAccount from "@/modules/currentAccount/models/CurrentAccount";
import eventBus from "@/services/eventBus";
import HeaderItem from "@/components/HeaderItem.vue";
import { getPartnerTypes } from "@/modules/currentAccount/api/PartnerTypesApi";
import { IdNameDto } from "@/modules/letterOfCredit/letterOfCredit";
import { dateFormat, dateFormatHU } from "@/filters/date-format";
import CurrentAccountPreFilters from "../models/CurrentAccountPreFilters";

interface Data {
  extraFilter: any;
  cAccount: CurrentAccount;
  partnerTypeFilters: IdNameDto[];
}

interface Methods {
  removeIndex(array: IdNameDto[], index: number): void;
  removeIndexFromFilter(arrayProperty: string, index: number): void;
  removeItem(array: IdNameDto[], item: IdNameDto): void;
  removeItemFromFilter(arrayProperty: string, item: IdNameDto): void;
  setProperty(property: string, value: any, refreshCurrentAccount: boolean): void;
  refreshCurrentAccoutnApiSearch(): void;
  setPropertiesUndefined(property1: string, property2: string): void;

  getName(array: IdNameDto[], id: number): string;
  getExpirationFilterName(array: IdNameDto[], id: number): string;
}

interface Computed {
  statusFilters: IdNameDto[];
  expirationFilters: IdNameDto[];
  isVisibleDateFiltersDaysFilter: boolean;
  advanceRequestTypeFilters: IdNameDto[];
  ledgerPostingFilters: IdNameDto[];
}

interface Props {
  apiSearch: ApiSearch;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { HeaderItem },
  async created() {
    eventBus.$on("refreshed", async (apiSearch) => {
      this.cAccount = await getCurrentAccount(apiSearch);
      this.extraFilter = JSON.parse(JSON.stringify(apiSearch.extraFilter));
    });
    this.cAccount = await getCurrentAccount(this.apiSearch);
    this.partnerTypeFilters = await getPartnerTypes();

    if(this.apiSearch.extraFilter)
      this.extraFilter = JSON.parse(JSON.stringify(this.apiSearch.extraFilter));
  },
  data(): Data {
    return {
      extraFilter: new CurrentAccountPreFilters(),
      cAccount: new CurrentAccount(),
      partnerTypeFilters: [],
    };
  },
  props: {
    apiSearch: ApiSearch,
  },
  methods: {
    setPropertiesUndefined(property1: string, property2: string): void
    {
      this.setProperty(property1, undefined, false);
      this.setProperty(property2, undefined, false);
      this.refreshCurrentAccoutnApiSearch();
    },
    refreshCurrentAccoutnApiSearch(): void
    {
      eventBus.$emit("refreshCurrentAccount");
    },
    setProperty(property: string, value: any, refreshCurrentAccount: boolean): void 
    {
      this.extraFilter[property] = value;
      this.$forceUpdate();
      if(this.extraFilter[property] !==  this.apiSearch.extraFilter[property])
        this.apiSearch.extraFilter[property] = value;
      if(refreshCurrentAccount)
        this.refreshCurrentAccoutnApiSearch();
    },
    removeItemFromFilter(arrayProperty: string, item: IdNameDto): void
    {
      this.removeItem(this.extraFilter[arrayProperty], item);
      this.$forceUpdate();
      if(this.extraFilter[arrayProperty] !==  this.apiSearch.extraFilter[arrayProperty])
        this.removeItem(this.apiSearch.extraFilter[arrayProperty], item);

      this.refreshCurrentAccoutnApiSearch();
    },
    removeIndexFromFilter(arrayProperty: string, index: number): void
    {
      const value = this.extraFilter[arrayProperty][index];
      this.removeIndex(this.extraFilter[arrayProperty], index);
      this.$forceUpdate();
      if(this.extraFilter[arrayProperty] !==  this.apiSearch.extraFilter[arrayProperty])
      {
        const index = this.apiSearch.extraFilter[arrayProperty].indexOf(value);
        if(index > -1)
          this.removeIndex(this.apiSearch.extraFilter[arrayProperty], index);
      }
      this.refreshCurrentAccoutnApiSearch();
    },
    removeIndex(array: IdNameDto[], index: number): void
    {
      array.splice(index, 1);
    },
    removeItem(array: IdNameDto[], item: IdNameDto): void
    {
      const index = array.indexOf(item)
      this.removeIndex(array, index);
    },
    getName(array: IdNameDto[], id: number): string 
    {
      console.log(this.extraFilter.expirationFilter);
      return array.find(x => x.id == id)?.name ?? "";
    },
    getExpirationFilterName(array: IdNameDto[], id: number): string 
    {
      if(id == this.expirationFilters[2].id)
        return `Csak az ${this.extraFilter.expirationDaysFilter} napnál régebben lejárt számlák`;

      return this.getName(array, id);
    }
  },
  computed: {
    statusFilters: () => [
      { name: "Nyitott", id: 0 },
      { name: "Részben fizetett", id: 1 },
      { name: "Rendezettek", id: 2 },
    ],
    expirationFilters: () => [
      { name: "Mind", id: 0 },
      { name: "Csak a lejárt számlák", id: 1 },
      { name: 'Csak az "X" napnál régebben lejárt számlák', id: 2 },
    ],
    isVisibleDateFiltersDaysFilter() {
      if (this.apiSearch.extraFilter.expirationFilter == undefined) return false;

      return this.apiSearch.extraFilter.expirationFilter == this.expirationFilters[2].id;
    },
    advanceRequestTypeFilters: () => [
      { name: "Folyószámla előlegbekérőkkel", id: 0 },
      { name: "Előlegbekérők nélkül", id: 1 },
      { name: "Csak előlegbekérők", id: 2 },
    ],
    ledgerPostingFilters: () => [
      { name: "Minden folyószámlatétel", id: 0 },
      { name: "Csak a főkönyv felé feladott folyószámlatételek", id: 1 },
    ],
  },
});
