























import CashInViewApiSearchPage from "@/modules/cashStatement/cashInView/views/CashInViewApiSearchPage.vue";
import Vue from "vue";
import http, { handleApiResponse } from "@/services/http";
import eventBus from "@/services/eventBus";
import BankFlowView from "@/modules/cashStatement/receipt/models/BankFlowView";
import ApiResponse from "@/models/ApiResponse";
import store, { CashDocByReceiptsStore } from "@/modules/cashStatement/receipt/services/CashDocByreceiptsStore";
import base64toUInt8Array from "@/utils/fileConvert";
import GeneratedViroPdfViewer from "@/modules/cashStatement/receipt/components/GeneratedViroPdfViewer.vue";

interface Data {
  isPrinting: boolean;
  items: BankFlowView[];
  store: CashDocByReceiptsStore;
  prefix: string;
  invNo: number;
}

interface Methods {
  payInvoices(itemArray: BankFlowView[]): void;
  printViros(viros: number[]): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    CashInViewApiSearchPage,
    GeneratedViroPdfViewer
  },

  beforeMount(){
    if (this.$route.params.prefix){
      this.prefix = this.$route.params.prefix;
    }

    if (this.$route.params.invNo){
      this.invNo = Number(this.$route.params.invNo);
    }
  },

  data: (): Data => {
    return {
      isPrinting: false,
      items: [],
      store,
      prefix: "",
      invNo: 1
    };
  },

  methods: {
    async payInvoices(itemArray: BankFlowView[]) {
      try {
        this.isPrinting = true;
        const result = await http
          .post<ApiResponse<number[]>>(`open-invoices/pay-invoices`, {
            invoices: itemArray.map((x) => {
              return { ...x };
            }),
          })
          .then(handleApiResponse);

        if (result.length > 0) {
          eventBus.$emit("success", "Sikeres bankkártyás fizetés!");
          eventBus.$emit("cash-in-view:reload");
        }
        console.log(result);
        await this.printViros(result);
      } finally {
        this.isPrinting = false;
      }
    },

    async printViros(viros: number[]) {
      this.store.generatedViroPdfs = await http
        .post<ApiResponse<string[]>>(`open-invoices/print-viros`, { viros })
        .then(handleApiResponse)
        .then((x) => {
          return x.map((y) => {
            return base64toUInt8Array(y);
          });
        });
      console.log(this.store.generatedViroPdfs);
    },
  },
});
