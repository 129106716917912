import AccountStatus from "@/modules/notice/models/AccountStatus";
import NoticeApi from "@/modules/notice/services/NoticeApi";

export class InvoiceSubmissionStore {
  accountingStatuses = new Array<AccountStatus>();

  loadAccountStatuses = async () => {
    this.accountingStatuses = await NoticeApi.getAccountStatuses();
  }
}

const store = new InvoiceSubmissionStore();
export default store;
