var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[(!_vm.loading)?_c('ApiSearchPage',{attrs:{"title":"Banki kivonatok","detailsField":"selected","columns":_vm.columns,"url":_vm.url,"create":"Új kivonat"},on:{"create":_vm.create},scopedSlots:_vm._u([{key:"openBalanceTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.openBalance,"currency":item.currency}})]}},{key:"closeBalanceTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.closeBalance,"currency":item.currency}})]}},{key:"statementDetailsButtonTemplate",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"infoButton",class:{
              selected: item.selected,
            },attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"10"}},[_vm._v("fa-bars")])],1)]}}],null,true)},[_c('span',[_vm._v("Kivonat információk")])])]}},{key:"commandTemplate",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])])]}},{key:"status",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusName(item))+" ")]}},{key:"details",fn:function(){return [_c('div',{staticStyle:{"cursor":"default","background-color":"var(--selectedColor)","border-bottom":"1px solid var(--primaryColor)","border-left":"2px solid var(--primaryColor)","border-right":"2px solid var(--primaryColor)"}},[_c('BankStatementDetails',{attrs:{"statementID":_vm.selectedStatementID}})],1)]},proxy:true}],null,false,1288668374)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }