


























import Vue from "vue";
import ApiSearch from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import eventBus from "@/services/eventBus";
import IconButton from "@/components/IconButton.vue";
import configuration, { loadConfiguration } from "@/models/Configuration";
import type { AppConfiguration } from "@/models/Configuration";
import dayjs from "dayjs";


interface Data {
  errorGeniCount: number;
  configuration: AppConfiguration;
}

interface Computed {
  columns: ApiSearchColumn[];
  onOperel: boolean;
}

interface Methods {
  getErrorGeniCount(): void;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    ApiSearch,
  },

  data: () => ({
    errorGeniCount: 0,
    configuration
  }),

  beforeMount() {

    if(!this.configuration.isLoaded) {loadConfiguration();}
    if(this.onOperel){return;}

    const now = new Date();
    const morning = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
    5,0,0);
    const night = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
    22,0,0);
    const dayTime=now > morning && now < night;

    if(dayTime && this.configuration.sysParams.getErrorGeniCount){
      if(!this.configuration.isLoaded) {loadConfiguration();}
      if(this.onOperel){return;}

    this.getErrorGeniCount();
    }

    setInterval(() => {
      if(dayTime && this.configuration.sysParams.getErrorGeniCount){
      this.getErrorGeniCount();
      }
    }, 10000);
  },

  methods: {
    async getErrorGeniCount() {
      const result = await http
        .get<ApiResponse<number>>(`general-incoming-invoice/failed-stock-modifiers/count`)
        .then(handleApiResponse);
      if (this.errorGeniCount != result) {
        eventBus.$emit("api-search-page:refresh");
      }
      this.errorGeniCount = result;
    },
  },

  computed: {
    onOperel(){
      return this.configuration.operel;
    },

    columns: (): ApiSearchColumn[] => [
      {
        field: "geni",
        title: "GENI ID",
      },
      {
        field: "user",
        title: "Indító felhasználó",
      },
      {
        field: "status",
        title: "Státusz",
      },
      {
        field: "errText",
        title: "Hibaüzenet",
        cell: "errTextTemplate"
      },
      {
        cell: "rerun",
      },
    ],
  },
});
