










































































































































import Vue from 'vue';
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';
import { FilteringModel } from '@/services/filtering';
import IconButton from "@/components/IconButton.vue";
import InvoiceRegistrationDTO from "../models/InvoiceRegistrationDTO";
import MoneyDisplay from '@/components/MoneyDisplay.vue';
import store, { PaymentModeStore } from "@/modules/paymentMode/services/PaymentModeStore";
import DateInput from "@/components/DateInput.vue";
import EmailPickerForCaro from "../components/EmailPickerForCaro.vue";
import http, { handleApiResponse } from '@/services/http';
import ApiResponse from '@/models/ApiResponse';
import eventBus from '@/services/eventBus';
import PdfViewer from "@/components/PdfViewer.vue";
import base64toUInt8Array from '@/services/fileConvert';
import Fragment from '@/components/Fragment.vue';

interface Data {
    filter: FilteringModel;
    items: InvoiceRegistrationDTO[];
    store: PaymentModeStore;
    reportSrc: Uint8Array;
    stornoInProgress: boolean;
    lastSuccesfulStorno: InvoiceRegistrationDTO | null;
}

interface Props {
  extraFilter: FilteringModel;
  title: string;
  isCashDoc: boolean;
  noPadding: boolean;
  noActionsColor: boolean;
}

interface Methods {
    getPicture(invNo: number, prefix: string): void;
    storno(item: InvoiceRegistrationDTO): void;
    showDetails(item: InvoiceRegistrationDTO): void;
    refresh(): void;
}

export default Vue.extend<Data, Methods, {}, Props>({
    props: {
        extraFilter: { type: Object as () => FilteringModel },
        title: {
            type: String,
            default: "Sztornózandó számlák",
        },
        isCashDoc: {
            type: Boolean,
            default: false,
        },
        noPadding: {type: Boolean, default: false},
        noActionsColor: {type: Boolean, default: false},
    },

    components: {
        ApiSearchPage,
        IconButton,
        MoneyDisplay,
        DateInput,
        EmailPickerForCaro,
        PdfViewer,
        Fragment
    },

    data() {
        return {
            filter: new FilteringModel(),
            url: "caro-storno/search",
            items: [],
            store,
            reportSrc: new Uint8Array(),
            stornoInProgress: false,
            lastSuccesfulStorno: null,
        }
    },

    async beforeMount() {
        this.filter = new FilteringModel();
        if (this.$props.extraFilter){
            this.filter = this.filter.fromFilter(this.$props.extraFilter.toObject());
        }
        await this.store.loadPaymentModesWithoutBusy();
    },

    methods: {
        refresh(){
            const childComponentInstance = this.$refs.asref;
            if (childComponentInstance) {
                (this.$refs.asref as any).refreshClick();
            }
        },

        async getPicture(extidinvoice: number, prefix: string){
            console.log(extidinvoice);
            await http
                .get<ApiResponse<string>>(
                    `experimental-invoices/report/final-invoice/${extidinvoice}/${prefix}/${false}`
                )
                .then(handleApiResponse)
                .then((x) => {
                    this.stornoInProgress = false;
                    this.reportSrc = base64toUInt8Array(x);
                })
        },

        async storno(item: InvoiceRegistrationDTO){
            this.stornoInProgress = true;
            try {
                await http.post<ApiResponse<number>>(`experimental-invoices/storno-invoice`, item).then(handleApiResponse)
                    .then((x) => {
                        this.getPicture(Number(x), item.code);
                        this.lastSuccesfulStorno = item;
                        eventBus.$emit("success", "Sikeres sztornózás!");
                    }).then((_) => {
                        this.store.loadPaymentModesWithoutBusy();
                    });
            } catch (ex) {
                console.log(ex);
                eventBus.$emit("error", "Nem sikerült sztornózni a számlát!");
                this.stornoInProgress = false;
            }
        },

        showDetails(item: InvoiceRegistrationDTO) {
            if (item.selected) {
                if (this.lastSuccesfulStorno != null && this.lastSuccesfulStorno.id == item.id)
                {
                    this.lastSuccesfulStorno = null;
                    this.reportSrc = new Uint8Array();
                    eventBus.$emit("carostorno:reload");
                    //this.items = [...this.items.filter((x) => x.id != item.id)]
                }
            }
            item.selected = !item.selected;
        },
    },

    computed: {
        columns: () => [
            {
                cell: "selectButton",
                filterable: false,
                width: "80px"
            },
            {
                title: "Azonosító",
                field: "isEinvoice",
                cell: "pinvID",
                filteable: false
            },
            { 
                field: "partnerName",
                title: "Partner"
            },
            { 
                field: "code",
                title: "Prefix"
            },
            { 
                field: "norder",
                title: "Új gj. rend."
            },
            { 
                field: "pmodeName",
                title: "Fizetési mód"
            },
            {
                field: "insDate",
                title: "Létrehozás dátuma",
                type: ColumnType.Date
            },
            {
                field: "accDate",
                title: "Teljesítés dátuma",
                type: ColumnType.Date
            },
            {
                field: "remark1",
                title: "Megjegyzés",
            },
        ],

        innerColumns: () => [
            {
                field: "id",
                title: "Azonosító",
                headerClassName: "prColor"
            },
            { 
                field: "partnerName",
                title: "Partner",
                headerClassName: "prColor"
            },
            {
                field: "car",
                title: "Gépjármű",
                headerClassName: "prColor"
            },
            {
                field: "prCar",
                title: "Gépjármű rendelés",
                headerClassName: "prColor"
            },
            {
                cell: "currTotalTemplate",
                field: "currTotal",
                title: "Összeg",
                type: ColumnType.Money,
                headerClassName: "right_text prColor"
            },
            {
                field: "statusName",
                title: "Státusz",
                headerClassName: "prColor"
            },
            {
                field: "accDate",
                title: "Teljesítés dátuma",
                type: ColumnType.Date,
                headerClassName: "prColor"
            },
            {
                field: "accStatusName",
                title: "Könyvelési állapot",
                headerClassName: "prColor"
            },
        ],
    },

    beforeDestroy() {
        this.reportSrc = new Uint8Array();
    },
})
