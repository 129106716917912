






































import Vue from "vue";

import IconButton from "../../../components/IconButton.vue";
import * as api from "../services/api";
import store, { InvoiceItemStore } from "../services/store";
import { ExtOrder, ExtOrderTypeOption } from "../models";
import eventBus from "../../../services/eventBus";
import CooperationOrdersTable from "./CooperationOrdersTable.vue";

interface Data {
  searchText: string;
  extOrders: ExtOrder[];
  store: InvoiceItemStore;
}

interface Methods {
  search(e: KeyboardEvent): void;
  clear(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    CooperationOrdersTable,
    IconButton,
  },

  data: () => ({
    store,
    extOrders: [],
    searchText: "",
  }),

  mounted() {
    const extOrderItemAddHandler = () => (this.extOrders = [])
    eventBus.$on("invoice-item:add", extOrderItemAddHandler);
  },

  methods: {
    search(e: KeyboardEvent) {
      if(e.key !== "Enter") {
        return
      }

      e.preventDefault();
      return api
        .getExtOrders(this.store.supplierID, this.searchText, ExtOrderTypeOption.Cooperation)
        .then((extOrders) => (this.extOrders = extOrders));
    },

    clear() {
      this.searchText = "";
      this.extOrders = [];
    }
  },
});
