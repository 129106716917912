


















































import iIstore, {
  InvoiceItemStore,
} from "@/modules/invoiceItems/services/store";
import { InvoiceItem } from "@/modules/invoiceItems/models";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import Vue from "vue";
import iFstore, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import TaxAndVatItem from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";
import gStore, {
  GeneralIngoingInvoiceStore,
} from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import dimStore, {
  InvoiceItemDimensionStore,
} from "@/modules/invoiceItemDimension/services/InvoiceItemDimensionStore";
import permStore, { PermissionStore } from "@/services/permissions";
import * as api from "@/modules/invoiceItems/services/api";

interface Data {
  gStore: GeneralIngoingInvoiceStore;
  iIstore: InvoiceItemStore;
  iFstore: InvoiceFlowStore;
  dimStore: InvoiceItemDimensionStore;
  permStore: PermissionStore;
  currency: string;
  invoiceId: number;
  partnerId: number;
  supplierId: number;
  defaultTaxID: number;
}


interface Methods {
  vatDivItems(item: InvoiceItem): TaxAndVatItem[];
  itemRowBackground(item: InvoiceItem): string;
}

export default Vue.extend<Data, Methods, {}, {}>({
  async beforeMount() {
    this.currency=this.iFstore.invoice.invCurrency;
    this.invoiceId = Number(this.$route.params.invoiceId);
    this.supplierId = Number(this.iIstore.supplierID);
    this.defaultTaxID=Number(this.iIstore.defaultTaxID);
    const units = await api.getUnits();
    const taxes = await api.getTaxes();
    const taxAndVatItems = await api.getAllTaxAndVatItem();
    const invoiceItems = await api.getInvoiceItems(this.invoiceId);
    const storeInFeeTypes = await api.getStoreInFeeTypes();

    this.iIstore.init({
      invoiceID: this.invoiceId,
      currency: this.currency,
      supplierID: this.supplierId,
      defaultTaxID: this.defaultTaxID,
      storeInFeeTypes,
      units,
      taxes,
      taxAndVatItems,
      invoiceItems,
    });
  },
  watch: {
    invoiceID(invoiceID: number) {
      api.getInvoiceItems(this.invoiceId).then((invoiceItems) =>
        this.iIstore.update({
          invoiceID,
          invoiceItems,
        })
      );
    },

    supplierID(supplierID: number) {
      this.iIstore.update({ supplierID });
    },

    currency(currency: string) {
      this.iIstore.update({ currency });
    },
  },

  data: () => ({
    defaultTaxID: 1,
    supplierId: 1,
    currency: "",
    dimStore,
    iIstore,
    iFstore,
    gStore,
    permStore,
    partnerId: 1,
    invoiceId: 1,
  }),

  components: { MoneyDisplay },

  methods: {
    vatDivItems(item: InvoiceItem): TaxAndVatItem[] {
      return this.iIstore.taxAndVatItems.filter((x) => x.id == item.tax.id);
    },

    itemRowBackground(item: InvoiceItem): string {
      return this.iIstore.itemList.items.findIndex((x) => x.cuid == item.cuid) %
        2 ==
        0
        ? "darker"
        : "whiter";
    },
  },

  computed: {
    items() {
      return this.iIstore.itemList.items;
    },
    columns: () => [
      {
        field: "itemName",
        title: "Tétel",
      },

      {
        field: "nettoValue",
        title: "Nettó",
        cell: "nettoValueTemplate",
      },
      {
        field: "bruttoValue",
        title: "Bruttó",
        cell: "bruttoValueTemplate",
      },
      {
        field: "taxValue",
        title: "Áfa",
        cell: "taxValueTemplate",
      },
      {
        field: "tax.degree",
        title: "Áfa %",
      },
      {
        field: "tax",
        title: "Áfa bontás",
        cell: "taxTemplate",
      },
    ],
  },
});
