
































































import Vue from "vue";
import { Stock } from "../models";
import Fragment from "../../../components/Fragment.vue";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import configuration from "@/models/Configuration";

interface Props {
  items: Stock[];
}

export default Vue.extend<{}, {}, {}, Props>({
  components: {
    Fragment,
    MoneyDisplay,
  },

  props: {
    items: {
      type: Array as () => Stock[],
    },
  },

  data() {
    return {
      configuration,
    };
  },

  mounted() {
    console.log(this.items);
  },

  computed: {
    hasData() {
      return this.items && this.items.length > 0;
    },
  },
});
