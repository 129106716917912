export default interface TechnicalInvoiceCreatorUser {
  id: number;
  employment: string;
  email: string;
  phone: string;
  address: string;
  city: number;
  isInvIns: boolean;
  isInvAccept: boolean;
  isInvContr: boolean;
  isInvPermit: boolean;
  pictureLink: string | null;
  hasPicture: boolean;
  loginName: string;
  section: number;
  manager: number;
  supervisor: boolean;
  name: string;
  readOnly: boolean;
  deleted: boolean;
}

export const technicalInvoiceCreatorUserDefaultProps: TechnicalInvoiceCreatorUser = {
  employment: "-",
  email: "-",
  phone: "-",
  address: "-",
  city: 1,
  isInvIns: true,
  isInvAccept: false,
  isInvContr: false,
  isInvPermit: false,
  pictureLink: null,
  hasPicture: false,
  loginName: "-",
  section: 1,
  manager: 1,
  supervisor: true,
  name: "-",
  readOnly: false,
  deleted: true,
  id: 1,
};
