export default interface IncomingInvoiceType {
  id: number;
  deleted: boolean;
  readOnly: boolean;
  name: string;
  foreign: string;
  english: string;
  promptArrive: boolean;
  postCalc: boolean;
  defAccgroup: number;
  detailedType: number;
  code: string;
  vatAccCode: string;
  expAccCode: string;
  diffAccCode: string;
  supplAccCode: string;
  separAccCode: string;
  separAAccCode: string;
  separPAccCode: string;
  owingVatAccCode: string;
  technVatAccCode: string;
  isDefault: boolean;
  isCar: boolean;
  isItem: boolean;
  isOutinv: boolean;
  isGparts: boolean;
  isSubtype: boolean;
  isMustAccGroup: boolean;
  isExtData: boolean;
  isCarInItem: boolean;
  isContr: boolean;
  isInvPermit: boolean;
  isProject: boolean;

  isAlive: boolean;
}

export const incomingInvoiceTypeDefaultProps: IncomingInvoiceType = {
  id: 0,
  deleted: false,
  readOnly: true,
  name: "",
  foreign: "",
  english: "",
  promptArrive: false,
  postCalc: false,
  defAccgroup: 0,
  detailedType: 0,
  code: "",
  vatAccCode: "",
  expAccCode: "",
  diffAccCode: "",
  supplAccCode: "",
  separAccCode: "",
  separAAccCode: "",
  separPAccCode: "",
  owingVatAccCode: "",
  technVatAccCode: "",
  isDefault: false,
  isCar: false,
  isItem: false,
  isOutinv: false,
  isGparts: false,
  isSubtype: false,
  isMustAccGroup: false,
  isExtData: false,
  isCarInItem: false,
  isContr: false,
  isInvPermit: false,
  isProject: false,

  isAlive: true,
};
