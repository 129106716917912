






































































































































































































































































import Vue from "vue";

import store from "../services/GeneralIngoingInvoiceStore";
import http, { baseURL } from "@/services/http";
import { debounce } from "debounce";
import { FilteringModel, SortingModel, SortOrder } from "../../../services/filtering";

import DateInput from "../../../components/DateInput.vue";
import SortView from "@/components/SortView.vue";
import TextInput from "@/components/TextInput.vue";
import configuration from "@/models/Configuration";
import eventBus from "../../../services/eventBus";

const shouldFilter = (filter: any) => {
  for (const key of Object.keys(filter)) {
    if (filter[key].hasValue()) {
      return true;
    }
  }

  return false;
};

export default Vue.extend({
  components: {
    DateInput,
    TextInput,
    SortView,
  },

  props: {
    excelExportVisible: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    store,
    configuration,
    active: false,
    filter: store.filterModel,
    sorting: store.sortModel,
    reloadHandler: () => {
      console.log("nop");
    },

    changeHandler: (filterValue: any, sortValue: any) => console.log(filterValue, sortValue),

    excelExportinProgress: false,
  }),

  async beforeMount() {
    if (!this.configuration.operel) {
      await this.store.loadTypes();
      await this.store.loadAcceptorAndControlUsers();
    }

    this.changeHandler = debounce(
      (filterValue, sortValue: SortingModel) => {
        const applyFilter = shouldFilter(filterValue);
        const sort = sortValue.toObject();
        const shouldApply = applyFilter || sort.order;
        if (shouldApply) {
          this.active = true;
          eventBus.$emit("incoming-invoice-pagefilter:search");
          this.$emit("change", {
            filter: filterValue,
            sort,
          });
        }
      },
      500,
      false
    );

    this.reloadHandler = () => {
      this.changeHandler(this.filter, this.sorting);
    };
    eventBus.$on("incoming-invoices:reload", this.reloadHandler);
  },

  beforeDestroy() {
    eventBus.$off("incoming-invoices:reload", this.reloadHandler);
  },

  methods: {
    excelExportOperel() {
      const filterParam = encodeURIComponent(JSON.stringify(this.filter));
      const sortParam = encodeURIComponent(JSON.stringify((this.sorting as SortingModel).toObject()));
      const uri = `${baseURL}general-incoming-invoice/excel?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}&filter=${filterParam}&sort=${sortParam}`;
      window.open(uri);
    },

    excelExport() {
      this.excelExportinProgress = true;
      const filterParam = JSON.stringify(this.filter);
      const sortParam = JSON.stringify((this.sorting as SortingModel).toObject());
      http.post(`general-incoming-invoice/excel/k2d`, {
        filter: filterParam,
        sort: sortParam
      },
      {
        responseType: 'blob'
      })
      .then(response => {
        this.excelExportinProgress = false;
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url, "invoices.xlsx");

        const a = document.createElement('a');
        a.href = url;
        a.download = 'invoices.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error(error);
      });
    },
  },

  watch: {
    filter: {
      deep: true,
      handler(filterValue) {
        this.changeHandler(filterValue, this.sorting);
      },
    },
    sorting: {
      deep: true,
      handler(sortingValue) {
        this.changeHandler(this.filter, sortingValue);
      },
    },
  },
});
