import SerStationGroup, {serStationGroupDefaultProps} from "../models/SerStationGroup";
import SerStationGroupApi from "./SerStationGroupApi";

export class SerStationGroupStore{
    serStationGroups: SerStationGroup[]=[]
    serStationGroup: SerStationGroup = {...serStationGroupDefaultProps}

    async loadSerStationGroups(){
        this.serStationGroups = await SerStationGroupApi.getAll();
    }
}

const store = new SerStationGroupStore()
export default store