
























































































































































































































































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";

import Vue from "vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import CashOutView from "@/modules/cashStatement/CashOutView/models/CashOutView";
import { FilteringModel } from "@/services/filtering";
import CheckBox from "@/components/CheckBox.vue";
import cashStatementStore from "@/modules/cashStatement/services/store";
import eventBus from "@/services/eventBus";
import pStore, {
  PaymentModeStore,
} from "@/modules/paymentMode/services/PaymentModeStore";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  filter: FilteringModel;
  sum: number;
  items: CashOutView[];
  selectedItems: CashOutView[];
  pStore: PaymentModeStore;
    configuration: AppConfiguration;
  addAll: boolean;
}

interface Props {
  extraFilter: FilteringModel;
  partnerId: number;
  currency: string;
  isOverpay: boolean;
  cashDocByReceipts: boolean;
  title: string;
  isOutgoingDocument: boolean;
  noPadding: boolean;
  noActionsColor: boolean;
}

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
}

interface Methods {
  selected(item: CashOutView): void;
  showDetails(item: CashOutView): void;
  addToSummary(dataItem: CashOutView): void;
  setItems(items: CashOutView[]): void;
  addItems(): void;
  createNewIncomingInvoice(): void;
  selectAllItems(): void;
  refresh(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ApiSearchPage, CheckBox },

  props: {
    extraFilter: { type: Object as () => FilteringModel },
    partnerId: { type: Number, default: 1 },
    currency: { type: String, default: "" },
    isOverpay: { type: Boolean, default: false },
    cashDocByReceipts: { type: Boolean, default: false },
    title: { type: String, default: "Számlaválogató" },
    isOutgoingDocument: { type: Boolean, default: false },
    noPadding: {type: Boolean, default: false},
    noActionsColor: {type: Boolean, default: false},
  },

  data: () => ({
    filter: new FilteringModel(),
    sum: 0,
    items: [],
    selectedItems: [],
    pStore,
    addAll: false,
    configuration,
  }),

  async beforeMount() {
    this.filter = new FilteringModel();
    if (this.$props.extraFilter)
      this.filter = this.filter.fromFilter(this.$props.extraFilter.toObject());
    if (this.pStore.paymentModes.length < 2) {
      await this.pStore.loadPaymentModesWithoutBusy();
    }
  },

  methods: {
    refresh(){
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },

    selectAllItems(){
      this.selectedItems = [];
      this.addAll = !this.addAll;

      if (this.addAll) {
        this.items.forEach(x => {
          if (x.lockAction.length == 0) {
            x.added = false;
            this.addToSummary(x);
          }
        });
      } else {
        this.items.forEach(x => {
          x.added = false;
        });
      }
    },

    createNewIncomingInvoice() {
      //this.$router.push("/general-incoming-invoice/create");
      if (this.configuration.invoiceFlow.enabled) {
        this.$router.push(`/general-incoming-invoice/flow/create/1`);
      }
      else {
        this.$router.push(`/general-incoming-invoice/create`);
      }
      this.$route.meta.isPictureRequired = false
    },

    async addItems() {
      if (!this.cashDocByReceipts) {
        await cashStatementStore.loadCashDocItemsByDocumentId(
          cashStatementStore.cashDocument.id
        );
      }

      this.$emit("add-selected-items", this.selectedItems);
    },

    selected(item: CashOutView) {
      this.$emit("selected", item);
    },

    showDetails(item: CashOutView) {
      item.selected = !item.selected;
    },

    addToSummary(dataItem: CashOutView) {
      eventBus.$emit("cash-out-view:get-items");
      if (!dataItem.added) {
        this.sum += dataItem.toPayment;
        dataItem.added = true;
      } else {
        this.sum -= dataItem.toPayment;
        dataItem.added = false;
        this.selectedItems = this.selectedItems.filter(
          (x) => x.invNo !== dataItem.invNo
        );
      }
      this.$emit("update-sum", this.sum);
    },

    setItems(items) {
      this.items = items;
      const newSelected = items.filter((x) => x.added);
      newSelected.forEach((element) => {
        if (!this.selectedItems.includes(element)) {
          this.selectedItems.push(element);
        }
      });
      console.log(this.selectedItems);
    },
  },

  computed: {
    columns: function() {
      return this.cashDocByReceipts
        ? [
            {
              cell: "detailsButton",
              filterable: false,
              header: 'allSelect'
            },
            //{ field: "prefix", title: "Prefix", headerClass: "prefix-column" },
            { field: "maskedInvNo", title: "Számlaszám" },
            { field: "partner", title: "Partner" },
            {
              field: "pmode",
              title: "Fizetési mód",
              multiselect: true,
              multiselectField: "pmodes",
              multiselectOptions: this.pStore.paymentModes.map((x) => ({
                text: x.name,
                value: x.id,
              })),
              multiselectSelect: this.pStore.paymentModes
                .filter((x) => x.name === "Készpénz" || x.name === "Bankkártya")
                .map((x) => x.id),
            },
            {
              cell: "total",
              field: "total",
              title: "Összeg",
              type: ColumnType.Money,
            },
            {
              cell: "toPayment",
              field: "toPayment",
              title: "Szla. nyitott rész",
              type: ColumnType.Money,
              sortable: false,
              filterable: false,
            },
            { field: "remark", title: "Megjegyzés", fieldClass: "note-column" },
          ]
        : [
            {
              cell: "detailsButton",
              filterable: false,
              header: 'allSelect'
            },
            //{ field: "prefix", title: "Prefix", headerClass: "prefix-column" },
            { field: "maskedInvNo", title: "Számlaszám" },
            { field: "partner", title: "Partner" },
            {
              field: "pmode",
              title: "Fizetési mód",
              multiselect: true,
              multiselectField: "pmodes",
              multiselectOptions: this.pStore.paymentModes.map((x) => ({
                text: x.name,
                value: x.id,
              })),
            },
            {
              cell: "total",
              field: "total",
              title: "Összeg",
              type: ColumnType.Money,
            },
            {
              cell: "toPayment",
              field: "toPayment",
              title: "Szla. nyitott rész",
              type: ColumnType.Money,
              filterable: false,
              sortable: false,
            },
            { field: "remark", title: "Megjegyzés", fieldClass: "note-column" },
          ];
    },

    url() {
      return this.$props.cashDocByReceipts
        ? `cash-flow/out/dto/search/cashdocbyreceipts/${this.$props.currency}`
        : `cash-flow/out/dto/search/${this.$props.isOverpay}/${this.$props.partnerId}/${this.$props.currency}`;
    },
  },
});
