import http, { handleApiResponse, checkResponse } from "@/services/http";
import CashPointDisplay from "@/modules/cashPoint/models/CashPointDisplay";
import CashPointEdit from "@/modules/cashPoint/models/CashPointEdit";
import AccountNoDetailedType from "@/modules/cashPoint/models/AccountNoDetailedType";
import CashRegType from "@/modules/cashPoint/models/CashRegType";
import PartBank from "@/modules/cashPoint/models/PartBank";
import Printer from "@/modules/cashPoint/models/Printer";
import Depot from "@/modules/cashPoint/models/Depot";
import Country from "@/modules/cashPoint/models/Country";
import CashUser from "@/modules/cashPoint/models/CashUser";
import CashTax from "@/modules/cashPoint/models/CashTax";
import ApiResponse from "@/models/ApiResponse";
import CashpointType from "../models/CashpointType";
import CashPointSelect from "../models/CashPointSelect";

export default class CashPointApi {
  static getAll(): Promise<CashPointDisplay[]> {
    return http.get<ApiResponse<CashPointDisplay[]>>(`cashPoint`).then(handleApiResponse);
  }

  static getAllShort(): Promise<CashPointEdit[]> {
    return http.get<ApiResponse<CashPointEdit[]>>(`cashPoint/short/all`).then(handleApiResponse);
  }

  static getShort(cashPointID: number): Promise<CashPointEdit> {
    return http.get<ApiResponse<CashPointEdit>>(`cashPoint/short/${cashPointID}`).then(handleApiResponse);
  }

  static get(id: number): Promise<CashPointEdit> {
    return http.get<ApiResponse<CashPointEdit>>(`cashPoint/${id}`).then(handleApiResponse);
  }

  static getNew(): Promise<CashPointEdit> {
    return http.get<ApiResponse<CashPointEdit>>(`cashPoint/new`).then(handleApiResponse);
  }

  static getAccountNoDetailedTypes(): Promise<AccountNoDetailedType[]> {
    return http.get<ApiResponse<AccountNoDetailedType[]>>(`accountDetailedType`).then(handleApiResponse);
  }

  static getCashpointTypes(): Promise<CashpointType[]> {
    return http.get<ApiResponse<CashpointType[]>>(`cashPoint/types`).then(handleApiResponse);
  }

  static getCashRegTypes(): Promise<CashRegType[]> {
    return http.get<ApiResponse<CashRegType[]>>(`cashRegType`).then(handleApiResponse);
  }

  static getPartBanks(isAlive: boolean): Promise<PartBank[]> {
    return http.get<ApiResponse<PartBank[]>>(`partBank/${isAlive}`).then(handleApiResponse);
  }

  static getPrinters(): Promise<Printer[]> {
    return http.get<ApiResponse<Printer[]>>(`printer`).then(handleApiResponse);
  }

  static getDepots(): Promise<Depot[]> {
    return http.get<ApiResponse<Depot[]>>(`depot`).then(handleApiResponse);
  }

  static getCurrencies(): Promise<Country[]> {
    return http.get<ApiResponse<Country[]>>(`currency`).then(handleApiResponse);
  }

  static getCountries(): Promise<Country[]> {
    return http.get<ApiResponse<Country[]>>(`country`).then(handleApiResponse);
  }

  static getIsCashier(): Promise<boolean> {
    return http.get<ApiResponse<boolean>>(`cashPoint/iscashier`).then(handleApiResponse);
  }

  static update(item: CashPointEdit): Promise<boolean> {
    return http.put(`cashPoint`, { cashPoint: item }).then(checkResponse);
  }

  static create(item: CashPointEdit): Promise<any> {
    return http.post(`cashPoint`, { cashPoint: item }).then(handleApiResponse);
  }

  static getCashPoints(): Promise<CashPointSelect[]> {
    return http.post<ApiResponse<CashPointSelect[]>>(`cash-statement/cashpoints/currentuser`).then(handleApiResponse);
  }

  static updateCashiers(item: CashUser[], cashPointId: number): Promise<boolean> {
    return http.put(`cashPoint/cashiers`, { cashiers: item, cashPointId: cashPointId }).then(checkResponse);
  }

  static updateTaxGroups(item: CashTax[], cashPointId: number): Promise<boolean> {
    return http.put(`cashPoint/tax-groups`, { taxGroups: item, cashPointId: cashPointId }).then(checkResponse);
  }

  static delete(id: number): Promise<boolean> {
    return http.delete(`cashPoint/${id}`).then(checkResponse);
  }
}
