








































































import PartBank from "@/modules/cashPoint/models/PartBank";
import { PageChangeEvent } from "@progress/kendo-vue-dropdowns/dist/npm/common/events";
import { GridRowProps } from "@progress/kendo-vue-grid";
import Vue from "vue";

interface Data {
  skip: number;
  take: number;
  bankAccountDialog: boolean;
}

interface Props {
  disabled: boolean;
  addNew: boolean;
  labelmd: string;
  showLabel: boolean;
  partBanks: PartBank[];
  selectedBankAccount: PartBank | undefined;
  extraClass: string;
  labelCol: string;
  itemCol: string;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    disabled: { type: Boolean, default: false },
    addNew: { type: Boolean, default: false },
    labelmd: { type: String, default: "2" },
    showLabel: { type: Boolean, default: true },
    partBanks: Array as () => PartBank[],
    selectedBankAccount: Object as () => PartBank,
    extraClass: { type: String, default: "" },
    labelCol: { type: String, default: "4" },
    itemCol: { type: String, default: "8" },
  },

  data: () => ({
    skip: 0,
    take: 10,
    bankAccountDialog: false,
  }),

  methods: {
    itemSelected(props: GridRowProps) {
      const partBank = props.dataItem;
      this.bankAccountDialog = false;
      this.$emit("change", partBank);
    },

    pageChange(event: PageChangeEvent) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
  },

  computed: {
    selectedName() {
      return (
        (this.selectedBankAccount?.accountNoSplit || this.selectedBankAccount?.accountNo || "-") +
        " | " + //
        (this.selectedBankAccount?.bank || "-") +
        " | " + //
        (this.selectedBankAccount?.currency || "-") //
      );
    },

    items() {
      return this.partBanks.slice(this.skip, this.take + this.skip).map((x) => ({
        ...x,
        selected: x.id === this.selectedBankAccount?.id,
      }));
    },

    total() {
      return this.partBanks ? this.partBanks.length : 0;
    },
  },
});
