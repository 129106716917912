

























































































































































































































































































import Vue from "vue";
import { validate } from "../../../services/validators/accountNo";
import store, { PartnerStore } from "../services/PartnerStore";
import bankStore, { BankStore } from "@/modules/bank/services/BankStore";
import DetailsOfCharges from "@/modules/bank/models/DetailsOfCharges";

import { NumericTextBox } from "@progress/kendo-vue-inputs";

interface Data {
  store: PartnerStore;
  bankStore: BankStore;
  bankAccFirst: string;
  bankAccSecond: string;
  bankAccThird: string;
  detailsOfCharges: DetailsOfCharges;

  rules: any;
  accountNoError: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  props: {
    partBankProps: Object,
  },

  components: {
    NumericTextBox,
  },

  data: () => ({
    store,
    bankStore,
    bankAccFirst: "",
    bankAccSecond: "",
    bankAccThird: "",
    detailsOfCharges: new DetailsOfCharges(),

    rules: {
      required: (value: any) => !!value || "Kötelező",
      counter: (value: any) => value.length <= 8 || "Maximum 8 karakter",
    },

    accountNoError: false,
  }),

  async beforeMount() {
    await Promise.all([this.store.loadCurrencies(), this.store.loadBanks(), this.bankStore.loadDetailsOfCharges()]);
    this.store.partBank = { ...this.$props.partBankProps };
    this.detailsOfCharges =
      this.bankStore.detailsOfCharges.find((x) => x.id == this.$props.partBankProps.detailsOfCharges) ??
      new DetailsOfCharges();

    if (this.$props.partBankProps.accountNo.includes("-")) {
      const accountNoArray = this.$props.partBankProps.accountNo.split("-");
      this.bankAccFirst = accountNoArray[0];
      this.bankAccSecond = accountNoArray[1] ?? "";
      this.bankAccThird = accountNoArray[2] ?? "";
    } else {
      this.bankAccFirst = this.$props.partBankProps.accountNo.slice(0, 8);
      this.bankAccSecond = this.$props.partBankProps.accountNo.slice(8, 16);
      this.bankAccThird = this.$props.partBankProps.accountNo.slice(16, 24);
    }
  },

  methods: {
    validate(accountNo: string) {
      validate(accountNo);
    },

    isNativeChanged(isNative: boolean) {
      if (!isNative) return;

      this.detailsOfCharges = new DetailsOfCharges();
      this.store.partBank.detailsOfCharges = this.detailsOfCharges.id;
    },

    jumpToNext(currItem: string) {
      if (currItem == "bankAccFirst" && this.bankAccFirst.length == 8) {
        (this.$refs["bankAccSecond"] as any).focus();
      } else if (currItem == "bankAccSecond" && this.bankAccSecond.length == 8) {
        (this.$refs["bankAccThird"] as any).focus();
      }
    },

    async save() {
      if (this.store.partBank.isNative) {
        if (this.bankAccThird.length == 0) {
          this.bankAccThird = "00000000";
        }

        this.store.partBank.accountNo = `${this.bankAccFirst}-${this.bankAccSecond}-${this.bankAccThird}`;
      }

      this.accountNoError =
        this.store.partBank.accountNo.trim() === "" ||
        (this.store.partBank.isNative && !validate(this.store.partBank.accountNo));

      if (this.accountNoError) return;

      this.$emit("clicked", true);
      await this.store.updatePartBank();
      await this.store.loadPartBanks(this.store.partner.id);
    },

    cancel() {
      this.store.partBank = { ...this.$props.partBankProps };
      this.$emit("clicked", false);
    },
  },
});
