var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[[_c('div',[_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns},scopedSlots:_vm._u([{key:"nettoValueTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"currency":props.dataItem.currency,"textalign":"text-left"},model:{value:(props.dataItem.nettoValue),callback:function ($$v) {_vm.$set(props.dataItem, "nettoValue", $$v)},expression:"props.dataItem.nettoValue"}})],1)]}},{key:"bruttoValueTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"currency":props.dataItem.currency,"textalign":"text-left"},model:{value:(props.dataItem.bruttoValue),callback:function ($$v) {_vm.$set(props.dataItem, "bruttoValue", $$v)},expression:"props.dataItem.bruttoValue"}})],1)]}},{key:"taxValueTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"currency":props.dataItem.currency,"textalign":"text-left"},model:{value:(props.dataItem.taxValue),callback:function ($$v) {_vm.$set(props.dataItem, "taxValue", $$v)},expression:"props.dataItem.taxValue"}})],1)]}},{key:"taxTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-select',{attrs:{"return-object":"","items":_vm.vatDivItems(props.dataItem),"outlined":"","dense":"","item-text":"vatItemName"},model:{value:(props.dataItem.tax),callback:function ($$v) {_vm.$set(props.dataItem, "tax", $$v)},expression:"props.dataItem.tax"}})],1)]}}])})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }