









































































import Vue from "vue";
import store, { AccountActionSourceStore } from "../services/AccountActionSourceStore";
import Page from "../../../components/Page.vue";
import AccountActionSource from "../models/AccountActionSource";

interface Data {
  store: AccountActionSourceStore;
}
export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadAccountActionSources();
  },

  computed: {
    columns: () => [
      { field: "name", title: "Megnevezés" },
      {
        field: "isPrinting",
        title: "Riport nyomtatás",
        cell: "isPrintingTemplate",
        editor: "boolean",
      },
      {
        field: "toPrinter",
        title: "Nyomtatóra/Képernyőre",
        cell: "toPrinterTemplate",
        editor: "boolean",
      },
      { cell: "commandTemplate", width: "120px" },
    ],

    items() {
      return this.store.accountActionSources;
    },
  },

  methods: {
    updateAccountActionSource(item: AccountActionSource) {
      this.$router.push(`/account/action-source/${item.id}`);
    },

    showDetails(item: AccountActionSource) {
      this.$router.push(`/account/action-source/${item.id}/details`);
    },

    editItem(item: AccountActionSource) {
      this.store.setEditing(item);
      console.log(item);
    },

    itemChange({ dataItem, value, field }: { dataItem: AccountActionSource; value: any; field: string }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      this.store.save(item, update);
    },

    cancelEdit(item: AccountActionSource) {
      item.inEdit = false;
      this.store.saveAccountActionSource(this.store.accountActionSource);
    },
  },
});
