

























































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, { AccountTypeStore } from "../services/AccountTypeStore";
import AccountType from "../models/AccountType";

interface Data {
  store: AccountTypeStore;
  opened: boolean;
  valid: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      valid: true,
      opened: false,
    };
  },

  async beforeMount() {
    const id = Number(this.$route.params.accountTypeId);
    await this.store.loadAccountType(id);
  },

  methods: {
    async save(item: AccountType) {
      await this.store.save(item, {});
      this.$router.push("/account/account-type");
    },
  },
});
