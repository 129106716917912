export default interface InvoiceFlowStatusRelation {
  id: number;
  invId: number;
  statusId: number;
  insUserId: number;
  insDate: string;
  insTime: string;
  modDate: string;
  modTime: string;
  remark: string;
  deleted: boolean;

  insUserName: string;
}

export const invoiceFlowStatusRelationDefaultProps: InvoiceFlowStatusRelation = {
  id: 0,
  invId: 1,
  statusId: 1,
  insUserId: 1,
  insDate: "1980-01-01",
  insTime: "1980-01-01T08:00:00",
  modDate: "1980-01-01",
  modTime: "1980-01-01T08:00:00",
  remark: "",
  deleted: false,

  insUserName: "",
};
