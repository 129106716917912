import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import SerStation from "../models/SerStation";

export default class SerStationApi{
    static getAll(): Promise<SerStation[]> {
        return http
            .get<ApiResponse<SerStation[]>>(`ser-station`)
            .then(handleApiResponse)
    }

    static getFilteredSerStations(accInvGroup: number): Promise<SerStation[]> {
        return http.get<ApiResponse<SerStation[]>>(`ser-station/filtered/${accInvGroup}`).then(handleApiResponse);
    }
}