import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import DocumentType from "@/modules/documentType/models/DocumentType"

export default class DocumentTypeApi{
    static getAll(): Promise<DocumentType[]> {
        return http
            .get<ApiResponse<DocumentType[]>>(`document-types`)
            .then(handleApiResponse)
    }

    static get(documentTypeId: number): Promise<DocumentType> {
        return http
            .get<ApiResponse<DocumentType>>(`document-types/${documentTypeId}`)
            .then(handleApiResponse)
    }

    static update(source: DocumentType): Promise<boolean> {
        return http
            .put(`document-types/${source.id}`, source)
            .then(checkResponse)
    }

    static create(documentType: DocumentType): Promise<DocumentType> {
        return http
            .post<ApiResponse<DocumentType>>(`document-types`, documentType)
            .then(handleApiResponse)
    }
}
