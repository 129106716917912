var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticStyle:{"display":"block"}},[_c('transition',{attrs:{"name":"fadeHeight","mode":"in-out"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configuration.invoiceFlow.enabled),expression:"configuration.invoiceFlow.enabled"}]},[_c('v-card',[_c('v-card-title',{staticStyle:{"margin-bottom":"0"}},[_c('v-col',[_vm._v(" Dokumentum sablon beállítások ")]),_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{staticClass:"whiteBtn d-flex",staticStyle:{"width":"32px !important","height":"32px !important"},on:{"click":function($event){_vm.isDocumentCardExpanded = !_vm.isDocumentCardExpanded}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-bars")])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDocumentCardExpanded),expression:"isDocumentCardExpanded"}]},[_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v("Flow dokumentum sablonok")])]),_c('div',{staticClass:"mb-1"},[_c('v-row',{staticClass:"d-flex align-baseline"},[_c('v-col',{staticStyle:{"display":"flex !important","flex-direction":"row-reverse !important"},attrs:{"sm":"4","md":"3","align":"right"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex ml-2",staticStyle:{"width":"4px !important","height":"4px !important"},attrs:{"outlined":"","color":"primary"},on:{"click":_vm.createTemplate}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"10"}},[_vm._v("fa-plus")])],1)]}}])},[_c('span',[_vm._v("Új sablon")])]),_vm._v(" Sablon ")],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.createDialog),expression:"!createDialog"}],attrs:{"sm":"6","md":"2","align":"left"}},[_c('v-select',{attrs:{"items":_vm.dtStore.documentTemplates,"outlined":"","dense":"","item-text":"name","item-value":"name","return-object":false,"hide-details":"auto"},on:{"change":_vm.templateChanged},model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}})],1),(!_vm.createDialog)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"align":"left"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex",staticStyle:{"width":"4px !important","height":"4px !important"},attrs:{"outlined":"","color":"primary"},on:{"click":_vm.deleteTemplate}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"10"}},[_vm._v("fa-minus")])],1)]}}],null,false,1354823222)},[_c('span',[_vm._v("Sablon törlése")])])],1):_vm._e()],1)],1),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.createDialog),expression:"createDialog"}],staticClass:"my-2"},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"align":"right","sm":"4","md":"3"}}),_c('v-col',{attrs:{"align":"left","sm":"6","md":"3"}},[_c('strong',[_vm._v("Sablon létrehozása")])])],1),_c('v-row',{staticClass:"align-baseline mb-n1"},[_c('v-col',{attrs:{"align":"right","sm":"4","md":"3"}},[_vm._v("Név")]),_c('v-col',{attrs:{"align":"left","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","single-line":""},model:{value:(_vm.dtStore.documentTemplate.name),callback:function ($$v) {_vm.$set(_vm.dtStore.documentTemplate, "name", $$v)},expression:"dtStore.documentTemplate.name"}})],1)],1)],1)]),_c('transition-group',{attrs:{"name":"fadeHeight","mode":"out-in"}},_vm._l((this.fStore.invoiceFlowStatuses),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.createDialog),expression:"!createDialog"}],key:item.id,staticClass:"mb-2"},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex !important","flex-direction":"row-reverse !important"},attrs:{"sm":"4","md":"3","align":"right"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex ml-2",staticStyle:{"width":"4px !important","height":"4px !important"},attrs:{"outlined":"","color":"primary","disabled":_vm.template.length < 2 || item.dropDown},on:{"click":function($event){item.dropDown = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"10"}},[_vm._v("fa-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Új dokumentum igény")])]),_c('label',[_vm._v(_vm._s(item.name))])],1),_c('v-col',_vm._l((_vm.rStore.flowDocumentRelations),function(element){return _c('v-row',{key:element.id},[_c('v-col',{attrs:{"sm":"3"}},[(element.statusId == item.id)?_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(element.documentType)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"sm":"1"}},[(element.statusId == item.id && element.required)?_c('span',{staticClass:"required_label mb-1"},[_vm._v("Kötelező")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(element.statusId == item.id)?_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex ml-2 mb-1",staticStyle:{"width":"4px !important","height":"4px !important"},attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteFlowDocumentRelations(element.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"10"}},[_vm._v("fa-minus")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Dokumentum igény törlése")])])],1)],1)}),1)],1),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.dropDown),expression:"item.dropDown"}]},[_c('InvoiceFlowSettingsPageTemplate',{attrs:{"item":item,"template":_vm.template,"componentId":item.id}})],1)])],1)}),0)],1),_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(!_vm.createDialog),expression:"!createDialog"}]},[_c('v-spacer'),_c('v-btn',{staticClass:"whiteBtn mr-1",on:{"click":_vm.clearDocumentTemplateItem}},[_vm._v("Mégse")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addDocumentTemplateItem}},[_vm._v("Mentés")])],1),_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(_vm.createDialog),expression:"createDialog"}]},[_c('v-spacer'),_c('v-btn',{staticClass:"whiteBtn mr-1",staticStyle:{"height":"32px !important"},on:{"click":_vm.cancelCreateTemplate}},[_vm._v("Mégse")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.dtStore.documentTemplate.name.length < 2},on:{"click":_vm.saveCreateTemplate}},[_vm._v("Mentés")])],1)],1)]),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDocumentCardExpanded),expression:"isDocumentCardExpanded"}]})])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }