export default class AccountSubtype {
  id = 0;
  name = "";
  type = 0;

  constructor(params?: Partial<AccountSubtype>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}
