import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import SerStationGroup from "../models/SerStationGroup";

export default class SerStationGroupApi{
    static getAll(): Promise<SerStationGroup[]> {
        return http
            .get<ApiResponse<SerStationGroup[]>>(`ser-station-group`)
            .then(handleApiResponse)
    }
}