

























import Vue from "vue";
import { LetterOfCreditStore } from "@/modules/letterOfCredit/letterOfCreditApi";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import { formatDate } from "@/utils/formatDates";

interface Data {
  store: LetterOfCreditStore;
  flowStore: InvoiceFlowStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store: new LetterOfCreditStore(),
      flowStore,
    };
  },

  async beforeMount() {
    if (this.flowStore.invoice.letterOfCredit > 1) {
      await this.store.getLetterOfCreditForInvoice(Number(this.flowStore.invoice.letterOfCredit));
    }
  },

  methods: {
    formatDate(date: string) {
      return formatDate(date);
    },
  },
});
