import dayjs from "dayjs";

export const dateFormat = (value: string) => dayjs(value).format("YYYY-MM-DD");

export const dateFormatHU = (value: string) => {
    if (!value){
        return '';
    }

    return dayjs(value).format("YYYY. MM. DD.");
};

export const dateTimeFormatHU = (value: string) => dayjs(value).format("YYYY. MM. DD. HH:mm:ss");
