










































































import Vue from "vue";
import CreditCardStore from "../services/CreditCardStore";
import InputWithLabel from "@/components/InputWithLabel.vue";
import NumberInput from "@/components/NumberInput.vue";

interface Data {
  store: CreditCardStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { InputWithLabel, NumberInput },
  data() {
    return {
      store: new CreditCardStore(),
    };
  },
  async beforeMount() {
    const id = Number(this.$route.params.id);
    await this.store.setItem(id);
  },
  methods: {
    async save() {
      await this.store.save();
      this.$router.push("/credit-cards");
    },
    changeYear() {
      const month = this.$refs.month as Vue;
      const input = month.$refs.input as HTMLElement;
      if(this.store.item.expirationYear.length === 0)
        input.focus();
    },
    changeMonth() {
      const year = this.$refs.year as Vue;
      const input = year.$refs.input as HTMLElement;
      if(this.store.item.expirationMonth.length === 2)
        input.focus();
    },
    keyDownYear(event: KeyboardEvent) {
      const month = this.$refs.month as Vue;
      const input = month.$refs.input as HTMLElement;
      if(event.code === "Backspace")
        if(this.store.item.expirationYear.length === 0)
          input.focus();
    },
    keyDownMonth(event: KeyboardEvent) {
      const year = this.$refs.year as Vue;
      const input = year.$refs.input as HTMLElement;
      if(event.code !== "Backspace" && event.code !== "Delete")
        if(this.store.item.expirationMonth.length === 2)
          input.focus();
    }
  },
});
