



































































import Vue from "vue";
import PowerBIApi from "../../powerBI/services/PowerBIApi";
import PowerBIResponse from "../../powerBI/models/PowerBIResponse";

interface Data {
  reportData: PowerBIResponse[];
  reportId: string;
}

interface Method {
  navigate(path: string): void;
  loadReport(id: string): void;
  getReportDatas(): void;
}

export default Vue.extend<Data, Method, {}, {}>({
  data() {
    return {
      reportData: [],
      reportId: "",
    };
  },

  methods: {
    loadReport(id: string) {
      //this.navigate("invoice-browser/" + id.toString());
      this.$router.push(`/power-bi/${id}`);
      this.reportId = id;
      this.getReportDatas();
    },

    navigate(path: string) {
      this.$router.push(`/reports/${path}`);
    },
    async getReportDatas() {
      this.reportData = await PowerBIApi.getAll();
    },
  },

  async beforeMount() {
    this.getReportDatas();
  },
});
