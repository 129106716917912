var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"align":"right"}},[_c('label',{staticClass:"info-label info-label-name"},[_vm._v("Levonható?")])]),_c('v-col',[_c('v-switch',{attrs:{"dense":""},model:{value:(_vm.$props.item.inVatCount),callback:function ($$v) {_vm.$set(_vm.$props.item, "inVatCount", $$v)},expression:"$props.item.inVatCount"}})],1)],1),_c('h5',{staticClass:"mt-2",staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v(" Gépjármű keresése ")]),_c('br'),_c('div',{staticClass:"d-flex flex-row"},[_c('v-text-field',{attrs:{"outlined":"","single-line":"","placeholder":"Gyártó, modell, alvázszám, motorszám, rendszám keresése..."},on:{"keyup":_vm.search},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('icon-button',{attrs:{"tooltip":"Találatok törlése","icon":"fa-times","disabled":_vm.$props.isPageDisabled},on:{"click":_vm.clear}}),_c('icon-button',{attrs:{"tooltip":"Kijelöltek hozzáadása","icon":"fa-plus","disabled":_vm.$props.isPageDisabled},on:{"click":_vm.addItems}})],1),_c('grid',{attrs:{"data-items":_vm.newCarItemRelations,"columns":_vm.newColumns},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('check-box',{attrs:{"selected":props.dataItem.isSelected},on:{"change":function($event){return _vm.selectItem(props.dataItem)}}})],1)]}},{key:"nettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.netto,"currency":props.dataItem.invCurrency}})],1)]}},{key:"vatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.vat,"currency":props.dataItem.invCurrency}})],1)]}},{key:"totalTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.total,"currency":props.dataItem.invCurrency}})],1)]}}])}),_c('h5',{staticClass:"mt-3",staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v("Gépjármű kapcsolat")]),_c('grid',{staticClass:"statements-grid",attrs:{"data-items":_vm.carItemRelations,"columns":_vm.columns,"detail":'detailsTemplate',"expand-field":'details',"selected-field":'details'},scopedSlots:_vm._u([{key:"detailsButtonTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showDetails(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"10"}},[_vm._v("fa-bars")])],1)]}}],null,true)},[_c('span',[_vm._v("Gépjármű információk")])])],1)]}},{key:"detailsTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"documents-info pa-2",staticStyle:{"cursor":"default"}},[_c('v-row',[_c('v-col',{staticClass:"px-0 py-1"},[_c('v-row',[_c('v-col',{attrs:{"sm":"3","align":"right"}},[_c('label',{staticClass:"info-label info-label-name",attrs:{"align":"right"}},[_vm._v(" Gyártó ")])]),_c('v-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"info-label"},[_vm._v(" "+_vm._s(props.dataItem.brandName)+" ")])]),_c('v-col',{attrs:{"sm":"1","align":"right"}},[_c('label',{staticClass:"info-label info-label-name",attrs:{"align":"right"}},[_vm._v(" Modell ")])]),_c('v-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"info-label"},[_vm._v(" "+_vm._s(props.dataItem.modelName)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"3","align":"right"}},[_c('label',{staticClass:"info-label info-label-name",attrs:{"align":"right"}},[_vm._v(" Alvázszám ")])]),_c('v-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"info-label"},[_vm._v(" "+_vm._s(props.dataItem.bodyNo)+" ")])]),_c('v-col',{attrs:{"sm":"1","align":"right"}},[_c('label',{staticClass:"info-label info-label-name",attrs:{"align":"right"}},[_vm._v(" Rendszám ")])]),_c('v-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"info-label"},[_vm._v(" "+_vm._s(props.dataItem.regNum)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"3","align":"right"}},[_c('label',{staticClass:"info-label info-label-name",attrs:{"align":"right"}},[_vm._v(" Szín ")])]),_c('v-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"info-label"},[_vm._v(" "+_vm._s(props.dataItem.color)+" ")])]),_c('v-col',{attrs:{"sm":"1","align":"right"}},[_c('label',{staticClass:"info-label info-label-name",attrs:{"align":"right"}},[_vm._v(" Kárpit ")])]),_c('v-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"info-label"},[_vm._v(" "+_vm._s(props.dataItem.drapery)+" ")])])],1)],1)],1)],1)]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","outlined":"","disabled":_vm.$props.isPageDisabled},on:{"click":function($event){return _vm.updateItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Mentés")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","outlined":"","disabled":_vm.$props.isPageDisabled},on:{"click":function($event){return _vm.deleteItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}},{key:"nettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-input',{staticClass:"mt-1",attrs:{"currency":props.dataItem.invCurrency,"disabled":_vm.$props.isPageDisabled},model:{value:(props.dataItem.netto),callback:function ($$v) {_vm.$set(props.dataItem, "netto", _vm._n($$v))},expression:"props.dataItem.netto"}})],1)]}},{key:"vatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-input',{staticClass:"mt-1",attrs:{"currency":props.dataItem.invCurrency,"disabled":_vm.$props.isPageDisabled},model:{value:(props.dataItem.vat),callback:function ($$v) {_vm.$set(props.dataItem, "vat", _vm._n($$v))},expression:"props.dataItem.vat"}})],1)]}},{key:"totalTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-input',{staticClass:"mt-1",attrs:{"currency":props.dataItem.invCurrency,"disabled":_vm.$props.isPageDisabled},model:{value:(props.dataItem.total),callback:function ($$v) {_vm.$set(props.dataItem, "total", _vm._n($$v))},expression:"props.dataItem.total"}})],1)]}}])}),(_vm.loaded)?_c('CarPreCalcInfo',{staticClass:"mt-6",attrs:{"propCarId":_vm.carIdComputed,"propPreStatusId":8,"dropDownVisible":false}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }