import http, { handleApiResponse, checkResponse } from '@/services/http'
import OutgoingInvoiceTypeEditModel from "@/modules/outgoingInvoiceType/models/OutgoingInvoiceTypeEditModel"
import ApiResponse from '@/models/ApiResponse'

export default class OutgoingInvoiceTypeEditModelApi {
    static getAll(): Promise<OutgoingInvoiceTypeEditModel[]> {
        return http
            .get<ApiResponse<OutgoingInvoiceTypeEditModel[]>>(`outgoing-invoice/type`)
            .then(handleApiResponse)
    }

    static getEditModel(outgoingInvoiceTypeEditModelId: number): Promise<OutgoingInvoiceTypeEditModel> {
        return http
        .get<ApiResponse<OutgoingInvoiceTypeEditModel>>(`outgoing-invoice/type/${outgoingInvoiceTypeEditModelId}/edit`)
        .then(handleApiResponse)
    }

    static saveEditModel(source: OutgoingInvoiceTypeEditModel): Promise<boolean>{
        return http
        .post(`outgoing-invoice/type/${source.id}/edit`, source)
        .then(checkResponse)
    }

    static delete(item: OutgoingInvoiceTypeEditModel){
        // return http
        //     .delete(`outgoing-invoice/type/${item.outgoingInvoiceType.id}`, item)
        //     .then(checkResponse)
        console.log("törlés");
        
    }
}