import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import DocumentTemplate from "@/modules/documentTemplate/models/DocumentTemplate";
import FlowDocumentTemplateRelation from "@/modules/flowDocumentRelation/models/FlowDocumentTemplateRelation";
import Document from "@/modules/flowDocumentRelation/models/Document";

export default class DocumentTemplateApi {
  static getAll(): Promise<DocumentTemplate[]> {
    return http.get<ApiResponse<DocumentTemplate[]>>(`document-template`).then(handleApiResponse);
  }

  static get(documentTemplateId: number): Promise<DocumentTemplate> {
    return http.get<ApiResponse<DocumentTemplate>>(`document-template/${documentTemplateId}`).then(handleApiResponse);
  }

  static getDocumentTemplateRelation(invoiceId: number): Promise<FlowDocumentTemplateRelation> {
    return http
      .get<ApiResponse<FlowDocumentTemplateRelation>>(`document-template/relation/${invoiceId}`)
      .then(handleApiResponse);
  }

  static updateDocumentTemplateRelation(
    invoiceId: number,
    docTemplateId: number
  ): Promise<FlowDocumentTemplateRelation> {
    return http
      .put<ApiResponse<FlowDocumentTemplateRelation>>(`document-template/relation`, {
        invoiceId: invoiceId,
        docTemplateId: docTemplateId,
      })
      .then(handleApiResponse);
  }

  static update(source: DocumentTemplate): Promise<boolean> {
    return http.put(`document-template/${source.id}`, source).then(checkResponse);
  }

  static create(documentTemplate: DocumentTemplate): Promise<number> {
    return http.post<ApiResponse<number>>(`document-template`, documentTemplate).then(handleApiResponse);
  }

  static deleteTemplate(templateId: number): Promise<boolean> {
    return http.delete(`document-template/${templateId}`).then(checkResponse);
  }

  static deletePreviousDocument(documentId: number): Promise<boolean> {
    return http.delete(`documents/delete/${documentId}`).then(checkResponse);
  }

  static uploadDocumentTemplateItem(
    itemId: number,
    doctype: number,
    description: string,
    file: File
  ): Promise<Document> {
    const data = new FormData();
    data.append("file", file);
    data.append("typeID", doctype.toString());
    data.append("relatedItemName", "rmaccinvflow_doc.szam");
    data.append("relatedItemID", itemId.toString());
    data.append("description", description);

    return http
      .post<ApiResponse<Document>>(`documents`, data, { headers: { "Content-Type": "multipart/form-data" } })
      .then(handleApiResponse);

    // http({
    //     method: "POST",
    //     url: "documents",
    //     headers: { "Content-Type": "multipart/form-data" },
    //     data
    // });
  }

  static uploadOtherDocumentItem(itemId: number, doctype: number, description: string, file: File): Promise<Document> {
    const data = new FormData();
    data.append("file", file);
    data.append("typeID", doctype.toString());
    data.append("relatedItemName", "imaccinvflow_doc.szam");
    data.append("relatedItemID", itemId.toString());
    data.append("description", description);

    return http
      .post<ApiResponse<Document>>(`documents`, data, { headers: { "Content-Type": "multipart/form-data" } })
      .then(handleApiResponse);
  }

  static uploadFinancialDocumentTemplateItem(
    itemId: number,
    doctype: number,
    description: string,
    file: File
  ): Promise<Document> {
    const data = new FormData();
    data.append("file", file);
    data.append("typeID", doctype.toString());
    data.append("relatedItemName", "mlgenpartner.szam");
    data.append("relatedItemID", itemId.toString());
    data.append("description", description);

    console.log(data);

    return http
      .post<ApiResponse<Document>>(`documents/financial`, data, { headers: { "Content-Type": "multipart/form-data" } })
      .then(handleApiResponse);
  }

  static getDocumentTypeByTypeName(doctype: string): Promise<number> {
    return http.get<ApiResponse<number>>(`documents/financial/type-name/${doctype}`).then(handleApiResponse);
  }
}
