
















































import Vue from "vue";
import Paginator from "../../../components/Paginator.vue";
import GeneralIngoinInvoiceDisplay from "../../generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import store, { DebitPaymentStore } from "../services/DebitPaymentStore";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import Pager from "../../../models/Pager";

interface Data {
  store: DebitPaymentStore;
  pager: Pager;
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];

  customFilter: CompositeFilterDescriptor | null;
  customSort: SortDescriptor[] | null;

  items: object[];
}

interface Computed {
  activeItems: object[];
  activeFilter: CompositeFilterDescriptor;
  activeSort: SortDescriptor[];
  columns: any;
}

export default Vue.extend<Data, {}, Computed, {}>({
  components: {
    Paginator,
  },

  data() {
    return {
      store,
      pager: new Pager(),
      filter: { logic: "and", filters: [] },
      sort: [],

      customFilter: null,
      customSort: null,

      items: [],
    };
  },

  async beforeMount() {
    await this.store.load();
    this.items = [...this.store.debitPayments];
  },

  methods: {
    create() {
      this.$router.push("/debit-payment/search");
    },

    pagerChanged(p: Pager) {
      this.pager = p;
      // this.$emit("pagerChanged", p);
    },

    editItem(item: GeneralIngoinInvoiceDisplay) {
      this.$router.push(`/debit-payment/edit/${item.incomingInvoice.partner}/${item.incomingInvoice.id}`);
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      if (this.customFilter) {
        this.$emit("filterChange", e.filter);
      } else {
        this.filter = e.filter;
      }
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      if (this.customSort) {
        this.$emit("sortChange", e.sort);
      } else {
        this.sort = e.sort;
      }
    },
  },

  computed: {
    activeFilter() {
      return this.customFilter ? this.customFilter : this.filter;
    },

    activeSort() {
      return this.customSort ? this.customSort : this.sort;
    },

    activeItems() {
      let items: object[] = this.items;
      items = filterBy(items, this.activeFilter);
      items = orderBy(items, this.activeSort);
      return items;
    },

    columns: () => [
      { field: "incomingInvoice.id", title: "GENI", headerClassName: "center_text", className: "center_text" },
      {
        field: "incomingInvoice.invoiceNo",
        title: "Bizonylat szám",
        headerClassName: "center_text",
        className: "center_text",
      },
      { field: "partnerName", title: "Szállító", headerClassName: "center_text", className: "center_text" },
      {
        field: "incomingInvoice.invoiceDate",
        title: "Számla dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        headerClassName: "center_text",
        className: "center_text",
      },
      {
        field: "incomingInvoice.payTerm",
        title: "Fizetési határidő",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        headerClassName: "center_text",
        className: "center_text",
      },
      {
        field: "incomingInvoice.total",
        title: "Bruttó",
        format: "{0:n}",
        headerClassName: "right_text",
        className: "right_text",
        // cell: (h: Function, _: VNode, props: GridRowProps) =>
        //   h("td", { class: "right_text" }, `${formatMoney(props.dataItem.brutto)} ${props.dataItem.currency}`),
      },
      { width: 70, className: "right_text", cell: "commandTemplate", sortable: false, filterable: false },
    ],
  },
});
