import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import InvoiceGroupApproval from '../models/InvoiceGroupApproval'

export default class InvoiceGroupApprovalApi {
    // static getAll(): Promise<InvoiceGroupApproval[]> {
    //     return http
    //         .post<ApiResponse<InvoiceGroupApproval[]>>(`invoice-group/approvals/approvals-grid`)
    //         .then(handleApiResponse)
    // }

    static get(invoiceGroupApprovalId: number): Promise<InvoiceGroupApproval> {
        return http
            .get<ApiResponse<InvoiceGroupApproval>>(`invoice-group/approvals-grid/${invoiceGroupApprovalId}`)
            .then(handleApiResponse)
    }
}