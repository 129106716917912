export interface IntrastatData {
  id: number;
  name: string;
  transCodeID: number;
  senderCountry: number;
  originCountry: number;
  weight: number;
  quantity: number;
  invoicedValue: number;
  statValue: number;
  isReported: boolean;
  arrivalDate: Date;
  deliveryDate: Date;
  quantityUnit: string;
  weightUnit: string;
  currency: string;
  invoiceItem: number;
  partsId: number;
  gpartsId: number;
  productID: number;
  supplimentarUnit: string;
  incoterms: number;
  isIndirectNoServ: boolean;
}


export const intrastatDefaultProps: IntrastatData = {
  id: 1,
  name: "",
  transCodeID: 1,
  senderCountry: 1,
  originCountry: 1,
  weight: 1,
  quantity: 1,
  invoicedValue: 0,
  statValue: 0,
  isReported: true,
  arrivalDate: new Date(1990, 1, 1),
  deliveryDate: new Date(1990, 1, 1),
  quantityUnit: "",
  weightUnit: "",
  currency: "",
  invoiceItem: 1,
  partsId: 1,
  gpartsId: 1,
  productID: 1,
  supplimentarUnit: "",
  incoterms: 1,
  isIndirectNoServ: false,
}

export interface PreviousIntrastatData {
  id: number;
  name: string;
  transCodeID: number;
  senderCountry: number;
  originCountry: number;
  weight: number;
  quantity: number;
  invoicedValue: number;
  statValue: number;
  isReported: boolean;
  arrivalDate: Date;
  deliveryDate: Date;
  quantityUnit: string;
  weightUnit: string;
  currency: string;
  invoiceItem: number;
  partsId: number;
  gpartsId: number;
  productID: number;
  supplimentarUnit: string;
  incoterms: number;
  isIndirectNoServ: boolean;
  invoiceType: string;
  invoiceNumber: string;
  geni: number;
}

export const previousIntrastatDataDefaultProps: PreviousIntrastatData = {
  id: 1,
  name: "",
  transCodeID: 1,
  senderCountry: 1,
  originCountry: 1,
  weight: 1,
  quantity: 1,
  invoicedValue: 0,
  statValue: 0,
  isReported: true,
  arrivalDate: new Date(1990, 1, 1),
  deliveryDate: new Date(1990, 1, 1),
  quantityUnit: "",
  weightUnit: "",
  currency: "",
  invoiceItem: 1,
  partsId: 1,
  gpartsId: 1,
  productID: 1,
  supplimentarUnit: "",
  incoterms: 1,
  isIndirectNoServ: false,
  invoiceType: "",
  invoiceNumber: "",
  geni: 1,
}