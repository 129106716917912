import ProjectItem, { projectItemDefaultProps } from "./ProjectItem"
import PublicProjectItem, { publicProjectItemDefaultProps } from "./PublicProjectItem"
import ProjectHead, { projectHeadDefaultProps } from "./ProjectHead"

export default interface ProjectData {
    selectedProjectItem: ProjectItem;
    selectedPublicProjectItem: PublicProjectItem;
    selectedProjectHead: ProjectHead;
    isProjectItem: boolean;
    isPublicProjectItem: boolean;

}

export const projectDataDefaultProps: ProjectData = {
    selectedProjectItem: { ...projectItemDefaultProps },
    selectedPublicProjectItem: { ...publicProjectItemDefaultProps },
    selectedProjectHead: { ...projectHeadDefaultProps },
    isProjectItem: true,
    isPublicProjectItem: false
}