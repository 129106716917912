





































import MoneyDisplay from "@/components/MoneyDisplay.vue";
import Vue from "vue";
import TransactionItem, {
  TransItemDimension
} from "../models/GeneralLedgerTransactionItem";
import api from "../services/api";

interface Data {
  dimensions: TransItemDimension[];
}

interface Props {
  item: TransactionItem | undefined;
}

interface Methods {
  setDimensions(): void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  data: () => ({
    dimensions: [],
  }),

  components:{MoneyDisplay},

  props: {
    item: { type: undefined, default: undefined },
  },

  beforeMount() {
     this.setDimensions()
  },

  methods: {
    async setDimensions() {
      const f = await api.GetDimensions(Number(this.item?.id));
      this.dimensions = f;
      console.log(f);
    },
  },
});
