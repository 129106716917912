








































































import Vue from "vue";
import Email from "@/modules/cashStatement/models/Email";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import CheckBox from "@/components/CheckBox.vue";
import IconButton from "@/components/IconButton.vue";
import eventBus from "@/services/eventBus";

interface Data {
  emails: Email[];
  isCreating: boolean;
  newItem: Email;
  isLoading: boolean;
}

interface Props {
  accinvreg: number;
  partner: number;
  disabled: boolean;
}

interface Methods {
  getEmails(): Promise<Email[]>;
  isSelected(email: Email): boolean;
  setSelected(email: Email): void;
  setEmail(email: Email, value: object): void;
  emailRules(value: string): string;
  saveEmail(): void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: {
    CheckBox,
    IconButton,
  },

  props: {
    accinvreg: { type: Number, default: 1 },
    partner: { type: Number, default: 1 },
    disabled: { type: Boolean, default: false }
  },

  data: () => {
    return {
      emails: new Array<Email>(),
      isCreating: false,
      newItem: new Email(),
      isLoading: false,
    };
  },

  async beforeMount() {
    this.emails = (await this.getEmails());
  },

  methods: {
    async saveEmail() {
      if (this.newItem.email == "") {
        return;
      }
      try {
        this.isLoading = true;
        const email: any = { email: this.newItem.email, isPrimary: this.newItem.isPrimary, isSelected: this.newItem.isSelected };

        await http.post(`emails-geno/${this.$props.accinvreg}`, {email}).then(checkResponse);
        this.emails.push(this.newItem);
        eventBus.$emit("success", "Sikeres mentés!");
      } catch (ex) {
        eventBus.$emit("error", "Nem sikerült a mentés!");
      } finally {
        this.isLoading = false;
        this.isCreating = false;
        this.newItem = new Email();
      }
    },

    emailRules(value: string) {
      if (value.length < 1) {
        return "Kötelező kitölteni!";
      }

      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value.toLowerCase()
      )
        ? ""
        : "Hibás e-mail cím!";
    },

    setEmail(email, value) {
      email.email = String(value);
    },

    setSelected(email) {
      email.isSelected = !email.isSelected;
      if (this.emails.includes(email)) {
        this.emails = this.emails.filter(
          (x) => x.email !== email.email
        );
        //this.$emit("input", this.invoice);
        return;
      }
      this.emails.push(email);
      //this.$emit("input", this.invoice);
    },

    isSelected(email) {
      console.log(
        this.emails.map((x) => x.email).includes(email.email)
      );
      return this.emails.map((x) => x.email).includes(email.email);
    },

    async getEmails() {
      const emails =  await http
        .get<ApiResponse<Email[]>>(`emails/geno/${this.$props.accinvreg ?? 1}`)
        .then(handleApiResponse).then((x) => {
          return x.map((y) => {
            return { ...y, isSelected: y.isPrimary };
          });
        });
      return emails;
    },
  },
});
