import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import Role from "@/modules/roles/models/Role";
import Feature from "../models/Feature";
import DeniedRelation from "../models/DeniedRelation";

export default class RoleApi {
  static getAll(): Promise<Role[]> {
    return http.get<ApiResponse<Role[]>>(`roles`).then(handleApiResponse);
  }

  static getAllFeatures(): Promise<Feature[]> {
    return http.get<ApiResponse<Feature[]>>(`roles/features`).then(handleApiResponse);
  }

  static save(role: Role): Promise<boolean> {
    return http.put(`roles/save`, role).then(checkResponse);
  }

  static getDeniedRelations(): Promise<DeniedRelation[]> {
    return http.get<ApiResponse<DeniedRelation[]>>(`roles/denied`).then(handleApiResponse);
  }

  static saveDeniedRelations(relation: DeniedRelation): Promise<boolean> {
    return http.put(`roles/denied/save`, { relation: relation }).then(checkResponse);
  }
}
