



















































































































// Models
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import permStore, { PermissionStore } from "@/services/permissions";
import InvoiceRejection from "../models/InvoiceRejection";
import InvoiceFlowApi from "../services/InvoiceFlowApi";
import DocumentTemplateApi from "@/modules/documentTemplate/services/DocumentTemplateApi";

// Components
import IconButton from "@/components/IconButton.vue";
import Confirmation from "@/components/Confirmation.vue";

// Mixins
import { ConfirmationMixin } from "@/mixins/confirmation";
import http from "@/services/http";
import baseURL from "../../../services/http";

interface Data {
  store: InvoiceFlowStore;
  permStore: PermissionStore;
  rejection: InvoiceRejection;
}

interface Methods {
  save(): void;
  undo(): void;
  cancel(): void;
  download(): void;
  deleteFile(): void;
  updateStat(invapprStat: number, rejectionComment: string): void;
  setInvoiceReportLink(): void;
}

export default ConfirmationMixin.extend<Data, Methods, {}, {}>({
  components: { IconButton, Confirmation },

  mixins: [ConfirmationMixin],

  data: () => ({
    store,
    permStore,
    rejection: new InvoiceRejection(),
  }),

  async beforeMount() {
    await this.store.loadInvoiceRejectionReasons();
    await InvoiceFlowApi.getInvoiceRejection(this.store.invoice.id).then((result) => {
      this.rejection = result;
      if (
        (this.rejection.reason = "-") ||
        !this.store.invoiceRejectionReasons.some((r) => r.name.includes(this.rejection.reason))
      ) {
        this.rejection.reason = this.store.invoiceRejectionReasons[0]?.name ?? "-";
      }
    });
  },

  methods: {
    setInvoiceReportLink() {
      if (this.rejection.id == 1) {
        return;
      }
      const url =
        baseURL.defaults.baseURL +
        `report/InvoiceComplaint?rejectId=${this.rejection.id}&connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`;
      window.open(url);
    },

    async updateStat(invapprStat: number, rejectionComment = "") {
      await this.store
        .updateAcceptRequestStatus(invapprStat)
        .then(() => this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated"));
      await this.store.loadAcceptorRequests(Number(this.$route.params.invoiceId));
      await this.store.loadControllerRequests(Number(this.$route.params.invoiceId));
      await this.store.loadFlowStatusRelations(Number(this.$route.params.invoiceId));

      if (invapprStat == 5 && rejectionComment != "") {
        window.open(
          `${http.defaults.baseURL}report/InvoiceComplaint?rejectId=${this.rejection.id}&connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`
        );
      }
    },

    async save() {
      const confirmed = await this.confirm("Biztosan elutasítja a számlát?");
      if (!confirmed) return;

      await this.store.sendInvoiceRejection(this.rejection);
      this.rejection = await InvoiceFlowApi.getInvoiceRejection(this.store.invoice.id);
      await this.updateStat(5, this.rejection.remark);
      // this.store.toggleInvoiceRejection();
    },

    async undo() {
      const confirmed = await this.confirm("Biztosan vissza akarja vonni a számla elutasítást?");
      if (!confirmed) return;

      await this.store.undoInvoiceRejection();
    },

    cancel() {
      this.store.toggleInvoiceRejection();
    },

    download() {
      window.open(this.rejection.documentLink);
    },

    async deleteFile() {
      await DocumentTemplateApi.deletePreviousDocument(this.rejection.documentID).then(() => {
        this.rejection.documentID = 0;
        this.rejection.documentLink = "";
      });
    },
  },

  computed: {
    disabled() {
      return false;
    },

    hasFile() {
      return this.rejection.documentID > 0 && this.rejection.documentLink != "";
    },
  },
});
