import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import { withBusyIndication } from "@/utils/busyIndication";
import { ActionLog } from "../models";

export const getAll = withBusyIndication(
  (): Promise<ActionLog[]> => http.get<ApiResponse<ActionLog[]>>(`action-log`).then(handleApiResponse)
);

export const get = (invoiceId: number): Promise<ActionLog> =>
http.get<ApiResponse<ActionLog>>(`action-log/template-detail/${invoiceId}`).then(handleApiResponse);


export const getInvoiceFlowLogs = (invoiceID: number): Promise<ActionLog[]> =>
  http.get<ApiResponse<ActionLog[]>>(`action-log/invoice-flow/${invoiceID}`).then(handleApiResponse);
