import {
  ConsumptionRecord,
  SalesAndOpportunity,
  SalesItem,
  GroupedConsumptions,
  InvoiceItemListResponse,
  mapInvoiceItems,
  OtherCostShareOption,
} from "./../models/index";
import ApiResponse from "@/models/ApiResponse";
import http, {
  checkResponse,
  fetchData,
  handleApiResponse,
} from "@/services/http";

import {
  ExtOrder,
  extOrderMapper,
  InvoiceItem,
  InvoiceRelation,
  StoreIn,
  storeInMapper,
  Unit,
  Tax,
  OtherItem,
  ValueDivisionMode,
  ExtOrderTypeOption,
  StoreInFeeType,
} from "@/modules/invoiceItems/models";

import SelectableParts from "@/modules/generalIngoingInvoice/models/IngoingInvoiceSelectableItem";
import { withBusyIndication } from "@/utils/busyIndication";
import TaxAndVatItem from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";
import cuid from "cuid";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import GParts from "@/modules/notice/models/GParts";
import WorkSheetItemGpart from "@/modules/invoiceItemDimension/models/WorkSheetItemGpart";
import WorkSheetItemRelation from "@/modules/invoiceItemDimension/models/WorkSheetItemRelations";

export const getUnits = (): Promise<Unit[]> =>
  http.get<ApiResponse<Unit[]>>(`units`).then(handleApiResponse);

export const getTaxes = (): Promise<Tax[]> =>
  http.get<ApiResponse<Tax[]>>(`tax`).then(handleApiResponse);

export const getAllTaxAndVatItem = (): Promise<TaxAndVatItem[]> =>
  http
    .get<ApiResponse<TaxAndVatItem[]>>(`tax/taxAndVatItems`)
    .then(handleApiResponse);

export const getInvoiceRelations = (
  invoiceID: number
): Promise<InvoiceRelation[]> =>
  http
    .get<ApiResponse<InvoiceRelation[]>>(
      `general-incoming-invoice/${invoiceID}/relations`
    )
    .then(handleApiResponse);

export const getStoreIns = withBusyIndication(
  (
    supplierID: number,
    productSearchText: string,
    start = "",
    end = ""
  ): Promise<StoreIn[]> =>
    http
      .get<ApiResponse<StoreIn[]>>("store-in", {
        params: {
          supplier: supplierID,
          product: productSearchText,
          start: new Date(start),
          end: new Date(end),
        },
      })
      .then(handleApiResponse)
      .then((data) => data.map(storeInMapper))
);

export const getDownPaymGParts = () =>
  http
    .get<ApiResponse<GParts[]>>(`gparts/downpaymgparts`)
    .then(handleApiResponse);

export const updateInvGroupDimensions = (invNo: number) =>
  http
    .post<ApiResponse<any>>(
      `general-incoming-invoice/update-inv-group-dimension-splitting/${invNo}`
    )
    .then(handleApiResponse);

export const getExtOrders = (
  supplierID: number,
  productSearchText: string,
  typeOption: ExtOrderTypeOption
): Promise<ExtOrder[]> => {
  return http
    .get<ApiResponse<ExtOrder[]>>("ext-orders", {
      params: {
        supplier: supplierID,
        product: productSearchText,
        isNormal: typeOption == ExtOrderTypeOption.Normal,
        isIndirect: typeOption == ExtOrderTypeOption.Indirect,
        isCooperation: typeOption == ExtOrderTypeOption.Cooperation,
      },
    })
    .then(handleApiResponse)
    .then((data) => data.map(extOrderMapper));
};

export const searchOtherParts = withBusyIndication(
  (searchText: string, isDownPay = false, id = 0): Promise<OtherItem[]> =>
    http
      .get<ApiResponse<SelectableParts[]>>(
        `general-incoming-invoice/items/other-parts/search`,
        {
          params: {
            q: searchText,
            d: isDownPay,
            i: id
          },
        }
      )
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: 1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            originalIncreasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: false,
            postCalcType: 1,
            isInclCarPrice: false,
            isCarRectItem: false,
            isService: i.isService,
          };
          return item;
        })
      )
);

export const getRawOtherItem = withBusyIndication(
  (gpartId: number): Promise<OtherItem[]> =>
    http
      .get<ApiResponse<SelectableParts[]>>(
        `general-incoming-invoice/items/other-parts/search/${gpartId}`
      )
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: i.quantity,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            originalIncreasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: false,
            postCalcType: 1,
            isInclCarPrice: false,
            isCarRectItem: false,
            isService: i.isService,
          };
          return item;
        })
      )
);

export const searchRectifyOtherParts = withBusyIndication(
  (searchText: string): Promise<OtherItem[]> =>
    http
      .get<ApiResponse<SelectableParts[]>>(
        `general-incoming-invoice/items/rectify-other-parts/search`,
        {
          params: {
            q: searchText,
          },
        }
      )
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: 1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            originalIncreasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: i.isPostCalc,
            postCalcType: i.postCalcType,
            isInclCarPrice: i.isInclCarPrice,
            isCarRectItem: i.isCarRectItem,
            isService: i.isService
          };
          return item;
        })
      )
);

export const searchRectifyWscontParts = withBusyIndication(
  (searchText: string): Promise<OtherItem[]> =>
    http
      .get<ApiResponse<SelectableParts[]>>(
        `general-incoming-invoice/items/rectify-wscont-parts/search`,
        {
          params: {
            q: searchText,
          },
        }
      )
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: 1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            originalIncreasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: i.isPostCalc,
            postCalcType: i.postCalcType,
            isInclCarPrice: i.isInclCarPrice,
            isCarRectItem: i.isCarRectItem,
            isService: i.isService
          };
          return item;
        })
      )
);

export const searchSubcontractorGParts = withBusyIndication(
  (searchText: string): Promise<OtherItem[]> =>
    http.get<ApiResponse<SelectableParts[]>>(
      `general-incoming-invoice/items/subcontractor/gparts/search`,
      {
        params: {
          q: searchText,
        },
      }
    ).then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: 1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: i.isPostCalc,
            postCalcType: i.postCalcType,
            isInclCarPrice: i.isInclCarPrice,
            isCarRectItem: i.isCarRectItem,
            isService: i.isService,
            originalIncreasesStockPrice: i.increasesStockPrice
          };
          return item;
        })
      )
);

export const searchOtherPartsForCars = withBusyIndication(
  (searchText: string): Promise<OtherItem[]> =>
    http
      .get<ApiResponse<SelectableParts[]>>(
        `general-incoming-invoice/items/other-parts-cars/search`,
        {
          params: {
            q: searchText,
          },
        }
      )
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: 1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            originalIncreasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: i.isPostCalc,
            postCalcType: i.postCalcType,
            isCarRectItem: false,
            isInclCarPrice: false,
            isService: i.isService,
          };
          return item;
        })
      )
);

export const searchOtherPartsForMaintenance = withBusyIndication(
  (searchText: string): Promise<OtherItem[]> =>
    http
      .get<ApiResponse<SelectableParts[]>>(
        `general-incoming-invoice/items/other-parts-maintenance/search`,
        {
          params: {
            q: searchText,
          },
        }
      )
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: 1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            originalIncreasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: i.isPostCalc,
            postCalcType: i.postCalcType,
            isCarRectItem: false,
            isInclCarPrice: false,
            isService: i.isService,
          };
          return item;
        })
      )
);

export const searchOtherPartsForSubContractors = withBusyIndication(
  (searchText: string): Promise<OtherItem[]> =>
    http
      .get<ApiResponse<SelectableParts[]>>(
        `general-incoming-invoice/items/other-parts-subcontractors/search`,
        {
          params: {
            q: searchText,
          },
        }
      )
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: 1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: i.increasesStockPrice,
            originalIncreasesStockPrice: i.increasesStockPrice,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: i.isPostCalc,
            postCalcType: i.postCalcType,
            isCarRectItem: false,
            isInclCarPrice: false,
            isService: i.isService,
          };
          return item;
        }),
      )
);

export const createInvoiceRelation = (
  invoiceID: number,
  relation: InvoiceRelation
): Promise<InvoiceRelation> =>
  http
    .post<ApiResponse<InvoiceRelation>>(
      `general-incoming-invoice/${invoiceID}/relations`,
      relation
    )
    .then(handleApiResponse);

export const deleteInvoiceRelation = (
  invoiceID: number,
  relationID: number
): Promise<any> =>
  http
    .delete(`general-incoming-invoice/${invoiceID}/relations/${relationID}`)
    .then(handleApiResponse);

export const getInvoiceItems = (invoiceID: number): Promise<InvoiceItem[]> =>
  http
    .get<ApiResponse<InvoiceItemListResponse>>(
      `general-incoming-invoice/${invoiceID}/items`
    )
    .then(handleApiResponse)
    .then((x) => mapInvoiceItems(x));

export const createCorrectionItem = async (
  items: any[],
  invNo: number
): Promise<boolean> =>
  http
    .post<ApiResponse<any>>(`general-incoming-invoice/create/correction-item/${invNo}`, { items }).then(checkResponse);

export const postInvoiceItems = async (
  invoiceID: number,
  items: InvoiceItem[]
): Promise<boolean> =>
  http
    .post<ApiResponse<any>>(
      `general-incoming-invoice/${invoiceID}/items`,
      await Promise.all(items.map((i) => i.toDto()))
    )
    .then(checkResponse);

//backenden "[action]"
export const getInvoices = withBusyIndication(
  (
    searchText: string,
    partner: number | undefined = undefined
  ): Promise<GeneralIngoinInvoiceDisplay[]> =>
    http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(
        `general-incoming-invoice/search/`,
        {
          params: {
            q: searchText,
            partner,
          },
        }
      )
      .then(handleApiResponse)
);

export const getInvoicesForRectify = withBusyIndication(
  (
    searchText: string, currInvId: number,
    partner: number | undefined = undefined
  ): Promise<GeneralIngoinInvoiceDisplay[]> =>
    http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(
        `general-incoming-invoice/search/rectify/`,
        {
          params: {
            q: searchText,
            partner: partner,
            currInvId: currInvId
          },
        }
      )
      .then(handleApiResponse)
);

export const getInvoiceItemsForStockChange = withBusyIndication(
  (
    searchText: string,
    partner: number,
    stock: number,
    parts: number
  ): Promise<GeneralIngoinInvoiceDisplay[]> =>
    http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(
        `general-incoming-invoice/search/rectify/stock-change`,
        {
          params: {
            q: searchText,
            partner: partner,
            stock: stock,
            parts: parts,
          },
        }
      )
      .then(handleApiResponse)
);

export const getInvoiceItemsForStockSwap = withBusyIndication(
  (
    searchText: string,
    partner: number,
    stock: number,
    parts: number
  ): Promise<GeneralIngoinInvoiceDisplay[]> =>
    http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(
        `general-incoming-invoice/search/rectify/stock-swap`,
        {
          params: {
            q: searchText,
            partner: partner,
            stock: stock,
            parts: parts,
          },
        }
      )
      .then(handleApiResponse)
);

export const getsupplierComplaintInvoiceItem = withBusyIndication(
  (
    searchText: string,
    partner: number | undefined = undefined
  ): Promise<GeneralIngoinInvoiceDisplay[]> =>
    http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(
        `general-incoming-invoice/search/supplier-complaint/`,
        {
          params: {
            q: searchText,
            partner,
          },
        }
      )
      .then(handleApiResponse)
);

export const getStoreInFeeTypes = () =>
  http
    .get<ApiResponse<StoreInFeeType[]>>("store-in-fee/types")
    .then(handleApiResponse);

export const generateRegTax = (id: number) =>
  http
    .get<ApiResponse<SelectableParts>>(`car/generate-reg-tax/${id}`)
    .then(handleApiResponse)
    .then((i) => {
      const item: OtherItem = {
        stockRelated: false,
        itemType: i.partsType,
        category: i.partGroup,
        code: i.code,
        productID: i.partId ?? 1,
        serviceID: i.serviceID ?? 1,
        gpartID: i.gPartId ?? 1,
        itemName: i.name,
        quantity: 1.0,
        unit: i.unitTypeName ?? "",
        unitPrice: i.unitPrice,
        relations: [],
        vatID: i.vatID,
        costShareOption: OtherCostShareOption.ToCurrentInvoice,
        hasUnitPrice: i.unitPrice > 0,
        increasesStockPrice: false,
        originalIncreasesStockPrice: false,
        valueDivisionMode: ValueDivisionMode.ByItemRate,
        orderNumber: "",
        isRegTax: true,
        inVatCount: false,
        isPostCalc: i.isPostCalc,
        postCalcType: i.postCalcType,
        isCarRectItem: false,
        isInclCarPrice: false,
        isService: false,
      };
      return item;
    });

export const searchConsignerConsumptions = (
  partnerID: number,
  minDate: Date | undefined = undefined,
  maxDate: Date | undefined = undefined,
  searchText: string | undefined = undefined
) =>
  http
    .get<ApiResponse<ConsumptionRecord[]>>("consigner-consumption/search", {
      params: {
        partner: partnerID,
        minDate: minDate,
        maxDate: maxDate,
        searchText: searchText,
      },
    })
    .then(handleApiResponse)
    .then((data) => data.map((i) => ({ ...i, cuid: cuid() })));

export const groupedConsignerConsumptionSearch = (
  partnerID: number,
  minDate: Date | undefined = undefined,
  maxDate: Date | undefined = undefined,
  searchText: string | undefined = undefined,
  currency: string | undefined = undefined
) =>
  http
    .get<ApiResponse<GroupedConsumptions[]>>(
      "consigner-consumption/grouped-search",
      {
        params: {
          partner: partnerID,
          minDate: minDate,
          maxDate: maxDate,
          searchText: searchText,
          currency: currency,
        },
      }
    )
    .then(handleApiResponse)
    .then((data) => {
      const items = data
        .flatMap((i) => i.groups)
        .flatMap((i) => i.consumptions);
      items.forEach((i) => (i.cuid = cuid()));
      return data;
    });

export const createConsignerConsumptionInvoiceItem = (
  invoiceID: number,
  taxID: number,
  record: ConsumptionRecord
): Promise<boolean> =>
  http
    .post(
      `general-incoming-invoice/${invoiceID}/relations/consigner-consumptions`,
      {
        invoiceID,
        taxID,
        stockID: record.stockID,
        consumptionItemID: record.consumptionItemID,
        itemName: `${record.partsCode} ${record.partsName}`,
        nettoValue: record.currNettoValue,
        quantity: record.quantity,
      }
    )
    .then(checkResponse);

export const searchSales = (searchText: string) =>
  fetchData<SalesAndOpportunity[]>("sales", {
    params: {
      q: searchText,
    },
  });

export const getOpportunityItems = (opportunityID: number) =>
  fetchData<SalesItem[]>(`sales/${opportunityID}/items`);

export interface SharedOtherCost {
  gpartsID: number;
  taxID: number;
  cost: number;
  name: string;
  unit: number;
  priceDivision: Array<{
    invoiceItemID: number;
    nettoValue: number;
  }>;
}

export const createSharedCostItem = (
  invoiceID: number,
  payload: SharedOtherCost
): Promise<boolean> =>
  http
    .post(
      `general-incoming-invoice/${invoiceID}/items/shared-other-cost`,
      payload
    )
    .then(checkResponse);

export const searchOtherPartnerOrders = (searchText: string) =>
  fetchData<any>("orders/other-partner/search", {
    params: {
      searchText,
    },
  });

export const searchConsignerConsumptionOrders = (searchText: string) =>
  fetchData<any>("orders/consigner-consumptions", {
    params: {
      searchText,
    },
  });

export interface ConsignerConsumptionOtherCost {
  gpartsID: number;
  taxID: number;
  cost: number;
  name: string;
  priceDivision: Array<{
    stockID: number;
    nettoValue: number;
  }>;
}

export const createConsignerConsumptionCost = (
  invoiceID: number,
  payload: ConsignerConsumptionOtherCost
): Promise<boolean> =>
  http
    .post(
      `general-incoming-invoice/${invoiceID}/items/consigner-consumption-cost`,
      payload
    )
    .then(checkResponse);

export const updateTax = (
  gpartId: number, partId: number, taxId: number
): Promise<boolean> =>
  http
    .put(`general-incoming-invoice/tax/${gpartId}/${partId}/${taxId}`)
    .then(checkResponse);

export const sendEmail = (
  nettoValue: number,
  bruttoValue: number,
  taxValue: number,
  originalNettoValue: number,
  originalBruttoValue: number,
  originalVatValue: number,
  invoiceId: number
): Promise<boolean> =>
  http
    .get<ApiResponse<boolean>>(
      `general-incoming-invoice/send-email-notification`,
      {
        params: {
          nettoValue: nettoValue,
          bruttoValue: nettoValue,
          taxValue: taxValue,
          originalNettoValue: originalNettoValue,
          originalBruttoValue: originalBruttoValue,
          originalVatValue: originalVatValue,
          invoiceId: invoiceId,
        },
      }
    )
    .then(handleApiResponse);

export const searchReturnOrder = async (
  stocks: number[]
): Promise<ExtOrder[]> =>
  http
    .post<ApiResponse<ExtOrder[]>>("store-in/return-order", {
      stocks: stocks,
    })
    .then(handleApiResponse)
    .then((data) =>
      data.map(extOrderMapper));

export const searchTrumesServices = async (
  stocks: number[]
): Promise<OtherItem[]> =>
  http
    .post<ApiResponse<SelectableParts[]>>("store-in/trumes-services", {
      stocks: stocks,
    })
    .then(handleApiResponse)
    .then((data) =>
      data.map((i) => {
        const item: OtherItem = {
          stockRelated: false,
          itemType: i.partsType,
          category: i.partGroup,
          code: i.code,
          productID: i.partId ?? 1,
          gpartID: i.gPartId ?? 1,
          serviceID: i.serviceID ?? 1,
          itemName: i.name,
          quantity: i.quantity,
          unit: i.unitTypeName ?? "",
          unitPrice: i.unitPrice,
          relations: [],
          vatID: i.vatID,
          costShareOption: OtherCostShareOption.ToCurrentInvoice,
          hasUnitPrice: i.unitPrice > 0,
          increasesStockPrice: i.increasesStockPrice,
          originalIncreasesStockPrice: i.increasesStockPrice,
          valueDivisionMode: ValueDivisionMode.ByItemRate,
          orderNumber: i.orderNumber,
          isRegTax: false,
          inVatCount: true,
          isPostCalc: false,
          postCalcType: 1,
          isCarRectItem: false,
          isInclCarPrice: false,
          isService: false,
        };
        return item;
      })
    );

export const searchCreditingStorein = async (
  orderins: number[]
): Promise<StoreIn[]> =>
  http
    .post<ApiResponse<StoreIn[]>>("store-in/crediting-storeins", {
      orderins: orderins,
    })
    .then(handleApiResponse)
    .then((data) =>
      data.map(storeInMapper));

export const searchWorkSheetItemRelations = withBusyIndication(
  (searchText: string, partnerId: number, gpartId: number, gpartFilter: boolean, isRect: boolean): Promise<WorkSheetItemGpart[]> =>
    http
      .get<ApiResponse<WorkSheetItemGpart[]>>(
        `worksheet/search/new`,
        {
          params: {
            q: searchText,
            partnerId: partnerId,
            gpartId: gpartId,
            gpartFilter: gpartFilter,
            isRect: isRect,
          },
        }
      )
      .then(handleApiResponse)
);

export const searchSubContractorItemRelations = withBusyIndication(
  (searchText: string, partnerId: number): Promise<any[]> =>
    http
      .get<ApiResponse<any[]>>(
        `subcontractor/search/new`,
        {
          params: {
            q: searchText,
            partnerId: partnerId,
          },
        }
      )
      .then(handleApiResponse)
);

export const searchWscontRectItemRelations = withBusyIndication(
  (searchText: string, partner: number): Promise<any[]> =>
    http
      .get<ApiResponse<any[]>>(
        `worksheet/wscont-rect-item-relations/search/${partner}`, { params: { searchText: searchText } }
      )
      .then(handleApiResponse)
);


export const getCurrentWscontRectItemRelation = withBusyIndication(
  (invItemId: number): Promise<any> =>
    http
      .get<ApiResponse<any>>(
        `worksheet/wscont-rect-item-relation/${invItemId}`
      )
      .then(handleApiResponse)
);

export const addWscontRectItemRelation = (
  selectedItems: any, invoiceId: number, invoiceItemId: number
): Promise<boolean> =>
  http
    .post(
      `worksheet/wscont-rect-item-relations/add/${invoiceId}/${invoiceItemId}`, selectedItems
    )
    .then(checkResponse);


export const deleteWscontRectItemRelation = (
  invItemId: number,
): Promise<boolean> =>
  http
    .put(
      `worksheet/wscont-rect-item-relation/delete/${invItemId}`
    )
    .then(checkResponse);

export const searchFachSheetCoopItemRelations = withBusyIndication(
  (searchText: string, partnerId: number, gpartId: number, gpartFilter: boolean): Promise<WorkSheetItemGpart[]> =>
    http
      .get<ApiResponse<WorkSheetItemGpart[]>>(
        `worksheet/search/new-coop`,
        {
          params: {
            q: searchText,
            partnerId: partnerId,
            gpartId: gpartId,
            gpartFilter: gpartFilter,
          },
        }
      )
      .then(handleApiResponse)
);

export const getCurrentWorkSheetItemRelations = withBusyIndication(
  (invItem: number): Promise<any[]> =>
    http
      .get<ApiResponse<any[]>>(
        `worksheet/worksheet-item-relations/${invItem}`
      )
      .then(handleApiResponse)
);

export const getCurrentSubcontractorItemRelations = withBusyIndication(
  (invItem: number): Promise<any[]> =>
    http
      .get<ApiResponse<any[]>>(
        `subcontractor/subcontractor-item-relations/${invItem}`
      )
      .then(handleApiResponse)
);

export const getCurrentFachSheetItemRelations = withBusyIndication(
  (invItem: number): Promise<any[]> =>
    http
      .get<ApiResponse<any[]>>(
        `worksheet/facsheet-item-relations/${invItem}`
      )
      .then(handleApiResponse)
);

export const deleteWorkSheetItemRelation = (
  relationId: number,
): Promise<boolean> =>
  http
    .post(
      `worksheet/worksheet-item-relations/delete/${relationId}`
    )
    .then(checkResponse);


export const addWorkSheetItemRelation = (
  items: WorkSheetItemRelation[]
): Promise<boolean> =>
  http
    .post(`worksheet/worksheet-item-relations/add`, items).then(checkResponse);

export const addSubcontractorItemRelation = (
  items: any[]
): Promise<boolean> =>
  http
    .post(`subcontractor/subcontractor-item-relations/add`, { items }).then(checkResponse);    