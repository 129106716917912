










































































import Vue from "vue";

import IconButton from "../../../components/IconButton.vue";
import { ExtOrder, InvoiceRelationState, StoreIn, getRelationState } from "../models";
import store, { InvoiceItemStore } from "../services/store";
import Fragment from "../../../components/Fragment.vue";
import RelationStateBadge from "./RelationStateBadge.vue";
import AddItemButton from "./AddItemButton.vue";
import CooperationOrderItemsTable from "./CooperationOrderItemsTable.vue";

interface Data {
  store: InvoiceItemStore;
}

interface Props {
  items: ExtOrder[];
}

interface Methods {
  getNetto(extOrder: ExtOrder): number;
  toggleDetails(extOrder: ExtOrder): void;
  getRelationState(item: StoreIn | ExtOrder): InvoiceRelationState;
  addExtOrder(item: any): void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: {
    IconButton,
    Fragment,
    CooperationOrderItemsTable,
    RelationStateBadge,
    AddItemButton
  },

  data: () => ({
    store,
  }),

  props: {
    items: {
      type: Array as () => ExtOrder[],
    },
  },

  computed: {
    hasData() {
      return this.items && this.items.length > 0;
    },

    currency() {
      return this.store.currency;
    },

    visibleItems() {
      return this.items.filter(
        (o) => this.store.selectedStateOption === getRelationState(o)
      );
    },
  },

  methods: {
    async addExtOrder(item: any){
      await this.store.addExtOrder(item);
      await this.store.save();
    },

    getRelationState,

    getNetto(extOrder: ExtOrder) {
      return extOrder.orderItems.reduce(
        (acc, i) => acc + i.quantity * i.unitPrice,
        0.0
      );
    },

    toggleDetails(extOrder: ExtOrder) {
      extOrder.expanded = !extOrder.expanded;
    }
  },
});
