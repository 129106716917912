




































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import Vue from "vue";
import bStore, {
  BankRemitStore,
} from "@/modules/bankRemit/services/BankRemitStore";
import BankRemitItem from "@/modules/bankRemit/models/BankRemitItem";
import BankRemitAndItems from "@/modules/bankRemit/models/BankRemitAndItems";
import { BankRemitStatusEnum } from "@/modules/bankRemit/views/BankRemitHeadView.vue";
import { bankRemitStatusDefaultProps } from "@/modules/bankRemit/models/BankRemitStatus";
import IconButton from "@/components/IconButton.vue";
import BankRemitItemListView from "./BankRemitItemListView.vue";
import eventBus from "@/services/eventBus";

export default Vue.extend({
  components: {
    ApiSearchPage,
    IconButton,
    BankRemitItemListView,
  },

  data() {
    return {
      bStore,
      defaultBankRemitStatus: bankRemitStatusDefaultProps,
      exportEnabled: false,
      statusFilter: new Array<number>(),
      maxDisplayFilter: 10,
      loading: false,
      waitingForPartners: false,
      selectedItems: new Array<BankRemitItem>(),
    };
  },

  beforeMount() {
    eventBus.$on("remit-item-selected", (item: BankRemitItem) =>
      this.setRemitItem(item)
    );
  },

  props: {
    isRiportSelect: { type: Boolean, default: false },
    isPartnerSelect: { type: Boolean, default: false },
  },

  methods: {
    emitPay() {
      eventBus.$emit("pay-remits", this.selectedItems);
    },

    emitCancel() {
      eventBus.$emit("cancel-import");
    },

    setRemitItem(item: BankRemitItem): void {
      if (this.selectedItems.includes(item) && !item.selected) {
        this.selectedItems = this.selectedItems.filter(
          (x) => x.itemId !== item.itemId
        );
      } else {
        this.selectedItems.push(item);
      }
    },

    emitRemitAndPartner(
      remitItem: BankRemitItem,
      remit: BankRemitAndItems
    ): void {
      this.$emit("remitAndPartner", remit.id, remitItem.partner);
    },

    async setShowPartners(item: BankRemitAndItems): Promise<void> {
      item.showPartners = !item.showPartners;
      item.expanded = item.showPartners;
    },

    emitRemitId(id: number): void {
      this.$emit("remitId", id.toString());
    },

    editItem(data: BankRemitAndItems): void {
      this.$router.push(`bank-remits/create/${data.id}/head`);
    },

    isExportEnabled(item: BankRemitAndItems): boolean {
      if (item.status === BankRemitStatusEnum.forwarded) {
        return true;
      }
      return false;
    },
    sum(items: BankRemitItem[]): string {
      let sum = 0;
      items.forEach((element: BankRemitItem) => {
        sum += element.currAmount;
      });

      return sum.toLocaleString();
    },
    setExportExpanded(item: BankRemitAndItems): void {
      if (!item.historyExpanded) {
        item.expanded = !item.exportExpanded;
      }
      item.exportExpanded = !item.exportExpanded;
    },

    setHistoryExpanded(item: BankRemitAndItems): void {
      if (!item.exportExpanded) {
        item.expanded = !item.historyExpanded;
      }
      item.historyExpanded = !item.historyExpanded;
    },
  },

  computed: {
    url(): string {
      return ``;
    },

    remitItemCols: () => [
      {
        title: "",
        width: "50px",
        cell: "selectPartnerTemplate",
      },
      {
        field: "partnerName",
        title: "Partner név",
      },
      {
        field: "bankAccountName",
        title: "Bankszámla",
      },
      {
        field: "invNo",
        title: "Számlaszám",
      },
      {
        field: "amount",
        title: "Összeg",
      },
      {
        field: "currAmount",
        title: "Deviza összeg",
      },
    ],

    columns: (): ApiSearchColumn[] => [
      {
        cell: "historyTemplate",
        filterable: false,
      },
      { field: "id", title: "Azonosító" },
      { field: "bankName", title: "Bank neve" },
      { field: "bankAccount", title: "Bankszámla" },
      {
        field: "currency",
        title: "Pénznem",
      },
      { field: "filename", title: "File neve" },
      {
        field: "remitDate",
        title: "Utalás dátuma",
        type: ColumnType.Date,
      },
      {
        title: "Állapot",
        filterable: true,
        field: "statusName",
        multiselectField: "selectedStatuses",
        multiselectOptions: bStore.bankRemitStatuses.map((x) => ({
          text: x.name,
          value: x.id,
        })),
        multiselect: true,
      },
      { cell: "importTemplate", filterable: false },
    ],

    itemColumns: () => [
      { field: "prefix", title: "Prefix", headerCell: "myHeaderTemplate" },
      { field: "invoice", title: "Számla", headerCell: "myHeaderTemplate" },
      {
        field: "invNo",
        title: "Bizonylat szám",
        headerCell: "myHeaderTemplate",
      },
      {
        field: "partnerName",
        title: "Partner",
        headerCell: "myHeaderTemplate",
      },
      {
        field: "amount",
        cell: "amountTemplate",
        title: "Számla összege",
        headerCell: "myHeaderTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        field: "dueAmount",
        cell: "dueAmountTemplate",
        title: "Esedékes",
        headerCell: "myHeaderTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      // {
      //   field: "invCurrency",
      //   title: "Pénznem",
      // },
      {
        field: "exchangeRate",
        title: "Árfolyamszorzó",
        headerCell: "myHeaderTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        field: "pterm",
        title: "Utalási határidő",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        headerCell: "myHeaderTemplate",
        className: "center_text adjust_right",
        headerClassName: "center_text adjust_right",
      },
      { width: 0, filterable: false },
    ],
  },
});
