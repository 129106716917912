var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"search-row"},[_c('v-col',{staticClass:"pr-0",attrs:{"sm":"11"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.search($event)}}},[_c('v-text-field',{attrs:{"outlined":"","single-line":"","hide-details":"auto","placeholder":"Helyesbítendő számla keresése..."},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]),_c('v-col',{staticClass:"pl-0",attrs:{"sm":"auto"}},[_c('IconButton',{attrs:{"tooltip":"Találatok törlése","icon":"fa-times"},on:{"click":_vm.clear}}),_c('IconButton',{attrs:{"tooltip":"Kijelöltek hozzáadása","icon":"fa-plus"},on:{"click":_vm.addInvoiceRectItems}})],1)],1),(_vm.items.length > 0)?_c('v-row',[_c('grid',{staticClass:"rectify-grid",attrs:{"data-items":_vm.items,"columns":_vm.columns,"scrollable":"none","detail":"detailsTemplate","expand-field":"details","selected-field":"details"},scopedSlots:_vm._u([{key:"itemsTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[(props.dataItem.items.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary","height":"30","width":"30"},on:{"click":function($event){return _vm.showDetails(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-bars")])],1)]}}],null,true)},[_c('span',[_vm._v("Számla tételek")])]):_vm._e()],1)]}},{key:"totalHeaderTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(props.title)+" ")])]}},{key:"idTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('label',[_vm._v(_vm._s(props.dataItem.incomingInvoice.id))]),(props.dataItem.isSupplierComplaint)?_c('v-icon',{staticClass:"red--text ml-1",attrs:{"small":""}},[_vm._v(" fa fa-registered ")]):_vm._e(),(_vm.isCreditingStoreInDetails(props.dataItem.items))?_c('v-icon',{staticClass:"orange--text ml-1",attrs:{"small":""}},[_vm._v(" fa-info-circle ")]):_vm._e()],1)]}},{key:"totalTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('label',[_c('money-display',{attrs:{"value":props.dataItem.incomingInvoice.currTotal,"currency":props.dataItem.incomingInvoice.invCurrency,"precision":_vm.configuration.currencyDigits.filter(
                    function (x) { return x.currency === props.dataItem.incomingInvoice.invCurrency; }
                  ).length > 0
                    ? _vm.configuration.currencyDigits.filter(
                        function (x) { return x.currency === props.dataItem.incomingInvoice.invCurrency; }
                      )[0].geniItemDigits
                    : 0}})],1)])]}},{key:"detailsTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('div',{staticClass:"items-info"},[_c('colspan-fixer',{attrs:{"cols":8}}),_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',[_c('label',{staticClass:"table_head"},[_vm._v("Tétel")])]),_c('th',[_c('label',{staticClass:"table_head"},[_vm._v("Készlet ID")])]),_c('th',[_c('label',{staticClass:"table_head"},[_vm._v("Egységár")])]),_c('th',{staticClass:"right_text"},[_c('label',{staticClass:"table_head"},[_vm._v("Mennyiség")])]),_c('th',{staticClass:"right_text"},[_c('label',{staticClass:"table_head"},[_vm._v("Nettó")])]),_c('th',{staticClass:"right_text"},[_c('label',{staticClass:"table_head"},[_vm._v("ÁFA")])]),_c('th',{staticClass:"right_text"},[_c('label',{staticClass:"table_head"},[_vm._v("Bruttó")])]),_c('th')])]),_vm._l((props.dataItem.items),function(item){return _c('InvoiceRectItem',{key:item.id,attrs:{"item":item,"currency":props.dataItem.incomingInvoice.invCurrency,"invoice":props.dataItem.rectifier,"isSupplierComplaint":props.dataItem.isSupplierComplaint}})})],2)],1)]}}],null,false,2590805462)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }