




















import Vue from 'vue';
import store, { KanriInvoiceStore } from "../services/KanriInvoiceStore";
import eventBus from '@/services/eventBus';
import configuration from '@/models/Configuration';

interface Data {
  store: KanriInvoiceStore;
  inProgress: boolean;
  errorList: string[];
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
      inProgress: true,
      errorList: []
    }
  },

  async beforeMount() {
    const objectid = Number(this.$route.params.objectId);
    if (!objectid || objectid < 1) {
      eventBus.$emit("error", `Kanri számla nem található ${objectid}!`);
      return;
    }

    const payload = {
      isEinvoice: false,
      shipDate: new Date().toDateString(),
      remark: "",
    };
    await this.store.refreshInvoice(objectid, "SALE");

    const ret = (await this.store.importKanri(Number(objectid), payload));
    console.log(ret);
    if (!ret) {
      this.$router.push(`/kanri-invoices/sale`);
      return;
    }

    this.errorList = [...ret.errorList];
    this.inProgress = false;

    if (ret && ret.id > 1 && ret.errorList.length == 0) {
      if (!ret.alreadyImported) {
        eventBus.$emit("success", "Sikeres számla beemelés!");
      }
      
      if (ret.retType == "geno") {
        this.$router.push(`/geno-experimental-invoices/${ret.id}`);
      }
      else if(ret .retType == "noto") {
        this.$router.push(`/noto/${ret.id}`);
      }
      else if(ret.retType == "geni") {
        if (configuration.invoiceFlow.enabled) {
          this.$router.push(`/general-incoming-invoice/flow/edit/${ret.id}/3`);
        }
        else {
          this.$router.push(`/general-incoming-invoice/edit/${ret.partnerId}/${ret.id}`);
        }
      }
    }
  },
})
