






































































































































































































































import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import MoneyInput from "../../../components/MoneyInput.vue";
import DateInput from "../../../components/DateInput.vue";
import BankRemitStatus from "../models/BankRemitStatus";
import api from "../services/BankRemitApi";
import BankRemitHistory from "../views/BankRemitHistory.vue";
import eventBus from "@/services/eventBus";
import BankRemitAnalitics from "./BankRemitAnalitcsView.vue";
import BankRemitRoles from "./BankRemitRoles.vue";
import auth from "@/services/auth";
import BankRemitRemark from "./BankRemitRemark.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";
import BankRemitItem from "../models/BankRemitItem";


interface Data {
  store: BankRemitStore;
  configuration: AppConfiguration;
}

interface Computed {
  status: number;
  isApprover: boolean;
  availableStatuses: BankRemitStatus[];
  items: BankRemitItem[];
  amount: number;
  currAmount: number;
}

interface Method {
  changeBankAccount(): void;
  save(): void;
  getRemit(): void;
  getApproval(): void;
}

export enum BankRemitStatusEnum {
  underProcess = 2,
  forwardable = 3,
  forwarded = 4,
  transferred = 5,
  failed = 6,
  underRepair = 7,
  partlyTransferred = 8,
}

export default Vue.extend<Data, Method, Computed, {}>({
  components: {
    BankRemitRemark,
    MoneyInput,
    DateInput,
    BankRemitHistory,
    BankRemitAnalitics,
    BankRemitRoles,
  },

  data() {
    return {
      store,
      originalStatus: 0,
      configuration
    };
  },

  async beforeMount() {
    this.store.step = 1;

    window.localStorage.setItem("previousPage", "head");

    /* await this.store.loadBankRemitStatuses().then(() => {
      this.getRemit();
      this.setStatuses();
    })
    .then(()=>{
      if(this.store.bankRemit.id>1){
      this.getApproval();
      }
    }); */
  },

  methods: {
    async getRemit() {
      await this.store.loadBankRemit(Number(this.$route.params.id));
    },

    async getApproval() {
      await this.store.loadApproval(this.store.bankRemit.id);
    },

    changeBankAccount() {
      this.$router.push("/bank-remits-account");
    },

    async save() {
      this.store.bankRemitApproval.bankRemit = this.store.bankRemit.id;

      const ret =
        (await this.store.save().then((x) => {
          return x;
        })) ?? 1;
      if (ret > 1) {
        this.$router.push(`/bank-remits/create/${ret}/item`);
      }
    },
  },

    computed: {
      availableStatuses() {
        switch (this.status) {
          case BankRemitStatusEnum.underProcess:
            return store.bankRemitStatuses.filter(
              (x) =>
                x.id == BankRemitStatusEnum.forwardable ||
                x.id == BankRemitStatusEnum.underProcess
            ) ?? [];
          case BankRemitStatusEnum.forwardable:
            return store.bankRemitStatuses.filter(
              (x) =>
                x.id == BankRemitStatusEnum.forwarded ||
                x.id == BankRemitStatusEnum.underRepair ||
                x.id == BankRemitStatusEnum.forwardable
            ) ?? [];
          case BankRemitStatusEnum.forwarded:
            return store.bankRemitStatuses.filter(
              (x) =>
                x.id == BankRemitStatusEnum.underRepair ||
                x.id == BankRemitStatusEnum.forwarded
            ) ?? [];
          case BankRemitStatusEnum.underRepair:
            return store.bankRemitStatuses.filter(
              (x) =>
                x.id == BankRemitStatusEnum.forwardable ||
                x.id == BankRemitStatusEnum.underRepair
            ) ?? [];
          case BankRemitStatusEnum.failed:
            return store.bankRemitStatuses.filter(
              (x) =>
                x.id == BankRemitStatusEnum.underRepair ||
                x.id == BankRemitStatusEnum.failed
            ) ?? [];
          default: return []; 
        }
      },

    status() {
      return this.store.bankRemit.status;
    },
    isApprover() {
      if (this.store.bankRemitApproval) {
        const x = this.store.allApproverUser.find(
          (x) => x.id == this.store.bankRemitApproval.acceptorId
        );
        if (x && x.name == auth.userName) {
          return true;
        } else return false;
      } else return false;
    },

    items(){
      return this.store.bankRemit.items;
    },

    amount() {
      const amounts = this.items.map(x => x.amount);
      return amounts.reduce((pv, cv) => pv + cv, 0);
    },

    currAmount(){
      const amounts = this.items.map(x => x.currAmount);
      return amounts.reduce((pv, cv) => pv + cv, 0);
    }
  },

  watch: {
    status() {
      const x = this.store.bankRemitStatuses.find((x) => x.id == this.status)
        ?.name;
      if (x) {
        this.store.bankRemit.statusName = x;
      }
    },

    items(){
      console.log(this.items);
    }
  },
});
