import ApiResponse from "@/models/ApiResponse";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import Message from "../models/Message";
import MessagedGroupResponse from "../models/MessagedGroupResponse";
import MessagedUserResponse from "../models/MessagedUserResponse";

export default class ChatApi {
  static getMessagedUsers(): Promise<MessagedUserResponse[]> {
    return http.get<ApiResponse<MessagedUserResponse[]>>(`chat/messaged-users`).then(handleApiResponse);
  }

  static getMessagedGroups(): Promise<MessagedGroupResponse[]> {
    return http.get<ApiResponse<MessagedGroupResponse[]>>(`chat/messaged-groups`).then(handleApiResponse);
  }

  static getGeniMessages(invoiceId: number): Promise<Message[]> {
    return http.get<ApiResponse<Message[]>>(`chat/geni/messages/${invoiceId}`).then(handleApiResponse);
  }

  static getMessages(toId: number): Promise<Message[]> {
    return http.get<ApiResponse<Message[]>>(`chat/messages/${toId}`).then(handleApiResponse);
  }

  static getGroupMessages(groupId: string): Promise<Message[]> {
    return http.get<ApiResponse<Message[]>>(`chat/group/messages/${groupId}`).then(handleApiResponse);
  }

  static getNewMessageCount(): Promise<number> {
    return http.get<ApiResponse<number>>(`chat/new-message-count`).then(handleApiResponse);
  }

  static createGroup(group: MessagedGroupResponse): Promise<MessagedGroupResponse> {
    return http
      .post<ApiResponse<MessagedGroupResponse>>(`chat/group`, { group: group })
      .then(handleApiResponse);
  }

  static addToGroup(groupId: string, userId: number): Promise<boolean> {
    return http.post(`chat/group/add`, { groupId: groupId, userId: userId }).then(checkResponse);
  }

  static leaveGroup(groupId: string): Promise<boolean> {
    return http.post(`chat/group/leave`, { groupId: groupId }).then(checkResponse);
  }

  static sendMessage(message: Message): Promise<Message> {
    return http
      .post<ApiResponse<Message>>(`chat/message`, { message: message })
      .then(handleApiResponse);
  }

  static readMessage(messageId: number): Promise<boolean> {
    return http.post(`chat/read`, { messageId: messageId }).then(checkResponse);
  }

  static generatePictureLink(userId: number) {
    return http.defaults.baseURL + `users/picture/${userId}`;
  }
}
