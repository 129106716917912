








































































































































































































































































































































































































































































































































































































































































































































































































import Page from "../../../components/Page.vue";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import ColspanFixer from "@/components/ColspanFixer.vue";
import { InvoicePreStatus } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import IconButton from "@/components/IconButton.vue";
import Fragment from "@/components/Fragment.vue";
import Tabs from "@/components/Tabs.vue";
import InvoiceAccounting from "@/modules/invoiceFlow/views/InvoiceAccounting.vue";
import SetIncorrectInvoice from "@/modules/invoiceFlow/components/SetIncorrectInvoice.vue";

import Vue from "vue";
import cuid from "cuid";
import debounce from "debounce";
import eventBus from "../../../services/eventBus";
import store, { CustomsInvoiceStore } from "../services/CustomsInvoiceStore";
import permStore, { PermissionStore } from "@/services/permissions";
import GeneralIngoingInvoiceItemDisplay, {
  generalIngoingInvoiceItemDisplayDefaultProps,
} from "../../generalIngoingInvoice/models/GeneralIngoingInvoiceItemDisplay";
import { generalIngoingInvoiceItemDefaultProps } from "../../generalIngoingInvoice/models/GeneralIngoingInvoiceItem";
import StockForCustomsSelection from "../models/StockForCustomsSelection";
import GeniForCustomSelection from "../models/GeniForCustomSelection";
import AccountNumberAndClaimRelation from "../models/AccountNumberAndClaimRelation";
import CustomsInvoicePictureView from "./CustomsInvoicePictureView.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: CustomsInvoiceStore;
  permStore: PermissionStore;
  geniSelectorVisible: boolean;
  genisTake: number;
  genisSkip: number;
  stockSearch: string;
  accountingVisible: boolean;
  statusColorMap: Record<string, string>;
  loading: boolean;

  configuration: AppConfiguration;
}

interface Methods {
  checkBeforeSave(): boolean;
  save(): void;
  addAll(item: any): void;
  accept(): void;
  update(): void;
  cancel(): void;
  partnerSelected(): void;
  addVat(): void;
  addDuty(): void;
  invoiceItemSelectedTaxChanged(item: GeneralIngoingInvoiceItemDisplay): void;
  calculateDuty(item: GeneralIngoingInvoiceItemDisplay): void;
  calculateVat(item: GeneralIngoingInvoiceItemDisplay): void;
  deleteItem(item: GeneralIngoingInvoiceItemDisplay): void;
  roundNumber(n: number): number;
  genisPageChange(event: any): void;
  loadGenis(): void;
  expandDutyDetails(item: GeneralIngoingInvoiceItemDisplay): void;
  expandSelectedDutyDetails(item: GeneralIngoingInvoiceItemDisplay): void;
  expandAccountDetails(item: GeneralIngoingInvoiceItemDisplay): void;
  closeDetails(item: GeneralIngoingInvoiceItemDisplay): void;
  geniSelectionChanged(item: GeniForCustomSelection): void;
  divideStockValues(item: GeneralIngoingInvoiceItemDisplay): void;
  addStockRelation(item: GeneralIngoingInvoiceItemDisplay, stock: StockForCustomsSelection): void;
  deleteStockRelation(item: GeneralIngoingInvoiceItemDisplay, stock: StockForCustomsSelection): void;
  stockRelationDisabled(stock: StockForCustomsSelection): boolean;
  accountingToggled(): void;
  setIncorrect(): void;
}

interface Computed {
  vatAccounts: AccountNumberAndClaimRelation[];
  dutyAccounts: AccountNumberAndClaimRelation[];
  vatItems: GeneralIngoingInvoiceItemDisplay[];
  vatItemsLength: number;
  dutyItems: GeneralIngoingInvoiceItemDisplay[];
  genis: GeniForCustomSelection[];
  genisTotal: number;
  total: number;
  disabled: boolean;
  availableStocks: StockForCustomsSelection[];
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    Page,
    PartnerSearch,
    MoneyInput,
    ColspanFixer,
    IconButton,
    Fragment,
    Tabs,
    InvoiceAccounting,
    SetIncorrectInvoice,
    CustomsInvoicePictureView,
  },

  data: () => ({
    store,
    permStore,
    geniSelectorVisible: false,
    genisTake: 10,
    genisSkip: 0,
    stockSearch: "",
    accountingVisible: false,
    statusColorMap: {
      default: "primary white--text",
      4: "orange white--text",
      5: "green white--text",
      8: "green white--text",
      6: "red darken-2 white--text",
      7: "red darken-2 white--text",
    },
    loading: false,

    configuration,
  }),

  created() {
    this.loadGenis = debounce(this.loadGenis, 500);
    this.accountingToggled = debounce(this.accountingToggled, 300);
  },

  async beforeMount() {
    this.store.genis = [];

    const invoiceId = Number(this.$route.params.invoiceId) || 0;
    await Promise.all([this.store.loadInvoice(invoiceId), this.store.loadTaxes()]);
    await this.store.loadDetails(invoiceId);

    this.store.invoiceItems
      .filter((item) => item.incomingInvoiceItem.gparts == this.store.details.vatGPartID)
      .forEach((item) => {
        item.selectedTax = this.store.taxes.find((tax) => tax.id == item.incomingInvoiceItem.tax);
      });
  },

  computed: {
    vatAccounts() {
      return this.store.details.accounts.filter((x) => x.claimId == 3);
    },

    dutyAccounts() {
      return this.store.details.accounts.filter((x) => x.claimId == 2);
    },

    vatItems() {
      this.store.invoiceItems
        .filter((x) => x.incomingInvoiceItem.gparts == this.store.details.vatGPartID)
        .forEach((item) => {
          item.selectedTax = this.store.taxes.find((x) => x.id == item.incomingInvoiceItem.tax);
        });

      return this.store.invoiceItems.filter((x) => x.incomingInvoiceItem.gparts == this.store.details.vatGPartID);
    },

    vatItemsLength() {
      return this.store.invoiceItems.filter((x) => x.incomingInvoiceItem.gparts == this.store.details.vatGPartID)
        .length;
    },

    dutyItems() {
      console.log(
        this.store.invoiceItems.filter((x) => x.incomingInvoiceItem.gparts == this.store.details.dutyGPartID)
      );
      return this.store.invoiceItems.filter((x) => x.incomingInvoiceItem.gparts == this.store.details.dutyGPartID);
    },

    genis() {
      const list = this.store.genis.slice(this.genisSkip, this.genisTake + this.genisSkip);
      list.forEach((geni) => {
        geni.isSelected = this.store.genisInRelation.some((x) => x.geniID == geni.geniID);
      });

      return list;
    },

    genisTotal() {
      return this.store.genis ? this.store.genis.length : 0;
    },

    total() {
      const vatItems = this.store.invoiceItems.filter(
        (x) => x.incomingInvoiceItem.gparts == this.store.details.vatGPartID
      );
      const dutyItems = this.store.invoiceItems.filter(
        (x) => x.incomingInvoiceItem.gparts == this.store.details.dutyGPartID
      );

      const vatItemsSum = vatItems.map((x) => x.incomingInvoiceItem.vat).reduce((a, b) => a + b, 0);
      const dutyItemsSum = dutyItems.map((x) => x.incomingInvoiceItem.netto).reduce((a, b) => a + b, 0);

      return vatItemsSum + dutyItemsSum;
    },

    disabled() {
      return [InvoicePreStatus.Accepted, InvoicePreStatus.WrongItem].includes(this.store.invoice.preStatus);
    },

    availableStocks() {
      return this.store.genisInRelation
        .map((x) => x.stocks)
        .reduce((a, b) => a.concat(b), [])
        .filter(
          (x) =>
            x.invoiceID
              ?.toString()
              .toLowerCase()
              .includes(this.stockSearch.toLowerCase()) ||
            x.invoiceNumber?.toLowerCase().includes(this.stockSearch.toLowerCase()) ||
            x.orderNo?.toLowerCase().includes(this.stockSearch.toLowerCase()) ||
            x.code?.toLowerCase().includes(this.stockSearch.toLowerCase()) ||
            x.partName?.toLowerCase().includes(this.stockSearch.toLowerCase())
        );
    },
  },

  methods: {
    addAll(item: any) {
      this.availableStocks.forEach((stock: any) => {
        this.addStockRelation(item, stock)
      });
    },

    checkBeforeSave() {
      const vatItems = this.store.invoiceItems.filter(
        (x) => x.incomingInvoiceItem.gparts == this.store.details.vatGPartID
      );
      const dutyItems = this.store.invoiceItems.filter(
        (x) => x.incomingInvoiceItem.gparts == this.store.details.dutyGPartID
      );

      const vatItemsSum = vatItems.map((x) => x.incomingInvoiceItem.vat).reduce((a, b) => a + b, 0);
      const dutyItemsSum = dutyItems.map((x) => x.incomingInvoiceItem.netto).reduce((a, b) => a + b, 0);

      const total = vatItemsSum + dutyItemsSum;

      if (this.store.invoice.total != total) {
        eventBus.$emit("error", "A tételek összege nem egyezik meg a vámhatározat összegével!");
        return false;
      }

      return true;
    },

    save() {
      this.store.invoice.partnerRel = this.store.details.dutyPartnerRel;

      if (this.store.invoice.id <= 1) {
        this.store.invoice.shipDate = this.store.invoice.invoiceDate;
        this.store.invoice.paymode = this.store.details.transferPaymentMode;
        this.store.invoice.divitem = this.store.details.defaultDivItem;
      }

      const vatItems = this.store.invoiceItems.filter(
        (x) => x.incomingInvoiceItem.gparts == this.store.details.vatGPartID
      );
      vatItems.forEach((x) => {
        x.incomingInvoiceItem.currVat = x.incomingInvoiceItem.vat;
        x.incomingInvoiceItem.brutto = x.incomingInvoiceItem.vat;
        x.incomingInvoiceItem.currBrutto = x.incomingInvoiceItem.vat;
        x.incomingInvoiceItem.inVatCount = true;

        x.incomingInvoiceItem.unit = this.store.details.pieceUnitTypeID;
        x.incomingInvoiceItem.quantity = 1;
      });

      const dutyItems = this.store.invoiceItems.filter(
        (x) => x.incomingInvoiceItem.gparts == this.store.details.dutyGPartID
      );
      dutyItems.forEach((x) => {
        x.incomingInvoiceItem.currNetto = x.incomingInvoiceItem.netto;
        x.incomingInvoiceItem.brutto = x.incomingInvoiceItem.netto;
        x.incomingInvoiceItem.currBrutto = x.incomingInvoiceItem.netto;
        x.incomingInvoiceItem.tax = this.store.details.exemptOfTaxLaw;
        x.incomingInvoiceItem.inVatCount = true;

        x.incomingInvoiceItem.unit = this.store.details.pieceUnitTypeID;
        x.incomingInvoiceItem.quantity = 1;
      });

      const vatItemsSum = vatItems.map((x) => x.incomingInvoiceItem.vat).reduce((a, b) => a + b, 0);
      const dutyItemsSum = dutyItems.map((x) => x.incomingInvoiceItem.netto).reduce((a, b) => a + b, 0);

      this.store.invoice.netto = dutyItemsSum;
      this.store.invoice.currNetto = dutyItemsSum;
      this.store.invoice.vat = vatItemsSum;
      this.store.invoice.currVat = vatItemsSum;
      this.store.invoice.invVat = vatItemsSum;
      // this.store.invoice.total = vatItemsSum + dutyItemsSum;
      this.store.invoice.currTotal = vatItemsSum + dutyItemsSum;
      this.store.invoice.currency = "HUF";
      this.store.invoice.invCurrency = "HUF";
      this.store.invoice.exchangeRate = 1;

      if (vatItems.length > 0) {
        this.store.invoice.tax = vatItems[0].incomingInvoiceItem.tax;
      } else {
        this.store.invoice.tax = this.store.details.exemptOfTax;
      }
    },

    async accept() {
      if (!this.checkBeforeSave()) return;

      await this.save();

      await this.store.accept().then(async () => {
        await Promise.all([
          this.store.loadInvoice(Number(this.$route.params.invoiceId)),
          this.store.loadDetails(Number(this.$route.params.invoiceId)),
        ]);
        eventBus.$emit("success", "Sikeres jóváhagyás!");
      });

      return;
    },

    async update() {
      await this.save();

      await this.store.update().then(async (result) => {
        if (result && result > 0) {
          if (this.store.invoice.id == 0) {
            this.$router.push(`/customs-invoice/edit/${result}`);
          }

          await this.store.loadInvoice(result);
        }

        eventBus.$emit("success", "Sikeres mentés!");
      });
    },

    cancel() {
      this.$router.push(`/customs-invoice`);
    },

    async partnerSelected() {
      await this.store.loadDetails(Number(this.$route.params.invoiceId));
    },

    genisPageChange(event: any) {
      this.genisSkip = event.page.skip;
      this.genisTake = event.page.take;
    },

    async loadGenis() {
      this.loading = true;
      if (this.store.searchText.length > 2) {
        await this.store.loadGenis().then((x) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          return x;
        });
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },

    addVat() {
      const newItem: GeneralIngoingInvoiceItemDisplay = {
        ...generalIngoingInvoiceItemDisplayDefaultProps,
        selectedStocks: [],
      };

      newItem.incomingInvoiceItem = {
        ...generalIngoingInvoiceItemDefaultProps,
        cuid: cuid(),
      };

      newItem.incomingInvoiceItem.gparts = this.store.details.vatGPartID;
      newItem.incomingInvoiceItem.claim = 3;

      newItem.selectedTax = this.store.taxes.find((x) => x.id == this.store.details.defaultTaxGr);
      newItem.incomingInvoiceItem.tax = newItem.selectedTax?.id ?? 1;

      this.store.invoiceItems.push(newItem);
    },

    addDuty() {
      const newItem: GeneralIngoingInvoiceItemDisplay = {
        ...generalIngoingInvoiceItemDisplayDefaultProps,
        selectedStocks: [],
      };

      newItem.incomingInvoiceItem = {
        ...generalIngoingInvoiceItemDefaultProps,
        cuid: cuid(),
      };

      newItem.incomingInvoiceItem.gparts = this.store.details.dutyGPartID;
      newItem.incomingInvoiceItem.claim = 2;
      newItem.incomingInvoiceItem.name = newItem.selectedStocks[0]?.partName ?? "";

      this.store.invoiceItems.push(newItem);
    },

    invoiceItemSelectedTaxChanged(item: GeneralIngoingInvoiceItemDisplay) {
      if (item.selectedTax) {
        item.incomingInvoiceItem.tax = item.selectedTax.id;
      }

      this.calculateVat(item);
    },

    calculateDuty(item: GeneralIngoingInvoiceItemDisplay) {
      item.incomingInvoiceItem.dutyPercentage = item.visibleDutyPercentage / 100;

      item.incomingInvoiceItem.netto = Math.round(
        item.incomingInvoiceItem.dutyBase * item.incomingInvoiceItem.dutyPercentage
      );
    },

    calculateVat(item: GeneralIngoingInvoiceItemDisplay) {
      if (item.selectedTax) {
        item.incomingInvoiceItem.vat = Math.round(item.incomingInvoiceItem.vatBase * (item.selectedTax.degree / 100));
      }
    },

    deleteItem(item: GeneralIngoingInvoiceItemDisplay) {
      const index = this.store.invoiceItems.indexOf(item);
      if (index > -1) {
        this.store.invoiceItems.splice(index, 1);
      }
    },

    expandDutyDetails(item: GeneralIngoingInvoiceItemDisplay) {
      item.accountDetails = false;
      item.selectedDutyDetails = false;
      item.dutyDetails = !item.dutyDetails;
      item.expanded = item.dutyDetails || item.selectedDutyDetails || item.accountDetails;
    },

    expandSelectedDutyDetails(item: GeneralIngoingInvoiceItemDisplay) {
      item.dutyDetails = false;
      item.accountDetails = false;
      item.selectedDutyDetails = !item.selectedDutyDetails;
      item.expanded = item.dutyDetails || item.selectedDutyDetails || item.accountDetails;
    },

    expandAccountDetails(item: GeneralIngoingInvoiceItemDisplay) {
      item.dutyDetails = false;
      item.selectedDutyDetails = false;
      item.accountDetails = !item.accountDetails;
      item.expanded = item.dutyDetails || item.selectedDutyDetails || item.accountDetails;
    },

    closeDetails(item: GeneralIngoingInvoiceItemDisplay) {
      item.accountDetails = item.dutyDetails = item.expanded = false;
    },

    roundNumber(n: number) {
      return Math.round((n + Number.EPSILON) * 100) / 100;
    },

    geniSelectionChanged(item: GeniForCustomSelection) {
      const included = this.store.genisInRelation.find((x) => x.geniID == item.geniID);

      if (item.isSelected) {
        if (included) return;

        this.store.genisInRelation.push(item);
      } else {
        if (!included) return;

        const index = this.store.genisInRelation.indexOf(included);
        if (index > -1) {
          this.store.genisInRelation.splice(index, 1);
        }
      }
    },

    divideStockValues(item: GeneralIngoingInvoiceItemDisplay) {
      const allValue = item.selectedStocks.map((x) => x.stockValue).reduce((a, b) => a + b, 0);

      let temp = 0;
      item.selectedStocks.forEach((stock, idx) => {
        const div = allValue == 0 ? 0 : this.roundNumber(stock.stockValue / allValue);

        if (idx == item.selectedStocks.length - 1) {
          stock.currNetto = item.incomingInvoiceItem.netto - temp;
        }
        else {
          stock.currNetto = this.roundNumber(item.incomingInvoiceItem.netto * div);
          temp += stock.currNetto;
        }
      });
    },

    addStockRelation(item: GeneralIngoingInvoiceItemDisplay, stock: StockForCustomsSelection) {
      if (this.stockRelationDisabled(stock)) {
        item.selectedStocks = item.selectedStocks.filter((x) => x.stockId != stock.stockId)
      } else {
        item.selectedStocks.push(JSON.parse(JSON.stringify(stock)));
        stock.currNetto = 0;
      }
    },

    deleteStockRelation(item: GeneralIngoingInvoiceItemDisplay, stock: StockForCustomsSelection) {
      const index = item.selectedStocks.indexOf(stock);
      if (index > -1) {
        item.selectedStocks.splice(index, 1);
      }
    },

    stockRelationDisabled(stock: StockForCustomsSelection) {
      return this.store.invoiceItems.some((x) => x.selectedStocks.some((y) => y.stockId == stock.stockId));
    },

    accountingToggled() {
      if (!this.accountingVisible) return;
      this.$vuetify.goTo(document.body.scrollHeight, { duration: 1000 });
    },

    async setIncorrect() {
      await this.store.setIncorrect().then(async () => {
        await Promise.all([
          this.store.loadInvoice(Number(this.$route.params.invoiceId)),
          this.store.loadDetails(Number(this.$route.params.invoiceId)),
        ]);
        eventBus.$emit("success", "Hibás rögzítés státusz frissítve.");
        this.$router.push("/customs-invoice");
      });
    },
  },
});
