var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._b({attrs:{"clearable":"","clear-icon":"fa-times","return-object":false,"item-text":_vm.itemText,"item-value":_vm.itemValue,"search-input":_vm.search,"items":_vm.displayedItems,"outlined":"","dense":"","no-filter":"","disabled":_vm.disabled,"menu-props":{ maxHeight: 888 },"placeholder":_vm.placeholder},on:{"click:clear":function($event){return _vm.clear()},"focus":function($event){_vm.ignoreSearch = false},"blur":function($event){_vm.ignoreSearch = true},"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"tr"},[_c('v-row',{staticClass:"table-head"},_vm._l((_vm.localColumns),function(c,i){return _c('v-col',{key:i + '_title',staticClass:"td white--text",class:_vm.getHeaderClasses(c),style:({ 'border-left: none': i == 0 })},[(
              _vm.sortable &&
                _vm.apiSearch.sort &&
                _vm.apiSearch.sort.definitions.find(function (x) { return x.field === c.field; })
            )?_c('fragment',[_c('a',{staticClass:"white--text",on:{"click":function($event){return _vm.toggleSort(c.field)}}},[_vm._v(" "+_vm._s(c.title)+" "),(_vm.getFieldOrder(c.field) === 'Ascending')?_c('v-icon',{staticClass:"ml-1 white--text",attrs:{"x-small":""}},[_vm._v(" fa-sort-alpha-asc ")]):_vm._e(),(_vm.getFieldOrder(c.field) === 'Descending')?_c('v-icon',{staticClass:"ml-1 white--text",attrs:{"x-small":""}},[_vm._v(" fa-sort-alpha-desc ")]):_vm._e()],1)]):_c('div',[_vm._v(_vm._s(c.title))]),(
              _vm.filterable &&
                _vm.apiSearch.filter &&
                _vm.apiSearch.filter.filters[c.field] &&
                !c.multiselect
            )?_c('v-text-field',{staticClass:"remove-bottom mt-1",attrs:{"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.apiSearch.filter.filters[c.field].value),callback:function ($$v) {_vm.$set(_vm.apiSearch.filter.filters[c.field], "value", $$v)},expression:"apiSearch.filter.filters[c.field].value"}}):(_vm.filterable && _vm.apiSearch.filter && c.multiselect)?_c('v-select',{staticClass:"remove-bottom mt-1",staticStyle:{"overflow":"hidden"},attrs:{"outlined":"","dense":"","hide-details":"auto","items":_vm.apiSearch.filter.filters[c.multiselectField].options,"item-value":"value","item-text":"text","multiple":"","deletable-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('div',{staticStyle:{"font-size":".7em"}},[(index < 1)?_c('span',[_vm._v(" "+_vm._s(item.text))]):_vm._e(),(index === 0)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.apiSearch.filter.filters[c.multiselectField] .selectedOptions.length - 1)+" kiválasztva)")]):_vm._e()])]}}],null,true),model:{value:(
              _vm.apiSearch.filter.filters[c.multiselectField].selectedOptions
            ),callback:function ($$v) {_vm.$set(_vm.apiSearch.filter.filters[c.multiselectField], "selectedOptions", $$v)},expression:"\n              apiSearch.filter.filters[c.multiselectField].selectedOptions\n            "}}):_c('v-text-field',{staticClass:"remove-bottom mt-1",attrs:{"disabled":"","outlined":"","dense":"","hide-details":"auto"}})],1)}),1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"noData"},[_vm._v("Nincs adat")])]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"tr"},[_c('v-row',_vm._l((_vm.localColumns),function(c,i){return _c('v-col',{key:i + '_field',staticClass:"td",class:_vm.getFieldClasses(c),style:({ 'border-left: none': i == 0 }),attrs:{"title":_vm.displayTitles ? item[c.field] : ''}},[(c.cell)?_c('fragment',[_vm._t(c.cell,null,{"column":c,"item":item})],2):(c.type === 'money')?_c('fragment',[_vm._v(" "+_vm._s(_vm._f("moneyFormat")(item[c.field]))+" ")]):(c.type === 'date')?_c('fragment',[_vm._v(" "+_vm._s(_vm._f("dateFormatHU")(item[c.field]))+" ")]):_c('fragment',[_vm._v(" "+_vm._s(item[c.field])+" ")])],1)}),1)],1)]}},{key:"append-item",fn:function(){return [_c('div',[_c('v-row',{staticClass:"table-footer"},[_c('v-col',[_c('ApiPaginator',{attrs:{"totalItems":_vm.totalItems,"baseSizes":_vm.baseSizes},on:{"change":_vm.pagerChanged}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},'v-autocomplete',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }