var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_vm._v("Terhelési értesítő kiválasztása")])],1)],1),_c('v-card-text',[_c('Tabs',{scopedSlots:_vm._u([{key:"headers",fn:function(){return [_c('v-tab',{key:"DebitPayment"},[_vm._v(" Terhelési értesítő előjegyzés ")]),_c('v-tab',{key:"Orders"},[_vm._v(" Megrendelés kapcsolat ")])]},proxy:true},{key:"items",fn:function(){return [_c('v-tab-item',[_c('v-row',{staticClass:"search-row",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","single-line":"","hide-details":"auto","placeholder":"Terhelési értesítő előjegyzés keresése..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('icon-button',{attrs:{"icon":"fa-times","tooltip":"Találatok törlése"},on:{"click":_vm.clear}})],1)],1),_c('grid',{attrs:{"scrollable":"none","columns":_vm.columns,"data-items":_vm.store.debitPaymentItems},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Terhelési értesítő előjegyzés kiválasztása")])])],1)]}}])})],1),_c('v-tab-item',[_c('v-row',{staticClass:"search-row",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","single-line":"","hide-details":"auto","placeholder":"Megrendelés keresése rendelés szám és cikk alapján..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},model:{value:(_vm.orderSearchText),callback:function ($$v) {_vm.orderSearchText=$$v},expression:"orderSearchText"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('icon-button',{attrs:{"icon":"fa-times","tooltip":"Találatok törlése"},on:{"click":_vm.clearOrder}})],1)],1),_c('grid',{attrs:{"scrollable":"none","columns":_vm.orderColumns,"data-items":_vm.store.extOrders},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Megrendelés kiválasztása")])])],1)]}}])})],1)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancel}},[_vm._v(" Mégse ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }