export interface TechnicalInvoiceItemVIRI {
    id: number;
    invNo: number;
    total: number;
    partner: number;
    checkType: number;
    regNum: string;
    car: number;
    viro: number;
    pterm: Date | null;
    pmode: number | null;
    payTime: Date | null;
    checkId: string | null;
}

export const technicalInvoiceVIRODefaultProps: TechnicalInvoiceItemVIRI = {
    id: 0,
    invNo: 1,
    total: 0,
    partner: 1,
    checkType: 1,
    regNum: "",
    car: 1,
    viro: 1,
    pterm: null,
    pmode: null,
    payTime: null,
    checkId: null,
}