import NoticeDisplay, { noticeDisplayDefaultProps } from "../models/NoticeDisplay";
import NoticeApi from "./NoticeApi";
import Notice, { noticeDefaultProps } from "../models/Notice";
import InvoiceStatus from "../models/InvoiceStatus";
import AccountStatus from "../models/AccountStatus";
import InvoiceGroupDisplay from "../../invoiceGroup/models/InvoiceGroupDisplay";
import User, { userDefaultProps } from "../../invoiceGroup/models/User";
import PaymentModeDisplay from "../../paymentMode/models/PaymentModeDisplay";
import Language from "../models/Language";
import NoticeType, { noticeTypeDefaultProps } from "../models/NoticeType";
import NoticeGenoType from "../models/NoticeGenoType";
import Country from "../../cashPoint/models/Country";
import NoticeDetails, { noticeDetailsDefaultProps } from "../models/NoticeDetails";
import PartBank from "../../cashPoint/models/PartBank";
import GPartsDisplay from "../models/GPartsDisplay";
import NoticeItem from "../models/NoticeItem";
import NoticeDivitem, { noticeDivitemDefaultProps } from "../models/NoticeDivitem";
import busyIndication from "../../../utils/busyIndication";
import auth from "@/services/auth";
import StornoOptions, { stornoOptionsDefaultProps } from "../models/StornoOptions";
import eventBus from "@/services/eventBus";
import PartCust, { partCustDefaultProps } from "@/modules/partner/models/PartCust";
import PartnerApi from "@/modules/partner/services/PartnerApi";
import InvoiceGroupApi from "@/modules/invoiceGroup/services/InvoiceGroupApi";
import DivisionItem from "@/modules/invoiceGroup/models/DivisionItem";
import CashPointSelect from "@/modules/cashPoint/models/CashPointSelect";
import CashPointApi from "@/modules/cashPoint/services/CashPointApi";

export class NoticeStore {
  notices: NoticeDisplay[] = [];
  notice: Notice = { ...noticeDefaultProps };
  noticeCopy: Notice = { ...noticeDefaultProps };
  invoiceStatuses: InvoiceStatus[] = [];
  accountStatuses: AccountStatus[] = [];
  invoiceGroups: InvoiceGroupDisplay[] = [];
  users: User[] = [];
  paymentModes: PaymentModeDisplay[] = [];
  languages: Language[] = [];
  noticeTypes: NoticeType[] = [];
  divitems: DivisionItem[] = [];
  noticeGenoTypes: NoticeGenoType[] = [];
  currencies: Country[] = [];
  noticeDetails: NoticeDetails = { ...noticeDetailsDefaultProps };
  partCust: PartCust = { ...partCustDefaultProps };
  partBanksIsAlive = true;
  partBanks: PartBank[] = [];
  gParts: GPartsDisplay[] = [];
  partName = "";
  noticeItems: NoticeItem[] = [];
  noticeItemsCopy: NoticeItem[] = [];
  stornoOptions: StornoOptions = stornoOptionsDefaultProps;
  cashPoints: CashPointSelect[] = [];

  @busyIndication()
  async loadNotices() {
    this.notices = (await NoticeApi.getAll()).map((i) => ({ ...i, deleting: false }));
  }

  @busyIndication()
  async loadNotice(noticeId: number) {
    if (noticeId > 1) {
      const result = await NoticeApi.get(noticeId);

      this.notice = result.notice;
      this.noticeItems = result.items;
    }
  }

  async loadNoticeByObjectId(objectId: number) {
    if (objectId > 1) {
      const result = await NoticeApi.getByObjectId(objectId);

      this.notice = result.notice;
      this.noticeItems = result.items;
    }
  }

  resetNOTO() {
    this.notice = { ...noticeDefaultProps };
    this.noticeItems = [];
  }

  async loadPartCust(partnerID: number) {
    this.partCust = (await PartnerApi.getPartCust(partnerID)) || { ...partCustDefaultProps }; // ha nincs vevői relációja, állítsuk be a defaultot és 0-s id-val tudjuk vizsgálni a validációkat
  }

  async loadPartBanks() {
    this.partBanks = await NoticeApi.getPartBanks(this.partBanksIsAlive);
  }

  async loadNoticeDetails(partnerId: number, noticeId: number) {
    this.noticeDetails = await NoticeApi.getNoticeDetails(partnerId, noticeId);
  }

  async deleteNoto(noticeId: number) {
    await NoticeApi.deleteNoto(noticeId);
  }

  async pay(noticeId: number) {
    return NoticeApi.pay(noticeId);
  }

  async payWithCashPoint(noticeId: number, cashPoint: number) {
    return NoticeApi.payWithCashPoint(noticeId, cashPoint);
  }

  async loadInvoiceStatuses() {
    this.invoiceStatuses = await NoticeApi.getInvoiceStatuses();
    const statusToUpdate = this.invoiceStatuses.find((is) => is.name === "-");
    if (statusToUpdate) {
      statusToUpdate.name = "Feldolgozás alatt"; // külön kérés volt
    }
  }

  async loadAccountStatuses() {
    this.accountStatuses = await NoticeApi.getAccountStatuses();
  }

  async loadInvoiceGroups() {
    this.invoiceGroups = await NoticeApi.getInvoiceGroups();
  }

  async loadUsers() {
    this.users = await NoticeApi.getUsers();
  }

  async loadPaymentModes() {
    this.paymentModes = await NoticeApi.getPaymentModes();
  }

  async loadLanguages() {
    this.languages = await NoticeApi.getLanguages();
  }

  async loadNoticeTypes() {
    const allNoticeTypes = await NoticeApi.getNoticeTypes();
    this.noticeTypes = allNoticeTypes.filter((nt) => (nt.code.toUpperCase() === "GENO" && nt.dpayment) || !nt.dpayment);
    this.noticeTypes.sort((a, b) => (a.dpayment ? 1 : -1));
  }

  async loadNoticeGenoTypes() {
    this.noticeGenoTypes = await NoticeApi.getNoticeGenoTypes();
  }

  async loadCurrencies() {
    this.currencies = await NoticeApi.getCurrencies();
  }

  async loadCashPoints() {
    this.cashPoints = await CashPointApi.getCashPoints();
  }

  async loadGParts() {
    this.gParts = await NoticeApi.getGParts(this.partName);
  }

  async loadRate() {
    const ret = await NoticeApi.getRate(this.notice.invCurrency, this.notice.shipDate.toLocaleString());
    this.notice.exchangeRate = (ret as any).centralRate;
    this.notice.rateDate = (ret as any).rateDate;
  }

  async loadDivitems() {
    this.divitems = await InvoiceGroupApi.getDivisionItems();
    //this.divitems = await NoticeApi.getDivitems(auth.userID);
    //this.divitems.push({ ...noticeDivitemDefaultProps });
    //this.divitems.sort((a, b) => (a.name > b.name.toUpperCase() ? 1 : -1));
  }

  setDeleting(item: NoticeDisplay) {
    item.deleting = !item.deleting;
    this.notices = [...this.notices];
  }

  async create(): Promise<number> {
    return await NoticeApi.create(this.notice, this.noticeItems);
  }

  async modify() {
    return await NoticeApi.update(this.notice, this.noticeItems);
  }

  async print(noticeId: number): Promise<void> {
    const base64 = await NoticeApi.print(noticeId);
    const pdfWindow = window.open("");
    if (pdfWindow) {
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64) + "'></iframe>"
      );
    }
  }

  async storno(noticeId: number, options: StornoOptions) {
    return await NoticeApi.storno(noticeId, options);
  }

  calculatePrices() {
    if (this.noticeItems.length > 0) {
      const vat = this.noticeItems.map((x) => x.vat).reduce((total, num) => total + num, 0);
      const netto = this.noticeItems.map((x) => x.netto).reduce((total, num) => total + num, 0);
      const brutto = this.noticeItems.map((x) => x.brutto).reduce((total, num) => total + num, 0);

      this.notice.currNetto = netto;
      this.notice.netto = netto;
      this.notice.currVat = vat;
      this.notice.vat = vat;
      this.notice.currTotal = brutto;
      this.notice.total = brutto;
    } else {
      this.notice.currNetto = 0;
      this.notice.netto = 0;
      this.notice.currVat = 0;
      this.notice.vat = 0;
      this.notice.currTotal = 0;
      this.notice.total = 0;
    }
  }

  twoNoticeEquals(item1: Notice, item2: Notice): boolean {
    return JSON.stringify(item1) === JSON.stringify(item2);
  }

  twoNoticeItemEquals(item1: NoticeItem, item2: NoticeItem): boolean {
    return JSON.stringify(item1) === JSON.stringify(item2);
  }

  checkForItemExistence(original: NoticeItem[], modified: NoticeItem[]): boolean[] {
    // lecsekkoljuk hogy az original lista elemei tartalmazzák e a modified lista elemeit
    return original.map((oldItem) => {
      const containsItem = modified.map((newItem) => {
        return this.twoNoticeItemEquals(oldItem, newItem);
      });
      return containsItem.includes(true) ? true : false; // ha tartalmazza, akkor a map true-t ad vissza
    });
  }

  twoNoticeItemListEquals(niList1: NoticeItem[], niList2: NoticeItem[]): boolean {
    // ha checkForItemExistence-t mindkét irányba megcsináljuk, a 2 lista egyenlő kell legyen egymással
    if (this.checkForItemExistence(niList1, niList2).includes(false)) return false;
    if (this.checkForItemExistence(niList2, niList1).includes(false)) return false;
    return true;
  }
}

const store = new NoticeStore();
export default store;
