import UserAccountNo, {userAccountNoDefaultProps} from "../models/UserAccountNo";
import UserAccountNoApi from "./UserAccountNoApi";
import busyIndication from "../../../utils/busyIndication";

export class UserAccountNoStore{
    userAccountNos: UserAccountNo[] = []
    userAccountNo: UserAccountNo = {...userAccountNoDefaultProps}

    @busyIndication()
    async loadUserAccountNos() {
        this.userAccountNos = (await UserAccountNoApi.getAll()).map((i) => ({ ...i, deleting: false, inEdit: false }))
    }

    @busyIndication()
    async loadUserAccountNo(userAccountNoId: number) {
        this.userAccountNo = userAccountNoId > 1 ? await UserAccountNoApi.get(userAccountNoId) : { ...userAccountNoDefaultProps }
    }

    @busyIndication()
    async save(){
        try {
            if (this.userAccountNo.id > 0) {
                await UserAccountNoApi.update(this.userAccountNo);
             }
             else{
                 await UserAccountNoApi.create(this.userAccountNo);
             }
        } catch (error) {
            console.error(error);
        }
    }

    @busyIndication()
    async delete(userAccountNoId: number) {
        try {
            await UserAccountNoApi.delete(userAccountNoId)
            this.userAccountNos = this.userAccountNos.filter(b => b.id !== userAccountNoId)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    setDeleting(item: UserAccountNo){
        item.deleting = !item.deleting;
        this.userAccountNos = [...this.userAccountNos];
    }
}

const store = new UserAccountNoStore()
export default store