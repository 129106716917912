export default interface UserAccountNo {
    id: number;
    userId: number;
    userName: string;
    accNo: string;

    deleting: boolean;
  }

  export const userAccountNoDefaultProps: UserAccountNo = {
    id: 0,
    userId: 0,
    userName: "",
    accNo: "",

    deleting: false,
  };