import { render, staticRenderFns } from "./HeaderItem.vue?vue&type=template&id=210d413c&scoped=true&"
import script from "./HeaderItem.vue?vue&type=script&lang=ts&"
export * from "./HeaderItem.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderItem.vue?vue&type=style&index=0&id=210d413c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "210d413c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
