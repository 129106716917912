import AccountNumberAndClaimRelation from "@/modules/customsInvoice/models/AccountNumberAndClaimRelation";

export default class InvoiceBankRemitView {
    invoice=0;
    pmode=0;
    pmodeName="";
    invDate: Date | string = "";
    shipDate: Date | string = "";
    pterm: Date | string ="";
    user = 0; 
    userName="";
    status=0;
    statusName="";
    currency="";
    invoiceSt=0;
    storno=false;
    vat=0;
    total=0;
    netto=0;
    partner=0;
    partnerName="";
    car=0;
    carName="";
    bankAccount=0;
    bankAccountName="";
    bankName="";
    code="";
    remark="";
    inOut="";
    acceptor=0;
    invNo="";
    invCurrency="";
    contrPers=0;
    permitUser=0;
    preStatus=0;
    preStatusName="";
    contrStatus=0;
    contrStatusName="";
    permitStatus=0;
    permitStatusName="";
    currTotal=0;
    currTotalPositive=0;
    currVat=0;
    currNetto=0;
    exchangeRate=0;
    vatOrDuty="";
    bankDefrType="";

    customsInvoiceDetails: AccountNumberAndClaimRelation[] = [];
    
    //isSelected is for selecting the item for adding
    //selected is for dropdown info and styling
    isSelected= false;
    selected= false;
    vatOrDutyDetails=false;

    constructor(params?: Partial<InvoiceBankRemitView>) {
        const data = { ...params };
        Object.assign(this, data);
      }
}
  
export const invoiceBankRemitViewDefaultProps: InvoiceBankRemitView = {
    invoice: 1,
    pmode: 1,
    pmodeName: "",
    invDate:  "2000. 01. 01.",
    shipDate:  "2000. 01. 01.",
    pterm:  "2000. 01. 01.",
    user: 1,
    userName: "",
    status: 1,
    statusName: "",
    currency: "",
    invoiceSt: 1,
    storno: false,
    vat: 1,
    total: 1,
    netto: 1,
    partner: 1,
    partnerName: "",
    car: 1,
    carName: "",
    bankAccount: 1,
    bankAccountName: "",
    bankName: "",
    code: "",
    remark: "",
    inOut: "",
    acceptor: 1,
    invNo: "",
    invCurrency: "",
    contrPers: 1,
    permitUser: 1,
    preStatus: 1,
    preStatusName: "",
    contrStatus: 1,
    contrStatusName: "",
    permitStatus: 1,
    permitStatusName: "",
    currTotal: 1,
    currTotalPositive: 1,
    currVat: 1,
    currNetto: 1,
    exchangeRate: 1,
    vatOrDuty: "",
    bankDefrType: "",

    customsInvoiceDetails: [],

    isSelected: false,
    selected: false,
    vatOrDutyDetails: false,
}