





























































































































































































































































































































































































import Vue, { VNode } from "vue";
import store, { GeneralIngoingInvoiceStore } from "../services/GeneralIngoingInvoiceStore";
import Page from "../../../components/Page.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";
import GeneralIngoinInvoiceDisplay from "../models/GeneralIngoinInvoiceDisplay";
import GeneralIngoingInvoiceDetails from "./GeneralIngoingInvoiceDetails.vue";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import links from "../../../models/Links";
import IncomingInvoicePageFilter from "./IncomingInvoicePageFilter.vue";
import { GridColumnProps, GridRowProps } from "@progress/kendo-vue-grid";
import CustomsInvoiceDetails from "@/modules/customsInvoice/views/CustomsInvoiceDetails.vue";
import debounce from "debounce";
import eventBus from "../../../services/eventBus";
import InvoiceStockModifierGenisView from "../../incomingInvoice/invoiceStockModification/views/InvoiceStockModfierGenisView.vue";
import { FilteringModel } from "@/services/filtering";
import dayjs from "dayjs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import AccStatus, { accStatusDefaultProps } from "../models/AccStatus";
import { accountStatusDefaultProps } from "@/modules/notice/models/AccountStatus";
import DisplayStatus, { displayStatusDefaultProps } from "../models/DisplayStatus";
import WorkflowStatus, { workflowStatusDefaultProps } from "../models/WorkflowStatus";

interface Data {
  configuration: AppConfiguration;
  store: GeneralIngoingInvoiceStore;
  filterShow: boolean;
  currentPageNumber: number;
  currentPageSize: number;
  firstPageNumberButtonVisible: boolean;
  firstPageNumberButtonValue: number;
  secondPageNumberButtonVisible: boolean;
  secondPageNumberButtonValue: number;
  thirdPageNumberButtonVisible: boolean;
  thirdPageNumberButtonValue: number;
  fourthPageNumberButtonVisible: boolean;
  fourthPageNumberButtonValue: number;
  fifthPageNumberButtonVisible: boolean;
  fifthPageNumberButtonValue: number;
  isTableView: boolean;
  isListView: boolean;
  filterText: string;
  invDateStart: string;
  invDateEnd: string;
  accDateStart: string;
  accDateEnd: string;
  insDateStart: string;
  insDateEnd: string;
  invStatus: string;
  invPartner: string;
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];
  debounceTimer: any;
  jsonFilters: JSONScheme;
  defaultDisplayStatus: DisplayStatus;
  defaultWorkflowStatus: WorkflowStatus;
  defaultAccStatus: AccStatus;
}

interface Methods {
  createInvoice(): void;
  updateInvoice(item: GeneralIngoinInvoiceDisplay): void;
  setPageNumbers(): void;
  setCurrentPageNumber(pageNumber: number): void;
  decrementPageNumber(): void;
  incrementPageNumber(): void;
  setTableView(): void;
  setListView(): void;
  partnerEdit(item: GeneralIngoinInvoiceDisplay): void;
  filterTextChanged(): void;
  reloadByAccDate(): void;
  reloadByInvDate(): void;
  reload(): void;
  filterChange(e: { filter: CompositeFilterDescriptor }): void;
  dropdownFilterChange(e: { filter: CompositeFilterDescriptor }): void;
  sortChange(e: { sort: SortDescriptor[] }): void;
  filterTransform(filter: any): void;
  dropdownFilterTransform(field: string, value: number | null): void;
  convertToDate(dateString: string): string;
}

interface Computed {
  columns: GridColumnProps[];
  items: GeneralIngoinInvoiceDisplay[];
  footerText: string;
  maxPageNumber: number;
  invoices: GeneralIngoinInvoiceDisplay[];
  invoiceCount: number;
  accountStatuses: AccStatus[];
  workflowStatuses: WorkflowStatus[];
  displayStatuses: DisplayStatus[];
}

interface Field {
  operator: string;
  value: null | string;
  operators: string[];
}

interface JSONScheme {
  [fieldName: string]: Field;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    Page,
    GeneralIngoingInvoiceDetails,
    CustomsInvoiceDetails,
    IncomingInvoicePageFilter,
    InvoiceStockModifierGenisView,
    "k-dropdown-list": DropDownList
  },

  data(): Data {
    return {
      configuration,
      store,
      filterShow: false,
      currentPageNumber: 1,
      currentPageSize: 10,
      firstPageNumberButtonVisible: true,
      firstPageNumberButtonValue: 1,
      secondPageNumberButtonVisible: true,
      secondPageNumberButtonValue: 2,
      thirdPageNumberButtonVisible: true,
      thirdPageNumberButtonValue: 3,
      fourthPageNumberButtonVisible: true,
      fourthPageNumberButtonValue: 4,
      fifthPageNumberButtonVisible: true,
      fifthPageNumberButtonValue: 5,
      isTableView: false,
      isListView: true,
      filterText: "",
      invDateStart: "",
      invDateEnd: "",
      accDateStart: "",
      accDateEnd: "",
      insDateStart: "",
      insDateEnd: "",
      invStatus: "",
      invPartner: "",
      filter: {
        logic: "and",
        filters: [],
      },
      sort: [],
      debounceTimer: null,

      jsonFilters: {},
      defaultDisplayStatus: { ...displayStatusDefaultProps },
      defaultWorkflowStatus: { ...workflowStatusDefaultProps },
      defaultAccStatus: { ...accStatusDefaultProps },
    };
  },

  async beforeMount() {
    if (!configuration.operel) {
      await this.store.loadAccStatuses();
      await this.store.loadWFStatuses();
      await this.store.loadDisplayStatuses();
    }
    this.filterTransform(this.filter);

    await this.store.loadInvoices(this.currentPageSize, this.currentPageNumber, this.filterText, this.jsonFilters);
    eventBus.$on("incoming-invoice-pagefilter:search", this.reload);
  },

  mounted(){
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(new Date().getFullYear() - 2);

    (this.store.filterModel.invDate as any).min = oneYearAgo; //.toISOString().split('T')[0].replaceAll('-', '.');
  },

  beforeDestroy() {
    eventBus.$off("incoming-invoice-pagefilter:search", this.reload);
  },

  computed: {
    columns() {
      return [
        {
          field: "incomingInvoiceId",
          title: "GENI",
          headerClassName: "adjust_left",
          className: "adjust_left",
          cell: "geniTemplate",
        },
        {
          field: "incomingInvoiceInvoiceNo",
          title: "Számlaszám",
          cell: "incomingInvoiceInvoiceNoTemplate",
        },
        {
          field: "typeName",
          title: "Típus",
          headerClassName: "center_text",
          className: "center_text",
        },
        {
          field: "partnerName",
          title: "Partner",
          cell: "partnerTemplate",
        },
        {
          title: "Fizetési határidő",
          field: "incomingInvoicePayTerm",
          cell: "incomingInvoicePayTermTemplate",
          headerClassName: "center_text",
          className: "center_text",
        },

        {
          field: "displayStatus",
          title: "Státusz",
          headerClassName: "center_text",
          className: "center_text",
          cell: "displayStatusTemplate",
          filterCell: "displayStatusFilterTemplate",
        },

        {
          field: "workflowStatus",
          title: "Workflow státusz",
          cell: "actualStatusTemplate",
          headerClassName: "center_text",
          className: "center_text",
          width: 200,
          filterCell: "workflowStatusFilterTemplate",
        },

        {
          field: "accStatus",
          title: "Könyvelési státusz",
          cell: "accStatusTemplate",
          headerClassName: "center_text",
          className: "center_text",
          filterCell: "accStatusFilterTemplate",
          filterable: configuration.operel ? false : true,
        },
        {
          field: "incomingInvoiceCurrNetto",
          title: "Nettó",
          cell: "incomingInvoiceCurrNettoTemplate",
        },
        {
          field: "incomingInvoiceCurrVat",
          title: "ÁFA",
          cell: "incomingInvoiceCurrVatTemplate",
        },
        {
          field: "incomingInvoiceInvVat",
          title: "Szállítói ÁFA",
          cell: "incomingInvoiceInvVatTemplate",
        },
        {
          field: "incomingInvoiceCurrTotal",
          title: "Fizetendő",
          cell: "incomingInvoiceCurrTotalTemplate",
        },
        {
          width: 50,
          cell: "commandTemplate",
          filterable: false,
          sortable: false,
        },
      ];
    },

    items() {
      this.setPageNumbers();
      return this.store.invoices;
    },

    invoiceCount() {
      return this.invoices.length;
    },

    invoices() {
      // a filtertext a globalis filter
      if (this.filterText) {
        const filtered = this.store.invoices;
        return orderBy(filterBy(filtered, this.filter), this.sort);
      } else {
        return orderBy(filterBy(this.store.invoices, this.filter), this.sort);
      }
    },

    footerText() {
      return `${Math.max((this.currentPageNumber - 1) * this.currentPageSize + 1, 0)} - ${Math.max(
        this.currentPageNumber * this.currentPageSize,
        this.invoices.length
      )} / ${this.store.totalPageCount}`;
    },

    maxPageNumber() {
      return Math.ceil(this.invoices.length / this.currentPageSize);
    },

    accountStatuses() {
      const accStatuses = [{ ...accStatusDefaultProps }];
      this.store.accountStatuses.forEach((accst) => {
        accStatuses.push(accst);
      });

      return accStatuses;
    },

    displayStatuses() {
      const displayStatuses = [{ ...displayStatusDefaultProps }];
      this.store.displayStatuses.forEach((ds) => {
        displayStatuses.push(ds);
      });

      return displayStatuses;
    },

    workflowStatuses() {
      const wfStatuses = [{ ...workflowStatusDefaultProps }];
      this.store.workflowStatuses.forEach((wfs) => {
        wfStatuses.push(wfs);
      });

      return wfStatuses;
    },
  },

  created() {
    this.filterTextChanged = debounce.debounce(this.filterTextChanged, 500);
  },

  methods: {
    filterChange(e: { filter: CompositeFilterDescriptor }) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(async () => {
        this.filter = e.filter;

        this.filterTransform(this.filter);
        this.reload();

        this.setCurrentPageNumber(1);
      }, 1000);
    },

    dropdownFilterChange(e: any) {
      // console.log(e.target);
      this.dropdownFilterTransform(e.target.name, e.target.value.id);
      this.reload();
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    },

    filterTextChanged() {
      this.currentPageNumber = 1;
      this.reload();
    },

    createInvoice() {
      //this.$router.push(`/general-incoming-invoice/create`);
      if (this.configuration.invoiceFlow.enabled) {
        this.$router.push(`/general-incoming-invoice/flow/create/1`);
      }
      else {
        this.$router.push(`/general-incoming-invoice/create`);
      }
    },

    updateInvoice(item: GeneralIngoinInvoiceDisplay) {
      const path = configuration.invoiceFlow.enabled
        ? `/general-incoming-invoice/flow/edit/${item.incomingInvoice.id}/2`
        : `/general-incoming-invoice/edit/${item.incomingInvoice.partner}/${item.incomingInvoice.id}`;

      this.$router.push(path);
    },

    setPageNumbers() {
      if (this.currentPageNumber == 0) {
        return;
      }

      const maxPageNumber = Math.ceil(this.store.totalPageCount / this.currentPageSize);
      if (maxPageNumber <= 5) {
        this.firstPageNumberButtonVisible = false;
        this.secondPageNumberButtonVisible = false;
        this.thirdPageNumberButtonVisible = false;
        this.fourthPageNumberButtonVisible = false;
        this.fifthPageNumberButtonVisible = false;

        if (maxPageNumber >= 1) {
          this.firstPageNumberButtonVisible = true;
          this.firstPageNumberButtonValue = 1;
        }

        if (maxPageNumber >= 2) {
          this.secondPageNumberButtonVisible = true;
          this.secondPageNumberButtonValue = 2;
        }

        if (maxPageNumber >= 3) {
          this.thirdPageNumberButtonVisible = true;
          this.thirdPageNumberButtonValue = 3;
        }

        if (maxPageNumber >= 4) {
          this.fourthPageNumberButtonVisible = true;
          this.fourthPageNumberButtonValue = 4;
        }

        if (maxPageNumber == 5) {
          this.fifthPageNumberButtonVisible = true;
          this.fifthPageNumberButtonValue = 5;
        }
      } else {
        this.firstPageNumberButtonVisible = true;
        this.secondPageNumberButtonVisible = true;
        this.thirdPageNumberButtonVisible = true;
        this.fourthPageNumberButtonVisible = true;
        this.fifthPageNumberButtonVisible = true;

        if (maxPageNumber - this.currentPageNumber < 5) {
          this.firstPageNumberButtonValue = maxPageNumber - 4;
          this.secondPageNumberButtonValue = maxPageNumber - 3;
          this.thirdPageNumberButtonValue = maxPageNumber - 2;
          this.fourthPageNumberButtonValue = maxPageNumber - 1;
          this.fifthPageNumberButtonValue = maxPageNumber;
        } else {
          if (this.currentPageNumber < 5) {
            this.firstPageNumberButtonValue = 1;
            this.secondPageNumberButtonValue = 2;
            this.thirdPageNumberButtonValue = 3;
            this.fourthPageNumberButtonValue = 4;
            this.fifthPageNumberButtonValue = 5;
          } else {
            this.firstPageNumberButtonValue = this.currentPageNumber - 2;
            this.secondPageNumberButtonValue = this.currentPageNumber - 1;
            this.thirdPageNumberButtonValue = this.currentPageNumber;
            this.fourthPageNumberButtonValue = this.currentPageNumber + 1;
            this.fifthPageNumberButtonValue = this.currentPageNumber + 2;
          }
        }
      }
    },

    setCurrentPageNumber(pageNumber: number) {
      this.currentPageNumber = pageNumber;
    },

    decrementPageNumber() {
      if (this.currentPageNumber == 1) {
        return;
      }

      this.currentPageNumber--;
    },

    incrementPageNumber() {
      const maxPageNumber = Math.ceil(this.store.totalPageCount / this.currentPageSize);
      if (this.currentPageNumber == maxPageNumber) {
        return;
      }

      this.currentPageNumber++;
    },

    setTableView() {
      this.isTableView = true;
      this.isListView = false;

      this.filter = {
        logic: "and",
        filters: [],
      };
      this.sort = [];
    },

    setListView() {
      this.isTableView = false;
      this.isListView = true;

      this.filter = {
        logic: "and",
        filters: [],
      };
      this.sort = [];
    },

    partnerEdit(item: GeneralIngoinInvoiceDisplay) {
      links.activate.partnerProfile(item);
    },

    reloadByAccDate() {
      if (this.store.accDateStart && this.store.accDateEnd) {
        this.store
          .loadInvoices(this.currentPageSize, this.currentPageNumber, this.filterText, this.jsonFilters)
          .then(() => (this.currentPageNumber = 1));
      }
    },

    reloadByInvDate() {
      if (this.store.invDateStart && this.store.invDateEnd) {
        this.store
          .loadInvoices(this.currentPageSize, this.currentPageNumber, this.filterText, this.jsonFilters)
          .then(() => (this.currentPageNumber = 1));
      }
    },

    reload() {
      this.store.loadInvoices(this.currentPageSize, this.currentPageNumber, this.filterText, this.jsonFilters);
    },

    filterTransform(filter: any) {
      this.columns.map((col) => {
        // létrehozunk egy jsont a filterhez az összes oszlopból null value-val
        if (col.field) {
          this.jsonFilters[col.field] = {
            operator: "Contains",
            value: null,
            operators: ["Contains"],
          };

          // ahol van van value (vagyis létrehozza a kendo a filter field-et) ott az írja felül a null value-t
          if (filter) {
            filter.filters.map((el: { field: string; value: string }) => {
              if (el.field === col.field) {
                this.jsonFilters[col.field] = {
                  operator: "Contains",
                  value: el.value,
                  operators: ["Contains"],
                };
              }
            });
          }
        }
      });
    },

    dropdownFilterTransform(field, value) {
      this.columns.map((col) => {
        if (col.field === field) {
          if (value && value !== 1) {
            this.jsonFilters[col.field] = {
              operator: "Contains",
              value: value.toString(),
              operators: ["Contains"],
            };
          } else {
            this.jsonFilters[col.field] = {
              operator: "Contains",
              value: null,
              operators: ["Contains"],
            };
          }
        }
      });
    },

    convertToDate(dateString) {
      return dayjs(dateString).format("YYYY-MM-DD");
    },
  },

  watch: {
    invDateStart: function() {
      this.store.invDateStart = this.invDateStart;
      this.reloadByInvDate();
    },

    invDateEnd: function() {
      this.store.invDateEnd = this.invDateEnd;
      this.reloadByInvDate();
    },

    accDateStart: function() {
      this.store.accDateStart = this.accDateStart;
      this.reloadByAccDate();
    },

    accDateEnd: function() {
      this.store.accDateEnd = this.accDateEnd;
      this.reloadByAccDate();
    },

    currentPageNumber: function() {
      this.reload();
    },

    currentPageSize: function() {
      this.currentPageNumber = 1;
      this.reload();
    },

    filterShow: function() {
      if (!this.filterShow) {
        //this.store.clearFilter();
        //this.store.clearSort();
      }
    },
  },
});
