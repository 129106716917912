

























































































































import IconButton from "@/components/IconButton.vue";
import Vue from "vue";
import Fragment from "../../../components/Fragment.vue";
import InvoiceAcceptState from "../models/InvoiceAcceptState";
import store from "../services/InvoiceFlowStore";

interface Props {
  state: InvoiceAcceptState;
}

export default Vue.extend<{}, {}, {}, Props>({
  components: { Fragment, IconButton },
  props: {
    state: {
      type: Object as () => InvoiceAcceptState,
    },
  },

  data() {
    return {
      store,
      acceptStateVisible: true,
    };
  },

  computed: {
    hasData() {
      return this.state.controllRequests.length > 0 || this.state.approvalRequests.length > 0;
    },
  },
});
