var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{staticClass:"pt-0",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" Kimenő számla típusok ")])],1),_c('v-card-text',{staticClass:"exp-sw"},[_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns,"detail":"deletingTemplate","expand-field":'deleting'},scopedSlots:_vm._u([{key:"defaultTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.default),callback:function ($$v) {_vm.$set(props.dataItem, "default", $$v)},expression:"props.dataItem.default"}})],1)]}},{key:"isCarTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isCar),callback:function ($$v) {_vm.$set(props.dataItem, "isCar", $$v)},expression:"props.dataItem.isCar"}})],1)]}},{key:"isCarItemTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isCarItem),callback:function ($$v) {_vm.$set(props.dataItem, "isCarItem", $$v)},expression:"props.dataItem.isCarItem"}})],1)]}},{key:"isMoreGpartsTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isMoreGparts),callback:function ($$v) {_vm.$set(props.dataItem, "isMoreGparts", $$v)},expression:"props.dataItem.isMoreGparts"}})],1)]}},{key:"isNoGpartsTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isNoGparts),callback:function ($$v) {_vm.$set(props.dataItem, "isNoGparts", $$v)},expression:"props.dataItem.isNoGparts"}})],1)]}},{key:"isFixAssetsTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isFixAssets),callback:function ($$v) {_vm.$set(props.dataItem, "isFixAssets", $$v)},expression:"props.dataItem.isFixAssets"}})],1)]}},{key:"isStockFinTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isStockFin),callback:function ($$v) {_vm.$set(props.dataItem, "isStockFin", $$v)},expression:"props.dataItem.isStockFin"}})],1)]}},{key:"isPrintOwnerRunTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isPrintOwnerRun),callback:function ($$v) {_vm.$set(props.dataItem, "isPrintOwnerRun", $$v)},expression:"props.dataItem.isPrintOwnerRun"}})],1)]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateOutgoingInvoiceTypeEditModel(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])])],1)]}},{key:"deletingTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"py-4 justify-space-between d-flex yellowBg"},[_c('confirmation'),_c('div',{staticClass:"auth"},[_c('p',{staticClass:"conf"},[_vm._v(" Biztos hogy törli a következő elemet: "),_c('strong',[_vm._v(_vm._s(props.dataItem.name))]),_vm._v(" ? ")])]),_c('div',{staticClass:"btns"},[_c('v-btn',{staticClass:"mx-1 warning ",on:{"click":function($event){return _vm.confirmDelete(props.dataItem)}}},[_vm._v(" Megerősítés ")]),_c('v-btn',{staticClass:"mx-1",on:{"click":function($event){return _vm.cancelDelete(props.dataItem)}}},[_vm._v(" Mégsem ")])],1)],1)]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }