import GeneralIngoingInvoice, { generalIngoingInvoiceDefaultProps } from "./GeneralIngoinInvoice";
import GeneralIngoingInvoiceItemDisplay from "./GeneralIngoingInvoiceItemDisplay";

export default interface GeneralIngoinInvoiceDisplay {
  incomingInvoice: GeneralIngoingInvoice;
  partnerName: string;
  paymodeName: string;
  invoiceStatusName: string;
  invoicePreStatusName: string;
  insertDate: string;
  typeName: string;
  taxName: string;
  invoiceDate: string;
  paymentDate: string;
  partnerCity: string;
  partnerAddress: string;
  partnerTaxNo: string;
  items: GeneralIngoingInvoiceItemDisplay[];
  isDuty: boolean;
  isInRelation: boolean;
  isItemsVisible: boolean;
  expanded: boolean;
  isVatItemsVisible: boolean;
  isDutyItemsVisible: boolean;
  isAccepted: boolean;
  partnerCID: string;
  displayStatus: string;
  workflowStatus: string;
  workflowStatusId: number;
  isStockRelated: boolean;
  accStatus: string;
  isStockCorrection: boolean;
  openPrice: number;
  supplierOrders: Array<{
    deliveryNumber: string;
    orderNumber: string;
  }>;
}

export const generalIngoinInvoiceDisplayDefaultProps: GeneralIngoinInvoiceDisplay = {
  incomingInvoice: { ...generalIngoingInvoiceDefaultProps },
  partnerName: "",
  paymodeName: "",
  invoiceStatusName: "",
  invoicePreStatusName: "",
  insertDate: "",
  typeName: "",
  taxName: "",
  invoiceDate: "",
  paymentDate: "",
  partnerCity: "",
  partnerAddress: "",
  partnerTaxNo: "",
  items: [],
  isDuty: false,
  isInRelation: false,
  isItemsVisible: false,
  expanded: false,
  isVatItemsVisible: false,
  isDutyItemsVisible: false,
  partnerCID: "",
  isAccepted: false,
  displayStatus: "",
  workflowStatus: "",
  workflowStatusId: 1,
  isStockRelated: false,
  accStatus: "",
  isStockCorrection: false,
  openPrice: 0.0,
  supplierOrders: [],
};
