













































































































import Vue from "vue";
import store, { InvoiceTypeStore } from "../services/InvoiceTypeStore";
import Page from "../../../components/Page.vue";
import InvoiceType from "../models/InvoiceType";
import setAttribute from "../../../utils/dom";

interface Data {
  store: InvoiceTypeStore;
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "8");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    confirmation: confirmationTemplate,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadInvoiceTypes();
  },

  computed: {
    columns: () => [
      { field: "code", title: "Prefix" },
      { field: "typeCode", title: "Típuskód" },
      { field: "name", title: "Értesítő típus" },
      { field: "detailedType", title: "Fők. tétel típ." },
      { field: "accountCode", title: "Fők. szám" },
      {
        field: "default",
        title: "Alapértelmezett",
        cell: "defaultTemplate",
      },
      {
        field: "inAdvance",
        title: "Előleg?",
        cell: "inAdvanceTemplate",
      },
      { cell: "commandTemplate", width: "100px" },
    ],

    items() {
      return this.store.invoiceTypes;
    },
  },

  methods: {
    createInvoiceType() {
      this.$router.push(`/invoice-types/create`);
    },

    updateInvoiceType(item: InvoiceType) {
      this.$router.push(`/invoice-types/${item.id}/edit`);
    },

    deleteInvoiceType(item: InvoiceType) {
      this.store.setDeleting(item);
    },

    confirmDelete(item: InvoiceType) {
      item.deleting = false;
      this.store.deleteInvoiceType(item.id);
    },

    cancelDelete(item: InvoiceType) {
      item.deleting = false;
    },
  },
});
