export default interface CashDocumentStatus {
  id: number;
  name: string;
}

export const cashDocumentStatusDefaultProps: CashDocumentStatus = {
  id: 0,
  name: "",
};

export enum cashDocumentStatusEnum {
  Placehold = 1,
  UnderProcess = 2,
  Printed = 3,
  Paid = 4,
  MessedUp = 5
}