











































// Models
import Vue from "vue";
import store, { InvoiceFlowStore } from "../../services/InvoiceFlowStore";
import VatSummaryDisplay from "../../models/VatSummaryDisplay";

// Components
import Fragment from "@/components/Fragment.vue";
import FlowAccountingSummary from "./FlowAccountingSummary.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Data {
  store: InvoiceFlowStore;
}

interface Computed {
  accountItemDebit: any;
  accountItemDemand: any;
  accountItemTotal: any;
  vatColumns: any;
  vatItems: any;
}

export default Vue.extend<Data, {}, Computed, {}>({
  components: { Fragment, FlowAccountingSummary, MoneyDisplay },

  data: () => ({
    store,
  }),

  computed: {
    vatColumns: () => [
      { field: "name", title: "Áfa megnevezése" },
      { field: "vatDegree", title: "Áfa kulcs" },
      { field: "vatSum", title: "Összeg", cell: "vatSumTemplate", headerClassName: "right_text" },
      { width: 50 }
    ],

    vatItems() {
      /* const result: VatDivisionDisplay[] = [];
      for (const item of this.store.vatDivisionsDisplay) {
        const groupKey = item.taxDegree;
        let group = result.find((i) => i.taxDegree === groupKey);
        if (!group) {
          group = item
          result.push(group);
        }

        group.vat += item.vat;
      }
      return result; */

      const summary: VatSummaryDisplay[] = [];
      this.store.vatDivisionsDisplay.forEach((x) => {
        if (x.substractable) {
          if (summary.filter((y) => y.vatDegree == x.taxDegree).length > 0) {
            summary.filter((y) => y.vatDegree == x.taxDegree && y.id != x.id)[0].vatSum += x.vat;
          } else {
            summary.push({ id: x.id, name: x.taxName, vatDegree: x.taxDegree, vatSum: x.vat });
          }
        }
      });
      return summary;
    },

    accountItemDebit() {
      let total = 0;
      this.store.accountItems.forEach((i) => {
        if (i.total) total += i.total;
      });

      return total;
    },

    accountItemDemand() {
      let total = 0;
      this.store.accountItems.forEach((i) => {
        if (i.totalDemand) total += i.totalDemand;
      });

      total = total * -1;
      return total;
    },

    accountItemTotal() {
      return this.accountItemDebit + this.accountItemDemand;
    },
  },
});
