





























































































































































import IconButton from "@/components/IconButton.vue";
import MoneyInput from "@/components/MoneyInput.vue";

import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";
import { DownPaymentItem } from "../models";
import remitStore, {
  BankRemitStore,
} from "@/modules/bankRemit/services/BankRemitStore";
import configuration, { AppConfiguration } from "@/models/Configuration";
import taxStore, { TaxStore } from "@/modules/taxes/services/TaxStore";
import eventBus from "@/services/eventBus";
import { InvoiceItemType } from "@/modules/invoiceItems/models";
import {
  TaxAndVatItemItemDefaultProps,
  Taxes,
} from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";
import * as InvItemsApi from "@/modules/invoiceItems/services/api";
import { taxDefaultProps } from "@/modules/generalIngoingInvoice/models/Tax";

interface Data {
  store: DownPaymentStore;
  remitStore: BankRemitStore;
  taxStore: TaxStore;

  configuration: AppConfiguration;
}

export default Vue.extend({
  components: { IconButton, MoneyInput },

  data: (): Data => ({
    store,
    remitStore,
    taxStore,

    configuration,
  }),

  methods: {
    isRoundingItem(item: DownPaymentItem){
      return  item.productName === InvoiceItemType.Rounding
    },

    async createRoundingItem() {
      const prevRoundingItem = this.store.downPayment.invoiceItems.find(
        (x) => x.productName == InvoiceItemType.Rounding
      );
      if (prevRoundingItem) {
        this.store.downPayment.invoiceItems = this.store.downPayment.invoiceItems.filter(
          (i) => i.productName !== InvoiceItemType.Rounding
        );
      }

      const items = this.store.downPayment.invoiceItems.filter(
        (x) => !x.isRoundingItem
      );

      const headNetto = this.store.downPayment.netto;
      const headVat = this.store.downPayment.vat;

      const tax =
        this.store.taxes.find((x) => x.id == this.store.downPayment.taxID)
          ?.degree ?? 27;

      const headTotal = headNetto * (1+(tax/100))

      const itemsNetto = items.reduce((x, y) => {
        return x + y.netto;
      }, 0);
      const itemsBrutto = items.reduce((x, y) => {
        return x + y.brutto;
      }, 0);
      const itemsVat = items.reduce((x, y) => {
        return x + y.vat;
      }, 0);

      const netto = (headNetto - itemsNetto) !== 0 ? headNetto - itemsNetto : 0;
      const brutto = headTotal - itemsBrutto;
      const vat = headVat - itemsVat;

      if (headTotal === itemsBrutto && headNetto === itemsNetto && headVat === itemsVat) return;
      if (Math.abs(brutto) > configuration.sysParams.geniMaxRound) {
        eventBus.$emit(
          "warn",
          "A számla fej és a tételek Bruttó különbsége meghaladja a maximum kerekítési értéket."
        );
        return;
      }

      this.store.downPayment.invoiceItems.push(
        new DownPaymentItem({
          isRoundingItem: true,
          productName: InvoiceItemType.Rounding,
          serviceName: InvoiceItemType.Rounding,
          currency: this.store.downPayment.currency,
          netto: netto,
          brutto: brutto,
          vat: vat,
          productID: 1,
          unit: {
            id: 1,
            name: "-",
          },
          tax: this.store.taxes.find(x=>x.id === Taxes.TVHK) ?? taxDefaultProps,
          quantity: 1,
        })
      );
    },

    addNewItem() {
      const taxID = taxStore.taxes.find((x) => x.degree === 27);

      const item = new DownPaymentItem({
          invoiceID: this.store.downPayment.id,
          productID: 1,
          gpartsID: 1,
          isFreeWordItem: true,
          productName:
            "Előleg tétel " +
            (this.store.downPayment.invoiceItems
              ? this.store.downPayment.invoiceItems.length + 1
              : 1),
          serviceName:
            "Előleg tétel " +
            (this.store.downPayment.invoiceItems
              ? this.store.downPayment.invoiceItems.length + 1
              : 1),
          currency: this.store.downPayment.currency,
          exchangeRate: 1,
          netto: this.store.downPayment.netto,
          brutto: this.store.downPayment.total,
          quantity: 1,
          tax: taxID,
          unit: {id: 2, name: "DB"},
          taxAndVatItem: this.store.taxAndVatItems.find(x => x.id == taxID?.id && x.vatItemID == 1),
        });

        item.vat = item.netto * (item.tax?.degree ?? 0) / 100;
        item.brutto = item.netto + item.vat;

      this.store.downPayment.invoiceItems.push(item);
    },

    currencyChanged(e: string) {
      this.store.downPayment.currency = e;
    },

    removeItem(item: DownPaymentItem) {
      const index = this.store.downPayment.invoiceItems.indexOf(item);
      if (index > -1) this.store.downPayment.invoiceItems.splice(index, 1);
    },

    itemChanged(item: DownPaymentItem)
    {
      if (item.taxAndVatItem && item.taxAndVatItem.id > 1){
        item.tax = store.taxes.find(x => x.id == item.taxAndVatItem.id);
        item.vatItem = item.taxAndVatItem.vatItemID;
      }

      if (item.isRoundingItem) return;
      item.vat = item.netto * ((item.tax?.degree ?? 0) / 100);
      item.brutto = item.netto + item.vat;
    }
  },
});
