import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import PaymentMode from '../models/PaymentMode'
import InvoiceType from '../models/InvoiceType'
import Depot from '../models/Depot'
import InvGroup from '../models/InvGroup'
import Result from '../models/Result'

export default class ParameterizedAccountReportApi {
    static async getPModes(): Promise<PaymentMode[]> {
        const response = await http
            .get<ApiResponse<PaymentMode[]>>(`payment-mode`)
        return handleApiResponse(response)
    }

    static async getInvoiceTypes(): Promise<InvoiceType[]> {
        const response = await http
            .get<ApiResponse<InvoiceType[]>>(`incoming-invoice-type`)
        return handleApiResponse(response)
    }

    static async getBehajtKtsVisible(): Promise<boolean> {
        const response = await http
            .get<ApiResponse<boolean>>(`crystal/behajt-kts-visible`)
        return handleApiResponse(response)
    }

    static async getDepots(): Promise<Depot[]> {
        const response = await http
            .get<ApiResponse<Depot[]>>(`depot`)
        return handleApiResponse(response)
    }

    static async getInvGroups(): Promise<InvGroup[]> {
        const response = await http
            .get<ApiResponse<InvGroup[]>>(`param-account-report/inv-group`)
        return handleApiResponse(response)
    }

    static async getCrystalReport(request: any): Promise<Result> {
        const response = await http
            .post<ApiResponse<Result>>(`param-account-report/report`, request)
        return handleApiResponse(response)
    }
}