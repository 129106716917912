var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" Könyvelési dimenziókód típusok ")])],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.store.dimCodeTypes,"columns":_vm.columns,"edit-field":'editing',"scrollable":"none"},on:{"itemchange":_vm.itemChange},scopedSlots:_vm._u([{key:"deletedTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.editing},on:{"change":function (value) { return _vm.itemChange({
                  dataItem: props.dataItem,
                  value: Boolean(value),
                  field: 'deleted',
                }); }},model:{value:(props.dataItem.deleted),callback:function ($$v) {_vm.$set(props.dataItem, "deleted", $$v)},expression:"props.dataItem.deleted"}})],1)]}},{key:"commandTemplate",fn:function(ref){
                var props = ref.props;
return [_c('td',[(!props.dataItem.editing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateDimCodeType(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.saveEdit(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok mentése")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }