import ApiResponse from "@/models/ApiResponse";
import http, { checkResponse, handleApiResponse } from "@/services/http";

export class CurrentAccountApi {
  static saveArrangement(payLoad: any): Promise<boolean> {
    return http.post<ApiResponse<boolean>>(`currentaccount/arrangement/save-arrangement`, { payLoad }).then(handleApiResponse);
  }

  static checkAccDate(accDate: string): Promise<any> {
    return http.get<ApiResponse<any>>(`currentaccount/arrangement/check-acc-date/${accDate}`).then(handleApiResponse);
  }
}