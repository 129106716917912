





























import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import IconButton from "@/components/IconButton.vue";
import BankExtractImportItems from "../components/BankExtractImportItems.vue";

import Vue from "vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import store, { BankExtractImportStore } from "../services/BankExtractImportStore";
import BankExtractImportItem from "../models/BankExtractImportItem";

interface Data {
  store: BankExtractImportStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { ApiSearchPage, IconButton, BankExtractImportItems },

  data: () => ({
    store,
  }),

  methods: {
    create() {
      this.$router.push(`bank-extract-import/create`);
    },

    edit(item: BankExtractImportItem) {
      this.$router.push(`bank-extract-import/edit/${item.id}`);
    },
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      { cell: "detailsButton", filterable: false, sortable: false },
      { field: "bankName", title: "Bank neve" },
      { field: "bankAccountNo", title: "Bankszámla" },
      { field: "invoiceNo", title: "Számlaszám" },
      {
        field: "status",
        title: "Állapot",
        type: ColumnType.Center,
        cell: "status",
      },
      { field: "fileName", title: "File neve" },
      {
        field: "extractDate",
        title: "Kivonat dátuma",
        type: ColumnType.Date,
      },
      {
        field: "importDate",
        title: "Bedolgozás dátuma",
        type: ColumnType.Date,
      },
      {
        field: "openBal",
        title: "Nyitó egyenleg",
        type: ColumnType.Money,
      },
      {
        field: "closeBal",
        title: "Záró egyenleg",
        type: ColumnType.Money,
      },
      { field: "currency", title: "Pénznem" },
      { cell: "command", filterable: false, sortable: false },
    ],
  },
});
