































import Vue from "vue";
import HeaderItem from "@/components/HeaderItem.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import store, { BankStatementStore } from "../services/BankStatementStore";

interface Data {
  store: BankStatementStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    HeaderItem,
    MoneyDisplay,
  },

  data() {
    return {
      store,
    };
  },

  computed: {
    statusName() {
      return this.store.bankStatuses.find(x => x.id == this.store.bankStatement.status)?.name ?? "";
    },
    accountingStatusName() {
      return this.store.bankAccountStatuses.find(x => x.id == this.store.bankStatement.status)?.name ?? "";
    },
    bankName() {
     return this.store.partBanks.find(
          (x) => x.id == this.store.bankStatement.bank
        )?.bank ?? "";
    }
  }
});
