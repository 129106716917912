import eventBus from "@/services/eventBus";
import http, { handleApiResponse, setConnection } from "./http";
import Vue from "vue";
import ApiResponse from "@/models/ApiResponse";
import LoginResult from "@/modules/login/models/LoginResult";
import configuration, { loadConfiguration } from "@/models/Configuration";
import PasswordCheckResult from "@/modules/login/models/PasswordCheckResult";
import MenuPermission from "@/models/MenuPermission";
import router from '../router';

const TOKEN_KEY = "accessToken";

function delay(milliSeconds: number) {
  return new Promise((res) => setTimeout(() => res(milliSeconds), milliSeconds));
}

export class Authentication {
  token = "";
  isLoggedIn = false;
  isVerified = false;
  userName = "";
  userID = 1;
  operelToken = "";
  passwordChangeRequired = false;
  menuPermissions: MenuPermission[] = [];

  async setToken(token: string, userName: string, userID: number) {
    this.token = token;
    this.userName = userName;
    this.userID = userID;

    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    window.localStorage.setItem(TOKEN_KEY, token);

    this.isLoggedIn = true;
    await loadConfiguration();

    if (!configuration.operel) {
      await this.getMenuPermissions();
    }

    eventBus.$emit("loggedIn");
  }

  logout() {
    window.localStorage.removeItem(TOKEN_KEY);
    delete http.defaults.headers.common["Authorization"];
    setConnection("");

    this.token = "";
    this.userName = "";
    this.userID = 1;
    this.isLoggedIn = false;

    router.push({ name: "Login" });
  }

  async authorize() {
    if (configuration.operel) {
      return this.operelAuth();
    } else {
      return this.verifyToken();
    }
  }

  async passwordCheck() {
    this.passwordChangeRequired = await http
      .get<ApiResponse<PasswordCheckResult>>(`users/password-check`)
      .then(handleApiResponse)
      .then((x) => {
        return x.passwordChangeRequired;
      });
  }

  async getMenuPermissions() {
    await http
      .get<ApiResponse<MenuPermission[]>>(`authorization/menu`)
      .then(handleApiResponse)
      .then((x) => {
        this.menuPermissions = x;
      });
  }

  async verifyToken() {
    try {
      const token = window.localStorage.getItem(TOKEN_KEY);

      if (!token) {
        this.isVerified = true;
        return;
      }

      this.token = token;
      const result = await http
        .post<ApiResponse<LoginResult>>(`login/verify`, { token: this.token })
        .then(handleApiResponse);

      if (result.connectionID) {
        setConnection(result.connectionID);
      }

      if (!result.success) {
        this.logout();
      } else {
        await this.setToken(this.token, result.userName, result.userID);
      }
    } catch (err) {
      this.logout();
    } finally {
      this.isVerified = true;
    }
  }

  private async operelAuth() {
    const uri = process.env.VUE_APP_OPEREL_AUTH_URL;
    if (!uri) {
      throw new Error("Operel authentikációs url nincs megadva!");
    }

    const operelResponse = await fetch(uri, { credentials: "include" });
    const { token: operelToken } = await operelResponse.json();

    this.operelToken = operelToken;
    await delay(1500);

    const response = await http.get<ApiResponse<LoginResult>>(`login/operel`, {
      params: {
        token: operelToken,
      },
    });

    const { token, success, userName, userID, connectionID } = response.data.data;

    if (!success) {
      throw new Error("Sikertelen operel authentikáció!");
    }

    setConnection(connectionID);
    await this.setToken(token, userName, userID);
  }
}

const auth = Vue.observable(new Authentication());

export function getOperelToken() {
  return auth.operelToken;
}

export default auth;
