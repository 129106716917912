































import Vue from "vue";
import dayjs from "dayjs";

import * as api from "../services/api";
import DateInput from "@/components/DateInput.vue";
import IconButton from "@/components/IconButton.vue";
import { ConsumptionGroup, GroupedConsumptions } from "../models";
import store from "@/modules/invoiceItems/services/store";
import eventBus from "@/services/eventBus";
import ConsignerConsumptionTable from "./ConsignerConsumptionTable.vue";

interface Data {
  searchText: string;
  minDate: Date;
  maxDate: Date;
  consumptions: GroupedConsumptions[];
}

interface Props {
  partnerID: number;
  currency: string;
}

interface Methods {
  search(e: KeyboardEvent): Promise<any>;
  attachToInvoice(groups: ConsumptionGroup[]): Promise<any>;
  clear(): void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: {
    DateInput,
    IconButton,
    ConsignerConsumptionTable,
  },

  data: () => ({
    searchText: "",
    minDate: dayjs()
      .add(-1, "years")
      .toDate(),
    maxDate: dayjs().toDate(),
    consumptions: [],
  }),

  computed: {
    hasData() {
      return this.consumptions.length > 0;
    },
  },

  props: {
    partnerID: {
      type: Number,
    },

    currency: {
      type: String,
    },
  },

  methods: {
    async search(e: KeyboardEvent) {
      e.preventDefault();
      if (e.key !== "Enter") {
        return;
      }

      const data = await api.groupedConsignerConsumptionSearch(
        this.partnerID,
        this.minDate,
        this.maxDate,
        this.searchText,
        this.$props.currency
      );

      this.consumptions = data.map((i) => ({
        ...i,
        groups: i.groups.map((g) => ({ ...g, pending: false })),
      }));
    },

    clear() {
      this.consumptions = [];
    },

    async attachToInvoice(groups: ConsumptionGroup[]) {
      let itemAdded = false;

      for (const group of groups) {
        group.pending = true;

        for (const item of group.consumptions.filter((i) => !i.attachedToInvoice)) {
          await store.addConsignerConsumptionItem(item);
          itemAdded = true;
        }
      }

      if (!itemAdded) {
        eventBus.$emit("error", "Nincs számlához rendelhető fogyás tétel!");
        return;
      }

      eventBus.$emit("success", "Bizományos fogyás kapcsolat létrehozva!");
      await store.reloadItems();
      this.clear();

      groups.forEach((g) => !g.pending);
    },
  },
});
