





















import Vue from 'vue';
import Fragment from '@/components/Fragment.vue'
import { InvoiceItem, SupplierComplaintDetails, supplierComplaintDetailsDefaultProps } from '../models'
import MoneyDisplay from '@/components/MoneyDisplay.vue';

interface Data {
    complaintItem: SupplierComplaintDetails;
}

interface Methods{
    selectDebit(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
    components: {
        MoneyDisplay,
    },

    props: {
        item: Object as () => InvoiceItem,
        currency: String,
    },

    data() {
        return {
            complaintItem: { ...supplierComplaintDetailsDefaultProps },
        }
    },

    mounted() {
        this.selectDebit();
    },

    methods: {
       selectDebit(){
            if (this.$props.item.joinedSupplierComplaintDetails != null && this.$props.item.joinedSupplierComplaintDetails != undefined && this.$props.item.joinedSupplierComplaintDetails.id > 1) {
                this.complaintItem = { ...this.$props.item.joinedSupplierComplaintDetails };
                console.log("1");
                
            }
            else {
                this.complaintItem = { ...this.$props.item.supplierComplaintDetails };
                console.log("2");
                
            }
       }
    },
})
