var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApiSearchPageForLedger',_vm._b({attrs:{"url":'ledger/transactions',"columns":_vm.columns,"search":false,"delay":1000,"refresh":true,"resetSort":false,"resetFilter":false,"detailsField":"selected","title":'Főkönyvi tranzakciók',"name":'ledger-transactions',"transStats":_vm.transStats,"currencies":_vm.currencies,"bookTypes":_vm.bookTypes,"create":'Új tranzakció'},on:{"create":_vm.create},scopedSlots:_vm._u([{key:"detailTemplate",fn:function(ref){
var item = ref.item;
return [_c('CheckBox',{staticStyle:{"width":"32px !important"},attrs:{"selected":item.selectedStatus},on:{"change":function($event){return _vm.emitSetSelected(item)}}}),_c('v-btn',{staticClass:"ml-1",attrs:{"outlined":"","color":"primary"},on:{"click":function () { item.selected = !item.selected }}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa fa-bars")])],1)]}},{key:"stornoTemplate",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticStyle:{"color":"var(--primaryColor)"},attrs:{"small":""}},[_vm._v(" "+_vm._s(item.storno ? 'fa fa-check' : 'fa fa-times')+" ")])]}},{key:"editTemplate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1",attrs:{"outlined":"","color":"primary"},on:{"click":function () { _vm.$router.push(("/transactions/edit/" + (item.id))) }}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa fa-edit")])],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"background":"#f2f2f2"}},[_c('div',{staticClass:"px-3 pb-1 pt-2"},[_c('TransactionItems',{attrs:{"transaction":item}})],1)])]}}])},'ApiSearchPageForLedger',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }