import SerCar, { serCarDefaultProps } from "../models/SerCar";
import SerCarApi from "./SerCarApi";
import busyIndication from "@/utils/busyIndication";

export class SerCarStore {
  serCars: SerCar[] = [];
  serCar: SerCar = { ...serCarDefaultProps };
  showDeleted = false;

  @busyIndication()
  async loadSerCars() {
    this.serCars = await SerCarApi.getAll();
  }

  async loadSerCarsWithoutBusy() {
    this.serCars = await SerCarApi.getAll();
  }

  async loadSerCarRegNumsBy(name: string) {
    this.serCars = await SerCarApi.getAllRegNumsBy(name, this.showDeleted);
  }

  async loadSerCar(serCarId: number) {
    this.serCar = serCarId > 0 ? await SerCarApi.get(serCarId) : { ...serCarDefaultProps };
  }
}

const store = new SerCarStore();
export default store;
