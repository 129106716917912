


















































































































































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import statStore, { InvoiceFlowStatusStore } from "@/modules/invoiceFlowStatus/services/InvoiceFlowStatusStore";
import relStore, { FlowDocumentRelationStore } from "@/modules/flowDocumentRelation/services/FlowDocumentRelationStore";
import dtStore, { DocumentTemplateStore } from "../../documentTemplate/services/DocumentTemplateStore";
import FlowDocumentRelation from "@/modules/flowDocumentRelation/models/FlowDocumentRelation";
import DocumentTemplateApi from "@/modules/documentTemplate/services/DocumentTemplateApi";
import FlowDocumentRelationApi from "@/modules/flowDocumentRelation/services/FlowDocumentRelationApi";
import eventBus from "@/services/eventBus";
import permStore, { PermissionStore, InvoicePermissions } from "@/services/permissions";
import http from "@/services/http";

interface Data {
  permStore: PermissionStore;
  store: InvoiceFlowStore;
  dtStore: DocumentTemplateStore;
  relStore: FlowDocumentRelationStore;
  statStore: InvoiceFlowStatusStore;
}

interface Methods {
  templateChanged(template: string, init?: boolean): void;
  showFile(relation: FlowDocumentRelation): void;
  deleteFile(relation: FlowDocumentRelation): void;
  hasPermission(statusId: number): boolean;
  uploadFile(relation: FlowDocumentRelation): void;
  beforeDownload(relation: FlowDocumentRelation): void;
  downloadFile(link: string): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  data() {
    return {
      permStore,
      store,
      dtStore,
      relStore,
      statStore,
    };
  },

  created() {
    eventBus.$on("flow-doc-template:updated", this.templateChanged);
  },

  beforeDestroy() {
    eventBus.$off("flow-doc-template:updated", this.templateChanged);
  },

  async beforeMount() {
    await this.statStore.loadInvoiceFlowStatuses();
    await this.dtStore.loadDocumentTemplateRelation(Number(this.$route.params.invoiceId));
    const templateId = this.dtStore.documentTemplateRelation?.docTemplateId;
    if (templateId <= 1) {
      this.templateChanged(this.dtStore.template);
    } else {
      await this.relStore.loadFlowDocumentRelationsByHeadAndInvoiceId(templateId, this.store.invoice.id);
    }
  },

  methods: {
    async templateChanged(template) {
      if (!template || template == "") return;

      const templateId = this.dtStore.documentTemplates.find((x: any) => x.name == template)?.id ?? 0;
      if (templateId == 0) return;

      await this.relStore.loadFlowDocumentRelationsByHeadAndInvoiceId(templateId, this.store.invoice.id);
    },

    async showFile(relation) {
      relation.uploading = !relation.uploading;
    },

    async deleteFile(relation) {
      if (!this.hasPermission(relation.statusId)) {
        eventBus.$emit("error", "Nincs jogosultsága az adott dokumentum módosításához!");
        return;
      }

      if (relation.link == "" || !relation.hasFile || relation.fileName == "") return;

      await DocumentTemplateApi.deletePreviousDocument(relation.documentId).then(() => {
        relation.link = "";
        relation.hasFile = false;
        relation.fileName = "";
      });
    },

    hasPermission(statusId) {
      // if (statusId != this.store.step) return false;
      // iktatás
      if (statusId == 2 && !this.permStore.has([InvoicePermissions.Recording])) return false;
      // pénzü. r.
      else if (statusId == 3 && !this.permStore.has([InvoicePermissions.FinancialRecording])) return false;
      // készletkapcs
      else if (statusId == 4 && !this.permStore.hasInvoice([InvoicePermissions.StockRelationRecording])) return false;
      // ell. jóvh.
      else if (
        statusId == 5 &&
        !this.permStore.hasInvoice([InvoicePermissions.Controlling, InvoicePermissions.Accepting])
      )
        return false;
      // könyvelés
      else if (statusId == 6 && !this.permStore.hasInvoice([InvoicePermissions.Accounting])) return false;

      return true;
    },

    async uploadFile(relation) {
      if (!this.hasPermission(relation.statusId)) {
        eventBus.$emit("error", "Nincs jogosultsága az adott dokumentum módosításához!");
        return;
      }

      const file = (this.$refs.file as any).find((x: any) => {
        if (x?.id == `file-${relation.statusId}-${relation.id}-${relation.documentTypeId}`) return true;
        else return false;
      }).$refs.input.files[0];
      if (!file) return;

      // delete previous file
      if (relation.hasFile) this.deleteFile(relation);

      await this.dtStore
        .uploadDocumentTemplateItem(this.dtStore.documentTemplateRelation.id, relation.documentTypeId, file)
        .then((result) => {
          relation.documentId = result.id;
          relation.link = FlowDocumentRelationApi.generateDocumentDownLoadLink(result.id);
          relation.hasFile = relation.link != "";
          relation.fileName = result.fileName + result.fileExtension;
        });
    },

    beforeDownload(relation) {
      if (!relation.hasFile || !this.store.isEditingEnabled || this.store.invoice.id == 0) return;
      this.downloadFile(relation.link);
    },

    downloadFile(link) {
      window.open(link + `?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    },
  },
});
