export default interface GeneralIngoingInvoice {
    id: number;
    /**
     * Partner id
     */
    partner: number;
    /**
     * bankAccount Id (partbank)
     */
    bankAccount: number;
    /**
     * A számla típusa
     */
    type: number;
    /**A számla fajtája */
    group: number;
    prcar: number;
    car: number;
    invoiceNo: string;
    invoiceDate: string;
    payTerm: string;
    paymode: number;
    netto: number;
    vat: number;
    /**Kerekítési eltérés a fizetendő összegben */
    roundDiffTotal: number;
    currency: string;
    shipDate: null | string;
    /**Költséghely */
    accGroup: number;
    status: number;
    acceptor: number;
    accepDate: null | string;
    arrivDate: string;
    /**Jovahagyas id */
    preSzam: number;
    payable: boolean;
    userID: number;
    total: number;
    insertDate: string;
    insertTime: string;
    preStatus: number;
    accStatus: number;
    inVatCount: boolean;
    /**Normál számla/Számlahelyettesítő */
    isNormal: boolean;
    remark: string;
    /**Helyesbítő számla? (+/-) */
    rectify: boolean;
    /**Helyesbített számla száma (mminigeninvoice) */
    invoiceRect: null | number;
    /**
     * Előlegszámla? 
     */
    dpaym: boolean;
    /**Ebbe(n) a számlába(n) beszámít már korábban számlázott előleget */
    dpaymIncl: boolean;
    /**Beszámított előleg nélkül a számla nettó értéke */
    nettoWDpaym: null | number;
    /**Beszámított előleg nélkül a számla ÁFA értéke */
    vatWDpaym: null | number;
    /**Különbözeti ÁFA elszámolás alá esik-e? */
    diffTax: boolean;
    regDate: string;
    refuseDate: null | string;
    vatStatus: number;
    partnerRel: number;
    specTax: boolean;
    latecomer: boolean;
    subType: number;
    specTaxType: number;
    shipDateC: boolean;
    isSeparCont: boolean;
    divitem: number;
    isRegTax: boolean;
    orchiId: string;
    contrPers: number;
    contrStat: number;
    contrDate: null | string;
    contrTime: null | string;
    contrNo: string;
    acceptNo: string;
    tax: number;
    isVatScaling: boolean;
    invCurrency: string;
    currNetto: number;
    currTotal: number;
    currVat: number;
    invVat: number;
    exchangeRate: number;
    rateDate: string;
    fixassets: boolean;
    accGroupTempl: number;
    accDate: null | string;
    accDateStart: null | string;
    acceptorWf: number;
    postCalc: number;
    isCashAccounting: boolean;
    permitStatus: number;
    permitUser: number;
    permitWf: number;
    permittingUser: number;
    permitNo: string;
    taxType: number;
    isPerfCertifReq: boolean;
    partnerTax: number;
    /**A számla szolgáltatás? (egyébként termék értékesítés) */
    isServ: boolean;
    isPayed: boolean;
    isAccepted: boolean;
    payDate: null | string;
    vatDate: string;
    preDownPayment: boolean;
    preDebitPayment: boolean;
    detailsOfCharges: number;
    currDiscount: null | number;
    discPterm: null | string;

    isProjectItem: boolean;
    isPublicProjectItem: boolean;
    selectedProjectId: number;
    selectedProjectDefaultStoreId: number;
    letterOfCredit: number;
    rectVatDate: Date | string | null;
    isDifferentialVat: boolean;
    bankDefrType: string;
    partnerEuTax: string | null;
    filingNumber: string;
}

export const generalIngoingInvoiceDefaultProps: GeneralIngoingInvoice = {
    id: 0,
    partner: 1,
    bankAccount: 1,
    type: 1,
    group: 1,
    prcar: 1,
    car: 1,
    invoiceNo: '',
    invoiceDate: '',
    payTerm: '',
    paymode: 1,
    netto: 0,
    vat: 0,
    roundDiffTotal: 0,
    currency: '',
    shipDate: null,
    accGroup: 1,
    status: 1,
    acceptor: 1,
    accepDate: null,
    arrivDate: '',
    preSzam: 1,
    payable: true,
    userID: 1,
    total: 0,
    insertDate: '',
    insertTime: '',
    preStatus: 1,
    accStatus: 1,
    inVatCount: true,
    isNormal: true,
    remark: '',
    rectify: false,
    invoiceRect: null,
    dpaym: false,
    dpaymIncl: false,
    nettoWDpaym: null,
    vatWDpaym: null,
    diffTax: false,
    regDate: '',
    refuseDate: null,
    vatStatus: 1,
    partnerRel: 1,
    specTax: false,
    latecomer: false,
    subType: 1,
    specTaxType: 1,
    shipDateC: false,
    isSeparCont: false,
    divitem: 1,
    isRegTax: false,
    orchiId: '',
    contrPers: 1,
    contrStat: 1,
    contrDate: null,
    contrTime: null,
    contrNo: '',
    acceptNo: '',
    tax: 1,
    isVatScaling: false,
    invCurrency: 'HUF',
    currNetto: 0,
    currTotal: 0,
    currVat: 0,
    invVat: 0,
    exchangeRate: 1,
    rateDate: new Date().toJSON(),
    fixassets: false,
    accGroupTempl: 1,
    accDate: null,
    accDateStart: null,
    acceptorWf: 1,
    postCalc: 1,
    isCashAccounting: false,
    permitStatus: 1,
    permitUser: 1,
    permitWf: 1,
    permittingUser: 1,
    permitNo: '',
    taxType: 2, //2 - Magyar adótörvény
    isPerfCertifReq: false,
    partnerTax: 1,
    isServ: false,
    isPayed: false,
    payDate: null,
    vatDate: '',
    isProjectItem: false,
    isPublicProjectItem: false,
    selectedProjectId: 1,
    selectedProjectDefaultStoreId: 1,
    isAccepted: false,
    preDownPayment: false,
    preDebitPayment: false,
    detailsOfCharges: 1,
    currDiscount: null,
    discPterm: null,
    letterOfCredit: 1,
    rectVatDate: null,
    isDifferentialVat: false,
    bankDefrType: "",
    partnerEuTax: "",
    filingNumber: "",
}