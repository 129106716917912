


















































































































































































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, { TaxStore } from "../services/TaxStore";
import Tax, { rules } from "../models/Tax";
import { ValidationRules } from "@/models/Validation";
import officeStore, { OfficeStore } from "../services/OfficeStore";
import Office from "@/modules/taxes/models/Office";
import { TaxType } from "@/modules/taxes/models/Tax";
import DateInput from "@/components/DateInput.vue";
import VatBreakdowns from "../components/VatBreakdowns.vue";



interface Data {
  store: TaxStore;
  opened: boolean;
  valid: boolean;
  rules: ValidationRules;
  searchText: string;
  officeStore: OfficeStore;
  selectedOffice: Office | undefined;
  taxType: TaxType | undefined;
  selectedTaxType: TaxType | undefined;
  expand: boolean;
  vatItemSend: boolean;
  panel: number;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    DateInput,
    VatBreakdowns,
  },

  computed: {},

  data(): Data {
    return {
      store,
      rules,
      valid: true,
      opened: false,
      searchText: "",
      officeStore,
      selectedOffice: undefined,
      taxType: undefined,
      selectedTaxType: undefined,
      expand: true,
      vatItemSend: false,
      panel: 0,
    };
  },

  async beforeMount() {
    const taxId = Number(this.$route.params.taxId);
    await Promise.all([
      this.store.loadTax(taxId),
      this.officeStore.loadOffices(),
      this.store.loadTaxTypes(),
    ]);

    this.selectedTaxType = this.store.taxTypes.find(
      (x) => x.id == this.store.tax.taxType
    );
    this.selectedOffice = this.officeStore.offices.find(
      (x) => x.id == this.store.tax.office
    );
  },

  methods: {
    async save(tax: Tax) {
      await this.store.save(tax);
      this.$router.push("/tax");
    },

    selectedOfficeChange(event: Office) {
      this.store.tax.office = event.id;
    },

    selectedTaxTypeChange(event: TaxType) {
      this.store.tax.taxType = event.id;
    },

    back(){
      this.$router.push('/tax')
    }
  },
});
