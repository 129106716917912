var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.store.accountStatuses.length > 0 && _vm.store.bankStatuses.length > 0)?_c('ApiSearchPage',{staticClass:"statements-grid filtered-grid",attrs:{"columns":_vm.columns,"url":_vm.url,"create":"Új kivonat","filterable":"","detailsField":"selected"},on:{"create":_vm.create},scopedSlots:_vm._u([{key:"statementDetailsButtonTemplate",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Kivonat "+_vm._s(item.statement.status == 2 ? "szerkesztése" : "megtekintése")+" ")])]),_c('icon-button',{class:{ selected: item.selected },attrs:{"icon":"fa-bars","tooltip":"Kivonat információk","btnClasses":'ml-100'},on:{"click":function($event){return _vm.showDetails(item)}}})]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('colspan-fixer',{attrs:{"cols":9}}),_c('CashStatementDetails',{staticStyle:{"border-left":"2px solid var(--primaryColor)","border-right":"2px solid var(--primaryColor)"},attrs:{"statement":item}})],1)]}},{key:"openBalTemplate",fn:function(ref){
var item = ref.item;
return [_c('money-display',{attrs:{"value":item.openBal,"currency":item.currency}})]}},{key:"closeBalTemplate",fn:function(ref){
var item = ref.item;
return [_c('money-display',{attrs:{"value":item.closeBal,"currency":item.currency}})]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")]}},{key:"accStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAccStatusName(item.accStatus))+" ")]}}],null,false,95097787)}):_c('div',{staticStyle:{"width":"inherit","display":"flex","justify-content":"center","align-items":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }