export default interface ApprovalMatrixHead {
  id: number;
  deleted: boolean;
  apprType: number;
  accInvGroup: number;
  partner: number;
  isValueIndependent: boolean;
  minAmount: number;
  maxAmount: number;
  currency: string;
  approversNum: number;
  groupId: string;

  currencyDisabled: boolean;
  accInvGroupName: string | undefined;
  partnerName: string | undefined;
  expanded: boolean;
  deleting: boolean;
}

export const approvalMatrixHeadDefaultProps: ApprovalMatrixHead = {
  id: 0,
  deleted: false,
  apprType: 1,
  accInvGroup: 1,
  partner: 1,
  isValueIndependent: false,
  minAmount: 0,
  maxAmount: 0,
  currency: "HUF",
  approversNum: 0,
  groupId: "",

  currencyDisabled: false,
  accInvGroupName: "-",
  partnerName: "-",
  expanded: false,
  deleting: false,
};
