






























































































// Models
import Vue from "vue";
import store, { CashStatementStore } from "../../cashStatement/services/store";
import GeneralDenom from "../models/GeneralDenom";

// Components
import MoneyInput from "@/components/MoneyInput.vue";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Data {
  store: CashStatementStore;

  configuration: AppConfiguration;
}

interface Computed {
  validation: string;
  items: GeneralDenom[];
  fromItems: number;
  total: number;
}

interface Props {
  amount: number;
}

export default Vue.extend<Data, {}, Computed, Props>({
  props: { amount: Number },

  components: { MoneyInput, ValidationError, MoneyDisplay },

  async beforeMount() {
    await this.store.loadGeneralDenoms();
  },

  data: () => ({
    store,
    configuration,
  }),

  methods: {
    save() {
      this.$emit("save", this.items);
    },

    back() {
      this.$emit("back");
    },

    cancel() {
      this.$emit("cancel");
    },
  },

  computed: {
    validation() {
      if (this.amount > this.fromItems +2 || this.amount < this.fromItems-2 || this.total > 2 || this.total < -2) {
        return "Nem egyezik a záró egyenleggel a megadott címletek összege!";
      }

      if (this.fromItems < 0 || this.items.some((x) => x.quantity < 0)) {
        return "Nem lehet negatív a címletjegyzék számla";
      }

      return "";
    },

    items() {
      return this.store.generalDenoms
        .filter((x) => x.currency == "HUF" && x.denomination > 2)
        .sort((a, b) => b.denomination - a.denomination);
    },

    fromItems() {
      return this.items.reduce(
        (acc, current) => acc + current.denomination * current.quantity,
        0
      );
    },

    total() {
      return this.amount - this.fromItems;
    },
  },
});
