export default interface FlowDocumentTemplateRelation {
  id: number;
  invId: number;
  docTemplateId: number;
}

export const flowDocumentTemplateRelationDefaultProps: FlowDocumentTemplateRelation = {
  id: 0,
  invId: 1,
  docTemplateId: 1,
};
