import eventBus from "@/services/eventBus";
import Country from "@/modules/cashPoint/models/Country";
import { CurrencyRate, currencyRateDefaultProps } from "@/modules/currencyRate/models/CurrencyRate";
import CurrencyRateApi from "./CurrencyRateApi";
import busyIndication from "../../../utils/busyIndication";

export class CurrencyRateStore {
  currencyRates: CurrencyRate[] = [];
  currencyRate: CurrencyRate = { ...currencyRateDefaultProps };
  currencies: Country[] = [];

  @busyIndication()
  async loadCurrencyRates(startDate?: string, endDate?: string) {
    this.currencyRates = await CurrencyRateApi.getAll(startDate, endDate);
  }

  @busyIndication()
  async loadFilteredCurrencyRates(startDate?: string, endDate?: string, currencyCUR?: string) {
    this.currencyRates = await CurrencyRateApi.getAll(startDate, endDate, currencyCUR);
  }

  async loadCurrencies() {
    this.currencies = await CurrencyRateApi.getCurrencies();
  }

  async loadCurrencyRate(currency: string) {
    this.currencyRate.mnbCentralRate = await CurrencyRateApi.getRate(currency);
  }

  @busyIndication()
  async loadCurrencyRateMnb(currency: string, date: Date | string) {
    this.currencyRate.mnbCentralRate = await CurrencyRateApi.getRateMnb(currency, date);
  }

  async save() {
    if (!this.currencyRate) {
      return;
    }

    await this.createCurrencyRate().then(() => eventBus.$emit("success", "Az árfolyam rögzítésre került."));
  }

  private async createCurrencyRate() {
    await CurrencyRateApi.create({
      rateDate: this.currencyRate.rateDate,
      centralRate: Number(this.currencyRate.centralRate),
      currency: this.currencyRate.localCurrency,
      unit: this.currencyRate.unit,
    });
  }
}

const store = new CurrencyRateStore();
export default store;
