import CreditCard from "../models/CreditCard";
import { CreditCardApi } from "./CreditCardApi";

export default class CreditCardStore {
  items: CreditCard[] = [];
  item: CreditCard = new CreditCard();

  async set() {
    this.items = await CreditCardApi.getAll();
  }

  async setItem(id: number) {
    if(id === 0)
      this.item = new CreditCard();
      
    this.item = await CreditCardApi.get(id) ?? new CreditCard();
  }

  getItem(id: number): CreditCard {
    return this.items.find(creditCard => creditCard.id === id) as CreditCard;
  }

  async save() {
    //console.log(this.item);
    await CreditCardApi.save(this.item);
  }
}
