

























































































































































import Vue from "vue";
import store, { PartnerStore } from "../services/PartnerStore";
import dtStore, { DocumentTemplateStore } from "../../documentTemplate/services/DocumentTemplateStore";
import FlowDocumentRelation from "../../flowDocumentRelation/models/FlowDocumentRelation";
import DocumentTemplateApi from "../../documentTemplate/services/DocumentTemplateApi";
import relStore, { FlowDocumentRelationStore } from "@/modules/flowDocumentRelation/services/FlowDocumentRelationStore";
import http from "@/services/http";

interface Data {
  store: PartnerStore;
  dtStore: DocumentTemplateStore;
  relStore: FlowDocumentRelationStore;
  partnerId: number;
  financialDocumentNameString: string;
}

interface Methods {
  uploadFile(relation: FlowDocumentRelation): void;
  uploadNewFile(): void;
  deleteFile(relation: FlowDocumentRelation): void;
  downloadFile(link: any): void;
  showFile(relation: FlowDocumentRelation): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  data() {
    return {
      store,
      dtStore,
      relStore,
      partnerId: Number(this.$route.params.partnerId),
      financialDocumentNameString: "Általános pénzügyi dokumentum",
    };
  },

  async beforeMount() {
    await this.relStore.loadFlowDocumentRelationsByPartnerId(this.partnerId);
  },

  methods: {
    async uploadNewFile() {
      const file = (this.$refs.newFile as any).$refs.input.files[0];

      if (!file) return;

      const docType = await this.dtStore.getDocumentTypeByTypeName(this.financialDocumentNameString);

      await this.dtStore.uploadNewFinancialDocumentTemplateItem(this.partnerId, docType ?? 1, file);
      await this.relStore.loadFlowDocumentRelationsByPartnerId(this.partnerId);
      (this.$refs.newFile as any) = null;
    },

    async deleteFile(relation: FlowDocumentRelation) {
      if (relation.link == "" || relation.fileName == "") return;

      await DocumentTemplateApi.deletePreviousDocument(relation.id);
      this.relStore.financialDocumentRelations = this.relStore.financialDocumentRelations.filter(
        (x) => x.id != relation.id
      );
    },

    async uploadFile(relation: FlowDocumentRelation) {
      const file = ((this.$refs as any).file[0] as any).$refs.input.files[0];

      if (!file) return;

      // delete previous file
      this.deleteFile(relation);

      const docType = await this.dtStore.getDocumentTypeByTypeName(this.financialDocumentNameString);

      await this.dtStore.uploadFinancialDocumentTemplateItem(this.partnerId, docType, file);
      await this.relStore.loadFlowDocumentRelationsByPartnerId(this.partnerId);
      (this.$refs.newFile as any) = null;
    },

    downloadFile(link: any) {
      window.open(link + `?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    },

    async showFile(relation) {
      relation.uploading = !relation.uploading;
    },
  },
});
