













import ApiSearch from "@/components/apiSearch/components/ApiSearch.vue";

import Vue from "vue";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import ProjectHeadDTO from "@/modules/projectHead/models/ProjectHeadDTO";
import { FilteringModel } from "@/services/filtering";

export default Vue.extend({
  components: { ApiSearch },

  props: {
    initId: Number,
    /** 2: profitcentrum, 3: költségcentrum @default 1 */
    invGroupType: { type: Number, default: 1 },
  },

  data: () => ({
    filter: new FilteringModel(),
    itemText: "name",
  }),

  beforeMount() {
    this.itemText = this.invGroupType === 2 ? "partnerName" : "name";
    this.filter = new FilteringModel().value("invGroupType", this.invGroupType);
  },

  methods: {
    selected(project: ProjectHeadDTO): void {
      this.$emit("change", project);
    },
  },

  computed: {
    id(): number {
      return this.initId ?? 1;
    },

    columns: (): ApiSearchColumn[] => [
      { field: "name", title: "Projekt" },
      { field: "mainCode", title: "Projekt kód" },
      { field: "code", title: "Projekt külső kód" },
      { field: "poWorkNumber", title: "Munkaszám" },
      { field: "poNumber", title: "Megrendelésszám" },
      { field: "poExtCode", title: "Megr. levélszám" },
      { field: "partnerName", title: "Partner" },
      { field: "partnerExtCode", title: "Partner külső kód" },
    ],
  },
});
