import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import GPartType from '../models/GPartType';
import PartGroup from '../models/PartGroup';
import UnitType from '../models/UnitType';
import Tax from '../models/Tax';
import TaxItem from '../models/TaxItem';
import CarCostType from '../models/CarCostType';
import GPart from '../models/GPart';

export default class GpartsEditApi {
    static getTypes(): Promise<GPartType[]> {
        return http.get<ApiResponse<GPartType[]>>(`gparts/types`)
          .then(handleApiResponse);
      }

      static getPartGroups(): Promise<PartGroup[]> {
        return http.get<ApiResponse<PartGroup[]>>(`gparts/partGroups`)
          .then(handleApiResponse);
      }

      static getCurrencies(): Promise<string[]> {
        return http.get<ApiResponse<string[]>>(`gparts/currencies`)
          .then(handleApiResponse);
      }

      static getUnitTypes(): Promise<UnitType[]> {
        return http.get<ApiResponse<UnitType[]>>(`gparts/unittypes`)
          .then(handleApiResponse);
      }

      static getTaxes(): Promise<Tax[]> {
        return http.get<ApiResponse<Tax[]>>(`gparts/taxes`)
          .then(handleApiResponse);
      }

      static getTaxItems(taxID: number): Promise<TaxItem[]> {
        return http.get<ApiResponse<TaxItem[]>>(`gparts/taxitems/${taxID}`)
          .then(handleApiResponse);
      }

      static getCarCostTypes(): Promise<CarCostType[]> {
        return http.get<ApiResponse<CarCostType[]>>(`gparts/carcosttypes`)
          .then(handleApiResponse);
      }

      static getGpart(id: number): Promise<GPart> {
        return http.get<ApiResponse<GPart>>(`gparts/gpart/${id}`)
          .then(handleApiResponse);
      }

      static updateGpart(gpart: GPart): Promise<number> {
        return http.post<ApiResponse<number>>(`gparts/update`, { gpart: gpart })
          .then(handleApiResponse);
      }
    
}