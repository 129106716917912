import Email from "@/modules/cashStatement/models/Email";

export default interface ExperimentalInvoice {
  pinvID: number;
  prefix: string;
  partner: string;
  partnerID: number;
  pmode: string;
  invDate: Date;
  invTime: Date;
  shipDate: Date;
  pterm: Date;
  netto: number;
  vat: number;
  total: number;
  storno: boolean;
  invoiceSt: number;
  status: string;
  dpaym: boolean;
  dpaymIncl: boolean;
  dpaymNetto: number;
  dpaymVat: number;
  accGroup: string;
  currency: string;
  remark1: string;
  remark2: string;
  withVat: boolean;
  userID: number;
  isRabatt: boolean;
  bankAccount: number;
  bankName: string;
  bankAccountNo: string;
  accStatus: string;
  invoiceRect: number;
  version: number;
  invCurrency: string;
  currNetto: number;
  currTotal: number;
  currVat: number;
  exchangeRate: number;
  rateDate: Date;

  selected: boolean;

  isShipDateContinous: boolean;
  accDate: Date;
  pdays: number;
  isEInvoice: boolean;
}

export const experimentalInvoiceDefaultProps: ExperimentalInvoice = {
  pinvID: 1,
  prefix: "",
  partner: "",
  partnerID: 1,
  pmode: "",
  bankName: "",
  invDate: new Date(),
  invTime: new Date(),
  shipDate: new Date(),
  pterm: new Date(),
  netto: 1,
  vat: 1,
  total: 1,
  storno: false,
  invoiceSt: 1,
  status: "",
  dpaym: false,
  dpaymIncl: false,
  dpaymNetto: 1,
  dpaymVat: 1,
  accGroup: "",
  currency: "",
  remark1: "",
  remark2: "",
  withVat: false,
  userID: 1,
  isRabatt: false,
  bankAccount: 1,
  bankAccountNo: "",
  accStatus: "",
  invoiceRect: 1,
  version: 1,
  invCurrency: "",
  currNetto: 1,
  currTotal: 1,
  currVat: 1,
  exchangeRate: 1,
  rateDate: new Date(),

  selected: false,

  isShipDateContinous: false,
  accDate: new Date(),
  pdays: 0,
  isEInvoice: false
};

export class ExperimentalInvoiceClass {
  pinvID = 1;
  prefix = "";
  partner = "";
  partnerID = 1;
  pmode = "";
  bankName = "";
  invDate = new Date();
  invTime = new Date();
  shipDate = new Date();
  pterm = new Date();
  netto = 1;
  vat = 1;
  total = 1;
  storno = false;
  invoiceSt = 1;
  status = "";
  dpaym = false;
  dpaymIncl = false;
  dpaymNetto = 1;
  dpaymVat = 1;
  accGroup = "";
  currency = "";
  remark1 = "";
  remark2 = "";
  withVat = false;
  userID = 1;
  userName= "";
  isRabatt = false;
  bankAccount = 1;
  bankAccountNo = "";
  accStatus = "";
  invoiceRect = 1;
  version = 1;
  invCurrency = "";
  currNetto = 1;
  currTotal = 1;
  currVat = 1;
  exchangeRate = 1;
  rateDate = new Date();

  selected = false;

  isShipDateContinous = false;
  accDate = new Date();
  pdays = Number(0);
  isEInvoice= false;
  emails= new Array<Email>();


  constructor(object?: Partial<ExperimentalInvoiceClass>) {
    Object.assign(this, {...object})
  }
}
