export default interface LoginResult {
  success: boolean;
  token: string;
  validTo: string;
  userName: string;
  userID: number;
  connectionID: string;
}

export const loginResultDefaultProps: LoginResult = {
  success: true,
  token: "",
  validTo: "",
  userName: "",
  userID: 1,
  connectionID: "",
};
