











































import Vue from "vue";
import InvoiceGroupApproval from "../models/InvoiceGroupApproval";
import store, { InvoiceGroupApprovalStore } from "../services/InvoiceGroupApprovalStore";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: InvoiceGroupApprovalStore;
  configuration: AppConfiguration;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    ApiSearchPage,
  },

  data() {
    return {
      store,
      configuration,
    };
  },

  methods: {
    clickHandler(item: any) {
      if (!configuration.invoiceFlow.enabled) {
        this.$router.push(`/general-incoming-invoice/edit/${item.partnerId}/${item.invId}`);
      } else {
        this.$router.push(`/general-incoming-invoice/flow/edit/${item.invId}/5`);
      }
    },
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      {
        field: "invId",
        title: "GENI szám",
        cell: "invIdCell",
      },
      {
        field: "invNo",
        title: "Bizonylat szám",
      },
      { field: "regCode", title: "Rendelés szám" },
      { field: "partsName", title: "Cikk" },
      {
        field: "partCode",
        title: "Cikkszám",
      },
      {
        field: "invGroupName",
        title: "Költséghley",
      },
      {
        field: "netto",
        title: "Nettó",
        type: ColumnType.Money,
      },
      { field: "currency", title: "Pénznem" },
      {
        field: "approvalTypeName",
        title: "Jóváhagyás",
      },
      {
        field: "typeName",
        title: "Tétel típus",
      },
      {
        field: "modUser",
        title: "Módosító",
      },
      {
        field: "daysSpentInStatus",
        title: "Jóváhagyásra vár",
        cell: "daysSpentInStatusTemplate",
      },
      {
        field: "apprType",
        title: "Típus",
      },
      { sortable: false, filterable: false },
    ],
  },
});
