













































































































































































// Models / Interfaces
import Vue from "vue";
import Pager from "@/models/Pager";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  GroupDescriptor,
  filterBy,
  orderBy,
  groupBy,
  process,
  DataResult,
  State,
} from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";

// Components
import Paginator from "./Paginator.vue";
import IconButton from "./IconButton.vue";
import ColspanFixer from "./ColspanFixer.vue";

interface Data {
  pager: Pager;
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];
  group: GroupDescriptor[];
}

interface Computed {
  hasCustomGrid: boolean;
  activeDataResult: DataResult;
  activeFilter: CompositeFilterDescriptor;
  activeSort: SortDescriptor[];
  activeGroup: GroupDescriptor[];
}

interface Props {
  // Header
  title: string;
  create: string;
  refresh: boolean;

  // Grid - content
  columns: any[];
  items: any[];

  removeCustom: string;

  columnMenu: boolean;

  filterable: boolean;
  removeFilter: boolean;
  customFilter: CompositeFilterDescriptor;

  sortable: boolean;
  removeSort: boolean;
  customSort: SortDescriptor[];

  groupable: boolean;
  removeGroup: boolean;
  customGroup: GroupDescriptor[];

  // Grid - templates
  gridClasses: string;

  customDetailsButton: boolean;
  detailsTemplate: string;
  disabledField: string;
  expandField: string;
  colspan: number;

  hasID: boolean;
  hasCustomIDTemplate: boolean;
  id: string;
  edit: boolean;

  stockRelated: boolean;
  stockRelatedText: string;

  badgeProp: string;
  badgePropFirstText: string;
  badgePropSecondText: string;
}

export const DEFAULT_FILTER: CompositeFilterDescriptor = { logic: "and", filters: [] };

export default Vue.extend<Data, {}, Computed, Props>({
  components: {
    Paginator,
    IconButton,
    ColspanFixer,
  },

  props: {
    // Header
    title: { type: String, default: "-" },
    create: { type: String, default: "" },
    refresh: { type: Boolean, default: false },

    // Grid - content
    columns: {
      type: Array as () => any[],
      default() {
        return [];
      },
    },

    items: {
      type: Array as () => any[],
      default() {
        return [];
      },
    },

    removeCustom: { type: String, default: "" },

    columnMenu: { type: Boolean, default: false },

    filterable: { type: Boolean, default: false },
    removeFilter: { type: Boolean, default: false },
    customFilter: {
      type: Object as () => CompositeFilterDescriptor,
      default() {
        return null;
      },
    },

    sortable: { type: Boolean, default: false },
    removeSort: { type: Boolean, default: false },
    customSort: {
      type: Array as () => SortDescriptor[],
      default() {
        return null;
      },
    },

    groupable: { type: Boolean, default: false },
    removeGroup: { type: Boolean, default: false },
    customGroup: {
      type: Array as () => GroupDescriptor[],
      default() {
        return null;
      },
    },

    // Grid - templates
    gridClasses: { type: String, default: "" },

    customDetailsButton: { type: Boolean, default: false },
    detailsTemplate: { type: String, default: "detailsTemplate" },
    disabledField: { type: String, default: "" },
    expandField: { type: String, default: "expanded" },
    colspan: { type: Number, default: 4 },

    hasID: { type: Boolean, default: false },
    hasCustomIDTemplate: { type: Boolean, default: false },
    id: { type: String, default: "id" },
    edit: { type: Boolean, default: false },

    stockRelated: { type: Boolean, default: false },
    stockRelatedText: { type: String, default: "Készletkapcsolatos" },

    badgeProp: { type: String, default: "" },
    badgePropFirstText: String,
    badgePropSecondText: String,
  },

  data: () => ({
    // Pager
    pager: new Pager(),
    filter: DEFAULT_FILTER,
    sort: [],
    group: [],
  }),

  methods: {
    excelExport() {
      saveExcel({
        data: this.items,
        fileName: "Szamla_bongeszo",
        columns: [
          {
            field: "entity.id",
            title: "GENI",
          },
          {
            field: "entity.invoiceNo",
            title: "Bizonylat szám",
          },
          {
            field: "partner",
            title: "Szállító",
          },
          {
            field: "invoiceType",
            title: "Számla típusa",
          },
          {
            field: "dpaymInv",
            title: "Előleg számla",
          },
          {
            field: "entity.invoiceDate",
            title: "Számla dátuma",
            type: "date",
            format: "{0: yyyy-MM-dd}",
          },
          {
            field: "entity.payTerm",
            title: "Fizetési határidő",
            type: "date",
            format: "{0: yyyy-MM-dd}",
          },
          {
            field: "entity.currTotal",
            title: "Bruttó",
          },
          {
            field: "entity.shipDate",
            title: "Teljesítés dátuma",
            type: "date",
            format: "{0: yyyy-MM-dd}",
          },
          {
            field: "entity.regDate",
            title: "Iktatás dátuma",
            type: "date",
            format: "{0: yyyy-MM-dd}",
          },
          {
            field: "flowModDate",
            title: "Módosítás dátuma",
            type: "date",
            format: "{0: yyyy-MM-dd}",
          },
          {
            field: "flowUserName",
            title: "Módosító",
          },
          {
            title: "Könyvelési státusz",
            field: "accStatus",
          },
          {
            title: "Számla státusz",
            field: "statusName",
          },
          {
            field: "flowStatusName",
            title: "Workflow státusz",
          },
          {
            field: "daysSpentInStatus",
            title: "Státuszban eltöltött idő",
          },
        ],
      });
    },

    // Header
    createClick() {
      this.$emit("createClick");
    },

    removeCustomClick() {
      this.$emit("removeCustom");
    },

    removeFilterClick() {
      this.filter = DEFAULT_FILTER;
      if (this.hasCustomGrid) this.$emit("removeFilter");
    },

    removeSortClick() {
      this.sort = [];
      if (this.hasCustomGrid) this.$emit("removeSort");
    },

    removeGroupClick() {
      this.group = [];
      if (this.hasCustomGrid) this.$emit("removeGroup");
    },

    refreshClick() {
      this.$emit("refreshClick");
    },

    // Pager
    pagerChanged(p: Pager) {
      if (this.hasCustomGrid) this.$emit("pagerChange", p);
      else this.pager = p;
    },

    // Grid
    editItem(item: any) {
      this.$emit("editItem", item);
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      if (this.customFilter) this.$emit("filterChange", e.filter);
      else this.filter = e.filter;
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      if (this.customSort) this.$emit("sortChange", e.sort);
      else this.sort = e.sort;
    },

    groupChange(e: { group: GroupDescriptor[] }) {
      if (this.customGroup) this.$emit("groupChange", e.group);
      else this.group = e.group;
    },

    // TODO: fix double-expand getting an extra unknown column
    expandChange(e: any) {
      Vue.set(
        e.dataItem,
        e.target.$props.expandField,
        e.dataItem.expanded === undefined ? false : !e.dataItem.expanded
      );
      // e.dataItem[e.target.$props.expandField] = e.value;
    },

    badgeColor(b: boolean) {
      return b ? "teal" : "red";
    },
  },

  computed: {
    hasCustomGrid() {
      return !!this.$slots.customGrid;
    },

    activeDataResult() {
      let options: State = {};
      if (this.filterable) {
        options = { ...options, filter: this.activeFilter };
      }
      if (this.sortable) {
        options = { ...options, sort: this.activeSort };
      }
      if (this.groupable) {
        options = { ...options, group: this.activeGroup };
      }
      // TODO: take / skip doesn't work well with grouping yet
      // options = { ...options, take: this.pager.pageSize, skip: this.pager.currentPage };
      return process(this.items, { ...options });
    },

    activeFilter() {
      return this.customFilter ? this.customFilter : this.filter;
    },

    activeSort() {
      return this.customSort ? this.customSort : this.sort;
    },

    activeGroup() {
      return this.customGroup ? this.customGroup : this.group;
    },
  },
});
