


























































































































































































































































































































































































































import dayjs from "dayjs";
import Vue from "vue";
import Glim, { DefaultPropsGlim } from "../models/GeneralLedgerInvoiceMirror";
import { Year } from "../models/YearModell";
import store, { GeneralLedgerStore } from "../services/store";
import api from "../services/api";
import ButtonCheckBox from "@/components/ButtonCheckBox.vue";
import InvMirrorTreeView from "./InvMirrorTreeView.vue";
import {
  GroupInvMirrorEdit,
  GroupInvMirrorEditProps,
} from "../models/TypesForEdit";
import http, { checkResponse } from "@/services/http";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import eventBus from "@/services/eventBus";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import EditInvoiceMirror from "../components/EditInvoiceMirror.vue";
import GeneralLedgerSelect from "../components/GeneralLedgerChooser.vue";
import CustomTab from "@/components/CustomTab.vue";
import VDataHeaders from "@/models/VDataHeaders";

interface Data {
  store: GeneralLedgerStore;
  headers: VDataHeaders[];
  hiearchyView: boolean;
  pageSizes: number[];
  selectedItems: number[];
  allItemSelect: boolean;
  deleteMode: boolean;
  glimThatHasStatusChanged: Glim[];
  creatingMirror: Glim | null;
  secondHeaders: VDataHeaders[];
  comingFromTreeView: boolean;
  isTableLoading: boolean;
  expanded: Glim[];
  groupEdit: boolean;
  groupInvMirrorEdit: GroupInvMirrorEdit;
  tab: number;
  noData: boolean;
  refreshGrid: boolean;
}
interface Props {
  yearThatIsBeingChecked: Year;
}
interface Computed {
  glims: Glim[];
  hasItemsSelected: boolean;
  yearBeingWatched: number;
  accumGlims: Glim[];
  columns: ApiSearchColumn[];
  apiUrl: string;
}

export default Vue.extend<Data, {}, Computed, Props>({
  data(): Data {
    return {
      store: store,
      selectedItems: [],
      expanded: [],
      comingFromTreeView: false,
      hiearchyView: false,
      deleteMode: false,
      allItemSelect: false,
      creatingMirror: null,
      groupEdit: false,
      tab: 0,
      noData: false,
      refreshGrid: false,
      groupInvMirrorEdit: GroupInvMirrorEditProps,
      isTableLoading: true,
      pageSizes: [4, 8, 16, 32],
      headers: [
        { text: "Hosszú név", value: "nationalLong" },
        { text: "Fők. szám", value: "accno" },
        { text: "Megjegyzés", value: "note" },
        { text: "Gyűjtő", value: "isAccum" },
        { text: "Állapot", value: "status" },
        { text: "0. fők. számlaszám", value: "currAccno" },
        { text: "", value: "modify" },
        { text: "", value: "select" },
      ],
      secondHeaders: [{ text: "Hosszú név", value: "nationalLong" }],
      glimThatHasStatusChanged: [],
    };
  },
  computed: {
    apiUrl() {
      return `generalledger/invoicemirror/${this.yearBeingWatched}/search`;
    },
    columns: (): ApiSearchColumn[] => [
      {
        field: "select",
        cell: "select",
        filterable: false,
        sortable: false,
      },
      {
        field: "nationalLong",
        title: "Hosszú név",
        filterable: true,
        sortable: true,
      },
      {
        field: "accno",
        title: "Fők. szám",
        filterable: true,
        sortable: true,
      },
      {
        field: "note",
        title: "Megjegyzés",
        filterable: true,
        sortable: true,
      },
      {
        field: "isAccum",
        cell: "isAccum",
        title: "Gyűjtő",
        filterable: true,
        sortable: true,
      },
      {
        field: "user",
        title: "Létrehozó felhasználó",
        filterable: true,
        sortable: true,
      },
      {
        field: "partner",
        title: "Partner",
        filterable: true,
        sortable: true,
      },
      {
        field: "status",
        title: "Állapot",
        filterable: true,
        sortable: true,
      },
      {
        field: "mainType",
        title: "Típus",
        filterable: true,
        sortable: true,
      },
      {
        field: "currAccno",
        title: "0. fők. számlaszám",
        filterable: true,
        sortable: true,
      },
      {
        field: "editable",
        sortable: false,
        filterable: false,
        cell: "editable",
      },
      {
        field: "id",
        cell: "id",
        filterable: false,
        sortable: false,
      },
      {
        field: "modify",
        cell: "modify",
        filterable: false,
        sortable: false,
        fieldClass: "pr-0"
      },
      {
        cell: "deleteTemplate",
        filterable: false,
        sortable: false,
        fieldClass: "pl-0"
      },
    ],

    glims() {
      return this.store.glims;
    },
    accumGlims() {
      return this.store.glims.filter((x) => x.isAccum);
    },
    hasItemsSelected(): boolean {
      if (this.allItemSelect) {
        return true;
      } else if (this.selectedItems.length > 0) {
        return true;
      } else return false;
    },
    yearBeingWatched() {
      return this.store.yearBeingChecked;
    },
  },
  components: {
    GeneralLedgerSelect,
    CustomTab,
    ApiSearchPage,
    EditInvoiceMirror,
    ButtonCheckBox,
    InvMirrorTreeView,
  },
  methods: {
    setNoData(itemCount: number) {
      this.noData = itemCount == 0;
    },
    groupEditHandle() {
      if (this.groupEdit) {
        this.selectedItems = [];
      }
      this.groupEdit = !this.groupEdit;
    },

    async saveGroupInvMirrorEdit() {
      if (this.tab === 0) {
        //Koltseghelyek
        this.groupInvMirrorEdit.dimEdit = true;
        this.groupInvMirrorEdit.typeEdit = false;
        this.groupInvMirrorEdit.openCloseEdit = false;
        this.groupInvMirrorEdit.manualMachineEdit = false;
      }

      if (this.tab === 1) {
        //fotipus es altipus mentes
        this.groupInvMirrorEdit.typeEdit = true;
        this.groupInvMirrorEdit.dimEdit = false;
        this.groupInvMirrorEdit.openCloseEdit = false;
        this.groupInvMirrorEdit.manualMachineEdit = false;
      }

      if (this.tab === 2) {
        //nyito, zaro, atvezeto
        this.groupInvMirrorEdit.typeEdit = false;
        this.groupInvMirrorEdit.dimEdit = false;
        this.groupInvMirrorEdit.openCloseEdit = true;
        this.groupInvMirrorEdit.manualMachineEdit = false;
      }

      if (this.tab === 3) {
        //man vagy gepi konyv
        this.groupInvMirrorEdit.typeEdit = false;
        this.groupInvMirrorEdit.dimEdit = false;
        this.groupInvMirrorEdit.openCloseEdit = false;
        this.groupInvMirrorEdit.manualMachineEdit = true;
      }

      const res = await http
        .put(
          `generalledger/invoicemirror/${this.yearBeingWatched}/groupupdateinvmirrors`,
          this.groupInvMirrorEdit
        )
        .then(checkResponse);
      if (res) {
        eventBus.$emit("success", "Sikeres mentés!");
        this.groupInvMirrorEdit.typeEdit = false;
        this.groupInvMirrorEdit.dimEdit = false;
        this.groupInvMirrorEdit.openCloseEdit = false;
        this.groupInvMirrorEdit.manualMachineEdit = false;
        this.selectedItems = [];
        this.groupEdit = false;
      } else {
        eventBus.$emit("error", "A mentés nem sikerült!");
      }
    },

    setSelectedItems(itemId: number) {
      if (this.selectedItems.includes(itemId)) {
        this.selectedItems.splice(this.selectedItems.indexOf(itemId), 1);
      } else {
        this.selectedItems.push(itemId);
      }
    },

    async deleteCurrentItem(item: number){
      const f = await this.store.DeleteGlims([item]);
      if (f) {
        //this.selectedItems = [];
        eventBus.$emit("api-search-page:reload");
      }
    },
    
    async deleteSelected() {
      const f = await this.store.DeleteGlims(this.selectedItems);
      if (f) {
        this.selectedItems = [];
        eventBus.$emit("api-search-page:reload");
      }
    },

    toggleSelect(item: Glim) {
      item.select = !item.select;
    },
    isItemChecked(item: Glim) {
      return item.select;
    },
    async generateInvMirrors() {
      await this.store.generateInvMirrors().then(()=>{
        eventBus.$emit("api-search-page:reload")
      });
      
    },
    toDate(item: string) {
      return dayjs(item)
        .format("YYYY-MM-DD")
        .replaceAll("-", ".");
    },
    toTime(item: string) {
      return dayjs(item)
        .format("HH:mm")
        .replaceAll("-", ".");
    },
    async detailItem(item: Glim) {
      if (this.expanded.filter((x) => x.id == item.id).length > 0) {
        this.expanded = [];
        return;
      } else {
        this.expanded = [];
        this.expanded.push(item);
      }
      this.comingFromTreeView = false;
    },
    exitEditMode() {
      this.hiearchyView = this.comingFromTreeView ? true : false;
      this.expanded = [];
    },
    mirrorChangedStatus(item: Glim) {
      this.glimThatHasStatusChanged.push(item);
    },
    async saveChangesToStatuses() {
      if (this.glimThatHasStatusChanged.length > 0) {
        const d = await this.store.saveGlimStatusChanges(
          this.glimThatHasStatusChanged
        );
        if (d) {
          this.glimThatHasStatusChanged = [];
          eventBus.$emit("success", "Sikeres mentés!");
        } else {
          eventBus.$emit("error", "A mentés nem sikerült!");
        }
      }
    },
    async createInvMirror() {
      await this.store.setStatuses();
      this.store.mainTypes = await api.GetMainTypes();
      this.store.detailedTypes = await api.GetDetailedTypes();
      this.store.dimSortTypes = await api.GetDimSortTypes();

      this.creatingMirror = DefaultPropsGlim;

      this.expanded.push(this.creatingMirror);
    },
    setEditItemFromTreeView(x: string) {
      {
        if (this.glims.filter((y) => y.accno == x).length > 0) {
          this.expanded.push(this.glims.filter((y) => y.accno == x)[0]);
        } else {
          eventBus.$emit("api-pager:set-page", 1);
          this.expanded.push(this.glims.filter((y) => y.accno == x)[0]);
        }
        this.comingFromTreeView = true;
      }
    },
    changeToTableView() {
      this.hiearchyView = false;
      eventBus.$emit("api-pager:set-page", 1);
    },
  },
  watch: {
    selectedItems() {
      this.groupInvMirrorEdit.itemIds = this.selectedItems;
    },

    allItemSelect() {
      if (this.allItemSelect) {
        this.store.glims.forEach((x) => (x.select = true));
      } else {
        this.store.glims.forEach((x) => (x.select = false));
      }
    },
    async yearBeingWatched() {
      this.isTableLoading = true;

      this.isTableLoading = false;

      this.store.hasGlimGenerated = false;
      this.allItemSelect = false;
      this.deleteMode = false;
      if (this.store.glims.length > 0) {
        this.store.hasGlimByNature = true;
      }
      this.glimThatHasStatusChanged = [];
    },
  },

  props: {
    yearThatIsBeingChecked: {},
  },
  beforeUpdate() {
    if (this.store.glims.length > 0) {
      this.store.hasGlimByNature = true;
    } else {
      this.store.hasGlimByNature = false;
    }
  },
  async beforeMount() {
    this.store.setDimActivities();

    this.isTableLoading = true;

    //eventBus.$emit("api-search-page:reload");
    this.isTableLoading = false;

    if (this.store.glims.length > 0) {
      this.store.hasGlimByNature = true;
    } else {
      this.store.hasGlimByNature = false;
    }

    this.glimThatHasStatusChanged = [];
    eventBus.$emit("api-pager:set-page", 1);
    this.store.hasGlimGenerated = false;
    this.allItemSelect = false;
    this.deleteMode = false;
    if (this.store.glims.length > 0) {
      this.store.hasGlimByNature = true;
    }

    const filtered = this.store.glims.filter((x) => x.isAccum);
    for (let i = 0; i < filtered.length; i++) {
      const element = filtered[i];
    }
  },
});
