<template>
  <div
    :class="{
      'list-group-item': true,
      'status-progress': item.incomingInvoice.status == 1 || item.incomingInvoice.status == 2,
      'status-issued': item.incomingInvoice.status == 3,
      'status-partially': item.incomingInvoice.status == 4,
      'status-paid': item.incomingInvoice.status == 5 || item.incomingInvoice.status == 7,
      'status-overpaid': item.incomingInvoice.status == 6,
    }"
  >
    <div class="list-group-item-heading">
      <div style="padding-top: 5px;">
        <h5>
          <a @click="item.isItemsVisible = !item.isItemsVisible">{{ item.incomingInvoice.invoiceNo }}</a>
          <v-chip class="mx-1" v-if="item.invoiceStatusName" label small color="primary">{{
            item.invoiceStatusName
          }}</v-chip>
        </h5>
      </div>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon outlined color="primary" @click="updateInvoice(item)">
              <v-icon x-small>fa-edit</v-icon>
            </v-btn>
          </template>
          <span>Adatok módosítása</span>
        </v-tooltip>
      </div>
    </div>
    <div class="list-group-item-text">
      <v-row>
        <v-col sm="3" class="address">
          <a @click="partnerEdit(item)">
            <strong>{{ item.partnerName }}</strong>
          </a>
          <br />
          <span>{{ item.partnerCity }}</span> <br />
          <span>{{ item.partnerAddress }}</span> <br />
          <span>{{ item.partnerTaxNo }}</span>
        </v-col>
        <v-col sm="8">
          <grid
            :data-items="[item]"
            :columns="[
              {
                field: 'invoiceDate',
                title: 'Vámhatározat dátuma',
                className: 'center_text',
                headerClassName: 'center_text',
              },
              {
                field: 'paymentDate',
                title: 'Vámhatározat pénzügyi esedékessége',
                className: 'center_text',
                headerClassName: 'center_text',
              },
              {
                field: 'incomingInvoice.netto',
                title: 'Kivetett vám',
                format: '{0:n}',
                className: 'right_text',
                headerClassName: 'right_text',
              },
              {
                field: 'incomingInvoice.vat',
                title: 'Kivetett vám ÁFA',
                format: '{0:n}',
                className: 'right_text',
                headerClassName: 'right_text',
              },
              {
                field: 'incomingInvoice.total',
                title: 'Vámhatározat összege',
                format: '{0:n}',
                className: 'right_text',
                headerClassName: 'right_text',
              },
            ]"
            scrollable="none"
            class="list-group-table border-top"
            style="padding-top: 10px; width: 100%"
          >
          </grid>
        </v-col>
      </v-row>
      <v-row>
        <transition name="fadeHeight" mode="out-in">
          <div v-show="item.isItemsVisible" style="width: 100%;">
            <div class="title-bar">
              <a class="title a">Tételek</a>
            </div>
            <div class="pt-4">
              <h5 @click="item.isDutyItemsVisible = !item.isDutyItemsVisible">
                Vám tételek
                <span>
                  <a>
                    <v-icon small v-show="!item.isDutyItemsVisible">fa-angle-double-down</v-icon>
                    <v-icon small v-show="item.isDutyItemsVisible">fa-angle-double-up</v-icon>
                  </a>
                </span>
              </h5>
            </div>
            <div class="py-2">
              <transition name="fadeHeight" mode="out-in">
                <div v-show="item.isDutyItemsVisible">
                  <grid
                    :data-items="dutyItems"
                    :columns="[
                      { field: 'incomingInvoiceItem.statCode', title: 'Vámtarifaszám' },
                      {
                        field: 'incomingInvoiceItem.dutyBase',
                        title: 'Kivetett vám alapja',
                        format: '{0:n}',
                        className: 'right_text',
                        headerClassName: 'right_text',
                      },
                      {
                        field: 'incomingInvoiceItem.netto',
                        title: 'Kivetett vám összege',
                        format: '{0:n}',
                        className: 'right_text',
                        headerClassName: 'right_text',
                      },
                    ]"
                    scrollable="none"
                    class="items-grid list-group-table"
                  >
                  </grid>
                </div>
              </transition>
            </div>
            <div class="pt-4">
              <h5 @click="item.isVatItemsVisible = !item.isVatItemsVisible">
                ÁFA tétel
                <span>
                  <a>
                    <v-icon small v-show="!item.isVatItemsVisible">fa-angle-double-down</v-icon>
                    <v-icon small v-show="item.isVatItemsVisible">fa-angle-double-up</v-icon>
                  </a>
                </span>
              </h5>
            </div>
            <div class="py-2">
              <transition name="fadeHeight" mode="out-in">
                <div v-show="item.isVatItemsVisible">
                  <grid
                    :data-items="item.items.filter((x) => x.isTax)"
                    :columns="[
                      { field: 'taxName', title: 'ÁFA kulcs' },
                      {
                        field: 'incomingInvoiceItem.vatBase',
                        title: 'Kivetett ÁFA alapja',
                        format: '{0:n}',
                        className: 'right_text',
                        headerClassName: 'right_text',
                      },
                      {
                        field: 'incomingInvoiceItem.vat',
                        title: 'Kivetett vám ÁFÁ-ja',
                        format: '{0:n}',
                        className: 'right_text',
                        headerClassName: 'right_text',
                      },
                    ]"
                    scrollable="none"
                    class="items-grid list-group-table"
                  >
                  </grid>
                </div>
              </transition>
            </div>
          </div>
        </transition>
      </v-row>
    </div>
    <v-chip x-small label color="grey" style="color: white;"> {{ item.typeName }} </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
  },

  methods: {
    updateInvoice(item) {
      this.$router.push(`/customs-invoice/edit/${item.incomingInvoice.id}`);
    },

    partnerEdit(item) {
      this.$router.push(`/partners/${item.incomingInvoice.partner}`);
    },
  },

  computed: {
    dutyItems() {
      const items = this.item.items.filter((x) => x.isDuty).map((x) => ({ ...x }));
      return items;
    },
  },
};
</script>

<style>
.address {
  margin-bottom: 17px;
  font-style: normal;
  line-height: 1.42857143;
}

.status-progress {
  border-left-width: 4px !important;
  border-left-style: solid !important;
}

.status-issued {
  border-left-width: 4px !important;
  border-left-style: solid !important;
  border-left-color: #9b59b6 !important;
}

.status-partially {
  border-left-width: 4px !important;
  border-left-style: solid !important;
  border-left-color: #f39c12 !important;
}

.status-paid {
  border-left-width: 4px !important;
  border-left-style: solid !important;
  border-left-color: #2ecc71 !important;
}

.status-overpaid {
  border-left-width: 4px !important;
  border-left-style: solid !important;
  border-left-color: red !important;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #394263;
}

.list-group-item-heading {
  display: flex;
  justify-content: space-between;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.title.a {
  color: #777;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-bottom-color: white;
  cursor: default;
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-right: 2px;
  line-height: 1.42857143;
  border-radius: 0;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px !important;
  width: 65px;
  font-weight: 400;
  margin-bottom: -1px;
}

.title-bar {
  border-bottom: 1px solid #ddd;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.8s;
  max-height: 1000px;
}

.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}

h5 {
  font-size: 16px !important;
  font-weight: 400;
}
</style>
