



































import Vue from "vue";
import { debounce } from "debounce";

interface Data {
  setting: boolean;
}

/** Usage / example: @see SystemParametersPage.vue */
export default Vue.extend({
  props: {
    /** Title / label of the component  */
    title: String,
    /** Function to call when the value changed */
    change: Function,
    /** The value of the property */
    value: [String, Number, Boolean],
    /** Should render a v-switch and add adjustment classes - @default false */
    isSwitch: { type: Boolean, default: false },
    /** Should render a v-text-field and add adjustment classes - @default false */
    isInput: { type: Boolean, default: false },
    propCols: { type: String, default: "auto"} ,
  },

  data: (): Data => ({
    setting: false,
  }),

  created() {
    this.update = debounce(this.update, 400);
  },

  watch: {
    value() {
      //! avoid duped update
      // do not watch if it doesn't have a custom template
      if (!this.$scopedSlots.default) return;
      this.setting = true;
      this.update();
    },
  },

  methods: {
    onChange(value: string | number | boolean) {
      //! avoid duped update
      // do not change if it has a custom template
      if (this.$scopedSlots.default) return;

      this.setting = true;
      this.$emit("input", value);
      this.update();
    },

    update() {
      if (!this.change) {
        this.setting = false;
        return;
      }

      this.change(this.value);
      this.setting = false;
    },
  },
});
