



























































































import Vue from "vue";
import store, { TaxStore } from "../services/TaxStore";
import DateInput from "@/components/DateInput.vue";
import setAttribute from "../../../utils/dom";
import Page from "../../../components/Page.vue";
import VatItem from "../models/VatItem";
import VatBreakdown from "./VatBreakdown.vue";

interface Data {
  store: TaxStore;
  createVatItm: boolean;
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "7");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  async beforeMount() {
    const taxId = Number(this.$route.params.taxId);
    await Promise.all([this.store.loadVatItems(taxId)]);
  },
  components: {
    DateInput,
    confirmation: confirmationTemplate,
    Page,
    VatBreakdown,
  },

  computed: {
    retVatItem(){
        return this.store.createVatItm;
    },
    
    columns: () => [
      { field: "name", title: "Megnevezés" },
      { field: "foreign", title: "Megnevezés id. nyelven" },
      { field: "english", title: "Megnevezés angolul" },
      {
        field: "validFrom",
        title: "Mikortól",
        editor: "Date",
        cell: "ValidFromTemplate",
      },
      {
        field: "validTo",
        title: "Meddig",
        editor: "Date",
        cell: "ValidToTemplate",
      },
      {
        field: "backRate",
        title: "Levonható %",
        template: "BackRateTemplate",
      },

      { cell: "commandTemplate", width: "70px" },
    ],
    items() {
      return this.store.vatItems;
    },
  },

  data(): Data {
    return {
      store,
      createVatItm: false,
    };
  },

  methods: {
    changeDeleting(item: VatItem) {
      this.store.setDeleting(item);
    },
    confirmDelete(item: VatItem) {
      item.deleting = false;
      this.store.deleteVatItem(item.id);
    },
    changeVatItem() {
        this.store.createVatItm=!this.store.createVatItm;
    },
  },
});
