













import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import Vue from "vue";

interface Data {
  items: string[];
}

interface Props {
  value: string | string[];
  multiselect: boolean;
  placeholder: string;
}

interface Computed {
  inputValue: string | string[];
}

export default Vue.extend<Data, {}, Computed, Props>({
  data() {
    return {
      items: [] as string[],
    };
  },
  async beforeMount() {
    this.items = await http
      .get<ApiResponse<string[]>>(`currency/currencies`)
      .then(handleApiResponse);
  },
  props: {
    placeholder: { type: String, default: "" },
    multiselect: { type: Boolean, default: false },
    value: {
      type: Array,
      validator: function(value) {
        // Ellenőrzés, hogy az érték string vagy string tömb
        return (
          typeof value === "string" ||
          (Array.isArray(value) &&
            value.every((item) => typeof item === "string"))
        );
      },
      default: function(): string | string[] {
        if (this.multiselect) return [];
        else return "";
      },
    },
  },
  computed: {
    inputValue: {
      get(): string | string[] {
        return this.value;
      },
      set(value: string | string[]) {
        this.$emit("input", value);
        //this.$emit("change", value);
      },
    },
  },
});
