





































































































import Vue from "vue";
import { InvoiceItem } from "@/modules/invoiceItems/models";
import store, { InvoiceItemStore } from "../../invoiceItems/services/store";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import configuration from "@/models/Configuration";

interface Data {
  store: InvoiceItemStore;
}

interface Computed {
  nettoValue: number;
  bruttoValue: number;
  taxValue: number;
  originalNettoValue: number;
  originalBruttoValue: number;
  originalVatValue: number;
}

export default Vue.extend<Data, {}, Computed, {}>({
  data() {
    return {
      store,
      configuration,
    };
  },

  components: {
    MoneyDisplay,
  },

  methods: {
    sendEmail() {
      this.store.sendEmail(
        this.nettoValue,
        this.bruttoValue,
        this.taxValue,
        this.originalNettoValue,
        this.originalBruttoValue,
        this.originalVatValue,
        Number(this.$route.params.invoiceId)
      );
    },
  },

  computed: {
    nettoValue() {
      const a = (store.itemList.items as InvoiceItem[]).map((x) => x.nettoValue).reduce((a, b) => a + b, 0.0);
      return a;
    },

    bruttoValue() {
      const a = (store.itemList.items as InvoiceItem[]).map((x) => x.bruttoValue).reduce((a, b) => a + b, 0.0);
      return a;
    },

    taxValue() {
      const a = (store.itemList.items as InvoiceItem[]).map((x) => x.taxValue).reduce((a, b) => a + b, 0.0);
      return a;
    },

    originalNettoValue() {
      const a = (store.itemList.items as InvoiceItem[]).map((x) => x.originalNettoValue).reduce((a, b) => a + b, 0.0);
      return a;
    },

    originalBruttoValue() {
      const a = (store.itemList.items as InvoiceItem[]).map((x) => x.originalBruttoValue).reduce((a, b) => a + b, 0.0);
      return a;
    },

    originalVatValue() {
      const a = (store.itemList.items as InvoiceItem[]).map((x) => x.originalVatValue).reduce((a, b) => a + b, 0.0);
      return a;
    },
  },
});
