



















import Vue from "vue";
import * as signalR from "@microsoft/signalr";

import http from "@/services/http";
import auth from "@/services/auth";
import { HubConnectionBuilder } from "@microsoft/signalr";

interface SessionMessage {
  timestamp: string;
  activeUsers: string[];
  sessionID: string;
}

interface Data {
  activeUsers: string[];
  cleanUp: (() => void) | undefined;
}

interface Props {
  sessionID: string;
  message: string;
}

interface Computed {
  userNames: string;
}

export default Vue.extend<Data, {}, Computed, Props>({
  props: {
    message: {
      type: String,
    },

    sessionID: {
      type: String,
    },
  },

  data: () => ({
    activeUsers: [] as string[],
    cleanUp: undefined,
  }),


  async created() {
    const connection = ((window as any).signalrMock as signalR.HubConnection) || new signalR.HubConnectionBuilder()
      .withUrl(http.defaults.baseURL + `connections/hub/edit-sessions`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    const startHandler = (msg: SessionMessage) => {
      this.activeUsers = msg.activeUsers.filter((u) => auth.userName !== u);
    };

    const endHandler = (msg: SessionMessage) => {
      this.activeUsers = msg.activeUsers.filter((u) => auth.userName !== u);
    };

    connection.on("EditSessionStarted", startHandler);
    connection.on("EditSessionEnded", endHandler);

    await connection.start();
    connection.invoke("StartEditSession", this.sessionID, auth.userName);

    this.cleanUp = () => {
      connection.invoke("EndEditSession", this.sessionID, auth.userName);
      connection.off("EditSessionStarted", startHandler);
      connection.off("EditSessionEnded", endHandler);
      connection.stop();
    };
  },

  beforeDestroy() {
    if (this.cleanUp) {
      this.cleanUp();
    }
  },
});
