var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('ApiSearchPage',{attrs:{"url":_vm.url,"columns":_vm.columns},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")]}},{key:"geni",fn:function(ref){
var item = ref.item;
return [(item.geni > 1)?_c('router-link',{attrs:{"to":_vm.getGeniLink(item.geni)}},[_vm._v(" "+_vm._s(item.geni)+" ")]):_c('span',[_vm._v("-")])]}},{key:"isFixingInv",fn:function(ref){
var item = ref.item;
return [(item.isFixingInv)?_c('v-icon',{staticStyle:{"color":"var(--primaryColor)"},attrs:{"small":""}},[_vm._v(" fa fa-check ")]):_c('v-icon',{staticStyle:{"color":"grey"},attrs:{"small":""}},[_vm._v(" fa fa-times ")])]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getType(item))+" ")])]}},{key:"prevComplaint",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prevDebitNotiId > 1 ? item.prevDebitNotiId : "-")+" ")]}}],null,false,1058779308)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }