import InvoiceItemTemplate from "./InvoiceItemTemplate";

export default interface InvoiceFlowTemplate {
    id: number;
    name: string;
    english: string;
    foreign: string;
    partner: number;
    insUserName: string;
    template: {
        //head
        payMode: number;
        accountNo: number;
        documentIds: number[];
        controllerIds: object[];
        acceptorIds: object[];
        //items
        items: InvoiceItemTemplate[];
    };

    expanded: boolean;
}
  
export const invoiceFlowTemplateDefaultProps: InvoiceFlowTemplate = {
    id: 0,
    name: "",
    english: "",
    foreign: "",
    partner: 1,
    insUserName: "",
    template: {
        //head
        payMode: 1,
        accountNo: 1,
        documentIds: [],
        controllerIds: [],
        acceptorIds: [],
        //items
        items: [],
    },

    expanded: false,
};
  