










































































































































































































































































































































import ButtonCheckBox from "@/components/ButtonCheckBox.vue";
import dayjs from "dayjs";
import Vue from "vue";
import store, { GeneralLedgerStore } from "../services/store";
import { Dimension, DefaultDimensionProps } from "../models/Dimension";
import eventBus from "@/services/eventBus";
import auth from "@/services/auth";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";

interface Data {
  store: GeneralLedgerStore;
  selectAllItems: boolean;
  dimBeingEdited: null | Dimension;
  changedDimStatuses: Dimension[];
  refreshGrid: boolean;
  noData: boolean;
  selectedItems: number[];
}

interface Computed {
  columns: ApiSearchColumn[];
  yearBeingWatched: number;
  apiUrl: string;
}

export default Vue.extend<Data, {}, Computed, {}>({
  data(): Data {
    return {
      store: store,
      selectAllItems: false,
      dimBeingEdited: null,
      changedDimStatuses: [],
      refreshGrid: false,
      noData: false,
      selectedItems: []
    };
  },

  components: { ButtonCheckBox, ApiSearchPage },

  computed: {
    apiUrl() {
      return `generalledger/dimensioncode/${this.yearBeingWatched}/search`;
    },
    columns: (): ApiSearchColumn[] => [
      {
        field: "select",
        cell: "select",
        filterable: false,
        sortable: false,
      },
      {
        field: "dimcode",
        title: "Dimenzió kód",
        filterable: true,
        sortable: true,
      },
      {
        field: "nationalLong",
        title: "Kezdő dátum",
        filterable: true,
        sortable: true,
      },
      { field: "note", title: "Megjegyzés", filterable: true, sortable: true },
      {
        field: "isAccum",
        cell: "isAccum",
        title: "Gyűjtő",
        filterable: true,
        sortable: true,
      },
      { field: "type", title: "Típus", filterable: true, sortable: true },
      { field: "status", title: "Állapot", filterable: true, sortable: true },
      {
        field: "activity",
        title: "Tevékenység típusa",
        filterable: true,
        sortable: true,
      },
      {
        field: "absorption",
        title: "Abszorpciós ráta %",
        filterable: true,
        sortable: true,
      },
      {
        cell: "edit",
        field: "edit",
        filterable: false,
        sortable: false,
        fieldClass: "pr-0"
      },
      {
        cell: "deleteTemplate",
        filterable: false,
        sortable: false,
        fieldClass: "pl-0"
      },
    ],
    yearBeingWatched() {
      return this.store.yearBeingChecked;
    },
  },

  async beforeMount() {
    //eventBus.$emit("api-search-page:reload");
    this.store.setStatuses();
    this.store.setDimCodeTypes();
    this.store.setDimActivities();
    this.selectAllItems = false;
  },

  beforeUpdate() {
    this.store.setStatuses();
  },

  methods: {
    setSelectedItems(itemId: number) {
      if (this.selectedItems.includes(itemId)) {
        this.selectedItems.splice(this.selectedItems.indexOf(itemId), 1);
      } else {
        this.selectedItems.push(itemId);
      }
    },

    setNoData(itemCount: number) {
      this.noData = itemCount == 0;
    },

    formatDate(date: Date) {
      return dayjs(date)
        .format("YYYY-MM-DD-HH:mm")
        .replace("-", ".")
        .replace("-", ".")
        .replace("-", " ");
    },

    editDim(dim: Dimension) {
      this.dimBeingEdited = dim;
    },

    async saveDimEdits() {
      if (this.dimBeingEdited != null) {
        await this.store.saveDimEdits(this.dimBeingEdited);
      }

      this.dimBeingEdited = null;
    },

    async saveStatusChanges() {
      if (this.changedDimStatuses.length > 0) {
        const d = await this.store.saveDimStatusChanges(
          this.changedDimStatuses
        );
        if (d) {
          this.changedDimStatuses = [];
          eventBus.$emit(
            "success",
            "Az állapot változtatások sikeresen mentve!"
          );
        } else {
          eventBus.$emit(
            "error",
            "Az állapot változtatások mentése közben hiba lépett fel!"
          );
        }
      }
    },

    async deleteSelectedDims() {
      await this.store.DeleteDimensions(this.selectedItems);

      this.selectedItems = [];
      eventBus.$emit("api-search-page:reload");
    },

    async deleteCurrentDim(id: number){
      await this.store.DeleteDimensions([id]);
      //this.selectedItems = [];
      eventBus.$emit("api-search-page:reload");
    },

    async generateDims() {
      await this.store.generateDims().then(()=>{
        eventBus.$emit("api-search-page:reload");
      });
    },

    createDimension() {
      this.dimBeingEdited = { ...DefaultDimensionProps, type: this.store.dimCodeTypes[0].national, status: this.store.statuses[0], activity: this.store.dimActivities[0].national, user: auth.userName };
      const d = this.store.years.find(
        (x) => x.id == this.store.yearBeingChecked
      );
      if (d) {
        this.dimBeingEdited.year = d.year.toString();
      }
    },
  },

  watch: {
    yearBeingWatched() {
      this.selectAllItems = false;
      this.dimBeingEdited = null;
      this.changedDimStatuses = [];
    },

    selectAllItems() {
      this.store.dimensions.forEach((x) => {
        x.select = this.selectAllItems;
      });
    },
  },
});
