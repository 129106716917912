

















































































// Components
import Fragment from "@/components/Fragment.vue";
import IconButton from "@/components/IconButton.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";

// Models
import Vue from "vue";
import store, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import correctionStore, { UninvoicedStockCorrectionStore } from "../services/store";
import { getStockWeight, InvoiceRelation, InvoiceRelationType, Stock } from "@/modules/invoiceItems/models";
import configuration from "@/models/Configuration";

interface ItemState {
  quantity: number;
  unitPrice: number;
  attributesVisible: boolean;
  selected: boolean;
}

interface Data {
  store: InvoiceFlowStore;
  correctionStore: UninvoicedStockCorrectionStore;
  itemStates: Record<number, ItemState>;
}

interface Methods {
  getStockWeight(item: Stock): number;
  initialize(items: Stock[]): void;
  quantityChanged(item: Stock, value: number): void;
  toggleItemSelection(item: Stock): void;
  submitStock(stock: Stock): void;
  addSelectedItems(): void;
  isAttachedToInvoice(item: Stock): boolean;
}

interface Computed {
  hasData: boolean;
  hasSelectedItem: boolean;
  currency: string;
}

interface Props {
  items: Stock[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Fragment, IconButton, MoneyDisplay, MoneyInput },

  data: () => ({
    store,
    correctionStore,
    itemStates: {},
    configuration,
  }),

  props: {
    items: {
      type: Array as () => Stock[],
    },
  },

  mounted() {
    this.initialize(this.items);
  },

  methods: {
    getStockWeight,

    initialize(items: Stock[]) {
      const states: Record<number, ItemState> = {};

      items.forEach((v) => {
        states[v.stockID] = {
          quantity: v.quantity,
          unitPrice: v.unitPrice,
          attributesVisible: true,
          selected: false,
        };
      });

      this.itemStates = states;
    },

    toggleItemSelection(item: Stock) {
      const state: ItemState = this.itemStates[item.stockID];
      state.selected = !state.selected;
    },

    quantityChanged(item: Stock, value: number) {
      if (value <= 0 || value > item.quantity) {
        this.itemStates = {
          ...this.itemStates,
          [item.stockID]: {
            ...this.itemStates[item.stockID],
            quantity: item.quantity,
          },
        };
      }
    },

    submitStock(stock: Stock) {
      const state: ItemState = this.itemStates[stock.stockID];
      this.correctionStore.addStock(stock, state.quantity, "");
    },

    addSelectedItems() {
      const stocks = this.items.filter((i) => this.itemStates[i.stockID]?.selected);
      stocks.forEach((i) => {
        this.submitStock(i);
      });
    },

    isAttachedToInvoice(item: Stock) {
      let relations: InvoiceRelation[] = [];

      this.correctionStore.items.forEach((i) => {
        relations = [...relations, ...i.relations];
      });

      const itemData = item as any;

      if (itemData.storeInID && !itemData.stock) {
        const stock = item as Stock;
        return relations.some((i) => i.type === InvoiceRelationType.Stock && i.relatedItemID === stock.stockID);
      }

      return false;
    },
  },

  computed: {
    hasData() {
      return this.items && this.items.length > 0;
    },

    currency() {
      return this.store.invoice.invCurrency;
    },

    hasSelectedItem() {
      return this.items.some((i) => this.itemStates[i.stockID]?.selected);
    },
  },
});
