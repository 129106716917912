






















import Vue from "vue";
import configuration, { AppConfiguration } from "../../../models/Configuration";
import Tabs from "../../../components/Tabs.vue";
import AllGpartsTab from "./AllGpartsTab.vue";

interface Data {
  configuration: AppConfiguration;
}

export default Vue.extend<Data, {}, {}>({
  components: {
    Tabs,
    AllGpartsTab,
  },

  data: () => ({
    configuration,
  }),
});
