import DebitPaymentApi from './DebitPaymentApi';
import busyIndication from "../../../utils/busyIndication";
import GeneralIngoinInvoiceDisplay, { generalIngoinInvoiceDisplayDefaultProps } from '@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay';
import DebitPayment from '../models/DebitPayment';
import { DownPaymentExtOrder } from '@/modules/downPayment/models';
import DebitPaymentEditModelComplaint from '../models/DebitPaymentEditModelComplaint';

export class DebitPaymentStore {
    //unused
    debitPayments: GeneralIngoinInvoiceDisplay[] = []
    debitPayment: GeneralIngoinInvoiceDisplay = { ...generalIngoinInvoiceDisplayDefaultProps }
    debitPaymentItems: DebitPayment[] = []
    extOrders: DownPaymentExtOrder[] = [];
    //


    debits: DebitPaymentEditModelComplaint[] = []

    async getDebitPayments(partnerId: number, searchText: string){
        this.debits = await DebitPaymentApi.getDebitPayments(partnerId, searchText);
    }

    async load() {
        this.debitPayments = await DebitPaymentApi.getAll();
    }

    async loadDebit(id: number){
        this.debitPayment = await DebitPaymentApi.get(id);
    }

    @busyIndication()
    async loadDebitItems(partnerId: number, searchText: string){
        this.debitPaymentItems = await DebitPaymentApi.getDebitItems(partnerId, searchText);
    }

    async loadExtOrders(partnerId: number, searchText: string){
        this.extOrders = await DebitPaymentApi.getExtOrders(partnerId, searchText);
    }

    async save(){
        try {
            if(this.debitPayment.incomingInvoice.id == 0)
            {
                await DebitPaymentApi.create(this.debitPayment.incomingInvoice);
            }
            else{
                await DebitPaymentApi.update(this.debitPayment.incomingInvoice);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async createGeneralIngoingInvoiceItem(item: DebitPaymentEditModelComplaint) {
        try {
            await DebitPaymentApi.createItem(item);
        } catch (error) {
            console.error(error);
        }
    }
}

const store = new DebitPaymentStore()
export default store