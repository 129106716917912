export default interface PaymentMode {
  id: number;
  name: string;
  foreign: string;
  english: string;
  deleted: boolean;
  promptPay: boolean;
  noCurrentAccount: boolean;
  creditCheck: boolean;
  sapCode: string;
  webPayMode: number;
  isConstr: boolean;
  partnerAccountCode: string;
  deleting: boolean;
}

export const paymentModeDefaultProps: PaymentMode = {
  id: 0,
  name: "",
  foreign: "",
  english: "",
  deleted: false,
  promptPay: false,
  noCurrentAccount: false,
  creditCheck: false,
  sapCode: "",
  webPayMode: 0,
  isConstr: false,
  partnerAccountCode: "",
  deleting: false,
};

enum OperelPaymentMode {
  None = 1,
  Cash = 2,
  Transaction = 3,
  BankQuery = 4,
  CreditCard = 5,
  Forwarding = 6,
  PayOnReceive = 7
}

export const shouldAutoApproveByPaymentMode = (paymentMode: OperelPaymentMode) => {
  return [OperelPaymentMode.Cash, OperelPaymentMode.CreditCard].includes(paymentMode)
}
