









































































































































































































































































































































































































































import IconButton from "@/components/IconButton.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import ApiResponse from "@/models/ApiResponse";
import configuration from "@/models/Configuration";
import http, { handleApiResponse } from "@/services/http";
import Vue from "vue";
import AccCode from "../models/AccCode";
import IncomingInvoiceAccItem from "../models/IncomingInvoiceAccItem";
import store from "../services/InvoiceFlowStore";

export default Vue.extend({
  components: { MoneyInput, IconButton },

  data() {
    return {
      store,
      configuration,
      accountItemGroupOrder: ["Partner", "Áfa", "Készlet"],
    };
  },

  async beforeMount() {
    await this.store.loadAccCodes(store.invoice.id, false);
  },

  props: {
    accItems: () => new Array<IncomingInvoiceAccItem>(),
  },

  computed: {
    accCodes() {
      return store.accCodes.map((x: AccCode) => ({
        value: x.code,
        name: `${x.code} - ${x.accName}`,
      }));
    },

    accountingPrecision(): number {
      return (
        configuration?.getCurrDigits(store.invoice.invCurrency)
          ?.accountingDigits ?? 0
      );
    },

    hufInvoice() {
      return store.invoice.invCurrency === "HUF";
    },

    columns() {
      const _columns = [
        {
          cell: "typeTemplate",
          width: 80,
          headerCell: "typeHeaderTemplate",
        },
        {
          field: "accCode",
          title: "Tartozik",
          cell: "tartozikTemplate",
          headerCell: "headerIncomeTemplate",
        },
        {
          width: 150,
          field: "total",
          title: "Összeg",
          cell: "tartozikTotalTemplate",
          headerCell: "headerIncomeTemplate",
        },
        {
          width: 150,
          field: "currTotal",
          title: "Összeg deviza",
          cell: "tartozikCurrTotalTemplate",
          headerCell: "headerIncomeTemplate",
        },
        {
          field: "accCodeDemand",
          title: "Követel",
          cell: "kovetelTemplate",
          headerCell: "headerDemandTemplate",
        },
        {
          width: 150,
          field: "totalDemand",
          title: "Összeg",
          cell: "kovetelTotalTemplate",
          headerCell: "headerDemandTemplate",
        },
        {
          width: 150,
          field: "currTotalDemand",
          title: "Összeg deviza",
          cell: "kovetelCurrTotalTemplate",
          headerCell: "headerDemandTemplate",
        },
        {
          field: "accDate",
          title: "Könyvelés dátuma",
          headerClassName: "font-weight-bold grey--text text--darken-3",
          cell: "accDateTemplate",
          width: 100,
        },
      ];

      // Insert separator column
      const separatorIndex = this.hufInvoice ? 4 : 5;

      if (this.hufInvoice) {
        return _columns.filter(
          (c) => !["currTotal", "currTotalDemand"].includes(c.field || "")
        );
      }

      return _columns;
    },
  },

  methods: {
    async loadAccItems() {
      const invoiceID = store.invoice.id;
      store.accountItems = await http
        .post<ApiResponse<IncomingInvoiceAccItem[]>>(
          `accounting/${invoiceID}/test-pre-accounting`
        )
        .then(handleApiResponse);
    },

    tartozikStockGroupClass(item: IncomingInvoiceAccItem) {
      if (!item.isDemand) {
        return item.groupClass;
      } else {
        return "";
      }
    },

    hasNote(item: IncomingInvoiceAccItem) {
      if (item && item.note && item.note.length > 1) {
        return item.note.toLowerCase().includes("ár");
      }
      return false;
    },

    getMarginUnderStockGroup(
      h: any,
      trElement: any,
      defaultSlots: any,
      props: any
    ) {
      let k = "";
      if (props.dataItem.groupClass == "groupStockItem") {
        k = "groupStockMargin";
      }

      const trProps = {
        class: k,
      };

      return h("tr", trProps, defaultSlots);
    },
  },
});
