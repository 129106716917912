import ApiResponse from "@/models/ApiResponse";
import Email from "@/modules/cashStatement/models/Email";
import http, { checkResponse, handleApiResponse } from "@/services/http";

export class ExperimentalInvoiceStore {
  expReportSrc = new Uint8Array();
  reportSrc = new Uint8Array();
  experimentalInvoice = 1;
  experimentalInvoicePrefix = "";
  extIdInvoice = 1;

  reset() {
    this.experimentalInvoice = 1;
    this.experimentalInvoicePrefix = "";
    this.reportSrc = new Uint8Array();
    this.expReportSrc = new Uint8Array();
  }

  async getSelectableEmails(partner?: number, prefix?: string) {
    return await http
      .get<ApiResponse<Email[]>>(`emails/${partner ?? 1}/${prefix??''}`)
      .then(handleApiResponse)
      .then((x) => {
        return x.map((y) => {
          return { ...y, isSelected: y.isPrimary };
        });
      });
  }

  async saveEmail(emails: Email[], invoice: number) {
    return await http.post(`emails`, { emails, invoice }).then(checkResponse);
  }
}

const store = new ExperimentalInvoiceStore();
export default store;
