export interface Dimension {
    select: boolean;
    id: number;
    year: string;
    dimcode: string;
    national: string;
    foreign: string;
    english: string;
    nationalLong: string;
    note: string;
    isAccum: boolean;
    thisLevelCharno: number;
    downLevelCharno: number;
    type: string;
    status: string;
    insDate: Date;
    user: string;
    activity: string;
    absorption: number;
}

export const DefaultDimensionProps: Dimension = {
    select: false,
    id: 1,
    year: "",
    dimcode: "-",
    national: "-",
    foreign: "-",
    english: "-",
    nationalLong: "-",
    note: "-",
    isAccum: false,
    thisLevelCharno: 1,
    downLevelCharno: 1,
    type: "-",
    status: "-",
    insDate: new Date(1901, 0, 1),
    user: "-",
    activity: "-",
    absorption: 1
}