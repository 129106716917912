





















import Vue from "vue";
import Tooltip from "./Tooltip.vue";

export default Vue.extend({
  components: { Tooltip },

  props: {
    type: {
      type: String,
      default: "button",
    },

    outlined: {
      type:Boolean,
      default: true
    },

    icon: {
      type: String,
      default: "fa-plus",
    },

    tooltip: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: "primary",
    },

    btnStyles: {
      type: String,
      default: "",
    },

    btnClasses: {
      type: String,
      default: "",
    },

    top: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clickHandler() {
      this.$emit("click");
    },
  },
});
