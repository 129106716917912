

















































































































































































































// Models
import Vue from "vue";
import eventBus from "@/services/eventBus";
import store, { CashStatementStore } from "../services/store";
import pStore, { PartnerStore } from "@/modules/partner/services/PartnerStore";
import Country, {
  countryDefaultProps,
} from "@/modules/cashPoint/models/Country";
import User, { userDefaultProps } from "@/modules/invoiceGroup/models/User";
import { formatDate, formatDateISO, newDate } from "@/utils/formatDates";
import Partner from "@/modules/partner/models/Partner";
import CashDocument from "../models/CashDocument";
import CurrencyRateApi from "@/modules/currencyRate/services/CurrencyRateApi";
// Components
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import dayjs from "dayjs";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import { cashDocumentStatusEnum } from "../models/CashDocumentStatus";

interface Data {
  cashDocument: CashDocument;
  createdID: number | void;
  store: CashStatementStore;
  pStore: PartnerStore;
  selectedCurrency: Country | undefined;
  selectedCashier: User | undefined;
  closeDate: string;
  isDocumentDateReadOnly: boolean;
  minCloseDate: string;
  maxCloseDate: string;
}

interface Methods {
  save(): void;
  incomeTypeChanged(newValue: boolean): void;
  isMonthOrDayCloseCheck(): void;
  selectedPartnerChanged(partner: Partner): void;
  selectedCurrencyChanged(currency: string): void;
  voucherDateChanged(value: string): void;
}

interface Computed {
  validation: string;
  rateDate: string;
}

const DEFAULT_MIN_DATE = "2000-01-01";

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    PartnerSearch,
    ValidationError,
  },

  data: () => ({
    cashDocument: new CashDocument(),
    createdID: 0,
    store,
    pStore,
    selectedCurrency: { ...countryDefaultProps },
    selectedCashier: { ...userDefaultProps },
    closeDate: "",
    isDocumentDateReadOnly: false,
    minCloseDate: DEFAULT_MIN_DATE,
    maxCloseDate: "",
  }),

  async beforeMount() {
    const cashStatementId = Number(this.$route.params.cashStatementId) || 0;
    this.cashDocument = new CashDocument();

    await this.pStore.loadCurrencies();

    this.selectedCurrency = this.pStore.currencies.find(
      (x) => x.currency === this.store.cashPoint.currency
    );
    if (!this.selectedCurrency && this.pStore.currencies.length > 0) {
      this.selectedCurrency = this.pStore.currencies[0];
    }

    this.cashDocument.initialize(
      this.store.cashPoint,
      cashStatementId,
      this.selectedCurrency
    );

    this.selectedCashier = this.store.currentUser;

    const today = new Date();
    this.closeDate = formatDateISO(today);
    this.isMonthOrDayCloseCheck();
    this.maxCloseDate = formatDateISO(today);
  },

  methods: {
    async voucherDateChanged(value) {
      if (
        this.store.cashDocuments.some(
          (x) => dayjs(x.closeDate).unix() > dayjs(value).unix() && x.status !== "Rontott"
        )
      ) {
        this.closeDate = dayjs(Date.now()).format("YYYY-MM-DD");
        eventBus.$emit(
          "error",
          "A bizonylat dátuma nem lehet régebbi, mint az előző bizonylaté!"
        );
        return;
      }

      if (dayjs(Date.now()).unix() < dayjs(value).unix()) {
        this.closeDate = dayjs(Date.now()).format("YYYY-MM-DD");
        eventBus.$emit(
          "error",
          "A bizonylat dátuma nem lehet a mai napnál későbbi!"
        );
        return;
      }

      try{await http
        .post(`cash-document/check-date`, { value })
        .then(handleApiResponse);

        this.closeDate = value;
        this.cashDocument.rateDate = value;
      }
      catch(ex){
        console.log(ex)
        this.closeDate = dayjs(Date.now()).format("YYYY-MM-DD");
        return;
      }

      this.closeDate = value;
      this.cashDocument.rateDate = value;
    },

    async save() {
      // this.cashDocument.partnerName = this.cashDocument.partnerName.toUpperCase();
      this.createdID = await this.store.createCashDocument(this.cashDocument);
      if (this.createdID > 0) {
        this.cashDocument.income
          ? this.store.cashPoint.inDocNo++
          : this.store.cashPoint.outDocNo++;
      }
      this.$emit("value", this.createdID);
    },

    incomeTypeChanged(newValue) {
      this.cashDocument.uniqueNo = newValue
        ? this.store.cashPoint.inDocNo + 1
        : this.store.cashPoint.outDocNo + 1;

      this.isMonthOrDayCloseCheck();
    },

    isMonthOrDayCloseCheck() {
      if (this.store.cashPoint.isDay) {
        this.minCloseDate = this.closeDate = formatDateISO(
          this.store.cashStatement.extractDate
        );
        this.isDocumentDateReadOnly = true;
      } else if (this.store.cashPoint.isMonth) {
        const lastDate = formatDateISO(this.store.cashStatement.extractDate);
        let date = "";
        if (this.cashDocument.income) {
          date = formatDateISO(this.store.cashStatement.lastIncome?.closeDate);
        } else {
          date = formatDateISO(this.store.cashStatement.lastExpense?.closeDate);
        }
        this.minCloseDate = date != "" ? date : lastDate;
        this.isDocumentDateReadOnly = false;
      } else {
        const dates = this.store.cashDocuments
          .filter((x) => x.document.status == 4)
          .map((x) => x.closeDate)
          .sort();
        this.minCloseDate =
          dates.length > 0 ? formatDateISO(dates[0]) : DEFAULT_MIN_DATE;
      }
    },

    selectedPartnerChanged(partner) {
      this.cashDocument.partner = partner.id;
      this.cashDocument.partnerName = partner.name;
    },

    async selectedCurrencyChanged(currency) {
      this.cashDocument.currency = currency;
      if (currency === "HUF") {
        this.cashDocument.exchangeRate = 1;
        this.cashDocument.rateDate = this.cashDocument.closeDate;
        return;
      }

      await CurrencyRateApi.getRateAndDate(
        this.cashDocument.currency,
        this.cashDocument.closeDate
      ).then((result) => {
        this.cashDocument.exchangeRate = result
          ? result.centralRate / result.unit
          : 1;
        this.cashDocument.rateDate = formatDateISO(
          result?.rateDate ?? this.cashDocument.closeDate
        );
      });
    },
  },

  watch: {
    minCloseDate(newValue) {
      if (newValue > this.closeDate) {
        eventBus.$emit(
          "warn",
          "Típus változás miatt a Bizonylat dátumának alsó határa is megváltozott."
        );
        this.closeDate = newValue;
      }
    },

    async closeDate(newValue) {
      this.cashDocument.closeDate = newDate(newValue);
      this.cashDocument.rateDate = newDate(newValue);
      if (!newValue || this.cashDocument.currency === "HUF") return;

      await CurrencyRateApi.getRateAndDate(
        this.cashDocument.currency,
        newValue
      ).then((result) => {
        this.cashDocument.exchangeRate = result.centralRate / result.unit;
        this.cashDocument.rateDate = formatDateISO(newValue);
      });
    },
  },

  computed: {
    validation() {
      if (this.cashDocument.cashAccount == 0) {
        return "Pénztár megadása közelező.";
      }
      if (
        this.cashDocument.partner == 0 &&
        this.cashDocument.partnerName.trim() === ""
      ) {
        return "Partner vagy befizető/átvevő megadása közelező.";
      }
      if (!(this.cashDocument.partnerName.length > 0)) {
        return "Átvevő / Befizető nevének megadása kötelező.";
      }
      if (!this.cashDocument.closeDate) {
        return "Hibás a megadott Bizonylat dátum.";
      }
      if (this.cashDocument.closeDate < this.minCloseDate) {
        return `A Bizonylat dátuma nem lehet az előző bizonylat dátuma előtt: ${formatDate(
          new Date(this.minCloseDate)
        )}`;
      }
      if (formatDateISO(this.cashDocument.closeDate) > this.maxCloseDate) {
        return "A Bizonylat dátuma nem lehet jövőbeli.";
      }
      if (this.cashDocument.exchangeRate == 0) {
        return "Az árfolyam nem lehet 0.";
      }
      return "";
    },

    rateDate() {
      return formatDate(this.cashDocument.rateDate);
    },
  },
});
