






import Vue from "vue";
import StatusColorMap from "@/models/StatusColorMap";

export default Vue.extend({
  props: {
    value: [Number, String],
    status: String,
    coloring: Object as () => StatusColorMap,
    block: { type: Boolean, default: true },
  },

  computed: {
    classes(): string {
      const block = this.block ? "d-block " : "";
      /* return [block, this.coloring.get(this.value)].filter((x) => !!x).join(" "); */
      return block;
    },

    color(){
      return this.coloring.get(this.value);
    }
  },
});
