


























































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import { ValidationRules } from "../../../models/Validation";
import User from "../models/User";
import InvoiceGroupType from "../models/InvoiceGroupType";
import DivisionItem from "../models/DivisionItem";
import store, { InvoiceGroupStore } from "../services/InvoiceGroupStore";

interface Data {
  store: InvoiceGroupStore;
  opened: boolean;
  valid: boolean;
  selectedControlPerson: User | undefined;
  selectedAcceptor: User | undefined;
  selectedInvoiceGroupType: InvoiceGroupType | undefined;
  selectedDivisionItem: DivisionItem | undefined;
  //rules: ValidationRules;
}

const CREATE_TITLE = "Költséghely létrehozása";
const MODIFY_TITLE = "Költséghely adatok módosítása";

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      //rules,
      valid: true,
      opened: false,
      selectedControlPerson: undefined,
      selectedAcceptor: undefined,
      selectedInvoiceGroupType: undefined,
      selectedDivisionItem: undefined,
    };
  },

  computed: {
    title() {
      return this.store.invoiceGroup?.id > 0 ? MODIFY_TITLE : CREATE_TITLE;
    },
  },

  async beforeMount() {
    const invoiceGroupId = Number(this.$route.params.invoiceGroupId);
    await Promise.all([
      this.store.loadInvoiceGroup(invoiceGroupId),
      this.store.loadUsers(),
      this.store.loadInvoiceGroupTypes(),
      this.store.loadDivisionItems(),
    ]);

    this.selectedControlPerson = this.store.users.find(
      (x) => x.id == this.store.invoiceGroup.controlPerson
    );
    this.selectedAcceptor = this.store.users.find(
      (x) => x.id == this.store.invoiceGroup.user
    );
    this.selectedInvoiceGroupType = this.store.invoiceGroupTypes.find(
      (x) => x.id == this.store.invoiceGroup.contrType
    );
    this.selectedDivisionItem = this.store.divisionItems.find(
      (x) => x.id == this.store.invoiceGroup.defDivItem
    );
  },

  methods: {
    async save() {
      await this.store.save();
      this.$router.push("/settings/invoice-group");
    },

    selectedControlPersonChanged(event: any) {
      //this.selectedControlPerson = event.value;
      this.store.invoiceGroup.controlPerson = event.id;
    },

    selectedAcceptorChanged(event: any) {
      //this.selectedAcceptor = event.value;
      this.store.invoiceGroup.user = event.id;
    },

    selectedInvoiceGroupTypeChanged(event: any) {
      //this.selectedInvoiceGroupType = event.value;
      this.store.invoiceGroup.contrType = event.id;
    },

    selectedDivisionItemChanged(event: any) {
      //this.selectedDivisionItem = event.value;
      this.store.invoiceGroup.defDivItem = event.id;
    },
  },
});
