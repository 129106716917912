import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import PowerBIResponse from '../models/PowerBIResponse'

export default class PowerBIApi {
    static async get(reportID: string): Promise<PowerBIResponse> {
        const response = await http
            .get<ApiResponse<PowerBIResponse>>(`powerbi/report/${reportID}`)
        return handleApiResponse(response)
    }

    static getAll(): Promise<PowerBIResponse[]> {
        return http
            .get<ApiResponse<PowerBIResponse[]>>(`powerbi/reports`)
            .then(handleApiResponse)
    }

    static getAllGeneralLedger(): Promise<PowerBIResponse[]> {
        return http
            .get<ApiResponse<PowerBIResponse[]>>(`powerbi/reports/general-ledger`)
            .then(handleApiResponse)
    }
}