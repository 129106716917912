
































import Vue from "vue";
import store, { InvoiceBgViewStore } from "../services/InvoiceBgViewStore";

interface Data {
  store: InvoiceBgViewStore;
  skip: number;
  take: number;
  total: number;
}

interface Methods {
  ok(): void;
  cancel(): void;
  pageChange(event: any): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  async beforeMount() {
    // await this.store.loadInvoicesBgView();
    this.total = this.store.invoicesBgView?.length;
  },

  data() {
    return {
      store,
      skip: 0,
      take: 10,
      total: 0,
    };
  },

  methods: {
    ok() {
      this.$emit("ok");
    },

    cancel() {
      this.$emit("cancel");
    },

    pageChange(event: any) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
  },

  computed: {
    items() {
      return this.store.invoicesBgView.slice(this.skip, this.take + this.skip);
    },
  },
});
