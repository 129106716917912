





















// Models
import Vue from "vue";
import store from "../../services/InvoiceFlowStore";

// Components
import IncomingInvoiceTimeline from "./IncomingInvoiceTimeline.vue";
import IconButton from "@/components/IconButton.vue";

export default Vue.extend({
  components: {
    IncomingInvoiceTimeline,
    IconButton,
  },

  data: () => ({
    store,
    activeTab: null,
    visible: true,
  }),

  methods: {},
});
