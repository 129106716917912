import busyIndication from "../../../utils/busyIndication";
import InvoiceGroupApproval, { invoiceGroupApprovalDefaultProps } from "../models/InvoiceGroupApproval";
import InvoiceGroupApprovalApi from "./InvoiceGroupApprovalApi"

export class InvoiceGroupApprovalStore {
    invoiceGroupApprovals: InvoiceGroupApproval[] = []
    invoiceGroupApproval: InvoiceGroupApproval = { ...invoiceGroupApprovalDefaultProps }

    @busyIndication()
    // async loadInvoiceGroupApprovals() {
    //     this.invoiceGroupApprovals = await InvoiceGroupApprovalApi.getAll();
    // }

    @busyIndication()
    async loadInvoiceGroupApproval(invoiceGroupApprovalId: number) {
        this.invoiceGroupApproval = invoiceGroupApprovalId > 1 ? await InvoiceGroupApprovalApi.get(invoiceGroupApprovalId) : { ...invoiceGroupApprovalDefaultProps }
    }
}

const store = new InvoiceGroupApprovalStore()
export default store
