var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Elszámolási előleg/ bankkártya kezelés ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"sm":"4"}},[_c('label',{staticClass:"input-label",attrs:{"align":"right"}},[_vm._v(" Elszámolás ")])]),_c('v-col',{attrs:{"sm":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","items":_vm.cashPointStore.typedCashpoints,"item-text":"name","item-value":"id"},model:{value:(_vm.transaction.selectedCashPoint),callback:function ($$v) {_vm.$set(_vm.transaction, "selectedCashPoint", $$v)},expression:"transaction.selectedCashPoint"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.transaction.selectedCashPoint>1)?_c('ApiSearchPage',{ref:"asref",attrs:{"name":"backpayment-handler","url":_vm.url,"columns":_vm.columns},scopedSlots:_vm._u([{key:"select",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{attrs:{"tooltip":"Tétel generálás"},on:{"click":function($event){return _vm.generateBackpayment(item)}}})]}},{key:"transactionAmount",fn:function(ref){
var item = ref.item;
return [_c('MoneyInput',{attrs:{"currency":_vm.cashStatementStore.cashDocument.invCurrency,"maxValue":item.amount},model:{value:(item.transactionAmount),callback:function ($$v) {_vm.$set(item, "transactionAmount", $$v)},expression:"item.transactionAmount"}}),_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"info-circle",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" fa-info-circle ")])]}}],null,true)},[_c('span',[_vm._v(" Maximálisan mvisszavehető összeg: "+_vm._s(_vm._f("moneyFormat")(item.amount))+" ")])])]}}],null,false,2200846115)}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Mentés ")]),_c('v-btn',{staticClass:"whiteBtn",on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Mégse ")]),_c('div',{staticClass:"mr-4"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }