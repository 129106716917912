export default interface PartBank {
    id: number;
    accountNo: string;
    accountNoSplit: string;
    bank: string;
    currency: string;
    transferDay: number | null;
    own: boolean;
    primaryAccNo: boolean;
    technical: boolean;
    accCode: string;
    detailedTypeId: number;
    detailedType: string;
    ibanNo: string;
    swiftNo: string;
    isAlive: boolean;
    isAutoCount: boolean;
    autoCountFrom: number;
    autoCountTo: number;
    isNative: boolean;
    inEdit: boolean;
    partner: number;
    deleted: boolean;
    detailsOfCharges: number;
    path: string;
}

export const partBankDefaultProps: PartBank = {
    id: 0,
    accountNo: '',
    accountNoSplit: '',
    bank: '',
    currency: '',
    transferDay: null,
    own: false,
    primaryAccNo: false,
    technical: false,
    accCode: '',
    detailedTypeId: 1,
    detailedType: '',
    ibanNo: '',
    swiftNo: '',
    isAlive: false,
    isAutoCount: false,
    autoCountFrom: 0,
    autoCountTo: 0,
    isNative: false,
    inEdit: false,
    partner: 0,
    deleted: false,
    detailsOfCharges: 1,
    path: ''
}

export const partBankTechProps: PartBank = {
    id: 1,
    accountNo: '-',
    accountNoSplit: '-',
    bank: '-',
    currency: '-',
    transferDay: null,
    own: false,
    primaryAccNo: false,
    technical: false,
    accCode: '',
    detailedTypeId: 1,
    detailedType: '',
    ibanNo: '',
    swiftNo: '',
    isAlive: false,
    isAutoCount: false,
    autoCountFrom: 0,
    autoCountTo: 0,
    isNative: false,
    inEdit: false,
    partner: 0,
    deleted: false,
    detailsOfCharges: 1,
    path: ''
}