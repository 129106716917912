












import BankAccountPicker from "@/modules/invoiceFlow/components/BankAccountPicker.vue"
import PartBank from "@/modules/cashPoint/models/PartBank"
import PartBankStore from "@/modules/partBank/services/partBankStore"
import Vue from "vue"

interface Data {
    partBankStore: PartBankStore;
}

interface Props {
    value: number;
    disabled: boolean;
    addNew: boolean;
    partnerId: number;
    onlyMyOwn: boolean;
}

interface Computed {
    partBank: PartBank | undefined;
}

export default Vue.extend<Data,{},Computed,Props>({
    components: { BankAccountPicker },
    props: {
        value: {type:Number, default:0},
        disabled: {type:Boolean, default:false},
        addNew: {type:Boolean, default:false},
        partnerId: {type:Number, default:0},
        onlyMyOwn: {type: Boolean, default:false}
    },
    data() {
        return {
            partBankStore: new PartBankStore(),
        }
    },
    async beforeMount() {
        if(this.onlyMyOwn)
            await this.partBankStore.setMyOwn()
        else if(this.partnerId === 0)
            await this.partBankStore.set()
        else if(this.partnerId !== 0)
            await this.partBankStore.setByPartner(this.partnerId)
    },
    methods: {
        selected(partBank: PartBank) {
            this.$emit("input", partBank.id)
            this.$emit("change", partBank.id)
        }
    },
    computed: {
        partBank:{
            get(): PartBank | undefined {
                return this.partBankStore.get(this.value);
            },
            set(newValue: PartBank | undefined) {
                if(newValue){
                    this.$emit("input", newValue.id);
                    this.$emit("change", newValue.id);
                }
                else{
                    this.$emit("input", 0);
                    this.$emit("change", 0);
                }
            }
        }
    }  
})

