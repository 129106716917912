import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import InvoiceItemDimension from "../models/InvoiceItemDimension";
import InvoiceItemOtherDimension from '../models/InvoiceItemOtherDImension';
import InvItemAndDimensionsById from '@/modules/invoiceFlow/models/InvItemAndDimensionsById';
import GeneralIngoingInvoiceItemMinEditModel from '@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItemMinEditModel';

export default class InvoiceItemDimensionApi {
    static loadInvItemAndDimensionsById(invoiceId: number): Promise<InvItemAndDimensionsById[]> {
        return http
            .get<ApiResponse<InvItemAndDimensionsById[]>>(`invoice-item-dimension/item-dimensions/${invoiceId}`)
            .then(handleApiResponse);
    }

    static getAllByInvItemId(invItemId: number, isCurr: boolean): Promise<InvoiceItemDimension[]> {
        return http
            .get<ApiResponse<InvoiceItemDimension[]>>(`invoice-item-dimension/${invItemId}/${isCurr}`)
            .then(handleApiResponse)
    }

    static create(invoiceItemDimension: InvoiceItemDimension, preAccount: boolean, isCurr: boolean): Promise<number> {
        return http
            .post<ApiResponse<number>>(`invoice-item-dimension/${preAccount}/${isCurr}`, invoiceItemDimension)
            .then(handleApiResponse)
    }

    static update(invoiceItemDimension: InvoiceItemDimension, preAccount: boolean, isCurr: boolean): Promise<boolean> {
        return http
            .put(`invoice-item-dimension/${invoiceItemDimension.id}/${preAccount}/${isCurr}`, invoiceItemDimension)
            .then(checkResponse)
    }

    static delete(itemId: number, invGroup: number, preAccount: boolean): Promise<boolean> {
        return http
            .delete(`invoice-item-dimension/${itemId}/${invGroup}/${preAccount}`)
            .then(checkResponse)
    }

    static clearAllDimensions(invId: number): Promise<boolean> {
        return http
            .delete(`invoice-item-dimension/clear-dims/${invId}`)
            .then(checkResponse)
    }

    //other
    static createOtherDimensionVersion2(otherDimension: InvoiceItemOtherDimension, preAccount: boolean): Promise<number> {
        const payload = Object.freeze({ otherDimension });
        return http
            .post<ApiResponse<number>>(`invoice-item-dimension/other-dimension/version2/${preAccount}`, payload)
            .then(handleApiResponse)
    }

    //dont use
    static createOtherDimension(invoiceItemDimension: InvoiceItemDimension, preAccount: boolean): Promise<number> {
        return http
            .post<ApiResponse<number>>(`invoice-item-dimension/other-dimension/${preAccount}`, invoiceItemDimension)
            .then(handleApiResponse)
    }

    static deleteOtherDimension(itemId: number, preAccount: boolean): Promise<boolean> {
        return http
            .delete(`invoice-item-dimension/other-dimension/${itemId}/${preAccount}`)
            .then(checkResponse)
    }

    static updateOtherDimension(invoiceItemOtherDimension: InvoiceItemOtherDimension, preAccount: boolean): Promise<boolean> {
        return http
            .put(`invoice-item-dimension/other-dimension/${invoiceItemOtherDimension.id}/${preAccount}`, invoiceItemOtherDimension)
            .then(checkResponse)
    }

    static updateComment(id: number, item: GeneralIngoingInvoiceItemMinEditModel): Promise<boolean> {
        return http
            .put(`general-incoming-invoice/item/${id}/comment`, item)
            .then(checkResponse)
    }
}
