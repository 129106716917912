/**
 * Capizalize the first character in a string
 * @param s text to modify
 * @returns text with capitalized first character
 */
export function capitalize(s: string | number[]) {
  if(typeof(s)==="string")
  return (s && s[0].toUpperCase() + s.slice(1)) || "";
}

/**
 * Lowercase the first character in a string
 * @param s text to modify
 * @returns text lowercased first character
 */
export function lowercase(s: string) {
  return (s && s[0].toLowerCase() + s.slice(1)) || "";
}
