




























































































































































































import Vue from "vue";
import flowStore, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import GeneralIngoingInvoiceItemDisplay from "../../generalIngoingInvoice/models/GeneralIngoingInvoiceItemDisplay";
import store, { InvoiceItemStore } from "../../invoiceItems/services/store";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import CheckBox from "@/components/CheckBox.vue";
import { ConfirmationMixin } from "@/mixins/confirmation";
import ConfirmationForTable from "../../../components/ConfirmationForTable.vue";
import IconButton from "../../../components/IconButton.vue";
import Fragment from "@/components/Fragment.vue";
import * as api from "../services/api";
import MoneyInput from "@/components/MoneyInput.vue";
import ItemIt from "../../generalIngoingInvoice/models/ItemIt";
import StoreInTemp from "../../generalIngoingInvoice/models/StoreInTemp";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Props {
  item: GeneralIngoingInvoiceItemDisplay;
  currency: string;
  invoice: number;
  isSupplierComplaint: boolean;
}

interface Data {
  flowStore: InvoiceFlowStore;
  store: InvoiceItemStore;
  stockChangeInProgress: boolean;
  stockSwapInProgress: boolean;
  searchText: string;
  itemsForStockChange: GeneralIngoingInvoiceItemDisplay[];
  itemsForStockSwap: StoreInTemp[];
  swappedStocks: any[];
  configuration: AppConfiguration;
}

interface Methods {
  select(): void;
  clear(): void;
  switchStock(item: any): void;
  swapStock(item: any): void;
  searchStocks(e: KeyboardEvent): void;
  swapStocks(e: KeyboardEvent): void;
  calculateNewItemValues(): void;
  findTax(): number;
  addCorrectionItem(): void;
  getCurrUnitDigits(currency: string): number;
}

interface Computed {
  isSelected: boolean;
}

export default ConfirmationMixin.extend<Data, Methods, Computed, Props>({
  props: {
    item: Object as () => GeneralIngoingInvoiceItemDisplay,
    currency: String,
    invoice: { type: Number, default: 1 },
    isSupplierComplaint: { type: Boolean, default: false },
  },

  components: {
    MoneyDisplay,
    CheckBox,
    ConfirmationForTable,
    Fragment,
    IconButton,
    MoneyInput
  },

  mixins: [ConfirmationMixin],

  data: () => ({
    flowStore,
    store,
    stockChangeInProgress: false,
    stockSwapInProgress: false,
    searchText: "",
    itemsForStockChange: [],
    itemsForStockSwap: [],
    swappedStocks: [],
    configuration,
  }),

  async mounted() {
    await this.flowStore.loadAllTaxes();
  },

  methods: {
   async select() {
      if (!this.$props.isSupplierComplaint) {
        if (!this.isSelected) {
          //AQLT1183
          if ((this.item as any).creditingStoreInDetails != null && this.item.incomingInvoiceItem.stock > 1 && this.item.incomingInvoiceItem.parts > 1 && this.configuration.isStockStToRectInv.enabled) {
            const confirmed = await this.confirm(`Szeretne az eredeti számla tétel készletét jóváíró stock-ok közül választani?`);
            if (confirmed) {
              this.stockSwapInProgress = true;
              this.store.rectItemsToCreate.push(this.item);
              await api
                .getInvoiceItemsForStockSwap("", Number(this.flowStore.invoice.partner), this.item.incomingInvoiceItem.stock, this.item.incomingInvoiceItem.parts)
                .then(
                  (result: any[]) => (this.itemsForStockSwap = result)
                );
              this.itemsForStockSwap = this.itemsForStockSwap.map((x) => ({...x, isSelected: false}));
              return;
            }
            else {
              //CRNT1260
              this.calculateNewItemValues();
              ///
              this.stockSwapInProgress = false;
              this.store.rectItemsToCreate.push(this.item);
            }
          }
          ///
          if ((this.item as any).creditingStoreInDetails == null && this.item.incomingInvoiceItem.stock > 1 && this.item.incomingInvoiceItem.parts > 1) {
            const confirmed = await this.confirm(`Ehhez a betároláshoz nem tartozik jóváíró betárolás. Biztos ezt a betárolást választja?`);
            if (!confirmed) {
              this.stockChangeInProgress = true;
              await api
                .getInvoiceItemsForStockChange("", Number(this.flowStore.invoice.partner), this.item.incomingInvoiceItem.stock, this.item.incomingInvoiceItem.parts)
                .then(
                  (result: any[]) => (this.itemsForStockChange = result)
                );
              this.itemsForStockChange = this.itemsForStockChange.map((x) => ({...x, isSelected: false}));
              return;
            }
            else {
              this.store.rectItemsToCreate.push(this.item);
            }
          }
          else {
            const found = this.store.rectItemsToCreate.find((x) => x.incomingInvoiceItem.id == this.item.incomingInvoiceItem.id);
            if (!found) {
              this.store.rectItemsToCreate.push(this.item);
            }
          }
        } else {
          this.store.rectItemsToCreate = this.store.rectItemsToCreate.filter((x) => x.incomingInvoiceItem.id != this.item.incomingInvoiceItem.id);
          this.store.correctionGpartsToCreate = this.store.correctionGpartsToCreate.filter((x) => x.incomingInvoiceItem.id != this.item.incomingInvoiceItem.id);
        }
        ///
      } else {
        if (!this.isSelected) {
          this.store.supplComplItemsToCreate.push(this.item);
        } else {
          this.store.supplComplItemsToCreate = this.store.rectItemsToCreate.filter(
            (x) => x.incomingInvoiceItem.id != this.item.incomingInvoiceItem.id
          );
        }
      }
    },

    addCorrectionItem(){
      const copy = JSON.parse(JSON.stringify(this.item));
      copy.incomingInvoiceItem.currNetto = (copy.creditingStoreInDetails.currUnitPrice * copy.incomingInvoiceItem.quantity) - copy.incomingInvoiceItem.currNetto;
      this.store.correctionGpartsToCreate.push(copy);
    },

    calculateNewItemValues(){
      if (this.item.incomingInvoiceItem.currNetto != this.item.incomingInvoiceItem.currUnitPrice * this.item.incomingInvoiceItem.quantity) {
        this.item.incomingInvoiceItem.currNetto = this.item.incomingInvoiceItem.currUnitPrice * this.item.incomingInvoiceItem.quantity;
        this.item.incomingInvoiceItem.currBrutto = (this.item.incomingInvoiceItem.currUnitPrice * this.item.incomingInvoiceItem.quantity) * (1 + this.findTax());
        this.item.incomingInvoiceItem.currVat = (this.item.incomingInvoiceItem.currBrutto) - (this.item.incomingInvoiceItem.currNetto);

        this.item.incomingInvoiceItem.unitPrice = this.item.incomingInvoiceItem.currUnitPrice * this.flowStore.invoice.exchangeRate;
        this.item.incomingInvoiceItem.netto = this.item.incomingInvoiceItem.currUnitPrice * this.flowStore.invoice.exchangeRate * this.item.incomingInvoiceItem.quantity;
        this.item.incomingInvoiceItem.brutto = (this.item.incomingInvoiceItem.currUnitPrice * this.flowStore.invoice.exchangeRate * this.item.incomingInvoiceItem.quantity) * (1 + this.findTax());
        this.item.incomingInvoiceItem.vat = (this.item.incomingInvoiceItem.brutto) - (this.item.incomingInvoiceItem.netto);

        this.addCorrectionItem();
      }
    },

    findTax(){
      const tax = this.flowStore.taxes.find((x) => x.id == this.item.incomingInvoiceItem.tax);
      return ((tax?.degree ?? 27)/100);
    },

    clear(){
      this.searchText = "";
      this.itemsForStockChange = [];
      this.itemsForStockSwap = [];
    },

    switchStock(item: GeneralIngoingInvoiceItemDisplay){
      if (!item.isSelected) {
        const temp = { ...this.item };
        temp.incomingInvoiceItem.stock = item.incomingInvoiceItem.stock;
        temp.incomingInvoiceItem.originalItemId = item.incomingInvoiceItem.id;
        temp.incomingInvoiceItem.quantity = item.incomingInvoiceItem.quantity;
        this.store.rectItemsToCreate.push(JSON.parse(JSON.stringify(temp)));
      }
      else {
        this.store.rectItemsToCreate = this.store.rectItemsToCreate.filter((x) => x.incomingInvoiceItem.stock != item.incomingInvoiceItem.stock);
      }
      item.isSelected = !item.isSelected;
    },

    swapStock(item: any) {
      if (!item.isSelected) {
        const temp = { ...this.item };
        const it: ItemIt = {
          id: 0,
          invNo: Number(this.$route.params.invoiceId),
          item: 1,
          stock: item.creditingStockId,
          netto: 0,
          currNetto: 0,
        };
        this.item.invoiceItemIt.push(it);
        //this.swappedStocks.push(it);
        //this.store.rectItemsToCreate.push(JSON.parse(JSON.stringify(temp)));
      }
      else {
        this.item.invoiceItemIt = this.item.invoiceItemIt.filter((x) => x.stock != item.creditingStockId);
      }
      item.isSelected = !item.isSelected;
    },

    async searchStocks(e: KeyboardEvent){
      if (e.key != "Enter") {
        return;
      }
      
      await api
        .getInvoiceItemsForStockChange(this.searchText, Number(this.flowStore.invoice.partner), this.item.incomingInvoiceItem.stock, this.item.incomingInvoiceItem.parts)
        .then(
          (result: any[]) => (this.itemsForStockChange = result)
        );

      this.itemsForStockChange = this.itemsForStockChange.map((x) => ({...x, isSelected: false}))
    },

    async swapStocks(e: KeyboardEvent){
      if (e.key != "Enter") {
        return;
      }
      
      await api
        .getInvoiceItemsForStockSwap(this.searchText, Number(this.flowStore.invoice.partner), this.item.incomingInvoiceItem.stock, this.item.incomingInvoiceItem.parts)
        .then(
          (result: any[]) => (this.itemsForStockSwap = result)
        );

      this.itemsForStockSwap = this.itemsForStockSwap.map((x) => ({...x, isSelected: false}))
    },

    getCurrUnitDigits(currency: string) {
      const digits = this.configuration.currencyDigits.find((x) => x.currency == currency)?.unitDigits ?? 2;
      return digits;
    }
  },

  computed: {
    isSelected() {
      if (!this.$props.isSupplierComplaint) {
        return store.rectItemsToCreate.includes(this.item);
      }
      return store.supplComplItemsToCreate.includes(this.item);
    },
  },
});
