export default interface PartnerCustoms {
    id: number;
    partner: number;
    dutyAccCode: string;
    dutyVatAccCode: string;

    editing: boolean;
    deleting: boolean;
}

export const partnerCustomsDefaultProps: PartnerCustoms = {
    id: 0,
    partner: 0,
    dutyAccCode: "",
    dutyVatAccCode: "",

    editing: false,
    deleting: false,
}