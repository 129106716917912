import GeneralIngoingInvoice, {
  generalIngoingInvoiceDefaultProps,
} from "./../../generalIngoingInvoice/models/GeneralIngoinInvoice";

export default interface GeneralIngoingInvoiceEditModel {
  entity: GeneralIngoingInvoice;

  id: number;
  invNo: string;
  invoiceType: string;
  bankAccountNo: string;
  bank: string;
  currency: string;

  isStockSelected: boolean;
  partner: string;
  flowStatusName: string;
  flowStatus: number;
  flowInsDate: Date | string;
  flowInsTime: Date | string;
  flowModDate: Date | string;
  flowModTime: Date | string;
  flowRemark: string;
  flowUserName: string;
  permitStatusName: string;
  daysSpentInStatus: number;
  relatedInvoice: boolean;
  wrongItem: boolean;
  accStatus: string;
  isStockCorrection: boolean;
  statusName: string;
  dpaymInv: number[];
  preDpaymInv: number[];
  isIndirectNoserv: boolean;
  intrastatIds: string;

  regNums: string[];
  bodyNums: string[];

  expanded: boolean;
  selected: boolean;
}

export const generalIngoingInvoiceEditModelDefaultProps: GeneralIngoingInvoiceEditModel = {
  entity: { ...generalIngoingInvoiceDefaultProps },

  id: 1,
  invNo: "-",
  invoiceType: "-",
  bankAccountNo: "-",
  bank: "-",
  currency: "-",

  isStockSelected: false,
  partner: "",
  flowStatusName: "",
  flowStatus: 0,
  flowInsDate: "1980-01-01",
  flowInsTime: "1980-01-01",
  flowModDate: "1980-01-01",
  flowModTime: "1980-01-01",
  flowRemark: "",
  flowUserName: "",
  permitStatusName: "",
  daysSpentInStatus: 0,
  relatedInvoice: false,
  wrongItem: false,
  accStatus: "",
  isStockCorrection: false,
  statusName: "",
  dpaymInv: [],
  preDpaymInv: [],
  isIndirectNoserv: false,
  intrastatIds: "",

  regNums: [],
  bodyNums: [],

  expanded: false,
  selected: false,
};
