import CustomsInvoiceApi from "./CustomsInvoiceApi";

import GeneralIngoinInvoice, {
  generalIngoingInvoiceDefaultProps,
} from "../../generalIngoingInvoice/models/GeneralIngoinInvoice";
import GeneralIngoingInvoiceItemDisplay from "../../generalIngoingInvoice/models/GeneralIngoingInvoiceItemDisplay";
import CustomsInvoiceDetails, { customsInvoiceDetailsDefaultProps } from "../models/CustomsInvoiceDetails";
import GeniForCustomSelection from "../models/GeniForCustomSelection";
import Tax from "../../generalIngoingInvoice/models/Tax";
import GeneralIngoinInvoiceDisplay from "../../generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import busyIndication from "../../../utils/busyIndication";
import cuid from "cuid";

export class CustomsInvoiceStore {
  invoices: GeneralIngoinInvoiceDisplay[] = [];
  invoice: GeneralIngoinInvoice = { ...generalIngoingInvoiceDefaultProps };
  invoiceItems: GeneralIngoingInvoiceItemDisplay[] = [];
  details: CustomsInvoiceDetails = { ...customsInvoiceDetailsDefaultProps };
  taxes: Tax[] = [];
  invDateStart = "";
  invDateEnd = "";
  payDateStart = "";
  payDateEnd = "";
  searchText = "";
  genis: GeniForCustomSelection[] = [];
  genisInRelation: GeniForCustomSelection[] = [];

  async loadInvoice(invoiceId: number) {
    const result = await CustomsInvoiceApi.get(invoiceId);

    this.invoice = result.invoice;
    this.invoiceItems = result.items.map((x) => ({
      ...x,
      incomingInvoiceItem: { ...x.incomingInvoiceItem, cuid: cuid() },
      expanded: x.selectedStocks.length > 0,
      dutyDetails: false,
      selectedDutyDetails: x.selectedStocks.length > 0,
      accountDetails: false,
      visibleDutyPercentage: x.incomingInvoiceItem.dutyPercentage * 100,
    }));

    this.genisInRelation = result.genisInRelation.map((x) => ({ ...x, isSelected: true }));

    this.invoice.payTerm = this.trimDateTime(this.invoice.payTerm) ?? "";
    this.invoice.invoiceDate = this.trimDateTime(this.invoice.invoiceDate) ?? "";
    this.invoice.shipDate = this.trimDateTime(this.invoice.shipDate);
    this.invoice.regDate = this.trimDateTime(this.invoice.regDate) ?? "";
    this.invoice.arrivDate = this.trimDateTime(this.invoice.arrivDate) ?? "";
    this.invoice.accDate = this.trimDateTime(this.invoice.accDate);
  }

  @busyIndication()
  async loadInvoices() {
    if (this.invDateStart && this.invDateEnd && this.payDateStart && this.payDateEnd) {
      this.invoices = (
        await CustomsInvoiceApi.getAllInvAndPayDate(
          this.invDateStart,
          this.invDateEnd,
          this.payDateStart,
          this.payDateEnd
        )
      ).map((x) => ({
        ...x,
        isItemsVisible: false,
        expanded: false,
        isVatItemsVisible: false,
        isDutyItemsVisible: false,
      }));
    } else if (this.invDateStart && this.invDateEnd) {
      this.invoices = (await CustomsInvoiceApi.getAllByInvDate(this.invDateStart, this.invDateEnd)).map((x) => ({
        ...x,
        isItemsVisible: false,
        expanded: false,
        isVatItemsVisible: false,
        isDutyItemsVisible: false,
      }));
    } else if (this.payDateStart && this.payDateEnd) {
      this.invoices = (await CustomsInvoiceApi.getAllPayDate(this.payDateStart, this.payDateEnd)).map((x) => ({
        ...x,
        isItemsVisible: false,
        expanded: false,
        isVatItemsVisible: false,
        isDutyItemsVisible: false,
      }));
    } else {
      this.invoices = (await CustomsInvoiceApi.getAll()).map((x) => ({
        ...x,
        isItemsVisible: false,
        expanded: false,
        isVatItemsVisible: false,
        isDutyItemsVisible: false,
      }));
    }
  }

  async loadTaxes() {
    this.taxes = await CustomsInvoiceApi.getTaxes();
  }

  async update() {
    if (!this.invoice) {
      return;
    }

    this.invoice.currNetto = Number(this.invoice.currNetto);

    if (this.invoice.id > 1) {
      await CustomsInvoiceApi.update(
        this.invoice,
        this.invoiceItems.map((x) => x.incomingInvoiceItem),
        this.genisInRelation,
        this.invoiceItems.map((x) => ({
          itemCUID: x.incomingInvoiceItem.cuid,
          itemId: x.incomingInvoiceItem.id,
          items: x.selectedStocks,
        }))
      );
    } else {
      return await CustomsInvoiceApi.create(
        this.invoice,
        this.invoiceItems.map((x) => x.incomingInvoiceItem),
        this.genisInRelation,
        this.invoiceItems.map((x) => ({
          itemCUID: x.incomingInvoiceItem.cuid,
          itemId: x.incomingInvoiceItem.id,
          items: x.selectedStocks,
        }))
      );
    }
  }

  async accept() {
    await CustomsInvoiceApi.accept(
      this.invoice,
      this.invoiceItems.map((x) => x.incomingInvoiceItem),
      this.genisInRelation,
      this.invoiceItems.map((x) => ({
        itemCUID: x.incomingInvoiceItem.cuid,
        itemId: x.incomingInvoiceItem.id,
        items: x.selectedStocks,
      }))
    );
  }

  async setIncorrect() {
    await CustomsInvoiceApi.setIncorrect(this.invoice.id);
  }

  async loadDetails(invoiceId: number) {
    this.details = await CustomsInvoiceApi.getDetails(this.invoice.partner, invoiceId);
  }

  async loadGenis() {
    if (this.searchText) {
      const id = this.invoice?.id ?? 0;
      this.genis = await CustomsInvoiceApi.getGenis(this.searchText, id);
    }
  }

  trimDateTime(input: string | null) {
    if (input) {
      return input.split("T")[0];
    }

    return input;
  }
}

const store = new CustomsInvoiceStore();
export default store;
