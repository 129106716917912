
















































































































































































import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import BankRemitApi from "../services/BankRemitApi";
import PaymentScheduleView from "../models/PaymentScheduleView";
import Paginator from "../../../components/Paginator.vue";
import Pager from "../../../models/Pager";
import DateInput from "../../../components/DateInput.vue";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import MoneyInput from "../../../components/MoneyInput.vue";
import { formatDate } from "../../../utils/formatDates";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import configuration, { AppConfiguration } from "@/models/Configuration";
import BankRemitItem, {bankRemitItemDefaultProps} from "../models/BankRemitItem";


interface Data {
  store: BankRemitStore;
  bankAcc: string;
  partner: string;
  currency: string;
  invoices: PaymentScheduleView[];

  pager: Pager;
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];

  customFilter: CompositeFilterDescriptor | null;
  customSort: SortDescriptor[] | null;

  accDateMin: string;
  accDateMax: string;
  pDateMin: string;
  pDateMax: string;

  configuration: AppConfiguration;
}

interface Computed {
  activeItems: object[];
  columns: any;
  activeFilter: CompositeFilterDescriptor;
  activeSort: SortDescriptor[];
}

export default Vue.extend<Data, {}, Computed, {}>({
  components: {
    Paginator,
    DateInput,
    // MoneyDisplay,
    MoneyInput,
  },

  data() {
    return {
      store,
      bankAcc: "",
      partner: "",
      currency: "",
      invoices: [],

      //filters
      accDateMin: "2000. 01. 01.",
      accDateMax: "2999. 12. 31.",
      pDateMin: "2000. 01. 01.",
      pDateMax: "2999. 12. 31.",

      pager: new Pager(),
      filter: { logic: "and", filters: [] },
      sort: [],

      customFilter: null,
      customSort: null,

      configuration
    };
  },

  methods: {
    async addItem(item: PaymentScheduleView) {
      this.store.bankRemitItem = {...bankRemitItemDefaultProps};

      this.store.bankRemitItem.bankRemit = this.store.bankRemit.id;
      this.store.bankRemitItem.prefix = item.prefix;
      this.store.bankRemitItem.invoice = item.invoice.incomingInvoice.id;
      this.store.bankRemitItem.amount = item.invoice.incomingInvoice.total;
      this.store.bankRemitItem.partner = item.invoice.incomingInvoice.partner;
      this.store.bankRemitItem.partnerName = item.invoice.partnerName;
      this.store.bankRemitItem.invCurrency = item.currency;
      this.store.bankRemitItem.currAmount = item.invoice.incomingInvoice.currTotal;
      this.store.bankRemitItem.invNo = item.invoice.incomingInvoice.invoiceNo;
      //this.store.bankRemitItem.exchangeRate = ;
      this.store.bankRemitItem.isTransfered = false;
      this.store.bankRemitItem.pterm = item.invoice.incomingInvoice.payDate ?? new Date();
      this.store.bankRemitItem.itemId = 1;

      debugger;

      const id = Number(await this.store.saveItem());
      if (id > 1) {
        this.store.bankRemitItem.itemId = id;
        this.store.bankRemit.items.unshift(this.store.bankRemitItem);
      }
    },

    async search(e: Event) {
      e.preventDefault();
      BankRemitApi.getPaymentScheduleViews(
        this.bankAcc,
        this.partner,
        this.currency,
        this.accDateMin ?? "2000. 01. 01.",
        this.accDateMax ?? "2999. 12. 31.",
        this.pDateMin ?? "2000. 01. 01.",
        this.pDateMax ?? "2999. 12. 31."
      ).then((invoices) => (this.invoices = invoices));

      this.$nextTick(() =>
        this.invoices.forEach((element: PaymentScheduleView) => {
          element.selected = false;
        })
      );
    },

    formatDate(date: string) {
      return formatDate(date);
    },

    showDetails(item: PaymentScheduleView) {
      item.selected = !item.selected;
    },

    clear() {
      this.invoices = [];
      this.bankAcc = "";
      this.partner;
    },

    pagerChanged(p: Pager) {
      this.pager = p;
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      if (this.customFilter) {
        this.$emit("filterChange", e.filter);
      } else {
        this.filter = e.filter;
      }
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      if (this.customSort) {
        this.$emit("sortChange", e.sort);
      } else {
        this.sort = e.sort;
      }
    },
  },

  computed: {
    activeFilter() {
      return this.customFilter ? this.customFilter : this.filter;
    },

    activeSort() {
      return this.customSort ? this.customSort : this.sort;
    },

    activeItems() {
      let items: object[] = this.invoices;
      items = filterBy(items, this.activeFilter);
      items = orderBy(items, this.activeSort);
      return items;
    },

    columns: () => [
      // { cell: "detailsButtonTemplate", width: 50, filterable: false },
      {
        field: "prefix",
        title: "Prefix",
        width: 60,
      },
      {
        field: "invoiceId",
        title: "Azonosító",
        width: 80,
      },
      {
        field: "invoice.incomingInvoice.invoiceNo",
        title: "Bizonylat szám",
      },
      {
        field: "invoice.partnerName",
        title: "Partner",
      },
      {
        field: "invoice.incomingInvoice.payDate",
        title: "Fizetési tétel dátuma",
        cell: "pdateTemplate",
        width: 150,
      },
      {
        field: "invoice.incomingInvoice.amount",
        title: "Összeg",
        cell: "amountTemplate",
        headerClassName: "right_text",
        className: "right_text",
        width: 200,
      },
      {
        field: "invoice.incomingInvoice.remark",
        title: "Megjegyzés",
      },
      { cell: "commandTemplate", width: "60px" },
      { width: 0, filterable: false },
    ],
  },
});
