

























































































































import Vue from "vue";

import store, { InvoiceItemStore } from "../services/store";
import { InvoiceRelation, OtherItem } from "../models";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

import CheckBox from "@/components/CheckBox.vue";
import eventBus from "@/services/eventBus";

interface Data {
  store: InvoiceItemStore;
  localRelations: InvoiceRelation[];
}

interface Methods {
  getIncreasedValue(relation: any): number;
  selectAll(): void;
  selectDivision(i: number): void;
  divisionValueChanged(rel: InvoiceRelation, e: number): void;
  updateLocalRelationsPrice(e: InvoiceRelation[]): void;
}

interface Computed {
  itemCost: number;
  totalDivisionValue: number;
}

interface Props {
  isReadonly: boolean;
  relations: InvoiceRelation[];
  otherItem: OtherItem;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  data: () => ({
    store,
    localRelations: [],
  }),

  components: {
    MoneyDisplay,
    CheckBox
  },

  props: {
    isReadonly: {
      type: Boolean,
    },

    relations: {
      type: Array as () => InvoiceRelation[],
    },

    otherItem: {
      type: Object as () => OtherItem,
    },
  },

  beforeMount() {
    this.localRelations = this.$props.relations;
  },

  computed: {
    itemCost(): number {
      return this.$props.otherItem.unitPrice * this.$props.otherItem?.quantity;
    },

    totalDivisionValue() {
      return this.localRelations.reduce(
        (acc, r) => acc + (r.divisionValue ?? 0.0),
        0.0
      );
    },
  },

  methods: {
    divisionValueChanged(rel: InvoiceRelation, e: number) {
      rel.divisionValue = Number(e);

      this.$nextTick(() => {
        this.localRelations = [ ...this.localRelations ];
      })
    },

    selectDivision(i: number) {
      this.localRelations[i].selectedForDivision = !this.localRelations[i].selectedForDivision;
      this.localRelations = [ ...this.localRelations ];
    },

    selectAll(){
      if (this.localRelations.every((x) => x.selectedForDivision)) {
        this.localRelations.forEach(e => {
          e.selectedForDivision = false;
        });
      }
      else {
        this.localRelations.forEach(e => {
          e.selectedForDivision = true;
        });
      }
      this.localRelations = [ ...this.localRelations ];
    },

    getIncreasedValue(relation: any) {
      const divisionValue = relation.divisionValue
      
      if (!divisionValue) {
        return 0.0;
      }

      const unitPrice = relation.relatedItem.unitPrice
      const quantity = relation.quantity
      return  unitPrice * quantity + divisionValue
    },

    updateLocalRelationsPrice(e: InvoiceRelation[]){
      this.localRelations = [ ...e ];
    }
  },

  beforeDestroy() {
    eventBus.$off("InvoiceItemRelationsTable:refreshLocalRelations", this.updateLocalRelationsPrice);
  },

  created() {
    eventBus.$on("InvoiceItemRelationsTable:refreshLocalRelations", this.updateLocalRelationsPrice);
  },
});
