export default class MainCode {
  id = 0;
  type = 1;
  mainNumber = 0;
  generated = "";
  mask = "";
  regex = new RegExp("");

  constructor(props?: Partial<MainCode>) {
    const data: Partial<MainCode> = { ...props };
    Object.assign(this, data);

    this.regex = new RegExp(props?.regex ?? "");
  }

  public toDto() {
    return {
      id: this.id,
      type: this.type,
      mainNumber: this.mainNumber,
      generated: this.generated,
      mask: this.mask,
      regex: "",
    };
  }
}
