






















































































import store, { InvoiceFlowTaskStore } from "../services/InvoiceFlowTaskStore";
import Vue from "vue";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import CheckBox from "@/components/CheckBox.vue";
import GeneralIngoingInvoiceEditModel from "@/modules/generalIngoingInvoiceEditModel/models/GeneralIngoingInvoiceEditModel";
import { FlowStatus } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { DropDownList } from "@progress/kendo-vue-dropdowns";

interface Data {
  store: InvoiceFlowTaskStore;
  selected: boolean;
}

interface Methods {
  rowClick(e: GridCellProps): void;
  selectAll(): void;
  filterChange(e: { filter: CompositeFilterDescriptor }): void;
  sortChange(e: { sort: SortDescriptor[] }): void;
  editInvoice(invoice: GeneralIngoingInvoiceEditModel): void;
}

interface Props {
  selectable: boolean;
  recording: boolean;
  financialRecording: boolean;
  stockRecording: boolean;
  accepting: boolean;
  accounting: boolean;
  relatedCheck: boolean;
  columns: GridColumnProps[];
  dataItems: GeneralIngoingInvoiceEditModel[];
  downPaymentRequest: boolean;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: { CheckBox, DropDownList },

  props: {
    selectable: { type: Boolean, default: false },
    recording: { type: Boolean, default: false },
    financialRecording: { type: Boolean, default: false },
    stockRecording: { type: Boolean, default: false },
    accepting: { type: Boolean, default: false },
    accounting: { type: Boolean, default: false },
    relatedCheck: { type: Boolean, default: false },
    columns: Array as () => GridColumnProps[],
    dataItems: Array as () => GeneralIngoingInvoiceEditModel[],
    downPaymentRequest: { type: Boolean, default: false },
  },

  data: () => ({
    store,
    selected: false,
  }),

  methods: {
    rowClick(e) {
      if ((e.dataItem as GeneralIngoingInvoiceEditModel).relatedInvoice) {
        e.dataItem.selected = !e.dataItem.selected;
      }
    },

    selectAll() {
      this.dataItems.filter((x) => x.relatedInvoice).forEach((x) => (x.selected = !this.selected));
      this.selected = !this.selected;
    },

    filterChange(e) {
      this.store.filterChange(e);
    },

    sortChange(e) {
      this.store.sortChange(e);
    },

    editInvoice(invoice) {
      if (this.downPaymentRequest) {
        this.$router.push(`down-payment/edit/${invoice.entity.id}`);
        return;
      }

      const newStep = invoice.flowStatus + 1;
      const step =
        invoice.flowStatus == FlowStatus.Accounting
          ? invoice.flowStatus
          : invoice.flowStatus > 1
          ? newStep
          : FlowStatus.Recording;
      this.$router.push(`/general-incoming-invoice/flow/edit/${invoice.entity.id}/${step}`);
    },
  },

  computed: {
    compColumns() {
      const related = {
        width: 200,
        field: "relatedInvoice",
        title: "Kihez tartozik a számla?",
        cell: "relatedInvoiceTemplate",
        filterCell: "relatedInvoiceFilterTemplate",
        headerClassName: "center_text",
        className: "center_text",
      };
      if (this.recording) {
        const rec = {
          field: "flowUserName",
          title: "Iktató",
          className: "adjust_left",
          headerClassName: "adjust_left",
        };
        return [...this.columns, rec];
      } else if (this.financialRecording) {
        const extraCol = {
          field: "flowModUserName",
          title: "Pénzügyi rögzítő",
          className: "adjust_left",
          headerClassName: "adjust_left",
        };
        return [...this.columns, extraCol];
      } else if (this.stockRecording) {
        const extraCol = {
          field: "flowModUserName",
          title: "Teljesítés igazoló",
          className: "adjust_left",
          headerClassName: "adjust_left",
        };
        if (this.relatedCheck) {
          return [...this.columns, extraCol, related];
        }
        return [...this.columns, extraCol];
      } else if (this.accepting) {
        const extraCol = {
          field: "flowModUserName",
          title: "Számla igazoló",
          className: "adjust_left",
          headerClassName: "adjust_left",
        };
        if (this.relatedCheck) {
          return [...this.columns, extraCol, related];
        }
        return [...this.columns, extraCol];
      } else if (this.accounting) {
        const extraCol = {
          field: "flowModUserName",
          title: "Könyvelő",
          className: "adjust_left",
          headerClassName: "adjust_left",
        };
        return [...this.columns, extraCol];
      }
      return this.columns;
    },

    compDataItems() {
      return this.dataItems;
    },
  },
});
