




import Vue from "vue";

interface Data {
  x: number;
  y: number;
  leftWidth: number;
  resizer: any;
  left: any;
  right: any;
}

export default Vue.extend({
  data: (): Data => ({
    x: 0,
    y: 0,
    leftWidth: 0,
    resizer: null,
    left: null,
    right: null,
  }),

  mounted() {
    this.resizer = document.getElementById("resizer");
    this.left = this.resizer.previousElementSibling;
    this.right = this.resizer.nextElementSibling;
  },

  methods: {
    mouseDown(e: MouseEvent) {
      this.x = e.clientX;
      this.y = e.clientY;
      this.leftWidth = this.left.getBoundingClientRect().width;

      document.addEventListener("mousemove", this.mouseMove);
      document.addEventListener("mouseup", this.mouseUp);
    },

    mouseMove(e: MouseEvent) {
      if (!this.resizer || !this.left) return;

      const newLeftWidth =
        ((this.leftWidth + e.clientX - this.x) * 100) / this.resizer.parentNode.getBoundingClientRect().width;
      this.left.style.width = `${newLeftWidth}%`;

      document.body.style.cursor = "col-resize";
      this.left.style.userSelect = "none";
      this.left.style.pointerEvents = "none";

      this.right.style.userSelect = "none";
      this.right.style.pointerEvents = "none";
    },

    mouseUp() {
      document.body.style.removeProperty("cursor");

      this.left.style.removeProperty("user-select");
      this.left.style.removeProperty("pointer-events");

      this.right.style.removeProperty("user-select");
      this.right.style.removeProperty("pointer-events");

      document.removeEventListener("mousemove", this.mouseMove);
      document.removeEventListener("mouseup", this.mouseUp);
      this.$emit("released", this.left.style.width);
    },
  },
});
