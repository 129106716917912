export default interface InvItemDownRel {
    id: number;
    invItem: number;
    dpaymItem: number;
    amount: number;
    netto: number;
    vat: number;
}

export const invItemDownRelDefaultProps: InvItemDownRel = {
    id: 1,
    invItem: 1,
    dpaymItem: 1,
    amount: 0,
    netto: 0,
    vat: 0,
};

