import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import InvoiceFlowStatus from "@/modules/invoiceFlowStatus/model/InvoiceFlowStatus"
import InvoiceFlowStatusRelation from '../model/InvoiceFlowStatusRelation'

export default class InvoiceFlowStatusApi {
    static getAll(): Promise<InvoiceFlowStatus[]> {
        return http
            .get<ApiResponse<InvoiceFlowStatus[]>>(`invoice-flow-status`)
            .then(handleApiResponse)
    }

    static get(invoiceFlowStatusId: number): Promise<InvoiceFlowStatus> {
        return http
            .get<ApiResponse<InvoiceFlowStatus>>(`invoice-flow-status/${invoiceFlowStatusId}`)
            .then(handleApiResponse)
    }

    static getInvoiceFlowStatusRelations(invoiceId: number): Promise<InvoiceFlowStatusRelation[]> {
        return http
            .get<ApiResponse<InvoiceFlowStatusRelation[]>>(`invoice-flow-status/relations/${invoiceId}`)
            .then(handleApiResponse);
    }

    static manageInvoiceFlowStatusRelations(invoiceId: number, relation: InvoiceFlowStatusRelation): Promise<boolean> {
        return http
            .put<ApiResponse<boolean>>(`invoice-flow-status/relations/${invoiceId}`, {relation: relation})
            .then(checkResponse);
    }
}