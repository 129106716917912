

































































































import IconButton from "../../../components/IconButton.vue";
import Vue from "vue";
import Fragment from "../../../components/Fragment.vue";
import { StoreIn, getRelationState, ExtOrder, InvoiceRelationState } from "../models";
import store, { InvoiceItemStore } from "../services/store";
import StockTable from "./StockTable.vue";
import RelationStateBadge from "./RelationStateBadge.vue";
import StoreInFees from "./StoreInFees.vue";
import AddItemButton from "./AddItemButton.vue";
import OthersTable from "./OthersTable.vue";
import eventBus from "@/services/eventBus";
import Configuration from "@/models/Configuration";

interface Data {
  store: InvoiceItemStore;
  warningMsg: string;
}

interface Props {
  title: string;
  items: StoreIn[];
}

interface Computed {
  hasData: boolean;
  currency: string;
  visibleItems: StoreIn[];
  optionalColumns: Set<string>;
}

interface Methods {
  toggleDetails(storeIn: StoreIn): void;
  getRelationState(item: StoreIn | ExtOrder): InvoiceRelationState;
  getOrderNumber(storeIn: StoreIn): string | undefined;
  addStoreIn(items: StoreIn): void;
  orderNumberOrHyphen(items: StoreIn): string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Fragment,
    StockTable,
    IconButton,
    RelationStateBadge,
    StoreInFees,
    AddItemButton,
    OthersTable,
  },

  data: () => ({
    store,
    warningMsg: "",
  }),

  props: {
    items: {
      type: Array as () => StoreIn[],
    },

    title: {
      type: String,
      default: "Betárolás találatok",
    },
  },

  computed: {
    hasData() {
      return this.items && this.items.length > 0;
    },

    currency() {
      return this.store.currency;
    },

    visibleItems() {
      return this.items.filter((i) => this.store.selectedStateOption === getRelationState(i));
    },

    optionalColumns() {
      const columns = new Set<string>();

      if (!this.hasData) {
        return columns;
      }

      if (this.visibleItems.some((i) => !!this.getOrderNumber(i))) {
        columns.add("orderNumber");
      }

      return columns;
    },
  },

  methods: {
    orderNumberOrHyphen(item: StoreIn) {
      if (!item.stocks || item.stocks.length == 0) {
        return "-";
      }
      if (item.stocks[0].orderNumber == item.stocks[item.stocks.length - 1].orderNumber) {
        return item.stocks[0].orderNumber;
      }
      return "-";
    },

    async addStoreIn(item: StoreIn) {
      this.store.addStoreIn(item);
      if (Configuration.operel) return;
      await this.store.searchTrumesServices(item);
      await this.store.searchCreditingStorein(item);

      await this.store.searchReturnOrder(item);
      await this.store.save();
    },

    getRelationState,
    toggleDetails(storeIn: StoreIn) {
      storeIn.expanded = !storeIn.expanded;
    },

    getOrderNumber(storeIn: StoreIn) {
      const stock = (storeIn.stocks || []).find((s) => s.orderNumber);
      if (stock) {
        return stock.orderNumber;
      }

      return undefined;
    },
  },
});
