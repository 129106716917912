



























































































































































































































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import { ValidationRules } from "../../../models/Validation";
import store, {
  OutgoingInvoiceTypeEditModelStore,
} from "../services/OutgoingInvoiceTypeEditModelStore";
import cStore, {
  CashPointStore,
} from "../../cashPoint/services/CashPointStore";

interface Data {
  store: OutgoingInvoiceTypeEditModelStore;
  cStore: CashPointStore;
  opened: boolean;
  valid: boolean;
}

const CREATE_TITLE = "Kimenő számla típus létrehozása";
const MODIFY_TITLE = "Kimenő számla típus adatok módosítása";

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      cStore,
      valid: true,
      opened: false,
    };
  },

  computed: {
    title() {
      return this.store.outgoingInvoiceTypeEditModel?.id > 0
        ? MODIFY_TITLE
        : CREATE_TITLE;
    },

    relationItems() {
      return this.store.outgoingInvoiceTypeEditModel.partnerRelations;
    },
  },

  async beforeMount() {
    const id = Number(this.$route.params.outgoingInvoiceTypeId);
    await this.store.loadOutgoingInvoiceTypeEditModel(id);
    await this.cStore.loadAccountNoDetailedTypes();
  },

  methods: {
    async save() {
      await this.store.save();
      this.$router.push("/outgoing-invoice/type");
    },
  },
});
