






































































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import IconButton from "../../../components/IconButton.vue";
import InvoiceDpaym from "../../generalIngoingInvoice/models/InvoiceDpaym";
import PaidInvoiceDpaym from "../../generalIngoingInvoice/models/PaidInvoiceDpaym";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import eventBus from "@/services/eventBus";
import iStore, { InvoiceItemStore } from "@/modules/invoiceItems/services/store";

interface Data {
  iStore: InvoiceItemStore;
  store: InvoiceFlowStore;
  searchText: string;
  targetItemId: number;
}

interface Methods {
  setTargetItemId(e: any): void;
  deleteRel(item: PaidInvoiceDpaym): void;
  pay(item: InvoiceDpaym): void;
  search(isOrder: boolean): void;
  clear(): void;
  getItemBrutto(item: InvoiceDpaym): number;
  getItemVat(item: InvoiceDpaym): number;
}

export default Vue.extend<Data, Methods, {}, {}>({
  props: {
    title: String,
  },

  components: {
    IconButton,
    MoneyDisplay,
    MoneyInput,
  },

  data() {
    return {
      store,
      iStore,
      searchText: "",
      targetItemId: 0,
    };
  },

  async beforeMount() {
    await this.store.loadInvItemMins(this.store.invoice.id);
    await this.store.getPaid();
  },

  created() {
    eventBus.$on("InvoiceGroupDimensionCreating", this.setTargetItemId);
  },

  beforeDestroy() {
    eventBus.$off("InvoiceGroupDimensionCreating", this.setTargetItemId);
    this.store.dpaymInvoices = [];
    this.searchText = "";
  },

  computed: {
    columns: () => [
      { field: "id", title: "GENI" },
      {
        field: "orderName",
        title: "Rendelés",
      },
      { field: "name", title: "Tétel" },
      {
        field: "currNetto",
        title: "Nettó",
        cell: "currNettoTemplate",
        className: "right_text",
      },
      {
        field: "currTotal",
        title: "Bruttó",
        cell: "currTotalTemplate",
        className: "right_text",
        headerClasName: "right_text",
      },
      {
        field: "currVat",
        title: "ÁFA",
        cell: "currVatTemplate",
        className: "right_text",
        headerClasName: "right_text",
      },
      // {
      //   field: "paid",
      //   title: "Beszámított összeg",
      //   format: "{0: #,##.##}",
      //   className: "right_text",
      //   headerClassName: "right_text",
      // },
      // {
      //   field: "remaining",
      //   title: "Fennmaradó összeg",
      //   format: "{0: #,##.##}",
      //   className: "right_text",
      //   headerClassName: "right_text",
      // },
      // {
      //   cell: "invItemDropDownTemplate",
      //   title: "Végszámla tétel",
      //   className: "center_text",
      //   headerClassName: "center_text",
      // },
      {
        cell: "addTemplate",
        width: "40px",
      },
      { width: 0, filterable: false },
    ],
  },

  methods: {
    getItemBrutto(item: InvoiceDpaym) {
      item.currTotal = item.currNetto * item.tax;
      return item.currTotal;
    },

    getItemVat(item: InvoiceDpaym) {
      item.currVat = item.currTotal - item.currNetto;
      return item.currVat;
    },

    setTargetItemId() {
      this.targetItemId = this.store.downPayTargetItem;
      this.store.downPayTargetItem = 0;
    },

    async deleteRel(item: PaidInvoiceDpaym) {
      await this.store.deletePaidDpaymInvoice(item.relId);
    },

    async pay(item: InvoiceDpaym) {
      await iStore.save();
      item.endInvId = this.store.invoice.id;
      item.invItemToPayId = this.targetItemId;
      await this.store.addDownPay(item);
      this.store.invoice.dpaymIncl = true;
      await this.iStore.reloadItems();
      //await this.store.getPaid();
      this.$emit("closeDownPayVisible");
    },

    async search(isOrder: boolean) {
      if (this.targetItemId == 0) {
        isOrder = true;
      } else {
        isOrder = false;
      }
      await this.store.getDpaymInvoicesByPartner(this.searchText, isOrder);
    },

    clear() {
      this.searchText = "";
      this.store.dpaymInvoices = [];
    },
  },
});
