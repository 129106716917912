import CashPointEdit, {
  cashPointEditDefaultProps,
} from "@/modules/cashPoint/models/CashPointEdit";
import User, { userDefaultProps } from "@/modules/invoiceGroup/models/User";
import CashPointApi from "@/modules/cashPoint/services/CashPointApi";
import CashStatement from "../models/CashStatement";
import CashStatementDisplay from "../models/CashStatementDisplay";
import CashStatementApi from "./api";
import InvoiceGroupApi from "@/modules/invoiceGroup/services/InvoiceGroupApi";
import AccountStatus from "@/modules/notice/models/AccountStatus";
import NoticeApi from "@/modules/notice/services/NoticeApi";
import BankStatus from "../models/BankStatus";
import CashDocumentDisplay from "../models/CashDocumentDisplay";
import CashDocItemDisplay from "../models/CashDocItemDisplay";
import busyIndication from "@/utils/busyIndication";
import CashDocument from "../models/CashDocument";
import CashDocumentStatus from "../models/CashDocumentStatus";
import CashProcType from "../models/CashProcType";
import CashSubType, { cashSubTypeDefaultProps } from "../models/CashSubType";
import { trimDate, trimTime } from "@/utils/formatDates";
import eventBus from "@/services/eventBus";
import PartEmployee, {
  partEmployeeDefaultProps,
} from "@/modules/partner/models/PartEmployee";
import PartnerApi from "@/modules/partner/services/PartnerApi";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import SysFile, {
  sysFileDefaultProps,
} from "../../configuration/models/SysFile";
import ConfigurationApi from "@/modules/configuration/services/ConfigurationApi";
import SysFile1, {
  sysFile1DefaultProps,
} from "@/modules/configuration/models/SysFile1";
import GeneralDenom from "../../denomination/models/GeneralDenom";
import DenominationApi from "@/modules/denomination/services/DenominationApi";
import CashStatementDenom from "../models/CashStatementDenom";
import CashDocItem, { cashDocItemDefaultProps } from "../models/CashDocItem";
import BankOutView from "@/modules/bankOutView/models/BankOutView";
import BankInView from "@/modules/bankInView/models/BankInView";
import AccCode from "@/modules/invoiceFlow/models/AccCode";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import base64toUInt8Array from "@/services/fileConvert";

export class CashStatementStore {
  cashStatements: CashStatementDisplay[] = [];
  cashPointStatements: CashStatement[] = [];
  statementsFilter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  statementsSort: SortDescriptor[] = [{ dir: "desc", field: "extractDate" }];
  bankStatuses: BankStatus[] = [];
  selectedBankStatus: BankStatus | undefined = { id: -1, name: "Betöltés..." };
  accountStatuses: AccountStatus[] = [];
  selectedAccStatus: AccountStatus | undefined = {
    id: -1,
    name: "Betöltés...",
  };
  /*  */
  cashStatement = new CashStatement({});
  cashDocuments: CashDocumentDisplay[] = [];
  cashDocument: CashDocument = new CashDocument();
  cashDocumentStatuses: CashDocumentStatus[] = [];
  cashDocItems: CashDocItemDisplay[] = [];
  insDateTime = "";
  cashProcTypes: CashProcType[] = [];
  cashSubTypes: CashSubType[] = [];
  cashPoints: CashPointEdit[] = [];
  cashPoint: CashPointEdit = { ...cashPointEditDefaultProps };
  cashiers: User[] = [];
  cashier: User = { ...userDefaultProps };
  partEmployee: PartEmployee = { ...partEmployeeDefaultProps };
  currentUser: User = { ...userDefaultProps };
  sysFile: SysFile = { ...sysFileDefaultProps };
  sysFile1: SysFile1 = { ...sysFile1DefaultProps };
  generalDenoms: GeneralDenom[] = [];
  accountingCodes: AccCode[] = [];
  statementBalanceReportSrc = new Uint8Array();

  //stepper
  step = 0;

  //details
  isFullPage = false;

  // temp
  isDifferentCurrency = false;
  isOwnIdentifierReadOnly = true;
  isStatementStatusWIP = true;
  isOpenCashPointReadOnly = true;
  isStatementCashierTheCurrentUser = true;

  showPrintedCashDocument = false;

  printedCashDocumentBase64 = "";
  printedCashDocumentUInt8Array: Uint8Array = new Uint8Array();

  notoPdfUint8Array: Uint8Array[] = new Array<Uint8Array>();

  async checkStatementClosable() {
    return await http
      .get<ApiResponse<boolean>>(`cash-statement/${this.cashStatement.id}/closable`)
      .then(handleApiResponse);
  }

  setIsOpenCashPointReadOnly(b = false) {
    this.isOpenCashPointReadOnly =
      b ||
      !this.isStatementStatusWIP ||
      this.cashPoint.statementCount != 1 ||
      !!this.cashPoint.lastDocument;
  }

  setIsOwnIdentifierReadOnly() {
    this.isOwnIdentifierReadOnly =
      !this.isStatementStatusWIP || this.cashDocuments.length > 0;
  }

  setIsStatementStatusWIP() {
    this.isStatementStatusWIP = this.cashStatement.status == 2;
  }

  setIsStatementCashierTheCurrentUser() {
    this.isStatementCashierTheCurrentUser =
      this.currentUser.id == this.cashStatement.cashier;
  }

  async setStatementBalanceReport() {
    this.statementBalanceReportSrc = await http
      .get<string>(
        `cash-statement/${this.cashStatement.id}/report/statement-balance`
      )
      .then((x) => base64toUInt8Array(x.data));
  }

  @busyIndication()
  async rekontir() {
    const result = await CashStatementApi.rekontir(this.cashStatement.id);
    return result;
  }

  @busyIndication()
  async sendStatementToLedger() {
    return await CashStatementApi.sendStatementToLedger(this.cashStatement.id);
  }

  @busyIndication()
  async loadCashStatements() {
    this.cashStatements = (await CashStatementApi.getAll()).map((x) => ({
      ...x,
      selected: false,
    }));
  }

  async loadCashStatementsByCashPoint(cashPoint: number) {
    this.cashPointStatements = await CashStatementApi.getAllCashStatementByCashPoint(
      cashPoint
    );
  }

  async loadCashStatement(cashStatementId: number) {
    await CashStatementApi.get(cashStatementId).then((result) => {
      this.cashStatement = new CashStatement(result);
    });
  }

  getCashDocumentStatusCanBeChanged = async (
    cashDocument: number,
    status: number
  ) =>
    await CashStatementApi.getCashDocumentStatusCanBeChanged(
      cashDocument,
      status
    );

  async openCashPoint(openingAmount: number) {
    await CashStatementApi.openCashPoint(
      openingAmount,
      this.cashStatement.id,
      new CashDocument(),
      {
        ...cashDocItemDefaultProps,
      }
    ).then(() => {
      eventBus.$emit("success", "Sikeres pénztár nyitás.");
    });
  }

  async setCashStatementWrong() {
    return await CashStatementApi.setCashStatementWrong(this.cashStatement.id);
  }

  async update(emitMessage = true) {
    if (!this.cashStatement) return;

    if (this.cashStatement.id > 1) {
      console.log(
        "STATEMENT: update >",
        JSON.parse(JSON.stringify(this.cashStatement))
      );
      await CashStatementApi.update(this.cashStatement).then(() => {
        if (emitMessage)
          eventBus.$emit("success", "Pénztári kivonat frissítve.");
      });
    }
  }

  async create() {
    if (!this.cashStatement) return;

    console.log(
      "STATEMENT: create >",
      JSON.parse(JSON.stringify(this.cashStatement))
    );
    const ret = await CashStatementApi.create(this.cashStatement);
    if (ret > 0) eventBus.$emit("success", "Pénztári kivonat létrehozva.");
    return ret;
  }

  async loadCashDocumentsByStatementId(cashStatementId: number) {
    if (cashStatementId > 1) {
      this.cashDocuments = (
        await CashStatementApi.getDocumentsByStatementId(cashStatementId)
      ).map((x) => ({
        ...x,
        selected: false,
      }));
    } else {
      this.cashDocuments = [];
    }
  }

  async printStatementDenom() {
    return await http
      .get(`cash-statement/${this.cashStatement.id}/print-denoms`)
      .then((x) => {
        return base64toUInt8Array(x.data);
      });
  }

  async loadCashDocument(cashDocumentId: number) {
    const result =
      (await CashStatementApi.getDocument(cashDocumentId)) ??
      new CashDocument();
    this.cashDocument = result;
    this.insDateTime =
      trimDate(this.cashDocument.insDate) +
      "T" +
      trimTime(this.cashDocument.insTime);
  }

  async updateCashDocument(emitMessage = false) {
    if (!this.cashDocument) return;

    if (this.cashDocument.id > 1) {
      console.log(
        "DOCUMENT: update >",
        JSON.parse(JSON.stringify(this.cashDocument))
      );
      await CashStatementApi.updateDocument(this.cashDocument).then(() => {
        if (emitMessage) eventBus.$emit("success", "Bizonylat frissítve.");
      });
    }
  }

  async printCashDocumentRiport(): Promise<string> {
    const pdfB64 = await CashStatementApi.getCashDocumentRiportInBase64(
      this.cashDocument.id
    );
    this.printedCashDocumentBase64 = pdfB64;
    this.printedCashDocumentUInt8Array = base64toUInt8Array(pdfB64);
    this.showPrintedCashDocument = true;
    return pdfB64;
  }

  async loadNotoPdfs() {
    this.notoPdfUint8Array = await CashStatementApi.getNotoPdfsBase64(
      this.cashDocument.id
    ).then((x) => {
      return x.map((y) => {
        return base64toUInt8Array(y);
      });
    });
  }

  async setCashDocumentPrinted() {
    console.log(
      "DOCUMENT: printed >",
      JSON.parse(JSON.stringify(this.cashDocument))
    );
    await CashStatementApi.setDocumentPrinted(this.cashDocument, {
      ...cashDocItemDefaultProps,
    }).then(() => {
      //eventBus.$emit("success", "Bizonylat nyomtatva.");
    });
  }

  async loadAccountingCodes() {
    this.accountingCodes = await CashStatementApi.getAccountingCodes(
      this.cashStatement.id
    );
  }

  async setCashDocumentPaid(cashPointId: number) {
    console.log(
      "DOCUMENT: paid >",
      JSON.parse(JSON.stringify(this.cashDocument))
    );
    await CashStatementApi.setDocumentPaid(
      this.cashDocument.id,
      cashPointId
    ).then((x) => {
      eventBus.$emit("success", "Bizonylat fizetve.");
      this.notoPdfUint8Array = x.map((y) => base64toUInt8Array(y));
    });
  }

  async createCashDocument(cashDocument: CashDocument) {
    if (!cashDocument) return;

    console.log("DOCUMENT: create >", JSON.parse(JSON.stringify(cashDocument)));
    const ret = await CashStatementApi.createDocument(
      cashDocument,
      this.cashStatement.cashPoint
    );
    if (ret > 0) eventBus.$emit("success", "Bizonylat létrehozva.");
    return ret;
  }

  async loadCashDocItemsByDocumentId(cashDocumentId: number) {
    this.cashDocItems = (
      await CashStatementApi.getDocItemsByDocumentId(cashDocumentId)
    ).map((x) => ({
      ...x,
      deleting: false,
      cashProcTypes: [],
    }));
  }

  async addBankInViewSelectedItems(cashDocId: number, items: BankInView[]) {
    const result = await CashStatementApi.addBankInViewSelectedItems(
      cashDocId,
      items
    );
    if (result) {
      eventBus.$emit("success", "Kimenő számlák sikeresen hozzáadva!");
    } else
      eventBus.$emit(
        "error",
        "Hiba történt a kimenő számlák hozzárendelése közben!"
      );
  }

  async addBankOutViewSelectedItems(cashDocId: number, items: BankOutView[]) {
    const result = await CashStatementApi.addBankOutViewSelectedItems(
      cashDocId,
      items
    );
    if (result) {
      eventBus.$emit("success", "Bejövő számlák sikeresen hozzáadva!");
    } else
      eventBus.$emit(
        "error",
        "Hiba történt a bejövő számlák hozzárendelése közben!"
      );
  }

  async updateCashDocItems(emitMessage = false) {
    await CashStatementApi.updateDocItems(
      this.cashDocument,
      this.cashDocItems.map((x) => x.docItem)
    );

    await this.loadCashDocItemsByDocumentId(this.cashDocument.id);

    if (emitMessage){
      eventBus.$emit("success", "Bizonylat tételek frissítve.");
    }

    console.log(
      "DOC ITEM: update >",
      JSON.parse(JSON.stringify(this.cashDocItems))
    );
  }

  async loadCashDocumentStatuses() {
    this.cashDocumentStatuses = await CashStatementApi.getDocumentStatuses();
  }

  async loadCashProcTypes() {
    this.cashProcTypes = await CashStatementApi.getProcTypes();
  }

  async loadCashSubTypes() {
    this.cashSubTypes = await CashStatementApi.getSubTypes().then((x) => {
      console.log("cashSubTypes:");
      console.log(x);
      return x;
    });
  }

  @busyIndication()
  async loadCashPointsShort() {
    this.cashPoints = await CashPointApi.getAllShort();
  }

  async loadCashPointShort(cashPointId: number) {
    this.cashPoint = await CashPointApi.getShort(cashPointId);
  }

  async loadCashiersByCashStatementRelation(cashStatementID: number) {
    this.cashiers = await InvoiceGroupApi.getUsersByCashStatementRelation(
      cashStatementID
    );
  }

  async loadCashier(userId: number) {
    this.cashier = await CashStatementApi.getCashier(userId);
  }

  async loadPartEmployee(partnerId: number) {
    this.partEmployee = await PartnerApi.getPartEmployee(partnerId);
  }

  async loadCurrentUser() {
    this.currentUser = await CashStatementApi.getCurrentUser();
  }

  async loadSettings() {
    this.sysFile = await ConfigurationApi.getSettings();
  }

  async loadSettings1() {
    this.sysFile1 = await ConfigurationApi.getSettings1();
  }

  async loadBankStatuses() {
    this.bankStatuses = await CashStatementApi.getBankStatuses();
  }

  async loadAccountStatuses() {
    this.accountStatuses = await NoticeApi.getAccountStatuses();
  }

  async loadGeneralDenoms() {
    const result = await DenominationApi.getGeneralDenoms();
    this.generalDenoms = result.map((x) => ({ ...x, quantity: 0 }));
  }

  async createStatementDenoms(statementDenoms: CashStatementDenom[]) {
    if (!statementDenoms) return;

    console.log(
      "STATEMENT DENOMS: create >",
      JSON.parse(JSON.stringify(statementDenoms))
    );
    await DenominationApi.createStatementDenoms(statementDenoms).then(() => {
      eventBus.$emit("success", "Címletbizonylat létrehozva.");
    });
  }

  async startInterCashMovement(e: any) {
    if (this.cashDocument.id == 0 || this.cashPoint.id == 0) return;

    await CashStatementApi.startInterCashMovement(
      this.cashDocument.id,
      this.cashPoint.id,
      e
    ).then(() => {
      eventBus.$emit("success", "Sikeres pénztáriközi mozgás.");
    });
  }
}

const store = new CashStatementStore();
export default store;
