import BankPaymentItem from "@/modules/bank/models/PaymentItem";

export class BankStatementItemEntity {
    id = 0;

    /**
     * Bankszámla kivonat azonosító
     */
    extNo = 1;

    /**
     * Partner azonosító
     */
    partner = 1;

    /**
     * Összeg
     */
    amount = 0;

    /**
     * A tétel pénzneme
     */
    currency = 'HUF';

    /**
     * Jóváírás/Terhelés
     */
    crediting = false;

    /**
     * Bankkivonat tétel csoport azonosító
     */
    accountType = 2;

    /**
     * Bankkivonat tétel státusza
     */
    status = 2;

    /**
     * Megjegyzés
     */
    comment = '';

    /**
     * Bankkivonat tétel altípus
     */
    accountSubtype = 1;

    /**
     * 1. dimenzió (költséghely)
     */
    dimCode1 = '';

    /**
     * Rögzítő felhasználó
     */
    user = 1;

    /**
     * Rögzítés dátuma
     */
    insDate = new Date();

    /**
     * Rögzítés időpontja
     */
    insTime = new Date();

    /**
     * Eredeti pénznem
     */
    invCurrency = '';

    /**
     * Fizetett összeg az eredeti pénznemben
     */
    currAmount = 0;

    /**
     * Végleges árfolyamszorzó
     */
    exchangeRate = 1;

    /**
     * Az árfolyam mely napra vonatkozik
     */
    rateDate: Date = new Date();

    /**
     * Utókalkuláció
     */
    postCalc = 1;

    /**
     * 
     */
    oppositeAccNo = '';

    isDev = false;
}

export class BankDocumentInvoice {
    prefix = "";
    code = "";
    invoice = 1;
    currAmount = 0;
    currency = "HUF";
    status = "";
    bankDocItem = 1;
}

export default class BankDocument {
    bankStatementItem = new BankStatementItemEntity();
    partner = "";
    bankAccountCurrency = "";
    accountType = "";
    status = "";
    accountSubtype = "";
    user = "";
    postCalcName = "";
    selected = false;
    paymAndOver = false;
    payInDeviza = false;
    showPayments = false;
    paymentItems: BankPaymentItem[] = [];
    invoiceItems: BankDocumentInvoice[] = [];
    showInvoices = false;
}