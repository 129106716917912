



















































import Vue from "vue";
import NotiDownPayEditModel from "../../generalIngoingInvoice/models/NotiDownPayEditModel";
import store, {
  InvoiceFlowStore,
} from "../../invoiceFlow/services/InvoiceFlowStore";
import eventBus from "@/services/eventBus";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import IconButton from "@/components/IconButton.vue";
import DownPaymentItemsGrid from "../components/noticeTable/DownPaymentItemsGrid.vue";

interface Data {
  invoiceid: number;
  store: InvoiceFlowStore;
  visible: boolean;
  addedItems: number[];
  removedItems: number[];
  itemsOnInvoice: NotiDownPayEditModel[];
}

interface Method {
  save(): void;
  addPayment(item: NotiDownPayEditModel): void;
  deleteRelation(item: NotiDownPayEditModel): void;
  noItemsError(count: number): void;
  setItems(items: NotiDownPayEditModel[]): void;
  setExpanded(hasItems: boolean, item: NotiDownPayEditModel): void;
}

export default Vue.extend<Data, Method, {}, {}>({
  components: {
    ApiSearchPage,
    IconButton,
    DownPaymentItemsGrid,
  },

  data: () => ({
    invoiceid: 1,
    store,
    visible: true,
    addedItems: [],
    removedItems: [],
    itemsOnInvoice: [],
  }),

  beforeMount() {
    this.invoiceid = Number(this.$route.params.invoiceId);
  },

  destroyed() {
    eventBus.$on("flowfinancepage-saved", this.save);
  },

  methods: {
    setExpanded(hasItems, item) {
      console.log(item.id + ": " + hasItems);
      item.expanded = hasItems;
    },

    setItems(items) {
      this.itemsOnInvoice = items;
      this.$emit("itemsOnInvoice", items);
      this.store.notisOnInvoce = items;
    },

    save() {
      console.log("saved page");
    },

    noItemsError(count) {
      if (count > 0) this.store.hasNoti = true;
      else this.store.hasNoti = false;
    },

    async addPayment(item) {
      item.notiPaym = item.paymId;
      item.notiInv = item.id;
      item.invoice = Number(this.$route.params.invoiceId);
      console.log(item)
      if (item.id != 1 && item.notiPaym.length > 0 && item.invoice != 1) {
        await this.store.createNoti(item).then(() => {
          this.addedItems.push(item.id);
          this.removedItems = this.removedItems.filter((x) => x != item.id);
          eventBus.$emit("api-search-page:refresh");
        });
        await this.store.generateDownPaymentInvoiceItems(Number(this.$route.params.invoiceId));
      }
    },

    async deleteRelation(item) {
      item.notiInv = item.id;
      await this.store.deleteNoti(item).then(() => {
        this.addedItems = this.addedItems.filter((x) => x != item.id);
        this.removedItems.push(item.id);
        eventBus.$emit("api-search-page:refresh");
      });
      await this.store.deleteDownPaymentInvoiceItems(Number(this.$route.params.invoiceId));
    },
  },

  computed: {
    allApiUrl() {
      return `invoice-dpaym/${this.invoiceid}/search`;
    },

    addedApiUrl() {
      return `invoice-dpaym/${this.invoiceid}/added/search`;
    },

    columns: (): ApiSearchColumn[] => [
      { field: "id", title: "ID" },
      { field: "invoiceNo", title: "Bizonylat szám" },
      { field: "partnerName", title: "Szállító" },
      {
        field: "invoiceDate",
        title: "Számla Dátuma",
        type: ColumnType.Date,
      },
      {
        field: "payTerm",
        title: "Fizetési határidő",
        type: ColumnType.Date,
      },
      {
        field: "netto",
        title: "Nettó",
        type: ColumnType.Money,
      },
      {
        field: "total",
        title: "Bruttó",
        type: ColumnType.Money,
      },
      { field: "currency", title: "Pénznem" },
      { cell: "command", sortable: false, filterable: false },
    ],

    addedColumns: (): ApiSearchColumn[] => [
      { field: "id", title: "ID", filterable: false, sortable: false },
      {
        field: "invoiceNo",
        title: "Bizonylat szám",
      },
      {
        field: "partnerName",
        title: "Szállító",
      },
      {
        field: "invoiceDate",
        title: "Számla Dátuma",
        type: ColumnType.Date,
      },
      {
        field: "payTerm",
        title: "Fizetési határidő",
        type: ColumnType.Date,
      },
      {
        field: "netto",
        title: "Nettó",
        type: ColumnType.Money,
      },
      {
        field: "total",
        title: "Bruttó",
        type: ColumnType.Money,
      },
      {
        field: "currency",
        title: "Pénznem",
      },
      { cell: "command", sortable: false, filterable: false },
    ],
  },
});
