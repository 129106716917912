



























































// Components
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";

// Models
import Vue from "vue";
import store, { CashStatementStore } from "../services/store";
import { formatDate, newDate } from "@/utils/formatDates";

interface Data {
  valid: boolean;
  store: CashStatementStore;
  isDifferentCurrency: boolean;
  minExtractDate: string;
  maxExtractDate: string;
}

interface Methods {
  curencyDisableCheck(): boolean;
  save(edit?: boolean): void;
  cancel(): void;
}

interface Computed {
  disabled: boolean;
  validation: string;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    ValidationError,
  },

  data: () => ({
    valid: true,
    store,
    isDifferentCurrency: false,
    minExtractDate: "",
    maxExtractDate: "",
  }),

  beforeMount() {
    // create
    const today = new Date();
    const isHUF = this.store.cashPoint?.currency.toUpperCase().trim() === "HUF";
    this.store.cashStatement.createFrom(this.store.cashPoint);

    // component
    const minDate = this.store.cashPoint.lastDocument?.minCloseDate ?? "2000-01-01";
    this.minExtractDate = minDate;
    this.maxExtractDate = newDate(today);
    this.store.isDifferentCurrency = !this.store.cashPoint.hufOnly && !isHUF;
  },

  methods: {
    curencyDisableCheck() {
      if (this.store.cashPoint.hufOnly) return true;
      return false;
    },

    async save() {
      const cashStatementId = await this.store.create();
      if (!cashStatementId) return;

      this.store.cashStatement.id = cashStatementId ?? 0;
      await this.store.loadCashStatement(cashStatementId);
      await this.store.loadCashiersByCashStatementRelation(cashStatementId);
      this.$router.push(`/cash-statement/edit/${cashStatementId}`);
    },

    cancel() {
      this.$router.push(`/cash-statement`);
    },
  },

  watch: {
    "store.cashStatement.extractDateTime"(newValue) {
      const dateTimeSplit = newValue.split(".")[0];
      this.store.cashStatement.extractDate = dateTimeSplit;
      this.store.cashStatement.extractTime = dateTimeSplit;
    },
  },

  computed: {
    disabled() {
      return this.validation != "";
    },

    validation() {
      if ([null, ""].includes(this.store.cashStatement.extractDateTime)) {
        return "Hibás a megadott dátum!";
      }

      if (this.store.cashStatement.extractDateTime < this.minExtractDate) {
        return `A kivonat dátuma nem lehet az előző bizonylatának dátuma előtt: ${formatDate(this.minExtractDate)}`;
      }

      if (this.store.cashStatement.extractDateTime > this.maxExtractDate) {
        return "A kivonat dátuma nem lehet jövőbeli!";
      }

      if (this.store.cashStatement.cashPoint == 0) {
        return "Hibás Pénztár";
      }

      return "";
    },
  },
});
