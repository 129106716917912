










































































import Vue from "vue";
import store, { AccountCodeEditModelStore } from "../services/AccountCodeEditModelStore";
import AccountCodeEditModel from "../models/AccountCodeEditModel";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";

interface Data {
  store: AccountCodeEditModelStore;
  skip: number;
  take: number;
  pageable: any;

  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
      skip: 0,
      take: 10,
      pageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: true,
        previousNext: true,
      },

      sort: [],
      filter: {
        logic: "and",
        filters: [],
      },
    };
  },

  async beforeMount() {
    window.scrollTo(0, 0);
    await this.store.loadAccountCodeEditModels();
  },

  computed: {
    columns: () => [
      { field: "valueNat", title: "Tétel", cell: "valueNatTemplate" },
      { field: "displayTableName", title: "Rekord típusa", cell: "displayTableNameTemplate" },
      { field: "largeText", title: "Típus", cell: "largeTextTemplate" },
      // { field: "tableName", title: "Tábla név" },
      // { field: "columnName", title: "Oszlop név" },
      { field: "accountCode", title: "Főkönyvi szám" },
      { cell: "commandTemplate", width: "70px", filterable: false },
    ],

    result() {
      return orderBy(filterBy(this.store.editModels.slice(this.skip, this.take + this.skip), this.filter), this.sort);
    },

    total() {
      return this.store.editModels ? this.store.editModels.length : 0;
    },
  },

  methods: {
    pageChangeHandler(event: any) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      this.filter = e.filter;
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    },

    editItem(item: AccountCodeEditModel) {
      this.store.setEditing(item);

      //this.$router.push(`/account-codes/editmodels/${item.accountCodeId}/${item.columnDictionaryId}`);
    },

    cancelEdit(item: AccountCodeEditModel) {
      item.inEdit = false;
      this.store.saveEditModel();
    },

    itemChange({ dataItem, value, field }: { dataItem: AccountCodeEditModel; value: any; field: string }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      this.store.save(item, update);
    },
  },
});
