

































































































































































import Vue from "vue";
import IconButton from "../../../components/IconButton.vue";
import Fragment from "../../../components/Fragment.vue";

import { getStockWeight, Stock } from "../models";
import InvoiceRelationBadge from "./InvoiceRelationBadge.vue";
import AttributeList from "./AttributeList.vue";
import store, { InvoiceItemStore } from "../services/store";
import AddItemButton from "./AddItemButton.vue";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface ItemState {
  quantity: number;
  unitPrice: number;
  attributesVisible: boolean;
  selected: boolean;
}

interface Data {
  store: InvoiceItemStore;
  itemStates: Record<number, ItemState>;
  configuration: AppConfiguration;
}

interface Props {
  items: Stock[];
}

interface Computed {
  hasData: boolean;
  hasSelectedItem: boolean;
  currency: string;
}

interface Methods {
  initialize(items: Stock[]): void;
  submitStock(stock: Stock): void;
  getRelationQuantity(stock: Stock): number;
  toggleAttributes(item: Stock): void;
  getStockWeight(item: Stock): number;
  quantityChanged(item: Stock, value: number): void;
  toggleItemSelection(item: Stock): void;
  addSelectedItems(): void;
  getGeniDigits(currency: string): number;
  getGeniUnitDigits(currency: string): number;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    IconButton,
    Fragment,
    InvoiceRelationBadge,
    AttributeList,
    AddItemButton,
    MoneyDisplay,
  },

  data: () => ({
    store,
    itemStates: {},
    configuration,
  }),

  props: {
    items: {
      type: Array as () => Stock[],
    },
  },

  computed: {
    hasData() {
      return this.items && this.items.length > 0;
    },

    currency() {
      return this.store.currency;
    },

    hasSelectedItem() {
      return this.items.some((i) => this.itemStates[i.stockID]?.selected);
    },
  },

  mounted() {
    this.initialize(this.items);
  },

  methods: {
    getGeniUnitDigits(currency) {
      return configuration.operel
        ? 2
        : configuration.getCurrDigits(currency) !== undefined
        ? configuration.getCurrDigits(currency)?.geniUnitDigits ?? 0
        : 0;
    },

    getGeniDigits(currency) {
      return configuration.operel
        ? 2
        : configuration.getCurrDigits(currency) !== undefined
        ? configuration.getCurrDigits(currency)?.geniDigits ?? 0
        : 0;
    },

    getStockWeight,

    submitStock(stock: Stock) {
      const state: ItemState = this.itemStates[stock.stockID];
      store.addStock(stock, state.quantity, "");
    },

    getRelationQuantity(stock: Stock) {
      return stock.relatedInvoices.reduce((acc, r) => acc + r.quantity, 0.0);
    },

    initialize(items: Stock[]) {
      const states: Record<number, ItemState> = {};

      items.forEach((v) => {
        states[v.stockID] = {
          quantity: v.quantity,
          unitPrice: v.unitPrice,
          attributesVisible: true,
          selected: false,
        };
      });

      this.itemStates = states;
    },

    toggleAttributes(item: Stock) {
      const state = this.itemStates[item.stockID];
      state.attributesVisible = !state.attributesVisible;
    },

    quantityChanged(item: Stock, value: number) {
      if (value <= 0 || value > item.quantity) {
        this.itemStates = {
          ...this.itemStates,
          [item.stockID]: {
            ...this.itemStates[item.stockID],
            quantity: item.quantity,
          },
        };
      }
    },

    toggleItemSelection(item: Stock) {
      const state: ItemState = this.itemStates[item.stockID];
      state.selected = !state.selected;
    },

    addSelectedItems() {
      const stocks = this.items.filter((i) => this.itemStates[i.stockID]?.selected);
      stocks.forEach((i) => {
        this.submitStock(i);
      });
    },
  },

  watch: {
    items: "initialize",
  },
});
