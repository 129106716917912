





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import IconButton from "../../../components/IconButton.vue";
import Fragment from "../../../components/Fragment.vue";
import IntrastatForm from "../components/IntrastatForm.vue";
import RelatedInvoicesTable from "../components/RelatedInvoicesTable.vue";

import store, { InvoiceItemStore } from "../services/store";
import * as api from "../services/api";
import { generalRectItemDetailsDefaultProps, getWeight, InvoiceItem, InvoiceItemType, InvoiceRelationType, OtherItem } from "../models";
import RelationDisplayName from "../components/RelationDisplayName.vue";
import MoneyInput from "../../../components/MoneyInput.vue";
import configuration, { loadConfiguration } from "@/models/Configuration";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import eventBus from "@/services/eventBus";

import { isIntrastatPartner } from "@/modules/generalIngoingInvoice/models/InvoiceDetails";
import FixAssetForm from "../components/FixAssetForm.vue";
import { formatMoney } from "@/filters/money-format";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
// import InvoiceItemStockDivision from "../components/InvoiceItemStockDivision.vue";
import InvoiceDebitNoteRow from "../components/InvoiceDebitNoteRow.vue";
import StockCostList from "../components/StockCostList.vue";
import GParts from "@/modules/notice/models/GParts";
import OtherItemRelations from "@/modules/invoiceItems/components/OtherItemRelations.vue";
import type { AppConfiguration } from "@/models/Configuration";
import WorkSheetItemRelationForm from "@/modules/invoiceItems/components/WorksheetItemRelationForm.vue";
import SubContractorItemRelationForm from "@/modules/invoiceItems/components/SubContractorItemRelationForm.vue";
import CarRectItemRelationForm from "@/modules/invoiceItems/components/CarRectItemRelationForm.vue";
import WscontRectItemRelationForm from "@/modules/invoiceItems/components/WscontRectItemRelationForm.vue";

import FacsheetCoopItemRealtionForm from "@/modules/invoiceItems/components/FacsheetCoopItemRealtionForm.vue"; 
import CarItemRelationForm from "@/modules/canNet/views/CarItemRelationForm.vue";
import DateInput from "@/components/DateInput.vue";
import MaintenanceItemRelationForm from "@/modules/subcontractorServices/MaintenanceItemRelationForm.vue";
import PreStatus from "@/modules/incomingInvoice/models/PreStatus";
import OverBillingView from "./OverBillingView.vue";
import SupplierComplaintRow from "../components/SupplierComplaintRow.vue";
import iStore, { GeneralIngoingInvoiceStore } from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import OperelInvoiceDimensionsForTable from "@/modules/generalIngoingInvoice/views/OperelInvoiceDimensionsForTable.vue";
import NavItemsRelations from "./NavItemsRelations.vue";
import ItemItList from "../components/ItemItList.vue";

interface ItemState {
  descriptionVisible: boolean;
  stockRelationsVisible: boolean;
  itemItVisible: boolean;
  invoiceRelationsVisible: boolean;
  intrastatFormVisible: boolean;
  fixAssetFormVisible: boolean;
  inverseVatFormVisible: boolean;
}

interface Data {
  store: InvoiceItemStore;
  flowStore: InvoiceFlowStore;
  itemStates: Record<string, ItemState>;
  invoiceItem: InvoiceItem | null;
  vatChangeVisible: boolean;
  downpaymGParts: GParts[];
  configuration: AppConfiguration;
  maintenanceItemSelectorVisible: boolean;
  iStore: GeneralIngoingInvoiceStore;
}

interface Props {
  isPageDisabled: boolean;
  invoiceID: number;
  supplierID: number;
  defaultTaxID: number;
  currency: string;
  exchangeRate?: number;
  notesEnabled: boolean;
  notesEdit: boolean;
}

interface Computed {
  items: InvoiceItem[];
  hasData: boolean;
  itemGroups: Record<string, InvoiceItem[]>;
  itemsNetto: number;
  itemsBrutto: number;
  displayedPrecision: number | undefined;
  onOperel: boolean;
  isNavItems: boolean;
  taxAndVatItems: any[];
}

interface Methods {
  isIndirectNoServ(item: InvoiceItem): void;
  toggleIncreaseStockPrice(invoiceItem: InvoiceItem): void;
  deleteRel(id: number): void;
  select(item: InvoiceItem): void;
  setOverBilling(item: InvoiceItem): void;
  nettoValueChange(invoiceItem: InvoiceItem, e: number): void;
  quantityChange(invoiceItem: InvoiceItem, e: number): void;
  toggleItem(invoiceItem: InvoiceItem): void;
  toggleStockRelations(invoiceItem: InvoiceItem): void;
  toggleItemItRelations(invoiceItem: InvoiceItem): void;
  toggleInvoiceRelations(invoiceItem: InvoiceItem): void;
  toggleIntrastatForm(invoiceItem: InvoiceItem): void;
  toggleFixAssetForm(invoiceItem: InvoiceItem): void;
  toggleInverseVatRelations(invoiceItem: InvoiceItem): void;
  hasStockRelations(invoiceItem: InvoiceItem): boolean;
  getItemState(invoiceItem: InvoiceItem): ItemState;
  isOtherItem(item: InvoiceItem): boolean;
  isCarRectItem(item: InvoiceItem): boolean;
  isWscontRectItem(item: InvoiceItem): boolean;
  isInverseVat(invoiceItem: InvoiceItem): boolean;
  groupNetto(key: string): number;
  groupBrutto(key: string): number;
  isRoundingItem(key: string): boolean;
  stockPriceToleranceViolated(item: InvoiceItem): string;
  stockPriceDifferenceInfo(item: InvoiceItem): string;
  canApproveToleranceDifference(): void;
  intrastatEnabled(item: InvoiceItem): boolean;
  findTax(taxID: number): string;
  formatMoney(value: number): string;
  getWeight(item: InvoiceItem, quantity: number): number;
  isOtherProduct(invoiceItem: InvoiceItem): boolean;
  pricePrecision(item: InvoiceItem): number;
  priceUnitPrecision(item: InvoiceItem): number;
  priceHeadPrecision(item: InvoiceItem): number;
  taxChanged(e: InvoiceItem): void;
  confirmTaxChange(): void;
  cancelTaxChange(): void;
  isIntrastatFilled(invItem: InvoiceItem): void;
  getIntrastatColor(invItem: InvoiceItem): void;
  setIsIntrastatFilled(isFilled: boolean, item: InvoiceItem): void;
  valuesChanged(item: InvoiceItem): boolean;
  listDifferences(): void;
  getGeniItemDigits(currency: string): number;
  getGeniUnitDigits(currency: string): number;
  getGeniDigits(currency: string): number;
  downPaymentDetailsVisible(item: InvoiceItem): boolean;
  getItemVat(item: InvoiceItem): number;
  getItemBrutto(item: InvoiceItem): number;
  generateRegTax(item: InvoiceItem): void;
  toggleCarItemRelationSelector(item: InvoiceItem): void;
  toggleNavItems(item: InvoiceItem): void;
  toggleCarRectItem(item: InvoiceItem): void;
  toggleWscontRectItem(item: InvoiceItem): void;
  toggleMaintenanceItemRelationSelector(item: InvoiceItem): void;
  toggleWorkSheetRelationSelector(item: InvoiceItem): void;
  toggleSubcontractorSelector(item: InvoiceItem): void;
  toggleOperelDimensions(item: InvoiceItem): void;
  toggleFacsheetRelationSelector(item: InvoiceItem): void;
  displayRectNo(item: InvoiceItem): string;
  wscontPriceDifferenceInfo(item: any): string;
  hasHasWsCont(item: any): boolean;
  onlyRectItem(item: InvoiceItem): boolean;
  isOverBillingDisabled(): boolean;
  setInvoiceItemsVatToHead(): void;
  setInvoiceItemsVatToOriginal(): void;
  removeGeneralRect(item: InvoiceItem): void;
  updateGeneralRect(item: InvoiceItem): void;
  getColor(item: InvoiceItem): string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  data: () => ({
    store,
    flowStore,
    configuration,
    itemStates: {},
    invoiceItem: null,
    vatChangeVisible: false,
    downpaymGParts: [],
    maintenanceItemSelectorVisible: false,
    iStore,
  }),

  components: {
    IconButton,
    Fragment,
    IntrastatForm,
    RelatedInvoicesTable,
    RelationDisplayName,
    MoneyInput,
    FixAssetForm,
    MoneyDisplay,
    StockCostList,
    // InvoiceDebitNoteRow,
    OtherItemRelations,
    // InvoiceItemStockDivision,
    CarItemRelationForm,
    MaintenanceItemRelationForm,
    DateInput,
    WorkSheetItemRelationForm,
    SubContractorItemRelationForm,
    FacsheetCoopItemRealtionForm,
    CarRectItemRelationForm,
    WscontRectItemRelationForm,
    OverBillingView,
    SupplierComplaintRow,
    OperelInvoiceDimensionsForTable,
    NavItemsRelations,
    ItemItList,
  },

  props: {
    isPageDisabled: {
      type: Boolean,
      default: true,
    },

    invoiceID: {
      type: Number,
    },

    supplierID: {
      type: Number,
    },

    exchangeRate: {
      type: Number,
      default: 1.0,
    },

    currency: {
      type: String,
    },

    defaultTaxID: {
      type: Number,
    },

    notesEnabled: {
      type: Boolean,
    },

    notesEdit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    taxAndVatItems(){
      if (this.flowStore.invoice.type == 3) {
        console.log(this.store.taxAndVatItems);
        return this.store.taxAndVatItems.filter((x) => x.vatDisplayName == "ÁFA 27%" || x.vatDisplayName == "Használt Ingóság Adó")
      }
      return this.store.taxAndVatItems;
    },

    isNavItems(){
      return this.flowStore.navItems.length > 0;
    },

    onOperel(){
      return this.configuration.operel;
    },

    items() {
      return this.store.itemList.items;
    },

    hasData() {
      return this.store.itemList.items.length > 0;
    },

    itemGroups() {
      const groups: Record<string, InvoiceItem[]> = {};

      const items = [...(this.store.itemList.items as InvoiceItem[])];
      items.sort((a, b) => a.type.localeCompare(b.type));

      items.forEach((i) => {
        groups[i.type] = groups[i.type] ?? [];
        groups[i.type].push(i);
      });
      return groups;
    },

    itemsNetto() {
      const items = this.items as InvoiceItem[];
      if (this.flowStore.invoice.dpaymIncl) {
        const originalItems = items
          .filter((i) => i.includeInSummary && !i.dpaymItem)
          .reduce((acc, i) => acc + i.nettoValue, 0.0);
        const negativeItems = items
          .filter((i) => i.includeInSummary && i.dpaymItem)
          .reduce((acc, i) => acc + i.nettoValue, 0.0);
        let dpaymSum = 0;
        items.forEach((e) => {
          dpaymSum += e.downPaymentDetails?.netto ?? 0;
        });
        return originalItems - Number(dpaymSum) - Number(negativeItems);
      }
      return items.filter((i) => i.includeInSummary).reduce((acc, i) => acc + i.nettoValue, 0.0);
    },

    itemsBrutto() {
      const items = this.items as InvoiceItem[];
      if(!configuration.operel){
      if (this.flowStore.invoice.dpaymIncl) {
        const originalItems = items
          .filter((i) => i.includeInSummary && !i.dpaymItem)
          .reduce((acc, i) => acc + i.bruttoValue, 0.0);
        const negativeItems = items
          .filter((i) => i.includeInSummary && i.dpaymItem)
          .reduce((acc, i) => acc + i.bruttoValue, 0.0);
        let dpaymSum = 0;
        items.forEach((e) => {
          dpaymSum += e.downPaymentDetails?.amount ?? 0;
        });
        return originalItems - Number(dpaymSum) - Number(negativeItems);
      }
      if(items.every(x=>x.dpaymItem)){
        return items.filter((i) => i.includeInSummary).reduce((acc, i) => acc + i.originalBruttoValue, 0.0);
      }
      return items.filter((i) => i.includeInSummary).reduce((acc, i) => acc + i.bruttoValue, 0.0);
    }
    else{
      const itemsBruttoSum = items.filter((i) => i.includeInSummary).reduce((acc, i) => acc + (i.nettoValue * (1+(i.tax.degree/100))), 0.0);
      return itemsBruttoSum;
    }
    },

    displayedPrecision() {
      return this.items.filter((i) => i.includeInSummary).find((x) => x.manualPrecision)?.manualPrecision;
    },
  },

  methods: {
    getColor(item: InvoiceItem){
      return (item.hasFixAssetRelation || (item.fixAsset != null && item.fixAsset.id > 1) ? 'green' : 'primary');
    },

    async setInvoiceItemsVatToOriginal(){
      const relations = this.store.itemList.items.flatMap((x) => x.relations);
      const ret = (await this.flowStore.getOriginalVatValues(relations.map((x) => x.relatedItem.stockID)));
      
      this.store.itemList.items.forEach(i => {
        const found = i.relations.find((x) => ret.flatMap((y: any) => y.stockID).includes(x.relatedItem.stockID));
        if (found && i.type == InvoiceItemType.Stocked) {
          i.tax = this.store.taxAndVatItems.find((x) => x.id == (ret.find((a: any) => a.stockID == found.relatedItem.stockID)).vatID) ?? this.store.taxAndVatItems[0];
        }
      });
    },
    
    setInvoiceItemsVatToHead(){
      this.store.itemList.items.forEach(i => {
        if (i.type == InvoiceItemType.Stocked) {
          i.tax = this.store.taxAndVatItems.find((x) => x.id == this.iStore.invoice.tax) ?? this.store.taxAndVatItems[0];
        }
      });
    },

    isOverBillingDisabled(){
      return this.flowStore.invoice.preStatus !== PreStatus.Accepted && this.flowStore.invoice.preStatus !== PreStatus.AcceptedUnderProcessing
    },

    onlyRectItem(item){
      if (item.rectifiedType == 'S' && configuration.onlyRectItem.enabled) {
        return false;
      }
      return true;
    },

    async toggleCarRectItem(item){
      const invoiceItemId = item.invoiceItemID;

      const temp = !item.carRectItemSelectorVisible;
      if (!item.carRectItemSelectorVisible) {
        await this.store.save();
      }
      const newItem = this.store.itemList.items.find((x) => x.invoiceItemID == invoiceItemId);
      
      if (newItem != null) {
        newItem.carRectItemSelectorVisible = temp;
      }
    },

    async toggleWscontRectItem(item){
      const invoiceItemId = item.invoiceItemID;

      const temp = !item.wscontRectItemSelectorVisible;
      if (!item.wscontRectItemSelectorVisible) {
        await this.store.save();
      }
      const newItem = this.store.itemList.items.find((x) => x.invoiceItemID == invoiceItemId);
      
      if (newItem != null) {
        newItem.wscontRectItemSelectorVisible = temp;
      }
    },

    async toggleCarItemRelationSelector(item){
      const invoiceItemId = item.invoiceItemID;

      const temp = !item.carItemRelationSelectorVisible;
      if (!item.carItemRelationSelectorVisible) {
        await this.store.save();
      }
      const newItem = this.store.itemList.items.find((x) => x.invoiceItemID == invoiceItemId);
      
      if (newItem != null) {
        newItem.carItemRelationSelectorVisible = temp;
      }
    },

    async toggleNavItems(item){
      if(item.invoiceItemID < 2) {
        await this.store.save();
        const newItem = this.store.itemList.items.find((x) => x.gpartID == item.gpartID && 
        x.productID == item.productID && 
        (x.serviceID == item.serviceID || (x.serviceID < 2 && item.serviceID < 2)) && 
        x.bruttoValue == item.bruttoValue && 
        x.nettoValue == item.nettoValue);

        if (newItem != null) {
          newItem.navItemsVisible = true;
        }
      } else {
        item.navItemsVisible = !item.navItemsVisible;
      }
    },

    async toggleMaintenanceItemRelationSelector(item){
      await this.store.save();
      this.maintenanceItemSelectorVisible = !this.maintenanceItemSelectorVisible;
    },

    toggleOperelDimensions(item: InvoiceItem){
      item.operelDimensionsVisible = !item.operelDimensionsVisible;
    },

    async toggleWorkSheetRelationSelector(item: InvoiceItem){
      const invoiceItemId = item.invoiceItemID;

      const temp = !item.workSheetItemRelationSelectorVisible;
      if (!item.workSheetItemRelationSelectorVisible) {
        await this.store.save();
      }
      const newItem = this.store.itemList.items.find((x) => x.invoiceItemID == invoiceItemId);
      
      if (newItem != null) {
        newItem.workSheetItemRelationSelectorVisible = temp;
      }
    },

    async toggleSubcontractorSelector(item: InvoiceItem){
      const invoiceItemId = item.invoiceItemID;

      const temp = !item.subcontractorRelationSelectorVisible;
      if (!item.subcontractorRelationSelectorVisible) {
        await this.store.save();
      }
      const newItem = this.store.itemList.items.find((x) => x.invoiceItemID == invoiceItemId);
      
      if (newItem != null) {
        newItem.subcontractorRelationSelectorVisible = temp;
      }
    },

    async toggleFacsheetRelationSelector(item: InvoiceItem){
      const invoiceItemId = item.invoiceItemID;

      const temp = !item.fachSheetItemRelationSelectorVisible;
      if (!item.fachSheetItemRelationSelectorVisible) {
        await this.store.save();
      }
      const newItem = this.store.itemList.items.find((x) => x.invoiceItemID == invoiceItemId);
      
      if (newItem != null) {
        newItem.fachSheetItemRelationSelectorVisible = temp;
      }
    
    //  if (item.invoiceItemID < 2) {
    //   await this.store.save();
    //   const newItem = this.store.itemList.items.find((x) => x.gpartID == item.gpartID && x.bruttoValue == item.bruttoValue && x.nettoValue == item.nettoValue && x.isService)
    //   if (newItem != null) {
    //     newItem.fachSheetItemRelationSelectorVisible = true;
    //   }
    //  } else {
    //    item.fachSheetItemRelationSelectorVisible = !item.fachSheetItemRelationSelectorVisible;
    //  }
    },

    async generateRegTax(item){
      await this.store.generateRegTax(item.invoiceItemID);
      this.flowStore.invoice.isRegTax = true;
    },

    isIndirectNoServ(item) {      
      return (item.gpartID > 1 && !item.isService) || (item.productID > 1 && item.isIndirect);
    },

    getItemBrutto(item){
      if(!this.configuration.operel){

        return item.bruttoValue
      }

      
      const brutto = item.nettoValue * (1+(item.tax.degree/100));
      return brutto;
    },

    getItemVat(item){
      if(!this.configuration.operel){
        return item.taxValue;
      }

      return item.nettoValue * (1+(item.tax.degree/100)) - item.nettoValue;
    },

    displayRectNo(item: InvoiceItem) {
      return item.rectifiedInvNo > 1
        ? "GENI szám: " + item.rectifiedInvNo.toString()
        : "";
    },

    wscontPriceDifferenceInfo(item: any){
      const tolerance = configuration.sysParams.geniFulFillTolerance;
      if (!tolerance || tolerance < 0) return "";

      const difference = Math.abs(100 - (100 * item.nettoValue) / item.wscontDetail.total);
      if (!difference || difference == 0) return "";
      else if (this.flowStore.invoice.rectify || this.flowStore.invoice.dpaym) return "";
      else if (difference > tolerance) return `Tolerancia szintet meghaladó eltérés (${difference.toFixed(2)}%)`;
      else if (difference <= tolerance) return `Tolerancia szinten belüli eltérés (${difference.toFixed(2)}%)`;

      return "";
    },

    hasHasWsCont(item: any){
      if (item.wscontDetail != null && item.wscontDetail != undefined) {
        if (item.wscontDetail.total != item.nettoValue) {
          return true;
        }
      }
      return false;
    },

    downPaymentDetailsVisible(item: InvoiceItem) {
      let visible = false;
      if (item.downPaymentDetails != null) {
        if (item.downPaymentDetails.id > 1) {
          visible = true;
        }
      }
      return visible;
    },
    getGeniDigits(currency) {
      return configuration.operel
        ? 2
        : configuration.getCurrDigits(currency) != undefined
        ? configuration.getCurrDigits(currency)?.geniDigits ?? 2
        : 2;
    },

    getGeniUnitDigits(currency) {
      if (configuration.operel) return 2;

      return configuration.getCurrDigits(currency) != undefined
        ? configuration.getCurrDigits(currency)?.geniUnitDigits ?? 2
        : 2;
    },

    getGeniItemDigits(currency) {
      if (configuration.operel) return 2;

      const res =
        configuration.getCurrDigits(currency) != undefined
          ? configuration.getCurrDigits(currency)?.geniItemDigits ?? 0
          : 0;
      return res;
    },

    quantityChange(invoiceItem: InvoiceItem, e: number){
      console.log(invoiceItem);
      console.log(e);
      invoiceItem.quantity = e;

      if (invoiceItem.rawOtherItem) {
        this.$nextTick(() => {
          (invoiceItem.rawOtherItem as OtherItem).quantity = invoiceItem.quantity;
          (invoiceItem.rawOtherItem as OtherItem).unitPrice = invoiceItem.unitPrice;
        });
      }
      
      this.$nextTick(() => {
        this.store.itemList.items = [...this.store.itemList.items];
      });
      console.log(invoiceItem);
    },

    nettoValueChange(invoiceItem: InvoiceItem, e: number) {
      invoiceItem.nettoValue = e;
      eventBus.$emit("otherItemRelations:unitPrice", invoiceItem.unitPrice);
      console.log(invoiceItem.unitPrice);

      if (invoiceItem.rawOtherItem != null && invoiceItem.increasesStockPrice) {        
        if (invoiceItem.nettoValue < 0 && invoiceItem.quantity > 0) {
          this.$nextTick(() => {
            (invoiceItem.rawOtherItem as OtherItem).unitPrice = Math.abs(invoiceItem.unitPrice);
            (invoiceItem.rawOtherItem as OtherItem).quantity = -(invoiceItem.rawOtherItem as OtherItem).quantity;
            (invoiceItem.rawOtherItem as any) = {...invoiceItem.rawOtherItem};
            });
        }
        else {
          this.$nextTick(() => {
            (invoiceItem.rawOtherItem as any) = {...invoiceItem.rawOtherItem, unitPrice: invoiceItem.unitPrice }
          });
        }
      }
      if (invoiceItem.nettoValue < 0 && invoiceItem.quantity > 0) {
        invoiceItem.quantity = -invoiceItem.quantity;
      }

      this.$nextTick(() => {
        this.store.itemList.items = [...this.store.itemList.items];
      });
    },

    async toggleIncreaseStockPrice(item: InvoiceItem) {
      if (item.invoiceItemID > 1 && item.rawOtherItem == null) {
        const ret = await api.getRawOtherItem(item.gpartID);
        if (ret.length > 0) {
          item.rawOtherItem = { ...ret[0], unitPrice: item.unitPrice, quantity: item.quantity };
        }
        else {
          eventBus.$emit("error", `Szolgáltatás tétel nem található: ${item.gpartID}!`);
          return;
        }
      }

      (item.rawOtherItem as OtherItem).stockRelated = !(item.rawOtherItem as OtherItem).stockRelated;
      this.store.toggleOtherItem(item.rawOtherItem as OtherItem);
      item.increaseStockPriceVisible = !item.increaseStockPriceVisible;
    },

    async deleteRel(id: number) {
      await this.flowStore.deletePaidDpaymInvoice(id);
    },

    setOverBilling(item: InvoiceItem) {
      item.isSelectedForOverBilling = !item.isSelectedForOverBilling;
      //this.$emit("setOverBillingVisible", item);
    },

    select(item: InvoiceItem) {
      item.isSelectedForDownPayment = !item.isSelectedForDownPayment;
      this.$emit("setDownPayVisibleWithTargetItem", item);
    },

    listDifferences() {
      this.$emit("setDifferencesVisible", true);
    },

    valuesChanged(item: InvoiceItem) {
      return (
        (item.bruttoValue != item.originalBruttoValue ||
          item.nettoValue != item.originalNettoValue ||
          item.taxValue != item.originalVatValue ||
          item.quantity != item.originalQuantity) &&
        item.sumItem
      );
    },

    setIsIntrastatFilled(isFilled, item) {
      item.isIntrastatFilled = isFilled;
    },

    getIntrastatColor(invItem) {
      return invItem.isIntrastatFilled ? "green" : "grey";
    },

    async isIntrastatFilled(invItem) {
      if (configuration.operel) {
        invItem.isIntrastatFilled = true;
        return;
      }
      invItem.isIntrastatFilled = await this.store.isIntrastatFilled(invItem.invoiceItemID);
    },

    taxChanged(e: InvoiceItem) {
      this.invoiceItem = e;
      if (configuration.operel) return;
      if (!configuration.taxChangeQuestionVisible.enabled) return;
      this.vatChangeVisible = true;
    },

    confirmTaxChange() {
      if (this.invoiceItem == null) {
        return;
      }
      this.store.updateTax(this.invoiceItem.gpartID, this.invoiceItem.productID, this.invoiceItem.tax?.id ?? 1);

      this.invoiceItem = null;
      this.vatChangeVisible = false;
    },

    cancelTaxChange() {
      this.invoiceItem = null;
      this.vatChangeVisible = false;
    },

    isOtherProduct(invoiceItem: InvoiceItem): boolean {
      return !invoiceItem.isService;
    },

    intrastatEnabled(item: InvoiceItem) {
      if (isIntrastatPartner(this.flowStore.invoiceDetails) && item.invoiceItemID > 1 
      && (this.flowStore.invoice.rectify ? item.rectifiedType==='R' && (item.gpartID > 1 || item.serviceID > 1 || item.stockId > 1) && item.rectifiedItemHasIntrastat : true ) ) {
        return true;
      } else { return false;}
    },

    formatMoney,

    getWeight,

    async removeGeneralRect(item: InvoiceItem){
      await this.flowStore.removeGeneralRect(item.generalRectItemDetails.id);
      item.generalRectItemDetails = { ...generalRectItemDetailsDefaultProps };
      item.isGeneralRectItem = false;
    },

    async updateGeneralRect(item: InvoiceItem){
      await this.flowStore.updateGeneralRect(item);
    },

    toggleItem(invoiceItem: InvoiceItem) {
      const state = this.getItemState(invoiceItem);
      state.descriptionVisible = !state.descriptionVisible;
      this.itemStates = { ...this.itemStates };
    },

    toggleStockRelations(invoiceItem: InvoiceItem) {
      const state = this.getItemState(invoiceItem);
      state.stockRelationsVisible = !state.stockRelationsVisible;
      this.itemStates = { ...this.itemStates };
    },

    toggleItemItRelations(invoiceItem: InvoiceItem) {
      const state = this.getItemState(invoiceItem);
      state.itemItVisible = !state.itemItVisible;
      this.itemStates = { ...this.itemStates };
    },

    toggleInvoiceRelations(invoiceItem: InvoiceItem) {
      const state = this.getItemState(invoiceItem);
      state.invoiceRelationsVisible = !state.invoiceRelationsVisible;
      this.itemStates = { ...this.itemStates };
    },

    toggleIntrastatForm(invoiceItem: InvoiceItem) {
      const state = this.getItemState(invoiceItem);
      state.intrastatFormVisible = !state.intrastatFormVisible;
      this.itemStates = { ...this.itemStates };
    },

    toggleFixAssetForm(invoiceItem: InvoiceItem) {
      const state = this.getItemState(invoiceItem);
      state.fixAssetFormVisible = !state.fixAssetFormVisible;
      this.itemStates = { ...this.itemStates };
    },

    toggleInverseVatRelations(invoiceItem: InvoiceItem) {
      const state = this.getItemState(invoiceItem);
      state.inverseVatFormVisible = !state.inverseVatFormVisible;
      this.itemStates = { ...this.itemStates };
    },

    hasStockRelations(invoiceItem: InvoiceItem) {
      return invoiceItem.hasStockDivision;
    },

    getItemState(invoiceItem: InvoiceItem) {
      let state = this.itemStates[invoiceItem.cuid];
      if (!state) {
        state = {
          descriptionVisible: false,
          stockRelationsVisible: false,
          intrastatFormVisible: false,
          invoiceRelationsVisible: false,
          fixAssetFormVisible: false,
          inverseVatFormVisible: false,
          itemItVisible: false,
        };
      }

      this.itemStates[invoiceItem.cuid] = state;
      return state;
    },

    isOtherItem(item: InvoiceItem) {
      return item.type === InvoiceItemType.Service;
    },

    isCarRectItem(item: InvoiceItem) {
      return item.isCarRectItem && this.flowStore.invoice.rectify;
    },

    isWscontRectItem(item: InvoiceItem){
      return item.isWscontRectItem && this.flowStore.invoice.rectify;
    },

    isInverseVat(item: InvoiceItem) {
      return (
        !configuration.operel && (item.tax?.isInverseTax ?? false)
        // && item.relations.some((r) => (r.relatedItem as any)?.productID > 1 && r.type === InvoiceRelationType.Stock)
      );
    },

    groupNetto(key: string) {
      const items = this.items as InvoiceItem[];
      if (this.flowStore.invoice.dpaymIncl) {
        const originalItems = items.filter((i) => i.type === key && !i.dpaymItem).reduce((acc, i) => i.nettoValue + acc, 0.0);
        let dpaymSum = 0;
        items.filter((i) => i.type === key && i.dpaymItem).forEach((e) => {
          dpaymSum += e.downPaymentDetails?.netto ?? 0;
          dpaymSum += e.dpaymItem ? e.nettoValue : 0
        });
        return originalItems - Number(dpaymSum);
      }
      if (!configuration.onlyRectItem.enabled) {
        return items.filter((i) => i.type === key && i.visible).reduce((acc, i) => i.nettoValue + acc, 0.0);
      }
      return items.filter((i) => i.type === key && i.rectifiedType != "S").reduce((acc, i) => i.nettoValue + acc, 0.0);
    },

    groupBrutto(key: string) {
      const items = this.items as InvoiceItem[];
      if(!configuration.operel){
        if (this.flowStore.invoice.dpaymIncl) {
          const originalItems = items.filter((i) => i.type === key && !i.dpaymItem).reduce((acc, i) => i.bruttoValue + acc, 0.0);
          let dpaymSum = 0;
          items.filter((i) => i.type === key && i.dpaymItem).forEach((e) => {
            dpaymSum += e.downPaymentDetails?.amount ?? 0;
            dpaymSum += e.dpaymItem ? e.bruttoValue : 0;
          });
          return originalItems - Number(dpaymSum);
        }
        if (!configuration.onlyRectItem.enabled) {
          return items.filter((i) => i.type === key && i.visible).reduce((acc, i) => i.bruttoValue + acc, 0.0);
        }
        return items.filter((i) => i.type === key && i.rectifiedType != "S").reduce((acc, i) => i.bruttoValue + acc, 0.0);
    }
    else{
      const itemsBruttoSum = items.filter((i) => i.includeInSummary).reduce((acc, i) => acc + (i.nettoValue * (1+(i.tax.degree/100))), 0.0);
      return itemsBruttoSum;
    }
    },

    isRoundingItem(key: string) {
      const items = this.items as InvoiceItem[];
      const result = items.filter((i) => i.type === key).some((x) => x.isRoundingItem);
      return result;
    },

    stockPriceToleranceViolated(item: InvoiceItem) {
      const tolerance = configuration?.sysParams?.geniFulFillTolerance;
      if (!tolerance || tolerance < 0) return "";

      return item.isPriceToleranceViolated(tolerance) ? "error" : "warning";
    },

    stockPriceDifferenceInfo(item: InvoiceItem) {
      if (!item.toleranceCheckNeeded) {
        return "";
      }

      const tolerance = configuration.sysParams.geniFulFillTolerance;
      if (!tolerance || tolerance < 0) return "";

      const difference = item.stockPriceDifference;
      if (!difference || difference == 0) return "";
      else if (this.flowStore.invoice.rectify || this.flowStore.invoice.dpaym) return "";
      else if (difference > tolerance) return `Tolerancia szintet meghaladó eltérés (${difference}%)`;
      else if (difference <= tolerance) return `Tolerancia szinten belüli eltérés (${difference}%)`;

      return "";
    },

    async canApproveToleranceDifference() {
      const tolerance = configuration.sysParams.geniFulFillTolerance;
      const itemHasNonStock =
        this.store.itemList.items.length == 0 ||
        !this.store.itemList.items.every((i) => i.relations.some((r) => r.type === InvoiceRelationType.Stock));
      if (configuration.operel || !tolerance || tolerance < 0 || itemHasNonStock) return;

      await this.flowStore.getToleranceApprovalPermission(this.invoiceID, this.store.itemList.items);
    },

    findTax(taxID) {
      return this.store.taxAndVatItems.find((x) => x.id == taxID)?.name ?? "-";
    },

    pricePrecision(item: InvoiceItem) {
      const curr = item === null ? this.currency : item.currency;

      if (configuration.operel) return 2;
      else
        return configuration.getCurrDigits(curr) !== undefined
          ? configuration.getCurrDigits(curr)?.geniItemDigits ?? 2
          : 2;
    },

    priceUnitPrecision(item: InvoiceItem) {
      if (configuration.operel) return 2;
      else
        return configuration.getCurrDigits(item.currency) !== undefined
          ? configuration.getCurrDigits(item.currency)?.geniUnitDigits ?? 2
          : 2;
    },

    priceHeadPrecision(item: InvoiceItem) {
      if (configuration.operel) return 4;
      else
        return configuration.getCurrDigits(item.currency) !== undefined
          ? configuration.getCurrDigits(item.currency)?.geniHeadDigits ?? 0
          : 0;
    },
  },

  async mounted() {
    const units = await api.getUnits();
    const taxes = await api.getTaxes();
    const taxAndVatItems = await api.getAllTaxAndVatItem();
    const invoiceItems = await api.getInvoiceItems(this.invoiceID).then((x) => {
      x.map((f) => {
        this.isIntrastatFilled(f);
        return f;
      });
      return x;
    });

    if(configuration.isLoaded) {loadConfiguration()}

    const storeInFeeTypes = await api.getStoreInFeeTypes();
    const manualPrecision = configuration.sysParams.isGeniHUFItemPrecise ? 2 : undefined;

    if(configuration.isLoaded) {loadConfiguration()}
    if (!configuration.operel) this.downpaymGParts = await this.store.loadDownPaymGParts();
    this.store.init({
      invoiceID: this.invoiceID,
      currency: this.currency,
      supplierID: this.supplierID,
      defaultTaxID: this.defaultTaxID,
      storeInFeeTypes,
      units,
      taxes,
      taxAndVatItems,
      invoiceItems,
      manualPrecision,
    });

    if (!configuration.operel) {
      this.canApproveToleranceDifference();
    }
  },

  created() {
    if (!configuration.operel) {
      eventBus.$on("invoice-item:add", this.canApproveToleranceDifference);
    }
  },

  destroyed() {
    if (!configuration.operel) {
      eventBus.$off("invoice-item:add", this.canApproveToleranceDifference);
    }
  },

  watch: {
    invoiceID(invoiceID: number) {
      api.getInvoiceItems(this.invoiceID).then((invoiceItems) =>
        this.store.update({
          invoiceID,
          invoiceItems,
        })
      );
    },

    supplierID(supplierID: number) {
      this.store.update({ supplierID });
    },

    currency(currency: string) {
      this.store.update({ currency });
    },

    exchangeRate(exchangeRate: number) {
      this.store.update({ exchangeRate });
    },

    defaultTaxID(defaultTaxID: number) {
      this.store.update({ defaultTaxID });
    },
  },
});
