import { render, staticRenderFns } from "./NoticeItemSection.vue?vue&type=template&id=6cd887aa&scoped=true&"
import script from "./NoticeItemSection.vue?vue&type=script&lang=ts&"
export * from "./NoticeItemSection.vue?vue&type=script&lang=ts&"
import style0 from "./NoticeItemSection.vue?vue&type=style&index=0&id=6cd887aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd887aa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
installComponents(component, {VIcon,VTab,VTabItem})
