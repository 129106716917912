


































import Vue from "vue";
import PdfViewer from "@/components/PdfViewer.vue";
import store, { BankStatementStore } from "../services/BankStatementStore";

interface Data {
  store: BankStatementStore;
  currentPdf: number;
}

interface Methods {
  next(): void;
  previous(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  data: () => {
    return {
      store,
      currentPdf: 0,
    };
  },

  methods: {
    next() {
      if (this.currentPdf + 1 < store.notoPdfUint8Array.length) {
        this.currentPdf++;
      }
    },

    previous() {
      if (this.currentPdf - 1 >= 0) {
        this.currentPdf--;
      }
    },
  },

  components: {
    PdfViewer,
  },
});
