



















































































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import { InvoiceControlRequest, invoiceControlRequestDefaultProps } from "../models/InvoiceRequest";
import User from "../../partner/models/User";
import setAttribute from "../../../utils/dom";

interface Data {
  invoiceId: number;
  store: InvoiceFlowStore;
}

interface Props {
  fulFillRequest: boolean;
}

const colspanFixer = {
  render(h: any) {
    return h();
  },
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "3");
  },
};

export default Vue.extend<Data, {}, {}, Props>({
  components: {
    colspanFixer: colspanFixer,
  },

  props: {
    fulFillRequest: { type: Boolean, default: false },
  },

  data: () => ({
    invoiceId: 0,
    store,
  }),

  async beforeMount() {
    this.invoiceId = Number(this.$route.params.invoiceId);
    if (this.store.users.length == 0) await this.store.loadAccContrUsers();
    await this.store.loadControllerRequests(this.invoiceId);
  },

  computed: {
    controllers() {
      return this.fulFillRequest ? this.store.flowUsers.fullfilmentChecking : this.store.flowUsers.invoiceChecking;
    },

    items() {
      return this.store.controllerRequests.filter((x) => x.isFullFillReq == this.fulFillRequest);
    },

    columns: () => [
      { title: "Név", field: "name", className: "center_text" },
      { title: "Kérelem típusa", className: "center_text", cell: "requestTypeTemplate" },
      { title: "Megjegyzés", field: "remark", className: "center_text", cell: "remarkTemplate" },
      { width: 60, cell: "commandTemplate" },
    ],
  },

  methods: {
    async updateDelegateRequests() {
      await this.store.updateControlRequests();
      await this.store.loadAcceptState();
      this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
      this.$emit("closeView");
    },

    selectedControllerChanged(user: User) {
      this.store.invoice.contrPers = user?.id ?? 1;

      if (user?.id > 1) {
        const requestAlreadyExists = this.store.controllerRequests.some(
          (x) => x.controllerId == user.id && x.isFullFillReq == this.fulFillRequest
        );
        if (requestAlreadyExists) return;

        const newRequest: InvoiceControlRequest = {
          ...invoiceControlRequestDefaultProps,
          invId: this.store.invoice.id,
          controllerId: user.id,
          name: user.name,
          isFullFillReq: this.fulFillRequest,
        };
        this.store.controllerRequests = [newRequest, ...this.store.controllerRequests];
      }
    },

    setControllerDeleting(item: InvoiceControlRequest) {
      if (item.id == 0) {
        const index = this.store.controllerRequests.indexOf(item);
        if (index > -1) {
          this.store.controllerRequests.splice(index, 1);
        }
        return;
      }

      item.deleting = !item.deleting;
    },

    async confirmDelete(item: InvoiceControlRequest) {
      item.deleting = !item.deleting;
      await this.store.deleteControllerRequest(item.id);
      this.store.controllerRequests = this.store.controllerRequests.filter((x) => x.id != item.id);
      await this.store.loadAcceptState();
    },

    cancelDelete(item: InvoiceControlRequest) {
      item.deleting = !item.deleting;
    },

    closeControllersView() {
      this.$emit("closeView");
    },
  },
});
