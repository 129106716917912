



















































import Vue from "vue";
import CashDocByReceiptsStore from "../services/CashDocByreceiptsStore";
import ButtonCheckBox from "@/components/ButtonCheckBox.vue";
import Fragment from "@/components/Fragment.vue";

export default Vue.extend({
  components: {
    ButtonCheckBox,
  },

  props: {
    showTitle :{
      type: Boolean,
      default: true
    }
  },

  data: () => {
    return {
      CashDocByReceiptsStore,
    };
  },
});
