
























































































import api from "../services/BankRemitApi";
import Vue from "vue";
import BankRemitAndItems from "../models/BankRemitAndItems";
import BankRemitHistory from "../models/BankRemitHistory";
import dayjs from "dayjs";

interface Props {
  remit: BankRemitAndItems;
}

interface Data {
  remitHistory: BankRemitHistory[];
}

interface Method {
  modDate(value: Date): string;
  checkIfStatus(value: string): boolean;
}

export default Vue.extend<Data, Method, {}, Props>({
  props: {
    remit: { type: Object as () => BankRemitAndItems },
  },

  data: () => ({
    remitHistory: [],
  }),

  async beforeMount() {
    if (this.remit.id != 1) {
      this.remitHistory = await api.getBankRemitHistory(this.remit.id);
    } else if (Number(this.$route.params.id) != 1) {
      this.remitHistory = await api.getBankRemitHistory(Number(this.$route.params.id));
    }
  },

  methods: {
    modDate(value: Date) {
      return dayjs(value).format("YYYY. MM. DD HH:mm:ss");
    },
    checkIfStatus(value: string) {
      return value.toLowerCase().includes("állapot");
    },
  },
});
