var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"align":"left"}},[_c('h4',[_vm._v(" Előkontírozási tételek ")])]),_c('v-col',{attrs:{"align":"right"}},[_c('IconButton',{attrs:{"icon":"fa fa-refresh","tooltip":"Frissítés"},on:{"click":_vm.loadAccItems}})],1)],1),_c('grid',{ref:"accountItemsGrid",attrs:{"data-items":_vm.accItems,"edit-field":"inEdit","columns":_vm.columns,"scrollable":"none","expand-field":"expanded","detail":"detailTemplate","row-render":_vm.getMarginUnderStockGroup},scopedSlots:_vm._u([{key:"typeTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"\n                    primary--text\n                    text-center text-body-2\n                    font-weight-bold\n                  "},[_c('div',{staticClass:"row d-flex"},[(props.dataItem.head || _vm.hasNote(props.dataItem))?_c('div',{staticStyle:{"max-width":"180px","word-wrap":"break-word"}},[(props.dataItem.head)?_c('span',[_vm._v(_vm._s(props.dataItem.group))]):_vm._e(),(_vm.hasNote(props.dataItem))?_c('span',{staticStyle:{"display":"block","text-align":"left","white-space":"normal","overflow":"hidden","font-size":"11px","font-weight":"500","min-width":"120px","max-width":"150px !important","word-break":"keep-all"}},[_vm._v(_vm._s(props.dataItem.note))]):_vm._e()]):_vm._e()])])]}},{key:"typeHeaderTemplate",fn:function(){return [_c('div')]},proxy:true},{key:"tartozikTemplate",fn:function(ref){
var props = ref.props;
return [(props.dataItem.accCode === 'placehold')?_c('td',{staticClass:"\n                    primary--text\n                    text-center text-body-2\n                    font-weight-bold\n                  ",staticStyle:{"background-color":"white !important"}},[_c('div',{staticClass:"ml-8",staticStyle:{"font-weight":"bold","text-align":"left"}},[_vm._v(" ID ")])]):_c('td',{staticClass:"income-cell",class:_vm.tartozikStockGroupClass(props.dataItem)},[(
            props.dataItem.groupClass == 'groupStock' &&
              !props.dataItem.isDemand
          )?_c('div',{staticClass:"row groupStockInvItem"},[_vm._v(" "+_vm._s(props.dataItem.invItemName)+" ")]):_vm._e(),_c('div',{staticClass:"flex-row d-flex row",staticStyle:{"float":"left","width":"fit-content"}},[_c('v-col',{attrs:{"sm":"3","lg":"3"}},[(
                props.dataItem.accCode !== 'placehold' &&
                  props.dataItem.group === _vm.accountItemGroupOrder[2]
              )?_c('div',{staticClass:"d-flex align-items-center ml-auto",staticStyle:{"float":"left","width":"fit-content"}},[_c('v-chip',{staticClass:"my-auto mx-2",staticStyle:{"float":"left"},attrs:{"color":"grey","text-color":"white","small":""}},[_c('div',[_vm._v(" "+_vm._s(props.dataItem.stockID > 1 ? props.dataItem.stockID : "-")+" ")]),_c('v-icon',{attrs:{"x-small":"","right":""}},[_vm._v(" fa-info-circle ")])],1)],1):_vm._e()]),_c('v-col',{attrs:{"sm":"auto"}},[(
                props.dataItem.accCode !== 'placehold' &&
                  !props.dataItem.inEdit &&
                  !props.dataItem.isDemand
              )?_c('v-autocomplete',{attrs:{"dense":"","outlined":"","items":_vm.accCodes,"item-text":"name","item-value":"value","return-object":false,"hide-details":"auto"},model:{value:(props.dataItem.accCode),callback:function ($$v) {_vm.$set(props.dataItem, "accCode", $$v)},expression:"props.dataItem.accCode"}}):_vm._e()],1),_c('v-col',{attrs:{"sm":"auto"}},[(
                props.dataItem.inEdit &&
                  props.dataItem.accCode !== 'placehold'
              )?_c('v-autocomplete',{attrs:{"dense":"","outlined":"","items":_vm.accCodes,"item-text":"name","item-value":"value","return-object":false,"hide-details":"auto"},model:{value:(props.dataItem.accCode),callback:function ($$v) {_vm.$set(props.dataItem, "accCode", $$v)},expression:"props.dataItem.accCode"}}):_vm._e()],1)],1)])]}},{key:"tartozikTotalTemplate",fn:function(ref){
              var props = ref.props;
return [(props.dataItem.accCode !== 'placehold')?_c('td',{staticClass:"income-cell"},[_c('div',[(!props.dataItem.inEdit)?_c('div',[(!props.dataItem.isDemand && props.dataItem.total != null)?_c('div',[_c('money-input',{attrs:{"disabled":"","precision":2,"currency":"HUF"},model:{value:(props.dataItem.total),callback:function ($$v) {_vm.$set(props.dataItem, "total", _vm._n($$v))},expression:"props.dataItem.total"}})],1):_vm._e()]):_c('div',[_c('money-input',{attrs:{"disabled":"","currency":"HUF","precision":2},model:{value:(props.dataItem.total),callback:function ($$v) {_vm.$set(props.dataItem, "total", _vm._n($$v))},expression:"props.dataItem.total"}})],1)])]):_c('td',{staticClass:"income-cell",staticStyle:{"background-color":"white !important"}})]}},{key:"tartozikCurrTotalTemplate",fn:function(ref){
              var props = ref.props;
return [(props.dataItem.accCode !== 'placehold')?_c('td',{staticClass:"income-cell"},[(!props.dataItem.inEdit)?_c('div',[(
              !props.dataItem.isDemand && props.dataItem.currTotal != null
            )?_c('div',[_c('money-input',{attrs:{"currency":props.dataItem.invCurrency,"disabled":"","precision":_vm.accountingPrecision},model:{value:(props.dataItem.currTotal),callback:function ($$v) {_vm.$set(props.dataItem, "currTotal", _vm._n($$v))},expression:"props.dataItem.currTotal"}})],1):_vm._e()]):_c('div',[_c('money-input',{attrs:{"disabled":"","currency":props.dataItem.currency,"precision":_vm.accountingPrecision},model:{value:(props.dataItem.currTotal),callback:function ($$v) {_vm.$set(props.dataItem, "currTotal", _vm._n($$v))},expression:"props.dataItem.currTotal"}})],1)]):_c('td',{staticClass:"income-cell",staticStyle:{"background-color":"white !important"}})]}},{key:"kovetelTemplate",fn:function(ref){
            var props = ref.props;
return [(props.dataItem.accCode !== 'placehold')?_c('td',{staticClass:"demand-cell"},[(!props.dataItem.inEdit)?_c('div',[(props.dataItem.isDemand)?_c('div',[_c('v-autocomplete',{staticStyle:{"width":"95% !important"},attrs:{"dense":"","outlined":"","items":_vm.accCodes,"item-text":"name","item-value":"value","return-object":false,"hide-details":"auto"},model:{value:(props.dataItem.accCodeDemand),callback:function ($$v) {_vm.$set(props.dataItem, "accCodeDemand", $$v)},expression:"props.dataItem.accCodeDemand"}})],1):_vm._e()]):_c('div',[_c('v-autocomplete',{staticStyle:{"width":"95% !important"},attrs:{"dense":"","outlined":"","items":_vm.accCodes,"item-text":"name","item-value":"value","return-object":false,"hide-details":"auto"},model:{value:(props.dataItem.accCodeDemand),callback:function ($$v) {_vm.$set(props.dataItem, "accCodeDemand", $$v)},expression:"props.dataItem.accCodeDemand"}})],1)]):_c('td',{staticClass:"demand-cell",staticStyle:{"background-color":"white !important"}})]}},{key:"kovetelTotalTemplate",fn:function(ref){
            var props = ref.props;
return [(props.dataItem.accCode !== 'placehold')?_c('td',{staticClass:"demand-cell"},[(!props.dataItem.inEdit)?_c('div',[(
              props.dataItem.isDemand && props.dataItem.totalDemand != null
            )?_c('div',[_c('money-input',{attrs:{"disabled":"","currency":"HUF","precision":2},model:{value:(props.dataItem.totalDemand),callback:function ($$v) {_vm.$set(props.dataItem, "totalDemand", _vm._n($$v))},expression:"props.dataItem.totalDemand"}})],1):_vm._e()]):_c('div',[_c('money-input',{attrs:{"disabled":"","currency":"HUF","precision":2},model:{value:(props.dataItem.totalDemand),callback:function ($$v) {_vm.$set(props.dataItem, "totalDemand", _vm._n($$v))},expression:"props.dataItem.totalDemand"}})],1)]):_c('td',{staticClass:"demand-cell",staticStyle:{"background-color":"white !important"}})]}},{key:"kovetelCurrTotalTemplate",fn:function(ref){
            var props = ref.props;
return [(props.dataItem.accCode !== 'placehold')?_c('td',{staticClass:"demand-cell"},[(!props.dataItem.inEdit)?_c('div',[(
              props.dataItem.isDemand &&
                props.dataItem.currTotalDemand != null
            )?_c('div',[_c('money-input',{attrs:{"currency":props.dataItem.invCurrency,"disabled":"","precision":_vm.accountingPrecision},model:{value:(props.dataItem.currTotalDemand),callback:function ($$v) {_vm.$set(props.dataItem, "currTotalDemand", _vm._n($$v))},expression:"props.dataItem.currTotalDemand"}})],1):_vm._e()]):_c('div',[_c('money-input',{attrs:{"disabled":"","currency":props.dataItem.invCurrency,"precision":_vm.accountingPrecision},model:{value:(props.dataItem.currTotalDemand),callback:function ($$v) {_vm.$set(props.dataItem, "currTotalDemand", _vm._n($$v))},expression:"props.dataItem.currTotalDemand"}})],1)]):_c('td',{staticClass:"demand-cell",staticStyle:{"background-color":"white !important"}})]}},{key:"currencyTemplate",fn:function(ref){
            var props = ref.props;
return [(props.dataItem.accCode !== 'placehold')?_c('td',[_c('tooltip',{attrs:{"tooltip":("Árfolyam: " + (props.dataItem.exchangeRate)),"show":props.dataItem.exchangeRate > 1},scopedSlots:_vm._u([{key:"activator",fn:function(s){return [(!props.dataItem.inEdit)?_c('span',_vm._g({},s.on),[_vm._v(" "+_vm._s(props.dataItem[props.field])+" ")]):_c('span',_vm._g({},s.on),[_c('v-select',{staticClass:"no-padding",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"auto","items":_vm.store.currencies,"item-text":"currency","item-value":"currency"},model:{value:(props.dataItem.currency),callback:function ($$v) {_vm.$set(props.dataItem, "currency", $$v)},expression:"props.dataItem.currency"}})],1)]}}],null,true)})],1):_c('td',{staticStyle:{"background-color":"white !important"}})]}},{key:"accDateTemplate",fn:function(ref){
            var props = ref.props;
return [(
          _vm.store.invoice.accDate != props.dataItem[props.field] &&
            _vm.store.invoice.shipDate != props.dataItem[props.field] &&
            _vm.store.invoice.shipDateC &&
            props.dataItem.accCode !== 'placehold'
        )?_c('td',{staticClass:"center_text"},[(!props.dataItem.inEdit)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormatHU")(props.dataItem[props.field]))+" "),_c('v-icon',[_vm._v("fa-exclamation")])],1):_c('span',[_c('v-text-field',{staticClass:"no-padding",attrs:{"type":"date","outlined":"","single-line":"","hide-details":"auto"},model:{value:(props.dataItem.accDate),callback:function ($$v) {_vm.$set(props.dataItem, "accDate", $$v)},expression:"props.dataItem.accDate"}})],1)]):(props.dataItem.accCode !== 'placehold')?_c('td',{staticClass:"center_text"},[(!props.dataItem.inEdit)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormatHU")(props.dataItem[props.field]))+" ")]):_c('span',[_c('v-text-field',{staticClass:"no-padding",attrs:{"type":"date","outlined":"","single-line":"","hide-details":"auto"},model:{value:(props.dataItem.accDate),callback:function ($$v) {_vm.$set(props.dataItem, "accDate", $$v)},expression:"props.dataItem.accDate"}})],1)]):_c('td',{staticStyle:{"background-color":"white !important"}})]}},{key:"separatorTemplate",fn:function(ref){
        var props = ref.props;
return [(props.dataItem.accCode !== 'placehold')?_c('td',{staticClass:"income-demand-separator"}):_c('td',{staticStyle:{"background-color":"white !important"}})]}},{key:"headerIncomeTemplate",fn:function(ref){
        var props = ref.props;
return [_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"font-weight-bold grey--text text--darken-3"},[_vm._v(_vm._s(props.title))]),_c('v-icon',{staticClass:"ml-1 green--text",attrs:{"x-small":""}},[_vm._v(" fa-arrow-up ")])],1)]}},{key:"headerDemandTemplate",fn:function(ref){
        var props = ref.props;
return [_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"font-weight-bold grey--text text--darken-3"},[_vm._v(_vm._s(props.title))]),_c('v-icon',{staticClass:"ml-1 red--text",attrs:{"x-small":""}},[_vm._v(" fa-arrow-down ")])],1)]}},{key:"headerNumericTemplate",fn:function(ref){
        var props = ref.props;
return [_c('span',{staticStyle:{"text-align":"right","display":"block"}},[_vm._v(_vm._s(props.title))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }