




















































import Vue from "vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import UpdatingParameter from "@/modules/configuration/components/UpdatingParameter.vue";

interface Data{
  store: InvoiceFlowStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  props: {
    items: {
      type: Array as () => string[],
    },
    invCurrency: String,
  },

  data() {
    return {
      store,
    }
  },

  components: {
    MoneyDisplay,
    UpdatingParameter
  },
});
