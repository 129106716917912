


















import Vue from "vue";
import {
  ExperimentalInvoiceClass,
  experimentalInvoiceDefaultProps,
} from "../models/ExperimentalInvoice";
import PdfViewer from "@/components/PdfViewer.vue";
import cashStatementStore from "@/modules/cashStatement/services/store";
import store from "../services/store";

export default Vue.extend({
  methods:{
    setReportEmpty(){
      this.store.reportSrc = new Uint8Array();
      this.$emit("exit")
    }
  },

  data() {
    return {
      cashStatementStore,
      store,
    };
  },
  
  components: {
    PdfViewer,
  },
});
