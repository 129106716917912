export default interface BankStatus {
  id: number;
  name: string;
}

export const bankStatusDefaultProps: BankStatus = {
  id: 0,
  name: "",
};

export const bankStatusDefaultFilterProps: BankStatus = {
  id: 0,
  name: "Összes",
};
