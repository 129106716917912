export default interface ProjectHeadEditModel {
    id: number;
    name: string;
    code: string;
    codeAndName: string;
}

export const projectHeadEditModelDefaultProps: ProjectHeadEditModel = {
    id: 0,
    name: "",
    code: "",
    codeAndName: "",
}