














































































































































































































































































































































































































































import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import DocumentTemplatePicker from "../components/DocumentTemplatePicker.vue";
import CurrencyPicker from "../components/CurrencyPicker.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import SetIncorrectInvoice from "../components/SetIncorrectInvoice.vue";
import StockCorrectionMaskGenerate from "@/modules/uninvoicedStockCorrection/components/StockCorrectionMaskGenerate.vue";

import Vue from "vue";
import eventBus from "../../../services/eventBus";
import { formatDate, formatDateISO } from "@/utils/formatDates";
import configuration, { AppConfiguration } from "@/models/Configuration";
import store, { FlowStatus, InvoiceFlowStore } from "../services/InvoiceFlowStore";
import wizardStore, { InvoiceWizardStore } from "@/modules/invoiceFlowTask/services/InvoiceTaskWizard";
import permStore, { PermissionStore } from "@/services/permissions";
import relStore, { FlowDocumentRelationStore } from "@/modules/flowDocumentRelation/services/FlowDocumentRelationStore";
import partnerStore, { PartnerStore } from "../../partner/services/PartnerStore";
import dtStore, { DocumentTemplateStore } from "@/modules/documentTemplate/services/DocumentTemplateStore";
import correctionStore, { UninvoicedStockCorrectionStore } from "@/modules/uninvoicedStockCorrection/services/store";
import InvoiceFlowStatusRelation from "@/modules/invoiceFlowStatus/model/InvoiceFlowStatusRelation";
import Partner from "../../partner/models/Partner";
import itemStore, { InvoiceItemStore } from "@/modules/invoiceItems/services/store";

interface Data {
  configuration: AppConfiguration;
  store: InvoiceFlowStore;
  itemStore: InvoiceItemStore;
  wizardStore: InvoiceWizardStore;
  permStore: PermissionStore;
  relStore: FlowDocumentRelationStore;
  partnerStore: PartnerStore;
  dtStore: DocumentTemplateStore;
  correctionStore: UninvoicedStockCorrectionStore;
  isUninvoicedStockCorrectionSubtype: boolean;
  optionalPropsVisible: boolean;
  partnerSearchDialog: boolean;
  detailsVisible: boolean;
  currency: string;
  isLoading: boolean;
  bankDerfTypes: any[];
}

interface Methods {
  changePartner(): void;
  editPartner(): void;
  createPartner(): void;
  totalChanged(): void;
  setPayTermByInvoiceDate(): Promise<void>;
  onPartnerChange(partner: Partner): void;
  onPaymentModeChange(): void;
  checkBeforeSave(): boolean;
  update(saveOnly?: boolean): void;
  createInvoiceOnPictureUpload(): void;

  isUninvoicedStockCorrectionSubtypeChanged(v: boolean): void;
  getGeniHeadDigits(currency: string): number;
  next(): void;
}

interface Computed {
  validationError: string;
  isPageDisabled: boolean;
  // eslint-disable-next-line
  form: any;
  recordedStatus: InvoiceFlowStatusRelation | undefined;
  recorder: string | undefined;
  preStatusCheck: boolean | undefined;
  regDate: string | undefined;
  selectedCurrency: string;
  isNext: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    PartnerSearch,
    DocumentTemplatePicker,
    CurrencyPicker,
    MoneyInput,
    SetIncorrectInvoice,
    StockCorrectionMaskGenerate,
  },

  data: (): Data => ({
    configuration,
    store,
    itemStore,
    wizardStore,
    permStore,
    relStore,
    partnerStore,
    dtStore,
    correctionStore,
    isUninvoicedStockCorrectionSubtype: false,
    optionalPropsVisible: false,
    partnerSearchDialog: false,
    detailsVisible: false,
    currency: "HUF",
    isLoading: false,
    bankDerfTypes: [
      {
        id: 0,
        name: "-"
      },
      {
        id: 1,
        name: "SHA"
      },
      {
        id: 2,
        name: "OUR"
      },
      {
        id: 3,
        name: "BEN"
      },
    ],
  }),

  async created() {
    eventBus.$on("geni-picture:new", this.createInvoiceOnPictureUpload);

    if (
      this.store.selectedCurrency?.currency.toUpperCase().trim() !== "HUF" &&
      this.store.selectedCurrency?.currency !== "" &&
      this.store.invoice.invCurrency !== "" &&
      this.store.invoice.exchangeRate < 2 &&
      this.store.invoice.id > 1
    ) {
      try {
        console.log(
          "this.store.selectedCurrency?.currency.toUpperCase().trim() = " +
            this.store.selectedCurrency?.currency.toUpperCase().trim()
        );
        console.log("this.store.selectedCurrency?.currency = " + this.store.selectedCurrency?.currency);
        console.log("this.store.invoice.invCurrency = " + this.store.invoice.invCurrency);
        console.log(
          "this.store.invoice.exchangeRate = " +
            this.store.invoice.exchangeRate +
            " ---> <2 ?= " +
            (this.store.invoice.exchangeRate < 2)
        );
        await this.store.selectedCurrencyChanged();
      } catch (ex) {
        console.log(ex);
      }
    }
  },

  beforeDestroy() {
    eventBus.$off("geni-picture:new", this.createInvoiceOnPictureUpload);
  },

  async beforeMount() {
    window.scrollTo(0, 0);
    console.log(this.$route.params);
    if (this.store.paymentModes.length == 0) await this.store.loadPaymentModes();

    if (Number(this.$route.params.partnerId) > 2) {
      this.store.invoice.partner = Number(this.$route.params.partnerId);

      await this.store.loadInvoiceDetails(Number(this.$route.params.partnerId));
    }

    await this.store.loadUninvoicedStockCorrectionSubtypes();
    await this.correctionStore.loadMainCode(this.store.invoice.id, this.store.invoice.partner);

    this.store.invDateStart = "";
    this.store.invDateEnd = "";
    this.store.accDateStart = "";
    this.store.accDateEnd = "";

    // Nem számlázott készlet?
    const subtype = this.store.subtypes.find((x) => x.id == this.store.invoice.subType);
    this.isUninvoicedStockCorrectionSubtype = this.store.invoice.subType != 1 && subtype != undefined;
    this.store.selectedSubtype = subtype ?? this.store.subtypes.find((x) => x.id == 1) ?? this.store.subtypes[0];

    this.currency =
      this.store.invoice.invCurrency.trim() != ""
        ? this.store.invoice.invCurrency
        : (this.store.selectedCurrency?.currency ?? "").trim() != ""
        ? this.store.selectedCurrency?.currency ?? "HUF"
        : "HUF";

    if (this.configuration.operel) {
      return;
    }
    if (!this.configuration.sysParams.bankDefrayerEnabled) {
      return;
    }
    if (this.store.invoice.id > 2) {
      return;
    }
    if (this.store.invoiceDetails.partnerSpecTaxType == 3) {
      this.store.invoice.bankDefrType = "SHA";
    }
  },

  watch: {
    selectedCurrency() {
      this.currency = this.selectedCurrency ?? this.currency;
    },
  },

  mounted() {
    this.form.validate();
  },

  methods: {
    next() {
      this.$router.push(`/general-incoming-invoice/flow/edit/${this.store.invoice.id}/3`);
    },

    getGeniHeadDigits(currency) {
      return configuration.operel
        ? 2
        : configuration.getCurrDigits(currency)
        ? configuration.getCurrDigits(currency)?.geniHeadDigits ?? 0
        : 0;
    },

    editPartner() {
      if (!this.store.isEditingEnabled || this.isPageDisabled) return;
      else this.$router.push(`/partners/${this.store.invoice.partner}/${this.$route.params.invoiceId}`);
    },

    createPartner() {
      if (!this.store.isEditingEnabled || this.isPageDisabled) return;
      else {
        this.$router.push(`/partners/create/${this.$route.params.invoiceId}`);
      }
    },

    changePartner() {
      if (!this.store.isEditingEnabled || this.isPageDisabled || this.preStatusCheck) return;
      else this.partnerSearchDialog = !this.partnerSearchDialog;
    },

    totalChanged() {
      this.store.totalChanged(true);
    },

    async setPayTermByInvoiceDate() {
      // TODO: Ha kell > ne változzon a fizetési határidő ha már iktatva van a számla
      // if (this.store.flowStatusRelations.some((x) => x.statusId >= FlowStatus.Recording)) return;

      const payTerm = await this.store.loadPartnerPayTerm(
        Number(this.$route.params.partnerId) || this.store.invoice.partner
      );

      console.log(payTerm);

      if (payTerm) {
        this.store.invoice.payTerm = payTerm;
      }
    },

    async onPartnerChange(partner: Partner) {
      this.store.invoice.partner = partner.id;
      await this.store.loadInvoiceDetails(partner.id);
      this.itemStore.supplierID = partner.id;

      if (!this.store.invoiceDetails.partnerIsNative) {
        await this.partnerStore.loadAllEuTaxesByPartnerIdParam(partner.id);
      }

      // currency changed
      this.store.selectedCurrency = this.store.currencies.find(
        (x) => x.currency == this.store.invoiceDetails.partnerCurrency
      );
      await this.store.selectedCurrencyChanged();

      // paymode changed
      this.store.selectedPaymentMode = this.store.paymentModes.find(x => x.id == this.store.invoiceDetails.partnerPaymentMethodId);

      // payTerm / payDate changed
      await this.setPayTermByInvoiceDate();

      // load (spec)tax rates for partner's tax
      await this.store.loadAllTaxes().then(() => {
        this.store.setSpecTax();
      });

      this.partnerSearchDialog = false;
      ((this.$refs.invoiceNo as any).$el.querySelector('input') as HTMLElement).focus();
    },

    onPaymentModeChange() {
      this.store.invoice.paymode = this.store.selectedPaymentMode?.id ?? 1;
    },

    checkBeforeSave() {
      if (!this.store.invoice.invoiceNo.trim()) {
        eventBus.$emit("error", "Bizonylat szám megadása kötelező!");
        return false;
      }

      if (this.configuration.sysParams.invoiceDocumentMandatory) {
        if ((this.store.attachment.file == "" || (this.store.attachment.id > 1 && this.store.attachment.link == "")) && this.$route.meta.isPictureRequired) {
          eventBus.$emit("error", "Számla kép feltöltése közelező!");
          return false;
        }
      }

      if (this.relStore.hasAllRequiredDocuments(FlowStatus.Recording)) {
        eventBus.$emit("error", "Hiányoznak az iktatáshoz tartozó kötelező dokumentum(ok)!");
        return false;
      }

      return true;
    },

    createInvoiceOnPictureUpload() {
      this.update(true);
    },

    async update(saveOnly = false) {
      console.log(this.store.attachment.name);
      if (this.isPageDisabled) {
        eventBus.$emit("error", "Nincs jogosultsága a számla iktatáshoz!");
        return;
      }

      const currentUrl = window.location.href;
      if (currentUrl.includes("diff-vat")) {
        this.store.invoice.isDifferentialVat = true;
      }

      if (!saveOnly) {
        if (!this.checkBeforeSave()) return;
        if (this.store.currentStatusID() < FlowStatus.Recording) {
          this.store.invoice.regDate = formatDateISO(new Date());
        }
        // console.log(this.store.invoice.id, "Iktatás - update()", JSON.parse(JSON.stringify(this.store.invoice)));
      }

      try {
        this.isLoading = true;
        await this.store.updateInvoiceRecording(saveOnly).then(async (invoiceId) => {
          const isPictureRequired = this.$route.meta.isPictureRequired;
          let path = "";
          if (this.store.invoice.id == 0) {
            path = `/general-incoming-invoice/flow/edit/${invoiceId}/${FlowStatus.Recording}`;
          }

          if (path.trim() != "") this.$router.push(path);

          if (!saveOnly) {
            this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
            this.$emit("update");
            eventBus.$emit("success", "Sikeres számla iktatás.");
          } else {
            eventBus.$emit("success", "Sikeres mentés.");
          }

          if (this.store.invoice.id == 0) {
            this.store.invoice.id = invoiceId;
            eventBus.$emit(
              "flow-doc-template:new-geni",
              {
                template: this.dtStore.template ?? this.store.invoiceDetails.partnerDocumentTemplate ?? "-",
                init: false,
                invoice: invoiceId
              }
            );
          }

          if (invoiceId && this.store.attachment.id == 0 && this.store.attachment.file != "") {
            await this.store.uploadGENIPicture(invoiceId);
            await this.store.loadGENIPicture(invoiceId);
          }

          // Uninvoiced Stock Correction
          if (
            // new mainCode
            this.correctionStore.mainCode.id == 0 &&
            // subtype is selected
            this.correctionStore.mainCode.type > 1 &&
            this.store.invoice.subType > 1 &&
            // generated wasn't overwritten
            this.correctionStore.mainCode.generated == this.store.invoice.invoiceNo
          ) {
            await this.correctionStore.saveMainCode(
              this.store.invoice.id,
              this.store.invoice.partner,
              this.store.selectedSubtype?.id ?? 1,
              this.correctionStore.mainCode
            );
          }
          this.$route.meta.isPictureRequired = isPictureRequired;
        });

        if (!this.store.invoiceDetails.partnerIsNative) {
          const partnerEuTaxes = this.partnerStore.partnerEuTaxes.filter((x) => x.id == 0);
          await this.partnerStore.createPartnerEuTaxes(partnerEuTaxes);
        }

        if (saveOnly && this.configuration.sysParams.automaticSupplierRelationEnabled) {
          await this.store.createSupplierRelation();
        }
      } catch (ex) {
        console.log(ex);
        throw ex;
      } finally {
        this.isLoading = false;
        
        this.$nextTick(() => (this.$refs.doneButton as any).$el.focus());
      }
    },

    isUninvoicedStockCorrectionSubtypeChanged(v) {
      if (v) {
        this.store.invoice.type = this.store.selectedSubtype?.type ?? 1;
        this.store.invoice.subType = this.store.selectedSubtype?.id ?? 1;
        return;
      }

      this.store.invoice.type = 1;
      this.store.invoice.subType = 1;
    },
  },

  computed: {
    isNext() {
      console.log(this.store.flowStatusRelations);
      return this.store.flowStatusRelations.length > 1;
    },

    selectedCurrency() {
      return this.store.selectedCurrency?.currency ?? this.currency;
    },

    validationError() {
      const invDate = new Date(this.store.invoice.invoiceDate);
      const arrivDate = new Date(this.store.invoice.arrivDate);
      if (invDate > arrivDate) {
        return "A számla dátuma nem lehet későbbi mint az érkezés dátuma.";
      }

      return "";
    },

    isPageDisabled() {
      return !this.permStore.hasRecording() || !this.store.isEditingEnabled;
    },

    form(): Vue {
      return this.$refs.form as Vue;
    },

    recordedStatus() {
      return this.store.flowStatusRelations.find((x) => x.statusId == FlowStatus.Recording);
    },

    regDate() {
      const date = this.recordedStatus?.insDate;
      return formatDate(date);
    },

    recorder() {
      return this.recordedStatus?.insUserName;
    },

    preStatusCheck() {
      return this.store.invoice.preStatus == 9;
    },
  },
});
