import { getDecimalPlaces } from "@/services/prices"
import Decimal from "decimal.js-light"

export const formatMoney = (value: number, currency = "HUF", decimals: number | undefined = undefined) => {
    const minimumFractionDigits = decimals ?? getDecimalPlaces(currency)
    return new Intl.NumberFormat('hu-HU', { minimumFractionDigits, maximumFractionDigits: minimumFractionDigits, useGrouping: true }).format(value)
}

const trimStringValue = (value: string, decimals: number) => {
    const parts = value.split('.')
    if (parts.length !== 2) {
        return value
    }

    const decimalPart = parts[1]
    if (decimalPart.length > decimals) {
        return value.substring(0, value.length - (decimalPart.length - decimals))
    }

    return value
}

const moneyFormat = {
    filter: (value: number, currency = "HUF", decimals: undefined | number) => formatMoney(value, currency, decimals),
    parser: (value: string, currency = "HUF", decimals: number | undefined = undefined): number => {
        if (!value) {
            return 0.0
        }

        const thousandSeparator = Intl.NumberFormat("hu-HU").format(11111).replace(/\p{Number}/gu, '');
        const decimalSeparator = Intl.NumberFormat("hu-HU").format(1.1).replace(/\p{Number}/gu, '');
        const maxDecimals = decimals ?? getDecimalPlaces(currency)

        const strValue = trimStringValue(value
            .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
            .replace(new RegExp('\\' + decimalSeparator), '.'), maxDecimals
        )

        const decimalValue = new Decimal(parseFloat(strValue))
        return decimalValue.toDecimalPlaces(maxDecimals, Decimal.ROUND_HALF_CEIL).toNumber()
    }
}

export default moneyFormat