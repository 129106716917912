




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import store, { NoticeStore } from "../services/NoticeStore";
import auth from "@/services/auth";
import Page from "../../../components/Page.vue";
import InvoiceStatus from "../models/InvoiceStatus";
import AccountStatus from "../models/AccountStatus";
import InvoiceGroupDisplay from "../../invoiceGroup/models/InvoiceGroupDisplay";
import User, { userDefaultProps } from "../../invoiceGroup/models/User";
import PaymentModeDisplay from "../../paymentMode/models/PaymentModeDisplay";
import Language from "../models/Language";
import NoticeType, { noticeTypeDefaultProps } from "../models/NoticeType";
import NoticeGenoType from "../models/NoticeGenoType";
import Country from "../../cashPoint/models/Country";
import Partner, { partnerDefaultProps } from "../../partner/models/Partner";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import NoticeItemSection from "./NoticeItemSection.vue";
import ExistingGparts from "./ExistingGparts.vue";
import eventBus from "@/services/eventBus";
import DateInput from "@/components/DateInput.vue";
import Notice from "../models/Notice";
import ConfirmationBox from "@/components/ConfirmationBox.vue";
import NoticeDivitem, { noticeDivitemDefaultProps } from "../models/NoticeDivitem";
import HeaderItem from "@/components/HeaderItem.vue";
import PartBank, { partBankDefaultProps } from "@/modules/cashPoint/models/PartBank";
import kanriStore, { KanriInvoiceStore } from "@/modules/kanri/services/KanriInvoiceStore";
import configuration, { AppConfiguration } from '@/models/Configuration';
import MoneyInput from "@/components/MoneyInput.vue";
import { ConfirmationMixin } from "@/mixins/confirmation";
import ConfirmationRow from "../../../components/Confirmation.vue";
import CashPointSelect from "@/modules/cashPoint/models/CashPointSelect";

class Confirmation {
  actionType: string;
  message: string;
  primaryText?: string = "Igen";
  secondaryText?: string = "Nem";
  acceptOperation?: Function;
  declineOperation?: Function;

  constructor(
    actionType: string,
    message: string,
    primaryText?: string,
    secondaryText?: string,
    acceptOperation?: Function,
    declineOperation?: Function
  ) {
    this.actionType = actionType;
    this.message = message;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.acceptOperation = acceptOperation;
    this.declineOperation = declineOperation;
  }
}

interface Data {
  store: NoticeStore;
  kanriStore: KanriInvoiceStore;
  selectedStatus: InvoiceStatus | undefined;
  selectedAccountStatus: AccountStatus | undefined;
  selectedInvoiceGroup: InvoiceGroupDisplay | undefined;
  selectedUser: User;
  selectedPaymentMode: PaymentModeDisplay | undefined;
  selectedLanguage: Language | undefined;
  selectedNoticeType: NoticeType | undefined;
  selectedNoticeGenoType: NoticeGenoType | undefined;
  selectedCurrency: Country | undefined;
  selectedDivitem: NoticeDivitem;
  accountsTake: number;
  accountsSkip: number;
  banksDialog: boolean;
  bankName: string;
  partnerSearchDialog: boolean;
  partnerDetailsVisible: boolean;
  detailsVisible: boolean;
  validationError: string;
  confirmationVisible: boolean;
  confirmationMessage: string;
  confirmations: Confirmation[];
  currentConfirmation: Confirmation;
  actionType: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  configuration: AppConfiguration;
  payInProgress: boolean;
  selectedExhangeRate: string;
  isCashPointChoose: boolean;
  selectedCashPoint: CashPointSelect;
}

interface Methods {
  selectedInvoiceGroupChanged(): void;
  selectedUserChanged(): void;
  findPartnerPMode(): void;
  selectedPaymentModeChanged(): void;
  selectedLanguageChanged(): void;
  selectedNoticeGenoTypeChanged(): void;
  findPartnerCurrency(): void;
  selectedCurrencyChanged(): void;
  selectedDivitemChanged(): void;
  partBanksIsAliveChanged(): void;
  findPartnerBankAccount(): void;
  selectedBankChanged(event: any): void;
  accountsPageChange(event: any): void;
  calculatePterm(): void;
  formatDate(date: any): string;
  addDays(date: Date, days: number): Date;
  cancel(): void;
  saveAction(): void;
  printAction(): void;
  deleteNoto(): void;
  pay(): void;
  stornoAction(): void;
  validateSave(): boolean;
  validatePrint(): boolean;
  validateStorno(): boolean;
  showConfirmation(): void;
  acceptConfirmationBox(): void;
  closeConfirmationBox(): void;
  doCurrentAction(): void;
  performSaveAction(): void;
  performPrintAction(): void;
  performStornoAction(): void;
  currencyChanged(): void;
  partnerSelected(partner: Partner): void;
  validator(newNoto: Notice, oldNoto: Notice): void;
  resetValidator(): void;
  passedBankAccountValidation(noto: Notice, prevNoto: Notice): boolean;
  passedPartnerValidation(noto: Notice, prevNoto: Notice): boolean;
  passedNoticeTypeValidation(noto: Notice, prevNoto: Notice): boolean;
  passedProjectValidation(noto: Notice, prevNoto: Notice): boolean;
  passedShipDateValidation(noto: Notice, prevNoto: Notice): boolean;
  passedItemValidation(): boolean;
  copyFromLastSave(): void;
  modifiedSinceOpened(): boolean;
  confirmationNeeded(actionType: string): boolean;
  addConfirmation(confirmation: Confirmation): void;
  adjustScrollbarForConfirmationBox(): void;
  selectedExhangeRateChanged(e: any): void;
  payCard(): void;
  payCheck(): void;
}

interface Computed {
  inCreateMode: boolean;
  inEditMode: boolean;
  bankItems: any;
  accountsTotal: any;
  partsTotal: any;
  passedValidation: boolean;
  notoToWatch: Notice;
  calculatedNetto: number;
  calculatedVat: number;
  calculatedTotal: number;
  stornoButtonVisible: boolean;
  printed: boolean;
  payable: boolean;
}

export default ConfirmationMixin.extend<Data, Methods, Computed, {}>({
  components: {
    Page,
    PartnerSearch,
    NoticeItemSection,
    ExistingGparts,
    DateInput,
    ConfirmationBox,
    HeaderItem,
    MoneyInput,
    ConfirmationRow
  },

  mixins: [ConfirmationMixin],

  data(): Data {
    return {
      store,
      kanriStore,
      selectedStatus: undefined,
      selectedAccountStatus: undefined,
      selectedInvoiceGroup: undefined,
      selectedUser: { ...userDefaultProps },
      selectedPaymentMode: undefined,
      selectedLanguage: undefined,
      selectedNoticeType: undefined,
      selectedNoticeGenoType: undefined,
      selectedCurrency: undefined,
      selectedDivitem: { ...noticeDivitemDefaultProps },
      accountsTake: 10,
      accountsSkip: 0,
      banksDialog: false,
      bankName: "",
      partnerSearchDialog: false,
      partnerDetailsVisible: false,
      detailsVisible: false,
      validationError: "",
      confirmations: [],
      confirmationVisible: false,
      confirmationMessage: "",
      currentConfirmation: { actionType: "", message: "" },
      actionType: "",
      primaryButtonText: "Igen",
      secondaryButtonText: "Nem",
      configuration,
      payInProgress: false,
      selectedExhangeRate: "",
      isCashPointChoose: false,
      selectedCashPoint: new CashPointSelect(),
    };
  },

  async beforeMount() {
    await this.store.loadNotice(+this.$route.params.noticeId || 0);

    this.inCreateMode;

    await Promise.all([
      this.store.loadNoticeDetails(this.store.notice.partner, this.store.notice.id),
      this.store.loadInvoiceStatuses(),
      this.store.loadAccountStatuses(),
      this.store.loadInvoiceGroups(),
      this.store.loadUsers(),
      this.store.loadPaymentModes(),
      this.store.loadLanguages(),
      this.store.loadNoticeTypes(),
      this.store.loadNoticeGenoTypes(),
      this.store.loadCurrencies(),
      this.store.loadPartCust(this.store.notice.partner),
      this.store.loadPartBanks(),
      this.store.loadDivitems(),
      this.store.loadCashPoints(),
    ]);

    // új/meglévő noto esetén értékek beállítása
    if (this.inCreateMode || this.inEditMode) {
      this.selectedStatus = this.store.invoiceStatuses.find((x) => x.id == this.store.notice.status);
      this.selectedAccountStatus = this.store.accountStatuses.find((x) => x.id == this.store.notice.accStatus);
      this.selectedInvoiceGroup = this.store.invoiceGroups.find((x) => x.id == this.store.notice.accGroup);

      if (!this.selectedInvoiceGroup && this.store.invoiceGroups.length > 0) {
        this.selectedInvoiceGroup = this.store.invoiceGroups[0];
        this.store.notice.accGroup = this.selectedInvoiceGroup.id;
      }

      this.selectedLanguage = this.store.languages.find((x) => x.id == this.store.notice.language);
      if (!this.selectedLanguage && this.store.languages.length > 0) {
        this.selectedLanguage = this.store.languages[0];
        this.store.notice.language = this.selectedLanguage.id;
      }

      this.selectedNoticeGenoType = this.store.noticeGenoTypes.find((x) => x.id == this.store.notice.genoInvType);
      if (!this.selectedNoticeGenoType && this.store.noticeGenoTypes.length > 0) {
        this.selectedNoticeGenoType = this.store.noticeGenoTypes[0];
        this.store.notice.genoInvType = this.selectedNoticeGenoType.id;
      }

      if (new Date(this.store.notice.rateDate) > new Date("1950. 01. 01.")) {
        this.selectedExhangeRate = this.store.notice.rateDate.toString();
      }
    }

    const partBank = this.store.partBanks.find((x) => x.id == this.store.notice.bankAccount);
    if (partBank) {
      this.bankName = partBank.bank;
    }

    // csak új noto esetén értékek beállítása
    if (this.inCreateMode) {
      this.store.notice.userId = auth.userID;
      this.selectedUser = { name: auth.userName, id: auth.userID };

      this.store.notice.invType =
        this.store.noticeTypes.find((nt) => nt.code.toUpperCase() === "GENO" && nt.dpayment)?.id || 1;

      this.findPartnerBankAccount();
      this.findPartnerPMode();
      this.findPartnerCurrency();
      this.calculatePterm();
    }

    // csak meglévő noto esetén értékek beállítása
    if (this.inEditMode) {
      this.copyFromLastSave();

      this.selectedUser = this.store.users.find((x) => x.id == this.store.notice.userId) || { ...userDefaultProps };
      this.selectedPaymentMode = this.store.paymentModes.find((x) => x.id == this.store.notice.paymentMode);
      this.selectedCurrency = this.store.currencies.find(
        (x) => x.currency.toUpperCase().trim() == this.store.notice.invCurrency.toUpperCase().trim()
      );
      this.selectedNoticeType = this.store.noticeTypes.find((x) => x.id == this.store.notice.invType);
    }
  },

  beforeDestroy() {
    this.store.resetNOTO();
  },

  methods: {
    selectedExhangeRateChanged(e: any){
      console.log(e);
    },

    payCheck(){
      if (this.store.cashPoints.length <= 1){
          this.pay();
      } else {
          this.isCashPointChoose = true;
      }
    },

    pay(){
      if (!this.payInProgress){
        this.payInProgress = true;

        if (this.isCashPointChoose){
          this.store.payWithCashPoint(this.store.notice.id, this.selectedCashPoint.value).then(result => {
          this.$router.push(`/cash-statement/edit/${result.cashStatementID}/${result.cashDocumentID}`);
          }).finally(() => {
            this.payInProgress = false;
            this.isCashPointChoose = false;
          });

        } else {

          this.store.pay(this.store.notice.id).then(result => {
          this.$router.push(`/cash-statement/edit/${result.cashStatementID}/${result.cashDocumentID}`);
          }).finally(() => {
            this.payInProgress = false;
            this.isCashPointChoose = false;
          });
        }
      }
    },

    payCard(){
            this.$router.push('/general-outgoing-invoices/pay-open-invoices/noto/' + this.store.notice.id.toString());
        },

    selectedInvoiceGroupChanged() {
      this.store.notice.accGroup = this.selectedInvoiceGroup?.id ?? 1;
    },

    selectedUserChanged() {
      this.store.notice.userId = this.selectedUser?.id ?? 1;
    },

    selectedPaymentModeChanged() {
      this.store.notice.paymentMode = this.selectedPaymentMode?.id ?? 1;
    },

    selectedLanguageChanged() {
      this.store.notice.language = this.selectedLanguage?.id ?? 1;
    },

    selectedNoticeGenoTypeChanged() {
      this.store.notice.genoInvType = this.selectedNoticeGenoType?.id ?? 1;
    },

    selectedCurrencyChanged() {
      this.store.notice.invCurrency = this.selectedCurrency?.currency?.toUpperCase()?.trim() ?? "";
      this.currencyChanged();
    },

    partBanksIsAliveChanged() {
      this.store.loadPartBanks();
      this.accountsSkip = 0;
    },

    selectedBankChanged(event: any) {
      this.store.notice.bankAccountNo = event.dataItem.accountNo;
      this.store.notice.bankAccount = event.dataItem.id;
      this.bankName = event.dataItem.bank;
      this.banksDialog = false;
    },

    accountsPageChange(event: any) {
      this.accountsSkip = event.page.skip;
      this.accountsTake = event.page.take;
    },

    calculatePterm() {
      if (this.store.partCust.id > 0) {
        this.store.partCust.payTermFromShip
          ? (this.store.notice.paymentTerm = this.addDays(
              new Date(this.store.notice.shipDate),
              this.store.partCust.payTerm
            ))
          : (this.store.notice.paymentTerm = this.addDays(new Date(), this.store.partCust.payTerm));
      } else {
        this.store.notice.paymentTerm = this.addDays(new Date(), this.store.noticeDetails.payterm);
      }
    },

    selectedDivitemChanged() {
      this.store.notice.divItem = this.selectedDivitem.id;
    },

    formatDate(date: any) {
      if (date) {
        const mm = date.getMonth() + 1;
        const dd = date.getDate();

        return [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join(". ");
      }

      return "";
    },

    addDays(date: Date, days: number) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    async currencyChanged() {
      if (this.selectedCurrency?.currency != "HUF") {
        await this.store.loadRate();
      }
    },

    async partnerSelected(partner: Partner) {
      this.store.notice.partner = partner.id;
      await this.store.loadNoticeDetails(partner.id, this.store.notice.id);
      await this.store.loadPartCust(partner.id);
      if (this.inCreateMode) {
        this.findPartnerBankAccount();
        this.findPartnerPMode();
        this.findPartnerCurrency();
        this.calculatePterm();
      }
      this.partnerSearchDialog = false;
    },

    async deleteNoto() {
      const confirmed = await this.confirm("Biztosan törli?");
      if (!confirmed) {
        return;
      }

      await this.store.deleteNoto(this.store.notice.id);
      this.$router.push("/notice")
    },

    cancel() {
      this.$router.go(-1);
    },

    saveAction() {
      if (!this.validateSave()) return;
      if (this.confirmationNeeded("save")) this.showConfirmation();
      else this.performSaveAction();
    },

    printAction() {
      if (!this.validatePrint()) return;
      if (this.confirmationNeeded("print")) this.showConfirmation();
      else this.performPrintAction();
    },

    stornoAction() {
      if (!this.validateStorno()) return;
      if (this.confirmationNeeded("storno")) this.showConfirmation();
      else this.performStornoAction();
    },

    showConfirmation() {
      this.currentConfirmation = this.confirmations[0];
      this.confirmationVisible = true;
      this.adjustScrollbarForConfirmationBox();
    },

    acceptConfirmationBox() {
      // ha van a current-nek acceptOperationja itt futtassuk meg
      if (this.currentConfirmation.acceptOperation) {
        this.currentConfirmation.acceptOperation();
      }

      this.doCurrentAction();

      // current confirmation törlése
      this.confirmations.shift();
      this.confirmationVisible = false;
      // ha van még akkor hívjuk újra
      if (this.confirmations.length > 0) {
        this.showConfirmation();
      }
    },

    closeConfirmationBox() {
      // ha van a current-nek declineOperatorja itt futtassuk meg
      if (this.currentConfirmation.declineOperation) {
        this.currentConfirmation.declineOperation();
        //ilyenkor a close nem is inkább close, hanem B verzió, és utána nyomjuk tovább a valdiációkat, lehet át kéne nevezni a functiont
        this.doCurrentAction();
      }

      this.confirmationVisible = false;
      this.resetValidator();
    },

    doCurrentAction() {
      //rekurzív hívása a confirmation-öknek
      const currentActionType = this.currentConfirmation.actionType;
      if (currentActionType === "save" && this.confirmations.length === 1) {
        this.performSaveAction();
      } else if (currentActionType === "print" && this.confirmations.length === 1) {
        this.performPrintAction();
      } else if (currentActionType === "storno" && this.confirmations.length === 1) {
        this.performStornoAction();
      }
    },

    async performSaveAction() {
      try {
        eventBus.$busy(true);
        if (this.inCreateMode) {
          const resultID = await this.store.create();
          this.copyFromLastSave();
          this.$router.push(`/noto/${resultID}`);
          eventBus.$emit("success", "Sikeres mentés!");
        } else if (this.inEditMode) {
          await this.store.modify();
          this.copyFromLastSave();
          eventBus.$emit("success", "Sikeres mentés!");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.resetValidator();
        eventBus.$busy(false);
      }
    },

    async performPrintAction() {
      try {
        eventBus.$busy(true);
        await this.store.modify();
        await this.store.print(this.store.notice.id);
        location.reload(); // ha nem dob exceptiont egyik await sem, újratöltünk, hogy ne tudjon módosítani
      } catch (error) {
        console.log(error);
      } finally {
        this.resetValidator();
        eventBus.$busy(false);
      }
    },

    async performStornoAction() {
      try {
        eventBus.$busy(true);

        const resultID = await this.store.storno(+this.store.notice.id, this.store.stornoOptions);

        if (!resultID || resultID <= 1) throw new Error("Hiba történt, az értesítő sztornózása sikertelen!");

        this.$router.push(`/noto/${resultID}`);
        eventBus.$emit("success", "Sikeres sztornózás!");
      } catch (error) {
        console.log(error);
      } finally {
        this.resetValidator();
        eventBus.$busy(false);
      }
    },

    validateSave() {
      if (this.inEditMode) {
        if (this.store.noticeItemsCopy.length > 0) {
          if (
            this.store
              .checkForItemExistence(this.store.noticeItemsCopy, store.noticeItems)
              .filter((item) => item === true).length !== this.store.noticeItemsCopy.length
          ) {
            const modifiedc = new Confirmation("save", "A meglévő tételekben módosítás történt. Folytatja?");
            this.addConfirmation(modifiedc);
          }
        }
      }

      return true;
    },

    validatePrint() {
      if (!this.printed) {
        if (this.store.noticeItems.length < 1) {
          eventBus.$emit("error", "Tétel nélkül nem nyomtathat!");
          return false;
        }

        if (this.store.notice.divItem === 1) {
          eventBus.$emit("error", "Meg kell adnia aldivíziót mielőtt nyomtatja az értesítőt!");
          return false;
        }

        if (this.store.notice.genoInvType === 1) {
          eventBus.$emit("error", "Meg kell adnia a GENO típust!");
          return false;
        }

        if (
          this.store.noticeTypes.find((nt) => nt.code.toUpperCase() === "GENO" && nt.dpayment)?.id ===
            this.store.notice.invType &&
          this.store.notice.netto <= 0
        ) {
          eventBus.$emit("error", "Nem lehet negatív összegű ez a típus!");
          return false;
        }

        if (!this.store.noticeItems.every((item) => item.tax === this.store.noticeItems[0].tax)) {
          eventBus.$emit("error", "A tételek ÁFA kulcsának megegyezőnek kell lennie!");
          return false;
        }

        const printc = new Confirmation("print", "Biztosan nyomtatja az értesítőt?");
        this.addConfirmation(printc);
      }
      return true;
    },

    validateStorno() {
      if (this.store.notice.storno) {
        eventBus.$emit("error", "Az értesítő már jóvá lett írvá vagy jóváíró!");
        return false;
      }

      if (this.store.notice.rectify) {
        eventBus.$emit("error", "Helyesbítő számlát nem lehet jóváírni!");
        return false;
      }

      if (this.store.notice.invoiceRect) {
        eventBus.$emit("error", "Helyesbített számlát nem lehet jóváírni!");
        return false;
      }

      const stornoc = new Confirmation("storno", "Az értesítő jóváírásra kerül. Folytatja?");
      this.addConfirmation(stornoc);

      const shipDatevsTodayc = new Confirmation(
        "storno",
        "A most készítendő jóváíró értesítő teljesítés dátuma az eredeti értesítő teljesítés dátuma legyen vagy a mai nap?",
        "Teljesítés dátuma",
        "Mai",
        () => {
          //ha az acceptre nyom - nincs kedvem kiszervezni
          this.store.stornoOptions.shipDate = this.store.notice.shipDate;
        },
        () => {
          //ha a cancelre nyom
          this.store.stornoOptions.shipDate = new Date();
        }
      );
      this.addConfirmation(shipDatevsTodayc);

      return true;
    },

    validator(noto, prevNoto) {
      this.resetValidator();

      // Bankszámla validációk
      if (!this.passedBankAccountValidation(noto, prevNoto)) return;

      // Partner validációk
      if (!this.passedPartnerValidation(noto, prevNoto)) return;

      // Értesítő típus validációk
      if (!this.passedNoticeTypeValidation(noto, prevNoto)) return;

      // Projekt validációk
      if (!this.passedProjectValidation(noto, prevNoto)) return;

      //Teljesítés dátuma validációk
      if (!this.passedShipDateValidation(noto, prevNoto)) return;

      // Tétel validációk
      if (!this.passedItemValidation()) return;

      return "";
    },

    resetValidator() {
      this.validationError = "";
      this.confirmationVisible = false;
      this.confirmations = [];
      this.confirmationMessage = "";
    },

    passedBankAccountValidation(noto, prevNoto) {
      if (!noto.bankAccountNo) {
        this.validationError = "Válasszon egy bankszámlát!";
        return false;
      }
      return true;
    },

    passedPartnerValidation(noto, prevNoto) {
      if (noto.partner < 2) {
        this.validationError = "Válasszon ki egy partnert!";
        return false;
      }

      if (!this.store.noticeDetails.outsider) {
        this.validationError = "Csak külső partnert lehet választani!";
        return false;
      }

      //az ÁFÁS validációk mind igazából a választott partnertől függnek, ezért ide kerültek beépítésre
      if (!noto.withVat) {
        // ha belföldi vagy spectax!=3v4
        if (
          this.store.noticeDetails.native ||
          (this.store.noticeDetails.specTaxId !== 3 && this.store.noticeDetails.specTaxId !== 4)
        ) {
          // ha van tétel
          if (this.store.noticeItems.length > 0)
            eventBus.$emit(
              "error",
              "Nem lehet ezt a partnert választani, mert az értesítőnek már van tétele és ÁFA mentesen felé nem lehet számlázni, törölje a tételeket és próbálja újra!"
            );

          setTimeout(() => {
            this.store.notice.withVat = true;
            eventBus.$emit("warn", "A partnerhez kapcsolódó értesítő csak ÁFÁ-s lehet!");
          }, 100);
        }

        // ha NEM belföldi vagy spectax==3v4
        else {
          //ha eutax nincs és spectax 3
          if (this.store.noticeDetails.euTaxAccount.length === 0 && this.store.noticeDetails.specTaxId === 3) {
            eventBus.$emit(
              "error",
              "Ennek a partnernek nincs kitöltve a Közösségi adószáma, ahhoz, hogy adómentesen számlázhasson meg kell adnia!"
            );

            setTimeout(() => {
              this.store.notice.withVat = true;
              eventBus.$emit("warn", "Az értesítő csak ÁFÁ-s lehet, nem engedélyezett művelet!");
            }, 100);
          }
        }
      }

      return true;
    },

    passedNoticeTypeValidation(noto, prevNoto) {
      if (this.inCreateMode && noto.invType <= 1) {
        this.validationError = "Válasszon értesítő típust!";
        return false;
      }
      return true;
    },

    passedProjectValidation(noto, prevNoto) {
      return true; // TODO
    },

    passedShipDateValidation(noto, prevNoto) {
      if (this.inCreateMode) {
        if (new Date(noto.shipDate) > new Date(noto.invDate)) {
          this.validationError = "A Teljesítés dátuma nem lehet nagyobb mint az Értesítő dátuma!";
          return false;
        }

        if (+new Date() - +new Date(noto.shipDate) > 691200000) {
          const shipDatec = new Confirmation("save", "A Teljesítés dátuma régebbi mint 8 nap. ");
          this.addConfirmation(shipDatec);
        }
      }
      return true;
    },

    passedItemValidation() {
      if (!this.printed) {
        if (this.store.noticeItems.map((ni) => ni.quantity === 0).includes(true)) {
          this.validationError = "Tétel mennyisége nem lehet 0 vagy negatív!";
          return false;
        }
      }

      return true;
    },

    modifiedSinceOpened() {
      if (!this.store.twoNoticeEquals(this.store.notice, this.store.noticeCopy)) return true;

      if (this.store.noticeItems.length !== this.store.noticeItemsCopy.length) return true;

      if (!this.store.twoNoticeItemListEquals(this.store.noticeItems, this.store.noticeItemsCopy)) return true;

      return false;
    },

    copyFromLastSave() {
      this.store.noticeCopy = JSON.parse(JSON.stringify(this.store.notice)); // másolat a mentett fejről
      this.store.noticeItemsCopy = JSON.parse(JSON.stringify(this.store.noticeItems)); // másolat a mentett tételekről
    },

    confirmationNeeded(actionType) {
      return (
        this.confirmations.length > 0 && this.confirmations.map((c) => c.actionType.includes(actionType)).includes(true)
      );
    },

    addConfirmation(confirmation) {
      // ha már egyszer benne van a confirmation akkor ne rakjuk bele újra (előfordulhat ha "Nem"-mel válaszol és újra azt a hibát követi el)
      if (!this.confirmations.map((c) => c.message === confirmation.message).includes(true)) {
        {
          this.confirmations.push(confirmation);
        }
      }
    },

    adjustScrollbarForConfirmationBox() {
      this.$nextTick(() => {
        const confirmationBoxElement = document.getElementById("action-confirmation-box");
        if (confirmationBoxElement) {
          confirmationBoxElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
      });
    },

    findPartnerBankAccount() {
      if (this.store.partCust.id > 0 && this.store.partCust.bankId > 1) {
        const partnerBankOnInit: PartBank = this.store.partBanks
          .sort((a, b) => b.id - a.id)
          .find((b) => b.accountNo === this.store.partCust.bank) ?? { ...partBankDefaultProps }; // partnerhez kiválasztott szállítói bankszámla
        this.store.notice.bankAccountNo = partnerBankOnInit.accountNo;
        this.store.notice.bankAccount = partnerBankOnInit.id;
        this.bankName = partnerBankOnInit.bank;
        this.banksDialog = false;
      } else {
        const partnerBankOnInit: PartBank = this.store.partBanks
          .sort((a, b) => b.id - a.id)
          .find((b) => b.primaryAccNo) ?? { ...partBankDefaultProps }; // legutóbb felvett primary bank, vagy ha nincs iylen akkor a def
        this.store.notice.bankAccountNo = partnerBankOnInit.accountNo;
        this.store.notice.bankAccount = partnerBankOnInit.id;
        this.bankName = partnerBankOnInit.bank;
        this.banksDialog = false;
      }

      const partBank = this.store.partBanks.find((x) => x.id == this.store.notice.bankAccount);
      if (partBank) {
        this.bankName = partBank.bank;
      }
    },

    findPartnerPMode() {
      if (this.store.partCust.id > 0) {
        this.selectedPaymentMode = this.store.paymentModes.find((x) => x.id == this.store.partCust.paymodeId);
        if (!this.selectedPaymentMode && this.store.paymentModes.length > 0) {
          this.selectedPaymentMode = this.store.paymentModes[0];
          this.store.notice.paymentMode = this.selectedPaymentMode.id;
        }
      } else {
        this.selectedPaymentMode = this.store.paymentModes[0];
      }
      this.selectedPaymentModeChanged();
    },

    findPartnerCurrency() {
      if (this.store.partCust.id > 0) {
        this.selectedCurrency = this.store.currencies.find(
          (x) => x.currency.toUpperCase().trim() == this.store.partCust.defCurrency.toUpperCase().trim()
        );
        if (!this.selectedCurrency && this.store.currencies.length > 0) {
          this.selectedCurrency = this.store.currencies[0];
          this.store.notice.invCurrency = this.selectedCurrency.currency.toUpperCase().trim();
        }
      } else {
        this.selectedCurrency = this.store.currencies[0];
      }
      this.selectedCurrencyChanged();
    },
  },

  computed: {
    payable(){
      return (this.store.notice.status == 2 || this.store.notice.status == 3 || this.store.notice.status == 4) && this.store.notice.currTotal > 0;
    },

    inCreateMode() {
      if (this.$route.name === "NoticeCreate") return true;
      return false;
    },

    inEditMode() {
      if (this.$route.name === "NoticeEdit") return true;
      return false;
    },

    bankItems() {
      return this.store.partBanks.slice(this.accountsSkip, this.accountsTake + this.accountsSkip).map((item) => ({
        ...item,
        selected: item.id === this.store.notice.bankAccount,
      }));
    },

    accountsTotal() {
      return this.store.partBanks ? this.store.partBanks.length : 0;
    },

    partsTotal() {
      return this.store.gParts ? this.store.gParts.length : 0;
    },

    passedValidation() {
      if (this.validationError === "") return true;
      return false;
    },

    notoToWatch() {
      return JSON.parse(JSON.stringify(this.store.notice)); // ezzel fogjuk watcholni, ha változik valamelyik értéke a notonak (objektum referencia miatt van szükség erre)
    },

    calculatedNetto() {
      return this.store.notice.currNetto * this.store.notice.exchangeRate;
    },

    calculatedVat() {
      return this.store.notice.currVat * this.store.notice.exchangeRate;
    },

    calculatedTotal() {
      return this.store.notice.currTotal * this.store.notice.exchangeRate;
    },

    stornoButtonVisible() {
      return this.inEditMode && this.store.notice.status > 2;
    },

    printed() {
      return this.store.notice.status >= 3;
    },
  },

  watch: {
    notoToWatch: {
      handler(newVal, prevVal) {
        this.validator(newVal, prevVal);
      },
      deep: true,
      immediate: true,
    },

    calculatedNetto(newVal) {
      this.store.notice.netto = newVal;
    },

    calculatedVat(newVal) {
      this.store.notice.vat = newVal;
    },

    calculatedTotal(newVal) {
      this.store.notice.total = newVal;
    },
  },
});
