




















/* HASZNÁLAT ->
...
...
</v-card-actions> // ez után érdemes tenni
<confirmation-box
  :visible="aTeBooleanod"
  :question="aTeKerdesed"
  @accept="aTeEventedAcceptEseten"
  @close="aTeBooleanod = false"
/>
...
...
*/
import Vue from "vue";

export class Confirmation {
  message: string;
  primaryText?: string = "Igen";
  secondaryText?: string = "Nem";
  actionType?: string;
  acceptOperation?: Function;
  declineOperation?: Function;

  constructor(
    message: string,
    primaryText?: string,
    secondaryText?: string,
    actionType?: string,
    acceptOperation?: Function,
    declineOperation?: Function
  ) {
    this.message = message;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.actionType = actionType;
    this.acceptOperation = acceptOperation;
    this.declineOperation = declineOperation;
  }
}

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },

    question: {
      type: String,
      default: "",
      required: true,
    },

    primaryButtonText: {
      type: String,
      default: "Igen",
    },

    secondaryButtonText: {
      type: String,
      default: "Nem",
    },
  },

  methods: {
    emitAcceptEvent(): void {
      this.$emit("accept");
    },

    emitCloseEvent(): void {
      this.$emit("close");
    },
  },
});
