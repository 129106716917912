































































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import InvoiceFlowTemplate from "../models/InvoiceFlowTemplate";
import { GridColumnProps } from "@progress/kendo-vue-grid";
import setAttribute from "../../../utils/dom";

interface Data {
  store: InvoiceFlowStore;
}

interface Methods {
  confirmLoad(item: InvoiceFlowTemplate): void;
  cancelLoad(item: InvoiceFlowTemplate): void;
  showWarning(item: InvoiceFlowTemplate): void;
}

interface Computed {
  templateItems: InvoiceFlowTemplate[];
  templateColumns: GridColumnProps[];
}

const confirmationTemplate = {
  render(h: any) {
    return h();
  },
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "5");
  },
};

export default Vue.extend<Data, Methods, Computed, {}>({
  props: {
    isPageDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    confirmation: confirmationTemplate,
  },

  data() {
    return {
      store,
    };
  },

  computed: {
    templateColumns: () => [
      {
        title: "Név",
        field: "name",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        title: "Létrehozó",
        field: "insUserName",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        title: "Létrehozás dátuma",
        field: "insDate",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        title: "Utolsó módosítás dátuma",
        field: "updDate",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        width: "70px",
        cell: "commandTemplate",
      },
    ],

    templateItems() {
      return this.store.invoiceFlowTemplates;
    },
  },

  methods: {
    showWarning(item) {
      item.expanded = true;
    },

    cancelLoad(item) {
      item.expanded = false;
    },

    async confirmLoad(item) {
      await this.store.loadInvoiceFlowTemplate(item.id, Number(this.$route.params.invoiceId));
      await this.store.loadFlowActionLogs(Number(this.$route.params.invoiceId));
      item.expanded = false;
      this.$emit("toggleInvoiceTemplates");

      window.location.reload();
    },
  },
});
