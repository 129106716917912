

























































































import IconButton from "@/components/IconButton.vue";

import Vue from "vue";
import dayjs from "dayjs";
import store, { CurrencyRateStore } from "../services/CurrencyRateStore";
import CurrencyRateApi from "../services/CurrencyRateApi";
import { ValidationRules } from "../../../models/Validation";
import { CurrencyRate, rules } from "../models/CurrencyRate";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";

interface Data {
  store: CurrencyRateStore;
  valid: boolean;
  rules: ValidationRules;
  existingRates: CurrencyRate[];
}

export default Vue.extend({
  components: {
    IconButton,
    ValidationError,
  },

  data: (): Data => ({
    store,
    rules,
    valid: true,
    existingRates: [],
  }),

  async beforeMount() {
    this.store.currencyRate = new CurrencyRate({ localCurrency: this.store.currencyRate.currency });
    await this.store.loadCurrencies();
  },

  watch: {
    "store.currencyRate.rateDate": {
      deep: true,
      async handler() {
        if (dayjs(this.store.currencyRate.rateDate) < dayjs()) {
          await this.loadExistingRates();
        }
      },
    },

    "store.currencyRate.localCurrency": {
      deep: true,
      async handler() {
        if (dayjs(this.store.currencyRate.rateDate) < dayjs()) {
          await this.loadExistingRates();
        }
      },
    },
  },

  methods: {
    async getCurrencyRate() {
      if (this.errorMessage != "") return;

      await this.store
        .loadCurrencyRateMnb(this.store.currencyRate.localCurrency, this.store.currencyRate.rateDate)
        .catch((error) => {
          this.store.currencyRate.mnbCentralRate = -1;
          console.error(error);
        })
        .then(() => (this.store.currencyRate.centralRate = this.store.currencyRate.mnbCentralRate));
    },

    currencyChanged() {
      this.store.currencyRate.unit = ["JPY", "IDR", "KRW"].includes(this.store.currencyRate.localCurrency) ? 100 : 1;
    },

    cancel() {
      this.$router.push(`/currency-rates`);
    },

    async save() {
      if (this.errorMessage != "") return;

      await this.store.save();
      this.$router.push(`/currency-rates/`);
    },

    async loadExistingRates() {
      const date = dayjs(this.store.currencyRate.rateDate).format("YYYY-MM-DD");
      this.existingRates = (await CurrencyRateApi.getAll(date, date)).filter(
        (c) => c.currency === this.store.currencyRate.localCurrency
      );
    },
  },

  computed: {
    errorMessage(): string {
      if (this.existingRates.length > 0)
        return "Erre a dátumra és pénznemre már van rögzítve árfolyaminformáció az adatbázisban.";

      if (this.store.currencyRate.rateDate.toString().length < 1)
        return "Nem létező dátumra nem lehet lekérdezni az árfolyaminformációkat.";

      if (this.store.currencyRate.localCurrency.length < 1)
        return "A középárfolyam lekérdezéséhez kötelező a listában szereplő elemek egyikének kiválasztása.";

      return "";
    },
  },
});
