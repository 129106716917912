import http, { handleApiResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import Office from '../models/Office'

export default class OfficeApi {
    static getOffices(): Promise<Office[]> {
        return http
            .get<ApiResponse<Office[]>>(`partners`)
            .then(handleApiResponse)
    }
}