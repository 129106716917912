


































































import Vue from "vue";
import { DimCodeType } from "../../generalLedger/models/TypesForEdit";
import store, { DimCodeTypeStore } from "../services/DimCodeTypeStore";

interface Data {
  store: DimCodeTypeStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadDimCodeTypes();
  },

  computed: {
    columns: () => [
      { field: "national", title: "Dimenziókód típus", editable: false },
      { cell: "deletedTemplate", title: "Kötelező dimenzió?" },
      { cell: "commandTemplate", width: "60px" },
      { width: 0, editable: false },
    ],
  },

  methods: {
    updateDimCodeType(item: DimCodeType) {
      item.editing = true;
    },

    saveEdit(item: DimCodeType) {
      item.editing = false;
      this.store.save();
    },

    itemChange({ dataItem, value, field }: { dataItem: DimCodeType; value: any; field: string }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      this.store.itemChange(item, update);
    },
  },
});
