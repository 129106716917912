import configuration from "@/models/Configuration";
import { SpecialTaxes } from "@/modules/partner/models/SpecialTax";
import dayjs from "dayjs";

export default interface InvoiceDetails {
  name: string;
  address: string;
  taxNumber: string;
  euTaxNumber: string;
  partnerName: string;
  partnerAddress: string;
  extendedPartnerAddress: string;
  partnerIsNative: boolean;
  partnerTaxNumber: string;
  partnerEuTaxNumber: string;
  partnerIsSpecTax: boolean;
  partnerSpecTaxType: number;
  partnerPaymentDeadlineDate: string;
  partnerPaymentMethodId: number;
  partnerPaymentDate: string;
  partnerCurrency: string;
  partnerDocumentTemplate: string;
  defaultTax: number;
  tax: number;
  partnerIsIntrastat: boolean;
}

export const invoiceDetailsDefaultProps: InvoiceDetails = {
  name: "",
  address: "",
  taxNumber: "",
  euTaxNumber: "",
  partnerName: "",
  partnerAddress: "",
  extendedPartnerAddress: "",
  partnerIsNative: false,
  partnerTaxNumber: "",
  partnerEuTaxNumber: "",
  partnerIsSpecTax: false,
  partnerSpecTaxType: 1,
  partnerPaymentDeadlineDate: dayjs().format("YYYY-MM-DD"),
  partnerPaymentMethodId: 1,
  partnerPaymentDate: "",
  partnerCurrency: "",
  partnerDocumentTemplate: "",
  defaultTax: 1,
  tax: 1,
  partnerIsIntrastat: false,
};

export const isIntrastatPartner = (details: InvoiceDetails) => {
  return (
    details.partnerIsSpecTax &&
    (details.partnerSpecTaxType === SpecialTaxes.InCommunity ||
      (details.partnerSpecTaxType === SpecialTaxes.OutOfComunity &&
        details.partnerIsIntrastat)) &&
    configuration.sysParams.geniIntrastat
  );
};
