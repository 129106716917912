
































import Vue from "vue"

interface Methods {
  setStep(step: number): any;
}

export default Vue.extend<{},Methods,{},{}>({
  beforeMount() {
    console.log(this.$route.path);
    if(this.$route.path.endsWith("invoices"))
      this.$data.step = 1
    else
      this.$data.step = 2
  },
  data() {
      return {
        step: 1,
      }
    },
    methods: {
        setStep(step: number) {
            if(step == 1)
            {
                this.$data.step = step;
                this.$router.push({name: "WriteOffDebtInvoices", query: this.$route.query});
            }
            if(step == 2)
            {
                this.$data.step = step;
                this.$router.push({name: "WriteOff", query: this.$route.query});
            }
        }
    },
});
