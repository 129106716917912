var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":_vm.closeOnClick,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:'filter-button ' + _vm.cssClass,staticStyle:{"padding":"8px 10px !important"},attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v(" fa-caret-down ")])],1)]}}])},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[(!_vm.customItems)?_c('v-list-item-group',{attrs:{"multiple":_vm.multiple}},[(_vm.multiple)?_c('fragment',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.selected(item)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"inherit"},style:({
                  visibility: item[_vm.selectProp] ? 'visible' : 'hidden',
                }),attrs:{"size":"12"}},[_vm._v(" fa-check ")]),_c('span',[_vm._v(_vm._s(item[_vm.nameProp]))])],1)]},proxy:true}],null,true)})}),1):_c('fragment',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.selected(item)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"inherit"},style:({
                  visibility: item[_vm.selectProp] ? 'visible' : 'hidden',
                }),attrs:{"size":"12"}},[_vm._v(" fa-check ")]),_c('span',[_vm._v(_vm._s(item[_vm.nameProp]))])],1)]},proxy:true}],null,true)})}),1)],1):_c('v-list-item-group',[_vm._t("customItems")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }