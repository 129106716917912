var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-6"},[(!_vm.loading)?_c('ApiSearchPage',{attrs:{"title":"Banki csomagok","url":"bank-remit/search","columns":_vm.columns,"detailsField":"expanded","search":false,"filterable":false,"refresh":false,"sortable":false,"delay":1000},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var item = ref.item;
return [(!_vm.isRiportSelect)?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])])],1):_vm._e()]}},{key:"importTemplate",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{attrs:{"icon":!item.expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-up',"tooltip":"Tételek"},on:{"click":function($event){item.expanded = !item.expanded}}})]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('BankRemitItemListView',{attrs:{"remitID":item.id,"checkInvoicePaid":true}})],1)]}},{key:"amountTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('money-display',{attrs:{"value":item.amount,"currency":item.invCurrency}})],1)]}},{key:"dueAmountTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('money-display',{attrs:{"value":item.dueAmount,"currency":item.invCurrency}})],1)]}}],null,false,1807462322)}):_vm._e(),_c('v-row',{staticClass:"pr-3 pb-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.emitPay()}}},[_vm._v("Fizetés")]),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.emitCancel()}}},[_vm._v("Mégse")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }