






import Vue from 'vue'
import BankRemitItemView from '@/modules/bankRemit/views/BankRemitItemView.vue';

export default Vue.extend({
    components: {
        BankRemitItemView
    },

    props: {
        remitID: {type: Number, default: 1},
        checkInvoicePaid: {type: Boolean, default: false}
    }
})
