import http, { checkResponse, fetchData } from "@/services/http"

export interface InvoiceDimensionType {
    id: number;
    name: string;
}

export interface InvoiceDimension {
    id: number;
    invoiceID: number;
    type: InvoiceDimensionType;
    dimensionName: string;
    dimensionAmount: number;
    invoiceItemID: number;
}

class InvoiceDimensionsApi {
    static getInvoiceDimensionTypes() {
        return fetchData<InvoiceDimensionType[]>(`operel/invoice-dimension-types`)
    }

    static fetchByInvoice(invoiceID: number) {
        return fetchData<InvoiceDimension[]>(`operel/invoice-dimensions/${invoiceID}`)
    }

    static fetchByInvoiceItem(itemId: number) {
        return fetchData<InvoiceDimension[]>(`operel/item/invoice-dimensions/${itemId}`)
    }

    static createDimension(dimension: InvoiceDimension) {
        return http.post(`operel/invoice-dimensions`, dimension).then(checkResponse)
    }

    static deleteDimension(dimensionID: number) {
        return http.delete(`operel/invoice-dimensions/${dimensionID}`).then(checkResponse)
    }

    static getInvoiceDimensionNames() {
        return fetchData<string[]>(`operel/invoice-dimension-names`)
    }
}

export default InvoiceDimensionsApi