var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticStyle:{"height":"auto"}},[_c('v-row',[_c('v-col',[_c('v-row',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-col',_vm._l((_vm.store.requiredDocuments),function(item){return _c('v-chip',{key:item.id,staticStyle:{"font-size":"12px","margin":"0px 2px 0px 0px"},attrs:{"color":item.isOk == true ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"infoButton",staticStyle:{"height":"fit-content !important"},attrs:{"outlined":"","color":"primary"},on:{"click":_vm.newDocument}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]}}])},[_c('span',[_vm._v("Új dokumentum")])])],1)],1),_c('v-card-text',[_c('v-expand-transition',[(_vm.newDoc)?_c('div',[_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding":"0px","margin":"0px"},attrs:{"cols":"auto"}},[_c('v-select',{staticStyle:{"padding":"0px","margin":"0px"},attrs:{"items":_vm.store.documentTypes,"item-value":"id","item-text":"name","dense":"","outlined":""},model:{value:(_vm.newFile.documentTypeId),callback:function ($$v) {_vm.$set(_vm.newFile, "documentTypeId", $$v)},expression:"newFile.documentTypeId"}})],1),_c('v-col',[_c('v-file-input',{ref:"newFile",attrs:{"dense":"","outlined":"","hide-details":"auto","clear-icon":"fa-times","prepend-icon":"","accept":"image/jpeg,image/png,application/pdf,text/plain","id":("new-file-" + (_vm.newFile.id) + "-" + (_vm.newFile.documentTypeId))},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('label',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-plus ")])]},proxy:true}],null,false,203104056)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.uploadNewFile(_vm.newFile)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-upload ")])],1)]}}],null,false,4040844676)},[_c('span',[_vm._v("Fájl feltöltése")])])],1)],1)],1):_vm._e()]),_vm._l((_vm.store.documentsStatements),function(relation){return _c('div',{key:relation.id,staticClass:"mx-3 mb-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"vertical-align":"baseline"},attrs:{"color":relation.hasFile ? 'var(--primaryColor)' : 'error',"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(relation.hasFile ? "fa-check" : "fa-circle-o")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(relation.hasFile ? "Feltöltve" : "Nincs feltöltve")+" ")])]),_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"title":'Dokumentum feltöltése'},on:{"click":function($event){return _vm.showFile(relation)}}},[_vm._v(" "+_vm._s(relation.documentType)+": "+_vm._s(relation.fileName)+" ")]),_c('v-expand-transition',[(relation.uploading)?_c('div',{staticClass:"px-2",staticStyle:{"background-color":"#f5f5f5"}},[_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(relation.fileName != ''),expression:"relation.fileName != ''"}]},[_c('v-expand-transition',[_c('label',[_vm._v(" "+_vm._s(relation.fileName)+" ")])])],1)]),_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-right":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!relation.hasFile},on:{"click":function($event){return _vm.downloadFile(relation.link)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-download ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Fájl letöltése ")])])],1),_c('v-col',[_c('v-file-input',{ref:"file",refInFor:true,attrs:{"dense":"","outlined":"","hide-details":"auto","clear-icon":"fa-times","prepend-icon":"","accept":"image/jpeg,image/png,application/pdf,text/plain","id":("file-" + (relation.id) + "-" + (relation.documentTypeId))},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('label',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-plus ")])]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.uploadFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl feltöltése")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!relation.hasFile},on:{"click":function($event){return _vm.deleteFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-trash-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl törlése")])])],1)],1)],1):_vm._e()])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }