















































import Vue from "vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import VIROGen from "./VIROGen.vue";

export default Vue.extend({
  components: {
    ApiSearchPage,
    VIROGen,
  },

  props: {
    currency: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    isCashDoc: {
      type: Boolean,
      default: false
    },
    noPadding: { 
      type: Boolean, 
      default: false
    },
    noActionsColor: {
      type: Boolean, 
      default: false
    },
  },

  data() {
    return {
      inStatement: false
    };
  },

  beforeMount() {
    this.inStatement = this.$route.fullPath.includes("cash-statement")
  },

  methods: {
    refresh(){
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },

    createVIRIItems() {
      this.$router.push("/viri-item/create");
    },
    showDetails(item: any) {
      item.selected = !item.selected;
    },
    edit(item: any) {
      this.$router.push(`/viri-item/edit/${item.invoice}`);
    }
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
    { cell: "commandTemplate", filterable: false },
      {
        field: "invoice",
        title: "Számlaszám",
      },
      {
        field: "invDate",
        title: "Számla dátuma",
        type: ColumnType.Date,
      },
      {
        field: "total",
        title: "Totál",
        type: ColumnType.Money
      },
      {
        field: "remark1",
        title: "Megjegyzés",
      },
    ],
  },
});
