export class InverseVatSummaryItem {
  id = 0;
  customCode = "";
  weight = 0;
  currNetto = 0;
  netto = 0;

  constructor(params?: Partial<InverseVatSummaryItem>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

export class TariffNumber {
  tariffNo = "";
}
