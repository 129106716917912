

























import Vue from "vue";
import Page from "../../../components/Page.vue";
import configuration, {AppConfiguration} from "@/models/Configuration";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";

interface Data {
  configuration: AppConfiguration;
  importItemsInTable: any;
}

interface Methods {
  importItems(items: any): void;
  refreshApiSearch(): void;
  updateInvoice(item: any): void;
}

interface Computed {
  columns: ApiSearchColumn[];
}

export default Vue.extend<Data, Methods, Computed, {}>({
  data() {
    return {
      configuration,
      importItemsInTable: [],
    };
  },

  components: {
    Page,
    ApiSearchPage,
  },

  methods: {
    importItems(items: any){
      this.importItemsInTable = items;
    },

    refreshApiSearch() {
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },

    updateInvoice(item: any) {
      const path = configuration.invoiceFlow.enabled
        ? `/general-incoming-invoice/flow/edit/${item.invoiceID}/2`
        : `/general-incoming-invoice/edit/${item.invoicePartner}/${item.invoiceID}`;

      this.$router.push(path);
    },
  },

  watch: {

  },

  computed: {

    columns: (): ApiSearchColumn[] => [
      {
        title: "Számlaszám",
        field: "invoiceID",
        cell: "geniID",
      },
      {
        title: "Külső számlaszám",
        field: "invoiceNo",
      },
      {
        title: "Hivatkozott külső számla száma",
        field: "originalInvoiceNo",
      },
      {
        title: "Számla dátuma",
        field: "invDate",
        type: ColumnType.Date
      },
      {
        title: "Teljesítés dátuma",
        field: "deliveryDate",
        type: ColumnType.Date
      },
      {
        title: "Fizetési mód",
        field: "paymentMode",
      },
      {
        title: "Fizetési határidő",
        field: "payDate",
        type: ColumnType.Date
      },
      {
        title: "Pénznem",
        field: "currency",
      },
      {
        title: "Szállító neve",
        field: "supplierName",
      },
      {
        title: "Szállító adószáma",
        field: "supplierTaxNo",
      },
      {
        title: "Nettó",
        field: "netto",
        type: ColumnType.Money,
        filterable: false,
        sortable: false,
      },
      {
        title: "ÁFA",
        field: "vat",
        type: ColumnType.Money,
        filterable: false,
        sortable: false,
      },
      {
        title: "Bruttó",
        field: "brutto",
        type: ColumnType.Money,
        filterable: false,
        sortable: false,
      },
    ],
  },
});
