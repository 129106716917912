import GeneralIngoingInvoiceItem, { generalIngoingInvoiceItemDefaultProps } from "./GeneralIngoingInvoiceItem";
import StockForCustomsSelection from "@/modules/customsInvoice/models/StockForCustomsSelection";
import ItemIt from "./ItemIt";
import Tax from "../models/Tax";

export default interface GeneralIngoingInvoiceItemDisplay {
  incomingInvoiceItem: GeneralIngoingInvoiceItem;
  invoiceItemIt: ItemIt[];
  gPartsName: string;
  unitTypeName: string;
  taxName: string;
  taxDegree: number;
  taxID: number;
  code: string;
  isTax: boolean;
  isDuty: boolean;
  selectedTax: Tax | undefined;
  selectedStocks: StockForCustomsSelection[];
  vatItemName: string;
  vatItemId: number;
  visibleDutyPercentage: number;

  expanded: boolean;
  dutyDetails: boolean;
  selectedDutyDetails: boolean;
  accountDetails: boolean;
  totalWeight: number;
  weightPerUnit: number;
  weightUnit: string;
  isSelected: boolean;
}

export const generalIngoingInvoiceItemDisplayDefaultProps: GeneralIngoingInvoiceItemDisplay = {
  incomingInvoiceItem: { ...generalIngoingInvoiceItemDefaultProps },
  invoiceItemIt: [],
  gPartsName: "",
  unitTypeName: "",
  taxName: "",
  code: "",
  isTax: false,
  isDuty: false,
  taxDegree: 1.0,
  taxID: 1.0,
  selectedTax: undefined,
  selectedStocks: [],
  vatItemId: 0,
  vatItemName: "-",
  visibleDutyPercentage: 0,

  expanded: false,
  dutyDetails: false,
  selectedDutyDetails: false,
  accountDetails: false,
  totalWeight: 0.0,
  weightPerUnit: 0.0,
  weightUnit: '',
  isSelected: false,
};
