































import Vue from "vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import { fetchData } from "@/services/http";
import configuration from "@/models/Configuration";

interface StockDivision {
  id: number;
  stockID: number;
  parts: string;
  unit: string;
  currency: string;
  quantity: number;
  exchangeRate: number;
  currentUnitPrice: number;
  originalUnitPrice: number;
  currentPrice: number;
  originalPrice: number;
  currNetto: number;
  otherCosts: OtherCost[];
}

interface OtherCost {
  invoiceID: number;
  invoiceItemID: number;
  currency: string;
  partner: string;
  itemName: string;
  unitPrice: number;
  price: number;
  exchangeRate: number;
  otherCostExchangeRate: number | null;
}

interface Data {
  stockDivision: StockDivision[];
}

export default Vue.extend<Data, {}, {}, {}>({
    components: {
        MoneyDisplay,
    },

    props: {
        invoiceItemID: { type: Number },
    },

    data: () => ({
        stockDivision: [],
        configuration,
    }),

    async beforeMount() {
        const stockDivision = await fetchData<StockDivision[]>(`incoming-invoice-items/${this.$props.invoiceItemID}/stock-costs`)
            .then((x)=>{
                console.log(x);
                return x;
            });
        this.stockDivision = stockDivision;
    },
})
