import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import GeneralIngoinInvoiceDisplay from '@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay';
import GeneralIngoingInvoice from '@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice';
import DebitPayment from '../models/DebitPayment';
import { DownPaymentExtOrder } from '@/modules/downPayment/models';
import DebitPaymentEditModelComplaint from '../models/DebitPaymentEditModelComplaint';

export default class DebitPaymentApi {
    static getAll(): Promise<GeneralIngoinInvoiceDisplay[]> {
        return http
            .get<ApiResponse<GeneralIngoinInvoiceDisplay[]>>(`debit-paym`)
            .then(handleApiResponse)
    }

    static getDebitPayments(partnerId: number, searchText: string): Promise<DebitPaymentEditModelComplaint[]>{
        return http
            .get<ApiResponse<DebitPaymentEditModelComplaint[]>>(`debit-paym/editmodels`, {
                params: {
                    partnerId: partnerId,
                    searchText: searchText,
                  },
            })
            .then(handleApiResponse)
    }

    static get(id: number): Promise<GeneralIngoinInvoiceDisplay>{
        return http
            .get<ApiResponse<GeneralIngoinInvoiceDisplay>>(`debit-paym/edit/${id}`)
            .then(handleApiResponse)
    }

    static getDebitItems(partnerId: number, searchText: string): Promise<DebitPayment[]>{
        return http
            .get<ApiResponse<DebitPayment[]>>("debit-paym/debits", {
                params: {
                  partnerId: partnerId,
                  searchText: searchText,
                },
            }).then(handleApiResponse);
    }

    static getExtOrders(partnerId: number, searchText: string): Promise<DownPaymentExtOrder[]>{
        return http
            .get<ApiResponse<DownPaymentExtOrder[]>>("debit-paym/extorders", {
                params: {
                  partnerId: partnerId,
                  searchText: searchText,
                },
            }).then(handleApiResponse);
    }

    static update(item: GeneralIngoingInvoice): Promise<any>{
        return http
            .put(`debit-paym/${item.id}`, item)
            .then(checkResponse)
    }

    static create(item: GeneralIngoingInvoice): Promise<any>{
        return http
            .post(`item`, item)
            .then(handleApiResponse)
    }

    static createItem(item: DebitPaymentEditModelComplaint): Promise<any>{
        return http
            .post(`debit-paym/item`, item)
            .then(handleApiResponse)
    }
}