export default interface VatItem{
    id: number;
    name: string;
    foreign: string; 
    english: string;
    deleted: boolean;
    tax: number;
    validFrom: Date;
    validTo: Date;
    backRate: number;
    deleting: boolean;
}

export const defaultVatItemProps: VatItem ={
    id:1,
    name: '',
    foreign: '', 
    english: '',
    deleted: false,
    tax: 1,
    validFrom: new Date('1990,01,01'),
    validTo: new Date('1990,01,01'),
    backRate: 0,
    deleting:false
}