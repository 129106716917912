






































































import Paginator from "@/components/Paginator.vue";
import Pager from "@/models/Pager";
import Vue from "vue";
import { Year } from "../models/YearModell";
import store, { GeneralLedgerStore } from "../services/store";
import AccountNumberTreeNode from "../models/InvoiceMirrorTreeNode";

interface Data {
  store: GeneralLedgerStore;
  pager: Pager;
  pageSizes: number[];
  triggerRerender: number;
  openedNodes: number[];
}
interface Props {
  yearThatIsBeingChecked: Year;
}
interface Computed {
  yearBeingWatched: number;
  invMirrorTree: AccountNumberTreeNode[];
}

export default Vue.extend<Data, {}, Computed, Props>({
  data(): Data {
    return {
      store: store,
      pager: new Pager(),
      pageSizes: [16, 32],
      triggerRerender: 0,
      openedNodes: [1],
    };
  },
  computed: {
    invMirrorTree() {
      return this.store.invMirrorTrees;
    },
    yearBeingWatched() {
      return this.store.yearBeingChecked;
    },
  },
  components: { Paginator },
  methods: {
    async pagerChanged(obj: any) {
      this.pager = obj;
      await new Promise((x) => setTimeout(x, 30));
      if (this.store.invMirrorTrees.length > 0) {
        (this.$refs.tree as any).updateAll(true);
      }
    },
  },
  watch: {
    async yearBeingWatched() {
      await this.store.getOpenTreeItems(this.yearBeingWatched);
      await this.store.getInvMirrorTree(this.yearBeingWatched);
      this.openedNodes = this.store.openedTreeItems;

      if (this.store.invMirrorTrees.length > 0) {
        (this.$refs.tree as any).updateAll(true);
      }

      this.store.hasGlimGenerated = false;
      if (this.store.invMirrorTrees.length > 0) {
        this.store.hasGlimByNature = true;
      }

      console.log(this.store.invMirrorTrees);
    },
  },

  props: {
    yearThatIsBeingChecked: {},
  },

  async beforeMount() {
    await this.store.getOpenTreeItems(this.yearBeingWatched);

    await this.store.getInvMirrorTree(this.yearBeingWatched);
    if (this.store.invMirrorTrees.length > 0) {
      (this.$refs.tree as any).updateAll(true);
    }

    if (this.store.invMirrorTrees.length > 0) {
      this.store.hasGlimByNature = true;
    } else {
      this.store.hasGlimByNature = false;
    }
  },
});
