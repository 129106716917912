











import Vue from "vue";

export default Vue.extend({
  props: {
    value: String
  },

  methods: {
      onInput(value: string) {
          this.$emit('input', value)
      }
  }
});
