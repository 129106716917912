export default interface DocumentTemplate {
    id: number;
    name: string;
    invTypeId: number;
    invSubTypeId: number;
    deleted: boolean;
}

export const documentTemplateDefaultProps: DocumentTemplate = {
    id: 0,
    name: '',
    invTypeId: 1,
    invSubTypeId: 1,
    deleted: false,
}

export const documentTemplateTechnicalRecord: DocumentTemplate = {
    id: 1,
    name: '-',
    invTypeId: 1,
    invSubTypeId: 1,
    deleted: false,
}
