

















































































import Vue from "vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import StornoSummaryInvoice from "../models/StornoSummaryModel";
import ApiSearch, { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import dayjs from "dayjs";
import { store } from "../services/StornoSummaryStore";
import eventBus from "@/services/eventBus";
import { dateFormatHU } from "@/filters/date-format";
import moneyFormat from "@/filters/money-format";
import DateInput from "@/components/DateInput.vue";

export default Vue.extend({
  components: {
    ApiSearchPage,
    DateInput
  },

  data() {
    return {
      store,
      selectedInvoices: [] as any[],
    };
  },

  methods: {
    refreshApiSearch() {
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },

    selectChangeHandler(item: any) {
      item.selected = !item.selected;
      const selectedInv = item;
      if (item.selected) this.selectedInvoices.push(selectedInv);
      if (!item.selected)
        this.selectedInvoices = this.selectedInvoices.filter((i) => !(item.prefix == i.prefix && item.invId == i.invId));
    },

    async selectButtonClickHandler() {
      if (this.selectedInvoices.length === 0) {
        eventBus.$emit("error", "Jelöljön ki legalább egy számlát!");
        return;
      }

      const ret = await this.store.StornoSummary(this.selectedInvoices);
      if (ret){
        eventBus.$emit("success", "Sikeres sztornó!");
        this.refreshApiSearch();
      }
    },

    beforeRefreshHandler() {
      this.selectedInvoices = [];
    },
  },

  computed: {
    columns(): ApiSearchColumn[] {
      return [
        { cell: "selectTemplate", filterable: false },
        { field: "prefix", title: "Prefix" },
        { field: "invId", title: "Belső azonosító" },
        { field: "outerInvNo", title: "Külső számlaszám" },
        { field: "bgInvNo", title: "Számlaszám" },
        { field: "invDate", title: "Számla dátuma", cell: "invDateTemplate", headerClass: "wider-centered", fieldClass: "wider-centered"},
        { field: "outerInvNoSt", title: "Jóváíró külső számlaszám" },
        { field: "invoiceStId", title: "Jóváíró belső azonosító" },
        { field: "invoiceStInvNo", title: "Jóváíró számlaszám" },
        { field: "stornoDate", title: "Jóváírás dátuma", cell: "stornoDateTemplate" },
        { field: "shipDate", title: "Teljesítés dátuma", cell: "shipDateTemplate"},
        { field: "accDate", title: "Könyvelés dátuma", cell: "accDateTemplate"},
        { field: "partnerName", title: "Partner neve" },
        { field: "currTotal", title: "Bruttó (számla pénzneme)", cell: "currTotalTemplate" },
        { field: "currNetto", title: "Nettó (számla pénzneme)", cell: "currNettoTemplate" },
        { field: "currVat", title: "Áfa (számla pénzneme)", cell: "currVatTemplate"},
        { field: "invCurrency", title: "Számla pénzneme" },
        { field: "stornoUserName", title: "Jóváíró felhasználó" },
        { field: "netto", title: "Nettó", cell: "nettoTemplate"},
        { field: "vat", title: "ÁFA", cell: "vatTemplate"},
        { field: "total", title: "Bruttó", cell: "totalTemplate"},
        { field: "currency", title: "Pénznem" },
      ];
    },
  },
});
