
































































import store, { BankRemitStore } from "../services/BankRemitStore";
import api from "../services/BankRemitApi";
import Vue from "vue";
import eventBus from "@/services/eventBus";
import http, { baseURL } from "@/services/http";

export enum BankRemitStatusEnum {
  underProcess = 2,
  forwardable = 3,
  forwarded = 4,
  transferred = 5,
  failed = 6,
  underRepair = 7,
  partlyTransferred = 8,
}

interface Data {
  store: BankRemitStore;
  selExportType: string;
  exportTypes: string[];
  downloadButtonAvaliable: boolean;
}

interface Method {
  exportRemit(): void;
  downloadRemit(): void;
}

interface Computed {
  exportEnabled: boolean;
  downloadEnabled: boolean;
  exported: boolean;
}

export default Vue.extend<Data, Method, Computed, {}>({
  data() {
    return {
      store,
      selExportType: "",
      exportTypes: [],
      downloadButtonAvaliable: false,
    };
  },

  computed: {
    exportEnabled() {
      return this.store.bankRemit.status === BankRemitStatusEnum.forwardable;
    },
    downloadEnabled() {
      return this.store.bankRemit.status === BankRemitStatusEnum.forwarded;
    },
    exported() {
      return this.store.bankRemit.status === BankRemitStatusEnum.forwarded;
    },
  },

  methods: {
    async exportRemit() {
      const x = await api
        .generatePackage(this.store.bankRemit, this.selExportType)
        .then(async (f) => {
          if (f) {
            await this.store.loadBankRemit(this.store.bankRemit.id);
          }
          return f;
        });
      if (x) {
        eventBus.$emit("success", "Sikeres fájlgenerálás!");
      }
    },
    async downloadRemit() {
      window.open(`${baseURL}bank-remit/${this.store.bankRemit.id}/download?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`)
    }
  },

  async beforeMount() {
    this.store.step = 4;

    await this.store.loadBankRemit(Number(this.$route.params.id));

    if (this.exportEnabled) {
      this.exportTypes = await api
        .getExportTypes(this.store.bankRemit.id)
        .then((x) => {
          this.selExportType = x[0];
          return x;
        });
    }
  },
});
