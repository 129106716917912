export default interface CarPostCalc {
    id: number;
    name: string;
    inInv: number;
    isSelected: boolean;
  }
  
  export const carPostCalcDefaultProps: CarPostCalc = {
    id: 0,
    name: "",
    inInv: 1,
    isSelected: false,
  };