import axios, { Canceler, CancelToken } from "axios";
import http, { catchCancelRequest } from "@/services/http";
import { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import ApiSearch from "../models/ApiSearch";
import ApiSearchResult from "../models/ApiSearchResult";

export class CancelableApi {
  requests: { name: string; cancel: Canceler }[] = [];

  resetToken(name: string): CancelToken {
    const r = this.requests.find((x) => x.name == name);
    if (r) r.cancel("New request");

    const axiosSource = axios.CancelToken.source();
    const newRequest = { name: name, cancel: axiosSource.cancel };
    this.requests.push(newRequest);

    return axiosSource.token;
  }

  resetRequest(name: string) {
    const r = this.requests.find((x) => x.name == name);
    if (!r) return;

    const index = this.requests.indexOf(r);
    if (index > -1) this.requests.splice(index, 1);
  }
}

//* not static ->
// Every ApiSearch component gets it's own requests[]
// to avoid calling .cancel() on other's requests
export default class ServerSideSearchApi extends CancelableApi {
  async get(url: string, id: number | string): Promise<object | void> {
    const name = url + ":" + this.get.name;
    const token = this.resetToken(name);

    const response = await http
      .get<ApiResponse<object>>(url, { params: { id }, cancelToken: token })
      .then(
        (value) => handleApiResponse(value),
        (reason) => catchCancelRequest(reason)
      );

    this.resetRequest(name);
    return response;
  }

  async post(
    url: string,
    apiSearch: ApiSearch
  ): Promise<ApiSearchResult<object> | void> {
    const name = url + ":" + this.post.name;
    const token = this.resetToken(name);

    const response = await http
      .post<ApiResponse<ApiSearchResult<object>>>(
        url,
        {
          currentPage: apiSearch.currentPage,
          pageSize: apiSearch.pageSize,
          searchText: apiSearch.searchText,
          filter: apiSearch.filter?.filters,
          extraFilter: apiSearch?.extraFilter,
          sort: apiSearch.sort?.toObject(),
        },
        { cancelToken: token }
      )
      .then(
        (value) => handleApiResponse(value),
        (reason) => catchCancelRequest(reason)
      );

    this.resetRequest(name);
    return response;
  }
}
