import http, { handleApiResponse } from "@/services/http";
import ComplaintStatus from "../models/ComplaintsStatus";
import ApiResponse from "@/models/ApiResponse";

export class AccComplaintsStore {
  statuses: ComplaintStatus[] = [];

  async loadStatuses() {
    this.statuses = await http
      .get<ApiResponse<ComplaintStatus[]>>(`acc-complaints/statuses`)
      .then(handleApiResponse);
  }
}

const store = new AccComplaintsStore();
export default store;
