























































































































































































































































































































































































































































































import MoneyDisplay from "@/components/MoneyDisplay.vue";
import Vue from "vue";
import MultiPartnerSearch from "../partner/views/MultiPartnerSearch.vue";
import WriteOffDebtStore, {getStore} from "./store/writeOffDebtStore";
import ApiSearch from "@/components/apiSearch/models/ApiSearch";
import WriteOffDebtLimitter from "./components/WriteOffDebtLimitter.vue";
import DateInput from "@/components/DateInput.vue";
import PartnerPossibleWriteOffData from "./models/PartnerPossibleWrtireOffDebt";
import PossibleWriteOffDebt from "./models/PossibleWriteOffDebt";
import IconButton from "@/components/IconButton.vue";
import eventBus from "@/services/eventBus";

interface Data {
    direction: boolean;
    limit: boolean;
    store: WriteOffDebtStore | null;
    loading: boolean;
}

interface Computed{
    showLimit: boolean;
}

interface Methods {
  load(): any;
  writeOffSelecteds(item: PartnerPossibleWriteOffData): any;
  sendToApiRemoveInvoiceToWriteOffDebtTemp(item: PossibleWriteOffDebt): any;
  sendAllToApiRemoveInvoiceToWriteOffDebtTemp(item: PossibleWriteOffDebt[]): any;
}

export default Vue.extend<Data,Methods,Computed,{}>({
    async beforeMount() {
        this.limit = this.$route.query.limit == "true";
        this.direction = this.$route.query.direction == "true";
        this.store = getStore(this.direction, this.limit);
        await this.store?.loadItems();
    },

    components: { MultiPartnerSearch, MoneyDisplay, WriteOffDebtLimitter, DateInput, IconButton },

    data() {
        return {
            store: null,
            direction: false,
            limit: false,
            apiSearch: new ApiSearch(),
            loading: false
        }
    },

    methods: {
      async sendAllToApiRemoveInvoiceToWriteOffDebtTemp(item: PossibleWriteOffDebt[])
      {
        try
        {
          this.loading = true;
          await this.store?.sendToApiRemoveInvoiceToWriteOffDebtTemp(item);
          await this.store?.loadItems();
        }
        finally
        {
          this.loading = false;
        }
      },
      async sendToApiRemoveInvoiceToWriteOffDebtTemp(item: PossibleWriteOffDebt) {
        try
        {
          this.loading = true;
          await this.store?.sendToApiRemoveInvoiceToWriteOffDebtTemp([item]);
          await this.store?.loadItems();
        }
        finally
        {
          this.loading = false;
        }
      },
      async writeOffSelecteds(item: PartnerPossibleWriteOffData)
      {
        try
        {
          this.loading = true;
          await this.store?.writeOffSelecteds(item);
          await this.store?.loadItems();
          eventBus.$emit("success", "Sikeres kivezetés!");
        }
        finally
        {
          this.loading = false;
        }
      },
      async load() 
      {
        try
        {
          this.loading = true;
          await this.store?.loadItems();
        }
        finally
        {
          this.loading = false;
        }
      }
    },

    watch: 
    {
        async "store.filter.partnerFilters"() {
          await this.load();
        },
        async "store.filter.limit"() {
          await this.load();
        }
    }
})
