import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import SysFile from "../models/SysFile";
import SysFile1 from "../models/SysFile1";
import { SysParam } from "../models/SysParam";
import User from "@/modules/user/models/User";

export default class ConfigurationApi {
  static updateSysParam(sysParam: SysParam): Promise<boolean> {
    return http.put(`configuration/sys-param`, { sysParam: sysParam }).then(checkResponse);
  }

  static getToleranceApprovers(): Promise<User[]> {
    return http.get<ApiResponse<User[]>>(`configuration/tolerance-approvers`).then(handleApiResponse);
  }

  static updateToleranceApprovers(users: User[]): Promise<boolean> {
    return http.post(`configuration/tolerance-approvers/update`, { users }).then(checkResponse);
  }

  static getSettings(): Promise<SysFile> {
    return http.get<ApiResponse<SysFile>>(`configuration/sys`).then(handleApiResponse);
  }

  static getSettings1(): Promise<SysFile1> {
    return http.get<ApiResponse<SysFile1>>(`configuration/sys1`).then(handleApiResponse);
  }

  static updateIsInvPermitEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/sys1/invoice-permit`, { isInvPermit: b }).then(checkResponse);
  }

  // static getSysParam(key: string): Promise<SysParam> {
  //   return http.get<ApiResponse<SysParam>>(`configuration/sys-param/${key}`).then(handleApiResponse);
  // }

  static getSeletedToleranceApprovers(): Promise<User[]> {
    return http.get<ApiResponse<User[]>>(`configuration/selected-tolerance-approvers`).then(handleApiResponse);
  }

  static updateIsInvoiceFlowEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration`, { isInvoiceFlowEnabled: b }).then(checkResponse);
  }

  static updateGeneralRectItemEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/general-rect-item`, { setGeneralRectItemEnabled: b }).then(checkResponse);
  }

  static updateIsDepotVisible(b: boolean): Promise<boolean> {
    return http.put(`configuration/depot-visible`, { isDepotVisible: b }).then(checkResponse);
  }

  static updateOnlyRectItem(b: boolean): Promise<boolean> {
    return http.put(`configuration/only-rect-item`, { onlyRectItem: b }).then(checkResponse);
  }

  static updateGeniOutInv(b: boolean): Promise<boolean> {
    return http.put(`configuration/geni-out-inv`, { geniOutInv: b }).then(checkResponse);
  }

  static updateIsInvAcceptedEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/inv-accepted`, { isInvAccepted: b }).then(checkResponse);
  }

  static updateIsExchangeRateEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/exchange-rate`, { isExchangeRateEnabled: b }).then(checkResponse);
  }

  static updateIsExtCodeEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/ext-code`, { isExtCodeEnabled: b }).then(checkResponse);
  }

  static updateIsExtendedPartnerInfoEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/extended-partner-info`, { isExtendedPartnerInfoEnabled: b }).then(checkResponse);
  }

  static updateIsBankImportNotificationEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/bank-notification`, { isBankImportNotificationEnabled: b }).then(checkResponse);
  }

  static updateGeniItemNoteVisible(b: boolean): Promise<boolean> {
    return http.put(`configuration/geni-item-note-visible`, { geniItemNoteVisible: b }).then(checkResponse);
  }

  static updateInvGroupsSaved(b: boolean): Promise<boolean> {
    return http.put(`configuration/inv-groups-saved`, { invGroupsSaved: b }).then(checkResponse);
  }

  static updateBankDefrayerEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/bank-defrayer-enabled`, { bankDefrayerEnabled: b }).then(checkResponse);
  }

  static setTaxChangeQuestionVisible(b: boolean): Promise<boolean> {
    return http.put(`configuration/tax-change-question-visible`, { taxChangeQuestionVisible: b }).then(checkResponse);
  }

  static setIsInvPermit(b: boolean): Promise<boolean> {
    return http.put(`configuration/set-is-inv-permit`, { isInvPermit: b }).then(checkResponse);
  }

  static setSupplierRelationEnabled(b: boolean): Promise<boolean> {
    return http.put(`configuration/supplier-relation-enabled`, { setSupplierRelationEnabled: b }).then(checkResponse);
  }
}
