export default class BankRemitItem {
    itemId= 1;
    bankRemit= 1;
    prefix = "";
    invoice = 1;
    invNo ="";
    amount=0;
    dueAmount=0;
    partner=1;
    partnerName="";
    bankAccount=1;
    bankAccountName="";
    inEnclosuse="";
    invCurrency="";
    currAmount=0;
    exchangeRate=0;
    isTransfered=false;
    pterm: Date | string = "";
    bankDefrType = "";

    expanded= false;
    showInvoice= false;
    deleting= false;

    selected= false;

    constructor(params?: Partial<BankRemitItem>) {
        const data = { ...params };
        Object.assign(this, data);
      }
}
  
export const bankRemitItemDefaultProps: BankRemitItem = {
    itemId: 1,
    bankRemit: 1,
    prefix: "",
    invoice: 1,
    invNo: "",
    amount: 1,
    dueAmount: 0,
    partner: 1,
    partnerName: "",
    bankAccount: 1,
    bankAccountName: "",
    inEnclosuse: "",
    invCurrency: "",
    currAmount: 1,
    exchangeRate: 1,
    isTransfered: false,
    pterm:  "2000. 01. 01.",
    bankDefrType: "",

    expanded: false,
    showInvoice: false,
    deleting: false,

    selected: false
}