















































































































































































































































































































































































































































































































































































































































































































































































import Fragment from "../../../components/Fragment.vue";
import MoneyInput from "../../../components/MoneyInput.vue";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import ProjectApiSearch from "../components/ProjectApiSearch.vue";
import PartsApiSearch from "../components/PartsApiSearch.vue";

import Vue from "vue";
import eventBus from "../../../services/eventBus";
import configuration, { AppConfiguration } from "../../../models/Configuration";
import store, { InvoiceItemDimensionStore } from "../services/InvoiceItemDimensionStore";
import flowStore, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import sgStore, { SerStationGroupStore } from "../../serStationGroup/services/SerStationGroupStore";
import dStore, { DimCodeTypeStore } from "../../dimCodeType/services/DimCodeTypeStore";
import InvoiceItemOtherDimension, { invoiceItemOtherDimensionDefaultProps } from "../models/InvoiceItemOtherDImension";
import InvoiceItemDimension, { invoiceItemDimensionDefaultProps } from "../models/InvoiceItemDimension";
import ProjectHeadDTO from "@/modules/projectHead/models/ProjectHeadDTO";
import PartDTO from "@/modules/part/models/PartDTO";
import User from "../../user/models/User";
import UserApi from "../../user/services/UserApi";
import SerPartEditModel from "../../serPart/models/SerPartEditModel";
import SerPartApi from "../../serPart/services/SerPartApi";
import SerStation from "../../serStation/models/SerStation";
import SerStationApi from "../../serStation/services/SerStationApi";
import SerCar from "../../serCar/models/SerCar";
import SerCarApi from "../../serCar/services/SerCarApi";
import sStore, { SerCarStore } from "../../serCar/services/SerCarStore";
import EleventhDimension from "../models/EleventhDimension";

interface Data {
  localOtherDimensions: InvoiceItemOtherDimension[];
  newItem: InvoiceItemOtherDimension;
  backupInvGroupDimension: InvoiceItemDimension;
  maxNetto: number;

  users: User[];
  serPartEditModels: SerPartEditModel[];
  serStations: SerStation[];
  serCars: SerCar[];
  eleventhDimensions: EleventhDimension[];

  store: InvoiceItemDimensionStore;
  flowStore: InvoiceFlowStore;
  sgStore: SerStationGroupStore;
  dStore: DimCodeTypeStore;
  sStore: SerCarStore;

  categoryContent: Record<string, any>;
  dropDownContent: Record<string, any>;

  creating: boolean;

  dropDownVisible: boolean;
  categoryVisible: boolean;

  stationVisible: boolean;
  stationGroupVisible: boolean;
  toolVisible: boolean;
  userVisible: boolean;

  deletingTemplateVisible: boolean;
  itemTitle: string;
  itemToDelete: InvoiceItemOtherDimension;

  updating: boolean;
  itemToUpdate: InvoiceItemOtherDimension;
  backupItem: InvoiceItemOtherDimension;

  valid: boolean;
  configuration: AppConfiguration;

  searchSerPart: any;
  searchSerPartUpdate: any;
  searchSerCar: any;
  searchSerCarUpdate: any;

  componentKey: number;
}

interface Methods {
  create(): void;
  saveCreate(): void;
  cancelCreate(): void;

  selectedDropDownChanged(): void;
  station(dimItem: InvoiceItemOtherDimension): string;
  backButton(): void;

  deleteItem(item: InvoiceItemOtherDimension): void;
  cancelDelete(): void;
  confirmDelete(): void;

  updateItem(item: InvoiceItemOtherDimension): void;
  cancelUpdate(): void;
  confirmUpdate(): void;

  selectedDropDownChangedUpdate(): void;

  checkStationValid(): void;
  checkCarValid(): void;
  checkProjectValid(): void;
  checkPartValid(): void;
  checkEleventhDimensionValid(): void;
  checkTwelfthDimensionValid(): void;

  checkStationValidUpdate(net: number): void;
  checkCarValidUpdate(net: number): void;
  checkProjectValidUpdate(net: number): void;
  checkPartValidUpdate(net: number): void;
  checkEleventhDimenisonValidUpdate(net: number): void;
  checkTwelfthDimenisonValidUpdate(net: number): void;

  preLoadStationNet(): void;
  preLoadCarNet(): void;
  preLoadProjectNet(): void;
  preLoadPartNet(): void;
  preLoadEleventhDimensionNet(): void;
  preLoadTwelfthDimensionNet(): void;

  preLoadStationNetUpdate(net: number): void;
  preLoadCarNetUpdate(net: number): void;
  preLoadProjectNetUpdate(net: number): void;
  preLoadPartNetUpdate(net: number): void;
  preLoadEleventhDimensionNetUpdate(net: number): void;
  preLoadTwelfthDimensionNetUpdate(net: number): void;

  invoiceDimensionCreatingHandler(): void;
  stationType(dimItem: InvoiceItemOtherDimension): string;

  //creating changehandlers
  stationChange(): void;
  carChange(): void;
  projectChange(project: ProjectHeadDTO): void;
  partChange(part: PartDTO): void;
  eleventhDimensionChange(e: any): void;
  twelfthDimensionChange(e: any): void;

  //updating changehandlers
  stationChangeUpdate(): void;
  carChangeUpdate(): void;
  projectChangeUpdate(project: ProjectHeadDTO): void;
  partChangeUpdate(part: PartDTO): void;
  eleventhDimensionChangeUpdate(e: any): void;
  twelfthDimensionChangeUpdate(e: any): void;

  loadFilteredUsers(accInvGroup: number): void;
  loadUsers(): void;

  loadFilteredSerParts(name: string, accInvGroup: number): void;
  loadSerParts(name: string): void;

  loadFilteredSerStations(accInvGroup: number): void;
  loadSerStations(): void;

  loadSerCars(name?: string): void;
  loadEleventhDimensions(): void;
  showDeleted(): void;
}

interface Computed {
  calculateEroforrasNet: number;
  calculateCarNet: number;
  calculateProjectNet: number;
  calculatePartNet: number;
  calculateEleventhDimensionNet: number;
  calculateTwelfthDimensionNet: number;

  visibleItems: InvoiceItemOtherDimension[];
  filteredEleventhDimensions: EleventhDimension[];
  filteredTwelfthDimensions: EleventhDimension[];
  getPrecision: number;
}

interface Props {
  isPageDisabled: boolean;
  invGroupDimension: InvoiceItemDimension;
  currency: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Fragment, MoneyInput, ProjectApiSearch, PartsApiSearch, MoneyDisplay },
  props: {
    isPageDisabled: {
      type: Boolean,
      default: true,
    },
    invGroupDimension: {
      type: Object as () => InvoiceItemDimension,
    },
    currency: {
      type: String,
      default: "HUF"
    }
  },

  data: () => ({
    searchSerPart: null,
    searchSerPartUpdate: null,
    searchSerCar: null,
    searchSerCarUpdate: null,

    users: [],
    serPartEditModels: [],
    serStations: [],
    serCars: [],
    eleventhDimensions: [],

    localOtherDimensions: [],
    newItem: { ...invoiceItemOtherDimensionDefaultProps },
    backupInvGroupDimension: { ...invoiceItemDimensionDefaultProps },
    maxNetto: 0,

    store,
    flowStore,
    sgStore,
    dStore,
    sStore,

    categoryContent: [
      { id: 0, name: "Erőforrás" },
      { id: 1, name: "Gépjármű" },
      { id: 2, name: "Projekt" },
    ],

    dropDownContent: [
      { id: 0, name: "Munkaállomás" },
      // { id: 1, name: "Munkaállomás csoport" },
      { id: 2, name: "Szerszám" },
      { id: 3, name: "Munkatárs" },
    ],

    creating: false,

    categoryVisible: true,
    dropDownVisible: false,

    stationVisible: true,
    stationGroupVisible: false,
    toolVisible: false,
    userVisible: false,

    deletingTemplateVisible: false,
    itemTitle: "",
    itemToDelete: { ...invoiceItemOtherDimensionDefaultProps },

    updating: false,
    itemToUpdate: { ...invoiceItemOtherDimensionDefaultProps },
    backupItem: { ...invoiceItemOtherDimensionDefaultProps },

    valid: false,
    configuration,
    componentKey: 0,
  }),

  async beforeMount() {
    this.backupInvGroupDimension = { ...this.$props.invGroupDimension };
    this.localOtherDimensions = [...this.$props.invGroupDimension.otherDimensions];
    this.maxNetto = this.$props.invGroupDimension.invGroupNet;
    this.newItem = {...invoiceItemOtherDimensionDefaultProps };
  },

  computed: {
    getPrecision() {
      const curr = this.flowStore.invoice.invCurrency;
      const digits = this.configuration.operel ? 2 : this.configuration.getCurrDigits(curr)?.geniItemDigits;
      return digits ?? 0;
    },

    filteredEleventhDimensions(){
      return this.eleventhDimensions.filter((x) => x.accGroup == this.$props.invGroupDimension.invGroup && x.type == 12);
    },

    filteredTwelfthDimensions(){
      return this.eleventhDimensions.filter((x) => x.type == 13);
    },

    calculateEroforrasNet() {
      let stationNet = 0;
      this.localOtherDimensions.forEach((element: InvoiceItemOtherDimension) => {
        stationNet += element.stationNet + element.partNet;
      });
      return Number((this.maxNetto - stationNet).toFixed(2));
    },

    calculatePartNet() {
      let partNet = 0;
      this.localOtherDimensions.forEach((part: InvoiceItemOtherDimension) => {
        partNet += part.partNet + part.stationNet;
      });
      if (this.maxNetto - partNet > 0) {
        return Number((this.maxNetto - partNet).toFixed(2));
      }
      return 0;
    },

    calculateProjectNet() {
      let projectNet = 0;
      this.localOtherDimensions.forEach((project: InvoiceItemOtherDimension) => {
        projectNet += project.projectNet;
      });
      if (this.maxNetto - projectNet != 0) {
        return Number((this.maxNetto - projectNet).toFixed(2));
      }
      return 0;
    },

    calculateCarNet() {
      let carNet = 0;
      this.localOtherDimensions.forEach((i: InvoiceItemOtherDimension) => {
        carNet += i.carNet;
      });
      if (this.maxNetto - carNet != 0) {
        return Number((this.maxNetto - carNet).toFixed(2));
      }
      return 0;
    },

    calculateEleventhDimensionNet() {
      let eleventhDimensionNet = 0;
      this.localOtherDimensions.forEach((i: InvoiceItemOtherDimension) => {
        eleventhDimensionNet += i.eleventhDimensionNet;
      });
      if (this.maxNetto - eleventhDimensionNet > 0) {
        return Number((this.maxNetto - eleventhDimensionNet).toFixed(2));
      }
      return 0;
    },

    calculateTwelfthDimensionNet() {
      let twelfthDimensionNet = 0;
      this.localOtherDimensions.forEach((i: InvoiceItemOtherDimension) => {
        twelfthDimensionNet += i.twelfthDimensionNet;
      });
      if (this.maxNetto - twelfthDimensionNet > 0) {
        return Number((this.maxNetto - twelfthDimensionNet).toFixed(2));
      }
      return 0;
    },

    visibleItems() {
      if (!this.localOtherDimensions) {
        return [];
      }

      return this.localOtherDimensions.filter(
        (i: InvoiceItemOtherDimension) =>
          i.stationName.length > 2 ||
          i.stationGroupName.length > 2 ||
          i.userName.length > 2 ||
          i.toolName.length > 2 ||
          i.carRegNum.length > 2 ||
          i.projectId > 1 ||
          i.partName.length > 2 ||
          i.eleventhDimensionName.length > 1 ||
          i.twelfthDimensionName.length > 1
      );
    },
  },

  created() {
    const createOtherDimensionEventHandlerName = "InvoiceDimensionCreating" + this.$props.invGroupDimension.id;
    eventBus.$on(createOtherDimensionEventHandlerName, this.invoiceDimensionCreatingHandler);
  },

  beforeDestroy() {
    const createOtherDimensionEventHandlerName = "InvoiceDimensionCreating" + this.$props.invGroupDimension.id;
    eventBus.$off(createOtherDimensionEventHandlerName, this.invoiceDimensionCreatingHandler);
  },

  watch: {
    searchSerPart(val: string) {
      if (this.store.preFilterStations) {
        if (val == null) this.loadFilteredSerParts("", this.backupInvGroupDimension.sectionType);
        else if (val.length > 2) this.loadFilteredSerParts(val, this.backupInvGroupDimension.sectionType);
      } else {
        if (val == null) this.loadSerParts("");
        else if (val.length > 2) this.loadSerParts(val);
      }
    },

    searchSerPartUpdate(val: string) {
      if (this.store.preFilterStations) {
        if (val == null) this.loadFilteredSerParts("", this.backupInvGroupDimension.sectionType);
        else if (val.length > 2) this.loadFilteredSerParts(val, this.backupInvGroupDimension.sectionType);
      } else {
        if (val == null) this.loadSerParts("");
        else if (val.length > 2) this.loadSerParts(val);
      }
    },

    searchSerCar(val: string) {
      if (val == null) this.loadSerCars("");
      else if (val.length > 2) this.loadSerCars(val);
    },

    searchSerCarUpdate(val: string) {
      if (val == null) this.loadSerCars("");
      else if (val.length > 2) this.loadSerCars(val);
    },
  },

  methods: {
    showDeleted(){
      this.sStore.showDeleted = !this.sStore.showDeleted;
    },

    //updating changehandlers
    stationChangeUpdate() {
      this.valid = this.itemToUpdate.stationNet > 0;
    },

    carChangeUpdate() {
      this.valid = this.itemToUpdate.carNet != 0;
    },

    eleventhDimensionChangeUpdate(e: any) {
      this.valid = this.itemToUpdate.eleventhDimensionNet > 0;
      this.itemToUpdate.eleventhDimensionDimCode = e.dimcode;
      this.itemToUpdate.eleventhDimensionName = e.national;
    },

    twelfthDimensionChangeUpdate(e: any) {
      this.valid = this.itemToUpdate.twelfthDimensionNet > 0;
      this.itemToUpdate.twelfthDimensionDimCode = e.dimcode;
      this.itemToUpdate.twelfthDimensionName = e.national;
    },

    projectChangeUpdate(project: ProjectHeadDTO) {
      this.itemToUpdate.projectId = project.id;
      if (this.backupInvGroupDimension.invGroupType != 2) {
        this.itemToUpdate.projectName = project.name;
      } else {
        this.itemToUpdate.projectName = project.partnerName;
      }
      this.valid = this.itemToUpdate.projectNet != 0;
    },

    partChangeUpdate(part: PartDTO) {
      this.itemToUpdate.partId = part.id;
      this.itemToUpdate.partName = part.name;
      this.valid = this.itemToUpdate.partNet > 0;
    },

    //creating changehandlers
    stationChange() {
      this.valid = this.newItem.stationNet > 0 && this.newItem.stationNet <= this.calculateEroforrasNet;
    },
    
    carChange() {
      this.valid = this.newItem.carNet != 0 && Math.abs(this.newItem.carNet) <= Math.abs(this.calculateCarNet);
    },

    eleventhDimensionChange(e: any) {
      this.newItem.eleventhDimensionName = e.national;
      this.newItem.eleventhDimensionDimCode = e.dimcode;
      this.valid = this.newItem.eleventhDimensionNet > 0 && this.newItem.eleventhDimensionNet <= this.calculateEleventhDimensionNet;
    },

    twelfthDimensionChange(e: any) {
      this.newItem.twelfthDimensionName = e.national;
      this.newItem.twelfthDimensionDimCode = e.dimcode;
      this.valid = this.newItem.twelfthDimensionNet > 0 && this.newItem.twelfthDimensionNet <= this.calculateTwelfthDimensionNet;
    },

    projectChange(project: ProjectHeadDTO) {
      this.newItem.projectId = project.id;
      if (this.backupInvGroupDimension.invGroupType != 2) {
        this.newItem.projectName = project.name;
      } else {
        this.newItem.projectName = project.partnerName;
      }
      this.valid = this.newItem.projectNet != 0 && this.newItem.projectNet <= this.calculateProjectNet;
    },

    partChange(part: PartDTO) {
      this.newItem.partId = part.id;
      this.newItem.partName = part.name;
      this.valid = this.newItem.partNet > 0 && this.newItem.partNet <= this.calculatePartNet;
    },

    stationType(dimItem: InvoiceItemOtherDimension) {
      if (dimItem.stationName != null && dimItem.stationName != "--") {
        return "Munkaállomás";
      } else if (dimItem.stationGroupName != null && dimItem.stationGroupName != "-") {
        return "Munkaállomás csoport";
      } else if (dimItem.toolName != null && dimItem.toolName != "--") {
        return "Szerszám";
      } else if (dimItem.userName != null && dimItem.userName != "-") {
        return "Munkatárs";
      }
      return "-";
    },

    invoiceDimensionCreatingHandler() {
      this.create();
      this.loadSerCars();
      this.loadEleventhDimensions();
    },

    backButton() {
      this.dropDownVisible = false;
      this.categoryVisible = true;
      this.newItem = { ...invoiceItemOtherDimensionDefaultProps };
    },

    station(dimItem: InvoiceItemOtherDimension) {
      if (dimItem.stationName != "--") {
        return dimItem.stationName;
      } else if (dimItem.stationGroupName != "-") {
        return dimItem.stationGroupName;
      } else if (dimItem.toolName != "--") {
        return dimItem.toolName;
      } else if (dimItem.userName != "-") {
        return dimItem.userName;
      } else {
        return "-";
      }
    },

    async updateItem(clickedItem: InvoiceItemOtherDimension) {
      clickedItem.inEdit = true;
      this.localOtherDimensions = [...this.localOtherDimensions];
      this.backupItem = clickedItem;

      if (clickedItem.stationName.length > 2) {
        clickedItem.type = "Munkaállomás";
      } else if (clickedItem.stationGroupName.length > 2) {
        clickedItem.type = "Munkaállomás csoport";
      } else if (clickedItem.toolName.length > 2) {
        clickedItem.type = "Szerszám";
      } else if (clickedItem.userName.length > 2) {
        clickedItem.type = "Munkatárs";
      }

      this.itemToUpdate = { ...clickedItem };
      this.itemToUpdate.inEdit = true;
      this.updating = true;
      this.categoryVisible = true;
      this.dropDownVisible = false;

      this.valid = false;

      console.log(this.itemToUpdate);
      

      await this.selectedDropDownChangedUpdate();
      await this.loadSerCars();
      await this.loadEleventhDimensions();

      this.$emit("findInvGroup", clickedItem.invGroup);
    },

    cancelUpdate() {
      this.backupItem.inEdit = false;
      this.localOtherDimensions = [...this.localOtherDimensions];

      this.updating = false;
      this.categoryVisible = false;
      this.dropDownVisible = false;
      this.itemToUpdate = { ...invoiceItemOtherDimensionDefaultProps };

      this.$emit("showMessageInParent", "");

      this.users = [];
      this.serStations = [];
      this.serPartEditModels = [];
      this.serCars = [];
      this.eleventhDimensions = [];
    },

    async confirmUpdate() {
      if (this.valid) {
        this.backupItem.inEdit = false;
        this.itemToUpdate.inEdit = false;

        this.updating = false;
        this.categoryVisible = false;
        this.dropDownVisible = false;

        this.itemToUpdate.invItem = Number(this.$props.invGroupDimension.invItem);

        let ret = null;
        if (this.flowStore.step == 6) {
          ret = await this.store.updateOtherDimension(this.itemToUpdate, true);
          if (this.configuration.isInvAccepted.enabled) {
            this.flowStore.reopen();
          }
        } else {
          ret = await this.store.updateOtherDimension(this.itemToUpdate, false);
        }

        if (ret) {
          this.localOtherDimensions = this.localOtherDimensions.filter((x) => x.id != this.backupItem.id);
          this.localOtherDimensions.push(this.itemToUpdate);
          this.localOtherDimensions = [...this.localOtherDimensions];
        }
      }

      this.$emit("showMessageInParent", "");
    },

    deleteItem(clickedItem: InvoiceItemOtherDimension) {
      this.itemToDelete = { ...clickedItem };
      this.deletingTemplateVisible = true;
      this.itemToDelete.deleting = true;

      if (clickedItem.stationName != "--") {
        this.itemTitle = clickedItem.stationName;
      } else if (clickedItem.stationGroupName != "-") {
        this.itemTitle = clickedItem.stationGroupName;
      } else if (clickedItem.toolName != "--") {
        this.itemTitle = clickedItem.toolName;
      } else if (clickedItem.userName != "-") {
        this.itemTitle = clickedItem.userName;
      } else if (clickedItem.projectName != "-, -" && clickedItem.projectName != "-") {
        this.itemTitle = clickedItem.projectName;
      } else if (clickedItem.carRegNum != "-") {
        this.itemTitle = clickedItem.carRegNum;
      } else if (clickedItem.partName != "-") {
        this.itemTitle = clickedItem.partName;
      } else {
        this.itemTitle = "";
      }
    },

    cancelDelete() {
      this.deletingTemplateVisible = false;
      this.itemToDelete.deleting = false;
    },

    async confirmDelete() {
      this.deletingTemplateVisible = false;
      this.itemToDelete.deleting = false;

      let ret = null;
      if (this.flowStore.step == 6) {
        ret = await this.store.deleteOtherDimension(this.itemToDelete.id, true);
        if (this.configuration.isInvAccepted.enabled) {
          this.flowStore.reopen();
        }
      } else {
        ret = await this.store.deleteOtherDimension(this.itemToDelete.id, false);
      }

      if (ret) {
        this.localOtherDimensions = this.localOtherDimensions.filter((x) => x.id != this.itemToDelete.id);
        this.localOtherDimensions = [...this.localOtherDimensions];
      }
    },

    create() {
      this.newItem = { ...invoiceItemOtherDimensionDefaultProps };
      this.newItem.type = "-";
      this.valid = false;
      this.creating = true;
    },

    async saveCreate() {
      if (this.valid) {
        this.newItem.invItem = this.$props.invGroupDimension.invItem;
        this.newItem.invGroup = this.$props.invGroupDimension.invGroup;

        let ret = null;
        if (this.flowStore.step == 6) {
          ret = await this.store.createOtherDimensionVersion2(this.newItem, true);
          if (this.configuration.isInvAccepted.enabled) {
            this.flowStore.reopen();
          }
        } else {
          ret = await this.store.createOtherDimensionVersion2(this.newItem, false);
        }

        this.newItem.id = ret ?? 1;
        this.localOtherDimensions.push(this.newItem);
        this.localOtherDimensions = [...this.localOtherDimensions];

        this.creating = false;
        this.dropDownVisible = false;
        this.categoryVisible = true;
        this.newItem = { ...invoiceItemOtherDimensionDefaultProps };
      }
      this.componentKey += 1;
      this.$emit("showMessageInParent", "");
    },

    cancelCreate() {
      this.creating = false;
      this.dropDownVisible = false;
      this.categoryVisible = true;

      this.$emit("showMessageInParent", "");

      this.users = [];
      this.serStations = [];
      this.serPartEditModels = [];
      this.serCars = [];
      this.eleventhDimensions = [];
    },

    //beírt összeg ellenőrzése update
    checkPartValidUpdate(net: number) {
      this.valid = this.itemToUpdate.partNet <= this.calculatePartNet + net && this.itemToUpdate.partNet > 0;
    },

    checkProjectValidUpdate(net: number) {
      this.valid = this.itemToUpdate.projectNet <= this.calculateProjectNet + net && this.itemToUpdate.projectNet != 0;
    },

    checkCarValidUpdate(net: number) {
      this.valid = Math.abs(this.itemToUpdate.carNet) <= Math.abs(this.calculateCarNet + net) && this.itemToUpdate.carNet != 0;
    },

    checkEleventhDimenisonValidUpdate(net: number) {
      this.valid = this.itemToUpdate.eleventhDimensionNet <= this.calculateEleventhDimensionNet + net && this.itemToUpdate.eleventhDimensionNet > 0;
    },

    checkTwelfthDimenisonValidUpdate(net: number) {
      this.valid = this.itemToUpdate.twelfthDimensionNet <= this.calculateTwelfthDimensionNet + net && this.itemToUpdate.twelfthDimensionNet > 0;
    },

    checkStationValidUpdate(net: number) {
      if (this.itemToUpdate.type == "Munkaállomás") {
        this.valid =
          this.itemToUpdate.stationNet <= this.calculateEroforrasNet + net &&
          this.itemToUpdate.stationNet > 0 &&
          this.itemToUpdate.stationName.length > 2;
      } else if (this.itemToUpdate.type == "Munkaállomás csoport") {
        this.valid =
          this.itemToUpdate.stationNet <= this.calculateEroforrasNet + net &&
          this.itemToUpdate.stationNet > 0 &&
          this.itemToUpdate.stationGroupName.length > 2;
      } else if (this.itemToUpdate.type == "Szerszám") {
        this.valid =
          this.itemToUpdate.stationNet <= this.calculateEroforrasNet + net &&
          this.itemToUpdate.stationNet > 0 &&
          this.itemToUpdate.toolName.length > 2;
      } else if (this.itemToUpdate.type == "Munkatárs") {
        this.valid =
          this.itemToUpdate.stationNet <= this.calculateEroforrasNet + net &&
          this.itemToUpdate.stationNet > 0 &&
          this.itemToUpdate.userName.length > 2;
      }
    },

    //beírt összeg ellenőrzése create
    checkPartValid() {
      this.valid = this.newItem.partNet <= this.calculatePartNet && this.newItem.partNet > 0;
    },

    checkProjectValid() {
      if (this.newItem.projectNet <= this.calculateProjectNet && this.newItem.projectNet != 0) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },

    checkCarValid() {
      this.valid = Math.abs(this.newItem.carNet) <= Math.abs(this.calculateCarNet) && this.newItem.carNet != 0;
    },

    checkEleventhDimensionValid() {
      this.valid = this.newItem.eleventhDimensionNet <= this.calculateEleventhDimensionNet && this.newItem.eleventhDimensionNet > 0;
    },

    checkTwelfthDimensionValid() {
      this.valid = this.newItem.twelfthDimensionNet <= this.calculateTwelfthDimensionNet && this.newItem.twelfthDimensionNet > 0;
    },

    checkStationValid() {
      if (this.newItem.type == "Munkaállomás") {
        this.valid =
          this.newItem.stationNet <= this.calculateEroforrasNet &&
          this.newItem.stationNet > 0 &&
          this.newItem.stationName.length > 2;
      } else if (this.newItem.type == "Munkaállomás csoport") {
        this.valid =
          this.newItem.stationNet <= this.calculateEroforrasNet &&
          this.newItem.stationNet > 0 &&
          this.newItem.stationGroupName.length > 2;
      } else if (this.newItem.type == "Szerszám") {
        this.valid =
          this.newItem.stationNet <= this.calculateEroforrasNet &&
          this.newItem.stationNet > 0 &&
          this.newItem.toolName.length > 2;
      } else if (this.newItem.type == "Munkatárs") {
        this.valid =
          this.newItem.stationNet <= this.calculateEroforrasNet &&
          this.newItem.stationNet > 0 &&
          this.newItem.userName.length > 2;
      }
    },

    //előtöltés update
    preLoadPartNetUpdate(net: number) {
      if (this.itemToUpdate.partNet != 0) return;

      this.itemToUpdate.partNet = this.calculatePartNet + net;
      this.valid = this.itemToUpdate.partName.length > 4 && this.itemToUpdate.partNet > 0;
    },

    preLoadProjectNetUpdate(net: number) {
      if (this.itemToUpdate.projectNet != 0) return;

      this.itemToUpdate.projectNet = this.calculateProjectNet + net;
      this.valid = this.itemToUpdate.projectName.length > 4 && this.itemToUpdate.projectNet != 0;
    },

    preLoadCarNetUpdate(net: number) {
      if (this.itemToUpdate.carNet != 0) return;

      this.itemToUpdate.carNet = this.calculateCarNet + net;
      this.valid = this.itemToUpdate.carRegNum !== "-" && this.itemToUpdate.carNet != 0;
    },

    preLoadEleventhDimensionNetUpdate(net: number) {
      if (this.itemToUpdate.eleventhDimensionNet != 0) return;

      this.itemToUpdate.eleventhDimensionNet = this.calculateEleventhDimensionNet + net;
      this.valid = this.itemToUpdate.eleventhDimensionName !== "-" && this.itemToUpdate.eleventhDimensionNet > 0;
    },

    preLoadTwelfthDimensionNetUpdate(net: number) {
      if (this.itemToUpdate.twelfthDimensionNet != 0) return;

      this.itemToUpdate.twelfthDimensionNet = this.calculateTwelfthDimensionNet + net;
      this.valid = this.itemToUpdate.twelfthDimensionName !== "-" && this.itemToUpdate.twelfthDimensionNet > 0;
    },

    preLoadStationNetUpdate(net: number) {
      if (this.itemToUpdate.stationNet != 0) return;

      if (this.itemToUpdate.type == "Munkaállomás") {
        this.itemToUpdate.stationNet = this.calculateEroforrasNet + net;
        this.valid =
          this.itemToUpdate.stationName !== "--" &&
          this.itemToUpdate.stationName.length > 2 &&
          this.itemToUpdate.stationNet > 0;
      } else if (this.itemToUpdate.type == "Munkaállomás csoport") {
        this.itemToUpdate.stationNet = this.calculateEroforrasNet + net;
        this.valid =
          this.itemToUpdate.stationGroupName !== "-" &&
          this.itemToUpdate.stationGroupName.length > 2 &&
          this.itemToUpdate.stationNet > 0;
      } else if (this.itemToUpdate.type == "Szerszám") {
        this.itemToUpdate.stationNet = this.calculateEroforrasNet + net;
        this.valid =
          this.itemToUpdate.toolName !== "--" &&
          this.itemToUpdate.toolName.length > 2 &&
          this.itemToUpdate.stationNet > 0;
      } else if (this.itemToUpdate.type == "Munkatárs") {
        this.itemToUpdate.stationNet = this.calculateEroforrasNet + net;
        this.valid =
          this.itemToUpdate.userName !== "-" &&
          this.itemToUpdate.userName.length > 2 &&
          this.itemToUpdate.stationNet > 0;
      }
    },

    //előtöltés create
    preLoadPartNet() {
      if (this.newItem.partNet != 0) return;

      this.newItem.partNet = this.calculatePartNet;
      this.valid = this.newItem.partName !== "-" && this.newItem.partNet > 0;
    },

    preLoadProjectNet() {
      if (this.newItem.projectNet != 0) return;

      this.newItem.projectNet = this.calculateProjectNet;
      this.valid = this.newItem.projectName !== "-" && this.newItem.projectNet != 0;
    },

    preLoadCarNet() {
      if (this.newItem.carNet != 0) return;

      this.newItem.carNet = this.calculateCarNet;
      this.valid = this.newItem.carRegNum !== "-" && this.newItem.carNet != 0;
    },

    preLoadEleventhDimensionNet() {
      if (this.newItem.eleventhDimensionNet != 0) return;

      this.newItem.eleventhDimensionNet = this.calculateEleventhDimensionNet;
      this.valid = this.newItem.eleventhDimensionName !== "-" && this.newItem.eleventhDimensionNet > 0;
    },

    preLoadTwelfthDimensionNet() {
      if (this.newItem.twelfthDimensionNet != 0) return;

      this.newItem.twelfthDimensionNet = this.calculateTwelfthDimensionNet;
      this.valid = this.newItem.twelfthDimensionName !== "-" && this.newItem.twelfthDimensionNet > 0;
    },

    preLoadStationNet() {
      if (this.newItem.stationNet != 0) return;

      if (this.newItem.type == "Munkaállomás") {
        this.newItem.stationNet = this.calculateEroforrasNet;
        this.valid =
          this.newItem.stationName !== "--" && this.newItem.stationName.length > 2 && this.newItem.stationNet > 0;
      } else if (this.newItem.type == "Munkaállomás csoport") {
        this.newItem.stationNet = this.calculateEroforrasNet;
        this.valid =
          this.newItem.stationGroupName !== "-" &&
          this.newItem.stationGroupName.length > 2 &&
          this.newItem.stationNet > 0;
      } else if (this.newItem.type == "Szerszám") {
        this.newItem.stationNet = this.calculateEroforrasNet;
        this.valid = this.newItem.toolName !== "--" && this.newItem.toolName.length > 2 && this.newItem.stationNet > 0;
      } else if (this.newItem.type == "Munkatárs") {
        this.newItem.stationNet = this.calculateEroforrasNet;
        this.valid = this.newItem.userName !== "-" && this.newItem.userName.length > 2 && this.newItem.stationNet > 0;
      }
    },

    async selectedDropDownChangedUpdate() {
      if (this.itemToUpdate.type == "Munkaállomás") {
        this.stationVisible = true;
        this.stationGroupVisible = false;
        this.toolVisible = false;
        this.userVisible = false;
        if (this.store.preFilterStations) {
          await this.loadFilteredSerStations(this.backupInvGroupDimension.sectionType);
        } else {
          await this.loadSerStations();
        }
      } else if (this.itemToUpdate.type == "Munkaállomás csoport") {
        this.stationVisible = false;
        this.stationGroupVisible = true;
        this.toolVisible = false;
        this.userVisible = false;
      } else if (this.itemToUpdate.type == "Szerszám") {
        this.stationVisible = false;
        this.stationGroupVisible = false;
        this.toolVisible = true;
        this.userVisible = false;
        if (this.store.preFilterStations) {
          await this.loadFilteredSerParts("", this.backupInvGroupDimension.sectionType);
        } else {
          await this.loadSerParts("");
        }
      } else if (this.itemToUpdate.type == "Munkatárs") {
        this.stationVisible = false;
        this.stationGroupVisible = false;
        this.toolVisible = false;
        this.userVisible = true;
        if (this.store.preFilterStations) {
          await this.loadFilteredUsers(this.backupInvGroupDimension.sectionType);
        } else {
          await this.loadUsers();
        }
      }
      this.valid = false;
    },

    selectedDropDownChanged() {
      if (this.newItem.type == "Munkaállomás") {
        this.stationVisible = true;
        this.stationGroupVisible = false;
        this.toolVisible = false;
        this.userVisible = false;
        if (this.store.preFilterStations) {
          this.loadFilteredSerStations(this.backupInvGroupDimension.sectionType);
        } else {
          this.loadSerStations();
        }
      } else if (this.newItem.type == "Munkaállomás csoport") {
        this.stationVisible = false;
        this.stationGroupVisible = true;
        this.toolVisible = false;
        this.userVisible = false;
      } else if (this.newItem.type == "Szerszám") {
        this.stationVisible = false;
        this.stationGroupVisible = false;
        this.toolVisible = true;
        this.userVisible = false;
        if (this.store.preFilterStations) {
          this.loadFilteredSerParts("", this.backupInvGroupDimension.sectionType);
        } else {
          this.loadSerParts("");
        }
      } else if (this.newItem.type == "Munkatárs") {
        this.stationVisible = false;
        this.stationGroupVisible = false;
        this.toolVisible = false;
        this.userVisible = true;
        if (this.store.preFilterStations) {
          this.loadFilteredUsers(this.backupInvGroupDimension.sectionType);
        } else {
          this.loadUsers();
        }
      }
      this.valid = false;
    },

    async loadFilteredUsers(accInvGroup: number) {
      await UserApi.getFilteredUsers(accInvGroup).then((data) => (this.users = data));
    },

    async loadUsers() {
      await UserApi.getUsers().then((data) => (this.users = data));
    },

    async loadFilteredSerParts(name: string, accInvGroup: number) {
      await SerPartApi.getFilteredSerParts(name, accInvGroup).then((data) => (this.serPartEditModels = data));
    },

    async loadSerParts(name: string) {
      await SerPartApi.getAll(name).then((data) => (this.serPartEditModels = data));
    },

    async loadFilteredSerStations(accInvGroup: number) {
      await SerStationApi.getFilteredSerStations(accInvGroup).then((data) => (this.serStations = data));
    },

    async loadSerStations() {
      await SerStationApi.getAll().then((data) => (this.serStations = data));
    },

    async loadSerCars(name: string) {
      await SerCarApi.getAllRegNumsBy(name, this.sStore.showDeleted).then((data) => (this.serCars = data));
    },

    async loadEleventhDimensions() {
      await SerCarApi.getEleventhDimensions().then((data) => (this.eleventhDimensions = data));
    },
  },
});
