



















import Page from "@/components/Page.vue";
import ApiSearch from "@/components/apiSearch/components/ApiSearch.vue";

import Vue from "vue";
import store, { CashStatementStore } from "../services/store";

interface Data {
  store: CashStatementStore;
}

interface Methods {
  selected(cashPointID: number): void;
  cancel(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    Page,
    ApiSearch,
  },

  data: () => ({
    store,
  }),

  methods: {
    selected(cashPointID: number) {
      this.$router.push(`/cash-statement/create/${cashPointID}`);
    },

    cancel() {
      this.$router.push(`/cash-statement`);
    },
  },

  computed: {
    columns: () => [
      { field: "partner", title: "Partner neve" },
      { field: "site", title: "Telephely" },
      { field: "name", title: "Pénztár neve" },
      { field: "currency", title: "Pénznem" },
      { field: "accNo", title: "Max kivonat szám" },
      { field: "inDocNo", title: "Max kiadási biz. szám" },
      { field: "outDocNo", title: "Max bevételi biz. szám" },
      { field: "accCode", title: "Pénztár főkönyvi szám" },
    ],
  },
});
