












































































































































































































































































































import Vue from "vue";
import store, {
  GeneralIngoingInvoiceEditModelStore,
} from "../generalIngoingInvoiceEditModel/services/GeneralIngoingInvoiceEditModelStore";
import store2, { GeneralIngoingInvoiceStore } from "../generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import flowStore, { InvoiceFlowStore } from "../invoiceFlow/services/InvoiceFlowStore";
import FlowPaymentInfoView from "../invoiceFlow/views/FlowPaymentInfoView.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";
import MoneyDisplay from "../../components/MoneyDisplay.vue";
import Tooltip from "@/components/Tooltip.vue";
import InvoiceStockModfierGenisView from "@/modules/incomingInvoice/invoiceStockModification/views/InvoiceStockModfierGenisView.vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import InvoiceBrowserExtraFilter from "@/modules/invoiceBrowser/InvoiceBrowserExtraFilter.vue";
import ApiSearch, { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import dayjs from "dayjs";
import eventBus from "@/services/eventBus";
import DateInput from "@/components/DateInput.vue";
import CheckBox from "@/components/CheckBox.vue";

interface Data {
  configuration: AppConfiguration;
  store: GeneralIngoingInvoiceEditModelStore;
  store2: GeneralIngoingInvoiceStore; // miert kellett 2 store?
  flowStore: InvoiceFlowStore;
  filter: CompositeFilterDescriptor;
  extraFilter: CompositeFilterDescriptor;
  sort: SortDescriptor[];
  loading: boolean;
}

interface Methods {
  // TODO InvoiceBrowserModel -t fel kéne venni az any-k helyett
  showDetails(item: any): void;
  editInvoice(item: any): void;
  editDpaymInvoice(item: number, partner: number): void;
  editPreDpaymInvoice(item: number): void;
  convertToDate(dateString: string): string;
  handleExtraFilterToggle(isVisible: boolean): void;
  handleResetFiltering(): void;
  refreshApiSearch(): void;
  showInvImage(item: any): void;
  viewButtonClick(): void;
}

interface Computed {
  columns: ApiSearchColumn[];
  viewButtonText: string | null;
  typeLabel: string;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    FlowPaymentInfoView,
    MoneyDisplay,
    InvoiceStockModfierGenisView,
    Tooltip,
    ApiSearchPage,
    InvoiceBrowserExtraFilter,
    CheckBox,
    DateInput,
  },

  data: () => ({
    configuration,
    store,
    store2,
    flowStore,
    filter: {
      logic: "and",
      filters: [],
    },
    extraFilter: {
      logic: "and",
      filters: [],
    },
    sort: [],
    loading: false,
  }),

  async beforeMount() {
    // be kell várni az értékkészleteket a státusz szűrésnél
    this.loading = true;

    try {
      if (!this.configuration.operel) {
        await Promise.all([
          this.store.loadAccountStatuses(),
          this.store.loadStatusNames(),
          this.store2.loadTypes(),
          /*a flowstatus-t manualisan beallitjuk a store-ban */
        ]);
      } else {
        await Promise.all([this.store.loadAccountStatuses(), this.store.loadStatusNames()]);
      }
    } catch (error) {
      console.error(error);
      eventBus.$emit("error", "Hiba történt az adatok lekérdezésekor!");
    } finally {
      this.loading = false;
    }
  },

  mounted(){
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(new Date().getFullYear() - 1);

    (this.store2.eFilters.filters.invDateFrom as any).value = oneYearAgo.toISOString().split('T')[0].replaceAll('-', '.'); // ennél a dátumnál frissebb számlákat kérünk le a backendről
  },

  beforeDestroy() {
    this.store.clearStatuses();
    this.handleResetFiltering();
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      {
        cell: "invoiceDetailsTemplate",
        filterable: false,
      },
      {
        cell: "invoiceInvImageCommandTemplate",
        filterable: false,
      },
      {
        field: "id",
        title: "Azonosító",
        cell: "IDTemplate",
        fieldClass: "align__field__center",
        headerClass: "align__center",
      },
      { field: "prefix", title: "Prefix", cell: "prefixTemplate" },
      {
        field: "invoiceNo",
        title: "Bizonylat szám",
        cell: "invoiceNoTemplate",
      },
      {
        field: "partnerName",
        title: "Partner",
        headerClass: "geniLabel",
      },
      {
        field: "invoiceTypeName",
        title: "Számla típusa",
      },
      {
        field: "dpaymInv",
        title: "Előleg számla",
        cell: "dpaymTemplate",
        sortable: false,
      },
      {
        field: "preDpaymInv",
        title: "Előlegbekérő",
        cell: "preDpaymTemplate",
        sortable: false,
      },
      {
        field: "invoiceDate",
        title: "Számla dátuma",
        cell: "invoiceDateTemplate",
      },
      {
        field: "payTerm",
        title: "Fizetési határidő",
        cell: "payTermTemplate",
      },
      {
        field: "crediting",
        title: "Jóváíró/Jóváírt",
        fieldClass: "align__field__center",
        multiselect: true,
        multiselectField: "selectedCrediting",
        multiselectOptions: [{text: '-', value: '-'}, {text: 'Jóváíró', value: 'Jóváíró'}, { text: 'Jóváírt', value: 'Jóváírt'}],
      },
      {
        field: "statusName",
        title: "Státusz",
      },
      {
        field: "navStatusName",
        title: "NAV státusz",
      },
      {
        field: "currTotal",
        cell: "bruttoTemplate",
        title: "Bruttó",
        fieldClass: "align__right",
        headerClass: "align__right",
      },
      {
        field: "currPaymSum",
        cell: "sumpayTemplate",
        title: "Eddig fizetett",
      },
      {
        field: "invCurrency",
        title: "Pénznem",
        cell: "currencyTemplate",
      },
      {
        field: "shipDate",
        title: "Teljesítés dátuma",
        cell: "shipDateTemplate",
      },
      {
        field: "regDate",
        title: "Iktatás dátuma",
        cell: "regDateTemplate",
      },
      {
        field: "flowModDate",
        title: "Módosítás dátuma",
        cell: "flowModDateTemplate",
      },
      {
        field: "flowUserName",
        title: "Módosító",
      },
      {
        title: "Könyvelési státusz",
        field: "accStatusName",
        cell: "accStatusTemplate",
        fieldClass: "align__field__center",
        multiselect: true,
        multiselectField: "selectedAccStatuses",
        multiselectOptions: store.accountStatuses.map((x) => ({ text: x.name, value: x.id })),
      },
      {
        title: "Számla jóváhagyási státusz",
        field: "preStatusName",
        cell: "preStatusNameTemplate",
        fieldClass: "align__field__center",
        multiselect: true,
        multiselectField: "selectedPreStatuses",
        multiselectOptions: store.statusNames.map((x) => ({ text: x.name, value: x.id })),
      },
      {
        field: "flowStatusName",
        title: "Workflow státusz",
        cell: "flowStatusTemplate",
        fieldClass: "align__field__center",
        multiselect: true,
        multiselectField: "selectedFlowStatuses",
        multiselectOptions: store.flowStatuses.map((x) => ({ text: x.name, value: x.id })),
      },
      {
        field: "daysSpentInStatus",
        title: "Státuszban eltöltött idő",
        cell: "daysSpentTemplate",
      },
      {
        field: "isIntrastat",
        title: "Lehetséges intrastat",
        cell: "isIntrastat",
        type: ColumnType.Center,
        multiselect: true,
        multiselectField: "selectedIsIntrastat",
        multiselectOptions: [
          { text: "Nem", value: 0 },
          { text: "Igen", value: 1 },
        ],
      },
      {
        field: "isIndirectNoserv",
        title: "Nem készletes intrastatos",
        cell: "isIndirectNoserv",
        type: ColumnType.Center,
        multiselect: true,
        multiselectField: "selectedIsIndirectNoserv",
        multiselectOptions: [
          { text: "Nem", value: 0 },
          { text: "Igen", value: 1 },
        ],
      },
      {
        field: "intrastatIds",
        title: "Intrastat azonosítók",
        cell: "intrastatIds",
      },
    ],

    viewButtonText() {
      if (this.$route.params.typeFilter === "incoming") return `Váltás a "Minden számla" nézetre`;
      if (this.$route.params.typeFilter === "outgoing") return `Váltás a "Minden számla" nézetre`;
      if (this.$route.params.typeFilter === "all") return null;
      return null;
    },

    typeLabel() {
      if (this.$route.params.typeFilter === "incoming") return "(Bejövő számlák)";
      if (this.$route.params.typeFilter === "outgoing") return "(Kimenő számlák)";
      if (this.$route.params.typeFilter === "all") return "(Minden számla)";
      return "";
    },
  },

  methods: {
    editInvoice(item) {
      if (item.prefix === "GENI") {
        if (item.preDownPayment){
          this.$router.push(`/down-payment/edit/${item.id}`);
        } else {
          const path = configuration.invoiceFlow.enabled
          ? item.isStockCorrection
            ? `/uninvoiced-stock-correction/edit/${item.id}`
            : `/general-incoming-invoice/flow/edit/${item.id}/${item.flowStatus}`
          : `/general-incoming-invoice/edit/${item.partner}/${item.id}`;

        this.$router.push(path);
        }
      }

      if (item.prefix === "NOTO") {
        this.$router.push(`/noto/${item.id}`);
      }
    },

    editDpaymInvoice(item, partner) {
      const path = configuration.invoiceFlow.enabled
        ? `/general-incoming-invoice/flow/edit/${item}/4`
        : `/general-incoming-invoice/edit/${partner}/${item}`;

      this.$router.push(path);
    },

    editPreDpaymInvoice(item) {
      const path = `/down-payment/edit/${item}`;

      this.$router.push(path);
    },

    showDetails(item: any) {
      item.expanded = !item.expanded;
    },

    convertToDate(dateString) {
      if (dayjs(dateString).isValid()) {
        return dayjs(dateString).format("YYYY-MM-DD");
      }
      return "-";
    },

    //szűrő gombnyomás
    handleExtraFilterToggle(isVisible: boolean) {
      if (!isVisible) {
        this.handleResetFiltering();
        this.refreshApiSearch();
      }
    },

    // szűrők törlése gombnyomás
    handleResetFiltering() {
      this.store2.resetExtraFilter();
    },

    refreshApiSearch() {
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },

    async showInvImage(item) {
      await this.store.openInvoiceImage(item.id, item.prefix);
    },

    viewButtonClick() {
      this.$router.push(`/invoice-browser/all`);
    },
  },
});
