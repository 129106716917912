import UserAccountNo from "../models/UserAccountNo";
import ApiResponse from '@/models/ApiResponse'
import http, { handleApiResponse, checkResponse } from '@/services/http'

export default class UserAccountNoApi{
    static getAll(): Promise<UserAccountNo[]> {
        return http
            .get<ApiResponse<UserAccountNo[]>>(`user-acc-no`)
            .then(handleApiResponse)
    }

    static get(userAccountNoId: number): Promise<UserAccountNo> {
        return http
            .get<ApiResponse<UserAccountNo>>(`user-acc-no/${userAccountNoId}`)
            .then(handleApiResponse)
    }

    static update(userAccountNo: UserAccountNo): Promise<boolean>{
        return http
            .put(`user-acc-no/${userAccountNo.id}`, userAccountNo)
            .then(checkResponse)
    }

    static create(userAccountNo: UserAccountNo): Promise<number>{
        return http
            .post<ApiResponse<number>>(`user-acc-no`, userAccountNo)
            .then(handleApiResponse)
    }

    static delete(userAccountNoId: number): Promise<boolean> {
        return http
            .delete(`user-acc-no/${userAccountNoId}`)
            .then(checkResponse)
    }
}