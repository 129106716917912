

































































































































































































import IconButton from "@/components/IconButton.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import Tabs from "@/components/Tabs.vue";
import BankInViewApiSearch from "./BankInViewApiSearch.vue";

import Vue from "vue";
import BankExtractImportItem from "../models/BankExtractImportItem";
import store from "../services/BankExtractImportStore";
import BankExtractImportItemInvoiceRelation from "../models/BankExtractImportItemInvoiceRelation";
import BankInViewDTO from "../models/BankInViewDTO";
import { getAltRow } from "@/utils/classes";
import { FilteringModel } from "@/services/filtering";
import BankOutViewDTO from "../models/BankOutViewDTO";
import BankOutViewApiSearch from "./BankOutViewApiSearch.vue";

import configuration from "@/models/Configuration";

export default Vue.extend({
  components: { Tabs, BankInViewApiSearch, MoneyInput, MoneyDisplay, IconButton, BankOutViewApiSearch },

  props: {
    item: { type: Object as () => BankExtractImportItem },
    edit: Boolean,
  },

  data: () => ({
    store,
    defaultTab: 0,
    filter: new FilteringModel(),
    configuration
  }),

  beforeMount() {
    this.defaultTab = [2, 5].includes(this.item.statusID) ? 1 : 0;
    this.filter = new FilteringModel()
      .value("partner", this.item.partnerName)
      .multiSelect("ignoredStatuses", [{ text: "Teljesítve", value: 5 }], true);
  },

  methods: {
    invoiceSelected(invoice: BankInViewDTO | BankOutViewDTO): void {
      if (this.item.invoiceRelations.some((x) => x.prefix == invoice.prefix && x.invoiceID == invoice.invoiceID))
        return;

      const newRelation = new BankExtractImportItemInvoiceRelation({
        prefix: invoice.prefix,
        invoiceID: invoice.invoiceID,
        invoiceNo: invoice.invoiceNo,
        total: invoice.total,
        currency: invoice.currency,
        partnerName: invoice.partner,
        remark: invoice.remark,
      });

      this.item.invoiceRelations = [...this.item.invoiceRelations, newRelation];
    },

    removeInvoiceRelation(relation: BankExtractImportItemInvoiceRelation): void {
      const index = this.item.invoiceRelations.findIndex(
        (x) => x.prefix == relation.prefix && x.invoiceID == relation.invoiceID
      );
      if (index > -1) {
        this.item.invoiceRelations.splice(index, 1);
      }
    },

    getAltRow,
  },

  computed: {
    relationsTotal(): number {
      return this.item.invoiceRelations.reduce((acc, curr) => acc + curr.total, 0);
    },

    invoicesDifference(): number {
      return this.item.total - this.relationsTotal;
    },
  },
});
