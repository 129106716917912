import InvoiceFlowStatus, {invoiceFlowStatusDefaultProps} from "@/modules/invoiceFlowStatus/model/InvoiceFlowStatus"
import InvoiceFlowStatusApi from "../services/InvoiceFlowStatusApi"

export class InvoiceFlowStatusStore{
    invoiceFlowStatuses: InvoiceFlowStatus[] = []
    invoiceFlowStatus: InvoiceFlowStatus = {...invoiceFlowStatusDefaultProps}

    async loadInvoiceFlowStatuses() {
        this.invoiceFlowStatuses = (await InvoiceFlowStatusApi.getAll()).map((i) => ({ ...i, dropDown: false, dialog: false }))
    }

    async loadInvoiceFlowStatusesWithoutBusy() {
        this.invoiceFlowStatuses = (await InvoiceFlowStatusApi.getAll()).map((i) => ({ ...i, dropDown: false, dialog: false }))
    }

    async loadInvoiceFlowStatus(invoiceFlowStatusId: number) {
        this.invoiceFlowStatus = invoiceFlowStatusId > 1 ? await InvoiceFlowStatusApi.get(invoiceFlowStatusId) : { ...invoiceFlowStatusDefaultProps }
    }
}

const store = new InvoiceFlowStatusStore();
export default store