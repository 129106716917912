export default class BankExtractImportAccountType {
  id = 0;
  code = "";
  bankID = 1;
  typeID = 1;
  subtypeID = 1;
  deleted = false;

  constructor(params?: Partial<BankExtractImportAccountType>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}
