













































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import * as api from "../services/CarNetApi";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import CarSearch from "@/components/carSearch/CarSearch.vue";
import dayjs from "dayjs";
import AngledDoubleArrow from "@/components/AngledDoubleArrow.vue";

interface Data {
  carId: number;
  bevetelResze: any[];
  bevetelNemResze: any[];
  koltsegResze: any[];
  koltsegNemResze: any[];
  carDetails: {};
  postCalcInfoVisible: boolean;
}

interface Methods {
  searchPostCalcsByCar(): void;
  searchPostCalcsByCarWithParams(id: number): void;
  toDate(date: string): any;
  setSelected(item: any): any;
  showDetails(): any;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    MoneyDisplay,
    CarSearch,
    AngledDoubleArrow,
  },

  props: {
    propCarId: { type: Number, default: 1 },
    propPreStatusId: { type: Number, default: 1 },
    dropDownVisible: { type: Boolean, default: true },
  },

  async mounted() {
    console.log(this.$props.propCarId);
    console.log(this.$props.propPreStatusId);

    if (Number(this.$props.propCarId) > 1 && Number(this.$props.propPreStatusId == 8)) {
      await this.searchPostCalcsByCarWithParams(Number(this.$props.propCarId));
    }
  },

  data() {
    return {
      carId: 1,
      bevetelResze: [],
      bevetelNemResze: [],
      koltsegResze: [],
      koltsegNemResze: [],
      carDetails: {},
      postCalcInfoVisible: false,
    };
  },

  watch: {
    async carId(newId, oldId) {
      if (newId != oldId) {
        await this.searchPostCalcsByCar();
      }
    },
  },

  computed: {
    preSum() {
      let sum = 0;
      // sum += this.bevetelResze?.filter((x: any) => x.preCalcItemId > 0)
      //   .map((x) => x.netto)
      //   .reduce((a, b) => a + b, 0);
      // sum += this.bevetelNemResze?.filter((x: any) => x.preCalcItemId > 0)
      //   .map((x) => x.netto)
      //   .reduce((a, b) => a + b, 0);
      sum += this.koltsegResze
        .filter((x: any) => x.preCalcItemId > 0)
        .map((x) => x.netto)
        .reduce((a, b) => a + b, 0);
      sum += this.koltsegNemResze
        .filter((x: any) => x.preCalcItemId > 0)
        .map((x) => x.netto)
        .reduce((a, b) => a + b, 0);
      return sum;
    },

    postSum() {
      let sum = 0;
      // sum += this.bevetelResze?.filter((x: any) => x.postCalcItemId > 0)
      //   .map((x) => x.postCalcNetto)
      //   .reduce((a, b) => a + b, 0);
      // sum += this.bevetelNemResze?.filter((x: any) => x.postCalcItemId > 0)
      //   .map((x) => x.postCalcNetto)
      //   .reduce((a, b) => a + b, 0);
      sum += this.koltsegResze
        .filter((x: any) => x.postCalcItemId > 0)
        .map((x) => x.postCalcNetto)
        .reduce((a, b) => a + b, 0);
      sum += this.koltsegNemResze
        .filter((x: any) => x.postCalcItemId > 0)
        .map((x) => x.postCalcNetto)
        .reduce((a, b) => a + b, 0);
      return sum;
    },

    calculatePreStockValue() {
      let sum = 0;
      // sum += this.bevetelResze?.filter((x: any) => x.isStockValue && x.preCalcItemId > 0)
      //   .map((x) => x.netto)
      //   .reduce((a, b) => a + b, 0);
      // sum += this.bevetelNemResze?.filter((x: any) => x.isStockValue && x.preCalcItemId > 0)
      //   .map((x) => x.netto)
      //   .reduce((a, b) => a + b, 0);
      sum -= this.koltsegResze
        .filter((x: any) => x.isStockValue && x.preCalcItemId > 0)
        .map((x) => x.netto)
        .reduce((a, b) => a + b, 0);
      sum -= this.koltsegNemResze
        .filter((x: any) => x.isStockValue && x.preCalcItemId > 0)
        .map((x) => x.netto)
        .reduce((a, b) => a + b, 0);
      return sum;
    },

    calculatePostStockValue() {
      let sum = 0;
      // sum += this.bevetelResze?.filter((x: any) => x.postIsStockValue && x.postCalcItemId > 0)
      //   .map((x) => x.postCalcNetto)
      //   .reduce((a, b) => a + b, 0);
      // sum += this.bevetelNemResze?.filter((x: any) => x.postIsStockValue && x.postCalcItemId > 0)
      //   .map((x) => x.postCalcNetto)
      //   .reduce((a, b) => a + b, 0);
      sum -= this.koltsegResze
        .filter((x: any) => x.postIsStockValue && x.postCalcItemId > 0)
        .map((x) => x.postCalcNetto)
        .reduce((a, b) => a + b, 0);
      sum -= this.koltsegNemResze
        .filter((x: any) => x.postIsStockValue && x.postCalcItemId > 0)
        .map((x) => x.postCalcNetto)
        .reduce((a, b) => a + b, 0);
      return sum;
    },

    columns: () => [
      {
        title: "Típus",
        cell: "typeTemplate",
        headerClassName: "header_text",
      },
      {
        field: "netto",
        title: "Előkalkulációs tételek",
        cell: "nettoTemplate",
        headerClassName: "right_text header_text",
      },
      {
        field: "postCalcNetto",
        title: "Utókalkulációs tételek",
        cell: "postCalcNettoTemplate",
        headerClassName: "right_text header_text",
      },
      {
        title: "Utókalkulációban nem szereplő egyenleg",
        cell: "diffTemplate",
        headerClassName: "right_text header_text",
      },
      { width: 80, cell: "commandTemplate" },
      { width: 0 },
    ],
  },

  methods: {
    showDetails() {
      console.log(this.postCalcInfoVisible);

      this.postCalcInfoVisible = !this.postCalcInfoVisible;
    },

    setSelected(item) {
      item.isSelected = !item.isSelected;
    },

    toDate(item: string) {
      return dayjs(item)
        .format("YYYY-MM-DD")
        .replaceAll("-", ".");
    },

    async searchPostCalcsByCarWithParams(propCarId: number) {
      if (propCarId > 1) {
        const response = await api.searchPostCalcsByCar(propCarId);
        console.log(response);

        this.bevetelResze = response.bevetelResze;
        this.bevetelNemResze = response.bevetelNemResze;
        this.koltsegResze = response.koltsegResze;
        this.koltsegNemResze = response.koltsegNemResze;
        this.carDetails = response.carDetails;
      }
    },

    async searchPostCalcsByCar() {
      if (this.carId > 1) {
        const response = await api.searchPostCalcsByCar(this.carId);
        console.log(response);

        this.bevetelResze = response.bevetelResze;
        this.bevetelNemResze = response.bevetelNemResze;
        this.koltsegResze = response.koltsegResze;
        this.koltsegNemResze = response.koltsegNemResze;
        this.carDetails = response.carDetails;
      }
    },
  },
});
