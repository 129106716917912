






import Vue from "vue";
import FlowAccountingPage from "./FlowAccountingPage.vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import permStore, { PermissionStore } from "@/services/permissions";

interface Props {
  partner: number | null;
}

interface Data {
  store: InvoiceFlowStore;
  permStore: PermissionStore;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    partner: { type: Number, default: null },
  },

  data: () => ({
    store,
    permStore,
  }),

  computed: {
    loaded() {
      return this.store.invoice?.id ?? 0 > 0;
    },
  },

  components: {
    FlowAccountingPage,
  },

  async beforeMount() {
    const invoiceId = Number(this.$route.params.invoiceId) || 0;
    console.log(invoiceId);
    await this.store.loadInvoice(invoiceId);
    let partnerId = 1;

    if (this.partner === null) partnerId = this.store.invoice.partner;
    else partnerId = this.partner;

    console.log(this.partner)

    const queries = [
      this.store.loadInvoiceDetails(partnerId),
      this.store.loadInvoiceDisplay(invoiceId),
      this.store.loadFlowStatusRelations(invoiceId),
      this.store.loadApprovalMatrix(),
      this.store.loadCurrencies(),
      this.store.loadAllTaxes(),
      this.store.loadSpecialTaxTypes(),
      this.store.loadPaymentModes(),
      this.permStore.getFlowPermissions(invoiceId),
    ];

    await Promise.all(queries);
    await this.store.loadAcceptState(invoiceId);

    if (!this.store.specTaxes.some((x) => x.id == 1)) {
      this.store.specTaxes = [...this.store.specTaxes];
    }

    this.store.setSpecTax(true);
    this.store.setSelectedCurrency();
    this.store.setIsEditingEnabled();

    await this.store.flowUsers.load();
    await this.store.loadUsersWithTechRecord();
  },
});
