






















































































































































































































































































































import Vue from "vue";
//import PartnerSearch from "./PartnerSearch.vue";
import store, { PartnerStore } from "../services/PartnerStore";
import Partner from "../models/Partner";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import configuration, { AppConfiguration } from "../../../models/Configuration";
import { debounce } from "debounce";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";

interface Data {
  store: PartnerStore;
  configuration: AppConfiguration;
  currentPageSize: number;
  currentPageNumber: number;
  filter: string;
  toggle: boolean;
  firstPageNumberButtonVisible: boolean;
  firstPageNumberButtonValue: number;
  secondPageNumberButtonVisible: boolean;
  secondPageNumberButtonValue: number;
  thirdPageNumberButtonVisible: boolean;
  thirdPageNumberButtonValue: number;
  fourthPageNumberButtonVisible: boolean;
  fourthPageNumberButtonValue: number;
  fifthPageNumberButtonVisible: boolean;
  fifthPageNumberButtonValue: number;

  sort: SortDescriptor[];
  filter2: CompositeFilterDescriptor;
  columns: any;
  partnerStatuses: any[];
  selectedPartnerStatus: string;
}

interface Methods {
  searchHandler(): void;
  setCurrentPageNumber(pageNumber: number): void;
  setCurrentPageSize(pageSize: number): void;
  decrementPageNumber(): void;
  incrementPageNumber(): void;
  setPageNumbers(): void;
  toggleList(): void;
  toggleCards(): void;
  load(pageNumber: number, setPageNumbers?: boolean): void;
  handleEdit(item: Partner): void;
  partnerCreate(): void;

  filterChange(e: { filter: CompositeFilterDescriptor }): void;
  sortChange(e: { sort: SortDescriptor[] }): void;
  columnReorder(options: any): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: { ApiSearchPage },

  data(): Data {
    return {
      partnerStatuses: [
        {
          id: 0,
          name: "Összes",
        },
        {
          id: 1,
          name: "Aktív, létező",
        },
        {
          id: 2,
          name: "Téves rögzítés",
        },
      ],
      selectedPartnerStatus: "Összes",
      toggle: false,
      store,
      configuration,
      currentPageSize: 10,
      currentPageNumber: 0,
      filter: "",
      firstPageNumberButtonVisible: true,
      firstPageNumberButtonValue: 1,
      secondPageNumberButtonVisible: true,
      secondPageNumberButtonValue: 2,
      thirdPageNumberButtonVisible: true,
      thirdPageNumberButtonValue: 3,
      fourthPageNumberButtonVisible: true,
      fourthPageNumberButtonValue: 4,
      fifthPageNumberButtonVisible: true,
      fifthPageNumberButtonValue: 5,

      sort: [],
      filter2: {
        logic: "and",
        filters: [],
      },

      columns: [
        {
          field: "name",
          title: "Ügyfél neve",
          cell: "partnerNameTemplate",
        },
        { field: "invoiceCity", title: "Város", className: "center_text" },
        { field: "invoiceAddress", title: "Cím", className: "center_text" },
        { field: "taxAccount", title: "Adószám", className: "center_text" },
        {
          field: "taxAccGroup",
          title: "Csoportos adószám",
          className: "center_text",
        },
        { field: "phoneNumber", title: "Telefon", className: "center_text" },
        { field: "email", title: "Email", className: "center_text" },
        // { field: "userId", title: "Kapcsolattartó", className: "center_text" },
        {
          field: "companyForm",
          title: "Vállalkozási forma",
          className: "center_text",
        },
        { field: "partnerType", title: "Típus", className: "center_text" },
        {
          field: "insDate",
          title: "Rögzítés dátuma",
          className: "center_text",
        },
        { field: "id", title: "Partner ID", className: "center_text" },
      ],
    };
  },

  async beforeMount() {
    window.scrollTo(0, 0);

    await this.store.loadGeneralPartners(1, this.currentPageSize, false);
    this.currentPageNumber = 1;
  },

  created() {
    this.load = debounce(this.load, 400);
  },

  computed: {
    items() {
      if (this.selectedPartnerStatus == "Összes") {
        return orderBy(filterBy(this.store.partners, this.filter2), this.sort);
      }
      return orderBy(filterBy(this.store.partners.filter((x) => x.status == this.selectedPartnerStatus), this.filter2), this.sort);
    },

    maxPageNumber() {
      return Math.ceil(this.store.partnersCount / this.currentPageSize);
    },

    footerText() {
      return `${this.store.partnersCount} / ${Math.max(
        (this.currentPageNumber - 1) * this.currentPageSize + 1,
        0
      )} - ${Math.min(this.currentPageNumber * this.currentPageSize, this.store.partnersCount)}`;
    },
  },

  watch: {
    currentPageNumber() {
      this.setPageNumbers();
    },
  },

  methods: {
    columnReorder(options) {
      this.columns = options.columns;
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      this.filter2 = e.filter;
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    },

    partnerCreate() {
      this.$router.push("/partners/create");
    },

    handleEdit(partner: Partner) {
      this.$router.push(`/partners/${partner.id}`);
    },

    toggleList() {
      this.toggle = false;
    },

    toggleCards() {
      this.toggle = true;
    },

    load(pageNumber) {
      if (this.filter) {
        this.store.loadGeneralPartnersByName(this.filter, pageNumber, this.currentPageSize, false).then(() => {
          this.currentPageNumber = pageNumber;
        });
      } else {
        this.store.loadGeneralPartners(pageNumber, this.currentPageSize, false).then(() => {
          this.currentPageNumber = pageNumber;
        });
      }
    },

    searchHandler() {
      this.load(1);
    },

    setCurrentPageSize(pageSize: number) {
      this.currentPageSize = pageSize;
      this.load(1);
    },

    setCurrentPageNumber(pageNumber: number) {
      this.currentPageNumber = pageNumber;
      this.load(this.currentPageNumber);
    },

    decrementPageNumber() {
      if (this.currentPageNumber == 1) return;
      this.currentPageNumber--;
      this.load(this.currentPageNumber);
    },

    incrementPageNumber() {
      const maxPageNumber = Math.ceil(this.store.partnersCount / this.currentPageSize);
      if (this.currentPageNumber == maxPageNumber) return;
      this.currentPageNumber++;
      this.load(this.currentPageNumber);
    },

    setPageNumbers() {
      if (this.currentPageNumber == 0) return;

      const maxPageNumber = Math.ceil(this.store.partnersCount / this.currentPageSize);
      if (maxPageNumber <= 5) {
        this.firstPageNumberButtonVisible = false;
        this.secondPageNumberButtonVisible = false;
        this.thirdPageNumberButtonVisible = false;
        this.fourthPageNumberButtonVisible = false;
        this.fifthPageNumberButtonVisible = false;

        if (maxPageNumber >= 1) {
          this.firstPageNumberButtonVisible = true;
          this.firstPageNumberButtonValue = 1;
        }

        if (maxPageNumber >= 2) {
          this.secondPageNumberButtonVisible = true;
          this.secondPageNumberButtonValue = 2;
        }

        if (maxPageNumber >= 3) {
          this.thirdPageNumberButtonVisible = true;
          this.thirdPageNumberButtonValue = 3;
        }

        if (maxPageNumber >= 4) {
          this.fourthPageNumberButtonVisible = true;
          this.fourthPageNumberButtonValue = 4;
        }

        if (maxPageNumber == 5) {
          this.fifthPageNumberButtonVisible = true;
          this.fifthPageNumberButtonValue = 5;
        }
      } else {
        this.firstPageNumberButtonVisible = true;
        this.secondPageNumberButtonVisible = true;
        this.thirdPageNumberButtonVisible = true;
        this.fourthPageNumberButtonVisible = true;
        this.fifthPageNumberButtonVisible = true;

        if (maxPageNumber - this.currentPageNumber < 5) {
          this.firstPageNumberButtonValue = maxPageNumber - 4;
          this.secondPageNumberButtonValue = maxPageNumber - 3;
          this.thirdPageNumberButtonValue = maxPageNumber - 2;
          this.fourthPageNumberButtonValue = maxPageNumber - 1;
          this.fifthPageNumberButtonValue = maxPageNumber;
        } else {
          if (this.currentPageNumber < 5) {
            this.firstPageNumberButtonValue = 1;
            this.secondPageNumberButtonValue = 2;
            this.thirdPageNumberButtonValue = 3;
            this.fourthPageNumberButtonValue = 4;
            this.fifthPageNumberButtonValue = 5;
          } else {
            this.firstPageNumberButtonValue = this.currentPageNumber - 2;
            this.secondPageNumberButtonValue = this.currentPageNumber - 1;
            this.thirdPageNumberButtonValue = this.currentPageNumber;
            this.fourthPageNumberButtonValue = this.currentPageNumber + 1;
            this.fifthPageNumberButtonValue = this.currentPageNumber + 2;
          }
        }
      }
    },
  },
});
