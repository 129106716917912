var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-form',{ref:"headForm",attrs:{"disabled":_vm.store.disabledMode}},[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_c('v-card',{staticClass:"mb-5",staticStyle:{"width":"100%"}},[_c('v-card-title',[_c('v-row',[_c('v-col',[_vm._v("Hatósági csoportos csekk")])],1)],1),_c('v-card-text',[_c('v-layout',[_c('v-flex',[_c('v-row',[_c('v-col',{staticClass:"input-column",attrs:{"sm":"2","align":"right"}},[_c('label',{staticClass:"input-label"},[_vm._v("Számla dátuma")])]),_c('v-col',{staticClass:"input-column",attrs:{"sm":"2"}},[_c('date-input',{attrs:{"min":_vm.store.minDateString},model:{value:(_vm.tInvoice.invDate),callback:function ($$v) {_vm.$set(_vm.tInvoice, "invDate", $$v)},expression:"tInvoice.invDate"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"input-column",attrs:{"sm":"2","align":"right"}},[_c('label',{staticClass:"input-label"},[_vm._v("Megjegyzés")])]),_c('v-col',{staticClass:"input-column",attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":""},model:{value:(_vm.tInvoice.remark),callback:function ($$v) {_vm.$set(_vm.tInvoice, "remark", $$v)},expression:"tInvoice.remark"}})],1)],1),_c('div',{staticClass:"rounded-sm d-flex align-center px-5 py-1 white--text primary lighten-1"},[_c('h5',{staticClass:"group-title flex"},[_vm._v("Tételek")]),_c('div',{staticClass:"mr-5 group-summary d-flex"},[_c('span',{staticClass:"mr-1"},[_vm._v("Összesen:")]),_c('money-display',{attrs:{"value":_vm.tInvoice.total,"currency":_vm.tInvoice.currency}})],1)]),_c('v-row',[_c('grid',{staticClass:"items-grid",attrs:{"data-items":_vm.store.tInvoiceVIRIItems,"columns":_vm.itemColumns,"filterable":false,"filter":_vm.filter,"sortable":false,"sort":_vm.sort},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className,staticStyle:{"display":"flex","align-items":"start","justify-content":"start"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.removeItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Tétel törlése")])])],1)]}},{key:"partner",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('partner-search',_vm._b({staticStyle:{"margin-top":"-13px"},attrs:{"partnerId":props.dataItem.partner,"dense":""},on:{"update:partnerId":function($event){return _vm.$set(props.dataItem, "partner", $event)},"update:partner-id":function($event){return _vm.$set(props.dataItem, "partner", $event)}}},'partner-search',_vm.$attrs,false))],1)]}},{key:"car",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('car-search',{staticStyle:{"margin-top":"-13px"},attrs:{"carId":props.dataItem.car,"dense":""},on:{"update:carId":function($event){return _vm.$set(props.dataItem, "car", $event)},"update:car-id":function($event){return _vm.$set(props.dataItem, "car", $event)}}})],1)]}},{key:"checkTypes",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-select',{attrs:{"single-line":"","dense":"","outlined":"","hide-details":"auto","item-text":"name","item-value":"id","items":_vm.store.checkTypes},model:{value:(props.dataItem.checkType),callback:function ($$v) {_vm.$set(props.dataItem, "checkType", $$v)},expression:"props.dataItem.checkType"}})],1)]}},{key:"pMode",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-select',{attrs:{"single-line":"","dense":"","hide-details":"auto","outlined":"","items":_vm.store.pmodes,"item-text":"name","item-value":"id"},model:{value:(props.dataItem.pmode),callback:function ($$v) {_vm.$set(props.dataItem, "pmode", $$v)},expression:"props.dataItem.pmode"}})],1)]}},{key:"checkId",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('text-input',{model:{value:(props.dataItem.checkId),callback:function ($$v) {_vm.$set(props.dataItem, "checkId", $$v)},expression:"props.dataItem.checkId"}})],1)]}},{key:"total",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className,staticStyle:{"display":"flex","align-items":"start","justify-content":"start"}},[_c('MoneyInput',{attrs:{"currency":_vm.tInvoice.currency},model:{value:(props.dataItem.total),callback:function ($$v) {_vm.$set(props.dataItem, "total", _vm._n($$v))},expression:"props.dataItem.total"}})],1)]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-spacer'),_c('v-btn',{staticClass:"whiteBtn margin-right-5",attrs:{"outlined":""},on:{"click":_vm.createItem}},[_vm._v("Új tétel")]),_c('v-btn',{staticClass:"margin-right-5",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.saveHead}},[_vm._v("Mentés")]),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.navigateBack}},[_vm._v("Bezárás")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }