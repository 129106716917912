var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[_c('v-card',[_c('v-card-title',[_c('v-col',[_vm._v(" Szerepkörökhöz rendelt alkalmazás funkciók ")])],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.store.roles,"columns":_vm.columns,"detail":"deletingTemplate","expand-field":'deleting'},scopedSlots:_vm._u([{key:"featureTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-select',{staticClass:"remove-bottom",attrs:{"hide-details":"auto","items":_vm.store.features,"small-chips":"","item-text":"name","multiple":""},on:{"change":function($event){return _vm.saveFeatures(props.dataItem, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"ma-2"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true),model:{value:(props.dataItem.features),callback:function ($$v) {_vm.$set(props.dataItem, "features", $$v)},expression:"props.dataItem.features"}})],1)]}},{key:"commandTemplate",fn:function(){return [_c('td')]},proxy:true}])})],1)],1),(_vm.permStore.isSupervisor)?_c('DeniedUserFeatureRelations'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }