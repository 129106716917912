var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.invoice.acceptNo != '')?_c('div',{staticClass:"rounded-sm d-flex align-center mx-5 px-5 py-1 white--text primary lighten-1 badge"},[_c('strong',[_vm._v(" Jóváhagyási megjegyzés:")]),_vm._v(" "+_vm._s(_vm.invoice.acceptNo)+" ")]):_vm._e(),_c('v-row',{class:{ 'mt-3': _vm.invoice.acceptNo != '' }},[_c('v-col',[_c('v-row',[_c('v-col',[_c('h5',{staticClass:"section-title pb-4"},[_c('v-icon',{staticClass:"mx-1",staticStyle:{"vertical-align":"baseline"},attrs:{"small":"","color":"black"}},[_vm._v(" fa-user-plus ")]),_vm._v(" Ellenőrzők ")],1)])],1),_c('v-row',[_c('v-col',[_c('grid',{attrs:{"scrollable":"none","data-items":_vm.controllerRequests,"columns":[
              { field: 'name', title: 'Név', className: 'center_text' },
              { field: 'statusName', title: 'Státusz', className: 'center_text' },
              { field: 'remark', title: 'Megjegyzés', className: 'center_text' },
              {
                field: 'modDateTime',
                title: 'Módosítás dátuma',
                type: 'date',
                format: '{0: yyyy. MM. dd. HH:mm:ss}',
                className: 'center_text',
              } ]}})],1)],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[_c('v-row',[_c('v-col',[_c('h5',{staticClass:"section-title pb-4"},[_c('v-icon',{staticClass:"mx-1",staticStyle:{"vertical-align":"baseline"},attrs:{"small":"","color":"black"}},[_vm._v(" fa-user-plus ")]),_vm._v(" Jóváhagyók ")],1)])],1),_c('v-row',[_c('v-col',[_c('grid',{attrs:{"scrollable":"none","data-items":_vm.acceptorRequests,"columns":[
              { field: 'name', title: 'Név', className: 'center_text' },
              { field: 'statusName', title: 'Státusz', className: 'center_text' },
              { field: 'remark', title: 'Megjegyzés', className: 'center_text' },
              {
                field: 'modDateTime',
                title: 'Módosítás dátuma',
                type: 'date',
                format: '{0: yyyy. MM. dd. HH:mm:ss}',
                className: 'center_text',
              } ]}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }