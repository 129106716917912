







































import Vue from "vue";
import pdf from "vue-pdf";
import IconButton from "./IconButton.vue";

const base64Arraybuffer = async (data: Uint8Array) => {
    // Use a FileReader to generate a base64 data URI
    const base64url = await new Promise<string>((r) => {
        const reader = new FileReader()
        reader.onload = () => r(reader.result?.toString()??"")
        reader.readAsDataURL(new Blob([data]))
    })

    /*
    The result looks like 
    "data:application/octet-stream;base64,<your base64 data>", 
    so we split off the beginning:
    */
    return base64url.substring(base64url.indexOf(',')+1)
}

export default Vue.extend({
  props: {
    src: Uint8Array,
    openOnNewPageButton: { type: Boolean, default: true },
    showExitButton: { type: Boolean, default: true },
  },

  components: {
    pdf,
    IconButton,
  },

  data() {
    return {
      currentPage: 1,
      pageCount: 1,
    };
  },

  methods: {
    async openOnNewPage() {
      const b64encoded = await base64Arraybuffer(this.src)

      const pdfWindow = window.open("");
      if (pdfWindow) {
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(b64encoded) +
            "'></iframe>"
        );
      }
    },

    nextPage() {
      if (this.currentPage == this.pageCount) return;
      this.currentPage = this.currentPage + 1;
    },
    prevPage() {
      if (this.currentPage < 2) return;
      this.currentPage = this.currentPage - 1;
    },
    exitPdf() {
      this.$emit("exit");
    },
  },
});
