











import Vue from "vue";
import AppMenu from "./AppMenu.vue";
import VersionInfo from "./VersionInfo.vue";
import configuration from "@/models/Configuration";

export default Vue.extend({
  components: { AppMenu, VersionInfo },

  data: () => ({
    configuration
  }),

  methods: {
    navigate(to: string) {
      if (this.$route.path != to) {
        this.$router.push(to);
      }
    },
  },
});
