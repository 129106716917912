import cuid from "cuid";
import { ValidationRules } from "@/models/Validation";
import PartBank from "@/modules/cashPoint/models/PartBank";
import PaymentModeDisplay from "@/modules/paymentMode/models/PaymentModeDisplay";
import { formatDateISO, newDate } from "@/utils/formatDates";
import User from "@/modules/invoiceGroup/models/User";

const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);

export enum PaymentScheduleOption {
  Custom = "Egyedi",
  Monthly = "Havi",
  Quarterly = "Negyedéves",
}

export class PaymentSchedule {
  id = 0;
  invoiceNo = 1;
  currency = "HUF";
  responsibleUserID = 1;
  items: PaymentScheduleItem[] = [];
  selectedResponsibleUser: User | undefined = undefined;

  constructor(params?: Partial<PaymentSchedule>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

export class PaymentScheduleItem {
  id = 0;
  cuid = cuid();
  amount = 0;
  deadline = newDate(today);
  bankAccount = 1;
  paymentMode = 1;

  deadlineDisplay = formatDateISO(today);
  selectedPaymentMode: PaymentModeDisplay | undefined = undefined;
  selectedBankAccount: PartBank | undefined = undefined;
  accountNoSplit = "";

  constructor(params?: Partial<PaymentScheduleItem>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

// validation
export const rules: ValidationRules = {
  date: [(v: string) => !!v || "Hibás dátum"],
  amount: [(v: number) => !!v || "Kötelező megadni", (v: number) => v > 0 || "Az összeg nem lehet nulla vagy negatív"],
  deadline: [(v: string) => !!v || "Hibás dátum", (v: string) => new Date(v) > today || "Múltbeli fizetési határidő"],
  bankAccount: [(v: PartBank) => !!v || "Hibás számla megadás"],
  paymentMode: [(v: PaymentModeDisplay) => !!v || "Fizetési mód megadása kötelező"],
};
