import { ValidationRules } from "@/models/Validation"

export default interface OutgoingInvoiceTypeEditModel {   
    id: number;
    name: string;
    foreign: string;
    english: string;
    default: boolean;
    isCar: boolean;
    isCarItem: boolean;
    code: string;
    isMoreGparts: boolean;
    detailedType: string;
    isNoGparts: boolean;
    isFixAssets: boolean;
    isStockFin: boolean;
    isImsCustReg: boolean;
    isDistrib: boolean;
    isPrintOwnerRun: boolean;
    technVatAccCode: string;
    extInvType: string;
    extSubsystem: number;
    isIntr: boolean;
    isManual: boolean;
    isContract: boolean;
        
    partnerRelations: [
        {
            partnerRelationID: number;
            partnerRelationName: string;
            selected: boolean;
        }
    ];    
    deleting: boolean;
}

export const outgoingInvoiceTypeEditModelDefaultProps: OutgoingInvoiceTypeEditModel = {
    
    id: 0,
    name: '',
    foreign:'',
    english:'',
    default: false,
    isCar: false,
    isCarItem: false,
    code:'',
    isMoreGparts: false,
    detailedType: '--',
    isNoGparts: false,
    isFixAssets: false,
    isStockFin: false,
    isImsCustReg: false,
    isDistrib: false,
    isPrintOwnerRun: false,
    technVatAccCode:'',
    extInvType:'',
    extSubsystem: 0,
    isIntr: false,
    isManual: false,
    isContract: false,
        
    partnerRelations: [
        {
            partnerRelationID: 0,
            partnerRelationName: '',
            selected: false,
        }        
    ],
    deleting: false,
}