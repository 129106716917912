import SerStation, {serStationDefaultProps} from "../models/SerStation";
import SerStationApi from "./SerStationApi";

export class SerStationStore{
    serStations: SerStation[]=[]
    serStation: SerStation = {...serStationDefaultProps}

    async loadSerStations(){
        this.serStations = await SerStationApi.getAll();
    }
}

const store = new SerStationStore()
export default store