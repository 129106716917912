



















































































































import Page from "@/components/Page.vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import Vue from "vue";
import CashStatementAccountingItem from "../models/CashStatementAccountingItem";
import CashStatementAccountingSummary from "../../bankStatement/views/StatementAccountingSummary.vue";
import store, { CashStatementStore } from "../services/store";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import configuration, { loadConfiguration } from "@/models/Configuration";
import type { AppConfiguration } from "@/models/Configuration";
import dayjs from "dayjs";
import eventBus from "@/services/eventBus";
import CashStatementApi from "../services/api";
import AccItemError from "../models/AccItemError";

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
  hasError: boolean;
}

interface Data {
  items: CashStatementAccountingItem[];
  store: CashStatementStore;
  configuration: AppConfiguration;
  errorItems: AccItemError[];
  showErrorList: boolean;
}

interface Method {
  setItems(items: CashStatementAccountingItem[]): void;
  getAccName(code: string): string;
  getPrecision(): number;
  getBookingDate(item: CashStatementAccountingItem): string;
  rekontir(): void;
  sendAccounting(): void;
  refreshApiSearch(): void;
}

export default Vue.extend<Data, Method, Computed, {}>({
  components: {
    Page,
    ApiSearchPage,
    CashStatementAccountingSummary,
    MoneyDisplay
  },

  data: () => ({
    items: [],
    store,
    configuration,
    errorItems: [],
    showErrorList: false,
  }),

  methods: {
    async rekontir(){
      const res = await this.store.rekontir();
      if(res){
        eventBus.$emit("succes", "Sikeresen újrakontírozva!");
        this.refreshApiSearch();
      }
      else eventBus.$emit("error", "Nem sikerült újrakontírozni!");
    },

    setItems(items: CashStatementAccountingItem[]) {
      this.items = items;
    },

    getBookingDate(item){
        return dayjs(item.accDate) > dayjs('1990-01-01') ? dayjs(item.accDate).format('YYYY. MM. DD') : "-";
    },

    getAccName(code: string){
        return this.store.accountingCodes.find(x=>x.code == code)?.accName??"-";
    },

    getPrecision(){
        const res = this.configuration.operel
            ? this.store.cashStatement.currency != "HUF"
                ? 2
                : 0
            : this.configuration.getCurrDigits(this.store.cashStatement.currency)?.geniHeadDigits??2;
        return res;
    },

    sendAccounting(){
      CashStatementApi.sendToAccounting(Number(this.$route.params.cashStatementId)).then(x => {
        eventBus.$emit("succes", "Sikeresen főkönyvbe küldve!");
        this.store.loadCashStatement(Number(this.$route.params.cashStatementId));
      });
    },

    refreshApiSearch() {
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      { field: "type", title: "" },
      { field: "owe", title: "Tartozik", fieldClass: "oweCell" },
      {
        field: "oweAmount",
        title: "Összeg",
        cell: "oweAmount",
        fieldClass: "oweCell",
        type: ColumnType.Money,
      },
      {
        field: "demands",
        title: "Követel",
        cell: "demands",
        fieldClass: "demandsCell",
      },
      {
        field: "demandedAmount",
        title: "Összeg",
        cell: "demandedAmount",
        fieldClass: "demandsCell",
        type: ColumnType.Money,
      },
      {
        field: "accDate",
        title: "Könyvelés dátuma",
        cell: "accDate"
      },
      { field: "invNo", title: "Számla", cell: "invNo" },
    ],

    url() {
      return `cash-statement/${this.$route.params.cashStatementId}/account-items`;
    },

    hasError(){
      return this.errorItems.length > 0;
    }
  },

  mounted() {
    this.store.loadAccountingCodes();

    if(!this.configuration.isLoaded){
        loadConfiguration();
    }
  },

  watch: {
    items: function() {
      CashStatementApi.getAccItemErrors(Number(this.$route.params.cashStatementId)).then(x => {
        this.errorItems = x;
      })
    }
  },
});
