










































































































import MoneyInput from "@/components/MoneyInput.vue";
import Fragment from "@/components/Fragment.vue";
import IconButton from "@/components/IconButton.vue";

import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";
import { DownPayment, DownPaymentExtOrder, DownPaymentExtOrderItem, PreDownPayment } from "../models";
import { roundMoney } from "@/services/prices";
import configuration, { AppConfiguration } from "@/models/Configuration";


interface Data {
  store: DownPaymentStore;

  configuration: AppConfiguration;
}

export default Vue.extend({
  components: { Fragment, IconButton, MoneyInput },

  props: {
    items: { type: Array as () => DownPaymentExtOrderItem[] },
    edit: { type: Boolean, default: false },
  },

  data: (): Data => ({
    store,

    configuration
  }),

  computed: {
    hasData() {
      return this.items && this.items.length > 0;
    },
  },

  methods: {
    findPreDownPaymentElement(e: DownPaymentExtOrder) {
      return this.store.selectedPreDownPayments.find((x) => x.orderItems.find((y) => y.id == e.id));
    },

    select(item: DownPaymentExtOrderItem): void {
      if (item.hasDownPayment) return;

      const order = this.store.extOrders.find((e) => e.orderItems.some((i) => i == item)) || null;

      const orderItems =
        this.store.preDownPayment?.order?.id == item.orderID && this.store.preDownPayment.type != 2
          ? this.store.preDownPayment.orderItems.filter((x) => x.id !== item.id) ?? []
          : [];

      this.store.preDownPayment = new PreDownPayment({
        order: order,
        productName: item.productName,
        orderName: item.orderNumber,
        currency: item.currency,
        orderItems: [...orderItems, item],
        type: orderItems.length > 0 ? 3 : 1,
        netto: order?.amount ?? 0,
      });

      this.store.preDownPayment.amount =
        this.store.preDownPayment.orderItems.reduce((acc, curr) => acc + curr.finalAmount, 0) ?? item.finalAmount;

      this.store.downPayment = new DownPayment({
        ...this.store.downPayment,
        netto: this.store.preDownPayment.amount,
        currency: item.currency,
      });

      if (order) {
        const items =
          this.store.preDownPayment.order?.id == item.orderID &&
          this.store.extOrder &&
          this.store.extOrder.id == item.orderID
            ? this.store.extOrder.orderItems.filter((i) => i.id !== item.id)
            : [];

        this.store.extOrder = { ...order, orderItems: [...items, item] };
      }

      this.store.bruttoChanged();
    },

    remove(item: DownPaymentExtOrderItem): void {
      if (!this.store.preDownPayment?.orderItems || !this.store.extOrder?.orderItems) return;

      this.store.preDownPayment.orderItems = this.store.preDownPayment.orderItems.filter((x) => x.id != item.id);
      this.store.preDownPayment.type = this.store.preDownPayment.orderItems.length > 1 ? 3 : 1;
      this.store.extOrder.orderItems = this.store.extOrder.orderItems.filter((x) => x.id != item.id);

      this.store.downPayment.netto = this.store.preDownPayment.amount = this.items.reduce(
        (acc, curr) => acc + curr.finalAmount,
        0
      );
      if (this.edit) this.store.bruttoChanged();
    },

    amountChanged(item: DownPaymentExtOrderItem): void {
      item.percent = roundMoney((item.finalAmount / item.amount) * 100, item.currency, 2);
      if (!this.edit || !this.store.preDownPayment) return;

      this.store.downPayment.netto = this.store.preDownPayment.amount = this.items.reduce(
        (acc, curr) => acc + curr.finalAmount,
        0
      );
      if (this.edit) this.store.bruttoChanged();
    },

    percentChanged(item: DownPaymentExtOrderItem): void {
      item.finalAmount = roundMoney(item.amount * (item.percent / 100), item.currency);
      if (!this.edit || !this.store.preDownPayment) return;

      this.store.downPayment.netto = this.store.preDownPayment.amount = this.items.reduce(
        (acc, curr) => acc + curr.finalAmount,
        0
      );
      if (this.edit) this.store.bruttoChanged();
    },

    isAdded(item: DownPaymentExtOrderItem): boolean {
      return this.store.preDownPayment?.orderItems.some((x) => x.id == item.id) ?? false;
    },
  },
});
