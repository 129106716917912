











































































































































































import Vue from 'vue';
import ApiSearchPage from '@/components/apiSearch/components/ApiSearchPage.vue';
import MoneyDisplay from '@/components/MoneyDisplay.vue';
import Tabs from "../../../components/Tabs.vue";
import eventBus from '@/services/eventBus';
import http, { handleApiResponse } from '@/services/http';
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';

type TransactionItem = any;
type TransactionItemDimension = any;

interface Data {
  url: string;
  unfilteredDimensions: TransactionItemDimension[];
}

interface Methods {
  preSetItems(e: any): void;
  setItems(e: string): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  props: {
    transactionItem: { type: Object as () => TransactionItem }
  },

  components: {
    ApiSearchPage,
    Tabs,
    MoneyDisplay
  }, 

  data() {
    return {
      url: "",
      unfilteredDimensions: [],
    }
  },

  async beforeMount() {
    this.url = `ledger/transactions/items/${this.$props.transactionItem.id}/dimension`;
    const apiSearch = {
      pageSize: 100,
      filter: null,
      extraFilter: null,
      sort: null,
      searchText: "",
    }
    
    this.unfilteredDimensions = ((await http.post(this.url, apiSearch).then(handleApiResponse)) as any).currentPage;
    console.log(this.unfilteredDimensions);
    this.setItems("dim4");
  },

  methods: {
    setItems(e: string) {
      console.log(e);
      if (e == "dim4") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions.filter((x) => x.dimCode > 1);
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          });
        }, 10);
      }
      else if (e == "dim5") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions.filter((x) => x.extProdParts > 1);
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          });
        }, 10);
      }
      else if (e == "dim6") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions.filter((x) => x.stock > 1 || x.production > 1 || x.parts > 1);
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          })
        }, 10);
      }
      else if (e == "dim7") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions.filter((x) => x.carId > 1);
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          })
        }, 10);
      }
      else if (e == "dim8") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions.filter((x) => x.stationId > 1 || x.toolId > 1 || x.userId > 1);
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          })
        }, 10);
      }
      else if (e == "dim9") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions.filter((x) => x.project > 1);
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          })
        }, 10);
      }
      else if (e == "dim10") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions.filter((x) => x.extProdPartner > 1);
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          })
        }, 10);
      }
      else if (e == "dim11") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions;
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          })
        }, 10);
      }
      else if (e == "dim12") {
        setTimeout(() => {
          const filteredDimensions = this.unfilteredDimensions;
          this.$nextTick(() => {
            eventBus.$emit(`ledger-transaction-item-${e}:set-items`, filteredDimensions);
          })
        }, 10);
      }
      else {
        return;
      }
    },

    preSetItems(e: any) {
      this.setItems(e.target.classList[0]);
    }
  },

  computed: {
    dim4columns: () => [
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "dimCodeName",
        title: "Dimenzió kód"
      },
      {
        field: "total",
        title: "Összeg",
        filterable: false,
        cell: "totalTemplate",
        type: ColumnType.Right,
        headerClass: "text-right",
      },
    ],

    dim5columns: () => [
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "dimCodeName",
        title: "Dimenzió kód"
      },
      {
        field: "extProdParts",
        title: "Cikk ID"
      },
      {
        field: "extProdPartsName",
        title: "Cikkszám"
      },
      {
        field: "total",
        title: "Összeg",
        filterable: false,
        cell: "totalTemplate",
        type: ColumnType.Right,
        headerClass: "text-right",
      },
    ],

    dim6columns: () => [
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "dimCodeName",
        title: "Dimenzió kód"
      },
      {
        field: "parts",
        title: "Gyártott termék"
      },
      {
        field: "partsCode",
        title: "Cikkszám"
      },
      {
        field: "partsName",
        title: "Gyári alkatrész"
      },
      {
        field: "stock",
        title: "Készlet ID"
      },
      {
        field: "total",
        title: "Összeg",
        filterable: false,
        cell: "totalTemplate",
        type: ColumnType.Right,
        headerClass: "text-right",
      },
    ],

    dim7columns: () => [
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "dimCodeName",
        title: "Dimenzió kód"
      },
      {
        field: "carId",
        title: "Gépjármű"
      },
      {
        field: "regNum",
        title: "Rendszám"
      },
      {
        field: "total",
        title: "Összeg",
        filterable: false,
        cell: "totalTemplate",
        type: ColumnType.Right,
        headerClass: "text-right",
      },
    ],

    dim8columns: () => [
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "dimCodeName",
        title: "Dimenzió kód"
      },
      {
        field: "userName",
        title: "Munkatárs"
      },
      {
        field: "stationName",
        title: "Munkaállomás"
      },
      {
        field: "toolName",
        title: "Szerszám"
      },
      {
        field: "total",
        title: "Összeg",
        filterable: false,
        cell: "totalTemplate",
        type: ColumnType.Right,
        headerClass: "text-right",
      },
    ],

    dim9columns: () => [
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "dimCodeName",
        title: "Dimenzió kód"
      },
      {
        field: "project",
        title: "Projekt ID"
      },
      {
        field: "projectName",
        title: "Név"
      },
      {
        field: "projectMainCode",
        title: "Belső ID"
      }, 
      {
        field: "projectCode",
        title: "Külső ID"
      },  
      {
        field: "total",
        title: "Összeg",
        filterable: false,
        cell: "totalTemplate",
        type: ColumnType.Right,
        headerClass: "text-right",
      },
    ],

    dim10columns: () => [
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "dimCodeName",
        title: "Dimenzió kód"
      },
      {
        field: "extProdPartner",
        title: "Kiegészító partner ID"
      },
      {
        field: "extProdPartnerName",
        title: "Partner név"
      }, 
      {
        field: "total",
        title: "Összeg",
        filterable: false,
        cell: "totalTemplate",
        type: ColumnType.Right,
        headerClass: "text-right",
      },
    ],
  },
})
