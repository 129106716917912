




















































































































































































































































































































































































































































































































































































import Vue from "vue";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import store, { BankStatementStore } from "../services/BankStatementStore";
import pStore, { PartnerStore } from "../../partner/services/PartnerStore";
import BankDocument from "../models/BankDocument";
import Partner from "../../partner/models/Partner";
import DateInput from "@/components/DateInput.vue";
import dayjs from "dayjs";
import auth from "@/services/auth";
import MoneyInput from "@/components/MoneyInput.vue";
import SortingView from "./SortingView.vue";
import bIStore, {
  BankInViewStore,
} from "@/modules/bankInView/services/BankInViewStore";
import bOStore, {
  BankOutViewStore,
} from "@/modules/bankOutView/services/BankOutViewStore";
import BankOutView from "@/modules/bankOutView/models/BankOutView";
import BankInView from "@/modules/bankInView/models/BankInView";
import configuration, { AppConfiguration } from "@/models/Configuration";
import BankInViewApiSearchPage from "@/modules/bankExtract/components/BankInViewApiSearchPage.vue";
import BankOutViewApiSearchPage from "@/modules/bankExtract/components/BankOutViewApiSearchPage.vue";
import { FilteringModel } from "@/services/filtering";
import eventBus from "@/services/eventBus";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import BankStatementApi from "../services/BankStatementApi";
import AccountType from "@/modules/accountType/models/AccountType";
import ConfirmationBox from "@/components/ConfirmationBox.vue";
import BankInOverViewApiSearchPage from "@/modules/bankExtract/components/BankInOverViewApiSearchPage.vue";
import BankOutOverViewApiSearchPage from "@/modules/bankExtract/components/BankOutOverViewApiSearchPage.vue";

export interface BankOutSelect {
  selectedItems: BankOutView[];
  partnerID: number;
  isOverrideAmount: boolean;
}

export interface BankInSelect {
  selectedItems: BankInView[];
  partnerID: number;
  isOverrideAmount: boolean;
}

interface Data {
  store: BankStatementStore;
  pStore: PartnerStore;
  bIStore: BankInViewStore;
  bOStore: BankOutViewStore;

  sortVisible: boolean;

  configuration: AppConfiguration;

  filter: FilteringModel;

  loading: boolean;
  isOverpaymentWarning: boolean;
  isOverPayment: boolean;
  originalAccountType: number;
  payInProgress: boolean;
  wrongInProgress: boolean;
  saveInProgress: boolean;
  payementGenIsProgress: boolean;
  currAmount: number;
  amount: number;
  isAmountChangeVisible: boolean;
  bankOutSelect: BankOutSelect|undefined;
  bankInSelect: BankInSelect|undefined;
}

interface Methods {
  generateBankOutsSelected(items: BankOutSelect): void;
  generateBankInsSelected(items: BankInSelect): void;
  cancel(): void;
  save(): void;
  partnerSelected(partner: Partner): void;
  sort(): void;
  cancelSort(): void;
  setExchangeRate(): void;
  resetCurrencyAmounts(): void;
  setBackToDefault(): void;
  bankDocumentAmountChanged(amount: number): void;
  bankDocumentCurrAmountChanged(amount: number): void;
  accountSubTypeChanged(value: number): void;
  accountTypeChanged(value: number): void;
  setPaid(): void;
  setWrong(): void;
  getNewItemCurrencyRate(force: boolean): Promise<void>;
  setAccountSubType(): void;
  paid(): void;
  generateOverPayment(): void;
  saveOnlyPaid(): void;
  generateBankInOverSelected(items: any): void;
  generateBankOutOverSelected(items: any): void;
  setAmounts(): void;

}

interface Computed {
    isDisabled: boolean;
    isSortingVisible: boolean;
    bankDocument: BankDocument;
    crediting: boolean;
    defaultCurrency: any;
    currencyRate: number;
    accountType: number;
    isInvoiceSelect: boolean;
    isOverSelect: boolean;
    isReopen: boolean;
    accountTypes: any;
    isPayment: boolean;
    isDevBank: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    PartnerSearch,
    DateInput,
    MoneyInput,
    SortingView,
    BankInViewApiSearchPage,
    BankOutViewApiSearchPage,
    ConfirmationBox,
    BankInOverViewApiSearchPage,
    BankOutOverViewApiSearchPage
  },

  data() {
    return {
      store,
      pStore,
      bIStore,
      bOStore,

      sortVisible: false,

      configuration,
      filter: new FilteringModel(),

      loading: false,
      isOverpaymentWarning: false,
      isOverPayment: false,
      originalAccountType: 1,
      payInProgress: false,
      wrongInProgress: false,
      saveInProgress: false,
      payementGenIsProgress: false,
      currAmount: 0,
      amount: 0,
      isAmountChangeVisible: false,
      bankOutSelect: undefined,
      bankInSelect: undefined,
    };
  },

  async beforeMount() {
    if (this.store.bankDocument.bankStatementItem.id <= 1) {
      this.store.bankDocument.user = auth.userName;
      this.store.bankDocument.bankStatementItem.user = auth.userID;
      this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
      this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
      this.store.bankDocument.bankStatementItem.extNo = Number.parseInt(this.$route.params.id);
      this.store.bankDocument.bankAccountCurrency = this.store.bankStatement.currency;
    }

    this.filter = new FilteringModel()
        .value("partner", store.bankDocument.partner)
        .value("currency", store.bankDocument.bankStatementItem.currency)
        .value("bankStatement", store.bankDocument.bankStatementItem.id)

    await this.pStore.loadCurrencies();
    await this.pStore.loadUsers();
    await this.store.loadAccountTypes();
    await this.store.loadBankAccountStatuses();
    await this.store.loadAccountSubtypes();
  },

  mounted(){  
    this.store.setAccountSubtypes();
    
    this.getNewItemCurrencyRate(false);
    if (this.store.bankDocument.bankStatementItem.id < 2){
        this.setAccountSubType();
        this.originalAccountType = 1;
      } else {
        this.originalAccountType = this.store.bankDocument.bankStatementItem.accountType;
      }

      if (!(this.store.bankDocument.bankStatementItem.invCurrency)){
        this.store.bankDocument.bankStatementItem.invCurrency = this.defaultCurrency.value;
      }

      this.store.bankDocument.payInDeviza = this.store.bankDocument.bankStatementItem.isDev;

      this.setAmounts();
      this.isAmountChangeVisible = false;
      this.bankInSelect = undefined;
      this.bankOutSelect = undefined;
  },

  methods: {
    async generateBankOutOverSelected(items: any){
      if (items.selectedItems.some((x: any) => x.statementID == this.store.bankStatement.id)){
        eventBus.$emit("error", "Nem fizetheti vissza ugyanazon a kivonaton a túlfizetést, amiben keletkezett!");
        eventBus.$emit("bank-over-out-view:reload");
        return;
      }

      if (!this.payementGenIsProgress){
        this.payementGenIsProgress = true;

        if (!(this.store.bankDocument.bankStatementItem.currency)) this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
        if (!(this.store.bankDocument.bankStatementItem.invCurrency)) this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
        await this.store.saveItem(false);

        await this.store.generateBankOutOverPayments(
          Number(this.$route.params["id"]),
          this.store.bankDocument.bankStatementItem.id,
          items.selectedItems
        );
        eventBus.$emit("bank-over-out-view:reload");
        this.$emit("refreshItems");

        this.payementGenIsProgress = false;
      }
    },

    async generateBankInOverSelected(items){
      if (items.selectedItems.some((x: any) => x.statementID == this.store.bankStatement.id)){
        eventBus.$emit("error", "Nem fizetheti vissza ugyanazon a kivonaton a túlfizetést, amiben keletkezett!");
        eventBus.$emit("bank-over-in-view:reload");
        return;
      }

      if (!this.payementGenIsProgress){
        this.payementGenIsProgress = true;

        if (!(this.store.bankDocument.bankStatementItem.currency)) this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
        if (!(this.store.bankDocument.bankStatementItem.invCurrency)) this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
        await this.store.saveItem(false);

        await this.store.generateBankInOverPayments(
          Number(this.$route.params["id"]),
          this.store.bankDocument.bankStatementItem.id,
          items.selectedItems
        );
        eventBus.$emit("bank-over-in-view:reload");
        this.$emit("refreshItems");

        this.payementGenIsProgress = false;
      }
    },

    setAccountSubType() {
      const accType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'számlakiegyenlítés');
      if (store.bankDocument.bankStatementItem.accountType == accType?.id){
        if (this.store.bankDocument.bankStatementItem.crediting){
          const subType = this.store.accountSubtypes.find(x => x.name.toLocaleLowerCase() == 'számlakiegyenlítés (vevői)');
          store.bankDocument.bankStatementItem.accountSubtype = subType?.id ?? 1;
        } else {
          const subType = this.store.accountSubtypes.find(x => x.name.toLocaleLowerCase() == 'számlakiegyenlítés (szállítói)');
          store.bankDocument.bankStatementItem.accountSubtype = subType?.id ?? 1;
        }
      }
    },

    setWrong(){
      if (!this.wrongInProgress){
        this.wrongInProgress = true;

        this.store.wrongBankDocument(this.store.bankDocument.bankStatementItem.id).then(x => {
          this.store.bankDocument.bankStatementItem.status = 4;
        }).finally(() => {
          this.$emit("refreshItems");
          this.wrongInProgress = false;
        });
      }
    },

    setPaid(){
      if (!this.payInProgress){
        this.payInProgress = true;

        this.store.paidBankDocument(this.store.bankDocument.bankStatementItem.id).then(x => {
          this.store.bankDocument.bankStatementItem.status = 3;
        }).finally(() => {
          this.$emit("refreshItems");
          this.payInProgress = false;
        });
      }
    },

    accountTypeChanged(value) {
      if (value == 2)//"számlakiegyenlítés"
      {
        //this.isOverpaymentWarning = true;
      }
      this.store.bankDocument.bankStatementItem.accountSubtype = 1;
      this.store.setAccountSubtypes();
      if (this.store.bankDocument.bankStatementItem.id < 2){
        this.setAccountSubType();
      }
    },

    accountSubTypeChanged(value) {
      //Nem kell
    },

    bankDocumentCurrAmountChanged(amount) {
      this.store.bankDocument.bankStatementItem.currAmount = amount;

      if(amount)
      {
        if (this.bankDocument.payInDeviza && this.store.bankStatement.currency == this.defaultCurrency.value) {
          this.amount = amount * (this.store.bankDocument.bankStatementItem.exchangeRate == 0 ? 1 : this.store.bankDocument.bankStatementItem.exchangeRate);
          this.currAmount = amount;
          
          this.store.bankDocument.bankStatementItem.amount = this.amount;
          this.store.bankDocument.bankStatementItem.currAmount = this.amount;
          
          return;
        }

        this.store.bankDocument.bankStatementItem.amount =
          amount * (this.store.bankDocument.bankStatementItem.exchangeRate == 0 ? 1 : this.store.bankDocument.bankStatementItem.exchangeRate);
      }
      else
      {
        this.store.bankDocument.bankStatementItem.amount = 0;
        this.store.bankDocument.bankStatementItem.currAmount = 0;
      }
    },

    bankDocumentAmountChanged(amount) {
      this.store.bankDocument.bankStatementItem.amount = amount;

      if(amount)
      {
        if(this.store.bankDocument.bankStatementItem.exchangeRate && this.store.bankDocument.bankStatementItem.exchangeRate > 0)
        {
          if (this.bankDocument.payInDeviza && this.store.bankStatement.currency == this.defaultCurrency.value) {
            this.currAmount = amount / (this.store.bankDocument.bankStatementItem.exchangeRate);
            this.amount = amount;

            this.store.bankDocument.bankStatementItem.currAmount = this.amount;
            this.store.bankDocument.bankStatementItem.amount = this.amount;
            return;
          }

          this.store.bankDocument.bankStatementItem.currAmount =
            amount / (this.store.bankDocument.bankStatementItem.exchangeRate);
        }
        else
          this.store.bankDocument.bankStatementItem.currAmount = 0;
      }
      else
      {
        this.store.bankDocument.bankStatementItem.amount = 0;
        this.store.bankDocument.bankStatementItem.currAmount = 0;
      }
    },

    setAmounts(){
      if (this.bankDocument.payInDeviza && this.store.bankStatement.currency == this.defaultCurrency.value) {
            this.currAmount = this.store.bankDocument.bankStatementItem.currAmount / this.store.bankDocument.bankStatementItem.exchangeRate;
            this.amount = this.store.bankDocument.bankStatementItem.currAmount;
            return;
      }
      else {
        this.currAmount = this.store.bankDocument.bankStatementItem.currAmount;
        this.amount = this.store.bankDocument.bankStatementItem.amount;
      }
    },

    resetCurrencyAmounts() {
        this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankDocument.bankAccountCurrency;
        this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
        this.store.bankDocument.bankStatementItem.rateDate = this.store.bankStatement.edate;

      if (this.store.bankStatement.currency == this.defaultCurrency.value){
        this.store.bankDocument.bankStatementItem.exchangeRate = 1;
        this.store.bankDocument.bankStatementItem.currAmount = this.store.bankDocument.bankStatementItem.amount;
      } else {
        this.getNewItemCurrencyRate(true).then(_ => {
          this.bankDocumentAmountChanged(this.store.bankDocument.bankStatementItem.amount);
        });
      }
    },

    setBackToDefault() {
      this.store.bankDocument.bankStatementItem.isDev = this.store.bankDocument.payInDeviza;

      if (!this.store.bankDocument.payInDeviza) {
        this.resetCurrencyAmounts();
      } else if (this.store.bankStatement.currency != this.defaultCurrency.value){
        this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
        this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
      }

      if (this.sortVisible)
      {
        this.sortVisible = false;

        /*if (this.isInvoiceSelect){
          if (this.store.bankDocument.bankStatementItem.crediting){
            eventBus.$emit("bank-in-view:get-items");
            console.log('1');
          } else {
            eventBus.$emit("bank-out-view:get-items");
            console.log('2');
          }
        } else if (this.isOverSelect){
          if (this.store.bankDocument.bankStatementItem.crediting){
            eventBus.$emit("bank-over-in-view:get-items");
            console.log('3');
          } else {
            eventBus.$emit("bank-over-out-view:get-items");
            console.log('4');
          }
        }*/
      }
    },

    async setExchangeRate() {
      try {
        this.loading = true;

        if (
          this.store.bankDocument.bankStatementItem.currency ===
          this.store.bankDocument.bankAccountCurrency
        ) {
          this.resetCurrencyAmounts();
          return;
        }

        const currency = this.store.bankStatement.currency == this.defaultCurrency.value ?
          this.store.bankDocument.bankStatementItem.currency :
          this.store.bankStatement.currency;
        
          const result = await http
          .post<ApiResponse<{ rate: number; rateDate: Date }>>(
            `bank-statement/document/exchange-rate`, {
              currency: currency,
              rateDate: this.store.bankDocument.bankStatementItem.rateDate,
              checkForPrevious: true
            }
          )
          .then(handleApiResponse);

          this.store.bankDocument.bankStatementItem.exchangeRate = result.rate;
          this.store.bankDocument.bankStatementItem.rateDate = result.rateDate;

      } finally {
        this.loading = false;
      }
    },

    async generateBankInsSelected(items) {
      const sum = items.selectedItems.reduce((pv, cv) => pv + Math.round((cv.currToPayment * cv.exchangeRate)), 0);
      const paymentSum = this.store.bankDocument.paymentItems.reduce((pv, cv) => pv + Math.round(cv.paidCurrAmount * cv.exchangeRate), 0);
      if (sum + paymentSum != this.amount && !this.isAmountChangeVisible){
        this.isAmountChangeVisible = true;
        this.bankInSelect = items;
        return;
      }
      
      this.isAmountChangeVisible = false;

      if (this.bankInSelect && items.selectedItems.length == 0){
        items.selectedItems = this.bankInSelect.selectedItems;
      }

      if (!this.payementGenIsProgress){
        this.payementGenIsProgress = true;

        if (!(this.store.bankDocument.bankStatementItem.currency)) this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
        if (!(this.store.bankDocument.bankStatementItem.invCurrency)) this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
        await this.store.saveItem(false);

        await this.store.generateBankInPayments(
          Number(this.$route.params["id"]),
          this.store.bankDocument.bankStatementItem.id,
          items.selectedItems,
          items.isOverrideAmount
        );

        this.bankInSelect = undefined;

        eventBus.$emit("bank-in-view:reload");
        this.$emit("refreshItems");

        this.payementGenIsProgress = false;
      }
    },

    async generateBankOutsSelected(items) {
      const sum = items.selectedItems.reduce((pv, cv) => pv + Math.round((cv.currToPayment * cv.exchangeRate)), 0);
      const paymentSum = this.store.bankDocument.paymentItems.reduce((pv, cv) => pv + Math.round(cv.paidCurrAmount * cv.exchangeRate), 0);
      if (sum + paymentSum != this.amount && !this.isAmountChangeVisible){
        this.isAmountChangeVisible = true;
        this.bankOutSelect = items;
        return;
      }

      this.isAmountChangeVisible = false;

      if (this.bankOutSelect && items.selectedItems.length == 0){
        items.selectedItems = this.bankOutSelect.selectedItems;
      }

      if (!this.payementGenIsProgress){
        this.payementGenIsProgress = true;

        if (!(this.store.bankDocument.bankStatementItem.currency)) this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
        if (!(this.store.bankDocument.bankStatementItem.invCurrency)) this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
        await this.store.saveItem(false);

        await this.store.generateBankOutPayments(
        Number(this.$route.params["id"]),
        this.store.bankDocument.bankStatementItem.id,
        items.selectedItems,
        items.isOverrideAmount
      );

      this.bankOutSelect = undefined;

      eventBus.$emit("bank-out-view:reload");
      this.$emit("refreshItems");
      this.payementGenIsProgress = false;
      }
    },

    cancelSort() {
      this.sortVisible = false;
    },

    sort() {
      this.sortVisible = !this.sortVisible;
    },

    cancel() {
      this.store.bankDocument = new BankDocument();
      this.$emit("hideCreate", false);
    },

    async getNewItemCurrencyRate(force = false){
      
      if (this.store.bankDocument.bankStatementItem.id > 1 && !force){
        return;
      }

      if (this.store.bankStatement.currency != this.defaultCurrency.value){
        const crediting = this.store.bankDocument.bankStatementItem.crediting;
        this.store.bankDocument.bankStatementItem.rateDate = this.store.bankStatement.edate;
        

        if (crediting){
          const currencyRate = await BankStatementApi.getCreditingRate(this.store.bankStatement.currency, this.store.bankStatement.edate);
          this.store.bankDocument.bankStatementItem.exchangeRate = currencyRate.rate;
          this.store.bankDocument.bankStatementItem.rateDate = currencyRate.rateDate;
        } else {
          const openRate = this.store.bankStatement.openRate;
          const closeRate = await BankStatementApi.getAverageRate(this.store.bankStatement.id);

          if (!closeRate || closeRate.rate == 0){
            this.store.bankDocument.bankStatementItem.exchangeRate = openRate;
          } else {
            this.store.bankDocument.bankStatementItem.exchangeRate = closeRate.rate;
            this.store.bankDocument.bankStatementItem.rateDate = closeRate.rateDate;
          }
        }

      } else {
        if (this.store.bankDocument.bankStatementItem.currency == this.defaultCurrency.value){
          this.store.bankDocument.bankStatementItem.exchangeRate = 1;
          this.store.bankDocument.bankStatementItem.rateDate = this.store.bankStatement.edate;
        }
      }
    },

    async paid(){
      const accType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'számlakiegyenlítés');
      if (store.bankDocument.bankStatementItem.accountType == accType?.id){
        if (this.store.bankDocument.bankStatementItem.id > 1){
        const bankDocument = this.store.bankDocuments.find(x => x.bankStatementItem.id == this.store.bankDocument.bankStatementItem.id);
        if (bankDocument && bankDocument.paymentItems.length == 0){
            this.isOverPayment = true;
        } else {
          await this.saveOnlyPaid();
        }
      } else {
        this.isOverPayment = true;
      }
      } else {
        await this.saveOnlyPaid();
      }
    },

    async generateOverPayment(){
        this.isOverPayment = false;

        if (!(this.store.bankDocument.bankStatementItem.currency)) this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
        if (!(this.store.bankDocument.bankStatementItem.invCurrency)) this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
        await this.store.saveItem(true);

        this.setPaid();
    },

    async saveOnlyPaid(){
        this.isOverPayment = false;
        if (this.payInProgress){
          return;
        }

        if (!(this.store.bankDocument.bankStatementItem.currency)) this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
        if (!(this.store.bankDocument.bankStatementItem.invCurrency)) this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
        await this.store.saveItem(false);

        this.setPaid();
    },

    async save() {
      if (this.store.bankDocument.bankStatementItem.accountSubtype < 2 && this.store.accountSubtypes.length > 1){
        eventBus.$emit("error", "Meg kell adnia altípust!");
        return;
      }

      if (!this.saveInProgress){
        this.saveInProgress = true;

        if (!(this.store.bankDocument.bankStatementItem.currency)) this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
        if (!(this.store.bankDocument.bankStatementItem.invCurrency)) this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;

        await this.store.saveItem(false);
        
        const document = this.store.bankDocument;

        if(document.bankStatementItem.status != 2) // ha már nem módosítható
        {
          this.store.bankDocument = new BankDocument();
          //this.$emit("hideCreate", false);
          this.$emit("refreshItems");

          this.saveInProgress = false;
          return;
        }

        if(document.bankStatementItem.accountType == 2) // ha számlaválogatásos
        {
          //this.store.bankDocument = new BankDocument();
          //this.$emit("hideCreate", false);
          this.$emit("refreshItems");

          this.saveInProgress = false;
          return;
        }

        //this.store.bankDocument = new BankDocument();
        //this.$emit("hideCreate", false);
        this.$emit("refreshItems");

        this.saveInProgress = false;
        return;
      }
    },

    partnerSelected(partner: Partner) {
      this.store.bankDocument.partner = partner.name;
      this.setAccountSubType();
    },
  },

  computed: {
    isDevBank(){
      return this.store.bankStatement.currency != this.defaultCurrency.value;
    },

    isPayment(){
      if (this.store.bankDocument.bankStatementItem.id > 1){
        const bankDocument = this.store.bankDocuments.find(x => x.bankStatementItem.id == this.store.bankDocument.bankStatementItem.id);
        if (bankDocument && bankDocument.paymentItems.length > 0){
            return true;
        }
      }

      return false;
    },

    accountTypes(){
      if (this.store.bankStatement.isReopen){
        const invoiceAccType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'számlakiegyenlítés')?.id ?? 1;
        const overAccType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'túlfizetés rendezés')?.id ?? 1;

        return this.store.accountTypes.filter(x => (x.id != invoiceAccType || this.store.bankDocument.bankStatementItem.accountType == invoiceAccType) && (x.id != overAccType || this.store.bankDocument.bankStatementItem.accountType == overAccType));
      } else {

        if (this.store.bankDocument.bankStatementItem.crediting){
          //Jóváírás

          return this.store.accountTypes.filter(x => x.isIn);
        }
        else{
          //Terhelés

          return this.store.accountTypes.filter(x => x.isOut);
        }        
      }
    },

    isReopen(){
      let accType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'számlakiegyenlítés');
      const isInvoice = this.originalAccountType == accType?.id;

      accType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'túlfizetés rendezés');
      const isOver = this.originalAccountType == accType?.id;

      return this.store.bankStatement.isReopen && !isInvoice && !isOver;
    },

    isInvoiceSelect(){
      const accType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'számlakiegyenlítés');
      return store.bankDocument.bankStatementItem.accountType == accType?.id;
    },

    isOverSelect(){
      const accType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'túlfizetés rendezés');
      return store.bankDocument.bankStatementItem.accountType == accType?.id;
    },

    isSortingVisible() {
      return (this.isInvoiceSelect || this.isOverSelect) && this.store.bankDocument.bankStatementItem.id > 1;
    },

    isDisabled() {
      if (this.store.bankDocument.bankStatementItem.status == 3 || this.store.bankDocument.bankStatementItem.status == 4) 
      {
        return true;
      }
      return false;
    },

    bankDocument() { 
      return this.store.bankDocument;
    },

    crediting(){
      return this.store.bankDocument.bankStatementItem.crediting;
    },

    defaultCurrency(){
      return configuration.sysParams.defaultCurrency;
    },

    currencyRate(){
      return this.store.bankDocument.bankStatementItem.exchangeRate;
    },

    accountType(){
      return this.store.bankDocument.bankStatementItem.accountType;
    }
  },

  watch: {
    accountType: function(){
      this.setAccountSubType();
    },

    crediting: function(){
      this.getNewItemCurrencyRate(true);
      this.setAccountSubType();
    },

    bankDocument(){
      if (this.store.bankDocument.bankStatementItem.id <= 1) {
      this.store.bankDocument.user = auth.userName;
      this.store.bankDocument.bankStatementItem.user = auth.userID;
      this.store.bankDocument.bankStatementItem.currency = this.defaultCurrency.value;
      this.store.bankDocument.bankStatementItem.invCurrency = this.store.bankStatement.currency;
      this.store.bankDocument.bankStatementItem.extNo = Number.parseInt(this.$route.params.id);
      this.store.bankDocument.bankAccountCurrency = this.store.bankStatement.currency;
    } else {
      this.store.bankDocument.payInDeviza = this.store.bankDocument.bankStatementItem.isDev;
    }

      this.getNewItemCurrencyRate(false);
      this.originalAccountType = this.store.bankDocument.bankStatementItem.accountType;
      this.filter.value("bankStatement", store.bankDocument.bankStatementItem.id);

      this.store.setAccountSubtypes();

      if (this.sortVisible){
        this.sortVisible = false;
      }

      this.setAmounts();
    },

    currencyRate() {
      const accType = this.store.accountTypes.find(x => x.name.toLocaleLowerCase() == 'árf. ker. különbözet');

      if (this.store.bankDocument.bankStatementItem.accountType != (accType?.id ?? 0)){
        this.bankDocumentCurrAmountChanged(this.currAmount);
      }
    },

    isInvoiceSelect() {
      if (this.store.bankDocument.bankStatementItem.id < 2) {
        //this.setExchangeRate();
      }
    }, 
  },
});
