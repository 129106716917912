





































import Vue from 'vue';
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Data {
  localItems: any[];
}

type DownloadableInvoice = any;
type DownloadableInvoiceItem = any;

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    MoneyDisplay,
  },

  props: {
    head: { type: Object as () => DownloadableInvoice }
  },

  data() {
    return {
      localItems: [],
    }
  },

  beforeMount() {
    this.localItems = this.$props.head.items;
  },

  computed: {
    columns: () => [
      {
          field: "id",
          title: "Azonosító"
      },
      {
          field: "itemName",
          title: "Név"
      },
      {
          field: "itemNo",
          title: "Cikkszám"
      },
      {
          field: "unitPrice",
          title: "Egységár",
          cell: "unitPriceTemplate",
          headerClassName: "right_text"
      },
      {
          field: "amount",
          title: "Mennyiség",
          cell: "amountTemplate",
          headerClassName: "right_text"
      },
      {
          field: "netto",
          title: "Nettó",
          cell: "nettoTemplate",
          headerClassName: "right_text"
      },
      {
          field: "vat",
          title: "Áfa",
          cell: "vatTemplate",
          headerClassName: "right_text"
      },
      {
          field: "total",
          title: "Bruttó",
          cell: "totalTemplate",
          headerClassName: "right_text"
      },
    ]
  },
})
