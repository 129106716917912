








































































































































import Vue from "vue";
import store, { DebitPaymentStore } from "../services/DebitPaymentStore";
import BankAccountPicker from "../../../modules/invoiceFlow/components/BankAccountPicker.vue";
import ValidationError from "../../../modules/downPayment/components/ValidationError.vue";
import MoneyInput from "../../../components/MoneyInput.vue";
import Country from "../../cashPoint/models/Country";
import CurrencyRateApi from "../../currencyRate/services/CurrencyRateApi";
import GeneralIngoingInvoiceApi from "../../generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import PaymentModeDisplay from "../../paymentMode/models/PaymentModeDisplay";
import PartBank from "../../cashPoint/models/PartBank";
import PartnerApi from "../../partner/services/PartnerApi";
import DebitPaymentCreatePage from "./DebitPaymentCreatePage.vue";
import { generalIngoingInvoiceDefaultProps } from "../../generalIngoingInvoice/models/GeneralIngoinInvoice";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: DebitPaymentStore;
  currencies: Country[];
  paymentModes: PaymentModeDisplay[];
  selectedPaymentMode: PaymentModeDisplay | undefined;
  selectedBankAccount: PartBank | undefined;
  partBanks: PartBank[];

  creating: boolean;

  configuration: AppConfiguration;
}

interface Methods {
  paymentModeChanged(): void;
  onPaymentModeChange(id: number): void;
  onBankAccountChange(partBank: PartBank): void;
  save(): void;
  cancel(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    BankAccountPicker,
    // ValidationError,
    MoneyInput,
    DebitPaymentCreatePage,
  },

  data() {
    return {
      store,
      currencies: [],
      paymentModes: [],
      selectedPaymentMode: undefined,
      selectedBankAccount: undefined,
      partBanks: [],

      creating: false,

      configuration
    };
  },

  async beforeMount() {
    await CurrencyRateApi.getCurrencies().then((data) => (this.currencies = data));
    await GeneralIngoingInvoiceApi.getPaymentModes().then((data) => (this.paymentModes = data));
    await PartnerApi.getPartBanksWithTech(Number(this.$route.params.partnerId)).then(
      (result) => (this.partBanks = result)
    );

    if (Number(this.$route.params.invoiceId) > 1) {
      await this.store.loadDebit(Number(this.$route.params.invoiceId));
      this.selectedPaymentMode = this.paymentModes.find((x) => x.id == this.store.debitPayment.incomingInvoice.paymode);
      if (!this.selectedPaymentMode && this.paymentModes.length > 0) {
        this.selectedPaymentMode = this.paymentModes[0];
        this.store.debitPayment.incomingInvoice.paymode = this.selectedPaymentMode.id;
      }

      this.selectedBankAccount = this.partBanks.find(
        (x) => x.id == this.store.debitPayment.incomingInvoice.bankAccount
      );
      if (!this.selectedBankAccount && this.partBanks.length > 0) {
        this.selectedBankAccount = this.partBanks[0];
        this.store.debitPayment.incomingInvoice.bankAccount = this.partBanks[0].id;
      }
      this.creating = false;
    } else {
      this.store.debitPayment.incomingInvoice = { ...generalIngoingInvoiceDefaultProps };
      this.store.debitPayment.incomingInvoice.partner = Number(this.$route.params.partnerId);
      this.creating = true;
    }
  },

  methods: {
    async save() {
      const partnerId = Number(this.$route.params.partnerId);
      const invoiceId = Number(this.$route.params.invoiceId);

      await this.store.save();
    },

    cancel() {
      this.$router.push(`/debit-payment`);
    },

    paymentModeChanged() {
      this.store.debitPayment.incomingInvoice.paymode = this.selectedPaymentMode?.id ?? 1;
      this.onPaymentModeChange(this.store.debitPayment.incomingInvoice.paymode);
    },

    onPaymentModeChange(partBankID: number) {
      if (partBankID == 2) {
        this.selectedBankAccount = this.partBanks.find((x) => x.id == 1);
        this.store.debitPayment.incomingInvoice.bankAccount = 1;
      } else if (this.store.debitPayment.incomingInvoice.bankAccount > 1) {
        return;
      } else {
        const filtered = this.partBanks.filter((x) => x.id > 1);
        if (filtered.length > 0) {
          this.selectedBankAccount = filtered[0];
          this.store.debitPayment.incomingInvoice.bankAccount = this.selectedBankAccount.id;
        }
      }
    },

    onBankAccountChange(partBank) {
      this.selectedBankAccount = partBank;
      this.store.debitPayment.incomingInvoice.bankAccount = partBank.id;
    },
  },
});
