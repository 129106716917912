import http, { handleApiResponse, checkResponse } from '@/services/http'
import AccountActionSource from "@/modules/AccountActionSource/models/AccountActionSource"
import ApiResponse from '@/models/ApiResponse'

export default class AccountActionSourceApi {
    static getAll(): Promise<AccountActionSource[]> {
        return http
            .get<ApiResponse<AccountActionSource[]>>(`account/action-source`)
            .then(handleApiResponse)
    }

    static get(accountActionSourceId: number): Promise<AccountActionSource> {
        return http
            .get<ApiResponse<AccountActionSource>>(`account/action-source/${accountActionSourceId}`)
            .then(handleApiResponse)
    }

    static update(source: AccountActionSource): Promise<boolean> {
        return http
            .put(`account/action-source/${source.id}`, source)
            .then(checkResponse)
    }
}