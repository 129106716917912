import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import { InvoiceBgViewResponse } from "./../models/InvoiceBgView";

export default class InvoiceBgViewApi {
  static getInvoicesBgViewAny(): Promise<InvoiceBgViewResponse> {
    return http.get<ApiResponse<InvoiceBgViewResponse>>(`invoice-bg-view/any`).then(handleApiResponse);
  }

  static getInvoicesBgView(): Promise<InvoiceBgViewResponse> {
    return http.get<ApiResponse<InvoiceBgViewResponse>>(`invoice-bg-view`).then(handleApiResponse);
  }
}
