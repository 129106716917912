
























import auth from "@/services/auth";
import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import User from "@/modules/invoiceGroup/models/User";
import BankRemitApi from "../services/BankRemitApi";
import eventBus from "@/services/eventBus";


interface Data {
  store: BankRemitStore;
}

interface Method {
  saveRemark(): void;
}

interface Computed {
  isAcceptor: boolean;
  allApproverUser: User[];
}

export default Vue.extend<Data, Method, Computed, {}>({
  data: () => ({
    store,
  }),

  watch: {
    allAcceptorUser(){
        console.log(this.allApproverUser)
        console.log(this.allApproverUser.find((x)=>x.id==2))
    }
  },

  computed: {
    allApproverUser(){
        return this.store.allApproverUser;
    },

    isAcceptor() {
      if (this.store.allApproverUser) {
        const x = this.store.allApproverUser.find(
          (x) => x.id == this.store.bankRemitApproval.acceptorId
        );
        if (x && x.name == auth.userName) {
          return true;
        } else return false;
      } else return false;
    },
  },

  methods: {
    async saveRemark() {
      const result = await BankRemitApi.updateApproval(this.store.bankRemitApproval);
      if(result){
        eventBus.$emit("success","Megjegyzés sikeresen elmentve!")
      }
      else {
        eventBus.$emit("error","Nem sikerült elmenteni a megjegyzést! )")
      }
    },
  },
});
