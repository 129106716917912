

























































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, { CashPointStore } from "../services/CashPointStore";
import Bank, { partBankDefaultProps } from "../models/PartBank";
import AccountDetailedType, {
  accountNoDetailedTypeDefaultProps,
} from "../models/AccountNoDetailedType";
import CashRegType, { cashRegTypeDefaultProps } from "../models/CashRegType";
import Printer, { printerDefaultProps } from "../models/Printer";
import Country, { countryDefaultProps } from "../models/Country";
import CashUser from "../models/CashUser";
import { rules } from "../models/CashPointEdit";
import { ValidationRules } from "../../../models/Validation";
import employeeStore, {
  EmployeeStore,
} from "../../employee/services/EmployeeStore";
import Employee from "@/modules/employee/models/Employee";

interface Data {
  store: CashPointStore;
  opened: boolean;
  valid: boolean;
  selectedBank: Bank | undefined;
  selectedDetailedType: AccountDetailedType | undefined;
  selectedCashRegType: CashRegType | undefined;
  selectedPrinter: Printer | undefined;
  selectedCurrency: Country | undefined;
  selectedSiteId: number;
  depotsDialog: boolean;
  banksDialog: boolean;
  cashUserDialog: boolean;
  taxGroupDialog: boolean;
  rules: ValidationRules;
  depotsTake: number;
  depotsSkip: number;
  accountsTake: number;
  accountsSkip: number;
  isDocDateShip: boolean;
  isDocDateDay: boolean;
  cashiersTake: number;
  cashiersSkip: number;
  employeeStore: EmployeeStore;
  employeesSkip: number;
  employeesTake: number;
  employeesDialog: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      employeeStore,
      valid: true,
      opened: false,
      selectedBank: { ...partBankDefaultProps },
      selectedDetailedType: { ...accountNoDetailedTypeDefaultProps },
      selectedCashRegType: { ...cashRegTypeDefaultProps },
      selectedPrinter: { ...printerDefaultProps },
      selectedCurrency: { ...countryDefaultProps },
      selectedSiteId: 0,
      depotsDialog: false,
      banksDialog: false,
      cashUserDialog: false,
      taxGroupDialog: false,
      rules,
      depotsTake: 10,
      depotsSkip: 0,
      accountsTake: 10,
      accountsSkip: 0,
      isDocDateShip: false,
      isDocDateDay: false,
      cashiersSkip: 0,
      cashiersTake: 10,
      employeesSkip: 0,
      employeesTake: 10,
      employeesDialog: false,
    };
  },

  async beforeMount() {
    const id = Number(this.$route.params.cashPointId);
    await Promise.all([
      this.store.loadCashPoint(id),
      this.store.loadAccountNoDetailedTypes(),
      this.store.loadCashRegTypes(),
      this.store.loadPartBanks(),
      this.store.loadPrinters(),
      this.store.loadDepots(),
      this.store.loadCurrencies(),
      this.store.loadCashPoints(),
      this.store.loadCashpointTypes(),
      this.employeeStore.loadEmployees(),
    ]);
    console.log(this.store.depots);

    this.selectedDetailedType = this.store.accountDetailedTypes.find(
      (x) => x.id == this.store.cashPoint.detailedType
    );
    this.selectedCashRegType = this.store.cashRegTypes.find(
      (x) => x.id == this.store.cashPoint.cashRegType
    );
    this.selectedPrinter = this.store.printers.find(
      (x) => x.id == this.store.cashPoint.printer
    );

    this.isDocDateShip = this.store.cashPoint.isDocDateShip;
    this.isDocDateDay = !this.store.cashPoint.isDocDateShip;

    if (store.cashPoint.currency) {
      this.selectedCurrency = this.store.currencies.find(
        (x) => x.currency.trim() == store.cashPoint.currency.trim()
      );
    } else {
      this.selectedCurrency = this.store.currencies.find(
        (x) => x.currency.trim().toUpperCase() == "HUF"
      );

      if (this.selectedCurrency) {
        this.store.cashPoint.currency = this.selectedCurrency.currency.trim();
      }
    }
  },

  methods: {
    setEmployeeDialog(value: any, event: any){
      this.employeesDialog=!this.employeesDialog;
    },

    cashpointtypeChanged(value: number) {
      if (value === 1 || value === 2) {
        this.store.cashPoint.employee = 1;
      }
    },

    async save() {
      await this.store.update();
      this.$router.push("/settings/cash-points");
    },

    async saveCashiers() {
      await this.store.updateCashiers();
      this.cashUserDialog = false;
    },

    async saveTaxGroups() {
      await this.store.updateTaxGroups();
      this.taxGroupDialog = false;
    },

    selectedEmployeeChanged(event: any) {
      this.store.cashPoint.employee = event.dataItem.id;
      this.employeesDialog = false;
    },

    selectedBankChanged(event: any) {
      this.store.cashPoint.bank = event.dataItem.accountNo;
      this.store.cashPoint.bankNo = event.dataItem.id;
      this.banksDialog = false;
    },

    selectedDetailedTypeChanged(item: AccountDetailedType) {
      this.store.cashPoint.detailedType = item.id;
    },

    selectedCurrencyChanged(item: Country) {
      this.store.cashPoint.currency = item.currency.trim();
    },

    selectedCashRegTypeChanged(item: CashRegType) {
      this.store.cashPoint.cashRegType = item.id;

      if (item.id == 6) {
        this.store.cashPoint.cashRegPort = null;
        this.store.cashPoint.cashRegSpeed = null;
        this.store.cashPoint.cashRegPar1 = null;
        this.store.cashPoint.cashRegPar2 = null;
        this.store.cashPoint.cashRegPar3 = null;
        this.store.cashPoint.isDivItem = true;
        this.store.cashPoint.isAutoPrintDoc = true;
        return;
      }

      this.store.cashPoint.cashRegPort = "COM1";
      this.store.cashPoint.cashRegSpeed = "9600";
      this.store.cashPoint.cashRegPar1 = "6";
      this.store.cashPoint.cashRegPar2 = "1";
      this.store.cashPoint.cashRegPar3 = "";
      this.store.cashPoint.isDivItem = true;
      this.store.cashPoint.isAutoPrintDoc = false;

      this.store.cashPoint.printer = 1;
      this.selectedPrinter = this.store.printers.find(
        (x) => x.id == this.store.cashPoint.printer
      );
    },

    selectedPrinterChanged(item: Printer) {
      this.store.cashPoint.printer = item.id;
    },

    selectedDepotChanged(event: any) {
      this.store.cashPoint.siteId = event.dataItem.id;
      this.store.cashPoint.site = event.dataItem.name;
      this.depotsDialog = false;
    },

    partBanksIsAliveChanged() {
      this.store.loadPartBanks();
      this.accountsSkip = 0;
    },

    cashierDefaultChanged(item: CashUser) {
      if (item.isDefault) item.isAssociated = true;

      this.store.cashPoint.cashUsers.forEach((i) => {
        if (i.userID != item.userID) i.isDefault = false;
      });

      // if (this.store.cashPoint.cashUsers.every((i) => !i.isDefault)) this.$nextTick(() => (item.isDefault = true));
    },

    isCashRegChanged() {
      if (store.cashPoint.isCashReg) {
        this.store.cashPoint.cashRegPort = "COM1";
        this.store.cashPoint.cashRegSpeed = "9600";
        this.store.cashPoint.cashRegPar1 = "6";
        this.store.cashPoint.cashRegPar2 = "1";
        this.store.cashPoint.cashRegPar3 = "";
        return;
      }

      this.store.cashPoint.cashRegPort = null;
      this.store.cashPoint.cashRegSpeed = null;
      this.store.cashPoint.cashRegPar1 = null;
      this.store.cashPoint.cashRegPar2 = null;
      this.store.cashPoint.cashRegPar3 = null;
      this.store.cashPoint.isDivItem = false;
      this.store.cashPoint.isAutoClose = false;

      this.store.cashPoint.printer = 1;
      this.store.cashPoint.cashRegType = 1;

      this.selectedCashRegType = this.store.cashRegTypes.find(
        (x) => x.id == this.store.cashPoint.cashRegType
      );
      this.selectedPrinter = this.store.printers.find(
        (x) => x.id == this.store.cashPoint.printer
      );
    },

    nameValidCheck(input: string) {
      if (!input) {
        return "Megnevezés megadása kötelező";
      }

      if (
        this.store.cashPoints.some(
          (x) => x.name == input && this.store.cashPoint.id < 1
        )
      ) {
        return "Ilyen nevű pénztár már létezik";
      }

      return true;
    },

    employeesPageChange(event: any) {
      this.employeesSkip = event.page.skip;
      this.employeesTake = event.page.take;
    },

    depotsPageChange(event: any) {
      this.depotsSkip = event.page.skip;
      this.depotsTake = event.page.take;
    },

    accountsPageChange(event: any) {
      this.accountsSkip = event.page.skip;
      this.accountsTake = event.page.take;
    },

    isDocDateShipChanged() {
      this.isDocDateDay = !this.isDocDateShip;
      this.store.cashPoint.isDocDateShip = !this.isDocDateDay;
    },

    isDocDateDayChanged() {
      this.isDocDateShip = !this.isDocDateDay;
      this.store.cashPoint.isDocDateShip = this.isDocDateShip;
    },

    cashiersPageChange(event: any) {
      this.cashiersSkip = event.page.skip;
      this.cashiersTake = event.page.take;
    },
  },

  computed: {
    isCashiersInvalid() {
      if(this.store.cashPoint.cashUsers && this.store.cashPoint.cashUsers.filter((x) => x.isAssociated).every((x) => !x.isDefault)){
        return this.store.cashPoint.cashUsers.filter((x) => x.isAssociated).every((x) => !x.isDefault);
      }
      return false;
    },

    depotItems() {
      return this.store.depots
        .slice(this.depotsSkip, this.depotsTake + this.depotsSkip)
        .map((item) => ({
          ...item,
          selected: item.id === this.store.cashPoint.siteId,
        }));
    },

    selectedEmployeesName: {
      get(): string{
        return employeeStore.employees.find(x=>x.id === store.cashPoint.employee)?.name??"-";
      }
    },

    employees() {
      return this.employeeStore.employees
        .slice(this.employeesSkip, this.employeesTake + this.employeesSkip)
        .map((item) => ({
          ...item,
          selected: item.id === this.store.cashPoint.employee,
        }));
    },

    bankItems() {
      return this.store.partBanks
        .slice(this.accountsSkip, this.accountsTake + this.accountsSkip)
        .map((item) => ({
          ...item,
          selected: item.id === this.store.cashPoint.bankNo,
        }));
    },

    employeesTotal() {
      return this.employeeStore.employees
        ? this.employeeStore.employees.length
        : 0;
    },

    depotsTotal() {
      return this.store.depots ? this.store.depots.length : 0;
    },

    accountsTotal() {
      return this.store.partBanks ? this.store.partBanks.length : 0;
    },

    isTaxGroupEnabled() {
      if (this.store.cashPoint.cashUsers) {
        return this.store.cashPoint.cashUsers.some((x) => x.isAssociated);
      }

      return false;
    },

    cashierItems() {
      if (this.store.cashPoint.cashUsers) {
        return this.store.cashPoint.cashUsers.slice(
          this.cashiersSkip,
          this.cashiersTake + this.cashiersSkip
        );
      }

      return this.store.cashPoint.cashUsers;
    },

    cashiersTotal() {
      return this.store.cashPoint.cashUsers
        ? this.store.cashPoint.cashUsers.length
        : 0;
    },
  },
});
