

























































































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import CreateInvoiceFromExperimentalView from "./CreateInvoiceFromExperimentalView.vue";
import Vue from "vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import { FilteringModel } from "@/services/filtering";
import ExperimentalInvoice, {
  ExperimentalInvoiceClass,
  experimentalInvoiceDefaultProps,
} from "@/modules/cashStatement/receipt/experimentInvoice/models/ExperimentalInvoice";
import IconButton from "@/components/IconButton.vue";
import cashStatementStore from "@/modules/cashStatement/services/store";
import eventBus from "@/services/eventBus";
import experimentInvoiceStore, {
  ExperimentalInvoiceStore,
} from "../services/store";
import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import FinalInvoiceFromExperimentalReportViewer from "./FinalInvoiceFromExperimentalReportViewer.vue";
import base64toUInt8Array from "@/services/fileConvert";
import dayjs from "dayjs";

interface Data {
  filter: FilteringModel;
  sum: number;
  items: ExperimentalInvoiceClass[];
  selectedItems: ExperimentalInvoiceClass[];
  generatedCashDocId: number | null;
  isGenerated: boolean;
  experimentInvoiceStore: ExperimentalInvoiceStore;
  reportLoading: boolean;
  generateCashDocAfterPdf: boolean;
  lastProform: ExperimentalInvoiceClass;
  isCreditCardPay: boolean;
  hover: boolean;
}

interface Props {
  extraFilter: FilteringModel;
  partnerId: number;
  cashDocByReceipts: boolean;
  title: string;
  inStatement: boolean;
  currency: string;
  showTitle: boolean;
  noPadding: boolean;
  noActionsColor: boolean;
}

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
}

interface Methods {
  selected(item: ExperimentalInvoiceClass): void;
  showDetails(item: ExperimentalInvoiceClass): void;
  addItem(): void;
  setItemValue(
    value: ExperimentalInvoiceClass,
    item: ExperimentalInvoiceClass
  ): void;
  generated(
    extIdInvoice: number,
    cashDocId: number,
    item: ExperimentalInvoiceClass,
    isCashDoc: boolean
  ): void;
  goToCashDocument(): void;
  setItems(items: ExperimentalInvoiceClass[]): void;
  exitFinalInvoicePdf(): void;
  setCreditCardPay(value: boolean): void;
  getTimeAndDate(item: ExperimentalInvoiceClass): string;
  refresh(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ApiSearchPage,
    IconButton,
    CreateInvoiceFromExperimentalView,
    FinalInvoiceFromExperimentalReportViewer,
  },

  props: {
    extraFilter: { type: Object as () => FilteringModel },
    partnerId: { type: Number, default: 1 },
    cashDocByReceipts: { type: Boolean, default: false },
    title: {
      type: String,
      default: "Számlaválogató",
    },
    inStatement: { type: Boolean, default: true },
    currency: {
      type: String,
      default: "",
    },
    showTitle: { type: Boolean, default: true },
    noPadding: {type: Boolean, default: false},
    noActionsColor: {type: Boolean, default: false},
  },

  data: () => ({
    filter: new FilteringModel(),
    sum: 0,
    items: [],
    selectedItems: [],
    generatedCashDocId: null,
    isGenerated: false,
    reportLoading: false,
    experimentInvoiceStore,
    generateCashDocAfterPdf: false,
    lastProform: new ExperimentalInvoiceClass(),
    isCreditCardPay: false,
    hover: false,
  }),

  beforeMount() {
    this.filter = new FilteringModel();
    if (this.$props.extraFilter)
      this.filter = this.filter.fromFilter(this.$props.extraFilter.toObject());
  },

  methods: {
    refresh(){
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },

    getTimeAndDate(item) {
        return dayjs(item.invDate).format("YYYY-MM-DD") +" "+ dayjs(item.invTime).format("HH:MM:ss")
    },

    setCreditCardPay(value) {
      this.isCreditCardPay = value;
    },

    exitFinalInvoicePdf() {
      if (this.generateCashDocAfterPdf) {
        this.goToCashDocument();
      }
      if (this.isCreditCardPay) {
        this.$emit("go-to-credit-card-pay");
        this.$emit(
          "setInvoiceIDFilter",
          this.experimentInvoiceStore.extIdInvoice
        );
      } else {
        this.lastProform.selected = false;
      }
      this.isGenerated = false;
      eventBus.$emit("experimentalinvoices:reload")
    },

    setItems(items) {
      this.items = items;
    },

    goToCashDocument() {
      if (this.generatedCashDocId) {
        this.$router.push(
          `/cash-statement/edit/${cashStatementStore.cashStatement.id}/${this.generatedCashDocId}`
        );
      }
      this.isGenerated = false;
    },

    async generated(extIdInvoice, cashDocId, invoice, isCashDoc) {
      this.reportLoading = true;
      this.experimentInvoiceStore.extIdInvoice = extIdInvoice;
      this.generateCashDocAfterPdf = isCashDoc;
      this.lastProform = invoice;
      this.generatedCashDocId = cashDocId > 1 ? cashDocId : null;

      console.log(extIdInvoice + ";" + cashDocId + ";" + invoice + ";" + isCashDoc);

      try {
        await http
          .get<ApiResponse<string>>(
            `experimental-invoices/report/final-invoice/${extIdInvoice}/${this.experimentInvoiceStore.experimentalInvoicePrefix}`
          )
          .then(handleApiResponse)
          .then((x) => {
            this.experimentInvoiceStore.expReportSrc = new Uint8Array();
            this.experimentInvoiceStore.reportSrc = base64toUInt8Array(x);
            this.isGenerated = true;
            this.reportLoading = false;
          })
      } catch (ex) {
        console.log(ex);
        eventBus.$emit("error", ex);
        this.isGenerated = false;
        this.reportLoading = false;
        experimentInvoiceStore.reportSrc = new Uint8Array();
      }

      cashStatementStore.loadCashDocumentsByStatementId(
        cashStatementStore.cashStatement.id
      );
    },

    setItemValue(value, item) {
      item = { ...value, pdays: Number(value.pdays) };
    },

    async addItem() {
      this.$emit("add-selected-item");
    },

    selected(item: ExperimentalInvoiceClass) {
      this.$emit("selected", item);
    },

    showDetails(item: ExperimentalInvoiceClass) {
      item.selected = !item.selected;
      if (item.selected) {
        this.experimentInvoiceStore.experimentalInvoice = item.pinvID;
        this.experimentInvoiceStore.experimentalInvoicePrefix = item.prefix;
      } else {
        this.experimentInvoiceStore.reportSrc = new Uint8Array();
        this.experimentInvoiceStore.reset();
      }
    },
  },

  computed: {
    columns: () => [
      {
        cell: "selectButton",
        filterable: false,
      },
      {
        field: "pinvID",
        title: "Próbaszámla ID",
        cell: "pinvID",
        headerClass: "d-flex justify-content-right",
      },
      { field: "partner", title: "Partner" },
      {
        field: "prefix",
        title: "Prefix"
      },
      {
        cell: "total",
        field: "total",
        title: "Összeg",
        type: ColumnType.Money,
      },
      {
        field: "bankAccountNo",
        title: "Bankszámlaszám",
      },
      {
        field: "userName",
        title: "Készítő neve",
      },
      {
        field: "invDate",
        title: "Készítés ideje",
        cell: "createdAt"
      },
      { field: "remark1", title: "Megjegyzés" },
    ],

    url() {
      return !this.currency ? `experimental-invoices/search` : `experimental-invoices/search/${this.currency}`;
    },
  },

  destroyed() {
    this.experimentInvoiceStore.reportSrc = new Uint8Array(); 
  },
});
