















import Vue from "vue";

const ccFormat = function(inputValue: string): string {
  if(!inputValue)
    return inputValue;

  const matches = inputValue
    .replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "")
    .match(/\d{4,16}/g);
  const match = (matches && matches[0]) || "";
  const parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join("-");
  }

  return inputValue;
};

export default Vue.extend({
  data () {
    return {
      rules: [(v: string) => !!v && v.length == 19 || '16 számjegy például:1234-1234-1234-1234'],
    }
  },
  props: {
    value: String,
  },
  computed:{
    inputValue(): string {
      return ccFormat(this.value);
    }
  },
  methods: {
    onKeyPress: function(evt: any) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onInput(value: string) {
      let strValue = value;
      if(strValue)
        strValue = value.split("-").join("");

      this.$emit("input", strValue);
      this.$emit("change", strValue);
    },
  },
});
