




















































































































































































































import Vue from "vue";
import configuration from "@/models/Configuration";
import IncomingInvoiceListItemActions from "./IncomingInvoiceListItemActions.vue";
import GeneralIngoinInvoiceDisplay from "../models/GeneralIngoinInvoiceDisplay";
import links from "@/models/Links";

export default Vue.extend({
  components: {
    IncomingInvoiceListItemActions,
  },

  props: {
    item: {},
  },

  data: () => ({
    configuration,
  }),

  // mounted() {
  //   console.log(this.$props.item);
  // },

  methods: {
    updateInvoice(invoice: GeneralIngoinInvoiceDisplay) {
      const path = configuration.invoiceFlow.enabled
        ? invoice.isStockCorrection
          ? `/uninvoiced-stock-correction/edit/${invoice.incomingInvoice.id}`
          : `/general-incoming-invoice/flow/edit/${invoice.incomingInvoice.id}/2`
        : `/general-incoming-invoice/edit/${invoice.incomingInvoice.partner}/${invoice.incomingInvoice.id}`;

      this.$router.push(path);
    },

    partnerEdit(invoice: GeneralIngoinInvoiceDisplay) {
      links.activate.partnerProfile(invoice);
    },
  },
});
