var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"md":"6"}},[_vm._v("Bejövő számla típusok")])],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns},scopedSlots:_vm._u([{key:"isDefaultTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isDefault),callback:function ($$v) {_vm.$set(props.dataItem, "isDefault", $$v)},expression:"props.dataItem.isDefault"}})],1)]}},{key:"promptArriveTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.promptArrive),callback:function ($$v) {_vm.$set(props.dataItem, "promptArrive", $$v)},expression:"props.dataItem.promptArrive"}})],1)]}},{key:"postCalcTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.postCalc),callback:function ($$v) {_vm.$set(props.dataItem, "postCalc", $$v)},expression:"props.dataItem.postCalc"}})],1)]}},{key:"isMustAccGroupTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isMustAccGroup),callback:function ($$v) {_vm.$set(props.dataItem, "isMustAccGroup", $$v)},expression:"props.dataItem.isMustAccGroup"}})],1)]}},{key:"isExtDataTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isExtData),callback:function ($$v) {_vm.$set(props.dataItem, "isExtData", $$v)},expression:"props.dataItem.isExtData"}})],1)]}},{key:"isContrTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isContr),callback:function ($$v) {_vm.$set(props.dataItem, "isContr", $$v)},expression:"props.dataItem.isContr"}})],1)]}},{key:"isInvPermitTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isInvPermit),callback:function ($$v) {_vm.$set(props.dataItem, "isInvPermit", $$v)},expression:"props.dataItem.isInvPermit"}})],1)]}},{key:"isProjectTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isProject),callback:function ($$v) {_vm.$set(props.dataItem, "isProject", $$v)},expression:"props.dataItem.isProject"}})],1)]}},{key:"isAliveTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isAlive),callback:function ($$v) {_vm.$set(props.dataItem, "isAlive", $$v)},expression:"props.dataItem.isAlive"}})],1)]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])])],1)]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }