export default interface PossibleWriteOffDebtPreFilter {
  limit: undefined | number;
  direction: boolean;
  partnerFilters: number[];
}

export const defaultPossibleWriteOffDebtPreFilter: PossibleWriteOffDebtPreFilter = {
  limit: undefined,
  direction: false,
  partnerFilters: [],
};


