import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import CashStatementDisplay from "../models/CashStatementDisplay";
import CashStatement from "../models/CashStatement";
import BankStatus from "../models/BankStatus";
import CashDocumentDisplay from "../models/CashDocumentDisplay";
import CashDocument from "../models/CashDocument";
import CashDocItemDisplay from "../models/CashDocItemDisplay";
import CashDocumentStatus from "../models/CashDocumentStatus";
import User from "@/modules/invoiceGroup/models/User";
import CashProcType from "../models/CashProcType";
import CashSubType from "../models/CashSubType";
import CashDocItem from "../models/CashDocItem";
import BankOutView from "@/modules/bankOutView/models/BankOutView";
import BankInView from "@/modules/bankInView/models/BankInView";
import AccCode from "@/modules/invoiceFlow/models/AccCode";
import AccItemError from "../models/AccItemError";
import busyIndication from "@/utils/busyIndication";

export default class CashStatementApi {
  static getAll(): Promise<CashStatementDisplay[]> {
    return http
      .get<ApiResponse<CashStatementDisplay[]>>(`cash-statement`)
      .then(handleApiResponse);
  }

  static get(cashStatementId: number): Promise<CashStatement> {
    return http
      .get<ApiResponse<CashStatement>>(`cash-statement/${cashStatementId}`)
      .then(handleApiResponse);
  }

  static async addBankOutViewSelectedItems(
    cashDocId: number,
    items: BankOutView[]
  ) {
    const response = await http.post(
      `cash-doc-item/bankout/${cashDocId}`,
      items
    );
    return checkResponse(response);
  }

  static async addBankInViewSelectedItems(
    cashDocId: number,
    items: BankInView[]
  ) {
    const response = await http.post(
      `cash-doc-item/bankin/${cashDocId}`,
      items
    );
    return checkResponse(response);
  }

  static openCashPoint(
    openingAmount: number,
    cashStatementId: number,
    cashDocumentDefaultProps: CashDocument,
    cashDocItemDefaultProps: CashDocItem
  ): Promise<boolean> {
    return http
      .put(`cash-statement/open`, {
        openingAmount: openingAmount,
        cashStatementId: cashStatementId,
        cashDocumentDefaultProps: cashDocumentDefaultProps,
        cashDocItemDefaultProps: cashDocItemDefaultProps,
      })
      .then(checkResponse);
  }

  static update(cashStatement: CashStatement): Promise<boolean> {
    return http
      .put(`cash-statement`, { cashStatement: cashStatement })
      .then(checkResponse);
  }

  static create(cashStatement: CashStatement): Promise<number> {
    return http
      .post<ApiResponse<number>>(`cash-statement`, {
        cashStatement: cashStatement,
      })
      .then(handleApiResponse);
  }

  static getAccountingCodes(statement: number, isBank= false): Promise<AccCode[]> {
    return http
      .get<ApiResponse<AccCode[]>>(
        `cash-statement/${statement}/acc-codes/${isBank}`
      )
      .then(handleApiResponse);
  }

  static getAccItemErrors(statement: number): Promise<AccItemError[]> {
    return http
      .get<ApiResponse<AccItemError[]>>(
        `cash-statement/account-items-errors/${statement}`
      )
      .then(handleApiResponse);
  }

  @busyIndication()
  static sendToAccounting(statement: number): Promise<boolean> {
    return http
      .post<ApiResponse<boolean>>(
        `cash-statement/send-accounting/${statement}`
      )
      .then(handleApiResponse);
  }

  static getAllCashStatementByCashPoint(cashPoint: number): Promise<CashStatement[]> {
    return http
      .get<ApiResponse<CashStatement[]>>(`cashPoint/${cashPoint}/cash-statements`)
      .then(handleApiResponse)
  }

  static setCashStatementWrong(cashStatementId: number): Promise<boolean> {
    return http
      .put<ApiResponse<boolean>>(`cash-statement/${cashStatementId}/set-wrong`)
      .then(handleApiResponse)
  }
  
  static getDocumentsByStatementId(
    cashStatementId: number
  ): Promise<CashDocumentDisplay[]> {
    return http
      .get<ApiResponse<CashDocumentDisplay[]>>(
        `cash-document/statement/${cashStatementId}`
      )
      .then(handleApiResponse);
  }

  static getCashDocumentStatusCanBeChanged(cashDocument: number, status: number): Promise<boolean> {
    return http.get<ApiResponse<boolean>>(`cash-document/${cashDocument}/is-cash-document-status-changeable/${status}`)
      .then(handleApiResponse);
  }

  static getDocument(cashDocumentId: number): Promise<CashDocument> {
    return http
      .get<ApiResponse<CashDocument>>(`cash-document/${cashDocumentId}`)
      .then(handleApiResponse);
  }

  static createDocument(
    cashDocument: CashDocument,
    cashPointId: number
  ): Promise<number> {
    return http
      .post<ApiResponse<number>>(`cash-document/${cashPointId}`, {
        cashDocument: cashDocument,
      })
      .then(handleApiResponse);
  }

  static updateDocument(cashDocument: CashDocument): Promise<boolean> {
    return http
      .put(`cash-document`, { cashDocument: cashDocument })
      .then(checkResponse);
  }

  static getCashDocumentRiportInBase64(
    cashDocumentId: number
  ): Promise<string> {
    return http
      .get<ApiResponse<string>>(`cash-document/riport/${cashDocumentId}`)
      .then(handleApiResponse);
  }

  static getNotoPdfsBase64(cashDocId: number){
    return http.get<ApiResponse<string[]>>(``)
    .then(handleApiResponse);
  }

  static async rekontir(cashStatementId: number) {
    const response = await http.post<ApiResponse<boolean>>(
      `cash-statement/${cashStatementId}/rekontir`
    );
    return handleApiResponse(response);
  }

  static async sendStatementToLedger(cashStatementID: number) {
    return await http.post(`cash-statement/${cashStatementID}/sendtoledger`)
    .then(x=>{
      if(x.status == 200){
        return true;
      }
      else{
        return false
      }
    });
  }

  static setDocumentPrinted(
    cashDocument: CashDocument,
    cashDocItem: CashDocItem
  ): Promise<boolean> {
    return http
      .put(`cash-document/printed`, {
        cashDocument: cashDocument,
        cashDocItem: cashDocItem,
      })
      .then(checkResponse);
  }

  static setDocumentPaid(
    cashDocumentId: number,
    cashPointId: number
  ): Promise<string[]> {
    return http
      .put<ApiResponse<string[]>>(`cash-document/paid`, {
        cashDocumentId: cashDocumentId,
        cashPointId: cashPointId,
      })
      .then(handleApiResponse);
  }

  static getDocItemsByDocumentId(
    cashDocumentId: number
  ): Promise<CashDocItemDisplay[]> {
    return http
      .get<ApiResponse<CashDocItemDisplay[]>>(
        `cash-doc-item/document/${cashDocumentId}`
      )
      .then(handleApiResponse)
      .then((x) => {
        console.log(x);
        return x;
      });
  }

  static updateDocItems(
    document: CashDocument,
    items: CashDocItem[]
  ): Promise<boolean> {
    return http
      .put(`cash-doc-item`, { cashDocument: document, cashDocItems: items })
      .then(checkResponse);
  }

  static getDocumentStatuses(): Promise<CashDocumentStatus[]> {
    return http
      .get<ApiResponse<CashDocumentStatus[]>>(`cash-document-status`)
      .then(handleApiResponse);
  }

  static getProcTypes(): Promise<CashProcType[]> {
    return http
      .get<ApiResponse<CashProcType[]>>(`cash-doc-item/proc-types`)
      .then(handleApiResponse);
  }

  static getSubTypes(): Promise<CashSubType[]> {
    return http
      .get<ApiResponse<CashSubType[]>>(`cash-doc-item/sub-types`)
      .then(handleApiResponse);
  }

  static getCashier(userId: number): Promise<User> {
    return http
      .get<ApiResponse<User>>(`users/${userId}`)
      .then(handleApiResponse);
  }

  static getCurrentUser(): Promise<User> {
    return http.get<ApiResponse<User>>(`users/current`).then(handleApiResponse);
  }

  static getBankStatuses(): Promise<BankStatus[]> {
    return http
      .get<ApiResponse<BankStatus[]>>(`bank-status`)
      .then(handleApiResponse);
  }

  static startInterCashMovement(
    fromCashDocumentId: number,
    fromCashPointId: number,
    e: any
  ): Promise<boolean> {
    return http
      .put(`cash-document/inter-cash-movement`, {
        fromCashDocumentId: fromCashDocumentId,
        fromCashPointId: fromCashPointId,
        toCashPointId: e.toCashPointId,
        amount: e.amount,
        docNo: e.docNo,
        partnerName: e.partnerName,
      })
      .then(checkResponse);
  }
}
