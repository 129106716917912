










































import User from '@/modules/invoiceGroup/models/User';
import auth from '@/services/auth';
import Vue from 'vue'
import BankRemitAndItems, { bankRemitAndItemsDefaultProps } from '../models/BankRemitAndItems'
import store, { BankRemitStore } from "../services/BankRemitStore";

interface Data{
    store: BankRemitStore;
    showAcceptor: boolean;
}


export default Vue.extend<Data,{},{},{}>({
    data: () => ({
        store,
        showAcceptor: false,
    }),

    async beforeMount(){
      await this.store.loadAllBankRemitAcceptorUser().then(async ()=>{
        await this.store.loadApproval(this.store.bankRemit.id);
      })
    },

})
