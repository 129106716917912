import InvoiceGroup from "@/modules/invoiceGroup/models/InvoiceGroup";
import eventBus from "@/services/eventBus";
import Vue from "vue";
import InvoiceParams from '../models/sysinvoice'
import api from '../services/api';

export class InvoiceParamStore {
    invoiceParams: InvoiceParams[] = [];
    invGroupList: InvoiceGroup[] = [];

    /* In get and set functions deleted and isnocuracc props are negated, because of wording.  */

    async setInvoiceParams(){
        this.invoiceParams = await api.GetAllInvoiceParams()
        .then(x=> {
            for (const y of x) {
                y.isNoCuracc=!y.isNoCuracc;
                y.deleted=!y.deleted;
            }
            return x;
        })
    }

    async getInvoiceParam(invoiceParamsId: number): Promise<InvoiceParams>{
        const x: InvoiceParams = await api.GetInvoiceParams(invoiceParamsId)
        .then(y =>{
                y.isNoCuracc=!y.isNoCuracc;
                y.deleted=!y.deleted;
            return y;
        });
        return x;
    }

    async updateInvoiceParams(invoiceParams: InvoiceParams){
        invoiceParams.isNoCuracc=!invoiceParams.isNoCuracc;
        invoiceParams.deleted=!invoiceParams.deleted;
        const x = await api.UpdateInvoiceParams(invoiceParams);
        if(x){
            eventBus.$emit("success","Sikeres mentés!")
        }
        
        this.setInvoiceParams()
    }

    async setInvGroupList(){
        this.invGroupList = await api.GetInvGroupList();
    }

    //Mentéskor a nocuracc értékét megfordítani és a deletedet
}

const store = Vue.observable(new InvoiceParamStore());
export default store;