var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticStyle:{"font-weight":"bold","font-size":"15px"}},[_vm._v(" Tolerancia eltérés ")])],1),_c('v-row',[_c('v-col',[_c('v-divider',{staticClass:"mt-2 mb-4"})],1)],1),_c('v-row'),_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"outlined":""}},[_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns,"filterable":true,"filter":_vm.filter,"sortable":true,"sort":_vm.sort},on:{"filterchange":_vm.filterChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"geniTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('label',{staticStyle:{"color":"var(--primaryColor)","cursor":"pointer"},on:{"click":function($event){return _vm.redirectToInvoce(props.dataItem.incomingInvoice)}}},[_vm._v(" "+_vm._s(props.dataItem.incomingInvoice.id)+" ")])])]}},{key:"currTotalTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.incomingInvoice.currTotal,"currency":props.dataItem.incomingInvoice.invCurrency}})],1)]}},{key:"currNettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.incomingInvoice.currNetto,"currency":props.dataItem.incomingInvoice.invCurrency}})],1)]}},{key:"currVatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.incomingInvoice.currVat,"currency":props.dataItem.incomingInvoice.invCurrency}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }