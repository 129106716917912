



































import Vue from "vue";
import store, { CarPostCalcStore } from "../services/CarPostCalcStore";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import itemStore, { InvoiceItemStore } from "@/modules/invoiceItems/services/store";
import CheckBox from "@/components/CheckBox.vue";
import CarPostCalc from "../models/CarPostCalc";

interface Data {
  store: CarPostCalcStore;
  flowStore: InvoiceFlowStore;
  itemStore: InvoiceItemStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    CheckBox,
  },

  data() {
    return {
      store,
      flowStore,
      itemStore,
    };
  },

  async beforeMount() {
    await this.store.getPostCalcs(Number(this.$route.params.invoiceId));
  },

  methods: {
    async generateCarPostCalc() {
      await this.store.generateCarPostCalc(Number(this.$route.params.invoiceId));
      await this.store.getPostCalcs(Number(this.$route.params.invoiceId));
    },

    // async save(){
    //   await this.flowStore.
    //   await this.itemStore.save();
    // },

    select(e: CarPostCalc) {
      this.store.carPostCalcs.forEach((element) => {
        element.isSelected = false;
      });

      e.isSelected = true;
      this.flowStore.invoice.postCalc = e.id;
      this.itemStore.itemList.items[0].postCalc = e.id;
    },
  },

  computed: {
    columns: () => [
      { field: "id", title: "Azonosító" },
      { field: "arrive", title: "Érkeztetés azonosító" },
      { field: "statusName", title: "Státusz", cell: "statusNameTemplate" },
      { field: "inInv", title: "Beszerzés számla" },
      { cell: "commandTemplate", title: "Számlához rendelve?", width: "140px" },
      { width: 0 },
    ],
  },
});
