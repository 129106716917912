var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"details-btn",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.addFFControllerUser}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("fa-plus")]),_vm._v(" Teljesítés ellenőrző ")],1),_c('div',{staticClass:"details-section",staticStyle:{"margin-right":"-1px"}},[_c('grid',{attrs:{"scrollable":"none","columns":[
            { field: 'name', title: 'Teljesítés ellenőrzők', cell: 'userTemplate' },
            { width: 60, cell: 'deleteTemplate' } ],"data-items":_vm.head.fullfillControllerUsers},scopedSlots:_vm._u([{key:"userTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-select',{attrs:{"single-line":"","return-object":"","dense":"","outlined":"","items":_vm.fullfilmentControllers,"item-text":"name"},on:{"change":function (selectedUser) {
                    _vm.userChanged(props.dataItem, selectedUser);
                  }},model:{value:(props.dataItem.selectedUser),callback:function ($$v) {_vm.$set(props.dataItem, "selectedUser", $$v)},expression:"props.dataItem.selectedUser"}})],1)]}},{key:"deleteTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteFFControllerUser(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"details-btn",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.addFFApproverUser}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("fa-plus")]),_vm._v(" Teljesítés jóváhagyó ")],1),_c('div',{staticClass:"details-section",staticStyle:{"margin-right":"-1px"}},[_c('grid',{attrs:{"scrollable":"none","columns":[
            { field: 'name', title: 'Teljesítés jóváhagyók', cell: 'userTemplate' },
            { width: 60, cell: 'deleteTemplate' } ],"data-items":_vm.head.fullfillApproverUsers},scopedSlots:_vm._u([{key:"userTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-select',{attrs:{"single-line":"","return-object":"","dense":"","outlined":"","items":_vm.fullfilmentApprovers,"item-text":"name"},on:{"change":function (selectedUser) {
                    _vm.userChanged(props.dataItem, selectedUser);
                  }},model:{value:(props.dataItem.selectedUser),callback:function ($$v) {_vm.$set(props.dataItem, "selectedUser", $$v)},expression:"props.dataItem.selectedUser"}})],1)]}},{key:"deleteTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteFFApproverUser(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"details-btn",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.addControllerUser}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("fa-plus")]),_vm._v(" Számla ellenőrző ")],1),_c('div',{staticClass:"details-section",staticStyle:{"margin-right":"-1px"}},[_c('grid',{attrs:{"scrollable":"none","columns":[
            { field: 'name', title: 'Számla ellenőrzők', cell: 'userTemplate' },
            { width: 60, cell: 'deleteTemplate' } ],"data-items":_vm.head.controllerUsers},scopedSlots:_vm._u([{key:"userTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-select',{attrs:{"single-line":"","return-object":"","dense":"","outlined":"","items":_vm.controllers,"item-text":"name"},on:{"change":function (selectedUser) {
                    _vm.userChanged(props.dataItem, selectedUser);
                  }},model:{value:(props.dataItem.selectedUser),callback:function ($$v) {_vm.$set(props.dataItem, "selectedUser", $$v)},expression:"props.dataItem.selectedUser"}})],1)]}},{key:"deleteTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteControllerUser(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}}])})],1)],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"details-btn",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.addApproverUser}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("fa-plus")]),_vm._v(" Számla jóváhagyó ")],1),_c('div',{staticClass:"details-section",staticStyle:{"margin-right":"-1px"}},[_c('grid',{attrs:{"scrollable":"none","columns":[
            { field: 'name', title: 'Számla jóváhagyók', cell: 'userTemplate' },
            { width: 60, cell: 'deleteTemplate' } ],"data-items":_vm.head.approverUsers},scopedSlots:_vm._u([{key:"userTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-select',{attrs:{"single-line":"","return-object":"","dense":"","outlined":"","items":_vm.approvers,"item-text":"name"},on:{"change":function (selectedUser) {
                    _vm.userChanged(props.dataItem, selectedUser);
                  }},model:{value:(props.dataItem.selectedUser),callback:function ($$v) {_vm.$set(props.dataItem, "selectedUser", $$v)},expression:"props.dataItem.selectedUser"}})],1)]}},{key:"deleteTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteApproverUser(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }