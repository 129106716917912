









































































































































































import Vue from "vue";
import store, {
  OutgoingInvoiceTypeEditModelStore,
} from "../services/OutgoingInvoiceTypeEditModelStore";
import Page from "../../../components/Page.vue";
import OutgoingInvoiceTypeEditModel from "../models/OutgoingInvoiceTypeEditModel";
import setAttribute from "../../../utils/dom";

interface Data {
  store: OutgoingInvoiceTypeEditModelStore;
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "12");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    confirmation: confirmationTemplate,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadOutgoingInvoiceTypeEditModels();
  },

  computed: {
    columns: () => [
      {
        field: "name",
        title: "Kimenő szla. típus",
      },
      {
        field: "default",
        title: "Alapértelmezett",
        cell: "defaultTemplate",
      },
      {
        field: "isCar",
        title: "Gj. tart.",
        cell: "isCarTemplate",
      },
      {
        field: "isCarItem",
        title: "Gj. szla tét.",
        cell: "isCarItemTemplate",
      },
      {
        field: "isMoreGparts",
        title: "Azon. cikk",
        cell: "isMoreGpartsTemplate",
      },
      { field: "detailedType", title: "Fők. tétel típus" },
      {
        field: "isNoGparts",
        title: "Cikk/szolg. nélkül",
        cell: "isNoGpartsTemplate",
      },
      {
        field: "isFixAssets",
        title: "Tárgyi eszköz",
        cell: "isFixAssetsTemplate",
      },
      {
        field: "isStockFin",
        title: "Készletfinansz.",
        cell: "isStockFinTemplate",
      },
      {
        field: "isPrintOwnerRun",
        title: "Üzembentartó szla-ra",
        cell: "isPrintOwnerRunTemplate",
      },
      {
        field: "technVatAccCode",
        title: "Techn.áfa fk.szám",
      },
      { cell: "commandTemplate", width: "80px" },
    ],

    items() {
      return this.store.outgoingInvoiceTypeEditModels;
    },
  },

  methods: {
    createOutgoingInvoiceTypeEditModel() {
      this.$router.push(`/outgoing-invoice/type/create`);
    },

    updateOutgoingInvoiceTypeEditModel(item: OutgoingInvoiceTypeEditModel) {
      this.$router.push(`/outgoing-invoice/type/${item.id}/edit`);
    },

    deleteItem(item: OutgoingInvoiceTypeEditModel) {
      this.store.setDeleting(item);
    },

    confirmDelete(item: OutgoingInvoiceTypeEditModel) {
      item.deleting = false;
      this.store.deleteoutgoingInvoiceTypeEditModel(item);
    },

    cancelDelete(item: OutgoingInvoiceTypeEditModel) {
      item.deleting = false;
    },
  },
});
