var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"disabled":_vm.disabled}},[[_c('div',[_c('grid',{staticClass:"analitics-grid",attrs:{"data-items":this.store.vatDivisionsDisplay,"columns":_vm.columns,"detail":'detailsTemplate',"expand-field":'selected'},scopedSlots:_vm._u([{key:"detailsButtonTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"infoButton",class:{
                    selected: props.dataItem.selected,
                  },attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showDetails(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-bars")])],1)]}}],null,true)},[_c('span',[_vm._v("További információk")])])],1)]}},{key:"detailsTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('div',{staticStyle:{"cursor":"default"}},[_c('VatAnaliticsDetails',{attrs:{"items":props.dataItem.items,"invCurrency":props.invCurrency}})],1)]}},{key:"nettoTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.netto,"currency":props.dataItem.currency,"textalign":"text-left"}})],1)]}},{key:"bruttoTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.brutto,"currency":props.dataItem.currency,"textalign":"text-left"}})],1)]}},{key:"vatTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.vat,"currency":props.dataItem.currency,"textalign":"text-left"}})],1)]}},{key:"invNettoTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.invNetto,"currency":props.dataItem.invCurrency,"textalign":"text-left"}})],1)]}},{key:"invBruttoTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.invBrutto,"currency":props.dataItem.invCurrency,"textalign":"text-left"}})],1)]}},{key:"invVatTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('money-display',{attrs:{"value":props.dataItem.invVat,"currency":props.dataItem.invCurrency,"textalign":"text-left"}})],1)]}},{key:"substractTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('v-form',{attrs:{"disabled":!_vm.isSubtractableEdit}},[_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.substractableChanged(props.dataItem)}},model:{value:(props.dataItem.substractable),callback:function ($$v) {_vm.$set(props.dataItem, "substractable", $$v)},expression:"props.dataItem.substractable"}})],1)],1)]}},{key:"investTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.emit(props.dataItem)}},model:{value:(props.dataItem.isInvest),callback:function ($$v) {_vm.$set(props.dataItem, "isInvest", $$v)},expression:"props.dataItem.isInvest"}})],1)]}},{key:"backDemandTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.emit(props.dataItem)}},model:{value:(props.dataItem.backDemand),callback:function ($$v) {_vm.$set(props.dataItem, "backDemand", $$v)},expression:"props.dataItem.backDemand"}})],1)]}},{key:"serviceTemplate",fn:function(ref){
                  var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":true},model:{value:(props.dataItem.isService),callback:function ($$v) {_vm.$set(props.dataItem, "isService", $$v)},expression:"props.dataItem.isService"}})],1)]}}])})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }