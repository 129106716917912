


















































































































































import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import relStore, { FlowDocumentRelationStore } from "@/modules/flowDocumentRelation/services/FlowDocumentRelationStore";
import dtStore, { DocumentTemplateStore } from "../../documentTemplate/services/DocumentTemplateStore";
import FlowDocumentRelation, {
  flowDocumentRelationDefaultProps,
} from "../../flowDocumentRelation/models/FlowDocumentRelation";
import eventBus from "../../../services/eventBus";
import FlowDocumentRelationApi from "../../flowDocumentRelation/services/FlowDocumentRelationApi";
import permStore, { PermissionStore, InvoicePermissions } from "@/services/permissions";
import DocumentTemplateApi from "../../documentTemplate/services/DocumentTemplateApi";
import dStore, { DocumentTypeStore } from "../../documentType/services/DocumentTypeStore";
import http from "@/services/http";

interface Data {
  store: InvoiceFlowStore;
  relStore: FlowDocumentRelationStore;
  permStore: PermissionStore;
  dtStore: DocumentTemplateStore;
  dStore: DocumentTypeStore;

  showDocumentTypes: boolean;
  newDocumentRelationItem: FlowDocumentRelation;
}

interface Methods {
  showFile(relation: FlowDocumentRelation): void;
  deleteFile(relation: FlowDocumentRelation): void;
  hasPermission(statusId: number): boolean;
  uploadFile(relation: FlowDocumentRelation): void;
  downloadFile(link: string): void;
  showDocumentType(): void;
  createDocumentRelation(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  data() {
    return {
      store,
      relStore,
      permStore,
      dtStore,
      dStore,

      showDocumentTypes: false,
      newDocumentRelationItem: { ...flowDocumentRelationDefaultProps },
    };
  },

  async beforeMount() {
    await this.relStore.loadFlowDocumentRelationsByInvoiceId(Number(this.$route.params.invoiceId));
  },

  methods: {
    async createDocumentRelation() {
      this.newDocumentRelationItem.invId = Number(this.$route.params.invoiceId);
      this.newDocumentRelationItem.headId = 1;
      this.newDocumentRelationItem.statusId = 1;
      this.newDocumentRelationItem.required = false;
      this.relStore.flowDocumentRelation = { ...this.newDocumentRelationItem };

      await this.relStore.create();
      await this.relStore.loadFlowDocumentRelationsByInvoiceId(Number(this.$route.params.invoiceId));
    },

    async showDocumentType() {
      if (!this.showDocumentTypes) {
        await this.dStore.loadDocumentTypes();
      }
      this.showDocumentTypes = !this.showDocumentTypes;
    },

    showFile(relation: FlowDocumentRelation) {
      relation.uploading = !relation.uploading;
    },

    downloadFile(link: any) {
      window.open(link + `?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    },

    async uploadFile(relation: FlowDocumentRelation) {
      if (!this.hasPermission(relation.statusId)) {
        eventBus.$emit("error", "Nincs jogosultsága az adott dokumentum módosításához!");
        return;
      }

      console.log(relation);

      const file = (this.$refs.file as any).find((x: any) => {
        if (x?.id == `file-${relation.statusId}-${relation.id}-${relation.documentTypeId}`) return true;
        else return false;
      }).$refs.input.files[0];
      if (!file) return;

      // delete previous file
      if (relation.hasFile) this.deleteFile(relation);

      await this.dtStore.uploadOtherDocumentItem(relation.id, relation.documentTypeId, file).then((result: any) => {
        relation.documentId = result.id;
        relation.link = FlowDocumentRelationApi.generateDocumentDownLoadLink(result.id);
        relation.hasFile = relation.link != "";
        relation.fileName = result.fileName + result.fileExtension;
      });
    },

    hasPermission(statusId: number) {
      // if (statusId != this.store.step) return false;
      // iktatás
      if (statusId == 2 && !this.permStore.has([InvoicePermissions.Recording])) return false;
      // pénzü. r.
      else if (statusId == 3 && !this.permStore.has([InvoicePermissions.FinancialRecording])) return false;
      // készletkapcs
      else if (statusId == 4 && !this.permStore.hasInvoice([InvoicePermissions.StockRelationRecording])) return false;
      // ell. jóvh.
      else if (
        statusId == 5 &&
        !this.permStore.hasInvoice([InvoicePermissions.Controlling, InvoicePermissions.Accepting])
      )
        return false;
      // könyvelés
      else if (statusId == 6 && !this.permStore.hasInvoice([InvoicePermissions.Accounting])) return false;

      return true;
    },

    async deleteFile(relation: FlowDocumentRelation) {
      if (!this.hasPermission(relation.statusId)) {
        eventBus.$emit("error", "Nincs jogosultsága az adott dokumentum módosításához!");
        return;
      }

      if (relation.link == "" || !relation.hasFile || relation.fileName == "") return;

      await DocumentTemplateApi.deletePreviousDocument(relation.documentId).then(() => {
        relation.link = "";
        relation.hasFile = false;
        relation.fileName = "";
      });
    },
  },
});
