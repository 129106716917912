























































































import IconButton from "@/components/IconButton.vue";
import Vue from "vue";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import CheckBox from "@/components/CheckBox.vue";
import * as api from "@/modules/invoiceItems/services/api";

interface Data {
  flowStore: InvoiceFlowStore;
  subcontractorItemRelations: any[];
  newSubcontractorItemRelaions: any[];
  searchText: string;

  selectedItems: any[];
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { IconButton, MoneyDisplay, CheckBox },
  props: {
    invItemId: Number,
    invItemGpartId: Number,
    invItemNetto: Number,
  },

  data() {
    return {
      searchText: "",
      subcontractorItemRelations: [],
      newSubcontractorItemRelaions: [],
      flowStore,
      selectedItems: [],
    }
  },
  
  async beforeMount() {
    this.subcontractorItemRelations = await api.getCurrentSubcontractorItemRelations(Number(this.$props.invItemId));
  },

  methods: {
    async search(e: KeyboardEvent) {
      if (e.key != "Enter") {
        return;
      }
      this.newSubcontractorItemRelaions = (
        await api.searchSubContractorItemRelations(
          this.searchText,
          this.flowStore.invoice.partner,
        )
      ).map((x: any) => ({ ...x, isSelected: false }));
    },

    clear() {
      this.newSubcontractorItemRelaions = [];
      this.searchText = "";
    },

    selectItem(item: any) {
      if (!item.isSelected) {
        item.isSelected = true;
        this.selectedItems.push(item);
      } else {
        item.isSelected = false;
        this.selectedItems = this.selectedItems.filter((x) => x.genoItemId != item.genoItemId);
      }
    },

    async deleteItem(item: any) {
      this.subcontractorItemRelations = this.subcontractorItemRelations.filter((x) => x.relId != item.relId);
      await api.deleteWorkSheetItemRelation(item.relId);
    },

    async addItems() {
      if (this.selectedItems.length == 0) {
        return;
      }

      const items: any = [];
      this.selectedItems.forEach((e: any) => {
        items.push({
          id: 1,
          invNo: Number(this.$route.params.invoiceId),
          genoItem: e.genoItemId,
          itemId: Number(this.$props.invItemId),
          total: Number(e.netto),
        });
      });

      console.log(items);

      const success = await api.addSubcontractorItemRelation(items);
      this.newSubcontractorItemRelaions = [];
      this.subcontractorItemRelations = await api.getCurrentSubcontractorItemRelations(Number(this.$props.invItemId));
      this.selectedItems = [];
      
      if (success) {
        this.flowStore.invoice.type = 4;
      }
    },
  },

  computed: {
    columns: () => [
      { field: "genoId", title: "Számla szám" },
      { field: "k2dInvNo", title: "Bizonylat szám" },
      { field: "genoItemName", title: "Tétel" },
      { field: "quantity", title: "Mennyiség", cell: "quantityTemplate", headerClassName: "right_text" },
      { field: "total", title: "Nettó összeg", cell: "totalTemplate", headerClassName: "right_text" },
      { cell: "commandTemplate", width: "60px" },
      { width: 0 },
    ],

    newColumns: () => [
      { field: "genoId", title: "Számla szám" },
      { field: "genoItemName", title: "Tétel" },
      { field: "supplierName", title: "Alvállalkozó" },
      { field: "gparts", title: "Szolgáltatás" },
      { field: "parts", title: "Cikk" },
      { field: "car", title: "Gépjármű" },
      { field: "quantity", title: "Mennyiség", cell: "quantityTemplate", headerClassName: "right_text" },
      { field: "currNetto", title: "Nettó", cell: "nettoTemplate", headerClassName: "right_text" },
      { field: "currBrutto", title: "Bruttó", cell: "bruttoTemplate", headerClassName: "right_text" },
      { field: "currVat", title: "ÁFA", cell: "vatTemplate", headerClassName: "right_text" },
      { cell: "commandTemplate", width: "60px" },
      { width: 0 },
    ],
  },
})
