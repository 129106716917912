import { FilteringModel, FilterOption, SortingModel } from "@/services/filtering";
import { deepCopy } from "@/utils/objects";

export default class ApiSearch {
  currentPage = 1;
  pageSize = 10;
  searchText = "";
  filter: FilteringModel | undefined;
  extraFilter: any;
  private initFilter: FilteringModel | undefined;
  sort: SortingModel | undefined;
  private initSort: SortingModel | undefined;
  /** While it is true, the page doesn't load automacaly if filters(ApiSearch.Filter or ApiSearch.Sord) changed.  */
  stopFilterLoad = false;
  stopSortLoad = false;

  constructor(params?: Partial<ApiSearch>) {
    const data = { ...params };
    Object.assign(this, data);

    if (this.filter) this.initFilter = new FilteringModel(deepCopy(this.filter));
    if (this.sort) this.initSort = new SortingModel(deepCopy(this.sort));
  }

  resetFiltering() {
    if (!this.initFilter) return;
    this.filter = new FilteringModel(deepCopy(this.initFilter));
  }

  resetSorting() {
    if (!this.initSort) return;
    this.sort = new SortingModel(deepCopy(this.initSort));
  }
}

export enum ColumnType {
  Money = "money",
  Date = "date",
  Center = "center",
  Right = "right",
}

export class ApiSearchColumn {
  field?: string | undefined = "";
  field2?: string | undefined = "";
  multiselectField?: string | undefined;
  filterable? = true;
  sortable? = true;
  title? = "";
  headerClass?: string;
  fieldClass?: string;
  type?: ColumnType;
  cell?: string;
  extraFilterValue? = "";
  multiselect? = false;
  multiselectOptions?: FilterOption[] | undefined = undefined;
  multiselectSelect?: number | undefined | Array<number>;
  header?: string;
  range? = false;
  hide?: false;
  isSecod? = false;

  constructor(params?: Partial<ApiSearchColumn>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}
