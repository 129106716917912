




































import Vue from "vue";
import IconButton from "../../../components/IconButton.vue";
import OtherItemsTable from "./OtherItemsTable.vue";

import * as api from "../services/api";
import eventBus from "../../../services/eventBus";
import { OtherItem } from "../models";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import CarPreCalcInfo from "@/modules/canNet/components/CarPreCalcInfo.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  searchText: string;
  otherItems: OtherItem[];
  flowStore: InvoiceFlowStore;
  configuration: AppConfiguration;
  carSearchVisible: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    IconButton,
    OtherItemsTable,
    CarPreCalcInfo,
  },

  data: () => ({
    searchText: "",
    otherItems: [],
    flowStore,
    carSearchVisible: false,
    configuration,
  }),

  mounted() {
    const otherItemItemAddHandler = () => (this.otherItems = []);
    eventBus.$on("invoice-item:add", otherItemItemAddHandler);
  },

  methods: {
    showCarSearch() {
      this.carSearchVisible = !this.carSearchVisible;
    },

    search() {
      if (this.searchText) {
        return api.searchRectifyOtherParts(this.searchText).then((data) => (this.otherItems = data));
      }
    },

    clear() {
      this.searchText = "";
      this.otherItems = [];
    },
  },
});
