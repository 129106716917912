var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"action-cell"}),_c('th',[_vm._v("Rendelés szám")]),_c('th',{staticClass:"center_text"},[_vm._v("Rendelés dátum")]),_c('th',{staticClass:"right_text"},[_vm._v("Rendelési érték")]),_c('th',{staticClass:"right_text"},[_vm._v("Nettó előleg érték")]),_c('th',{staticClass:"right_text",staticStyle:{"width":"8rem"}},[_vm._v("Előleg %")]),_c('th',{staticClass:"action-cell"})])]),(_vm.store.selectedPreDownPayments)?_c('tbody',_vm._l((_vm.store.selectedPreDownPayments),function(e){return _c('fragment',{key:e.id},[(e.order)?_c('tr',[_c('td',{staticClass:"action-cell"},[(_vm.hasItems(e))?_c('icon-button',{attrs:{"icon":"fa-bars","tooltip":"Megrendelés tételek"},on:{"click":function($event){return _vm.toggleDetails()}}}):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(e.order.orderNumber || "-")+" ")]),_c('td',{staticClass:"center_text"},[_vm._v(" "+_vm._s(e.order.orderDate ? new Date(e.order.orderDate).toLocaleDateString("hu") : "-")+" ")]),_c('td',{staticClass:"right_text"},[_c('money-display',{staticClass:"remove-bottom",attrs:{"decimals":_vm.configuration.operel ? 2 : undefined,"value":_vm.findPreDownPaymentElement(e.order).netto,"currency":e.currency}})],1),_c('td',{staticClass:"right_text"},[(_vm.store.selectedPreDownPayments == null)?_c('money-display',{staticClass:"remove-bottom",attrs:{"decimals":_vm.configuration.operel ? 2 : undefined,"value":"-","currency":e.currency}}):(
            _vm.findPreDownPaymentElement(e.order).type == 2 &&
            _vm.findPreDownPaymentElement(e.order).id == 0
          )?_c('money-input',{staticClass:"remove-bottom",attrs:{"precision":_vm.configuration.operel ? 2 : undefined,"currency":_vm.findPreDownPaymentElement(e.order).currency},on:{"input":function($event){return _vm.amountChanged()}},model:{value:(_vm.findPreDownPaymentElement(e.order).amount),callback:function ($$v) {_vm.$set(_vm.findPreDownPaymentElement(e.order), "amount", _vm._n($$v))},expression:"findPreDownPaymentElement(e.order).amount"}}):_c('money-display',{staticClass:"remove-bottom",attrs:{"decimals":_vm.configuration.operel ? 2 : undefined,"value":Number(_vm.findPreDownPaymentElement(e.order).amount),"currency":_vm.findPreDownPaymentElement(e.order).currency}})],1),_c('td',{staticClass:"right_text"},[(
            _vm.findPreDownPaymentElement(e.order).type == 2 &&
            _vm.findPreDownPaymentElement(e.order).id == 0
          )?_c('money-input',{staticClass:"remove-bottom",attrs:{"precision":_vm.configuration.operel ? 2 : undefined,"append":"%"},on:{"input":function($event){return _vm.percentChanged()}},model:{value:(e.percent),callback:function ($$v) {_vm.$set(e, "percent", _vm._n($$v))},expression:"e.percent"}}):_c('label',[_vm._v(" "+_vm._s(_vm.findPreDownPaymentElement(e.order).type != 2 ? "-" : e.percent + " %")+" ")])],1),_c('td',{staticClass:"action-cell"},[_c('icon-button',{attrs:{"disabled":_vm.disabled,"icon":"fa-times","tooltip":"Előjegyzés törlése"},on:{"click":function($event){_vm.deletePreDownpayment(e);
          _vm.store.preDownPayment = null;
          e = null;}}})],1)]):_vm._e(),(_vm.detailsVisible && _vm.hasItems(e))?_c('tr',[_c('td',{staticClass:"px-0",attrs:{"colspan":"7"}},[_c('div',{staticClass:"details-container"},[_c('DownPaymentExtOrderItemTable',{attrs:{"items":e.orderItems,"edit":""}})],1)])]):_vm._e()])}),1):_c('tbody',[_c('tr',[_c('td',{staticClass:"center_text",attrs:{"colspan":"7"}},[_vm._v(" Nincs előjegyzés ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }