export default interface SysFile {
  id: number;
  partnerId: number;
  firmName: string;
  postCode: string;
  city: string;
  address: string;
  phoneNumber: string;
  faxNumber: string;
  emailAddress: string;
  taxNumber: string;
  euTaxAccount: string;
  defaultReportPath: string;
  defaultTax: number;
  currency: string;
  isCashAccitem: boolean;
}

export const sysFileDefaultProps: SysFile = {
  id: 0,
  partnerId: 0,
  firmName: "",
  postCode: "",
  city: "",
  address: "",
  phoneNumber: "",
  faxNumber: "",
  emailAddress: "",
  taxNumber: "",
  euTaxAccount: "",
  defaultReportPath: "",
  defaultTax: 0,
  currency: "",
  isCashAccitem: false,
};
