import { FlowStatus } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import busyIndication from "@/utils/busyIndication";
import FlowDocumentRelation, { flowDocumentRelationDefaultProps } from "../models/FlowDocumentRelation";
import FlowDocumentRelationApi from "../services/FlowDocumentRelationApi";

export class FlowDocumentRelationStore {
    flowDocumentRelations: FlowDocumentRelation[] = [];
    flowDocumentRelation: FlowDocumentRelation = { ...flowDocumentRelationDefaultProps };

    otherDocumentRelations: FlowDocumentRelation[] = [];
    financialDocumentRelations: FlowDocumentRelation[] = [];

    hasAllRequiredDocuments(relation: FlowStatus) {
       return this.flowDocumentRelations.find((x) => x.required && !x.hasFile && x.statusId == relation)
    }

    async loadFlowDocumentRelations() {
        this.flowDocumentRelations = await FlowDocumentRelationApi.getAll();
    }

    @busyIndication()
    async loadFlowDocumentRelationsByHeadId(headId: number) {
        this.flowDocumentRelations = await FlowDocumentRelationApi.getAllByHeadId(headId);
    }

    async loadFlowDocumentRelationsByHeadAndInvoiceId(headId: number, invoiceId: number) {
        this.flowDocumentRelations = (await FlowDocumentRelationApi.getAllByHeadAndInvoiceId(headId, invoiceId)).map((x) => ({
            ...x,
            link: FlowDocumentRelationApi.generateDocumentDownLoadLink(x.documentId),
            uploading: false,
        }));
    }

    @busyIndication()
    async loadFlowDocumentRelationsByPartnerId(partnerId: number) {
        this.financialDocumentRelations = (await FlowDocumentRelationApi.getAllByPartnerId(partnerId)).map((x) => ({ ...x, link: FlowDocumentRelationApi.generateFinancialDocumentDownLoadLink(x.id), uploading: false }));
    }

    @busyIndication()
    async loadFlowDocumentRelationsByInvoiceId(invId: number) {
        this.otherDocumentRelations = (await FlowDocumentRelationApi.getOtherDocumentsByInvoiceId(invId)).map((x) => ({ ...x, link: FlowDocumentRelationApi.generateDocumentDownLoadLink(x.documentId), uploading: false }));
        console.log(this.otherDocumentRelations);
    }

    async loadFlowDocumentRelationsByInvoiceIdWithoutBusy(invId: number) {
        this.otherDocumentRelations = (await FlowDocumentRelationApi.getOtherDocumentsByInvoiceId(invId)).map((x) => ({ ...x, link: FlowDocumentRelationApi.generateDocumentDownLoadLink(x.documentId), uploading: false }));
    }

    async loadFlowDocumentRelation(flowDocumentRelationId: number) {
        this.flowDocumentRelation =
            flowDocumentRelationId > 1
                  ? await FlowDocumentRelationApi.get(flowDocumentRelationId)
                  : { ...flowDocumentRelationDefaultProps };
    }

    @busyIndication()
    async delete(flowDocumentRelationId: number) {
        try {
            await FlowDocumentRelationApi.delete(flowDocumentRelationId);
            this.flowDocumentRelations = this.flowDocumentRelations.filter((b) => b.id !== flowDocumentRelationId);
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    async save() {
        if (!this.flowDocumentRelation) {
            return;
        }

        try {
            if (this.flowDocumentRelation.id > 1) {
                await FlowDocumentRelationApi.update(this.flowDocumentRelation);
            } else {
                const ret = Number(await FlowDocumentRelationApi.create(this.flowDocumentRelation));
                // this.flowDocumentRelation.id = ret;
                // this.flowDocumentRelations.push(this.flowDocumentRelation);
                // this.flowDocumentRelation = { ...flowDocumentRelationDefaultProps };
                await this.loadFlowDocumentRelationsByHeadId(this.flowDocumentRelation.headId);
            }
        } catch (err) {
            // TODO: notification
            console.error(err);
        }
    }

    async create(){
        try {
            await FlowDocumentRelationApi.create(this.flowDocumentRelation);
        } catch (error) {
            console.error(error);
        }
    }
}

const store = new FlowDocumentRelationStore();
export default store;
