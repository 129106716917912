import { ValidationRules } from "@/models/Validation"

export default interface AccountCodeEditModel {
    accountCodeId: number;
    columnDictionaryId: number;
    valueNat: string;
    tableName: string;
    columnName: string;
    accountCode: string;
    largeText: string;
    gridText: string;
    displayTableName: string;
    inEdit: boolean;
}

export const rules: ValidationRules = {
    accountCode: [
        (v: string) => !!v || 'Kötelező',
        (v: string) => v.length <= 10 ||'Nem lehet hosszabb mint 10 karakter'
    ],
    tableName: [
        (v: string) => !!v || 'Kötelező',
    ],
    columnName: [
        (v: string) => !!v || 'Kötelező',
    ]
}

export const accountCodeEditModelDefaultProps: AccountCodeEditModel = {
    accountCodeId: 0,
    columnDictionaryId: 0,
    valueNat: "",
    tableName: "",
    columnName: "",
    accountCode: "",
    largeText: "",
    gridText: "",
    displayTableName: "",
    inEdit: false,
}

export interface AccountCodeEditModelResponse {
    accountCodeEditModels: AccountCodeEditModel[];
    count: number;
}