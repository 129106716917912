import Partner, { partnerDefaultProps } from "@/modules/partner/models/Partner";
import {
  TechnicalInvoiceVIRO,
  TechnicalInvoiceVIRI,
  technicalInvoiceVIRODefaultProps,
  technicalInvoiceVIRIDefaultProps,
} from "../models/TechnicalInvoiceModel";
import TechnicalInvoicePmode from "../models/TechnicalInvoicePmodeModel";
import TechnicalInvoiceStatus from "../models/TechnicalInvoiceStatusModel";
import TechnicalInvoiceAccGroup from "../models/TechnicalInvoiceAccGroupModel";
import TechnicalInvoiceAccStatus from "../models/TechnicalInvoiceAccStatusModel";
import TechnicalInvoicePartnerRel from "../models/TechnicalInvoicePartnerRelModel";
import TechnicalInvoiceDivitem from "../models/TechnicalInvoiceDivitemModel";
import TechnicalInvoiceCurrency from "../models/TechnicalInvoiceCurrency";
import { TechnicalInvoiceVIROType, TechnicalInvoiceVIRIType } from "../models/TechnicalInvoiceTypeModel";
import TechnicalInvoiceCreatorUser, {
  technicalInvoiceCreatorUserDefaultProps,
} from "../models/TechnicalInvoiceCreatorUserModel";

import TechnicalInvoiceApi from "./TechnicalInvoiceApi";
import eventBus from "@/services/eventBus";

export class TechnicalInvoiceStore {
  tInvoiceVIRO: TechnicalInvoiceVIRO = { ...technicalInvoiceVIRODefaultProps };
  tInvoiceVIRI: TechnicalInvoiceVIRI = { ...technicalInvoiceVIRIDefaultProps };
  tInvoicePartner: Partner = { ...partnerDefaultProps };

  autoVIRI: TechnicalInvoiceVIRI = { ...technicalInvoiceVIRIDefaultProps };

  minDate: Date = new Date("1980-01-01");
  minDateString: string = this.minDate.toISOString().split("T")[0];

  pmodes: TechnicalInvoicePmode[] = [];
  statuses: TechnicalInvoiceStatus[] = [];
  accGroups: TechnicalInvoiceAccGroup[] = [];
  accStatuses: TechnicalInvoiceAccStatus[] = [];
  partnerRels: TechnicalInvoicePartnerRel[] = [];
  divitems: TechnicalInvoiceDivitem[] = [];
  currencies: TechnicalInvoiceCurrency[] = [];
  VIROInvTypes: TechnicalInvoiceVIROType[] = [];
  VIRIInvTypes: TechnicalInvoiceVIRIType[] = [];
  creatorUser: TechnicalInvoiceCreatorUser = { ...technicalInvoiceCreatorUserDefaultProps };

  // TODO
  // invoiceRects: any[] = []; // helyesbítő számla lista, ez egy nagyobb falat lesz

  autoViriCreation = false;
  disabledMode = false;

  resetTInvoice(codeFilter: string) {
    if (codeFilter === "viro") {
      this.tInvoiceVIRO = { ...technicalInvoiceVIRODefaultProps };
    }
    if (codeFilter === "viri") {
      this.tInvoiceVIRI = { ...technicalInvoiceVIRIDefaultProps };
    }
  }

  resetTInvoicePartner() {
    this.tInvoicePartner = { ...partnerDefaultProps };
  }

  typeNyitoKimenoIsValid() {
    let viroIsValid = true;

    if (viroIsValid) {
      if (this.tInvoicePartner.id === 1 || this.tInvoicePartner.name.trim().length === 0) {
        eventBus.$emit("error", "Válasszon ki egy partnert!");
        return (viroIsValid = false);
      }
    }

    if (viroIsValid) {
      const moneyInputs = {
        nettó: this.tInvoiceVIRO.netto,
        ÁFA: this.tInvoiceVIRO.vat,
        totál: this.tInvoiceVIRO.total,
      };

      Object.entries(moneyInputs).forEach(([key, value]) => {
        if (value === null) {
          eventBus.$emit("error", `Adja meg a számla ${key} értékét!`);
          viroIsValid = false;
          return;
        } else {
          if (value < 0) {
            eventBus.$emit("error", `A számla ${key} értéke nem lehet negatív!`);
            viroIsValid = false;
            return;
          }
        }
      });
    }

    if (viroIsValid) {
      if (this.tInvoiceVIRO.invDate < this.minDate) {
        eventBus.$emit("error", `A számla dátuma nem érvényes dátum!`);
        viroIsValid = false;
      }

      if (this.tInvoiceVIRO.pterm < this.minDate) {
        eventBus.$emit("error", `A számla fizetési határideje nem érvényes dátum!`);
        viroIsValid = false;
      }

      if (this.tInvoiceVIRO.shipDate !== null && this.tInvoiceVIRO.shipDate < this.minDate) {
        eventBus.$emit("error", `A számla teljesítés dátuma nem érvényes dátum!`);
        viroIsValid = false;
        this.tInvoiceVIRO.shipDate = technicalInvoiceVIRODefaultProps.shipDate; // vissza a default értékre
      }

      if (this.tInvoiceVIRO.pterm < this.tInvoiceVIRO.invDate) {
        eventBus.$emit("error", `A fizetési határidő nem lehet korábban mint a számla dátuma!`);
        viroIsValid = false;
      }

      if (this.tInvoiceVIRO.shipDate !== null && this.tInvoiceVIRO.invDate > this.tInvoiceVIRO.shipDate) {
        eventBus.$emit("error", `A teljesítés dátuma nem lehet korábban mint a számla dátuma!`);
        viroIsValid = false;
      }
    }

    return viroIsValid;
  }

  typeNyitoBejovoIsValid() {
    let viriIsValid = true;

    if (viriIsValid) {
      if (this.tInvoicePartner.id === 1 || this.tInvoicePartner.name.trim().length === 0) {
        eventBus.$emit("error", "Válasszon ki egy partnert!");
        return (viriIsValid = false);
      }
    }

    if (viriIsValid) {
      const moneyInputs = {
        nettó: this.tInvoiceVIRI.netto,
        ÁFA: this.tInvoiceVIRI.vat,
        totál: this.tInvoiceVIRI.total,
      };

      Object.entries(moneyInputs).forEach(([key, value]) => {
        if (value === null) {
          eventBus.$emit("error", `Adja meg a számla ${key} értékét!`);
          viriIsValid = false;
          return;
        } else {
          if (value < 0) {
            eventBus.$emit("error", `A számla ${key} értéke nem lehet negatív!`);
            viriIsValid = false;
            return;
          }
        }
      });
    }

    if (viriIsValid) {
      if (this.tInvoiceVIRI.invDate < this.minDate) {
        eventBus.$emit("error", `A számla dátuma nem érvényes dátum!`);
        viriIsValid = false;
      }

      if (this.tInvoiceVIRI.pterm < this.minDate) {
        eventBus.$emit("error", `A számla fizetési határideje nem érvényes dátum!`);
        viriIsValid = false;
      }

      if (this.tInvoiceVIRI.shipDate !== null && this.tInvoiceVIRI.shipDate < this.minDate) {
        eventBus.$emit("error", `A számla teljesítés dátuma nem érvényes dátum!`);
        viriIsValid = false;
        this.tInvoiceVIRI.shipDate = technicalInvoiceVIRODefaultProps.shipDate; // vissza a default értékre
      }

      if (this.tInvoiceVIRI.pterm < this.tInvoiceVIRI.invDate) {
        eventBus.$emit("error", `A fizetési határidő nem lehet korábban mint a számla dátuma!`);
        viriIsValid = false;
      }
    }

    return viriIsValid;
  }

  async saveNewTInvoice(tInvoice: TechnicalInvoiceVIRO | TechnicalInvoiceVIRI, codeFilter: string) {
    try {
      // megkapjuk a válaszba a frissen mentett technikai számla adatait, vagy errort
      const result = await TechnicalInvoiceApi.update(tInvoice, codeFilter)
        // hibakezelés
        .then(async (x) => {
          if (x) {
            eventBus.$emit("success", "Sikeres mentés!");
          } else eventBus.$emit("error", "Hiba történt mentés közben!");
          return x;
        });
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async getViro(invoiceId: number) {
    this.tInvoiceVIRO = await TechnicalInvoiceApi.getViroInvoice(invoiceId);
    this.tInvoicePartner = await TechnicalInvoiceApi.getPartner(this.tInvoiceVIRO.partner);
  }

  async getViri(invoiceId: number) {
    this.tInvoiceVIRI = await TechnicalInvoiceApi.getViriInvoice(invoiceId);
    this.tInvoicePartner = await TechnicalInvoiceApi.getPartner(this.tInvoiceVIRI.partner);
  }

  async loadPmodes() {
    this.pmodes = await TechnicalInvoiceApi.getPmodes();
    // console.log(this.pmodes);
  }

  async loadStatuses() {
    this.statuses = await TechnicalInvoiceApi.getStatuses();
  }

  async loadaccGroups() {
    this.accGroups = await TechnicalInvoiceApi.getAccGroups();
  }

  async loadaccStatuses() {
    this.accStatuses = await TechnicalInvoiceApi.getAccStatuses();
  }

  async loadPartnerRels(codeFilter: string) {
    this.partnerRels = await TechnicalInvoiceApi.getPartnerRels(codeFilter);
  }

  async loadDivitems() {
    this.divitems = await TechnicalInvoiceApi.getDivitems();
  }

  async loadCurrencies() {
    this.currencies = await TechnicalInvoiceApi.getCurrencies();
  }

  async loadAllInvTypes(codeFilter: string) {
    if (codeFilter === "viro") {
      this.VIROInvTypes = await TechnicalInvoiceApi.getVIROInvoiceTypes();
    }
    if (codeFilter === "viri") {
      this.VIRIInvTypes = await TechnicalInvoiceApi.getVIRIInvoiceTypes();
    }
  }

  async loadOpenerInvTypes(codeFilter: string) {
    if (codeFilter === "viro") {
      this.VIROInvTypes = await TechnicalInvoiceApi.getOpenerVIROInvoiceTypes();
    }
    if (codeFilter === "viri") {
      this.VIRIInvTypes = await TechnicalInvoiceApi.getOpenerVIRIInvoiceTypes();
    }
  }

  async loadCreatorUser(userId: number) {
    this.creatorUser = await TechnicalInvoiceApi.getCreatorUser(userId);
  }
}

export const store = new TechnicalInvoiceStore();
