































































































































































// Models
import Vue from "vue";
import eventBus from "@/services/eventBus";
import dayjs from "dayjs";
import { GridColumnProps } from "@progress/kendo-vue-grid";
import store, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import scheduleStore, { PaymentScheduleStore } from "../services/store";
import { ValidationRules } from "@/models/Validation";
import { formatDateISO, newDate } from "@/utils/formatDates";
import { PaymentSchedule, PaymentScheduleItem, PaymentScheduleOption, rules } from "../models/PaymentSchedule";
import PartBank, { partBankTechProps } from "@/modules/cashPoint/models/PartBank";
import PaymentModeDisplay, { paymentModeDisplayTechProps } from "@/modules/paymentMode/models/PaymentModeDisplay";
import configuration from "@/models/Configuration";


// Components
import MoneyInput from "@/components/MoneyInput.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import Confirmation from "@/components/Confirmation.vue";
import IconButton from "@/components/IconButton.vue";

// Mixins
import { ConfirmationMixin } from "@/mixins/confirmation";
import User from "@/modules/invoiceGroup/models/User";
import { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: InvoiceFlowStore;
  scheduleStore: PaymentScheduleStore;
  rules: ValidationRules;
  paymentSchedule: PaymentSchedule;
  minDeadline: string | Date;
  scheduleIntervals: number[];
  scheduleInterval: number;
  scheduleOption: PaymentScheduleOption;
  scheduleOptions: PaymentScheduleOption[];
  startDate: string;
  endDate: string;

  configuration: AppConfiguration;
}

interface Methods {
  generateItems(): void;
  addItem(): void;
  deleteItems(): void;
  deleteItem(e: PaymentScheduleItem): void;
  deadlineChanged(e: PaymentScheduleItem): void;
  bankAccountChanged(item: PaymentScheduleItem, e: PartBank): void;
  paymentModeChanged(item: PaymentScheduleItem, e: PaymentModeDisplay): void;
  responsibleUserChanged(e: User): void;
}

interface Computed {
  form: any; // eslint-disable-line
  scheduleItemsTotal: number;
  scheduleItemsLeft: number;
  columns: GridColumnProps[];
  items: PaymentScheduleItem[];
  customSchedulingEnabled: boolean;
}

interface Props {
  isPageDisabled: boolean;
  isEditingEnabled: boolean;
  invoiceTotal: number;
  currency: string;
  invoiceDate: string;
  deadline: string;
  prefix: string;
  invoiceID: number;

  partBanks: PartBank[];
  bankAccount: number;
  paymentModes: PaymentModeDisplay[];
  paymentMode: number;

  users: User[];
}

export default ConfirmationMixin.extend<Data, Methods, Computed, Props>({
  components: {
    MoneyInput,
    MoneyDisplay,
    Confirmation,
    IconButton,
  },

  props: {
    isPageDisabled: { type: Boolean, default: true },
    isEditingEnabled: { type: Boolean, default: true },
    invoiceTotal: { type: Number, default: 0 },
    currency: { type: String, default: "" },
    invoiceDate: { type: String, default: "" },
    deadline: { type: String, default: "" },
    prefix: { type: String, default: "" },
    invoiceID: { type: Number, default: 0 },

    partBanks: { type: Array as () => PartBank[] },
    bankAccount: { type: Number, default: 0 },
    paymentModes: { type: Array as () => PaymentModeDisplay[] },
    paymentMode: { type: Number, default: 0 },

    users: { type: Array as () => User[] },
  },

  data: () => ({
    store,
    scheduleStore,
    rules,
    paymentSchedule: new PaymentSchedule(),
    minDeadline: formatDateISO(new Date()),
    scheduleIntervals: [10, 20, 30, 40, 50],
    scheduleInterval: 30,
    scheduleOption: PaymentScheduleOption.Monthly,
    scheduleOptions: [PaymentScheduleOption.Custom, PaymentScheduleOption.Monthly, PaymentScheduleOption.Quarterly],
    startDate: "2000-01-01",
    endDate: "2000-01-01",

    configuration
  }),

  async beforeMount() {
    const invoiceId = Number(this.invoiceID) || 0;
    const prefix = this.prefix ? this.prefix : "geni";
    this.paymentSchedule = await this.scheduleStore.loadPaymentScheduleAndItems(
      invoiceId,
      this.partBanks,
      this.paymentModes,
      this.users,
      prefix
    );

    if (this.paymentSchedule.invoiceNo != invoiceId) {
      this.paymentSchedule.invoiceNo = invoiceId;
      this.paymentSchedule.currency = this.currency;
    }

    this.startDate = this.invoiceDate;
    this.endDate = this.deadline;
  },

  methods: {
    generateItems() {
      if (this.isPageDisabled) return;
      this.deleteItems();

      // Kezdő nap (számla dátuma)
      const start = dayjs(this.startDate);

      // Utolsó nap (fizetési határidő)
      const end = dayjs(this.endDate);

      if (!start.isValid() || !end.isValid()) {
        eventBus.$emit("warn", "Hibás kezdő dátum vagy határidő!");
        return;
      }

      if (!this.scheduleInterval || this.scheduleInterval == 0) {
        this.scheduleInterval = this.scheduleIntervals[0];
      }

      // Egyedi, Havi vagy Negyedéves?
      const interval = this.customSchedulingEnabled
        ? this.scheduleInterval
        : this.scheduleOption === PaymentScheduleOption.Monthly
          ? 1
          : 3;

      // A két dátum közti eltérés
      const diffInTime = end.toDate().getTime() - start.toDate().getTime();

      if (diffInTime < 0) {
        eventBus.$emit("warn", "A kezdő dátuma későbbi mint a fizetési határidő!");
        return;
      } else if (diffInTime == 0) {
        eventBus.$emit("warn", "A kezdő dátuma megegyezik a fizetési határidővel!");
        return;
      }

      const difference = this.customSchedulingEnabled
        ? // Az eltérés napokban
        Math.round(diffInTime / (1000 * 60 * 60 * 24)) // 1 day in millis
        : // Az eltérés hónapokban
        Math.max((end.year() - start.year()) * 12 + end.month() - start.month(), 0);

      if (this.customSchedulingEnabled && difference == 0) {
        eventBus.$emit("warn", "A kezdő dátuma és a határidő között nincsen 1 nap eltérés!");
        return;
      }

      if (!this.customSchedulingEnabled && difference < interval) {
        eventBus.$emit(
          "warn",
          "A kezdő dátuma és a határidő között nincs elegendő eltérés a kiválasztott beosztás alkalmazásához!"
        );
        return;
      }

      // Tételek száma
      const itemCount = Math.ceil((difference + 1) / interval);

      // Egy tételbe kerülő fizetendő összeg
      const amount = this.store.toDecimal(this.invoiceTotal / itemCount);

      // Kerekítésből eredendő visszamaradt különbözet
      const roundingDiff = this.invoiceTotal - amount * itemCount;

      const bankAccount = this.bankAccount ?? this.partBanks[0]?.id ?? 1;
      const paymentMode = this.paymentMode ?? this.paymentModes[0]?.id ?? 1;
      const selectedPaymentMode = this.paymentModes.find((x) => x.id == paymentMode) ??
        this.paymentModes[0] ?? { ...paymentModeDisplayTechProps };
      const selectedBankAccount = this.partBanks.find((x) => x.id == bankAccount) ??
        this.partBanks[0] ?? { ...partBankTechProps };

      for (let i = 0; i < itemCount; i++) {
        const intervalName = this.customSchedulingEnabled ? "day" : "month";
        const deadline = dayjs(start).add(interval * i, intervalName);

        // Ha az utolsó tétel napja > határidő
        if (i == itemCount - 1 && deadline.date() > end.date()) {
          deadline.set("date", end.date());
        }

        this.paymentSchedule.items = [
          ...this.paymentSchedule.items,
          new PaymentScheduleItem({
            paymentMode: paymentMode,
            selectedPaymentMode: selectedPaymentMode,
            bankAccount: bankAccount,
            selectedBankAccount: selectedBankAccount,
            deadline: deadline.format("YYYY-MM-DD"),
            deadlineDisplay: deadline.format("YYYY-MM-DD"),
            amount: i == itemCount - 1 ? amount + roundingDiff : amount,
          }),
        ];
      }
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },

    addItem() {
      if (this.isPageDisabled) return;

      const newItem: PaymentScheduleItem = new PaymentScheduleItem({
        bankAccount: this.bankAccount ?? this.partBanks[0]?.id ?? 1,
        paymentMode: this.paymentMode ?? this.paymentModes[0]?.id ?? 1,
        deadline: this.endDate,
        deadlineDisplay: this.endDate,
        amount: this.invoiceTotal - this.scheduleItemsTotal,
      });

      newItem.selectedPaymentMode = this.paymentModes.find((x) => x.id == newItem.paymentMode) ??
        this.paymentModes[0] ?? { ...paymentModeDisplayTechProps };

      newItem.selectedBankAccount = this.partBanks.find((x) => x.id == newItem.bankAccount) ??
        this.partBanks[0] ?? { ...partBankTechProps };

      this.paymentSchedule.items.push(newItem);
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },

    deleteItems() {
      if (this.isPageDisabled) return;
      this.paymentSchedule.items = [];
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },

    async deleteItem(e) {
      if (this.isPageDisabled) return;

      const confirmed = await this.confirm("", "confirm" + e.cuid);
      if (!confirmed) return;

      const index = this.paymentSchedule.items.indexOf(e);
      if (index > -1) this.paymentSchedule.items.splice(index, 1);
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },

    deadlineChanged(e) {
      e.deadline = newDate(e.deadlineDisplay);
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },

    bankAccountChanged(item, e) {
      item.selectedBankAccount = e;
      item.bankAccount = e.id;
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },

    paymentModeChanged(item, e) {
      item.selectedPaymentMode = e;
      item.paymentMode = e.id;
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },

    responsibleUserChanged(e) {
      this.paymentSchedule.selectedResponsibleUser = e;
      this.paymentSchedule.responsibleUserID = e.id;
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },
  },

  watch: {
    paymentSchedule() {
      this.$emit("paymentScheduleChanged", this.paymentSchedule);
    },

    deadline() {
      this.endDate = this.deadline;
    },
  },

  computed: {
    form(): Vue {
      return this.$refs.form as Vue;
    },

    scheduleItemsTotal() {
      return this.items?.reduce((acc, current) => (acc += current?.amount), 0);
    },

    scheduleItemsLeft() {
      return this.scheduleItemsTotal - this.invoiceTotal;
    },

    columns: () => [
      { field: "paymentMode", title: "Fizetési mód", cell: "paymentModeTemplate" },
      { field: "bankAccount", title: "Bankszámla", cell: "bankAccountTemplate" },
      { field: "deadlineDisplay", title: "Fizetési határidő", cell: "deadlineTemplate" },
      { field: "amount", title: "Összeg", headerClassName: "right_text", cell: "amountTemplate" },
      { width: 100, cell: "commandTemplate" },
    ],

    items() {
      return this.paymentSchedule.items;
    },

    customSchedulingEnabled() {
      return this.scheduleOption === PaymentScheduleOption.Custom;
    },
  },
});
