













import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import Partner from "../../partner/models/Partner";
import Vue from "vue";
import store, { DebitPaymentStore } from "../services/DebitPaymentStore";

interface Data {
  store: DebitPaymentStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    PartnerSearch,
  },

  data: () => ({
    store,
  }),

  methods: {
    partnerSelected(partner: Partner) {
      this.store.debitPayment.incomingInvoice.partner = partner.id;
      this.store.debitPayment.partnerName = partner.name;

      this.$router.push(`/debit-payment/create/${partner.id}`);
    },

    cancel() {
      this.$router.push(`/debit-payment`);
    },
  },
});
