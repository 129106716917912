import AccountSubtype from "@/modules/accountType/models/AccountSubtype";
import http, { handleApiResponse, checkResponse } from "@/services/http";
import AccountType from "@/modules/accountType/models/AccountType";
import ApiResponse from "@/models/ApiResponse";

export default class AccountTypeApi {
  static getAll(filterDeleted = true): Promise<AccountType[]> {
    return http
      .get<ApiResponse<AccountType[]>>(`account/account-type`, { params: { filterDeleted: filterDeleted } })
      .then(handleApiResponse);
  }

  static get(accountTypeId: number): Promise<AccountType> {
    return http.get<ApiResponse<AccountType>>(`account/account-type/${accountTypeId}`).then(handleApiResponse);
  }

  static update(accountType: AccountType): Promise<boolean> {
    return http.put(`account/account-type/${accountType.id}`, accountType).then(checkResponse);
  }

  static create(accountType: AccountType): Promise<AccountType> {
    return http.post<ApiResponse<AccountType>>(`account/account-type`, accountType).then(handleApiResponse);
  }

  static delete(accountTypeId: number): Promise<boolean> {
    return http.delete(`account/account-type/${accountTypeId}`).then(checkResponse);
  }

  static getSubtypes(filterDeleted = true): Promise<AccountSubtype[]> {
    return http
      .get<ApiResponse<AccountSubtype[]>>(`account-subtype`, { params: { filterDeleted: filterDeleted } })
      .then(handleApiResponse);
  }
}
