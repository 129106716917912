




















































































import Vue from "vue";
import InvoiceFlowApi from "../services/InvoiceFlowApi";
import { InvoiceAcceptRequest, InvoiceControlRequest } from "../models/InvoiceRequest";
import { trimDate, trimTime } from "@/utils/formatDates";

interface Data {
  controllerRequests: InvoiceControlRequest[];
  acceptorRequests: InvoiceAcceptRequest[];
}

interface Props {
  invoice: any;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    invoice: Object,
  },

  data: () => ({
    controllerRequests: [],
    acceptorRequests: [],
  }),

  async beforeMount() {
    await InvoiceFlowApi.getControlRequests(this.invoice.id).then((result) => {
      const requests = result.map((x) => ({
        ...x,
        modDateTime: trimDate(x.modDate) + "T" + trimTime(x.modTime),
      }));
      this.controllerRequests = requests;
    });

    await InvoiceFlowApi.getAcceptRequests(this.invoice.id).then((result) => {
      const requests = result.map((x) => ({
        ...x,
        modDateTime: trimDate(x.modDate) + "T" + trimTime(x.modTime),
      }));
      this.acceptorRequests = requests;
    });
  },
});
