




















































































import Vue from "vue";
import store, { InvoiceGroupStore } from "../services/InvoiceGroupStore";
import Page from "../../../components/Page.vue";
import InvoiceGroup from "../models/InvoiceGroup";
import setAttribute from "../../../utils/dom";

interface Data {
  store: InvoiceGroupStore;
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "8");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    confirmation: confirmationTemplate,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadInvoiceGroups();
  },

  computed: {
    columns: () => [
      { field: "name", title: "Megnevezés" },
      { field: "dimCodeIn", title: "Dim. kód (bejövő)" },
      { field: "dimCodeOut", title: "Dim. kód (kimenő)" },
      { field: "controlPerson", title: "Ellenőrző" },
      { field: "user", title: "Átvevő" },
      { field: "defDivItem", title: "Alapértért aldiv" },
      { field: "contrType", title: "Költséghely típus" },
      { cell: "commandTemplate", width: "120px" },
      { width: 0 },
    ],

    items() {
      return this.store.invoiceGroups;
    },
  },

  methods: {
    showDetails(item: InvoiceGroup) {
      this.$router.push(`/invoice-group/${item.id}/details`);
    },

    createInvoiceGroup() {
      this.$router.push(`/invoice-group/create`);
    },

    updateInvoiceGroup(item: InvoiceGroup) {
      this.$router.push(`/invoice-group/${item.id}/edit`);
    },

    deleteInvoiceGroup(item: InvoiceGroup) {
      this.store.deleteInvoiceGroup(item.id);
    },

    deleteItem(item: InvoiceGroup) {
      this.store.setDeleting(item);
    },

    confirmDelete(item: InvoiceGroup) {
      item.deleting = false;
      this.store.deleteInvoiceGroup(item.id);
    },

    cancelDelete(item: InvoiceGroup) {
      item.deleting = false;
    },
  },
});
