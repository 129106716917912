import busyIndication from "@/utils/busyIndication";
import FixAssetDocumentTemplate from "../models/FixAssetDocumentTemplate";
import FixAssetGroup, { fixAssetGroupDefaultProps } from "../models/FixAssetGroup";
import FixAssetMainGroup from "../models/FixAssetMainGroup";
import FixAssetGroupApi from "./FixAssetGroupApi";

export class FixAssetGroupStore {
    fixAssetMainGroups: FixAssetMainGroup[] = [];
    fixAssetGroups: FixAssetGroup[] = [];
    fixAssetGroup: FixAssetGroup = { ...fixAssetGroupDefaultProps };
  
    @busyIndication()
    async loadFixAssetGroups() {
      this.fixAssetGroups = await FixAssetGroupApi.getAll();
    }

     async loadFixAssetGroupsByMainGroup(id: number) {
      this.fixAssetGroups = await FixAssetGroupApi.getAllByMainGroup(id);
    }

    async loadFixAssetMainGroups() {
      this.fixAssetMainGroups = await FixAssetGroupApi.getAllMainGroups();
    }

    @busyIndication()
    async loadFixAssetGroup(id: number) {
      this.fixAssetGroup = await FixAssetGroupApi.get(id);
    }

    async save(item: FixAssetDocumentTemplate) {
      if (item.id === 1) {
        await this.create(item);
      } else {
        await this.update(item);
      }
    }
  
    private async create(item: FixAssetDocumentTemplate) {
      try {
        const id = await FixAssetGroupApi.create(item);
      } catch (err) {
        // TODO: notification
        console.error(err);
      }
    }
  
    private async update(item: FixAssetDocumentTemplate) {
      try {
        await FixAssetGroupApi.update(item);
      } catch (err) {
        // TODO: notification
        console.error(err);
      }
    }

    async loadFixAssetGroupFiles(id: number) {
      this.fixAssetGroup = await FixAssetGroupApi.getFiles(id);
      console.log(this.fixAssetGroup);
    }

    async createDefaultDocuments(id: number, assetId: number){
      await FixAssetGroupApi.createDefaultDocuments(id, assetId);
    }
}

const store = new FixAssetGroupStore();
export default store;
