























































import Vue from "vue";
import store, { NoticeStore } from "../services/NoticeStore";
import Page from "../../../components/Page.vue";
import NoticeDisplay from "../models/NoticeDisplay";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import dayjs from "dayjs";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";

interface Data {
  store: NoticeStore;
  loading: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    ApiSearchPage,
  },

  data(): Data {
    return {
      store,
      loading: false,
    };
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      { field: "entityId", title: "Számlaszám", cell: "entityIdTemplate" },
      { field: "partner", title: "Partner" },
      { field: "type", title: "Típus" },
      { field: "status", title: "Státusz" },
      { field: "genoType", title: "GENO típus" },
      {
        field: "entityCurrNetto",
        title: "Nettó",
        //cell: "entityCurrNettoTemplate",
        headerClass: "align__right",
        fieldClass: "align__right",
        type: ColumnType.Money
      },
      {
        field: "entityCurrVat",
        title: "ÁFA",
        //cell: "entityCurrVatTemplate",
        headerClass: "align__right",
        fieldClass: "align__right",
        type: ColumnType.Money
      },
      {
        field: "entityCurrTotal",
        title: "Totál",
        //cell: "entityCurrTotalTemplate",
        headerClass: "align__right",
        fieldClass: "align__right",
        type: ColumnType.Money
      },
      { field: "entityInvCurrency", title: "Pénznem", cell: "entityInvCurrencyTemplate" },
      {
        field: "invDate",
        title: "Értesítő dátuma",
        cell: "invDateTemplate",
        headerClass: "align__center",
        fieldClass: "align__center",
      },
      {
        field: "paymentTerm",
        title: "Fizetési határidő",
        cell: "paymentTermTemplate",
        headerClass: "align__center",
        fieldClass: "align__center",
      },

      { cell: "commandTemplate", filterable: false },
    ],
  },

  methods: {
    newClick() {
      this.$router.push(`/notice/create`);
    },

    modify(item: NoticeDisplay) {
      this.$router.push(`/noto/${item.entity.id}`);
    },

    convertToDate(dateString: string) {
      if (dayjs(dateString).isValid()) {
        return dayjs(dateString).format("YYYY-MM-DD");
      }
      return "-";
    },
  },
});
