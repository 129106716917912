





























































































































































import DateInput from "@/components/DateInput.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import Partner from "@/modules/partner/models/Partner";
import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import Vue from "vue";
import { store, VIRIStore } from "../services/VIRIStore";
import {
  TechnicalInvoiceVIRI,
  technicalInvoiceVIRIDefaultProps,
} from "@/modules/technicalInvoice/models/TechnicalInvoiceModel";
import eventBus from "@/services/eventBus";
import { Prop } from "vue/types/options";
import { TechnicalInvoiceVIRIType } from "@/modules/technicalInvoice/models/TechnicalInvoiceTypeModel";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import {
  TechnicalInvoiceItemVIRI,
  technicalInvoiceVIRODefaultProps,
} from "@/modules/technicalInvoice/models/TechnicalInvoiceItemModel";
import TextInput from "@/components/TextInput.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import CarSearch from "@/components/carSearch/CarSearch.vue";

interface Data {
  store: VIRIStore;
  partnerSearchDialog: boolean;
  detailsVisible: boolean;
  rules: object;
  backRoute: string;
}

interface Methods {
  saveHead(): void;
  createItem(): void;
  removeItem(item: TechnicalInvoiceItemVIRI): void;
  navigateBack(): void;
}

interface Computed {
  tInvoice: TechnicalInvoiceVIRI;
  detailsIcon: string;
  headForm: Vue & { validate: () => boolean };
  // itemForm: Vue & { validate: () => boolean }; // TODO
  partnerFullAdress: string;
  itemColumns: Array<object>;
  total: number;
  saved: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
  components: {
    MoneyDisplay,
    MoneyInput,
    DateInput,
    PartnerSearch,
    TextInput,
    CarSearch,
  },

  data() {
    return {
      store,
      partnerSearchDialog: false,
      detailsVisible: false,
      rules: {
        requiredRule: (v: string) => !!v.trim() || "Kötelező megadni",
        requiredSelectRule: (v: number) => v !== 1 || "Kötelező megadni",
      },
      sort: [],
      filter: {
        logic: "and",
        filters: [],
      },
      backRoute: "",
    };
  },

  async created() {
    // Adatmezők beállítása
    // meglévő számla esetén
    if (this.$route.params.invoiceId) {
      try {
        await this.store.getViri(+this.$route.params.invoiceId);
        await this.store.getViriItems(+this.$route.params.invoiceId);
      } catch (error) {
        eventBus.$emit("error", error);
      }
    }
    // új számla esetén
    else {
      await store.getNewViri();
    }
  },

  async beforeMount() {
    await this.store.loadVirCheckTypes();
    await this.store.loadPmodes();
    await this.store.loadAllInvTypes();
  },

  destroyed() {
    this.store.resetTInvoice();
    this.store.resetTInvoicePartner();
    this.store.resetTInvoiceItems();
  },

  methods: {
    async removeItem(item: TechnicalInvoiceItemVIRI) {
      try {
        if (item.id > 1) {
          await this.store.removeItem(item.id);
          await this.store.getViriItems(this.tInvoice.id);
        } else {
          const index = this.store.tInvoiceVIRIItems.indexOf(item);
          if (index > -1) {
            this.store.tInvoiceVIRIItems.splice(index, 1);
          }
        }

        eventBus.$emit("success", "Törölve");
      } catch (error) {
        eventBus.$emit("error", "Törlés sikertelen!");
      }
    },

    createItem() {
      const newItem = { ...technicalInvoiceVIRODefaultProps };
      newItem.pmode = 2;
      newItem.checkType = this.store.checkTypes[0]?.id ?? 1;
      this.store.tInvoiceVIRIItems.push(newItem);
    },

    async saveHead() {
      this.tInvoice.total = this.total;
      this.tInvoice.netto = this.tInvoice.total;
      this.tInvoice.currNetto = this.tInvoice.total;
      this.tInvoice.currTotal = this.tInvoice.total;

      // új számla mentés ha minden validáción átment - vissza kellene kapjuk az apitól az id-t
      this.tInvoice.id = await this.store.saveNewTInvoice(this.tInvoice);

      if(this.tInvoice.id < 2)
      {
        this.$emit("error", "A számla mentése sikertelen!")
        return;
      }

      try {     
      for (let i = 0; i < this.store.tInvoiceVIRIItems.length; i++) {
        if (this.store.tInvoiceVIRIItems[i].partner <= 1)
          throw new Error("A tételekben a partner megadása kötelező!");
        if (this.store.tInvoiceVIRIItems[i].car <= 1) 
          throw new Error("A tételekben a gépjármű megadása kötelező!");
        this.store.tInvoiceVIRIItems[i].invNo = this.tInvoice.id;
        this.store.tInvoiceVIRIItems[i].id = await this.store.saveNewTInvoiceItem(this.store.tInvoiceVIRIItems[i]);
      }
    }
    catch(err)
    {
      this.$emit("error", err.message);
    }

    this.store.getViriItems(this.tInvoice.id);
    this.$router.push(`/viri-item/edit/${this.tInvoice.id}`);
    eventBus.$emit("success", "Számla mentése sikeres!");
    },

    navigateBack() {
      if (this.backRoute.includes("viri-item")) {
        this.$router.go(-2);
        return;
      }
      this.$router.push(this.backRoute);
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$data.backRoute = from.fullPath;
    });
  },

  computed: {
    saved() {
      return this.store.tInvoiceVIRI.id > 1;
    },

    total() {
      const result = this.store.tInvoiceVIRIItems.reduce((x, y) => {
        return x + y.total;
      }, 0);
      return result;
    },

    tInvoice() {
      return this.store.tInvoiceVIRI;
    },

    detailsIcon() {
      return this.detailsVisible ? "fa-angle-double-up" : "fa-angle-double-down";
    },

    headForm(): Vue & { validate: () => boolean } {
      return this.$refs.headForm as Vue & { validate: () => boolean }; // boiler plate kód netről, a form validáláshoz
    },

    partnerFullAdress() {
      return `${this.store.tInvoicePartner.postCode || ""} ${this.store.tInvoicePartner.invoiceCity || ""} ${this.store
        .tInvoicePartner.invoiceAddress || ""}`;
    },

    itemColumns: () => [
      { field: "partner", title: "Partner", cell: "partner" },
      { field: "car", title: "Gépjármű", cell: "car" },
      { field: "pmode", title: "Fizetési mód", cell: "pMode" },
      { field: "checkType", title: "Típus", cell: "checkTypes", width: "250px" },
      { field: "total", title: "Összeg", cell: "total" },
      { field: "check_id", title: "Csekk azonosító", cell: "checkId" },
      {
        title: "",
        cell: "commandTemplate",
        width: 60,
      },
      { width: 0, filterable: false },
    ],
  },
});
