import Vue from "vue";

type Listener = (payload: any) => void;

class EventBus {
  listeners: Record<string, Listener[]> = {};

  $emit(event: string, payload: any = null) {
    (this.listeners[event] || []).forEach((l) => {
      Vue.nextTick(() => l(payload));
    });
  }

  $error(payload: any = null) {
    const event = "error";
    (this.listeners[event] || []).forEach((l) => {
      Vue.nextTick(() => l(payload));
    });
  }

  $busy(payload: any = null) {
    const event = "busy-message";
    (this.listeners[event] || []).forEach((l) => {
      Vue.nextTick(() => l(payload));
    });
  }

  $on(event: string, listener: Listener) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    if (this.listeners[event].some((l) => l.name === listener.name)) {
      this.listeners[event] = this.listeners[event].filter(
        (l) => l.name !== listener.name
      );
    }

    this.listeners[event].push(listener);
  }

  $off(event: string, listener: Listener) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(
        (l) => l !== listener
      );
    }
  }

  clear() {
    this.listeners = {};
  }
}

let eventBus = new EventBus();

export function createEventBus() {
  if (eventBus) {
    eventBus.clear();
  }

  eventBus = new EventBus();
  return eventBus;
}

export default eventBus;
