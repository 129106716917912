

















import PdfViewer from "@/components/PdfViewer.vue";
import Vue from "vue";
import store from "../services/store";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import base64toUInt8Array from "@/services/fileConvert";
import cashStatementStore, { CashStatementStore } from "@/modules/cashStatement/services/store";
import ButtonCheckBox from "@/components/ButtonCheckBox.vue";

export default Vue.extend({
  data: () => {
    return {
      store,
    };
  },

  async beforeMount(){
     const expReportSrc = await http
        .get<ApiResponse<string>>(
          `experimental-invoices/report/${store.experimentalInvoice}/${store.experimentalInvoicePrefix}`
        )
        .then(handleApiResponse)
        .then(x=>{
          return x;
        });

        this.store.expReportSrc = base64toUInt8Array(expReportSrc);
  },

  components: {
    PdfViewer,
    ButtonCheckBox
  },
});
