




















































































































































































































































































































































































































































































































import Vue from "vue";
import BankDocument from "../models/BankDocument";
import { BankStatementDTO } from "../models/BankStatement";
import store, { BankStatementStore } from "../services/BankStatementStore";
import BankDocumentDetails from "./BankDocumentDetails.vue";
import PagingElement, { pagingElementDefaultProps } from "../models/PagingElement";
import BankStatementApi from "../services/BankStatementApi";

interface Data {
  store: BankStatementStore;
  documentTitle: string;
  selectedDocumentID: number;
  selectedStatementID: number;
  selectedStatement: BankStatementDTO;
  activeStatementTab: number;

  filteredDocuments: BankDocument[];
  selectedDocumentField: string;
  isIncome: boolean;

  selectedDocuments: BankDocument[];

  firstPagingElement: PagingElement;
  secondPagingElement: PagingElement;
}

interface Methods {
  changeActiveStatementTab(activeStatementTab: number, isIncome?: boolean): void;
  setCurrentPageNumber(pageNumber: number, e: PagingElement): void;
  setCurrentPageSize(pageSize: number, e: PagingElement): void;
  setPageNumbers(e: PagingElement): void;
  filterDocumentsByIncome(isIncome: boolean): void;

  incrementPageNumber(e: PagingElement): void;
  decrementPageNumber(e: PagingElement): void;
  setDocumentsCountByIncome(e: PagingElement, income?: boolean): void;
  setDocumentsCountToAll(e: PagingElement): void;
  sliceByPageNumberAndSize(e: PagingElement, resultList: any): any | any[];
}

export default Vue.extend<Data, Methods, {}, {}>({
  props: { statementID: Number },
  components: {
    BankDocumentDetails,
  },

  data() {
    return {
      store,
      documentTitle: "Bizonylatok",
      selectedDocumentID: 0,
      selectedStatementID: 0,
      selectedStatement: new BankStatementDTO(),
      activeStatementTab: 0,

      filteredDocuments: [],
      selectedDocumentField: "selected",
      isIncome: true,

      selectedDocuments: [],

      firstPagingElement: { ...pagingElementDefaultProps },
      secondPagingElement: { ...pagingElementDefaultProps },
    };
  },

  async beforeMount() {
    this.changeActiveStatementTab(0);
    this.store.loadBankDocumentsByStatementId(this.$props.statementID);
    this.selectedDocuments = this.store.bankDocuments;

    // this.selectedStatement = this.store.bankStatements.find((s) => s.id === this.$props.statementID) ?? {
    //   ...bankStatementDefaultProps,
    // };

    this.selectedStatement = await BankStatementApi.getBankStatementDTO(this.$props.statementID) ?? new BankStatementDTO();

    console.log(this.store.bankStatements);

    console.log(this.selectedStatement);
  },

  computed: {
    maxPageNumberExpenses() {
      return Math.ceil(this.secondPagingElement.documentsCount / this.secondPagingElement.currentPageSize);
    },

    mixedColumns: () => [
      { width: 50 },
      //{ width: 150, field: "uniqueNo", title: "Sorszám" },
      { field: "status", title: "Állapot" },
      {
        className: "customIncomes",
        field: "incomesMixed",
        title: "Bevételek",
      },
      {
        className: "customExpenses",
        field: "expensesMixed",
        title: "Kiadások",
      },
    ],

    mixedItems() {
      const resultList = this.selectedDocuments.map((d: BankDocument) => ({
        //uniqueNo: d.extNo,
        status: d.status,
        incomesMixed: d.bankStatementItem.crediting ? "+ " + d.bankStatementItem.amount.toLocaleString() + " " + d.bankStatementItem.currency : "",
        expensesMixed: !d.bankStatementItem.crediting ? "- " + d.bankStatementItem.amount.toLocaleString() + " " + d.bankStatementItem.currency : "",
      }));
      return this.sliceByPageNumberAndSize(this.firstPagingElement, resultList);
    },

    columns: () => [
      { width: 50, cell: "documentDetailsButtonTemplate" },
      { field: "partner", title: "Partner" },
      { field: "bankStatementItem.amount", title: "Összeg", format: "{0: #,##.##}" },
      { field: "bankStatementItem.currency", title: "Pénznem" },
      { field: "status", title: "Állapot" },
      { field: "accountType", title: "Tétel típus" },
      {
        field: "user",
        title: "Rögzítő",
      },
      {
        field: "bankStatementItem.insDate",
        title: "Rögz. dát.",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
      },
      {
        field: "bankStatementItem.exchangeRate",
        title: "Árfoly.",
      },
      { width: 0 },
    ],

    items() {
      return this.sliceByPageNumberAndSize(this.firstPagingElement, this.filteredDocuments);
    },

    incomes() {
      const resultList = this.filteredDocuments?.filter((d: BankDocument) => d.bankStatementItem.crediting === true);
      return this.sliceByPageNumberAndSize(this.firstPagingElement, resultList);
    },

    expenses() {
      const resultList = this.filteredDocuments?.filter((d: BankDocument) => d.bankStatementItem.crediting === false);
      return this.sliceByPageNumberAndSize(this.secondPagingElement, resultList);
    },

    totalIncomeInCurr() {
      let totalIncome = 0;
      this.filteredDocuments
        .filter(
          (d: BankDocument) => d.bankStatementItem.crediting === true // && d.status === ("Fizetve" || "Nyomtatva")
        )
        .forEach((d: BankDocument) => {
          totalIncome += d.bankStatementItem.currAmount;
        });
      return totalIncome;
    },

    totalExpenseInCurr() {
      let totalExpense = 0;
      this.filteredDocuments
        .filter(
          (d: BankDocument) => d.bankStatementItem.crediting === false // && d.status === ("Fizetve" || "Nyomtatva")
        )
        .forEach((d: BankDocument) => {
          totalExpense += d.bankStatementItem.currAmount;
        });
      return totalExpense;
    },

    totalOutHUF() {
      let totalOut = 0;
      this.filteredDocuments
        .filter((d) => d.bankStatementItem.crediting === false)
        .forEach((d) => {
          totalOut += d.bankStatementItem.amount;
        });
      return totalOut;
    },

    openInHUF() {
      return (
        (this.selectedStatement?.openBalance * this.selectedStatement?.openRate).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + " Ft"
      );
    },

    closeInHUF() {
      return (
        (this.selectedStatement?.closeBalance * this.selectedStatement?.closeRate).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + " Ft"
      );
    },

    footerTextIncomes() {
      return `${this.firstPagingElement.documentsCount} / ${Math.max(
        (this.firstPagingElement.currentPageNumber - 1) * this.firstPagingElement.currentPageSize + 1,
        0
      )} - ${Math.min(
        this.firstPagingElement.currentPageNumber * this.firstPagingElement.currentPageSize,
        this.firstPagingElement.documentsCount
      )}`;
    },

    footerTextExpenses() {
      return `${this.secondPagingElement.documentsCount} / ${Math.max(
        (this.secondPagingElement.currentPageNumber - 1) * this.secondPagingElement.currentPageSize + 1,
        0
      )} - ${Math.min(
        this.secondPagingElement.currentPageNumber * this.secondPagingElement.currentPageSize,
        this.secondPagingElement.documentsCount
      )}`;
    },

    maxPageNumberIncomes() {
      return Math.ceil(this.firstPagingElement.documentsCount / this.firstPagingElement.currentPageSize);
    },
  },

  methods: {
    setDocumentsCountToAll(e: PagingElement) {
      e.documentsCount = this.filteredDocuments.length;
    },

    setDocumentsCountByIncome(e: PagingElement, income = true) {
      const filtered = this.filteredDocuments?.filter((d) => d.bankStatementItem.crediting === income);
      e.documentsCount = filtered.length;
    },

    decrementPageNumber(e: PagingElement) {
      if (e.currentPageNumber == 1) {
        return;
      }
      e.currentPageNumber--;
    },

    incrementPageNumber(e: PagingElement) {
      const maxPageNumber = Math.ceil(e.documentsCount / e.currentPageSize);
      if (e.currentPageNumber == maxPageNumber) {
        return;
      }
      e.currentPageNumber++;
    },

    changeActiveStatementTab(activeStatementTab: number, isIncome = true) {
      this.activeStatementTab = activeStatementTab;
      this.firstPagingElement = { ...pagingElementDefaultProps };
      this.secondPagingElement = { ...pagingElementDefaultProps };

      if (activeStatementTab === 1) {
        this.documentTitle = "Bevételi bizonylatok";
        this.filterDocumentsByIncome(isIncome);
        this.setDocumentsCountByIncome(this.firstPagingElement);
      } else if (activeStatementTab === 2) {
        this.documentTitle = "Kiadási bizonylatok";
        this.filterDocumentsByIncome(isIncome);
        this.setDocumentsCountByIncome(this.firstPagingElement, false);
      } else if (activeStatementTab === 3) {
        this.documentTitle = "Bizonylatok";
        this.isIncome = true;
        this.filteredDocuments = this.selectedDocuments;
        this.setDocumentsCountByIncome(this.firstPagingElement);
        this.setDocumentsCountByIncome(this.secondPagingElement, false);
      } else if (activeStatementTab === 4) {
        this.documentTitle = "Bizonylat mozgások";
        this.filteredDocuments = this.selectedDocuments;
        this.setDocumentsCountToAll(this.firstPagingElement);
      }

      this.setCurrentPageNumber(1, this.firstPagingElement);
    },

    setCurrentPageNumber(pageNumber: number, e: PagingElement) {
      e.currentPageNumber = pageNumber;
    },

    setCurrentPageSize(pageSize: number, e: PagingElement) {
      this.setCurrentPageNumber(1, e);
      e.currentPageSize = pageSize;
    },

    filterDocumentsByIncome(isIncome: boolean) {
      if (this.isIncome !== isIncome) {
        this.isIncome = isIncome;
      }
      this.filteredDocuments = this.selectedDocuments.filter((x) => x.bankStatementItem.crediting == isIncome);
    },

    setPageNumbers(e: PagingElement) {
      if (e.currentPageNumber == 0) {
        return;
      }

      const maxPageNumber = Math.ceil(e.documentsCount / e.currentPageSize);

      if (maxPageNumber <= 5) {
        e.firstPageNumberButtonVisible = false;
        e.secondPageNumberButtonVisible = false;
        e.thirdPageNumberButtonVisible = false;
        e.fourthPageNumberButtonVisible = false;
        e.fifthPageNumberButtonVisible = false;

        if (maxPageNumber >= 1) {
          e.firstPageNumberButtonVisible = true;
          e.firstPageNumberButtonValue = 1;
        }

        if (maxPageNumber >= 2) {
          e.secondPageNumberButtonVisible = true;
          e.secondPageNumberButtonValue = 2;
        }

        if (maxPageNumber >= 3) {
          e.thirdPageNumberButtonVisible = true;
          e.thirdPageNumberButtonValue = 3;
        }

        if (maxPageNumber >= 4) {
          e.fourthPageNumberButtonVisible = true;
          e.fourthPageNumberButtonValue = 4;
        }

        if (maxPageNumber == 5) {
          e.fifthPageNumberButtonVisible = true;
          e.fifthPageNumberButtonValue = 5;
        }
      } else {
        e.firstPageNumberButtonVisible = true;
        e.secondPageNumberButtonVisible = true;
        e.thirdPageNumberButtonVisible = true;
        e.fourthPageNumberButtonVisible = true;
        e.fifthPageNumberButtonVisible = true;

        if (maxPageNumber - e.currentPageNumber < 5) {
          e.firstPageNumberButtonValue = maxPageNumber - 4;
          e.secondPageNumberButtonValue = maxPageNumber - 3;
          e.thirdPageNumberButtonValue = maxPageNumber - 2;
          e.fourthPageNumberButtonValue = maxPageNumber - 1;
          e.fifthPageNumberButtonValue = maxPageNumber;
        } else {
          if (e.currentPageNumber < 5) {
            e.firstPageNumberButtonValue = 1;
            e.secondPageNumberButtonValue = 2;
            e.thirdPageNumberButtonValue = 3;
            e.fourthPageNumberButtonValue = 4;
            e.fifthPageNumberButtonValue = 5;
          } else {
            e.firstPageNumberButtonValue = e.currentPageNumber - 2;
            e.secondPageNumberButtonValue = e.currentPageNumber - 1;
            e.thirdPageNumberButtonValue = e.currentPageNumber;
            e.fourthPageNumberButtonValue = e.currentPageNumber + 1;
            e.fifthPageNumberButtonValue = e.currentPageNumber + 2;
          }
        }
      }
    },

    sliceByPageNumberAndSize(e: PagingElement, resultList: any) {
      this.setPageNumbers(e);
      const pageNumber = e.currentPageNumber - 1;
      const pageSize = e.currentPageSize;
      const startNumber = pageNumber * pageSize;
      return resultList?.slice(startNumber, startNumber + pageSize) ?? [];
    },
  },
});
