

















































































































































































































































import InvoiceItemDimensionView from "../../invoiceItemDimension/views/InvoiceItemDimensionView.vue";

import Vue from "vue";
import eventBus from "../../../services/eventBus";
import store, { FlowStatus, InvoiceFlowStore } from "../services/InvoiceFlowStore";
import permStore, { PermissionStore, InvoicePermissions } from "@/services/permissions";
import gStore, { GeneralIngoingInvoiceStore } from "../../generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import iStore, { InvoiceItemDimensionStore } from "../../invoiceItemDimension/services/InvoiceItemDimensionStore";
import pStore, { SerPartStore } from "../../serPart/services/SerPartStore";
import sStore, { SerStationStore } from "../../serStation/services/SerStationStore";
import sgStore, { SerStationGroupStore } from "../../serStationGroup/services/SerStationGroupStore";
import hStore, { ProjectHeadStore } from "../../projectHead/services/ProjectHeadStore";
import uStore, { UserStore } from "../../user/services/UserStore";
import igStore, { InvoiceGroupStore } from "../../invoiceGroup/services/InvoiceGroupStore";
import cStore, { SerCarStore } from "../../serCar/services/SerCarStore";
import paStore, { PartStore } from "../../part/services/PartStore";
import dStore, { DimCodeTypeStore } from "../../dimCodeType/services/DimCodeTypeStore";
import GeneralIngoingInvoiceItemMinEditModel from "../../generalIngoingInvoice/models/GeneralIngoingInvoiceItemMinEditModel";
import InvoiceItemFreeDimension from "@/modules/invoiceItemDimension/views/InvoiceItemFreeDimension.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import configuration, { AppConfiguration } from "../../../models/Configuration";

interface Data {
  store: InvoiceFlowStore;
  gStore: GeneralIngoingInvoiceStore;
  iStore: InvoiceItemDimensionStore;
  pStore: SerPartStore;
  sStore: SerStationStore;
  uStore: UserStore;
  sgStore: SerStationGroupStore;
  hStore: ProjectHeadStore;
  cStore: SerCarStore;
  paStore: PartStore;
  igStore: InvoiceGroupStore;
  dStore: DimCodeTypeStore;

  permStore: PermissionStore;
  invoiceId: number;

  childCreating: boolean;
  configuration: AppConfiguration;
}

interface Methods {
  addInvoiceGroup(invoiceItem: GeneralIngoingInvoiceItemMinEditModel): void;
  showDimensions(dataItem: GeneralIngoingInvoiceItemMinEditModel): void;
  refreshAll(): void;
  setChildCreating(): void;
  editSwitch(item: GeneralIngoingInvoiceItemMinEditModel): void;
  cancelFreeDimUpdate(item: GeneralIngoingInvoiceItemMinEditModel): void;

  showMessageInParent(invoiceItemName: string): void;
  saveComment(i: GeneralIngoingInvoiceItemMinEditModel, e: KeyboardEvent): void;
}

interface Props {
  accepting: boolean;
  disabled: boolean;
  isEditAfterVatConfession: boolean;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: {
    InvoiceItemDimensionView,
    InvoiceItemFreeDimension,
    MoneyDisplay,
  },

  props: {
    accepting: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    isEditAfterVatConfession: { type: Boolean, default: false },
  },

  data: () => ({
    store,
    gStore,
    iStore,
    pStore,
    sStore,
    uStore,
    sgStore,
    hStore,
    cStore,
    igStore,
    permStore,
    paStore,
    dStore,

    invoiceId: 0,

    childCreating: false,
    configuration,
  }),

  async beforeMount() {
    this.invoiceId = Number(this.$route.params.invoiceId) || 1;

    await this.dStore.loadDimCodeTypesWithoutBusy();
    await this.igStore.loadInvoiceGroupsWithoutBusy();
    await this.gStore.loadGeneralIngoingInvoiceItemMinEditModelsByInvId(this.invoiceId, this.store.step != 6 ? true : false);

    await this.store.loadProjectHeads(this.store.invoice.partner);

    await this.sgStore.loadSerStationGroups();

    //await this.cStore.loadSerCarRegNumsBy("");
  },

  destroyed() {
    this.gStore.invoiceItemMinEditModelsByInvId = [];
  },

  methods: {
    async saveComment(i: GeneralIngoingInvoiceItemMinEditModel, e: KeyboardEvent) {
      if (e.key != "Enter") {
        return;
      }

      e.preventDefault();
      i.savingComment = true;
      await setTimeout(() => {
        this.iStore.updateComment(i.id, i);
        i.savingComment = false;
      }, 1000);
    },

    cancelFreeDimUpdate(item) {
      item.dim5edit = false;
    },

    editSwitch(item) {
      item.dim5edit = !item.dim5edit;
      item.expanded = item.expanded ? true : item.dim5edit;
    },

    showMessageInParent(invoiceItemName: string) {
      this.$emit("showMessage", invoiceItemName);
    },

    addInvoiceGroup(invoiceItem: GeneralIngoingInvoiceItemMinEditModel) {
      invoiceItem.expanded = true;

      const eventHandlerName = "InvoiceGroupDimensionCreating" + invoiceItem.id;

      this.$nextTick(function() {
        eventBus.$emit(eventHandlerName, invoiceItem.id);
      });
      this.showMessageInParent(invoiceItem.name);

      this.setChildCreating();
    },

    async showDimensions(dataItem: GeneralIngoingInvoiceItemMinEditModel) {
      if (!dataItem.expanded) {
        await this.iStore.loadInvoiceItemDimensionsByInvItemId(dataItem.id, this.store.step != 6 ? true : false);
      }
      dataItem.expanded = !dataItem.expanded;
      dataItem.dim5edit = dataItem.expanded ? dataItem.expanded : false;
    },

    async refreshAll() {
      await this.gStore.loadGeneralIngoingInvoiceItemMinEditModelsByInvId(this.invoiceId, this.store.step != 6 ? true : false);
      await this.store.loadAcceptState();
    },

    setChildCreating() {
      this.childCreating = !this.childCreating;
    },
  },

  computed: {
    getPrecision() {
      const curr = this.store.invoice.invCurrency;
      const digits = this.configuration.operel ? 2 : this.configuration.getCurrDigits(curr)?.geniItemDigits;
      return digits ?? 0;
    },

    columns() {
      return [
        {
          cell: "selectionTemplate",
          filterable: false,
          width: 30,
        },
        {
          title: "",
          cell: "addInvoiceGroupTemplate",
          filterable: false,
          className: "no_padding_left",
          width: 90,
        },
        {
          title: "Tétel",
          field: "name",
          className: "no_padding_left",
          width: 200,
        },
        {
          title: "Mennyiség",
          field: "quantity",
          cell: "quantityTemplate",
          format: "{0: #,##.##}",
          className: "no_padding_right",
          headerClassName: "right_center",
          width: 90,
        },
        {
          title: "ÁFA kulcs",
          field: "tax",
          className: "no_padding_center",
          width: 70,
        },
        {
          title: "Egységár",
          field: "unitPrice",
          cell: "unitPriceTemplate",
          className: "no_padding_right",
          headerClassName: "right_text",
        },
        {
          title: "Nettó",
          field: "currNetto",
          cell: "currNettoTemplate",
          className: "no_padding_right",
          headerClassName: "right_text",
        },
        {
          title: "ÁFA",
          field: "currVat",
          cell: "currVatTemplate",
          className: "no_padding_right",
          headerClassName: "right_text",
        },
        {
          title: "Bruttó",
          field: "currBrutto",
          cell: "currBruttoTemplate",
          className: "no_padding_right",
          headerClassName: "right_text",
        },
        {
          title: "Fennmaradó",
          field: "remaining",
          cell: "remainingTemplate",
          className: "no_padding_right",
          headerClassName: "right_text",
        },
        {
          cell: "noteTemplate",
          title: "Megjegyzés",
          field: "note",
        },
        {
          cell: "editSwitchTemplate",
          filterable: false,
          width: 50,
        },
        {
          width: 0,
          filterable: false,
          className: "no_padding_left",
        },
      ];
    },

    isPageDisabled() {
      if (this.isEditAfterVatConfession){
        return false;
      }

      const stepDone = this.store.flowStatusRelations.some(
        (x) => x.statusId === this.store.step && !x.deleted
      );
      if (stepDone) {
        return true;
      }

      if (this.permStore.isAllInOneRecorder) {
        return false;
      }

      if (!this.accepting) {
        return this.disabled;
      }

      const relations = [FlowStatus.FinancialRecording, FlowStatus.StockRelationRecording];
      return (
        !this.permStore.hasInvoice([InvoicePermissions.Controlling, InvoicePermissions.Accepting]) ||
        !this.store.flowStatusRelations.some((x) => relations.includes(x.statusId) && !x.deleted)
      );
    },
  },
});
