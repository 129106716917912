





























































































import IconButton from "@/components/IconButton.vue";
import Vue from "vue";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import CheckBox from "@/components/CheckBox.vue";
import * as api from "@/modules/invoiceItems/services/api";
import WorkSheetItemGpart from "@/modules/invoiceItemDimension/models/WorkSheetItemGpart";
import WorkSheetItemRelation from "@/modules/invoiceItemDimension/models/WorkSheetItemRelations";

interface Data {
  flowStore: InvoiceFlowStore;
  workSheetItemRelaions: any[];
  newWorkSheetItemRelaions: WorkSheetItemGpart[];
  searchText: string;

  selectedItems: WorkSheetItemGpart[];

  autocompleteItems: any[];
  gpartsFilter: boolean;
  //selectedAutocompleteItem: string;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { IconButton, MoneyDisplay, CheckBox },
  props: {
    invItemId: Number,
    invItemGpartId: Number,
    invItemNetto: Number,
  },

  data() {
    return {
      flowStore,
      workSheetItemRelaions: [],
      newWorkSheetItemRelaions: [],
      searchText: "",

      selectedItems: [],
      autocompleteItems: [
        {
          id: 0,
          name: "Számla tétellel megegyező gparts",
        },
        {
          id: 1,
          name: "Bármilyen gparts",
        },
      ],
      selectedAutocompleteItem: {
        id: 0,
        name: "Számla tétellel megegyező gparts",
      },
      gpartsFilter: true,
    };
  },

  async beforeMount() {
    this.workSheetItemRelaions = await api.getCurrentWorkSheetItemRelations(Number(this.$props.invItemId));
  },

  methods: {
    autocompleteItemChanged(e: any) {
      if (e.id == 0) {
        this.gpartsFilter = true;
      } else this.gpartsFilter = false;
    },

    async search(e: KeyboardEvent) {
      if (e.key != "Enter") {
        return;
      }
      this.newWorkSheetItemRelaions = (
        await api.searchWorkSheetItemRelations(
          this.searchText,
          this.flowStore.invoice.partner,
          Number(this.$props.invItemGpartId),
          this.gpartsFilter,
          this.flowStore.invoice.rectify
        )
      ).map((x: WorkSheetItemGpart) => ({ ...x, isSelected: false }));
    },

    clear() {
      this.newWorkSheetItemRelaions = [];
      this.searchText = "";
    },

    selectItem(item: WorkSheetItemGpart) {
      if (!item.isSelected) {
        item.isSelected = true;
        this.selectedItems.push(item);
      } else {
        item.isSelected = false;
        this.selectedItems = this.selectedItems.filter((x) => x.id == item.id);
      }
    },

    async deleteItem(item: any) {
      this.workSheetItemRelaions = this.workSheetItemRelaions.filter((x) => x.relationId != item.relationId);
      await api.deleteWorkSheetItemRelation(item.relationId);
    },

    async addItems() {
      if (this.selectedItems.length == 0) {
        return;
      }

      const items: any = [];
      this.selectedItems.forEach((e) => {
        items.push({
          id: 1,
          invNo: Number(this.$route.params.invoiceId),
          wsCont: e.id,
          itemId: Number(this.$props.invItemId),
          total: Number(e.netto),
          facSheetCoop: 1,
          note: "",
        });
      });

      console.log(items);

      const success = await api.addWorkSheetItemRelation(items);
      this.newWorkSheetItemRelaions = [];
      this.workSheetItemRelaions = await api.getCurrentWorkSheetItemRelations(Number(this.$props.invItemId));
      this.selectedItems = [];
      
      if (success) {
        this.flowStore.invoice.type = 4;
      }
    },
  },

  computed: {
    columns: () => [
      { field: "workSheetHeadId", title: "Munkalap fej" },
      { field: "regNum", title: "Rendszám" },
      { field: "bodyNo", title: "Alvázszám" },
      { field: "partnerName", title: "Alvállalkozó" },
      { field: "orderDate", title: "Lezárás dátuma", type: "date", format: "{0: yyyy. MM. dd.}" },
      // { field: "gpartName", title: "Gparts neve" },
      { field: "statusName", title: "Státusz" },
      { field: "netto", title: "Nettó összeg", cell: "nettoTemplate", headerClassName: "right_text" },
      { cell: "commandTemplate", width: "60px" },
      { width: 0 },
    ],

    newColumns: () => [
      { field: "workSheetHeadId", title: "Munkalap fej" },
      { field: "regNum", title: "Rendszám" },
      { field: "bodyNo", title: "Alvázszám" },
      { field: "defrayerName", title: "Vevő" },
      { field: "partnerName", title: "Alvállalkozó" },
      { field: "gpartName", title: "Gparts neve" },
      { field: "orderDate", title: "Lezárás dátuma", type: "date", format: "{0: yyyy. MM. dd.}" },
      { field: "netto", title: "Nettó összeg", cell: "nettoTemplate", headerClassName: "right_text" },
      { cell: "commandTemplate", width: "60px" },
      { width: 0 },
    ],
  },
});
