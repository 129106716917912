






import Vue from "vue";
import { InvoiceRelationState } from "../models";

export default Vue.extend({
  props: {
    text: {
      type: String,
    },
  },

  computed: {
    cssClass() {
      let result = "badge ";

      if (this.text === InvoiceRelationState.Full) {
        result += "full";
      }

      if (this.text === InvoiceRelationState.Partial) {
        result += "partial";
      }

      if (this.text === InvoiceRelationState.None) {
        result += "none";
      }

      return result;
    },
  },
});
