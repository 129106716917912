import eventBus from "@/services/eventBus";
import CarPostCalc, { carPostCalcDefaultProps } from "../models/CarPostCalc"
import CarPostCalcApi from "./CarPostCalcApi";

export class CarPostCalcStore {
    carPostCalcs: CarPostCalc[] = [];
    carPostCalc: CarPostCalc = { ...carPostCalcDefaultProps };

    async getPostCalcs(invNo: number) {
        this.carPostCalcs = await CarPostCalcApi.getCarPostCalcs(invNo);
    }

    async generateCarPostCalc(invNo: number){
        const success = await CarPostCalcApi.generateCarPostCalc(invNo);
        if (success) {
            eventBus.$emit("success","Sikeres utókalkuláció generálás!");
        }
        else{
            eventBus.$emit("error","Sikertelen utókalkuláció generálás!");
        }
    }
}

const store = new CarPostCalcStore()
export default store