import Vue from "vue";
import ExcelData, { DefaultExcelDataProps } from "../models/ExcelData";
import ExcelResponse from "../models/ExcelResponse";

export class ExcelStore {
  excelDatas: ExcelData[] = [{
    invoiceID:1,
    serviceID:1,
    serviceName:"",
    vatDegree:1,
    nettoValue:1,
    vatValue:1,
    bruttoValue:1,
    item: "",
    invGroup: [],
    invGroupName: []
  }];

  errorMessage: string[] = [];

  excelResponse: ExcelResponse = {warnings: [], exceptions: []}
}

const store = Vue.observable(new ExcelStore());
export default store;
