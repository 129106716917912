






































































import BankSelect from "../components/BankSelect.vue";

import Vue from "vue";
import eventBus from "@/services/eventBus";
import BankExtractImportApi from "../services/BankExtractImportApi";
import ImportFormat from "../models/ImportFormat";

interface Data {
  selectedBank: number;
  file: File | null;
  importChoices: ImportFormat[];
  foundNoImportChoices: boolean;
  selectedImpType: number;
  techAmount: number;
}

export default Vue.extend({
  components: {
    BankSelect,
  },

  data: (): Data => ({
    selectedBank: 0,
    file: null,
    importChoices: [],
    foundNoImportChoices: false,
    selectedImpType: 1,
    techAmount: 0,
  }),

  methods: {
    async importFile() {
      const resFile = this.file ?? new File([], "");
      const res = await BankExtractImportApi.createImportStatement(
        resFile,
        this.selectedBank,
        this.selectedImpType,
        this.techAmount
      );
      if (res) {
        eventBus.$emit("success", "Sikeresen importálva!");
        this.$router.push(`/bank-extract-import`);
      }
    },

    setFile(value: File | null) {
      this.file = value;
    },

    async getImportChoices(value: number) {
      this.selectedBank = value;
      //send to api a bank id and return choices
      this.importChoices = await BankExtractImportApi.getImportChoices(value);
      if (this.importChoices.length < 1) {
        this.foundNoImportChoices = true;
      } else {
        this.foundNoImportChoices = false;
      }
    },

    cancel() {
      this.$router.push("/bank-extract-import");
    },
  },

  computed: {
    importChoicesOn() {
      return this.importChoices.length > 0;
    },

    hasFile() {
      return !!this.file;
    },
  },
});
