
















































































import Vue from "vue";
import store, { ExcelStore } from "../services/ExcelStore";
import invStore, {
  GeneralIngoingInvoiceStore,
} from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import eventBus from "@/services/eventBus";
import * as api from "@/modules/invoiceItems/services/api";
import invoiceflowStore, {
  InvoiceFlowStore,
} from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import ExcelApi from "../services/ExcelApi";
import iStore, {
  InvoiceItemDimensionStore,
} from "../../invoiceItemDimension/services/InvoiceItemDimensionStore";
import invitemStore, {
  InvoiceItemStore,
} from "@/modules/invoiceItems/services/store";

interface Data {
  file: File | undefined;
  invStore: GeneralIngoingInvoiceStore;
  invoiceflowStore: InvoiceFlowStore;
  lastReadFile: File | undefined;
  store: ExcelStore;
  iStore: InvoiceItemDimensionStore;
  invitemStore: InvoiceItemStore;
}

interface Props {
  case: string;
}

export default Vue.extend<Data, {}, {}, Props>({
  data: () => ({
    store,
    file: undefined,
    invStore,
    invitemStore,
    invoiceflowStore,
    lastReadFile: undefined,
    iStore,
  }),

  props: {
    case: String,
  },

  computed: {
    canUpload(): boolean {
      return this.file ? true : false;
    },
    errorGuide() {
      let result = "";

      if (
        this.store.excelResponse.exceptions.length > 0 ||
        this.store.errorMessage.length > 0
      ) {
        result = "Hibalist: \n";
      } else result = "";

      return result;
    },
    warningGuide() {
      if (this.store.excelResponse.warnings.length > 0) {
        return "Figyelmeztetés: \n";
      }
      return "";
    },
    eRespWarning() {
      return this.store.excelResponse.warnings;
    },

    eRespExcps() {
      return this.store.excelResponse.exceptions;
    },

    errorMessage() {
      return this.store.errorMessage;
    },
    hasError() {
      return this.store.errorMessage.length > 0 ||
        this.store.excelResponse.exceptions.length > 0
        ? true
        : false;
    },
    hasWarnings() {
      return this.store.excelResponse.warnings.length > 0 ? true : false;
    },
  },

  methods: {
    clearError() {
      this.store.errorMessage = [];
      this.store.excelResponse.exceptions = [];
      console.log(this.store.excelResponse.exceptions);
      console.log(this.store.errorMessage);
    },
    clearWarning() {
      this.store.excelResponse.warnings = [];
    },
    async uploadFile() {
      this.$emit("loading", true)
      if (!this.file) {
        return;
      }
      if (
        this.file === this.lastReadFile &&
        this.store.errorMessage.length == 0 &&
          this.store.excelResponse.warnings.length == 0 &&
          this.store.excelResponse.exceptions.length == 0
      ) {
        eventBus.$emit("error", "Egy fájlt csak egyszer lehet beolvasni!");
        this.$emit("loading", false)
      } else {
        this.store.errorMessage = [];
        const invoiceId = Number(this.$route.params.invoiceId);
        const invoiceNo = this.invoiceflowStore.invoice.invoiceNo;
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("invoiceNo", invoiceNo);
        if (this.case === "itemrecord") {
          await ExcelApi.PostExcel(formData, invoiceId);
          this.$emit("loading", false)
        } else if (this.case === "accountingdiv") {
          await ExcelApi.PostAccountingExcel(formData, invoiceId)
            .then((x) => {
              store.errorMessage = x;
            })
            .then(async function updateItems() {
              await api.getInvoiceItems(invoiceId).then((invoiceItems) =>
                invitemStore.update({
                  invoiceItems,
                })
              );
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
            this.$emit("loading", false)
        }
        this.lastReadFile = this.file;
      }
      
    },
    filesChanged(file: File) {
      this.file = file;
      this.store.errorMessage = [];
    },
  },

  beforeMount() {
    this.store.errorMessage = [];
    this.store.excelResponse.exceptions = [];
    this.store.excelResponse.warnings = [];
  },
});
