/*
SQL TÁBLÁK-- 
VIRO hmouivirinvoice
VIRI hminivirinvoice
// a frontendes validációk a modell(ek) default értékeitől függnek
*/

export interface TechnicalInvoiceVIRO {
  id: number; //-id
  invNo: string; //-számlaszám
  partner: number; //-partner *mlgenpartner
  pmode: number; //-fizetési mód *csgenpaymode
  invDate: Date; //-számla dátuma
  shipDate: Date | null; //-teljesítés dátuma
  pterm: Date; //-fizetési határidő
  netto: number; //-nettó
  total: number; //-totál
  vat: number; //-áfa
  status: number; //-állapot *ssaccinvstatus
  invType: number; //-számla típusa *csouivirinvtype
  accGroup: number; //-költséghely *csaccinvgroup
  currency: string; //-devizanem
  remark: string | null; //-megjegyzés
  withVat: boolean; //-áfa-s?
  userid: number; //-munkatárs/létrehozó, a backenden lesz beállítva
  accStatus: number; //-könyvelési állapot *ssaccaccstatus
  rectify: boolean; //-helyesbítő-e?
  invoiceRect: number | null; //-a helyesbítő számla száma *hmouivirinvoice
  storno: boolean;
  invoiceSt: number | null; // jóváírt számlaszám **hmouivirinvoice
  partnerRel: number; //-partner reláció *csgenpartrel
  prefix: string; // automatikusan beállítom az url codeFilter-jével
  invoice: number; //lehet hogy fog kelleni-számlaszám amelyhez a számla készült
  norder: number; // *mmcarnorder
  sorder: number; // *mmcarsorder
  worksheet: number; // *hlserworksheet
  custorder: number; // *hlstrcustorder
  virAccType: number; // *csaccviracctype
  divitem: number; //-aldivízió *csgendivitem
  car: number; // *mmsercar
  prcar: number; // *mmcarprorder
  invCurrency: string;
  currNetto: number;
  currTotal: number;
  currVat: number;
  exchangeRate: number;
  rateDate: Date;
  isFinalRate: boolean; // csak VIRO esetén van, ez az egy különbség a két tábla közt
  stfContract: number;
}

export interface TechnicalInvoiceVIRI {
  id: number; //-id
  invNo: string; //-számlaszám
  partner: number; //-partner *mlgenpartner
  pmode: number; //-fizetési mód *csgenpaymode
  invDate: Date; //-számla dátuma
  shipDate: Date | null; //-teljesítés dátuma
  pterm: Date; //-fizetési határidő
  netto: number; //-nettó
  total: number; //-totál
  vat: number; //-áfa
  status: number; //-állapot *ssaccinvstatus
  invType: number; //-számla típusa *csouivirinvtype
  accGroup: number; //-költséghely *csaccinvgroup
  currency: string; //-devizanem
  remark: string | null; //-megjegyzés
  withVat: boolean; //-áfa-s?
  userid: number; //-munkatárs/létrehozó, a backenden lesz beállítva
  accStatus: number; //-könyvelési állapot *ssaccaccstatus
  rectify: boolean; //-helyesbítő-e?
  invoiceRect: number | null; //-a helyesbítő számla száma *hminivirinvoice
  storno: boolean;
  invoiceSt: number | null; //- **hminivirinvoice
  partnerRel: number; //-partner reláció *csgenpartrel
  prefix: string; // automatikusan beállítom az url codeFilter-jével
  invoice: number; //lehet hogy fog kelleni-számlaszám amelyhez a számla készült
  norder: number; // *mmcarnorder
  sorder: number; // *mmcarsorder
  worksheet: number; // *hlserworksheet
  custorder: number; // *hlstrcustorder
  virAccType: number; // *csaccviracctype
  divitem: number; //-aldivízió *csgendivitem
  car: number; // *mmsercar
  prcar: number; // *mmcarprorder
  invCurrency: string;
  currNetto: number;
  currTotal: number;
  currVat: number;
  exchangeRate: number;
  rateDate: Date;
  stfContract: number;
}

export const technicalInvoiceVIRODefaultProps: TechnicalInvoiceVIRO = {
  id: 0,
  invNo: "",
  partner: 1,
  pmode: 1,
  invDate: new Date(),
  shipDate: null,
  pterm: new Date(),
  netto: 0,
  total: 0,
  vat: 0,
  status: 2,
  invType: 2, // Nyitó kimenő számla
  accGroup: 1,
  currency: "HUF",
  remark: "",
  withVat: false,
  userid: 1,
  accStatus: 2, // Nem adható fel
  rectify: false,
  invoiceRect: null,
  storno: false,
  invoiceSt: null,
  partnerRel: 2,
  prefix: "",
  invoice: 1,
  norder: 1,
  sorder: 1,
  worksheet: 1,
  custorder: 1,
  virAccType: 1,
  divitem: 1,
  car: 1,
  prcar: 1,
  invCurrency: "HUF",
  currNetto: 0,
  currTotal: 0,
  currVat: 0,
  exchangeRate: 1,
  rateDate: new Date(),
  isFinalRate: true,
  stfContract: 1,
};

export const technicalInvoiceVIRIDefaultProps: TechnicalInvoiceVIRI = {
  id: 0,
  invNo: "",
  partner: 1,
  pmode: 2,
  invDate: new Date(),
  shipDate: null,
  pterm: new Date(),
  netto: 0,
  total: 0,
  vat: 0,
  status: 2,
  invType: 2, // Nyitó bejövő számla
  accGroup: 1,
  currency: "HUF",
  remark: "",
  withVat: false,
  userid: 1,
  accStatus: 2, // Nem adható fel
  rectify: false,
  invoiceRect: null,
  storno: false,
  invoiceSt: null,
  partnerRel: 2,
  prefix: "",
  invoice: 1,
  norder: 1,
  sorder: 1,
  worksheet: 1,
  custorder: 1,
  virAccType: 1,
  divitem: 1,
  car: 1,
  prcar: 1,
  invCurrency: "HUF",
  currNetto: 0,
  currTotal: 0,
  currVat: 0,
  exchangeRate: 1,
  rateDate: new Date(),
  stfContract: 1,
};

// // BACKENDEN ez az alapértelmezés (a VIRO adatbázis 1-es id-jű rekordja alapján)
// {
//   id: 0,
//   invNo: "-",
//   partner: 1,
//   pmode: 1,
//   invDate: "1980.01.01",
//   shipDate: "1980.01.01",
//   pterm: "1980.01.01",
//   netto: 0,
//   total: 0,
//   vat: 0,
//   status: 1,
//   invType: 1,
//   accGroup: 1,
//   currency: "HUF",
//   remark: "-",
//   withVat: "-",
//   userid: 1,
//   accStatus: 1,
//   rectify: "-",
//   invoiceRect: null,
//   storno: "-",
//   invoiceSt: null,
//   partnerRel: 1,
//   prefix: "-",
//   invoice: 1,
//   norder: 1,
//   sorder: 1,
//   worksheet: 1,
//   custorder: 1,
//   virAccType: 1,
//   divitem: 1,
//   car: 1,
//   prcar: 1,
//   invCurrency: "HUF",
//   currNetto: 0,
//   currTotal: 0,
//   currVat: 0,
//   exchangeRate: 1,
//   rateDate: "1980.01.01",
//   isFinalRate: "+",
//   stfContract: 1,
// };
