import eventBus from "@/services/eventBus";
import http from "@/services/http";
import * as signalR from "@microsoft/signalr";
import { VueConstructor } from "vue/types/umd";
import configuration from "@/models/Configuration";

declare module "vue/types/vue" {
  export interface Vue {
    $invoiceTaskUpdateHub: signalR.HubConnection;
  }
}

export default {
  install(Vue: VueConstructor) {
    if (configuration.operel) {
      return;
    }

    const connection = ((window as any)?.signalrMock as signalR.HubConnection) || new signalR.HubConnectionBuilder()
      .withUrl(http.defaults.baseURL + `connections/hub/invoice-task-update`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    connection.on("InvoiceTaskUpdated", () => {
      if (connection.state != signalR.HubConnectionState.Connected) {
        connection.start().then(() => eventBus.$emit("invoice-task-updated"));
      } else eventBus.$emit("invoice-task-updated");
    });

    Vue.prototype.$invoiceTaskUpdateHub = connection;

    let reconnectTries = 0;
    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch(() => {
        reconnectTries++;
        if (reconnectTries > 5) return;
        return new Promise((resolve, reject) =>
          setTimeout(
            () =>
              start()
                .then(resolve)
                .catch(reject),
            5000
          )
        );
      });
      return startedPromise;
    }

    connection.onclose(() => start());

    start();
  },
};
