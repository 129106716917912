import { documentTemplateTechnicalRecord } from "./../models/DocumentTemplate";
import FlowDocumentTemplateRelation, {
  flowDocumentTemplateRelationDefaultProps,
} from "@/modules/flowDocumentRelation/models/FlowDocumentTemplateRelation";
import busyIndication from "@/utils/busyIndication";
import DocumentTemplate, { documentTemplateDefaultProps } from "../models/DocumentTemplate";
import DocumentTemplateApi from "../services/DocumentTemplateApi";

export class DocumentTemplateStore {
  documentTemplates: DocumentTemplate[] = [];
  documentTemplate: DocumentTemplate = { ...documentTemplateDefaultProps };
  documentTemplateRelation: FlowDocumentTemplateRelation = { ...flowDocumentTemplateRelationDefaultProps };
  template = "-";

  async loadDocumentTemplateRelation(invoiceId: number) {
    this.documentTemplateRelation = await DocumentTemplateApi.getDocumentTemplateRelation(invoiceId);
  }

  async updateDocumentTemplateRelation(invoiceId: number, docTemplateId: number) {
    this.documentTemplateRelation = await DocumentTemplateApi.updateDocumentTemplateRelation(invoiceId, docTemplateId);
  }

  async uploadDocumentTemplateItem(itemId: number, doctype: number, file: File) {
    return await DocumentTemplateApi.uploadDocumentTemplateItem(itemId, doctype, "", file);
  }

  async uploadOtherDocumentItem(itemId: number, doctype: number, file: File) {
    return await DocumentTemplateApi.uploadOtherDocumentItem(itemId, doctype, "", file);
  }

  async uploadFinancialDocumentTemplateItem(itemId: number, doctype: number, file: File) {
    return await DocumentTemplateApi.uploadFinancialDocumentTemplateItem(itemId, doctype, "", file);
  }

  async uploadNewFinancialDocumentTemplateItem(itemId: number, doctype: number, file: File) {
    return await DocumentTemplateApi.uploadFinancialDocumentTemplateItem(itemId, doctype, "", file);
  }

  async getDocumentTypeByTypeName(docType: string) {
    return await DocumentTemplateApi.getDocumentTypeByTypeName(docType);
  }

  @busyIndication()
  async loadDocumentTemplates() {
    this.documentTemplates = await DocumentTemplateApi.getAll();
  }

  async loadDocumentTemplatesWithTechnicalRecord() {
    await DocumentTemplateApi.getAll().then((result) => {
      this.documentTemplates = [documentTemplateTechnicalRecord, ...result];
    });
  }

  async loadDocumentTemplatesWithoutBusy() {
    this.documentTemplates = await DocumentTemplateApi.getAll();
  }

  async loadDocumentTemplate(documentTemplateId: number) {
    this.documentTemplate =
      documentTemplateId > 1 ? await DocumentTemplateApi.get(documentTemplateId) : { ...documentTemplateDefaultProps };
  }

  async createDocumentTemplate() {
    try {
      const ret = Number(await DocumentTemplateApi.create(this.documentTemplate));
      if (ret > 0) {
        this.documentTemplate.id = ret;
        this.documentTemplates.unshift(this.documentTemplate);
        this.documentTemplates = [...this.documentTemplates];
      }
      //this.documentTemplate = { ...documentTemplateDefaultProps };
      return this.documentTemplate.name;
    } catch (error) {
      console.error(error);
    }
  }

  async deleteTemplate(templateId: number) {
    try {
      if (templateId != 0) {
        await DocumentTemplateApi.deleteTemplate(templateId);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

const store = new DocumentTemplateStore();
export default store;
