import { render, staticRenderFns } from "./BankRemitRoles.vue?vue&type=template&id=49cf6817&scoped=true&"
import script from "./BankRemitRoles.vue?vue&type=script&lang=ts&"
export * from "./BankRemitRoles.vue?vue&type=script&lang=ts&"
import style0 from "./BankRemitRoles.vue?vue&type=style&index=0&id=49cf6817&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49cf6817",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VCol,VExpandTransition,VIcon,VRow})
