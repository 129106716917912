export default interface IncomingInvoiceTypeDisplay {
  id: number;
  name: string;
  isDefault: boolean;

  defAccgroup: string;
  detailedType: string;
  code: string;
  promptArrive: boolean;
  postCalc: boolean;
  isMustAccGroup: boolean;
  isExtData: boolean;
  isContr: boolean;
  isInvPermit: boolean;
  isProject: boolean;

  isAlive: boolean;
}

export const incomingInvoiceTypeDisplayDefaultProps: IncomingInvoiceTypeDisplay = {
  id: 0,
  name: "",
  isDefault: false,

  defAccgroup: "",
  detailedType: "",
  code: "",
  promptArrive: false,
  postCalc: false,
  isMustAccGroup: false,
  isExtData: false,
  isContr: false,
  isInvPermit: false,
  isProject: false,

  isAlive: true,
};
