



































import Vue from "vue";
import PdfViewer from "@/components/PdfViewer.vue";
import http, { baseURL, handleApiResponse } from "@/services/http";
import BankRemitsPage from "@/modules/bankRemit/views/BankRemitsPage.vue";

interface Data {
  pdf: Uint8Array;
  url: string;
  remitId: number;
  partnerId: number;
  loading: boolean;
}

interface Method {
  setRemitAndPartnerId(remitId: number, partnerId: number): void;
  loadPdf(remitId: number, partnerId: number): void;
}

export default Vue.extend<Data, Method, {}, {}>({
  components: {
    PdfViewer,
    BankRemitsPage,
  },

  data() {
    return {
      pdf: new Uint8Array(),
      url: "",
      remitId: 1,
      partnerId: 1,
      loading: false,
    };
  },

  watch: {
    partnerId() {
      this.loadPdf(this.remitId, this.partnerId);
    },
  },

  methods: {
    setRemitAndPartnerId(remitId, partnerId) {
      this.remitId = Number(remitId);
      this.partnerId = Number(partnerId);
    },

    async loadPdf(id: number) {

      function _base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
      }

      this.loading = true;
      const file = await http
        .get<string>(`${baseURL}crystal/transfer-notify/${this.remitId}/${this.partnerId}`)
        .then((x) => {
          this.pdf = _base64ToArrayBuffer(x.data);
          this.loading = false;
          return x;
        });
    },
  },
});
