import busyIndication from "../../../utils/busyIndication";
import Office, { OfficeDefaultProps } from "../models/Office";
import OfficeApi from "./OfficeApi";

export class OfficeStore{
    offices: Office[] = []
    office: Office = { ...OfficeDefaultProps }
    officeId= 0

    @busyIndication()
    async loadOffices() {
        this.offices = (await OfficeApi.getOffices())
    }
}

const officeStore = new OfficeStore()
export default officeStore
