










































































































































































































































































































































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";

import Vue from "vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import { FilteringModel } from "@/services/filtering";
import BankInOverViewDTO from "../models/BankInOverViewDTO";
import CheckBox from "@/components/CheckBox.vue";
import cashStatementStore from "@/modules/cashStatement/services/store";
import eventBus from "@/services/eventBus";
import MoneyInput from "@/components/MoneyInput.vue";
import store, { BankStatementStore } from "@/modules/bankStatement/services/BankStatementStore";

interface Data {
  filter: FilteringModel;
  sum: number;
  items: BankInOverViewDTO[];
  selectedItems: BankInOverViewDTO[];
  store: BankStatementStore;
}

interface Props {
  extraFilter: FilteringModel;
  partnerId: number;
  currency: string;
  title: string;
}

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
}

interface Methods {
  selected(item: BankInOverViewDTO): void;
  showDetails(item: BankInOverViewDTO): void;
  addToSummary(dataItem: BankInOverViewDTO): void;
  setItems(items: BankInOverViewDTO[]): void;
  generatePaymentItems(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ApiSearchPage, CheckBox, MoneyInput },

  props: {
    extraFilter: { type: Object as () => FilteringModel },
    partnerId: { type: Number, default: 1 },
    currency: { type: String, default: "" },
    title: {
      type: String,
      default: "Számlaválogató",
    },
  },

  data: () => ({
    filter: new FilteringModel(),
    sum: 0,
    items: [],
    selectedItems: [],
    store
  }),

  beforeMount() {
    this.filter = new FilteringModel();
    if (this.$props.extraFilter)
      this.filter = this.filter.fromFilter(this.$props.extraFilter.toObject());
  },

  methods: {
    selected(item: BankInOverViewDTO) {
      this.$emit("selected", item);
    },

    showDetails(item: BankInOverViewDTO) {
      item.selected = !item.selected;
    },

    addToSummary(dataItem: BankInOverViewDTO) {
      eventBus.$emit("bank-over-in-view:get-items");
      if (!dataItem.added) {
        this.sum += Math.abs(dataItem.amountToAdd);
        dataItem.added = true;
      } else {
        this.sum -= Math.abs(dataItem.amountToAdd);
        dataItem.added = false;
        this.selectedItems = this.selectedItems.filter(
          (x) => x.invoiceNo !== dataItem.invoiceNo && x.prefix != dataItem.prefix
        );
      }
      
      let sumToSend = this.sum;
      if (this.store.bankStatement.currency != "HUF"){
        sumToSend = sumToSend / this.store.bankDocument.bankStatementItem.exchangeRate;
      }

      eventBus.$emit("update-sum", sumToSend);
    },

    generatePaymentItems() {
      this.$emit("generate-payments", {
        selectedItems: this.selectedItems,
        partnerID: this.$props.partnerId,
      });

      this.selectedItems = [];
    },

    setItems(items) {
      this.items = items;
      const newSelected = items.filter((x) => x.added);
      newSelected.forEach((element) => {
        if (!this.selectedItems.includes(element)) {
          this.selectedItems.push(element);
        }
      });
    },
  },

  computed: {
    columns: function() {
      return [
            {
              cell: "statementDetailsButtonTemplate",
              filterable: false,
            },
            { field: "prefix", title: "Prefix" },
            { field: "invoiceNo", title: "Számlaszám" },
            { field: "itemNo", title: "Kivonat azonosító" },
            {
              cell: "amount",
              field: "amount",
              title: "Tényleges összeg",
              type: ColumnType.Money,
            },
            { field: "currency", title: "Pénznem" },
            {
              cell: "oriAmount",
              field: "oriAmount",
              title: "Telj. össz. telj. p.",
              type: ColumnType.Money,
            },
            { field: "oriCurrency", title: "Teljesítés pénznem" },
            {
              cell: "amountToAdd",
              field: "amountToAdd",
              title: "Fizetendő",
              type: ColumnType.Money,
            },
            { field: "remark", title: "Megjegyzés" },
            {
              cell: "selectButton",
              filterable: false,
            },
          ];
    },

    url() {
      return `bankin/over/search/${this.$props.partnerId}/${this.$props.currency}`;
    },
  },
});
