





















import YellowConfirmation from "@/components/YellowConfirmation.vue";
import { ConfirmationMixin } from "@/mixins/confirmation";

import eventBus from "@/services/eventBus";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import wizardStore, { InvoiceWizardStore } from "@/modules/invoiceFlowTask/services/InvoiceTaskWizard";

interface Data {
  store: InvoiceFlowStore;
  wizardStore: InvoiceWizardStore;
}

interface Computed {
  id: number;
}

interface Props {
  buttonText: string;
  incorrectCols: string;
  disabled: boolean;
  align: string;
  prefix: string;
  backPath: string;
  notFlow: boolean;
  otherConfirmation: string;
  customText: string;
}

export default ConfirmationMixin.extend<Data, {}, Computed, Props>({
  components: { YellowConfirmation },

  props: {
    buttonText: { type: String, default: "Hibás rögzítés" },
    incorrectCols: { type: String, default: "auto" },
    disabled: { type: Boolean, default: false },
    align: { type: String, default: "right" },
    prefix: { type: String, default: "GENI" },
    backPath: { type: String, default: "/" },
    notFlow: { type: Boolean, default: false },
    otherConfirmation: { type: String, default: "" },
    customText: String,
  },

  mixins: [ConfirmationMixin],

  data: () => ({
    store,
    wizardStore,
  }),

  computed: {
    id() {
      return !this.notFlow ? this.store.invoice.id : Number(this.$route.params.invoiceId) || 0;
    },
  },

  methods: {
    async setIncorrect() {
      const text = this.customText
        ? this.customText
        : `${this.notFlow ? "" : this.prefix + ": "}${
            this.id
          }, Biztos hogy Hibás rögzítésre állítja a számla státuszát?`;
      const confirmed = await this.confirm(text, "set-incorrect");
      if (!confirmed) return;

      let confirmedOther = true;

      if (this.otherConfirmation != "") {
        // timeout for the other confirmation to appear
        await setTimeout(() => {
          return;
        }, 10);
        confirmedOther = await this.confirm(this.otherConfirmation, "set-incorrect");
      }
      if (!confirmedOther) return;

      if (this.notFlow) {
        this.$emit("confirmed");
        return;
      }

      await this.store.setIncorrect().then(() => {
        this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
      });

      eventBus.$emit("success", `Hibás rögzítés státusz frissítve.`);

      if (this.wizardStore?.wizard?.hasItems) {
        this.store.isEditingEnabled = false;
        return;
      }

      if (this.backPath != "") this.$router.push(`${this.backPath}`);
    },
  },
});
