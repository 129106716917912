var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.store.accountType)?_c('v-card',[_c('v-card-title',[_c('v-col',[_vm._v("Folyószámla tétel csoportok karbantartása")])],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns,"edit-field":'inEdit'},on:{"itemchange":_vm.itemChange},scopedSlots:_vm._u([{key:"defaultTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'default',
                    }); }},model:{value:(props.dataItem.default),callback:function ($$v) {_vm.$set(props.dataItem, "default", $$v)},expression:"props.dataItem.default"}})],1)]}},{key:"isInTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'isIn',
                    }); }},model:{value:(props.dataItem.isIn),callback:function ($$v) {_vm.$set(props.dataItem, "isIn", $$v)},expression:"props.dataItem.isIn"}})],1)]}},{key:"isOutTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'isOut',
                    }); }},model:{value:(props.dataItem.isOut),callback:function ($$v) {_vm.$set(props.dataItem, "isOut", $$v)},expression:"props.dataItem.isOut"}})],1)]}},{key:"isInvoiceTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'isInvoice',
                    }); }},model:{value:(props.dataItem.isInvoice),callback:function ($$v) {_vm.$set(props.dataItem, "isInvoice", $$v)},expression:"props.dataItem.isInvoice"}})],1)]}},{key:"isCredTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'isCred',
                    }); }},model:{value:(props.dataItem.isCred),callback:function ($$v) {_vm.$set(props.dataItem, "isCred", $$v)},expression:"props.dataItem.isCred"}})],1)]}},{key:"isOverTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'isOver',
                    }); }},model:{value:(props.dataItem.isOver),callback:function ($$v) {_vm.$set(props.dataItem, "isOver", $$v)},expression:"props.dataItem.isOver"}})],1)]}},{key:"isTechTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'isTech',
                    }); }},model:{value:(props.dataItem.isTech),callback:function ($$v) {_vm.$set(props.dataItem, "isTech", $$v)},expression:"props.dataItem.isTech"}})],1)]}},{key:"isDebTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'isDeb',
                    }); }},model:{value:(props.dataItem.isDeb),callback:function ($$v) {_vm.$set(props.dataItem, "isDeb", $$v)},expression:"props.dataItem.isDeb"}})],1)]}},{key:"isGeniTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":!props.dataItem.inEdit},on:{"change":function (value) { return _vm.itemChange({
                      dataItem: props.dataItem,
                      value: Boolean(value),
                      field: 'isGeni',
                    }); }},model:{value:(props.dataItem.isGeni),callback:function ($$v) {_vm.$set(props.dataItem, "isGeni", $$v)},expression:"props.dataItem.isGeni"}})],1)]}},{key:"commandTemplate",fn:function(ref){
                    var props = ref.props;
return [_c('td',[(!props.dataItem.inEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary","disabled":props.dataItem.updating},on:{"click":function($event){return _vm.editItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.cancelEdit(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok mentése")])])],1)]}}],null,false,2528341779)})],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }