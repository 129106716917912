






































































































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import Bank from "../models/Bank";
import { ValidationRules } from "../../../models/Validation";
import store, { BankStore } from "../services/BankStore";
import { rules } from "../models/Bank";
import Partner from "../../partner/models/Partner";
import pStore, { PartnerStore } from "../../partner/services/PartnerStore";

interface Data {
  store: BankStore;
  pStore: PartnerStore;
  opened: boolean;
  valid: boolean;
  rules: ValidationRules;
  selectedPartnerId: Partner | undefined;
}

interface Computed {
  title: string;
  editFormType: boolean;
}

const CREATE_TITLE = "Bank létrehozása";
const MODIFY_TITLE = "Bank adatok módosítása";

export default Vue.extend<Data, {}, Computed, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      pStore,
      rules,
      valid: true,
      opened: false,
      selectedPartnerId: undefined,
    };
  },

  computed: {
    title() {
      return this.editFormType ? MODIFY_TITLE : CREATE_TITLE;
    },

    editFormType() {
      return this.store.bank?.id > 0;
    },
  },

  async beforeMount() {
    const bankId = Number(this.$route.params.bankId);
    await this.store.loadBank(bankId);
    this.store.setBankCodes();
    await this.pStore.loadPartnersWithoutPage();
    await this.store.loadK2dBankCodes();

    this.selectedPartnerId = this.pStore.partners.find((x) => x.id == this.store.bank.partnerID);
    console.log(this.store.bankCodes);
  },

  methods: {
    cancel() {
      this.$router.push("/settings/banks");
    },

    async save(item: Bank) {
      await this.store.save(item, {});
      this.$router.push("/settings/banks");
    },

    selectedPartnerIdChanged(event: any) {
      this.selectedPartnerId = event.value;
      this.store.bank.partnerID = event.id;
    },
  },
});
