













































































































































import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import configuration, {
  AppConfiguration,
  loadConfiguration,
} from "@/models/Configuration";
import BankStatementAccountingItem from "../models/BankStatementAccountingItem";
import eventBus from "@/services/eventBus";
import http, { checkResponse } from "@/services/http";
import dayjs from "dayjs";
import Vue from "vue";
import store, { BankStatementStore } from "../services/BankStatementStore";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import Page from "@/components/Page.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
  isReopenDisabled: boolean;
  hasError: boolean;
}

interface Data {
  items: BankStatementAccountingItem[];
  store: BankStatementStore;
  configuration: AppConfiguration;
  showErrorList: boolean;
}

interface Method {
  setItems(items: BankStatementAccountingItem[]): void;
  getAccName(code: string): string;
  getPrecision(): number;
  getBookingDate(item: BankStatementAccountingItem): string;
  preAccount(): void;
  reopen(): void;
}

export default Vue.extend<Data, Method, Computed, {}>({
  components: { ApiSearchPage, Page, MoneyDisplay },

  data() {
    return {
      store,
      configuration,
      items: [],
      showErrorList: false
    };
  },

  methods: {
    async reopen(){
      const result = await http
        .post(`bank-statement/reopen`, {
          BankStatementID: Number(this.$route.params.id)
        })
        .then(checkResponse);
      if (result) {
        await this.store.loadBankStatement(Number(this.$route.params.id));
        eventBus.$emit("success", "Sikeres visszanyitás!");
      }
    },

    async preAccount() {
      const result = await http
        .post(`bank-statement/${Number(this.$route.params.id)}/pre-account`)
        .then(checkResponse);
      if (result) {
        await this.store.loadAccountingErrors();

        eventBus.$emit("success", "Sikeres kontírozás!");
        eventBus.$emit("bank-statement-accounting:reload");
      }
    },
    setItems(items: BankStatementAccountingItem[]) {
      this.items = items;
    },

    getBookingDate(item) {
      return dayjs(item.accDate) > dayjs("1990-01-01")
        ? dayjs(item.accDate).format("YYYY. MM. DD")
        : "-";
    },

    getAccName(code: string) {
      return (
        this.store.accountingCodes.find((x) => x.code == code)?.accName ?? "-"
      );
    },

    getPrecision() {
      const res = this.configuration.operel
        ? this.store.bankStatement.currency != "HUF"
          ? 2
          : 0
        : this.configuration.getCurrDigits(this.store.bankStatement.currency)
            ?.geniHeadDigits ?? 2;
      return res;
    },
  },

  async mounted() {
    if (this.store.bankStatement.id < 1) {
      await this.store.loadBankStatement(Number(this.$route.params.id));
    }

    await this.store.loadAccountingCodes(Number(this.$route.params.id));
    await this.store.loadAccountingErrors();
    await this.store.loadBankStatementSummary();

    if (!this.configuration.isLoaded) {
      await loadConfiguration();
    }
  },

  computed: {
    hasError(){
      return this.store.accountingErrors.length > 0;
    },

    isReopenDisabled(){
      let isSend = false;

      const accountStatus = this.store.bankStatement.accountStatus;
      if (accountStatus == 4 || accountStatus == 6 || accountStatus == 9 || accountStatus == 10){
        isSend = true;
      }

      return this.store.bankStatement.status != 3 || isSend;
    },

    url() {
      return `bank-statement/${Number(this.$route.params.id)}/account-items`;
    },

    columns: (): ApiSearchColumn[] => [
      { field: "type", title: "" },
      { field: "owe", title: "Tartozik", fieldClass: "oweCell" },
      {
        field: "oweAmount",
        title: "Összeg",
        cell: "oweAmount",
        fieldClass: "oweCell",
        type: ColumnType.Money,
      },
      {
        field: "demands",
        title: "Követel",
        cell: "demands",
        fieldClass: "demandsCell",
      },
      {
        field: "demandedAmount",
        title: "Összeg",
        cell: "demandedAmount",
        fieldClass: "demandsCell",
        type: ColumnType.Money,
      },
      {
        field: "accDate",
        title: "Könyvelés dátuma",
        cell: "accDate",
      },
      { field: "invNo", title: "Számla", cell: "invNo" },
    ],
  },
});
