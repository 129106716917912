export default interface PartCust {
  id: number;
  partner: number;
  credit: number;
  promptlyInv: boolean;
  paymode: string;
  paymodeId: number;
  payTerm: number;
  contactUser: string;
  isEinvoice: boolean;
  payTermFromShip: boolean;
  defCurrency: string;
  bank: string;
  bankId: number;
  deleted: boolean;
  inEdit: boolean;
  custType: number;
  custTypeName: string;
}

export const partCustDefaultProps: PartCust = {
  id: 0,
  partner: 0,
  credit: 0,
  promptlyInv: false,
  paymode: "-",
  paymodeId: 0,
  payTerm: -1,
  contactUser: "-",
  isEinvoice: false,
  payTermFromShip: false,
  defCurrency: "-",
  bank: "",
  bankId: 0,
  deleted: false,
  inEdit: false,
  custType: 1,
  custTypeName: "-",
};
