











import Vue from "vue";

interface Data {
  activeTab: number | string | null;
}

export default Vue.extend({
  props: {
    defaultTab: Number,
  },

  beforeMount() {
    if (this.defaultTab) this.activeTab = this.defaultTab;
  },

  data: (): Data => ({
    activeTab: null,
  }),
});
