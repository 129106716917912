enum PreStatus{
    UnderProcessing = 2, Arrived, StillNotAccepted,
    Credited,
    Wrong,
    Refused,
    Accepted,
    AcceptedUnderProcessing,
    InDownPayment
}


export default PreStatus;