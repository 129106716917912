import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import IncomingInvoiceType from "../models/IncomingInvoiceType";
import AccountNoDetailedType from "@/modules/cashPoint/models/AccountNoDetailedType";
import IncomingInvoiceTypeDisplay from "../models/IncomingInvoiceTypeDisplay";
import InvoiceGroupDisplay from "@/modules/invoiceGroup/models/InvoiceGroupDisplay";
import PartnerRelation from "@/modules/partner/models/PartnerRelation";
import IncomingInvoiceSubtype from "../models/IncomingInvoiceSubtype";

export default class IncomingInvoiceTypeApi {
  // Incoming Invoice Type
  static getAll(): Promise<IncomingInvoiceTypeDisplay[]> {
    return http
      .get<ApiResponse<IncomingInvoiceTypeDisplay[]>>(`incoming-invoice-type`)
      .then(handleApiResponse);
  }

  static get(invoiceId: number): Promise<IncomingInvoiceType> {
    return http
      .get<ApiResponse<IncomingInvoiceType>>(`incoming-invoice-type/${invoiceId}`)
      .then(handleApiResponse);
  }

  static update(invoiceType: IncomingInvoiceType): Promise<boolean> {
    return http
      .put(`incoming-invoice-type`, { invoiceType: invoiceType })
      .then(checkResponse);
  }

  // Invoice Groups | Költséghelyek
  static getInvoiceGroups(): Promise<InvoiceGroupDisplay[]> {
    return http
      .get<ApiResponse<InvoiceGroupDisplay[]>>(`invoice-group`)
      .then(handleApiResponse);
  }

  // Detailed Type | Főkönyvi tétel típusok
  static getAccountNoDetailedTypes(): Promise<AccountNoDetailedType[]> {
    return http
      .get<ApiResponse<AccountNoDetailedType[]>>(`accountDetailedType`)
      .then(handleApiResponse);
  }

  // Incoming Invoice Subtypes
  static getAllByType(type: number): Promise<IncomingInvoiceSubtype[]> {
    return http
      .get<ApiResponse<IncomingInvoiceSubtype[]>>(`incoming-invoice-subtype/type/${type}`)
      .then(handleApiResponse);
  }

  // Partner Relation
  static getPartnerRelationsByInvoiceType(
    invoiceType: number
  ): Promise<PartnerRelation[]> {
    return http
      .get<ApiResponse<PartnerRelation[]>>(`partner-relation/type/${invoiceType}`)
      .then(handleApiResponse);
  }

  static updatePartnerRelation(partnerRelation: PartnerRelation): Promise<boolean> {
    return http
      .put(`incoming-invoice-type/relation`, {
        partnerRelation: partnerRelation,
      })
      .then(checkResponse);
  }

  static createPartnerRelation(partnerRelation: PartnerRelation): Promise<boolean> {
    return http
      .post(`incoming-invoice-type/relation`, {
        partnerRelation: partnerRelation,
      })
      .then(checkResponse);
  }

  static deletePartnerRelation(partnerRelationId: number): Promise<boolean> {
    return http
      .delete(`incoming-invoice-type/relation/${partnerRelationId}`)
      .then(checkResponse);
  }
}
