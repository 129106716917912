





















































































import Vue from "vue";
import cashPointStore, {
  CashPointStore,
} from "@/modules/cashPoint/services/CashPointStore";
import CashPointDisplay from "../models/CashPointDisplay";
import CashPointDownPaymentTransaction from "../models/CashPointDownPaymentTransaction";
import User from "@/modules/invoiceGroup/models/User";
import MoneyInput from "@/components/MoneyInput.vue";
import employeeStore, {
  EmployeeStore,
} from "@/modules/employee/services/EmployeeStore";
import cashStatementStore, {
  CashStatementStore,
} from "@/modules/cashStatement/services/store";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import eventBus from "@/services/eventBus";

enum CashPointType {
  "-" = 1,
  "Hagyományos",
  "Elszámolási előleg kezelés",
  "Bankkártya kezelés",
}

interface Data {
  cashPointStore: CashPointStore;
  employeeStore: EmployeeStore;
  cashStatementStore: CashStatementStore;
  transaction: CashPointDownPaymentTransaction;
  employeesWithCashpoint: User[];
}

interface Computed {
  items: CashPointDisplay[];
}

interface Method {
  cashPointSelected(): void;
  save(): void;
  cancel(): void;
}

interface Props {
  currency: string;
}

export default Vue.extend<Data, Method, Computed, Props>({
  data: () => {
    return {
      cashPointStore,
      employeeStore,
      cashStatementStore,
      transaction: new CashPointDownPaymentTransaction(
        cashStatementStore.cashDocument.id,
        1,
        0,
        "",
        1
      ),
      employeesWithCashpoint: [],
    };
  },

  props: {
    currency: { type: String, default: "HUF" },
  },

  components: {
    MoneyInput,
  },

  methods: {
    async save(){
      const result = await http.post("cashPoint/downpayment",this.transaction).then(checkResponse);
      if(result){
        await this.cashStatementStore.loadCashDocument(this.cashStatementStore.cashDocument.id);
        await this.cashStatementStore.loadCashDocItemsByDocumentId(this.cashStatementStore.cashDocument.id)
        eventBus.$emit("success", "Sikeres generálás!")
      }
      else{
        eventBus.$emit("error", "Nem sikerült a generálás!");
      }
    },

    cancel(){
      this.$emit("cancel")
    },

    cashPointSelected() {
      this.transaction.recipient =
        this.cashPointStore.typedCashpoints.find(
          (x) => x.id === this.transaction.selectedCashPoint
        )?.employee ?? 1;
      return;
    },
  },

  computed: {
    items() {
      return this.cashPointStore.typedCashpoints;
    },
  },

  async beforeMount() {
    if (employeeStore.employees.length < 1) {
      await employeeStore.loadEmployees();
    }
    await this.cashPointStore.loadCashpointsByType(
      CashPointType["Elszámolási előleg kezelés"]
    );
    this.cashPointStore.getEmployeesWithCashpoint().then((x) => {
      this.employeesWithCashpoint = x;
      return x;
    });

    this.transaction = new CashPointDownPaymentTransaction(
      cashStatementStore.cashDocument.id,
      1,
      0,
      "",
      1
    );
  },
});
