import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import SerCar from "../models/SerCar";
import EleventhDimension from "@/modules/invoiceItemDimension/models/EleventhDimension";

export default class SerCarApi {
  static getAll(): Promise<SerCar[]> {
    return http.get<ApiResponse<SerCar[]>>(`ser-car`).then(handleApiResponse);
  }

  static getAllRegNumsBy(name: string, showDeleted: boolean): Promise<SerCar[]> {
    return http
      .get<ApiResponse<SerCar[]>>(`ser-car/search`, { params: { q: name, x: showDeleted } })
      .then(handleApiResponse);
  }

  static getEleventhDimensions(): Promise<EleventhDimension[]> {
    return http
      .get<ApiResponse<EleventhDimension[]>>(`ser-car/eleventh-dimension`)
      .then(handleApiResponse);
  }

  static get(serCarId: number): Promise<SerCar> {
    return http.get<ApiResponse<SerCar>>(`ser-car/${serCarId}`).then(handleApiResponse);
  }
}
