import AccountNoDetailedType from "@/modules/cashPoint/models/AccountNoDetailedType";
import InvoiceGroupDisplay from "@/modules/invoiceGroup/models/InvoiceGroupDisplay";
import IncomingInvoiceType, { incomingInvoiceTypeDefaultProps } from "../models/IncomingInvoiceType";
import IncomingInvoiceTypeApi from "./IncomingInvoiceTypeApi";
import IncomingInvoiceTypeDisplay from "../models/IncomingInvoiceTypeDisplay";
import IncomingInvoiceSubtype, { incomingInvoiceSubtypeDefaultProps } from "../models/IncomingInvoiceSubtype";
import IncomingInvoiceSubtypeApi from "./IncomingInvoiceSubtypeApi";
import PartnerRelation, { partnerRelationDefaultProps } from "@/modules/partner/models/PartnerRelation";
import eventBus from "@/services/eventBus";
import busyIndication from "../../../utils/busyIndication";

export class IncomingInvoiceTypeStore {
  invoiceTypes: IncomingInvoiceTypeDisplay[] = [];
  invoiceType: IncomingInvoiceType = { ...incomingInvoiceTypeDefaultProps };
  invoiceGroups: InvoiceGroupDisplay[] = [];
  accountDetailedTypes: AccountNoDetailedType[] = [];
  subtypesByType: IncomingInvoiceSubtype[] = [];
  partnerRelationsByType: PartnerRelation[] = [];
  defaultInvoiceType: IncomingInvoiceType = { ...incomingInvoiceTypeDefaultProps };
  defaultSubtypesByType: IncomingInvoiceSubtype[] = [];
  defaultPartnerRelationsByType: PartnerRelation[] = [];

  // Incoming Invoice Type
  @busyIndication()
  async loadInvoiceTypes() {
    this.invoiceTypes = await IncomingInvoiceTypeApi.getAll();
  }

  async loadInvoiceTypesWithoutBusy() {
    this.invoiceTypes = await IncomingInvoiceTypeApi.getAll();
  }

  @busyIndication()
  async loadInvoiceType(id: number) {
    this.invoiceType = await IncomingInvoiceTypeApi.get(id);
    if (this.invoiceType == null) {
      this.invoiceType = { ...incomingInvoiceTypeDefaultProps };
      return;
    }
    this.invoiceType.isAlive = !this.invoiceType?.deleted ?? false;
    this.defaultInvoiceType = Object.assign({}, this.invoiceType);
  }

  // Invoice Groups | Költséghelyek
  async loadInvoiceGroups() {
    this.invoiceGroups = await IncomingInvoiceTypeApi.getInvoiceGroups();
  }

  // Detailed Type | Főkönyvi tétel típusok
  async loadAccountNoDetailedTypes() {
    this.accountDetailedTypes = await IncomingInvoiceTypeApi.getAccountNoDetailedTypes();
  }

  // Incoming Invoice Subtypes
  async loadInvoiceSubtypesByType(type: number) {
    this.subtypesByType = await IncomingInvoiceTypeApi.getAllByType(type);
    if (this.subtypesByType == null) {
      this.subtypesByType = [{ ...incomingInvoiceSubtypeDefaultProps }];
      return;
    }
    this.defaultSubtypesByType = JSON.parse(JSON.stringify(this.subtypesByType));
  }

  // Partner Relations
  async loadPartnerRelationsByInvoiceType(invoiceType: number) {
    this.partnerRelationsByType = await IncomingInvoiceTypeApi.getPartnerRelationsByInvoiceType(invoiceType);
    if (this.partnerRelationsByType == null) {
      this.partnerRelationsByType = [{ ...partnerRelationDefaultProps }];
      return;
    }
    this.defaultPartnerRelationsByType = JSON.parse(JSON.stringify(this.partnerRelationsByType));
  }

  // Incoming Invoice Type
  async save(invType: IncomingInvoiceType, partRelations: PartnerRelation[], subtypes: IncomingInvoiceSubtype[]) {
    this.invoiceType = invType;
    this.partnerRelationsByType = partRelations;
    this.subtypesByType = subtypes;

    console.log(subtypes);
    

    await this.updateAll();
  }

  private async updateAll() {
    this.updateType();
    this.updateSubtypes();
    this.updatePartnerRelations();
  }

  private async updateType() {
    const updatedInvoiceType = this.invoiceType;
    if (this.anyErrors("InvoiceType", updatedInvoiceType, this.defaultInvoiceType)) {
      return;
    }

    await IncomingInvoiceTypeApi.update(updatedInvoiceType);
    eventBus.$emit("success", "Bejövő számla típus frissítve.");

    if (updatedInvoiceType.isDefault) {
      await this.updateTypeDefaultCheck(updatedInvoiceType.id);
    }
  }

  async updateTypeDefaultCheck(updatedInvoiceTypeID: number) {
    // Default check:
    for (let i = 0; i < this.invoiceTypes.length; i++) {
      const invType = this.invoiceTypes[i];

      if (invType.isDefault && invType.id !== updatedInvoiceTypeID) {
        const tempInvType = await IncomingInvoiceTypeApi.get(invType.id);
        tempInvType.isDefault = false;
        await IncomingInvoiceTypeApi.update(tempInvType);

        this.invoiceTypes[i].isDefault = false;
      }
    }
  }

  private async updateSubtypes() {
    const updatedSubtypes = this.subtypesByType;
    if (this.anyErrors("Subtypes[]", updatedSubtypes, this.defaultSubtypesByType)) {
      return;
    }
    updatedSubtypes.forEach(async (updatedSubtype) => {
      await IncomingInvoiceSubtypeApi.update(updatedSubtype);
    });
    eventBus.$emit("success", "Bejövő számla altípus(ok) frissítve.");
  }

  private async updatePartnerRelations() {
    const updatedPartRels = this.partnerRelationsByType;
    if (this.anyErrorsCheckDefault("PartnerRelations[]", updatedPartRels, this.defaultPartnerRelationsByType)) {
      return;
    }
    updatedPartRels.forEach(async (updatedPartRel) => {
      const id = updatedPartRel.id;
      const isSelected = updatedPartRel.isSelected;
      if (id > 1) {
        if (!isSelected) {
          await IncomingInvoiceTypeApi.deletePartnerRelation(updatedPartRel.id);
        } else {
          await IncomingInvoiceTypeApi.updatePartnerRelation(updatedPartRel);
        }
      } else if (id === 0 && isSelected) {
        await IncomingInvoiceTypeApi.createPartnerRelation(updatedPartRel);
      }
    });
    eventBus.$emit("success", "Bejövő számla típus reláció(k) frissítve.");
  }

  // Error checks:
  anyErrors(source: string, updatedObject: any, savedObject: any) {
    if (this.valuesDidNotChange(source, updatedObject, savedObject)) {
      return true;
    } else if (this.isZero(source, updatedObject)) {
      return true;
    }
    return false;
  }

  anyErrorsCheckDefault(source: string, updatedObject: any, savedObject: any) {
    const anyErrorsResult = this.anyErrors(source, updatedObject, savedObject);
    if (anyErrorsResult) {
      return true;
    } else if (this.isDefaultSet(source, updatedObject)) {
      return true;
    }
    return false;
  }

  valuesDidNotChange(source: string, updatedObject: any, savedObject: any) {
    const result = JSON.stringify(updatedObject) === JSON.stringify(savedObject);
    if (result) {
      console.warn(`Update Warning: Values of ${source} did not change.`);
      return true;
    }
    return false;
  }

  isZero(source: string, prop: any) {
    const result = prop.length === 0 || prop.id === 0;
    if (result) {
      console.error(`Update Error: ${source} length or ID is 0.`);
      return true;
    }
    return false;
  }

  isDefaultSet(source: string, updatedObject: any) {
    const result = updatedObject.some((o: any) => {
      return o.isDefault === true;
    });
    if (!result) {
      console.error(`Update Error: ${source} had no IsDefault set.`);
      return true;
    }
    return false;
  }
}

const store = new IncomingInvoiceTypeStore();
export default store;
