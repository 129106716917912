










































































































































































































































import Vue from "vue";
import * as api from "../services/api";
import store, { InvoiceItemStore } from "../services/store";
import IconButton from "../../../components/IconButton.vue";
import Fragment from "@/components/Fragment.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import GeneralIngoingInvoiceItemDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItemDisplay";
import eventBus from "@/services/eventBus";
import MoneyInput from "@/components/MoneyInput.vue";
import GeneralIngoingInvoiceItem from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItem";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import SupplierOrderSubtitle from "./SupplierOrderSubtitle.vue";
import configuration from "@/models/Configuration";

interface InvoiceViewState {
  id: number;
  detailsVisible: boolean;
}

interface SelectedItem {
  id: number;
  item: GeneralIngoingInvoiceItem;
  invoiceId: number;
  invoiceNo: string;
  supplier: string;
  itemName: string;
  priceDivision: number;
  currency: string;
  netto: number;
  weight: number;
  weightUnit: string;
}

interface Data {
  searchText: string;
  store: InvoiceItemStore;
  invoices: GeneralIngoinInvoiceDisplay[];
  invoiceStates: InvoiceViewState[];
  selection: SelectedItem[];
}

interface Methods {
  search(e: KeyboardEvent): void;
  clear(): void;
  showItems(invoice: GeneralIngoinInvoiceDisplay): void;
  addInvoice(invoice: GeneralIngoinInvoiceDisplay): void;
  filterItems(invoice: GeneralIngoinInvoiceDisplay): GeneralIngoingInvoiceItemDisplay[];
  addInvoiceItem(item: GeneralIngoingInvoiceItemDisplay): void;
  invoiceState(id: number): InvoiceViewState | undefined;
  removeSelected(selectedItem: SelectedItem): void;
  submit(): Promise<void>;
  getGeniDigits(currency: string): number;
}

interface Computed {
  selectionIsEmpty: boolean;
  otherCost: number;
  dividedCost: number;
  validation: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    IconButton,
    Fragment,
    MoneyDisplay,
    MoneyInput,
    SupplierOrderSubtitle,
  },

  data: () => ({
    searchText: "",
    store,
    invoices: [],
    invoiceStates: [],
    selection: [],
    addInvoiceCost: () => Promise.resolve(),

    configuration,
  }),

  computed: {
    selectionIsEmpty() {
      return this.selection.length === 0;
    },

    otherCost() {
      return this.store.targetItem?.unitPrice ?? 0.0;
    },

    dividedCost() {
      return +(this.selection.reduce((sum, item) => sum + item.priceDivision, 0.0)).toFixed(2);
    },

    validation() {
      const otherCost = this.store.targetItem?.unitPrice ?? 0.0;
      return otherCost === this.dividedCost;
    },
  },

  methods: {
    getGeniDigits(currency) {
      return configuration.operel
        ? 2
        : configuration.getCurrDigits(currency) !== undefined
        ? configuration.getCurrDigits(currency)?.geniDigits ?? 2
        : 2;
    },

    async search(e: KeyboardEvent) {
      if (e.key !== "Enter") {
        return;
      }

      const data = await api.getInvoices(this.searchText);

      const invoices = (data as GeneralIngoinInvoiceDisplay[]).filter((i) =>
        i.items.some((item) => item.incomingInvoiceItem.stock > 1)
      );

      this.invoiceStates = invoices.map((i) => ({
        id: i.incomingInvoice.id,
        detailsVisible: false,
      }));

      this.invoices = invoices;
    },

    clear() {
      this.invoices = [];
      this.invoiceStates = [];
    },

    showItems(invoice: GeneralIngoinInvoiceDisplay) {
      this.invoiceStates = this.invoiceStates.map((i) => ({
        ...i,
        detailsVisible: i.id === invoice.incomingInvoice.id ? !i.detailsVisible : i.detailsVisible,
      }));
    },

    addInvoice(invoice: GeneralIngoinInvoiceDisplay) {
      const items = this.filterItems(invoice);

      for (const item of items) {
        this.selection.push({
          item: item.incomingInvoiceItem,
          id: item.incomingInvoiceItem.id,
          invoiceId: invoice.incomingInvoice.id,
          invoiceNo: invoice.incomingInvoice.invoiceNo,
          supplier: invoice.partnerName,
          itemName: item.incomingInvoiceItem.name,
          currency: invoice.incomingInvoice.invCurrency,
          priceDivision: 0.0,
          netto: item.incomingInvoiceItem.currNetto,
          weight: item.totalWeight,
          weightUnit: item.weightUnit,
        });
      }
    },

    filterItems(invoice: GeneralIngoinInvoiceDisplay) {
      const selectedIds = this.selection.map((i) => i.id);
      return invoice.items.filter(
        (i) => !selectedIds.includes(i.incomingInvoiceItem.id) && i.incomingInvoiceItem.stock > 1
      );
    },

    addInvoiceItem(item: GeneralIngoingInvoiceItemDisplay) {
      const invoice = this.invoices.find((i) => i.incomingInvoice.id === item.incomingInvoiceItem.invNo);

      if (!invoice) {
        throw new Error("Számla nem található!");
      }

      if (this.selection.some((s) => s.id === item.incomingInvoiceItem.id)) {
        return;
      }

      this.selection.push({
        item: item.incomingInvoiceItem,
        id: item.incomingInvoiceItem.id,
        invoiceId: invoice.incomingInvoice.id,
        invoiceNo: invoice.incomingInvoice.invoiceNo,
        supplier: invoice.partnerName,
        itemName: item.incomingInvoiceItem.name,
        currency: invoice.incomingInvoice.invCurrency,
        priceDivision: 0.0,
        weight: item.totalWeight,
        weightUnit: item.weightUnit,
        netto: item.incomingInvoiceItem.currNetto,
      });
    },

    invoiceState(id: number) {
      return this.invoiceStates.find((i) => i.id === id);
    },

    removeSelected(selectedItem: SelectedItem) {
      this.selection = this.selection.filter((i) => i.id !== selectedItem.id);
    },

    async submit() {
      this.store.itemList.items = this.store.itemList.items.filter((x) => x.rawOtherItem == null);
      await this.store.save();

      const otherCost = this.store.targetItem;
      if (!otherCost) {
        return;
      }

      const success = await api.createSharedCostItem(this.store.invoiceID, {
        name: otherCost.itemName,
        gpartsID: otherCost.gpartID,
        cost: otherCost.unitPrice,
        taxID: otherCost.vatID,
        priceDivision: this.selection.map((i) => ({
          invoiceItemID: i.id,
          nettoValue: i.priceDivision,
        })),
        unit: this.store.units.find((u) => u.name === otherCost.unit)?.id ?? this.store.units[0]?.id,
      });

      if (success) {
        await this.store.reloadItems();
        eventBus.$emit("invoice-item:add");
      }
    },
  },

  watch: {
    selection(value: SelectedItem[]) {
      const otherItem = this.store.targetItem;
      if (!otherItem) {
        return;
      }

      const updatedSelection = this.store.calculateInvoiceStockDivision(otherItem, value);

      for (const item of updatedSelection) {
        const selectedItem = this.selection.find((i) => i.id === item.id);
        if (selectedItem) {
          selectedItem.priceDivision = item.priceDivision;
        }
      }
    },
  },
});
