export default interface NoticeDivitem {
  id: number;
  name: string;
  dimCode1: string;
  depot: number;
  division: number;
}

export const noticeDivitemDefaultProps: NoticeDivitem = {
  id: 1,
  name: "-",
  dimCode1: "",
  depot: 1,
  division: 1,
};
