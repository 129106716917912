














































































import { formatDate } from "@/utils/formatDates";
import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";

interface Data {
  store: InvoiceFlowStore;
}

interface Computed {
  regDate: any;
}

export default Vue.extend<Data, {}, Computed, {}>({
  data() {
    return {
      store,
    };
  },

  methods: {},

  computed: {
    regDate() {
      return formatDate(this.store.invoice.regDate);
    },
  },
});
