













































































































































































































































import Vue from "vue";

import store, { GeneralIngoingInvoiceStore } from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import DateInput from "@/components/DateInput.vue";
import SortView from "@/components/SortView.vue";
import TextInput from "@/components/TextInput.vue";
import ApiSearch from "@/components/apiSearch/models/ApiSearch";
import { FilteringModel, SortingModel, SortOrder } from "@/services/filtering";
import http, { baseURL } from "@/services/http";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: GeneralIngoingInvoiceStore;
  configuration: AppConfiguration;
  apiSearchSortModelLength: number;
  filter: FilteringModel;
  sorting: SortingModel;
}

interface Methods {
  excelExport(): void;
  mergeSortObjects(): void;
}

interface Computed {
  excelExportVisible: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    DateInput,
    TextInput,
    SortView,
  },

  props: {
    apiSearch: ApiSearch,
  },

  data() {
    return {
      store,
      apiSearchSortModelLength: this.$props.apiSearch.sort?.definitions.length,
      configuration,
      filter: store.eFilters,
      sorting: store.eSortModel,
    };
  },

  beforeMount() {
    this.mergeSortObjects();
  },

  methods: {
    excelExport() {
      const filterParam = JSON.stringify(this.filter.filters);
      const sortParam = JSON.stringify((this.$props.apiSearch.sort as SortingModel).toObject());

      http.post(`general-incoming-invoice/excel/k2d`, {
        filter: filterParam,
        sort: sortParam
      },
      {
        responseType: 'blob'
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'invoices.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error(error);
      });

      // const filterParam = encodeURIComponent(JSON.stringify(this.filter.filters));
      // const sortParam = encodeURIComponent(JSON.stringify((this.$props.apiSearch.sort as SortingModel).toObject()));
      // const uri = `${baseURL}general-incoming-invoice/excel/k2d?connectionKey=${http.defaults.headers["X-Trufinance-ConnectionID"]}&filter=${filterParam}&sort=${sortParam}`;
      // window.open(uri);
    },

    mergeSortObjects(): void {
      // this.apiSearch.sort-ba mergeljük a this.store.sortModel-t
      this.store.eSortModel.definitions.forEach((definition2) => {
        if (this.$props.apiSearch.sort) {
          const definition1Index = this.$props.apiSearch.sort.definitions.findIndex(
            (definition1: any) => definition1.field === definition2.field
          );

          if (definition1Index !== -1) {
            this.$props.apiSearch.sort.definitions[definition1Index].order = definition2.order;
          } else {
            this.$props.apiSearch.sort.definitions.push(definition2);
          }
        }
      });
    },
  },

  computed: {
    excelExportVisible() {
      return !this.configuration.operel
    }
  },

  watch: {
    "apiSearch.sort": {
      handler(newVal) {
        // console.log(newVal);
        // mergeSortObjects-ben megvolt a merge, külön kell kezelni viszont az eredeti és az extrasort objektumokat, és resetelni egyiket ha a másikba kerül érték
        if (this.$props.apiSearch.sort && this.apiSearchSortModelLength) {
          for (let i = this.apiSearchSortModelLength; i < this.$props.apiSearch.sort.definitions.length; i++) {
            const element = this.$props.apiSearch.sort.definitions[i];
            if (element.order !== SortOrder.None) {
              for (let i = 0; i < this.apiSearchSortModelLength; i++) {
                const element = this.$props.apiSearch.sort.definitions[i];
                element.order = SortOrder.None;
              }
            }
          }
        }
      },
      deep: true,
    },
  },
});
