



















































import { ValidationFunc } from "@/models/Validation";
import dayjs, { ManipulateType} from "dayjs";
import Vue from "vue";

export default Vue.extend({
  props: {
    className: { type: String, default: "" },
    value: [Date, String],
    disabled: { type: Boolean },
    min: { type: String, default: "" },
    fromToday: { type: Boolean, default: false},
    rules: { type: Array as () => Array<ValidationFunc>, default:() => [(v: Date | string) => true]}
  },

  mounted(){
    if (this.value){
      this.innerValue = this.parseDate(this.value);
    }
  },

  data: () => {
    const obj: { menu1: boolean; innerValue: string | null; dateFormatted: string | null } = {
      menu1: false,
      innerValue: '',
      dateFormatted: ''
    };

    return obj;
  },

  computed: {
    stringValue() {
      if (!this.value) {
        return null;
      }

      return dayjs(this.value).format("YYYY-MM-DD");
    },
    stringToday() {
      return dayjs(new Date()).format("YYYY-MM-DD");
    },
  },

  methods: {
    onInput(value: (string | null), addoffset = false) {
      if (value) {

        const pattern = /\d{4}.\d{2}.\d{2}./g;
        const pattern2 = /\d{4}-\d{2}-\d{2}/g;
        const pattern3 = /\d{4}.\d{2}.\d{2}/g;
        if(pattern.test(value) || pattern2.test(value) || pattern3.test(value))
        {
          this.innerValue = this.parseDate(value);

          let min = this.min;
          if (this.fromToday && !min){
            min = dayjs(new Date()).format('YYYY-MM-DD');
          }

          if ( !!(min) && dayjs(this.innerValue).isBefore(min)){
            this.innerValue = '';
            this.$emit("input", null);
          } else {
            const originalTimeZoneOffset = dayjs(value).toDate().getTimezoneOffset();
            const ret = dayjs(value).add(addoffset ? (originalTimeZoneOffset * -1) : 0, 'minute').toDate();

            this.$emit("input", ret);
          }
        } else {
          this.innerValue = '';
          this.$emit("input", null);
        }
        
      } else {
        this.$emit("input", null);
      }
    },

      parseDate (date: (string | null) ) {
        if (!date) return null;

        const ret = dayjs(date).format("YYYY-MM-DD");

        return ret;
      },
  },

  watch: {
    innerValue(){
      if (!(this.innerValue) || this.innerValue == '')  {
        this.dateFormatted = null;
        return;
      }

      this.dateFormatted = dayjs(this.innerValue).format("YYYY.MM.DD.");
    },

    value(){
      this.innerValue = this.parseDate(this.value);
    }
  },
});
