




















































































import Vue from "vue";
import store, { CashPointStore } from "../services/CashPointStore";
import Page from "../../../components/Page.vue";
import CashPointDisplay from "../models/CashPointDisplay";
import setAttribute from "../../../utils/dom";

interface Data {
  store: CashPointStore;
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "6");
  },
};
export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    confirmation: confirmationTemplate,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadCashPoints();
  },

  computed: {
    columns: () => [
      { field: "bankNo", title: "Bankszámla" },
      { field: "name", title: "Pénztár neve" },
      { field: "partner", title: "Tulajdonos" },
      { field: "cashier", title: "Pénztáros" },
      { field: "site", title: "Telephely" },
      { cell: "commandTemplate", width: "200px" },
      { width: 0 },
    ],

    items() {
      return this.store.cashPoints;
    },
  },

  methods: {
    createCashPoint() {
      this.$router.push(`/cash-points/create`);
    },

    updateCashPoint(item: CashPointDisplay) {
      this.$router.push(`/cash-points/${item.id}`);
    },

    deleteItem(item: CashPointDisplay) {
      this.store.setDeleting(item);
    },

    confirmDelete(item: CashPointDisplay) {
      item.deleting = false;
      this.store.delete(item.id);
    },

    cancelDelete(item: CashPointDisplay) {
      item.deleting = false;
    },
  },
});
