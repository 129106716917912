export default class OverBillingInvItem {
    id= 1;
    iniPrefix = "";
    invNo = 1;
    ouiPrefix = "";
    ouiInv= 1;
    partner= 1;
    partnerName= "";
    invDate= new Date();
    outinvtype= 1;
    gparts= 1;
    name= "";
    unitPrice= 0;
    quantity= 0;
    unit= 0;
    netto= 0;
    tax= 1;
    vat= 0;
    brutto= 1;
    currency= "";
    accGroup= 1;
    accCode= "";
    note= "";
    userid= 1;
    insDate= new Date();
    insTime= new Date();
    divitem= 1;
    car= 1;
    invNoItem= 1;

    new= false;
}