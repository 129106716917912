




































































































































import Vue from "vue";

import Fragment from "../../../components/Fragment.vue";
import IconButton from "../../../components/IconButton.vue";
import AttributeList from "./AttributeList.vue";
import InvoiceRelationBadge from "./InvoiceRelationBadge.vue";

import { ExtOrderItem } from "../models";
import store, { InvoiceItemStore } from "../services/store";
import AddItemButton from "./AddItemButton.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import configuration from "@/models/Configuration";

interface ItemState {
  quantity: number;
  unitPrice: number;
  attributesVisible: boolean;
  selected: boolean;
}

interface Data {
  store: InvoiceItemStore;
  itemStates: Record<number, ItemState>;
}

interface Props {
  items: ExtOrderItem[];
}

interface Computed {
  hasData: boolean;
  hasSelectedItem: boolean;
  currency: string;
  conditionalColumns: string[];
}

interface Methods {
  submitOrderItem(orderItem: ExtOrderItem): void;
  initialize(items: ExtOrderItem[]): void;
  toggleAttributes(item: ExtOrderItem): void;
  quantityChanged(item: ExtOrderItem, value: number): void;
  toggleItemSelection(item: ExtOrderItem): void;
  addSelectedItems(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Fragment,
    IconButton,
    InvoiceRelationBadge,
    AttributeList,
    AddItemButton,
    MoneyDisplay,
    MoneyInput,
  },

  data: () => ({
    store,
    itemStates: {},

    configuration,
  }),

  props: {
    items: {
      type: Array as () => ExtOrderItem[],
    },
  },

  computed: {
    hasData() {
      return this.items && this.items.length > 0;
    },

    currency() {
      return this.store.currency;
    },

    hasSelectedItem() {
      return this.items.some((i) => this.itemStates[i.id]?.selected);
    },

    conditionalColumns() {
      const columns = [];

      if (this.items.some((i) => i.targetUser || i.targetSection)) {
        columns.push("target-data");
      }

      if (this.items.some((i) => i.relatedInvoices.length > 0)) {
        columns.push("related-invoices");
      }

      return columns;
    },
  },

  mounted() {
    this.initialize(this.items);
  },

  methods: {
    submitOrderItem(orderItem: ExtOrderItem) {
      const state: ItemState = this.itemStates[orderItem.id];
      store.addExtOrderItem(orderItem, state.quantity, state.unitPrice);
    },

    initialize(items: ExtOrderItem[]) {
      const itemStates: Record<number, ItemState> = {};

      items.forEach((v) => {
        itemStates[v.id] = {
          quantity: v.quantity,
          unitPrice: v.unitPrice,
          attributesVisible: false,
          selected: false,
        };
      });

      this.itemStates = itemStates;
    },

    toggleAttributes(item: ExtOrderItem) {
      this.itemStates[item.id].attributesVisible = !this.itemStates[item.id].attributesVisible;
    },

    quantityChanged(item: ExtOrderItem, value: number) {
      if (value <= 0 || value > item.quantity) {
        this.itemStates = {
          ...this.itemStates,
          [item.id]: {
            ...this.itemStates[item.id],
            quantity: item.quantity,
          },
        };
      }
    },

    toggleItemSelection(item: ExtOrderItem) {
      const state: ItemState = this.itemStates[item.id];
      state.selected = !state.selected;
    },

    addSelectedItems() {
      const stocks = this.items.filter((i) => this.itemStates[i.id]?.selected);
      stocks.forEach((i) => {
        this.submitOrderItem(i);
      });
    },
  },

  watch: {
    items: "initialize",
  },
});
