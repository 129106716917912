







import Vue from 'vue'
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn } from '@/components/apiSearch/models/ApiSearch';

export default Vue.extend({
    components: {
        ApiSearchPage
    },

    computed: {
        url() {
      return `cashPoint/open-payoff-downpayments`;
    },

    columns: (): ApiSearchColumn[] => [
      { field: "name", title: "Pénztár" },
      { field: "employee", title: "Munkavállaló" },
      { field: "statement", title: "Kivonat" },
      { field: "document", title: "Bizonylat sorszám" },
      { field: "docItem", title: "Bizonylat tétel" },
      { field: "amount", title: "Bizonylat tétel összege" },
      { field: "amount", cell: "select", title: "", fieldClass: "text-center", filterable: false, sortable: false},
    ]
    }
})
