import DocumentAttachApi from "./DocumentAttachApi";
import DocumentStatement from "./DucomentStatement";
import { IdNameDto } from "@/modules/letterOfCredit/letterOfCredit";

export default class DocumentAttachStore {
  documentsStatements: DocumentStatement[] = [];
  documentTypes: IdNameDto[] = [];
  requiredDocuments: RequiredDocument[] = [];

  async loadAllRequiredDocumentBy(submitRequirementId: number) {
    this.requiredDocuments = (
      await DocumentAttachApi.getSubmitDocumentRequirements(submitRequirementId)
    ).map((x) => ({
      ...x,
      isOk: false,
    }));

    this.remarkDocumentRequiremens();
  }

  remarkDocumentRequiremens() {
    const docTypes = this.documentsStatements
      .filter((x) => x.hasFile)
      .map((x) => x.documentTypeId);
    this.requiredDocuments.forEach((item) => {
      item.isOk = docTypes.includes(item.id);
    });
  }

  async loadAllStatement(statementId: number, table: string, column: string) {
    this.documentsStatements = (
      await DocumentAttachApi.getDocumentStatements(statementId, table, column)
    ).map((x) => ({
      ...x,
      link: DocumentAttachApi.generateDocumentDownLoadLink(x.id),
      uploading: false,
    }));

    this.remarkDocumentRequiremens();
  }

  async loadAllDocumentTypes(table: string, column: string) {
    this.documentTypes = await DocumentAttachApi.getDocumentTypes(
      table,
      column
    );
  }

  async uploadStatement(
    table: string,
    column: string,
    itemId: number,
    doctype: number,
    file: File
  ) {
    return await DocumentAttachApi.uploadStatement(
      table,
      column,
      itemId,
      doctype,
      "",
      file
    );
  }
}

export class RequiredDocument extends IdNameDto {
  isOk = false;
}
