import { IncomingInvoiceTimelineDescriptionItem, IncomingInvoiceTimelineItem } from "./../../actionLog/models/index";
import dayjs, { Dayjs } from "dayjs";

export default class FlowHistory implements IncomingInvoiceTimelineItem {
  orderId = 0;
  orderNo = "";
  storeIn = 0;
  orderDate: Date = new Date();
  offerDate: Date = new Date();
  orderer = "";
  deliveryDate: Date = new Date();
  certifNo = "";
  storeInUser = "";
  invoiceDate: Date = new Date();
  regDate: Date = new Date();

  constructor(params?: Partial<FlowHistory>) {
    const data = { ...params };
    Object.assign(this, data);
  }

  /*
   * IncomingInvoiceTimelineItem implementation for IncomingInvoiceTimeline.vue
   */
  get timestamp(): Dayjs {
    return dayjs(this.regDate);
  }

  get title(): string {
    return `Beszerzés`;
  }

  get subtitle(): string {
    return ``;
  }

  get description(): string | IncomingInvoiceTimelineDescriptionItem[] | undefined {
    return [
      { label: `Rendelés:`, value: `${dayjs(this.orderDate).format("YYYY. MM. DD.")}, ${this.orderer}` },
      { label: `Megrendelés szám:`, value: this.orderNo },
      { label: `Betárolás:`, value: `${dayjs(this.deliveryDate).format("YYYY. MM. DD.")}, ${this.storeInUser}` },
      { label: `Szállítólevél szám:`, value: this.certifNo },
      { label: `Számla kelte:`, value: `${dayjs(this.invoiceDate).format("YYYY. MM. DD.")}` },
    ];
  }

  get icon(): string {
    return `fa-cube`;
  }

  get color(): string {
    return `lighten-3 blue`;
  }
}
