import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import BankRemitAndItems, {
  BankRemitResponse,
} from "../models/BankRemitAndItems";
import BankRemitStatus from "../models/BankRemitStatus";
import PartBank from "@/modules/cashPoint/models/PartBank";
import BankRemitItem from "../models/BankRemitItem";
import InvoiceBankRemitView from "../models/InvoiceBankRemitView";
import BankOverOutView from "../models/BankOverOutView";
import BankRemitHistory from "../models/BankRemitHistory";
import BankRemitApproval from "../models/BankRemitApproval";
import BankRemitAnalitics from "../models/BankRemitAnalitics";
import PaymentScheduleView from "../models/PaymentScheduleView";
import User from "@/modules/user/models/User";
import dayjs from "dayjs";

export default class BankRemitApi {
  static async importRemitFile(file: File): Promise<string> {
    return await http
      .post<ApiResponse<string>>(`bank-remit/import`, file, {
        headers: {
          "Content-Type": "multipart/mixed",
        },
      })
      .then(handleApiResponse);
  }

  static async getAllApproverUser(){
    return await http
        .get<ApiResponse<User[]>>(`bank-remit/approver-users`)
        .then(handleApiResponse)
  }

  static async getExportTypes(remitId: number): Promise<string[]> {
    return await http
      .get<ApiResponse<string[]>>(`bank-remit/${remitId}/exporttypes`)
      .then(handleApiResponse);
  }

  static async generatePackage(
    remit: BankRemitAndItems,
    exportType: string
  ): Promise<boolean> {
    return await http
      .post<ApiResponse<boolean>>(`bank-remit/bankexport/${exportType}`, remit)
      .then(checkResponse);
  }

    static getAll(pageNumber: number, pageSize: number, searchText: string): Promise<BankRemitResponse> {
        return http
            .get<ApiResponse<BankRemitResponse>>(`bank-remit`, {
                params: {
                    pageNumber,
                    pageSize,
                    searchText
                }
            })
            .then(handleApiResponse)
    }


    static async getBankRemitHistory(remitid: number): Promise<BankRemitHistory[]> {
        return await http
            .get<ApiResponse<BankRemitHistory[]>>(`bank-remit/${remitid}/history`)
            .then(handleApiResponse)
    }

    static async get(remitId: number): Promise<BankRemitAndItems> {
        return await http
            .get<ApiResponse<BankRemitAndItems>>(`bank-remit/${remitId}`)
            .then(handleApiResponse)
    }

    static async create(bankRemit: BankRemitAndItems): Promise<number> {
        return await http
            .post<ApiResponse<number>>(`bank-remit`, bankRemit)
            .then(handleApiResponse)
    }

    
    

    static async update(bankRemit: BankRemitAndItems) {
        return await http
            .put(`bank-remit/${bankRemit.id}`, bankRemit)
            .then(handleApiResponse)
    }

    static async getBankRemitStatuses(): Promise<BankRemitStatus[]> {
        return await http
            .get<ApiResponse<BankRemitStatus[]>>(`bank-remit/status`)
            .then(handleApiResponse)
    }

    static getPartBanks(): Promise<PartBank[]> {
        return http
            .get<ApiResponse<PartBank[]>>(`partBank/${true}`)
            .then(handleApiResponse)
    }

    static getPartBanksByPartner(partnerId: number): Promise<PartBank[]> {
        return http
            .get<ApiResponse<PartBank[]>>(`partBank/${true}/${partnerId}`)
            .then(handleApiResponse)
    }

    static getBankDefrTypes(): Promise<string[]> {
        return http
        .get<ApiResponse<string[]>>(`bank-remit/bankDefrTypes`)
        .then(handleApiResponse);
    }

    //item
    static getInvoiceBankRemitViews(bankAcc: string, partner: string, currency: string, invDateMin: string, invDateMax: string, ptermMin: string, ptermMax: string, payModeFilters: number[], outSiderFilter: boolean, inSiderFilter: boolean, geniFilter: boolean, genoFilter: boolean, invFilter: boolean, vamFilter: boolean, arrivedFilter: boolean, controlledFilter: boolean, appovedFilter: boolean, remittanceFilter: boolean, sameBank: boolean, bankId: number): Promise<InvoiceBankRemitView[]> {
        return http
            .get<ApiResponse<InvoiceBankRemitView[]>>(`bank-remit/invoice-remit-views`, {
                params: {
                    bankAcc,
                    partner,
                    currency,
                    invDateMin,
                    invDateMax,
                    ptermMin,
                    ptermMax,
                    payModeFilters,
                    outSiderFilter,
                    inSiderFilter,
                    geniFilter,
                    genoFilter,
                    invFilter,
                    vamFilter,
                    arrivedFilter,
                    controlledFilter,
                    appovedFilter,
                    remittanceFilter,
                    sameBank,
                    bankId,
                }
            })
            .then(handleApiResponse)
    }

    static getBankOverOutViews(bankAcc: string, partner: string, currency: string, accDateMin: string, accDateMax: string, pDateMin: string, pDateMax: string): Promise<BankOverOutView[]> {
        return http
            .get<ApiResponse<BankOverOutView[]>>(`bank-remit/bank-over-out`, {
                params: {
                    bankAcc,
                    partner,
                    currency,
                    accDateMin,
                    accDateMax,
                    pDateMin,
                    pDateMax,
                }
            })
            .then(handleApiResponse)
    }

    static getPaymentScheduleViews(bankAcc: string, partner: string, currency: string, accDateMin: string, accDateMax: string, pDateMin: string, pDateMax: string): Promise<PaymentScheduleView[]> {
        return http
            .get<ApiResponse<PaymentScheduleView[]>>(`bank-remit/payment-schedule`, {
                params: {
                    bankAcc,
                    partner,
                    currency,
                    accDateMin,
                    accDateMax,
                    pDateMin,
                    pDateMax,
                }
            })
            .then(handleApiResponse)
    }

    static createItem(bankRemitItem: BankRemitItem): Promise<number> {
        return http
            .post<ApiResponse<number>>(`bank-remit/item`, bankRemitItem)
            .then(handleApiResponse)
    }

    static updateItem(bankRemitItem: BankRemitItem): Promise<boolean> {
        return http
            .put(`bank-remit/item/${bankRemitItem.itemId}`, bankRemitItem)
            .then(checkResponse)
    }

    static deleteItem(itemId: number): Promise<boolean> {
        return http.delete(`bank-remit/item/${itemId}`).then(checkResponse);
    }

    //approval
    static async createBankRemitApproval(bankRemitApproval: BankRemitApproval): Promise<boolean> {
        const body = {
            id: bankRemitApproval.id,
            bankRemit: bankRemitApproval.bankRemit,
            userId: bankRemitApproval.userId,
            userName: bankRemitApproval.userName ?? "",
            acceptorId: bankRemitApproval.acceptorId,
            acceptorName: bankRemitApproval.acceptorName ?? "",
            remark: bankRemitApproval.remark ?? "",
            insDate: dayjs(bankRemitApproval.insDate).toDate(),
            accDate: dayjs(bankRemitApproval.accDate).toDate()
        };
        return await http
            .post<ApiResponse<boolean>>(`bank-remit/approval`, body)
            .then(checkResponse)
    }

    static getApproval(remitId: number): Promise<BankRemitApproval> {
        return http
            .get<ApiResponse<BankRemitApproval>>(`bank-remit/approval/${remitId}`)
            .then(handleApiResponse)
    }

    static updateApproval(bankRemitApproval: BankRemitApproval): Promise<boolean> {
        const body = {
            id: bankRemitApproval.id,
            bankRemit: bankRemitApproval.bankRemit,
            userId: bankRemitApproval.userId,
            userName: bankRemitApproval.userName ?? "",
            acceptorId: bankRemitApproval.acceptorId,
            acceptorName: bankRemitApproval.acceptorName ?? "",
            remark: bankRemitApproval.remark ?? "",
            insDate: dayjs(bankRemitApproval.insDate).toDate(),
            accDate: dayjs(bankRemitApproval.accDate).toDate()
        };
        return http
            .post<ApiResponse<boolean>>(`bank-remit/approval/update`,body)
            .then(handleApiResponse)
    }

    static approve(bankRemitApprovalId: number): Promise<boolean> {
        return http
            .put(`bank-remit/approve/${bankRemitApprovalId}`)
            .then(checkResponse)
    }

    //analitics
    static loadAnalitics(remitId: number): Promise<BankRemitAnalitics> {
        return http
            .get<ApiResponse<BankRemitAnalitics>>(`bank-remit/analitics/${remitId}`)
            .then(handleApiResponse)
    }
}
