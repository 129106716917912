import GeneralIngoingInvoiceItem from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItem";
import Tax from "@/modules/generalIngoingInvoice/models/Tax";

export default interface VatDivision{
    id: number;
    invNo: number;
    tax: number;
    netto: number;
    vat: number;
    brutto: number;
    currency: string;
    invNetto: number;
    invVat: number;
    invBrutto: number;
    invCurrency: string;
    substractable: boolean;
    isInvest: boolean;
    backDemand: boolean;
    isService: boolean;
    tax2: number;

    items: GeneralIngoingInvoiceItem[];
}

export interface VatDivisionModifier{
    invoiceItemId: number;
    invoiceId: number;
    vatDivisionId: number;
}

export const VatDivisionModifierDefProps: VatDivisionModifier={
    invoiceItemId:1,
    invoiceId:1,
    vatDivisionId:1
}