var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.showLabel)?_c('v-col',{staticClass:"item-label",attrs:{"cols":_vm.labelCol,"md":_vm.labelmd,"align":"right"}},[_c('label',{staticClass:"input-label"},[_vm._v("Bankszámla")])]):_vm._e(),_c('v-col',{staticClass:"item-value",attrs:{"cols":_vm.itemCol}},[_c('v-row',[_c('v-text-field',{class:'append-outer-fix ' + _vm.extraClass,staticStyle:{"width":"250px"},attrs:{"disabled":_vm.disabled,"readonly":"","outlined":"","hide-details":"auto"},on:{"click":function($event){_vm.bankAccountDialog = !_vm.bankAccountDialog}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":_vm.disabled},on:{"click":function($event){_vm.bankAccountDialog = !_vm.bankAccountDialog}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-caret-down")])],1),(_vm.addNew)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"disabled":_vm.disabled,"outlined":"","color":"primary","height":"31px !important"},on:{"click":function($event){return _vm.$emit('visibleChanged')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]}}],null,false,1981257988)},[_c('span',[_vm._v("Új bankszámla hozzáadása")])]):_vm._e()]},proxy:true}]),model:{value:(_vm.selectedName),callback:function ($$v) {_vm.selectedName=$$v},expression:"selectedName"}})],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bankAccountDialog),expression:"bankAccountDialog"}],staticClass:"fadeDiv"},[_c('v-row',[_c('grid',{staticStyle:{"max-height":"900px","width":"100%"},attrs:{"data-items":_vm.items,"selected-field":"selected","columns":[
              { field: 'ibanNo', title: 'IBAN' },
              { field: 'accountNo', title: 'Bankszámlaszám', width: 200 },
              { field: 'bank', title: 'Bank' },
              { field: 'swiftNo', title: 'SWIFT' },
              { field: 'currency', title: 'Pénznem', width: 80 } ],"pageable":"","skip":_vm.skip,"take":_vm.take,"total":_vm.total},on:{"rowclick":_vm.itemSelected,"pagechange":_vm.pageChange}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }