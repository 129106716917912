var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.store.accountingStatuses.length > 0 && _vm.pStore.paymentModes.length > 0
    )?_c('ApiSearchPage',{attrs:{"delay":1000,"search":false,"title":"Főkönyvi feladás","name":"invoice-ledgers","url":_vm.url,"columns":_vm.columns},on:{"items":function (items) { return _vm.setShowedItems(items); }},scopedSlots:_vm._u([{key:"accStatus",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.accStatus.name)+" ")]}},{key:"partner",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.partner.name)+" ")]}},{key:"pmode",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.pmode.name)+" ")]}},{key:"accGroup",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.accGroup.name)+" ")]}},{key:"selector",fn:function(ref){
    var item = ref.item;
return [_c('CheckBox',{attrs:{"selected":_vm.isItemSelected(item)},on:{"change":function($event){return _vm.toggleItemSelected(item)}}})]}},{key:"selectorHeader",fn:function(){return [_c('CheckBox',{attrs:{"selected":_vm.isAllSelected},on:{"change":function($event){return _vm.toggleAllSelected()}}})]},proxy:true}],null,false,1148424968)}):_vm._e(),_c('v-card-actions',[_c('v-row',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","disabled":_vm.selectedItems.length < 1},on:{"click":function($event){return _vm.preAccounting()}}},[_vm._v(" Számlák újrakontírozása ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selectedItems.length < 1},on:{"click":function($event){return _vm.postAccounting()}}},[_vm._v(" Számlák feladása főkönyvbe ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }