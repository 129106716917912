import eventBus from "@/services/eventBus";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import * as api from "@/modules/invoiceItems/services/api";
import store, { InvoiceItemStore } from "@/modules/invoiceItems/services/store";
import eStore from "../services/ExcelStore";
import ApiResponse from "@/models/ApiResponse";
import { InvoiceItemsTable } from "@/modules/invoiceItems";
import ExcelResponse from "../models/ExcelResponse";


export default class ExcelApi{
    static async PostExcel(formData: FormData, invoiceId: number)
    {
         await http
        .post<ExcelResponse>(`uploadExcel`, formData, {
          headers: {
            "Content-Type": "multipart/mixed",
          },
        })
        .then(({ data })=> {eStore.excelResponse=data; console.log(eStore.excelResponse)})
        .then(async function updateItems() {
          await api.getInvoiceItems(invoiceId).then((invoiceItems) =>
            store.update({
              invoiceItems,
            }) 
          );
        }
        )
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }

    static async PostAccountingExcel (formData: FormData, invoiceId: number): Promise<string[]>
    {
      return await http
        .post<ApiResponse<string[]>>(`accounting/excelpost`, formData, {
          headers: {"Content-Type": "multipart/mixed",},
        })
        .then(handleApiResponse)
    }
}