var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v("Jóváhagyók")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"item-label",attrs:{"sm":"4","md":"4","lg":"5","align":"right"}},[_c('label',{staticClass:"input-label"},[_vm._v("Jóváhagyó")])]),_c('v-col',{staticClass:"item-value",attrs:{"cols":"auto"}},[_c('v-combobox',{attrs:{"dense":"","outlined":"","items":_vm.acceptors,"item-text":"name"},on:{"change":_vm.selectedAcceptorChanged}})],1)],1),_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns,"scrollable":"none","detail":"deletingTemplate","expand-field":"deleting"},scopedSlots:_vm._u([{key:"remarkTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-text-field',{attrs:{"outlined":"","single-line":"","hide-details":"auto"},model:{value:(props.dataItem.remark),callback:function ($$v) {_vm.$set(props.dataItem, "remark", $$v)},expression:"props.dataItem.remark"}})],1)]}},{key:"requestTypeTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"badge black--text grey lighten-3 d-flex justify-center"},[_c('strong',[_vm._v(" "+_vm._s(props.dataItem.isFullFillReq ? "Teljesítés jóváhagyás" : "Jóváhagyás")+" ")])])])]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.setAcceptorDeleting(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Ez a Törlés?")])])],1)]}},{key:"deletingTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"yellowBg"},[_c('colspanFixer'),_c('div',{staticClass:"auth"},[_c('p',{staticClass:"conf"},[_vm._v(" Biztos hogy törli a következő felhasználó kérelmét: "),_c('strong',[_vm._v(_vm._s(props.dataItem.name))]),_vm._v(" ? ")])]),_c('div',{staticClass:"pr-3"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmDelete(props.dataItem)}}},[_vm._v(" Megerősítés ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.cancelDelete(props.dataItem)}}},[_vm._v(" Mégsem ")])],1)],1)]}}])})],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.closeAcceptorsView}},[_vm._v("Mégse")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updateDelegateRequests}},[_vm._v("Küldés")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }