

























































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, {
  AccountActionSourceStore,
} from "../services/AccountActionSourceStore";
import AccountActionSource from "../models/AccountActionSource";

interface Data {
  store: AccountActionSourceStore;
  opened: boolean;
  valid: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      valid: true,
      opened: false,
    };
  },

  async beforeMount() {
    const id = Number(this.$route.params.accountActionSourceId);
    await this.store.loadAccountActionSource(id);
  },

  methods: {
    async save(item: AccountActionSource) {
      await this.store.save(item, {});
      this.$router.push("/settings/account/action-source");
    },
  },
});
