export default interface InvoiceType {

    id: number;
    name: string;
    foreign: string;
    english: string;
    default: boolean;
    inAdvance: boolean;
    code: string;
    typeCode: string;
    accountCode: string;
    detailedType: string;

    partnerRelations: [
        {
            partnerRelationID: number;
            partnerRelationName: string;
            selected: boolean;
        }
    ];
    deleting: boolean;
}


export const invoiceTypeDefaultProps: InvoiceType = {

    id: 0,
    name: '',
    foreign: '',
    english: '',
    default: false,
    inAdvance: false,
    code: '-',
    typeCode: '',
    accountCode: '',
    detailedType: '--',

    partnerRelations: [
        {
            partnerRelationID: 0,
            partnerRelationName: '',
            selected: false,
        }
    ],
    deleting: false,
}
