























































































































































































































































































































































































































































































































































































































































































































































































































































































































































interface Methods {
    generate(age: boolean): void;
    depotSelection(item: Depot): void;
    partnerSelected(partner: Partner): void;
    deletePartner(partner: Partner): void;
    invGroupSelection(item: InvGroup): void;
    periodChanged(): void;
    replaceAt(input: string, index: number, replace: string): string;
}

interface Data {
  invDate: boolean;
  invDateIg: Date;
  invDateTol: Date;
  pTerm: boolean;
  pTermIg: Date;
  pTermTol: Date;
  shipDate: boolean;
  shipDateIg: Date;
  shipDateTol: Date;
  accDate: boolean;
  accDateIg: Date;
  accDateTol: Date;
  accStatusItems: any[];
  accStatus: any;
  accStatusId: number | null;
  fordulo: boolean;
  penzugyiOrSzamviteli: boolean | null;
  isInvoiceDate: boolean | null;
  dpFordulo: Date;
  fordInvPaym: boolean | null;
  insPayDate: boolean | null;
  present: boolean;
  paymentModes: PaymentMode[];
  selectedPaymentModes: number[];
  bank: boolean;
  cash: boolean;
  other: boolean;
  prefixes: string[];
  selectedPrefixes: string[];
  maxDisplay: number;
  invoiceTypes: InvoiceType[];
  selectedInvoiceTypes: number[];
  reportTitle: string;
  invRels: PartnerRel[];
  selectedInvRels: number[];
  type: number;
  invType: number;
  overPay: boolean;
  behajtKtsVisible: boolean;
  balanceDate: Date;
  withExtra: number;
  elolegType: number;
  nulla: boolean;
  lapdobas: boolean;
  openType: number;
  delivType: boolean;
  secType: boolean;
  all: boolean;
  include: boolean;
  defrayer: boolean;
  outsider: boolean;
  depot: boolean;
  depotItems: Depot[];
  partnerItems: Partner[];
  invRiport: InvRiport;
  isPrinting: boolean;
  isExcel: boolean;

  isPeriod: boolean;
  periodFrom: Date;
  period1: number;
  period2: number;
  period3: number;
  period4: number;
  period5: number;
  period6: number;
  period7: number;
  period8: number;
  period9: number;
  period10: number;
  period11: number;
  period12: number;
  period13: number;
  period14: number;
  isInvGroup: boolean;
  invGroupFilter: boolean;
  invGroups: InvGroup[];
  isInvGroupVisible: boolean;
  isPeriod6: boolean;
  isPeriod7: boolean;
}

interface Computed {
  penzugyOrSzamviteliVisibe: boolean;
  isSzamviteli: boolean;
  insDateVisible: boolean;
  fordInv: boolean;
  fordPaym: boolean;
  insDate: boolean;
  pDate: boolean;
  simple: boolean;
  tabl: boolean;
  age: boolean;
  bony: boolean;
  balance: boolean;
  age2: boolean;
  dev: boolean;
  vevo: boolean;
  szallito: boolean;
  tartozik: boolean;
  kovetel: boolean;
  mind: boolean;
  extra: boolean;
  wExtra: boolean;
  eloleg: boolean;
  veg: boolean;
  eloVeg: boolean;
  nyitott: boolean;
  allSzla: boolean;
  deliv: boolean;
  delivNo: boolean;
  sec: boolean;
  secNo: boolean;
  depotColumns: any[];
  partnerColumns: any[];
  invGroupColumns: any[];
}

import Vue from 'vue';
import dayjs, { ManipulateType} from "dayjs";
import PaymentMode from './models/PaymentMode';
import InvoiceType from './models/InvoiceType';
import PartnerRel from './models/PartnerRel';
import api from './services/ParamtererizedAccountReportApi';
import Depot from './models/Depot';
import Partner, { partnerDefaultProps } from "../../partner/models/Partner";
import InvGroup from './models/InvGroup';
import InvRiport from './models/InvRiport';

import CheckBox from "@/components/CheckBox.vue";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import IconButton from "@/components/IconButton.vue";
import eventBus from '@/services/eventBus';
import DateInput from "@/components/DateInput.vue";

export default Vue.extend<Data, Methods, Computed, {}>({
    components: {
      CheckBox, PartnerSearch, IconButton, DateInput
    },

    data: () => ({
      invDate: false,
      invDateIg: new Date(),
      invDateTol: new Date(),
      pTerm: false,
      pTermIg: new Date(),
      pTermTol: new Date(),
      shipDate: false,
      shipDateIg: new Date(),
      shipDateTol: new Date(),
      accDate: false,
      accDateIg: new Date(),
      accDateTol: new Date(),
      accStatusItems: [{name: 'Feladva (külső)', id: 4}, { name: 'Fők. rendsz. feladva', id: 6 }],
      accStatus: {name: 'Feladva (külső)', id: 4},
      accStatusId: 4,
      fordulo: true,
      fordInvPaym: null,
      insPayDate: null,
      dpFordulo: new Date(),
      present: false,
      paymentModes: [],
      selectedPaymentModes: [],
      penzugyiOrSzamviteli: true, //TODO null
      isInvoiceDate: null, //TODO null
      bank: true,
      cash: true,
      other: true,
      prefixes: [ '-', 'GENI', 'GENO', 'INTO', 'INTR', 'NOTO', 'PARO', 'SERO', 'SHCO', 'CARO', 'VIRO', 'VIRI' ],
      selectedPrefixes: [ '-', 'GENI', 'GENO', 'INTO', 'INTR', 'NOTO', 'PARO', 'SERO', 'SHCO', 'CARO', 'VIRO', 'VIRI' ],
      maxDisplay: 4,
      invoiceTypes: [],
      selectedInvoiceTypes: [],
      reportTitle: 'Nyitott számlák adott fordulónapon partnerenként, prefixenként',
      invRels: [{ id: 2, name: 'Vevő'}, { id: 3, name: 'Szállító'}, {id: 4, name: 'Munkavállaló'}, { id: 5, name: 'Hatóság'}, {id: 6, name: 'Hitelező'}, { id: 7, name: 'Biztosító'}, { id: 8, name: 'Finanszírozó'}, { id: 9, name: 'Vámhatóság'}],
      selectedInvRels: [2, 3],
      type: 1,
      invType: 5,
      overPay: true,
      behajtKtsVisible: false,
      balanceDate: new Date(),
      withExtra: 1,
      elolegType: 3,
      nulla: true,
      lapdobas: false,
      openType: 1,
      delivType: false,
      secType: false,
      all: true,
      include: true,
      defrayer: true,
      outsider: false,
      depot: true,
      depotItems: [],
      partnerItems: [],
      invRiport: new InvRiport(),
      isPrinting: false,
      isExcel: false,

      isPeriod:false,
      periodFrom: new Date(),
      period1: 1,
      period2: 30,
      period3: 31,
      period4: 60,
      period5: 61,
      period6: 90,
      period7: 91,
      period8: 180,
      period9: 181,
      period10: 360,
      period11: 361,
      period12: 540,
      period13: 541,
      period14: 720,
      isInvGroup: false,
      invGroupFilter: false,
      invGroups: [],
      isInvGroupVisible: false,
      isPeriod6: false,
      isPeriod7: false
    }),

    async beforeMount(){
      this.paymentModes = await api.getPModes();
      this.invoiceTypes = await api.getInvoiceTypes();
      this.selectedInvoiceTypes = this.invoiceTypes.map(x => x.id);
      this.depotItems = (await api.getDepots()).map(x => ({ ...x, selected: true}));

      this.behajtKtsVisible = await api.getBehajtKtsVisible();
      this.invGroups = (await api.getInvGroups()).map(x => ({...x, selected: false}));
    },

    methods: {
      replaceAt(input, index, replace) {
        return input.substring(0, index) + replace + input.substring(index + replace.length);
      },

      async generate(age: boolean){
        if (this.selectedPrefixes.length == 0 &&!this.overPay){
          eventBus.$emit("error", "Válassza ki a túlfizetéseket és/vagy legalább egy számlaprefixet!");
        }

        if (this.invDate && this.invDateTol > this.invDateIg){
          eventBus.$emit("error", "A számla dátum tól, nagyobb mint az ig!");
        }

        if (this.pTerm && this.pTermTol > this.pTermIg){
          eventBus.$emit("error", "A fizetési határidő tól, nagyobb mint az ig!");
        }

        if (this.shipDate && this.shipDateTol > this.shipDateIg){
          eventBus.$emit("error", "A teljesítés dátuma tól, nagyobb mint az ig!");
        }

        if (this.accDate && this.accDateTol > this.accDateIg){
          eventBus.$emit("error", "A könyvelés dátuma tól, nagyobb mint az ig!");
        }

        if (this.partnerItems.length == 0 && !this.all){
          eventBus.$emit("error", "Legalább egy partnert válasszon ki!");
        }

        if (!this.depot && this.depotItems.filter(x => x.selected).length == 0){
          eventBus.$emit("error", "Legalább egy telephelyet válasszon ki!");
        }

        if (this.penzugyiOrSzamviteli){
          this.invRiport.financial = 1;
        } else {
          this.invRiport.financial = 2;
        }

        this.invRiport.sPmode = '';

        if (this.selectedPaymentModes.length > 0){
          this.invRiport.pmode = 1;
          this.invRiport.sPmode = this.selectedPaymentModes.join(',');

        } else {
          this.invRiport.pmode = 0;
        }

        if (this.vevo) {
          this.invRiport.vevo = 1;
        } else if (this.szallito){
          this.invRiport.vevo = 2;
        } else if (this.tartozik){
          this.invRiport.vevo = 3;
        } else if (this.kovetel){
          this.invRiport.vevo = 4;
        } else {
          this.invRiport.vevo = 5;
        }

        if (this.simple){
          this.invRiport.simple = 1;
        }

        if (this.bony){
          this.invRiport.simple = 2;
        }

        if (this.tabl){
          this.invRiport.simple = 3;
        }

        if (this.age){
          this.invRiport.simple = 4;
        }

        if (this.balance){
          if (this.extra){
            this.invRiport.simple = 51;
          } else {
            this.invRiport.simple = 52;
          }

          if (this.behajtKtsVisible){
            this.invRiport.balanceDate = this.balanceDate;
          }
        }

        if (this.age2){
          this.invRiport.simple = 6;
        }

        if (this.dev){
          this.invRiport.simple = 7;
        }

        this.invRiport.overPaym = this.overPay;
        this.invRiport.allPartner = this.all;
        this.invRiport.include = this.include;
        this.invRiport.bbreak = this.lapdobas;

        if (this.fordulo){
          if (this.isSzamviteli){
          this.invRiport.date = this.dpFordulo;
        } else {
          this.invRiport.date = new Date();
        }
        } else {
          this.invRiport.date = null;
        }

        if (this.invDate){
          this.invRiport.to = this.invDateIg;
          this.invRiport.from = this.invDateTol;
        } else {
          this.invRiport.from = null;
          if (this.fordulo && this.isSzamviteli && this.fordInv){
            this.invRiport.to = this.invRiport.date;
          } else {
            this.invRiport.to = null;
          }
        }

        if (this.pTerm){
          this.invRiport.ptermFrom = this.pTermTol;
          this.invRiport.ptermTo = this.pTermIg;
        } else {
          this.invRiport.ptermFrom = null;
          this.invRiport.ptermTo = null;
        }

        if (this.shipDate){
          this.invRiport.shipDateFrom = this.shipDateTol;
          this.invRiport.shipDateTo = this.shipDateIg;
        } else {
          this.invRiport.shipDateFrom = null;
          this.invRiport.shipDateTo = null;
        }

        if (this.accDate){
          this.invRiport.accDateFrom = this.accDateTol;
          this.invRiport.accDateTo = this.accDateIg;

          this.invRiport.accStatus == this.accStatusId;
          if (this.present){
            this.invRiport.present = '+';
          } else {
            this.invRiport.present = '-';
          }
        } else {
          this.invRiport.accDateFrom = null;
          this.invRiport.accDateTo = null;
          this.invRiport.accStatus = 1;
          this.invRiport.present = '-';
        }

        this.invRiport.code = [];
        this.selectedPrefixes.forEach(x => {
          this.invRiport.code.push(x);
        });

        this.invRiport.partnerRel = "--------------------";

        this.selectedInvRels.forEach(x => {
          this.invRiport.partnerRel = this.replaceAt(this.invRiport.partnerRel, x - 1, '+');
        });

        this.invRiport.partner = [];
        this.partnerItems.forEach(x => {
          this.invRiport.partner.push(x.id);
        });

        this.invRiport.depot = false;
        this.invRiport.sDepot = "'Mind'";

        if (!this.depot || this.depotItems.some(x => !x.selected)){
          // akkor van csak szűrés, ha valami nincs bepipálva
          this.invRiport.depot = true;
          this.invRiport.sDepot = "";
          this.invRiport.nullDepot = this.depot;
          this.invRiport.lDepot = [];

          this.depotItems.filter(x => x.selected).forEach(x => {
            this.invRiport.lDepot.push(x.id);
            if (this.invRiport.sDepot != ""){
              this.invRiport.sDepot += String.fromCharCode(10);
            }
            
            this.invRiport.sDepot += "'"+ x.name  +"'";
          });

          if (this.invRiport.nullDepot){
            if (this.invRiport.sDepot != ""){
              this.invRiport.sDepot += String.fromCharCode(10);
            }

            this.invRiport.sDepot += "'Minden olyan számla, aminek nem lehet a telephelyét meghatározni'";
          }
        }

        this.invRiport.open = this.nyitott;
        if (this.eloleg) {
          this.invRiport.dpaym = 1;
        } else if (this.veg) {
          this.invRiport.dpaym = 2;
        } else {
          this.invRiport.dpaym = 3;
        }

        if (this.insDate) {
          this.invRiport.insDate = '+';
        } else {
          this.invRiport.insDate = '-';
        }

        if (this.bank) {
          this.invRiport.bank = '+';
        } else {
          this.invRiport.bank = '-';
        }

        if (this.cash) {
          this.invRiport.cash = '+';
        } else {
          this.invRiport.cash = '-';
        }

        if (this.other) {
          this.invRiport.other = '+';
        } else {
          this.invRiport.other = '-';
        }

        this.invRiport.delivered = this.deliv;
        this.invRiport.sechandIn = this.sec;
        this.invRiport.text = this.reportTitle;
        this.invRiport.nulla = this.nulla;
        this.invRiport.ok = true;

        if (!age && (this.age || this.age2)){
          this.isPeriod = true;
          return;
        }
        this.isPeriod = false;

        /*const request = {
          To:  this.invDate ? this.invDateIg : null,
          From: this.invDate ? this.invDateTol : null,
          PtermFrom: this.pTerm ? this.pTermTol : null,
          PtermTo: this.pTerm ? this.pTermIg : null,
          ShipDateFrom: this.shipDate ? this.shipDateTol : null,
          ShipDateTo: this.shipDate ? this.shipDateIg : null,
          AccDateFrom: this.accDate ? this.accDateTol : null,
          AccDateTo: this.accDate ? this.accDateIg : null,
        };*/

        const period: number[] = [];
        if (this.invRiport.simple == 4 || this.invRiport.simple == 6){
          period.push(this.period1);
          period.push(this.period2);
          period.push(this.period3);
          period.push(this.period4);
          period.push(this.period5);
          period.push(this.period6);
          period.push(this.period7);
          period.push(this.period8);
          period.push(this.period9);
          period.push(this.period10);

          if (this.isPeriod6){
            period.push(this.period11);
            period.push(this.period12);
          }

          if (this.isPeriod7) {
            period.push(this.period13);
            period.push(this.period14);
          }

        }

        const request = {
          InvRiport: this.invRiport,
          period: period,
          defrayer: this.defrayer,
          outsider: this.outsider,
          geniTypes: this.selectedInvoiceTypes,
          periodFrom: this.periodFrom,
          isExcel: this.isExcel,
        };

        this.isPrinting = true;
        try
        {
          if (this.isExcel){
            const report = await api.getCrystalReport(request);

            const linkSource = `data:application/vnd.openxmlformats;base64,${report.file}`;
              const downloadLink = document.createElement("a");
              const fileName =
                "PARAMFOLYRIP_" +
                dayjs(new Date()).format("YYYYMMDD") +
                ".xlsx";
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();

          } else {
            const pdfWindow = window.open();

            const report = await api.getCrystalReport(request);

            if (pdfWindow) {
                pdfWindow.document.write(
                  "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(report.file) +
                    "'></iframe>"
                );
            }
          }
        }
        finally
        {
          this.isPrinting = false;
        }
        
      },

      depotSelection(item: Depot){
        item.selected = !item.selected;
      },

      partnerSelected(partner){
        if (partner.id > 1){
          if (this.partnerItems.every(x => x.id != partner.id)){
            this.partnerItems.push(partner);
          }
        }
      },

      deletePartner(partner) {
        this.partnerItems = this.partnerItems.filter(x => x.id != partner.id);
      },

      invGroupSelection(item){
        item.selected = !item.selected;
      },

      periodChanged(){
        /*if (this.period1 > this.period2){
          this.period2 = this.period1 + 1;
        }*/

        this.period3 = Number(this.period2) + 1;

        if (Number(this.period3) > Number(this.period4)){
          this.period4 = Number(this.period3) + 1;
        }

        this.period5 = Number(this.period4) + 1;

        if (Number(this.period5) > Number(this.period6)){
          this.period6 = Number(this.period5) + 1;
        }

        this.period7 = Number(this.period6) + 1;

        if (Number(this.period7) > Number(this.period8)){
          this.period8 = Number(this.period7) + 1;
        }

        this.period9 = Number(this.period8) + 1;

        if (Number(this.period9) > Number(this.period10)){
          this.period10 = Number(this.period9) + 1;
        }

        this.period11 = Number(this.period10) + 1;

        if (Number(this.period11) > Number(this.period12)){
          this.period12 = Number(this.period11) + 1;
        }

        this.period13 = Number(this.period12) + 1;

        if (Number(this.period13) > Number(this.period14)){
          this.period14 = Number(this.period13) + 1;
        }
      }
    },

    watch: {
      accStatus() {
        if (this.accStatus){
          this.accStatusId = this.accStatus.id;
        } else {
          this.accStatusId = null;
        }
      },

      openType(){
        if (this.nyitott){
          this.reportTitle = 'Nyitott számlák adott fordulónapon partnerenként, prefixenként';
        } else {
          this.reportTitle = 'Minden számla adott fordulónapon partnerenként, prefixenként';
        }
      }
    },

    computed: {
      invGroupColumns: () => [
        { cell: "selection", width: "70px" },
        { field: "name", title: "Megnevezés", width: '200px' },
        { field: "dimCodeIn", title: "Dim. kód bejövő" },
        { field: "dimCodeOut", title: "Dim. kód kimenő" },
        { field: "liveName", title: "Élő" },
      ],

      depotColumns: () => [
        { cell: "selection", width: "70px" },
        { field: "name", title: "Megnevezés" },
      ],

      partnerColumns: () => [
        { cell: "delete", width: "70px" },
        { field: "name", title: "Megnevezés" },
      ],

      penzugyOrSzamviteliVisibe(){
        return !this.accDate;
      },

      isSzamviteli(){
        return this.fordulo && !this.accDate && !this.penzugyiOrSzamviteli && this.penzugyiOrSzamviteli != null;
      },

      insDateVisible(){
        return this.isSzamviteli;
      },

      fordPaym(){
        return this.fordInvPaym != null && !this.fordInvPaym;
      },

      fordInv(){
        return this.fordInvPaym != null && this.fordInvPaym;
      },

      insDate(){
        return this.insPayDate != null && this.insPayDate;
      },

      pDate() {
        return this.insPayDate != null && !this.insPayDate;
      },

      simple(){
        return this.type == 1;
      },

      tabl(){
        return this.type == 2;
      },

      age(){
        return this.type == 3;
      },

      bony(){
        return this.type == 4;
      },

      balance(){
        return this.type == 5;
      },

      age2(){
        return this.type == 6;
      },

      dev(){
        return this.type == 7;
      },

      vevo() {
        return this.invType == 1;
      },

      szallito(){
        return this.invType == 2;
      },

      tartozik(){
        return this.invType == 3;
      },

      kovetel(){
        return this.invType == 4;
      },

      mind(){
        return this.invType == 5;
      },

      extra(){
        return this.withExtra == 1;
      },

      wExtra(){
        return this.withExtra == 2;
      },

      eloleg(){
        return this.elolegType == 1;
      },

      veg(){
        return this.elolegType == 2;
      },

      eloVeg() {
        return this.elolegType == 3;
      },

      nyitott(){
        return this.openType == 1;
      },

      allSzla() {
        return this.openType == 2;
      },

      deliv(){
        return this.delivType;
      },

      delivNo() {
        return !this.delivType;
      },

      sec(){
        return this.secType;
      },

      secNo() {
        return !this.secType;
      },
    },
})
