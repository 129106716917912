import DocumentStatement from "./DucomentStatement";
import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import { IdNameDto } from "@/modules/letterOfCredit/letterOfCredit";

export default class DocumentAttachApi {
  static getSubmitDocumentRequirements(
    submitRequirementId: number
  ): Promise<IdNameDto[]> {
    return http
      .get<ApiResponse<IdNameDto[]>>(
        "documents/GetSubmitEventDocTypesRequirements",
        {
          params: {
            id: submitRequirementId,
          },
        }
      )
      .then(handleApiResponse);
  }

  static getDocumentStatements(
    statementId: number,
    table: string,
    column: string
  ): Promise<DocumentStatement[]> {
    return http
      .post<ApiResponse<DocumentStatement[]>>(`documents/all`, {
        statementId,
        table,
        column,
      })
      .then(handleApiResponse);
  }

  static getDocumentTypes(table: string, column: string): Promise<IdNameDto[]> {
    return http
      .post<ApiResponse<IdNameDto[]>>(`documents/alltypes`, { table, column })
      .then(handleApiResponse);
  }

  static generateDocumentDownLoadLink(documentId: number) {
    return http.defaults.baseURL + `document/download/${documentId}`;
  }

  static deletePreviousDocument(documentId: number): Promise<boolean> {
    return http.delete(`documents/delete/${documentId}`).then(checkResponse);
  }

  static uploadStatement(
    table: string,
    column: string,
    itemId: number,
    doctype: number,
    description: string,
    file: File
  ): Promise<Document> {
    const data = new FormData();
    data.append("file", file);
    data.append("typeID", doctype.toString());
    data.append("relatedItemName", table + "." + column);
    data.append("relatedItemID", itemId.toString());
    data.append("description", description);

    return http
      .post<ApiResponse<Document>>(`documents`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(handleApiResponse);
  }
}
