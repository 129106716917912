import {  SelectablePeriod } from "./PeriodModell";

export interface Year {
  id: number;
  name: string;
  year: number;
  accYear: Date;
  currency: string;
  startDate: Date;
  endDate: Date;
  creator: string;
  periods: SelectablePeriod;
  status: string;
  closeDate: Date;
}

export const DefaultYearProps: Year = {
  id: 1,
  name: "",
  year: 1,
  accYear: new Date("1901-01-01"),
  currency: "HUF",
  startDate: new Date("1901-01-01"),
  endDate: new Date("1901-01-01"),
  creator: "",
  periods: { name: "", value: 0 },
  status: "Előkészítés alatt",
  closeDate: new Date("1901-01-01"),
};
