














































































































































































































































import User from "@/modules/partner/models/User";
import setAttribute from "@/utils/dom";
import Vue from "vue";
import ApprovalMatrixHeadResponse, {
  approvalMatrixHeadResponseDefaultProps,
} from "../models/ApprovalMatrixHeadResponse";
import ApprovalMatrixUser, { approvalMatrixUserDefaultProps } from "../models/ApprovalMatrixUser";
import store, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";

interface Data {
  store: InvoiceFlowStore;
}

interface Props {
  head: ApprovalMatrixHeadResponse;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    head: {
      default: { ...approvalMatrixHeadResponseDefaultProps },
    },
  },

  data: () => ({
    store,
  }),

  mounted() {
    setAttribute(".k-detail-cell", "colspan", "3");
  },

  methods: {
    userChanged(user: ApprovalMatrixUser, selectedUser: User) {
      user.userId = selectedUser.id;
      user.name = selectedUser.name;
    },

    // számla teljesítés ell.
    addFFControllerUser() {
      const newUser = { ...approvalMatrixUserDefaultProps, head: this.head.id };
      this.head.fullfillControllerUsers = [newUser, ...this.head.fullfillControllerUsers];
    },

    deleteFFControllerUser(item: ApprovalMatrixUser) {
      const index = this.head.fullfillControllerUsers.indexOf(item);
      if (index > -1) {
        this.head.fullfillControllerUsers.splice(index, 1);
      }
    },

    // számla teljesítés ell.
    addFFApproverUser() {
      const newUser = { ...approvalMatrixUserDefaultProps, head: this.head.id };
      this.head.fullfillApproverUsers = [newUser, ...this.head.fullfillApproverUsers];
    },

    deleteFFApproverUser(item: ApprovalMatrixUser) {
      const index = this.head.fullfillApproverUsers.indexOf(item);
      if (index > -1) {
        this.head.fullfillApproverUsers.splice(index, 1);
      }
    },

    // jóváhagyó
    addApproverUser() {
      const newUser = { ...approvalMatrixUserDefaultProps, head: this.head.id };
      this.head.approverUsers = [newUser, ...this.head.approverUsers];
    },

    deleteApproverUser(item: ApprovalMatrixUser) {
      const index = this.head.approverUsers.indexOf(item);
      if (index > -1) {
        this.head.approverUsers.splice(index, 1);
      }
    },

    // ellenőrző
    addControllerUser() {
      const newUser = { ...approvalMatrixUserDefaultProps, head: this.head.id };
      this.head.controllerUsers = [newUser, ...this.head.controllerUsers];
    },

    deleteControllerUser(item: ApprovalMatrixUser) {
      const index = this.head.controllerUsers.indexOf(item);
      if (index > -1) {
        this.head.controllerUsers.splice(index, 1);
      }
    },
  },

  computed: {
    fullfilmentControllers() {
      return this.store.flowUsers.fullfilmentChecking;
    },

    controllers() {
      return this.store.flowUsers.invoiceChecking;
    },

    fullfilmentApprovers() {
      return this.store.flowUsers.fullfilmentApproval;
    },

    approvers() {
      return this.store.flowUsers.invoiceApproval;
    },
  },
});
