export default interface BankRemitStatus {
    id: number;
    name: string;
}
  
export const bankRemitStatusDefaultProps: BankRemitStatus = {
    id: 1,
    name: "",
}

export const bankRemitStatusDefaultFilterProps: BankRemitStatus = {
    id: 0,
    name: "Összes",
  };