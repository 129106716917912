import CurrencyDigit from "@/modules/currencyDigits/models/CurrencyDigit";
import http from "@/services/http";
import permissions from "@/services/permissions";

export interface AppConfiguration {
  isLoaded: boolean;
  operel: boolean;
  roundingPrecisions: Record<string, number>;
  roundingUnitPrecisions: Record<string, number>;
  currencyDigits: CurrencyDigit[];
  isWithoutCar: boolean;

  sysParams: {
    geniMaxRound: number;
    geniFulFillTolerance: number;
    geniSendEmail: boolean;
    geniTaskReminderDays: number;
    geniIntrastat: boolean;
    isGeniHUFItemPrecise: boolean;
    isGeniExchangeRateEditEnabled: boolean;
    isBankImportNotificationEnabled: boolean;
    generalRectItem: boolean;
    /** 
     * Banki k�lts�gviseli haszn�lva van,
     * Banki csomagn�l a Geni sz�ml�b�l �tm�sol�dika BankDefrType a BankRemitItem-be.
     * Ennek az oszlop�t kapcsolgatja ki be.
    */
    bankDefrayerEnabled: boolean;
    isDPaymInvoiceItem: boolean;
    defaultCurrency: string;
    isKanriEnabled: boolean;
    shipDateChangeNotificationEnabled: boolean;
    automaticSupplierRelationEnabled: boolean;
    invoiceDocumentMandatory: boolean;
    paymentScheduleVisible: boolean;
    specialInvoiceInformationVisible: boolean;
    skontoVisible: boolean;
    subcontractorVisible: boolean;
    controllersVisible: boolean;
    combinedFinancialRecording: boolean;
    dpaymInvoiceGroupsMandatory: boolean;
    getErrorGeniCount: boolean;
  };
  writeOffDebtMaxSmallAmount: number;
  invoiceFlow: {
    enabled: boolean;
  };
  isInvAccepted: {
    enabled: boolean;
  };
  exchangeRate: {
    enabled: boolean;
  };
  incomingInvoice: {
    otherItemStockRelationsEnabled: boolean;
  };
  invoicePermit: {
    enabled: boolean;
  };
  isDepotVisible: {
    enabled: boolean;
  };
  onlyRectItem: {
    enabled: boolean;
  };
  isGeniSeparCont: {
    enabled: boolean;
  };
  geniItemNoteVisible: {
    enabled: boolean;
  };
  invGroupsSaved: {
    enabled: boolean;
  };
  isStockStToRectInv: {
    enabled: boolean;
  };
  sysPartnerId: number;
  servVoucAmount: number;
  partner: {
    isExtCodeEnabled: boolean;
    isExtendedPartnerInfoEnabled: boolean;
    isPartnerEmailRequired: boolean;
    isPartnerPhoneRequired: boolean;
  };
  isGeniOutInv: {
    enabled: boolean;
  };

  taxChangeQuestionVisible: {
    enabled: boolean;
  };

  isInvPermit: boolean;
  currUnitPriceDecimals(currency: string, overrideWithZero: boolean): number;
  currPriceDecimals(currency: string, overrideWithZero: boolean): number;
  getCurrDigits(currency: string): CurrencyDigit | undefined;
  isTestDb: {
    enabled: boolean;
  };
}

const configuration: AppConfiguration = {
  isLoaded: false,
  operel: process.env.VUE_APP_MODE === "library",
  sysParams: {
    geniSendEmail: false,
    geniFulFillTolerance: 0,
    geniMaxRound: 0,
    geniTaskReminderDays: 0,
    geniIntrastat: false,
    isGeniHUFItemPrecise: false,
    isGeniExchangeRateEditEnabled: false,
    isBankImportNotificationEnabled: false,
    generalRectItem: false,
    bankDefrayerEnabled: false,
    isDPaymInvoiceItem: false,
    isKanriEnabled: false,
    defaultCurrency: 'HUF',
    shipDateChangeNotificationEnabled: false,
    automaticSupplierRelationEnabled: false,

    invoiceDocumentMandatory: false,
    paymentScheduleVisible: false,
    specialInvoiceInformationVisible: false,
    skontoVisible: false,
    subcontractorVisible: false,
    controllersVisible: false,
    combinedFinancialRecording: false,
    dpaymInvoiceGroupsMandatory: false,
    getErrorGeniCount: false,

  },
  writeOffDebtMaxSmallAmount: 0,
  invoiceFlow: {
    enabled: false,
  },
  isInvAccepted: {
    enabled: false,
  },
  exchangeRate: {
    enabled: false,
  },
  incomingInvoice: {
    otherItemStockRelationsEnabled: false,
  },
  invoicePermit: {
    enabled: false,
  },
  partner: {
    isExtCodeEnabled: false,
    isExtendedPartnerInfoEnabled: false,
    isPartnerPhoneRequired: false,
    isPartnerEmailRequired: false,
  },
  isDepotVisible: {
    enabled: false,
  },
  onlyRectItem: {
    enabled: false,
  },
  isGeniSeparCont: {
    enabled: false,
  },
  roundingPrecisions: {
    HUF: 0,
  },
  roundingUnitPrecisions: {
    HUF: 0,
  },
  currencyDigits: [],
  isWithoutCar: true,
  isGeniOutInv: {
    enabled: false,
  },
  geniItemNoteVisible: {
    enabled: false,
  },
  invGroupsSaved: {
    enabled: true,
  },
  isStockStToRectInv: {
    enabled: false,
  },
  taxChangeQuestionVisible: {
    enabled: true,
  },
  sysPartnerId: 1,
  servVoucAmount: 0,
  isInvPermit: false,

  getCurrDigits(currency: string): CurrencyDigit | undefined {
    if (!configuration.currencyDigits) return;
    const res =
      configuration.currencyDigits.filter((x) => x.currency === currency)
        .length > 0
        ? configuration.currencyDigits.filter((x) => x.currency === currency)[0]
        : undefined;
    if (!res) {
      return;
    }

    res.geniHeadDigits =
      res.geniHeadDigits < 1 ? res.headDigits : res.geniHeadDigits;

    res.geniItemDigits =
      res.geniItemDigits < 1 ? res.digits : res.geniItemDigits;

    res.geniUnitDigits =
      res.geniUnitDigits < 1 ? res.unitDigits : res.geniUnitDigits;

    res.geniDigits = res.geniDigits < 1 ? res.digits : res.geniDigits;
    return res;
  },

  currUnitPriceDecimals(currency: string, overrideWithZero = false) {
    if (overrideWithZero && currency == "HUF") {
      return 0;
    }

    if (!Object.keys(configuration.roundingUnitPrecisions).includes(currency)) {
      return 2;
    }
    return configuration.roundingUnitPrecisions[currency];
  },

  currPriceDecimals(currency: string, overrideWithZero = false) {
    if (overrideWithZero && currency == "HUF") {
      return 0;
    }

    if (!Object.keys(configuration.roundingPrecisions).includes(currency)) {
      return 2;
    }
    return configuration.roundingPrecisions[currency];
  },
  isTestDb: {
    enabled: false
  }
};

export async function loadConfiguration() {
  await permissions.get();
  await http.get("configuration").then((res) => {
    const config = res.data.data;
    console.log(res.data);


    // Invoice related
    configuration.invoiceFlow.enabled = config.isInvoiceFlowEnabled;
    configuration.exchangeRate.enabled = config.isExchangeRateEnabled;
    configuration.invoicePermit.enabled = config.isInvoicePermitEnabled;
    configuration.incomingInvoice = config.incomingInvoice;
    configuration.isInvAccepted.enabled = config.isInvAccepted;
    configuration.isGeniOutInv.enabled = config.isGeniOutInvEnabled;
    configuration.taxChangeQuestionVisible.enabled = config.taxChangeQuestionVisible;
    // Syster Parameters
    configuration.sysParams.geniSendEmail = config.geniSendEmail;
    configuration.sysParams.geniFulFillTolerance = config.geniFulFillTolerance;
    configuration.sysParams.geniMaxRound = config.geniMaxRound;
    configuration.sysParams.geniTaskReminderDays = config.geniTaskReminderDays;
    configuration.sysParams.geniIntrastat = config.isIntrastatEnabled;
    configuration.sysParams.isGeniHUFItemPrecise = config.isGeniHUFItemPrecise;
    configuration.sysParams.isGeniExchangeRateEditEnabled =
      config.isGeniExchangeRateEditEnabled;
    configuration.sysParams.isBankImportNotificationEnabled =
      config.isBankImportNotificationEnabled;
    configuration.sysParams.generalRectItem = config.generalRectItem;
    configuration.sysParams.isDPaymInvoiceItem = config.isDPaymInvoiceItem;
    configuration.sysParams.defaultCurrency = config.defaultCurrency;
    configuration.sysParams.isKanriEnabled = config.isKanriEnabled;
    configuration.sysParams.shipDateChangeNotificationEnabled = config.shipDateChangeNotificationEnabled;
    configuration.sysParams.automaticSupplierRelationEnabled = config.automaticSupplierRelationEnabled;

    configuration.sysParams.invoiceDocumentMandatory = config.invoiceDocumentMandatory;
    configuration.sysParams.paymentScheduleVisible = config.paymentScheduleVisible;
    configuration.sysParams.specialInvoiceInformationVisible = config.specialInvoiceInformationVisible;
    configuration.sysParams.skontoVisible = config.skontoVisible;
    configuration.sysParams.subcontractorVisible = config.subcontractorVisible;
    configuration.sysParams.controllersVisible = config.controllersVisible;
    configuration.sysParams.combinedFinancialRecording = config.combinedFinancialRecording;
    configuration.sysParams.dpaymInvoiceGroupsMandatory = config.dpaymInvoiceGroupsMandatory;
    configuration.sysParams.getErrorGeniCount = config.getErrorGeniCount;

    // General
    configuration.isLoaded = true;
    // Partner
    configuration.partner.isExtCodeEnabled = config.isExtCodeEnabled;
    configuration.roundingPrecisions = config.roundingPrecisions;
    configuration.roundingUnitPrecisions = config.roundingUnitPrecisions;
    configuration.operel = config.operel;
    configuration.partner.isExtendedPartnerInfoEnabled = config.isExtendedPartnerInfo;
    configuration.partner.isPartnerPhoneRequired = config.isPartnerPhoneRequired;
    configuration.partner.isPartnerEmailRequired = config.isPartnerEmailRequired;

    //Telephely
    configuration.isDepotVisible.enabled = config.isDepotVisible;
    configuration.onlyRectItem.enabled = config.onlyRectItem;

    configuration.isGeniSeparCont.enabled = config.isGeniSeparCont;

    configuration.currencyDigits = config.currencyDigits;
    configuration.isWithoutCar = config.isWithoutCar;

    configuration.geniItemNoteVisible.enabled = config.geniItemNoteVisible;
    configuration.invGroupsSaved.enabled = config.invGroupsSaved;
    configuration.isStockStToRectInv.enabled = config.isStockStToRectInv;
    configuration.sysParams.bankDefrayerEnabled = config.bankDefrayerEnabled;
    configuration.isTestDb.enabled = config.isTestDb;
    configuration.writeOffDebtMaxSmallAmount = config.writeOffDebtMaxSmallAmount;
    configuration.sysPartnerId = config.sysPartnerId;
    configuration.servVoucAmount = config.servVoucAmount;

    configuration.isInvPermit = config.isInvPermit;
  });
}

export default configuration;
