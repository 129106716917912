import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import { TechnicalInvoiceVIRO, TechnicalInvoiceVIRI } from "../models/TechnicalInvoiceModel";
import TechnicalInvoicePmode from "../models/TechnicalInvoicePmodeModel";
import TechnicalInvoiceStatus from "../models/TechnicalInvoiceStatusModel";
import TechnicalInvoiceAccGroup from "../models/TechnicalInvoiceAccGroupModel";
import TechnicalInvoiceAccStatus from "../models/TechnicalInvoiceAccStatusModel";
import TechnicalInvoicePartnerRel from "../models/TechnicalInvoicePartnerRelModel";
import TechnicalInvoiceDivitem from "../models/TechnicalInvoiceDivitemModel";
import TechnicalInvoiceCurrency from "../models/TechnicalInvoiceCurrency";
import { TechnicalInvoiceVIROType, TechnicalInvoiceVIRIType } from "../models/TechnicalInvoiceTypeModel";
import TechnicalInvoiceCreatorUser from "../models/TechnicalInvoiceCreatorUserModel";
import Partner from "@/modules/partner/models/Partner";
import Rate from "../models/Rate";

export default class TechnicalInvoiceApi {
  static async update(tInvoice: TechnicalInvoiceVIRO | TechnicalInvoiceVIRI, codeFilter: string) {
    return await http
      .post<ApiResponse<number>>(`invoice-browser/technical-invoice/${codeFilter}/update`, { invoice: tInvoice })
      .then(handleApiResponse);
  }

  static async getViroInvoice(invoiceId: number): Promise<TechnicalInvoiceVIRO> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIRO>>(`invoice-browser/technical-invoice/viro/${invoiceId}`)
      .then(handleApiResponse);
  }

  static async getViriInvoice(invoiceId: number): Promise<TechnicalInvoiceVIRI> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIRI>>(`invoice-browser/technical-invoice/viri/${invoiceId}`)
      .then(handleApiResponse);
  }

  static async getPartner(partnerId: number): Promise<Partner> {
    return await http.get<ApiResponse<Partner>>(`partners/${partnerId}`).then(handleApiResponse);
  }

  static async getPmodes(): Promise<TechnicalInvoicePmode[]> {
    return await http.get<ApiResponse<TechnicalInvoicePmode[]>>(`payment-mode`).then(handleApiResponse);
  }

  static async getStatuses(): Promise<TechnicalInvoiceStatus[]> {
    return await http.get<ApiResponse<TechnicalInvoiceStatus[]>>(`invoice-status`).then(handleApiResponse);
  }

  static async getAccGroups(): Promise<TechnicalInvoiceAccGroup[]> {
    return await http.get<ApiResponse<TechnicalInvoiceAccGroup[]>>(`invoice-group`).then(handleApiResponse);
  }

  static async getAccStatuses(): Promise<TechnicalInvoiceAccStatus[]> {
    return await http.get<ApiResponse<TechnicalInvoiceAccStatus[]>>(`account-status`).then(handleApiResponse);
  }

  static async getPartnerRels(code: string): Promise<TechnicalInvoicePartnerRel[]> {
    return await http
      .get<ApiResponse<TechnicalInvoicePartnerRel[]>>(`partner-relation/type/${code}`)
      .then(handleApiResponse);
  }

  static async getDivitems(): Promise<TechnicalInvoiceDivitem[]> {
    return await http.get<ApiResponse<TechnicalInvoiceDivitem[]>>(`division-item`).then(handleApiResponse);
  }

  static async getCurrencies(): Promise<TechnicalInvoiceCurrency[]> {
    return await http.get<ApiResponse<TechnicalInvoiceCurrency[]>>(`currency`).then(handleApiResponse);
  }

  static async getVIROInvoiceTypes(): Promise<TechnicalInvoiceVIROType[]> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIROType[]>>(`techincal-invoice-type/viro`)
      .then(handleApiResponse);
  }

  static async getVIRIInvoiceTypes(): Promise<TechnicalInvoiceVIRIType[]> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIRIType[]>>(`techincal-invoice-type/viri`)
      .then(handleApiResponse);
  }

  static async getCreatorUser(userId: number): Promise<TechnicalInvoiceCreatorUser> {
    return await http.get<ApiResponse<TechnicalInvoiceCreatorUser>>(`users/${userId}`).then(handleApiResponse);
  }

  static async getOpenerVIROInvoiceTypes(): Promise<TechnicalInvoiceVIROType[]> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIROType[]>>("techincal-invoice-type/opener/viro")
      .then(handleApiResponse);
  }

  static async getOpenerVIRIInvoiceTypes(): Promise<TechnicalInvoiceVIRIType[]> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIRIType[]>>("techincal-invoice-type/opener/viro")
      .then(handleApiResponse);
  }

  static async getRate(currency: string, rateDate: Date): Promise<Rate> {
    return await http
      .get<ApiResponse<Rate>>("currency/rate-date", { params: { c: currency, d: rateDate} })
      .then(handleApiResponse);
  }
}
