export default interface InvoiceGroupDisplay {
    id: number;
    name: string;
    nameAndSite: string;
    nameAndCode: string;
    dimCodeIn: string;
    dimCodeOut: string;
    controlPerson: string;
    user: string;
    defDivItem: string;
    contrType: string;
    deleted: boolean;
}

export const invoiceGroupDisplayDefaultProps: InvoiceGroupDisplay = {
    id: 0,
    name: '',
    nameAndSite: '',
    nameAndCode: '',
    dimCodeIn: '',
    dimCodeOut: '',
    controlPerson: '',
    user: '',
    defDivItem: '',
    contrType: '',
    deleted: false
}
