export default interface CashDocItem {
  id: number;
  document: number;
  cashProcType: number;
  partner: number;
  docNo: string | null;
  invPrefix: string;
  invNo: number;
  amount: number;
  note: string | null;
  cashSubType: number;
  invCurrency: string;
  currAmount: number;
  exchangeRate: number;
  avgRate: number;
  rateDate: string;
  isFinalRate: boolean;
  extNo: string | null;
  extDimcode1: string | null;
  extDimcode2: string | null;
  extDimcode3: string | null;
  postcalc: number;
  dimCode1: string | null;
  readOnly: boolean;
  deleted: boolean;
}

export const cashDocItemDefaultProps: CashDocItem = {
  id: 0,
  document: 0,
  cashProcType: 0,
  partner: 0,
  docNo: null,
  invPrefix: '',
  invNo: 1,
  amount: 0,
  note: "",
  cashSubType: 1,
  invCurrency: "",
  currAmount: 0,
  exchangeRate: 1,
  avgRate: 1,
  rateDate: "2000-01-01T00:00",
  isFinalRate: true,
  extNo: null,
  extDimcode1: null,
  extDimcode2: null,
  extDimcode3: null,
  postcalc: 1,
  dimCode1: null,
  readOnly: false,
  deleted: false,
};
