import City, { cityDefaultProps } from "../models/City";
import CityApi from "./CityApi";

export class CityStore {
  cities: City[] = [];
  city: City = { ...cityDefaultProps };
  filteredCities: City[] = [];

  corCity: City = { ...cityDefaultProps };
  filteredCorCities: City[] = [];

  async loadCities() {
    this.cities = await CityApi.getAll();
  }

  async loadCityByPostCode(cityPostCode: number) {
    this.city = await CityApi.getByPostCode(cityPostCode);
  }

  async loadCorCityByPostCode(corCityPostCode: number) {
    this.corCity = await CityApi.getByPostCode(corCityPostCode);
  }

  async filterCitiesByName(cityName: string) {
    this.filteredCities = await CityApi.getCitiesByName(cityName);
  }
}

const store = new CityStore();
export default store;
