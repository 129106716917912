import { Tax } from "@/modules/invoiceItems/models/index";

export default interface TaxAndVatItem extends Tax {
  vatItemID: number;
  vatItemName: string;
  vatDisplayName?: string;
}

export const TaxAndVatItemItemDefaultProps: TaxAndVatItem = {
  vatItemID: 1,
  vatItemName: "-",
  id: 1,
  name: "",
  degree: 0,
  vatDisplayName: "",
};

export enum Taxes {
  // TV.H.K. - Áfa tárgyi hatályán kívül
  TVHK = 9,
}
