







































































import Vue from "vue";
import store, { GeneralLedgerStore } from "../services/store";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import ButtonCheckBox from "@/components/ButtonCheckBox.vue";
import TransactionsDimensions from "../components/TransactionsDimensions.vue";
import { dateFormatHU } from "@/filters/date-format";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Data {
  store: GeneralLedgerStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    ApiSearchPage,
    ButtonCheckBox,
    TransactionsDimensions,
    MoneyDisplay,
  },

  data: () => ({ store }),

  methods: {
    setDate(date: Date) {
      if (
        new Date(dateFormatHU(date.toString())) >
        new Date(dateFormatHU(new Date("1990-01-01").toString()))
      ) {
        return dateFormatHU(date.toString());
      } else return "-";
    },
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      {
        field: "isHasDimensions",
        cell: "dimensionsChk",
        filterable: false,
        sortable: false,
      },
      { field: "trans", title: "Tranzakció azonosító" },
      {
        field: "accDate",
        title: "Gazd. es. dátum",
        cell: "accDate",
      },
      { field: "prefix", title: "Prefix" },
      { field: "invoiceType", title: "Számla típusa" },
      { field: "bookType", title: "Napló" },
      { field: "productionId", title: "Gyártási lejelentés", cell: "productionId" },
      { field: "taskCard", title: "Feladatkártya", cell: "taskCard" },
      {
        field: "bookingDate",
        title: "Könyvekben történő rögzítés pontos ideje",
        cell: "bookingDate",
      },
      { field: "accSign", title: "Tartozik/Követel", cell: "accSignTemp" },
      { field: "total", title: "Összeg", cell: "total" },
      { field: "currTotal", title: "Összeg (devizában)", cell: "currTotal" },
      { field: "balance", title: "Egyenleg", cell: "balance" },
      { field: "accNo", title: "Fők. szám" },
      { field: "againstAccno", title: "Ellen fők. szám", filterable: false, sortable: false },
      { field: "accNoName", title: "Fők. szám neve" },
      { field: "invNo", title: "Számlaszám" },
      { field: "partner", title: "Partner neve" },
      { field: "note", title: "Megjegyzés" },
      { field: "remark", title: "Egyéb azon." },
      { field: "bookingUser", title: "Ellenőr", cell: "bookingUser" },
      { field: "user", title: "Létreh. felh." },
      { field: "currency", title: "Pénznem" },
      { field: "exchangeRate", title: "Árfolyamszorzó", cell: "exchangeRate" },
    ],
  },
});
