import StornoSummaryInvoice from "../models/StornoSummaryModel";
import StornoSummaryApi from "./StornoSummaryApi";

export class StornoSummaryStore {
  public StornoSummary(invoices: StornoSummaryInvoice[]): Promise<any> {
    return StornoSummaryApi.StornoSummary(invoices);
  }
}

export const store = new StornoSummaryStore();
