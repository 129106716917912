














import Vue from "vue";
import store from "../services/PartnerStore";
import PartBankCreateComponent from "../components/PartBankCreateComponent.vue";

export default Vue.extend({
  components: {
    PartBankCreateComponent,
  },

  data: () => ({
    store,
  }),

  methods: {
    back() {
      const partnerId = this.store.partner.id > 0 ? this.store.partner.id : Number(this.$route.params.partnerId);
      this.$router.push(`/partners/${partnerId}/${this.$route.params.source}`);
    },
  },
});
