import FixAssetDocumentTemplate from "./FixAssetDocumentTemplate";

export default interface FixAssetGroup {
    id: number;
    name: string;
    mainGroup: number;
    documentTypes: FixAssetDocumentTemplate[];
}

export const fixAssetGroupDefaultProps: FixAssetGroup = {
    id: 0,
    name: "",
    mainGroup: 0,
    documentTypes: [],
}