var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h5',{staticClass:"section-title"},[_vm._v("Utókalkuláció")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.generateCarPostCalc}},[_vm._v("Új utókalkuláció")])],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.store.carPostCalcs,"columns":_vm.columns},scopedSlots:_vm._u([{key:"statusNameTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('label',[_vm._v(_vm._s(props.dataItem.statusName))]),(props.dataItem.status == 4)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"red--text ml-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fa-exclamation-circle")])]}}],null,true)},[_c('span',[_vm._v("Ez az utókalkulácó lezárt!")])]):_vm._e()],1)]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticStyle:{"text-align":"right !important"}},[_c('check-box',{attrs:{"selected":props.dataItem.isSelected},on:{"change":function($event){return _vm.select(props.dataItem)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }