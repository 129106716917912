






































































































// Models
import Vue from "vue";
import store, { CashStatementStore } from "../services/store";
import BankStatus, { bankStatusDefaultProps } from "../models/BankStatus";
import AccountStatus, {
  accountStatusDefaultProps,
} from "@/modules/notice/models/AccountStatus";

// Components
import HeaderItem from "@/components/HeaderItem.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import IconButton from "@/components/IconButton.vue";

interface Data {
  store: CashStatementStore;
  selectedStatus: BankStatus | undefined;
  selectedAccStatus: AccountStatus | undefined;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    HeaderItem,
    MoneyDisplay,
    IconButton
  },

  data: () => ({
    store,
    selectedStatus: { ...bankStatusDefaultProps },
    selectedAccStatus: { ...accountStatusDefaultProps },
  }),



  methods: {},

  computed: {
    tooltip(){
      return `Teljes képernyős mód ${ store.isFullPage ? "ki" : "be" }kapcsolása`
    },

    openInHUF() {
      return (
        this.store.cashStatement.openBal * this.store.cashStatement.openRate
      );
    },

    closeInHUF() {
      return (
        this.store.cashStatement.closeBal * this.store.cashStatement.closeRate
      );
    },

    statusName() {
      return (
        this.store.bankStatuses.find((x) => {
          return x.id === this.store.cashStatement.status;
        })?.name ?? "-"
      );
    },

    accStatusName() {
      return (
        this.store.accountStatuses.find((x) => {
          return x.id === this.store.cashStatement.accStatus;
        })?.name ?? "-"
      );
    },
  },
});
