export default interface InvoiceImportItem{
    id: number;
    partsID: number|null;
    gPartsID: number|null;
    codes: string;
    description: string;
    quantity: number;
    unitType: string;
    netto: number;
    vat: number;
    brutto: number;
    invoiceItemId: number;
    isValid: boolean;
    vatRate: number;
}

export const InvoiceImportItemDefaultProps: InvoiceImportItem = {
    id: 1,
    partsID: null,
    gPartsID: null,
    codes: '',
    description: '',
    quantity: 0,
    unitType: '',
    netto: 0,
    vat: 0,
    brutto: 0,
    invoiceItemId: 1,
    isValid: false,
    vatRate: 0,
}