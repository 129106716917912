

































































import Vue from "vue";
import CashDocByReceiptsHead from "../components/CashDocByReceiptsHead.vue";
import CashDocByReceiptsApiSearchPage from "../components/CashDocByReceiptsApiSearchPage.vue";
import ExperimentalInvoicesApiSearchPage from "../experimentInvoice/views/ExperimentalInvoicesApiSearchPage.vue";
import cashStatementStore, { CashStatementStore } from "../../services/store";
import ExperimentalInvoice, {
  ExperimentalInvoiceClass,
} from "../experimentInvoice/models/ExperimentalInvoice";
import VIRIPage from "@/modules/cashStatement/viriItems/views/VIRIPage.vue";
import ApiResponse from "@/models/ApiResponse";
import http from "@/services/http";
import configuration, { AppConfiguration } from "@/models/Configuration";
import cashDocByReceiptsStore, {
  CashDocByReceiptsStore,
} from "../services/CashDocByreceiptsStore";
import CaroStornoPage from "../../../caroStorno/views/CaroStornoPage.vue";
import Tabs from "@/components/Tabs.vue";

interface Data {
  tab: number;
  cashStatementStore: CashStatementStore;
  configuration: AppConfiguration;
  cashDocByReceiptsStore: CashDocByReceiptsStore;
  invoiceIDFilter: undefined;
  isLoading: boolean;
}

interface Props {
  fromStatement: boolean;
}

interface Methods {
  setInvoiceIDFilter(value: undefined): void;
  goToCreditCardPay(): void;
  createDocByExpInvoice(items: ExperimentalInvoiceClass): void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  props: {
    fromStatement: {
      type: Boolean,
      default: true,
    },
  },

  data: () => {
    return {
      tab: 0,
      cashStatementStore,
      configuration,
      cashDocByReceiptsStore,
      invoiceIDFilter: undefined,
      isLoading: false,
    };
  },

  methods: {
    setInvoiceIDFilter(value: undefined) {
      this.invoiceIDFilter = value;
    },

    goToCreditCardPay() {
      this.tab = 0;
      this.cashDocByReceiptsStore.isCreditCardPay = true;
    },

    async createDocByExpInvoice(items: ExperimentalInvoiceClass) {
      this.$emit("cancel");
      await http
        .post<ApiResponse<number>>(
          `cash-doc-item/experimental-invoice/${cashStatementStore.cashStatement.id}/generate-cash-doc`,
          items
        )
        .then((x) => {
          cashStatementStore.loadCashDocumentsByStatementId(
            cashStatementStore.cashStatement.id
          );
        });
    },
  },

  components: {
    Head: CashDocByReceiptsHead,
    CashDocByReceiptsApiSearchPage,
    ExperimentalInvoicesApiSearchPage,
    VIRIPage,
    CaroStornoPage,
    Tabs
  },
});
