















































































import eventBus from "@/services/eventBus";
import store, { BankExtractImportAccountTypeStore } from "../services/BankExtractImportAccountTypeStore";
import BankExtractImportAccountType from "../models/BankExtractImportAccountType";

import { ConfirmationMixin } from "@/mixins/confirmation";
import Confirmation from "@/components/Confirmation.vue";
import IconButton from "@/components/IconButton.vue";
import AccountSubtype from "@/modules/accountType/models/AccountSubtype";

interface Data {
  store: BankExtractImportAccountTypeStore;
}

export default ConfirmationMixin.extend({
  components: { IconButton, Confirmation },

  mixins: [ConfirmationMixin],

  data: (): Data => ({
    store,
  }),

  async beforeMount() {
    await Promise.all([
      this.store.load(),
      this.store.loadBanks(),
      this.store.loadAccTypes(),
      this.store.loadAccSubtypes(),
    ]);
  },

  methods: {
    newItem(): void {
      this.store.items.unshift(new BankExtractImportAccountType());
    },

    getSubtypes(typeID: number): AccountSubtype[] {
      return this.store.subtypes.filter((x) => x.type == typeID || x.id == 1);
    },

    async deleteItem(index: number): Promise<void> {
      const confirmed = await this.confirm("", "confirmation-" + index);
      if (!confirmed) return;

      this.store.items.splice(index, 1);
    },

    async save(): Promise<void> {
      await this.store.save().then(() => eventBus.$emit("success", "Sikeres mentés!"));
    },
  },
});
