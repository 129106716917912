




























































import Pager, { PaginatedPager } from "@/models/Pager";
import createPagerText from "@/utils/pagerText";
import paginate from "jw-paginate";
import Vue from "vue";

interface Data {
  pager: Pager;
}

interface Methods {
  setPage(obj: Pager, page: number, size?: number): void;
  setPageSize(obj: Pager, size: number): void;
}

interface Props {
  items: object[];
  baseSizes: number[];
}

//
//!  Usage
//  @change - returns the inner pager object that contains the paged item list, currentPage, pageSize
//  :items  - items to slice
//
//? <template>
//    <Paginator :items="items" @change="pagerChanged" />
//
//    <grid :data-items="pager.sliced" ... />
//
//? <script>
//    import Paginator from "@/components/Paginator.vue";
//
//? Data:
//    pager: new Pager();
//
//? Methods:
//    pagerChanged(pager: Pager): void;
//    pagerChanged(pager) { this.pager = pager; },
//
// TODO: backend léptetés
//

export default Vue.extend<Data, Methods, {}, Props>({
  name: "paginator",

  props: {
    items: {
      type: Array as () => object[],
      default: () => [],
    },

    baseSizes: {
      type: Array as () => number[],
      default: () => [10, 25, 50, 100],
    },
  },

  data: () => ({
    pager: new Pager(),
  }),

  created() {
    this.pager = {
      ...this.pager,
      pager: new PaginatedPager(),
      list: this.items,
      sliced: [],
      currentPage: 1,
    };
  },

  beforeMount() {
    if (!this.pager.list) return;
    this.setPageSize(this.pager, this.baseSizes[0]);
  },

  methods: {
    setPage(obj: Pager, page: number, size?: number) {
      let pager = new PaginatedPager();
      pager = size ? paginate(obj.list.length, page, size, 5) : paginate(obj.list.length, page, obj.pageSize, 5);
      obj.pager = pager;
      obj.sliced = obj.list.slice(pager.startIndex, pager.endIndex + 1);
      obj.currentPage = obj.pager.currentPage;
      obj.pageSize = obj.pager.pageSize;

      this.$emit("change", obj);
    },

    setPageSize(obj: Pager, size: number) {
      obj.pageSize = size;
      this.setPage(obj, 1, size);
    },
  },

  watch: {
    items() {
      this.pager.list = this.items;

      if (this.pager.currentPage !== 1 && this.pager.pageSize !== this.baseSizes[0]) {
        this.setPageSize(this.pager, this.baseSizes[0]);
      } else {
        this.setPage(this.pager, 1);
      }
    },
  },

  computed: {
    footerText() {
      return createPagerText(this.pager.list.length, this.pager.currentPage, this.pager.pageSize);
    },
  },
});
