import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import ProjectHead from "../models/ProjectHead";
import ProjectHeadEditModel from "../models/ProjectHeadEditModel";

export default class ProjectHeadApi {
  static getAll(): Promise<ProjectHead[]> {
    return http.get<ApiResponse<ProjectHead[]>>(`project-head`).then(handleApiResponse);
  }

  static getAllEditModels(): Promise<ProjectHeadEditModel[]> {
    return http.get<ApiResponse<ProjectHeadEditModel[]>>(`project-head/edit/model`).then(handleApiResponse);
  }
}
