<template>
  <a>
    <v-icon small class="mx-1" :color="color" style="vertical-align: top">
      {{ visible ? "fa-angle-double-up" : "fa-angle-double-down" }}
    </v-icon>
  </a>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    visible: { type: Boolean, default: false },
    color: { type: String, default: "var(--primaryColor)" },
  },
});
</script>
