





































































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import BankRemitAndItems from "../models/BankRemitAndItems";
import store, { BankRemitStore } from "../services/BankRemitStore";
import BankRemitStatus, {
  bankRemitStatusDefaultFilterProps,
} from "../models/BankRemitStatus";
import BankRemitItem from "../models/BankRemitItem";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import { FilteringModel, FilterOption } from "@/services/filtering";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import eventBus from "@/services/eventBus";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import BankRemitHistory from "../views/BankRemitHistory.vue";
import BankRemitSelectExportType from "../components/BankRemitSelectExportType.vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import IconButton from "@/components/IconButton.vue";
import { BankRemitStatusEnum } from "./BankRemitHeadView.vue";



interface Data {
  store: BankRemitStore;

  defaultBankRemitStatus: BankRemitStatus;

  exportEnabled: boolean;

  statusFilter: number[];
  maxDisplayFilter: number;

  loading: boolean;
  waitingForPartners: boolean;
}

interface Methods {
  createBankRemit(): void;
  editItem(data: BankRemitAndItems): void;

  isExportEnabled(item: BankRemitAndItems): boolean;
    sum(items: BankRemitItem[]): string;

  setHistoryExpanded(item: BankRemitAndItems): void;
  setExportExpanded(item: BankRemitAndItems): void;

  emitRemitId(id: number): void;

  setShowPartners(item: BankRemitAndItems): void;
  emitRemitAndPartner(remitItem: BankRemitItem, remit: BankRemitAndItems): void;
}

interface Props{
  isRiportSelect: boolean;
  isPartnerSelect: boolean;
}

export default Vue.extend<Data, Methods, {}, Props>({
  props: {
    isRiportSelect: { type: Boolean, default: false },
    isPartnerSelect: { type: Boolean, default: false },
  },

  components: {
    IconButton,
    MoneyDisplay,
    BankRemitHistory,
    BankRemitSelectExportType,
    ApiSearchPage,
    Page,
  },

  data() {
    return {
      store,
      showHistory: false,

      defaultBankRemitStatus: { ...bankRemitStatusDefaultFilterProps },

      exportEnabled: false,

      statusFilter: [],
      maxDisplayFilter: 1,

      loading: false,
      waitingForPartners: false,
    };
  },


  async beforeMount() {
    this.loading = true;
    await this.store.loadBankDefrTypes()
    await this.store.loadBankRemitStatuses().then((x) => {
      this.loading = false;
    });

    

    window.localStorage.setItem("previousPage", "list");
  },

  computed: {
    createButtonText(){
      return this.isRiportSelect ? "" : 'Új banki csomag';
    },

    items() {
      return this.store.bankRemits;
    },

    bankRemitStatuses() {
      return store.bankRemitStatuses;
    },

    remitItemCols: () => [
      {
        title: "",
        width: "50px",
        cell: "selectPartnerTemplate",
      },
      {
        field: "partnerName",
        title: "Partner név",
      },
      {
        field: "bankAccountName",
        title: "Bankszámla",
      },
      {
        field: "invNo",
        title: "Számlaszám",
      },
      {
        field: "amount",
        title: "Összeg",
      },
      {
        field: "currAmount",
        title: "Deviza összeg",
      },
    ],


    columns: (): ApiSearchColumn[] => [
      {
        cell: "historyTemplate",
        filterable: false,
      },
      { field: "bankName", title: "Bank neve" },
      { field: "bankAccount", title: "Bankszámla" },
      {
        field: "currency",
        title: "Pénznem",
      },
      { field: "filename", title: "File neve" },
      {
        field: "remitDate",
        title: "Utalás dátuma",
        type: ColumnType.Date,
      },
      {
        title: "Állapot",
        filterable: true,
        field: "statusName",
        multiselectField: "selectedStatuses",
        multiselectOptions: store.bankRemitStatuses.map((x) => ({ text: x.name, value: x.id })),
        multiselect: true,
      },
      { cell: "exportTemplate", filterable: false },
      { cell: "commandTemplate", filterable: false },
    ],

    itemColumns: () => [
      { field: "prefix", title: "Prefix", headerCell: "myHeaderTemplate" },
      { field: "invoice", title: "Számla", headerCell: "myHeaderTemplate" },
      {
        field: "invNo",
        title: "Bizonylat szám",
        headerCell: "myHeaderTemplate",
      },
      {
        field: "partnerName",
        title: "Partner",
        headerCell: "myHeaderTemplate",
      },
      {
        field: "amount",
        cell: "amountTemplate",
        title: "Számla összege",
        headerCell: "myHeaderTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        field: "dueAmount",
        cell: "dueAmountTemplate",
        title: "Esedékes",
        headerCell: "myHeaderTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      // {
      //   field: "invCurrency",
      //   title: "Pénznem",
      // },
      {
        field: "exchangeRate",
        title: "Árfolyamszorzó",
        headerCell: "myHeaderTemplate",
        headerClassName: "right_text",
        className: "right_text",
      },
      {
        field: "pterm",
        title: "Utalási határidő",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        headerCell: "myHeaderTemplate",
        className: "center_text adjust_right",
        headerClassName: "center_text adjust_right",
      },
      { width: 0, filterable: false },
    ],
  },

  methods: {
    createBankRemit() {
      this.$router.push("bank-remits-account");
    },

    emitRemitAndPartner(remitItem: BankRemitItem, remit: BankRemitAndItems) {
      this.$emit("remitAndPartner", remit.id, remitItem.partner);
    },

    async setShowPartners(item) {
      item.showPartners = !item.showPartners;
      item.expanded = item.showPartners;
    },

    emitRemitId(id) {
      this.$emit("remitId", id.toString());
    },

    editItem(data: BankRemitAndItems) {
      this.$router.push(`bank-remits/create/${data.id}/head`);
    },

    isExportEnabled(item: BankRemitAndItems) {
      if (item.status === BankRemitStatusEnum.forwarded) {
        return true;
      }
      return false;
    },
    sum(items: BankRemitItem[]) {
      let sum = 0;
      items.forEach((element: BankRemitItem) => {
        sum += element.currAmount;
      });

      return sum.toLocaleString();
    },
    setExportExpanded(item: BankRemitAndItems) {
      if (!item.historyExpanded) {
        item.expanded = !item.exportExpanded;
      }
      item.exportExpanded = !item.exportExpanded;
    },

    setHistoryExpanded(item: BankRemitAndItems) {
      if (!item.exportExpanded) {
        item.expanded = !item.historyExpanded;
      }
      item.historyExpanded = !item.historyExpanded;
    },
  },
});
