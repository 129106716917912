import { render, staticRenderFns } from "./NoticesPage.vue?vue&type=template&id=0a68ba1f&scoped=true&"
import script from "./NoticesPage.vue?vue&type=script&lang=ts&"
export * from "./NoticesPage.vue?vue&type=script&lang=ts&"
import style0 from "./NoticesPage.vue?vue&type=style&index=0&id=0a68ba1f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a68ba1f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VTooltip})
