













import User, { userDefaultProps } from "@/modules/invoiceGroup/models/User";
import Vue from "vue"
import { UserStore } from "@/modules/user/services/UserStore";

interface Data {
  store: UserStore;
  user: User | undefined;
}

export default Vue.extend({
  data(): Data {
      return {
          store: new UserStore(),
          user: userDefaultProps,
      }       
  },
  props: {
    value: Number,
  },
  async beforeMount() {
    await this.store.loadUsers();
    this.user = this.store.getUser(this.value);
  },
  watch: { 
    value: function(newVal: number, oldVal: number) {
      if(this.user)
      {
        if(newVal !== this.user.id)
          this.user = this.store.getUser(newVal);
      }
      this.user = this.store.getUser(newVal);
    }
  },
  methods:
  {
    onChange(selectedUser: User) {
      this.$emit("input", selectedUser.id);
      this.$emit("change", selectedUser.id);
    }
  }
})

