import ApiResponse from "@/models/ApiResponse";
import InvoiceGroup from "@/modules/invoiceGroup/models/InvoiceGroup";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import InvoiceParams from "../models/sysinvoice";

export default class InvoiceGroupApi {
  static async GetAllInvoiceParams(): Promise<InvoiceParams[]> {
    return await http
      .get<ApiResponse<InvoiceParams[]>>(
        `general-incoming-invoice/invoice-params`
      )
      .then(handleApiResponse);
  }

  static async GetInvoiceParams(invoiceParamsId: number): Promise<InvoiceParams> {
    return await http
      .get<ApiResponse<InvoiceParams>>(
        `general-incoming-invoice/invoice-params/${invoiceParamsId}`
      )
      .then(handleApiResponse);
  }

  static async UpdateInvoiceParams(dto: InvoiceParams): Promise<boolean>{
    return await http
      .post(`general-incoming-invoice/update-invoice-params`,dto)
      .then(checkResponse);
  }

  static async GetInvGroupList(): Promise<InvoiceGroup[]>{
    return await http
      .get<ApiResponse<InvoiceGroup[]>>(`invoice-group/`)
      .then(handleApiResponse)
  }
}
