var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" Áfák ")])],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns,"pageable":_vm.pageable,"skip":_vm.skip,"take":_vm.take,"detail":"deletingTemplate","expand-field":'deleting',"total":_vm.total,"sortable":true,"sort":_vm.sort,"edit-field":'inEdit',"scrollable":"none"},on:{"pagechange":_vm.pageChangeHandler,"sortchange":_vm.sortChange,"itemchange":_vm.itemChange},scopedSlots:_vm._u([{key:"MaxDegreeTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":true},model:{value:(props.dataItem.maxDegree),callback:function ($$v) {_vm.$set(props.dataItem, "maxDegree", $$v)},expression:"props.dataItem.maxDegree"}})],1)]}},{key:"DiffTaxTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":true},model:{value:(props.dataItem.diffTax),callback:function ($$v) {_vm.$set(props.dataItem, "diffTax", $$v)},expression:"props.dataItem.diffTax"}})],1)]}},{key:"SpecTaxTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":true},model:{value:(props.dataItem.specTax),callback:function ($$v) {_vm.$set(props.dataItem, "specTax", $$v)},expression:"props.dataItem.specTax"}})],1)]}},{key:"isInverseTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":true},model:{value:(props.dataItem.isInverseTax),callback:function ($$v) {_vm.$set(props.dataItem, "isInverseTax", $$v)},expression:"props.dataItem.isInverseTax"}})],1)]}},{key:"ReadOnlyTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":true},model:{value:(props.dataItem.readOnly),callback:function ($$v) {_vm.$set(props.dataItem, "readOnly", $$v)},expression:"props.dataItem.readOnly"}})],1)]}},{key:"DeletedTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.deleted),callback:function ($$v) {_vm.$set(props.dataItem, "deleted", $$v)},expression:"props.dataItem.deleted"}})],1)]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateTax(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])])],1)]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }