

















































import Vue from "vue";
import store, { AccountCodeEditModelStore } from "../services/AccountCodeEditModelStore";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
  import {
  ApiSearchColumn,
  ColumnType,
  } from "@/components/apiSearch/models/ApiSearch";
import AccountCodeModel from "../models/AccountCodeModel";

interface Data {
  store: AccountCodeEditModelStore;
}

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
}

interface Methods {
  editItem(item: AccountCodeModel): void;
  cancelEdit(item: AccountCodeModel): void;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
      ApiSearchPage
  },

  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    window.scrollTo(0, 0);
    //await this.store.loadAccountCodeEditModels();
  },

  computed: {
      columns: (): ApiSearchColumn[] => [
      {
          title: "Csoport",
          field: "tableNameDisplay",
      },
      {
          title: "Típus",
          field: "columnNameDisplay",
      },
      {
          title: "Megnevezés",
          field: "national",
      },
      {
          title: "Főkönyvi szám",
          field: "accCode",
          cell: "accCodeTemplate"
      },
      {
          title: "",
          field: "tableID",
          cell: "commandTemplate",
          filterable: false,
          sortable: false,
          headerClass: "command",
          fieldClass: "command"
      }
      ],

      url() {
          return 'account-codes/editmodels/v2';
      }
  },

  methods: {
    editItem(item){
      item.inEdit = true;
      item.originalAccCode = item.accCode;
    },

    cancelEdit(item){
      item.inEdit = false;
      item.originalAccCode = item.accCode;

      this.store.saveAccountCode(item);
    }
  },
});
