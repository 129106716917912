





























































































































































import ApiSearch from "@/components/apiSearch/components/ApiSearch.vue";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import MoneyInput from "@/components/MoneyInput.vue";
import ApiResponse from "@/models/ApiResponse";
import eventBus from "@/services/eventBus";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import Vue from "vue";
import InvoiceItemDimension, {
  invoiceItemDimensionDefaultProps,
} from "../models/InvoiceItemDimension";
import InvoiceItemFreeDimension, {
  FreeDimension,
} from "../models/InvoiceItemFreeDimension";

interface Data {
  dataItems: FreeDimension[];
  selectedDimId: number;
  itemText: string;
  dims: InvoiceItemFreeDimension[];
  newDim: InvoiceItemFreeDimension;
}

interface Props {
  invItem: number;
  netto: number;
}

interface Method{
  getDims(): void;
  selected(item: number): void;
  cancelUpdate(): void;
  saveDim(): void;
  removeDim(dim: InvoiceItemFreeDimension): void;
}

export default Vue.extend<Data, Method, {}, Props>({
  props: {
    invItem: Number,
    netto: Number,
  },

  components: {
    ApiSearch,
    MoneyInput,
  },

  data() {
    return {
      dataItems: [],
      itemText: "name",
      selectedDimId: 1,
      dims: [],
      newDim: {
        id: 0,
        amount: 0,
        dimension: 1,
        dimName: "-"
      }
    };
  },

  computed: {
    columns: (): ApiSearchColumn[] => [{ field: "name", title: "Megnevezés" }],
  },

  methods: {
    selected(item: number) {
      console.log(item)
      this.newDim.dimension = item;
    },

    cancelUpdate() {
      this.$emit("cancelUpdate");
    },

    async saveDim(){
        const result =await http.post(`invoice-item-dimension/free-dimensions/${this.invItem}`,this.newDim).then(checkResponse)
        if(result){
            eventBus.$emit("success","Sikeres mentés!");
            this.newDim.id=0;
            this.newDim.dimension=1;
            this.newDim.amount=this.netto;
            this.getDims();
        }
        else{
            eventBus.$emit("error","Nem sikerült a mentés!");
        }
    },

    async removeDim(dim){
      const result = await http.delete(`invoice-item-dimension/free-dimension/${dim.id}`)
      .then(checkResponse);
      if(result){
        eventBus.$emit("success","Sikeresen törölve!")
        this.getDims()
      }
      else eventBus.$emit("error","A törlés nem sikerült!")
    },

    async getDims(){
      this.dims = await http
      .get<ApiResponse<InvoiceItemFreeDimension[]>>(
        `invoice-item-dimension/free-dimensions/${this.invItem}`
      )
      .then(handleApiResponse);
    }
  },

  async mounted() {
    this.getDims();
    this.dims.filter((x) => x.amount > 1).map((x) => (x.amount = this.netto));
    this.newDim.amount=this.netto;
  },
});
