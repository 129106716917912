



















































































































// Components
import Tabs from "@/components/Tabs.vue";
import IconButton from "@/components/IconButton.vue";
import Fragment from "@/components/Fragment.vue";

// Models
import Vue from "vue";
import dayjs from "dayjs";
import eventBus from "@/services/eventBus";
import store, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import partnerStore, { PartnerStore } from "@/modules/partner/services/PartnerStore";
import correctionStore, { UninvoicedStockCorrectionStore } from "../services/store";
import { InvoiceRelation, InvoiceRelationType, StoreIn } from "@/modules/invoiceItems/models";
import StockCorrectionStoreInItems from "./StockCorrectionStoreInItems.vue";
import itemStore, { InvoiceItemStore } from "@/modules/invoiceItems/services/store";

interface Data {
  store: InvoiceFlowStore;
  partnerStore: PartnerStore;
  correctionStore: UninvoicedStockCorrectionStore;
  itemStore: InvoiceItemStore;
  searchTypes: SearchType[];
  searchType: SearchType;
  searchText: string;
  startDate: string;
  endDate: string;
}

interface Methods {
  searchStoreIns(): void;
  getOrderNumber(storeIn: StoreIn): string | undefined;
  toggleDetails(storeIn: StoreIn): void;
  clearItemSearch(): void;
  searchTypeChanged(type: SearchType): void;
  isAttachedToInvoice(storeIn: StoreIn): boolean;
  addStoreIn(item: any): void;
}

interface Props {
  create: boolean;
}

enum SearchType {
  LastWeek = "Előző hét",
  LastTwoWeeks = "Előző két hét",
  LastMonth = "Előző hónap",
  LastTwoMonths = "Előző két hónap",
  LastSixMonths = "Előző félév",
  LastYear = "Előző év",
  LastTwoYears = "Előző két év",
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: { Tabs, IconButton, Fragment, StockCorrectionStoreInItems },

  props: {
    create: { type: Boolean, default: false },
  },

  data: () => ({
    store,
    partnerStore,
    correctionStore,
    itemStore,
    searchTypes: Object.values(SearchType),
    searchType: SearchType.LastYear,
    searchText: "",
    startDate: dayjs()
      .add(-1, "years")
      .format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  }),

  created() {
    eventBus.$on("uninvoiced-stock-correction-item:add", this.clearItemSearch);
  },

  beforeDestroy() {
    eventBus.$off("uninvoiced-stock-correction-item:add", this.clearItemSearch);
  },

  methods: {
    async addStoreIn(item: any) {
      await this.correctionStore.addStoreIn(item);
      await this.itemStore.save();
    },

    async searchStoreIns() {
      if (this.create) {
        await this.correctionStore.loadAllStoreIns(this.searchText, this.startDate, this.endDate);
      } else {
        const supplierID = Number(this.$route.params.partnerId) || this.store.invoice.partner;
        await this.correctionStore.loadStoreIns(supplierID, this.searchText, this.startDate, this.endDate);
      }
    },

    toggleDetails(storeIn: StoreIn) {
      storeIn.expanded = !storeIn.expanded;
    },

    getOrderNumber(storeIn) {
      const stock = (storeIn.stocks || []).find((s) => !!s.orderNumber);
      if (stock) {
        return stock.orderNumber;
      }

      return undefined;
    },

    clearItemSearch() {
      this.correctionStore.storeIns = [];
    },

    searchTypeChanged(type) {
      const today = dayjs();

      switch (type) {
        case SearchType.LastWeek:
          this.startDate = today.subtract(1, "week").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;

        case SearchType.LastTwoWeeks:
          this.startDate = today.subtract(2, "week").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;

        case SearchType.LastMonth:
          this.startDate = today.subtract(1, "month").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;

        case SearchType.LastTwoMonths:
          this.startDate = today.subtract(2, "month").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;

        case SearchType.LastSixMonths:
          this.startDate = today.subtract(6, "month").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;

        case SearchType.LastYear:
          this.startDate = today.subtract(1, "year").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;

        case SearchType.LastTwoYears:
          this.startDate = today.subtract(2, "year").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;

        default:
          break;
      }
    },

    isAttachedToInvoice(storeIn) {
      let relations: InvoiceRelation[] = [];

      this.correctionStore.items.forEach((i) => {
        relations = [...relations, ...i.relations];
      });

      if (storeIn.stocks.length != 0) {
        return storeIn.stocks.some((s) =>
          relations.some((i) => i.type === InvoiceRelationType.Stock && i.relatedItemID === s.stockID)
        );
      }

      return false;
    },
  },

  computed: {
    optionalColumns() {
      const columns = new Set<string>();

      if (this.correctionStore.storeIns.some((i) => !!this.getOrderNumber(i))) {
        columns.add("orderNumber");
      }

      return columns;
    },

    disabled() {
      return !this.create && !this.store.isEditingEnabled;
    },
  },
});
