import User from "@/modules/invoiceGroup/models/User";
import { InvoiceDeadline } from "./../models/InvoiceDeadline";
import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import { PaymentSchedule } from "../models/PaymentSchedule";
import { formatDateISO } from "@/utils/formatDates";
import { splitAccountNumber } from "@/modules/partner/services/PartnerApi";
import PartBank from "@/modules/cashPoint/models/PartBank";
import PaymentMode from "../../paymentMode/models/PaymentModeDisplay";

export class PaymentScheduleApi {
  static getPaymentScheduleResponse = (prefix: string, invoiceId: number): Promise<PaymentSchedule> =>
    http.get<ApiResponse<PaymentSchedule>>(`payment-schedule/${prefix}/${invoiceId}`).then(handleApiResponse);

  static managePaymentScheduleAndItems = (prefix: string, head: PaymentSchedule): Promise<PaymentSchedule> =>
    http
      .put<ApiResponse<PaymentSchedule>>(`payment-schedule/${prefix}`, {
        paymentSchedule: head,
      })
      .then(handleApiResponse);

  static getInvoiceDeadlines(): Promise<InvoiceDeadline[]> {
    return http.get<ApiResponse<InvoiceDeadline[]>>(`payment-schedule`).then(handleApiResponse);
  }

  static setPaymentSchedule(
    result: PaymentSchedule,
    partBanks: PartBank[],
    paymentModes: PaymentMode[],
    users: User[]
  ): PaymentSchedule {
    return new PaymentSchedule({
      ...result,
      selectedResponsibleUser: users.find((x) => x.id == result.responsibleUserID) ?? users[0],
      items:
        result.items?.map((i) => ({
          ...i,
          selectedBankAccount: partBanks.find((x) => x.id == i.bankAccount) ?? partBanks[0],
          selectedPaymentMode: paymentModes.find((x) => x.id == i.paymentMode) ?? paymentModes[0],
          deadlineDisplay: formatDateISO(i.deadline),
          accountNoSplit: splitAccountNumber(i.bankAccount.toString()),
        })) ?? [],
    });
  }

  static getResponsibleUsers(): Promise<User[]> {
    return http.get<ApiResponse<User[]>>(`payment-schedule/responsible-users`).then(handleApiResponse);
  }
}
