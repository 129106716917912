




























































































































































































































































































































































import MoneyInput from "@/components/MoneyInput.vue";
import ProjectApiSearch from "@/modules/invoiceItemDimension/components/ProjectApiSearch.vue";

import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import partsStore, { SerPartStore } from "../../serPart/services/SerPartStore";
import stationStore, { SerStationStore } from "../../serStation/services/SerStationStore";
import userStore, { UserStore } from "../../user/services/UserStore";
import carStore, { SerCarStore } from "../../serCar/services/SerCarStore";
import Dimension, { dimensionDefaultProps } from "../models/Dimension";

interface Data {
  backupItem: Dimension;
  newDim: Dimension;
  inEdit: boolean;
  creating: boolean;
  deleting: boolean;
  indexToDelete: number;
  visibleItems: Dimension[];

  store: InvoiceFlowStore;
  partsStore: SerPartStore;
  stationStore: SerStationStore;
  userStore: UserStore;
  carStore: SerCarStore;

  searchSerPart: any;
  searchSerPartUpdate: any;
  searchSerCar: any;
  searchSerCarUpdate: any;
}

interface Method {
  setInEdit(item: Dimension): void;
  cancelEdit(item: Dimension): void;
  saveEdit(item: Dimension): void;
  deleteItem(item: Dimension): void;
  cancelDelete(): void;
  confirmDelete(): void;
  createItem(): void;
  confirmCreate(): void;
  cancelCreate(): void;

  getSerParts(val: string): void;
  getSerCars(val: string): void;
}

interface Props {
  items: Dimension[];
  currencyDecimals: number;
  headID: number;
  isPageDisabled: boolean;
}

export default Vue.extend<Data, Method, {}, Props>({
  props: {
    items: { type: Array as () => Dimension[] },
    currencyDecimals: { type: Number },
    headID: { type: Number },
    isPageDisabled: { type: Boolean },
  },

  components: {
    ProjectApiSearch,
    MoneyInput,
  },

  data: () => ({
    backupItem: { ...dimensionDefaultProps },
    newDim: { ...dimensionDefaultProps },
    inEdit: false,
    creating: false,
    deleting: false,
    indexToDelete: 0,
    newDimCode: "",
    newTotal: 0,
    visibleItems: [],

    store,
    partsStore,
    stationStore,
    userStore,
    carStore,

    searchSerPart: null,
    searchSerPartUpdate: null,
    searchSerCar: null,
    searchSerCarUpdate: null,
  }),

  async mounted() {
    const mapped = this.items.map((x) => Vue.observable({ ...x, inEdit: false, resourceType: "" }));

    mapped.forEach((x) => {
      if (x.stationID > 1) {
        x.resourceType = "Munkaállomás";
      } else if (x.toolID > 1) {
        x.resourceType = "Szerszám";
      } else if (x.userID > 1) {
        x.resourceType = "Munkatárs";
      }
    });

    this.visibleItems = mapped;

    await Promise.all([
      this.partsStore.loadSerPartEditModels(""),
      this.stationStore.loadSerStations(),
      this.userStore.loadUsers(),
      this.carStore.loadSerCarRegNumsBy(""),
    ]);
  },

  watch: {
    searchSerPart(val: string) {
      if (val == null) this.getSerParts("");
      else if (val.length > 2) this.getSerParts(val);
    },

    searchSerPartUpdate(val: string) {
      if (val == null) this.getSerParts("");
      else if (val.length > 2) this.getSerParts(val);
    },

    searchSerCar(val: string) {
      if (val == null) this.getSerCars("");
      else if (val.length > 2) this.getSerCars(val);
    },

    searchSerCarUpdate(val: string) {
      if (val == null) this.getSerCars("");
      else if (val.length > 2) this.getSerCars(val);
    },
  },

  methods: {
    async getSerParts(v) {
      await this.partsStore.loadSerPartEditModels(v);
    },

    async getSerCars(v) {
      await this.carStore.loadSerCarRegNumsBy(v);
    },

    setInEdit(item) {
      this.inEdit = true;
      this.backupItem = { ...item };
      item.inEdit = true;
    },

    cancelEdit(item) {
      item.carID = this.backupItem.carID;
      item.carTotal = this.backupItem.carTotal;
      item.resourceTotal = this.backupItem.resourceTotal;
      item.resourceType = this.backupItem.resourceType;
      item.projectTotal = this.backupItem.projectTotal;
      item.project = this.backupItem.project;
      item.userID = this.backupItem.userID;
      item.stationID = this.backupItem.stationID;
      item.toolID = this.backupItem.toolID;
      item.carID = this.backupItem.carID;

      item.inEdit = false;

      this.inEdit = false;
    },

    async saveEdit(item) {
      await this.store.updateAccItemDimension(item, this.headID);

      item.inEdit = false;
      this.inEdit = false;
    },

    deleteItem(item) {
      this.indexToDelete = this.visibleItems.indexOf(item);
      this.deleting = true;
    },

    cancelDelete() {
      this.deleting = false;
    },

    async confirmDelete() {
      const item = this.visibleItems[this.indexToDelete];

      const projectID = item.projectDimID || 0;
      const resourceID = item.resourceDimID || 0;
      const carID = item.carDimID || 0;

      await this.store.deleteAccItemDimension(projectID, resourceID, carID);

      this.visibleItems.splice(this.indexToDelete, 1);
      this.visibleItems = [...this.visibleItems];
      this.deleting = false;
    },

    createItem() {
      this.newDim = { ...dimensionDefaultProps, resourceType: "Munkaállomás" };

      this.creating = true;
    },

    async confirmCreate() {
      await this.store.createAccItemDimension(this.newDim, this.headID).then((data) => {
        this.newDim.projectDimID = data.projectDimID;
        this.newDim.resourceDimID = data.resourceDimID;
        this.newDim.carDimID = data.carDimID;
      });
      this.visibleItems.push(this.newDim);

      this.creating = false;
    },

    cancelCreate() {
      this.creating = false;
    },
  },
});
