import Vue from "vue";

export const ConfirmationMixin = Vue.extend({
  methods: {
    confirm(message: string, refName = "confirmation") {
      const ref = this.$refs[refName];
      const confirmationRef = Array.isArray(ref) ? ref[0] : ref;
      return (confirmationRef as any).show(message);
    },
  },
});
