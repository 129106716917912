





























































































import IconButton from "@/components/IconButton.vue";
import Vue from "vue";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import CheckBox from "@/components/CheckBox.vue";
import * as api from "@/modules/invoiceItems/services/api";
import WorkSheetItemGpart from "@/modules/invoiceItemDimension/models/WorkSheetItemGpart";
import WorkSheetItemRelation from "@/modules/invoiceItemDimension/models/WorkSheetItemRelations";

interface Data {
  flowStore: InvoiceFlowStore;
  workSheetItemRelaions: any[];
  newWorkSheetItemRelaions: WorkSheetItemGpart[];
  searchText: string;

  selectedItems: WorkSheetItemGpart[];

  autocompleteItems: any[];
  gpartsFilter: boolean;
  //selectedAutocompleteItem: string;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { IconButton, MoneyDisplay, CheckBox },
  props: {
    invItemId: Number,
    invItemGpartId: Number,
    invItemNetto: Number,
  },

  data() {
    return {
      flowStore,
      workSheetItemRelaions: [],
      newWorkSheetItemRelaions: [],
      searchText: "",

      selectedItems: [],
      autocompleteItems: [
      {
          id: 1,
          name: "Bármilyen szolgáltatás",
        },
        {
          id: 0,
          name: "Számla tétellel megegyező szolgáltatás",
        }
      ],
      selectedAutocompleteItem: {
          id: 1,
          name: "Bármilyen szolgáltatás",
        },
      gpartsFilter: false,
    };
  },

  async beforeMount() {
    this.workSheetItemRelaions = await api.getCurrentFachSheetItemRelations(Number(this.$props.invItemId));
  },

  methods: {
    autocompleteItemChanged(e: any) {
      if (e.id == 0) {
        this.gpartsFilter = true;
      } else this.gpartsFilter = false;
    },

    async search(e: KeyboardEvent) {
      if (e.key != "Enter") {
        return;
      }
      this.newWorkSheetItemRelaions = (
        await api.searchFachSheetCoopItemRelations(
          this.searchText,
          this.flowStore.invoice.partner,
          Number(this.$props.invItemGpartId),
          this.gpartsFilter
        )
      ).map((x: WorkSheetItemGpart) => ({ ...x, isSelected: false }));
    },

    clear() {
      this.newWorkSheetItemRelaions = [];
      this.searchText = "";
    },

    selectItem(item: WorkSheetItemGpart) {
      if (!item.isSelected) {
        item.isSelected = true;
        this.selectedItems.push(item);
      } else {
        item.isSelected = false;
        this.selectedItems = this.selectedItems.filter((x) => x.id == item.id);
      }
    },

    async deleteItem(item: any) {
      this.workSheetItemRelaions = this.workSheetItemRelaions.filter((x) => x.relationId != item.relationId);
      await api.deleteWorkSheetItemRelation(item.relationId);
    },

    async addItems() {
      const items: any = [];
      this.selectedItems.forEach((e) => {
        items.push({
          id: 1,
          invNo: Number(this.$route.params.invoiceId),
          wsCont: 1,
          itemId: Number(this.$props.invItemId),
          total: Number(this.$props.invItemNetto),
          facSheetCoop: 1,
          fachSheetBr: e.id,
          note: "",
        });
      });

      console.log(items);

      await api.addWorkSheetItemRelation(items);
      this.newWorkSheetItemRelaions = [];
      this.workSheetItemRelaions = await api.getCurrentFachSheetItemRelations(Number(this.$props.invItemId));
      this.selectedItems = [];
    },
  },

  computed: {
    columns: () => [
      { field: "mainCode", title: "Munkalap igény azonosító" },
      { field: "regCode", title: "Megrendelés szám" },
      { field: "partnerName", title: "Alvállalkozó" },
      { field: "deliveryDate", title: "Határidő", type: "date", format: "{0: yyyy. MM. dd.}" },
      { field: "gpartName", title: "Szolgáltatás neve" },
      { field: "netto", title: "Megrendelt érték" },
      { field: "currency", title: "Valuta" },
      { field: "workSheetId", title: "ID" },
      { cell: "commandTemplate", width: "60px" },
      { width: 0 },
    ],

    newColumns: () => [
      { field: "mainCode", title: "Munkalap igény azonosító" },
      { field: "regCode", title: "Megrendelés szám" },
      { field: "partnerName", title: "Alvállalkozó" },
      { field: "deliveryDate", title: "Határidő", type: "date", format: "{0: yyyy. MM. dd.}" },
      { field: "gpartName", title: "Szolgáltatás neve" },
      { field: "netto", title: "Megrendelt érték" },
      { field: "currency", title: "Valuta" },
      { field: "id", title: "ID" },
      { cell: "commandTemplate", width: "60px" },
      { width: 0 },
    ],
  },
});
