
















































import Page from "../../../components/Page.vue";
import DeniedUserFeatureRelations from "./DeniedUserFeatureRelations.vue";

import Vue from "vue";
import store, { RoleStore } from "@/modules/roles/services/roleStore";
import permStore, { PermissionStore } from "@/services/permissions";
import Role from "../models/Role";

interface Data {
  store: RoleStore;
  permStore: PermissionStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    DeniedUserFeatureRelations,
  },

  data: () => ({
    store,
    permStore,
  }),

  async beforeMount() {
    await this.store.loadRoles();
    await this.store.loadFeatures();
  },

  computed: {
    columns: () => [
      { field: "themeName", title: "Jogosultság csoport" },
      { field: "whoamiName", title: "Jogosultság szint" },
      { field: "features", title: "Funkciók", cell: "featureTemplate" },
      { cell: "commandTemplate", width: "100px" },
      { width: 0 },
    ],
  },

  methods: {
    saveFeatures(role: Role, features: string[]): void {
      this.store.save(role.id, features);
    },
  },
});
