









































































import DateInput from "@/components/DateInput.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import TextInput from "@/components/TextInput.vue";
import NumberInput from "@/components/NumberInput.vue";
import CreditCardNumberInput from "@/components/CreditCardNumberInput.vue";
import UserPicker from "@/components/UserPicker.vue";
import BankAccountPicker from "@/components/BankAccountPicker.vue";
import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import Vue from "vue";
import { isNumber } from "util";

interface Props {
  title: string;
  isText: boolean;
  isPartner: boolean;
  isMoney: boolean;
  isDate: boolean;
  isBool: boolean;
  isNumber: boolean;
  isCreditCard: boolean;
  isUser: boolean;
  isBankAccount: boolean;
  sm: string;
  value: string | Date | number | boolean | object;
  disabled: boolean;
  currency: string;
  append: string;
  decimals: undefined | number;
  min: string;
  isSelect: boolean;
  items: object[];
  itemText: string;
  itemValue: string;
  onlyMyOwn: boolean;
  partnerId: number;
  isReadOnly: boolean;
}

interface Computed {
  inputValue: string | Date | number | boolean | object;
}

// TODO: select, [object]
export default Vue.extend<{}, {}, Computed, Props>({
  components: {
    CreditCardNumberInput,
    MoneyInput,
    TextInput,
    DateInput,
    NumberInput,
    UserPicker,
    BankAccountPicker,
    PartnerSearch,
  },
  props: {
    title: String,
    isPartner: Boolean,
    isText: Boolean,
    isMoney: Boolean,
    isDate: Boolean,
    isReadOnly: { type: Boolean, default: false },
    isBool: Boolean,
    isNumber: Boolean,
    isCreditCard: Boolean,
    isUser: Boolean,
    isBankAccount: Boolean,
    isSelect: Boolean,
    sm: { type: String, default: "3" },
    value: [String, Date, Number, Boolean, Object],
    disabled: Boolean,
    currency: String,
    append: String,
    decimals: { default: undefined },
    min: { type: String, default: "2016-06-15" },
    onlyMyOwn: { type: Boolean, default: false },
    partnerId: { type: Number, default: 0 },
    items: Array as () => object[],
    itemText: { type: String, default: "name" },
    itemValue: { type: String, default: "id" },
  },
  methods: {
    onInput(v: [string, Date, number, boolean, object]) {
      this.$emit("input", v);
      this.$emit("change", v);
    },
  },
  computed: {
    inputValue: {
      get() {
        if (this.isNumber) return this.value?.toString();

        return this.value;
      },
      set(newValue) {
        let val = newValue;
        if (this.isNumber) val = new Number(newValue);

        this.$emit("input", val);
        this.$emit("change", val);
      },
    },
  },
});
