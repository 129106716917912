

















































































































import Vue from "vue";
import store, { TaxStore } from "../services/TaxStore";
import Page from "../../../components/Page.vue";
import Tax from "../models/Tax";
import setAttribute from "../../../utils/dom";
import {
  orderBy,
  SortDescriptor,
} from "@progress/kendo-data-query";

interface Data {
  store: TaxStore;
  skip: number;
  take: number;
  pageable: any;

  sort: SortDescriptor[];
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "8");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      skip: 0,
      take: 10,
      pageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: true,
        previousNext: true,
      },
      sort: [],
    };
  },

  async beforeMount() {
    window.scrollTo(0, 0);
    await this.store.loadTaxes();
  },

  computed: {

    columns: () => [
      { field: "name", title: "Adótípus" },
      { field: "degree", title: "Adókulcs (%)" },
      {
        field: "isInverseTax",
        title: "Ford. ÁFA",
        editor: "boolean",
        cell: "isInverseTemplate",
      },
      {
        field: "specTax",
        title: "Spec. Áfa",
        editor: "boolean",
        cell: "SpecTaxTemplate",
      },
      {
        field: "diffTax",
        title: "Kül. Áfa",
        editor: "boolean",
        cell: "DiffTaxTemplate",
      },
      {
        field: "maxDegree",
        title: "Max. adókulcs",
        editor: "boolean",
        cell: "MaxDegreeTemplate",
      },
      {
        field: "readOnly",
        title: "Kötött",
        editor: "boolean",
        cell: "ReadOnlyTemplate",
      },
      {
        field: "deleted",
        title: "Élő",
        editor: "boolean",
        cell: "DeletedTemplate",
      },
      { cell: "commandTemplate", width: "70px", filterable: false },
    ],

    items() {
      return orderBy(
          this.store.taxes.slice(this.skip, this.take + this.skip),
          this.sort
      );
    },

    total() {
      return this.store.taxes ? this.store.taxes.length : 0;
    },
  },

  methods: {
    pageChangeHandler(event: any) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },


    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    },
    itemChange({
      dataItem,
      value,
      field,
    }: {
      dataItem: Tax;
      value: any;
      field: string;
    }) {
      const item = dataItem;
      const update = {
        [field]: value,
      };

      this.store.save(item);
    },
    showDetails(tax: Tax) {
      this.$router.push(`/tax/${tax.id}/details`);
    },

    createTax() {
      this.$router.push(`/tax/create`);
    },

    updateTax(tax: Tax) {
      this.$router.push(`/tax/${tax.id}/edit`);
    },


  },
});
