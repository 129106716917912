
















import Vue from "vue";
import CashDocByReceipts from "@/modules/cashStatement/receipt/views/CashDocByReceipts.vue";
/* import OpenCashStatementPicker from "../components/OpenCashStatementPicker.vue"; */
import ExperimentalInvoicesApiSearchPage from "@/modules/cashStatement/receipt/experimentInvoice/views/ExperimentalInvoicesApiSearchPage.vue";

export default Vue.extend({
  components: {
    ExperimentalInvoicesApiSearchPage,
  },
});
