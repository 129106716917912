import CashStatementApi from "@/modules/cashStatement/services/api";
import User, { userDefaultProps } from "@/modules/user/models/User";
import Message from "../models/Message";
import MessagedGroupResponse, { messagedGroupResponseDefaultProps } from "../models/MessagedGroupResponse";
import ChatApi from "./ChatApi";
import MessagedUserResponse, { messagedUserResponseDefaultProps } from "../models/MessagedUserResponse";
import GeniChat, { geniChatDefaultProps } from "../models/GeniChat";

export class ChatStore {
  messages: Message[] = [];
  messagedUsers: MessagedUserResponse[] = [];
  messagedGroups: MessagedGroupResponse[] = [];
  messageCount = 0;
  currentUser = 0;
  selectedMessagedUser: MessagedUserResponse = { ...messagedUserResponseDefaultProps };
  selectedMessagedGroup: MessagedGroupResponse = { ...messagedGroupResponseDefaultProps };
  geniChat: GeniChat = { ...geniChatDefaultProps };

  async loadCurrentUser() {
    this.currentUser = (await CashStatementApi.getCurrentUser()).id;
  }

  async loadMessagedUsers() {
    this.messagedUsers = (await ChatApi.getMessagedUsers()).map((x) => ({
      ...x,
      selected: false,
      user: { ...x.user, pictureLink: ChatApi.generatePictureLink(x.user.id) },
    }));
  }

  async loadMessagedGroups() {
    this.messagedGroups = (await ChatApi.getMessagedGroups()).map((x) => ({
      ...x,
      selected: false,
    }));
  }

  async loadGeniMessages(invoiceId: number) {
    this.messages = await ChatApi.getGeniMessages(invoiceId);
  }

  async loadMessages(toId: number) {
    this.messages = await ChatApi.getMessages(toId);
  }

  async loadGroupMessages(groupId: string) {
    this.messages = await ChatApi.getGroupMessages(groupId);
  }

  async createGroup(group: MessagedGroupResponse) {
    return await ChatApi.createGroup(group);
  }

  async addToGroup(groupId: string, userId: number) {
    if (!this.selectedMessagedGroup.selected) return;

    await ChatApi.addToGroup(groupId, userId);
  }

  async leaveGroup() {
    if (!this.selectedMessagedGroup.selected) return;

    const groupId = this.selectedMessagedGroup.groupId;
    await ChatApi.leaveGroup(groupId).then(() => {
      const index = this.messagedGroups.indexOf(this.selectedMessagedGroup);
      if (index > -1) {
        this.messagedGroups.splice(index, 1);
        this.selectedMessagedGroup = { ...messagedGroupResponseDefaultProps };
        this.messages = [];
      }
    });

    return groupId;
  }

  async sendMessage(message: Message) {
    return await ChatApi.sendMessage(message).then((result) => {
      this.messages = [...this.messages, result];
      return result;
    });
  }

  async loadNewMessageCount() {
    this.messageCount = await ChatApi.getNewMessageCount();
  }

  async recieve(message: Message) {
    if (
      (this.selectedMessagedUser.user.id == message.fromId &&
        this.selectedMessagedUser.selected &&
        message.topic == "") ||
      (this.selectedMessagedGroup.groupId == message.groupId &&
        this.selectedMessagedGroup.selected &&
        message.topic == "") ||
      (this.geniChat.invoiceId.toString() == message.invoiceId && this.geniChat.selected)
    ) {
      this.messages = [...this.messages, message];
      await ChatApi.readMessage(message.id);
      return true;
    } else if (message.toId != 1 && message.groupId == "" && message.topic == "") {
      const index = this.messagedUsers.findIndex((x) => x.user.id == message.fromId);
      if (index > -1) {
        this.messagedUsers[index].unreadMessageCount++;
      } else {
        const newUser: User = { ...userDefaultProps, id: message.fromId, name: message.fromName };
        const newUserR: MessagedUserResponse = {
          ...messagedUserResponseDefaultProps,
          user: newUser,
          unreadMessageCount: 1,
        };
        this.messagedUsers = [newUserR, ...this.messagedUsers];
      }
      return false;
    }
    return false;
  }
}

const store = new ChatStore();
export default store;
