import eventBus from "../services/eventBus";

export default function busyIndication() {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const func = descriptor.value
        descriptor.value = async function (...args: any) {
            eventBus.$emit('busy-message', true)

            try {
                const result = await func?.call(this, ...args)
                return result
            } catch (error) {
                console.log(error);
            }
            finally {
                eventBus.$emit('busy-message', false)
            }
        }
    }
}

export const withBusyIndication = (func: (...args: any) => Promise<any>) => (...args: any) => {
    eventBus.$emit('busy-message', true)
    return func(...args)
        .catch(() => eventBus.$emit('busy-message', false))
        .then(result => {
            eventBus.$emit('busy-message', false)
            return result
        })
        .finally(() => eventBus.$emit('busy-message', false))
}