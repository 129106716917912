

























import Vue from 'vue';
import store, { KanriInvoiceStore } from "../services/KanriInvoiceStore";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

type KanriItem = any;
type KanriInvoice = any;

interface Data {
    store: KanriInvoiceStore;
    kanriItems: KanriItem[];
}

export default Vue.extend<Data, {}, {}, {}>({
    components: {
        MoneyDisplay,
    },

    props: {
        head: { type: Object as () => KanriInvoice }
    },

    data() {
        return {
            store,
            kanriItems: [],
        }
    },

    async beforeMount() {
        this.kanriItems = await this.store.getItemsByHead(Number(this.$props.head.id));
    },

    computed: {
        columns: () => [
            {
                field: "id",
                title: "Azonosító"
            },
            {
                field: "itemName",
                title: "Név"
            },
            // {
            //     field: "positionNo",
            //     title: "Pozíció"
            // },
            {
                field: "positionType",
                title: "Típus"
            },
            {
                field: "docNo",
                title: "Dokumentum"
            },
            {
                field: "vin",
                title: "Alvázszám"
            },
            // {
            //     field: "amount",
            //     title: "Totál?"
            // },
            {
                field: "netto",
                title: "Nettó",
                cell: "nettoTemplate",
                headerClassName: "right_text"
            },
            {
                field: "vat",
                title: "Áfa",
                cell: "vatTemplate",
                headerClassName: "right_text"
            },
            {
                field: "total",
                title: "Bruttó",
                cell: "totalTemplate",
                headerClassName: "right_text"
            },
        ]
    },
})
