import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import Bank from "@/modules/bank/models/Bank";
import DetailsOfCharges from "../models/DetailsOfCharges";
import BankCode from "../models/BankCode";

export default class BankApi {
  static getAll(): Promise<Bank[]> {
    return http.get<ApiResponse<Bank[]>>(`bank`).then(handleApiResponse);
  }

  static getBankCodes(): Promise<BankCode[]> {
    return http.get<ApiResponse<BankCode[]>>(`bank/codes`).then(handleApiResponse);
  }

  static get(bankId: number): Promise<Bank> {
    return http.get<ApiResponse<Bank>>(`bank/${bankId}`).then(handleApiResponse);
  }

  static create(bank: Bank): Promise<Bank> {
    return http.post<ApiResponse<Bank>>(`bank`, bank).then(handleApiResponse);
  }

  static update(bank: Bank): Promise<boolean> {
    return http.put(`bank/${bank.id}`, bank).then(checkResponse);
  }

  static delete(bankId: number): Promise<boolean> {
    return http.delete(`bank/${bankId}`).then(checkResponse);
  }

  static getDetailsOfCharges(): Promise<DetailsOfCharges[]> {
    return http.get<ApiResponse<DetailsOfCharges[]>>(`bank/details-of-charges`).then(handleApiResponse);
  }
}
