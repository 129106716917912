export default class DocumentStatement {
    id= 0
    relatedItemID= 1
    hasFile= false
    fileName= ""
    documentTypeId= 1
    documentType= ""
    link= ""
    uploading= false
}

export interface GetDocumentStatement {
    statementId: 0;
    docType: 0;
}