import { DownPayment, PreDownPayment, DownPaymentExtOrder } from "./../models/index";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import dayjs from "dayjs";
import { withBusyIndication } from "@/utils/busyIndication";
import { OtherCostShareOption, OtherItem, ValueDivisionMode } from "@/modules/invoiceItems/models";
import SelectableParts from "@/modules/generalIngoingInvoice/models/IngoingInvoiceSelectableItem";
import AcceptRequest from "../../invoiceFlow/models/InvoiceAcceptState"
import { InvoiceAcceptRequest } from "@/modules/invoiceFlow/models/InvoiceRequest";
import User from "@/modules/user/models/User";
import TaxAndVatItem from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";

// Előleg értesítő előjegyzés
export const getAllPreByPartner = (partnerID: number, searchText: string, currency: string): Promise<PreDownPayment[]> =>
  http
    .get<ApiResponse<PreDownPayment[]>>(`pre-down-payment/partner`, {
      params: {
        partnerID: partnerID,
        searchText: searchText,
        currency: currency,
      },
    })
    .then(handleApiResponse);

//régi 1 előjegyzés
export const getPreByInvoice = (partnerID: number, invoiceID: number): Promise<PreDownPayment> =>
  http
    .get<ApiResponse<PreDownPayment>>(`pre-down-payment/partner/${partnerID}/invoice/${invoiceID}`)
    .then(handleApiResponse);

//új több előjegyzés
export const loadExtOrdersData = (partnerID: number, invoiceID: number): Promise<PreDownPayment[]> =>
  http
    .get<ApiResponse<PreDownPayment[]>>(`pre-down-payment/down-payments/partner/${partnerID}/invoice/${invoiceID}`)
    .then(handleApiResponse);

// Megrendelések
export const getExtOrders = (partnerID: number, searchText: string): Promise<DownPaymentExtOrder[]> =>
  http
    .get<ApiResponse<DownPaymentExtOrder[]>>("pre-down-payment/ext-orders", {
      params: {
        partnerID: partnerID,
        searchText: searchText,
      },
    })
    .then(handleApiResponse);

export const getExtOrderData = (preDownPaymendID: number): Promise<DownPaymentExtOrder> =>
  http.get<ApiResponse<DownPaymentExtOrder>>(`pre-down-payment/ext-order/${preDownPaymendID}`).then(handleApiResponse);

// Előlegbekérő
export const get = (invoiceID: number): Promise<DownPayment> =>
  http
    .get<ApiResponse<DownPayment>>(`down-payment/invoice/${invoiceID}`)
    .then(handleApiResponse)
    .then((data) => ({
      ...data,
      invoiceDate: dayjs(data.invoiceDate).format("YYYY-MM-DD"),
      payTerm: dayjs(data.payTerm).format("YYYY-MM-DD"),
    }));

export const getAll = (): Promise<DownPayment[]> =>
  http.get<ApiResponse<DownPayment[]>>(`down-payment`).then(handleApiResponse);


export const create = (dto: DownPayment, preDTO: PreDownPayment[] | null): Promise<number> =>
  http
    .post<ApiResponse<number>>(`down-payment`, { DTO: dto, preDTO: preDTO })
    .then(handleApiResponse);

export const update = (dto: DownPayment, preDTO: PreDownPayment[]): Promise<number> =>
  http
    .put<ApiResponse<number>>(`down-payment`, { DTO: dto, preDTO: preDTO })
    .then(handleApiResponse);

export const deletePreDownpayment = (pdpId: number): Promise<boolean> =>
  http
    .delete<ApiResponse<boolean>>(`down-payment/${pdpId}`)
    .then(checkResponse)

export const generatePayment = (id: number): Promise<boolean> =>
  http.post<ApiResponse<boolean>>(`down-payment/payment/${id}`).then(handleApiResponse);

export const setIncorrect = (id: number, deletePreDownPayment: boolean): Promise<boolean> =>
  http
    .put<ApiResponse<boolean>>(`down-payment/incorrect/${id}`, { deletePreDownPayment: deletePreDownPayment })
    .then(handleApiResponse);

export const setApproved = (id: number): Promise<boolean> =>
  http.put<ApiResponse<boolean>>(`down-payment/approve/${id}`).then(handleApiResponse);

// Szolgáltatások
export const getServices = withBusyIndication(
  (searchText: string): Promise<OtherItem[]> =>
    http
      .get<ApiResponse<SelectableParts[]>>(`down-payment/services/search`, { params: { q: searchText } })
      .then(handleApiResponse)
      .then((data) =>
        data.map((i) => {
          const item: OtherItem = {
            stockRelated: false,
            itemType: i.partsType,
            category: i.partGroup,
            code: i.code,
            productID: i.partId ?? 1,
            serviceID: i.serviceID ?? 1,
            gpartID: i.gPartId ?? 1,
            itemName: i.name,
            quantity: 1.0,
            unit: i.unitTypeName ?? "",
            unitPrice: i.unitPrice,
            relations: [],
            vatID: i.vatID,
            costShareOption: OtherCostShareOption.ToCurrentInvoice,
            hasUnitPrice: i.unitPrice > 0,
            increasesStockPrice: false,
            originalIncreasesStockPrice: false,
            valueDivisionMode: ValueDivisionMode.ByItemRate,
            orderNumber: "",
            isRegTax: false,
            inVatCount: true,
            isPostCalc: false,
            postCalcType: 1,
            isCarRectItem: false,
            isInclCarPrice: false,
            isService: false,
          };

          return item;
        })
      )
);

export const getAllTaxAndVatItem = (): Promise<TaxAndVatItem[]> =>
  http
    .get<ApiResponse<TaxAndVatItem[]>>(`tax/taxAndVatItems`)
    .then(handleApiResponse);

//Jóváhagyás
export const getDownPaymentAcceptRequests = (invoiceID: number,): Promise<AcceptRequest[]> =>
  http
    .get<ApiResponse<AcceptRequest[]>>(`down-payment/approval-requests/${invoiceID}`, {
    })
    .then(handleApiResponse);

export const getAcceptRequests = (invoiceID: number,): Promise<InvoiceAcceptRequest[]> =>
  http
    .get<ApiResponse<InvoiceAcceptRequest[]>>(`invoice-flow/requests/accept/${invoiceID}`, {
    })
    .then(handleApiResponse);

export const updateAcceptRequests = (acceptorRequests: InvoiceAcceptRequest[], invId: number): Promise<boolean> =>
  http
    .put<ApiResponse<boolean>>(`down-payment/request/update`, { acceptorRequests: acceptorRequests, invId: invId, })
    .then(checkResponse)

export const getDownPaymentAcceptorsList = (downPaymentId: number,): Promise<User[]> =>
  http
    .get<ApiResponse<User[]>>(`down-payment/approvals-list/${downPaymentId}`)
    .then(handleApiResponse);

export const addDownPaymentAcceptor = (invId: number): Promise<boolean> =>
  http.post<ApiResponse<boolean>>(`down-payment/request/accept/create/${invId}`).then(handleApiResponse);

export const approveDownPayment = (invId: number): Promise<boolean> =>
  http.post<ApiResponse<boolean>>(`down-payment/request/accept/update/${invId}`).then(handleApiResponse);