import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import { DimCodeType } from '@/modules/generalLedger/models/TypesForEdit'

export default class DimCodeTypeApi {
    static async GetAllDimCodeTypes(): Promise<DimCodeType[]>{
        return http
        .get<ApiResponse<DimCodeType[]>>(`generalledger/dimensioncode/dimcodetypes/all`)
        .then(handleApiResponse)
    }

    static update(dimCodeType: DimCodeType): Promise<boolean> {
        return http
            .put(`generalledger/dimensioncode/dimcodetypes/all/${dimCodeType.id}`, dimCodeType)
            .then(checkResponse)
    }
}