import http, { handleApiResponse, checkResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import { TechnicalInvoiceVIRO, TechnicalInvoiceVIRI } from "@/modules/technicalInvoice/models/TechnicalInvoiceModel";
import TechnicalInvoicePmode from "@/modules/technicalInvoice/models/TechnicalInvoicePmodeModel";
import TechnicalInvoiceStatus from "@/modules/technicalInvoice/models/TechnicalInvoiceStatusModel";
import TechnicalInvoiceAccGroup from "@/modules/technicalInvoice/models/TechnicalInvoiceAccGroupModel";
import TechnicalInvoiceAccStatus from "@/modules/technicalInvoice/models/TechnicalInvoiceAccStatusModel";
import TechnicalInvoicePartnerRel from "@/modules/technicalInvoice/models/TechnicalInvoicePartnerRelModel";
import TechnicalInvoiceDivitem from "@/modules/technicalInvoice/models/TechnicalInvoiceDivitemModel";
import TechnicalInvoiceCurrency from "@/modules/technicalInvoice/models/TechnicalInvoiceCurrency";
import { TechnicalInvoiceVIRIType } from "@/modules/technicalInvoice/models/TechnicalInvoiceTypeModel";
import TechnicalInvoiceCreatorUser from "@/modules/technicalInvoice/models/TechnicalInvoiceCreatorUserModel";
import Partner from "@/modules/partner/models/Partner";
import { IdNameDto } from "@/modules/letterOfCredit/letterOfCredit";
import { TechnicalInvoiceItemVIRI } from "@/modules/technicalInvoice/models/TechnicalInvoiceItemModel";
import { TechnicalInvoiceItemInfo } from "@/modules/technicalInvoice/models/TechnicalInvoiceItemInfoModel";

export default class TechnicalInvoiceApi {
  static async getVIRIPdf(invoiceId: number) {
    return await http
      .get<ApiResponse<string>>(`invoice-browser/technical-invoice/viri/pdf/${invoiceId}`)
      .then(handleApiResponse);
  }

  static async createViroAndCashDoc(tInvoiceId: number, statement: number) {
    return await http
      .post<ApiResponse<number>>(`invoice-browser/technical-invoice/viri/create/viro-and-cash-doc`, { id:tInvoiceId, statement: statement })
      .then(handleApiResponse);
  }

  static async createVIROfromVIRI(tInvoiceId: number) {
    return await http
      .post<ApiResponse<number>>(`invoice-browser/technical-invoice/viro/create/fromviri`, { id:tInvoiceId })
      .then(handleApiResponse);
  }

  static async create(tInvoice: TechnicalInvoiceVIRI) {
    return await http
      .post<ApiResponse<number>>(`invoice-browser/technical-invoice/viri/create`, { inv: tInvoice })
      .then(handleApiResponse);
  }

  static async getNewVIRI() {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIRI>>(`invoice-browser/technical-invoice/viri/new`)
      .then(handleApiResponse);
  }

  static async getViriItems(viriId: number): Promise<TechnicalInvoiceItemVIRI[]> {
    return await http
      .get<ApiResponse<TechnicalInvoiceItemVIRI[]>>(`invoice-browser/technical-invoice/viri/items/${viriId}`)
      .then(handleApiResponse);
  }

  static async removeItem(tInvoiceItemId: number) {
    return await http
      .post<ApiResponse<number>>(`invoice-browser/technical-invoice/viri/items/delete`, { id: tInvoiceItemId })
      .then(checkResponse);
  }

  static async createItem(tInvoiceItem: TechnicalInvoiceItemVIRI) {
    return await http
      .post<ApiResponse<number>>(`invoice-browser/technical-invoice/viri/items/create`, tInvoiceItem)
      .then(handleApiResponse);
  }

  static async getViriInvoice(invoiceId: number): Promise<TechnicalInvoiceVIRI> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIRI>>(`invoice-browser/technical-invoice/viri/${invoiceId}`)
      .then(handleApiResponse);
  }

  static async getVirCheckTypes(): Promise<IdNameDto[]> {
    return await http
      .get<ApiResponse<IdNameDto[]>>(`invoice-browser/technical-invoice/viri/checktypes`)
      .then(handleApiResponse);
  }

  static async getViriCheckPartner(): Promise<number> {
    return await http
      .get<ApiResponse<number>>(`invoice-browser/technical-invoice/viri/checkpartner`)
      .then(handleApiResponse);
  }

  static async getPartner(partnerId: number): Promise<Partner> {
    return await http.get<ApiResponse<Partner>>(`partners/${partnerId}`).then(handleApiResponse);
  }

  static async getPmodes(): Promise<TechnicalInvoicePmode[]> {
    return await http.get<ApiResponse<TechnicalInvoicePmode[]>>(`payment-mode`).then(handleApiResponse);
  }

  static async getStatuses(): Promise<TechnicalInvoiceStatus[]> {
    return await http.get<ApiResponse<TechnicalInvoiceStatus[]>>(`invoice-status`).then(handleApiResponse);
  }

  static async getAccGroups(): Promise<TechnicalInvoiceAccGroup[]> {
    return await http.get<ApiResponse<TechnicalInvoiceAccGroup[]>>(`invoice-group`).then(handleApiResponse);
  }

  static async getAccStatuses(): Promise<TechnicalInvoiceAccStatus[]> {
    return await http.get<ApiResponse<TechnicalInvoiceAccStatus[]>>(`account-status`).then(handleApiResponse);
  }

  static async getPartnerRels(): Promise<TechnicalInvoicePartnerRel[]> {
    return await http
      .get<ApiResponse<TechnicalInvoicePartnerRel[]>>(`partner-relation/type/string`)
      .then(handleApiResponse);
  }

  static async getDivitems(): Promise<TechnicalInvoiceDivitem[]> {
    return await http.get<ApiResponse<TechnicalInvoiceDivitem[]>>(`division-item`).then(handleApiResponse);
  }

  static async getCurrencies(): Promise<TechnicalInvoiceCurrency[]> {
    return await http.get<ApiResponse<TechnicalInvoiceCurrency[]>>(`currency`).then(handleApiResponse);
  }

  static async getVIRIInvoiceTypes(): Promise<TechnicalInvoiceVIRIType[]> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIRIType[]>>(`techincal-invoice-type/viri`)
      .then(handleApiResponse);
  }

  static async getCreatorUser(userId: number): Promise<TechnicalInvoiceCreatorUser> {
    return await http.get<ApiResponse<TechnicalInvoiceCreatorUser>>(`users/${userId}`).then(handleApiResponse);
  }

  static async getOpenerVIRIInvoiceTypes(): Promise<TechnicalInvoiceVIRIType[]> {
    return await http
      .get<ApiResponse<TechnicalInvoiceVIRIType[]>>("techincal-invoice-type/opener/viro")
      .then(handleApiResponse);
  }
  
  static async getViriItemsInfo(viriId: number): Promise<TechnicalInvoiceItemInfo[]> {
    return await http
      .get<ApiResponse<TechnicalInvoiceItemInfo[]>>(`invoice-browser/technical-invoice/viri/items/info/${viriId}`)
      .then(handleApiResponse);
  }
}
