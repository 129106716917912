export default class CurrentAccount {
  debt = 0;
  expiredDebt = 0;
  overpayedDebt = 0;

  claim = 0;
  expiredClaim = 0;
  overpayedClaim = 0;

  totalDifference = 0;
  absoluteDifference = 0;
}

export function substract(a: CurrentAccount, b: CurrentAccount)
{
  a.debt -= b.debt;
  a.claim -= b.claim;
  console.log("claim:" + b.claim);
  a.expiredDebt -= b.expiredDebt;
  a.expiredClaim -= b.expiredClaim;
  a.overpayedDebt -= b.overpayedDebt;
  a.overpayedClaim -= b.overpayedClaim;

  //public decimal TotalDifference => Debt + OverpayedDebt - Claim - OverpayedClaim;
  a.totalDifference = a.debt + a.overpayedDebt - a.claim - a.overpayedClaim;
  a.absoluteDifference = Math.abs(a.totalDifference);

  console.log(a);
}
