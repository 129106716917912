













































































import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import BankRemitApi from "../services/BankRemitApi";
import BankRemitAnalitics, { bankRemitAnaliticsDefaultProps } from "../models/BankRemitAnalitics";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";

interface Data {
  store: BankRemitStore;
  data: BankRemitAnalitics;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    MoneyDisplay,
  },

  data() {
    return {
      store,
      data: { ...bankRemitAnaliticsDefaultProps },
    };
  },

  async beforeMount() {
    if (Number(this.$route.params.id) != this.data.id) {
      await BankRemitApi.loadAnalitics(Number(this.$route.params.id)).then((data) => (this.data = data));
    }
  },
});
