import Login, { loginDefaultProps } from "../models/Login"
import LoginApi from "./LoginApi"
import LoginResult, { loginResultDefaultProps } from "../models/LoginResult"
import busyIndication from "../../../utils/busyIndication";

export class LoginStore {
    login: Login = { ...loginDefaultProps }
    loginResult: LoginResult = { ...loginResultDefaultProps }

    @busyIndication()
    async doLogin() {
        this.loginResult = await LoginApi.login(this.login.name, this.login.password);
    }

}

const store = new LoginStore()
export default store