
import Vue from "vue";
import setAttribute from "@/utils/dom";

export default Vue.extend({
  props: {
    cols: {
      type: Number,
      default: 0,
    },

    className: {
      type: String,
      default: ".k-detail-cell",
    },
  },

  render(h: Function) {
    return h();
  },

  mounted() {
    setAttribute(this.className, "colspan", this.cols.toString());
  },
});
