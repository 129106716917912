




























































































































































































































































































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import ConfirmationBox from "@/components/ConfirmationBox.vue";

import Vue from "vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import BankOutView from "@/modules/bankOutView/models/BankOutView";
import { FilteringModel } from "@/services/filtering";
import CheckBox from "@/components/CheckBox.vue";
import cashStatementStore from "@/modules/cashStatement/services/store";
import eventBus from "@/services/eventBus";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import store, { BankStatementStore } from "@/modules/bankStatement/services/BankStatementStore";
import MoneyInput from "@/components/MoneyInput.vue";

interface Data {
  filter: FilteringModel;
  sum: number;
  currSum: number;
  items: BankOutView[];
  selectedItems: BankOutView[];
  store: BankStatementStore;
  isDiffCurrency: boolean;
}

interface Props {
  extraFilter: FilteringModel;
  partnerId: number;
  bankStatemnt: number;
  currency: string;
  inCashPoint: boolean;
  isOverpay: boolean;
  title: string;
  isAmountChangeVisible: boolean;
  isEurBank: boolean;
}

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
}

interface Methods {
  selected(item: BankOutView): void;
  showDetails(item: BankOutView): void;
  addToSummary(dataItem: BankOutView): void;
  setItems(items: BankOutView[]): void;
  addItems(): void;
  generatePaymentItems(isOverride: boolean): void;
  amountChanged(item: BankOutView): void;
  currAmountChanged(item: BankOutView): void;
  statementAmountChanged(item: BankOutView): void;
  setSummary(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ApiSearchPage, CheckBox, ValidationError, MoneyInput, ConfirmationBox },

  props: {
    extraFilter: { type: Object as () => FilteringModel },
    partnerId: { type: Number, default: 1 },
    currency: { type: String, default: "" },
    bankStatemnt: { type: Number, default: 1 },
    inCashPoint: { type: Boolean, default: false },
    isOverpay: { type: Boolean, default: false },
    title: { type: String, default: "Számlaválogató" },
    isAmountChangeVisible: { type: Boolean, default: false },
    isEurBank: { type: Boolean, default: false },
  },

  data: () => ({
    filter: new FilteringModel(),
    sum: 0,
    currSum: 0,
    items: [],
    selectedItems: [],
    store,
    isDiffCurrency: false
  }),

  beforeMount() {
    this.filter = new FilteringModel();
    if (this.$props.extraFilter)
      this.filter = this.filter.fromFilter(this.$props.extraFilter.toObject());
  },

  watch: {
    isDiffCurrency(){
      (this.$refs.apiSearch as any).refreshClick();
    }
  },

  methods: {
    async addItems() {
      await cashStatementStore.loadCashDocItemsByDocumentId(
        cashStatementStore.cashDocument.id
      );

      this.$emit("add-selected-items", this.selectedItems);
    },

    selected(item: BankOutView) {
      this.$emit("selected", item);
    },

    showDetails(item: BankOutView) {
      item.selected = !item.selected;
    },

    addToSummary(dataItem: BankOutView) {
      eventBus.$emit("bank-out-view:get-items");
      if (!dataItem.added) {
        dataItem.added = true;
      } else {
        dataItem.added = false;
        this.selectedItems = this.selectedItems.filter(
          (x) => x.invNo !== dataItem.invNo
        );
      }

      this.setSummary();
    },

    setSummary(){
      let sum = 0;
      let currSum = 0;

      this.items.forEach((x: BankOutView) => {
        if (x.added){
          sum += Math.abs(x.total);
          currSum += Math.abs(x.statementTotal);
        }
      });

      if (this.store.bankStatement.currency != "HUF"){
        eventBus.$emit("update-curr-sum", currSum);
      } else {
        eventBus.$emit("update-sum", sum);
      }
    },

    generatePaymentItems(isOverride: boolean) {
      this.$emit("generate-payments", {
        selectedItems: this.selectedItems,
        partnerID: this.$props.partnerId,
        isOverrideAmount: isOverride
      });

      this.selectedItems.forEach(x => x.added = false);
      this.selectedItems = [];
    },

    setItems(items) {
      this.items = items;

      this.items.forEach(x => {
        if (this.isEurBank){
          const currency = store.bankDocument.bankStatementItem.invCurrency;
          if (x.invCurrency != currency) {
            x.statementTotal = Math.round(x.currTotal * x.exchangeRate / store.bankDocument.bankStatementItem.exchangeRate * 100) / 100;
          } else {
            x.statementTotal = x.currTotal;
          }
        } else {
          x.statementTotal = x.total;
        }
      });

      const newSelected = items.filter((x) => x.added);
      newSelected.forEach((element) => {
        if (!this.selectedItems.includes(element)) {
          this.selectedItems.push(element);
        }
      });
    },

    amountChanged(item){
      item.currTotal = Math.round(item.total / item.exchangeRate * 100) / 100;

      if (this.isEurBank) {
        const currency = store.bankDocument.bankStatementItem.invCurrency;
        if (currency != item.invCurrency) {
          item.statementTotal = Math.round(item.currTotal * item.exchangeRate / store.bankDocument.bankStatementItem.exchangeRate * 100) / 100;
          } else {
            item.statementTotal = item.currTotal;
          }
        } else {
          item.statementTotal = item.total;
      }

      this.setSummary();
    },

    currAmountChanged(item){
      item.total = Math.round(item.currTotal * item.exchangeRate * 100) / 100;

      if (this.isEurBank) {
        const currency = store.bankDocument.bankStatementItem.invCurrency;
        if (currency != item.invCurrency) {
          item.statementTotal = Math.round(item.currTotal * item.exchangeRate / store.bankDocument.bankStatementItem.exchangeRate * 100) / 100;
          } else {
            item.statementTotal = item.currTotal;
          }
        } else {
          item.statementTotal = item.total;
      }

      this.setSummary();
    },

    statementAmountChanged(item) {
      if (this.isEurBank) {
        const currency = store.bankDocument.bankStatementItem.invCurrency;
        if (item.invCurrency != currency) {
          item.currTotal = Math.round(item.statementTotal * store.bankDocument.bankStatementItem.exchangeRate / item.exchangeRate * 100) / 100;
          item.total = item.currTotal * item.exchangeRate;
        } else {
          item.currTotal = item.statementTotal;
          item.total = item.currTotal * item.exchangeRate;
        }
      } else {
        item.currTotal = item.statementTotal;
        item.total = item.currTotal;
      }

      this.setSummary();
    }
  },

  computed: {
    columns: function() {
      return [
            {
              cell: "statementDetailsButtonTemplate",
              filterable: false,
            },
            //{ field: "code2", title: "Prefix" },
            /*{ field: "invNo", title: "Számlaszám" },*/
            { field: "k2DInvNo", title: "Számlaszám" },
            {
              cell: "total",
              field: "total",
              title: "Összeg",
              type: ColumnType.Money,
            },
            {
              cell: "currTotal",
              field: "currTotal",
              title: "Összeg (dev)",
              type: ColumnType.Money,
            },
            {
              cell: "statementTotal",
              field: "statementTotal",
              title: "Összeg (bank)",
              type: ColumnType.Money,
            },
            {
              cell: "toPayment",
              field: "toPayment",
              title: "Szla. nyitott rész",
              type: ColumnType.Money,
            },
            {
              cell: "currToPayment",
              field: "currToPayment",
              title: "Szla. nyitott rész (dev)",
              type: ColumnType.Money,
            },
            { field: "invCurrency", title: "Pénznem" },
            { field: "remark1", title: "Megjegyzés" },
            {
              cell: "selectButton",
              filterable: false,
            },
          ];
    },

    url() {
      return `bankout/dto/search/${this.$props.isOverpay}/${this.$props.partnerId}/${this.$props.currency}/${this.$props.bankStatement}/${this.isDiffCurrency}`;
    },
  },
});
