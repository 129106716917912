export default interface Country {
    id: number;
    name: string;
    currency: string;
}

export const countryDefaultProps: Country = {
    id: 0,
    name: '',
    currency: '',
}