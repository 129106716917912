import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import TransactionDTO from "../models/TransactionDTO";
import Country from "@/modules/cashPoint/models/Country";

export default class TransactionApi {
  static get(id: number): Promise<TransactionDTO> {
    return http.get<ApiResponse<TransactionDTO>>(`ledger/transactions/${id}`).then(handleApiResponse);
  }

  static getTransStats(): Promise<any[]> {
    return http.get<ApiResponse<any[]>>("ledger/detail/trans-stats").then(handleApiResponse);
  }

  static getTransTypes(): Promise<any[]> {
    return http.get<ApiResponse<any[]>>("ledger/detail/trans-types").then(handleApiResponse);
  }

  static getCurrencies(): Promise<Country[]> {
    return http.get<ApiResponse<Country[]>>("currency").then(handleApiResponse);
  }

  static getPeriods(year: string): Promise<any[]> {
    return http.get<ApiResponse<any[]>>(`ledger/head/periods/${Number(year.split("-")[0])}`).then(handleApiResponse);
  }

  static getBookTypes(): Promise<any[]> {
    return http.get<ApiResponse<any[]>>("ledger/detail/book-types").then(handleApiResponse);
  }

  static save(id: number, head: TransactionDTO): Promise<number> {
    return http.put<ApiResponse<number>>(`ledger/transactions/edit/${id}`, { head }).then(handleApiResponse);
  }
}