export default interface BankStatementPict {
    id: number;
    relatedItemID: number;
    hasFile: boolean;
    fileName: string;
    documentTypeId: number;
    documentType: string;
    link: string;

    uploading: boolean;
}

export const bankStatementPictDefaultProps: BankStatementPict = {
    id: 0,
    relatedItemID: 1,
    hasFile: false,
    fileName: "",
    documentTypeId: 17,
    documentType: "",
    link: "",

    uploading: false,
}