








































import Vue from "vue";

import GeneralIngoingInvoice from "../models/GeneralIngoinInvoice";
import api from "../services/GeneralIngoingInvoiceApi";

import MoneyDisplay from "../../../components/MoneyDisplay.vue";

import { InvoicePayment } from "../models/InvoicePayment";

interface Data {
  payments: InvoicePayment[];
}

interface Computed {
  hasData: boolean;
}

interface Props {
  invoice: GeneralIngoingInvoice;
}

export default Vue.extend<Data, {}, Computed, Props>({
  components: {
    MoneyDisplay,
  },

  data: () => ({
    payments: [],
    paymentInput: null,
    viewState: "view",
  }),

  props: {
    invoice: {
      type: Object as () => GeneralIngoingInvoice,
    },
  },

  computed: {
    hasData() {
      return this.payments.length > 0;
    },
  },

  beforeMount() {
    api.getInvoicePayments(this.invoice.id).then(({ payments }) => {
      this.payments = payments;
    });
  },
});
