














































































































































































































































































































import Vue from "vue";
import store, { PartnerStore } from "../../partner/services/PartnerStore";
import oStore, { OverSummaryStore } from "../services/OverSummaryStore";
import CheckBox from "@/components/CheckBox.vue";
import configuration, { AppConfiguration } from "../../../models/Configuration";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import OverSummary from "../models/OverSummary";
import { formatDate } from "../../../utils/formatDates";
import eventBus from "@/services/eventBus";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import Partner from "@/modules/partner/models/Partner";
import dayjs from "dayjs";

interface Data {
    store: PartnerStore;
    oStore: OverSummaryStore;
    selectedOvers: OverSummary[];
    configuration: AppConfiguration;
    date: string;
    partnerId: number;
    inProgress: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
    components: {
        CheckBox,
        MoneyDisplay,
        PartnerSearch
    },

    data() {
        return {
            store,
            oStore,
            selectedOvers: [],
            configuration,
            date: "",
            partnerId: 1,
            inProgress: false,
        }
    },

    mounted() {
        this.date = dayjs(new Date()).format("YYYY-MM-DD");
    },

    destroyed() {
        this.oStore.overSummaries = [];
    },

    methods: {
        async partnerSelected(partner: Partner){
            this.partnerId = partner.id;
            setTimeout(() => {
                console.log("-");
                
            }, 1);

            await this.store.loadPartner(this.partnerId);
            await this.oStore.getSummariesByPartner(this.partnerId);
        },

        cancel(){
            this.$router.push('/over-summary');
        },

        async save(){
            console.log(this.selectedOvers);
            
            if (this.selectedOvers.length == 0) {
                eventBus.$emit("error", "Mindkét oldalon válasszon ki legalább egy tételt!");
                return;
            }

            if (this.selectedOvers.find((x) => x.crediting) == null || this.selectedOvers.find((x) => !x.crediting) == null) {
                eventBus.$emit("error", "Mindkét oldalon válasszon ki legalább egy tételt!!");
                return;
            }

            if (new Date(this.date) > new Date()) {
                eventBus.$emit("error", "Nem adhat meg jövőbeli dátumot!");
                return;
            }

            const found = this.selectedOvers.some((x) => new Date(x.accDate) > new Date(this.date));
            if (found) {
                eventBus.$emit("error", "Minden kiválasztott fizetési tétel keletkezésének könyvelés dátumánál későbbi könyvelés dátumot kell megadnia!");
                return;
            }

            this.inProgress = true;
            const ret = await this.oStore.createOverSummary(this.selectedOvers, this.date.toString());
            if (ret) {
                eventBus.$emit("success", "Sikeres összevezetés!");
                this.selectedOvers = [];
                await this.oStore.getSummariesByPartner(this.partnerId);
            }
            else {
                eventBus.$emit("error", "Hiba!");
            }
            this.inProgress = false;
            //this.$router.push('/over-summary');
        },

        selectItem(item: OverSummary){
            if (item.isSelected) {
                this.selectedOvers = this.selectedOvers.filter((x) => x.id != item.id)
            }
            else {
                this.selectedOvers.push(item);
            }
            item.isSelected = !item.isSelected;
        },

        redirectToInvoice(invoiceId: number){
            if (configuration.invoiceFlow) {
                this.$router.push(`/general-incoming-invoice/flow/edit/${invoiceId}/6`);
            }
            else {
                this.$router.push(`/general-incoming-invoice/edit/${invoiceId}`);
            }
        },

        showDetails(item: OverSummary){
            item.detailsVisible = !item.detailsVisible;
        },

        formatDate(date: string) {
            return formatDate(date);
        },
    },

    computed: {
        columns: () => [
            { width: "50", filterable: false, cell: "selectionTemplate" },
            { width: "50", filterable: false, cell: "showDetailsTemplate" },
            { field: "code", title: "Prefix", },
            { field: "invoice", title: "Számlaszám", width: "100" },
            { field: "accountItems", title: "Bankkivonat tétel" },
            { field: "cashDocument", title: "Pénztári bizonylat tétel" },
            { field: "itemNo", title: "Kivonat" },
            { field: "amount", title: "Összeg", cell: "amountTemplate", headerClassName: "right_text", width: "120" },
            { field: "accStatusName", title: "Könyvelési státusz" },
            { field: "pdate", title: "Túlfizetés időpontja", type: "date", format: "{0: yyyy. MM. dd.}", },
            { width: "0" },
        ],

        creditingItems() {
            return this.oStore.overSummaries.filter((x) => x.crediting);
        },

        items() {
            return this.oStore.overSummaries.filter((x) => !x.crediting);
        }
    }
})
