import configuration from "./Configuration"
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay"
import VueRouter from "vue-router";

export interface Links {
    router?: VueRouter;
    partnerProfile(invoice: GeneralIngoinInvoiceDisplay): void;
}


const links: Links = {
    partnerProfile(invoice: GeneralIngoinInvoiceDisplay) {
        links.router?.push(`/partners/${invoice.incomingInvoice.partner}`)
    }
}

const operelLinks: Links = {
    partnerProfile(invoice: GeneralIngoinInvoiceDisplay) {
        window.location.href = `#crm/accounts/${invoice.partnerCID}`
    }
}

export default {
    activate: configuration.operel
        ? operelLinks
        : links
}

