import http, { handleApiResponse, checkResponse } from '@/services/http'
import Tax from "@/modules/taxes/models/Tax"
import ApiResponse from '@/models/ApiResponse'
import {TaxType} from "@/modules/taxes/models/Tax"
import VatItem from '../models/VatItem'

export default class TaxApi {
    static getAll(): Promise<Tax[]> {
        return http
            .get<ApiResponse<Tax[]>>(`tax/everyTax`)
            .then(handleApiResponse)
    }
    static getAllTaxType(): Promise<TaxType[]> {
        return http
            .get<ApiResponse<TaxType[]>>(`tax/taxtypes`)
            .then(handleApiResponse)
    }
    static getVatItems(taxId: number): Promise<VatItem[]> {
        return http
            .get<ApiResponse<VatItem[]>>(`tax/vatItems/${taxId}`)
            .then(handleApiResponse)
    }

    static get(taxId: number): Promise<Tax> {
        return http
            .get<ApiResponse<Tax>>(`tax/get/${taxId}`)
            .then(handleApiResponse)
    }


    static update(tax: Tax): Promise<boolean> {
        return http
            .put(`tax/${tax.id}`, tax)
            .then(checkResponse)
    }

    static delete(taxId: number): Promise<boolean> {
        return http
            .delete(`tax/${taxId}`)
            .then(checkResponse)
    }
    static deleteVatItem(vatItemId: number): Promise<boolean> {
        return http
            .delete(`tax/deleteVat/${vatItemId}`)
            .then(checkResponse)
    }

    static setVatItem(item: VatItem, taxId: number): Promise<VatItem> {
        return http
            .post<ApiResponse<VatItem>>(`tax/vatItem/${taxId}`,item)
            .then(handleApiResponse)
    }
}