import dayjs, { Dayjs } from "dayjs";

export interface IncomingInvoiceTimelineItem {
  timestamp: Dayjs;
  title: string;
  subtitle: string;
  description: string | IncomingInvoiceTimelineDescriptionItem[] | undefined;
  // type: number;
  icon: string;
  color: string;
}

export class IncomingInvoiceTimelineDescriptionItem {
  label = "";
  value = "";
  constructor(params?: Partial<IncomingInvoiceTimelineDescriptionItem>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}

enum ActionLogTableName {
  GENI = "Bejövő számla",
}

export class ActionLog implements IncomingInvoiceTimelineItem {
  tableName = "";
  recordID = "";
  actionName = "";
  insertUserName = "";
  date: string | Date = "";
  changes: ActionLogEntry[] = [];
  expanded = false;

  get hasItems() {
    return this.changes.length > 0;
  }

  constructor(params?: Partial<ActionLog>) {
    const data = { ...params };
    Object.assign(this, data);
  }

  /*
   * IncomingInvoiceTimelineItem implementation for IncomingInvoiceTimeline.vue
   */
  get timestamp(): Dayjs {
    return dayjs(this.date);
  }

  get title(): string {
    return this.actionName.startsWith(ActionLogTableName.GENI)
      ? this.actionName.replace(ActionLogTableName.GENI, "")
      : this.actionName;
  }

  get subtitle(): string {
    return this.insertUserName;
  }

  get description(): string | IncomingInvoiceTimelineDescriptionItem[] | undefined {
    return this.changes.map(
      (c) =>
        new IncomingInvoiceTimelineDescriptionItem({
          label: `${c.description}:`,
          value: `${c.oldValue} > ${c.newValue}`,
        })
    );
  }

  get icon(): string {
    return `fa-sticky-note`;
  }

  get color(): string {
    return `lighten-2 teal`;
  }
}

export class ActionLogEntry {
  description = "";
  oldValue = "";
  newValue = "";
}
