import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import BankExtractImport from "../models/BankExtractImport";
import BankExtractImportItem from "../models/BankExtractImportItem";
import ImportFormat from "../models/ImportFormat";

export default class BankExtractImportApi {
  static get(headID: number): Promise<BankExtractImport> {
    return http.get<ApiResponse<BankExtractImport>>(`bank-extract-import/${headID}`).then(handleApiResponse);
  }

  static getItems(headID: number): Promise<BankExtractImportItem[]> {
    return http
      .get<ApiResponse<BankExtractImportItem[]>>(`bank-extract-import/${headID}/items`)
      .then(handleApiResponse);
  }

  static update(bankExtractImport: BankExtractImport): Promise<boolean> {
    return http.put(`bank-extract-import`, { bankExtractImport: bankExtractImport }).then(checkResponse);
  }

  static delete(headID: number): Promise<boolean> {
    return http.delete(`bank-extract-import/${headID}`).then(checkResponse);
  }

  static prepareCheck(bankExtractImport: BankExtractImport): Promise<string> {
    return http
      .put<ApiResponse<string>>(`bank-extract-import/prepare`, {
        bankExtractImport: bankExtractImport,
      })
      .then(handleApiResponse);
  }

  static prepareToImport(bankExtractImport: BankExtractImport): Promise<boolean> {
    return http
      .put(`bank-extract-import/prepare`, {
        bankExtractImport: bankExtractImport,
        confirmed: true,
      })
      .then(checkResponse);
  }

  static createImportStatement(file: File, bank: number, type: number, techAmount: number): Promise<boolean> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bank", bank.toString());
    formData.append("type", type.toString());
    formData.append("technicalAmount", techAmount.toString());
    return http
      .post(`bank-extract-import/create`, formData, { headers: { "Content-Type": "multipart/mixed" } })
      .then(checkResponse);
  }

  static getImportChoices(bankId: number): Promise<ImportFormat[]> {
    return http.get<ApiResponse<ImportFormat[]>>(`bank-extract-import/choices/${bankId}`).then(handleApiResponse);
  }
}
