import http, { handleApiResponse, checkResponse } from '@/services/http'
import AccountCodeEditModel, { AccountCodeEditModelResponse } from "../models/AccountCodeEditModel";
import ApiResponse from '@/models/ApiResponse'
import AccountCodeModel from '../models/AccountCodeModel';

export default class AccountCodeEditModelApi{
    static getAll(): Promise<AccountCodeEditModel[]>{
        return http
            .get<ApiResponse<AccountCodeEditModel[]>>(`account-codes/editmodels/`)
            .then(handleApiResponse)
    }

    static get(accountCodeEditModelId: number, columnDictionaryId: number): Promise<AccountCodeEditModel> {
        return http
            .get<ApiResponse<AccountCodeEditModel>>(`account-codes/editmodels/${accountCodeEditModelId}/${columnDictionaryId}`)
            .then(handleApiResponse)
    }

    static update(source: AccountCodeEditModel): Promise<boolean> {
        return http
            .put(`account-codes/editmodels/${source.accountCodeId}`, source)
            .then(checkResponse)
    }

    static updateV2(source: AccountCodeModel): Promise<boolean> {
        return http
            .put(`account-codes/editmodels/update`, { Model: source })
            .then(checkResponse)
    }
}