




























import Vue from "vue";
import {
  DownPaymentExtOrder,
  PreDownPayment,
} from "@/modules/downPayment/models";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import DownPaymentExtOrderItemsGrid from "./DownPaymentExtOrderItemsGrid.vue";
import Fragment from "@/components/Fragment.vue";

interface Props {
  notiDownpaymId: number;
  partnerId: number;
}

interface Data {
  preDownPayments: PreDownPayment[];
  detailsVisible: boolean;
}

export default Vue.extend<Data, {}, {}, Props>({
  data: () => ({
    preDownPayments: [],
    detailsVisible: false,
  }),

  props: {
    notiDownpaymId: { type: Number, default: 1 },
    partnerId: { type: Number, default: 1 },
  },

  async beforeMount() {
    this.preDownPayments = await http
      .get<ApiResponse<PreDownPayment[]>>(
        `pre-down-payment/down-payments/partner/${this.partnerId}/invoice/${this.notiDownpaymId}`
      )
      .then(handleApiResponse)
      .then((x) => {
        this.$emit("hasItems", x.length > 0);
        console.log(x.length);
        return x;
      });
  },

  methods: {
    hasItems(e: DownPaymentExtOrder): boolean {
      return e.orderItems.length > 0;
    },

    findPreDownPaymentElement(e: DownPaymentExtOrder) {
      return this.preDownPayments.find((x) => x.order?.id == e.id);
    },

    toggleDetails() {
      this.detailsVisible = !this.detailsVisible;
    },
  },

  components: {
    /*     MoneyDisplay,
    IconButton, */
    DownPaymentExtOrderItemsGrid,
    Fragment,
  },
});
