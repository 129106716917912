import '@progress/kendo-theme-bootstrap/dist/all.css'
import '@progress/kendo-ui/js/kendo.spreadsheet'
import { Grid } from "@progress/kendo-vue-grid"
import { VueConstructor } from 'vue/types/umd'

import { Spreadsheet,
    SpreadsheetSheet,
    SpreadsheetInstaller } from '@progress/kendo-spreadsheet-vue-wrapper'

export default {
    install(Vue: VueConstructor) {
        Vue.component('grid', Grid)
        Vue.use(SpreadsheetInstaller)
        Vue.component('spreadsheet', Spreadsheet)
        Vue.component('spreadsheet-sheet', SpreadsheetSheet)
    }
}