import Message from "@/modules/chat/models/Message";
import eventBus from "@/services/eventBus";
import http from "@/services/http";
import * as signalR from "@microsoft/signalr";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { VueConstructor } from "vue/types/umd";

declare module "vue/types/vue" {
  interface Vue {
    $chatHub: signalR.HubConnection;
  }
}

export default {
  install(Vue: VueConstructor) {
    const connection = ((window as any).signalrMock as signalR.HubConnection) || new signalR.HubConnectionBuilder()
      .withUrl(http.defaults.baseURL + `connections/hub/chat`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    connection.on("ReceiveMessage", (message: Message) => {
      eventBus.$emit("receive-message", message);
    });

    connection.on("UserLeftGroup", (groupId: string) => {
      eventBus.$emit("user-left-group", groupId);
    });

    connection.on("UserAddedToGroup", (groupId: string) => {
      eventBus.$emit("user-added-to-group", groupId);
    });

    Vue.prototype.$chatHub = connection;

    let reconnectTries = 0;
    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch(() => {
        reconnectTries++;
        if (reconnectTries > 5) return;
        return new Promise((resolve, reject) =>
          setTimeout(
            () =>
              start()
                .then(resolve)
                .catch(reject),
            5000
          )
        );
      });
      return startedPromise;
    }

    connection.onclose(() => start());

    start();
  },
};
