









import Vue from "vue";

export default Vue.extend({
  props: {
    errorMessage: {
      type: String,
      default: "",
    },

    warningMessage: {
      type: String,
      default: "",
    },
  },

  computed: {
    color(): string {
      return this.errorMessage != "" ? "red" : "orange";
    },
  },
});
