import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=50461507&scoped=true&"
import script from "./Tabs.vue?vue&type=script&lang=ts&"
export * from "./Tabs.vue?vue&type=script&lang=ts&"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=50461507&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50461507",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTabs,VTabsItems})
