export default interface AccountVoucherType {
    id: number;
    name: string;
    foreign: string;
    english: string;
    deleted: boolean;
    inEdit: boolean;
    deleting: boolean;
}

export const accountVoucherTypeDefaultProps: AccountVoucherType = {
    id: 0,
    name: "",
    foreign: "",
    english: "",
    deleted: false,
    inEdit: false,
    deleting: false,
}