



































import Vue from 'vue';
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';
import { FilteringModel } from '@/services/filtering';
import ApiSearchPage from '@/components/apiSearch/components/ApiSearchPage.vue';
import IconButton from "@/components/IconButton.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import store, { KanriInvoiceStore } from "../services/KanriInvoiceStore";
import { rules } from "../models/GenoExperimentalInvoice";
import eventBus from '@/services/eventBus';
import configuration from '@/models/Configuration';

interface Data {
  url: string;
  filter: FilteringModel;
  store: KanriInvoiceStore;
  importInProgress: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    ApiSearchPage,
    IconButton,
    MoneyDisplay,
  },

  data () {
    return {
      url: "kanri/car-agreements",
      filter: new FilteringModel(),
      store,
      rules,
      importInProgress: false,
    }
  },

  methods: {
    async importKanri(head: any) {
      console.log(head);
      this.importInProgress = true;

      const ret = (await this.store.importCarAgreement(head.objectId, head.id));

      if (ret && (ret as any).id > 1 && ret.errorList.length == 0) {
        eventBus.$emit("success", "Sikeres számla beemelés!");
        if (configuration.invoiceFlow.enabled) {
          this.$router.push(`/general-incoming-invoice/flow/edit/${(ret as any).id}/3`);
        }
        else {
          this.$router.push(`/general-incoming-invoice/edit/${(ret as any).partnerId}/${(ret as any).id}`);
        }
      }
      else {
        eventBus.$error(ret.errorList.join("\n"));
      }
      this.importInProgress = false;
    }
  },

  computed: {
    columns: () => [
      {
        field: "objectId",
        title: "Kanri ID",
      },
      {
        field: "carAgreementNumber",
        title: "Vételi jegy",
      },
      {
        field: "carAgreementType",
        title: "Típus",
      },
      {
        field: "vin",
        title: "Alvázszám",
      },
      {
        field: "total",
        title: "Totál",
        cell: "totalTemplate",
        type: ColumnType.Right,
        filterable: false
      },
      {
        field: "paymentMode",
        title: "Fizetési mód"
      },
      {
        field: "status",
        title: "Státusz",
      },
      {
        filterable: false,
        cell: "importCell"
      },
    ]
  },
})
