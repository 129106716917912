














































































import IconButton from "../../../components/IconButton.vue";
import Vue from "vue";
import StoreInTable from "./StoreInTable.vue";
import * as api from "../services/api";
import store, { InvoiceItemStore } from "../services/store";
import { InvoiceRelationType, RelatedInvoice, Stock, StoreIn } from "../models";
import eventBus from "../../../services/eventBus";
import dayjs from "dayjs";
import configuration from "../../../models/Configuration";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";

interface Data {
  searchText: string;
  startDate: string;
  endDate: string;
  store: InvoiceItemStore;
  storeIns: StoreIn[];
  rawStoreIns: StoreIn[];
  invoiceSupplierOnly: boolean;
  currencyShowing: boolean;
  flowStore: InvoiceFlowStore;
}

interface Methods {
  search(e: KeyboardEvent): void;
  onRelationRemoved(relation: RelatedInvoice): void;
  clear(): void;
  currencyChangedHandler(): void;
}

const storeInFilter = (store: InvoiceItemStore) => (storeIn: StoreIn) =>
  store.currency === storeIn.currency;

const storeInFilter2 = (store: InvoiceItemStore) => (storeIn: StoreIn) =>
  store.currency != storeIn.currency;

export default Vue.extend<Data, Methods, {}, {}>({
  props: {
    isPageDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    StoreInTable,
    IconButton,
  },

  data: () => ({
    configuration,
    store,
    flowStore,
    searchText: "",
    searchOptions: [
      { text: "Keresés a számla szállítója alapján", value: true },
      { text: "Keresés minden beszállítóra", value: false },
    ],
    invoiceSupplierOnly: true,
    startDate: dayjs()
      .add(-1, "years")
      .format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    storeIns: [],
    rawStoreIns: [],
    currencyShowing: true,
  }),

  mounted() {
    const storeInItemAddHandler = () => (
      (this.storeIns = []), (this.rawStoreIns = [])
    );
    eventBus.$on("invoice-item:add", storeInItemAddHandler);
    eventBus.$on("invoice-relation:remove", (relation: RelatedInvoice) =>
      this.onRelationRemoved(relation)
    );
  },

  computed: {
    hasMoreData() {
      return this.storeIns.length > 0;
    },
  },

  methods: {
    currencyChangedHandler() {
      this.currencyShowing = !this.currencyShowing;
      if (this.currencyShowing) {
        this.storeIns = this.rawStoreIns.filter(storeInFilter(this.store));
      } else {
        this.storeIns = this.rawStoreIns.filter(storeInFilter2(this.store));
      }
    },

    search(e: KeyboardEvent) {
      if (e.key !== "Enter") {
        return;
      }

      const supplierID = this.invoiceSupplierOnly
        ? this.store.supplierID
        : null;

      e.preventDefault();
      api
        .getStoreIns(supplierID, this.searchText, this.startDate, this.endDate)
        .then((data) => {
          this.rawStoreIns = data;

          if (this.currencyShowing) {
            this.storeIns = this.rawStoreIns.filter(storeInFilter(this.store));
          } else {
            this.storeIns = this.rawStoreIns.filter(storeInFilter2(this.store));
          }
        });
    },

    onRelationRemoved(relation: RelatedInvoice) {
      const items: StoreIn[] = this.storeIns;

      const storeIn: StoreIn | undefined = items.find(
        (s) =>
          relation.type === InvoiceRelationType.Stock &&
          s.storeInID === relation.relatedItemID
      );

      if (storeIn) {
        storeIn.relatedInvoices = storeIn.relatedInvoices.filter(
          (i) => i.relationID !== relation.relationID
        );
      }

      const stock: Stock | undefined = items
        .flatMap((si) => si.stocks)
        .find(
          (s) =>
            relation.type === InvoiceRelationType.Stock &&
            s.stockID === relation.relatedItemID
        );

      if (stock) {
        stock.relatedInvoices = stock.relatedInvoices.filter(
          (i) => i.relationID !== relation.relationID
        );
      }
    },

    clear() {
      this.searchText = "";
      this.storeIns = [];
      this.rawStoreIns = [];
    },
  },
});
