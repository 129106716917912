






















































































































































import Partner, { PartnerResponse } from "@/modules/partner/models/Partner";
import PartnerApi from "@/modules/partner/services/PartnerApi";
import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import eventBus from "@/services/eventBus";
import { GridColumnProps } from "@progress/kendo-vue-grid";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import ApprovalMatrixHead from "../models/ApprovalMatrixHead";
import ApprovalMatrixHeadResponse, {
  approvalMatrixHeadResponseDefaultProps,
} from "../models/ApprovalMatrixHeadResponse";
import store, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import ApprovalMatrixUsersView from "../components/ApprovalMatrixUsersView.vue";
import { ConfirmationMixin } from "@/mixins/confirmation";
import Confirmation from "@/components/Confirmation.vue";

interface Data {
  store: InvoiceFlowStore;
  filter: string;
  tab: string | null;
  partnerSearchFirstPage: PartnerResponse | null;
}

interface Methods {
  partnerChanged(item: ApprovalMatrixHead, partner: Partner): void;
  addHead(): void;
  deleteHead(item: ApprovalMatrixHeadResponse): void;
  hideDetails(): void;
  showDetails(item: ApprovalMatrixHeadResponse): void;
  save(): void;
}

interface Computed {
  columns: GridColumnProps[];
  items: ApprovalMatrixHeadResponse[];
}

export default ConfirmationMixin.extend<Data, Methods, Computed, {}>({
  components: {
    PartnerSearch,
    ApprovalMatrixUsersView,
    NumericTextBox,
    Confirmation,
  },

  mixins: [ConfirmationMixin],

  data: () => ({
    store,
    filter: "",
    tab: null,
    partnerSearchFirstPage: null,
  }),

  async beforeMount() {
    await PartnerApi.getAll(1, 10, false).then((data) => (this.partnerSearchFirstPage = data));
    await Promise.all([this.store.flowUsers.load(), this.store.loadApprovalMatrixHeads()]);
  },

  methods: {
    partnerChanged(item, partner) {
      const existing = this.store.approvalMatrixHeads.find(
        (x) => x != item && x.partner > 1 && x.partner == partner.id
      );

      if (existing) {
        eventBus.$emit("warn", "A kiválasztott Szállítóhoz már létezik ellenőrző jóváhagyó előtési adat.");
        return;
      }

      item.partner = partner.id;
      item.partnerName = partner.name;
    },

    addHead() {
      const newItem: ApprovalMatrixHeadResponse = {
        ...approvalMatrixHeadResponseDefaultProps,
        controllerUsers: [],
        approverUsers: [],
        fullfillControllerUsers: [],
        fullfillApproverUsers: [],
      };
      this.store.approvalMatrixHeads = [newItem, ...this.store.approvalMatrixHeads];
    },

    async deleteHead(item) {
      const confirmed = await this.confirm("", `confirmation-${item.partner}-${item.groupId}`);
      if (!confirmed) return;

      const index = this.store.approvalMatrixHeads.indexOf(item);
      if (index > -1) {
        this.store.approvalMatrixHeads.splice(index, 1);
      }
    },

    hideDetails() {
      this.store.approvalMatrixHeads.forEach((head) => {
        head.expanded = false;
      });
    },

    showDetails(item) {
      item.expanded = !item.expanded;
    },

    async save() {
      this.store.approvalMatrixHeads.forEach((x) => {
        x.controllerUsers = x.controllerUsers.filter((y) => y.userId != 1);
        x.approverUsers = x.approverUsers.filter((y) => y.userId != 1);
        x.fullfillControllerUsers = x.fullfillControllerUsers.filter((y) => y.userId != 1);
        x.fullfillApproverUsers = x.fullfillApproverUsers.filter((y) => y.userId != 1);
      });
      await this.store.updateApprovalMatrix();
    },
  },

  computed: {
    columns: () => [
      { width: 60, cell: "detailsButtonTemplate", headerCell: "detailsButtonHeaderTemplate" },
      { field: "partner", title: "Szállító", cell: "partnerTemplate" },
      { width: 110, cell: "commandTemplate" },
    ],

    items() {
      const items: ApprovalMatrixHeadResponse[] = this.store.approvalMatrixHeads.filter((x) => x.accInvGroup == 1);
      items.forEach((x) => {
        x.currencyDisabled = items.filter((y) => y.partner == x.partner).length > 1;
      });
      return items.filter((x) => x.partnerName?.toUpperCase().includes(this.filter.toUpperCase()));
    },
  },
});
