






































































































import IconButton from "@/components/IconButton.vue";
import Vue from "vue";
import * as api from "./api";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import ServiceItem from "./ServiceItem";
import ServiceItemRelation from "./ServiceItemRelation";

interface Data {
  flowStore: InvoiceFlowStore;
  carItemRelaions: ServiceItemRelation[];
  newCarItemRelations: ServiceItem[];
  searchText: string;

  selectedItems: ServiceItem[];
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { IconButton, MoneyDisplay, MoneyInput },
  props: {
    invItemId: Number,
    amount: Number,
    currency: String,
  },

  data() {
    return {
      flowStore,
      carItemRelaions: [],
      newCarItemRelations: [],
      searchText: "",

      selectedItems: [],
    };
  },

  async beforeMount() {
    this.carItemRelaions = await api.getCurrentCarItemRelations(this.$props.invItemId);
  },

  watch: {
    amount() {
      if (this.carItemRelaions) {
        this.carItemRelaions.forEach((element) => {
          element.amount = this.$props.amount;
        });
      }
    },
  },

  methods: {
    setAmmount() {
      if (this.carItemRelaions) {
        this.carItemRelaions.forEach((element) => {
          element.amount = this.$props.amount;
        });
      }
    },

    async search(e: KeyboardEvent) {
      if (e.key != "Enter") {
        return;
      }
      this.newCarItemRelations = (
        await api.searchCarItemRelations(this.searchText, this.flowStore.invoice.partner)
      ).map((x: ServiceItem) => ({ ...x, isSelected: false }));
    },

    clear() {
      this.newCarItemRelations = [];
      this.searchText = "";
    },

    async updateItem(item: ServiceItemRelation) {
      await api.updateCarItemRelation(item);
    },

    async deleteItem(item: ServiceItemRelation) {
      this.carItemRelaions = this.carItemRelaions.filter((x) => x.id != item.id);
      await api.deleteCarItemRelation(item.id);
    },

    // selectItem(item: ServiceItem) {
    //   if (!item.isSelected) {
    //     item.isSelected = true;
    //     this.selectedItems.push(item);
    //   } else {
    //     item.isSelected = false;
    //     this.selectedItems = this.selectedItems.filter((x) => x.id == item.id);
    //   }
    // },

    async addItems(item: ServiceItem) {
      this.selectedItems = [];
      this.selectedItems.push(item);

      await api.addCarItemRelation(
        this.selectedItems,
        Number(this.$route.params.invoiceId),
        Number(this.$props.invItemId)
      );
      this.newCarItemRelations = [];
      this.carItemRelaions = await api.getCurrentCarItemRelations(this.$props.invItemId);
    },
  },

  computed: {
    addDisabled() {
      if (!this.$data.carItemRelaions) {
        return true;
      }
      return !(this.$data.carItemRelaions.length < 1);
    },
    columns: () => [
      { field: "operationName", title: "Munka" },
      { field: "amount", title: "Bruttó", cell: "totalTemplate", headerClassName: "right_text" },
      //{ field: "tax", title: "ÁFA", cell: "vatTemplate", headerClassName: "right_text" },
      { cell: "commandTemplate", width: "120px" },
      { width: 0 },
    ],

    newColumns: () => [
      { field: "operationName", title: "Munka" },
      //{ field: "amount", title: "Díj", cell: "totalTemplate", headerClassName: "right_text" },
      //{ field: "tax", title: "ÁFA", cell: "vatTemplate", headerClassName: "right_text" },
      { cell: "commandTemplate", width: "70px" },
      { width: 0 },
    ],
  },
});
