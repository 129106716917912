






















































































































































import Vue from 'vue';
import store, { TransactionStore } from "../services/TransactionStore";
import DateInput from '@/components/DateInput.vue';
import Partner from '@/modules/partner/models/Partner';
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import dayjs from "dayjs";
import { transactionDTODefaultProps } from '../models/TransactionDTO';
import eventBus from '@/services/eventBus';

interface Data {
  store: TransactionStore;
  editing: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    DateInput,
    PartnerSearch,
  },

  data() {
    return {
      store,
      editing: false,
    }
  },

  async beforeMount() {
    if (Number(this.$route.params.id) > 1) {
      this.editing = true;
      await this.store.getTransaction(Number(this.$route.params.id));
    }
    else {
      this.store.transaction = {...transactionDTODefaultProps };
      this.store.transaction.accDate = dayjs(new Date()).format("YYYY-MM-DD");

      await Promise.all([
        this.store.getBookTypes(),
        this.store.getCurrencies(),
        this.store.getPeriods(this.store.transaction.accDate),
        this.store.getTransStats(),
        this.store.getTransTypes(),
      ]);

      this.store.transaction.statusName = "Előkészítés alatt";
      const period = this.store.periods.find((x) => new Date(x.startDate) <= new Date(this.store.transaction.accDate) && new Date(x.endDate) >= new Date(this.store.transaction.accDate));
      this.store.transaction.periodName = period?.period;
      this.store.transaction.typeName = "Manuális";
    }
  },

  methods: {
    async onPartnerChange(partner: Partner) {
      this.store.transaction.partner = partner.id;
      this.store.transaction.partnerName = partner.name;
    },

    cancel() {
      this.$router.push("/transactions");
    },

    async save() {
      const ret = await this.store.save(Number(this.$route.params.id));
      if (ret > 1) {
        eventBus.$emit("success", "Sikeres mentés!");
        this.$router.push(`/transactions/edit/${ret}`)
      }
      else {
        eventBus.$emit("error", "Hiba!");
      }
    }
  },
})
