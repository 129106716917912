var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[(!_vm.loading)?_c('ApiSearchPage',{key:_vm.$route.fullPath,attrs:{"url":"notice/apisearch","columns":_vm.columns,"title":"Kimenő értesítők","delay":1000,"create":"Új értesítő","resetSort":false},on:{"create":function($event){return _vm.newClick()}},scopedSlots:_vm._u([{key:"entityIdTemplate",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.id))])]}},{key:"entityCurrNettoTemplate",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.currNetto))])]}},{key:"entityCurrVatTemplate",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.currVat))])]}},{key:"entityCurrTotalTemplate",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.currTotal))])]}},{key:"entityInvCurrencyTemplate",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.invCurrency))])]}},{key:"invDateTemplate",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(_vm.convertToDate(item.invDate)))])]}},{key:"paymentTermTemplate",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(_vm.convertToDate(item.paymentTerm)))])]}},{key:"commandTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.modify(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])])],1)]}}],null,false,2557409112)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }