




























































































import Vue from "vue";
import store, { TaxStore } from "../services/TaxStore";
import DateInput from "@/components/DateInput.vue";
import { defaultVatItemProps } from "../models/VatItem";


interface Data {
  store: TaxStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    DateInput,
  },

  data(): Data {
    return {
      store,
    };
  },

  methods: {
    changeVatItem() {
      this.store.createVatItm = !this.store.createVatItm;
    },

    async addItem() {
      await this.store.createVatItem();
      this.store.vatItem = { ...defaultVatItemProps };
    },
  },
});
