var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-2"},[_c('h5',{staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v(" Helyesbítendő alvállalkozói számla keresése ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addGeneralWscontRectItem}},[_vm._v("Előzmény nélküli helyesbítés")])],1),_c('br'),_c('div',{staticClass:"d-flex flex-row"},[_c('v-text-field',{attrs:{"outlined":"","single-line":"","placeholder":"Számla szám keresése..."},on:{"keyup":_vm.search},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('icon-button',{attrs:{"tooltip":"Találatok törlése","icon":"fa-times"},on:{"click":_vm.clear}}),_c('icon-button',{attrs:{"tooltip":"Kijelölt tétel helyesbítése","icon":"fa-plus"},on:{"click":_vm.addItem}})],1),_c('grid',{attrs:{"data-items":_vm.newWscontRectItemRelations,"columns":_vm.columns},scopedSlots:_vm._u([{key:"nettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.netto,"currency":props.dataItem.invCurrency}})],1)]}},{key:"vatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.vat,"currency":props.dataItem.invCurrency}})],1)]}},{key:"bruttoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.brutto,"currency":props.dataItem.invCurrency}})],1)]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[(_vm.$props.invItemNetto <= props.dataItem.netto)?_c('check-box',{attrs:{"selected":props.dataItem.isSelected},on:{"change":function($event){return _vm.selectItem(props.dataItem)}}}):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v(" fa-exclamation-circle ")])]}}],null,true)},[_c('span',[_vm._v("A helyesbítő tétel összege nem lehet nagyobb a helyesbíteni kívánt tétel összegénél!")])])],1)]}}])}),_c('h5',{staticClass:"mt-2 mb-3",staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v("Helyesbített alvállalkozói számla")]),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("GENI szám")]),_c('th',[_vm._v("Közvetített szolgáltatás")]),_c('th',[_vm._v("Munkalap")]),_c('th',[_vm._v("Alvállakozó")]),_c('th',[_vm._v("Nettó")]),_c('th',[_vm._v("ÁFA")]),_c('th',[_vm._v("Bruttó")]),_c('th')])]),(_vm.selectedWscontRectItem != undefined)?_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.selectedWscontRectItem.id))]),_c('td',[_vm._v(_vm._s(_vm.selectedWscontRectItem.wscont))]),_c('td',[_vm._v(_vm._s(_vm.selectedWscontRectItem.workSheet))]),_c('td',[_vm._v(_vm._s(_vm.selectedWscontRectItem.facSheetBr))]),_c('td',[_c('money-display',{attrs:{"value":_vm.selectedWscontRectItem.netto,"currency":_vm.selectedWscontRectItem.invCurrency}})],1),_c('td',[_c('money-display',{attrs:{"value":_vm.selectedWscontRectItem.vat,"currency":_vm.selectedWscontRectItem.invCurrency}})],1),_c('td',[_c('money-display',{attrs:{"value":_vm.selectedWscontRectItem.brutto,"currency":_vm.selectedWscontRectItem.invCurrency}})],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","outlined":"","disabled":_vm.$props.isPageDisabled},on:{"click":_vm.deleteItem}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash-o")])],1)]}}],null,false,2963067933)},[_c('span',[_vm._v("Törlés")])])],1)])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }