export function validate (accountNo: string){
    if (accountNo.length < 18) {
        return false;
    }

    const sumFirst =
        Number(accountNo[0]) * 9 +
        Number(accountNo[1]) * 7 +
        Number(accountNo[2]) * 3 +
        Number(accountNo[3]) +
        Number(accountNo[4]) * 9 +
        Number(accountNo[5]) * 7 +
        Number(accountNo[6]) * 3 +
        Number(accountNo[7]);

    const sumSecondAndThird =
        Number(accountNo[9]) * 9 +
        Number(accountNo[10]) * 7 +
        Number(accountNo[11]) * 3 +
        Number(accountNo[12]) +
        Number(accountNo[13]) * 9 +
        Number(accountNo[14]) * 7 +
        Number(accountNo[15]) * 3 +
        Number(accountNo[16]) +
        Number(accountNo[18]) * 9 +
        Number(accountNo[19]) * 7 +
        Number(accountNo[20]) * 3 +
        Number(accountNo[21]) +
        Number(accountNo[22]) * 9 +
        Number(accountNo[23]) * 7 +
        Number(accountNo[24]) * 3 +
        Number(accountNo[25]);

    if (sumFirst % 10 == 0 && sumSecondAndThird % 10 == 0) {
        return true;
    }
    
    return false;
}
