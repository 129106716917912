














































































import Vue from "vue";
import IconButton from "../../../components/IconButton.vue";
import OtherItemsTable from "./OtherItemsTable.vue";

import * as api from "../services/api";
import eventBus from "../../../services/eventBus";
import { OtherItem } from "../models";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import configuration, { AppConfiguration } from "@/models/Configuration";
import CarPreCalcInfo from "@/modules/canNet/components/CarPreCalcInfo.vue";
import GPartEdit from "@/modules/gpartsEdit/components/GPartEdit.vue";
import store, { InvoiceItemStore } from "../services/store";
import MoneyInput from "../../../components/MoneyInput.vue";
import gpartStore, { GpartsEditStore } from "@/modules/gpartsEdit/services/GpartsEditStore"

interface Data {
  searchText: string;
  otherItems: OtherItem[];
  flowStore: InvoiceFlowStore;
  carSearchVisible: boolean;
  configuration: AppConfiguration;
  isNew: boolean;
  store: InvoiceItemStore;
  unitPrice: number;
  gpartsStore: GpartsEditStore;
  isAddInProgress: boolean;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    IconButton,
    OtherItemsTable,
    CarPreCalcInfo,
    GPartEdit,
    MoneyInput
  },

  data: () => ({
    searchText: "",
    otherItems: [],
    flowStore,
    carSearchVisible: false,
    configuration,
    isNew: false,
    store,
    unitPrice: 0,
    gpartsStore: gpartStore,
    isAddInProgress: false,
  }),

  mounted() {
    const otherItemItemAddHandler = () => (this.otherItems = []);
    eventBus.$on("invoice-item:add", otherItemItemAddHandler);
  },

  methods: {
    showCarSearch() {
      this.carSearchVisible = !this.carSearchVisible;
    },

    search(e: KeyboardEvent) {
      // e.preventDefault();
      if (e.key != 'Enter') {
        return;
      }
      if (this.flowStore.isComponent) {
        if (this.searchText) {
          return api.searchOtherParts(this.searchText, this.flowStore.invoice.dpaym).then((data) => (this.otherItems = data));
        }
      } else if (this.flowStore.isCar) {
        if (this.searchText) {
          return api.searchOtherPartsForCars(this.searchText).then((data) => (this.otherItems = data));
        }
      } else if (this.flowStore.isSubcontractor) {
        if (this.searchText) {
          return api.searchOtherPartsForSubContractors(this.searchText).then((data) => (this.otherItems = data));
        }
      }
      //TODO: talán kell majd egy flag a flowStore-ba
      else {
        if (this.searchText) {
          return api.searchOtherPartsForMaintenance(this.searchText).then((data) => (this.otherItems = data));
        }
      }
    },

    clear(){
      this.searchText = "";
      this.otherItems = [];
    },

    getGeniUnitDigits(currency: string) {
      return configuration.operel
        ? 2
        : configuration.getCurrDigits(currency) !== undefined
        ? configuration.getCurrDigits(currency)?.geniUnitDigits ?? 4
        : 4;
    },

    async addNewGpart(){
      if (!this.isAddInProgress){

        try{
          this.isAddInProgress = true;
          await this.gpartsStore.updateGpart();
          const id = this.gpartsStore.gpart.id;

          const newItems = await api.searchOtherParts('', this.flowStore.invoice.dpaym, id) as OtherItem[];
          const newItem = newItems[0];

          newItem.unitPrice = this.unitPrice;
          this.store.addOtherItem(newItem);

          this.isNew = false;
        } finally{
          this.isAddInProgress = false;
        }
      }
    }
  },

  watch: {
    isNew(){
      this.unitPrice = 0;
    }
  }
});
