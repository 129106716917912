export default interface PartEmployee {
    id: number;
    partner: number;
    maxAmount: number;
    inEdit: boolean;
    deleted: boolean;
}

export const partEmployeeDefaultProps: PartEmployee = {
    id: 0,
    partner: 0,
    maxAmount: 0,
    inEdit: false,
    deleted: false
}
