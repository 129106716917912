export default interface PartPerson {
    id: number;
    name: string;
    sex: boolean;
    profession: string;
    city: string;
    postcode: string;
    address: string;
    otherNo: string;
    email: string;
    mobile: string;
    deleted: boolean;
    inEdit: boolean;
    partnerId: number | null;
}

export const partPersonDefaultProps: PartPerson = {
    id: 0,
    name: "",
    sex: false,
    profession: "",
    city: "",
    postcode: "",
    address: "",
    otherNo: "",
    email: "",
    mobile: "",
    deleted: false,
    inEdit: false,
    partnerId: 0,
}