var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('v-row',{staticClass:"search-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"justify-content":"center"},attrs:{"outlined":"","single-line":"","hide-details":"auto","placeholder":"Keresés..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.refreshApiSearch()}},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-col',{attrs:{"sm":"1"}},[_c('icon-button',{attrs:{"tooltip":"Találatok törlése","icon":"fa-times"},on:{"click":_vm.clear}})],1),_c('v-col',{attrs:{"cols":"2"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ApiSearchPage',{ref:"asref",attrs:{"url":_vm.url,"columns":_vm.columns,"search":false,"refresh":false,"resetFilter":false,"resetSort":false,"filterable":false,"sortable":false,"hideOnEmpty":true},scopedSlots:_vm._u([{key:"entityNameTemplate",fn:function( ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.name))])]}},{key:"entityCodeTemplate",fn:function( ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.code))])]}},{key:"entityMaincodeTemplate",fn:function( ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.mainCode))])]}},{key:"entityServiceTemplate",fn:function( ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":"","dense":""},model:{value:(item.entity.service),callback:function ($$v) {_vm.$set(item.entity, "service", $$v)},expression:"item.entity.service"}})]}},{key:"entitySetUnitPriceTemplate",fn:function( ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.setUnitPrice))])]}},{key:"entityDealerRabatTemplate",fn:function( ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.entity.dealerRabat))])]}},{key:"commandTemplate",fn:function(ref){
var item = ref.item;
return [_c('icon-button',{attrs:{"tooltip":"Hozzáadás","icon":"fa-plus"},on:{"click":function($event){return _vm.addItem(item)}}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }