var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tabs',{attrs:{"grow":""},scopedSlots:_vm._u([{key:"headers",fn:function(){return [_c('v-tab',{key:"dim4",staticClass:"dim4",on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" IV. dimenzió ")]),_c('v-tab',{key:"dim5",staticClass:"dim5",on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" V. dimenzió ")]),_c('v-tab',{key:"dim6",staticClass:"dim6",on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" VI. dimenzió ")]),_c('v-tab',{key:"dim7",staticClass:"dim7",on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" VII. dimenzió ")]),_c('v-tab',{key:"dim8",staticClass:"dim8",on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" VIII. dimenzió ")]),_c('v-tab',{key:"dim9",staticClass:"dim9",on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" IX. dimenzió ")]),_c('v-tab',{key:"dim10",staticClass:"dim10",on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" X. dimenzió ")]),_c('v-tab',{key:"dim11",staticClass:"dim11",attrs:{"disabled":""},on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" XI. dimenzió ")]),_c('v-tab',{key:"dim12",staticClass:"dim12",attrs:{"disabled":""},on:{"click":function (e) { return _vm.preSetItems(e); }}},[_vm._v(" XII. dimenzió ")])]},proxy:true},{key:"items",fn:function(){return [_c('v-tab-item',{key:"dim4"},[_c('ApiSearchPage',_vm._b({attrs:{"url":'',"columns":_vm.dim4columns,"search":false,"refresh":false,"resetSort":false,"resetFilter":false,"hasPager":false,"sortable":false,"filterable":false,"delay":1000,"name":'ledger-transaction-item-dim4'},scopedSlots:_vm._u([{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.total,"currency":_vm.$props.transactionItem.currency,"decimals":2}})]}}])},'ApiSearchPage',_vm.$attrs,false))],1),_c('v-tab-item',{key:"dim5"},[_c('ApiSearchPage',_vm._b({attrs:{"url":'',"columns":_vm.dim5columns,"search":false,"refresh":false,"resetSort":false,"resetFilter":false,"hasPager":false,"sortable":false,"filterable":false,"delay":1000,"name":'ledger-transaction-item-dim5'},scopedSlots:_vm._u([{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.total,"currency":_vm.$props.transactionItem.currency,"decimals":2}})]}}])},'ApiSearchPage',_vm.$attrs,false))],1),_c('v-tab-item',{key:"dim6"},[_c('ApiSearchPage',_vm._b({attrs:{"url":'',"columns":_vm.dim6columns,"search":false,"refresh":false,"resetSort":false,"resetFilter":false,"hasPager":false,"sortable":false,"filterable":false,"delay":1000,"name":'ledger-transaction-item-dim6'},scopedSlots:_vm._u([{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.total,"currency":_vm.$props.transactionItem.currency,"decimals":2}})]}}])},'ApiSearchPage',_vm.$attrs,false))],1),_c('v-tab-item',{key:"dim7"},[_c('ApiSearchPage',_vm._b({attrs:{"url":'',"columns":_vm.dim7columns,"search":false,"refresh":false,"resetSort":false,"resetFilter":false,"hasPager":false,"sortable":false,"filterable":false,"delay":1000,"name":'ledger-transaction-item-dim7'},scopedSlots:_vm._u([{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.total,"currency":_vm.$props.transactionItem.currency,"decimals":2}})]}}])},'ApiSearchPage',_vm.$attrs,false))],1),_c('v-tab-item',{key:"dim8"},[_c('ApiSearchPage',_vm._b({attrs:{"url":'',"columns":_vm.dim8columns,"search":false,"refresh":false,"resetSort":false,"resetFilter":false,"hasPager":false,"sortable":false,"filterable":false,"delay":1000,"name":'ledger-transaction-item-dim8'},scopedSlots:_vm._u([{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.total,"currency":_vm.$props.transactionItem.currency,"decimals":2}})]}}])},'ApiSearchPage',_vm.$attrs,false))],1),_c('v-tab-item',{key:"dim9"},[_c('ApiSearchPage',_vm._b({attrs:{"url":'',"columns":_vm.dim9columns,"search":false,"refresh":false,"resetSort":false,"resetFilter":false,"hasPager":false,"sortable":false,"filterable":false,"delay":1000,"name":'ledger-transaction-item-dim9'},scopedSlots:_vm._u([{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.total,"currency":_vm.$props.transactionItem.currency,"decimals":2}})]}}])},'ApiSearchPage',_vm.$attrs,false))],1),_c('v-tab-item',{key:"dim10"},[_c('ApiSearchPage',_vm._b({attrs:{"url":'',"columns":_vm.dim10columns,"search":false,"refresh":false,"resetSort":false,"resetFilter":false,"hasPager":false,"sortable":false,"filterable":false,"delay":1000,"name":'ledger-transaction-item-dim10'},scopedSlots:_vm._u([{key:"totalTemplate",fn:function(ref){
var item = ref.item;
return [_c('MoneyDisplay',{attrs:{"value":item.total,"currency":_vm.$props.transactionItem.currency,"decimals":2}})]}}])},'ApiSearchPage',_vm.$attrs,false))],1),_c('v-tab-item',{key:"dim11"}),_c('v-tab-item',{key:"dim12"})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }