// @date: Date | string | number | undefined | null
// @addLastDot: boolean = true
// -> 2021. 01. 02
// -> 2021. 01. 02.
export function formatDate(input: Date | string | number | undefined | null, addLastDot = true) {
  if (input) {
    const date = new Date(input);
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    const dot = addLastDot ? "." : "";

    return [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd + dot].join(". ");
  }
  return "";
}

// ISO 8601 formatted Date
// @date: Date | string | number | undefined | null
// -> 2021-01-20
export function formatDateISO(input: Date | string | number | undefined | null) {
  if (input) {
    const date = new Date(input);
    const mm = date.getMonth() + 1;
    const dd = date.getDate();

    return [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("-");
  }
  return "";
}

// @date: Date | string | number | undefined | null
// @addZeros: boolean = false
// -> 01:23
// -> 01:23:00
export function formatTime(input: Date | string | number | undefined | null, addZeros = false) {
  if (input) {
    const date = new Date(input);
    const h = date.getHours();
    const m = date.getMinutes();
    const s = addZeros ? ":00" : "";

    return [(h > 9 ? "" : "0") + h, (m > 9 ? "" : "0") + m + s].join(":");
  }
  return "";
}

// @date: Date | string | number | undefined | null
// -> 1970-01-02T03:04:05.678Z -> 1970-01-02T03:04:05
// -> 1970-01-02               -> 1970-01-02T00:00:00
export function newDate(input?: Date | string | number | undefined | null) {
  if (input) {
    const date = new Date(input);
    const ret = formatDateISO(date) + "T" + formatTime(date);
    return ret;
  }
  return "";
}

// @input: string | null
// -> 1970-01-02T03:04:05 -> 1970-01-02
export function trimDate(input: string | null) {
  if (input) {
    return input.split("T")[0];
  }
  return input;
}

// @input: string | null
// -> 1970-01-02T03:04:05.678Z -> 03:04:05
export function trimTime(input: string | null) {
  if (input) {
    return input.split("T")[1].split(".")[0];
  }
  return input;
}
