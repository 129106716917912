import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse, checkResponse } from "@/services/http";
import IncomingInvoiceSubtype from "../models/IncomingInvoiceSubtype";

export default class IncomingInvoiceTypeApi {
  static getAll(): Promise<IncomingInvoiceSubtype[]> {
    return http.get<ApiResponse<IncomingInvoiceSubtype[]>>(`incoming-invoice-subtype`).then(handleApiResponse);
  }

  static getAllByType(type: number): Promise<IncomingInvoiceSubtype[]> {
    return http
      .get<ApiResponse<IncomingInvoiceSubtype[]>>(`incoming-invoice-subtype/type/${type}`)
      .then(handleApiResponse);
  }

  static update(invoiceSubtype: IncomingInvoiceSubtype): Promise<any> {
    return http
      .put(`incoming-invoice-subtype`, {
        invoiceSubtype: invoiceSubtype,
      })
      .then(checkResponse);
  }

  static getUninvoicedStockCorrectionSubtypes(): Promise<IncomingInvoiceSubtype[]> {
    return http
      .get<ApiResponse<IncomingInvoiceSubtype[]>>(`incoming-invoice-subtype/uninvoiced-stock-correction`)
      .then(handleApiResponse);
  }
}
