export interface MainType {
  id: number;
  name: string;
  nickname: string;
  natRep: string;
}
export interface DetailedType {
  id: number;
  name: string;
  nickname: string;
}
export interface DimSort {
  id: number;
  name: string;
}

export interface DimCodeType {
  id: number;
  national: string;
  foreign: string;
  english: string;
  required: boolean;

  deleted: boolean;
  editing: boolean;
}

export interface DimActivity {
  id: number;
  national: string;
  foreign: string;
  english: string;
}

export interface DimCodeActivity{
  id: number;
  national: string;
  foreign: string;
  english: string;
}

export interface SelectChange{
  yearId: number;
  allItemsSelected: boolean;
}

export interface GroupInvMirrorEdit{
  itemIds: number[];
  dim1: number;
  dim2: number;
  dim3: number;
  dim4: number;
  dim5: number;
  dim6: number;
  dim7: number;
  dim8: number;
  dim9: number;
  mainType: number;
  detailedType: number;
  dimEdit: boolean;
  typeEdit: boolean;
  openCloseEdit: boolean;
  manualMachineEdit: boolean;

  manual: boolean;
  machine: boolean;

  transitionAccno: string;
  openAccno: string;
  closeAccno: string;
  partnerOpenClose: boolean;
}

export const GroupInvMirrorEditProps: GroupInvMirrorEdit = {
  itemIds: [],
  dim1: 1,
  dim2: 1,
  dim3: 1,
  dim4: 1,
  dim5: 1,
  dim6: 1,
  dim7: 1,
  dim8: 1,
  dim9: 1,
  
  mainType: 1,
  detailedType: 1,
  dimEdit: false,
  typeEdit: false,
  openCloseEdit: false,
  manualMachineEdit: false,

  manual: false,
  machine: false,

  openAccno: "",
  partnerOpenClose: false,
  closeAccno: "",
  transitionAccno: "",
}
export interface SelectableInvoiceMirror{
  accno: string;
  name: string;
}
