









































































































  import Vue from "vue";
  import Page from "../../../components/Page.vue";
  import GeneralIngoingInvoiceApi from "../services/GeneralIngoingInvoiceApi";
  import InvoiceImport from "../models/InvoiceImport";
  import InvoiceImportItem from "../models/InvoiceImportItem";
  import ApiSearch from "@/components/apiSearch/components/ApiSearch.vue";
  import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import PartDTO from "@/modules/part/models/PartDTO";
  
  interface Data {
    importItems: InvoiceImport[];
    importItemItems: InvoiceImportItem[];
  }

  interface Methods {
    select(item: InvoiceImport): void;
    setValid(): void;
    setItemsValid(): void;
    partSelected(item: InvoiceImportItem, part: PartDTO): void;
    gPartSelected(item: InvoiceImportItem, part: PartDTO): void;
  }

  interface Props {
    ids: number[];
  }

  interface Computed {
    partsColumns: ApiSearchColumn[];
    gPartsColumns: ApiSearchColumn[];
  }

  export default Vue.extend<Data, Methods, Computed, Props>({
    props: {
        ids: {
            type: Array as () => number[],
            default: () => [],
        }
    },

    beforeMount(){
        GeneralIngoingInvoiceApi.getInvoiceImportItems(this.ids).then(x => {
          this.importItems = x;
          this.setValid();
        });
    },

    data() {
      return {
        importItems: [],
        importItemItems: [],
      };
    },
  
    components: {
      Page,
      ApiSearch
    },
  
    methods: {
      gPartSelected(item: InvoiceImportItem, part: PartDTO) {
        item.gPartsID = part.id;
        this.setItemsValid();
        this.setValid();
      },

      partSelected(item: InvoiceImportItem, part: PartDTO) {        
        item.partsID = part.id;
        this.setItemsValid();
        this.setValid();
      },

      select(item: InvoiceImport){
        this.importItems.forEach(x => x.selected = false);
        item.selected = true;
        this.importItemItems = item.items;
      },

      setValid() {
        this.importItems.forEach(x => {
          if (x.items.some(y => y.partsID == null && y.gPartsID == null)){
            x.isValid = false;
          } else {
            x.isValid = true;
          }
        })
      },

      setItemsValid(){
        this.importItemItems.forEach(x => {
          if (x.partsID == null && x.gPartsID == null){
            x.isValid = false;
          } else {
            x.isValid = true;
          }
        });
      }
    },

    watch:{
      importItemItems: function() {
        this.setItemsValid();
      }
    },

    computed: {
      partsColumns: (): ApiSearchColumn[] => [
      { field: "code", title: "Cikkszám" },
      { field: "name", title: "Megnevezés" },
      { field: "group", title: "Cikkcsoport" },
      { field: "mainGroup", title: "Főcsoport" },
      { field: "subGroup", title: "Alcsoport" },
      { field: "extCode", title: "Külső kód" },
    ],

    gPartsColumns: (): ApiSearchColumn[] => [
      { field: "code", title: "Cikkszám" },
      { field: "name", title: "Megnevezés" },
      { field: "group", title: "Cikkcsoport" },
      { field: "mainGroup", title: "Főcsoport" },
      { field: "subGroup", title: "Alcsoport" },
      { field: "extCode", title: "Belső kód" },
    ],
    },
  });
  