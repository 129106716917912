
































































































































































import ApiPaginator from "./ApiPaginator.vue";
import Fragment from "../../Fragment.vue";

import Vue from "vue";
import ApiSearch, { ApiSearchColumn } from "../models/ApiSearch";
import { getHeaderClasses, getFieldClasses } from "@/utils/classes";
import { PaginatedPager } from "@/models/Pager";
import { FilteringModel, SortingModel, SortOrder } from "@/services/filtering";

import ServerSideSearchApi from "../services/ServerSideSearchApi";
import { debounce } from "debounce";
import eventBus from "@/services/eventBus";

interface Data {
  /** Local API instance */
  api: ServerSideSearchApi;
  /** Used API paths */
  localGetUrl: string;
  localPostUrl: string;
  /** CurrentPage, PageSize, SearchText, Filter, Sorting container */
  apiSearch: ApiSearch;
  /** Initiate search, used in search-input.sync */
  search: string;
  /** Currently selected item's @itemValue, used in v-model */
  selectedValue: number | string | null;
  /** Object of the currently selected item */
  selectedItem: object | null;
  /** Displayed columns mapped as "ApiSearchColumn" */
  localColumns: ApiSearchColumn[];
  /** Displayed items */
  items: object[];
  /** Total item count */
  totalItems: number;
  /** Ignore the following case: item selected -> @search changed */
  ignoreSearch: boolean;
}

interface Methods {
  changeHandler(): Promise<void>;
  pagerChanged(pager: PaginatedPager): void;
  searchInputChanged(search: string): void;
  /** Update local @selectedItem and emit it, or it's ID */
  selectItem(item: number | string): void;
  clear(): void;

  getFieldOrder(field: string): SortOrder;
  toggleSort(field: string): void;

  getHeaderClasses(column: ApiSearchColumn): string;
  getFieldClasses(column: ApiSearchColumn): string;
}

interface Computed {
  displayedItems: object[];
}

interface Props {
  //! Search API
  /** API paths */
  url: string;
  disabled: boolean;
  postUrl: string;
  getUrl: string;
  /** Selectable page sizes @default [10,15] */
  baseSizes: number[];
  /** Debounce delay @default 0 */
  delay: number;

  //! Autocomplete
  /** Raw columns @default [] */
  columns: ApiSearchColumn[];
  /** The existing item's ID to load in beforeMount @default 1 */
  itemId: number;
  /** The displayed text of the selected item @default "name" */
  itemText: string;
  /** The return-value from selecting an item @default "id" */
  itemValue: string;
  /** Return an object or the @itemValue itself @default false */
  returnObject: boolean;
  /** List should be filterable @default false */
  filterable: boolean;
  /** List should be sortable @default false */
  sortable: boolean;
  /** Placeholder text while the input is empty */
  placeholder: string;
  /** Extra filters passed through as a prop */
  extraFilter: FilteringModel;
  multiselect: boolean;
  /** @itemText is not a Number */
  notID: boolean;
  /** Display the whole content on hover - @default true */
  displayTitles: boolean;
  noSearch: boolean;
  name: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ApiPaginator,
    Fragment,
  },

  props: {
    disabled: { type: Boolean, default: false },
    url: String,
    getUrl: String,
    postUrl: String,
    baseSizes: { type: Array as () => number[], default: () => [10, 15] },
    delay: { type: Number, default: 0 },

    columns: { type: Array as () => ApiSearchColumn[], default: () => [] },
    itemId: { type: Number, default: 1 },
    itemText: { type: String, default: "name" },
    itemValue: { type: String, default: "id" },
    returnObject: Boolean,
    filterable: { type: Boolean, default: true },
    sortable: { type: Boolean, default: true },
    placeholder: String,
    extraFilter: { type: Object as () => FilteringModel },
    notID: Boolean,
    displayTitles: { type: Boolean, default: true },
    multiselect: { type: Boolean, default: false },
    noSearch: { type: Boolean, default: false },
    name: { type: String, default: "" },
  },

  data: (): Data => ({
    api: new ServerSideSearchApi(),
    localGetUrl: "",
    localPostUrl: "",
    apiSearch: new ApiSearch(),
    search: "",
    selectedValue: null,
    selectedItem: null,
    localColumns: [],
    items: [],
    totalItems: 0,
    ignoreSearch: false,
  }),

  created() {
    eventBus.$on(`${this.$props.name}:reset`, () => {
      this.selectedItem = null;
    });
  },

  beforeDestroy() {
    eventBus.$off(`${this.$props.name}:reset`, () => {
      console.log("reset");
    })
  },

  async beforeMount() {
    this.localGetUrl = this.getUrl ? this.getUrl : this.url;
    this.localPostUrl = this.postUrl ? this.postUrl : this.url;

    if (this.itemId > 1) {
      this.selectedValue = this.itemId;

      //! Can be cancelled
      const result = await this.api.get(this.localGetUrl, this.itemId);
      if (result) this.selectedItem = result;
    }

    /** Create new ApiSearchColumn from params */
    this.localColumns = this.columns.map((c) => new ApiSearchColumn(c));

    let filter = this.filterable
      ? new FilteringModel().values(
          this.localColumns
            .filter((x) => x.filterable && !x.multiselect)
            .map((x) => ({ field: x.field ?? "" }))
            .filter((x) => !!x.field)
        )
      : undefined;

    this.localColumns
      .filter((x) => x.multiselect)
      .forEach((e) => {
        if (filter) {
          filter = filter.multiSelect(
            e.multiselectField ?? "",
            e.multiselectOptions ?? [],
            e.multiselectOptions?.map((x) => x.value)
          );
        }
      });

    if (filter && this.extraFilter) {
      filter = filter.fromFilter(this.extraFilter.toObject());
    }

    const sorting = this.sortable
      ? new SortingModel().capitalize().fields(
          this.localColumns
            .filter((x) => x.sortable)
            .map((x) => ({ field: x.field ?? "", text: x.title ?? "" }))
            .filter((x) => !!x.field && !!x.text)
        )
      : undefined;

    this.apiSearch = new ApiSearch({
      pageSize: this.baseSizes[0],
      filter: filter,
      sort: sorting,
      searchText: "",
    });

    this.changeHandler();
    this.changeHandler = debounce(this.changeHandler, this.delay);
  },

  watch: {
    search: {
      deep: true,
      handler(val: string, old: string) {
        if (!old) return;

        if (val == null) {
          this.searchInputChanged("");
          return;
        }

        this.searchInputChanged(val);
      },
    },

    selectedValue(item: number | string | null) {
      if (item == null) return;
      // selected -> do not search for the new value
      this.ignoreSearch = true;
      this.selectItem(item);
    },

    "apiSearch.filter": {
      deep: true,
      async handler(
        _: FilteringModel | undefined,
        old: FilteringModel | undefined
      ) {
        // block refresh while it's undefined
        if (!old) return;
        this.changeHandler();
      },
    },

    "apiSearch.sort": {
      deep: true,
      async handler(
        _: SortingModel | undefined,
        old: SortingModel | undefined
      ) {
        // block refresh while it's undefined
        if (!old) return;
        this.changeHandler();
      },
    },
  },

  methods: {
    async changeHandler(): Promise<void> {
      //! Can be cancelled
      const result = await this.api.post(this.localPostUrl, this.apiSearch);
      if (result) {
        this.items = result.currentPage;
        this.totalItems = result.totalCount;
      }
    },

    async pagerChanged(pager: PaginatedPager): Promise<void> {
      if (
        // block double API calls from: search change -> pager update
        pager.totalItems === 0 ||
        (this.apiSearch.currentPage === pager.currentPage &&
          this.apiSearch.pageSize === pager.pageSize)
      )
        return;

      this.apiSearch.currentPage = pager.currentPage;
      this.apiSearch.pageSize = pager.pageSize;
      this.changeHandler();
    },

    async searchInputChanged(search): Promise<void> {
      if (this.$props.noSearch) {
        return;
      }

      if (this.ignoreSearch) {
        this.ignoreSearch = false;
        return;
      }

      if (search == null || typeof search != "string") return;

      this.apiSearch.searchText = search;
      this.changeHandler();
    },

    // TODO: Might need a refactor to be more clear about the item's type
    async selectItem(item: number | string | null): Promise<void> {
      if (item === null) return;

      if (!this.returnObject) {
        this.$emit("itemSelected", item);
        return;
      }

      if (this.itemId === item) return;

      const request = this.notID ? item : Number(item) || 1;

      // TODO: option to return the selected object instead of Api.get(..)
      //! Can be cancelled

      if (this.$props.noSearch) {
        return;
      }
      const result = await this.api.get(this.localGetUrl, request);
      if (result) {
        this.selectedItem = result;
        this.$emit("itemSelected", this.selectedItem);
      }
    },

    getFieldOrder(field: string): SortOrder {
      return (
        this.apiSearch.sort?.definitions.find((x) => x.field === field)
          ?.order ?? SortOrder.None
      );
    },

    toggleSort(field: string): void {
      this.apiSearch.sort?.toggle(field);
    },

    clear(): void {
      this.selectedItem = null;
      this.$emit("apiSearch:clear");
    },

    getHeaderClasses,
    getFieldClasses,
  },

  computed: {
    displayedItems(): object[] {
      let list = this.items;
      if (this.selectedItem != null) list = [this.selectedItem, ...list];
      return list;
    },
  },
});
