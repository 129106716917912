import CashDocItem, { cashDocItemDefaultProps } from "./CashDocItem";
import CashSubType from "./CashSubType";
import CashProcType from "./CashProcType";

export default interface CashDocItemDisplay {
  docItem: CashDocItem;
  cashProcType: string;
  cashSubType: string;
  cashSubTypes: CashSubType[];
  selectedCashProcType: CashProcType | undefined;
  selectedCashSubType: CashSubType | undefined;
  id: number;
  document: number;
  partner: string;
  docNo: string | null;
  invPrefix: string | null;
  invNo: number;
  amount: number;
  note: string | null;
  invCurrency: string;
  currAmount: number;
  exchangeRate: number;
  avgRate: number;
  rateDate: string;
  isFinalRate: boolean;
  extNo: string | null;
  extDimcode1: string | null;
  extDimcode2: string | null;
  extDimcode3: string | null;
  postcalc: number;
  dimCode1: string | null;
  deleting: boolean;
  cashProcTypes: CashProcType[];
  invoiceToPayment: number;
}

export const cashDocItemDisplayDefaultProps: CashDocItemDisplay = {
  docItem: { ...cashDocItemDefaultProps },
  cashProcType: "",
  cashSubType: "",
  cashSubTypes: [],
  selectedCashProcType: undefined,
  selectedCashSubType: undefined,
  id: 0,
  document: 0,
  partner: "",
  docNo: null,
  invPrefix: null,
  invNo: 1,
  amount: 0,
  note: "",
  invCurrency: "",
  currAmount: 0,
  exchangeRate: 1,
  avgRate: 1,
  rateDate: "",
  isFinalRate: true,
  extNo: null,
  extDimcode1: null,
  extDimcode2: null,
  extDimcode3: null,
  postcalc: 1,
  dimCode1: null,
  deleting: false,
  cashProcTypes: [],
  invoiceToPayment: 0
};
