import { newDate } from "@/utils/formatDates";
import CashPointEdit from "@/modules/cashPoint/models/CashPointEdit";
import Country from "@/modules/cashPoint/models/Country";

interface CashDocumentData {
  id: number;
  cashAccount: number;
  partnerName: string;
  income: boolean;
  amount: number;
  currency: string;
  comment: string | null;
  annexNo: number | null;
  status: number;
  partner: number;
  uniqueNo: number;
  amoutText: string;
  noCuracc: boolean;
  closeDate: string;
  minCloseDate: string | null;
  userID: number;
  insDate: string;
  insTime: string;
  invCurrency: string;
  currAmount: number;
  exchangeRate: number;
  rateDate: string;
}

export default class CashDocument implements CashDocumentData {
  id = 0;
  cashAccount = 0;
  partnerName = "";
  income = false;
  amount = 0;
  currency = "";
  comment: string | null = "";
  annexNo: number | null = 0;
  status = 2;
  partner = 0;
  uniqueNo = 0;
  amoutText = "";
  noCuracc = false;
  closeDate = "2000-01-01T00:00";
  minCloseDate: string | null = null;
  userID = 0;
  insDate = "2000-01-01T00:00";
  insTime = "2000-01-01T00:00";
  invCurrency = "";
  currAmount = 0;
  exchangeRate = 1;
  rateDate = "2000-01-01T00:00";

  initialize(cashPoint: CashPointEdit, statementID: number, selectedCurrency?: Country) {
    this.cashAccount = statementID;
    this.uniqueNo = this.income ? cashPoint.inDocNo + 1 : cashPoint.outDocNo + 1;

    this.invCurrency = cashPoint.currency;
    this.currency = selectedCurrency?.currency ?? cashPoint.currency;

    const today = new Date();
    this.insDate = newDate(today);
    this.insTime = newDate(today);
    this.closeDate = newDate(today);
    this.rateDate = newDate(today);
  }
}
