import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import User from "../models/User";

export default class UserApi {
  static getUsers(): Promise<User[]> {
    return http.get<ApiResponse<User[]>>(`users`).then(handleApiResponse);
  }

  static getFilteredUsers(accInvGroup: number): Promise<User[]> {
    return http.get<ApiResponse<User[]>>(`users/filtered/${accInvGroup}`).then(handleApiResponse);
  }
}
