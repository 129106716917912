import DocumentType, {documentTypeDefaultProps} from "@/modules/documentType/models/DocumentType"
import DocumetTypeApi from "../services/DocumentTypeApi"

export class DocumentTypeStore{
    documentTypes: DocumentType[] = []
    documentType: DocumentType = { ...documentTypeDefaultProps }

    async loadDocumentTypes() {
        this.documentTypes = await DocumetTypeApi.getAll()
    }

    async loadDocumentType(documentTypeId: number) {
        this.documentType = documentTypeId > 1 ? await DocumetTypeApi.get(documentTypeId) : { ...documentTypeDefaultProps }
    }

    async save() {
        if(!this.documentType) {
            return
        }

        try {
            if (this.documentType.id > 1){
                await DocumetTypeApi.update(this.documentType);           
            }
            else{
                await DocumetTypeApi.create(this.documentType);
                this.documentTypes.push(this.documentType);
                this.documentTypes = [...this.documentTypes];
                this.documentType = { ...documentTypeDefaultProps };
            }
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }
}

const store = new DocumentTypeStore()
export default store