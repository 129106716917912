import User, { userDefaultProps } from "@/modules/user/models/User";

export default interface MessagedUserResponse {
  user: User;
  unreadMessageCount: number;
  selected: boolean;
}

export const messagedUserResponseDefaultProps: MessagedUserResponse = {
  user: { ...userDefaultProps },
  unreadMessageCount: 0,
  selected: false,
};
