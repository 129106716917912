




































































































import Vue from "vue";
import PartPerson, { partPersonDefaultProps } from "../models/PartPerson";
import store from "../services/PartnerStore";

export default Vue.extend({
  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    window.scrollTo(0, 0);

    store.partPerson = { ...partPersonDefaultProps };
    //store.partPerson.partner = store.partner.id;
  },

  methods: {
    async savePartPerson(item: PartPerson) {
      item.partnerId = store.partner.id;
      await this.store.createPartPerson(item);
      this.$router.push(`/partners/${store.partner.id}/${this.$route.params.source}`);
    },

    cancelCreatePartPerson() {
      store.partPerson = { ...partPersonDefaultProps };
      this.$router.push(`/partners/${store.partner.id}/${this.$route.params.source}`);
    },
  },
});
