
























































































import Vue from "vue";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";
import DebitPaymentEditModelComplaint from "../models/DebitPaymentEditModelComplaint";
import store, { DebitPaymentStore } from "../services/DebitPaymentStore";
import flowStore, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import itemsStore, { InvoiceItemStore } from "../../invoiceItems/services/store";
import configuration, { AppConfiguration } from "@/models/Configuration";
import IconButton from "@/components/IconButton.vue";

interface Data {
  store: DebitPaymentStore;
  searchText: string;
  flowStore: InvoiceFlowStore;
  itemsStore: InvoiceItemStore;
  configuration: AppConfiguration;
}

interface Methods {
  clear(): void;
  search(e: Event): void;
  add(item: DebitPaymentEditModelComplaint): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    MoneyDisplay,
    IconButton,
  },

  data() {
    return {
      store,
      flowStore,
      itemsStore,
      searchText: "",
      configuration,
    };
  },

  async beforeMount() {
    await this.store.getDebitPayments(this.flowStore.invoice.partner, "");
  },

  methods: {
    clear() {
      this.searchText = "";
      this.store.debits = [];
    },

    async search(e: Event) {
      e.preventDefault();
      await this.store.getDebitPayments(this.flowStore.invoice.partner, this.searchText);
    },

    async add(item: DebitPaymentEditModelComplaint) {
      this.store.debits = [];
      item.invoiceId = Number(this.$route.params.invoiceId);
      await this.store.createGeneralIngoingInvoiceItem(item);

      this.$nextTick(async () => {
        //await this.store.getDebitPayments(this.flowStore.invoice.partner, this.searchText);
        await this.itemsStore.reloadItems();
      });

      this.clear();
    },
  },

  computed: {
    columns: () => [
      { field: "invId", title: "GENI" },
      { field: "id", title: "Reklamáció" },
      { field: "debitItemId", title: "Reklamáció tétel" },
      { field: "debitId", title: "Terhelési értesítő" },
      { field: "certifNo", title: "Szállítólevél" },
      { field: "partsName", title: "Cikk" },
      { cell: "isRetTemplate", title: "Selejt/visszáru", headerClassName: "center_text" },
      { field: "reQuantity", title: "Viszáruzott mennyiség", headerClassName: "right_text", className: "right_text" },
      {
        field: "fixedQuantity",
        title: "Helyesbített mennyiség",
        headerClassName: "right_text",
        className: "right_text",
      },
      { field: "statusName", title: "Státusz" },
      { cell: "addTemplate", filterable: false, width: 50 },
      { width: 0, filterable: false },
    ],
  },
});
