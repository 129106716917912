export default interface TransactionDTO {
  id: number;
  accEvent: string;
  type: boolean;
  period: number;
  k2dTrId: number;
  externalTrId: string;
  insDate: string;
  userId: number;
  status: number;
  statusName: string;
  remark: string;
  nicname: string;
  accDate: string;
  partner: number;
  partnerName: string;
  storno: boolean;
  transSt: number;
  transType: number;
  typeName: string;
  isMod: boolean;
  storeIn: number;
  gstoreIn: number;
  storeOut: number;
  sourceType: number;
  sourceHead: number;
  isRevalued: boolean;
  oriTrans: number;
  bokSheetOper: string;
  bokPeriodStatus: number;
  bokPeriodStatusName: string;
  selected: boolean;
  periodName: string;
  currency: string;
  bookTypeName: string;
  selectedStatus: boolean;
}

export const transactionDTODefaultProps: TransactionDTO = {
  id: 1,
  accEvent: '',
  type: false,
  period: 1,
  k2dTrId: 1,
  externalTrId: '',
  insDate: '',
  userId: 1,
  status: 1,
  statusName: '',
  remark: '',
  nicname: '',
  accDate: '',
  partner: 1,
  partnerName: '',
  storno: false,
  transSt: 1,
  transType: 1,
  typeName: '',
  isMod: false,
  storeIn: 1,
  gstoreIn: 1,
  storeOut: 1,
  sourceType: 1,
  sourceHead: 1,
  isRevalued: false,
  oriTrans: 1,
  bokSheetOper: '',
  bokPeriodStatus: 1,
  bokPeriodStatusName: '',
  selected: false,
  periodName: '',
  currency: '',
  bookTypeName: '',
  selectedStatus: false,
};