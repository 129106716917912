


















import Vue from "vue";
import itemStore, { InvoiceItemStore } from "@/modules/invoiceItems/services/store";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import CarItem from "../models/CarItem";

interface Data {
  itemStore: InvoiceItemStore;
  flowStore: InvoiceFlowStore;
}

interface Props {
  items: CarItem[];
  type: string;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    items: {
      type: Array as () => CarItem[],
    },

    type: {
      type: String,
      default: "1",
    },
  },

  data() {
    return {
      itemStore,
      flowStore,
    };
  },

  methods: {
    async addItem(item: CarItem) {
      item.netto = this.flowStore.invoice.currNetto - this.itemStore.itemList.netto;
      item.brutto = this.flowStore.invoice.currTotal - this.itemStore.itemList.brutto;
      item.vat = this.flowStore.invoice.currVat - this.itemStore.itemList.tax;

      this.itemStore.addNewCarItem(item);
      this.$emit("invoice-item-add");

      if (flowStore.invoice.type == 1) {
        this.flowStore.invoice.type = Number(this.$props.type);
      }

      this.flowStore.invoice.car = item.car;
      this.flowStore.invoice.prcar = item.prCar;
      this.flowStore.invoice.postCalc = item.postCalc;
      this.flowStore.invoice.inVatCount = true;

      await this.itemStore.save();
    },
  },

  computed: {
    columns: () => [
      // { field: "car", title: "Gépjármű" },
      // { field: "prCar", title: "Gépjármű rendelés" },
      { field: "carBrandName", title: "Gyártó" },
      { field: "modelName", title: "Modell" },
      { field: "bodyNo", title: "Alvázszám", width: "150px" },
      { field: "engineNo", title: "Motorszám" },
      // { field: "regNum", title: "Rendszám" },
      { field: "colorName", title: "Szín" },
      { field: "postCalc", title: "Utókalkuláció" },
      { cell: "commandTemplate", width: "100px" },
      { width: 0 },
    ],
  },
});
