























































































































 import IconButton from "@/components/IconButton.vue";
 import Vue from "vue";
 import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
 import InvoiceImportItem, {InvoiceImportItemDefaultProps} from "@/modules/generalIngoingInvoice/models/InvoiceImportItem";
 import InvoiceFlowApi from "@/modules/invoiceFlow/services/InvoiceFlowApi";
 import store, { InvoiceItemStore } from "../services/store";
 import { groupBy } from "@/utils/array";

interface Data {
   flowStore: InvoiceFlowStore;
   store: InvoiceItemStore;
   groupItems: boolean;
   groupNewItems: boolean;
 }

 interface Computed {
   navItems: InvoiceImportItem[];
   newNavItems: InvoiceImportItem[];
   grouppedNewNavItems: InvoiceImportItem[];
   grouppedNavItems: InvoiceImportItem[];
 }
 
 interface Props {
   invItemId: number;
   isPageDisabled: boolean;
   isGpart: boolean;
 }

 interface Methods {
   addItem(item: InvoiceImportItem): void;
   removeItem(item: InvoiceImportItem): void;
   addGroupItem(item: InvoiceImportItem): void;
   removeGroupItem(item: InvoiceImportItem): void;
 }

 export default Vue.extend<Data, Methods, Computed, Props>({
   components: { IconButton },
   props: {
     invItemId: {
       type: Number
     },
     isPageDisabled: {
       type: Boolean
     },
     isGpart: {
       type: Boolean
     },
   },
 
   data() {
     return {
       flowStore,
       store,
       groupItems: false,
       groupNewItems: false,
     };
   },
 
   methods: {
       addItem(item) {
           item.invoiceItemId = this.invItemId;
           InvoiceFlowApi.updateNavItem(this.invItemId, item.id);
       },

       removeItem(item) {
           item.invoiceItemId = 1;
           InvoiceFlowApi.updateNavItem(1, item.id);
       },

       addGroupItem(item){
         const groupped = groupBy(this.newNavItems, "vatRate");
         const items = groupped[item.vatRate] as InvoiceImportItem[];

         items.forEach(x => {
           this.addItem(x);
         });
       },

       removeGroupItem(item){
         const groupped = groupBy(this.navItems, "vatRate");
         const items = groupped[item.vatRate] as InvoiceImportItem[];

         items.forEach(x => {
           this.removeItem(x);
         });
       }
   },
 
   computed: {
       navItems(){
           return this.flowStore.navItems.filter(x => x.invoiceItemId == this.invItemId);
       },

       newNavItems(){
           const itemIDs = this.store.itemList.items.map(x => x.invoiceItemID);
           return this.flowStore.navItems.filter(x => !itemIDs.some(y => y == x.invoiceItemId));
       },

       grouppedNewNavItems(){
         const groupped = groupBy(this.newNavItems, "vatRate");
         const ret: InvoiceImportItem[] = [];

         Object.keys(groupped).forEach(key => {
           const itemArray = (groupped[key] as InvoiceImportItem[]);
           const groupItem = {...InvoiceImportItemDefaultProps};

           groupItem.vatRate = itemArray[0].vatRate;
           groupItem.netto = itemArray.reduce((sum, x) => x.netto + sum, 0);
           groupItem.vat = itemArray.reduce((sum, x) => x.vat + sum, 0);
           groupItem.brutto = itemArray.reduce((sum, x) => x.brutto + sum, 0);

           ret.push(groupItem);
         });

         return ret;
       },

       grouppedNavItems(){
         const groupped = groupBy(this.navItems, "vatRate");
         const ret: InvoiceImportItem[] = [];

         Object.keys(groupped).forEach(key => {
           const itemArray = (groupped[key] as InvoiceImportItem[]);
           const groupItem = {...InvoiceImportItemDefaultProps};

           groupItem.vatRate = itemArray[0].vatRate;
           groupItem.netto = itemArray.reduce((sum, x) => x.netto + sum, 0);
           groupItem.vat = itemArray.reduce((sum, x) => x.vat + sum, 0);
           groupItem.brutto = itemArray.reduce((sum, x) => x.brutto + sum, 0);

           ret.push(groupItem);
         });

         return ret;
       },
   },
 });
 