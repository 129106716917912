import { InvoiceFlowTaskResponse, SelectedInvoiceFlowTasks } from "../model";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import InvoiceFlowTaskApi from "./InvoiceFlowTaskApi";
import busyIndication from "@/utils/busyIndication";

interface RelatedInvoiceFilter {
  value: boolean | null;
  name: string;
}

export class InvoiceFlowTaskStore {
  tab: string | null = null;
  filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  sort: SortDescriptor[] = [{ field: "entity.payTerm", dir: "asc" }];
  filterText = "";
  invoices = new InvoiceFlowTaskResponse();
  selectedInvoices = new SelectedInvoiceFlowTasks();
  relatedInvoiceFilter: RelatedInvoiceFilter = { value: null, name: "Összes" };

  filterChange(e: { filter: CompositeFilterDescriptor }) {
    this.filter = e.filter;
  }

  sortChange(e: { sort: SortDescriptor[] }) {
    this.sort = e.sort;
  }

  @busyIndication()
  async loadInvoices() {
    this.invoices = await InvoiceFlowTaskApi.getInvoices();
  }

  saveSelectedItems() {
    this.selectedInvoices = new SelectedInvoiceFlowTasks(this.invoices);
  }

  loadSelectedItems() {
    this.invoices = this.selectedInvoices.loadSelectedItems(this.invoices);
  }

  async reloadInvoices() {
    await InvoiceFlowTaskApi.getInvoices().then((result) => {
      if (this.invoices == result) return;
      this.invoices = result;
    });
  }
}

const store = new InvoiceFlowTaskStore();
export default store;
