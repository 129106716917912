export default interface GeneralIngoingInvoiceItemMinEditModel {
    id: number;
    name: string;
    invNo: number;
    unitPrice: number;
    quantity: number;
    unit: string;
    tax: string;
    note: string;
    currNetto: number;
    currVat: number;
    currBrutto: number;
    remaining: number;
    editing: boolean;
    selectedField: boolean;
    expanded: boolean;
    isStockRelated: boolean;
    dim5edit: boolean;
    isService: boolean;
    isCar: boolean;
    savingComment: boolean;
    item: any;
}


export const generalIngoingInvoiceItemMinEditModelDefaultProps: GeneralIngoingInvoiceItemMinEditModel = {
    id: 0,
    name: '',
    invNo: 1,
    unitPrice: 0,
    quantity: 0,
    unit: '-',
    tax: '-',
    note: '',
    currNetto: 0,
    currVat: 0,
    currBrutto: 0,
    remaining: 0,
    editing: true,
    selectedField: false,
    expanded: true,
    isStockRelated: false,
    dim5edit: false,
    isService: false,
    isCar: false,
    savingComment: false,
    item: {},
}