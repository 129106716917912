




























































































































































// Models
import eventBus from "@/services/eventBus";
import permStore, { PermissionStore } from "@/services/permissions";
import flowStore, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import itemStore, {
  InvoiceItemStore,
} from "@/modules/invoiceItems/services/store";
import { getWeight, InvoiceItem } from "@/modules/invoiceItems/models";
import {
  InverseVatSummaryItem,
  TariffNumber,
} from "@/modules/vat/models/index";
import VatApi from "@/modules/vat/services/VatApi";
import * as ItemStoreApi from "@/modules/invoiceItems/services/api";

// Components
import { ConfirmationMixin } from "@/mixins/confirmation";
import IconButton from "@/components/IconButton.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import Confirmation from "@/components/Confirmation.vue";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Data {
  permStore: PermissionStore;
  flowStore: InvoiceFlowStore;
  itemStore: InvoiceItemStore;
  inverseVatItems: InverseVatSummaryItem[];
  tariffNumbers: TariffNumber[];
  expanded: boolean;
}

interface Methods {
  getWeight(item: InverseVatSummaryItem, quantity: number): number;
  addNew(): void;
  deleteAll(): void;
  deleteItem(item: InverseVatSummaryItem): void;
  tariffCheck(): Promise<boolean>;
  generate(): void;
  save(): void;
  itemCurrNettoChanged(item: InverseVatSummaryItem): void;
  itemNettoChanged(item: InverseVatSummaryItem): void;
}

interface Computed {
  hasPermission: boolean;
  disabled: boolean;
  isDifferentCurrency: boolean;
  items: InvoiceItem[];
  itemsTotal: number;
  currTotal: number;
  total: number;
  totalWeight: number;
  error: string;
  warning: string;
}

export default ConfirmationMixin.extend<Data, Methods, Computed, {}>({
  components: {
    IconButton,
    MoneyInput,
    Confirmation,
    ValidationError,
    MoneyDisplay,
  },

  mixins: [ConfirmationMixin],

  data: () => ({
    permStore,
    flowStore,
    itemStore,
    inverseVatItems: [],
    tariffNumbers: [],
    expanded: true,
  }),

  async beforeMount() {
    const invoiceID = Number(this.$route.params.invoiceId);
    this.inverseVatItems = await VatApi.getInverseVatSummary(invoiceID);
    this.tariffNumbers = await VatApi.getTariffNumbers();

    if (this.itemStore.itemList.items.length == 0) {
      const items = await ItemStoreApi.getInvoiceItems(invoiceID);
      this.itemStore.itemList.initialize(
        items,
        this.flowStore.invoice.exchangeRate,
        this.flowStore.invoice.invCurrency
      );
    }
  },

  methods: {
    getWeight,

    addNew() {
      this.inverseVatItems.push(new InverseVatSummaryItem());
    },

    deleteAll() {
      if (!this.hasPermission) {
        return;
      }

      this.inverseVatItems = [];
    },

    deleteItem(item) {
      if (!this.hasPermission) {
        return;
      }

      const index = this.inverseVatItems.indexOf(item);
      if (index > -1) {
        this.inverseVatItems.splice(index, 1);
      }
    },

    async tariffCheck() {
      const items = this.inverseVatItems.filter((x) =>
        this.tariffNumbers.every((y) => x.customCode != y.tariffNo)
      );
      if (!items) {
        return true;
      }

      const confirm = await this.confirm(
        `Adott meg olyan vámtarifaszámot, ami jelenleg nincs a vámtarifa szám törzsben (pl. ${items[0].customCode}). Biztosan folytatja?`
      );
      if (!confirm) {
        return false;
      }

      return true;
    },

    async generate() {
      if (!this.hasPermission) {
        return;
      }

      const confirmation = await this.confirm(
        "Ha folytatja, a jelenlegi megosztás törlésre kerül!"
      );
      if (!confirmation) {
        return;
      }

      await VatApi.putInverseVatSummary(
        Number(this.$route.params.invoiceId)
      ).then(async () => {
        this.inverseVatItems = await VatApi.getInverseVatSummary(
          Number(this.$route.params.invoiceId)
        );
        eventBus.$emit("success", "Sikeres automatikus felosztás.");
      });
    },

    async save() {
      if (!this.hasPermission) {
        return;
      }

      const result = await this.tariffCheck();
      if (!result) {
        return;
      }

      if (this.warning != "") {
        const confirmed = await this.confirm(
          this.warning + " Bizotsan folytatja?"
        );
        if (!confirmed) return;
      }

      await VatApi.postInverseVatSummary(
        Number(this.$route.params.invoiceId),
        this.inverseVatItems
      ).then(async () => {
        this.inverseVatItems = await VatApi.getInverseVatSummary(
          Number(this.$route.params.invoiceId)
        );
        eventBus.$emit("success", "Sikeres felosztás frissítés.");
      });
    },

    itemCurrNettoChanged(item) {
      item.netto = this.flowStore.toDecimal(
        item.currNetto * this.flowStore.invoice.exchangeRate,
        2
      );
    },

    itemNettoChanged(item) {
      item.currNetto = this.flowStore.toDecimal(
        item.netto / this.flowStore.invoice.exchangeRate,
        2
      );
    },
  },

  computed: {
    hasPermission() {
      return (
        this.permStore.hasAccounting() ||
        this.permStore.hasControlling() ||
        this.permStore.hasAccepting()
      );
    },

    disabled() {
      return !this.flowStore.isEditingEnabled || !this.hasPermission;
    },

    isDifferentCurrency() {
      return this.flowStore.invoice.invCurrency != "HUF";
    },

    items() {
      return this.itemStore.itemList.items.filter((x) => x.tax.isInverseTax);
    },

    itemsTotal() {
      return this.items.reduce((acc, current) => acc + current.nettoValue, 0);
    },

    currTotal() {
      return this.inverseVatItems.reduce(
        (acc, current) => acc + current.currNetto,
        0
      );
    },

    total() {
      return this.inverseVatItems.reduce(
        (acc, current) => acc + current.netto,
        0
      );
    },

    totalWeight() {
      return this.inverseVatItems.reduce(
        (acc, current) => acc + current.weight,
        0
      );
    },

    error() {
      if (this.inverseVatItems.length == 0) {
        return "Nincsenek tételek";
      }
      if (this.inverseVatItems.some((x) => x.currNetto == 0)) {
        return "Nulla összeget nem adhat meg";
      }
      if (this.inverseVatItems.some((x) => x.weight == 0)) {
        return "Nulla súlyt nem adhat meg";
      }
      if (this.itemsTotal < this.currTotal) {
        return "Az összérték nagyobb mint a megbontandó összeg!";
      }
      return "";
    },

    warning() {
      if (this.itemsTotal > this.currTotal) {
        return "Nem a teljes összeget bontotta meg!";
      }
      return "";
    },
  },
});
