
























































import IconButton from "../../../components/IconButton.vue";

import configuration from "../../../models/Configuration";
import eventBus from "../../../services/eventBus";
import store, {
  GeneralIngoingInvoiceStore,
} from "../services/GeneralIngoingInvoiceStore";
import {
  IncomingInvoiceValidator,
  OperelIncomingInvoiceValidator,
} from "../../../services/validators/incomingInvoice";
import { InvoiceItemList } from "../../invoiceItems/models";
import GeneralIngoinInvoiceDisplay from "../models/GeneralIngoinInvoiceDisplay";
import api from "../services/GeneralIngoingInvoiceApi";
import GeneralIngoingInvoiceApi from "../services/GeneralIngoingInvoiceApi";
import { OperelPermissions } from "../../../services/permissions";
import InvoicePaymentForm from "./InvoicePaymentForm.vue";
import { CreateInvoicePaymentInput } from "../models/InvoicePayment";
import Confirmation from "../../../components/Confirmation.vue";
import { ConfirmationMixin } from "../../../mixins/confirmation";

enum ViewState {
  Idle = "idle",
  UpdatingPayed = "updatingPayed",
}

export default ConfirmationMixin.extend({
  props: {
    invoice: {
      type: Object as () => GeneralIngoinInvoiceDisplay,
    },
  },

  mixins: [ ConfirmationMixin],

  data: () => ({
    store,
    viewState: ViewState.Idle,
    configuration,
  }),

  components: {
    Confirmation,
    IconButton,
    InvoicePaymentForm,
  },

  computed: {
    approvalGranted() {
      return OperelPermissions.isGranted("invoice_acceptor");
    },
  },

  methods: {
    editInvoice(invoice: GeneralIngoinInvoiceDisplay) {
      const path = configuration.invoiceFlow.enabled
        ? invoice.isStockCorrection
          ? `/uninvoiced-stock-correction/edit/${invoice.incomingInvoice.id}`
          : `/general-incoming-invoice/flow/edit/${invoice.incomingInvoice.id}/2`
        : `/general-incoming-invoice/edit/${invoice.incomingInvoice.partner}/${invoice.incomingInvoice.id}`;

      this.$router.push(path);
    },

    openPicture(invoice: GeneralIngoinInvoiceDisplay) {
      GeneralIngoingInvoiceApi.openInvoicePicture(invoice.incomingInvoice.id);
    },

    isValid(invoice: GeneralIngoinInvoiceDisplay) {
      const manualPrecision: number | undefined =
        !configuration.operel &&
        configuration.sysParams.isGeniHUFItemPrecise &&
        invoice.incomingInvoice.invCurrency === "HUF"
        ? 2
        : undefined;
      const itemList = InvoiceItemList.from(invoice, manualPrecision);
      const validator = configuration.operel
        ? new OperelIncomingInvoiceValidator(
            invoice.incomingInvoice,
            itemList,
            invoice
          )
        : new IncomingInvoiceValidator(invoice.incomingInvoice, itemList);
      const validationError = validator.validate();
      return !validationError;
    },

    approve(
      store: GeneralIngoingInvoiceStore,
      invoice: GeneralIngoinInvoiceDisplay
    ) {
      api.accept(invoice.incomingInvoice, invoice.items).then(() => {
        eventBus.$emit(
          "success",
          `${invoice.incomingInvoice.invoiceNo} számla sikeresen jóváhagyva!`
        );

        eventBus.$emit('incoming-invoices:reload')
      });
    },

    payed() {
      this.viewState = ViewState.UpdatingPayed;
    },

    cancelUpdatingPayed() {
      this.viewState = ViewState.Idle;
    },

    updatePayed(
      store: GeneralIngoingInvoiceStore,
      invoice: GeneralIngoinInvoiceDisplay,
      payment: CreateInvoicePaymentInput
    ) {
      this.viewState = ViewState.Idle;

      api.setPayed(invoice.incomingInvoice.id, payment).then(() => {
        eventBus.$emit(
          "success",
          `${invoice.incomingInvoice.invoiceNo} - teljesítés rögzítve!`
        );

        eventBus.$emit('incoming-invoices:reload')
      });
    },

    async unPayed(
      store: GeneralIngoingInvoiceStore,
      invoice: GeneralIngoinInvoiceDisplay
    ) {

      const confirmed = await this.confirm("Biztosan törli a számla teljesítéseket?")
      if(!confirmed) {
        return
      }

      const success = await api.setUnPayed(invoice.incomingInvoice.id);
      if (success) {
        eventBus.$emit(
          "success",
          `${invoice.incomingInvoice.invoiceNo} - teljesítések törölve!`
        );

        eventBus.$emit('incoming-invoices:reload')
      }
    },
  },
});
