



































































































































































































































































import Vue from "vue";
import InvoiceItemDimension, { invoiceItemDimensionDefaultProps } from "../models/InvoiceItemDimension";
import iStore, { InvoiceGroupStore } from "../../invoiceGroup/services/InvoiceGroupStore";
import store, { InvoiceItemDimensionStore } from "../services/InvoiceItemDimensionStore";
import ConfirmationTemplate from "./ConfirmationTemplate.vue";
import OtherDimensionView from "./OtherDimensionView.vue";
import eventBus from "../../../services/eventBus";
import flowStore, { InvoiceFlowStore } from "../../invoiceFlow/services/InvoiceFlowStore";
import InvoiceGroupDisplay from "../../invoiceGroup/models/InvoiceGroupDisplay";
import ButtonCheckBox from "../../../components/ButtonCheckBox.vue";
import InvoiceItemDimensionApprovalsView from "../../invoiceFlow/views/InvoiceItemDimensionApprovalsView.vue";
import configuration, { AppConfiguration } from "../../../models/Configuration";
import MoneyInput from "../../../components/MoneyInput.vue";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";

interface Data {
  invoiceId: number;
  itemNumber: number;
  itemNetto: number;
  flowStore: InvoiceFlowStore;
  store: InvoiceItemDimensionStore;
  iStore: InvoiceGroupStore;

  dimensionData: InvoiceItemDimension;
  columns: any;
  creating: boolean;

  valid: boolean;

  tick: Date;

  ownDimensions: InvoiceItemDimension[];
  configuration: AppConfiguration;

  isParentCreating: boolean;
  componentKey: number;
}

interface Computed {
  options: object;
  calculateNet: number;
  selectableInvGroups: InvoiceGroupDisplay[];
  getPrecision: number;
}

interface Methods {
  newItemChange(value: InvoiceGroupDisplay, id: number): void;
  saveEdit(item: InvoiceItemDimension): void;
  showOtherDimensions(item: InvoiceItemDimension): void;
  updateItem(item: InvoiceItemDimension): void;
  createDimension(): void;
  save(): void;
  cancel(): void;
  deleteItem(item: InvoiceItemDimension): void;
  confirmDelete(item: InvoiceItemDimension): void;
  cancelDelete(item: InvoiceItemDimension): void;
  addDimension(item: InvoiceItemDimension): void;
  checkValid(e: Event): void;
  checkValidEdit(net: number): void;
  checkValidCreate(net: number): void;

  addInvoiceGroupEventHandler(): void;
  setParentCreating(): void;
  calculateEditNet(item: InvoiceItemDimension): void;
  calculateNetCreate(item: InvoiceItemDimension): void;

  showMessageInParent(invoiceItemName: string): void;
  findInvGroup(invGroup: number): void;
  calculateNetMethod(net: number): number;
}

interface Props {
  isPageDisabled: boolean;
  isStornoItem: boolean;
  invItem: number;
  netto: number;
  isAfterVatConfession: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    isPageDisabled: { type: Boolean, default: true },
    isStornoItem: { type: Boolean, default: false },
    invItem: Number,
    netto: Number,
    isAfterVatConfession: { type: Boolean, default: false },
  },

  components: {
    ConfirmationTemplate,
    OtherDimensionView,
    ButtonCheckBox,
    InvoiceItemDimensionApprovalsView,
    MoneyInput,
    MoneyDisplay,
  },

  data() {
    return {
      componentKey: 0,
      configuration,
      invoiceId: 0,
      itemNumber: 1,
      itemNetto: 0,
      store,
      iStore,
      flowStore,
      oldNet: 0,
      ownDimensions: [],
      tick: new Date(),
      dimensionData: { ...invoiceItemDimensionDefaultProps },

      columns: [
        {
          cell: "selectionTemplate",
          width: 39,
          className: "no_padding_left",
        },
        {
          cell: "detailsButtonTemplate",
          width: 40,
          className: "no_padding_left",
        },
        {
          cell: "addDimensionTemplate",
          width: 39,
          className: "no_padding_left",
        },
        {
          cell: "commandTemplate",
          width: 90,
        },
        {
          title: "Jóváhagyta",
          cell: "approvalsTemplate",
          filterable: false,
          width: 95,
        },
        {
          cell: "invGroupTemplate",
          title: "Költséghely",
        },
        {
          cell: "invGroupNetTemplate",
          title: "Nettó összeg",
        },
        { width: 0, filterable: false, editable: false },
      ],
      creating: false,

      valid: true,

      isParentCreating: false,
    };
  },

  async beforeMount() {
    this.itemNumber = this.$props.invItem;
    this.itemNetto = this.$props.netto;
    this.componentKey = this.$props.invItem + Math.random();

    this.invoiceId = Number(this.$route?.params?.invoiceId ?? 0);
    this.ownDimensions = (await this.store.loadInvoiceItemDimensionsByInvItemId(this.$props.invItem, this.flowStore.step != 6 ? true : false));
  },

  computed: {
    options() {
      return {
        duration: 800,
        offset: 0,
        easing: "easeInOutQuad",
      };
    },

    getPrecision() {
      const curr = this.flowStore.invoice.invCurrency;
      const digits = this.configuration.operel ? 2 : this.configuration.getCurrDigits(curr)?.geniItemDigits;
      return digits ?? 0;
    },

    calculateNet: {
      get() {
        let sum = 0;
        let net = 0;
        for (let i = 0; i < this.ownDimensions.length; i++) {
          sum += Number(this.ownDimensions[i].invGroupNet);
        }

        net = this.itemNetto - sum;

        // if (net < 0) {
        //   net = 0;
        // }
        return Number(net.toFixed(2));
      },
    },

    selectableInvGroups() {
      const flowStore = this.flowStore as InvoiceFlowStore;
      const invoiceGroups = this.iStore.invoiceGroups as InvoiceGroupDisplay[];
      return invoiceGroups;
    },
  },

  created() {
    const eventHandlerName = "InvoiceGroupDimensionCreating" + this.$props.invItem;
    eventBus.$on(eventHandlerName, this.addInvoiceGroupEventHandler);
  },

  beforeDestroy() {
    const eventHandlerName = "InvoiceGroupDimensionCreating" + this.$props.invItem;
    eventBus.$off(eventHandlerName, this.addInvoiceGroupEventHandler);
  },

  methods: {
    calculateNetMethod(num: number) {
      let sum = 0;
      let net = 0;
      for (let i = 0; i < this.ownDimensions.length; i++) {
        sum += Number(this.ownDimensions[i].invGroupNet);
      }
      net = this.itemNetto - sum + num;

      // if (net < 0) {
      //   net = 0;
      // }
      return Number(net.toFixed(2));
    },

    findInvGroup(invGroup: number) {
      const found = this.ownDimensions.find((el) => el.invGroup == invGroup);
      this.showMessageInParent(found?.invGroupName ?? "Ismeretlen költséghely");
    },

    showMessageInParent(invoiceItemName: string) {
      this.$emit("showMessageInParent", invoiceItemName);
      this.componentKey += 1;
    },

    calculateNetCreate(item: InvoiceItemDimension) {
      if (item.invGroupNet == 0) {
        item.invGroupNet = this.calculateNet;
        this.checkValidCreate(item.invGroupNet);
      }
    },

    calculateEditNet(item: InvoiceItemDimension) {
      if (item.invGroupNet == 0) {
        item.invGroupNet = this.calculateNet - item.invGroupNet;
        this.checkValidEdit(item.invGroupNet);
      }
    },

    addInvoiceGroupEventHandler() {
      this.createDimension();
      this.setParentCreating();
    },

    setParentCreating() {
      this.isParentCreating = !this.isParentCreating;
    },

    checkValid(e: Event) {
      if (
        Number((e.target as HTMLTextAreaElement).value) <=
        this.calculateNet /*&&
        Number((e.target as HTMLTextAreaElement).value) > 0*/
      ) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },

    checkValidEdit(net: number) {
      if (Math.abs(net) <= Math.abs(this.calculateNetMethod(net)) && net != 0) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },

    checkValidCreate(net: number) {
      if (Math.abs(net) <= Math.abs(this.calculateNet) && net != 0) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },

    updateItem(item: InvoiceItemDimension) {
      this.store.setEditing(item);
      this.valid = true;

      this.showMessageInParent(item.invGroupName);
      this.$emit("parent-childCreating");
    },

    newItemChange(value: InvoiceGroupDisplay, id: number) {
      const invGroup = this.ownDimensions.find((x) => x.id == id);
      if (invGroup != null) {
        invGroup.invGroupName = value.name;
        invGroup.invGroup = value.id;
      }
    },

    async saveEdit(item: InvoiceItemDimension) {
      this.store.setEditing(item);

      if (this.valid) {
        if (this.flowStore.step == 6) {
          await this.store.updateInvoiceItemDimension(item, true, false);
          if (this.configuration.isInvAccepted.enabled && !this.isAfterVatConfession) {
            this.flowStore.reopen();
          }
        } else {
          await this.store.updateInvoiceItemDimension(item, false, true );
        }
        this.$emit("parent-childCreating");
        this.showMessageInParent("");
        this.$emit("refreshAll");
      }
    },

    showOtherDimensions(item: InvoiceItemDimension) {
      item.otherDimensionDetailOrDelete = !item.otherDimensionDetailOrDelete;
      item.otherDimensionDetail = !item.otherDimensionDetail;
    },

    createDimension() {
      this.valid = false;
      this.creating = true;
      this.dimensionData = { ...this.store.invoiceItemDimension };
      this.dimensionData.invGroupName = "-";
      this.dimensionData.invGroupNet = this.calculateNet;
    },

    async save() {
      this.dimensionData.invItem = this.$props.invItem;
      this.dimensionData.invGroup =
        this.iStore.invoiceGroups.find((x) => x.name === this.dimensionData.invGroupName)?.id ?? 1;

      let ret = null;
      if (this.flowStore.step == 6) {
        ret = (await this.store.createInvoiceItemDimension(this.dimensionData, true, false)) ?? 0;
        if (this.configuration.isInvAccepted.enabled && !this.isAfterVatConfession) {
          this.flowStore.reopen();
        }
      } else {
        ret = (await this.store.createInvoiceItemDimension(this.dimensionData, false, true)) ?? 0;
      }

      if (ret > 0) {
        this.dimensionData.id = ret;
        this.dimensionData.inEdit = false;
        this.dimensionData.deleting = false;
        this.ownDimensions.push(this.dimensionData);
        this.ownDimensions = [...this.ownDimensions];
      }
      this.creating = false;

      this.setParentCreating();

      this.$emit("parent-childCreating");
      this.showMessageInParent("");
      this.$emit("refreshAll");
    },

    cancel() {
      this.creating = false;
      this.setParentCreating();

      this.showMessageInParent("");
      this.$emit("parent-childCreating");
    },

    deleteItem(item: InvoiceItemDimension) {
      item.otherDimensionDetail = false;
      this.store.setDeleting(item);
      this.$emit("parent-childCreating");
    },

    async confirmDelete(item: InvoiceItemDimension) {
      item.deleting = false;
      item.otherDimensionDetailOrDelete = false;

      let ret = null;
      if (this.flowStore.step == 6) {
        ret = await this.store.deleteInvoiceItemDimension(item.id, item.invGroup, true);
        if (this.configuration.isInvAccepted.enabled && !this.isAfterVatConfession) {
          this.flowStore.reopen();
        }
      } else {
        ret = await this.store.deleteInvoiceItemDimension(item.id, item.invGroup, false);
      }

      if (ret) {
        this.ownDimensions = this.ownDimensions.filter((x) => x.id != item.id);
        this.ownDimensions = [...this.ownDimensions];
      }
      this.$emit("parent-childCreating");
      this.$emit("refreshAll");
    },

    cancelDelete(item: InvoiceItemDimension) {
      item.deleting = false;
      item.otherDimensionDetailOrDelete = false;
      this.$emit("parent-childCreating");
    },

    addDimension(item: InvoiceItemDimension) {
      item.otherDimensionDetailOrDelete = true;
      item.otherDimensionDetail = true;

      const createOtherDimensionEventHandlerName = "InvoiceDimensionCreating" + item.id;

      this.$nextTick(function() {
        eventBus.$emit(createOtherDimensionEventHandlerName, item.invGroupName);
      });

      this.showMessageInParent(item.invGroupName);
    },
  },
});
