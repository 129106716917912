export default interface PartnerPriSupl {
  id: number;
  partner: number;
  partnerName: string;
  compInit: boolean;
  compRec: boolean;
  defTransRabat: number;
  langType: number;
  langTypeName: string;
  defaultOfferTemplate: number;
  defaultOfferTemplateName: string;
  defaultOrderTemplate: number;
  defaultOrderTemplateName: string;
  orderTransportRemark: string;
  ratingSum: number;
  preOrderDays: number;
  previewToOrderDays: number;
  orderChangeableDays: number;
  // orderModAlarmDays: number;
  isStrinPrLabel: boolean;
  deleted: boolean;
}

export const partnerPriSuplDefaultProps: PartnerPriSupl = {
  id: 0,
  partner: 1,
  partnerName: "",
  compInit: false,
  compRec: false,
  defTransRabat: 0,
  langType: 1,
  langTypeName: "",
  defaultOfferTemplate: 1,
  defaultOfferTemplateName: "",
  defaultOrderTemplate: 1,
  defaultOrderTemplateName: "",
  orderTransportRemark: "",
  ratingSum: 0,
  preOrderDays: 0,
  previewToOrderDays: 0,
  orderChangeableDays: 0,
  // orderModAlarmDays: 0,
  isStrinPrLabel: false,
  deleted: true, // alapból true, mert a régebbi partnereknél ahol nincs alkatrész beszállító reláció ott így lesz helyes a felületen
};
