









































































import Vue from "vue";
import { ExperimentalInvoiceClass } from "../models/ExperimentalInvoice";
import Email from "@/modules/cashStatement/models/Email";
import http from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import store, { ExperimentalInvoiceStore } from "../services/store";
import CheckBox from "@/components/CheckBox.vue";
import IconButton from "@/components/IconButton.vue";
import eventBus from "@/services/eventBus";

interface Data {
  emails: Email[];
  store: ExperimentalInvoiceStore;
  isCreating: boolean;
  newItem: Email;
  isLoading: boolean;
}

interface Props {
  invoice: ExperimentalInvoiceClass;
}

interface Methods {
  getEmails(): Promise<Email[]>;
  isSelected(email: Email): boolean;
  setSelected(email: Email): void;
  setEmail(email: Email, value: object): void;
  emailRules(value: string): string;
  saveEmail(): void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: {
    CheckBox,
    IconButton,
  },

  props: {
    invoice: ExperimentalInvoiceClass,
  },

  data: () => {
    return {
      emails: new Array<Email>(),
      store,
      isCreating: false,
      newItem: new Email(),
      isLoading: false,
    };
  },

  async beforeMount() {
    this.emails = await this.getEmails().then((x) => {
      this.invoice.emails.push(
        ...x.filter((y) => y.isPrimary && !x.includes(y))
      );
      return x;
    });
  },

  methods: {
    async saveEmail() {
      try {
        this.isLoading = true;
        await this.store.saveEmail([this.newItem], this.invoice.pinvID);
        if (this.emailRules(this.newItem.email)) {
          this.emails.push(this.newItem);
        }
        eventBus.$emit("success", "Sikeres mentés!");
      } catch (ex) {
        eventBus.$emit("error", "Nem sikerült a mentés!");
      } finally {
        this.isLoading = false;
      }
    },

    emailRules(value: string) {
      if (value.length < 1) {
        return "Kötelező kitölteni!";
      }

      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value.toLowerCase()
      )
        ? ""
        : "Hibás e-mail cím!";
    },

    setEmail(email, value) {
      email.email = String(value);
    },

    setSelected(email) {
      email.isSelected = !email.isSelected;
      if (this.invoice.emails.includes(email)) {
        this.invoice.emails = this.invoice.emails.filter(
          (x) => x.email !== email.email
        );
        this.$emit("input", this.invoice);
        return;
      }
      this.invoice.emails.push(email);
      this.$emit("input", this.invoice);
    },

    isSelected(email) {
      console.log(
        this.invoice.emails.map((x) => x.email).includes(email.email)
      );
      return this.invoice.emails.map((x) => x.email).includes(email.email);
    },

    async getEmails() {
      const emails = await this.store.getSelectableEmails(
        this.$props.invoice.partnerID,
        this.invoice.prefix
      );
      return emails;
    },
  },
});
