var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApiSearchPage',{attrs:{"title":"Előlegbekérők","create":"Új előlegbekérő","createButton":true,"url":"down-payment/search","columns":_vm.columns,"refresh":"","filterable":false},on:{"create":function($event){return _vm.create()},"refresh":function($event){return _vm.store.loadDownPayments()}},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(item.id)+" "),(item.hasStockRelation)?_c('tooltip',{attrs:{"tooltip":"Jóváhagyott megrendelés kapcsolódik hozzá","show":item.hasStockRelation},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_c('v-icon',_vm._g({staticClass:"ml-1",staticStyle:{"vertical-align":"baseline"},attrs:{"color":"black","x-small":""}},props.on),[_vm._v(" fa-archive ")])]}}],null,true)}):_vm._e(),(item.hasPayment)?_c('tooltip',{attrs:{"tooltip":"Fizetés tartozik hozzá","show":item.hasPayment},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_c('v-icon',_vm._g({staticClass:"ml-1",staticStyle:{"vertical-align":"baseline"},attrs:{"color":"black","x-small":""}},props.on),[_vm._v(" fa-money ")])]}}],null,true)}):_vm._e()],1)]}},{key:"permitStatus",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{staticClass:"white--text",attrs:{"value":item.permitStatusID,"status":item.permitStatus,"coloring":_vm.store.permitStatusColors}})]}},{key:"preStatus",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{staticClass:"white--text",attrs:{"value":item.preStatusID,"status":item.preStatus,"coloring":_vm.store.preStatusColors}})]}},{key:"command",fn:function(ref){
var item = ref.item;
return [_c('icon-button',{attrs:{"tooltip":"Szerkesztés","icon":"fa-edit"},on:{"click":function($event){return _vm.editItem(item)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }