var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"removeTopBorder",attrs:{"elevation":0}},[_c('v-card-text',{class:{'no-padding': _vm.noPadding}},[_c('ApiSearchPage',_vm._b({ref:"asref",attrs:{"url":_vm.url,"columns":_vm.columns,"itemText":"combined","extraFilter":_vm.filter,"search":false,"delay":1000,"refresh":false,"resetSort":false,"resetFilter":false,"detailsField":"selected","title":_vm.showTitle ? _vm.title : undefined,"name":'experimentalinvoices'},on:{"items":_vm.setItems},scopedSlots:_vm._u([{key:"pinvID",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticStyle:{"height":"100% !important"},on:{"mouseover":function($event){item.hover = true},"mouseleave":function($event){item.hover = false}}},[_c('v-expand-x-transition',[(item.isEInvoice)?_c('div',{staticClass:"hover-p"},[_c('v-col',{staticClass:"cold",attrs:{"sm":2,"align":"left"}},[_c('div',{class:{ 'width-set': item.hover },staticStyle:{"height":"49px","background-color":"var(--primaryColor)","display":"flex","color":"white","justify-content":"center","align-items":"center","padding":"1em","transition":"1s all cubic-bezier(0.075, 0.82, 0.165, 1)"}},[_vm._v(" "+_vm._s(item.hover ? "Elektronikus számla" : "E")+" ")])])],1):_vm._e()]),_c('v-col',{staticClass:"cold mr-10",staticStyle:{"margin-top":"auto","margin-bottom":"auto"},attrs:{"align":"right"}},[_c('span',[_vm._v(_vm._s(item.pinvID))])])],1)]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTimeAndDate(item))+" ")]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneyFormat")(item.total,"", 2))+" ")]}},{key:"toPayment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneyFormat")(item.total,"", 2))+" ")]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"documents-info"},[_c('div',{staticClass:"px-1 pb-3 pt-1"},[_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center","align-items":"center"}},[(_vm.reportLoading)?_c('v-progress-circular',{staticStyle:{"text-align":"center"},attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(!_vm.isGenerated && !_vm.reportLoading)?_c('CreateInvoiceFromExperimentalView',{attrs:{"inStatement":_vm.inStatement,"invoice":item},on:{"generated":function (ref) {
                                var extIdInvoice = ref.extIdInvoice;
                                var cashDocId = ref.cashDocId;
                                var isCashDoc = ref.isCashDoc;

                                return _vm.generated(extIdInvoice, cashDocId, item, isCashDoc);
},"input":function (value) { return _vm.setItemValue(value, item); },"credit-card-pay":function (val) { return _vm.setCreditCardPay(val); }}}):_vm._e(),(
                _vm.experimentInvoiceStore.reportSrc.length > 1 && !_vm.reportLoading
              )?_c('FinalInvoiceFromExperimentalReportViewer',{on:{"exit":_vm.exitFinalInvoicePdf}}):_vm._e()],1)])]}},{key:"selectButton",fn:function(ref){
              var item = ref.item;
return [_c('IconButton',{staticStyle:{"min-width":"32px !important","margin-left":"10px"},on:{"click":function($event){return _vm.showDetails(item)}}})]}}])},'ApiSearchPage',_vm.$attrs,false))],1),(_vm.inStatement)?_c('v-card-actions',{class:{'bg-white': _vm.noActionsColor, 'removeTopBorder': _vm.noActionsColor}},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary"},on:{"click":_vm.refresh}},[_vm._v("Frissítés")]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Mégse")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }