import busyIndication from "@/utils/busyIndication";
import TransactionDTO, { transactionDTODefaultProps } from "../models/TransactionDTO";
import TransactionApi from "./TransactionApi";

export class TransactionStore {
  transaction: TransactionDTO = { ...transactionDTODefaultProps }
  bookTypes: any[] = [];
  transStats: any[] = [];
  transTypes: any[] = [];
  currencies: any[] = [];
  periods: any[] = [];


  @busyIndication()
  async getTransaction(id: number) {
    this.transaction = await TransactionApi.get(id);
  }

  async getBookTypes() {
    this.bookTypes = await TransactionApi.getBookTypes();
  }

  async getTransStats() {
    this.transStats = await TransactionApi.getTransStats();
  }

  async getTransTypes() {
    this.transTypes = await TransactionApi.getTransTypes();
  }

  async getCurrencies() {
    this.currencies = await TransactionApi.getCurrencies();
  }

  async getPeriods(year: string) {
    this.periods = await TransactionApi.getPeriods(year);
    console.log(this.periods)
  }

  async save(id: number) {
    return await TransactionApi.save(id, this.transaction);
  }
}

const store = new TransactionStore();
export default store;