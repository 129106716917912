





















































































































































































































































































































































































































































































































































































































import Page from "../../../components/Page.vue";

import InvoiceGroupDisplay from "../../invoiceGroup/models/InvoiceGroupDisplay";
import User from "../../invoiceGroup/models/User";
import Country from "../../cashPoint/models/Country";
import Partner from "../../partner/models/Partner";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import PartnerTax from "../models/PartnerTax";
import Tax from "../models/Tax";
import IncomingInvoiceSubtype from "../models/IncomingInvoiceSubtype";
import PaymentModeDisplay from "../../paymentMode/models/PaymentModeDisplay";
import Attachment from "../models/Attachment";

import store, { GeneralIngoingInvoiceStore } from "../services/GeneralIngoingInvoiceStore";

import GeneralIngoingInvoiceApi from "../services/GeneralIngoingInvoiceApi";

import itemsStore, { InvoiceItemStore } from "../../invoiceItems/services/store";

import { InvoiceItemsTable } from "../../invoiceItems";
import OperelInvoiceItemActions from "../../invoiceItems/views/OperelInvoiceItemActions.vue";

import InvoicePictureUpload from "./InvoicePictureUpload.vue";
import { OperelIncomingInvoiceValidator } from "../../../services/validators/incomingInvoice";
import { OperelPermissions } from "../../../services/permissions";
import IconButton from "../../../components/IconButton.vue";
import MoneyInput from "../../../components/MoneyInput.vue";
import InvoicePayments from "./InvoicePayments.vue";
import Tabs from "../../../components/Tabs.vue";
import Confirmation from "../../../components/Confirmation.vue";
import { shouldAutoApproveByPaymentMode } from "../../paymentMode/models/PaymentMode";
import { ConfirmationMixin } from "../../../mixins/confirmation";
import { debounce } from "debounce";
import OperelInvoiceDimensions from "./OperelInvoiceDimensions.vue";
import configuration from "../../../models/Configuration";

interface Data {
  store: GeneralIngoingInvoiceStore;
  itemsStore: InvoiceItemStore;
  selectedInvoiceGroup: InvoiceGroupDisplay | undefined;
  selectedUser: User | undefined;
  selectedPaymentMode: PaymentModeDisplay | undefined;
  selectedCurrency: Country | undefined;
  accountsTake: number;
  accountsSkip: number;
  banksDialog: boolean;
  bankAccount: string;
  partnerSearchDialog: boolean;
  partnerDetailsVisible: boolean;
  detailsVisible: boolean;
  selectedPartnerTax: PartnerTax | undefined;
  selectedTax: Tax | undefined;
  selectedSubType: IncomingInvoiceSubtype | undefined;
  selectedNoticeType: any;
  selectedNoticeGenoType: any;
  isPartnerReadOnly: boolean;
  isCurrencyReadOnly: boolean;
  isItemPricesEditable: boolean;
  pricesSaveButtonEnabled: boolean;
  isTaxComboBoxEnabled: boolean;
  fileNote: string;
  activeTab: any;
  validationErrors: Record<string, string>;
  updateValidation: any;
  saved: boolean;
  defaultExchangeRate: number;
}

interface Methods {
  selectedSubTypeChanged(): void;
  selectedPartnerTaxChanged(): void;
  selectedPaymentModeChanged(): void;
  selectedCurrencyChanged(): void;
  partBanksIsAliveChanged(): void;
  selectedBankChanged(event: any): void;
  accountsPageChange(event: any): void;
  formatDate(date: any): string;
  addDays(date: Date, days: number): Date;
  selectedTaxChanged(tax: Country): void;
  update(autoApprovalConfirmed?: boolean): Promise<void>;
  partnerSelected(partner: Partner): void;
  cancel(): void;
  roundNumber(number: number): number;
  setSpecTax(init: boolean): void;
  setIncorrect(): void;
  reopen(): void;
  accept(): void;
  deleteAttachment(item: Attachment): void;
  openInvoicePicture(): void;
  confirmThenUpdate(): void;
  load(): Promise<void>;
}

export default ConfirmationMixin.extend<Data, Methods, {}, {}>({
  components: {
    Page,
    PartnerSearch,
    OperelInvoiceItemActions,
    InvoiceItemsTable,
    InvoicePictureUpload,
    IconButton,
    MoneyInput,
    InvoicePayments,
    Tabs,
    Confirmation,
    OperelInvoiceDimensions,
  },

  mixins: [ConfirmationMixin],

  data(): Data {
    return {
      store,
      itemsStore,
      selectedInvoiceGroup: undefined,
      selectedUser: undefined,
      accountsTake: 10,
      accountsSkip: 0,
      banksDialog: false,
      bankAccount: "",
      partnerSearchDialog: false,
      partnerDetailsVisible: false,
      detailsVisible: false,
      selectedPartnerTax: undefined,
      selectedSubType: undefined,
      selectedPaymentMode: undefined,
      selectedCurrency: undefined,
      selectedNoticeType: undefined,
      selectedNoticeGenoType: undefined,
      selectedTax: undefined,
      isPartnerReadOnly: false,
      isCurrencyReadOnly: false,
      isItemPricesEditable: true,
      pricesSaveButtonEnabled: true,
      isTaxComboBoxEnabled: true,
      fileNote: "",
      activeTab: null,
      validationErrors: {},
      updateValidation: null,
      saved: false,
      defaultExchangeRate: 0
    };
  },

  async beforeMount() {
    this.load();

    this.updateValidation = debounce(async () => {
      const { errors } = await GeneralIngoingInvoiceApi.validateInvoiceParams({
        invoiceNo: this.store.invoice.invoiceNo,
        partnerID: this.store.invoice.partner,
      });

      this.validationErrors = errors;

      
    }, 500);
  },

  updated(){
    if(this.defaultExchangeRate > 1){
        this.store.invoice.exchangeRate = this.defaultExchangeRate;
      }
  },

  methods: {
    async load() {
      const partnerId = Number(this.$route.params.partnerId);
      const invoiceId = Number(this.$route.params.invoiceId) || 0;

      await Promise.all([
        this.store.loadInvoiceDetails(partnerId),
        this.store.loadPartnerTaxes(),
        this.store.loadSubTypes(),
        this.store.loadPaymentModes(),
        this.store.loadCurrencies(),
        this.store.loadTaxes(partnerId),
        this.store.loadInvoice(invoiceId),
      ]);

      this.defaultExchangeRate = this.store.invoice.exchangeRate;

      if (configuration.operel && this.store.invoice.id == 0) {
        await this.store.loadOperelPaymodeForPartner(Number(this.$route.params.partnerId));
      }

      if (this.store.invoice.id == 0) {
        this.store.invoice.partner = partnerId;

        this.store.invoice.payTerm = this.formatDate(new Date());
        this.store.invoice.invoiceDate = this.formatDate(new Date());
        this.store.invoice.shipDate = this.formatDate(new Date());
        this.store.invoice.regDate = this.formatDate(new Date());
        this.store.invoice.arrivDate = this.formatDate(new Date());
        this.store.invoice.accDate = this.formatDate(new Date());
        this.store.invoice.insertDate = this.formatDate(new Date());
        this.store.invoice.insertTime = this.formatDate(new Date());
        this.store.invoice.vatDate = this.formatDate(new Date());
      }

      await this.store.loadPartBanks();

      this.selectedPartnerTax = this.store.partnerTaxes.find((x) => x.id == this.store.invoice.partnerTax);
      if (!this.selectedPartnerTax && this.store.partnerTaxes.length > 0) {
        this.selectedPartnerTax = this.store.partnerTaxes[0];
        this.store.invoice.partnerTax = this.selectedPartnerTax.id;
      }

      this.selectedSubType = this.store.subTypes.find((x) => x.id == this.store.invoice.subType);
      if (!this.selectedSubType && this.store.subTypes.length > 0) {
        const defaultItem = this.store.subTypes.find((x) => x.isDefault);
        if (defaultItem) {
          this.selectedSubType = defaultItem;
          this.store.invoice.subType = this.selectedSubType.id;
        } else {
          this.selectedSubType = this.store.subTypes[0];
          this.store.invoice.subType = this.selectedSubType.id;
        }
      }
 
      this.selectedPaymentMode = this.store.pickCurrentPaymode();
      this.selectedPaymentModeChanged();
      this.selectedCurrency = this.store.pickCurrentCurrency();

      this.store.invoice.invCurrency = this.selectedCurrency?.currency?.toUpperCase()?.trim();

      if(this.store.invoice.exchangeRate<2){
      this.selectedCurrencyChanged();
      }

      this.selectedTax = this.store.pickCurrentTax();

      const partBank = this.store.partBanks.find((x) => x.id == this.store.invoice.bankAccount);
      if (partBank) {
        this.bankAccount = partBank.accountNo;
      } else if (this.store.partBanks.length == 1) {
        this.bankAccount = this.store.partBanks[0].accountNo;
        this.store.invoice.bankAccount = this.store.partBanks[0].id;
      }

      this.store.invoiceItems.forEach((x) => {
        x.selectedTax = this.store.taxes.find((y) => y.id == x.incomingInvoiceItem.tax);
      });

      this.store.invoice.isProjectItem = this.store.projectData.isProjectItem;
      this.store.invoice.isPublicProjectItem = this.store.projectData.isPublicProjectItem;

      this.setSpecTax(true);

      this.store.invDateStart = "";
      this.store.invDateEnd = "";
      this.store.accDateStart = "";
      this.store.accDateEnd = ""; 

      
    },

    selectedBankChanged(event: any) {
      this.store.invoice.bankAccount = event.dataItem.id;
      this.bankAccount = event.dataItem.accountNo;
      this.banksDialog = false;
    },

    selectedPartnerTaxChanged() {
      this.store.invoice.partnerTax = this.selectedPartnerTax?.id ?? 1;
    },

    selectedSubTypeChanged() {
      this.store.invoice.subType = this.selectedSubType?.id ?? 1;
    },

    selectedPaymentModeChanged() {
      this.store.invoice.paymode = this.selectedPaymentMode?.id ?? 1;
    },

    selectedCurrencyChanged() {
      if(typeof this.selectedCurrency === 'string'){
        this.store.invoice.invCurrency = this.selectedCurrency;
      }
      else{
      this.store.invoice.invCurrency = this.selectedCurrency?.currency?.toUpperCase()?.trim() ?? "";
      }
      this.store.loadRate();

      this.itemsStore.update({ currency: this.store.invoice.invCurrency });
    },

    async partnerSelected(partner: Partner) {
      this.store.invoice.partner = partner.id;

      await this.store.loadInvoiceDetails(partner.id);
      await this.store.loadPartBanks();
      await this.store.loadTaxes(partner.id).then((_) => {
        this.setSpecTax(false);
      });

      this.partnerSearchDialog = false;
      this.selectedCurrency = this.store.pickCurrentCurrency();
      this.selectedTax = this.store.pickCurrentTax();
      this.selectedPaymentMode = this.store.pickCurrentPaymode();
    },

    selectedTaxChanged(tax: Country) {
      this.store.invoice.tax = tax.id;
    },

    partBanksIsAliveChanged() {
      this.store.loadPartBanks();
      this.accountsSkip = 0;
    },

    accountsPageChange(event: any) {
      this.accountsSkip = event.page.skip;
      this.accountsTake = event.page.take;
    },

    deleteAttachment(item: Attachment) {
      const index = this.store.attachments.indexOf(item);
      if (index > -1) {
        this.store.attachments.splice(index, 1);
      }
    },

    formatDate(date: any) {
      if (date) {
        const mm = date.getMonth() + 1;
        const dd = date.getDate();

        return [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("-");
      }

      return "";
    },

    addDays(date: Date, days: number) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    async confirmThenUpdate() {
      const validator = new OperelIncomingInvoiceValidator(this.store.invoice, this.itemsStore.itemList);

      let shouldSave = true;
      if (validator.shouldConfirmInvoiceNetto()) {
        shouldSave = await this.confirm("A számla nettó összege 0! Biztosan menti az adatokat?");
      }

      if (!shouldSave) {
        return;
      }

      if (validator.shouldConfirmInvoiceValues()) {
        shouldSave = await this.confirm(
          "A számla értéke meghaladja a készlet nettó értékét! Biztosan menti az adatokat?"
        );
      }

      if (!shouldSave) {
        return;
      }

      let autoApprovalConfirmed = false;
      if (this.store.invoice.id <= 1 && shouldAutoApproveByPaymentMode(this.store.invoice.paymode)) {
        autoApprovalConfirmed = await this.confirm(
          "A beállított fizetési mód alapján a számla automatikusan jóváhagyható. Szeretné a mentés mellett a számlát automatikusan jóváhagyni?"
        );
      }

      this.update(autoApprovalConfirmed).then(() => {
        this.$router.push(`/general-incoming-invoice/edit/${this.store.invoice.partner}/${this.store.invoice.id}`);

        this.load();
      });
    },

    async update(autoApprovalConfirmed = false) {
      if (this.store.invoice.id > 1) {
        const success = await this.itemsStore.save();
        if (success) {
          await this.store.update(autoApprovalConfirmed);
          this.saved = true;
        }
      } else {
        let invoiceID: number | undefined = await this.store.update(autoApprovalConfirmed);
        invoiceID = invoiceID ?? this.store.invoice.id;

        this.itemsStore.invoiceID = invoiceID;
        await this.itemsStore.save();
        this.store.invoice.id = invoiceID;
        this.saved = true;
      }
    },

    cancel() {
      this.$router.push(`/general-incoming-invoice`);
    },

    roundNumber(number: number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    },

    setSpecTax(init: boolean) {
      if (this.store.invoiceDetails.partnerIsSpecTax) {
        this.store.invoice.specTax = true;
        this.store.invoice.specTaxType = this.store.invoiceDetails.partnerSpecTaxType;
        this.store.invoice.tax = this.store.invoiceDetails.tax;
      } else {
        if (this.store.invoice.id <= 1) {
          this.store.invoice.specTax = false;
          this.store.invoice.specTaxType = 1;
          this.store.invoice.tax = this.store.invoiceDetails.defaultTax;
        }
      }

      this.selectedTax = this.store.pickCurrentTax();
    },

    async setIncorrect() {
      const confirmed = await this.confirm("Biztosan beállítja a 'Téves rögzítés' státuszt ?");
      if (!confirmed) {
        return;
      }

      this.store.setIncorrect().then((_) => {
        this.$router.push(`/general-incoming-invoice`);
      });
    },

    async reopen() {
      if (configuration.operel) {
        await this.store.reopenOperel().then((_) => this.load());
      } else {
        await this.store.reopen().then((_) => window.location.reload());
      }
    },

    async accept() {
      if (configuration.operel) {
        this.update().then(() => {
          if (this.saved) {
            this.saved = false;
            this.store.accept().then((_) => {
              this.$router.push(`/general-incoming-invoice`);
            });
          }
        });
      } else {
        this.store.accept().then((_) => {
          this.$router.push(`/general-incoming-invoice`);
        });
      }
    },

    openInvoicePicture() {
      GeneralIngoingInvoiceApi.openInvoicePicture(this.store.invoice.id);
    },
  },

  computed: {
    bankItems() {
      return this.store.partBanks.slice(this.accountsSkip, this.accountsTake + this.accountsSkip).map((item) => ({
        ...item,
        selected: item.id === this.store.invoice.bankAccount,
      }));
    },

    accountsTotal() {
      return this.store.partBanks ? this.store.partBanks.length : 0;
    },

    stockOnAccept() {
      return this.selectedSubType?.stockOnAccept;
    },

    isIncorrectRecordingEnabled() {
      return this.store.invoice.id > 1 && !this.store.invoice.isPayed;
    },

    isInverseTax() {
      return this.selectedTax?.isInverseTax;
    },

    isReopenEnabled() {
      const stockOnAccept = this.selectedSubType?.stockOnAccept;
      return (this.store.invoice.preStatus == 8 || this.store.invoice.preStatus == 6) && !stockOnAccept;
    },

    isEditingEnabled() {
      return !this.store.invoice.isAccepted;
    },

    isAcceptable() {
      if (!OperelPermissions.isGranted("invoice_acceptor")) {
        return false;
      }

      return !(this.store.invoice.preStatus == 8 || this.store.invoice.preStatus == 6);
    },

    canSave() {
      const validator = new OperelIncomingInvoiceValidator(this.store.invoice, this.itemsStore.itemList);
      return validator.canSave();
    },

    canApprove() {
      const validator = new OperelIncomingInvoiceValidator(this.store.invoice, this.itemsStore.itemList);
      return validator.canApprove();
    },

    validationError() {
      const validator = new OperelIncomingInvoiceValidator(this.store.invoice, this.itemsStore.itemList);
      return validator.validate();
    },
  },
});
