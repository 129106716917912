import FixAssetMainGroup from "@/modules/fixAssetGroup/models/FixAssetMainGroup";
import http from "@/services/http";
import { toBase64 } from "@/utils/fileFunctions";
export class FixAssetType {
  id = 1;
  name = "-";
}

export class FixAssetDocumentData {
  id = 0;
  assetID = 0;
  description = "";
  document: string | ArrayBuffer | null = null;
  extension: string | null = null;
  hasDocument = false;
  link = "";
  file: File | null = null;

  constructor(props?: Partial<FixAssetDocumentData>) {
    const data = { ...props };
    Object.assign(this, data);
    this.link = this.hasDocument
      ? http.defaults.baseURL + `fix-asset/picture/${this.id}`
      : ``;
  }

  async toDto(): Promise<FixAssetDocumentData> {
    const base64 = this.file ? await toBase64(this.file) : null;
    const nameSplit = this.file?.name.split(".") ?? [];
    return base64
      ? {
        ...this,
        document: base64,
        extension: nameSplit[nameSplit.length - 1].toUpperCase() ?? null,
      }
      : this;
  }
}

export class FixAssetData {
  id = 0;
  type = 1;
  description = "";
  responsibleUser = 1;
  stockResponsibleUser = 1;
  originalStock = 1;
  documents: FixAssetDocumentData[] = [];
  mainGroup = 1;
  group = 1;
  status? = "";
  name? = "";

  constructor(props?: Partial<FixAssetData>, stockID?: number) {
    const data = { ...props };
    Object.assign(this, data);
    this.originalStock = stockID ?? this.originalStock ?? 0;
    this.documents =
      this.documents?.map((d) => new FixAssetDocumentData(d)) ?? [];
  }

  static CreateByStockID(stockID?: number): FixAssetData {
    const res = new FixAssetData();
    res.id = 0;
    res.type = 1;
    res.description = "";
    res.responsibleUser = 1;
    res.stockResponsibleUser = 1;
    res.originalStock = 1;
    res.documents = [];
    res.mainGroup = 1;
    res.group = 1;

    return res;
  }
}
