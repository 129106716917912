import Partner, { partnerDefaultProps } from "../models/Partner";
import PartnerApi from "./PartnerApi";
import User from "@/modules/partner/models/User";
import PartnerType from "@/modules/partner/models/PartnerType";
import PartnerStatus from "../models/PartnerStatus";
import SpecialTax, { specialTaxDefaultProps } from "../models/SpecialTax";
import PaymentMode from "@/modules/paymentMode/models/PaymentMode";
import CompanyForm, { companyFormDefaultProps } from "../models/CompanyForm";
import PayClass from "../models/PayClass";
import PartnerGroup1 from "../models/PartnerGroup1";
import PartnerGroup2 from "../models/PartnerGroup2";
import PartBank, { partBankDefaultProps } from "../../cashPoint/models/PartBank";
import PartSupplier, { partSupplierDefaultProps } from "../models/PartSupplier";
import PartEmployee, { partEmployeeDefaultProps } from "../models/PartEmployee";
import PartCust, { partCustDefaultProps } from "../models/PartCust";
import Country from "@/modules/cashPoint/models/Country";
import CashPointApi from "@/modules/cashPoint/services/CashPointApi";
import Bank, { bankDefaultProps } from "@/modules/bank/models/Bank";
import BankApi from "@/modules/bank/services/BankApi";
import SuplType from "../models/SuplType";
import PartPerson, { partPersonDefaultProps } from "../models/PartPerson";
import { StoreBase } from "../../../services/storebase";
import busyIndication from "../../../utils/busyIndication";
import PartnerEuTax from "../models/PartnerEuTax";
import BisnodePartner, { bisnodePartnerDefaultProps } from "../models/BisnodePartner";
import QuickSearchItem from "@/modules/partner/models/QuickSearchItem";
import PrePartnerName from "../models/PrePartnerName";
import StreetType from "../models/StreetType";
import PartnerCustoms, { partnerCustomsDefaultProps } from "../models/PartnerCustoms";
import PartnerPriSupl, { partnerPriSuplDefaultProps } from "../models/PartnerPriSupl";
import City from "@/modules/city/models/City";
import PartnerWorkflow, { partnerWorkflowDefaultProps } from "../models/PartnerWorkflow";
import SysParam from "../models/SysParam";
import BalanceStatementEmail from "../models/BalanceStatementEmail";

export class PartnerStore extends StoreBase {
  partners: Partner[] = [];
  partner: Partner = { ...partnerDefaultProps };
  partnersCount = 0;
  partnerEuTaxes: PartnerEuTax[] = [];
  users: User[] = [];
  partnerTypes: PartnerType[] = [];
  partnerStatuses: PartnerStatus[] = [];
  specialTaxes: SpecialTax[] = [];
  payModes: PaymentMode[] = [];
  custTypes: any[] = [];
  companyForms: CompanyForm[] = [];
  payClasses: PayClass[] = [];
  partnerGroups1: PartnerGroup1[] = [];
  partnerGroups2: PartnerGroup2[] = [];
  partBanks: PartBank[] = [];
  partBank: PartBank = { ...partBankDefaultProps };
  partSuppliers: PartSupplier[] = [];
  partSupplier: PartSupplier = { ...partSupplierDefaultProps };
  partEmployee: PartEmployee = { ...partEmployeeDefaultProps };
  partCust: PartCust = { ...partCustDefaultProps };
  currencies: Country[] = [];
  countries: Country[] = [];
  banks: Bank[] = [];
  suplTypes: SuplType[] = [];
  partPersons: PartPerson[] = [];
  partPerson: PartPerson = { ...partPersonDefaultProps };
  workflows: PartnerWorkflow[] = [];
  workflowIsActive = false;
  balanceStatementEmails: BalanceStatementEmail[] = [];

  //additional partner properties
  inEdit = false;
  isCashAccounting = false;

  //partnerPriSupl
  partnerPriSupl: PartnerPriSupl = { ...partnerPriSuplDefaultProps };

  bisnodePartners: BisnodePartner = { ...bisnodePartnerDefaultProps };
  quickSearchItems: QuickSearchItem[] = [];
  partnerCustoms: PartnerCustoms[] = [];
  partnerCustom: PartnerCustoms = { ...partnerCustomsDefaultProps };

  //backups
  partnersBackup: Partner[] = [];
  partEmployeeBackup: PartEmployee[] = [];
  partCustBackup: PartCust[] = [];

  //extendedPartnerInfo
  prePartnerNames: PrePartnerName[] = [];
  streetTypes: StreetType[] = [];

  async sendBalanceEmails() {
    await PartnerApi.sendBalanceEmails();
  }

  // @busyIndication()
  async loadPartners(pageNumber: number, pageSize: number, isCustoms: boolean) {
    const result = await PartnerApi.getAll(pageNumber, pageSize, isCustoms);
    this.partners = result.partners;
    this.partnersCount = result.count;
  }

  // @busyIndication()
  async loadPartnersByName(partnerName: string, pageNumber: number, pageSize: number, isCustoms: boolean) {
    const result = await PartnerApi.getAllByName(partnerName, pageNumber, pageSize, isCustoms);
    this.partners = result.partners;
    this.partnersCount = result.count;
  }

  @busyIndication()
  async loadGeneralPartners(pageNumber: number, pageSize: number, isCustoms: boolean) {
    const result = await PartnerApi.getAllGeneral(pageNumber, pageSize, isCustoms);
    this.partners = result.partners;
    this.partnersCount = result.count;
  }

  @busyIndication()
  async loadGeneralPartnersByName(partnerName: string, pageNumber: number, pageSize: number, isCustoms: boolean) {
    const result = await PartnerApi.getAllByNameGeneral(partnerName, pageNumber, pageSize, isCustoms);
    this.partners = result.partners;
    this.partnersCount = result.count;
  }

  async loadPartnersWithoutPage() {
    this.partners = await PartnerApi.getAllWithoutPage();
  }

  @busyIndication()
  async loadMinPartnersWithoutPage() {
    this.partners = await PartnerApi.getAllMinWithoutPage();
  }

  // @busyIndication()
  async loadPartner(id: number) {
    //this.partner = id > 1 ? await PartnerApi.get(id) : await PartnerApi.getNew()
    const result = await PartnerApi.get(id);
    this.partner = { ...result };
    this.inEdit = false;
  }

  async updatePartner(item: Partner) {
    this.partner.euTaxAccount = String(this.partner.euTaxAccount);

    try {
      this.partner = item;
      await PartnerApi.update(this.partner);
    } catch (error) {
      console.log(error);
    }
  }

  async createPartner() {
    try {
      this.partner.euTaxAccount = String(this.partner.euTaxAccount);
      const ret = Number(await PartnerApi.create(this.partner));
      if (ret > 0) {
        this.partner.id = ret;
        this.partners.push(this.partner);
      }
      return ret;
    } catch (error) {
      console.log(error);
    }
  }

  @busyIndication()
  async sendBisnode() {
    try {
      this.bisnodePartners = await PartnerApi.sendBisnode(this.partner.name);
      this.quickSearchItems = [...this.bisnodePartners.quickSearchItems];
      this.quickSearchItems.forEach((element) => {
        element.expanded = false;
      });
    } catch (error) {
      console.error(error);
    }
  }

  setExpanded(data: QuickSearchItem) {
    data.expanded = !data.expanded;
    this.quickSearchItems = [...this.quickSearchItems];
  }

  async loadBalanceStatementEmails(partner: number) {
    this.balanceStatementEmails = (await PartnerApi.getBalanceStatementEmail(partner)).map(x => ({ ...x, inEdit: false }));
  }

  async createBalanceStatementEmail(partner: number) {
    console.log(partner);
    const newItem = await PartnerApi.createBalanceStatementEmail(partner);
    newItem.inEdit = true;
    this.balanceStatementEmails.push(newItem);
  }

  async modifyBalanceStatementEmail(entity: BalanceStatementEmail) {
    await PartnerApi.modifyBalanceStatementEmail(entity);
  }

  async deleteBalanceStatementEmail(entity: BalanceStatementEmail) {
    await PartnerApi.deleteBalanceStatementEmail(entity);
    const item = this.balanceStatementEmails.find(x => x.id == entity.id);

    if (item) {
      const index = this.balanceStatementEmails.indexOf(item);
      this.balanceStatementEmails.splice(index, 1);
    }

  }

  async loadUsers() {
    this.users = await PartnerApi.getUsers();
  }

  async loadPartnerTypes() {
    this.partnerTypes = await PartnerApi.getPartnerTypes();
  }

  async loadPartnerStatuses() {
    this.partnerStatuses = await PartnerApi.getPartnerStatuses();
  }

  async loadSpecialTaxes() {
    this.specialTaxes = await PartnerApi.getSpecialTaxes();
  }

  async loadPayModes() {
    this.payModes = await PartnerApi.getPayModes();
  }
  async loadCustTypes() {
    this.custTypes = await PartnerApi.getCustTypes();
  }

  async loadCompanyForms() {
    this.companyForms = await PartnerApi.getCompanyForms();
    this.companyForms.unshift({ ...companyFormDefaultProps, name: "-" });
  }

  async loadPayClasses() {
    this.payClasses = await PartnerApi.getPayClasses();
  }

  async loadPartnerGroups1() {
    this.partnerGroups1 = await PartnerApi.getPartnerGroups1();
  }

  async loadPartnerGroups2() {
    this.partnerGroups2 = await PartnerApi.getPartnerGroups2();
  }

  async loadCurrencies() {
    this.currencies = await CashPointApi.getCurrencies();
  }

  async loadCountries() {
    this.countries = await CashPointApi.getCountries();
  }

  async loadCountry(postCode: string) {
    const countryOfSelectedCity: Country = await PartnerApi.getCountry(postCode);
    this.partner.countryName = countryOfSelectedCity.name;
  }

  async loadPartnerEuTaxesByPartnerId() {
    this.partnerEuTaxes = await PartnerApi.getPartnerEuTaxesByPartnerId(this.partner.id);
  }

  async loadPartnerEuTaxesByPartnerIdParam(partnerId: number) {
    this.partnerEuTaxes = await PartnerApi.getPartnerEuTaxesByPartnerId(partnerId);
  }

  async loadAllEuTaxesByPartnerIdParam(partnerId: number) {
    this.partnerEuTaxes = await PartnerApi.getAllEuTaxesByPartnerIdParam(partnerId);
  }

  async deletePartnerEuTax(taxesToDelete: number[]) {
    try {
      await taxesToDelete.forEach((taxId) => {
        PartnerApi.deleteTax(taxId);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async createPartnerEuTaxes(newEuTaxes: PartnerEuTax[]) {
    try {
      await PartnerApi.createPartnerEuTaxes(newEuTaxes);
    } catch (error) {
      console.error(error);
    }
  }

  //partBank
  async loadPartBanks(partnerID: number) {
    this.partBanks = (await PartnerApi.getPartBanks(partnerID)).map((i) => ({ ...i, inEdit: false }));
  }

  setPartBankEditing(item: PartBank) {
    item.inEdit = !item.inEdit;
    this.partBanks = [...this.partBanks];
  }

  updatePartBankElement(item: PartBank, update: Partial<PartBank>) {
    //item.inEdit = false;
    Object.assign(item, update);
    this.partBank = item;
  }

  async savePartBank(item: PartBank) {
    if (item.id !== 0) {
      await this.updatePartBank();
    }
  }

  async createPartBank() {
    try {
      await PartnerApi.createPartBank(this.partBank);
    } catch (error) {
      console.log(error);
    }
  }

  async updatePartBank() {
    try {
      await PartnerApi.updatePartBank(this.partBank);
    } catch (error) {
      console.log(error);
    }
  }

  async loadBanks() {
    this.banks = await BankApi.getAll();
  }

  //partSupplier
  async loadPartSuppliers(partnerID: number) {
    this.partSuppliers = (await PartnerApi.getPartSuppliers(partnerID)).map((i) => ({ ...i, inEdit: false }));
  }

  setPartSupplierEditing(item: PartSupplier) {
    item.inEdit = !item.inEdit;
    this.partSuppliers = [...this.partSuppliers];
  }

  updatePartSupplierElement(item: PartSupplier, update: Partial<PartSupplier>) {
    //item.inEdit = false;
    Object.assign(item, update);
    this.partSupplier = item;
  }

  async savePartSupplier(item: PartSupplier) {
    if (item.id !== 0) {
      await PartnerApi.updatePartSupplier(item);
    }
  }

  async createPartSupplier(item: PartSupplier) {
    try {
      await PartnerApi.createPartSupplier(item);
    } catch (error) {
      console.log(error);
    }
  }

  async loadSuplTypes() {
    this.suplTypes = await PartnerApi.getSuplTypes();
  }

  //partPriSupl
  async loadPartnerPriSupl(partnerID: number) {
    const ret = await PartnerApi.getPartnerPriSupl(partnerID);
    if (ret) {
      this.partnerPriSupl = ret;
    } else this.partnerPriSupl = { ...partnerPriSuplDefaultProps };
  }

  async savePartnerPriSupl(item: PartnerPriSupl, partnerId: number) {
    this.partnerPriSupl = { ...item };
    if (partnerId === 0) {
      await this.createPartnerPriSupl();
    } else {
      await this.updatePartnerPriSupl();
    }
  }

  async createPartnerPriSupl() {
    try {
      await PartnerApi.createPartnerPriSupl(this.partnerPriSupl);
    } catch (err) {
      // TODO: notification
      console.error(err);
    }
  }

  async updatePartnerPriSupl() {
    try {
      await PartnerApi.updatePartnerPriSupl(this.partnerPriSupl);
    } catch (err) {
      // TODO: notification
      console.error(err);
    }
  }

  //partEmployee
  async loadPartEmployee(partnerID: number) {
    const temp = await PartnerApi.getPartEmployee(partnerID);

    if (temp !== null) {
      this.partEmployee = { ...temp, inEdit: false };
    } else {
      this.partEmployee.id = 0;
      this.partEmployee.inEdit = false;
      this.partEmployee.maxAmount = 0;
      this.partEmployee.partner = partnerID;
    }
  }

  async savePartEmployee() {
    if (this.partEmployee.id == 0) {
      await this.createPartEmployee();
    } else {
      await this.updatePartEmployee();
    }
  }

  private async createPartEmployee() {
    try {
      // console.log(this.partEmployee);

      await PartnerApi.createPartEmployee(this.partEmployee);
    } catch (error) {
      console.log(error);
    }
  }

  private async updatePartEmployee() {
    try {
      // console.log(this.partEmployee);
      await PartnerApi.updatePartEmployee(this.partEmployee);
    } catch (error) {
      console.log(error);
    }
  }

  //partCust
  async loadPartCust(partnerID: number) {
    const temp = await PartnerApi.getPartCust(partnerID);

    if (temp !== null) {
      this.partCust = { ...temp, inEdit: false };
    } else {
      this.partCust.id = 0;
    }
  }

  async savePartCust(item: PartCust) {
    // console.log(item);

    if (item.id !== 0) {
      await this.updatePartCust();
    }
  }

  private async updatePartCust() {
    try {
      // console.log(this.partCust);
      await PartnerApi.updatePartCust(this.partCust);
    } catch (error) {
      console.log(error);
    }
  }

  async createPartCust(item: PartCust) {
    try {
      // console.log(item);

      await PartnerApi.createPartCust(item);
    } catch (error) {
      console.log(error);
    }
  }

  //partPerson
  async loadPartPersons(partnerID: number) {
    this.partPersons = (await PartnerApi.getPartPersons(partnerID)).map((i) => ({ ...i, inEdit: false }));
  }

  setPartPersonEditing(item: PartPerson) {
    item.inEdit = !item.inEdit;
    this.partPersons = [...this.partPersons];
  }

  updatePartPersonElement(item: PartPerson, update: Partial<PartPerson>) {
    //item.inEdit = false;
    Object.assign(item, update);
    this.partPerson = item;
  }

  async savePartPerson(item: PartPerson) {
    if (item.id !== 0) {
      await this.updatePartPerson();
    }
  }

  async createPartPerson(item: PartPerson) {
    try {
      await PartnerApi.createPartPerson(item);
    } catch (error) {
      console.log(error);
    }
  }

  async updatePartPerson() {
    try {
      await PartnerApi.updatePartPerson(this.partPerson);
    } catch (error) {
      console.log(error);
    }
  }

  //extendedPartnerInfo
  async loadPrePartnerNames() {
    this.prePartnerNames = await PartnerApi.getAllPrePartnerNames();
  }

  async loadStreetTypes() {
    this.streetTypes = await PartnerApi.getAllStreetTypes();
  }

  async updateExtendedPartnerInfo() {
    try {
      await PartnerApi.updateExtendedPartnerInfo(this.partner);
    } catch (error) {
      console.log(error);
    }
  }

  async createExtendedPartnerInfo() {
    try {
      await PartnerApi.createExtendedPartnerInfo(this.partner);
    } catch (error) {
      console.log(error);
    }
  }

  async loadPartnerCustoms(partner: number) {
    this.partnerCustoms = await PartnerApi.loadPartnerCustoms(partner);
  }

  async deletePartnerCustoms(id: number) {
    await PartnerApi.deletePartnerCustoms(id);
  }

  async updatePartnerCustoms(item: PartnerCustoms) {
    try {
      // console.log(item);
      // await PartnerApi.updatePartnerCustoms(item);
    } catch (error) {
      console.log(error);
    }
  }

  setPartnerCustoms(item: PartnerCustoms, update: Partial<PartnerCustoms>) {
    Object.assign(item, update);
    this.partnerCustom = item;
  }

  async savePartnerCustoms() {
    if (this.partnerCustom.id > 1) {
      try {
        await PartnerApi.updatePartnerCustoms(this.partnerCustom);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await PartnerApi.createPartnerCustoms(this.partnerCustom);
        await this.loadPartnerCustoms(this.partner.id);
      } catch (error) {
        console.log(error);
      }
    }
  }

  async loadWorkflows() {
    this.workflows = await PartnerApi.loadWorkflows();
  }

  async setWorkflowIsActive() {
    const key: SysParam = await PartnerApi.setWorkflowIsActive("IS_WORKFLOW_ENABLED");
    this.workflowIsActive = key.value;
  }

  async kanriSave(id: number) {
    await PartnerApi.kanriSave(id);
  }
}

const store = new PartnerStore();
export default store;
