import AccountActionSource, { accountActionSourceDefaultProps } from "../models/AccountActionSource"
import AccountActionSourceApi from "./AccountActionSourceApi"
import busyIndication from "../../../utils/busyIndication";

export class AccountActionSourceStore {
    accountActionSources: AccountActionSource[] = []
    accountActionSource: AccountActionSource = { ...accountActionSourceDefaultProps }

    @busyIndication()
    async loadAccountActionSources() {
        this.accountActionSources = (await AccountActionSourceApi.getAll()).map((i) => ({ ...i, inEdit: false }))
    }

    @busyIndication()
    async loadAccountActionSource(accountActionSourceId: number) {
        this.accountActionSource = accountActionSourceId > 0 ? await AccountActionSourceApi.get(accountActionSourceId) : { ...accountActionSourceDefaultProps }
    }

    async saveAccountActionSource(item: AccountActionSource){
        if(item.id !==0){
            await this.updateAccountActionSource();
        }
    }

    private async updateAccountActionSource() {
        try {
            await AccountActionSourceApi.update(this.accountActionSource)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async save(item: AccountActionSource, update: Partial<AccountActionSource>) {        
        Object.assign(item, update);
        this.accountActionSource = item;
    }

    setEditing(item: AccountActionSource) {
        item.inEdit = !item.inEdit;
        this.accountActionSources = [...this.accountActionSources];
    }
}

const store = new AccountActionSourceStore()
export default store