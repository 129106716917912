



































import Fragment from "@/components/Fragment.vue";

import Vue from "vue";
import { DownPaymentExtOrderItem, PreDownPayment } from "@/modules/downPayment/models";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

export default Vue.extend({
  components: { Fragment, MoneyDisplay },
  props: {
    items: { type: Array as () => DownPaymentExtOrderItem[] },
    preDownPayment: PreDownPayment,
  },
  methods: {
    hasData(): boolean {
      return this.items && this.items.length > 0;
    },
  },
});
