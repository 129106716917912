




































import Vue from "vue";
import PdfViewer from "@/components/PdfViewer.vue";
import store, {
  CashDocByReceiptsStore,
} from "../services/CashDocByreceiptsStore";

interface Methods {
  exitPdf(): void;
  next(): void;
  previous(): void;
}

interface Data {
  store: CashDocByReceiptsStore;
  currentPdf: number;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    PdfViewer,
  },

  data(): Data {
    return {
      store,
      currentPdf: 0,
    };
  },

  methods: {
    exitPdf() {
      this.store.generatedViroPdfs = [];
    },

    next() {
      if (this.currentPdf + 1 < this.store.generatedViroPdfs.length) {
        this.currentPdf++;
      }
    },

    previous() {
      if (this.currentPdf - 1 >= 0) {
        this.currentPdf--;
      }
    },
  },
});
