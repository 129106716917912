import { PrecisionType, roundMoney } from "./../../../services/prices";
import { FixAssetData } from "./../../invoiceFlow/models/FixAsset";
import eventBus from "@/services/eventBus";
import { Prices } from "@/services/prices";
import Decimal from "decimal.js-light";
import cuid from "cuid";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import GeneralIngoingInvoiceAndItems from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceAndItems";
import TaxAndVatItem from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";
import { toBase64 } from "@/utils/fileFunctions";
import FixAssetInvItemRelation from "@/modules/invoiceFlow/models/FixAssetInvItemRelation";
import DownPaymentInfo from "./DownPaymentInfo";
import InvItemDownRel, {
  invItemDownRelDefaultProps,
} from "@/modules/invoiceFlow/models/InvItemDownRel";
import SelectableParts from "@/modules/generalIngoingInvoice/models/IngoingInvoiceSelectableItem";
import configuration, { loadConfiguration } from "@/models/Configuration";

export type PartsAttribute = {
  name: string;
  value: string;
};

export interface StoreIn {
  deliveryNumber: string;
  expanded: boolean;
  storeInID: number;
  typeID: number;
  type: string;
  statusID: number;
  status: string;
  supplierID: number;
  supplier: string;
  storeInDate: Date;
  nettoTotal: number;
  stocks: Stock[];
  fees: StoreInFee[];
  relatedInvoices: RelatedInvoice[];
  currency: string;
  note: string;
}

export interface StoreInFee {
  id: number;
  name: string;
  storeInID: number;
  invoiceID: number;
  price: number;
  currPrice: number;
  inStockPrice: boolean;
  invoiceNo: string;
  type: string;
}

export interface StoreInFeeType {
  text: string;
  value: string;
}

export const feeTypes: StoreInFeeType[] = [
  { text: "Szállítási költség", value: "delivery_price" },
  { text: "Egyéb költség", value: "other_cost" },
];

export const priceModifyOptions = [
  { text: "Készlet ár módosító", value: true },
  { text: "Nem módosít készlet árat", value: false },
];

export interface Stock {
  deliveryNumber: string;
  orderNumber: string;
  orderID: number;
  stockID: number;
  storeInID: number;
  supplierID: number;
  supplier: string;
  stockDate: Date;
  attributes: PartsAttribute[];
  warehouse: string;
  productID: number;
  productName: string;
  quantity: number;
  quantityDisplay: string;
  unit: string;
  weightUnit: string;
  unitID: number;
  unitPrice: number;
  bruttoUnitPrice: number;
  unitWeight: number;
  relatedInvoices: RelatedInvoice[];
  vatID: number;
  batchNumber: string;
  financialFullfilmentAllowed: boolean;
  canSetFixAsset?: boolean;
  productCode: string;
  isInSupplierComplaint: boolean;
}

export const getStockWeight = (stock: Stock) => {
  if (stock.unitWeight <= 0) {
    return 0.0;
  }

  // Total weight in KG
  return (stock.unitWeight * stock.quantity) / 1000;
};

export enum ExtOrderTypeOption {
  Normal = "Beszerzések",
  Indirect = "Indirekt beszerzések",
  Cooperation = "Alvállalkozói rendelések",
}

export interface ExtOrder {
  id: number;
  expanded: boolean;
  orderNumber: string;
  supplierID: number;
  supplierName: string;
  orderDate: Date;
  plannedArrival: Date;
  statusID: number;
  statusName: string;
  orderItems: ExtOrderItem[];
  relatedInvoices: RelatedInvoice[];
  currency: string;
  others: SelectableParts[];
  type: any;
  letterOfCredit: number;
}

export interface ExtOrderItem {
  id: number;
  orderID: number;
  orderNumber: string;
  productID: number;
  productName: string;
  plannedArrival: Date;
  unitPrice: number;
  unit: string;
  unitID: number;
  quantity: number;
  attributes: PartsAttribute[];
  unitWeight: number;
  vatID: number;
  relatedInvoices: RelatedInvoice[];
  isIndirect: boolean;
  currency: string;
  customerName: string;
  salesNo: string;
  targetUser: string;
  targetSection: string;
  serviceID: number;
  gPartId: number;
  remark: string;
  originalStock: number;
}

export enum InvoiceRelationType {
  StoreIn,
  Stock,
  ExtOrder,
  ExtOrderItem,
  IncomingInvoice,
  SalesOrder,
}

export const enum InvoiceRelationState {
  None = "Nincs számla kapcsolat",
  Partial = "Részben számlához rendelve",
  Full = "Számlához rendelve",
}

function getInvoiceRelatedQuantity(item: Stock | ExtOrderItem) {
  if (item.relatedInvoices.length === 0) {
    return 0.0;
  }

  return item.relatedInvoices.reduce((acc, i) => acc + i.quantity, 0.0);
}

export function getRelationState(item: StoreIn | ExtOrder) {
  const stocks = (item as StoreIn).stocks;
  const orderItems = (item as ExtOrder).orderItems;

  let relatedQuantity = 0.0;
  let itemSum = 0.0;

  if (stocks && stocks.length > 0) {
    relatedQuantity = stocks.reduce(
      (acc, i) => acc + getInvoiceRelatedQuantity(i),
      0.0
    );
    itemSum = stocks.reduce((acc, i) => acc + i.quantity, 0.0);
  }

  if (orderItems && orderItems.length > 0) {
    relatedQuantity = orderItems.reduce(
      (acc, i) => acc + getInvoiceRelatedQuantity(i),
      0.0
    );
    itemSum = orderItems.reduce((acc, i) => acc + i.quantity, 0.0);
  }

  if (relatedQuantity === 0) {
    return InvoiceRelationState.None;
  }

  if (relatedQuantity === itemSum) {
    return InvoiceRelationState.Full;
  }

  return InvoiceRelationState.Partial;
}

export interface SupplierComplaintDetails {
  id: number;
  itemId: number;
  debitId: number;
  debitAmount: number;
  debitQuantity: number;
  fixedPrice: number;
  originalNetto: number;
  isRet: boolean;
}

export const supplierComplaintDetailsDefaultProps: SupplierComplaintDetails = {
  id: 1,
  itemId: 1,
  debitId: 1,
  debitAmount: 0,
  debitQuantity: 0,
  fixedPrice: 0,
  originalNetto: 0,
  isRet: false,
}

export interface InvoiceRelation {
  id: number;
  type: InvoiceRelationType;
  invoiceID: number;
  invoiceItemID: number;
  relatedItemID: number;
  relatedItem:
  | Stock
  | StoreIn
  | ExtOrder
  | ExtOrderItem
  | GeneralIngoingInvoiceAndItems
  | SalesAndOpportunity
  | OtherItem
  | any
  | null;
  quantity: number;
  divisionValue?: number | null;
  selectedForDivision: boolean;
}

export interface RelatedInvoice {
  relationID: number;
  invoiceID: number;
  invoiceNo: string;
  invoiceItemID: number;
  relatedItemID: number;
  type: InvoiceRelationType;
  quantity: number;
}

export interface Unit {
  id: number;
  name: string;
}

export interface Tax {
  id: number;
  name: string;
  degree: number;
  isInverseTax?: boolean;
}

export enum ValueDivisionMode {
  ByItemRate = "Készletérték arányban",
  ByManualRate = "Manuális felosztási arányban",
  ByWeightedRate = "Súly arányban",
}

export enum OtherCostShareOption {
  ToCurrentInvoice = "Az aktuális számlán",
  ToOtherInvoice = "Más bejövő számlán",
  ToConsignerConsumption = "Konszignációs készleten",
  ToOtherPartner = "Más partner készletén",
}

export function getCost(item: any, quantity: number) {
  return quantity * (item.unitPrice ?? 1.0);
}

export function getWeight(item: any, quantity: number) {
  return quantity * (item.unitWeight ?? 1.0);
}

export function getDivisionValue(
  item: OtherItem,
  relations: InvoiceRelation[],
  relation: InvoiceRelation
) {
  const itemCost = item.unitPrice * item.quantity;

  if (item.valueDivisionMode === ValueDivisionMode.ByItemRate) {
    const over = Number(relations.filter((x) => !x.selectedForDivision).reduce(
      (acc, r) => acc + (r as any).divisionValue ?? 0,
      0.0
    ).toFixed(2));
    const totalCost = Number(relations.filter((x) => x.selectedForDivision).reduce(
      (acc, r) => acc + getCost(r.relatedItem, r.quantity),
      0.0
    ).toFixed(2));

    const relationCost = Number(getCost(relation.relatedItem, relation.quantity).toFixed(2));
    const rate = relationCost / totalCost;
    return new Decimal((itemCost - over) * rate).toDecimalPlaces(2).toNumber();
  }

  if (item.valueDivisionMode === ValueDivisionMode.ByWeightedRate) {
    const totalWeight = relations.reduce(
      (acc, r) => acc + getWeight(r.relatedItem, r.quantity),
      0.0
    );
    const relationWeight = getWeight(relation.relatedItem, relation.quantity);
    const rate = relationWeight / totalWeight;
    return new Decimal(itemCost * rate).toDecimalPlaces(2).toNumber();
  }

  return 0.0;
}

export interface OtherItem {
  productID: number;
  serviceID: number;
  category: string;
  code: string;
  itemName: string;
  itemType: string;
  quantity: number;
  unit: string;
  unitPrice: number;
  vatID: number;
  hasUnitPrice: boolean;
  relations: InvoiceRelation[];
  stockRelated: boolean;
  costShareOption: OtherCostShareOption;
  valueDivisionMode: ValueDivisionMode;
  increasesStockPrice: boolean;
  orderNumber: string;
  gpartID: number;
  isRegTax: boolean;
  inVatCount: boolean;
  isPostCalc: boolean;
  postCalcType: number;
  isCarRectItem: boolean;
  isInclCarPrice: boolean;
  isService: boolean;
  originalIncreasesStockPrice: boolean;
}

export const otherItemDefaultProps: OtherItem = {
  productID: 1,
  serviceID: 1,
  category: "",
  code: "",
  itemName: "",
  itemType: "",
  quantity: 1,
  unit: "",
  unitPrice: 1,
  vatID: 1,
  hasUnitPrice: false,
  relations: [],
  stockRelated: false,
  costShareOption: OtherCostShareOption.ToCurrentInvoice,
  valueDivisionMode: ValueDivisionMode.ByItemRate,
  increasesStockPrice: false,
  orderNumber: "",
  gpartID: 1,
  isRegTax: false,
  inVatCount: false,
  isPostCalc: false,
  postCalcType: 1,
  isCarRectItem: false,
  isInclCarPrice: false,
  isService: false,
  originalIncreasesStockPrice: false,
}

export interface SalesAndOpportunity {
  opportunityID: number;
  salesOrderID: number;
  opportunityNo: string;
  salesOrderNo: string;
  partnerName: string;
  opportunityStatus: string;
  salesOrderStatus: string;
  currency: string;
  netto: number;
  vat: number;
}

export interface SalesAndOpportunityInput extends SalesAndOpportunity {
  name: string;
  price: number;
}

export interface SalesItem {
  opportunityID: number;
  salesOrderID: number;
  orderItemID: number;
  productID: number;
  unitID: number;
  vatID: number;
  partNo: string;
  productName: string;
  quantity: number;
  unitPrice: number;
  unit: string;
  vat: string;
  currency: string;
}

export interface CarDetails {
  model: string;
  bodyNo: string;
  regNum: string;
}

export enum InvoiceItemType {
  Stocked = "Készletes",
  Indirect = "Indirekt beszerzés",
  Service = "Szolgáltatás és egyéb cikk",
  DeliveryCost = "Szállítási költség",
  OtherCost = "Egyéb költség",
  Other = "Egyéb",
  Rectify = "Helyesbítő",
  Rounding = "Kerekítés",
  Sales = "Értékesítés",
  Car = "Gépjármű",
}

export interface GeneralRectItemDetailsData {
  id: number;
  invoice: number;
  invCode: string;
  item: number;
  rectInvNo: string;
  rectInvDate: string | Date;
  rectShipDate: string | Date;
  rectNetto: number;
  rectVat: number;
}

export const generalRectItemDetailsDefaultProps: GeneralRectItemDetailsData = {
  id: 0,
  invoice: 1,
  item: 1,
  invCode: "GENI",
  rectInvNo: "",
  rectInvDate: "",
  rectShipDate: "",
  rectNetto: 0,
  rectVat: 0,
}

export interface InvoiceItemData {
  invoiceItemID: number;
  type: InvoiceItemType;
  productID: number;
  productName: string;
  serviceID: number;
  gpartID: number;
  serviceName: string;
  unit: Unit;
  unitPrice: number;
  quantity: number;
  tax: TaxAndVatItem;
  nettoValue: number;
  taxValue: number;
  bruttoValue: number;
  description: string;
  relations: InvoiceRelation[];
  increasesStockPrice: boolean;
  feeType: StoreInFeeType;
  feeIncluded: boolean;
  currency: string;
  isRoundingItem: boolean;
  fixAsset: FixAssetData | null;
  canSetFixAsset?: boolean;
  hasFixAssetRelation: boolean;
  fixAssetInvItemRelations: FixAssetInvItemRelation[];
  statCode: string;
  creditingStoreInId: number;
  creditingItemId: number;
  sumItem: boolean;
  isService: boolean;
  hasStockDivision?: boolean;
  toleranceCheckNeeded: boolean;
  manualPrecision?: number | undefined;
  originalBruttoValue: number;
  originalNettoValue: number;
  originalVatValue: number;
  originalTax: number;
  originalQuantity: number;
  originalUnitPrice: number;
  isSelectedForDownPayment: boolean;
  downPaymentDetails: InvItemDownRel;
  orderService: number;
  isIndirect: boolean;
  joinedService: number;
  orderNumber: string;
  partNo: string;
  isInSupplierComplaint: boolean;
  supplierComplaintDetails: SupplierComplaintDetails;
  supplierComplaintDetailsForRectify: SupplierComplaintDetails;
  car: number;
  prCar: number;
  postCalc: number;
  inVatCount: boolean;
  isRegTax: boolean;
  carDetails: CarDetails;
  rectifiedInvNo: number;
  rectifiedItem: number;
  rectifiedType: string;
  isGeneralRectItem: boolean;
  generalRectItemDetails: GeneralRectItemDetailsData;
  workSheetItemRelationSelectorVisible: boolean;
  subcontractorRelationSelectorVisible: boolean;
  carRectItemSelectorVisible: boolean;
  carItemRelationSelectorVisible: boolean;
  navItemsVisible: boolean;
  stockId: number;
  visible: boolean;
  rectSumItem: boolean;
  normalQ: number;
  outQ: number;
  separQ: number;
  rectifiedItemHasIntrastat: boolean;
  hasExistingIntrastatData: boolean;
  isIntrastatFilled: boolean;
  originalItemId: number;
}

enum InvoiceItemListKind {
  Normal = 0,
  Rectify = 1,
}

export type InvoiceItemListResponse = {
  listKind: InvoiceItemListKind;
  invoiceItems: InvoiceItemData[];
};

export class GeneralRectItemDetails implements GeneralRectItemDetailsData {
  id = 1;
  invCode = "GENI";
  item = 1;
  invoice = 1;
  rectInvNo = "";
  rectInvDate = "";
  rectShipDate = "";
  rectNetto = 1;
  rectVat = 1;
}

export class InvoiceItem implements InvoiceItemData {
  cuid = cuid();
  isRoundingItem = false;
  invoiceItemID = 0;
  currency = "HUF";
  productName = "-";
  serviceName = "-";
  productID = 1;
  serviceID = 1;
  gpartID = 1;
  quantity = 0.0;
  nettoValue = 0.0;
  manualTax: number | null = null;
  exchangeRate: number | undefined = 1.0;
  description = "-";
  isService = false;
  relations: InvoiceRelation[] = [];
  increasesStockPrice = false;
  rawOtherItem: OtherItem | null = null;
  increaseStockPriceVisible = false;
  hasStockDivision = false;
  toleranceCheckNeeded = false;
  orderService = 1;
  manualPrecision?: number | undefined;
  isIndirect = false;
  isIndirectNoServ = false;
  navItemsVisible = false;
  stockId = 1;

  operelDimensionsVisible = false;
  joinedService = 1;

  dpaymItem = false;
  rectSumItem = false;
  visible = true;

  orderNumber = "";
  normalQ = 0;
  outQ = 0;
  separQ = 0;
  isIntrastatFilled = false;

  isInSupplierComplaint = false;
  supplierComplaintDetails = { ...supplierComplaintDetailsDefaultProps };
  supplierComplaintDetailsForRectify = { ...supplierComplaintDetailsDefaultProps };

  car = 1;
  prCar = 1;
  postCalc = 1;
  inVatCount = true;
  isRegTax = false;

  carDetails: CarDetails = {
    model: "",
    bodyNo: "",
    regNum: "",
  };

  rectifiedInvNo = 1;
  rectifiedItem = 1;
  rectifiedType = "N";

  isCarRectItem = false;
  isWscontRectItem = false;
  isInclCarPrice = false;

  feeIncluded = false;
  feeType: StoreInFeeType = {
    text: "-",
    value: "-",
  };

  unit: Unit = {
    id: 1,
    name: "-",
  };

  tax: TaxAndVatItem = {
    degree: 0.0,
    id: 1,
    name: "-",
    vatItemID: 1,
    vatItemName: "-",
    vatDisplayName: "",
  };

  fixAsset: FixAssetData | null = null;
  canSetFixAsset = false;
  hasFixAssetRelation = false;
  fixAssetInvItemRelations: FixAssetInvItemRelation[] = [];
  statCode = "";
  creditingStoreInId = 1;
  creditingItemId = 1;
  originalBruttoValue = 1;
  originalNettoValue = 1;
  originalVatValue = 1;
  originalTax = 1;
  originalQuantity = 1;
  originalUnitPrice = 1;
  sumItem = false;
  downPayment = new DownPaymentInfo();
  isSelectedForDownPayment = false;
  isSelectedForOverBilling = false;
  downPaymentDetails = { ...invItemDownRelDefaultProps };
  isGeneralRectItem = false;
  generalRectItemDetails = { ...generalRectItemDetailsDefaultProps }

  workSheetItemRelationSelectorVisible = false;
  subcontractorRelationSelectorVisible = false;
  fachSheetItemRelationSelectorVisible = false;
  carRectItemSelectorVisible = false;
  wscontRectItemSelectorVisible = false;
  carItemRelationSelectorVisible = false;

  rectifiedItemHasIntrastat = false;
  hasExistingIntrastatData = false;

  partNo = "";
  originalItemId = 1;
  get includeInSummary() {
    return true;
  }

  get cssClass() {
    let classes = this.invoiceItemID > 1 ? "" : "new-item";
    classes += this.isRoundingItem ? " primary lighten-4" : "";
    return classes;
  }

  get type(): InvoiceItemType {
    if (this.relations.some((r) => r.type === InvoiceRelationType.SalesOrder)) {
      return InvoiceItemType.Sales;
    }

    if (
      this.productName == InvoiceItemType.Rounding ||
      this.serviceName == InvoiceItemType.Rounding
    ) {
      return InvoiceItemType.Rounding;
    }

    if (this.feeType?.value === "delivery_price") {
      return InvoiceItemType.DeliveryCost;
    }

    if (this.feeType?.value === "other_cost") {
      return InvoiceItemType.OtherCost;
    }

    if (
      this.relations.some(
        (r) =>
          ((r.relatedItem as any)?.productID ?? 1) === this.productID &&
          r.type === InvoiceRelationType.Stock
      )
    ) {
      return InvoiceItemType.Stocked;
    }
    if (
      this.relations.some(
        (r) => r.relatedItem &&
          ((r.relatedItem as any)?.productID ?? 1) === this.productID &&
          r.type === InvoiceRelationType.ExtOrderItem &&
          (r.relatedItem as any).isIndirect
      )
    ) {
      return InvoiceItemType.Indirect;
    }

    if (
      this.relations.some(
        (r) =>
          ((r.relatedItem as any)?.productID ?? 1) === this.productID &&
          r.type === InvoiceRelationType.ExtOrderItem
      )
    ) {
      return InvoiceItemType.Stocked;
    }

    if ((this.gpartID > 1 || this.serviceID > 1) && (this.car == 1 && this.prCar == 1)) {
      return InvoiceItemType.Service;
    }

    if (this.prCar > 1 || this.car > 1) {
      return InvoiceItemType.Car;
    }

    return InvoiceItemType.Other;
  }

  get itemName() {
    return this.productID >= this.gpartID
      ? this.productName
      : this.serviceName;
  }

  constructor(params: Partial<InvoiceItemData>) {
    const data = { ...params };
    delete data.taxValue;
    delete data.unitPrice;
    delete data.bruttoValue;
    Object.assign(this, data);
    if (this.fixAsset) this.fixAsset = new FixAssetData(this.fixAsset);
  }

  private get prices() {
    return new Prices({
      currNetto: this.nettoValue,
      quantity: this.quantity,
      taxDegree: this.tax?.degree ?? 1,
      exchangeRate: this.exchangeRate,
      currency: this.currency,
      manualPrecision: configuration.operel ? 2 : configuration.getCurrDigits(this.currency)?.geniItemDigits ?? 2,
      unitPrice: this.originalUnitPrice,
    });
  }

  get unitPrice(): number {
    return this.prices.currUnitPrice;
  }

  get taxValue(): number {
    if (this.manualTax) {
      return this.manualTax;
    }
    if (this.downPayment.id > 1)
      return this.originalVatValue;

    return this.prices.currVat;
  }

  get bruttoValue(): number {
    if (this.downPayment.id > 1) {
      return this.originalBruttoValue;
    }
    return this.prices.currBrutto;
  }

  async toDto(): Promise<InvoiceItemData> {
    for (const doc of this.fixAsset?.documents ?? []) {
      if (!doc.file) continue;
      doc.document = await toBase64(doc.file);
      const split = doc.file?.name.split(".");
      doc.extension = split[split.length - 1].toUpperCase();
    }

    return {
      bruttoValue: this.bruttoValue,
      description: this.description,
      invoiceItemID: this.invoiceItemID,
      nettoValue: this.nettoValue,
      productID: this.productID,
      productName: this.productName,
      quantity: this.quantity,
      relations: this.relations,
      increasesStockPrice: this.increasesStockPrice,
      serviceID: this.serviceID > 1 ? this.serviceID : 1,
      orderService: this.orderService,
      serviceName: this.serviceName,
      tax: this.tax,
      taxValue: this.taxValue,
      type: this.type,
      unit: this.unit,
      unitPrice: this.unitPrice,
      feeType: this.feeType,
      feeIncluded: this.feeIncluded,
      currency: this.currency,
      isRoundingItem: this.isRoundingItem,
      fixAsset: this.fixAsset,
      canSetFixAsset: this.canSetFixAsset,
      hasFixAssetRelation: this.hasFixAssetRelation,
      fixAssetInvItemRelations: this.fixAssetInvItemRelations,
      statCode: this.statCode,
      creditingStoreInId: this.creditingStoreInId,
      creditingItemId: this.creditingItemId,
      sumItem: this.sumItem,
      isService: this.isService,
      toleranceCheckNeeded: false,
      originalBruttoValue: this.originalBruttoValue,
      originalNettoValue: this.originalNettoValue,
      originalVatValue: this.originalVatValue,
      originalTax: this.originalTax,
      originalQuantity: this.originalQuantity,
      originalUnitPrice: this.originalUnitPrice,
      isSelectedForDownPayment: false,
      downPaymentDetails: this.downPaymentDetails,
      isIndirect: false,
      joinedService: this.joinedService > 1 ? this.joinedService : 1,
      orderNumber: this.orderNumber,
      gpartID: this.gpartID,
      partNo: "",
      isInSupplierComplaint: this.isInSupplierComplaint,
      supplierComplaintDetails: { ...this.supplierComplaintDetails },
      supplierComplaintDetailsForRectify: { ...this.supplierComplaintDetailsForRectify },
      rectifiedInvNo: this.rectifiedInvNo,
      rectifiedItem: this.rectifiedItem,
      rectifiedType: this.rectifiedType,
      isGeneralRectItem: this.isGeneralRectItem,
      generalRectItemDetails: {
        id: this.generalRectItemDetails.id ?? generalRectItemDetailsDefaultProps.id,
        invoice: this.generalRectItemDetails.invoice ?? generalRectItemDetailsDefaultProps.invoice,
        invCode: this.generalRectItemDetails.invCode ?? generalRectItemDetailsDefaultProps.invCode,
        item: this.generalRectItemDetails.item ?? generalRectItemDetailsDefaultProps.invCode,
        rectInvNo: this.generalRectItemDetails.rectInvNo ?? generalRectItemDetailsDefaultProps.rectInvNo,
        rectNetto: this.generalRectItemDetails.rectNetto ?? generalRectItemDetailsDefaultProps.rectNetto,
        rectVat: this.generalRectItemDetails.rectVat ?? generalRectItemDetailsDefaultProps.rectVat,
        rectInvDate: this.generalRectItemDetails.rectInvDate != "" ? this.generalRectItemDetails.rectInvDate : new Date("1990-01-01"),
        rectShipDate: this.generalRectItemDetails.rectShipDate != "" ? this.generalRectItemDetails.rectShipDate : new Date("1990-01-01"),
      },
      car: this.car,
      prCar: this.prCar,
      postCalc: this.postCalc,
      inVatCount: this.inVatCount,
      isRegTax: this.isRegTax,
      carDetails: {
        model: "",
        bodyNo: "",
        regNum: ""
      },
      workSheetItemRelationSelectorVisible: false,
      subcontractorRelationSelectorVisible: false,
      carRectItemSelectorVisible: false,
      carItemRelationSelectorVisible: false,
      visible: this.visible,
      rectSumItem: this.rectSumItem,
      normalQ: this.normalQ,
      outQ: this.outQ,
      separQ: this.separQ,
      rectifiedItemHasIntrastat: this.rectifiedItemHasIntrastat,
      hasExistingIntrastatData: this.hasExistingIntrastatData,
      isIntrastatFilled: this.isIntrastatFilled,
      originalItemId: this.originalItemId,
      navItemsVisible: this.navItemsVisible,
      stockId: this.stockId,
    };
  }

  get relatedInvoices() {
    return this.relations
      .filter((i) => i.type === InvoiceRelationType.IncomingInvoice)
      .map((i) => i.relatedItem as GeneralIngoingInvoiceAndItems);
  }

  hasStock() {
    return (
      this.productID > 1 &&
      this.relations.some((i) => i.relatedItem.stockID > 1 || i.type === InvoiceRelationType.Stock)
    );
  }

  get stockPriceDifference() {
    if (!this.toleranceCheckNeeded) {
      return 0.0;
    }

    const itemPrice = this.nettoValue;
    const relation = this.relations.find(
      (i) => i.type === InvoiceRelationType.Stock || (i.type === InvoiceRelationType.ExtOrderItem && i.relatedItem.stockID > 1)
    );

    if (!relation) {
      return;
    }

    const stock = relation.relatedItem as Stock;
    const stockPrice = roundMoney(
      stock.unitPrice * this.quantity,
      this.currency, undefined, PrecisionType.geniItem
    );
    const difference = stockPrice != 0 ? Math.abs(100 - (100 * itemPrice) / stockPrice) : 0;

    return new Decimal(difference).toDecimalPlaces(2).toNumber();
  }

  isPriceToleranceViolated(threshold: number) {
    if (!this.toleranceCheckNeeded) {
      return false;
    }

    if (!this.stockPriceDifference) return false;
    const tolerance = this.stockPriceDifference - threshold;

    return 0 < new Decimal(tolerance).toDecimalPlaces(2).toNumber();
  }
}

export class RoundingItem extends InvoiceItem {
  _brutto = 0.0;

  constructor(params: Partial<InvoiceItemData>) {
    super(params);
    this._brutto = params.bruttoValue ?? 0.0;
    this.isRoundingItem = true;
  }

  get bruttoValue() {
    return this._brutto;
  }

  set bruttoValue(value: number) {
    this._brutto = value;
  }

  get type() {
    return InvoiceItemType.Rounding;
  }
}

export class RectifyItem extends InvoiceItem {
  editDisabled = false;

  get type() {
    return InvoiceItemType.Rectify;
  }

  get itemName(): string {
    const name = this.productID > 1 ? this.productName : this.serviceName;

    if (this.editDisabled) {
      return name;
    }

    return name + ` (${this.description})`;
  }

  get cssClass(): string {
    return this.editDisabled ? "grey--text grey lighten-4" : "";
  }

  get includeInSummary() {
    return !this.editDisabled;
  }

  constructor(params: Partial<InvoiceItemData>, itemIndex: number) {
    super(params);
    // Minden második helyesbítő sor lesz csak szerkeszthető
    // this.editDisabled = itemIndex % 2 === 0 || (params.isInSupplierComplaint as boolean);
    // Csak a nem stornó rekord szerkeszthető
    this.editDisabled = params.rectifiedType == "S";
  }
}

export const mapInvoiceItems = (response: InvoiceItemListResponse) =>
  response.invoiceItems.map((d, index) => {

    if (d.productName == InvoiceItemType.Rounding) {
      return new RoundingItem(d);
    }

    if (response.listKind === InvoiceItemListKind.Rectify) {
      return new RectifyItem(d, index);
    }

    return new InvoiceItem(d);
  });

export class InvoiceItemList {
  items: InvoiceItem[];
  manualPrecision?: number | undefined;

  constructor() {
    this.items = [];
  }

  static from(
    invoice: GeneralIngoinInvoiceDisplay,
    manualPrecision?: number | undefined
  ) {
    const exchangeRate = invoice.incomingInvoice.exchangeRate;
    const items = invoice.items.map((i) => {
      const item = new InvoiceItem({
        description: i.incomingInvoiceItem.note,
        invoiceItemID: i.incomingInvoiceItem.id,
        nettoValue: i.incomingInvoiceItem.currNetto,
        productID: i.incomingInvoiceItem.parts,
        productName: i.code,
        quantity: i.incomingInvoiceItem.quantity,
        gpartID: i.incomingInvoiceItem.gparts,
        serviceID: i.incomingInvoiceItem.service,
        serviceName: i.gPartsName,
        tax: {
          id: i.taxID,
          name: i.taxName,
          degree: i.taxDegree,
          vatItemName: i.vatItemName,
          vatItemID: i.vatItemId,
          vatDisplayName: `${i.taxName} - ${i.vatItemName}`,
        },
      });

      item.exchangeRate = invoice.incomingInvoice.exchangeRate;
      item.manualTax = i.incomingInvoiceItem.vat;
      return item;
    });

    const itemList = new InvoiceItemList();
    itemList.initialize(
      items,
      exchangeRate,
      invoice.incomingInvoice.invCurrency,
      manualPrecision
    );

    return itemList;
  }

  initialize(
    items: InvoiceItem[],
    exchangeRate: number | undefined,
    currency: string | undefined,
    manualPrecision?: number | undefined
  ) {
    this.manualPrecision = manualPrecision;
    this.items = items;
    this.items.forEach((i) => {
      i.exchangeRate = exchangeRate;
      i.currency = currency ?? "HUF";
      i.manualPrecision = this.manualPrecision ?? manualPrecision;
      i.isSelectedForDownPayment = false;
      i.isSelectedForOverBilling = false;
    });
  }

  get isRect() {
    if (this.items.length === 0) {
      return false;
    }

    if (this.items[0].type == InvoiceItemType.Rectify) {
      return true;
    }
    return false;
  }

  get dpaymNettos() {
    if (this.items.length == 0) {
      return 0;
    }

    const dpaymItems = this.items.filter((i) => i.downPaymentDetails != null);
    return dpaymItems
      .reduce(
        (acc, i) => acc.plus(i.downPaymentDetails.netto),
        new Decimal(0.0)
      )
      .toNumber();
  }

  get dpaymBruttos() {
    if (this.items.length == 0) {
      return 0;
    }

    const dpaymItems = this.items.filter((i) => i.downPaymentDetails != null);
    return dpaymItems
      .reduce(
        (acc, i) => acc.plus(i.downPaymentDetails.amount),
        new Decimal(0.0)
      )
      .toNumber();
  }

  get dpaymTaxes() {
    if (this.items.length == 0) {
      return 0;
    }

    const dpaymItems = this.items.filter((i) => i.downPaymentDetails != null);
    return dpaymItems
      .reduce((acc, i) => acc.plus(i.downPaymentDetails.vat), new Decimal(0.0))
      .toNumber();
  }

  get netto() {
    if (this.items.length === 0) {
      return 0.0;
    }

    if (this.isRect) {
      return this.items
        .filter((x) => x.visible)
        .reduce((acc, i) => (acc + i.nettoValue), 0);
    }

    return this.items
      .filter((i) => i.includeInSummary)
      .reduce((acc, i) => (i.dpaymItem ? acc - i.nettoValue : acc + i.nettoValue), 0);
  }

  get onlyRectNetto() {
    if (this.items.length === 0) {
      return 0.0;
    }

    if (this.isRect) {
      return this.items
        .filter((i) => i.rectifiedType != 'S')
        .reduce((acc, i) => (acc + i.nettoValue), 0);
    }

    return this.items
      .filter((i) => i.includeInSummary)
      .reduce((acc, i) => (acc + i.nettoValue), 0);
  }

  get tax() {
    if (this.items.length === 0) {
      return 0.0;
    }

    if (this.isRect) {
      return this.items
        .reduce((acc, i) => acc.plus(i.taxValue), new Decimal(0.0))
        .toNumber();
    }

    return this.items
      .filter((i) => i.includeInSummary)
      .reduce((acc, i) => acc.plus(i.taxValue), new Decimal(0.0))
      .toNumber();
  }

  get brutto() {
    if (this.items.length === 0) {
      return 0.0;
    }

    if (this.isRect) {
      return this.items
        .filter((x) => x.visible)
        .reduce((acc, i) => (acc + i.bruttoValue), 0);
    }

    return this.items
      .filter((i) => i.includeInSummary)
      .reduce((acc, i) => (i.dpaymItem ? acc - i.bruttoValue : acc + i.bruttoValue), 0);
  }

  get onlyRectBrutto() {
    if (this.items.length === 0) {
      return 0.0;
    }

    if (this.isRect) {
      return this.items
        .filter((i) => i.rectifiedType != 'S')
        .reduce((acc, i) => (acc + i.bruttoValue), 0);
    }

    return this.items
      .filter((i) => i.includeInSummary)
      .reduce((acc, i) => (acc + i.bruttoValue), 0);
  }

  get sumNettoStockValue(): number | null {
    const itemTypes = [InvoiceItemType.Stocked];
    const stockItems = this.items
      .filter((i) => i.includeInSummary)
      .filter((i) => itemTypes.includes(i.type));

    if (stockItems.length === 0) {
      return null;
    }

    return stockItems
      .reduce((acc, item) => acc.plus(item.nettoValue), new Decimal(0.0))
      .toNumber();
  }

  add(params: Partial<InvoiceItem>) {
    const invoiceItem = new InvoiceItem(params);
    if (this.manualPrecision)
      invoiceItem.manualPrecision = this.manualPrecision;
    if (!this.items.includes(invoiceItem)) {
      this.items = [...this.items, invoiceItem];
      eventBus.$emit("invoice-item:add");
    }
    console.log(this.items);

  }

  addItem(invoiceItem: InvoiceItem) {
    if (this.manualPrecision)
      invoiceItem.manualPrecision = this.manualPrecision;
    this.items = [...this.items, invoiceItem];

    eventBus.$emit("invoice-item:add");
  }

  remove(item: InvoiceItem) {
    this.items = this.items.filter((i) => i.cuid !== item.cuid);
  }
}

const stockMapper = (s: Stock) => ({
  ...s,
  quantityDisplay: `${s.quantity} ${s.unit}`,
  weightUnit: s.weightUnit || "g",
  stockDate: new Date(s.stockDate),
});

export const storeInMapper = (item: StoreIn) => ({
  ...item,
  expanded: false,
  storeInDate: new Date(item.storeInDate),
  stocks: item.stocks.map(stockMapper),
});

export const extOrderMapper = (item: ExtOrder) => ({
  ...item,
  expanded: false,
});

export interface GroupedConsumptions {
  consumptionID: number;
  consumptionStart: Date;
  consumptionEnd: Date;
  groups: ConsumptionGroup[];
  totalNettoValue: number;
  totalCurrNettoValue: number;
}

export interface ConsumptionGroup {
  partsID: number;
  partsCode: string;
  partsName: string;
  unit: string;
  consumptions: ConsumptionRecord[];
  quantity: number;
  weight: number;
  nettoValue: number;
  currNettoValue: number;
  unitPrice: number;
  attachedToInvoice: boolean;
  pending: boolean;
}

export interface ConsumptionRecord {
  cuid: string;
  consumptionID: number;
  consumptionItemID: number;
  invoiceID: number;
  stockID: number;
  partsID: number;
  vatID: number;
  note: string;
  attachedToInvoice: boolean;
  consumptionStart: string;
  consumptionEnd: string;
  partsCode: string;
  partsName: string;
  unitPrice: number;
  currUnitPrice: number;
  quantity: number;
  weight: number;
  nettoValue: number;
  currNettoValue: number;
  unit: string;
  weightUnit: string;
  currency: string;
}
