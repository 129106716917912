











































































































import dayjs from "dayjs";
import Vue from "vue";
import { ConsumptionGroup, GroupedConsumptions } from "../models";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import IconButton from "@/components/IconButton.vue";
import ButtonCheckBox from "@/components/ButtonCheckBox.vue";
import configuration from "@/models/Configuration";

export default Vue.extend({
  components: {
    MoneyDisplay,
    IconButton,
    ButtonCheckBox,
  },

  data() {
    return {
      configuration,
    };
  },

  props: {
    items: {
      type: Array as () => GroupedConsumptions[],
    },
  },

  computed: {
    itemSource() {
      return this.items;
    },
  },

  methods: {
    invoiceLink(id: number) {
      return `/general-incoming-invoice/flow/edit/${id}/4`;
    },

    dateFormat: (date: string) => dayjs(date).format("YYYY-MM-DD"),

    attachAll(g: GroupedConsumptions) {
      this.$emit("attach", g.groups);
    },

    attachPartsGroup(g: ConsumptionGroup) {
      this.$emit("attach", [g]);
    },

    pending(g: GroupedConsumptions) {
      return g.groups.some((_) => _.pending);
    },
  },
});
