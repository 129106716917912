


















































import Vue from "vue";

import IconButton from "../../../components/IconButton.vue";
import ExtOrderTable from "./ExtOrderTable.vue";
import * as api from "../services/api";
import store, { InvoiceItemStore } from "../services/store";
import {
  ExtOrder,
  ExtOrderItem,
  ExtOrderTypeOption,
  InvoiceRelationType,
  RelatedInvoice,
} from "../models";
import eventBus from "../../../services/eventBus";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";

interface Data {
  searchText: string;
  extOrders: ExtOrder[];
  store: InvoiceItemStore;
  selectedTypeOption: ExtOrderTypeOption;
  typeOptions: ExtOrderTypeOption[];
  flowStore: InvoiceFlowStore;
}

interface Methods {
  search(e: KeyboardEvent): void;
  onRelationRemoved(relation: RelatedInvoice): void;
  clear(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    ExtOrderTable,
    IconButton,
  },

  data: () => ({
    store,
    flowStore,
    extOrders: [],
    searchText: "",
    selectedTypeOption: ExtOrderTypeOption.Normal,
    typeOptions: [
      ExtOrderTypeOption.Normal,
      ExtOrderTypeOption.Indirect,
      ExtOrderTypeOption.Cooperation,
    ],
  }),

  mounted() {
    const extOrderItemAddHandler = () => (this.extOrders = []);
    eventBus.$on("invoice-item:add", extOrderItemAddHandler);
    eventBus.$on("invoice-relation:remove", (relation: RelatedInvoice) =>
      this.onRelationRemoved(relation)
    );
  },

  methods: {
    search(e: KeyboardEvent) {
      if (e.key !== "Enter") {
        return;
      }

      e.preventDefault();
      return api
        .getExtOrders(
          this.store.supplierID,
          this.searchText,
          this.selectedTypeOption
        )
        .then((extOrders) => {
          this.extOrders = extOrders;
        });
    },

    clear() {
      this.searchText = "";
      this.extOrders = [];
    },

    onRelationRemoved(relation: RelatedInvoice) {
      const items: ExtOrder[] = this.extOrders;

      const extOrder: ExtOrder | undefined = items.find(
        (o) =>
          relation.type === InvoiceRelationType.ExtOrder &&
          o.id === relation.relatedItemID
      );

      if (extOrder) {
        extOrder.relatedInvoices = extOrder.relatedInvoices.filter(
          (i) => i.relationID !== relation.relationID
        );
      }

      const extOrderItem: ExtOrderItem | undefined = items
        .flatMap((o) => o.orderItems)
        .find(
          (i) =>
            relation.type === InvoiceRelationType.ExtOrderItem &&
            i.id === relation.relatedItemID
        );

      if (extOrderItem) {
        extOrderItem.relatedInvoices = extOrderItem.relatedInvoices.filter(
          (i) => i.relationID !== relation.relationID
        );
      }
    },
  },
});
