import ApiResponse from "@/models/ApiResponse";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import CreditCard from "../models/CreditCard";

export class CreditCardApi {
  static getAll(): Promise<CreditCard[]> {
    return http
      .get<ApiResponse<CreditCard[]>>(`credit-card`)
      .then(handleApiResponse)
      .then(data => data.map(d => new CreditCard(d)));
  }

  static get(id: number): Promise<CreditCard> {
    return http
      .get<ApiResponse<CreditCard>>(`credit-card/${id}`)
      .then(handleApiResponse)
      .then(data => new CreditCard(data));
  }

  static save(p: CreditCard): Promise<boolean> {
    return http.put("credit-card", p).then(checkResponse);
  }
}
