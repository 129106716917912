





























































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, {
  IncomingInvoiceTypeStore,
} from "../services/IncomingInvoiceTypeStore";
import IncomingInvoiceType from "../models/IncomingInvoiceType";

interface Data {
  store: IncomingInvoiceTypeStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await Promise.all([this.store.loadInvoiceTypes()]);
  },

  computed: {
    columns: () => [
      { width: 200, field: "name", title: "Bejövő szla. típus" },
      {
        field: "isDefault",
        title: "Alapértelmezett",
        cell: "isDefaultTemplate",
      },
      {
        field: "promptArrive",
        title: "Érkezett szla. rögz.",
        cell: "promptArriveTemplate",
      },
      {
        field: "postCalc",
        title: "Utókal. szerepel",
        cell: "postCalcTemplate",
      },
      { width: 200, field: "defAccgroup", title: "Költséghely" },
      {
        field: "isMustAccGroup",
        title: "Köt. költséghely",
        cell: "isMustAccGroupTemplate",
      },
      { width: 80, field: "code", title: "Típuskód" },
      {
        field: "isExtData",
        title: "Kieg. adatok",
        cell: "isExtDataTemplate",
      },
      {
        field: "isContr",
        title: "Ellenőrizhető?",
        cell: "isContrTemplate",
      },
      {
        field: "isInvPermit",
        title: "Utalványozható?",
        cell: "isInvPermitTemplate",
      },
      {
        field: "isProject",
        title: "Project kapcsolat",
        cell: "isProjectTemplate",
      },
      { width: 60, cell: "commandTemplate" },
    ],

    items() {
      return this.store.invoiceTypes;
    },
  },

  methods: {
    editItem(item: IncomingInvoiceType) {
      this.$router.push(`/incoming-invoice-type/edit/${item.id}`);
    },
  },
});
