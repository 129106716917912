import BankOutView, { bankOutViewDefaultProps } from "../models/BankOutView"
import BankOutViewApi from "../services/BankOutViewApi";
import busyIndication from "../../../utils/busyIndication";

export class BankOutViewStore {
    bankOutViews: BankOutView[] = []
    bankOutView: BankOutView = { ...bankOutViewDefaultProps }

    //@busyIndication()
    async loadBankOutViews(partnerID: number, overpay: boolean) {
        this.bankOutViews = (await BankOutViewApi.getAll(partnerID, overpay)).map((i) => ({ ...i, selected: false, sorted: false, added: false, addable: false }));
    }
}

const store = new BankOutViewStore()
export default store