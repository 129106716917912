var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Kontírozás ")]),_c('v-card-text',[_c('ApiSearchPage',{ref:"asref",attrs:{"url":_vm.url,"hasPager":false,"refresh":false,"filterable":false,"sortable":false,"search":false,"columns":_vm.columns},on:{"items":_vm.setItems},scopedSlots:_vm._u([{key:"owe",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.accSign ? item.owe + " - " + _vm.getAccName(item.owe) : "")+" ")])]}},{key:"oweAmount",fn:function(ref){
var item = ref.item;
return [(item.accSign)?_c('MoneyDisplay',{attrs:{"value":item.oweAmount,"decimals":_vm.getPrecision(),"currency":_vm.store.cashStatement.currency}}):_vm._e()]}},{key:"demands",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.accSign ? "" : item.demands + " - " + _vm.getAccName(item.demands))+" ")])]}},{key:"demandedAmount",fn:function(ref){
var item = ref.item;
return [(!item.accSign)?_c('MoneyDisplay',{attrs:{"value":item.demandedAmount,"decimals":_vm.getPrecision(),"currency":_vm.store.cashStatement.currency}}):_vm._e()]}},{key:"accDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getBookingDate(item))+" ")])]}},{key:"invNo",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.invNo) > 1 ? item.invNo : "-")+" ")])]}}])}),_c('v-expand-transition',[(_vm.showErrorList)?_c('section',[_c('v-row',[_c('label',{staticClass:"section-title",staticStyle:{"align-self":"self-end"}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticStyle:{"color":"red","padding-right":"5px"}},[_vm._v("fa-exclamation")]),_vm._v(" Kontírozási hibák ")],1)]),_c('v-row',{staticClass:"error-row"},[_c('grid',{staticClass:"error-list",attrs:{"data-items":_vm.errorItems,"columns":[
                    { field: 'type', title: 'Hiba típus' },
                    { field: 'note', title: 'Megjegyzés' } ],"scrollable":"none"}})],1)],1):_vm._e()])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.showErrorList = !_vm.showErrorList}}},[_vm._v(" Hibanapló megtekintése "),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticStyle:{"color":"red","padding-left":"5px"},attrs:{"x-small":""}},[_vm._v("fa-exclamation")])],1),_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.store.cashStatement.accStatus == 6},on:{"click":_vm.rekontir}},[_vm._v("Automatikus kontírozás")]),_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.store.cashStatement.accStatus == 6
          || _vm.store.cashStatement.accStatus == 8 
          || _vm.store.cashStatement.accStatus == 9 
          || _vm.store.cashStatement.accStatus == 10},on:{"click":_vm.sendAccounting}},[_vm._v("Feladás főkönyvi rendszerbe")])],1)],1)],1),_c('v-col',{attrs:{"sm":"3"}},[_c('CashStatementAccountingSummary',{attrs:{"items":_vm.items}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }