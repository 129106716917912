












import Vue from "vue";
import Tooltip from "./Tooltip.vue";

const version = process.env.VUE_APP_VERSION;

export default Vue.extend({
  components: {
    Tooltip,
  },

  data() {
    return {
      version: version ? `Alkalmazás verzió: ${version}` : "",
    };
  },
});
