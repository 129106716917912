
































































































































































































// Models
import Vue from "vue";
import eventBus from "@/services/eventBus";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";

// Components
import FlowInvoiceInformationView from "./FlowInvoiceInformationView.vue";
import FlowDocumentView from "./FlowDocumentView.vue";
import GENIPictureView from "./GENIPictureView.vue";
import CustomTab from "../../../components/CustomTab.vue";
import InvoiceAcceptStateView from "./InvoiceAcceptStateView.vue";
import OtherDocumentView from "./OtherDocumentView.vue";
import PartnerDocumentView from "./PartnerDocumentView.vue";
import FlowAccountancyInfoTab from "../components/invoiceInfoCards/FlowAccountancyInfoTab.vue";
import FlowHistoryInfoTab from "../components/invoiceInfoCards/FlowHistoryInfoTab.vue";
import InverseVatSummaryView from "../components/InverseVatSummaryView.vue";
import IconButton from "@/components/IconButton.vue";

interface Data {
  store: InvoiceFlowStore;
  tab: number | null;
  invoiceDetailsVisible: boolean;
  cardViewEnabled: boolean;
  invoicePictureVisible: boolean;
  invoiceInfoVisible: boolean;
  documentDemandVisible: boolean;
  otherDocumentVisible: boolean;
  inverseVatSummaryVisible: boolean;
  isInvoiceDetailsInFocus: boolean;
}

export default Vue.extend({
  components: {
    FlowInvoiceInformationView,
    FlowDocumentView,
    GENIPictureView,
    CustomTab,
    InvoiceAcceptStateView,
    OtherDocumentView,
    PartnerDocumentView,
    FlowAccountancyInfoTab,
    FlowHistoryInfoTab,
    InverseVatSummaryView,
    IconButton,
  },

  data: (): Data => ({
    store,
    tab: null,
    invoiceDetailsVisible: true,
    cardViewEnabled: false,
    invoicePictureVisible: true,
    invoiceInfoVisible: true,
    documentDemandVisible: true,
    otherDocumentVisible: true,
    inverseVatSummaryVisible: false,
    isInvoiceDetailsInFocus: false,
  }),

  created() {
    eventBus.$on("toggle-inv-vat-summary", this.toggleInverseVatSummaryVisible);
  },

  beforeDestroy() {
    eventBus.$off("toggle-inv-vat-summary", this.toggleInverseVatSummaryVisible);
  },

  methods: {
    toggleInverseVatSummaryVisible() {
      this.inverseVatSummaryVisible = !this.inverseVatSummaryVisible;
    },

    switchView() {
      this.cardViewEnabled = !this.cardViewEnabled;
    },

    toggleInvoiceDetailsInFocus() {
      this.isInvoiceDetailsInFocus = !this.isInvoiceDetailsInFocus;
    },
  },
});
