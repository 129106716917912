import GeneralIngoingInvoiceEditModel, {
  generalIngoingInvoiceEditModelDefaultProps,
} from "../models/GeneralIngoingInvoiceEditModel";
import GeneralIngoingInvoiceEditModelApi from "./GeneralIngoingInvoiceEditModelApi";
import busyIndication from "../../../utils/busyIndication";
import AccountStatus, { accountStatusDefaultProps } from "@/modules/notice/models/AccountStatus";
import PreInvoiceStatus, { preInvoiceStatusDefaultPops } from "@/modules/notice/models/PreInvoiceStatus";
import InvoiceFlowStatus, { invoiceFlowStatusDefaultProps } from "@/modules/invoiceFlowStatus/model/InvoiceFlowStatus";
import Attachment, { attachmentDefaultProps } from "@/modules/generalIngoingInvoice/models/Attachment";

export class GeneralIngoingInvoiceEditModelStore {
  invoices: GeneralIngoingInvoiceEditModel[] = [];
  invoice: GeneralIngoingInvoiceEditModel = { ...generalIngoingInvoiceEditModelDefaultProps };
  attachment: Attachment = { ...attachmentDefaultProps };

  accountStatuses: AccountStatus[] = [];
  selectedAccountStatus: AccountStatus | undefined = { id: -1, name: "Betöltés..." };

  statusNames: PreInvoiceStatus[] = [];
  selectedStatusName: PreInvoiceStatus | undefined = { id: -1, name: "Betöltés..." };

  //prettier-ignore
  flowStatuses: InvoiceFlowStatus[] = [
    // InvoiceFlowStatusEntity.cs alapján -> az id-ket lejjebb toljuk 1-el, hogy mindig arra a lepesre tudjon szurni a user, ami kovetkezni fog (nem pedig arra amiben adatbazis szerint van)
    { ...invoiceFlowStatusDefaultProps, name: "-", id: 0 },
    { ...invoiceFlowStatusDefaultProps, name: "Iktatás", id: 1 },
    { ...invoiceFlowStatusDefaultProps, name: "Pénzügyi rögzítés", id: 2 },
    { ...invoiceFlowStatusDefaultProps, name: "Tétel rögzítés és teljesítés igazolás", id: 3 },
    { ...invoiceFlowStatusDefaultProps, name: "Ellenőrzés, jóváhagyás", id: 4 },
    { ...invoiceFlowStatusDefaultProps, name: "Könyvelés, rögzítés", id: 5 }, 
  ];

  selectedFlowStatus: InvoiceFlowStatus | undefined;

  @busyIndication()
  async loadInvoices() {
    this.invoices = (await GeneralIngoingInvoiceEditModelApi.getAll()).map((x) => ({
      ...x,
      expanded: false,
      selected: false,
    }));
  }

  async updatePermitStatuses(invoiceIds: number[], permitStatus: number) {
    await GeneralIngoingInvoiceEditModelApi.updatePermitStatuses(invoiceIds, permitStatus);
  }

  async loadAccountStatuses() {
    this.accountStatuses = await GeneralIngoingInvoiceEditModelApi.getAccountStatuses();
    // const ass = await GeneralIngoingInvoiceEditModelApi.getAccountStatuses();
    // const as: AccountStatus = { ...accountStatusDefaultProps, name: "-", id: null };
    // this.accountStatuses.push(as, ...ass);
  }

  async loadStatusNames() {
    this.statusNames = await GeneralIngoingInvoiceEditModelApi.getStatusNames();
    // const ss = await GeneralIngoingInvoiceEditModelApi.getStatusNames();
    // const s0: PreInvoiceStatus = { ...preInvoiceStatusDefaultPops, name: "-", id: null };
    // this.statusNames.push(s0, ...ss);
  }

  //ezt nem használjuk
  async loadInvoiceFlowStatuses() {
    // this.flowStatuses = await GeneralIngoingInvoiceEditModelApi.getFlowStatuses(); // nem kerjuk le a backendrol a workflow statuszokat, mert a kimeno szamlak bezavarnak
  }

  clearStatuses() {
    this.accountStatuses = [];
    this.statusNames = [];
    // this.flowStatuses = []; // ezt manuálisan beállítjuk
  }

  @busyIndication()
  async openInvoiceImage(invoiceId: number, prefix: string) {
    if (prefix === "GENI") {
      await GeneralIngoingInvoiceEditModelApi.getGENIFile(invoiceId).then((result) => {
        if (result == null) {
          this.attachment = attachmentDefaultProps;
          return;
        }
        this.attachment = result;
        this.attachment.link = GeneralIngoingInvoiceEditModelApi.generateFileDownLoadLink(result.id); // benne van így már a X-TruFinance-ConnectionID
        window.open(this.attachment.link);
      });
    } else {
      const base64 = await GeneralIngoingInvoiceEditModelApi.getFileFromKozpontiSzamlazo(invoiceId, prefix);
      const pdfWindow = window.open("");
      if (pdfWindow) {
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64) + "'></iframe>"
        );
      }
    }
  }
}

const store = new GeneralIngoingInvoiceEditModelStore();
export default store;
