



















import Vue, { PropType } from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import permStore, { PermissionStore } from "../../../services/permissions";

interface Data {
  store: InvoiceFlowStore;
  permStore: PermissionStore;
  invoiceId: number;
}

interface Methods {
  createRemark(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  props: {
    invId: { type: Number },
  },

  data: () => ({
    store,
    permStore,
    invoiceId: 1,
  }),

  async beforeMount() {
    this.invoiceId = Number(this.$route.params.invoiceId);
  },

  methods: {
    async createRemark() {
      const invoiceID = Number(this.$route.params.invoiceId);
      if (Number.isNaN(invoiceID)) return;
      await this.store.createInvoiceRemark(invoiceID, this.store.invoice.remark);
    }      
  },

  computed: {
    isInvId() {
      return Number.isNaN(Number(this.$route.params.invoiceId));
    },

    isPageDisabled() {
      switch (this.store.step) {
        default:
        case 2:
          return !this.permStore.hasRecording() || !this.store.isEditingEnabled;
        case 3:
          return !this.permStore.hasFinancialRecording() || !this.store.isEditingEnabled;
        case 4:
          return !this.permStore.hasStockRelationRecording() || !this.store.isEditingEnabled;
        case 5:
          return !this.permStore.hasControlling() || !this.permStore.hasAccepting() || !this.store.isEditingEnabled;
        case 6:
          return !this.permStore.hasAccounting(); // || !this.store.isEditingEnabled;
      }
    },
  },
});
