import { FlowStatus } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import Vue from "vue";

interface InvoiceListParams {
  step?: number;
  invoiceID?: number;
  isControl?: boolean;
  isPermit?: boolean;
}

function GENIPath(invoiceID: number, step: number) {
  return `/general-incoming-invoice/flow/edit/${invoiceID}/${step}`;
}

function DownPaymentPath(invoiceID: number) {
  return `/down-payment/edit/${invoiceID}`;
}

export class InvoiceWizardStep implements InvoiceListParams {
  step = 0;
  invoiceID = 0;
  isControl = false;
  isPermit = false;
  path = "";

  constructor({ step, invoiceID, isControl, isPermit }: InvoiceListParams) {
    this.step = step ?? 0;
    this.invoiceID = invoiceID ?? 0;
    this.isControl = isControl ?? false;
    this.isPermit = isPermit ?? false;

    this.path = this.step > 0 ? GENIPath(this.invoiceID, this.step) : DownPaymentPath(this.invoiceID);
  }
}

export class InvoiceWizard {
  steps: InvoiceWizardStep[] = [];
  currentIndex = 0;
  connectionID = "";

  // még nem iktatott
  rec: InvoiceWizardStep[] | null = null;
  // pénzügyesre vár
  fin: InvoiceWizardStep[] | null = null;
  // teljesítés igazolás ellenőrizhető
  ffc: InvoiceWizardStep[] | null = null;
  // teljesítés igazolás jóváhagyható
  ffa: InvoiceWizardStep[] | null = null;
  // ellenőrizhető
  con: InvoiceWizardStep[] | null = null;
  // jóváhagyható
  acc: InvoiceWizardStep[] | null = null;
  // utalványozható - normál
  per: InvoiceWizardStep[] | null = null;
  // utalványozható - előlegbekérő
  dwp: InvoiceWizardStep[] | null = null;
  // könyvelhető
  pay: InvoiceWizardStep[] | null = null;

  constructor(steps: InvoiceWizardStep[]) {
    this.steps = steps;
    this.currentIndex = 0;
    this.connectionID = localStorage.getItem("trufinance.connectionID") ?? "";

    this.rec = steps.filter((x) => x.step == FlowStatus.Recording && !x.isPermit);
    this.fin = steps.filter((x) => x.step == FlowStatus.FinancialRecording);
    this.ffc = steps.filter((x) => x.step == FlowStatus.StockRelationRecording && x.isControl);
    this.ffa = steps.filter((x) => x.step == FlowStatus.StockRelationRecording && !x.isControl);
    this.con = steps.filter((x) => x.step == FlowStatus.ControllingAccepting && x.isControl);
    this.acc = steps.filter((x) => x.step == FlowStatus.ControllingAccepting && !x.isControl);
    this.per = steps.filter((x) => x.step == FlowStatus.Recording && x.isPermit);
    this.dwp = steps.filter((x) => x.step == 0 && x.isPermit);
    this.pay = steps.filter((x) => x.step == FlowStatus.Accounting);
  }

  restart() {
    this.currentIndex = 0;
  }

  prev() {
    this.currentIndex--;
  }

  next() {
    this.currentIndex++;
  }

  get hasItems(): boolean {
    return (
      (this.rec?.length ?? 0) > 0 ||
      (this.fin?.length ?? 0) > 0 ||
      (this.ffc?.length ?? 0) > 0 ||
      (this.ffa?.length ?? 0) > 0 ||
      (this.con?.length ?? 0) > 0 ||
      (this.acc?.length ?? 0) > 0 ||
      (this.per?.length ?? 0) > 0 ||
      (this.dwp?.length ?? 0) > 0 ||
      (this.pay?.length ?? 0) > 0
    );
  }
}

export class InvoiceWizardStore {
  wizard: InvoiceWizard | undefined = undefined;

  startWizard(steps: InvoiceWizardStep[]) {
    this.wizard = new InvoiceWizard(steps);
  }

  cancelWizard() {
    this.wizard = undefined;
  }

  get() {
    const saved = localStorage.getItem("invoiceWizard");
    if (!saved) return;
    const parsed: InvoiceWizard = JSON.parse(saved);
    this.wizard = new InvoiceWizard(parsed.steps);
    this.wizard.currentIndex = parsed.currentIndex;
  }

  save() {
    const parsed = JSON.stringify(this.wizard);
    localStorage.setItem("invoiceWizard", parsed);
  }

  remove() {
    localStorage.removeItem("invoiceWizard");
    this.wizard = undefined;
  }

  setCurrentIndex(invoiceID: number) {
    if (!this.wizard) return;
    this.wizard.currentIndex = this.wizard.steps.findIndex((s) => s.invoiceID == invoiceID) ?? 0;
  }

  hasInvoice(invoiceID: number): boolean {
    return this.wizard?.steps.some((x) => x.invoiceID == invoiceID) ?? false;
  }

  get current(): InvoiceWizardStep | undefined {
    return this.wizard?.steps[this.wizard.currentIndex];
  }

  private currentProgress(list: InvoiceWizardStep[] | null | undefined) {
    return list && this.current
      ? `(${list.findIndex((i) => i.invoiceID == this.current?.invoiceID) + 1}/${list.length})`
      : "";
  }

  get progress(): string {
    if (!this.wizard) return "";
    return `(${this.wizard.currentIndex + 1}/${this.wizard.steps.length})`;
  }

  get currentList(): string {
    switch (this.current?.step) {
      case 0:
        return `Utalványozás - előlegbekérő ${this.currentProgress(this.wizard?.dwp)}`;

      case FlowStatus.Recording:
        if (this.current.isPermit) return `Utalványozás - normál számla ${this.currentProgress(this.wizard?.per)}`;
        return `Iktatás ${this.currentProgress(this.wizard?.rec)}`;

      case FlowStatus.FinancialRecording:
        return `Pénzügyi rögzítés ${this.currentProgress(this.wizard?.fin)}`;

      case FlowStatus.StockRelationRecording: {
        if (this.current.isControl) return `Teljesítés ellenőrzés ${this.currentProgress(this.wizard?.ffc)}`;
        else return `Teljesítés jóváhagyás ${this.currentProgress(this.wizard?.ffa)} `;
      }

      case FlowStatus.ControllingAccepting: {
        if (this.current.isControl) return `Számla ellenőrzés ${this.currentProgress(this.wizard?.con)}`;
        else return `Számla jóváhagyás ${this.currentProgress(this.wizard?.acc)} `;
      }
      case FlowStatus.Accounting:
        return `Könyvelés ${this.currentProgress(this.wizard?.pay)}`;

      default:
        return ``;
    }
  }
}

const store = Vue.observable(new InvoiceWizardStore());
export default store;
