






























import CashStatementAccountingItem from "../../cashStatement/models/CashStatementAccountingItem";
import Vue from "vue";
import store, { CashStatementStore } from "../../cashStatement/services/store";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import configuration, { loadConfiguration } from "@/models/Configuration";
import type { AppConfiguration } from "@/models/Configuration";
import bankStore, { BankStatementStore } from "@/modules/bankStatement/services/BankStatementStore";
import BankStatementAccountingItem from "@/modules/bankStatement/models/BankStatementAccountingItem";

interface Props {
  items: CashStatementAccountingItem[];
  isBank: boolean;
}

interface Data {
  store: CashStatementStore;
  bankStore: BankStatementStore;
  configuration: AppConfiguration;
}

interface Computed {
  income: number;
  outcome: number;
  currency: string;
}

interface Method{
    getPrecision(): number;
}

export default Vue.extend<Data, Method, Computed, Props>({
  props: {
    isBank: {
      type: Boolean,
      default: false
    },

    items: {
      type: Array as () => CashStatementAccountingItem[] | BankStatementAccountingItem[],
      default: new Array<BankStatementAccountingItem | CashStatementAccountingItem>(),
    },
  },

  methods: {
    getPrecision(){
        const res = this.configuration.operel
            ? this.currency != "HUF"
                ? 2
                : 0
            : this.configuration.getCurrDigits(this.currency)?.geniHeadDigits??2;
        return res;
    }
  },

  async mounted(){
    if(!this.configuration.isLoaded){
        await loadConfiguration();
    }
  },

  components: {
    MoneyDisplay
  },

  data: () => ({
    store,
    bankStore,
    configuration
  }),

  computed: {
    currency(){
      return this.$props.isBank ? 
        this.bankStore.bankStatement.currency
        : this.store.cashStatement.currency;
    },

    income() {
      const items = this.$props.isBank 
        ? this.$props.items as BankStatementAccountingItem[] 
        : this.$props.items as CashStatementAccountingItem[];

      return items
        .filter((x) => x.accSign)
        .reduce((accumulator, value) => accumulator + value.oweAmount, 0);
    },

    outcome() {
      const items = this.$props.isBank 
        ? this.$props.items as BankStatementAccountingItem[] 
        : this.$props.items as CashStatementAccountingItem[];

      return items
        .filter((x) => !x.accSign)
        .reduce((accumulator, value) => accumulator + value.demandedAmount, 0);
    },
  },
});
