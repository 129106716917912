import { CurrentAccountApi } from "./CurrentAccountApi";

export class CurrentAccountStore {
  async saveArrangement(payLoad: any) {
    return await CurrentAccountApi.saveArrangement(payLoad);
  }

  async checkAccDate(accDate: string) {
    const ret = (await CurrentAccountApi.checkAccDate(accDate));
    return ret;
  }
}

const store = new CurrentAccountStore();
export default store;