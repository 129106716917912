var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApiSearchPage',{attrs:{"title":"Banki kivonat importálás","create":"Új import","createButton":true,"url":"bank-extract-import/search","columns":_vm.columns},on:{"create":function($event){return _vm.create()}},scopedSlots:_vm._u([{key:"detailsButton",fn:function(ref){
var item = ref.item;
return [_c('icon-button',{attrs:{"tooltip":"Információ","icon":"fa-bars"},on:{"click":function($event){item.details = !item.details}}})]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('BankExtractImportItems',{attrs:{"head":item}})]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"d-block white--text",class:_vm.store.statusColors.get(item.statusID),attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"command",fn:function(ref){
var item = ref.item;
return [_c('icon-button',{attrs:{"tooltip":"Szerkesztés","icon":"fa-edit"},on:{"click":function($event){return _vm.edit(item)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }