export default interface PermitStatus {
  id: number;
  name: string;
}

export const permitStatusDefaultProps: PermitStatus = {
  id: 0,
  name: "",
};

export enum PermitStatuses {
  WaitingForPermit = 2,
  Payable = 3,
}
