import User from "@/modules/invoiceGroup/models/User";

export default interface ApprovalMatrixUser {
  id: number;
  deleted: boolean;
  head: number;
  userId: number;
  name: string;

  selectedUser: User | any;
  deleting: boolean;
}

export const approvalMatrixUserDefaultProps: ApprovalMatrixUser = {
  id: 0,
  deleted: false,
  head: 0,
  userId: 1,
  name: "-",

  selectedUser: "",
  deleting: false,
};
