import http, { handleApiResponse } from "@/services/http";
import CurrentAccount from "../models/CurrentAccount";
import ApiResponse from "@/models/ApiResponse";
import ApiSearch from "@/components/apiSearch/models/ApiSearch";

export function getCurrentAccount(
  apiSearch: ApiSearch
): Promise<CurrentAccount> {
  return http
    .post<ApiResponse<CurrentAccount>>("currentAccount", {
      currentPage: apiSearch.currentPage,
      pageSize: apiSearch.pageSize,
      searchText: apiSearch.searchText,
      filter: apiSearch.filter?.filters,
      extraFilter: apiSearch.extraFilter,
      sort: apiSearch.sort?.toObject(),
    })
    .then(handleApiResponse);
}
