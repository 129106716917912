
































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, {GpartsEditStore} from "../services/GpartsEditStore"
import GPartEdit from "../components/GPartEdit.vue";

interface Data {
  store: GpartsEditStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
      Page,
      GPartEdit
  },

  data() {
    return {
      store
    };
  },
});
