















































import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";

interface Data {
  store: DownPaymentStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadDownPaymentAcceptRequests(Number(this.$route.params.invoiceId));
  },
});
