



















































































































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import { ValidationRules } from "../../../models/Validation";
import store, { InvoiceTypeStore } from "../services/InvoiceTypeStore";
import cStore, {
  CashPointStore,
} from "../../cashPoint/services/CashPointStore";
import InvoiceType, { invoiceTypeDefaultProps } from "../models/InvoiceType";
//import InvoiceGroupDetailsPage from "../../invoiceGroup/views/InvoiceGroupDetailsPage.vue";

interface Data {
  store: InvoiceTypeStore;
  cStore: CashPointStore;
  opened: boolean;
  valid: boolean;
}

const CREATE_TITLE = "Értesítő típus létrehozása";
const MODIFY_TITLE = "Értesítő típusok módosítása";

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      cStore,
      valid: true,
      opened: false,
    };
  },

  computed: {
    title() {
      return this.store.invoiceType?.id > 0 ? MODIFY_TITLE : CREATE_TITLE;
    },

    relationItems() {
      return this.store.invoiceType.partnerRelations;
    },
  },

  async beforeMount() {
    const invoiceTypeId =
      Number(this.$route.params.invoiceTypeId) > 0
        ? Number(this.$route.params.invoiceTypeId)
        : 0;

    if (invoiceTypeId !== 0) {
      await this.store.loadInvoiceType(invoiceTypeId);
    } else {
      this.store.invoiceType = { ...invoiceTypeDefaultProps };
    }
    await this.cStore.loadAccountNoDetailedTypes();
    await this.store.loadInvoiceSetting();
  },

  methods: {
    async save() {
      await this.store.save();
      this.$router.push("/invoice-types");
    },
  },
});
