export default class BankExtractImportItemInvoiceRelation {
  id = 0;
  prefix = "";
  invoiceID = 0;
  invoiceNo = "";
  total = 0;
  currency = "";
  partnerName = "";
  remark = "";

  constructor(params?: Partial<BankExtractImportItemInvoiceRelation>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}
