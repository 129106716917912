
















import Vue from "vue";
import store, { BankRemitStore } from "../services/BankRemitStore";
import BankRemitApi from "../services/BankRemitApi";
import PartBank, { partBankDefaultProps } from "../../cashPoint/models/PartBank";

interface Data {
  store: BankRemitStore;
  partner: number;
  bankAccountNames: PartBank[];
}

interface Props {
  value: number;
  bankAccountName: string;
  disabled: boolean;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    value: { type: Number, default: 1 },
    bankAccountName: { type: String },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      store,
      partner: 1,
      bankAccountNames: [],
    };
  },

  async beforeMount() {
    //this.partner = { ...this.value };
    await BankRemitApi.getPartBanksByPartner(this.value).then((data) => (this.bankAccountNames = data));

    const p = { ...partBankDefaultProps };
    p.accountNo = this.bankAccountName;
    this.bankAccountNames.push(p);
  },

  methods: {
    bankAccountChanged(e: Event) {
      this.$emit("bankAccountChanged", e);
    },
  },
});
