




























import Vue from "vue";
import FixAssetGroup from "../models/FixAssetGroup";
import store, { FixAssetGroupStore } from "../services/FixAssetGroupStore";

interface Data {
  store: FixAssetGroupStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadFixAssetGroups();
  },

  methods: {
    editItem(item: FixAssetGroup) {
      this.$router.push(`/fix-asset/groups/${item.id}`);
    },
  },

  computed: {
    columns: () => [
      {
        field: "name",
        title: "Név",
      },
      {
        width: 50,
        cell: "commandTemplate",
      },
    ],
  },
});
