import User from "@/modules/invoiceGroup/models/User";

export default interface MessagedGroupResponse {
  users: User[];
  groupId: string;
  selected: boolean;
  names: string;
}

export const messagedGroupResponseDefaultProps: MessagedGroupResponse = {
  users: [],
  groupId: "",
  selected: false,
  names: "",
};
