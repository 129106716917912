export default interface FlowDocumentRelation {
    id: number;
    statusId: number;
    documentTypeId: number;
    headId: number;
    documentType: string;
    invId: number;
    required: boolean;
    deleted: boolean;

    hasFile: boolean;
    documentId: number;
    link: string;
    fileName: string;
    fileExtension: string;
    
    uploading: boolean;
}

export const flowDocumentRelationDefaultProps: FlowDocumentRelation = {
    id: 0,
    statusId: 0,
    documentTypeId: 0,
    headId: 0,
    documentType: "",
    invId: 0,
    required: false,
    deleted: false,

    hasFile: false,
    documentId: 0,
    link: "",
    fileName: "",
    fileExtension: "",

    uploading: false,
};
