var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.statStore.invoiceFlowStatuses),function(status,i){return _c('div',{key:status.id,class:{ 'mb-2': i + 1 < _vm.statStore.invoiceFlowStatuses.length }},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","align":"right"}},[_c('label',{staticClass:"input-label mt-0"},[_vm._v(" "+_vm._s(status.name)+" ")])]),_c('v-col',_vm._l((_vm.relStore.flowDocumentRelations),function(relation){return _c('div',{key:relation.id},[(relation.statusId == status.id)?_c('div',{staticClass:"value-label"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"vertical-align":"baseline"},attrs:{"color":relation.hasFile ? 'var(--primaryColor)' : 'error',"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(relation.hasFile ? "fa-check" : "fa-circle-o")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(relation.hasFile ? "Feltöltve" : "Nincs feltöltve")+" ")])]),_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"title":'Dokumentum feltöltése'},on:{"click":function($event){return _vm.showFile(relation)}}},[_vm._v(" "+_vm._s(relation.documentType)+" "),(relation.required)?_c('span',{staticClass:"required_label mr-1"},[_vm._v("Kötelező")]):_vm._e()]),_c('v-expand-transition',[(relation.uploading)?_c('div',{staticClass:"px-2",staticStyle:{"background-color":"#f5f5f5"}},[_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('v-expand-transition',[(relation.fileName != '')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('label',_vm._g(_vm._b({class:{ download: relation.fileName != '' },on:{"click":function($event){return _vm.beforeDownload(relation)}}},'label',attrs,false),on),[_vm._v(" "+_vm._s(relation.fileName)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Fájl letöltése ")])])],1):_vm._e()])],1),_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-right":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!relation.hasFile || !_vm.store.isEditingEnabled || _vm.store.invoice.id == 0},on:{"click":function($event){return _vm.downloadFile(relation.link)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-download ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Fájl letöltése ")])])],1),_c('v-col',[_c('v-file-input',{ref:"file",refInFor:true,attrs:{"dense":"","outlined":"","hide-details":"auto","clear-icon":"fa-times","prepend-icon":"","accept":"image/jpeg,image/png,application/pdf,text/plain","id":("file-" + (relation.statusId) + "-" + (relation.id) + "-" + (relation.documentTypeId)),"disabled":!_vm.store.isEditingEnabled || _vm.store.invoice.id == 0},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('label',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"append-inner",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-plus ")])]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!_vm.store.isEditingEnabled || _vm.store.invoice.id == 0},on:{"click":function($event){return _vm.uploadFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl feltöltése")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-bottom":"8px !important","margin-left":"-1px"},attrs:{"color":"primary","outlined":"","disabled":!relation.hasFile || !_vm.store.isEditingEnabled || _vm.store.invoice.id == 0},on:{"click":function($event){return _vm.deleteFile(relation)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-trash-o ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fájl törlése")])])],1)],1)],1):_vm._e()])],1):_vm._e()])}),0)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }