import { ValidationRules } from "@/models/Validation";
import PartBank, { partBankDefaultProps } from "@/modules/cashPoint/models/PartBank";

export default interface GenoExperimentalInvoice {
    id: number;
    partner: number;
    partnerName: string;
    pmode: number;
    pmodeName: string;
    invDate: Date | string;
    pterm: number;
    status: number;
    statusName: string;
    invType: number;
    invTypeName: string;
    accGroup: number;
    accGroupName: string;
    currency: string;
    remark1: string;
    remark2: string;
    language: number;
    languageName: string;
    withVat: boolean;
    userId: number;
    userName: string;
    insDate: Date | string;
    partCust: number;
    partCustName: string;
    bankAccount: number;
    bankAccountName: string;
    bankAccountObject: PartBank;
    partnerRel: number;
    partnerRelName: string;
    isCyclic: boolean;
    dayMonth: boolean;
    cyclic: number;
    car: number;
    divItem: number;
    divItemName: string;
    preType: number;
    preTypeName: string;
    shipDateC: boolean;
    poId: number;
    testMove: number;
    norder: number;
    sorder: number;
    costType: number;
    costTypeName: string;
    selected: boolean;
    finalGenoId: number;
    accInvReg: number;
    isProform: boolean;
    proformId: number;
    invoice: number;
    diffTax: boolean;
    isIntr: boolean;
    isEinvoice: boolean;
    finalGenoStatus: number;
    isRabatVisible: boolean;
    netto: number;
    total: number;
    vat: number;
    currNetto: number;
    currTotal: number;
    currVat: number;
    storno: boolean;
    invoiceSt: number;
    rectify: boolean;
    invoiceRect: number;
    isPaid: boolean;
}

export const rules: ValidationRules = {
    pmodeName: [
        (v: string) => v != "-" || 'Kötelező',
    ],
    accGroupName: [
        (v: string) => v != "-" || 'Kötelező'
    ],
    invTypeName: [
        (v: string) => v != "-" || 'Kötelező'
    ],
    statusName: [
        (v: string) => v != "-" || 'Kötelező'
    ],
    divItemName: [
        (v: string) => v != "-" || 'Kötelező'
    ],
    preTypeName: [
        (v: string) => v != "-" || 'Kötelező'
    ],
}

export const genoExperimentalInvoiceDefaultProps: GenoExperimentalInvoice = {
    id: 1,
    partner: 1,
    partnerName: "",
    pmode: 1,
    pmodeName: "",
    invDate: "",
    pterm: 1,
    status: 1,
    statusName: "",
    invType: 1,
    invTypeName: "",
    accGroup: 1,
    accGroupName: "",
    currency: "",
    remark1: "",
    remark2: "",
    language: 1,
    languageName: "",
    withVat: false,
    userId: 1,
    userName: "",
    insDate: "",
    partCust: 1,
    partCustName: "",
    bankAccount: 1,
    bankAccountName: "",
    bankAccountObject: { ...partBankDefaultProps },
    partnerRel: 1,
    partnerRelName: "",
    isCyclic: false,
    dayMonth: false,
    cyclic: 1,
    car: 1,
    divItem: 1,
    divItemName: "",
    preType: 1,
    preTypeName: "",
    shipDateC: false,
    poId: 1,
    testMove: 1,
    norder: 1,
    sorder: 1,
    costType: 1,
    costTypeName: "",
    selected: false,
    finalGenoId: 1,
    accInvReg: 1,
    isProform: false,
    proformId: 1,
    invoice: 1,
    diffTax: false,
    isIntr: false,
    isEinvoice: false,
    finalGenoStatus: 1,
    isRabatVisible: false,
    netto: 0,
    total: 0,
    vat: 0,
    currNetto: 0,
    currTotal: 0,
    currVat: 0,
    storno: false,
    invoiceSt: 1,
    rectify: false,
    invoiceRect: 1,
    isPaid: false
}