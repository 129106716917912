






























// Components
import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import StockCorrectionStoreInSearch from "../components/StockCorrectionStoreInSearch.vue";

// Models
import Vue from "vue";
import eventBus from "@/services/eventBus";
import store, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import correctionStore, { UninvoicedStockCorrectionStore } from "../services/store";
import * as InvoiceItemApi from "@/modules/invoiceItems/services/api";
import Partner from "@/modules/partner/models/Partner";
import { Stock } from "@/modules/invoiceItems/models";

interface Data {
  store: InvoiceFlowStore;
  correctionStore: UninvoicedStockCorrectionStore;
}

interface Methods {
  partnerSelected(partner: Partner): void;
  stockSelected(stock: Stock): void;
  cancel(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: { PartnerSearch, StockCorrectionStoreInSearch },

  data: () => ({ store, correctionStore }),

  created() {
    eventBus.$on("uninvoiced-stock-correction-item:add", this.stockSelected);
  },

  beforeDestroy() {
    eventBus.$off("uninvoiced-stock-correction-item:add", this.stockSelected);
  },

  async beforeMount() {
    this.correctionStore.storeIns = [];
    this.correctionStore.items = [];

    if (this.correctionStore.units.length == 0) {
      this.correctionStore.units = await InvoiceItemApi.getUnits();
    }
    if (this.correctionStore.taxAndVatItems.length == 0) {
      this.correctionStore.taxAndVatItems = await InvoiceItemApi.getAllTaxAndVatItem();
    }
  },

  methods: {
    partnerSelected(partner) {
      this.store.invoice.partner = partner.id;
      this.$router.push(`/uninvoiced-stock-correction/create/${partner.id}`);
    },

    stockSelected(stock) {
      if (Number(this.$route.params.partnerId) > 1) return;
      const supplierID = stock.supplierID;
      this.$router.push(`/uninvoiced-stock-correction/create/${supplierID}`);
    },

    cancel() {
      this.$router.push(`/uninvoiced-stock-correction`);
    },
  },
});
