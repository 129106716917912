





















































































































































































































































































import Vue from "vue";
import dayjs from "dayjs";
import store, { BankStatementStore } from "../services/BankStatementStore";
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { formatDate } from "@/utils/formatDates";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import BankDocumentCreatePage from "../views/BankDocumentCreatePage.vue";
import BankDocument, {BankDocumentInvoice} from "../models/BankDocument";
import IconButton from "@/components/IconButton.vue";
import ImportFromBankRemitsView from "./ImportFromBankRemitsView.vue";
import eventBus from "@/services/eventBus";
import BankRemitItem from "@/modules/bankRemit/models/BankRemitItem";
import MenuButton from "@/components/MenuButton.vue";
import { formatMoney } from "@/filters/money-format";
import configuration from "@/models/Configuration";
import NotoPdfViewer from "./NotoPdfViewer.vue";

interface Data {
  store: BankStatementStore;

  createPageVisible: boolean;

  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;
  isImportingRemit: boolean;
  isBalanceAll: boolean;
  isBalanceClosed: boolean;
  lastEditedBankStatementID: number;
  searchFilter: string;
}

interface Methods {
  payRemits(items: BankRemitItem[]): void;
  importFromRemit(): void;
  editShowItems(item: BankDocument): void;
  editShowInvoiceItems(item: BankDocument): void;
  deleteInvoice(item: BankDocumentInvoice): void;
  paidAllItems(): void;

  editItem(item: BankDocument): void;
  create(): void;
  hideCreate(): void;
  refreshItems(): void;
  filterChange(e: { filter: CompositeFilterDescriptor }): void;
  sortChange(e: { sort: SortDescriptor[] }): void;
}

interface Computed {
  options: any;
  documentColumns: any;
  documentItems: any;
  paymentItemColumns: any;
  invoiceItemColumns: any;
  balanceAll: number;
  balanceClosed: number;
  defaultCurrency: string;
  bankDocumentItems: BankDocument[];
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    MoneyDisplay,
    BankDocumentCreatePage,
    IconButton,
    ImportFromBankRemitsView,
    MenuButton,
    NotoPdfViewer
  },

  beforeMount(){
    eventBus.$on("pay-remits", (items)=> {
      this.payRemits(items)
    })

    eventBus.$on("cancel-import", ()=>{
      this.isImportingRemit = false;
    })        
  },

  data() {
    return {
      store,

      createPageVisible: false,
      isImportingRemit: false,

      sort: [],
      filter: {
        logic: "and",
        filters: [],
      },
      isBalanceAll: false,
      isBalanceClosed: false,
      lastEditedBankStatementID: 0,
      searchFilter: '',
    };
  },

  methods: {
    async paidAllItems(){
      await this.store.paidAllBankDocument();
      this.refreshItems();
    },

    async payRemits(items: BankRemitItem[]){
      const result = await this.store.payRemitItemsAsStatementItems(Number(this.$route.params.id),items)
      if(result){
        eventBus.$emit("success", "Sikeresen fizetve!")
        window.location.reload()
      }
      else eventBus.$emit("error", "Nem sikerült fizetni!")
    },  

    importFromRemit(): void {
      this.isImportingRemit = !this.isImportingRemit;
    },

    editShowItems(item: BankDocument): void {
      if (item.showInvoices && item.showPayments){
        //force update
        item.showPayments = false;
        item.showPayments = true;

        item.showInvoices = false;
      } else {
        item.showPayments = !item.showPayments;
        item.showInvoices = false;
      }
    },

    editShowInvoiceItems(item: BankDocument): void {
      if (!item.showInvoices && item.showPayments){
        //force update
        item.showPayments = false;
        item.showPayments = true;

        item.showInvoices = true;
      } else {
        item.showPayments = !item.showPayments;
        item.showInvoices = true;
      }
    },

    editItem(item: BankDocument) {
      this.store.bankDocument = { ...item };
      this.store.bankDocument.bankStatementItem = { ...item.bankStatementItem }

      this.lastEditedBankStatementID = this.store.bankDocument.bankStatementItem.id;
      this.createPageVisible = true;
    },

    create() {
      if (this.store.bankDocuments.some(x => x.status == 'Feldolgozás alatt') && this.store.bankStatement.currency != this.defaultCurrency){
        eventBus.$emit("error", "Kérem előbb zárja le a folyamatban lévő kivonat tételt!");
        return;
      }

      window.scrollTo(0, 0);
      this.store.bankDocument = new BankDocument();
      this.lastEditedBankStatementID = 0;
      this.createPageVisible = true;
    },

    hideCreate() {
      this.createPageVisible = false;
      this.lastEditedBankStatementID = 0;
    },

    deleteInvoice(item: BankDocumentInvoice){
      this.store.deleteInvoicePayment(item.bankDocItem, item.code, item.invoice).then(_ => {
        this.refreshItems();
      });
    },

    async refreshItems() {
      console.log(this.lastEditedBankStatementID);

      await this.store.loadBankDocumentsByStatementId(
        Number(this.$route.params.id)
      );

      if (this.lastEditedBankStatementID > 1){
        const item = this.store.bankDocuments.find(x => x.bankStatementItem.id == this.lastEditedBankStatementID);

        if (item){
          this.store.bankDocument = item;
        }
      } else {
        if (this.store.bankDocuments.length > 0){
          const item = this.store.bankDocuments[this.store.bankDocuments.length -1]
          this.store.bankDocument = item;
        }
      }
    },

    filterChange(e: { filter: CompositeFilterDescriptor }) {
      this.filter = e.filter;
    },

    sortChange(e: { sort: SortDescriptor[] }) {
      this.sort = e.sort;
    },
  },

  computed: {
    bankDocumentItems(){
      if (!this.searchFilter){
        return this.store.bankDocuments;
      } else {
        return this.store.bankDocuments.filter((x: any) => {
          return (dayjs(x.insDate).format("YYYY.MM.DD").includes(this.searchFilter)) ||
          (x.bankStatementItem.id.toString().toUpperCase().includes(this.searchFilter.toUpperCase())) ||
          (x.partner.toString().toUpperCase().includes(this.searchFilter.toUpperCase())) ||
          (x.status.toString().toUpperCase().includes(this.searchFilter.toUpperCase())) ||
          (x.accountType.toString().toUpperCase().includes(this.searchFilter.toUpperCase())) ||
          (x.user.toString().toUpperCase().includes(this.searchFilter.toUpperCase()));
        });
      }
    },

    balanceAll(){
      if (this.store.bankStatement.currency != this.defaultCurrency){
        return this.store.bankDocuments.filter(x => x.status.toLocaleLowerCase() != "rontott").map(x => x.bankStatementItem.currAmount * (x.bankStatementItem.crediting ? 1 : -1)).reduce((sum, x) => sum += x, 0) + this.store.bankStatement.openBalance;
      }

      return this.store.bankDocuments.filter(x => x.status.toLocaleLowerCase() != "rontott").map(x => x.bankStatementItem.amount * (x.bankStatementItem.crediting ? 1 : -1)).reduce((sum, x) => sum += x, 0) + this.store.bankStatement.openBalance;
    },

    balanceClosed(){
      if (this.store.bankStatement.currency != this.defaultCurrency){
        return this.store.bankDocuments.filter(x => x.status.toLocaleLowerCase() == "nem módosítható").map(x => x.bankStatementItem.currAmount * (x.bankStatementItem.crediting ? 1 : -1)).reduce((sum, x) => sum += x, 0) + this.store.bankStatement.openBalance;
      }

      return this.store.bankDocuments.filter(x => x.status.toLocaleLowerCase() == "nem módosítható").map(x => x.bankStatementItem.amount * (x.bankStatementItem.crediting ? 1 : -1)).reduce((sum, x) => sum += x, 0) + this.store.bankStatement.openBalance;
    },
    
    defaultCurrency(){
      return (configuration.sysParams.defaultCurrency as any).value;
    },

    options() {
      return {
        duration: 800,
        offset: 0,
        easing: "easeInOutQuad",
      };
    },

    documentItems() {
      return orderBy(
        filterBy(this.store.bankDocuments, this.filter),
        this.sort
      );
    },

    paymentItemColumns: () => [
      {
        field: "id",
        title: "Azonosító",
      },
      {
        field: "code",
        title: "Prefix",
      },
      {
        field: "invoice",
        title: "Számla azonosító",
      },
      {
        field: "externalInvNo",
        title: "Külső számlaszám",
      },
      {
        field: "paidCurrAmount",
        title: "Fiz. összeg (dev)",
        cell: "currAmount",
        headerClassName: "right_text",
      },
      {
        field: "amount",
        title: "Összeg",
        cell: "amount",
        headerClassName: "right_text",
      },
      {
        field: "exchangeRate",
        title: "Árfolyam",
      },
      {
        field: "exchangeRateDate",
        title: "Árfolyam dátuma",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        field: "status",
        title: "Állapot",
      },
      {
        field: "accStatus",
        title: "Könyvelési állapot",
      },
    ],

    invoiceItemColumns: () => [
      {
        field: "prefix",
        title: "Prefix",
      },
      {
        field: "invoice",
        title: "Számla azonosító",
      },
      {
        field: "currAmount",
        title: "Fiz. összeg",
        cell: "currAmount",
        headerClassName: "right_text",
      },
      { width: 60, cell: "itemsTemplate", filterable: false },
    ],

    documentColumns: () => [
    { field: "bankStatementItem.id", title: "Azonosító" },
      { field: "partner", title: "Partner" },
      {
        title: "Fiz. összeg (dev)",
        cell: "currAmountTemplate",
        headerClassName: "right_text",
      },
      {
        title: "Összeg",
        cell: "amountTemplate",
        headerClassName: "right_text",
      },
      { field: "status", title: "Állapot" },
      {
        field: "accountType",
        title: "Tétel típus",
      },
      {
        field: "accountSubtype",
        title: "Tétel altípus",
      },
      {
        field: "user",
        title: "Rögzítő",
      },
      {
        field: "insDate",
        title: "Rögz. dát.",
        type: "date",
        format: "{0: yyyy. MM. dd.}",
        className: "center_text",
        headerClassName: "center_text",
      },
      {
        field: "exchangeRate",
        title: "Árfolyam",
        className: "right_text",
        headerClassName: "right_text",
        cell: 'exchangeRate'
      },
      { width: 100, cell: "itemsTemplate", filterable: false },
      { width: 60, cell: "editTemplate", filterable: false },
    ],
  },
});
