import dayjs from "dayjs";
import busyIndication from "@/utils/busyIndication";
import GeneralIngoinInvoiceDisplay from "../models/GeneralIngoinInvoiceDisplay";
import GeneralIngoinInvoice, { generalIngoingInvoiceDefaultProps } from "../models/GeneralIngoinInvoice";
import PartnerTax from "../models/PartnerTax";
import Tax from "../models/Tax";
import SelectableParts from "../models/IngoingInvoiceSelectableItem";
import InvoiceDetails, { invoiceDetailsDefaultProps } from "../models/InvoiceDetails";
import IncomingInvoiceSubtype from "../models/IncomingInvoiceSubtype";
import PaymentMode, { paymentModeDisplayDefaultProps } from "../../paymentMode/models/PaymentModeDisplay";
import Country from "../../cashPoint/models/Country";
import PartBank from "../../cashPoint/models/PartBank";
import GeneralIngoingInvoiceItemDisplay from "../models/GeneralIngoingInvoiceItemDisplay";
import ProjectHeadDisplay from "../models/ProjectHeadDisplay";
import LogicalStorage from "../models/LogicalStorage";
import ProjectData, { projectDataDefaultProps } from "../models/ProjectData";
import GeneralIngoingInvoiceApi from "./GeneralIngoingInvoiceApi";
import Attachment, { attachmentDefaultProps } from "../models/Attachment";
import GeneralIngoingInvoiceItem, { generalIngoingInvoiceItemDefaultProps } from "../models/GeneralIngoingInvoiceItem";
import ProjectItem, { projectItemDefaultProps } from "../models/ProjectItem";
import GeneralIngoingInvoiceItemMinEditModel, {
  generalIngoingInvoiceItemMinEditModelDefaultProps,
} from "../models/GeneralIngoingInvoiceItemMinEditModel";
import { Unit } from "@/modules/invoiceItems/models";
import Vue from "vue";
import NotiDownPayEditModel, { notiDownPayEditModelDefaultProps } from "../models/NotiDownPayEditModel";
import { ActionMeta } from "@/models/ActionMeta";
import { FilteringModel, SortingModel, SortOrder, SortDefinition } from "../../../services/filtering";
import { deepCopy } from "@/utils/objects";
import configuration from "@/models/Configuration";
import { paymentModeDefaultProps } from "@/modules/paymentMode/models/PaymentMode";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import AccStatus, { accStatusDefaultProps } from "../models/AccStatus";
import WorkflowStatus from "../models/WorkflowStatus";
import GeneralIngoingInvoiceType from "../models/GeneralIngoingInvoiceType";
import DisplayStatus from "../models/DisplayStatus";
import User from "@/modules/user/models/User";

interface FilterObject {
  text: string;
  value: number;
}

export class GeneralIngoingInvoiceStore {
  invoices: GeneralIngoinInvoiceDisplay[] = [];
  invoice: GeneralIngoinInvoice = { ...generalIngoingInvoiceDefaultProps };
  invoiceItems: GeneralIngoingInvoiceItemDisplay[] = [];
  availableActions: ActionMeta[] = [];

  invoiceItemsByInvId: GeneralIngoingInvoiceItem[] = [];
  invoiceItemByInvId: GeneralIngoingInvoiceItem = {
    ...generalIngoingInvoiceItemDefaultProps,
  };
  invoiceItemMinEditModelsByInvId: GeneralIngoingInvoiceItemMinEditModel[] = [];
  invoiceItemMinEditModelByInvId: GeneralIngoingInvoiceItemMinEditModel = {
    ...generalIngoingInvoiceItemMinEditModelDefaultProps,
  };
  allUnits: Unit[] = [];

  subTypes: IncomingInvoiceSubtype[] = [];
  partnerTaxes: PartnerTax[] = [];
  taxes: Tax[] = [];
  accountStatuses: AccStatus[] = [];
  workflowStatuses: WorkflowStatus[] = [];
  displayStatuses: DisplayStatus[] = [];
  paymentModes: PaymentMode[] = [];
  operelPayMode: PaymentMode = { ...paymentModeDisplayDefaultProps };
  currencies: Country[] = [];
  partBanks: PartBank[] = [];
  partBanksIsAlive = true;
  invoiceDetails: InvoiceDetails = { ...invoiceDetailsDefaultProps };
  itemName = "";
  items: SelectableParts[] = [];
  projectHeads: ProjectHeadDisplay[] = [];
  projectItem: ProjectItem = { ...projectItemDefaultProps };
  localStorages: LogicalStorage[] = [];
  invDateStart = "";
  invDateEnd = "";
  accDateStart = "";
  accDateEnd = "";
  insDateStart = "";
  insDateEnd = "";
  invPartner = "";
  invStatus = "";
  projectData: ProjectData = { ...projectDataDefaultProps };
  attachments: Attachment[] = [];
  attachment: Attachment = { ...attachmentDefaultProps };
  totalPageCount = 0;
  invTypes: FilterObject[] = [];
  selectedInvTypeFilter = 1;

  notiDownPayEditModels: NotiDownPayEditModel[] = [];
  notiDownPayEditModel: NotiDownPayEditModel = {
    ...notiDownPayEditModelDefaultProps,
  };

  fullfilmentTolerances: GeneralIngoinInvoiceDisplay[] = [];

  //ez a bejövő számlák extrafiltere
  filterModel = new FilteringModel()
    .value("supplier")
    .multiSelect("invType", this.invTypes)
    .multiSelect("status", [
      { text: "Beérkezett", value: "Registered" },
      { text: "Elfogadva", value: "Approved" },
      { text: "Részben teljesítve", value: "PartiallyPayed" },
      { text: "Fizetve", value: "Payed" },
    ])
    .range("invDate")
    .range("regDate")
    .range("shipDate")
    .range("payTerm")
    .range("payDate")
    .value("fulfillAcceptor")
    .value("acceptor")
    .value("fulfillControlAcceptor")
    .value("controlAcceptor")
    .toObject();

  //ez a bejövő számlák sortmodellje
  sortModel = new SortingModel()
    .field("ID", "Azonosító", SortOrder.Descending)
    .field("InvoiceNo", "Számlaszám")
    .field("InvoiceDate", "Számla dátuma")
    .field("PayTerm", "Fizetési határidő")
    .field("RegDate", "Iktatva")
    .field("ShipDate", "Teljesítve")
    .field("PayDate", "Fizetve");

  //ez a számla böngésző extrafiltere
  eFilters = new FilteringModel()
    .value("supplier")
    .multiSelect("invType", this.invTypes)
    .value("invDateFrom")
    .value("invDateTo")
    .value("payTermFrom")
    .value("payTermTo")
    .value("regDateFrom")
    .value("regDateTo")
    .value("shipDateFrom")
    .value("shipDateTo")
    .value("payDateFrom")
    .value("payDateTo")
    .value("itemName")
    .value("itemPartsCode")
    .value("headBruttoFrom")
    .value("headBruttoTo")
    .value("itemBruttoFrom")
    .value("itemBruttoTo");

  //ez a számla böngésző sortmodellje
  eSortModel = new SortingModel()
    .field("Id", "Azonosító")
    .field("InvoiceNo", "Számlaszám")
    .field("InvoiceDate", "Számla dátuma")
    .field("PayTerm", "Fizetési határidő")
    .field("RegDate", "Iktatva")
    .field("ShipDate", "Teljesítve");

  fulfillAcceptors: User[] = [];
  acceptors: User[] = [];
  fulfillControlAcceptors: User[] = [];
  controlAcceptors: User[] = [];

  constructor() {
    this.invDateStart = "";
  }

  @busyIndication()
  async loadInvoices(pageLimit: number, pageOffset: number, filterText: string, gridFilter: any) {
    const invoicesWithPage = await GeneralIngoingInvoiceApi.searchForAll(
      pageLimit,
      pageOffset,
      filterText,
      this.filterModel,
      this.sortModel.toObject(),
      gridFilter
    );

    this.invoices = invoicesWithPage.currentPage.map((x) => ({
      ...x,
      isItemsVisible: false,
      expanded: false,
      isVatItemsVisible: false,
      isDutyItemsVisible: false,
    }));
    this.totalPageCount = invoicesWithPage.totalCount;
  }

  @busyIndication()
  async filterInvoices(filter: any, sort: any) {
    const invoicesWithPage = await GeneralIngoingInvoiceApi.filterInvoices(filter, sort);
    this.invoices = invoicesWithPage.currentPage.map((x) => ({
      ...x,
      isItemsVisible: false,
      expanded: false,
      isVatItemsVisible: false,
      isDutyItemsVisible: false,
    }));
    this.totalPageCount = invoicesWithPage.totalCount;
  }

  async loadProjectData(invoiceId: number) {
    this.projectData = await GeneralIngoingInvoiceApi.getProjectData(invoiceId);
  }

  async loadProjectItem(headId: number) {
    this.projectItem = await GeneralIngoingInvoiceApi.getProjectByHeadId(headId);
  }

  async loadGeneralIngoingInvoiceItemsByInvId(invoiceId: number) {
    this.invoiceItemsByInvId = (await GeneralIngoingInvoiceApi.getInvoiceItemsByInvId(invoiceId)).map((x) => ({
      ...x,
      editing: false,
      selectedField: false,
      expanded: false,
    }));
  }

   resetGeneralIngoingInvoiceItemMinEditModelsByInvId(){
    this.invoiceItemMinEditModelsByInvId = [];
   }

  //@busyIndication()
  async loadGeneralIngoingInvoiceItemMinEditModelsByInvId(invoiceId: number, isCurr: boolean) {
    this.invoiceItemMinEditModelsByInvId = (
      await GeneralIngoingInvoiceApi.getInvoiceItemMinEditModelsByInvId(invoiceId, isCurr)
    ).map((x) => ({
      ...x,
      editing: false,
      selectedField: false,
      expanded: true,
      dim5edit: false,
      savingComment: false,
    }));
  }

  @busyIndication()
  async loadInvoice(invoiceId: number) {
    if (invoiceId > 1) {
      const result = await GeneralIngoingInvoiceApi.get(invoiceId);
      this.invoice = result.invoice;
      this.invoiceItems = result.items.map((x) => ({
        ...x,
        expanded: false,
      }));
      this.attachments = result.attachments;

      this.attachments.forEach((element) => {
        element.link = GeneralIngoingInvoiceApi.generateFileDownLoadLink(element.id);
      });

      this.invoice.payTerm = this.trimDateTime(this.invoice.payTerm) ?? "";
      this.invoice.invoiceDate = this.trimDateTime(this.invoice.invoiceDate) ?? "";
      this.invoice.shipDate = this.trimDateTime(this.invoice.shipDate);
      this.invoice.regDate = this.trimDateTime(this.invoice.regDate) ?? "";
      this.invoice.arrivDate = this.trimDateTime(this.invoice.arrivDate) ?? "";
      this.invoice.accDate = this.trimDateTime(this.invoice.accDate);
      this.invoice.rateDate = this.formatDate(new Date());
      this.invoice.discPterm = this.trimDateTime(this.invoice.discPterm);
      this.invoice.payDate = this.trimDateTime(this.invoice.payDate);

      this.availableActions = result.availableActions;
    } else {
      this.invoice = { ...generalIngoingInvoiceDefaultProps };
      this.invoiceItems = [];
      this.attachments = [];
    }
  }

  async loadGeneralIngoingInvoiceItemByInvId(invId: number) {
    this.invoiceItemsByInvId = await GeneralIngoingInvoiceApi.getInvoiceItemsByInvId(invId);
  }

  async createGeneralIngoingInvoiceItem() {
    try {
      const created = await GeneralIngoingInvoiceApi.createItem(this.invoiceItemByInvId);
      this.invoiceItemsByInvId = [...this.invoiceItemsByInvId, created];
      this.invoiceItemByInvId = { ...generalIngoingInvoiceItemDefaultProps };
    } catch (error) {
      console.error(error);
    }
  }

  async createInvoiceItemMinEditModel() {
    try {
      const created = await GeneralIngoingInvoiceApi.createInvoiceItemMinEditModel(this.invoiceItemMinEditModelByInvId);
      if (created) {
        this.invoiceItemMinEditModelsByInvId.push(this.invoiceItemMinEditModelByInvId);
        this.invoiceItemMinEditModelsByInvId = [...this.invoiceItemMinEditModelsByInvId];
      }
      this.invoiceItemMinEditModelByInvId = {
        ...generalIngoingInvoiceItemMinEditModelDefaultProps,
      };
    } catch (error) {
      console.error(error);
    }
  }

  async loadRate() {
    if (this.invoice.invCurrency.toUpperCase() == "HUF") {
      this.invoice.exchangeRate = 1;
      this.invoice.rateDate = this.formatDate(new Date());
      this.invoice.netto = this.invoice.currNetto * this.invoice.exchangeRate;
    } else {
      let rateDate = new Date().toJSON();

      if (
        (this.invoice.specTax && this.invoice.specTaxType == 3) ||
        this.invoice.shipDateC ||
        new Date(this.invoice.shipDate ?? "") > new Date(this.invoice.invoiceDate)
      ) {
        rateDate = this.invoice.invoiceDate;
      } else {
        rateDate = this.invoice.shipDate ?? new Date().toJSON();
      }

      if (rateDate) {
        // if (configuration.exchangeRate.enabled) {
        //     const rate = await GeneralIngoingInvoiceApi.getPreviousRate(this.invoice.invCurrency);
        //     this.invoice.exchangeRate = rate.centralRate;
        //     this.invoice.rateDate = rate.rateDate.toString();
        // }
        // else {
        const newRate = await GeneralIngoingInvoiceApi.getRate(this.invoice.invCurrency, rateDate);
        this.invoice.exchangeRate = newRate;
        this.invoice.rateDate = rateDate;
        // }
        this.invoice.netto = this.invoice.currNetto * this.invoice.exchangeRate;
      }
    }

    if (!this.invoice.rateDate) {
      this.invoice.rateDate = new Date().toJSON();
    }
  }

  async loadPartnerTaxes() {
    this.partnerTaxes = await GeneralIngoingInvoiceApi.getPartnerTaxes();
    this.partnerTaxes.push({ id: 1, name: "-" });
  }

  async loadSubTypes() {
    this.subTypes = await GeneralIngoingInvoiceApi.getIncomingSubtypes();
  }

  async loadPaymentModes() {
    this.paymentModes = await GeneralIngoingInvoiceApi.getPaymentModes();
  }

  async loadOperelPaymodeForPartner(partnerID: number) {
    this.operelPayMode = await GeneralIngoingInvoiceApi.loadOperelPaymodeForPartner(partnerID);
  }

  async loadCurrencies() {
    this.currencies = await GeneralIngoingInvoiceApi.getCurrencies();
  }

  async loadTaxes(partnerId: number) {
    this.taxes = await GeneralIngoingInvoiceApi.getTaxes(partnerId);
  }

  async loadAllTaxes() {
    this.taxes = await GeneralIngoingInvoiceApi.getAllTaxes();
  }

  async loadAllUnits() {
    this.allUnits = await GeneralIngoingInvoiceApi.getAllUnits();
  }

  async loadTypes() {
    const types = await GeneralIngoingInvoiceApi.getTypes();
    types.forEach((type) => {
      const typeFilterObject: FilterObject = { text: type.name, value: type.id };
      this.invTypes.push(typeFilterObject);
    });
  }

  async loadPartBanks() {
    this.partBanks = (await GeneralIngoingInvoiceApi.getPartBanks(this.partBanksIsAlive, this.invoice.partner)).map(
      (x) => ({
        ...x,
        accountNoSplit: `${x.accountNo.substring(0, 8)}-${x.accountNo.substring(9, 17)}-${x.accountNo.substring(
          18,
          26
        )}`,
      })
    );
  }

  async loadItems() {
    if (this.itemName) {
      this.items = await GeneralIngoingInvoiceApi.getItems(this.itemName);
    }
  }

  async loadProjectHeads(partnerId: number) {
    this.projectHeads = await GeneralIngoingInvoiceApi.getProjectHeads(partnerId);
  }

  async loadLogicalStorages() {
    this.localStorages = await GeneralIngoingInvoiceApi.getLogicalStorages();
  }

  async loadInvoiceDetails(partnerId: number) {
    this.invoiceDetails = await GeneralIngoingInvoiceApi.getInvoiceDetails(
      partnerId,
      dayjs(this.invoice.invoiceDate).format("YYYY-MM-DD")
    );
  }

  trimDateTime(input: string | null) {
    if (input) {
      return input.split("T")[0];
    }

    return input;
  }

  formatDate(date: Date) {
    if (date) {
      const mm = date.getMonth() + 1;
      const dd = date.getDate();

      return [date.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("-");
    }

    return "";
  }

  update(autoApprovalConfirmed = false) {
    this.invoice.currNetto = Number(this.invoice.currNetto);

    if (this.invoice.id > 1) {
      return GeneralIngoingInvoiceApi.update(this.invoice, [], autoApprovalConfirmed).then(() =>
        Promise.resolve(this.invoice.id)
      );
    } else {
      return GeneralIngoingInvoiceApi.create(this.invoice, [], autoApprovalConfirmed);
    }
  }

  async loadGENIPicture(invoiceId: number) {
    await GeneralIngoingInvoiceApi.getFile(invoiceId).then((result) => {
      if (result == null) {
        this.attachment = attachmentDefaultProps;
        return;
      }
      this.attachment = result;
      this.attachment.link = GeneralIngoingInvoiceApi.generateFileDownLoadLink(result.id);
    });
  }

  async uploadGENIPicture(id: number) {
    if (this.attachment) {
      this.attachments = [this.attachment];
      await this.uploadFiles(id);
    }
  }

  async uploadFiles(id: number) {
    if (this.attachments && this.attachments.length > 0) {
      const reads = this.attachments
        .filter((x) => !!x.file)
        .map(async (x) => {
          return { ...x, fileBase64: await this.toBase64(x) };
        });
      await Promise.all(reads).then(async (x) => {
        await GeneralIngoingInvoiceApi.uploadFiles(
          x.map((y) => {
            return {
              file: y.file,
              fileBase64: typeof y.fileBase64 === "string" ? y.fileBase64 : "",
              id: y.id,
              name: y.name,
              size: y.size ?? 0,
              link: y.link,
              note: y.note,
            };
          }),
          id
        );
      });
    }
  }

  async setIncorrect() {
    await GeneralIngoingInvoiceApi.setIncorrect(this.invoice);
  }

  @busyIndication()
  async reopen() {
    const success = await GeneralIngoingInvoiceApi.reopen(this.invoice.id, this.invoice);
    return success;
  }

  async copy() {
    const success = await GeneralIngoingInvoiceApi.copy(this.invoice.id);
    return success;
  }

  @busyIndication()
  async reopenOperel() {
    const success = await GeneralIngoingInvoiceApi.reopenOperel(this.invoice.id);
    return success;
  }

  async accept() {
    try {
      await GeneralIngoingInvoiceApi.accept(this.invoice, this.invoiceItems, this.projectData);
      await GeneralIngoingInvoiceApi.updateStockPrices(this.invoice.id);
    } catch (err) {
      console.error(err);
    }
  }

  toBase64(attachment: Attachment) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(attachment.file);
      reader.onload = () => {
        attachment.fileBase64 = reader.result?.toString() ?? "";
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  pickCurrentCurrency() {
    const huf = this.currencies.find((x) => x.currency.toUpperCase().trim() === "HUF");

    if (this.invoice.id > 1) {
      return (
        this.currencies.find(
          (x) => x.currency.toUpperCase().trim() === this.invoice.invCurrency.toUpperCase().trim()
        ) ??
        huf ??
        this.currencies[0]
      );
    }

    return (
      this.currencies.find((x) => x.currency.toUpperCase().trim() === this.invoiceDetails.partnerCurrency) ??
      huf ??
      this.currencies[0]
    );
  }

  pickCurrentPaymode() {
    if (this.invoice?.id == 0 && configuration.operel) {
      return this.paymentModes.find((x) => this.operelPayMode?.id === x.id) ?? this.paymentModes[0];
    }
    const invoicePaymodeId = this.invoice?.paymode;
    const partnerPaymodeId = this.invoiceDetails?.partnerPaymentMethodId;

    return (
      this.paymentModes.find((x) => invoicePaymodeId && invoicePaymodeId > 1 && invoicePaymodeId === x.id) ??
      this.paymentModes.find((x) => partnerPaymodeId && partnerPaymodeId > 1 && partnerPaymodeId === x.id) ??
      this.paymentModes[0]
    );
  }

  pickCurrentTax() {
    const itemTaxes = this.invoiceItems.map((i) => i.taxID);

    return (
      this.taxes.find((t) => t.id === this.invoice.tax || (itemTaxes.includes(t.id) && t.id > 1)) ??
      this.taxes.find((t) => t.id === this.invoiceDetails.defaultTax) ??
      this.taxes.find((t) => t.degree === 27) ??
      this.taxes[0]
    );
  }

  @busyIndication()
  async loadNotis(partnerId: number) {
    this.notiDownPayEditModels = (await GeneralIngoingInvoiceApi.getAllByPartnerId(partnerId)).map((x) => ({
      ...x,
      selected: false,
    }));
  }

  async createNoti(noti: NotiDownPayEditModel) {
    try {
      await GeneralIngoingInvoiceApi.createNoti(noti);
    } catch (error) {
      console.log(error);
    }
  }

  async deleteNoti(noti: NotiDownPayEditModel) {
    try {
      await GeneralIngoingInvoiceApi.deleteNoti(noti);
    } catch (error) {
      console.log(error);
    }
  }

  refreshNotis() {
    this.notiDownPayEditModels.forEach((e) => {
      e.selected = false;
    });
  }

  async loadFullfilmentTolerances() {
    this.fullfilmentTolerances = await GeneralIngoingInvoiceApi.loadFullfilmentTolerances();
  }

  clearFilter() {
    this.filterModel.supplier.clear();
    this.filterModel.status.clear();
    this.filterModel.invDate.clear();
    this.filterModel.regDate.clear();
    this.filterModel.shipDate.clear();
    this.filterModel.payTerm.clear();
    this.filterModel.payDate.clear();
  }

  clearSort() {
    for (let i = 0; i < this.sortModel.definitions.length; i++) {
      if (this.sortModel.definitions[i].field == "ID") {
        this.sortModel.definitions[i].order = SortOrder.Descending;
      } else {
        this.sortModel.definitions[i].order = SortOrder.None;
      }
    }
  }

  async loadAccStatuses() {
    this.accountStatuses = await GeneralIngoingInvoiceApi.loadAccStatuses();
  }

  async loadWFStatuses() {
    this.workflowStatuses = await GeneralIngoingInvoiceApi.loadWorkflowStatuses();
  }

  async loadDisplayStatuses() {
    this.displayStatuses = await GeneralIngoingInvoiceApi.loadDisplayStatuses();
    this.displayStatuses = this.displayStatuses.filter((ds) => [1, 2, 3, 8, 9, 11].includes(ds.id));
  }

  async loadAcceptorAndControlUsers() {
    const users = await GeneralIngoingInvoiceApi.getAcceptorAndControlUsers();
    this.fulfillAcceptors = users.filter((user) => user.isInvAccept);
    this.acceptors = users.filter((user) => user.isInvAccept);
    this.fulfillControlAcceptors = users.filter((user) => user.isInvContr);
    this.controlAcceptors = users.filter((user) => user.isInvContr);
  }

  resetExtraFilter(): void {
    (this.eFilters.filters.supplier as any).value = null;
    (this.eFilters.filters.invType as any).selectedOptions = [];
    (this.eFilters.filters.invDateFrom as any).value = null;
    (this.eFilters.filters.invDateTo as any).value = null;
    (this.eFilters.filters.payTermFrom as any).value = null;
    (this.eFilters.filters.payTermTo as any).value = null;
    (this.eFilters.filters.regDateFrom as any).value = null;
    (this.eFilters.filters.regDateTo as any).value = null;
    (this.eFilters.filters.shipDateFrom as any).value = null;
    (this.eFilters.filters.shipDateTo as any).value = null;
    (this.eFilters.filters.payDateFrom as any).value = null;
    (this.eFilters.filters.payDateTo as any).value = null;
    // .value("supplier")
    // .multiSelect("invType", this.invTypes)
    // .value("invDateFrom")
    // .value("invDateTo")
    // .value("payTermFrom")
    // .value("payTermTo")
    // .value("regDateFrom")
    // .value("regDateTo")
    // .value("shipDateFrom")
    // .value("shipDateTo")
    // .value("payDateFrom")
    // .value("payDateTo");
  }
}

const store = Vue.observable(new GeneralIngoingInvoiceStore());
export default store;
