import Partner from "@/modules/partner/models/Partner";

export default class CurrentAccountPreFilters {
  statusFilters: number[] = [];
  expirationFilter = 0;
  expirationDaysFilter = 0;
  currencyFilters: string[] = [];
  partnerTypeFilters: number[] | undefined = undefined;
  advanceRequestTypeFilter = 0;
  partnerFilters: number[] = [];

  ledgerPostingFilter = 0;
  //analitikák

  proofDateFrom: Date | undefined = undefined;
  proofDateTo: Date | undefined = undefined;

  proofPaymentDeadlineFrom: Date | undefined = undefined;
  proofPaymentDeadlineTo: Date | undefined = undefined;

  proofPostingDateFrom: Date | undefined = undefined;
  proofPostingDateTo: Date | undefined = undefined;

  paymentItemDateFrom: Date | undefined = undefined;
  paymentItemDateTo: Date | undefined = undefined;

  paymentItemInsertDateFrom: Date | undefined = undefined;
  paymentItemInsertDateTo: Date | undefined = undefined;

  paymentItemPostingDateFrom: Date | undefined = undefined;
  paymentItemPostingDateTo: Date | undefined = undefined;

  /** Csak arra kell hogyha kiválasztasz partnereket, akkor ne keljen újból lekérdezni */
  partners: Partner[] | undefined = undefined;
}
