


































































import Vue from "vue";
import cashStatementStore, {
  CashStatementStore,
} from "@/modules/cashStatement/services/store";
import cashPointStore, { CashPointStore } from "../services/CashPointStore";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { ApiSearchColumn } from "@/components/apiSearch/models/ApiSearch";
import IconButton from "@/components/IconButton.vue";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import eventBus from "@/services/eventBus";
import MoneyInput from "@/components/MoneyInput.vue"

export class BackPaymentTransaction {
  originalCashDoc = 1;
  selectedCashPoint = 1;
  selectedCashDocItem = 1;
  amount = 0;
}

interface Data {
  cashStatementStore: CashStatementStore;
  cashPointStore: CashPointStore;
  transaction: BackPaymentTransaction;
  cashPointType: number;
}

interface Computed {
  url: string;
  columns: ApiSearchColumn[];
  selectedCashPoint: number;
}

interface Methods{
  refreshApiSearch(): void;
  save(): void;
  cancel(): void;
  generateBackpayment(item: any): void;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  data: () => {
    return {
      cashStatementStore,
      cashPointStore,
      transaction: new BackPaymentTransaction(),
      cashPointType: 3,
    };
  },

  components: {
    ApiSearchPage,
    IconButton,
    MoneyInput
},

  computed: {
    url() {
      return `cashPoint/${this.cashPointType}/${this.transaction.selectedCashPoint}`;
    },

    columns: (): ApiSearchColumn[] => [
      { field: "name", title: "Pénztár" },
      { field: "employee", title: "Munkavállaló" },
      { field: "statement", title: "Kivonat" },
      { field: "document", title: "Bizonylat sorszám" },
      { field: "docItem", title: "Bizonylat tétel" },
      { field: "transactionAmount", title: "Bizonylat tétel összege", cell: 'transactionAmount', filterable: false, sortable: false },
      { field: "remark", title: "Megjegyzés"},
      { field: "amount", cell: "select", title: "", fieldClass: "text-center", filterable: false, sortable: false},
    ],

    selectedCashPoint(){
      return this.transaction.selectedCashPoint;
    }
  },

  methods: {
    refreshApiSearch() {
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },

    save() {
      console.log();
    },

    cancel() {
      this.$emit("cancel");
    },

    async generateBackpayment(item: any){
      this.transaction.selectedCashDocItem = item.docItem;
      this.transaction.amount = item.transactionAmount;

      const result = await http.post(`cashPoint/${cashStatementStore.cashDocument.id}/generate-backpayment`, this.transaction).then(checkResponse)
      if(result){
        eventBus.$emit("success", "Sikeres tétel generálás!")
        await this.cashStatementStore.loadCashDocItemsByDocumentId(this.cashStatementStore.cashDocument.id)
        this.refreshApiSearch();
      }
      else{
        eventBus.$emit("error","Hiba!")
      }
    }
  },

  async beforeMount(){
    if(cashPointStore.typedCashpoints.length<1){
        await cashPointStore.loadCashpointsByType(3)
    }
  },

  watch: {
    selectedCashPoint: function(){
      this.refreshApiSearch();
    }
  }
});
