

















































































































import Fragment from "@/components/Fragment.vue";
import IconButton from "@/components/IconButton.vue";
import StatusChip from "@/components/StatusChip.vue";
import CheckBox from "@/components/CheckBox.vue";

import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";
import { DownPayment, DownPaymentExtOrder, PreDownPayment } from "../models";
import eventBus from "@/services/eventBus";

interface Data {
  store: DownPaymentStore;
  searchText: string;
}

interface Methods {
  select(item: PreDownPayment): void;
  search(): void;
  clear(): void;
  orderFinder(order: string): boolean;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: { Fragment, IconButton, StatusChip, CheckBox },

  data() {
    return {
      store,
      searchText: "",
    };
  },

  async beforeMount() {
    if (!this.store.preDownPaymentList || this.store.preDownPaymentList.items.length === 0) {
      const partnerID = Number(this.$route.params.partnerId) || this.store.downPayment.partner || 0;
      await this.store.loadPreDownPayments(partnerID, "", this.store.downPayment.currency);
    }
  },

  methods: {
    select(item: PreDownPayment) {
      if (item.type != 2) {
        //előlegberkérő tétel
        if (this.orderFinder(item.orderName)) {
          if (!this.store.selectedPreDownPayments.find((x) => x.id == item.id)) {
            item.selected = true;
            this.store.selectedPreDownPayments.push(item);
            this.store.extOrderList.push(item.order as DownPaymentExtOrder);

            this.store.extOrderList = [...this.store.extOrderList];
            this.store.downPayment.netto += item.amount;
          } else {
            item.selected = false;
            this.store.selectedPreDownPayments = this.store.selectedPreDownPayments.filter((x) => x.id != item.id);
            this.store.extOrderList = this.store.extOrderList.filter((x) => x.id != item.order?.id);

            this.store.extOrderList = [...this.store.extOrderList];
            this.store.downPayment.netto -= item.amount;
          }
        } else {
          eventBus.$emit("warn", "Eltérő rendelésszám.");
        }
      } else {
        //előlegbekérő megrendelés
        if (this.store.selectedPreDownPayments.length > 0) {
          if (this.store.selectedPreDownPayments[0].id == item.id) {
            item.selected = false;
            this.store.selectedPreDownPayments = this.store.selectedPreDownPayments.filter((x) => x.id != item.id);
            this.store.extOrderList = this.store.extOrderList.filter((x) => x.id != item.order?.id);

            this.store.extOrderList = [...this.store.extOrderList];
            this.store.downPayment.netto -= item.amount;
            this.store.downPayment.currency = item.currency;
          } else {
            eventBus.$emit("warn", "Az előlegbekérőhöz csak egy megrendelés tartozhat.");
          }
        } else {
          item.selected = true;
          this.store.selectedPreDownPayments.push(item);
          this.store.extOrderList.push(item.order as DownPaymentExtOrder);

          this.store.extOrderList = [...this.store.extOrderList];
          this.store.downPayment.netto += item.amount;
          this.store.downPayment.currency=item.currency;
        }
      }
      this.store.nettoChanged();
    },

    orderFinder(order: string) {
      if (this.store.selectedPreDownPayments.length > 0) {
        return this.store.selectedPreDownPayments.every((x) => x.orderName == order);
      }
      return true;
    },

    async search() {
      const partnerParam = Number(this.$route.params.partnerId);
      const partnerID = partnerParam > 1 ? partnerParam : this.store.downPayment.partner;
      await this.store.loadPreDownPayments(partnerID, this.searchText, this.store.downPayment.currency);
    },

    clear() {
      this.store.preDownPaymentList.items = [];
    },
  },

  computed: {
    hasData() {
      return this.store.preDownPaymentList.items && this.store.preDownPaymentList.items.length > 0;
    },
  },
});
