






















import ApiSearch from "@/components/apiSearch/components/ApiSearch.vue";
import StatusChip from "@/components/StatusChip.vue";

import Vue from "vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import { FilteringModel } from "@/services/filtering";
import StatusColorMap from "@/models/StatusColorMap";
import FixAssetApiSearchDTO from "../../models/FixAssetApiSearchDTO";

export default Vue.extend({
  components: { ApiSearch, StatusChip },

  data: () => ({
    statusColoring: new StatusColorMap({
      default: "grey",
      3: "teal",
      4: "green",
    }),
  }),

  methods: {
    itemSelected(fixAsset: FixAssetApiSearchDTO) {
      this.$emit("itemSelected", fixAsset);
    },
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      { field: "id", title: "Azonosító" },
      { field: "name", title: "Megnevezés" },
      { field: "supplier", title: "Szállító" },
      {
        field: "netto",
        title: "Beszerzési nettó érték",
        type: ColumnType.Money,
      },
      { field: "currency", title: "Pénznem" },
      { field: "description", title: "Leírás" },
      { field: "type", title: "Típus" },
      { field: "inventoryId", title: "Leltári szám" },
      { field: "factNumber", title: "Készletazonosító" },
      {
        field: "status",
        title: "Státusz",
        type: ColumnType.Center,
        cell: "status",
        multiselectField: "selectedStatuses",
        multiselectOptions: [
          { value: 2, text: "Feldolgozás alatt" },
          { value: 3, text: "Beruházva" },
          { value: 4, text: "Aktiválva" },
        ],
        multiselect: true,
      },
    ],
  },
});
