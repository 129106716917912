import http, { handleApiResponse, checkResponse } from '@/services/http'
import BankStatementEntity, { BankStatementDTO, BankStatementResponse } from "../models/BankStatement"
import ApiResponse from '@/models/ApiResponse'
import BankDocument, { BankStatementItemEntity } from '../models/BankDocument'
import BankStatus from '@/modules/bankStatement/models/BankStatus'
import BankAccountStatus from '../models/BankAccountStatus'
import AccountSubtype from '../../accountType/models/AccountSubtype'
import PartBank from '@/modules/cashPoint/models/PartBank'
import BankStatementPict from '../models/BankStatementPict'
import BankOutView from '@/modules/bankOutView/models/BankOutView'
import BankInView from '@/modules/bankInView/models/BankInView'
import BankRemitItem from '@/modules/bankRemit/models/BankRemitItem'
import { CurrencyRate } from '../models/CurrencyRate'
import BankOutOverViewDTO from '@/modules/bankExtract/models/BankOutOverViewDTO'
import BankInOverViewDTO from '@/modules/bankExtract/models/BankInOverViewDTO'
import busyIndication from '@/utils/busyIndication'
import AccountingError from '../models/AccountingError'
import BankStatementSummary from '../models/BankStatementSummary'

export default class BankStatementApi {
    static getAll(pageNumber: number, pageSize: number): Promise<BankStatementResponse> {
        return http
            .get<ApiResponse<BankStatementResponse>>(`bank-statement/${pageNumber}/${pageSize}`)
            .then(handleApiResponse)
    }

    static getAllWithFilter(filterId: number, pageNumber: number, pageSize: number): Promise<BankStatementResponse> {
        return http
            .get<ApiResponse<BankStatementResponse>>(`bank-statement/${filterId}/${pageNumber}/${pageSize}`)
            .then(handleApiResponse)
    }

    static closeBankStatement(bankStatementId: number, updateBalance: boolean){
        return http.post(`bank-statement/close`,
        {
            BankStatementID: bankStatementId,
            UserID: 1,
            UpdateBalance: updateBalance,
        })
        .then(checkResponse);
    }

    @busyIndication()
    static paidBankDocument(bankDocItem: number){
        return http.post<ApiResponse<string[]>>(`bank-statement/paid/${bankDocItem}`)
        .then(handleApiResponse);
    }

    @busyIndication()
    static paidAllBankDocument(bankStatementID: number){
        return http.post<ApiResponse<string[]>>(`bank-statement/paid-all/${bankStatementID}`)
        .then(handleApiResponse);
    }

    static loadAccountingErrors(bankStatement: number){
        return http.post<ApiResponse<AccountingError[]>>(`bank-statement/${bankStatement}/account-err`)
        .then(handleApiResponse);
    }

    static deleteInvoicePayment(bankDocItem: number, prefix: string, invoice: number){
        return http.post(`bank-statement/delete`, {
            BankDocItem: bankDocItem,
            Code: prefix,
            Invoice: invoice
        })
        .then(checkResponse);
    }

    static wrongBankDocument(bankDocItem: number){
        return http.post(`bank-statement/wrong/${bankDocItem}`)
        .then(checkResponse);
    }

    static generateBankOutOverPayments(bankStatementId: number,bankDocId: number, items: BankOutOverViewDTO[]){
        return http.post(`bankout/generatepayments/over/${bankStatementId}/${bankDocId}`,items)
        .then(checkResponse);
      }

      static generateBankInOverPayments(bankStatementId: number,bankDocId: number, items: BankInOverViewDTO[]){
        return http.post(`bankin/generatepayments/over/${bankStatementId}/${bankDocId}`,items)
        .then(checkResponse);
      }

    static generateBankOutPayments(bankStatementId: number,bankDocId: number, items: BankOutView[], isOverrideAmount: boolean){
        return http.post(`bankout/generatepayments/${bankStatementId}/${bankDocId}/${isOverrideAmount}`,items)
        .then(checkResponse);
      }
      static generateBankInPayments(bankStatementId: number,bankDocId: number, items: BankInView[], isOverrideAmount: boolean){
        return http.post(`bankin/generatepayments/${bankStatementId}/${bankDocId}/${isOverrideAmount}`,items)
        .then(checkResponse);
      }

    static getAllByName(bankStatementName: string, filterId: number, pageNumber: number, pageSize: number): Promise<BankStatementResponse> {
        return http
            .get<ApiResponse<BankStatementResponse>>(`bank-statement/${bankStatementName}/${filterId}/${pageNumber}/${pageSize}`)
            .then(handleApiResponse)
    }

    static getAllByNameWithoutFilter(bankStatementName: string, pageNumber: number, pageSize: number): Promise<BankStatementResponse> {
        return http
            .get<ApiResponse<BankStatementResponse>>(`bank-statement/name/${bankStatementName}/${pageNumber}/${pageSize}`)
            .then(handleApiResponse)
    }

    static get(bankStatementId: number): Promise<BankStatementEntity> {
        return http
            .get<ApiResponse<BankStatementEntity>>(`bank-statement/${bankStatementId}`)
            .then(handleApiResponse)
    }

    static getSummary(bankStatementId: number): Promise<BankStatementSummary> {
        return http
            .get<ApiResponse<BankStatementSummary>>(`bank-statement/summary/${bankStatementId}`)
            .then(handleApiResponse)
    }

    @busyIndication()
    static getDocumentsByStatementId(bankStatementId: number): Promise<BankDocument[]> {
        return http
            .get<ApiResponse<BankDocument[]>>(`bank-statement/doc/${bankStatementId}`)
            .then(handleApiResponse);
    }

    static getBankStatuses(): Promise<BankStatus[]> {
        return http.get<ApiResponse<BankStatus[]>>(`bank-status`).then(handleApiResponse);
    }

    static getPartBanks(): Promise<PartBank[]> {
        return http.get<ApiResponse<PartBank[]>>(`partBank`).then(handleApiResponse);
      }

      static getOwnPartBanks(partBank: number): Promise<PartBank[]> {
        return http.get<ApiResponse<PartBank[]>>(`partBank/own/${partBank}`).then(handleApiResponse);
      }

    static getBankStatementItemById(bankStatementId: number, bankStatementItemId: number): Promise<BankDocument> {
        return http
            .get<ApiResponse<BankDocument>>(`bank-statement/edit/${bankStatementId}/${bankStatementItemId}`)
            .then(handleApiResponse)
    }

    static getLastStatementRate(bankid: number, bankStatementId: number, date: Date): Promise<number> {
        return http
            .post<ApiResponse<number>>(`bank-statement/last-rate`, {
                BankID: bankid,
                BankStatementID: bankStatementId,
                Date: date
            })
            .then(handleApiResponse)
    }

    static getLastBalance(bankid: number, bankStatementId: number, date: Date): Promise<number> {
        return http
            .post<ApiResponse<number>>(`bank-statement/last-balance`, {
                BankID: bankid,
                BankStatementID: bankStatementId,
                Date: date
            })
            .then(handleApiResponse)
    }

    static getCreditingRate(currency: string, date: Date): Promise<CurrencyRate> {
        return http
            .post<ApiResponse<CurrencyRate>>(`bank-statement/current-rate`, {
                Currency: currency,
                Date: date
            })
            .then(handleApiResponse)
    }

    static getAverageRate(bankstatementID: number): Promise<CurrencyRate> {
        return http
            .post<ApiResponse<CurrencyRate>>(`bank-statement/average-rate`, {
                BankStatementID: bankstatementID,
            })
            .then(handleApiResponse)
    }

    static getBankStatementDTO(bankStatementId: number): Promise<BankStatementDTO> {
        return http
            .get<ApiResponse<BankStatementDTO>>(`bank-statement/info/${bankStatementId}`)
            .then(handleApiResponse)
    }

    static getBankAccountStatuses(): Promise<BankAccountStatus[]> {
        return http
            .get<ApiResponse<BankAccountStatus[]>>(`bank-account-status`)
            .then(handleApiResponse)
    }

    static getAccountSubtypes(): Promise<AccountSubtype[]> {
        return http
            .get<ApiResponse<AccountSubtype[]>>(`account-subtype`)
            .then(handleApiResponse)
    }

    static update(bankStatement: BankStatementEntity): Promise<boolean> {
        return http
            .put(`bank-statement/${bankStatement.id}`, { bankStatement })
            .then(checkResponse)
    }

    static create(bankStatement: BankStatementEntity): Promise<number> {
        return http
            .post<ApiResponse<number>>(`bank-statement/create`, { bankStatement })
            .then(handleApiResponse)
    }

    static createPaidBankStatementItems(statement: number, items: BankRemitItem[]) {
        return http
            .post<ApiResponse<boolean>>(`bank-statement/${statement}/create-items`, items)
            .then(handleApiResponse)
    }

    static updateBankStatementItem(bankStatementItem: BankStatementItemEntity, isOver: boolean): Promise<boolean> {
        return http
            .put(`bank-statement/item/${bankStatementItem.id}`, { bankStatementItem: bankStatementItem, isOver: isOver })
            .then(checkResponse)
    }

    static createBankStatementItem(bankStatementItem: BankStatementItemEntity, isOver: boolean): Promise<any> {
        return http
            .post(`bank-statement`, { bankStatementItem: bankStatementItem, isOver: isOver })
            .then(handleApiResponse)
    }

    static getBankStatementPictByStatementId(bankStatementId: number): Promise<BankStatementPict[]>{
        return http
        .get<ApiResponse<BankStatementPict[]>>(`bank-statement/pict/${bankStatementId}`)
        .then(handleApiResponse)
    }

    static generateDocumentDownLoadLink(documentId: number) {
        return http.defaults.baseURL + `document/download/${documentId}`;
    }

    static deletePreviousDocument(documentId: number): Promise<boolean> {
        return http.delete(`documents/delete/${documentId}`).then(checkResponse);
    }

    static uploadBankStatementPict(
        itemId: number,
        doctype: number,
        description: string,
        file: File
      ): Promise<Document> {
        const data = new FormData();
        data.append("file", file);
        data.append("typeID", doctype.toString());
        data.append("relatedItemName", "hmaccbankaccount.");
        data.append("relatedItemID", itemId.toString());
        data.append("description", description);
    
        return http
          .post<ApiResponse<Document>>(`documents`, data, { headers: { "Content-Type": "multipart/form-data" } })
          .then(handleApiResponse);
      }
}