import Vue from "vue";
import StatusColorMap from "@/models/StatusColorMap";
import AccountType from "@/modules/accountType/models/AccountType";
import AccountSubtype from "@/modules/accountType/models/AccountSubtype";
import BankExtractImport from "../models/BankExtractImport";
import BankExtractImportApi from "./BankExtractImportApi";
import AccountTypeApi from "@/modules/accountType/services/AccountTypeApi";

export class BankExtractImportStore {
  bankExtractImport = new BankExtractImport();
  statusColors = new StatusColorMap({
    default: "grey",
    2: "orange darken-1",
    3: "green",
    4: "blue-grey",
    5: "red darken-1",
    6: "blue lighten-2",
  });
  itemStatusColors = new StatusColorMap({
    default: "grey",
    2: "green",
    3: "blue-grey",
    4: "red darken-1",
    5: "teal",
    9: "red darken-1",
  });
  itemTypes: AccountType[] = [];
  itemSubtypes: AccountSubtype[] = [];

  reset() {
    this.bankExtractImport = new BankExtractImport();
  }

  async load(headID: number) {
    this.bankExtractImport = await BankExtractImportApi.get(headID);
  }

  async update() {
    await BankExtractImportApi.update(this.bankExtractImport);
  }

  async setDeleted() {
    await BankExtractImportApi.delete(this.bankExtractImport.id);
  }

  async prepareCheck() {
    return await BankExtractImportApi.prepareCheck(this.bankExtractImport);
  }

  async prepareToImport() {
    await BankExtractImportApi.prepareToImport(this.bankExtractImport);
  }

  async loadItemTypes() {
    await AccountTypeApi.getAll().then((result) => {
      this.itemTypes = result.find((x) => x.id === 1) ? result : [new AccountType({ id: 1, name: "-" }), ...result];
    });
  }

  async loadItemSubtypes() {
    await AccountTypeApi.getSubtypes().then((result) => {
      this.itemSubtypes = result.find((x) => x.id === 1)
        ? result
        : [new AccountSubtype({ id: 1, name: "-" }), ...result];
    });
  }
}

const store = Vue.observable(new BankExtractImportStore());
export default store;
