export default class FixAssetInvItemRelation {
  id = 0;
  invItemID = 1;
  fixAssetID = 1;
  fixAssetName = "";
  fixAssetSupplier = "";
  fixAssetNetto = 0;
  fixAssetCurrency = "";
  amount = 0;

  constructor(params?: Partial<FixAssetInvItemRelation>) {
    const data = { ...params };
    Object.assign(this, data);
  }
}
