var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-3 mt-1 ml-1",staticStyle:{"font-size":"16px !important","color":"#394263"}},[_vm._v("Tételek")]),_c('grid',{attrs:{"data-items":_vm.localItems,"columns":_vm.columns},scopedSlots:_vm._u([{key:"unitPriceTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.unitPrice,"currency":_vm.$props.head.currency,"decimals":2}})],1)]}},{key:"amountTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.amount,"currency":props.dataItem.unitName,"decimals":2}})],1)]}},{key:"totalTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":(props.dataItem.netto + props.dataItem.vat),"currency":_vm.$props.head.currency,"decimals":2}})],1)]}},{key:"nettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.netto,"currency":_vm.$props.head.currency,"decimals":2}})],1)]}},{key:"vatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('MoneyDisplay',{attrs:{"value":props.dataItem.vat,"currency":_vm.$props.head.currency,"decimals":2}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }