var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"mt-2",staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v(" Szolgáltatások keresése ")]),_c('br'),_c('div',{staticClass:"d-flex flex-row"},[_c('v-text-field',{attrs:{"outlined":"","single-line":"","placeholder":"Szolgáltásá neve, kódja..."},on:{"keyup":_vm.search},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('icon-button',{attrs:{"tooltip":"Találatok törlése","icon":"fa-times"},on:{"click":_vm.clear}})],1),_c('grid',{attrs:{"data-items":_vm.newCarItemRelations,"columns":_vm.newColumns},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('icon-button',{attrs:{"disabled":_vm.addDisabled,"tooltip":"Kijelöltek hozzáadása","icon":"fa-plus"},on:{"click":function($event){return _vm.addItems(props.dataItem)}}})],1)]}},{key:"nettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.netto,"currency":props.dataItem.currency}})],1)]}},{key:"vatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.tax,"currency":props.dataItem.currency}})],1)]}},{key:"totalTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.amount,"currency":props.dataItem.currency}})],1)]}}])}),_c('h5',{staticClass:"mt-2",staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v("Szolgáltatás kapcsolat")]),_c('grid',{attrs:{"data-items":_vm.carItemRelaions,"columns":_vm.columns},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.updateItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Mentés")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.deleteItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}},{key:"vatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-input',{staticClass:"mt-1",attrs:{"currency":props.dataItem.tax},model:{value:(props.dataItem.vat),callback:function ($$v) {_vm.$set(props.dataItem, "vat", _vm._n($$v))},expression:"props.dataItem.vat"}})],1)]}},{key:"totalTemplate",fn:function(){return [_c('td',[_c('money-display',{attrs:{"value":_vm.amount,"currency":_vm.currency}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }