import http, { handleApiResponse, checkResponse } from '@/services/http'
import PaymentMode from "@/modules/paymentMode/models/PaymentMode"
import PaymentModeDisplay from "@/modules/paymentMode/models/PaymentModeDisplay"
import WebPayMode from "@/modules/paymentMode/models/WebPayMode"
import ApiResponse from '@/models/ApiResponse'

export default class PaymentModeApi {
    static getAll(): Promise<PaymentModeDisplay[]> {
        return http
            .get<ApiResponse<PaymentModeDisplay[]>>(`payment-mode`)
            .then(handleApiResponse)
    }

    static get(paymentModeId: number): Promise<PaymentMode> {
        return http
            .get<ApiResponse<PaymentMode>>(`payment-mode/${paymentModeId}`)
            .then(handleApiResponse)
    }

    static getWebPayModes(): Promise<WebPayMode[]> {
        return http
            .get<ApiResponse<WebPayMode[]>>(`web-pay-mode`)
            .then(handleApiResponse)
    }

    static create(paymentMode: PaymentMode): Promise<PaymentMode> {
        return http
            .post<ApiResponse<PaymentMode>>(`payment-mode`, paymentMode)
            .then(handleApiResponse)
    }

    static update(paymentMode: PaymentMode): Promise<boolean> {
        return http
            .put(`payment-mode/${paymentMode.id}`, paymentMode)
            .then(checkResponse)
    }

    static delete(paymentModeId: number): Promise<boolean> {
        return http
            .delete(`payment-mode/${paymentModeId}`)
            .then(checkResponse)
    }
}