export default interface ProjectHead {
    id: number;
    name: string;
    mainCode: string;
    code: string;
    projectUser: number;
    partner: number;
    status: number;
    defaultStore: number;
}

export const projectHeadDefaultProps: ProjectHead = {
    id: 0,
    name: '',
    mainCode: '',
    code: '',
    projectUser: 1,
    partner: 1,
    status: 1,
    defaultStore: 1
}