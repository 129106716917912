



















































































































































































































import Vue from "vue";
import eventBus from "@/services/eventBus";
import { GridColumnProps, GridRowProps } from "@progress/kendo-vue-grid";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { PermitStatuses } from "@/modules/generalIngoingInvoice/models/PermitStatus";
import configuration, { AppConfiguration } from "@/models/Configuration";
import permStore, { PermissionStore } from "@/services/permissions";
import store, { InvoiceFlowTaskStore } from "../services/InvoiceFlowTaskStore";
import wizardStore, { InvoiceWizardStep, InvoiceWizardStore } from "../services/InvoiceTaskWizard";
import editStore, {
  GeneralIngoingInvoiceEditModelStore,
} from "@/modules/generalIngoingInvoiceEditModel/services/GeneralIngoingInvoiceEditModelStore";
import GeneralIngoingInvoiceEditModel from "@/modules/generalIngoingInvoiceEditModel/models/GeneralIngoingInvoiceEditModel";
import { FlowStatus } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import { formatMoney } from "@/filters/money-format";
import InvoiceStockModfierGenisView from "@/modules/incomingInvoice/invoiceStockModification/views/InvoiceStockModfierGenisView.vue";

import CustomTab from "@/components/CustomTab.vue";
import TaskTabGrid from "../components/TaskTabGrid.vue";
import DoubleTab from "../components/DoubleTab.vue";
import DoubleTabItems from "../components/DoubleTabItems.vue";
import IconButton from "@/components/IconButton.vue";

interface Data {
  wizardStore: InvoiceWizardStore;
  permStore: PermissionStore;
  configuration: AppConfiguration;
  store: InvoiceFlowTaskStore;
  editStore: GeneralIngoingInvoiceEditModelStore;
}

interface WizardStepParams {
  isControl?: boolean;
  isPermit?: boolean;
}

interface Methods {
  invoiceTaskUpdated(): void;
  newInvoice(): void;
  createWizardStep(
    list: GeneralIngoingInvoiceEditModel[] | null,
    step: number,
    params?: Partial<WizardStepParams>
  ): InvoiceWizardStep[] | null;
  createControlWizardStep(list: GeneralIngoingInvoiceEditModel[] | null, step: number): InvoiceWizardStep[] | null;
  createPermitWizardStep(list: GeneralIngoingInvoiceEditModel[] | null, step: number): InvoiceWizardStep[] | null;
  anySelected(): boolean;
  selectAll(value?: boolean): void;
  startWizard(): void;
  updatePermitStatuses(): void;
  filterList(list: GeneralIngoingInvoiceEditModel[], includeRelatedInvoice?: boolean): GeneralIngoingInvoiceEditModel[];
  orderAndFilter(
    list: GeneralIngoingInvoiceEditModel[] | null,
    includeRelatedInvoice?: boolean
  ): GeneralIngoingInvoiceEditModel[] | null;
  removeSorting(): void;
}

interface Computed {
  columns: GridColumnProps[];
  recordable: GeneralIngoingInvoiceEditModel[] | null;
  financiallyRecordable: GeneralIngoingInvoiceEditModel[] | null;
  ffControllable: GeneralIngoingInvoiceEditModel[] | null;
  ffAcceptable: GeneralIngoingInvoiceEditModel[] | null;
  controllable: GeneralIngoingInvoiceEditModel[] | null;
  acceptable: GeneralIngoingInvoiceEditModel[] | null;
  permittable: GeneralIngoingInvoiceEditModel[] | null;
  downPaymentRequests: GeneralIngoingInvoiceEditModel[] | null;
  payableItems: GeneralIngoingInvoiceEditModel[] | null;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    CustomTab,
    TaskTabGrid,
    DoubleTab,
    DoubleTabItems,
    IconButton,
    InvoiceStockModfierGenisView,
  },

  data: (): Data => ({
    wizardStore,
    permStore,
    configuration,
    store,
    editStore,
  }),

  async beforeMount() {
    if (this.permStore.permissions.length > 0) await this.store.loadInvoices();
  },

  created() {
    eventBus.$on("invoice-task-updated", this.invoiceTaskUpdated);
  },

  beforeDestroy() {
    eventBus.$off("invoice-task-updated", this.invoiceTaskUpdated);
  },

  methods: {
    async invoiceTaskUpdated(): Promise<void> {
      if (this.anySelected()) this.store.saveSelectedItems();
      await this.store.reloadInvoices();
      if (this.store.selectedInvoices.anySelected()) this.store.loadSelectedItems();
    },

    newInvoice(): void {
      this.$router.push(`/general-incoming-invoice/flow/create/1`);
    },

    createWizardStep(list, step, params): InvoiceWizardStep[] | null {
      if (!list || list.length == 0) return null;

      return list
        ?.filter((x) => x.selected)
        ?.map(
          (x) =>
            new InvoiceWizardStep({
              step: step,
              invoiceID: x.entity.id,
              isControl: params?.isControl ?? false,
              isPermit: params?.isPermit ?? false,
            })
        );
    },

    createControlWizardStep(list, step): InvoiceWizardStep[] | null {
      return this.createWizardStep(list, step, { isControl: true });
    },

    createPermitWizardStep(list, step): InvoiceWizardStep[] | null {
      return this.createWizardStep(list, step, { isPermit: true });
    },

    anySelected(): boolean {
      const i = this.store.invoices;
      if (i.recordableInvoices?.some((x) => x.selected)) return true;
      else if (i.financiallyRecordableInvoices?.some((x) => x.selected)) return true;
      else if (i.ffControllableInvoices?.some((x) => x.selected)) return true;
      else if (i.ffAcceptableInvoices?.some((x) => x.selected)) return true;
      else if (i.controllableInvoices?.some((x) => x.selected)) return true;
      else if (i.acceptableInvoices?.some((x) => x.selected)) return true;
      else if (i.permittableInvoices?.some((x) => x.selected)) return true;
      else if (i.downPaymentRequests?.some((x) => x.selected)) return true;
      else if (i.payableInvoices?.some((x) => x.selected)) return true;
      else return false;
    },

    selectAll(value = true): void {
      this.recordable?.forEach((e) => (e.selected = value));
      this.financiallyRecordable?.forEach((e) => (e.selected = value));
      this.ffControllable?.filter((e) => e.relatedInvoice).forEach((e) => (e.selected = value));
      this.ffAcceptable?.filter((e) => e.relatedInvoice).forEach((e) => (e.selected = value));
      this.controllable?.filter((e) => e.relatedInvoice).forEach((e) => (e.selected = value));
      this.acceptable?.filter((e) => e.relatedInvoice).forEach((e) => (e.selected = value));
      this.permittable?.forEach((e) => (e.selected = value));
      this.downPaymentRequests?.forEach((e) => (e.selected = value));
      this.payableItems?.forEach((e) => (e.selected = value));
    },

    startWizard(): void {
      if (!this.anySelected()) {
        // this.selectAll();
        return;
      }

      const i = this.store.invoices;
      const rec = this.createWizardStep(i.recordableInvoices, FlowStatus.Recording);
      const fin = this.createWizardStep(i.financiallyRecordableInvoices, FlowStatus.FinancialRecording);
      const ffc = this.createControlWizardStep(i.ffControllableInvoices, FlowStatus.StockRelationRecording);
      const ffa = this.createWizardStep(i.ffAcceptableInvoices, FlowStatus.StockRelationRecording);
      const con = this.createControlWizardStep(i.controllableInvoices, FlowStatus.ControllingAccepting);
      const acc = this.createWizardStep(i.acceptableInvoices, FlowStatus.ControllingAccepting);
      const per = this.createPermitWizardStep(i.permittableInvoices, FlowStatus.Recording);
      const dwp = this.createPermitWizardStep(i.downPaymentRequests, 0);
      const pay = this.createWizardStep(i.payableInvoices, FlowStatus.Accounting);

      const steps: InvoiceWizardStep[] = [];
      if (rec) steps.push(...rec);
      if (fin) steps.push(...fin);
      if (ffc) steps.push(...ffc);
      if (ffa) steps.push(...ffa);
      if (con) steps.push(...con);
      if (acc) steps.push(...acc);
      if (per) steps.push(...per);
      if (dwp) steps.push(...dwp);
      if (pay) steps.push(...pay);

      this.wizardStore.remove();
      this.wizardStore.startWizard(steps);
      this.wizardStore.save();

      const first = this.wizardStore.wizard?.steps[0].path;
      if (!first) return;

      this.$router.push(first);
    },

    async updatePermitStatuses(): Promise<void> {
      const permIDs = this.store.invoices.permittableInvoices
        ? this.store.invoices.permittableInvoices
            .filter(
              (x) =>
                x.selected &&
                x.entity.permitStatus != PermitStatuses.Payable &&
                x.flowStatus > FlowStatus.StockRelationRecording
            )
            .map((x) => x.entity.id)
        : [];

      const dpaymIDs = this.store.invoices.downPaymentRequests
        ? this.store.invoices.downPaymentRequests
            ?.filter((x) => x.selected && x.entity.permitStatus != PermitStatuses.Payable)
            .map((x) => x.entity.id)
        : [];

      const invoiceIDs = [...permIDs, ...dpaymIDs];

      if (permIDs.length == 0 && dpaymIDs.length == 0) {
        this.store.invoices.permittableInvoices?.forEach((x) => {
          x.selected = false;
        });

        this.store.invoices.downPaymentRequests?.forEach((x) => {
          x.selected = false;
        });

        return;
      }

      await this.editStore.updatePermitStatuses(invoiceIDs, PermitStatuses.Payable);

      eventBus.$emit("success", "Sikeres Utalványozás státusz állítás!");

      this.invoiceTaskUpdated();
    },

    filterList(list, includeRelatedInvoice = false): GeneralIngoingInvoiceEditModel[] {
      return list
        .filter(
          (x) =>
            x.entity.id.toString().includes(this.store.filterText) ||
            x.partner.toLowerCase().includes(this.store.filterText.toLowerCase()) ||
            (x.regNums != null && x.regNums.some(r => r.toLocaleLowerCase().includes(this.store.filterText.toLowerCase()))) ||
            (x.bodyNums != null && x.bodyNums.some(r => r.toLocaleLowerCase().includes(this.store.filterText.toLowerCase())))
        )
        .filter(
          (x) =>
            !includeRelatedInvoice ||
            x.relatedInvoice === this.store.relatedInvoiceFilter.value ||
            this.store.relatedInvoiceFilter.value === null
        );
    },

    orderAndFilter(list, includeRelatedInvoice = false): GeneralIngoingInvoiceEditModel[] | null {
      return list == null
        ? list
        : orderBy(filterBy(this.filterList(list, includeRelatedInvoice), this.store.filter), this.store.sort);
    },

    removeSorting(): void {
      this.store.sort = [];
    },
  },

  computed: {
    columns: () => [
      {
        width: 50,
        cell: "commandTemplate",
        headerCell: "commandHeaderTemplate",
        sortable: false,
        filterable: false,
      },
      {
        width: 200,
        field: "entity.id",
        title: "GENI",
        cell: "geniTemplate",
      },
      {
        field: "entity.invoiceNo",
        title: "Bizonylat szám",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "partner",
        title: "Szállító",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
      {
        field: "entity.invoiceDate",
        title: "Számla dátuma",
        type: "date",
        format: "{0: yyyy-MM-dd}",
        headerClassName: "center_text adjust_center",
        className: "center_text adjust_center",
      },
      {
        field: "entity.shipDate",
        title: "Teljesítés dátuma",
        type: "date",
        format: "{0: yyyy-MM-dd}",
        headerClassName: "center_text adjust_center",
        className: "center_text adjust_center",
      },
      {
        field: "entity.payTerm",
        title: "Fizetési határidő",
        type: "date",
        format: "{0: yyyy-MM-dd}",
        headerClassName: "center_text adjust_center",
        className: "center_text adjust_center",
      },
      {
        field: "entity.currTotal",
        title: "Bruttó",
        filter: "numeric",
        headerClassName: "right_text adjust_right",
        className: "right_text adjust_right",
        // eslint-disable-next-line
        cell: (h: any, _: any, props: GridRowProps) => {
          const invoice = props.dataItem.entity;
          const text = `${formatMoney(invoice.currTotal)}`;
          return h("td", { class: "right_text adjust_right" }, text);
        },
      },
      {
        field: "entity.invCurrency",
        title: "Pénznem",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },

      {
        field: "daysSpentInStatus",
        title: "Státuszban eltöltött idő",
        format: "{0} nap",
        filter: "numeric",
        headerClassName: "adjust_left",
        className: "adjust_left",
      },
    ],

    recordable(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.recordableInvoices);
    },

    financiallyRecordable(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.financiallyRecordableInvoices);
    },

    ffControllable(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.ffControllableInvoices, true);
    },

    ffAcceptable(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.ffAcceptableInvoices, true);
    },

    controllable(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.controllableInvoices, true);
    },

    acceptable(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.acceptableInvoices, true);
    },

    permittable(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.permittableInvoices);
    },

    downPaymentRequests(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.downPaymentRequests);
    },

    payableItems(): GeneralIngoingInvoiceEditModel[] | null {
      return this.orderAndFilter(this.store.invoices.payableInvoices);
    },
  },
});
