






























import Vue from "vue";
import IconButton from "@/components/IconButton.vue";
import Page from "../../../components/Page.vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import {
  ApiSearchColumn,
  ColumnType,
  } from "@/components/apiSearch/models/ApiSearch";
import store, {GpartsEditStore} from "../services/GpartsEditStore"

interface Data {
  store: GpartsEditStore;
}

interface Computed {
  columns: ApiSearchColumn[];
  url: string;
}

interface Methods {
  edit(item: any): void;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
      ApiSearchPage,
      Page,
      IconButton
  },

  data() {
    return {
      store
    };
  },

  methods: {
      edit(item: any){
          this.$router.push('/gparts/edit/' + item.id);
      }
  },

  computed: {
      url() {
          return `gparts/edit/search`;
      },

      columns: function() {
          return [
                  { field: "id", title: "ID" },
                  { field: "name", title: "Megnevezés" },
                  { field: "code", title: "Belső cikkszám" },
                  { field: "extCode", title: "Külső cikkszám" },
                  { field: "id", title: "", filterable: false, sortable: false, cell: 'command' },
              ];
  },
  }
});
