





























































































































import Vue from "vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import { fetchData } from "@/services/http";
import Fragment from "@/components/Fragment.vue";
import configuration from "@/models/Configuration";

interface StockDivision {
  stockID: number;
  parts: string;
  unit: string;
  currency: string;
  quantity: number;
  exchangeRate: number;
  currentUnitPrice: number;
  originalUnitPrice: number;
  currentPrice: number;
  originalPrice: number;
  otherCosts: OtherCost[];
}

interface OtherCost {
  invoiceID: number;
  invoiceItemID: number;
  currency: string;
  partner: string;
  itemName: string;
  unitPrice: number;
  price: number;
  exchangeRate: number;
  otherCostExchangeRate: number | null;
}

interface Data {
  stockDivision: StockDivision[];
}

interface Props {
  invoiceItemID: number;
}

export default Vue.extend<Data, {}, {}, Props>({
  async beforeMount() {
    const stockDivision = await fetchData<StockDivision[]>(`incoming-invoice-items/${this.invoiceItemID}/stock-costs`)
    .then((x)=>{
      console.log(x);
      return x;
    });
    this.stockDivision = stockDivision;
  },

  data: () => ({
    stockDivision: [],
    configuration,
  }),

  computed: {
    stockDivisionExchangeRate() {
      if (!this.stockDivision) {
        return null;
      }

      for (const item of this.stockDivision) {
        for (const cost of item.otherCosts) {
          if (cost.otherCostExchangeRate) {
            return cost.otherCostExchangeRate;
          }
        }
      }

      return null;
    },
  },

  components: {
    MoneyDisplay,
    Fragment,
  },

  props: {
    invoiceItemID: {
      type: Number,
    },
  },
});
