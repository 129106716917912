import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import LoginResult from '../models/LoginResult'

export default class LoginApi {
    static login(loginName: string, password: string): Promise<LoginResult> {
        return http
            .post<ApiResponse<LoginResult>>(`login`, {loginName: loginName, password: password})
            .then(handleApiResponse)
    }
}