import User from "../models/User";
import UserApi from "./UserApi";

export class UserStore {
  users: User[] = [];

  async loadUsers() {
    this.users = await UserApi.getUsers();
  }
  
  getUser(id: number): User {
    return this.users.find(x => x.id === id) as User;
  }
}

const store = new UserStore();
export default store;
