var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-6"},[_c('v-card-title',[_c('v-row',{staticClass:"align-baseline"},[_c('v-col',{staticClass:"pl-0"},[_c('h3',{staticStyle:{"font-size":"15px"}},[_vm._v("Vámhivatal reláció")])]),_c('v-col',{staticClass:"pr-0",attrs:{"align":"right"}},[(_vm.store.partnerCustoms.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" fa-exclamation-circle ")])]}}],null,false,349803809)},[_c('span',[_vm._v("A partnerhez már tartozik vámhivatal reláció")])]):_vm._e(),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.setAuthority}},[_vm._v("Partner vámhivatallá tétele")])],1)],1)],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.store.partnerCustoms,"columns":_vm.columns,"detail":"deletingTemplate","expand-field":'deleting',"edit-field":'editing'},on:{"itemchange":_vm.itemChange},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[(!props.dataItem.editing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setUpdatePartnerCustoms(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.cancelEdit(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok mentése")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.deletePartnerCustoms(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}},{key:"deletingTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"yellowBg"},[_c('div',{staticClass:"auth"},[_c('p',{staticClass:"conf"},[_vm._v(" Biztos hogy törli a következő elemet: "),_c('strong',[_vm._v(_vm._s(props.dataItem.dutyAccCode))]),_vm._v(" ? ")])]),_c('div',{staticClass:"btns"},[_c('v-btn',{staticClass:"con_btn",on:{"click":function($event){return _vm.confirmDelete(props.dataItem)}}},[_vm._v(" Megerősítés ")]),_c('v-btn',{staticClass:"canc_btn",on:{"click":function($event){return _vm.cancelDelete(props.dataItem)}}},[_vm._v(" Mégsem ")])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }