import { FixAssetData, FixAssetType } from "./../models/FixAsset";
import IncomingInvoiceAccItem from "../models/IncomingInvoiceAccItem";
import User from "@/modules/partner/models/User";
import GeneralIngoingInvoiceItem from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItem";
import GeneralIngoinInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";
import InvoiceFlowStatusRelation from "@/modules/invoiceFlowStatus/model/InvoiceFlowStatusRelation";
import GeneralIngoingInvoiceApi from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import {
  InvoiceRequest,
  InvoiceAcceptRequest,
  InvoiceAcceptRequestResponse,
  InvoiceControlRequest,
  InvoiceControlRequestResponse,
  InvoiceSimpleEditRequest
} from "../models/InvoiceRequest";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import PaymentInfoDisplay from "../models/PaymentInfoDisplay";
import ApprovalType from "../models/ApprovalType";
import ApprovalMatrixHeadResponse from "../../approvalMatrix/models/ApprovalMatrixHeadResponse";
import InvoiceAcceptState from "../models/InvoiceAcceptState";
import FlowHistory from "../models/FlowHistory";
import IncomingInvoiceRemark from "../models/IncomingInvoiceRemark";
import InvoiceItemDimension from "@/modules/invoiceItemDimension/models/InvoiceItemDimension";
import { ApprovalMatrixData } from "../models/ApprovalMatrix";
import AccCode from "../models/AccCode";
import Dimension from "../models/Dimension";
import { GeneralRectItemDetails, GeneralRectItemDetailsData, InvoiceItem, Stock } from "@/modules/invoiceItems/models";
import AccInvSError from "../models/AccInvSError";
import VatDivision, { VatDivisionModifier } from "../models/VatDivision";
import VatDivisionDisplay from "../models/VatDivisionDisplay";
import CreateDimensionRespone from "../models/CreateDimensionRespone";
import AccItemDetailedType from "../models/AccItemDetailedType";
import CancelAccountingResult from "../models/CancelAccountingResult";
import InvoiceFlowTemplate from "../models/InvoiceFlowTemplate";
import InvoiceDpaym from "@/modules/generalIngoingInvoice/models/InvoiceDpaym";
import PaidInvoiceDpaym from "@/modules/generalIngoingInvoice/models/PaidInvoiceDpaym";
import GeneralIngoingInvoiceItemMin from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItemMin";
import InvoiceRejectionReason from "../models/InvoiceRejectionReason";
import InvoiceRejection from "../models/InvoiceRejection";
import StoreInPaymentDate from "../models/StoreInPaymentDate";
import SubcontractorProjectDetail from "@/modules/generalIngoingInvoice/models/SubcontractorProjectDetail";
import VatReturnPhase from "@/modules/vat/models/VatReturnPhase";
import CheckResponse from "../models/CheckResponse";
import CarItemRelation from "@/modules/canNet/models/CarItemRelation";
import InvoiceImportItem from "@/modules/generalIngoingInvoice/models/InvoiceImportItem";

export default class InvoiceFlowApi extends GeneralIngoingInvoiceApi {
  static getNavItems(invoiceId: number): Promise<InvoiceImportItem[]> {
    return http.get<ApiResponse<InvoiceImportItem[]>>(`general-incoming-invoice/get-oninv-items/${invoiceId}`)
      .then(handleApiResponse);
  }

  static getFixAssetTypes(): Promise<FixAssetType[]> {
    return http.get<ApiResponse<FixAssetType[]>>(`fix-asset/types`).then(handleApiResponse);
  }

  static getFixAssetByStock(stockID: number): Promise<FixAssetData> {
    return http.get<ApiResponse<FixAssetData>>(`fix-asset/stock/${stockID}`).then(handleApiResponse);
  }

  static postFixAsset(fixAsset: FixAssetData, stockID: number, invoiceID: number): Promise<number> {
    return http
      .post<ApiResponse<number>>(`fix-asset`, { asset: fixAsset, stockID: stockID, invoiceID: invoiceID })
      .then(handleApiResponse);
  }

  static createDownPaymentInvoiceItems(invoiceId: number) {
    return http.post<ApiResponse<boolean>>(`noti/invoice/${invoiceId}`).then(handleApiResponse);
  }

  static deleteDownPaymentInvoiceItems(invoiceId: number) {
    return http.delete<ApiResponse<boolean>>(`noti/invoice/${invoiceId}/invoiceitems`).then(handleApiResponse);
  }

  static deleteFixAsset(id: number): Promise<boolean> {
    return http.delete(`fix-asset/${id}`).then(checkResponse);
  }

  static updateFlowRecording(invoice: GeneralIngoinInvoice, saveOnly: boolean): Promise<boolean> {
    const projectId = invoice?.selectedProjectId ?? 1;
    return http
      .put(`invoice-flow/recording`, { invoice: invoice, saveOnly: saveOnly, projectId: projectId })
      .then(checkResponse);
  }

  static updateFinancialRecording(
    invoice: GeneralIngoinInvoice,
    items: GeneralIngoingInvoiceItem[],
    saveOnly: boolean
  ): Promise<boolean> {
    const projectId = invoice?.selectedProjectId ?? 1;
    return http
      .put(`invoice-flow/financial-recording`, {
        invoice: invoice,
        items: items,
        saveOnly: saveOnly,
        projectId: projectId,
      })
      .then(checkResponse);
  }

  static updateSimple(request: InvoiceSimpleEditRequest): Promise<boolean> {
    return http
      .put(`general-incoming-invoice/simple-update`, request)
      .then(checkResponse);
  }

  //? Unused ?
  static update(invoice: GeneralIngoinInvoice, items: GeneralIngoingInvoiceItem[] | null): Promise<boolean> {
    return http
      .put(`general-incoming-invoice`, {
        invoice: invoice,
        items: items,
        projectInfo: null,
      })
      .then(checkResponse);
  }

  static createFlow(invoice: GeneralIngoinInvoice): Promise<number> {
    const projectId = invoice?.selectedProjectId ?? 1;
    return http
      .post<ApiResponse<number>>(`invoice-flow`, { invoice: invoice, projectId: projectId, isDifferentialVat: invoice.isDifferentialVat })
      .then(handleApiResponse);
  }

  static getInvoiceCurrencyRateForInvoice(
    invoice: GeneralIngoinInvoice
  ): Promise<{ exchangeRate: number; vatDate: string; rateDate: string; invoiceDate: string }> {
    return http
      .get<ApiResponse<{ exchangeRate: number; vatDate: string; rateDate: string; invoiceDate: string }>>(
        `currency/rate/invoice`,
        {
          params: {
            currency: invoice.invCurrency === "" || invoice.invCurrency === null ? "HUF" : invoice.invCurrency,
            invoiceID: invoice.id,
            partnerID: invoice.partner,
            invoiceDate: invoice.invoiceDate,
            arrivDate: invoice.arrivDate,
            shipDate: invoice.shipDate,
            netto: invoice.currNetto,
          },
        }
      )
      .then(handleApiResponse);
  }

  static getInvoiceDisplay(invoiceId: number): Promise<GeneralIngoinInvoiceDisplay> {
    return http
      .get<ApiResponse<GeneralIngoinInvoiceDisplay>>(`general-incoming-invoice/details/invoice/${invoiceId}`)
      .then(handleApiResponse);
  }

  static getInvoiceShippingPhaseDate(shipDate: string): Promise<VatReturnPhase> {
    return http.get<ApiResponse<VatReturnPhase>>(`vat/${shipDate}/shipping-date-phase`).then(handleApiResponse);
  }

  static getPartnerPayTerm(partnerID: number, invoiceDate: string): Promise<any> {
    return http
      .get<ApiResponse<string>>(`general-incoming-invoice/details/partner-deadline-date`, {
        params: {
          partnerID: partnerID,
          invoiceDate: invoiceDate,
        },
      })
      .then(handleApiResponse);
  }

  static getPaymentInfo(invoiceId: number): Promise<PaymentInfoDisplay> {
    return http.get<ApiResponse<PaymentInfoDisplay>>(`invoice-flow/payment-info/${invoiceId}`).then(handleApiResponse);
  }

  static getPaymentInfos(invoiceId: number): Promise<PaymentInfoDisplay[]> {
    return http
      .get<ApiResponse<PaymentInfoDisplay[]>>(`invoice-flow/payment-infos/${invoiceId}`)
      .then(handleApiResponse);
  }

  // static getAcceptorAndControlUsers(): Promise<User[]> {
  //   return http.get<ApiResponse<User[]>>(`users/accept-control`).then(handleApiResponse);
  // }

  static getFlowStatusRelations(invoiceId: number): Promise<InvoiceFlowStatusRelation[]> {
    return http
      .get<ApiResponse<InvoiceFlowStatusRelation[]>>(`invoice-flow-status/relations/${invoiceId}`)
      .then(handleApiResponse);
  }

  static resetFlowStatusRelations(invoiceId: number): Promise<any> {
    return http
      .get<ApiResponse<any>>(`invoice-flow-status/relations/reset/${invoiceId}`)
      .then(handleApiResponse);
  }

  static manageFlowStatusRelations(relation: InvoiceFlowStatusRelation): Promise<boolean> {
    return http.put(`invoice-flow-status/relation`, { relation: relation }).then(checkResponse);
  }

  static deleteFlowStatusRelation(invoiceId: number, statusId: number): Promise<boolean> {
    return http.delete(`invoice-flow-status/relation/${invoiceId}/${statusId}`).then(checkResponse);
  }

  static getControlRequests(invoiceId: number): Promise<InvoiceControlRequest[]> {
    return http
      .get<ApiResponse<InvoiceControlRequest[]>>(`invoice-flow/requests/control/${invoiceId}`)
      .then(handleApiResponse);
  }

  static updateControlRequests(
    invoiceId: number,
    requests: InvoiceControlRequest[]
  ): Promise<InvoiceControlRequestResponse> {
    return http
      .put<ApiResponse<InvoiceControlRequestResponse>>(`invoice-flow/requests/control`, {
        invoiceId: invoiceId,
        requests: requests,
      })
      .then(handleApiResponse);
  }

  static delegateToToleranceApprover(invoiceId: number): Promise<boolean> {
    return http
      .post<ApiResponse<boolean>>(`invoice-flow/request/accept/tolerance/${invoiceId}`)
      .then(handleApiResponse);
  }

  static toleranceDelegate(invoiceId: number): Promise<boolean> {
    return http
      .post<ApiResponse<boolean>>(`invoice-flow/request/accept/delegate/tolerance/${invoiceId}`)
      .then(handleApiResponse);
  }

  static deleteControlRequest(id: number) {
    return http.delete(`invoice-flow/request/control/${id}`).then(checkResponse);
  }

  static createControlRequest(invContrReq: InvoiceControlRequest): Promise<InvoiceControlRequest> {
    return http
      .post<ApiResponse<InvoiceControlRequest>>(`invoice-flow/request/control`, invContrReq)
      .then(handleApiResponse);
  }

  static getAcceptRequests(invoiceId: number): Promise<InvoiceAcceptRequest[]> {
    return http
      .get<ApiResponse<InvoiceAcceptRequest[]>>(`invoice-flow/requests/accept/${invoiceId}`)
      .then(handleApiResponse);
  }

  static getCurrentUsersApproval(invoiceId: number): Promise<InvoiceAcceptRequest> {
    return http
      .get<ApiResponse<InvoiceAcceptRequest>>(`invoice-flow/requests/accept/${invoiceId}/mine`)
      .then(handleApiResponse);
  }

  static getCurrentUsersFullfilmentRequests(invoiceId: number): Promise<InvoiceRequest[]> {
    return http
      .get<ApiResponse<InvoiceRequest[]>>(`invoice-flow/requests/fullfilment/${invoiceId}/mine`)
      .then(handleApiResponse);
  }

  static updateAcceptRequests(
    invoiceId: number,
    requests: InvoiceAcceptRequest[]
  ): Promise<InvoiceAcceptRequestResponse> {
    return http
      .put<ApiResponse<InvoiceAcceptRequestResponse>>(`invoice-flow/requests/accept`, {
        invoiceId: invoiceId,
        requests: requests,
      })
      .then(handleApiResponse);
  }

  static updateFulFillControlRequestStatus(invId: number, invapprStat: number) {
    return http
      .put<ApiResponse<boolean>>(`invoice-flow/fulfill-control/status/${invId}/${invapprStat}`)
      .then(checkResponse);
  }

  static updateFulFillAcceptRequestStatus(invId: number, invapprStat: number) {
    return http
      .put<ApiResponse<boolean>>(`invoice-flow/fulfill-accept/status/${invId}/${invapprStat}`)
      .then(checkResponse);
  }

  static updateAcceptRequestStatus(invId: number, invapprStat: number, invoice: GeneralIngoinInvoice) {
    return http.put<ApiResponse<boolean>>(`invoice-flow/accept/status/${invId}/${invapprStat}`, { invoice }).then(checkResponse);
  }

  static deleteAcceptRequest(id: number) {
    return http.delete(`invoice-flow/request/accept/${id}`).then(checkResponse);
  }

  static createAcceptRequest(invAccReq: InvoiceAcceptRequest): Promise<InvoiceAcceptRequest> {
    return http
      .post<ApiResponse<InvoiceAcceptRequest>>(`invoice-flow/request/accept`, invAccReq)
      .then(handleApiResponse);
  }

  static getApprovalMatrixHeads(): Promise<ApprovalMatrixHeadResponse[]> {
    return http.get<ApiResponse<ApprovalMatrixHeadResponse[]>>(`approval-matrix`).then(handleApiResponse);
  }

  static getApprovalMatrixHeadsByPartner(partnerId: number): Promise<ApprovalMatrixHeadResponse[]> {
    return http
      .get<ApiResponse<ApprovalMatrixHeadResponse[]>>(`approval-matrix/partner/${partnerId}`)
      .then(handleApiResponse);
  }

  static getApprovalTypes(): Promise<ApprovalType[]> {
    return http.get<ApiResponse<ApprovalType[]>>(`approval-matrix/types`).then(handleApiResponse);
  }

  static updateApprovalMatrixHeads(heads: ApprovalMatrixHeadResponse[]): Promise<ApprovalMatrixHeadResponse[]> {
    return http
      .put<ApiResponse<ApprovalMatrixHeadResponse[]>>(`approval-matrix`, {
        heads: heads,
      })
      .then(handleApiResponse);
  }

  static getInvoiceAcceptState(invoiceID: number): Promise<InvoiceAcceptState> {
    return http.get<ApiResponse<InvoiceAcceptState>>(`invoice-flow/accept-state/${invoiceID}`).then(handleApiResponse);
  }

  static getApprovalMatrixData(): Promise<ApprovalMatrixData> {
    return http.get<ApiResponse<ApprovalMatrixData>>(`approval-matrix/current`).then(handleApiResponse);
  }

  static getApprovalMatrixDataByUser(userID: number): Promise<ApprovalMatrixData> {
    return http.get<ApiResponse<ApprovalMatrixData>>(`approval-matrix/user/${userID}`).then(handleApiResponse);
  }

  static saveAccountItems(invoiceID: number, items: IncomingInvoiceAccItem[]): Promise<boolean> {
    items = items.filter((x) => x.accCode != "placehold");
    const payload = { invNo: invoiceID, Items: items };
    return http.post<ApiResponse<boolean>>(`general-incoming-invoice/update-account-items`, payload).then(handleApiResponse);
  }

  static getAccountCodes(invoiceID: number, isStatistic = true): Promise<AccCode[]> {
    return http.get<ApiResponse<AccCode[]>>(`accounting/get-acc-codes/${invoiceID}/${isStatistic}`).then(handleApiResponse);
  }

  static getAccountItems(invoiceID: number): Promise<IncomingInvoiceAccItem[]> {
    return http.get<ApiResponse<IncomingInvoiceAccItem[]>>(`accounting/${invoiceID}`).then(handleApiResponse);
  }

  static getCaroAccountItems(invoiceID: number): Promise<IncomingInvoiceAccItem[]> {
    return http.get<ApiResponse<IncomingInvoiceAccItem[]>>(`accounting/caro/${invoiceID}`).then(handleApiResponse);
  }

  static updateAccountItem(item: IncomingInvoiceAccItem) {
    const payload = { item: item };
    return http.post<ApiResponse<boolean>>(`accounting/update-acc-item`, payload).then(handleApiResponse);
  }

  static updateAccountItems(invoiceID: number, items: IncomingInvoiceAccItem[]) {
    items = items.filter((x) => x.accCode != "placehold");
    const payload = Object.freeze({ invoiceID, items });
    return http.post<ApiResponse<boolean>>(`accounting/update-acc-items`, payload).then(handleApiResponse);
  }

  static createAccountItem(item: IncomingInvoiceAccItem, invoiceID: number) {
    const payload = { Item: item, InvNo: invoiceID };
    return http.post<ApiResponse<number>>(`accounting/create-acc-item`, payload).then(handleApiResponse);
  }

  static deleteAccountItem(itemID: number) {
    return http.delete<ApiResponse<boolean>>(`accounting/delete-acc-item/${itemID}`).then(handleApiResponse);
  }

  static updateAccItemDimension(dimension: Dimension, headID: number): Promise<boolean> {
    return http
      .put<ApiResponse<boolean>>(`accounting/update-dimension`, { Dimension: dimension, HeadID: headID })
      .then(handleApiResponse);
  }

  static createAccItemDimension(dimension: Dimension, headID: number): Promise<CreateDimensionRespone> {
    return http
      .post<ApiResponse<CreateDimensionRespone>>(`accounting/create-dimension`, {
        Dimension: dimension,
        HeadID: headID,
      })
      .then(handleApiResponse);
  }

  static getAccItemDetailedType(): Promise<AccItemDetailedType[]> {
    return http.get<ApiResponse<AccItemDetailedType[]>>(`accountDetailedType`).then(handleApiResponse);
  }

  static deleteAccItemDimension(projectDimID: number, resourceDimID: number, carDimID: number): Promise<boolean> {
    return http
      .delete<ApiResponse<boolean>>(`accounting/delete-dimension/${projectDimID}/${resourceDimID}/${carDimID}`)
      .then(handleApiResponse);
  }

  static updateInvoiceItemApproval(approvalRequestID: number, invoiceItemId: number): Promise<boolean> {
    return http
      .put<ApiResponse<boolean>>(`invoice-item-appr/${approvalRequestID}/${invoiceItemId}`)
      .then(handleApiResponse);
  }

  static updateDimensionApproval(
    approvalRequestID: number,
    invoiceItemId: number,
    dimensionId: number
  ): Promise<boolean> {
    return http
      .put<ApiResponse<boolean>>(`invoice-item-appr/${approvalRequestID}/${invoiceItemId}/${dimensionId}`)
      .then(handleApiResponse);
  }

  static getFlowHistory(invNo: number): Promise<FlowHistory[]> {
    return http.get<ApiResponse<FlowHistory[]>>(`flow-history/${invNo}`).then(handleApiResponse);
  }

  static getRemarkHistory(invNo: number): Promise<IncomingInvoiceRemark[]> {
    return http.get<ApiResponse<IncomingInvoiceRemark[]>>(`remark-history/${invNo}`).then(handleApiResponse);
  }

  static updateNavItem(invoiceItemID: number, importItemID: number): Promise<any> {
    return http.post<ApiResponse<any>>(`general-incoming-invoice/update-oninv-item`, {
      ImportItemID: importItemID,
      InvoiceItemID: invoiceItemID
    }).then(handleApiResponse);
  }

  static createRemark(remark: IncomingInvoiceRemark): Promise<any> {
    return http.post<ApiResponse<any>>(`remark-history`, remark).then(handleApiResponse);
  }

  static createInvoiceRemark(invNo: number, remark: string): Promise<any> {
    return http.post<ApiResponse<any>>(`remark-history/invoice-remark`, { invNo, remark }).then(handleApiResponse);
  }

  static updateApproval(invoiceID: number, approvedDimensions: number[], invoice: GeneralIngoinInvoice) {
    return http
      .post<ApiResponse<any>>(`invoice-flow/approval/${invoiceID}/request`, {
        approvedDimensions,
        invoice
      })
      .then(handleApiResponse);
  }

  static getInvoiceDimensions(invoiceID: number) {
    return http
      .get<ApiResponse<InvoiceItemDimension[]>>(`invoice-flow/${invoiceID}/dimensions`)
      .then(handleApiResponse);
  }

  static getToleranceApprovalPermission(invoiceID: number, items: InvoiceItem[]): Promise<boolean> {
    return http
      .post<ApiResponse<any>>(
        `general-incoming-invoice/${invoiceID}/items/tolerance-approver`,
        items.map((i) => i.relations.map((r) => (r.relatedItem as Stock).deliveryNumber).find((x) => x))
      )
      .then(handleApiResponse);
  }

  static preAccounting(invoiceID: number) {
    return http
      .post<ApiResponse<any>>(`accounting/pre-accounting`, {
        InvoiceID: invoiceID,
      })
      .then(handleApiResponse);
  }

  static cancelAccounting(invoiceID: number): Promise<CancelAccountingResult> {
    return http
      .post<ApiResponse<CancelAccountingResult>>(`accounting/cancel-accounting`, { InvoiceID: invoiceID })
      .then(handleApiResponse);
  }

  static postAccounting(invoiceID: number): Promise<CancelAccountingResult> {
    return http
      .post<ApiResponse<CancelAccountingResult>>(`accounting/post-accounting`, { InvoiceID: invoiceID })
      .then(handleApiResponse);
  }

  static vatDateUpdate(invoiceID: number, vatDate: string, isWarningChecked: boolean): Promise<CheckResponse> {
    return http
      .post<ApiResponse<CheckResponse>>(`accounting/update-vatdate`,
        {
          InvNo: invoiceID,
          VatDate: vatDate,
          IsClosedVatIntervalChecked: isWarningChecked
        })
      .then(handleApiResponse);
  }

  static getAccInvSErrors(invoiceID: number) {
    return http.get<ApiResponse<AccInvSError[]>>(`accounting/acc-inv-serr/${invoiceID}`).then(handleApiResponse);
  }

  static getVatDivisions(invoiceID: number) {
    return http.get<ApiResponse<VatDivision[]>>(`tax/vatDivisions/${invoiceID}`).then(handleApiResponse);
  }

  static getVatDivisionEditModels(invoiceID: number) {
    return http.get<ApiResponse<VatDivision[]>>(`tax/vatDivisionEditModels/${invoiceID}`).then(handleApiResponse);
  }

  static saveVatDivisionItem(value: VatDivisionDisplay) {
    return http.put<ApiResponse<any>>(`tax/saveVatDiv`, value).then(handleApiResponse);
  }

  static saveVatDivisionItems(value: VatDivisionDisplay[]) {
    return http.put<ApiResponse<any>>(`tax/saveVatDivList`, value).then(handleApiResponse);
  }

  static saveVatDivChanges(itemToSave: VatDivisionModifier): Promise<any> {
    return http.put<ApiResponse<any>>(`tax/saveVatDivChanges`, itemToSave).then(handleApiResponse);
  }

  static runVatDividing(invoiceID: number): Promise<any> {
    return http.post<ApiResponse<any>>(`tax/RunVatDiv/${invoiceID}`).then(handleApiResponse);
  }

  static createInvoiceFlowTemplate(invoiceFlowTemplate: InvoiceFlowTemplate): Promise<any> {
    return http.post<ApiResponse<any>>(`invoice-flow-template`, invoiceFlowTemplate).then(handleApiResponse);
  }

  static loadInvoiceFlowTemplatesByPartnerId(partnerId: number): Promise<InvoiceFlowTemplate[]> {
    return http.get<ApiResponse<InvoiceFlowTemplate[]>>(`invoice-flow-template/${partnerId}`).then(handleApiResponse);
  }

  static loadInvoiceFlowTemplate(templateId: number, invNo: number): Promise<InvoiceFlowTemplate> {
    return http
      .get<ApiResponse<InvoiceFlowTemplate>>(`invoice-flow-template/template/${templateId}/${invNo}`)
      .then(handleApiResponse);
  }

  static closeGpartsOrder(invNo: number) {
    return http.put<ApiResponse<any>>(`invoice-flow/gpart-order/${invNo}`).then(handleApiResponse);
  }

  static kanriSync(invNo: number) {
    return http.post<ApiResponse<any>>(`invoice-flow/kanri/sync/${invNo}`).then(handleApiResponse);
  }

  static generateViriForLetterOfCredit(invNo: number) {
    return http.post<ApiResponse<any>>(`invoice-flow/generate-viri/${invNo}`).then(handleApiResponse);
  }

  static loadDpaymInvoicesByPartner(partnerId: number, searchText: string, isOrder: boolean): Promise<InvoiceDpaym[]> {
    return http
      .get<ApiResponse<InvoiceDpaym[]>>(`invoice-dpaym`, {
        params: {
          p: partnerId,
          s: searchText,
          i: isOrder,
        },
      })
      .then(handleApiResponse);
  }

  static createDownPay(item: InvoiceDpaym) {
    return http.post<ApiResponse<any>>(`invoice-dpaym`, item).then(handleApiResponse);
  }

  static loadPaid(invId: number): Promise<PaidInvoiceDpaym[]> {
    return http.get<ApiResponse<PaidInvoiceDpaym[]>>(`invoice-dpaym/paid/${invId}`).then(handleApiResponse);
  }

  static loadInvItemMins(invId: number): Promise<GeneralIngoingInvoiceItemMin[]> {
    return http.get<ApiResponse<GeneralIngoingInvoiceItemMin[]>>(`invoice-dpaym/min/${invId}`).then(handleApiResponse);
  }

  static deletePaidDpaymInvoice(relId: number): Promise<boolean> {
    return http.delete(`invoice-dpaym/${relId}`).then(checkResponse);
  }

  static setService(invoiceID: number, isService: boolean): Promise<boolean> {
    return http.put(`invoice-flow/set-service`, { invoiceID: invoiceID, isService: isService }).then(checkResponse);
  }

  static getInvoiceRejectionReasons(): Promise<InvoiceRejectionReason[]> {
    return http.get<ApiResponse<InvoiceRejectionReason[]>>(`invoice/reject/reasons`).then(handleApiResponse);
  }

  static getInvoiceRejection(invoiceID: number): Promise<InvoiceRejection> {
    return http.get<ApiResponse<InvoiceRejection>>(`invoice/reject/${invoiceID}`).then(handleApiResponse);
  }

  static postInvoiceRejection(invoiceID: number, rejection: InvoiceRejection): Promise<number> {
    const data = new FormData();
    data.append("file", rejection?.document ?? "");
    data.append("invoiceID", invoiceID.toString());
    data.append("id ", rejection.id.toString());
    data.append("reason", rejection.reason);
    data.append("remark", rejection.remark);

    return http
      .post<ApiResponse<number>>(`invoice/reject/geni`, data, { headers: { "Content-Type": "multipart/form-data" } })
      .then(handleApiResponse);
  }

  static undoInvoiceRejection(invoiceID: number): Promise<number> {
    return http.post<ApiResponse<number>>(`invoice/reject/geni/${invoiceID}`).then(handleApiResponse);
  }

  static changeInVatCount(items: GeneralIngoingInvoiceItem[]): Promise<boolean> {
    return http.put(`general-incoming-invoice/change-in-vat-count`, { items }).then(checkResponse);
  }

  static getStoreInPaymentDate(orderID: number): Promise<StoreInPaymentDate> {
    return http.get<ApiResponse<StoreInPaymentDate>>(`store-in/${orderID}/paydate`).then(handleApiResponse);
  }

  static getsubcontractorProjectDetails(projectID: number): Promise<SubcontractorProjectDetail[]> {
    return http
      .get<ApiResponse<SubcontractorProjectDetail[]>>(`project-head/${projectID}/subcontractor-details`)
      .then(handleApiResponse);
  }

  static generateNewDebitItem(items: any[]): Promise<any> {
    const payload = Object.freeze({ items });
    return http.post<ApiResponse<any>>("debit-paym/generate-new-debit", payload).then(handleApiResponse);
  }

  static getOriginalVatValues(items: any[]): Promise<any> {
    const payload = Object.freeze({ items });
    return http.post<ApiResponse<any>>("general-incoming-invoice/original-vat-values", payload).then(handleApiResponse);
  }

  static updateCarRelationItems(relations: CarItemRelation[]): Promise<boolean> {
    return http
      .put(
        `car/car-item-relations/auto-update`, { relations }
      )
      .then(checkResponse);
  }

  static removeGeneralRect(id: number): Promise<any> {
    return http.delete<ApiResponse<any>>(`general-incoming-invoice/general-rect-delete/${id}`).then(handleApiResponse);
  }

  static updateGeneralRect(item: any): Promise<any> {
    return http.put<ApiResponse<any>>(`general-incoming-invoice/general-rect-update`, { item }).then(handleApiResponse);
  }

  static getPartnerRelationsWithSelectedPartnerRelation(partner: number, id: number): Promise<any> {
    console.log(partner);
    console.log(id);

    return http.get<ApiResponse<any>>(`general-incoming-invoice/partner-relation/${partner}/${id}`)
      .then(handleApiResponse);
  }

  static updatePartnerRelRelation(partnerRelation: any): Promise<any> {
    console.log(partnerRelation);
    return http.post<ApiResponse<any>>(`general-incoming-invoice/update-partner-relation/create`, Object.freeze({ partnerRelation })).then(handleApiResponse);
  }

  static checkVatReturn(vatDate: string, invoice: number): Promise<any> {
    return http.post<ApiResponse<number>>(`general-incoming-invoice/check-vat-return`, { vatDate, invoice }).then(handleApiResponse);
  }

  static checkDpaym(id: number, partnerId: number): Promise<boolean> {
    return http.get<ApiResponse<boolean>>(`general-incoming-invoice/check-dpaym-invoices`, { params: { id: id, partnerId: partnerId }, }).then(handleApiResponse);
  }

  static createSupplierRelation(partnerId: number): Promise<boolean> {
    return http.post<ApiResponse<boolean>>(`general-incoming-invoice/create-supplier-relation`, { partnerId }).then(handleApiResponse);
  }
}
