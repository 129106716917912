


































































































































































































import DateInput from "@/components/DateInput.vue";
import BankExtractImportItemsEdit from "../components/BankExtractImportItemsEdit.vue";
import SetIncorrectInvoice from "@/modules/invoiceFlow/components/SetIncorrectInvoice.vue";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import Confirmation from "@/components/Confirmation.vue";
import { ConfirmationMixin } from "@/mixins/confirmation";
import HeaderItem from "@/components/HeaderItem.vue";

import eventBus from "@/services/eventBus";
import store from "../services/BankExtractImportStore";

export default ConfirmationMixin.extend({
  components: {
    DateInput,
    SetIncorrectInvoice,
    BankExtractImportItemsEdit,
    ValidationError,
    MoneyDisplay,
    Confirmation,
    HeaderItem,
  },

  data: () => ({
    store,
    loaded: false,
    expanded: true,
  }),

  mixins: [ConfirmationMixin],

  async beforeMount() {
    this.store.reset();

    await Promise.all([
      this.store.load(Number(this.$route.params.headId)),
      this.store.loadItemTypes(),
      this.store.loadItemSubtypes(),
    ]);

    this.expanded = this.store.bankExtractImport.items.some((x) => x.expanded);
    this.loaded = true;
  },

  methods: {
    async prepareToImport() {
      const confirmed = await this.confirm(
        "Vannak olyan tételek, amelyeknek kitöltetlen a tétel típusa! Ezek a tételek nem adhatók fel! Biztosan folytatni akarja?"
      );
      if (!confirmed) return;

      const checked = await this.store.prepareCheck();

      if (checked != "") {
        const confirm2 = await this.confirm(checked);
        if (!confirm2) return;
      }

      await this.store.prepareToImport().then(async () => {
        await this.store.load(Number(this.$route.params.headId));
        eventBus.$emit("success", "Sikeres beemelés!");
      });
    },

    async update() {
      await this.store.update().then(async () => {
        await this.store.load(Number(this.$route.params.headId));
        eventBus.$emit("success", "Sikeres frissítés!");
      });
    },

    async setDeleted() {
      await this.store.setDeleted().then(async () => {
        await this.store.load(Number(this.$route.params.headId));
        eventBus.$emit("success", "Sikeres Törlés!");
      });
    },

    toggleExpanded() {
      this.expanded = !this.expanded;
      this.store.bankExtractImport.items.forEach((x) => (x.expanded = this.expanded));
    },
  },

  computed: {
    disabled(): boolean {
      return this.store.bankExtractImport.cannotEdit;
    },

    errorMessage(): string {
      if (this.store.bankExtractImport.extract == "") return "A kivonat megadása kötelező!";

      if (this.store.bankExtractImport.items.some((x) => x.partnerID === 1)) return "Van kitöltetlen Partner!";

      return "";
    },
  },
});
