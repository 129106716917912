export default interface DisplayStatus {
  name: string;
  readOnly: boolean;
  deleted: boolean;
  id: number;
}

export const displayStatusDefaultProps: DisplayStatus = {
  name: "Összes",
  readOnly: true,
  deleted: false,
  id: 1,
};
