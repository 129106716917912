var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{staticClass:"vatItems"},[[_c('v-col',{attrs:{"md":"12","align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.changeVatItem}},[_vm._v(" Új tétel ")])],1),_c('v-container',{staticStyle:{"width":"90%","margin":"auto"}},[_c('v-expand-transition',[(this.store.createVatItm)?_c('v-sheet',{attrs:{"elevation":"7"}},[_c('vat-breakdown')],1):_vm._e()],1)],1),_c('v-container',{class:{ active: _vm.retVatItem }},[_c('grid',{attrs:{"data-items":_vm.items,"columns":_vm.columns,"detail":"deletingTemplate","expand-field":'deleting'},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.changeDeleting(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}},{key:"DeletedTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"readonly":""},model:{value:(props.dataItem.deleted),callback:function ($$v) {_vm.$set(props.dataItem, "deleted", $$v)},expression:"props.dataItem.deleted"}})],1)]}},{key:"ValidFromTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('date-input',{attrs:{"disabled":true},model:{value:(props.dataItem.validFrom),callback:function ($$v) {_vm.$set(props.dataItem, "validFrom", $$v)},expression:"props.dataItem.validFrom"}})],1)]}},{key:"ValidToTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('date-input',{attrs:{"disabled":true},model:{value:(props.dataItem.validTo),callback:function ($$v) {_vm.$set(props.dataItem, "validTo", $$v)},expression:"props.dataItem.validTo"}})],1)]}},{key:"BackRateTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:props.className},[_c('v-switch',{attrs:{"dense":""},model:{value:(props.dataItem.backRate),callback:function ($$v) {_vm.$set(props.dataItem, "backRate", _vm._n($$v))},expression:"props.dataItem.backRate"}})],1)]}},{key:"deletingTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"yellowBg"},[_c('confirmation'),_c('div',{staticClass:"auth"},[_c('p',{staticClass:"conf"},[_vm._v(" Biztos hogy törli a következő elemet: "),_c('strong',[_vm._v(_vm._s(props.dataItem.name))]),_vm._v(" ? ")])]),_c('v-row',{staticClass:"btns"},[_c('v-btn',{staticClass:"con_btn",on:{"click":function($event){return _vm.confirmDelete(props.dataItem)}}},[_vm._v(" Megerősítés ")]),_c('v-btn',{staticClass:"canc_btn",on:{"click":function($event){return _vm.changeDeleting(props.dataItem)}}},[_vm._v(" Mégsem ")])],1)],1)]}}])})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }