import ApiResponse from "@/models/ApiResponse";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import { withBusyIndication } from "@/utils/busyIndication";
import ServiceItem from "./ServiceItem";
import ServiceItemRelation from "./ServiceItemRelation";

export const searchNewCars = withBusyIndication(
  (searchText: string, partnerId: number): Promise<ServiceItem[]> =>
    http
      .get<ApiResponse<ServiceItem[]>>(`subcontractor/search/new`, {
        params: {
          q: searchText,
          partnerId: partnerId,
        },
      })
      .then(handleApiResponse)
);

export const searchUsedCars = withBusyIndication(
  (searchText: string): Promise<ServiceItem[]> =>
    http
      .get<ApiResponse<ServiceItem[]>>(`subcontractor/search/used`, {
        params: {
          q: searchText,
        },
      })
      .then(handleApiResponse)
);

export const getCurrentCarItemRelations = withBusyIndication(
  (invItem: number): Promise<ServiceItemRelation[]> =>
    http.get<ApiResponse<ServiceItemRelation[]>>(`subcontractor/item-relations/${invItem}`).then(handleApiResponse)
);

export const searchCarItemRelations = withBusyIndication(
  (searchText: string, partner: number): Promise<ServiceItem[]> =>
    http
      .get<ApiResponse<ServiceItem[]>>(`subcontractor/new-item-relations/${partner}`, {
        params: { searchText: searchText },
      })
      .then(handleApiResponse)
);

export const deleteCarItemRelation = (relationId: number): Promise<boolean> =>
  http.post(`subcontractor/item-relations/delete/${relationId}`).then(checkResponse);

export const updateCarItemRelation = (relation: ServiceItemRelation): Promise<boolean> =>
  http.put(`subcontractor/item-relations/update/${relation.id}`, relation).then(checkResponse);

export const addCarItemRelation = (
  selectedItems: ServiceItem[],
  invoiceId: number,
  invoiceItemId: number
): Promise<boolean> =>
  http.post(`subcontractor/item-relations/add/${invoiceId}/${invoiceItemId}`, selectedItems).then(checkResponse);
