import ApiResponse from "@/models/ApiResponse";
import OverSummary from "../models/OverSummary";
import http, { handleApiResponse } from "@/services/http";

export default class OverSummaryApi {
    static getSummariesByPartner(partnerId: number): Promise<OverSummary[]> {
        return http
            .get<ApiResponse<OverSummary[]>>(`over-summary/${partnerId}`)
            .then(handleApiResponse);
    }

    static createOverSummary(items: OverSummary[], date: string): Promise<boolean> {
        const payload = Object.freeze({ items, date });
        return http
            .post<ApiResponse<boolean>>(`over-summary/create`, payload)
            .then(handleApiResponse)
    }
}