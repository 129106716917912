import { ActionLog } from "./../../actionLog/models/index";
import { GeneralIngoingInvoiceStore } from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceStore";
import { userTechProps } from "./../../partner/models/User";
import {
  invoiceAcceptRequestDefaultProps,
  invoiceControlRequestDefaultProps,
  InvoiceRequest,
} from "./../models/InvoiceRequest";
import IncomingInvoiceAccItem from "../models/IncomingInvoiceAccItem";
import PaymentInfoDisplay, { paymentInfoDisplayDefaultProps } from "./../models/PaymentInfoDisplay";
import { generalIngoinInvoiceDisplayDefaultProps } from "./../../generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import { paymentModeDisplayDefaultProps } from "./../../paymentMode/models/PaymentModeDisplay";
import { specialTaxDefaultProps } from "./../../partner/models/SpecialTax";
import { formatDateISO, trimDate } from "@/utils/formatDates";
import GeneralIngoingInvoiceApi from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import Tax, { taxDefaultProps } from "@/modules/generalIngoingInvoice/models/Tax";
import IncomingInvoiceTypeDisplay from "@/modules/incomingInvoice/models/IncomingInvoiceTypeDisplay";
import IncomingInvoiceTypeApi from "@/modules/incomingInvoice/services/IncomingInvoiceTypeApi";
import InvoiceGroupDisplay, {
  invoiceGroupDisplayDefaultProps,
} from "@/modules/invoiceGroup/models/InvoiceGroupDisplay";
import InvoiceGroupApi from "@/modules/invoiceGroup/services/InvoiceGroupApi";
import SpecialTax from "@/modules/partner/models/SpecialTax";
import User from "@/modules/partner/models/User";
import PartnerApi from "@/modules/partner/services/PartnerApi";
import { PaymentSchedule } from "../../invoicePaymentSchedule/models/PaymentSchedule";
import InvoiceFlowApi from "./InvoiceFlowApi";
import InvoiceFlowStatusRelation, {
  invoiceFlowStatusRelationDefaultProps,
} from "@/modules/invoiceFlowStatus/model/InvoiceFlowStatusRelation";
import PaymentModeDisplay from "@/modules/paymentMode/models/PaymentModeDisplay";
import { InvoiceAcceptRequest, InvoiceControlRequest } from "../models/InvoiceRequest";
import GeneralIngoinInvoiceDisplay from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoiceDisplay";
import ApprovalType from "../models/ApprovalType";
import busyIndication from "@/utils/busyIndication";
import { generalIngoingInvoiceDefaultProps } from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import Country, { countryDefaultProps } from "@/modules/cashPoint/models/Country";
import InvoiceAcceptState from "../models/InvoiceAcceptState";
import eventBus from "@/services/eventBus";
import Vue from "vue";
import { ApprovalMatrix, ApprovalRowType } from "../models/ApprovalMatrix";
import ApprovalMatrixHeadResponse from "../../approvalMatrix/models/ApprovalMatrixHeadResponse";
import ApprovalMatrixUser from "../../approvalMatrix/models/ApprovalMatrixUser";
import FlowHistory from "../models/FlowHistory";
import { InvoiceFlowUsers } from "./InvoiceFlowUsers";
import IncomingInvoiceRemark from "../models/IncomingInvoiceRemark";
import AccCode from "../models/AccCode";
import Dimension from "../models/Dimension";
import { InvoiceItem } from "@/modules/invoiceItems/models";
import AccInvSError from "../models/AccInvSError";
import AccItemDetailedType from "../models/AccItemDetailedType";
import { FixAssetType } from "../models/FixAsset";

import VatDivision from "../models/VatDivision";
import VatDivisionDisplay from "../models/VatDivisionDisplay";
import { EntityReference } from "@/models/EntityReference";

import InvoiceFlowTemplate, { invoiceFlowTemplateDefaultProps } from "../models/InvoiceFlowTemplate";
import InvoiceDpaym from "@/modules/generalIngoingInvoice/models/InvoiceDpaym";
import PaidInvoiceDpaym from "@/modules/generalIngoingInvoice/models/PaidInvoiceDpaym";
import GeneralIngoingInvoiceItemMin from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItemMin";
import GeneralIngoingInvoice from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";
import { PaymentScheduleApi } from "@/modules/invoicePaymentSchedule/services/api";

import * as ActionLogApi from "@/modules/actionLog/services/api";
import InvoiceRejectionReason from "../models/InvoiceRejectionReason";
import InvoiceRejection from "../models/InvoiceRejection";
import { PrecisionType, roundMoney } from "@/services/prices";
import UninvoicedStockCorrectionApi from "@/modules/uninvoicedStockCorrection/services/api";
import IncomingInvoiceSubtypeApi from "@/modules/incomingInvoice/services/IncomingInvoiceSubtypeApi";
import IncomingInvoiceSubtype from "../../incomingInvoice/models/IncomingInvoiceSubtype";
import InvoiceDetails, { invoiceDetailsDefaultProps } from "@/modules/generalIngoingInvoice/models/InvoiceDetails";
import dayjs from "dayjs";
import StoreInPaymentDate from "../models/StoreInPaymentDate";
import NotiDownPayEditModel from "@/modules/generalIngoingInvoice/models/NotiDownPayEditModel";
import AccountMixItem from "../models/AccountMixItem";
import configuration, { loadConfiguration } from "@/models/Configuration";
import SubcontractorProjectDetail from "@/modules/generalIngoingInvoice/models/SubcontractorProjectDetail";
import GeneralIngoingInvoiceItem from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItem";
import DivisionItem from "@/modules/invoiceGroup/models/DivisionItem";
import CarItemRelation from "@/modules/canNet/models/CarItemRelation";
import InvoiceImportItem from "@/modules/generalIngoingInvoice/models/InvoiceImportItem";

export enum FlowStatus {
  Recording = 2,
  FinancialRecording = 3,
  StockRelationRecording = 4,
  ControllingAccepting = 5,
  Accounting = 6,
}

export enum InvoicePreStatus {
  WrongItem = 6,
  Rejected = 7,
  Accepted = 8,
}

class DimensionApprovalSelection {
  acceptedDimensions: number[] = [];

  load(approvalRequest: InvoiceAcceptRequest) {
    this.acceptedDimensions = approvalRequest.dimensionApprovals.map((i) => i.dimensionID);
  }

  selectAll(dimensionIDs: number[]) {
    this.acceptedDimensions = [...dimensionIDs];
  }

  isSelected(dimensionID: number) {
    return this.acceptedDimensions.includes(dimensionID);
  }

  toggle(dimensionID: number) {
    if (this.acceptedDimensions.includes(dimensionID)) {
      this.acceptedDimensions = this.acceptedDimensions.filter((i) => i !== dimensionID);
    } else {
      this.acceptedDimensions = [...this.acceptedDimensions, dimensionID];
    }
  }
}

export class IncomingInvoiceFinancialPageValidator {
  constructor(
    protected invoice: GeneralIngoingInvoice,
    protected controllerRequests: InvoiceControlRequest[],
    protected acceptorRequests: InvoiceAcceptRequest[],
    protected ffControllerRequests: InvoiceControlRequest[],
    protected ffAcceptorRequests: InvoiceAcceptRequest[],
    protected notis: NotiDownPayEditModel[],
    protected invoiceDetails: InvoiceDetails,
  ) { }

  validate() {
    if (
      this.invoice.shipDateC &&
      (!this.invoice.accDateStart ||
        this.invoice.accDateStart == "" ||
        !this.invoice.accDate ||
        this.invoice.accDate == "")
    ) {
      return "Időszakos elszámolású ügylet esetén a dátumok megadása kötelező!";
    }

    if (
      this.controllerRequests.length < 1 &&
      this.acceptorRequests.length < 1 &&
      this.ffControllerRequests.length < 1 &&
      this.ffAcceptorRequests.length < 1
    ) {
      return "A számlához nem lett kijelölve Ellenőrző vagy Jóváhagyó.";
    }

    if (this.invoice.invCurrency !== "HUF" && this.invoiceDetails.partnerIsNative && this.invoice.currVat !== 0 && this.invoice.invVat === 0) {
      return "A számlán szereplő áfa nincs kitöltve!";
    }

    if (this.invoice.dpaym && this.notis.length > 0) {
      if (
        Math.abs(
          this.notis
            .map((x) => x.netto)
            .reduce((pv, cv) => {
              return pv + roundMoney(cv, this.invoice.invCurrency, this.priceHeadPrecision(this.invoice.invCurrency));
            }, 0) - this.invoice.currNetto
        ) > 5
      ) {
        return "A számla és az előlegbekérők bruttó összege nem egyezik meg.";
      }
    }

    return "";
  }

  priceHeadPrecision(currency: string) {
    if (configuration.operel) return 4;
    else
      return configuration.getCurrDigits(currency) !== undefined
        ? configuration.getCurrDigits(currency)?.geniHeadDigits ?? 0
        : 0;
  }
}

export class InvoiceFlowStore extends GeneralIngoingInvoiceStore {
  showResizer = true;
  step = 1;
  accountingTabItem = 1;
  isComponent = true;
  isCar = false;
  isSubcontractor = false;

  isEditingEnabled = true;
  flowStatusRelations: InvoiceFlowStatusRelation[] = [];
  invoiceDisplay: GeneralIngoinInvoiceDisplay = {
    ...generalIngoinInvoiceDisplayDefaultProps,
  };
  paymentInfo: PaymentInfoDisplay = { ...paymentInfoDisplayDefaultProps };
  paymentInfos: PaymentInfoDisplay[] = [];
  flowHistory: FlowHistory[] = [];

  selectedCurrency: Country | undefined = { ...countryDefaultProps };
  selectedAccGroup: InvoiceGroupDisplay | undefined = {
    ...invoiceGroupDisplayDefaultProps,
  };
  selectedTax: Tax | undefined = { ...taxDefaultProps };
  isInverseTax = false;
  selectedSpecTaxType: SpecialTax | undefined = { ...specialTaxDefaultProps };
  selectedPaymentMode: PaymentModeDisplay | undefined = {
    ...paymentModeDisplayDefaultProps,
  };

  users: User[] = [];
  types: IncomingInvoiceTypeDisplay[] = [];
  accGroups: InvoiceGroupDisplay[] = [];
  specTaxes: SpecialTax[] = [];
  paymentSchedule: PaymentSchedule = new PaymentSchedule();

  controllerRequests: InvoiceControlRequest[] = [];
  acceptorRequests: InvoiceAcceptRequest[] = [];

  currentUsersApproval: InvoiceAcceptRequest | null = null;
  currentUsersFullfilmentRequests: InvoiceRequest[] = [];
  dimensionSelection = new DimensionApprovalSelection();

  approvalMatrixHeads: ApprovalMatrixHeadResponse[] = [];
  approvalTypes: ApprovalType[] = [];

  flowRemarks: IncomingInvoiceRemark[] = [];
  actionLogs: ActionLog[] = [];

  uninvoicedDeliveries: AccountMixItem[] = [];

  acceptState: InvoiceAcceptState = {
    approvalRequests: [],
    controllRequests: [],
  };

  approvalMatrix = new ApprovalMatrix([]);
  approvalMatrixByUser: Record<number, ApprovalMatrix> = {};
  flowUsers = new InvoiceFlowUsers();

  canApproveToleranceDifference = false;

  accountItems: IncomingInvoiceAccItem[] = [];
  caroAccountItems: IncomingInvoiceAccItem[] = [];
  accCodes: AccCode[] = [];
  accInvSErrors: AccInvSError[] = [];
  accItemDetailedTypes: AccItemDetailedType[] = [];

  fixAssetTypes: FixAssetType[] = [];

  vatDivisions: VatDivision[] = [];
  vatDivisionsDisplay: VatDivisionDisplay[] = [];

  invoiceFlowTemplates: InvoiceFlowTemplate[] = [];
  invoiceFlowTemplate: InvoiceFlowTemplate = {
    ...invoiceFlowTemplateDefaultProps,
  };
  invoiceAccountingStatus = "";

  dpaymInvoices: InvoiceDpaym[] = [];
  paidDpayms: PaidInvoiceDpaym[] = [];
  invItemMins: GeneralIngoingInvoiceItemMin[] = [];
  hasNoti = false;
  notisOnInvoce: NotiDownPayEditModel[] = [];

  invoiceRejectionReasons: InvoiceRejectionReason[] = [];

  subtypes: IncomingInvoiceSubtype[] = [];
  selectedSubtype: IncomingInvoiceSubtype | undefined = undefined;

  storeInPaymentDates: StoreInPaymentDate[] = [];

  //Tételes előlegbeszámítás esetén a végszámla tétel azonosítója
  downPayTargetItem = 0;
  subcontractorProjectDetails: SubcontractorProjectDetail[] = [];

  newPartnerNewInvoice = false; // ez a változó "tiltja" a beforeMount lefutását amikor a user visszalép az új partner létrehozásából, hogy megőrizzük az eddig bevitt adatokat a formon
  invoicePaymentModes: PaymentModeDisplay[] = [];
  divisionItems: DivisionItem[] = [];
  navItems: InvoiceImportItem[] = [];
  partnerRelations: any[] = [];
  selectedPartnerRelation: any;

  constructor() {
    super();
  }

  reset() {
    this.invoice = { ...generalIngoingInvoiceDefaultProps };
    this.invoiceDetails = { ...invoiceDetailsDefaultProps };
    this.invoiceDisplay = { ...generalIngoinInvoiceDisplayDefaultProps };
    this.items = [];
    this.selectedSubtype = undefined;
  }

  async loadUninvoicedStockCorrectionSubtypes() {
    this.subtypes = await IncomingInvoiceSubtypeApi.getUninvoicedStockCorrectionSubtypes();
  }

  setIsEditingEnabled() {
    this.isEditingEnabled =
      ![InvoicePreStatus.Accepted, InvoicePreStatus.WrongItem].includes(this.invoice.preStatus) &&
      this.currentStatusID() < 5;
  }

  toDecimal(number: number, decimals = -1, precisionType?: PrecisionType) {
    return roundMoney(number, this.invoice?.invCurrency ?? "HUF", decimals > -1 ? decimals : undefined);
  }

  async changeInVatCount(items: GeneralIngoingInvoiceItem[]) {
    await InvoiceFlowApi.changeInVatCount(items);
  }

  getInvoice() {
    return this.invoice;
  }

  @busyIndication()
  async loadInvoice(invoiceId: number) {
    await this.loadInvoiceWithoutBusy(invoiceId);
  }

  async loadInvoiceWithoutBusy(invoiceId: number) {
    if (invoiceId > 1) {
      const result = await GeneralIngoingInvoiceApi.get(invoiceId);
      /* this.loadAccountItems(invoiceId);
      this.getAccInvSErrors(invoiceId); */

      this.invoiceAccountingStatus = result.accStatus;
      this.invoice = result.invoice;
      this.invoice.selectedProjectId = result.projectID;
      this.invoiceItems = result.items.map((x) => ({
        ...x,
        expanded: false,
      }));

      if (result.attachments.length > 0) {
        this.attachments = result.attachments;
        // assuming flow-invoice only has 1 geni pic file
        this.attachment = result.attachments[0];
        this.attachment.link = GeneralIngoingInvoiceApi.generateFileDownLoadLink(this.attachment.id);
      }

      //! -> moved Attachments into a Component

      this.invoice.insertDate = trimDate(this.invoice.insertDate) ?? "";
      this.invoice.insertTime = trimDate(this.invoice.insertTime) ?? "";
      this.invoice.vatDate = trimDate(this.invoice.vatDate) ?? "";
      this.invoice.payTerm = trimDate(this.invoice.payTerm) ?? "";
      this.invoice.invoiceDate = trimDate(this.invoice.invoiceDate) ?? "";
      this.invoice.shipDate = trimDate(this.invoice.shipDate);
      this.invoice.regDate = trimDate(this.invoice.regDate) ?? "";
      this.invoice.arrivDate = trimDate(this.invoice.arrivDate) ?? "";
      this.invoice.accDate = trimDate(this.invoice.accDate);
      this.invoice.accDateStart = trimDate(this.invoice.accDateStart);
      this.invoice.payDate = trimDate(this.invoice.payDate);
      this.availableActions = result.availableActions;
    } else {
      this.invoice = { ...generalIngoingInvoiceDefaultProps };
      this.invoiceItems = [];
    }

    await this.loadNavItems(invoiceId);
  }

  async loadDivisionItems() {
    await InvoiceGroupApi.getDivisionItems().then((dItems) => {
      this.divisionItems = dItems;
    });
  }

  async loadInvoicePaymentModes() {
    await GeneralIngoingInvoiceApi.getPaymentModes().then((pModes) => {
      this.invoicePaymentModes = pModes;
    });
  }

  async loadFixAssetTypes() {
    await InvoiceFlowApi.getFixAssetTypes().then((types) => {
      this.fixAssetTypes = types.find((t) => t.id == 1) ? types : [{ id: 1, name: "-" }, ...types];
    });
  }

  async getToleranceApprovalPermission(invoiceID: number, items: InvoiceItem[]) {
    await InvoiceFlowApi.getToleranceApprovalPermission(invoiceID, items).then((result) => {
      this.canApproveToleranceDifference = result;
    });
  }

  async loadAccountItems(invoiceId: number) {
    if (invoiceId <= 1) return;
    await InvoiceFlowApi.getAccountItems(invoiceId).then((result) => {
      result.forEach((x) => {
        if (x.accDate) {
          x.accDate = x.accDate.toString().split("T")[0];
        }
      });

      this.accountItems = result.map((x) => ({
        ...x,
        inEdit: false,
        expanded: false,
        dimensionExpanded: false,
        dim1Edit: false,
      }));
    });

    /*await InvoiceFlowApi.getCaroAccountItems(invoiceId).then((result) => {
      result.forEach((x) => {
        if (x.accDate) {
          x.accDate = x.accDate.toString().split("T")[0];
        }
      });

      this.caroAccountItems = result.map((x) => ({
        ...x,
        inEdit: false,
        expanded: false,
        dimensionExpanded: false,
        dim1Edit: false,
      }));
    });*/

    await InvoiceFlowApi.getAccountCodes(invoiceId, false).then((result) => {
      this.accCodes = result;
    });
  }

  async saveAccountItems() {
    await InvoiceFlowApi.saveAccountItems(this.invoice.id, this.accountItems);
  }

  async loadAccCodes(invoiceId: number, isStatistic = true) {
    await InvoiceFlowApi.getAccountCodes(invoiceId, isStatistic).then((result) => {
      this.accCodes = result;
    });
  }

  async getInvoiceShippingPhaseDate() {
    return await InvoiceFlowApi.getInvoiceShippingPhaseDate(dayjs(this.invoice.vatDate).format("YYYY-MM-DD"));
  }

  async getAccItemDetailedTypes() {
    this.accItemDetailedTypes = await InvoiceFlowApi.getAccItemDetailedType();
    console.log(this.accItemDetailedTypes);
  }

  async getAccInvSErrors(invoiceID: number) {
    if (invoiceID <= 1) return;
    this.accInvSErrors = await InvoiceFlowApi.getAccInvSErrors(invoiceID);
  }

  async updateAccountItem(item: IncomingInvoiceAccItem) {
    return await InvoiceFlowApi.updateAccountItem(item);
  }

  async updateAccountItems(invoiceID: number, items: IncomingInvoiceAccItem[]) {
    return await InvoiceFlowApi.updateAccountItems(invoiceID, items);
  }

  async createAccountItem(item: IncomingInvoiceAccItem) {
    return await InvoiceFlowApi.createAccountItem(item, this.invoice.id);
  }

  async deleteAccountItem(itemID: number) {
    return await InvoiceFlowApi.deleteAccountItem(itemID);
  }

  async loadInvoiceAndItems(invoiceId: number) {
    if (invoiceId <= 1) return;

    await GeneralIngoingInvoiceApi.get(invoiceId).then((result) => {
      this.invoice = result.invoice;
      this.invoice.selectedProjectId = result.projectID;
      this.invoiceItems = result.items.map((x) => ({
        ...x,
        expanded: false,
      }));

      this.invoice.payTerm = trimDate(this.invoice.payTerm) ?? "";
      this.invoice.invoiceDate = trimDate(this.invoice.invoiceDate) ?? "";
      this.invoice.shipDate = trimDate(this.invoice.shipDate);
      this.invoice.regDate = trimDate(this.invoice.regDate) ?? "";
      this.invoice.arrivDate = trimDate(this.invoice.arrivDate) ?? "";
      this.invoice.accDate = trimDate(this.invoice.accDate);
      this.invoice.accDateStart = this.trimDateTime(this.invoice.accDateStart);
      this.invoice.payDate = this.trimDateTime(this.invoice.payDate);
    });
  }


  async loadNavItems(invoiceId: number) {
    if (invoiceId <= 1) return;

    await InvoiceFlowApi.getNavItems(invoiceId).then(result => {
      this.navItems = result;
    });
  }

  async updateInvoiceRecording(saveOnly: boolean) {
    if (!this.invoice) return 0;

    let id = this.invoice.id;
    if (this.invoice.id > 1) {
      await InvoiceFlowApi.updateFlowRecording(this.invoice, saveOnly);
      return id;
    }

    if (this.invoice.type > 1 && this.invoice.subType > 1 && this.selectedSubtype?.isUninvoicedStockCorrection) {
      id = await UninvoicedStockCorrectionApi.create(this.invoice, []);
    } else {
      id = await InvoiceFlowApi.createFlow(this.invoice);
    }

    await Promise.all([
      this.createFlowStatusRelation(1, id),
      this.loadFlowStatusRelations(id),
      this.loadFlowActionLogs(id),
    ]);

    return id;
  }

  setSelectedPaymentMode() {
    if (this.invoice.paymode != 1) {
      this.selectedPaymentMode = this.paymentModes.find((x) => x.id == this.invoice.paymode);
      this.invoice.paymode = this.selectedPaymentMode?.id ?? 1;
    } else {
      this.selectedPaymentMode = this.paymentModes.find((x) => x.id == this.invoiceDetails.partnerPaymentMethodId);
      this.invoice.paymode = this.selectedPaymentMode?.id ?? 1;
    }

    if (!this.selectedPaymentMode && this.paymentModes.length > 0) {
      this.selectedPaymentMode = this.paymentModes[0];
      this.invoice.paymode = this.selectedPaymentMode.id;
    }
  }

  async loadApprovalMatrix() {
    const { rows } = await InvoiceFlowApi.getApprovalMatrixData().then((x) => {
      return x;
    });

    this.approvalMatrix = new ApprovalMatrix(
      rows.filter((i: any) => i.type === ApprovalRowType.IncomingInvoiceApprove)
    );
  }

  async updateFlow(isOtherItemRelation: boolean, saveOnly: boolean) {
    if (!this.invoice) return 0;
    let id = this.invoice.id;
    const invoiceItems = this.invoiceItems.map((x) => x.incomingInvoiceItem);

    if (this.invoice.id > 1) {
      this.checkInvoiceCurrency(this.invoice);
      if (isOtherItemRelation) await InvoiceFlowApi.updateFinancialRecording(this.invoice, invoiceItems, saveOnly);
      else await InvoiceFlowApi.updateFinancialRecording(this.invoice, [], saveOnly);
    } else id = await InvoiceFlowApi.create(this.invoice, invoiceItems);
    return id;
  }

  async updateCarRelationItems(carItemRelations: CarItemRelation[]) {
    await InvoiceFlowApi.updateCarRelationItems(carItemRelations);
  }

  checkInvoiceCurrency(invoice: GeneralIngoingInvoice) {
    invoice.currency =
      invoice.currency.trim() === ""
        ? invoice.invCurrency.trim() === ""
          ? this.selectedCurrency?.currency ?? "HUF"
          : invoice.invCurrency
        : invoice.currency;
    invoice.invCurrency =
      invoice.invCurrency.trim() === ""
        ? invoice.currency.trim() === ""
          ? this.selectedCurrency?.currency ?? "HUF"
          : invoice.currency
        : invoice.invCurrency;
    if (invoice.invCurrency.trim() === "" || invoice.currency.trim() === "") {
      console.log(
        `Hiba: Mentéskor a számla invCurrency-je: ${invoice.invCurrency} és a currency: ${invoice.currency}!`
      );
      eventBus.$emit("error", "Hiba történt mentés közben, mert a számla pénzneme üres!");
    }
  }

  async loadInvoiceDisplay(invoiceId: number) {
    this.invoiceDisplay = await InvoiceFlowApi.getInvoiceDisplay(invoiceId);
  }

  @busyIndication()
  async loadPaymentInfo(invoiceId: number) {
    this.paymentInfo = await InvoiceFlowApi.getPaymentInfo(invoiceId);
  }

  async loadPaymentInfos(invoiceId: number) {
    this.paymentInfos = await InvoiceFlowApi.getPaymentInfos(invoiceId);
  }

  fillControllerApproverRequests(invoiceId: number) {
    // eslint-disable-next-line
    function newRequest(u: ApprovalMatrixUser, invoiceId: number, props: any, isFF = false) {
      return {
        ...props,
        name: u.name,
        controllerId: u.userId,
        acceptorId: u.userId,
        invId: invoiceId,
        remark: "Automatikus betöltés",
        isFullFillReq: isFF,
      };
    }

    for (const x of this.approvalMatrixHeads) {
      for (const u of x.fullfillControllerUsers) {
        this.controllerRequests.push(newRequest(u, invoiceId, invoiceControlRequestDefaultProps, true));
      }
      for (const u of x.fullfillApproverUsers) {
        this.acceptorRequests.push(newRequest(u, invoiceId, invoiceAcceptRequestDefaultProps, true));
      }
      for (const u of x.controllerUsers) {
        this.controllerRequests.push(newRequest(u, invoiceId, invoiceControlRequestDefaultProps));
      }
      for (const u of x.approverUsers) {
        this.acceptorRequests.push(newRequest(u, invoiceId, invoiceAcceptRequestDefaultProps));
      }
    }
  }

  setSpecTax(init = false) {
    this.selectedSpecTaxType = this.specTaxes.find((x) => x.id == this.invoice.specTaxType);
    if (!this.selectedSpecTaxType && this.specTaxes.length > 0) {
      this.selectedSpecTaxType = this.specTaxes[0];
      // this.invoice.specTaxType = this.selectedSpecTaxType.id;
    }

    if (
      this.invoiceDetails.partnerIsSpecTax &&
      this.invoice.specTaxType == 1 &&
      this.invoice.specTaxType != this.invoiceDetails.partnerSpecTaxType
    ) {
      this.invoice.specTax = true;
      this.invoice.specTaxType = this.invoiceDetails?.partnerSpecTaxType ?? 1;
      this.invoice.tax = this.invoiceDetails.tax;

      this.selectedSpecTaxType = this.specTaxes.find((x) => x.id == this.invoice.specTaxType);
      if (!this.selectedSpecTaxType && this.specTaxes.length > 0) {
        this.selectedSpecTaxType = this.specTaxes[0];
        this.invoice.specTaxType = this.selectedSpecTaxType.id;
      }
    } else {
      if (this.invoice.id <= 1) {
        this.invoice.specTax = false;
        this.invoice.specTaxType = 1;
        this.invoice.tax = this.invoiceDetails.defaultTax;
      }
    }

    this.setSelectedTax();

    if (!init || this.invoice.id == 0) {
      this.selectedTaxChanged();
    }
  }

  selectedTaxChanged() {
    this.invoice.tax = this.selectedTax?.id ?? 1;

    if (this.selectedTax) {
      this.isInverseTax = this.selectedTax.isInverseTax;

      if (this.isInverseTax) {
        const next = new Date(this.invoice.shipDate as string);
        next.setMonth(next.getMonth() + 1);
        next.setDate(15);
        const arr = [next, new Date(this.invoice.arrivDate)].sort((a, b) => a.getTime() - b.getTime());
        this.invoice.vatDate = dayjs(arr[0]).format("YYYY-MM-DD");
      }

      //! DLMP387/1 - 17
      // this.invoice.currVat = this.toDecimal(this.invoice.currNetto * (this.selectedTax.degree / 100));
      this.invoice.currVat =
        this.invoice.currTotal - this.toDecimal(this.invoice.currTotal / (1 + this.selectedTax.degree / 100));
      if (!configuration.isLoaded) loadConfiguration();

      this.invoice.invVat = roundMoney(
        this.invoice.currVat * this.invoice.exchangeRate,
        "HUF",
        undefined,
        PrecisionType.geniHead
      );
    } else {
      this.invoice.currVat = 0;
      this.invoice.invVat = 0;
    }

    //! DLMP387/1 - 17
    this.invoice.currNetto = this.invoice.currTotal - this.invoice.currVat;
  }

  setSelectedTax() {
    const taxes = this.taxes.filter(
      (x) => (this.invoice.specTax && x.specTax) || (!this.invoice.specTax && !x.specTax)
    );
    this.selectedTax = taxes.find((x) => x.id == this.invoice.tax);
    if (!this.selectedTax && taxes.length > 0) {
      this.selectedTax = taxes.find((x) => x.degree == 27) ?? taxes[0];
      this.invoice.tax = this.selectedTax.id;
    }
  }

  setSelectedCurrency() {
    this.selectedCurrency = this.currencies.find(
      (x) => x.currency.toUpperCase().trim() == this.invoice.invCurrency.toUpperCase().trim()
    );
    if (!this.selectedCurrency && this.currencies.length > 0) {
      this.selectedCurrency = this.currencies[0];
      this.invoice.invCurrency = this.selectedCurrency?.currency.toUpperCase().trim() ?? "";
    }
  }

  async selectedCurrencyChanged() {
    this.invoice.invCurrency = this.selectedCurrency?.currency?.toUpperCase()?.trim() ?? this.invoice.invCurrency;
    /* this.invoice.currency = this.invoice.invCurrency; */
    await this.currencyChanged();
  }

  async currencyChanged() {
    await this.loadRate().then(() => {
      this.nettoChanged();
    });
  }

  async loadRate() {
    if (this.invoice.invCurrency.toUpperCase() == "HUF") {
      this.invoice.exchangeRate = 1;
      this.invoice.rateDate = formatDateISO(new Date());
      this.invoice.netto = this.invoice.currNetto * this.invoice.exchangeRate;
      return;
    }

    // if (this.invoice.id < 2) return;

    try {
      console.log("Getting currency rate for invoice from api...");
      await InvoiceFlowApi.getInvoiceCurrencyRateForInvoice(this.invoice).then((result) => {
        console.log("Currency rate respone is " + result.exchangeRate);
        this.invoice.exchangeRate = result.exchangeRate;
        this.invoice.vatDate = formatDateISO(result.vatDate);
        this.invoice.rateDate = formatDateISO(result.rateDate);
        // this.invoice.invoiceDate = formatDateISO(result.invoiceDate);
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  async loadPartnerPayTermWithDate(partnerID: number, invoiceDate: string) {
    const { payTerm } = await InvoiceFlowApi.getPartnerPayTerm(partnerID, invoiceDate);
    return dayjs(payTerm).format("YYYY-MM-DD");
  }

  async loadPartnerPayTerm(partnerID: number) {
    const { payTerm } = await InvoiceFlowApi.getPartnerPayTerm(partnerID, this.invoice.invoiceDate);
    return dayjs(payTerm).format("YYYY-MM-DD");
  }

  private setCalculatedVATs() {
    this.invoice.currVat = this.toDecimal(this.invoice.currTotal - this.invoice.currNetto);

    this.invoice.vat = this.toDecimal(this.invoice.total - this.invoice.netto);

    if (!configuration.isLoaded) loadConfiguration();

  }

  // Default 27% VAT
  nettoChanged(calcTotal = false, calcVat = true) {
    this.invoice.currNetto = this.toDecimal(this.invoice.currNetto);
    const taxRate = 1.0 + (this.selectedTax?.degree ?? 27.0) / 100.0;

    this.invoice.currTotal = calcTotal
      ? this.toDecimal(this.invoice.currNetto * taxRate)
      : this.toDecimal(this.invoice.currTotal);

    if (calcVat) this.setCalculatedVATs();
  }

  totalChanged(calcNetto = false) {
    this.invoice.currTotal = this.toDecimal(this.invoice.currTotal);
    const taxRate = 1.0 + (this.selectedTax?.degree ?? 27.0) / 100.0;

    this.invoice.currNetto = calcNetto
      ? this.toDecimal(this.invoice.currTotal / taxRate)
      : this.toDecimal(this.invoice.currNetto);

    this.setCalculatedVATs();
  }

  async loadFlowStatusRelations(invoiceId: number | undefined) {
    if (invoiceId == null) return;
    this.flowStatusRelations = await InvoiceFlowApi.getFlowStatusRelations(invoiceId);
  }

  async resetFlowStatusRelations(invoiceId: number) {
    if (invoiceId == null) return;
    await InvoiceFlowApi.resetFlowStatusRelations(invoiceId);
  }

  async generateDownPaymentInvoiceItems(invoiceId: number | undefined) {
    if (!invoiceId) return;
    await InvoiceFlowApi.createDownPaymentInvoiceItems(invoiceId);
  }

  async deleteDownPaymentInvoiceItems(invoiceId: number | undefined) {
    if (!invoiceId) return;
    await InvoiceFlowApi.deleteDownPaymentInvoiceItems(invoiceId);
  }

  async createFlowStatusRelation(statusId: number, invoiceId: number | undefined) {
    if (statusId == 0 || invoiceId == null) return;

    const relation: InvoiceFlowStatusRelation = {
      ...invoiceFlowStatusRelationDefaultProps,
      invId: invoiceId,
      statusId: statusId,
    };
    await InvoiceFlowApi.manageFlowStatusRelations(relation);
  }

  async updateFlowStatusRelation(relation: InvoiceFlowStatusRelation) {
    await InvoiceFlowApi.manageFlowStatusRelations(relation);
  }

  async deleteFlowStatusRelation(statusId: number) {
    await InvoiceFlowApi.deleteFlowStatusRelation(this.invoice.id, statusId).then(() => {
      const index = this.flowStatusRelations.findIndex((x) => x.statusId == statusId);
      if (index > -1) {
        this.flowStatusRelations.splice(index, 1);
      }
    });
  }

  currentStatusID() {
    if (this.invoice.accStatus == 6) {
      return 6;
    }
    return Math.max(...this.flowStatusRelations.map((x) => x.statusId), 0);
  }

  currentStatusName() {
    if (this.invoice.preStatus == InvoicePreStatus.Rejected) {
      return "Elutasítva";
    }
    if (this.invoice.preStatus == InvoicePreStatus.WrongItem) {
      return "Hibás rögzítés";
    }

    switch (this.currentStatusID()) {
      case 2:
        return "Iktatva";
      case 3:
        return "Pénzügyileg rögzítve";
      case 4:
        return "Teljesítés ellenőrizve/jóváhagyva";
      case 5:
        return "Ellenőrizve/jóváhagyva";
      case 6:
        return "Könyvelve";
      default:
        return "Összeállítás alatt";
    }
  }

  currentStatusColor() {
    if (this.invoice.preStatus == InvoicePreStatus.WrongItem || this.invoice.preStatus == InvoicePreStatus.Rejected) {
      return "status-overpaid";
    }

    switch (this.invoice.status) {
      default:
      case 1:
      case 2:
        return "status-progress";
      case 3:
        return "status-issued";
      case 4:
        return "status-partially";
      case 5:
      case 7:
        return "status-paid";
      case 6:
        return "status-overpaid";
    }
  }

  async loadAccContrUsers() {
    this.users = await InvoiceFlowApi.getAcceptorAndControlUsers();
  }

  async loadUsersWithTechRecord() {
    await PartnerApi.getUsers().then((users) => {
      this.users = users.find((u) => u.id == 1) ? users : [userTechProps, ...users];
    });
  }

  async loadUsers() {
    this.users = await PartnerApi.getUsers();
  }

  async loadInvoiceTypes() {
    this.types = await IncomingInvoiceTypeApi.getAll();
  }

  async loadSubTypes() {
    this.subTypes = await GeneralIngoingInvoiceApi.getIncomingSubtypes();
  }

  async loadInvoiceGroups() {
    this.accGroups = await InvoiceGroupApi.getAll();
  }

  async loadSpecialTaxTypes() {
    this.specTaxes = await PartnerApi.getSpecialTaxes();
  }

  // #region PaymentSchedule
  async managePaymentScheduleAndItems(prefix = "GENI") {
    if (!this.paymentSchedule || (this.paymentSchedule.id == 0 && this.paymentSchedule.items.length == 0)) return;

    await PaymentScheduleApi.managePaymentScheduleAndItems(prefix, this.paymentSchedule).then(
      (result) =>
      (this.paymentSchedule = PaymentScheduleApi.setPaymentSchedule(
        result,
        this.partBanks,
        this.paymentModes,
        this.users
      ))
    );
  }
  // #endregion

  async loadControllerRequests(invoiceId: number) {
    this.controllerRequests = (await InvoiceFlowApi.getControlRequests(invoiceId)).map((x) => ({
      ...x,
      minAmount: 0,
      maxAmount: 0,
      deleting: false,
    }));
  }

  async loadAcceptorRequests(invoiceId: number) {
    this.acceptorRequests = (await InvoiceFlowApi.getAcceptRequests(invoiceId)).map((x) => ({
      ...x,
      minAmount: 0,
      maxAmount: 0,
      deleting: false,
    }));

    this.currentUsersApproval = await InvoiceFlowApi.getCurrentUsersApproval(invoiceId);
    if (this.currentUsersApproval) {
      this.dimensionSelection.load(this.currentUsersApproval);
    }

    this.currentUsersFullfilmentRequests = await InvoiceFlowApi.getCurrentUsersFullfilmentRequests(invoiceId);
    this.loadApprovalMatrixByUsers();
  }

  async loadApprovalMatrixByUsers() {
    const approverUserIDs = [...new Set(this.acceptorRequests.map((i) => i.acceptorId))];
    const result: Record<number, ApprovalMatrix> = {};

    for (const userID of approverUserIDs) {
      const { rows } = await InvoiceFlowApi.getApprovalMatrixDataByUser(userID);
      result[userID] = new ApprovalMatrix(rows.filter((i) => i.type === ApprovalRowType.IncomingInvoiceApprove));
    }

    this.approvalMatrixByUser = result;
  }

  // @busyIndication()
  async updateControlRequests(emailCheck = true) {
    await InvoiceFlowApi.updateControlRequests(this.invoice.id, this.controllerRequests).then((result) => {
      this.controllerRequests = result.requests.map((x) => ({
        ...x,
        minAmount: 0,
        maxAmount: 0,
        deleting: false,
      }));
      if (emailCheck) {
        this.missingEmailCheck(result.usersWithoutEmail);
      }
    });
  }

  // @busyIndication()
  async updateAcceptorRequests(emailCheck = true) {
    await InvoiceFlowApi.updateAcceptRequests(this.invoice.id, this.acceptorRequests).then((result) => {
      this.acceptorRequests = result.requests.map((x) => ({
        ...x,
        minAmount: 0,
        maxAmount: 0,
        deleting: false,
      }));
      if (emailCheck) {
        this.missingEmailCheck(result.usersWithoutEmail);
      }
    });
  }

  missingEmailCheck(users: string | null) {
    if (!users || users == "") return;
    eventBus.$emit("warn", `Az alábbi felhasználóknak nincs rögzítve az e-mail címe!</br><strong>${users}</strong>`);
  }

  async toleranceDelegate() {
    return InvoiceFlowApi.toleranceDelegate(this.invoice.id).then((result) => {
      return result;
    });
  }

  async delegateToToleranceApprover() {
    return InvoiceFlowApi.delegateToToleranceApprover(this.invoice.id).then((result) => {
      return result;
    });
  }

  @busyIndication()
  async deleteControllerRequest(id: number) {
    await InvoiceFlowApi.deleteControlRequest(id);
  }

  async createControllerRequest(invContrReq: InvoiceControlRequest) {
    //unused
    try {
      await InvoiceFlowApi.createControlRequest(invContrReq).then((ret) => {
        if (ret) {
          //invContrReq.id = ret.id;
          invContrReq.insDate = ret.insDate;
          invContrReq.modDate = ret.modDate;
          invContrReq.modTime = ret.modTime;
          this.controllerRequests.unshift(invContrReq);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  async updateFulFillControlRequestStatus(invapprStat: number) {
    await InvoiceFlowApi.updateFulFillControlRequestStatus(this.invoice.id, invapprStat).then(async (success) => {
      if (success) {
        await this.loadAcceptState();
      }
    });
  }

  async updateFulFillAcceptRequestStatus(invapprStat: number) {
    await InvoiceFlowApi.updateFulFillAcceptRequestStatus(this.invoice.id, invapprStat).then(async (success) => {
      if (success) {
        await this.loadAcceptState();
      }
    });
  }

  async updateAcceptRequestStatus(invapprStat: number) {
    await InvoiceFlowApi.updateAcceptRequestStatus(this.invoice.id, invapprStat, this.invoice).then(async (success) => {
      if (success) {
        if (invapprStat == 4) {
          eventBus.$emit("success", "Sikeres mentés!");
        } else {
          const approval = !!this.currentUsersApproval;
          const action = approval ? "jóváhagyás" : "ellenőrzés";
          eventBus.$emit("success", `Sikeres számla ${action}!`);
        }

        await this.loadAcceptState();
      }
    });
  }

  @busyIndication()
  async deleteAcceptorRequest(id: number) {
    await InvoiceFlowApi.deleteAcceptRequest(id);
  }

  async createAcceptorRequest(invAccReq: InvoiceAcceptRequest) {
    //unused
    try {
      await InvoiceFlowApi.createAcceptRequest(invAccReq).then((ret) => {
        if (ret) {
          //invAccReq.id = ret.id;
          invAccReq.insDate = ret.insDate;
          invAccReq.modDate = ret.modDate;
          invAccReq.modTime = ret.modTime;
          this.acceptorRequests.unshift(invAccReq);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  async loadApprovalMatrixHeadsByPartner(partnerId: number) {
    this.approvalMatrixHeads = await InvoiceFlowApi.getApprovalMatrixHeadsByPartner(partnerId);
  }

  async loadApprovalTypes() {
    this.approvalTypes = await InvoiceFlowApi.getApprovalTypes();
  }

  @busyIndication()
  async loadApprovalMatrixHeads() {
    await InvoiceFlowApi.getApprovalMatrixHeads().then((result) => {
      this.approvalMatrixHeads = result.map((h) => ({
        ...h,
        expanded: false,
        currencyDisabled:
          result.filter(
            (y) =>
              (h.partner == 1 && y.accInvGroup == h.accInvGroup && !y.deleted) ||
              (h.accInvGroup == 1 && y.partner == h.partner && !y.deleted)
          ).length > 1,
        ...this.setMatrixHeadSelectedUsers(h),
      }));
    });
  }

  private setMatrixHeadSelectedUsers(h: ApprovalMatrixHeadResponse) {
    function setSelectedUser(users: ApprovalMatrixUser[]) {
      return users.map((u) => ({ ...u, selectedUser: u.name }));
    }
    return {
      approverUsers: setSelectedUser(h.approverUsers),
      controllerUsers: setSelectedUser(h.controllerUsers),
      fullfillApproverUsers: setSelectedUser(h.fullfillApproverUsers),
      fullfillControllerUsers: setSelectedUser(h.fullfillControllerUsers),
    };
  }

  async updateApprovalMatrix() {
    for (let h of this.approvalMatrixHeads) {
      h = { ...h, ...this.setMatrixHeadSelectedUsers(h) };
    }

    await InvoiceFlowApi.updateApprovalMatrixHeads(this.approvalMatrixHeads).then((result) => {
      this.approvalMatrixHeads = result.map((h) => ({
        ...h,
        expanded: false,
        currencyDisabled:
          result.filter(
            (y) =>
              (h.partner == 1 && y.accInvGroup == h.accInvGroup && !y.deleted) ||
              (h.accInvGroup == 1 && y.partner == h.partner && !y.deleted)
          ).length > 1,
        ...this.setMatrixHeadSelectedUsers(h),
      }));
      eventBus.$emit("success", "Jóváhagyási mátrix mentése sikeres!");
    });
  }

  async loadAcceptState(invoiceId = this.invoice.id) {
    this.acceptState = await InvoiceFlowApi.getInvoiceAcceptState(invoiceId);
  }

  isInvoiceItemApproved(invoiceItemID: number) {
    const isStockRelatedItem =
      this.invoiceItems?.some(
        (i) =>
          i.incomingInvoiceItem.id === invoiceItemID &&
          (i.incomingInvoiceItem.stock > 1 ||
            i.incomingInvoiceItem.serviceId > 1 ||
            i.incomingInvoiceItem.name == "Kerekítés")
      ) ?? false;

    if (isStockRelatedItem) {
      return true;
    }

    if (!this.currentUsersApproval) {
      return false;
    }

    return this.currentUsersApproval.itemApprovals.some((a) => a.invoiceItemID === invoiceItemID);
  }

  isDimensionApproved(dimensionID: number) {
    if (!this.currentUsersApproval) {
      return false;
    }

    return this.dimensionSelection.isSelected(dimensionID);
  }

  private async refreshApprovalState(invoiceID: number) {
    await this.loadAcceptorRequests(invoiceID);
  }

  async loadFlowHistory(invNo: number) {
    this.flowHistory = (await InvoiceFlowApi.getFlowHistory(invNo)).map((h) => new FlowHistory(h));
  }

  async loadRemarks(invNo: number) {
    this.flowRemarks = (await InvoiceFlowApi.getRemarkHistory(invNo)).map((r) => new IncomingInvoiceRemark(r));
  }

  async loadFlowActionLogs(invoiceID: number) {
    this.actionLogs = (await ActionLogApi.getInvoiceFlowLogs(invoiceID)).map((l) => new ActionLog(l));
  }

  async createRemark(remark: IncomingInvoiceRemark) {
    try {
      await InvoiceFlowApi.createRemark(remark);
    } catch (error) {
      console.log(error);
    }
  }

  async createInvoiceRemark(invNo: number, remark: string) {
    try {
      await InvoiceFlowApi.createInvoiceRemark(invNo, remark);
    } catch (error) {
      console.log(error);
    }
  }

  // Dimenzió jóváhagyás (pipa) állítása
  async toggleDimensionApproval(dimensionID: number) {
    if (this.currentUsersApproval) {
      this.dimensionSelection.toggle(dimensionID);
    }
  }

  // Minden a felhasználó számára jóváhagyható költséghelyet jóváhagyunk
  async approvePartially(id: number) {
    const dimensions = await InvoiceFlowApi.getInvoiceDimensions(id);
    const approvableDimensions = dimensions
      .filter((d) => d.invGroupNet != 0 && d.invGroup > 1)
      .filter((d) => this.approvalMatrix.canApproveInvGroup(d))
      .map((d) => d.id);

    this.dimensionSelection.selectAll(approvableDimensions);
    return this.approveSelection();
  }

  // A kiválasztott költséghelyeket jóváhagyjuk
  async approveSelection() {
    const invoiceID = this.invoice.id;

    await InvoiceFlowApi.updateApproval(invoiceID, this.dimensionSelection.acceptedDimensions, this.invoice);
    await this.loadAcceptState();
    await this.loadAcceptorRequests(invoiceID);
    await this.loadControllerRequests(invoiceID);
    await this.loadFlowStatusRelations(invoiceID);
  }

  updateAccItemDimension(dimension: Dimension, headID: number) {
    return InvoiceFlowApi.updateAccItemDimension(dimension, headID);
  }

  createAccItemDimension(dimension: Dimension, headID: number) {
    return InvoiceFlowApi.createAccItemDimension(dimension, headID);
  }

  deleteAccItemDimension(projectDimID: number, resourceDimID: number, carDimID: number) {
    return InvoiceFlowApi.deleteAccItemDimension(projectDimID, resourceDimID, carDimID);
  }

  preAccounting(invID: number) {
    return InvoiceFlowApi.preAccounting(invID);
  }

  cancelAccounting(invID: number) {
    return InvoiceFlowApi.cancelAccounting(invID);
  }

  postAccounting(invID: number) {
    return InvoiceFlowApi.postAccounting(invID);
  }

  // Delegálás
  // további jóváhagyó részére
  async delegateToAdditionalApprover(user: EntityReference) {
    const isAlreadyDelegated = this.acceptorRequests.some((r) => !r.isFullFillReq && r.acceptorId === user.id);

    if (isAlreadyDelegated) {
      eventBus.$emit("error", `A jóváhagyás már delegálva lett: ${user.name} részére!`);
      return;
    }

    const newRequest = { ...invoiceAcceptRequestDefaultProps };
    newRequest.invId = this.invoice.id;
    newRequest.acceptorId = user.id;

    this.acceptorRequests.push(newRequest);

    await this.updateAcceptorRequests();
    await this.loadAcceptState();

    eventBus.$emit("Sikeres jóváhagyás delegálás!");
  }

  async createInvoiceFlowTemplate() {
    try {
      await InvoiceFlowApi.createInvoiceFlowTemplate(this.invoiceFlowTemplate);
      this.invoiceFlowTemplate = { ...invoiceFlowTemplateDefaultProps };
      this.invoiceFlowTemplate.template.items = [];
      this.invoiceFlowTemplate.template.acceptorIds = [];
      this.invoiceFlowTemplate.template.controllerIds = [];
      this.invoiceFlowTemplate.template.documentIds = [];
    } catch (error) {
      console.log(error);
    }
  }

  @busyIndication()
  async loadInvoiceFlowTemplatesByPartnerId(partnerId: number) {
    this.invoiceFlowTemplates = (await InvoiceFlowApi.loadInvoiceFlowTemplatesByPartnerId(partnerId)).map((i) => ({
      ...i,
      expanded: false,
    }));
  }

  async loadInvoiceFlowTemplate(templateId: number, invNo: number) {
    this.invoiceFlowTemplate = await InvoiceFlowApi.loadInvoiceFlowTemplate(templateId, invNo);
  }

  @busyIndication()
  async getDpaymInvoicesByPartner(searchText: string, isOrder: boolean) {
    this.dpaymInvoices = await InvoiceFlowApi.loadDpaymInvoicesByPartner(this.invoice.partner, searchText, isOrder);
  }

  async loadInvItemMins(invId: number) {
    this.invItemMins = await InvoiceFlowApi.loadInvItemMins(invId);
  }

  async addDownPay(item: InvoiceDpaym) {
    try {
      await InvoiceFlowApi.createDownPay(item);
      //this.dpaymInvoices = this.dpaymInvoices.filter((x) => x.id != item.id);
      this.dpaymInvoices = this.dpaymInvoices.filter((x) => JSON.stringify(x) != JSON.stringify(item));
    } catch (error) {
      console.error(error);
    }
  }

  async getPaid() {
    this.paidDpayms = await InvoiceFlowApi.loadPaid(this.invoice.id);
  }

  async deletePaidDpaymInvoice(relId: number) {
    try {
      await InvoiceFlowApi.deletePaidDpaymInvoice(relId);
      this.paidDpayms = this.paidDpayms.filter((b) => b.relId !== relId);
    } catch (err) {
      // TODO: notification
      console.error(err);
    }
  }

  actionAvailable(actionName: string) {
    return this.availableActions.some((a) => a.name === actionName);
  }

  async setService() {
    await InvoiceFlowApi.setService(this.invoice.id, this.invoice.isServ);
  }

  // #region | Invoice Rejection - számla elutasítás
  toggleInvoiceRejection() {
    eventBus.$emit("toggle-invoice-rejection");
  }

  async loadInvoiceRejectionReasons() {
    this.invoiceRejectionReasons = await InvoiceFlowApi.getInvoiceRejectionReasons();
  }

  async sendInvoiceRejection(rejection: InvoiceRejection) {
    await InvoiceFlowApi.postInvoiceRejection(this.invoice.id, rejection).then(async (result) => {
      this.invoice.preStatus = result;
      eventBus.$emit("success", "Sikeres számla Elutasítás!");
      await this.loadFlowActionLogs(this.invoice.id);
    });
  }

  async kanriSync(invoiceId: number) {
    await InvoiceFlowApi.kanriSync(invoiceId);
  }

  async closeGpartsOrder() {
    await InvoiceFlowApi.closeGpartsOrder(this.invoice.id);
  }
  async generateViriForLetterOfCredit() {
    await InvoiceFlowApi.generateViriForLetterOfCredit(this.invoice.id);
  }
  async undoInvoiceRejection() {
    await InvoiceFlowApi.undoInvoiceRejection(this.invoice.id).then(async (result) => {
      this.invoice.preStatus = result;
      eventBus.$emit("success", "Sikeres számla Elutasítás visszavonás!");
      await this.loadFlowActionLogs(this.invoice.id);
    });
  }
  // #endregion

  // #region Nem számlázott készletek elszámolása
  async saveUninvoicedStockCorrection(items: InvoiceItem[]) {
    if (this.invoice.id == 0) {
      return await UninvoicedStockCorrectionApi.create(this.invoice, items);
    } else {
      return await UninvoicedStockCorrectionApi.update(this.invoice, items);
    }
  }

  async acceptUninvoicedStockCorrection(items: InvoiceItem[]) {
    await UninvoicedStockCorrectionApi.accept(this.invoice, items);
  }

  async loadUninvoicedDelivery(stock: number) {
    this.uninvoicedDeliveries = await UninvoicedStockCorrectionApi.getUninvoicedDelivery(stock);
  }
  // #endregion

  async getsubcontractorProjectDetails(projectID: number) {
    this.subcontractorProjectDetails = await InvoiceFlowApi.getsubcontractorProjectDetails(projectID);
  }

  async generateNewDebitItem(items: any[]) {
    console.log(items);
    await InvoiceFlowApi.generateNewDebitItem(items);
  }

  async getOriginalVatValues(items: any[]) {
    return await InvoiceFlowApi.getOriginalVatValues(items);
  }

  async removeGeneralRect(id: number) {
    await InvoiceFlowApi.removeGeneralRect(id);
  }

  async updateGeneralRect(item: InvoiceItem) {
    await InvoiceFlowApi.updateGeneralRect(item.generalRectItemDetails);
  }

  async getPartnerRelationsWithSelectedPartnerRelation(partner: number, id: number) {
    const temp = await InvoiceFlowApi.getPartnerRelationsWithSelectedPartnerRelation(partner, id);

    this.partnerRelations = (temp as any).partnerRelations;
    this.selectedPartnerRelation = (temp as any).selectedPartnerRelation;
  }

  async updatePartnerRelRelation() {
    if (this.selectedPartnerRelation != null) {
      this.selectedPartnerRelation.invNo = Number(this.invoice.id);
      await InvoiceFlowApi.updatePartnerRelRelation(this.selectedPartnerRelation);
    }
  }

  async checkVatReturn(vatDate: string, invoice: number) {
    return await InvoiceFlowApi.checkVatReturn(vatDate, invoice);
  }

  async checkDpaym(id: number, partnerId: number) {
    return await InvoiceFlowApi.checkDpaym(id, partnerId);
  }

  async createSupplierRelation() {
    await InvoiceFlowApi.createSupplierRelation(this.invoice.partner);
  }
}

const store = Vue.observable(new InvoiceFlowStore());
export default store;
