






















































































































// Components
import MoneyInput from "@/components/MoneyInput.vue";
import RelationDisplayName from "@/modules/invoiceItems/components/RelationDisplayName.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import IconButton from "@/components/IconButton.vue";
import Fragment from "@/components/Fragment.vue";

// Models
import Vue from "vue";
import store, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import correctionStore, { UninvoicedStockCorrectionStore } from "../services/store";
import * as InvoiceItemApi from "@/modules/invoiceItems/services/api";
import { InvoiceItem } from "@/modules/invoiceItems/models";
import configuration from "@/models/Configuration";

interface ItemState {
  descriptionVisible: boolean;
}

interface Data {
  store: InvoiceFlowStore;
  correctionStore: UninvoicedStockCorrectionStore;
  itemStates: Record<string, ItemState>;
}

interface Methods {
  toggleDescription(invoiceItem: InvoiceItem): void;
  getItemState(invoiceItem: InvoiceItem): ItemState;
  removeItem(invoiceItem: InvoiceItem): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    MoneyInput,
    RelationDisplayName,
    MoneyDisplay,
    IconButton,
    Fragment,
  },

  data: () => ({
    store,
    correctionStore,
    itemStates: {},
    configuration,
  }),

  async mounted() {
    const invoiceID = Number(this.$route.params.invoiceId) || this.store.invoice.id;

    if (invoiceID <= 1) {
      return;
    }

    if (this.correctionStore.units.length == 0) {
      this.correctionStore.units = await InvoiceItemApi.getUnits();
    }
    if (this.correctionStore.taxAndVatItems.length == 0) {
      this.correctionStore.taxAndVatItems = await InvoiceItemApi.getAllTaxAndVatItem();
    }
    this.correctionStore.items = await InvoiceItemApi.getInvoiceItems(invoiceID);
  },

  methods: {
    toggleDescription(invoiceItem: InvoiceItem) {
      const state = this.getItemState(invoiceItem);
      state.descriptionVisible = !state.descriptionVisible;
      this.itemStates = { ...this.itemStates };
    },

    getItemState(invoiceItem: InvoiceItem) {
      let state = this.itemStates[invoiceItem.cuid];
      if (!state) {
        state = {
          descriptionVisible: false,
        };
      }

      this.itemStates[invoiceItem.cuid] = state;
      return state;
    },

    removeItem(invoiceItem: InvoiceItem) {
      this.correctionStore.items = this.correctionStore.items.filter((i) => i.cuid !== invoiceItem.cuid);
    },
  },

  computed: {
    disabled() {
      return !this.store.isEditingEnabled;
    },

    itemsNetto() {
      return this.correctionStore.items.reduce((acc, i) => acc + i.nettoValue, 0.0);
    },

    itemsBrutto() {
      return this.correctionStore.items.reduce((acc, i) => acc + i.bruttoValue, 0.0);
    },
  },
});
