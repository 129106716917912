





























































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, { PaymentModeStore } from "../services/PaymentModeStore";
import WebPayMode from "../models/WebPayMode";

interface Data {
  store: PaymentModeStore;
  opened: boolean;
  valid: boolean;
  selectedWebPayMode: WebPayMode | undefined;
}

const CREATE_TITLE = "Fizetési mód létrehozása";
const MODIFY_TITLE = "Fizetési mód adatok módosítása";

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      valid: true,
      opened: false,
      selectedWebPayMode: undefined,
    };
  },

  computed: {
    title() {
      return this.store.paymentMode?.id > 0 ? MODIFY_TITLE : CREATE_TITLE;
    },
  },

  async beforeMount() {
    const paymentModeId = Number(this.$route.params.paymentModeId);
    await Promise.all([
      this.store.loadPaymentMode(paymentModeId),
      this.store.loadwebPayModes(),
    ]);

    this.selectedWebPayMode = this.store.webPayModes.find(
      (x) => x.id == this.store.paymentMode.webPayMode
    );
  },

  methods: {
    async save() {
      await this.store.save();
      this.$router.push("/settings/payment-mode");
    },

    selectedWebPayModeChanged(item: WebPayMode) {
      this.store.paymentMode.webPayMode = item.id;
    },
  },
});
