import ApprovalMatrixHead, { approvalMatrixHeadDefaultProps } from "./ApprovalMatrixHead";
import ApprovalMatrixUser from "./ApprovalMatrixUser";
export default interface ApprovalMatrixHeadResponse extends ApprovalMatrixHead {
  controllerUsers: ApprovalMatrixUser[];
  approverUsers: ApprovalMatrixUser[];
  fullfillControllerUsers: ApprovalMatrixUser[];
  fullfillApproverUsers: ApprovalMatrixUser[];
}

export const approvalMatrixHeadResponseDefaultProps: ApprovalMatrixHeadResponse = {
  ...approvalMatrixHeadDefaultProps,
  controllerUsers: [],
  approverUsers: [],
  fullfillControllerUsers: [],
  fullfillApproverUsers: [],
};
