




import configuration from "@/models/Configuration";
import GeneralIngoingInvoicesPage from "@/modules/generalIngoingInvoice/views/GeneralIngoingInvoicesPage.vue";
import InvoiceFlowTaskPage from "@/modules/invoiceFlowTask/views/InvoiceFlowTaskPage.vue";
import Vue, { Component } from "vue";

export default Vue.extend({
  name: "Home",

  data: () => ({
    configuration,
  }),

  components: {
    InvoiceFlowTaskPage,
    GeneralIngoingInvoicesPage,
  },

  computed: {
    landingPage(): Component {
      return configuration.invoiceFlow.enabled && !configuration.operel
        ? InvoiceFlowTaskPage
        : GeneralIngoingInvoicesPage;
    },
  },
});
