


















import Vue from "vue";

interface Data {
  visible: boolean;
}

export default Vue.extend({
  props: {
    title: { type: String, default: "" },
    color: { type: String, default: "var(--primaryColor)" },
    padding: { type: String, default: "" },
    show: { type: Boolean, default: true },
  },

  data: (): Data => ({
    visible: true,
  }),

  methods: {
    onClick(): void {
      this.visible = !this.visible;
      this.$emit("click", this.visible);
    },
  },

  watch: {
    show() {
      this.visible = this.show;
    },
  },

  computed: {
    classes(): string {
      return this.padding;
    },
  },
});
