















import Vue from "vue";
import Page from "../../../components/Page.vue";
import PartnerSearch from "../../partner/views/PartnerSearch.vue";
import Partner from "../../partner/models/Partner";
import store from "../services/NoticeStore";

export default Vue.extend({
  components: {
    Page,
    PartnerSearch,
  },

  data() {
    return {
      store,
    };
  },

  methods: {
    partnerSelected(partner: Partner) {
      this.store.notice.partner = partner.id;
      this.$router.push(`/notice/create/0`);
    },
  },
});
