





















































































import Vue from "vue";
import IconButton from "../../../components/IconButton.vue";
import store, { InvoiceItemStore } from '../services/store'

import {
  feeTypes,
  priceModifyOptions,
  StoreInFee,
  StoreInFeeType,
} from "../models";
import MoneyInput from "../../../components/MoneyInput.vue";
import TextInput from "../../../components/TextInput.vue";

interface Data {
  store: InvoiceItemStore;
  isFormVisible: boolean;
  feeTypes: StoreInFeeType[];
  priceModifyOptions: Array<{ text: string; value: boolean }>;
  fee: StoreInFee | null;
}

interface Methods {
  startAdd(): void;
  cancelAdd(): void;
  submit(): void;
  reset(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    TextInput,
    IconButton,
    MoneyInput
  },

  data: () => ({
    store,
    isFormVisible: false,
    feeTypes,
    priceModifyOptions,
    fee: null,
  }),

  beforeMount() {
    this.reset();
  },

  methods: {
    startAdd() {
      this.isFormVisible = true;
    },

    cancelAdd() {
      this.isFormVisible = false;
    },

    submit() {
      if(!this.fee) {
        return
      }

      this.isFormVisible = false;
      this.store.addStoreInFee({...this.fee})
      this.reset();
    },

    reset() {
      this.fee = {
        id: 0,
        invoiceID: 0,
        type: feeTypes[0].value,
        name: "",
        storeInID: 1,
        price: 0.0,
        currPrice: 0.0,
        inStockPrice: true,
        invoiceNo: "",
      };
    },
  },
});
