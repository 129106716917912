import OutgoingInvoiceTypeEditModel, { outgoingInvoiceTypeEditModelDefaultProps } from "../models/OutgoingInvoiceTypeEditModel"
import OutgoingInvoiceTypeEditModelApi from "./OutgoingInvoiceTypeEditModelApi"
import busyIndication from "../../../utils/busyIndication";

export class OutgoingInvoiceTypeEditModelStore {
    outgoingInvoiceTypeEditModels: OutgoingInvoiceTypeEditModel[] = []
    outgoingInvoiceTypeEditModel: OutgoingInvoiceTypeEditModel = { ...outgoingInvoiceTypeEditModelDefaultProps }

    @busyIndication()
    async loadOutgoingInvoiceTypeEditModels() {
        this.outgoingInvoiceTypeEditModels = (await OutgoingInvoiceTypeEditModelApi.getAll()).map((i) => ({ ...i, deleting: false }))
    }

    @busyIndication()
    async loadOutgoingInvoiceTypeEditModel(outgoingInvoiceTypeEditModelId: number) {
        this.outgoingInvoiceTypeEditModel = outgoingInvoiceTypeEditModelId > 1 ? await OutgoingInvoiceTypeEditModelApi.getEditModel(outgoingInvoiceTypeEditModelId) : { ...outgoingInvoiceTypeEditModelDefaultProps }
    }
    
    private async updateOutgoingInvoiceTypeEditModel() {
        try {
            await OutgoingInvoiceTypeEditModelApi.saveEditModel(this.outgoingInvoiceTypeEditModel)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    setDeleting(item: OutgoingInvoiceTypeEditModel) {
        item.deleting = !item.deleting;
        this.outgoingInvoiceTypeEditModels = [...this.outgoingInvoiceTypeEditModels];
    }

    async deleteoutgoingInvoiceTypeEditModel(item: OutgoingInvoiceTypeEditModel) {
        try {
            await OutgoingInvoiceTypeEditModelApi.delete(item)
            this.outgoingInvoiceTypeEditModels = this.outgoingInvoiceTypeEditModels.filter(b => b !== item)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }   

    async save() {
        if(!this.outgoingInvoiceTypeEditModel) {
            return
        }
        await this.updateOutgoingInvoiceTypeEditModel();
    }
}

const store = new OutgoingInvoiceTypeEditModelStore()
export default store