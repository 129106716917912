import ApiResponse from "@/models/ApiResponse";
import DocumentType from "@/modules/document/models/DocumentTypeModel";
import Document from "@/modules/document/models/DocumentModel";

import http, { handleApiResponse, checkResponse } from "@/services/http";

export default class DocumentHandlerApi {
  static async loadDocumentTypes(): Promise<DocumentType[]> {
    return await http.get<ApiResponse<DocumentType[]>>(`document-types`).then(handleApiResponse);
  }

  static generateDocumentDownLoadLink(documentId: number) {
    return (
      http.defaults.baseURL +
      `document/download/${documentId}?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`
    );
  }

  static uploadDocument(itemId: number, doctype: number, description: string, file: File): Promise<Document> {
    const data = new FormData();
    data.append("file", file);
    data.append("typeID", doctype.toString());
    data.append("relatedItemName", "imgendoc.szam");
    data.append("relatedItemID", "-");
    data.append("description", description);

    return http
      .post<ApiResponse<Document>>(`documents`, data, { headers: { "Content-Type": "multipart/form-data" } })
      .then(handleApiResponse);
  }
}
