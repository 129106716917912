






























































import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";

import Vue from "vue";
import { DownPayment } from "../models";
import store, { DownPaymentStore } from "../services/store";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import StatusChip from "@/components/StatusChip.vue";

interface Data {
  store: DownPaymentStore;
}

export default Vue.extend({
  components: { ApiSearchPage, IconButton, Tooltip, StatusChip },

  data: (): Data => ({
    store,
  }),

  async beforeMount() {
    this.store.downPaymentList.items = [];
    /* await this.store.loadDownPayments(); */
  },

  methods: {
    create() {
      this.$router.push(`/down-payment/search`);
    },

    editItem(item: DownPayment) {
      this.$router.push(`/down-payment/edit/${item.id}`);
    },
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      {
        field: "ID",
        title: "Azonosító",
        cell: "id",
      },
      { field: "invoiceNo", title: "Bizonylat szám" },
      { field: "partnerName", title: "Szállító" },
      {
        field: "invoiceDate",
        title: "Számla Dátuma",
        type: ColumnType.Date,
      },
      {
        field: "payTerm",
        title: "Fizetési határidő",
        type: ColumnType.Date,
      },
      {
        field: "netto",
        title: "Nettó",
        type: ColumnType.Money,
      },
      { field: "currency", title: "Pénznem" },
      {
        field: "permitStatus",
        title: "Utalványozás státusz",
        cell: "permitStatus",
        type: ColumnType.Center,
      },
      {
        field: "preStatus",
        title: "Állapot",
        cell: "preStatus",
        type: ColumnType.Center,
      },
      { cell: "command", sortable: false, filterable: false },
    ],
  },
});
