







































// Models
import Vue from "vue";
import eventBus from "@/services/eventBus";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import bankStore, { BankStore } from "@/modules/bank/services/BankStore";
import DetailsOfCharges from "@/modules/bank/models/DetailsOfCharges";
import PartBank, { partBankDefaultProps } from "@/modules/cashPoint/models/PartBank";
import PartnerApi from "@/modules/partner/services/PartnerApi";

// Components
import BankAccountPicker from "./BankAccountPicker.vue";

interface Data {
  store: InvoiceFlowStore;
  bankStore: BankStore;
  selectedBankAccount: PartBank | undefined;
  skip: number;
  take: number;
  bankAccountDialog: boolean;
  detailsOfCharges: DetailsOfCharges;
}

interface Methods {
  loadPartnerBanks(): void;
  bankAccountSelected(partBank: PartBank): void;
  onPaymentMethodChange(partBankID: number): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: { BankAccountPicker },

  props: {
    isPageDisabled: { type: Boolean, default: true },
    addNew: { type: Boolean, default: false },
  },

  data: () => ({
    store,
    bankStore,
    selectedBankAccount: { ...partBankDefaultProps },
    skip: 0,
    take: 10,
    bankAccountDialog: false,
    detailsOfCharges: new DetailsOfCharges(),
  }),

  created() {
    eventBus.$on("payment-method:changed", this.onPaymentMethodChange);
    eventBus.$on("flow:part-bank-created", this.loadPartnerBanks);
  },

  beforeDestroy() {
    eventBus.$off("payment-method:changed", this.onPaymentMethodChange);
    eventBus.$off("flow:part-bank-created", this.loadPartnerBanks);
  },

  async beforeMount() {
    const partnerId = Number(this.$route.params.partnerId) || this.store.invoice.partner;
    await PartnerApi.getPartBanksWithTech(partnerId).then((result) => (this.store.partBanks = result));
    await this.bankStore.loadDetailsOfCharges();

    // manual "payment-method:changed"
    // when this component isn't rendered
    if (
      (this.store.invoice.paymode > 2 && this.store.invoice.bankAccount <= 1) ||
      (this.store.invoice.paymode == 2 && this.store.invoice.bankAccount > 1)
    ) {
      this.onPaymentMethodChange(this.store.invoice.paymode);
    } else {
      this.selectedBankAccount = this.store.partBanks.find((x) => x.id == this.store.invoice.bankAccount);
      if (!this.selectedBankAccount && this.store.partBanks.length > 0) {
        this.selectedBankAccount = this.store.partBanks[0];
        this.store.invoice.bankAccount = this.store.partBanks[0].id;
      }
    }

    this.detailsOfCharges =
      this.bankStore.detailsOfCharges.find(
        (x) =>
          (x.id == this.store.invoice.detailsOfCharges && x.id > 1) ||
          x.id == this.selectedBankAccount?.detailsOfCharges
      ) ?? new DetailsOfCharges();
  },

  methods: {
    async loadPartnerBanks() {
      const partnerId = Number(this.$route.params.partnerId) || this.store.invoice.partner;
      await PartnerApi.getPartBanksWithTech(partnerId).then((result) => (this.store.partBanks = result));
    },

    onPaymentMethodChange(partBankID: number) {
      if (partBankID == 2) {
        this.selectedBankAccount = this.store.partBanks.find((x) => x.id == 1);
        this.store.invoice.bankAccount = 1;
      } else if (this.store.invoice.bankAccount > 1) {
        return;
      } else {
        const filtered = this.store.partBanks.filter((x) => x.id > 1);
        if (filtered.length > 0) {
          this.selectedBankAccount = filtered[0];
          this.store.invoice.bankAccount = this.selectedBankAccount.id;
        }
      }
    },

    bankAccountSelected(partBank) {
      this.store.invoice.bankAccount = partBank.id;
      this.selectedBankAccount = partBank;
      this.bankAccountDialog = false;

      this.detailsOfCharges =
        this.bankStore.detailsOfCharges.find((x) => x.id == partBank.detailsOfCharges) ?? new DetailsOfCharges();
    },
  },

  computed: {
    docVisible() {
      return this.selectedBankAccount && !this.selectedBankAccount.isNative && this.selectedBankAccount.id > 1;
    },
  },
});
