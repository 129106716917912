






























































































import Fragment from "@/components/Fragment.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import IconButton from "@/components/IconButton.vue";
import FixAssetApiSearch from "./fixAsset/FixAssetApiSearch.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

import Vue from "vue";
import eventBus from "@/services/eventBus";
import itemStore, { InvoiceItemStore } from "../services/store";
import FixAssetInvItemRelation from "@/modules/invoiceFlow/models/FixAssetInvItemRelation";
import FixAssetApiSearchDTO from "../models/FixAssetApiSearchDTO";
import { InvoiceItem } from "../models";

interface Data {
  itemStore: InvoiceItemStore;
}

export default Vue.extend({
  components: { Fragment, MoneyInput, IconButton, FixAssetApiSearch, MoneyDisplay },

  props: { item: Object as () => InvoiceItem },

  data: (): Data => ({
    itemStore,
  }),

  methods: {
    addRelation(fixAsset: FixAssetApiSearchDTO): void {
      if (this.item.fixAssetInvItemRelations.some((x) => x.fixAssetID == fixAsset.id)) return;

      this.item.hasFixAssetRelation = true;

      this.item.fixAssetInvItemRelations.push(
        new FixAssetInvItemRelation({
          id: 0,
          invItemID: this.item.invoiceItemID ?? 1,
          fixAssetID: fixAsset?.id ?? 1,
          fixAssetName: fixAsset?.name ?? "-",
          fixAssetSupplier: fixAsset?.supplier ?? "-",
          fixAssetNetto: fixAsset?.netto ?? 0,
          fixAssetCurrency: fixAsset?.currency ?? "-",
          amount: 0,
        })
      );
    },

    removeRelation(i: number): void {
      this.item.fixAssetInvItemRelations.splice(i, 1);
    },

    async save() {
      //this.item.fixAssetInvItemRelations = this.item.fixAssetInvItemRelations.filter((x) => x.fixAssetID > 1);
      await this.itemStore.save().then(() => eventBus.$emit("success", "Sikeres tárgyi eszköz adat frissítés!"));
    },
  },

  computed: {
    disabled() {
      return false; // this.item.fixAsset != null;
    },
  },
});
