


































































import Vue from "vue";

import api, { InvoiceDimensionType, InvoiceDimension } from "../services/InvoiceDimensionsApi";

import MoneyInput from "@/components/MoneyInput.vue";
import TextInput from "@/components/TextInput.vue";
import IconButton from "@/components/IconButton.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Data {
  dimensionTypes: InvoiceDimensionType[];
  dimensions: InvoiceDimension[];
  dimensionName: string;
  dimensionAmount: 0;
  selectedType: InvoiceDimensionType;
  dimensionNames: string[];
}

interface Props {
  id: number;
  currency: string;
}

interface Methods {
  fetchDimensions(invoiceID: number): Promise<void>;
  addDimension(): void;
}

export default Vue.extend<Data, {}, Methods, Props>({
  data: () => ({
    dimensionTypes: [],
    dimensions: [],
    selectedType: {
      id: 0,
      name: "-",
    },
    dimensionName: "",
    dimensionAmount: 0,
    dimensionNames: []
  }),

  components: {
    MoneyInput,
    IconButton,
    MoneyDisplay,
  },

  props: {
    id: {
      type: Number,
    },

    currency: {
      type: String,
    },
  },

  async beforeMount() {
    const dimensionTypes = await api.getInvoiceDimensionTypes();
    this.dimensionTypes = dimensionTypes;
    this.selectedType = dimensionTypes[0];

    const dimensionNames = await api.getInvoiceDimensionNames();
    this.dimensionNames = dimensionNames;

    await this.fetchDimensions(this.id);
  },

  watch: {
    async id(value: number) {
      await this.fetchDimensions(value);
    },
  },

  methods: {
    async fetchDimensions(invoiceID: number) {
      const dimensions = await api.fetchByInvoice(invoiceID);
      this.dimensions = dimensions.map((d) => ({
        ...d,
        type: this.dimensionTypes.find((t) => t.id === d.type.id) ?? this.dimensionTypes[0],
      }));

      this.selectedType = {
        id: 0,
        name: "-",
      };
      this.dimensionName = "";
      this.dimensionAmount = 0;
    },

    async addDimension() {
      if (!this.selectedType) {
        return;
      }
      if (this.selectedType.id === undefined || this.selectedType.id === 0)
      {
         const stype = this.dimensionTypes.find((t) => t.name === this.selectedType.toString());
         
         if (stype !== undefined && stype.id !== undefined)
         {
          this.selectedType = stype
         }
         else
         {
          this.selectedType = {id: 0, name: this.selectedType.toString()};
         }
      }

      const dimension: InvoiceDimension = {
        id: 0,
        invoiceID: this.id,
        type: this.selectedType,
        dimensionName: this.dimensionName ?? "",
        dimensionAmount: this.dimensionAmount ?? 0,
        invoiceItemID: 1,
      };

      const success = await api.createDimension(dimension);

      if (success) {
        this.dimensionTypes = await api.getInvoiceDimensionTypes();
        this.dimensionNames = await api.getInvoiceDimensionNames();
        await this.fetchDimensions(this.id);
      }
    },

    async deleteDimension(dimensionID: number) {
      const success = await api.deleteDimension(dimensionID);

      if (success) {
        await this.fetchDimensions(this.id);
      }
    },
  },
});
