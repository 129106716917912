import http, { handleApiResponse } from "@/services/http";
import { CurrencyRate } from "@/modules/currencyRate/models/CurrencyRate";
import Country from "../../cashPoint/models/Country";
import ApiResponse from "@/models/ApiResponse";

export default class CurrencyRateApi {
  static getAll(startDate?: string, endDate?: string, currency?: string): Promise<CurrencyRate[]> {
    return http
      .get<ApiResponse<CurrencyRate[]>>(`currency-rates`, {
        params: {
          s: startDate,
          e: endDate,
          currency: currency,
        },
      })
      .then(handleApiResponse);
  }

  static getRateMnb(currency: string, rateDate: Date | string): Promise<number> {
    return http.get<ApiResponse<number>>(`currency/rate/${currency}/${rateDate}/mnb`).then(handleApiResponse);
  }

  static getRateOnDate(currency: string, date: string): Promise<number> {
    return http.get<ApiResponse<number>>(`currency/rate/${currency}/${date}`).then(handleApiResponse);
  }

  static getRateAndDate(currency: string, date: string): Promise<CurrencyRate> {
    return http
      .get<ApiResponse<CurrencyRate>>(`currency/rate-date`, {
        params: {
          c: currency,
          d: date,
        },
      })
      .then(handleApiResponse);
  }

  static getRate(currency: string): Promise<number> {
    return http.get<ApiResponse<number>>(`currency/rate/${currency}`).then(handleApiResponse);
  }

  static getCurrencies(): Promise<Country[]> {
    return http.get<ApiResponse<Country[]>>(`currency`).then(handleApiResponse);
  }

  static create(currencyRate: Partial<CurrencyRate>) {
    return http.post<ApiResponse<CurrencyRate>>(`currency-rates`, currencyRate).then(handleApiResponse);
  }
}
