import User from "@/modules/invoiceGroup/models/User";
import InvoiceGroupApi from "@/modules/invoiceGroup/services/InvoiceGroupApi";
import busyIndication from "../../../utils/busyIndication";
import Role, { roleDefaultProps } from "@/modules/roles/models/Role";
import RoleApi from "./roleApi";
import Feature, { featureDefaultProps } from "../models/Feature";
import DeniedRelation from "../models/DeniedRelation";

export class RoleStore {
  roles: Role[] = [];
  role: Role = { ...roleDefaultProps };
  features: Feature[] = [];
  deniedRelations: DeniedRelation[] = [];
  users: User[] = [];

  @busyIndication()
  async loadRoles() {
    this.roles = await RoleApi.getAll();
  }

  @busyIndication()
  async loadFeatures() {
    this.features = await RoleApi.getAllFeatures();
  }

  async save(roleId: number, features: string[]) {
    const saveItemIndex = this.roles.findIndex((x) => x.id == roleId);
    this.roles[saveItemIndex].features = [];
    for (let i = 0; i < features.length; i++) {
      const f: Feature = this.findFeature(features, i);
      if (f?.name !== "") {
        this.roles[saveItemIndex].features.push(f);
      }
    }
    await RoleApi.save(this.roles[saveItemIndex]);
  }

  findFeature(features: string[], i: number): Feature {
    const ret = this.features.find((x) => x.name === features[i]);
    return ret != null ? ret : { ...featureDefaultProps };
  }

  async loadUsers() {
    this.users = await InvoiceGroupApi.getUsers();
  }

  async loadDeniedRelations() {
    this.deniedRelations = await RoleApi.getDeniedRelations();
  }

  async saveDeniedRelations(relation: DeniedRelation) {
    await RoleApi.saveDeniedRelations(relation);
  }
}

const store = new RoleStore();
export default store;
