import AccountNumberAndClaimRelation from "./AccountNumberAndClaimRelation";

export default interface CustomsInvoiceDetails {
  accounts: AccountNumberAndClaimRelation[];
  vatGPartID: number;
  dutyGPartID: number;
  defaultTaxGr: number;
  transferPaymentMode: number;
  defaultDivItem: number;
  exemptOfTax: number;
  exemptOfTaxLaw: number;
  pieceUnitTypeID: number;
  dutyPartnerRel: number;
  partnerName: string;
  hasPayments: boolean;
  preStatusName: string;
}

export const customsInvoiceDetailsDefaultProps: CustomsInvoiceDetails = {
  accounts: [],
  vatGPartID: 1,
  dutyGPartID: 1,
  defaultTaxGr: 1,
  transferPaymentMode: 1,
  defaultDivItem: 1,
  exemptOfTax: 1,
  exemptOfTaxLaw: 1,
  pieceUnitTypeID: 1,
  dutyPartnerRel: 1,
  partnerName: "",
  hasPayments: false,
  preStatusName: "",
};
