import Part, {partDefaultProps} from "../models/Part";
import PartApi from "./PartApi";
import busyIndication from '@/utils/busyIndication';

export class PartStore{
    parts: Part[]=[]
    part: Part = {...partDefaultProps}

    @busyIndication()
    async loadParts(){
        this.parts = await PartApi.getAll();
    }

    async loadPartsMinSearch(name: string, invGroupId: number){
        this.parts = await PartApi.getAllMin(name, invGroupId);
    }
}

const store = new PartStore()
export default store