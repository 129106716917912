<template>
  <v-row style="margin-top:10px !important">
    <v-col>
      <!-- <label>Pénzügyi szűrési feltételek</label> -->
      <v-row>
        <v-col sm="4">
          <label class="input-label">Partner</label>
        </v-col>
        <v-col>
          <multi-partner-search
            style="margin-bottom: 10px;"
            :value.sync="filters.partners"
            :partnerId.sync="filters.partnerFilters"
          ></multi-partner-search>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label">Fizetési státusz</label>
        </v-col>
        <v-col>
          <multi-select
            placeholder="Mind..."
            no-data-text="Nincs adat"
            item-value="id"
            item-text="name"
            outlined
            :items="statusFilters"
            v-model="filters.statusFilters"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label">Számlák lejárat szerint</label>
        </v-col>
        <v-col>
          <v-select
            no-data-text="Nincs adat"
            item-value="id"
            item-text="name"
            outlined
            :items="expirationFilters"
            v-model.number="filters.expirationFilter"
            dense
          />
        </v-col>
      </v-row>
      <v-row v-if="isVisibleDateFiltersDaysFilter">
        <v-col sm="4">
          <label class="input-label">Hány napja járt le?</label>
        </v-col>
        <v-col>
          <NumericTextBox
            style="margin-bottom:18px"
            v-model="filters.expirationDaysFilter"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label">Devizanem</label>
        </v-col>
        <v-col>
          <currency-select
            placeholder="Mind..."
            :multiselect="true"
            v-model="filters.currencyFilters"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label">Partner típusa</label>
        </v-col>
        <v-col>
          <multi-select
            itemText="name"
            itemValue="id"
            placeholder="Mind..."
            :items="partnerTypeFilters"
            :returnObject="false"
            v-model="filters.partnerTypeFilters"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label">Előlegbekérők</label>
        </v-col>
        <v-col>
          <v-select
            item-text="name"
            item-value="id"
            no-data-text="Nincs adat"
            outlined
            :items="advanceRequestTypeFilters"
            v-model="filters.advanceRequestTypeFilter"
            dense
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <!-- <label>Könyvelési szűrési feltételek</label> -->
      <v-row>
        <v-col sm="4">
          <label class="input-label">Főkönyvi feladás</label>
        </v-col>
        <v-col>
          <v-select
            item-text="name"
            item-value="id"
            no-data-text="Nincs adat"
            outlined
            :items="ledgerPostingFilters"
            v-model="filters.ledgerPostingFilter"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col sm="4">
          <label class="input-label">Főkönyvet alátámasztó analitikák</label>
        </v-col>
        <v-col> </v-col> -->
      </v-row>
      <!-- <label>Dátum szűrések</label> -->
      <v-row>
        <v-col sm="4">
          <label class="input-label">Bizonylat dátumra</label>
        </v-col>
        <v-col>
          <v-row>
            <v-btn outlined class="src-btn" style="height: 31px !important">
              <v-icon x-small>fa-clock-o</v-icon>
            </v-btn>
            <date-input v-model="filters.proofDateFrom" />
            <span style="width: 32px; text-align: center; padding-top: 7px"
              >-</span
            >
            <date-input v-model="filters.proofDateTo" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label">Bizonylat fizetési határidőre</label>
        </v-col>
        <v-col>
          <v-row>
            <v-btn outlined class="src-btn" style="height: 31px !important">
              <v-icon x-small>fa-clock-o</v-icon>
            </v-btn>
            <date-input v-model="filters.proofPaymentDeadlineFrom" />
            <span style="width: 32px; text-align: center; padding-top: 7px"
              >-</span
            >
            <date-input v-model="filters.proofPaymentDeadlineTo" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label"
            >Bizonylat könyvelési feladás dátumra</label
          >
        </v-col>
        <v-col>
          <v-row>
            <v-btn outlined class="src-btn" style="height: 31px !important">
              <v-icon x-small>fa-clock-o</v-icon>
            </v-btn>
            <date-input v-model="filters.proofPostingDateFrom" />
            <span style="width: 32px; text-align: center; padding-top: 7px"
              >-</span
            >
            <date-input v-model="filters.proofPostingDateTo" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label">Fizetési tétel dátumára</label>
        </v-col>
        <v-col>
          <v-row>
            <v-btn outlined class="src-btn" style="height: 31px !important">
              <v-icon x-small>fa-clock-o</v-icon>
            </v-btn>
            <date-input v-model="filters.paymentItemDateFrom" />
            <span style="width: 32px; text-align: center; padding-top: 7px"
              >-</span
            >
            <date-input v-model="filters.paymentItemDateTo" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label">Fizetési tétel keletkezési dátumára</label>
        </v-col>
        <v-col>
          <v-row>
            <v-btn outlined class="src-btn" style="height: 31px !important">
              <v-icon x-small>fa-clock-o</v-icon>
            </v-btn>
            <date-input v-model="filters.paymentItemInsertDateFrom" />
            <span style="width: 32px; text-align: center; padding-top: 7px"
              >-</span
            >
            <date-input v-model="filters.paymentItemInsertDateTo" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <label class="input-label"
            >Fizetési tétel könyvelési feladás dátumra</label
          >
        </v-col>
        <v-col>
          <v-row>
            <v-btn outlined class="src-btn" style="height: 31px !important">
              <v-icon x-small>fa-clock-o</v-icon>
            </v-btn>
            <date-input v-model="filters.paymentItemPostingDateFrom" />
            <span style="width: 32px; text-align: center; padding-top: 7px"
              >-</span
            >
            <date-input v-model="filters.paymentItemPostingDateTo" />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import MultiSelect from "@/components//MultiSelect.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import CurrencySelect from "@/components/CurrencySelect.vue";
import DateInput from "@/components/DateInput.vue";
import MultiPartnerSearch from "@/modules/partner/views/MultiPartnerSearch.vue";
import { getPartnerTypes } from "@/modules/currentAccount/api/PartnerTypesApi";
import eventBus from "@/services/eventBus";
import ApiSearch from "@/components/apiSearch/models/ApiSearch";
import CurrentAccountPreFilters from "@/modules/currentAccount/models/CurrentAccountPreFilters";

export default {
  async beforeMount() {
    this.partnerTypeFilters = await getPartnerTypes();
  },
  mounted() {
    if (this.apiSearch?.extraFilter) this.filters = this.apiSearch.extraFilter;
    else this.apiSearch.extraFilter = this.filters;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari){
      console.log('safari');

      this.filters.proofDateFrom = new Date();
      this.filters.proofDateTo = new Date();
      this.filters.proofPaymentDeadlineFrom = new Date();
      this.filters.proofPaymentDeadlineTo = new Date();
      this.filters.proofPostingDateFrom = new Date();
      this.filters.proofPostingDateTo = new Date();
      this.filters.paymentItemDateFrom = new Date();
      this.filters.paymentItemDateTo = new Date();
      this.filters.paymentItemInsertDateFrom = new Date();
      this.filters.paymentItemInsertDateTo =  new Date();
      this.filters.paymentItemPostingDateFrom = new Date();
      this.filters.paymentItemPostingDateTo = new Date();
    }
  },
  data() {
    return {
      partnerTypeFilters: [],
      filters: new CurrentAccountPreFilters(),
    };
  },
  components: {
    MultiSelect,
    NumericTextBox,
    CurrencySelect,
    DateInput,
    MultiPartnerSearch,
  },
  props: {
    apiSearch: ApiSearch,
  },
  watch: {
    "filters.partnerFilters"() {
      const length = this.filters.partnerFilters.length ?? 0;
      eventBus.$emit("multipartner-select-changed", length);
    },
    // filters() {
    //   this.apiSearch.extraFilter = this.filters;
    // },
    apiSearch(newValue) {
      if (newValue?.extraFilter) this.filters = newValue.extraFilter;
      else this.apiSearch.extraFilter = this.filters;
    },
  },
  computed: {
    statusFilters: () => [
      { name: "Nyitott", id: 0 },
      { name: "Részben fizetett", id: 1 },
      { name: "Rendezettek", id: 2 },
    ],
    expirationFilters: () => [
      { name: "Mind", id: 0 },
      { name: "Csak a lejárt számlák", id: 1 },
      { name: 'Csak az "X" napnál régebben lejárt számlák', id: 2 },
    ],
    isVisibleDateFiltersDaysFilter() {
      if (this.filters.expirationFilter == undefined) return false;

      return this.filters.expirationFilter == this.expirationFilters[2].id;
    },
    advanceRequestTypeFilters: () => [
      { name: "Folyószámla előlegbekérőkkel", id: 0 },
      { name: "Előlegbekérők nélkül", id: 1 },
      { name: "Csak előlegbekérők", id: 2 },
    ],
    ledgerPostingFilters: () => [
      { name: "Minden folyószámlatétel", id: 0 },
      { name: "Csak a főkönyv felé feladott folyószámlatételek", id: 1 },
    ],
  },
};
</script>

<style></style>
