




















































































import Vue from "vue";
import store, {
  AccountVoucherTypeStore,
} from "../services/AccountVoucherTypeStore";
import Page from "../../../components/Page.vue";
import AccountVoucherType from "../models/AccountVoucherType";
import setAttribute from "../../../utils/dom";

interface Data {
  store: AccountVoucherTypeStore;
}

const confirmationTemplate = {
  mounted() {
    setAttribute(".k-detail-cell", "colspan", "4");
  },
};

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    Page,
    confirmation: confirmationTemplate,
  },

  data(): Data {
    return {
      store,
    };
  },

  async beforeMount() {
    await this.store.loadAccountVoucherTypes();
  },

  computed: {
    columns: () => [
      { field: "name", title: "Bizonylatkönyv típus neve (N)" },
      { field: "foreign", title: "Bizonylatkönyv típus neve (I)" },
      { field: "english", title: "Bizonylatkönyv típus neve (A)" },
      { cell: "commandTemplate", width: "100px" },
      { width: 0 },
    ],

    items() {
      return this.store.accountVoucherTypes;
    },
  },

  methods: {
    createAccountVoucherType() {
      this.$router.push(`/account-voucher-types/create`);
    },

    updateAccountVoucherType(item: AccountVoucherType) {
      this.$router.push(`/account-voucher-types/${item.id}`);
    },

    deleteItem(item: AccountVoucherType) {
      this.store.setDeleting(item);
    },

    confirmDelete(item: AccountVoucherType) {
      item.deleting = false;
      this.store.delete(item.id);
    },

    cancelDelete(item: AccountVoucherType) {
      item.deleting = false;
    },
  },
});
