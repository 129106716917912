var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"section-title mb-3"},[_vm._v("Előlegszámla és végszámla kapcsolata")]),_c('label',{staticClass:"section-subtitle mb-3"},[_vm._v(_vm._s(_vm.title))]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.search($event)}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"outlined":"","single-line":"","hide-details":"auto","placeholder":"Előlegszámla keresése..."},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('icon-button',{attrs:{"tooltip":"Találatok törlése","icon":"fa-times"},on:{"click":_vm.clear}})],1)],1),_c('v-row',[_c('v-col',[_c('label',{staticClass:"grid-title"},[_vm._v("Előlegszámla tétel találatok")])])],1),_c('grid',{staticClass:"dpaym-grid",attrs:{"scrollable":"none","columns":_vm.columns,"data-items":_vm.store.dpaymInvoices},scopedSlots:_vm._u([{key:"currNettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"right-text"},[_c('money-input',{attrs:{"currency":props.dataItem.currency},model:{value:(props.dataItem.currNetto),callback:function ($$v) {_vm.$set(props.dataItem, "currNetto", _vm._n($$v))},expression:"props.dataItem.currNetto"}})],1)]}},{key:"currTotalTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"right-text"},[_c('money-display',{attrs:{"value":_vm.getItemBrutto(props.dataItem),"currency":props.dataItem.currency}})],1)]}},{key:"currVatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"right-text"},[_c('money-display',{attrs:{"value":_vm.getItemVat(props.dataItem),"currency":props.dataItem.currency}})],1)]}},{key:"invItemDropDownTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","single-line":"","items":_vm.store.invItemMins,"item-text":"name","item-value":"id","return-object":false,"hide-details":"auto"},model:{value:(props.dataItem.invItemToPayId),callback:function ($$v) {_vm.$set(props.dataItem, "invItemToPayId", $$v)},expression:"props.dataItem.invItemToPayId"}})],1)]}},{key:"addTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{staticClass:"forceTop"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.pay(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Beszámítás")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }