

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Page from "@/components/Page.vue";
import InvoiceParams, { InvoiceParamsDefProps } from "../models/sysinvoice";
import store, { InvoiceParamStore } from "../services/store";
import MoneyInput from "@/components/MoneyInput.vue";
import eventBus from "@/services/eventBus";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  InvoiceParamsEdit: InvoiceParams;
  store: InvoiceParamStore;
  invParamFolder: File;

  configuration: AppConfiguration;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { Page, MoneyInput },
  data() {
    return {
      InvoiceParamsEdit: InvoiceParamsDefProps,
      store,
      invParamFolder: new File([], ""),

      configuration
    };
  },
  async beforeMount() {
    const requestId = Number(this.$route.params.InvoiceParamsId);
    if (requestId != 0) {
      this.InvoiceParamsEdit = await store.getInvoiceParam(requestId);
    }

    this.store.setInvGroupList();
  },
  methods: {
    saveEdits() {
      if (this.InvoiceParamsEdit.code.length>1 && this.InvoiceParamsEdit.name.length>1) {
        this.store.updateInvoiceParams(this.InvoiceParamsEdit);
        this.$router.push("/invoice-parameter/");
      }
      else{
        eventBus.$emit("error","A belső kód és a számítpusnak 1 karakternél hosszabb szöveget kell megadni!")
      }
    },
  },
});
