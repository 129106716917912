
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// Components
import Tabs from "../../../components/Tabs.vue";
import AccItemDimensionView from "./AccItemDimensionView.vue";
import VatAnalytics from "../components/VatAnalytics.vue";
import VatItemEdit from "../components/VatItemEdit.vue";
import FlowReviewGrid from "./FlowReviewGrid.vue";
import ExcelFileBrowser from "@/modules/excel-test/components/ExcelFileBrowser.vue";
import ExpandableSection from "@/components/ExpandableSection.vue";
import IconButton from "@/components/IconButton.vue";
import MoneyInput from "@/components/MoneyInput.vue";
import ColspanFixer from "@/components/ColspanFixer.vue";
import Tooltip from "@/components/Tooltip.vue";
import ButtonCheckBox from "@/components/ButtonCheckBox.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

// Models
import Vue from "vue";
import eventBus from "@/services/eventBus";
import InvoiceFlowApi from "../services/InvoiceFlowApi";
import CurrencyRateApi from "@/modules/currencyRate/services/CurrencyRateApi";
import store, { FlowStatus, InvoiceFlowStore } from "../services/InvoiceFlowStore";
import permStore, {
  InvoicePermissions,
  PermissionStore,
} from "@/services/permissions";
import itemStore, {
  InvoiceItemStore,
} from "@/modules/invoiceItems/services/store";
import {
  VatDivisionModifier,
  VatDivisionModifierDefProps,
} from "@/modules/invoiceFlow/models/VatDivision";
import IncomingInvoiceAccItem, {
  incomingInvoiceAccItemDefaultProps,
} from "../models/IncomingInvoiceAccItem";
import VatDivisionDisplay, {
  vatDivDispDefaultProps,
} from "../models/VatDivisionDisplay";
import AccInvSError from "../models/AccInvSError";
import { deepCopy } from "@/utils/objects";
import { InvoiceItemsTable } from "@/modules/invoiceItems";

import configuration, {
  AppConfiguration,
  loadConfiguration,
} from "@/models/Configuration";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import VatReturnStatus from "@/modules/vat/models/VatReturnStatus";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";
import ConfirmationBox from "@/components/ConfirmationBox.vue";
import { PermitStatuses } from "@/modules/generalIngoingInvoice/models/PermitStatus";
import editStore, {
  GeneralIngoingInvoiceEditModelStore,
} from "@/modules/generalIngoingInvoiceEditModel/services/GeneralIngoingInvoiceEditModelStore";

class AccCode {
  value = "";
  name = "";
}

class LedgerSummaryItem {
  isDemand = false;
  accCode = "";
  total = 0;
  currTotal = 0;
  currency = "";
  exchangeRate = 1;
  color = "";
}

interface Data {
  store: InvoiceFlowStore;
  permStore: PermissionStore;
  tab: null | number;
  accountItemGroupOrder: string[];
  showErrorList: boolean;
  backupItem: IncomingInvoiceAccItem;
  inEdit: boolean;
  itemIDToDelete: number;
  deleting: boolean;
  dim1editing: boolean;
  backupDim1: string;
  newItem: IncomingInvoiceAccItem;
  isNewItem: boolean;
  changedVatDivs: number[];
  editingVat: boolean;
  itemStore: InvoiceItemStore;
  modifiedVatItem: VatDivisionModifier;
  updateVatStore: boolean;
  filterShowExcel: boolean;
  ledgerSummaryView: boolean;
  statusColorMap: Record<number, string>;
  vatDateChangeVisible: boolean;
  uninvoicedDelivery: boolean;
  loading: boolean;
  accountingPrecision: number;
  configuration: AppConfiguration;
  reassureCashAccountingDialog: boolean;
  showShipDateConfirmation: boolean;
  shipDateConfirmed: boolean;
  shipDateChangeRequired: boolean;
  vatDate: string;
  vatDateErrorMessage: string;
  vatDateInfoMessage: string;
  vatDatePromptMessage: string;
  vatDatePrompt: boolean;
  editStore: GeneralIngoingInvoiceEditModelStore;
  vatDateClosed: boolean;
}

interface Methods {
  updatePermitStatuses(): void;
  update(): Promise<void>;
  setInEdit(dataItem: IncomingInvoiceAccItem): void;
  cancelEdit(dataItem: IncomingInvoiceAccItem): void;
  saveEdit(dataItem: IncomingInvoiceAccItem): void;
  setExpanded(dataItem: IncomingInvoiceAccItem): void;
  isDimensions(dataItem: IncomingInvoiceAccItem): boolean;
  preAccounting(): Promise<void>;
  postAccounting(): Promise<void>;
  showErrors(): void;
  setDelete(dataItem: IncomingInvoiceAccItem): void;
  cancelDelete(): void;
  confirmDelete(): void;
  setDimEdit(dataItem: IncomingInvoiceAccItem): void;
  saveDimEdit(dataItem: IncomingInvoiceAccItem): void;
  cancelDimEdit(dataItem: IncomingInvoiceAccItem): void;
  setNewItem(): void;
  cancelNewItem(): void;
  confirmNewItem(): void;
  cancelAccounting(): void;
  addItemToChanged(dataItem: VatDivisionDisplay): void;
  saveVatDivItems(): void;
  saveVatDivs(x: VatDivisionDisplay): Promise<void>;
  editVatItem(): void;
  saveVatDivChange(itemToSave: VatDivisionModifier): Promise<any>;
  saveVatDivChanges(): void;
  reload(invoiceId: number): Promise<any>;
  toggleInverseVatSummary(): void;
  setService(): void;
  currencyChanged(dataItem: IncomingInvoiceAccItem): void;
  totalChanged(dataItem: IncomingInvoiceAccItem): void;
  reopen(): void;
  copy(): void;
  getMarginUnderStockGroup(
    h: any,
    trElement: any,
    defaultSlots: any,
    props: any
  ): any;
  tartozikStockGroupClass(item: IncomingInvoiceAccItem): string;
  kovetelStockGroupClass(item: IncomingInvoiceAccItem): string;

  showUninvoicedDelivery(stock: number): void;
  hasNote(item: IncomingInvoiceAccItem): boolean;
  updateCashAccounting(): void;
  refresh(): Promise<void>;
  saveVatDate(isWarningChecked: boolean): void;
}

interface Computed {
  noAccountPerm: boolean;
  isPageDisabled: boolean;
  invoiceId: number;
  accountItems: IncomingInvoiceAccItem[];
  accInvSErrors: AccInvSError[];
  changeVatDivisionButtonText: string;
  hasErrors: boolean;
  columns: any[];
  accCodes: AccCode[];
  ledgerSummaryItems: LedgerSummaryItem[];
  hufInvoice: boolean;
  isOtherItemRelation: boolean;
  newItemValidation: string;
  isModifyDisabled: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    Tabs,
    AccItemDimensionView,
    VatAnalytics,
    VatItemEdit,
    FlowReviewGrid,
    ExcelFileBrowser,
    ExpandableSection,
    IconButton,
    MoneyInput,
    ColspanFixer,
    Tooltip,
    ButtonCheckBox,
    MoneyDisplay,
    InvoiceItemsTable,
    ValidationError,
    ConfirmationBox,
  },

  async beforeMount() {
    this.accountingPrecision =
      configuration?.getCurrDigits(this.store.invoice.invCurrency)
        ?.accountingDigits ?? 0;
  },

  async mounted() {
    if (!configuration.isLoaded) {
      loadConfiguration();
    }
    try {
      this.loading = true;
      await this.store.loadAccountItems(Number(this.$route.params.invoiceId));
      await this.store.getAccInvSErrors(Number(this.$route.params.invoiceId));
      if (this.store.accItemDetailedTypes.length < 1) {
        await this.store.getAccItemDetailedTypes();
      }

      if (this.store.currencies.length == 0) await this.store.loadCurrencies();
      /* await this.reload(this.invoiceId); */
      this.store.accountingTabItem = 1;

      //Van hogy nem jó a státusza a számlának, ez frissíti a státuszt
      if (this.store.invoice.accStatus == 5 || this.store.invoice.accStatus == 2) {
        await this.store.updateAccountItems(this.store.invoice.id, this.accountItems);
        await this.refresh();
      }

      this.vatDateClosed = false;
      if (this.store.invoice.vatDate) {
          const shippingDatePhase = await this.store.getInvoiceShippingPhaseDate();
          if (shippingDatePhase.id !== 1) {
            this.vatDateClosed = shippingDatePhase.statusID === VatReturnStatus.Closed;
          }
        }

    } finally {
      this.loading = false;
    }
  },

  data: () => ({
    editingVat: false,
    updateVatStore: false,
    modifiedVatItem: VatDivisionModifierDefProps,
    itemStore,
    permStore,
    store,
    changedVatDivs: [],
    stockDivisionItems: [],
    tab: 0,
    accountItemGroupOrder: ["Partner", "Áfa", "Készlet"],
    showErrorList: false,
    backupItem: { ...incomingInvoiceAccItemDefaultProps },
    inEdit: false,
    itemIDToDelete: 0,
    deleting: false,
    dim1editing: false,
    backupDim1: "",
    newItem: { ...incomingInvoiceAccItemDefaultProps },
    isNewItem: false,
    filterShowExcel: false,
    ledgerSummaryView: false,
    statusColorMap: {
      1: "primary white--text",
      8: "red white--text",
      7: "red white--text",
    },
    vatDateChangeVisible: false,
    uninvoicedDelivery: false,
    loading: false,
    accountingPrecision: 0,
    configuration,
    reassureCashAccountingDialog: false,
    showShipDateConfirmation: false,
    shipDateConfirmed: false,
    shipDateChangeRequired: false,
    vatDate: '',
    vatDateErrorMessage: '',
    vatDateInfoMessage: '',
    vatDatePromptMessage: '',
    vatDatePrompt: false,
    editStore,
    vatDateClosed: false,
  }),

  computed: {
    newItemValidation() {
      if (this.newItem.detailedTypeID < 2)
        return "Kötelező kiválasztani a tétel típusát!";
      if (
        Number(this.newItem.total) === 0 &&
        Number(this.newItem.currTotal) === 0 &&
        Number(this.newItem.totalDemand) === 0 &&
        Number(this.newItem.currTotalDemand) === 0
      )
        return "Kötelező kitölteni a követel vagy tartozik oldalon az összeget!";
      if (
        this.newItem.accCode.length < 1 &&
        this.newItem.accCodeDemand.length < 1
      )
        return "Kötelező kitölteni a követel vagy tartozik oldalon a főkönyvi számot!";

      return "";
    },

    changeVatDivisionButtonText() {
      return this.editingVat ? "Vissza" : "Áfa bontás módosítása";
    },

    isPageDisabled() {
      return (
        !this.permStore.hasAccounting() ||
        !this.permStore.hasInvoice([InvoicePermissions.Accounting]) ||
        this.store.invoice.accStatus == 6 ||
        this.loading
      );
    },

    isModifyDisabled(){
      return (
        !this.permStore.hasAccounting() ||
        !this.permStore.hasInvoice([InvoicePermissions.Accounting]) ||
        this.loading
      );
    },

    noAccountPerm(){
      return !this.permStore.hasAccounting();
    },

    invoiceId() {
      return Number(this.$route.params.invoiceId);
    },

    accountItems() {
      let result = [...this.store.accountItems];
      result = result.concat([...this.store.caroAccountItems]);      

      const newList: IncomingInvoiceAccItem[] = [];
      const items: IncomingInvoiceAccItem[] = [];

      const groupAccessor = this.ledgerSummaryView
        ? (item: IncomingInvoiceAccItem) => item.accCode || item.accCodeDemand
        : (item: IncomingInvoiceAccItem) => item.detailedType;

      const sortAccessor = this.ledgerSummaryView
        ? (item: IncomingInvoiceAccItem) => item.accCode || item.accCodeDemand
        : (item: IncomingInvoiceAccItem) =>
            this.accountItemGroupOrder.indexOf(item.detailedType);

      result.sort((a, b) => (sortAccessor(a) > sortAccessor(b) ? 1 : 0));

      /* console.log("raw items");
      console.log(result);
      console.log("--------"); */

      result.forEach((i) => {
        //head = a group's first item
        i.group = groupAccessor(i);
        i.head =
          i.id ===
            result.find((o) => groupAccessor(i) === groupAccessor(o))?.id ?? 0;

        //For stock items if it has a invoiceitem-item, the original item's id is the item-item's groupWith prop
        if (i.groupWith > 1) {
          const f = result.find((x) => x.id == i.groupWith);
          i.groupClass = "groupStock";
          newList.push(i);
          if (f) {
            f.groupClass = "groupStockItem";
            newList.push(f);
          }
        } else if (!newList.find((y) => y.id == i.id)) {
          newList.push(i);
        }
      });

      //the stock group's first item is the head
      const stockHeads = newList.filter(
        (x) => x.group == this.accountItemGroupOrder[2] && x.head
      );
      //every other item, which is not stock group, can be pushed into the array
      const notStock = newList.filter(
        (x) => x.group != this.accountItemGroupOrder[2]
      );
      notStock.forEach((x) => {
        items.push(x);
      });

      //a new row for stock groups (just for rendering)
      const emptyRow = incomingInvoiceAccItemDefaultProps;
      emptyRow.accCode = "placehold";
      emptyRow.group = this.accountItemGroupOrder[2];
      emptyRow.head = true;

      //adding new rows for every kind of group (for rendering)
      for (let i = 0; i < newList.length; i++) {
        stockHeads.forEach((x) => {
          const ind = newList.indexOf(x);
          if (i === ind) {
            items.push(emptyRow);
          }
          if (i >= ind) {
            newList[i].head =
              newList[i].group === "Készlet" ? false : newList[i].head;
            items.push(newList[i]);
          }
        });
      }

      //
      let count = 0;
      items.forEach((element) => {
        count = 0;
        items.forEach((xa) => {
          if (
            element.id == xa.id
          ) {
            count++;
            if (count > 1) {
              let del = 0;

              for (let index = items.length; index > 0; index--) {
                const element = items[index];
                if (xa === element) {
                  if (del > 0) {
                    continue;
                  }
                  items.splice(index, 1);
                  del++;
                }
              }
            }
          }
        });
      });
      /* console.log("result items");
      console.log(items.map(x=>x.note)); */
      return items;
    },

    accInvSErrors() {
      return this.store.accInvSErrors;
    },

    hasErrors() {
      return this.accInvSErrors.length > 0;
    },

    columns() {
      const _columns = [
        {
          width: 30,
          cell: "expandDetailTemplate",
        },
        {
          cell: "typeTemplate",
          width: 80,
          headerCell: "typeHeaderTemplate",
        },
        {
          field: "accCode",
          title: "Tartozik",
          cell: "tartozikTemplate",
          headerCell: "headerIncomeTemplate",
        },
        {
          width: 150,
          field: "total",
          title: "Összeg",
          cell: "tartozikTotalTemplate",
          headerCell: "headerIncomeTemplate",
        },
        {
          width: 150,
          field: "currTotal",
          title: "Összeg deviza",
          cell: "tartozikCurrTotalTemplate",
          headerCell: "headerIncomeTemplate",
        },
        {
          field: "accCodeDemand",
          title: "Követel",
          cell: "kovetelTemplate",
          headerCell: "headerDemandTemplate",
        },
        {
          width: 150,
          field: "totalDemand",
          title: "Összeg",
          cell: "kovetelTotalTemplate",
          headerCell: "headerDemandTemplate",
        },
        {
          width: 150,
          field: "currTotalDemand",
          title: "Összeg deviza",
          cell: "kovetelCurrTotalTemplate",
          headerCell: "headerDemandTemplate",
        },
        {
          field: "accDate",
          title: "Könyvelés dátuma",
          headerClassName: "font-weight-bold grey--text text--darken-3",
          cell: "accDateTemplate",
          width: 100,
        },
        { width: 50, cell: "commandsTemplate" },
      ];

      // Insert separator column
      const separatorIndex = this.hufInvoice ? 4 : 5;
      _columns.splice(separatorIndex, 0, {
        width: 5,
        cell: "separatorTemplate",
      });

      if (this.hufInvoice) {
        return _columns.filter(
          (c) => !["currTotal", "currTotalDemand"].includes(c.field || "")
        );
      }

      return _columns;
    },

    hufInvoice() {
      return this.store.invoice.invCurrency === "HUF";
    },

    accCodes() {
      return this.store.accCodes.map((x) => ({
        value: x.code,
        name: `${x.code} - ${x.accName}`,
      }));
    },

    ledgerSummaryItems() {
      const list: LedgerSummaryItem[] = this.store.accountItems.map((i) => ({
        isDemand: i.isDemand,
        accCode: i.isDemand ? i.accCodeDemand : i.accCode,
        total: i.isDemand ? i.totalDemand ?? 0 : i.total ?? 0,
        currTotal: i.isDemand ? i.currTotalDemand ?? 0 : i.currTotal ?? 0,
        currency: i.currency,
        exchangeRate: i.exchangeRate ?? 1,
        color: i.isDemand ? "#f99142" : "#3db6ef",
      }));

      const result: LedgerSummaryItem[] = [];

      list.forEach((x) => {
        const item = result.find(
          (y) => x.accCode == y.accCode && x.isDemand == y.isDemand
        );

        if (item) {
          item.total += x.total;
          item.currTotal += x.currTotal;
          if (item.exchangeRate == 1 && x.exchangeRate > 1) {
            item.currency = x.currency;
            item.exchangeRate = x.exchangeRate;
          }
        } else {
          result.push({
            isDemand: x.isDemand,
            accCode: x.accCode,
            total: x?.total ?? 0,
            currTotal: x?.currTotal ?? 0,
            currency: x.currency,
            exchangeRate: x.exchangeRate,
            color: x.color,
          });
        }
      });

      return result.sort((a, b) => Number(a.accCode) - Number(b.accCode));
    },

    isOtherItemRelation() {
      return this.store.invoice.type == 6;
    },
  },

  watch: {
    async invoiceId(value: number) {
      await this.reload(value);
    },

    ledgerSummaryView(value: boolean) {
      eventBus.$emit("FlowAccountingPage:ledgerSummaryView:changed", value);
    },

    vatDateChangeVisible(){
      this.vatDate = this.store.invoice.vatDate || '';
    }
  },

  methods: {
    async updatePermitStatuses(): Promise<void> {
      if (this.store.invoice.permitStatus == PermitStatuses.Payable) {
        eventBus.$emit("error", "A számla már fizethető!");
      }
      else if (!this.store.flowStatusRelations.filter((y) => !y.deleted).map((x) => x.statusId).includes(FlowStatus.StockRelationRecording)) {
        eventBus.$emit("error", "A számla nincs jóváhagyva!");
      }
      else {
        await this.editStore.updatePermitStatuses([this.store.invoice.id], PermitStatuses.Payable);
        eventBus.$emit("success", "Sikeres Utalványozás státusz állítás!");
      }
    },

    saveVatDate(isWarningChecked: boolean){
      this.vatDateErrorMessage = '';
      this.vatDateInfoMessage = '';
      this.vatDatePromptMessage = '';
      this.vatDatePrompt = false;

      InvoiceFlowApi.vatDateUpdate(this.store.invoice.id, this.vatDate, isWarningChecked).then(checkResponse => {
        if (checkResponse.isError){
          this.vatDateErrorMessage = checkResponse.message;
          console.log(this.vatDateErrorMessage);
        } else if (checkResponse.isInfo){
          this.vatDateInfoMessage = checkResponse.message;
          this.refresh();
        } else if (checkResponse.isYesNo){
          this.vatDatePromptMessage = checkResponse.message;
          this.vatDatePrompt = true;
        }
      });
    },

    async refresh() {
      try {
        this.loading = true;
        await this.reload(Number(this.$route.params.invoiceId));
      } finally {
        this.loading = false;
      }
    },

    async updateCashAccounting() {
      store.invoice.isCashAccounting = false;
      await this.update();
      await this.postAccounting();
    },

    hasNote(item) {
      if (item && item.note && item.note.length > 1) {
        return item.note.toLowerCase().includes("ár");
      }
      return false;
    },

    async showUninvoicedDelivery(stock: number) {
      this.uninvoicedDelivery = !this.uninvoicedDelivery;
      await this.store.loadUninvoicedDelivery(stock);
    },

    kovetelStockGroupClass(item: IncomingInvoiceAccItem) {
      if (item.isDemand) {
        return item.groupClass;
      } else {
        return "";
      }
    },

    tartozikStockGroupClass(item: IncomingInvoiceAccItem) {
      if (!item.isDemand) {
        return item.groupClass;
      } else {
        return "";
      }
    },

    getMarginUnderStockGroup(h, trElement, defaultSlots, props) {
      let k = "";
      if (props.dataItem.groupClass == "groupStockItem") {
        k = "groupStockMargin";
      }

      const trProps = {
        class: k,
      };

      return h("tr", trProps, defaultSlots);
    },

    async reload(invoiceId: number) {
      if (invoiceId <= 1) {
        return;
      }

      await Promise.all([
        this.store.loadInvoiceWithoutBusy(invoiceId),
        this.store.getAccItemDetailedTypes(),
        this.store.loadAccountItems(invoiceId),
        this.store.getAccInvSErrors(invoiceId),
      ]);

      if (this.hasErrors) {
        this.showErrorList = true;
      }
    },

    setDimEdit(dataItem) {
      this.backupDim1 = dataItem.dimCode1;

      dataItem.dim1Edit = true;
      this.dim1editing = true;
    },

    cancelDimEdit(dataItem) {
      dataItem.dimCode1 = this.backupDim1;
      dataItem.dim1Edit = false;
      this.dim1editing = false;
    },

    async saveDimEdit(dataItem) {
      await this.store.updateAccountItem(dataItem);
      dataItem.dim1Edit = false;
      this.dim1editing = false;
    },

    setDelete(dataItem) {
      this.itemIDToDelete = dataItem.id;
      this.deleting = true;
    },

    async confirmDelete() {
      await this.store.deleteAccountItem(this.itemIDToDelete);

      const item = this.store.accountItems.find(
        (x) => x.id == this.itemIDToDelete
      );
      if (item) {
        const index = this.store.accountItems.indexOf(item);
        this.store.accountItems.splice(index, 1);
        this.store.accountItems = [...this.store.accountItems];
      }

      this.deleting = false;
    },

    cancelDelete() {
      this.deleting = false;
    },

    showErrors() {
      this.showErrorList = !this.showErrorList;
    },

    async preAccounting() {
      try {
        this.loading = true;

        Promise.all([
          await this.update(),
          await this.store.preAccounting(this.invoiceId),
          await this.reload(this.invoiceId),
          await this.store.loadFlowActionLogs(
            Number(this.$route.params.invoiceId)
          ),
        ]);
        eventBus.$emit("success", `A kontírozás sikeresen lefutott!`);
      } catch (ex) {
        console.log(ex);
        eventBus.$emit("Hiba történt az automatikus kontírozás közben!");
        throw ex;
      } finally {
        this.loading = false;
      }
    },

    async cancelAccounting() {
      try {
        this.loading = true;
        eventBus.$emit("invoice-accounting:saving", true);
        await this.store
          .cancelAccounting(this.invoiceId)
          .then(async (result) => {
            await this.store
              .loadInvoice(this.store.invoice.id)
              .then(async () => await this.reload(this.invoiceId));

            if (result.code >= 0) {
              eventBus.$emit(
                "success",
                `A számla visszavétele főkönyvből megtörtént!`
              );
              await this.store.resetFlowStatusRelations(this.invoiceId);
              await this.store.loadFlowStatusRelations(this.invoiceId);
            } else {
              eventBus.$emit(
                "error",
                `${result.err}, code: ${result.code}, ipCode: ${result.ipCode}, sqlErr: ${result.sqlErr}, isam: ${result.isam}`
              );
            }
          });
      } catch (ex) {
        console.log(ex);
        throw ex;
      } finally {
        this.loading = false;
        eventBus.$emit("invoice-accounting:saving", false);
      }
    },

    async postAccounting() {
      try {
        //Ha az áfa dátum már lezárt áfa időszakban van akkor figyelmeztetni kell a felhasználót
        if (this.store.invoice.vatDate) {
          const shippingDatePhase = await this.store.getInvoiceShippingPhaseDate();
          if (shippingDatePhase.id !== 1) {
            this.showShipDateConfirmation = shippingDatePhase.statusID === VatReturnStatus.Closed;

            if (!this.showShipDateConfirmation) {
              const shippingDateErrorMsg = `Számla teljesítési dátuma nyitott áfa periódusba esik, kérem frissítse az áfa bevallást!`;
              eventBus.$emit("warn", shippingDateErrorMsg);
              this.shipDateConfirmed = true;
            }
          }
        }

        if (this.showShipDateConfirmation && !this.shipDateConfirmed) {
          return;
        }

        this.loading = true;
        eventBus.$busy(true);
        eventBus.$emit("invoice-accounting:saving", true);
        await this.store.updateAccountItems(this.store.invoice.id, this.accountItems);
        const result = await this.store.postAccounting(this.invoiceId);
        if (Number(result.sqlErr) === 0 && result.err.length < 1) {
          eventBus.$emit("success", `A számla feladása megtörtént!`);
          this.$invoiceTaskUpdateHub.send("InvoiceTaskUpdated");
          this.$emit("update");
        } else {
          eventBus.$emit(
            "error",
            `${result.err}, code: ${result.code}, ipCode: ${result.ipCode}, sqlErr: ${result.sqlErr}, isam: ${result.isam}`
          );
        }
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        eventBus.$busy(false);
        this.loading = false;
        eventBus.$emit("invoice-accounting:saving", false);
        await this.refresh();
      }
    },

    isDimensions(item) {
      const accCodeContains =
        item.accCode.startsWith("5") ||
        item.accCode.startsWith("8") ||
        item.accCode.startsWith("9");
      const accCodeDemandContains =
        item.accCodeDemand.startsWith("5") ||
        item.accCodeDemand.startsWith("8") ||
        item.accCodeDemand.startsWith("9");

      return accCodeContains || accCodeDemandContains;
    },

    setInEdit(dataItem) {
      this.backupItem = deepCopy(dataItem);
      const item = this.store.accountItems.find((i) => i.id === dataItem.id);

      if (item) {
        item.inEdit = true;
        this.inEdit = true;
        this.store.accountItems = [...this.store.accountItems];
      }
    },

    setNewItem() {
      this.newItem = { ...incomingInvoiceAccItemDefaultProps };
      this.newItem.accDate = this.store.invoice.accDate || "";
      this.isNewItem = true;
    },

    cancelNewItem() {
      this.isNewItem = false;
    },

    async confirmNewItem() {
      const type = this.store.accItemDetailedTypes.find(
        (x) => x.id == this.newItem.detailedTypeID
      );
      if (type) {
        this.newItem.detailedType = type.name;
      }

      console.log(this.newItem);

      await this.store.createAccountItem(this.newItem).then((id) => {
        this.newItem.id = id;
        // this.store.accountItems.push(this.newItem);
        this.store.accountItems = [...this.store.accountItems, this.newItem];
        this.isNewItem = false;
      });
      await this.reload(Number(this.$route.params.invoiceId));
      await this.store.loadAccountItems(Number(this.$route.params.invoiceId));
    },

    cancelEdit(dataItem) {
      const item = this.store.accountItems.find((i) => i.id === dataItem.id);
      if (item) {
        Object.assign(item, { ...this.backupItem, inEdit: false });
        this.inEdit = false;
      }
    },

    async saveEdit(dataItem) {
      const item = this.store.accountItems.find((i) => i.id === dataItem.id);
      if (item) {
        await this.store.updateAccountItem(item);
        item.inEdit = false;
        this.inEdit = false;
      }
    },

    setExpanded(dataItem) {
      dataItem.expanded = !dataItem.expanded;
      this.store.accountItems = [...this.store.accountItems];
    },

    async update() {
      try {
        this.loading = true;
        await this.store.changeInVatCount(
          this.store.vatDivisionsDisplay.flatMap((i) => i.items)
        );
        eventBus.$emit("invoice-accounting:saving", true);
        //await this.store.updateFlow(this.isOtherItemRelation, true);
        switch (this.tab) {
          default:
          case 0:
            await this.store.updateAccountItems(
              this.store.invoice.id,
              this.accountItems
            );
            eventBus.$emit("success", `Sikeres mentés!`);
            break;
          case 1:
            if (!this.editingVat) {
              this.saveVatDivItems();
            } else if (this.editingVat) {
              this.saveVatDivChanges();
            }
            break;
        }
      } catch (ex) {
        console.log(ex);
        throw ex;
      } finally {
        this.loading = false;
        eventBus.$emit("invoice-accounting:saving", false);
        await this.refresh();
      }
    },

    async saveVatDivChanges() {
      try {
        const d = VatDivisionModifierDefProps;
        for (const element of this.itemStore.itemList.items) {
          d.invoiceItemId = element.invoiceItemID;
          d.invoiceId = Number(this.$route.params.invoiceId);
          d.vatDivisionId = element.tax.vatItemID;
          await this.saveVatDivChange(d);
        }
        eventBus.$emit("success", "Sikeres mentés!");
        await InvoiceFlowApi.runVatDividing(
          Number(this.$route.params.invoiceId)
        );
        this.editingVat = false;
      } catch {
        eventBus.$emit("error");
      }
    },

    async saveVatDivChange(itemToSave: VatDivisionModifier) {
      await InvoiceFlowApi.saveVatDivChanges(itemToSave);
    },

    addItemToChanged(dataItem: VatDivisionDisplay) {
      if (
        this.changedVatDivs.find((x) => x === dataItem.id) === undefined ||
        this.changedVatDivs.find((x) => x === dataItem.id) === null
      )
        this.changedVatDivs.push(dataItem.id);
    },

    async saveVatDivItems() {
      await this.changedVatDivs.forEach((x) => {
        this.saveVatDivs(
          this.store.vatDivisionsDisplay.find((y) => y.id == x) ??
            vatDivDispDefaultProps
        );
      });
      eventBus.$emit("success", "Sikerese mentés!");
    },

    async saveVatDivs(x: VatDivisionDisplay) {
      try {
        if (x.id != 1) {
          await InvoiceFlowApi.saveVatDivisionItem(x);
        }
      } catch {
        eventBus.$emit("error", "Nem sikerült a mentés!");
      }
    },

    editVatItem() {
      this.editingVat = !this.editingVat;
      this.store;
    },

    toggleInverseVatSummary() {
      eventBus.$emit("toggle-inv-vat-summary");
    },

    async setService() {
      await this.store.setService().then(() => {
        if (this.store.invoice.invCurrency != "HUF") location.reload();
      });
    },

    async currencyChanged(dataItem: IncomingInvoiceAccItem) {
      dataItem.exchangeRate = await CurrencyRateApi.getRateOnDate(
        dataItem.currency,
        this.store.invoice.rateDate
      );
      this.totalChanged(dataItem);
    },

    totalChanged(dataItem: IncomingInvoiceAccItem) {
      if (!dataItem.exchangeRate) return;

      if (dataItem.total != null && dataItem.currTotal != null) {
        dataItem.total = this.store.toDecimal(
          dataItem.currTotal * dataItem.exchangeRate,
          4
        );
      }

      if (dataItem.totalDemand != null && dataItem.currTotalDemand != null) {
        dataItem.totalDemand = this.store.toDecimal(
          dataItem.currTotalDemand * dataItem.exchangeRate,
          4
        );
      }
    },

    async reopen() {
      try {
        this.loading = true;
        eventBus.$emit("invoice-accounting:saving", true);
        const success = await this.store.reopen();

        if (success) {
          window.location.reload();
        }
      } catch (ex) {
        console.log(ex);
        throw ex;
      } finally {
        this.loading = false;
        eventBus.$emit("invoice-accounting:saving", false);
      }
    },

    async copy() {
      const success = await this.store.copy();
      if (success > 1) {
        this.$router.push(`/general-incoming-invoice/flow/edit/${success}/2`)
      }
    }
  },
});
