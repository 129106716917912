var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5"},[_c('v-card-title',[_c('v-row',{staticClass:"align-baseline"},[_c('v-col',[_vm._v(" Számla sablonok ")])],1)],1),_c('v-card-text',[_c('grid',{attrs:{"columns":_vm.templateColumns,"data-items":_vm.templateItems,"scrollable":"none","detail":"invLoadTemplate","expand-field":'expanded'},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","width":"30","height":"30","outlined":"","color":"primary","disabled":_vm.isPageDisabled},on:{"click":function($event){return _vm.showWarning(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Sablon alkalmazása")])])],1)]}},{key:"invLoadTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"yellowBg"},[_c('confirmation'),_c('div',{staticClass:"auth"},[_c('p',{staticClass:"conf"},[_vm._v(" Biztos betölti a "),_c('strong',[_vm._v(_vm._s(props.dataItem.name))]),_vm._v(" sablont? A számla adatok felülíródhatnak. ")])]),_c('div',{staticClass:"btns"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmLoad(props.dataItem)}}},[_vm._v(" Megerősítés ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.cancelLoad(props.dataItem)}}},[_vm._v(" Mégse ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }