import PartBank from "@/modules/cashPoint/models/PartBank";
import PartBankApi from "./partBankApi";

export default class PartBankStore
{
    partBanks: PartBank[] = [];
    
    async set() {
        this.partBanks = await PartBankApi.getPartBanks();
    }

    async setByPartner(id: number) {
        this.partBanks = await PartBankApi.getPartBanksByPartner(id);
    }

    async setMyOwn() {
        this.partBanks  = await PartBankApi.getMyOwnPartBanks();
    }

    get(id: number): PartBank | undefined {
        return this.partBanks.find(x => x.id === id);
    }
}