import { Year, DefaultYearProps } from './../models/YearModell';
import Glim from "../models/GeneralLedgerInvoiceMirror";
import Vue from "vue";
import api from "../services/api";
import busyIndication from "@/utils/busyIndication";
import { Period, SelectablePeriod } from "../models/PeriodModell";
import { StatusChanged } from "../models/StatusChange";
import eventBus from "@/services/eventBus";
import {
  DetailedType,
  DimCodeActivity,
  DimCodeType,
  DimSort,
  MainType,
} from "../models/TypesForEdit";
import { Dimension } from "../models/dimension";
import AccountNumberTreeNode from "../models/invoiceMirrorTreeNode";

export class GeneralLedgerStore {
  glims: Glim[] = [];
  invMirrorTrees: AccountNumberTreeNode[] = [];

  openedTreeItems: number[] = [];

  periods: Period[] = [];
  periodSelectables: SelectablePeriod[] = [
    { name: "Negyedév", value: 3 },
    { name: "Félév", value: 6 },
    { name: "Év", value: 12 },
    { name: "Év+1", value: 13 },
  ];

  years: Year[] = [];
  createdYear: Year = DefaultYearProps;

  statuses: string[] = [];
  statusesMin: string[] = [
    "Előkészítés alatt",
    "Könyvelésre nyitott",
    "Végleg lezárt",
  ];

  yearBeingChecked = 0;
  hasGlimGenerated = false;
  hasGlimByNature = false;

  mainTypes: MainType[] = [];
  detailedTypes: DetailedType[] = [];
  dimSortTypes: DimSort[] = [];
  dimCodeTypes: DimCodeType[] = [];
  dimActivities: DimCodeActivity[] = [];

  dimensions: Dimension[] = [];


  @busyIndication()
  async generateDims() {
    await api
      .GenerateDimensionsByYearId(this.yearBeingChecked)
      .then((result) => {
        console.log(result)
        if (result) {
          eventBus.$emit("success", "Sikeres generálás!");
        } else {
          eventBus.$emit("error", "A generálás sikertelen volt!");
        }
      });
  }

  async SetMainTypes(){
    this.mainTypes= await api.GetMainTypes();
  }

  async SetDetailedTypes(){
    this.detailedTypes= await api.GetDetailedTypes();
  }

  async GetDimSortTypes(){
    this.dimSortTypes=await api.GetDimSortTypes();
  }

  async DeleteDimensions(dimensionIds: number[]) {
    const result = await api.DeleteDimensions(dimensionIds);
    if (result) {
      eventBus.$emit("success", "A kiválasztott dimenziók sikeresen törölve!");
    } else {
      eventBus.$emit("error", "A kiválasztott dimenziók törlése nem sikerült!");
    }
  }

  async CreateInvMirror(invMirror: Glim) {
    const result = await api.CreateInvMirror(invMirror);
    if (result!="") {
      eventBus.$emit("success", "Főkönyvi számla sikeresen létrehozva!");
      eventBus.$emit("success", result);
      eventBus.$emit("api-search-page:refresh")
    } else {
      eventBus.$emit("success", "Főkönyvi számla sikeresen létrehozva!");
    }
  }

  @busyIndication()
  async setGlims() {
    this.glims = await api.GetGeneralLedgerInvoiceMirrors();
  }
  @busyIndication()
  async setYears() {
    this.years = await api.GetGeneralLedgerYears();
  }
  @busyIndication()
  async setPeriods(yearId: number) {
    this.periods = await api.GetPeriods(yearId);
  }

  async setStatuses() {
    this.statuses = await api.GetStatuses();
  }

  async setDimActivities() {
    this.dimActivities = await api.GetDimActivities();
  }

  async saveYear() {
    await api.SaveYear(this.createdYear);
  }

  async saveStatus(item: StatusChanged[]) {
    await api.SaveStatus(item);
  }
  async saveGlimStatusChanges(glims: Glim[]) {
    return await api.UpdateGlimStatuses(glims);
  }

  async setDimCodeTypes() {
    this.dimCodeTypes = await api.GetDimCodeTypes();
  }

  async saveDimStatusChanges(changedDimStatuses: Dimension[]) {
    return await api.UpdateDimStatuses(changedDimStatuses);
  }

  async saveGlimEdits(invMirror: Glim){
    const result = await api.UpdateGlim(invMirror);
    if (result) {
      eventBus.$emit("success", "Sikeresen mentve!");
    } else {
      eventBus.$emit("error", "A mentés nem sikerült!");
    }
  }



  @busyIndication()
  async getInvMirrorTree(yearId: number){
    this.invMirrorTrees = await api.GetInvMirrorTree(yearId);
  }

  saveDimEdits(dim: Dimension) {
    api.UpdateDim(dim).then((x) => {
      if (x) {
        eventBus.$emit("success", "Dimenzó kód sikeresen módosítva!");
      } else {
        eventBus.$emit("error", "Hiba lépett fel a módosítás közben!");
      }
    });
  }

  @busyIndication()
  async getOpenTreeItems(yearId: number){
    this.openedTreeItems = await api.getOpenTreeItems(yearId);
  }

  @busyIndication()
  async generateInvMirrors() {
      this.hasGlimGenerated = true;
      eventBus.$emit("successs", "Sikeres generálás!");
      eventBus.$emit("api-pager:set-page", 1);
  }

  saveGeneratedGlims() {
    api.CreateGeneratedGlims(this.glims);
  }

  @busyIndication()
  async DeleteGlims(itemIds: number[]): Promise<boolean> {
    const success = await api.DeleteGlims(itemIds);
    if (success) {
      eventBus.$emit("success", "Sikeres mentés!");
    } else {
      eventBus.$emit("error", "Sikertelen volt a mentés!");
    }
    return success;
  }

}

const store = Vue.observable(new GeneralLedgerStore());
export default store;
