import GeneralIngoingInvoiceItem from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItem";

export default interface VatDivisionDisplay
{
    id: number;
    taxName: string ;
    taxDegree: number;
    netto: number;
    vat: number;
    brutto: number;
    currency: string;
    invNetto: number ;
    invVat: number;
    invBrutto: number;
    invCurrency: string;
    substractable: boolean;
    isInvest: boolean;
    backDemand: boolean;
    isService: boolean;
    taxId: number;
    tax2: number;

    items: GeneralIngoingInvoiceItem[];
    selected: boolean;
}

export const vatDivDispDefaultProps: VatDivisionDisplay = {
    id:1,
    taxName:"",
    taxDegree:1,
    netto:1,
    vat:1,
    brutto:1,
    currency:"",
    invNetto: 1,
    invVat: 1,
    invBrutto: 1,
    invCurrency: "",
    substractable:false,
    isInvest:false,
    backDemand:false,
    isService:false,
    taxId: 1,
    tax2: 0,

    items:[],
    selected: false,
}