import AccountVoucherType, {accountVoucherTypeDefaultProps} from "@/modules/accountVoucherType/models/AccountVoucherType"
import AccountVoucherTypeApi from "../services/AccountVoucherTypeApi"
import busyIndication from "../../../utils/busyIndication";

export class AccountVoucherTypeStore {
    accountVoucherTypes: AccountVoucherType[] = []
    accountVoucherType: AccountVoucherType = {...accountVoucherTypeDefaultProps}

    @busyIndication()
    async loadAccountVoucherTypes() {
        this.accountVoucherTypes = (await AccountVoucherTypeApi.getAll()).map((i) => ({ ...i, inEdit: false, deleting: false }))
    }

    @busyIndication()
    async loadAccountVoucherType(accountVoucherTypeId: number) {
        this.accountVoucherType = accountVoucherTypeId > 1 ? await AccountVoucherTypeApi.get(accountVoucherTypeId) : { ...accountVoucherTypeDefaultProps }
    }

    async delete(id: number) {
        try {
            console.log(id);
            
            await AccountVoucherTypeApi.delete(id)
            this.accountVoucherTypes = this.accountVoucherTypes.filter(b => b.id !== id)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async save() {
        if(!this.accountVoucherType) {
            return
        }    

        try {
            if (this.accountVoucherType.id > 1){
                await AccountVoucherTypeApi.update(this.accountVoucherType);           
            }
            else{
                await AccountVoucherTypeApi.create(this.accountVoucherType);
                this.accountVoucherType = { ...accountVoucherTypeDefaultProps };
            }
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }


    setEditing(item: AccountVoucherType) {
        item.inEdit = !item.inEdit;
        this.accountVoucherTypes = [...this.accountVoucherTypes];
    }

    setDeleting(item: AccountVoucherType) {
        item.deleting = !item.deleting;
        this.accountVoucherTypes = [...this.accountVoucherTypes];    
    }
}

const store = new AccountVoucherTypeStore()
export default store