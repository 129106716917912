import Vue from "vue";
import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";

export enum InvoicePermissions {
  /** Iktatás */
  Recording = "IncomingInvoice:Recording",
  /** Pénzügyi rögzítés */
  FinancialRecording = "IncomingInvoice:FinancialRecording",
  /** Teljesítés igazolás / Készletkapcsolat */
  StockRelationRecording = "IncomingInvoice:StockRelationRecording",
  /** Teljesítés ellenőrzés */
  FulFillControlling = "IncomingInvoice:FulFillControlling",
  /** Teljesítés jóváhagyás */
  FulFillAccepting = "IncomingInvoice:FulFillAccepting",
  /** Számla ellenőrzés */
  Controlling = "IncomingInvoice:Controlling",
  /** Számla jóváhagyás */
  Accepting = "IncomingInvoice:Accepting",
  /** Utalványozás */
  Permitting = "IncomingInvoice:Permitting",
  /** Könyvelés */
  Accounting = "IncomingInvoice:Accounting",
  /** Költséghely és egyéb dimenziók megadása */
  InvoiceGroupEditing = "IncomingInvoice:InvoiceGroupEditing",
  /** Számla adat módosító */
  InvoiceDataModifier = "IncomingInvoice:InvoiceDataModifier"
}

export class PermissionStore {
  isSupervisor = false;
  isAllInOneRecorder = false;

  // used in TaskView, general permissions
  permissions: string[] = [];

  // used in flow
  flowPermissions: string[] = [];

  clear() {
    this.permissions = [];
  }

  async get() {
    this.permissions = await http.get<ApiResponse<string[]>>(`permissions`).then(handleApiResponse);
    this.isSupervisor = await http.get<ApiResponse<boolean>>(`permissions/other`).then(handleApiResponse);
    this.isAllInOneRecorder = await http.get<ApiResponse<boolean>>(`permissions/other/all-in-one`).then(handleApiResponse);
  }

  has(permissions: string[]) {
    return permissions.some((x) => this.permissions.indexOf(x) > -1);
  }



  async getFlowPermissions(invoiceID: number) {
    this.flowPermissions = await http
      .get<ApiResponse<string[]>>(`incoming-invoice/${invoiceID}/permissions`)
      .then(handleApiResponse);
  }

  /** Számla esetén jogosult az átadott jogosultság lista bármelyikére? */
  hasInvoice(permissions: string[]) {
    return permissions.some((x) => this.flowPermissions.indexOf(x) > -1);
  }

  /** Iktatásra jogosult? */
  hasRecording() {
    return this.has([InvoicePermissions.Recording]);
  }

  /** Pénzügyi rögzítésre jogosult? */
  hasFinancialRecording() {
    return this.has([InvoicePermissions.FinancialRecording]);
  }

  /** Teljesítés igazolásra / Készletkapcsolatra jogosult? */
  hasStockRelationRecording() {
    return this.has([InvoicePermissions.FulFillControlling, InvoicePermissions.FulFillAccepting]);
  }

  /** Teljesítés jóváhagyásra jogosult? */
  hasFulFillControlling() {
    return this.has([InvoicePermissions.FulFillControlling]);
  }

  /** Teljesítés jóváhagyásra jogosult? */
  hasFulFillAccepting() {
    return this.has([InvoicePermissions.FulFillAccepting]);
  }

  /** Számla ellenőrzésre jogosult? */
  hasControlling() {
    return this.has([InvoicePermissions.Controlling]);
  }

  /** Számla jóváhagyásra jogosult? */
  hasAccepting() {
    return this.has([InvoicePermissions.Accepting]);
  }

  /** Utalványozásra jogosult? */
  hasPermitting() {
    return this.has([InvoicePermissions.Permitting]);
  }

  /** Könyvelésre jogosult? */
  hasAccounting() {
    return this.has([InvoicePermissions.Accounting]);
  }

  /** Költséghely és egyéb dimenziók szerkesztésre jogosult? */
  hasInvoiceGroupEditing() {
    return this.has([InvoicePermissions.InvoiceGroupEditing]);
  }

  hasInvoiceDataModifier() {
    return this.has([InvoicePermissions.InvoiceDataModifier]);
  }
}

let appState: Record<string, any>;

const isKeyDefined = (key: string) => {
  const keys = Object.keys(appState);
  return keys.includes(key);
};

export class OperelPermissions {
  static initialize(state: Record<string, any>) {
    appState = Vue.observable(state || {});
  }

  static isGranted(key: string) {
    return !isKeyDefined(key) || Boolean(appState[key]);
  }
}

const permStore = new PermissionStore();
export default permStore;
