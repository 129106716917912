import AccountType from "../models/AccountType"
import AccountTypeApi from "../services/AccountTypeApi"
import busyIndication from "../../../utils/busyIndication";

export class AccountTypeStore {
    accountTypes: AccountType[] = []
    accountType: AccountType = new AccountType()

    @busyIndication()
    async loadAccountTypes() {
        this.accountTypes = (await AccountTypeApi.getAll()).map((i) => ({ ...i, inEdit: false }))
    }

    @busyIndication()
    async loadAccountType(accountTypeId: number) {
        this.accountType = accountTypeId > 1 ? await AccountTypeApi.get(accountTypeId) : new AccountType()
    }

    async deleteAccountType(accountTypeId: number) {
        try {
            await AccountTypeApi.delete(accountTypeId)
            this.accountTypes = this.accountTypes.filter(a => a.id !== accountTypeId)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    private async updateAccountType() {
        try {
            await AccountTypeApi.update(this.accountType)
            
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async save(item: AccountType, update: Partial<AccountType>) {
        Object.assign(item, update);
        this.accountType = item;
    }

    async saveAccountType(item: AccountType){
        if (item.id !== 0) {
            await this.updateAccountType();  
        }
    }

    private async createAccountType() {
        try {
            const created = await AccountTypeApi.create(this.accountType)
            this.accountTypes = [...this.accountTypes, created]
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }
    setEditing(item: AccountType) {
        item.inEdit = !item.inEdit;
        this.accountTypes = [...this.accountTypes];    
    }
}

const store = new AccountTypeStore()
export default store