



































































import Vue from "vue";
import { Unit } from "../models";
import store, { InvoiceItemStore } from "../services/store";
import MoneyInput from "../../../components/MoneyInput.vue";
import TaxAndVatItem from "@/modules/generalIngoingInvoice/models/TaxAndVatItem";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface Data {
  store: InvoiceItemStore;
  name: string;
  unitPrice: number;
  quantity: number;
  unit: Unit | null;
  vat: TaxAndVatItem | null;

  configuration: AppConfiguration;
}

interface Methods {
  submit(): void;
  clear(): void;
  initSelection(): void;
}

interface Computed {
  isValid: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    MoneyInput
  },

  data: () => ({
    store,
    name: "",
    unitPrice: 1.0,
    quantity: 1.0,
    unit: null,
    vat: null,

    configuration
  }),

  mounted() {
    this.initSelection()
  },

  computed: {
    isValid() {
      return (
        !!this.name && 
        this.unitPrice !== 0 &&
        this.quantity > 0 &&
        !!this.unit && 
        !!this.vat
      )
    }
  },

  methods: {
    submit() {
      if (!this.isValid || !this.unit || !this.vat) {
        return;
      }

      store.addFreeWordItem(
        this.name,
        this.unitPrice,
        this.quantity,
        this.unit,
        this.vat
      );
      this.clear();
    },

    clear() {
      this.name = "";
      this.unitPrice = 1.0;
      this.quantity = 1.0;
      this.initSelection()
    },

    initSelection() {
      this.unit = store.units[0];
      this.vat = store.taxAndVatItems.find((v) => v.degree === 27) ?? store.taxAndVatItems[0];
    }
  },
});
