














































































































































































































import Vue from "vue";
import * as api from "../services/api";
import store, { InvoiceItemStore } from "../services/store";
import IconButton from "../../../components/IconButton.vue";
import Fragment from "@/components/Fragment.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import eventBus from "@/services/eventBus";
import MoneyInput from "@/components/MoneyInput.vue";
import configuration, { AppConfiguration } from "@/models/Configuration";

interface OrderViewState {
  id: number;
  detailsVisible: boolean;
}

interface SelectedItem {
  id: number;
  item: OrderItem;
  orderNumber: string;
  supplier: string;
  itemName: string;
  priceDivision: number;
  currency: string;
  netto: number;
  weight: number;
  weightUnit: string;
  certifNo: string;
  storeInId: number;
}

interface Data {
  store: InvoiceItemStore;
  searchText: string;
  orders: Order[];
  orderStates: OrderViewState[];
  selection: SelectedItem[];
  configuration: AppConfiguration;
}

interface Order {
  id: number;
  orderDate: string;
  orderNumber: string;
  supplier: string;
  currency: string;
  netto: number;
  certifNo: string;
  storeInId: number;
  items: OrderItem[];
}

interface OrderItem {
  id: number;
  orderId: number;
  stockId: number;
  name: string;
  supplier: string;
  netto: number;
  unitPrice: number;
  totalWeight: number;
  weightUnit: string;
  quantity: number;
  unit: string;
  certifNo: string;
  storeInId: number;
}

interface Methods {
  search(e: KeyboardEvent): void;
  clear(): void;
  showItems(order: Order): void;
  addOrder(order: Order): void;
  addOrderItem(item: OrderItem): void;
  orderState(id: number): OrderViewState | undefined;
  removeSelected(selectedItem: SelectedItem): void;
  submit(): Promise<void>;
  getDetailsVisible(orderId: number): boolean;
  getGeniDigits(currency: string): number;
}

interface Computed {
  selectionIsEmpty: boolean;
  otherCost: number;
  dividedCost: number;
  validation: boolean;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  components: {
    IconButton,
    Fragment,
    MoneyDisplay,
    MoneyInput,
  },

  data: () => ({
    searchText: "",
    store,
    orders: [],
    orderStates: [],
    selection: [],
    configuration,
  }),

  computed: {
    selectionIsEmpty() {
      return this.selection.length === 0;
    },

    otherCost() {
      return this.store.targetItem?.unitPrice ?? 0.0;
    },

    dividedCost() {
      return this.selection.reduce((sum, item) => sum + item.priceDivision, 0.0);
    },

    validation() {
      const otherCost = this.store.targetItem?.unitPrice ?? 0.0;
      return otherCost === this.dividedCost;
    },
  },

  methods: {
    getGeniDigits(currency){
      return configuration.operel? 2 : configuration.getCurrDigits(store.currency) !== undefined ? configuration.getCurrDigits(store.currency)?.geniDigits??2 : 2
    },

    getDetailsVisible(orderId) {
      return this.orderState(orderId)?.detailsVisible ?? false;
    },

    async search(e: KeyboardEvent) {
      if (e.key !== "Enter") {
        return;
      }

      const orders: Order[] = await api.searchConsignerConsumptionOrders(this.searchText);

      this.orderStates = orders.map((i) => ({
        id: i.id,
        detailsVisible: false,
      }));

      this.orders = orders;
    },

    clear() {
      this.orders = [];
      this.orderStates = [];
    },

    showItems(order: Order) {
      this.orderStates = this.orderStates.map((i) => ({
        ...i,
        detailsVisible: i.id === order.id ? !i.detailsVisible : i.detailsVisible,
      }));
    },

    addOrder(order: Order) {
      for (const item of order.items) {
        this.selection.push({
          item: item,
          id: item.id,
          orderNumber: order.orderNumber,
          supplier: order.supplier,
          itemName: item.name,
          currency: order.currency,
          priceDivision: 0.0,
          netto: item.netto,
          weight: item.totalWeight,
          weightUnit: item.weightUnit,
          storeInId: item.storeInId,
          certifNo: item.certifNo,
        });
      }
    },

    addOrderItem(item: OrderItem) {
      const order = this.orders.find((i) => i.id === item.orderId);

      if (!order) {
        throw new Error("Rendelés nem található!");
      }

      if (this.selection.some((s) => s.id === item.orderId)) {
        return;
      }

      this.selection.push({
        item: item,
        id: item.id,
        orderNumber: order.orderNumber,
        supplier: order.supplier,
        itemName: item.name,
        currency: order.currency,
        priceDivision: 0.0,
        weight: item.totalWeight,
        weightUnit: item.weightUnit,
        netto: item.netto,
        storeInId: item.storeInId,
        certifNo: item.certifNo,
      });
    },

    orderState(id: number) {
      return this.orderStates.find((i) => i.id === id) ?? undefined;
    },

    removeSelected(selectedItem: SelectedItem) {
      this.selection = this.selection.filter((i) => i.id !== selectedItem.id);
    },

    async submit() {
      const otherCost = this.store.targetItem;
      if (!otherCost) {
        return;
      }

      const success = await api.createConsignerConsumptionCost(this.store.invoiceID, {
        name: otherCost.itemName,
        gpartsID: otherCost.gpartID,
        cost: otherCost.unitPrice,
        taxID: otherCost.vatID,
        priceDivision: this.selection.map((i) => ({
          stockID: i.id,
          nettoValue: i.priceDivision,
        })),
      });

      if (success) {
        await this.store.reloadItems();
        eventBus.$emit("invoice-item:add");
      }
    },
  },

  watch: {
    selection(value: SelectedItem[]) {
      const otherItem = this.store.targetItem;
      if (!otherItem) {
        return;
      }

      const updatedSelection = this.store.calculateOrderStockDivision(otherItem, value);

      for (const item of updatedSelection) {
        const selectedItem = this.selection.find((i) => i.id === item.id);
        if (selectedItem) {
          selectedItem.priceDivision = item.priceDivision;
        }
      }
    },
  },
});
