










































import IconButton from "@/components/IconButton.vue";
import MoneyInput from "@/components/MoneyInput.vue";

import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";
import { DownPaymentItem } from "../models";
import { OtherItem } from "@/modules/invoiceItems/models";
import configuration, { AppConfiguration } from "@/models/Configuration";


interface Data {
  store: DownPaymentStore;
  searchText: string;

  configuration: AppConfiguration;
}

export default Vue.extend({
  components: { IconButton, MoneyInput },

  data: (): Data => ({
    store,
    searchText: "",

    configuration
  }),

  methods: {
    async search() {
      const text = this.searchText.trim();
      /* if (text.length < 3) return; */
      this.store.searchOtherItems(text);
    },

    clear() {
      this.store.searchResult = [];
      this.searchText = "";
    },

    alreadyAdded(item: OtherItem): boolean {
      return this.store.downPayment.invoiceItems.some(
        (x) =>
          (x.productID > 1 && x.productID === item.productID) || (x.gpartsID > 1 && x.gpartsID === item.gpartID)
      );
    },

    addItem(item: OtherItem) {
      const newItem: DownPaymentItem = new DownPaymentItem({
        invoiceID: Number(this.$route.params.invoiceId) || 0,
        gpartsID: item.gpartID,
        productID: item.productID,
        productName: `${item.code} ${item.itemName}`,
        serviceName: `${item.code} ${item.itemName}`,
        currency: this.store.downPayment.currency,
        netto: item.unitPrice * item.quantity,
        quantity: item.quantity,
        tax: this.store.taxes.find((x) => x.id === item.vatID) ?? this.store.taxes[0],
        unit: this.store.units.find((u) => u.name === item.unit) ?? this.store.units[0],
      });

      newItem.vat = newItem.netto * (newItem.tax?.degree ?? 0) / 100;
      newItem.brutto = newItem.netto + newItem.vat;

      this.store.downPayment.invoiceItems = [newItem, ...this.store.downPayment.invoiceItems];
      this.store.searchResult = [];
    },
  },

  computed: {
    disabled(): boolean {
      return (
        this.store.downPayment.isPayable || this.store.downPayment.isApproved || this.store.downPayment.isIncorrect
      );
    },
  },
});
