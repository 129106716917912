



















import Vue from "vue";
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import IncomingInvoiceRemark from "../models/IncomingInvoiceRemark";
import permStore, { PermissionStore } from "../../../services/permissions";
import eventBus from "@/services/eventBus";

interface Data {
  store: InvoiceFlowStore;
  permStore: PermissionStore;
  invoiceId: number;
  remark: IncomingInvoiceRemark;
}

interface Methods {
  createRemark(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  props: {
    invId: { type: Number },
  },

  data: () => ({
    store,
    permStore,
    invoiceId: 1,
    remark: new IncomingInvoiceRemark(),
  }),

  async beforeMount() {
    this.invoiceId = Number(this.$route.params.invoiceId);
  },

  methods: {
    async createRemark() {
      if (this.remark.remark.trim().length <= 1) return;

      const invoiceID = Number(this.$route.params.invoiceId);
      if (Number.isNaN(invoiceID)) return;

      this.remark.invId = invoiceID;
      this.remark.statusId = Number(this.store.step);

      await this.store.createRemark(this.remark).then(async () => {
        eventBus.$emit("flow-remark:created");
        this.remark = new IncomingInvoiceRemark({
          invId: invoiceID,
          statusId: this.store.step,
        });
        await this.store.loadRemarks(this.invoiceId);
      });

      await this.store.loadRemarks(invoiceID);
    },
  },

  computed: {
    isInvId() {
      return Number.isNaN(Number(this.$route.params.invoiceId));
    },

    isPageDisabled() {
      switch (this.store.step) {
        default:
        case 2:
          return !this.permStore.hasRecording() || !this.store.isEditingEnabled;
        case 3:
          return !this.permStore.hasFinancialRecording() || !this.store.isEditingEnabled;
        case 4:
          return !this.permStore.hasStockRelationRecording() || !this.store.isEditingEnabled;
        case 5:
          return !this.permStore.hasControlling() || !this.permStore.hasAccepting() || !this.store.isEditingEnabled;
        case 6:
          return !this.permStore.hasAccounting(); // || !this.store.isEditingEnabled;
      }
    },
  },
});
