export default interface SpecialTax {
  id: number;
  name: string;
  tax: number;
}

export const specialTaxDefaultProps: SpecialTax = {
  id: 0,
  name: "",
  tax: 1,
};

export const specialTaxTechProps: SpecialTax = {
  id: 1,
  name: "-",
  tax: 1,
};

export enum SpecialTaxes {
  // Közösségen belüli
  InCommunity = 3,
  // Közösségen kivímüli
  OutOfComunity = 4,
}
