














//! USAGE:
//  <check-box :selected="item.selected" />
//
//  or (on a kendo grid)
//
//  <check-box :selected="props.dataItem.selected" />
//  [{ width: 50, cell: "selectorTemplate", filterable: false, sortable: false }, ...columns]

import Vue from "vue";

export default Vue.extend({
  props: {
    selected: {type: Boolean, default: false},
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false}
  },
});
