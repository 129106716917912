import BankRemitItem from "./BankRemitItem";

export default interface BankRemitAndItems {
  id: number;
  bank: number;
  bankAccount: string;
  bankName: string;
  remitDate: Date | string;
  currency: string;
  filename: string;
  status: number;
  statusName: string;
  partnerPterm: boolean;
  insBalance: number;
  lastCloseBalance: number;
  items: BankRemitItem[];
  partnerName: string;
  createDate: Date;
  expanded: boolean;
  historyExpanded: boolean;
  exportExpanded: boolean;
  showPartners: boolean;
  path: string;
}

export interface BankRemitResponse {
  bankRemits: BankRemitAndItems[];
  count: number;
}


export const bankRemitAndItemsDefaultProps: BankRemitAndItems = {
    id: 1,
    bank: 1,
    bankAccount: "-",
    bankName: "-",
    remitDate: "2000. 01. 01",
    currency: "-",
    filename: "",
    status: 2,
    statusName: "Feldolgozás alatt",
    partnerPterm: false,
    insBalance: 0,
    lastCloseBalance: 0,
    items: [],
    partnerName: "",
    createDate: new Date(),
    expanded: false,
    historyExpanded: false,
    exportExpanded: false,
    showPartners: false,
    path: "",
}