

















import Vue from "vue";
import eventBus from "../services/eventBus";

interface Data {
  errorHandler: any;
  warnHandler: any;
  successHandler: any;
}

interface Methods {
  notifyTemplate(text: string, type: string, duration: number | undefined): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  data() {
    return {
      errorHandler: null,
      warnHandler: null,
      successHandler: null
    }
  },

  created() {
    this.errorHandler = (message: string, duration?: number) => {
      this.notifyTemplate(`${message}`, "custom-error", duration);
    }

    this.warnHandler = (message: string, duration?: number) => {
      this.notifyTemplate(`${message}`, "custom-warn", duration);
    }

    this.successHandler = (message: string, duration?: number) => {
      this.notifyTemplate(`${message}`, "custom-success", duration);
    }

    eventBus.$on("error", this.errorHandler);
    eventBus.$on("response-unavailable", this.errorHandler);
    eventBus.$on("warn", this.warnHandler);
    eventBus.$on("success", this.successHandler);
  },

  beforeDestroy() {
    eventBus.$off("error", this.errorHandler);
    eventBus.$off("response-unavailable", this.errorHandler);
    eventBus.$off("warn", this.warnHandler);
    eventBus.$off("success", this.successHandler);
  },

  methods: {
    notifyTemplate(text: string, type = "", duration = 10000) {
      duration = duration > 0 ? duration : 10000;
      this.$notify({
        text: text,
        type: type,
        duration: duration,
      });
    },
  },
});
