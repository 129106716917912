import AccountCodeEditModel, { accountCodeEditModelDefaultProps } from "../models/AccountCodeEditModel";
import AccountCodeEditModelApi from "./AccountCodeEditModelApi";
import busyIndication from "../../../utils/busyIndication";
import AccountCodeModel from "../models/AccountCodeModel";

export class AccountCodeEditModelStore{
    editModels: AccountCodeEditModel[] = []
    editModel: AccountCodeEditModel = {...accountCodeEditModelDefaultProps}

    @busyIndication()
    async loadAccountCodeEditModels() {
        this.editModels = (await AccountCodeEditModelApi.getAll()).map((x) => ({...x, inEdit: false}));
    }

    @busyIndication()
    async loadAccountCodeEditModel(accountCodeEditModelId: number, columnDictionaryId: number) {
        this.editModel = accountCodeEditModelId >= 1 ? await AccountCodeEditModelApi.get(accountCodeEditModelId, columnDictionaryId) : { ...accountCodeEditModelDefaultProps }
    }

    async saveEditModel(){
        if (this.editModel.accountCodeId == 0) {
            return;
        }

        try {
            console.log(this.editModel);
            await AccountCodeEditModelApi.update(this.editModel);
            this.loadAccountCodeEditModels();
        } catch (err) {
            console.error(err);
        }
    }

    async saveAccountCode(item: AccountCodeModel){
        await AccountCodeEditModelApi.updateV2(item);
    }

    async save(item: AccountCodeEditModel, update: Partial<AccountCodeEditModel>) {        
        Object.assign(item, update);
        this.editModel = {...item};
    }

    setEditing(item: AccountCodeEditModel) {
        item.inEdit = !item.inEdit;
        this.editModels = [...this.editModels];
    }
}

const store = new AccountCodeEditModelStore()
export default store