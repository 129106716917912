


































































































































































































import Vue from "vue";
import noticeStore, { NoticeStore } from "@/modules/notice/services/NoticeStore";
import NoticeItem from "../models/NoticeItem";
import MoneyInput from "@/components/MoneyInput.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";

interface Data {
  noticeStore: NoticeStore;
}

interface Methods {
  deleteItem(item: NoticeItem): void;
  quantityChanged(item: NoticeItem): void;
  unitPriceChanged(item: NoticeItem): void;
  bruttoChanged(item: NoticeItem): void;
}

interface Props {
  disabled: boolean;
  currency: string;
  invCurrency: string;
}

export default Vue.extend<Data, Methods, {}, Props>({
  props: {
    disabled: { default: false },
    currency: { default: "HUF" },
    invCurrency: { default: "HUF" },
  },

  components: {
    MoneyInput,
    MoneyDisplay
  },

  data: () => ({
    noticeStore,
  }),

  computed: {},

  methods: {
    deleteItem(item: NoticeItem) {
      const index = this.noticeStore.noticeItems.indexOf(item);
      if (index > -1) {
        this.noticeStore.noticeItems.splice(index, 1);
      }

      this.noticeStore.calculatePrices();
    },

    quantityChanged(item: NoticeItem) {
      item.netto = item.quantity * item.unitPrice;
      item.currNetto = item.netto;
      item.brutto = item.netto + item.unitVat * item.quantity;
      item.currBrutto = item.brutto;
      item.vat = item.unitVat * item.quantity;
      item.currVat = item.vat;

      this.noticeStore.calculatePrices();
    },

    unitPriceChanged(item: NoticeItem) {
      item.unitPrice = item.currUnitPrice;
      item.netto = item.quantity * item.unitPrice;
      item.currNetto = item.netto;
      item.brutto = Math.round(item.unitPrice * (1 + item.vatDegree / 100) * item.quantity);
      item.currBrutto = item.brutto;
      item.vat = item.brutto - item.netto;
      item.currVat = item.vat;
      item.unitVat = Math.round(item.vat / item.quantity);

      this.noticeStore.calculatePrices();
    },

    bruttoChanged(item: NoticeItem) {
      item.brutto = item.currBrutto;
      item.unitPrice = Math.round(item.brutto / item.quantity / (1 + item.vatDegree / 100));
      item.currUnitPrice = item.unitPrice;
      item.netto = item.unitPrice * item.quantity;
      item.currNetto = item.netto;
      item.vat = item.brutto - item.netto;
      item.currVat = item.vat;
      item.unitVat = Math.round(item.vat / item.quantity);

      this.noticeStore.calculatePrices();
    },
  },
});
