
























































































































































































































































































































































































































































































































































































import Fragment from "../../Fragment.vue";
import ApiPaginator from "./ApiPaginator.vue";
import IconButton from "../../IconButton.vue";
import Tooltip from "../../Tooltip.vue";

import Vue, { PropType } from "vue";
import ApiSearch, { ApiSearchColumn, ColumnType } from "../models/ApiSearch";
import { getAltRow, getHeaderClasses, getFieldClasses } from "@/utils/classes";
import { PaginatedPager } from "@/models/Pager";
import { FilteringModel, SortingModel, SortOrder } from "@/services/filtering";

import ServerSideSearchApi from "../services/ServerSideSearchApi";
import { debounce } from "debounce";
import eventBus from "@/services/eventBus";
import DateInput from "@/components/DateInput.vue";
import CheckBox from "@/components/CheckBox.vue";

import ApiSearchComponent from "@/components/apiSearch/components/ApiSearch.vue";
import dayjs from "dayjs";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";

interface Data {
  /** Local API instance */
  api: ServerSideSearchApi;
  /** CurrentPage, PageSize, SearchText, Filter, Sorting container */
  apiSearch: ApiSearch;
  /** Displayed items */
  items: object[];
  /** Total item count */
  totalItems: number;
  /** Displayed columns mapped as "ApiSearchColumn" */
  localColumns: ApiSearchColumn[];

  /** Dispaly in Table (or List) view - @default true */
  isTableView: boolean;
  /** Display filters - @default false */
  isFilterVisible: boolean;

  loading: boolean;
  eventListenersAdded: boolean;

  filters: boolean;
  selectedTransStats: any[];
  selectedCurrencies: any[];
  selectedPeriods: any[];
  selectedBookTypes: any[];

  isStorno: boolean;
  isMod: boolean;
  typeManual: boolean;
  typeAuto: boolean;
  startDate: string;
  endDate: string;
  today: string;
  periods: string[];
  periodSelectorVisible: boolean;
  periodResults: any[];
  searchText: string;

  selectedLedgers: any[];
}

interface Methods {
  showItem(item: any): boolean;
  refreshClick(): void;
  resetFilterClick(): void;
  resetSortClick(): void;

  /** Load items */
  changeHandler(): Promise<void>;
  pagerChanged(pager: PaginatedPager): void;

  getFieldOrder(field: string): SortOrder;
  toggleSort(field: string): void;

  getHeaderClasses(column: ApiSearchColumn): string;
  getFieldClasses(column: ApiSearchColumn): string;
  getAltRow(i: number): string;
  toggleExtraFilterVisibility(): void;
  addFilterWIdthEventListeners(): void;

  filterPeriod(e: any): void;
  filterCurrency(e: any): void;
  filterBookType(e: any): void;
  filterTransStat(e: any): void;
  selected(item: any): void;
  clear(): void;

  resetStartDate(): void;
  resetEndDate(): void;
  formatDate(date: string | Date): string;
  searchPeriodResults(e: any): void;
  addToSelected(e: any): void;
  setStatus(e: number): void;
  setSelected(e: any): void;
}

interface Computed {
  hasCustomTable: boolean;
  hasCustomTableBody: boolean;
  hasDetails: boolean;
  hasListView: boolean;
  hasFilters: boolean;
  hasNoItems: boolean;
  periodColumns: any[];
}

interface Props {
  /** Page title */
  title: string;

  //! Search API
  /** API path */
  url: string;
  /** Selectable page sizes - @default [10,20,50,100] */
  baseSizes: number[];
  /** Debounce delay - @default 0 */
  delay: number;

  hideOnEmpty: boolean;

  openDetailsByDefault: boolean;

  //! Top Buttons
  /** Display "Create" button with the given title */
  create: string;
  /** Display "Remove Filter" button - @default true */
  resetFilter: boolean;
  /** Display "Remove Sort" button - @default true */
  resetSort: boolean;
  /** Display "Search Box" - @default true */
  search: boolean;
  /** Displayed Placeholder inside the Search Box @default "Keresés" */
  searchPlaceholder: string;
  /** Display "Filter" button - @default false
   * @todo passed props
   * @todo default view */
  showFilter: boolean;
  /** Display "List/Table view" buttons - @default false
   * @todo passed props
   * @todo default view */
  multiView: boolean;
  /** Display "Refresh" button - @default true */
  refresh: boolean;
  /** Display divider - @default true */
  showDivider: boolean;
  /** Display extra date picker - @default false */
  showExtraDateFilter: boolean;

  //! Table
  /** Raw columns - @default [] */
  columns: ApiSearchColumn[];
  /** List should be filterable - @default true */
  filterable: boolean;
  /** List should be sortable - @default true */
  sortable: boolean;
  /** Same width for every col - @default false */
  fixedTable: boolean;
  /** Maximum column width for every col - @default 200 */
  maxColWidth: number;
  /** Replace "details" prop name - @default "details" */
  detailsField: string;
  /** Display the whole content on hover - @default false */
  displayTitles: boolean;
  hasPager: boolean;
  exclusiveItems: number[];
  /** Extra filters passed through as a prop */
  extraFilter: FilteringModel | undefined;
  multiselect: boolean;
  /** For setting the unique eventbus name **/
  name: string;
  /** Filter field grows with text input */
  filterFieldAutoGrow: boolean;

  transStats: any[];
  currencies: any[];
  bookTypes: any[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Fragment, ApiPaginator, IconButton, Tooltip, DateInput, CheckBox, /*ApiSearchComponent*/ },

  props: {
    title: String,

    hideOnEmpty: { type: Boolean, default: false },

    url: String,
    baseSizes: {
      type: Array as () => number[],
      default: () => [10, 20, 50, 100],
    },
    delay: { type: Number, default: 0 },

    openDetailsByDefault: { type: Boolean, default: false },

    create: String,
    resetFilter: { type: Boolean, default: true },
    resetSort: { type: Boolean, default: true },
    search: { type: Boolean, default: true },
    searchPlaceholder: { type: String, default: "Keresés" },
    showFilter: { type: Boolean, default: false },
    multiView: { type: Boolean, default: false },
    refresh: { type: Boolean, default: true },
    showDivider: { type: Boolean, default: true },
    showExtraDateFilter: { type: Boolean, default: false },
    filterFieldAutoGrow: { type: Boolean, default: true },

    columns: {
      type: Array as () => ApiSearchColumn[],
      default: () => new Array<ApiSearchColumn>(),
    },
    filterable: { type: Boolean, default: true },
    multiselect: { type: Boolean, default: false },
    sortable: { type: Boolean, default: true },
    fixedTable: { type: Boolean, default: false },
    extraFilter: { type: Object as () => FilteringModel, default: undefined },
    maxColWidth: { type: Number, default: 200 },
    detailsField: { type: String, default: "details" },
    displayTitles: { type: Boolean, default: false },
    hasPager: { type: Boolean, default: true },
    name: { type: String, default: "api-search-page" },
    exclusiveItems: {
      type: Array as () => number[],
      default: () => {
        return [];
      },
    },

    transStats: {
      type: Array as () => any[],
      default: () => [ { id: 1, name: "-"}, ],
    },

    currencies: {
      type: Array as () => any[],
      default: () => [ { id: 1, name: "-"}, ],
    },

    bookTypes: {
      type: Array as () => any[],
      default: () => [ { id: 1, name: "-"}, ],
    },
  },

  data: (): Data => ({
    api: new ServerSideSearchApi(),
    apiSearch: new ApiSearch(),
    items: [],
    totalItems: 0,
    localColumns: [],
    isTableView: true,
    isFilterVisible: false,
    loading: false,
    eventListenersAdded: false,
    filters: false,
    selectedTransStats: [],
    selectedCurrencies: [],
    selectedPeriods: [],
    selectedBookTypes: [],
    isStorno: false,
    isMod: false,
    typeManual: true,
    typeAuto: false,
    startDate: dayjs(new Date("2000-01-01")).format("YYYY-MM-DD"),
    endDate: dayjs(new Date()).format("YYYY-MM-DD"),
    today: dayjs(new Date()).format("YYYY-MM-DD"),
    periods: [],
    periodSelectorVisible: false,
    periodResults: [],
    searchText: "",

    selectedLedgers: [],
  }),

  async beforeMount() {
    /** Create new ApiSearchColumn from params */
    
    this.localColumns = this.$props.columns.map((c: unknown) => new ApiSearchColumn(c as Partial<ApiSearchColumn>));

    // TODO: default filter support
    let filter = this.$props.filterable
      ? new FilteringModel().values(
          this.localColumns
            .filter((x) => x.filterable && !x.multiselect)
            .map((x) => ({ field: x.field ?? "" }))
            .filter((x) => !!x.field)
        )
      : undefined;

    const extraFilterCols = this.localColumns.filter((x) => x.extraFilterValue);
    extraFilterCols.forEach((e) => {
      if (filter) {
        filter = filter.value(e.field ?? "", e.extraFilterValue ?? "");
      }
    });

    this.localColumns
      .filter((x) => x.multiselect)
      .forEach((e) => {
        if (filter) {
          filter = filter.multiSelect(
            e.multiselectField ?? "",
            e.multiselectOptions ?? [],
            typeof e.multiselectSelect === "number" && e.multiselectSelect > 1
              ? [e.multiselectSelect]
              : e.multiselectOptions?.map((x) => x.value)
          );
        }
      });

    this.localColumns
      .filter((x) => x.range)
      .forEach((e) => {
        if (filter) {
          filter = filter.range(e.field ?? "");
        }
        // console.log(e);
      });

    // TODO: default filter support
    const sort = this.sortable
      ? new SortingModel().capitalize().fields(
          this.localColumns
            .filter((x) => x.sortable)
            .map((x) => ({ field: x.field ?? "", text: x.title ?? "" }))
            .filter((x) => !!x.field && !!x.text)
        )
      : undefined;

    /** Init ApiSearch */
    this.apiSearch = new ApiSearch({
      pageSize: this.$props.hasPager ? this.$props.baseSizes[0] : 1000,
      filter: filter,
      extraFilter: this.$props.extraFilter,
      sort: sort,
      searchText: "",
    });

    if (this.$props.url != "") {
      await this.changeHandler(); // api call to the backend
    }
    this.changeHandler = debounce(this.changeHandler, this.$props.delay);
  },

  watch: {
    exclusiveItems() {
      this.changeHandler();
    },
    /** Load items on Filter change */
    "apiSearch.searchText": {
      deep: true,
      handler(_: FilteringModel | undefined, old: FilteringModel | undefined) {
        // block refresh while it's undefined
        if (!old) return;
        this.changeHandler();
      },
    },

    /** Load items on Filter change */
    "apiSearch.filter": {
      deep: true,
      handler(_: FilteringModel | undefined, old: FilteringModel | undefined) {
        // block refresh while it's undefined
        if (!old) return;
        this.changeHandler();
      },
    },

    /** Load items Sort change */
    "apiSearch.sort": {
      deep: true,
      handler(_: SortingModel | undefined, old: SortingModel | undefined) {
        // block refresh while it's undefined
        if (!old) return;
        this.changeHandler();
      },
    },
  },

  created() {
    eventBus.$on(`${this.$props.name}:setSelected`, this.setSelected);

    eventBus.$on(`${this.$props.name}:get-items`, () => {
      this.$emit("items", this.items);
    });
    eventBus.$on(`${this.$props.name}:set-items`, (items) => {
      this.items = items;
    });

    eventBus.$on(`${this.$props.name}:reload`, () => {
      this.changeHandler();
    });

    eventBus.$on(`${this.$props.name}:close-details`, () => {
      this.items.forEach((item: unknown) => {
        Object.defineProperty(item, this.$props.detailsField, {
          value: false,
          writable: true,
          enumerable: true,
          configurable: true,
        });
      });
    });
  },

  methods: {
    async setStatus(status: number) {
      const success = await http.post<ApiResponse<boolean>>(`ledger/set/status/${status}`, { selectedLedgers: this.items.filter((x: any) => x.selectedStatus) }).then(handleApiResponse);
      if (success) {
        await this.changeHandler();
      }
    },

    setSelected(e: any) {
      e.selectedStatus = !e.selectedStatus;
    },

    addToSelected(e: any) {
      if (e.selected) {
        this.periods = this.periods.filter((x) => x != e.name);
      }
      else {
        this.periods = [...this.periods, e.name]
      }

      e.selected = !e.selected;
    },

    async searchPeriodResults(e: any) {
      if (e.key != "Enter") {
        return;
      }

      this.periodResults = await http.post<ApiResponse<any[]>>("ledger/search/periods", { searchText: this.searchText }).then(handleApiResponse);
    },

    formatDate(date: string | Date){
      return dayjs(date).format("YYYY. MM. DD.");
    },

    resetEndDate(){
      this.endDate = dayjs(new Date()).format("YYYY-MM-DD");
    },

    resetStartDate(){
      this.startDate = dayjs(new Date("2000-01-01")).format("YYYY-MM-DD");
    },

    selected(item: any) {
      this.selectedPeriods = [ item ];
    },

    clear(){
      this.selectedPeriods = [];
    },

    filterPeriod(i: any){
      this.selectedPeriods = this.selectedPeriods.filter((x) => x != i.name);
      this.selectedPeriods = [...this.selectedPeriods];
    },

    filterCurrency(i: any) {
      this.selectedCurrencies = this.selectedCurrencies.filter((x) => x != i.currency);
      this.selectedCurrencies = [...this.selectedCurrencies];
    },

    filterBookType(i: any) {
      this.selectedBookTypes = this.selectedBookTypes.filter((x) => x != i.name);
      this.selectedBookTypes = [...this.selectedBookTypes];
    },

    filterTransStat(i: any) {
      this.selectedTransStats = this.selectedTransStats.filter((x) => x != i.name);
      this.selectedTransStats = [...this.selectedTransStats];
    },

    addFilterWIdthEventListeners(){
      if (this.filterFieldAutoGrow && !this.eventListenersAdded){

      this.eventListenersAdded = true;
      const inputs = document.querySelectorAll('.filter-field input');

      inputs.forEach(i => {
        let defaultWidth = 0; 
        const element = (i as any);

        const dummySpan = document.createElement('span');
        dummySpan.style.position = 'absolute';
        dummySpan.style.left = '-99999999px';
        dummySpan.style.fontSize = '12px';
        dummySpan.classList.add('maxWidth');
        element.parentNode.appendChild(dummySpan);

        i.addEventListener('input', () => {
          if (defaultWidth == 0){
            defaultWidth = i.clientWidth;
          }

          dummySpan.innerHTML = element.value.replace(/\s/g, '&nbsp;');
          const newWidth = dummySpan.offsetWidth;

          if (newWidth > defaultWidth){
            element.style.width = newWidth + 'px';
          }
        });
      });
    }
    },

    showItem(item: any) {
      if (this.$props.exclusiveItems.length < 0 || (item?.id ?? false)) {
        return true;
      } else {
        if (this.$props.exclusiveItems.includes(Number(item.id))) {
          return false;
        } else return true;
      }
    },

    refreshClick(): void {
      eventBus.$emit("api-pager:set-page", 1);
      this.changeHandler();
    },

    resetFilterClick(): void {
      this.apiSearch.resetFiltering();
      this.$emit("reset-filtering");
    },

    resetSortClick(): void {
      this.apiSearch.resetSorting();
      this.$emit("reset-sorting");
    },

    async changeHandler(): Promise<void> {
      const extrafilter = new FilteringModel()
        .multiSelect("selectedPeriods", this.selectedPeriods, true)
        .multiSelect("selectedTransStats", this.selectedTransStats, true)
        .multiSelect("selectedCurrencies", this.selectedCurrencies, true)
        .multiSelect("selectedBookTypes", this.selectedBookTypes, true)
        .value("isStorno", this.isStorno.toString())
        .value("isMod", this.isMod.toString())
        .value("typeAuto", this.typeAuto.toString())
        .value("typeManual", this.typeManual.toString())
        .value("startDate", dayjs(this.startDate).format("YYYY-MM-DD"))
        .value("endDate", dayjs(this.endDate).format("YYYY-MM-DD"))
      ;

      const filter = this.apiSearch?.filter?.fromFilter(extrafilter.toObject());
      this.apiSearch.filter = filter;

      //! Can be cancelled
      this.loading = true;
      try {
        const result = await this.api.post(this.$props.url, this.apiSearch);
        // console.log(result);
        if (result) {
          this.totalItems = result.totalCount;
          this.items = this.hasDetails
            ? result.currentPage.map((i) => {
                // Add custom detailsField prop
                Object.defineProperty(i, this.$props.detailsField, {
                  value: this.$props.openDetailsByDefault,
                  writable: true,
                  enumerable: true,
                  configurable: true,
                });

                return i;
              })
            : result.currentPage;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        console.log(this.apiSearch);
        this.loading = false;
      }

      this.$emit("itemCount", this.items?.length ?? 0);
      this.$emit("items", this.items);

      this.addFilterWIdthEventListeners();
    },

    pagerChanged(pager: PaginatedPager): void {
      if (
        // block double API calls from: search change -> pager update
        pager.totalItems === 0 ||
        (this.apiSearch.currentPage === pager.currentPage && this.apiSearch.pageSize === pager.pageSize)
      )
        return;

      this.apiSearch.currentPage = pager.currentPage;
      this.apiSearch.pageSize = pager.pageSize;
      this.changeHandler();
    },

    getFieldOrder(field: string): SortOrder {
      return this.apiSearch.sort?.definitions.find((x) => x.field === field)?.order ?? SortOrder.None;
    },

    toggleSort(field: string): void {
      this.apiSearch.sort?.toggle(field);
    },

    getHeaderClasses,
    getFieldClasses,
    getAltRow,

    toggleExtraFilterVisibility() {
      this.isFilterVisible = !this.isFilterVisible;
      this.$emit("efilter-toggle", this.isFilterVisible);
    },
  },

  computed: {
    periodColumns: () => [
      {
        cell: "addTemplate",
        width: 50,
        filterable: false
      },
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "name",
        title: "Periódus"
      },
      {
        field: "startDate",
        title: "Kezdődátum",
        cell: "startDateTemplate"
      },
      {
        field: "endDate",
        title: "Végdátum",
        cell: "endDateTemplate"
      },
      {
        field: "mustCloseString",
        title: "Zárás kötelező?"
      },
      {
        field: "status",
        title: "Állapot"
      },
      {
        field: "creator",
        title: "Létrehozó"
      },
    ],

    hasNoItems(): boolean {
      return (this.items?.length ?? 0) < 1;
    },

    hasCustomTable(): boolean {
      return !!this.$scopedSlots.customTable || !!this.$slots.customTable;
    },

    hasCustomTableBody(): boolean {
      return !!this.$scopedSlots.customTableBody || !!this.$slots.customTableBody;
    },

    hasDetails(): boolean {
      return !!this.$scopedSlots.details || !!this.$slots.details;
    },

    hasListView(): boolean {
      return !!this.$scopedSlots.listView || !!this.$slots.listView;
    },

    hasFilters(): boolean {
      return !!this.$scopedSlots.filters || !!this.$slots.filters;
    },
  },
});
