import http, { handleApiResponse, checkResponse } from "@/services/http";
import NoticeDisplay from "@/modules/notice/models/NoticeDisplay";
import InvoiceStatus from "@/modules/notice/models/InvoiceStatus";
import AccountStatus from "@/modules/notice/models/AccountStatus";
import InvoiceGroupDisplay from "@/modules/invoiceGroup/models/InvoiceGroupDisplay";
import User from "@/modules/invoiceGroup/models/User";
import PaymentModeDisplay from "@/modules/paymentMode/models/PaymentModeDisplay";
import Language from "@/modules/notice/models/Language";
import NoticeType from "@/modules/notice/models/NoticeType";
import NoticeGenoType from "@/modules/notice/models/NoticeGenoType";
import Country from "@/modules/cashPoint/models/Country";
import NoticeDetails from "@/modules/notice/models/NoticeDetails";
import ApiResponse from "@/models/ApiResponse";
import Notice, { noticeDefaultProps } from "../models/Notice";
import PartBank from "@/modules/cashPoint/models/PartBank";
import GPartsDisplay from "@/modules/notice/models/GPartsDisplay";
import NoticeItem from "../models/NoticeItem";
import NoticeAndItems from "../models/NoticeAndItems";
import NoticeDivitem from "../models/NoticeDivitem";
import StornoOptions from "../models/StornoOptions";
import PayResult from "@/modules/kanri/models/PayResult";

export default class NoticeApi {
  static getAll(): Promise<NoticeDisplay[]> {
    return http.get<ApiResponse<NoticeDisplay[]>>(`notice`).then(handleApiResponse);
  }

  static getNoticeDetails(partnerId: number, noticeId: number): Promise<NoticeDetails> {
    return http.get<ApiResponse<NoticeDetails>>(`notice/details/${partnerId}/${noticeId}`).then(handleApiResponse);
  }

  static deleteNoto(noticeId: number): Promise<any> {
    return http.delete<ApiResponse<any>>(`notice/delete/${noticeId}`).then(handleApiResponse);
  }

  static pay(noticeId: number): Promise<any> {
    return http.post<ApiResponse<PayResult>>(`cash-statement/generate-cash-statement/NOTO/${noticeId}`).then(handleApiResponse);
  }

  static payWithCashPoint(noticeId: number, cashPoint: number): Promise<any> {
    return http.post<ApiResponse<PayResult>>(`cash-statement/generate-cash-statement/NOTO/${noticeId}/${cashPoint}`).then(handleApiResponse);
  }

  static getInvoiceStatuses(): Promise<InvoiceStatus[]> {
    return http.get<ApiResponse<InvoiceStatus[]>>(`invoice-status`).then(handleApiResponse);
  }

  static getAccountStatuses(): Promise<AccountStatus[]> {
    return http.get<ApiResponse<AccountStatus[]>>(`account-status`).then(handleApiResponse);
  }

  static getInvoiceGroups(): Promise<InvoiceGroupDisplay[]> {
    return http.get<ApiResponse<InvoiceGroupDisplay[]>>(`invoice-group`).then(handleApiResponse);
  }

  static getUsers(): Promise<User[]> {
    return http.get<ApiResponse<User[]>>(`users`).then(handleApiResponse);
  }

  static getPaymentModes(): Promise<PaymentModeDisplay[]> {
    return http.get<ApiResponse<PaymentModeDisplay[]>>(`payment-mode`).then(handleApiResponse);
  }

  static getLanguages(): Promise<Language[]> {
    return http.get<ApiResponse<Language[]>>(`language`).then(handleApiResponse);
  }

  static getNoticeTypes(): Promise<NoticeType[]> {
    return http.get<ApiResponse<NoticeType[]>>(`notice-type`).then(handleApiResponse);
  }

  static getNoticeGenoTypes(): Promise<NoticeGenoType[]> {
    return http.get<ApiResponse<NoticeGenoType[]>>(`notice-geno-type`).then(handleApiResponse);
  }

  static getCurrencies(): Promise<Country[]> {
    return http.get<ApiResponse<Country[]>>(`currency`).then(handleApiResponse);
  }

  static getPartBanks(isAlive: boolean): Promise<PartBank[]> {
    return http.get<ApiResponse<PartBank[]>>(`partBank/${isAlive}`).then(handleApiResponse);
  }

  static getGParts(name: string): Promise<GPartsDisplay[]> {
    return http.get<ApiResponse<GPartsDisplay[]>>(`gparts/${name}`).then(handleApiResponse);
  }

  static async create(notice: Notice, items: NoticeItem[]): Promise<number> {
    return http
      .post<ApiResponse<number>>(`notice`, { notice: notice, items: items })
      .then(handleApiResponse);
  }

  static update(notice: Notice, items: NoticeItem[]): Promise<boolean> {
    return http.put(`notice`, { notice: notice, items: items }).then(checkResponse);
  }

  static get(noticeId: number): Promise<NoticeAndItems> {
    return http.get<ApiResponse<NoticeAndItems>>(`notice/${noticeId}`).then(handleApiResponse);
  }

  static getByObjectId(objectId: number): Promise<NoticeAndItems> {
    return http.get<ApiResponse<NoticeAndItems>>(`notice/kanri/${objectId}`).then(handleApiResponse);
  }

  static getRate(currency: string, shipDate: string): Promise<number> {

    return http.get<ApiResponse<any>>(`currency/rate-date/`, {
      params: {
        c: currency,
        d: shipDate,
      },
    }).then(handleApiResponse);
  }

  static async getDivitems(userid: number): Promise<NoticeDivitem[]> {
    return http.get<ApiResponse<NoticeDivitem[]>>(`division-item/noto/${userid}`).then(handleApiResponse);
  }

  static async print(noticeId: number): Promise<string> {
    return http.put<ApiResponse<string>>(`notice/print/${noticeId}`).then(handleApiResponse); // visszaadja a k.sz-ből kapott base64 stringet
  }

  static async storno(noticeId: number, options: StornoOptions): Promise<number> {
    return await http
      .put<ApiResponse<number>>(`notice/storno`, {
        noticeID: noticeId,
        options: { ShipDate: options.shipDate },
      })
      .then(handleApiResponse);
  }
}
