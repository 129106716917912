

















































































































































import Vue from "vue";
import ExperimentalInvoice, {
  experimentalInvoiceDefaultProps,
  ExperimentalInvoiceClass,
} from "../models/ExperimentalInvoice";
import store, {
  PaymentModeStore,
} from "@/modules/paymentMode/services/PaymentModeStore";
import expStore, { ExperimentalInvoiceStore } from "../services/store";
import DateInput from "@/components/DateInput.vue";
import http, { handleApiResponse } from "@/services/http";
import cashStatementStore, {
  CashStatementStore,
} from "@/modules/cashStatement/services/store";
import ApiResponse from "@/models/ApiResponse";
import dayjs from "dayjs";
import eventBus from "@/services/eventBus";
import EmailPicker from "../components/EmailPicker.vue";
import Email from "@/modules/cashStatement/models/Email";

export default Vue.extend({
  props: {
    invoice: {
      type: Object,
      default: experimentalInvoiceDefaultProps,
    },
    inStatement: {
      type: Boolean,
      default: true
    }
  },

  components: { DateInput, EmailPicker },

  async beforeMount() {
    this.loading = true;
    try {
      store.loadPaymentModesWithoutBusy();
    } finally {
      this.loading = false;
    }
  },

  methods: {
    setIsEInvoice() {
      this.invoice.isEInvoice = !this.invoice.isEInvoice;
      this.$emit("input", this.invoice);
    },

    getInvoice(invoice: object) {
      return new ExperimentalInvoiceClass(invoice);
    },

    emailInput(inv: ExperimentalInvoiceClass) {
      this.$emit("input", inv);
    },

    setEInvoiceFalse() {
      const tempInvoice = experimentalInvoiceDefaultProps;
      Object.assign(tempInvoice, this.invoice);
      tempInvoice.isEInvoice = false;
      this.$emit("input", tempInvoice);
      /* this.showEInvoiceWarning = false; */
    },

    startGeneration() {
      try {
        this.loading = true;
        if (this.invoice.pmode === "Átutalás" || !this.inStatement) {
          this.showQuestion = false;
          this.generateInvoice(this.invoice);
          return;
        }
        this.showQuestion = true;
        /* this.showEInvoiceWarning = true; */
      } catch (ex) {
        console.log(ex);
      } finally {
        this.loading = false;
      }
    },

    setPdays() {
      /* this.showEInvoiceWarning = true; */
      const dayDiff = dayjs(this.invoice.pterm).diff(dayjs(new Date()), "day");
      this.invoice.pdays = dayDiff + 1;
    },

    setPterm(days: number) {
      const now = new Date();
      try {
        const newDate = dayjs()
          .set("date", Number(Number(now.getDate()) + Number(days)))
          .toDate();
        this.invoice.pterm = newDate;
        this.$emit("input", this.invoice);
      } catch (ex) {
        console.log(ex);
      }
    },

    async generateInvoice(invoice: ExperimentalInvoiceClass) {
      try {
        this.loading = true;
        invoice.pdays = Number(invoice.pdays);
        /* this.showEInvoiceWarning = false; */
        this.showQuestion = false;
        await http
          .post<ApiResponse<number>>(
            `experimental-invoices/create-invoice`,
            invoice
          )
          .then(handleApiResponse)
          .then((x) => {
            this.$emit("generated", {
              extIdInvoice: x,
              cashDocId: 1,
              item: invoice,
              isCashDoc: false,
            });
            this.expStore.expReportSrc = new Uint8Array();
            eventBus.$emit("success", "Sikeres végszámla kiállítás!")
          });
      } catch (ex) {
        console.log(ex);
        eventBus.$emit("error", "Nem sikerült kiállítani a végszámlát!")
      } finally {
        this.loading = false;
        
        setTimeout(()=>{
          this.$emit("credit-card-pay", false);
        }, 1000);
      }
    },

    async generateInvoiceThenCashDoc(invoice: ExperimentalInvoiceClass) {
      this.$emit("credit-card-pay", false);

      if (invoice.pmode === "Bankkártya") {
        this.$emit("credit-card-pay", true);
        this.generateInvoice(invoice);
        return;
      }

      try {
        this.loading = true;
        invoice.pdays = Number(invoice.pdays);
        /* this.showEInvoiceWarning = false; */
        this.showQuestion = false;

        await http
          .post<ApiResponse<Record<string, number>>>(
            `experimental-invoices/create-invoice-with-cash-doc`,
            {
              invoice,
              statement: cashStatementStore.cashStatement.id,
            }
          )
          .then(handleApiResponse)
          .then((x: Record<string, number>) => {
            this.$emit("generated", {
              extIdInvoice: x["extIdInvoice"],
              cashDocId: x["cashDocId"],
              item: invoice,
              isCashDoc: true,
            });
            this.expStore.expReportSrc = new Uint8Array();
            return x;
          });
      } catch (ex) {
        console.log(ex);
      } finally {
        this.loading = false;
      }
    },
  },

  data: () => {
    return {
      store,
      cashStatementStore,
      showQuestion: false,
      expStore,
      /* showEInvoiceWarning: true, */
      emails: new Array<Email>(),
      isEmailSavable: false,
      loading: false,
    };
  },
});
