import OverSummary from "../models/OverSummary";
import OverSummaryApi from "../services/OverSummaryApi";
import eventBus from "@/services/eventBus";

export class OverSummaryStore {
    overSummaries: OverSummary[] = []

    async getSummariesByPartner(partnerId: number) {
        this.overSummaries = (await OverSummaryApi.getSummariesByPartner(partnerId)).map((x: OverSummary) => ({ ...x, isSelected: false, detailsVisible: false }));
    }

    async createOverSummary(list: OverSummary[], date: string) {
        return await OverSummaryApi.createOverSummary(list, date);
    }
}

const store = new OverSummaryStore()
export default store