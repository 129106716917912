import { OperelPermissions } from "./services/permissions";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import kendo from "./plugins/kendo";
import Notifications from "vue-notification";
import velocity from "velocity-animate";
import chatHub from "./plugins/chatHub";
import invoiceTaskUpdateHub from "./plugins/invoiceTaskUpdateHub";
import auth from "./services/auth";
import * as filters from "./filters";
import { createEventBus } from "./services/eventBus";

Vue.config.productionTip = false;
Vue.use(kendo);
Vue.use(chatHub);
Vue.use(invoiceTaskUpdateHub);
Vue.use(Notifications, { velocity });

filters.registerAll();

auth
  .authorize()
  .then(() => {
    if (auth.isLoggedIn) {
      auth.passwordCheck();
    }
  })
  .then(() => {
    createEventBus();
    OperelPermissions.initialize({});

    return new Vue({
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount("#finance-app");
  });
