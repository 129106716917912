export default interface PagingElement {
    documentsCount: number;
    currentPageSize: number;
    currentPageNumber: number;
    firstPageNumberButtonVisible: boolean;
    firstPageNumberButtonValue: number;
    secondPageNumberButtonVisible: boolean;
    secondPageNumberButtonValue: number;
    thirdPageNumberButtonVisible: boolean;
    thirdPageNumberButtonValue: number;
    fourthPageNumberButtonVisible: boolean;
    fourthPageNumberButtonValue: number;
    fifthPageNumberButtonVisible: boolean;
    fifthPageNumberButtonValue: number;
  }
  
  export const pagingElementDefaultProps: PagingElement = {
    documentsCount: 0,
    currentPageSize: 5,
    currentPageNumber: 1,
    firstPageNumberButtonVisible: true,
    firstPageNumberButtonValue: 1,
    secondPageNumberButtonVisible: true,
    secondPageNumberButtonValue: 2,
    thirdPageNumberButtonVisible: true,
    thirdPageNumberButtonValue: 3,
    fourthPageNumberButtonVisible: true,
    fourthPageNumberButtonValue: 4,
    fifthPageNumberButtonVisible: true,
    fifthPageNumberButtonValue: 5,
  };
  