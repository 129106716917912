import InvoiceItemDimension from "@/modules/invoiceItemDimension/models/InvoiceItemDimension";

export interface InvoiceRequest {
  id: number;
  deleted: boolean;
  invId: number;
  insUserId: number;
  insDate: null | string;
  remark: string;
  invApprStatus: number;
  modDate: null | string;
  modTime: null | string;
  modDateTime: null | string;
  isFullFillReq: boolean;

  name: string | undefined;
  statusName: string | undefined;
  deleting: boolean;

  isValueIndependent: boolean;
  minAmount: number;
  maxAmount: number;
}

const invoiceRequestDefaultProps: InvoiceRequest = {
  id: 0,
  deleted: false,
  invId: 1,
  insUserId: 1,
  insDate: null,
  remark: "",
  invApprStatus: 1,
  modDate: null,
  modTime: null,
  modDateTime: null,
  isFullFillReq: false,

  name: "",
  statusName: "",
  deleting: false,

  isValueIndependent: false,
  minAmount: 0,
  maxAmount: 0,
};

// control requests
export interface InvoiceControlRequest extends InvoiceRequest {
  controllerId: number;
  controlledDate: null | string;
}

export const invoiceControlRequestDefaultProps: InvoiceControlRequest = {
  ...invoiceRequestDefaultProps,
  controllerId: 1,
  controlledDate: null,
};

export interface InvoiceControlRequestResponse {
  requests: InvoiceControlRequest[];
  usersWithoutEmail: string;
}

export interface InvoiceItemApproval {
  approvalRequestID: number;
  invoiceItemID: number;
}

export interface InvoiceItemDimensionApproval {
  approvalRequestID: number;
  dimensionID: number;
  dimension: InvoiceItemDimension;
}

// accept requests
export interface InvoiceAcceptRequest extends InvoiceRequest {
  acceptorId: number;
  acceptedDate: null | string;
  accepted: boolean;
  itemApprovals: InvoiceItemApproval[];
  dimensionApprovals: InvoiceItemDimensionApproval[];
}

export const invoiceAcceptRequestDefaultProps: InvoiceAcceptRequest = {
  ...invoiceRequestDefaultProps,
  acceptorId: 1,
  acceptedDate: null,
  itemApprovals: [],
  dimensionApprovals: [],
  accepted: false,
};

export interface InvoiceAcceptRequestResponse {
  requests: InvoiceAcceptRequest[];
  usersWithoutEmail: string;
}

export const isRequestAccepted = (req: InvoiceRequest) => {
  return req.invApprStatus == 2 || req.invApprStatus == 3
}

export interface AccCode{
  id: number;
  code: string;
  codeDemand: string;
  detailedType: number;
}

export interface InvoiceSimpleEditRequest{
  invoiceId: number;
  type: number;
  subType: number;
  paymentMode: number;
  division: number;
  accInvGroup: number;
  remark: string;
  payTerm: string;
  accountItems: AccCode[];
  bankAccount: number;
}