import InvoiceItemDimensionApi from "./InvoiceItemDimensionApi";
import InvoiceItemDimension, { invoiceItemDimensionDefaultProps } from "../models/InvoiceItemDimension";
import InvoiceItemOtherDimension, { invoiceItemOtherDimensionDefaultProps } from "../models/InvoiceItemOtherDImension";
import InvItemAndDimensionsById from "@/modules/invoiceFlow/models/InvItemAndDimensionsById";
import InvItemAndDimensionsByIdHun, { invItemAndDimensionsByIdHunDefaultProps } from "@/modules/invoiceFlow/models/InveItemAndDimensionsByIdHun";
import busyIndication from "../../../utils/busyIndication";
import GeneralIngoingInvoiceItemMinEditModel from "@/modules/generalIngoingInvoice/models/GeneralIngoingInvoiceItemMinEditModel";
import eventBus from "@/services/eventBus";

export class InvoiceItemDimensionStore {
    invoiceItemDimensions: InvoiceItemDimension[] = []
    invoiceItemDimension: InvoiceItemDimension = { ...invoiceItemDimensionDefaultProps }
    preFilterStations = false;

    invItemAndDimensionsById: InvItemAndDimensionsById[] = [];
    invItemAndDimensionsByIdHun: InvItemAndDimensionsByIdHun[] = [];

    setPreFilterStations() {
        this.preFilterStations = !this.preFilterStations;
    }

    async loadInvItemAndDimensionsById(invId: number) {
        this.invItemAndDimensionsById = await InvoiceItemDimensionApi.loadInvItemAndDimensionsById(invId)
        //console.log(this.invItemAndDimensionsById.map(x => JSON.stringify(x)))
    }

    async loadInvoiceItemDimensionsByInvItemId(invItemId: number, isCurr: boolean) {
        this.invoiceItemDimensions = (await InvoiceItemDimensionApi.getAllByInvItemId(invItemId, isCurr)).map((x) => ({ ...x, deleting: false, inEdit: false, otherDimensionDetail: true, otherDimensionDetailOrDelete: true }));
        return this.invoiceItemDimensions;
    }

    async save(item: InvoiceItemDimension, update: Partial<InvoiceItemDimension>) {
        Object.assign(item, update);
        this.invoiceItemDimension = { ...item };
    }

    async updateInvoiceItemDimension(item: InvoiceItemDimension, preAccount: boolean, isCurr: boolean) {
        this.invoiceItemDimension = { ...item };
        if (this.invoiceItemDimension.id != 0) {
            try {
                await InvoiceItemDimensionApi.update(this.invoiceItemDimension, preAccount, isCurr);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async createInvoiceItemDimension(item: InvoiceItemDimension, preAccount: boolean, isCurr: boolean) {
        try {
            this.invoiceItemDimension = { ...item }
            this.invoiceItemDimension.otherDimensions[0] = { ...invoiceItemOtherDimensionDefaultProps }
            const ret = Number(await InvoiceItemDimensionApi.create(this.invoiceItemDimension, preAccount, isCurr));

            return ret;
        } catch (error) {
            console.error(error);
        }
    }

    setDeleting(item: InvoiceItemDimension) {
        item.otherDimensionDetailOrDelete = true;
        item.deleting = !item.deleting;
        this.invoiceItemDimensions = [...this.invoiceItemDimensions];
    }

    setEditing(item: InvoiceItemDimension) {
        item.inEdit = !item.inEdit;
        this.invoiceItemDimensions = [...this.invoiceItemDimensions];
    }

    async deleteInvoiceItemDimension(itemId: number, invGroup: number, preAccount: boolean) {
        try {
            const ret = (await InvoiceItemDimensionApi.delete(itemId, invGroup, preAccount));
            return ret;
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async clearAllDimensions(invId: number) {
        try {
            await InvoiceItemDimensionApi.clearAllDimensions(invId);
            eventBus.$emit("success", "Felosztás törölve!");
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    //dimensions
    async createOtherDimensionVersion2(dim: InvoiceItemOtherDimension, preAccount: boolean) {
        try {
            const ret = Number(await InvoiceItemDimensionApi.createOtherDimensionVersion2(dim, preAccount));
            return ret;
        } catch (error) {
            console.error(error)
        }
    }

    async createOtherDimension(item: InvoiceItemDimension, preAccount: boolean) {
        try {
            this.invoiceItemDimension = { ...item };

            const ret = Number(await InvoiceItemDimensionApi.createOtherDimension(this.invoiceItemDimension, preAccount));
            return ret;
        } catch (error) {
            console.error(error)
        }
    }

    async deleteOtherDimension(itemId: number, preAccount: boolean) {
        try {
            const ret = (await InvoiceItemDimensionApi.deleteOtherDimension(itemId, preAccount));
            return ret;
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async updateOtherDimension(invoiceItemOtherDimension: InvoiceItemOtherDimension, preAccount: boolean) {
        if (invoiceItemOtherDimension.id != 0) {
            try {
                const ret = (await InvoiceItemDimensionApi.updateOtherDimension(invoiceItemOtherDimension, preAccount));
                return ret;
            } catch (error) {
                console.error(error);
            }
        }
    }

    async updateComment(id: number, item: GeneralIngoingInvoiceItemMinEditModel) {
        try {
            await InvoiceItemDimensionApi.updateComment(id, item);
        } catch (error) {
            console.error(error);
        }
    }
}

const store = new InvoiceItemDimensionStore()
export default store