













































































import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";

interface Data {
  store: DownPaymentStore;
  acceptor: number;
}

export default Vue.extend<Data, {}, {}, {}>({
  data() {
    return {
      store,
      acceptor: 1
    };
  },

  async beforeMount() {
    await this.store.loadAcceptorRequests(Number(this.$route.params.invoiceId));
    await this.store.loadAcceptorsList(Number(this.$route.params.invoiceId));
    this.acceptor=this.store.acceptorRequests[0].acceptorId;
  },

  methods: {
    removeFromLocalList(id: number) {
      this.store.acceptorRequests = this.store.acceptorRequests.filter((x) => x.id != id);
    },

    async updateAcceptRequests() {
      await this.store.updateAcceptRequests(Number(this.$route.params.invoiceId));
    },

    async addDownPaymentAcceptor(id: number) {
      if (id < 1) {
        return;
      }
      /* await this.store.addDownPaymentAcceptor(id, Number(this.$route.params.invoiceId)); */
      await this.store.loadAcceptorRequests(Number(this.$route.params.invoiceId));
      await this.store.loadDownPaymentAcceptRequests(Number(this.$route.params.invoiceId));
    },
  },
});
