

























































































































































































































import Vue from "vue";
import DocumentStatement from "./DucomentStatement";
import DocumentAttachStore from "./DocumentAttachStore";
import DocumentAttachApi from "./DocumentAttachApi";
import http from "@/services/http";

interface Data {
  store: DocumentAttachStore;
  newDoc: boolean;
  newFile: DocumentStatement;
}

interface Methods {
  showFile(relation: DocumentStatement): void;
  deleteFile(relation: DocumentStatement): void;
  uploadFile(relation: DocumentStatement): void;
  uploadNewFile(relation: DocumentStatement): void;
  downloadFile(link: string): void;
  newDocument(): void;
}

interface Props {
  statementId: number;
  table: string;
  column: string;
  title: string;
  recivedDocs: number;
}

export default Vue.extend<Data, Methods, {}, Props>({
  props: {
    statementId: { type: Number, default: 1 },
    table: { type: String, default: "" },
    column: { type: String, default: "szam" },
    title: { type: String, default: "" },
    recivedDocs: { type: Number, default: 1 },
  },

  data() {
    return {
      store: new DocumentAttachStore(),
      newDoc: false,
      newFile: new DocumentStatement(),
    };
  },

  async beforeMount() {
    await this.store.loadAllDocumentTypes(this.table, this.column);
    await this.store.loadAllRequiredDocumentBy(this.recivedDocs);
    this.newFile.documentTypeId = this.store.documentTypes.length > 0 ? this.store.documentTypes[0].id : 0;
  },

  methods: {
    newDocument() {
      this.newDoc = !this.newDoc;
      this.newFile.relatedItemID = this.statementId;
    },

    showFile(relation: DocumentStatement) {
      relation.uploading = !relation.uploading;
    },

    downloadFile(link: any) {
      window.open(link+`?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    },

    async uploadNewFile(relation: DocumentStatement) {
      const file = (this.$refs.newFile as any).$refs.input.files[0];
      if (!file) return;

      await this.store
        .uploadStatement(this.table, this.column, relation.relatedItemID, relation.documentTypeId, file)
        .then((result: any) => {
          relation.id = result.id;
          relation.link = DocumentAttachApi.generateDocumentDownLoadLink(result.id);
          relation.hasFile = relation.link != "";
          relation.fileName = result.fileName + result.fileExtension;
        });

      this.newDoc = false;
      this.newFile = new DocumentStatement();

      await this.store.loadAllStatement(this.statementId, this.table, this.column);
    },

    async uploadFile(relation: DocumentStatement) {
      const file = (this.$refs.file as any).find((x: any) => {
        if (x?.id == `file-${relation.id}-${relation.documentTypeId}`) return true;
        else return false;
      }).$refs.input.files[0];
      if (!file) return;

      // delete previous file
      if (relation.hasFile) this.deleteFile(relation);

      await this.store
        .uploadStatement(this.table, this.column, relation.relatedItemID, relation.documentTypeId, file)
        .then((result: any) => {
          relation.id = result.id;
          relation.link = DocumentAttachApi.generateDocumentDownLoadLink(result.id);
          relation.hasFile = relation.link != "";
          relation.fileName = result.fileName + result.fileExtension;
        });

      relation.uploading = false;
      await this.store.loadAllStatement(this.statementId, this.table, this.column);
    },

    async deleteFile(relation: DocumentStatement) {
      if (relation.link == "" || !relation.hasFile || relation.fileName == "") return;

      await DocumentAttachApi.deletePreviousDocument(relation.id).then(() => {
        relation.link = "";
        relation.hasFile = false;
        relation.fileName = "";
      });

      this.store.documentsStatements.filter((x) => x.id != relation.id);
      this.store.remarkDocumentRequiremens();
    },
  },
  watch: {
    async statementId(newValue, oldValue) {
      await this.store.loadAllStatement(newValue, this.table, this.column);
      console.log(newValue, this.table, this.column);
    },
    async recivedDocs(newValue, oldValue) {
      await this.store.loadAllRequiredDocumentBy(newValue);
    },
  },
  computed: {
    canAdd(): boolean {
      return this.statementId > 1;
    },
  },
});
