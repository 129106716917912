

































import Vue from 'vue';
import ApiSearch from '@/components/apiSearch/components/ApiSearchPage.vue';
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';
import MoneyDisplay from '@/components/MoneyDisplay.vue';
import TransactionItemDimension from './TransactionItemDimension.vue';
import { FilteringModel } from '@/services/filtering';

interface Data {
  url: string;
}

type Transaction = any;

export default Vue.extend<Data, {}, {}, {}>({
  props: {
    transaction: { type: Object as () => Transaction },
    extraFilter: { type: Object as () => FilteringModel },
  },

  components: {
    TransactionItemDimension,
    ApiSearch,
    MoneyDisplay
  },

  data() {
    return {
      url: "",
    }
  },

  beforeMount() {
    console.log(this.$props.transaction);
    this.url = `ledger/transactions/${this.$props.transaction.id}/items`;
  },

  computed: {
    columns: () => [
      {
        filterable: false,
        cell: "detailTemplate"
      },
      {
        field: "id",
        title: "Azonosító"
      },
      {
        field: "prefix",
        title: "Prefix"
      },
      {
        field: "partner",
        title: "Partner neve"
      },
      { field: "accSign",
        title: "Tartozik/követel"
      },
      {
        field: "currTotal",
        title: "Összeg",
        filterable: false,
        cell: "currTotalTemplate"
      },
      {
        field: "accDate",
        title: "Dátuma",
        type: ColumnType.Date
      },
      {
        field: "accNo",
        title: "Főkönyvi szám"
      },
      {
        field: "period",
        title: "Periódus"
      },
      {
        field: "bookType",
        title: "Napló"
      },
      { field: "remark",
        title: "Megjegyzés"
      },
      {
        filterable: false,
        width: 0
      }
    ],
  },
})
