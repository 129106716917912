




















































































import Vue from "vue";
import { ColumnType } from "@/components/apiSearch/models/ApiSearch";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import WriteOffDebtLimitter from "./components/WriteOffDebtLimitter.vue";
import CheckBox from "@/components/CheckBox.vue";
import { defaultPossibleWriteOffDebtPreFilter } from "./models/PossibleWriteOffDebtPreFilter";
import PossibleWriteOffDebt from "./models/PossibleWriteOffDebt";
import api from "@/modules/writeOffDebt/api/WriteOffDebtApi"
import eventBus from "@/services/eventBus";
import {getLimitFromStore, setLimitToStore} from "./store/writeOffDebtStore";

interface Data {
  limit: boolean;
  direction: boolean;
  refreshEvent: string;
}

interface Methods {
  selected: any;
  sendToApiAddInvoiceToWriteOffDebtTemp: any;
  sendAllSelectedToApiAddInvoiceToWriteOffDebtTemp: any;
  refresh: any;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: { ApiSearchPage, WriteOffDebtLimitter, CheckBox },
  created() {
    this.limit = this.$route.query.limit == "true";
    this.direction = this.$route.query.direction == "true";
  },

  mounted() {
    this.refresh();
  },

  data() {
    return {
      refreshEvent: "refreshWriteOffDebt",
      limit: false,
      direction: false,
      urlIncome: "writeoffdebt/searchincome",
      urlOutgoing: "writeoffdebt/searchoutgoing",
    };
  },

  methods: {
    refresh() {
      eventBus.$emit(this.refreshEvent);
    },
    selected(e: boolean, item: PossibleWriteOffDebt) {
      item.isSelected = e;
    },
    async sendToApiAddInvoiceToWriteOffDebtTemp(item: PossibleWriteOffDebt) {
      await api.AddInvoicesToWriteOffDebtToTmp([{ invoice: item.invoice, code: item.code }]);
      this.refresh();
      eventBus.$emit("success", "Sikeresen hozzáadva a kivezetésekhez!");
    },
    async sendAllSelectedToApiAddInvoiceToWriteOffDebtTemp(items: PossibleWriteOffDebt[]) {
      const invs = items.filter(x => x.isSelected).map(item => ({
        invoice: item.invoice,
        code: item.code
      }));
      await api.AddInvoicesToWriteOffDebtToTmp(invs);
      this.refresh();
      eventBus.$emit("success", "Tételek sikeresen hozzáadva a kivezetésekhez!");
    }
  },

  computed: {
    apiSearchExtraFilter() {
      const extraFilter = defaultPossibleWriteOffDebtPreFilter;
      extraFilter.limit = this.limit ? 0 : undefined;
      return extraFilter;
    },
    title() {
      let title = this.limit ? "Kis összegű leírás" : "Limit nélküli leírás";
      title = title + (this.direction ? "(Kimenő)" : "(Bejövő)");

      return title;
    },
    columns() {
      return [
        {
          field: "isSelected",
          field2: "isLocked",
          type: "boolean",
          sortable: false,
          filterable: false,
          cell: "selected",
          checkBox: true
        },
        {
          cell: "commandTemplate",
          width: "45px",
          sortable: false,
          filterable: false,
        },
        {
          field: "code",
          title: "Prefix",
          width: "74px",
        },
        {
          field: "outer_Inv_No",
          title: "Külső számla szám",
          width: "150px",
        },
        {
          field: "partnerName",
          title: "Partner neve",
          width: "150px",
        },
        {
          title: "Legkor. könyv. dát.",
          field: "min_Acc_Date",
          type: "date",
          width: "130px",
          format: "{0:yyyy.MM.dd}",
        },
        {
          title: "Brutto (szla p.)",
          field: "curr_Total",
          width: "150px",
        },
        {
          title: "Fizetve (szla p.)",
          field: "curr_Payed",
          width: "150px",
        },
        {
          title: "Szla P.nem",
          field: "inv_Currency",
          width: "150px",
        },
        {
          title: "Szla státusz",
          field: "statusName",
          width: "150px",
        },
        {
          title: "Szla dátuma",
          field: "inv_Date",
          type: ColumnType.Date,
          width: "150px",
        },
        {
          title: "Stornó?",
          field: "isStorno",
          width: "150px",
          cell: "checkbox",
        },
        {
          title: "Stornó szla",
          field: "invoice_St",
          width: "150px",
        },
        {
          title: "Helyesbítő",
          field: "isRectify",
          width: "150px",
          cell: "rectifycheckbox",
        },
        {
          title: "Helyesbítő szla",
          field: "invoice_Rect",
          width: "150px",
        },
        {
          title: "Fizetési határidő szla",
          field: "pterm",
          type: ColumnType.Date,
          width: "130px",
        },
        {
          title: "Fizetési mód",
          field: "paymentMode",
          width: "150px",
        },
        {
          title: "Számla szám",
          field: "invoice",
          width: "150px",
        },
        {
          title: "Brutto",
          field: "total",
          width: "150px",
        },
        {
          title: "Fizetve",
          field: "payed",
          width: "150px",
        },
        {
          title: "P.nem",
          field: "currency",
          width: "150px",
        },
        {
          title: "Előleg értesítő?",
          field: "isDpaym",
          width: "150px",
          cell: "dpaymcheckbox",
        },
      ];
    },
  },
});
