import User from "@/modules/user/models/User";
import { SysParam } from "../models/SysParam";
import ConfigurationApi from "./ConfigurationApi";

export class ConfigurationStore {
  toleranceApprovers: User[] = [];

  async getToleranceApprovers() {
    this.toleranceApprovers = await ConfigurationApi.getToleranceApprovers();
  }

  async updateToleranceApprovers(users: User[]) {
    await ConfigurationApi.updateToleranceApprovers(users);
  }

  async updateSysParam(param: SysParam) {
    await ConfigurationApi.updateSysParam(param);
  }

  async setIsInvoiceFlowEnabled(b: boolean) {
    await ConfigurationApi.updateIsInvoiceFlowEnabled(b);
  }

  async setGeneralRectItemEnabled(b: boolean) {
    await ConfigurationApi.updateGeneralRectItemEnabled(b);
  }

  async setIsDepotVisible(b: boolean) {
    await ConfigurationApi.updateIsDepotVisible(b);
  }

  async setOnlyRectItem(b: boolean) {
    await ConfigurationApi.updateOnlyRectItem(b);
  }

  async setGeniOutInv(b: boolean) {
    await ConfigurationApi.updateGeniOutInv(b);
  }

  async setIsInvAcceptedEnabled(b: boolean) {
    await ConfigurationApi.updateIsInvAcceptedEnabled(b);
  }

  async setIsInvoicePermitEnabled(b: boolean) {
    await ConfigurationApi.updateIsInvPermitEnabled(b);
  }

  async setIsExchangeRateEnabled(b: boolean) {
    await ConfigurationApi.updateIsExchangeRateEnabled(b);
  }

  async setIsExtCodeEnabled(b: boolean) {
    await ConfigurationApi.updateIsExtCodeEnabled(b);
  }

  async setIsExtendedPartnerInfoEnabled(b: boolean) {
    await ConfigurationApi.updateIsExtendedPartnerInfoEnabled(b);
  }

  async setIsBankImportNotificationEnabled(b: boolean) {
    await ConfigurationApi.updateIsBankImportNotificationEnabled(b);
  }

  async setGeniItemNoteVisible(b: boolean) {
    await ConfigurationApi.updateGeniItemNoteVisible(b);
  }

  async setInvGroupsSaved(b: boolean) {
    await ConfigurationApi.updateInvGroupsSaved(b);
  }

  async setBankDefrayerEnabled(b: boolean) {
    await ConfigurationApi.updateBankDefrayerEnabled(b);
  }

  async setTaxChangeQuestionVisible(b: boolean) {
    await ConfigurationApi.setTaxChangeQuestionVisible(b);
  }

  async setIsInvPermit(b: boolean) {
    await ConfigurationApi.setIsInvPermit(b);
  }

  async setSupplierRelationEnabled(b: boolean) {
    await ConfigurationApi.setSupplierRelationEnabled(b);
  }
}

const store = new ConfigurationStore();
export default store;
