import ApiResponse from "@/models/ApiResponse";
import User from "@/modules/user/models/User";
import http, { handleApiResponse } from "@/services/http";

export interface UsersByRoles {
    fullfilmentChecking: User[];
    fullfilmentApproval: User[];
    invoiceChecking: User[];
    invoiceApproval: User[];
}

export class InvoiceFlowUsers implements UsersByRoles {
    fullfilmentChecking: User[] = [];
    fullfilmentApproval: User[]= [];
    invoiceChecking: User[] = [];
    invoiceApproval: User[]= [];

    load() {
        return http
            .get<ApiResponse<UsersByRoles>>('users/by-roles')
            .then(handleApiResponse)
            .then(data => Object.assign(this, data))
    }
}