














import Vue from "vue";
import bStore, { BankStore } from "../../bank/services/BankStore";

interface Data {
  selBank: number;
  bStore: BankStore;
}

export default Vue.extend({
  data(): Data {
    return {
      selBank: 0,
      bStore,
    };
  },

  async beforeMount() {
    await this.bStore.loadBanks();
  },

  methods: {
    setImportChoices() {
      this.$emit("selectedBank", this.selBank);
    },
  },
});
