





















































































import IconButton from "@/components/IconButton.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import DownPaymentExtOrderItemTable from "./DownPaymentExtOrderItemTable.vue";
import MoneyInput from "@/components/MoneyInput.vue";

import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";
import { roundMoney } from "@/services/prices";
import Fragment from "@/components/Fragment.vue";
import { DownPaymentExtOrder, PreDownPayment } from "../models";
import configuration, { AppConfiguration } from "@/models/Configuration";


interface Data {
  store: DownPaymentStore;
  detailsVisible: boolean;

  configuration: AppConfiguration;
}

export default Vue.extend({
  components: {
    IconButton,
    MoneyDisplay,
    DownPaymentExtOrderItemTable,
    MoneyInput,
    Fragment,
  },

  data: (): Data => ({
    store,
    detailsVisible: true,

    configuration
  }),

  async beforeMount() {
    await this.store.loadExtOrderData();
  },

  methods: {
    async deletePreDownpayment(e: PreDownPayment) {
      await this.store.deletePreDownpayment(e.id).then(async () => {

        this.store.selectedPreDownPayments = this.store.selectedPreDownPayments.filter((x) => x.id !== e.id);
        this.store.downPayment.netto -= e.amount;
        this.store.nettoChanged();
      });
    },

    hasItems(e: DownPaymentExtOrder) {
      return e?.orderItems?.length ?? 0 > 0;
    },

    findPreDownPaymentElement(e: DownPaymentExtOrder) {
      return this.store.selectedPreDownPayments.find(
        (x) => x.order?.id == e.id
      );
    },

    toggleDetails() {
      this.detailsVisible = !this.detailsVisible;
    },

    amountChanged() {
      if (!this.store.preDownPayment || !this.store.extOrder) return;
      this.store.downPayment.netto = this.store.extOrder.finalAmount = this.store.preDownPayment.amount;
      this.store.extOrder.percent = roundMoney(
        (this.store.extOrder.finalAmount / this.store.extOrder.amount) * 100,
        this.store.preDownPayment.currency,
        2
      );
      this.store.nettoChanged();
    },

    percentChanged() {
      if (!this.store.preDownPayment || !this.store.extOrder) return;
      this.store.preDownPayment.amount = this.store.downPayment.netto = this.store.extOrder.finalAmount = roundMoney(
        this.store.extOrder.amount * (this.store.extOrder.percent / 100),
        this.store.preDownPayment.currency,
        2
      );
      this.store.nettoChanged();
    },
  },

  computed: {
    disabled(): boolean {
      return (
        store.downPayment.isPayable ||
        store.downPayment.isApproved ||
        store.downPayment.isIncorrect
      );
    },
  },
});
