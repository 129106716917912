import { capitalize } from "@/utils/stringFunctions";

enum FilterOperator {
  Contains = "Contains",
}

const operators = [FilterOperator.Contains];

export interface FilterOption {
  text: string;
  value: any;
}

class RangeFilter {
  min: any;
  max: any;

  constructor() {
    this.min = null;
    this.max = null;
  }

  hasValue() {
    return this.max && this.min;
  }

  clear() {
    this.min = null;
    this.max = null;
  }
}

class ValueFilter {
  value: any;
  operator = FilterOperator.Contains;
  operators: FilterOperator[];

  constructor(availableOperators: FilterOperator[], value: any) {
    this.value = value ?? null;
    this.operator = availableOperators[0];
    this.operators = [...availableOperators];
  }

  hasValue() {
    return this.value;
  }

  clear() {
    this.value = null;
  }
}

class MultiSelectOptionsFilter {
  options: FilterOption[] = [];
  selectedOptions: any[] = [];

  constructor(options: FilterOption[], selected: number[] | boolean = false) {
    this.options = options;
    if (typeof selected !== "boolean") {
      this.selectedOptions = this.options.filter((x) => selected.includes(x.value)).map((x) => x.value);
    } else {
      this.selectedOptions = selected ? options : [];
    }
  }

  hasValue() {
    return this.selectedOptions && this.selectedOptions.length > 0;
  }

  clear() {
    this.selectedOptions = [];
  }
}

type FilterItem = RangeFilter | MultiSelectOptionsFilter | ValueFilter;

export class FilteringModel {
  filters: Record<string, FilterItem> = {};

  constructor(params?: Partial<FilteringModel>) {
    const data = { ...params };
    Object.assign(this, data);
  }

  range(field: string) {
    this.filters[field] = new RangeFilter();
    return this;
  }

  multiSelect(field: string, options: FilterOption[], selected: boolean | number[] = false) {
    this.filters[field] = new MultiSelectOptionsFilter(options, selected);
    this.filters[field] = new MultiSelectOptionsFilter(options, selected);
    return this;
  }

  value(field: string, value?: any, ops?: FilterOperator[]) {
    this.filters[field] = new ValueFilter(ops ?? operators, value ?? null);
    return this;
  }

  values(list: { field: any; value?: any; ops?: FilterOperator[] }[]) {
    list.forEach((item) => {
      this.filters[item.field] = new ValueFilter(item.ops ?? operators, item.value ?? null);
    });
    return this;
  }

  fromFilter(filter: Record<string, FilterItem>) {
    for (const f in filter) {
      this.filters[f] = filter[f];
    }

    return this;
  }

  toObject() {
    return { ...this.filters };
  }

  *[Symbol.iterator]() {
    for (const key of Object.keys(this)) {
      const value = (this as any)[key];
      if (typeof value === "object" && "operator" in value) {
        yield value;
      }
    }
  }
}

export enum SortOrder {
  None = "None",
  Ascending = "Ascending",
  Descending = "Descending",
}

export interface SortDefinition {
  text: string;
  field: string | number[];
  order: SortOrder;
}

export class SortingModel {
  definitions: SortDefinition[] = [];
  capitalized = false;

  constructor(params?: Partial<SortingModel>) {
    const data = { ...params };
    Object.assign(this, data);
  }

  capitalize(b = true) {
    this.capitalized = b;
    return this;
  }

  field(field: string, text: string, order: SortOrder = SortOrder.None) {
    this.definitions.push({ field, text, order });
    return this;
  }

  fields(list: { field: string | number[]; text: string; order?: SortOrder }[]) {
    list.forEach((item) => {
      this.definitions.push({
        field: item.field,
        text: item.text,
        order: item?.order ?? SortOrder.None,
      });
    });
    return this;
  }

  toggle(field: string) {
    this.definitions
      .filter((d) => d.field !== field)
      .forEach((d) => {
        d.order = SortOrder.None;
      });

    const definition = this.definitions.find((d) => d.field === field);
    if (!definition) {
      return;
    }

    const nextMethods = {
      [SortOrder.None]: SortOrder.Ascending,
      [SortOrder.Ascending]: SortOrder.Descending,
      [SortOrder.Descending]: SortOrder.None,
    };

    definition.order = nextMethods[definition.order];
  }

  toObject() {
    const activeDefinition = this.definitions.find((d) => d.order !== SortOrder.None);
    if (activeDefinition) {
      const field = this.capitalized ? capitalize(activeDefinition.field) : activeDefinition.field;
      return { field: field, order: activeDefinition.order };
    }

    return {};
  }

  fromSort(sort: SortingModel) {
    this.definitions = sort.definitions;
    this.capitalized = sort.capitalized;
    return this;
  }
}
