import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import BankOutView from '../models/BankOutView'

export default class BankOutViewApi {
    static getAll(partnerID: number, overpay: boolean): Promise<BankOutView[]> {
        return http
            .get<ApiResponse<BankOutView[]>>(`bankout/${partnerID}/${overpay}`)
            .then(handleApiResponse)
    }
}