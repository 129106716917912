export default interface City {
    id: number;
    name: string;
    postCode: string;
    country: string;
    deleted: boolean;
}

export const cityDefaultProps: City = {
    id: 0,
    name: '',
    postCode: '',
    country: '',
    deleted: false,
}
