import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import SerPartEditModel from "../models/SerPartEditModel";

export default class SerPartApi{
    static getAll(name: string): Promise<SerPartEditModel[]> {
        return http
            .get<ApiResponse<SerPartEditModel[]>>(`ser-part`, {
                params: {
                    q: name
                }
            })
            .then(handleApiResponse)
    }

    static getFilteredSerParts(name: string, accInvGroup: number): Promise<SerPartEditModel[]> {
        return http.get<ApiResponse<SerPartEditModel[]>>(`ser-part/filtered/${accInvGroup}`, {
            params: {
                q: name
            }
        }).then(handleApiResponse);
    }
}