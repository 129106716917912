



































































































import Vue from "vue";
import dStore, { DocumentTypeStore } from "../../documentType/services/DocumentTypeStore";
import rStore, { FlowDocumentRelationStore } from "../../flowDocumentRelation/services/FlowDocumentRelationStore";
import dtStore, { DocumentTemplateStore } from "../../documentTemplate/services/DocumentTemplateStore";
import FlowDocumentRelation, {
  flowDocumentRelationDefaultProps,
} from "../../flowDocumentRelation/models/FlowDocumentRelation";
import DocumentType, { documentTypeDefaultProps } from "../../documentType/models/DocumentType";
import eventBus from "../../../services/eventBus";
import InvoiceFlowStatus, { invoiceFlowStatusDefaultProps } from "../../invoiceFlowStatus/model/InvoiceFlowStatus";

interface Data {
  dStore: DocumentTypeStore;
  rStore: FlowDocumentRelationStore;
  dtStore: DocumentTemplateStore;
  newItem: FlowDocumentRelation;
  newDocumentType: DocumentType;

  localFlowStatusItem: InvoiceFlowStatus;
}

interface Methods {
  cancel(): void;
  save(item: any): void;
  openDocumentType(item: any): void;
  cancelDocumentType(item: any): void;
  createDocumentType(item: any): void;
  newItemDocumentTypeChanged(value: string): void;
  newItemDocumentRequiredChanged(value: boolean): void;

  addDocumentTemplateEventHandler(): void;
  clearDocumentTemplateEventHandler(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  props: {
    item: Object as () => InvoiceFlowStatus,
    template: String,
    componentId: Number,
  },

  data() {
    return {
      dStore,
      rStore,
      dtStore,
      newItem: { ...flowDocumentRelationDefaultProps },
      newDocumentType: { ...documentTypeDefaultProps },

      localFlowStatusItem: { ...invoiceFlowStatusDefaultProps },
    };
  },

  beforeMount() {
    this.localFlowStatusItem = { ...this.$props.item };
  },

  created() {
    eventBus.$on("DocumentTemplateItemCreating" + this.$props.componentId, this.addDocumentTemplateEventHandler);
    eventBus.$on("DocumentTemplateItemClearing" + this.$props.componentId, this.clearDocumentTemplateEventHandler);
  },

  beforeDestroy() {
    eventBus.$off("DocumentTemplateItemCreating" + this.$props.componentId, this.addDocumentTemplateEventHandler);
    eventBus.$off("DocumentTemplateItemClearing" + this.$props.componentId, this.clearDocumentTemplateEventHandler);
  },

  methods: {
    newItemDocumentRequiredChanged(v: boolean) {
      this.newItem.required = v;
    },

    newItemDocumentTypeChanged(v: string) {
      this.newItem.documentType = v;
    },

    addDocumentTemplateEventHandler() {
      this.save(this.localFlowStatusItem);
    },

    clearDocumentTemplateEventHandler() {
      this.cancel();
    },

    cancel() {
      this.$props.item.dropDown = false;
      this.newItem = { ...flowDocumentRelationDefaultProps };
    },

    async save(item: InvoiceFlowStatus) {
      if (this.newItem.documentType != "" && this.$props.template.length > 1) {
        this.newItem.statusId = item.id;
        this.newItem.headId = this.dtStore.documentTemplates.find((x: any) => x.name == this.$props.template)?.id ?? 0;
        item.dropDown = false;

        this.rStore.flowDocumentRelation = { ...this.newItem };

        await this.rStore.save();
        this.rStore.flowDocumentRelation = { ...flowDocumentRelationDefaultProps };
        this.newItem = { ...flowDocumentRelationDefaultProps };
        this.$props.item.dropDown = false;
      }
    },

    openDocumentType(item: any) {
      item.dialog = true;
    },

    cancelDocumentType(item: any) {
      item.dialog = false;
      this.newDocumentType = { ...documentTypeDefaultProps };
    },

    async createDocumentType(item: any) {
      item.dialog = false;
      this.dStore.documentType = { ...this.newDocumentType };

      await this.dStore.save();
      this.newDocumentType = { ...documentTypeDefaultProps };
    },
  },
});
