import InvoiceGroup, { invoiceGroupDefaultProps } from "../models/InvoiceGroup"
import InvoiceGroupDisplay from "../models/InvoiceGroupDisplay"
import InvoiceGroupType from "../models/InvoiceGroupType"
import DivisionItem from "../models/DivisionItem"
import User from "../models/User"
import InvoiceGroupApi from "./InvoiceGroupApi"
import busyIndication from "../../../utils/busyIndication";

export class InvoiceGroupStore {
    invoiceGroups: InvoiceGroupDisplay[] = []
    users: User[] = []
    invoiceGroupTypes: InvoiceGroupType[] = []
    divisionItems: DivisionItem[] = []
    invoiceGroup: InvoiceGroup = { ...invoiceGroupDefaultProps }

    @busyIndication()
    async loadInvoiceGroups() {
        this.invoiceGroups = (await InvoiceGroupApi.getAll()).map((i) => ({ ...i, deleting: false }))
    }

    async loadInvoiceGroupsWithoutBusy() {
        this.invoiceGroups = await InvoiceGroupApi.getAll();
    }

    async loadUsers() {
        this.users = await InvoiceGroupApi.getUsers()
    }

    async loadInvoiceGroupTypes() {
        this.invoiceGroupTypes = await InvoiceGroupApi.getInvoiceGroupTypes()
    }

    async loadDivisionItems() {
        this.divisionItems = await InvoiceGroupApi.getDivisionItems()
    }

    @busyIndication()
    async loadInvoiceGroup(invoiceGroupId: number) {
        this.invoiceGroup = invoiceGroupId > 1 ? await InvoiceGroupApi.get(invoiceGroupId) : { ...invoiceGroupDefaultProps }
    }

    async loadInvoiceGroupWithoutBusy(invoiceGroupId: number) {
        this.invoiceGroup = invoiceGroupId > 1 ? await InvoiceGroupApi.get(invoiceGroupId) : { ...invoiceGroupDefaultProps }
    }

    async deleteInvoiceGroup(invoiceGroupId: number) {
        try {
            await InvoiceGroupApi.delete(invoiceGroupId)
            this.invoiceGroups = this.invoiceGroups.filter(b => b.id !== invoiceGroupId)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    async save() {
        if(!this.invoiceGroup) {
            return
        }

        if(this.invoiceGroup.id === 0) {
            await this.createInvoiceGroup()
        } else {
            await this.updateInvoiceGroup()
        }
    }

    private async createInvoiceGroup() {
        try {
            await InvoiceGroupApi.create(this.invoiceGroup)
            await this.loadInvoiceGroups();
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    private async updateInvoiceGroup() {
        try {
            console.log(this.invoiceGroup);
            
            await InvoiceGroupApi.update(this.invoiceGroup)
        } catch (err) {
            // TODO: notification
            console.error(err)
        }
    }

    setDeleting(item: InvoiceGroup) {
        item.deleting = !item.deleting;
        this.invoiceGroups = [...this.invoiceGroups];    
    }
}

const store = new InvoiceGroupStore()
export default store
