export default interface User {
  id: number;
  name: string;
  employment: string;
  email: string;
  phone: string;
  address: string;
  city: number;
  isInvAccept: boolean;
  isInvContr: boolean;

  pictureLink: string;
  hasPicture: boolean;
  selected: boolean;
  active: boolean;
}

export function monogram(name: string) {
  if (name) {
    return name.split(" ").map((i) => i[0]).join("");
  }
  return "";
}

export const userDefaultProps: User = {
  id: 0,
  name: "",
  employment: "",
  email: "",
  phone: "",
  address: "",
  city: 0,
  isInvAccept: false,
  isInvContr: false,

  pictureLink: "",
  hasPicture: false,
  selected: false,
  active: false,
};

export const userTechProps: User = {
  id: 1,
  name: "-",
  employment: "-",
  email: "-",
  phone: "-",
  address: "-",
  city: 0,
  isInvAccept: false,
  isInvContr: false,

  pictureLink: "",
  hasPicture: false,
  selected: false,
  active: false,
};
