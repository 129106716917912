export default class GPart {
    id = 0;
    tax = 1;
    postCalcType = 1;
    code = '';
    statCode1 = '';
    mainCode = '';
    accCodeIn = '';
    name = '';
    accCodeOut = '';
    english = '';
    isPostCalc = false;
    gpartsType = 1;
    serv = true;
    partGroup = 1;
    downPay = false;
    currency = 'HUF';
    isRectItem = false;
    unitType = 1;
    isStockValue = false;
    otherSale = false;
    taxItem = 1;
    weight: number|null = null;
}