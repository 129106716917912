






















































// Models
import Vue from "vue";
import eventBus from "@/services/eventBus";
import store, { PaymentScheduleStore } from "../services/store";
import { InvoiceDeadline } from "../models/InvoiceDeadline";
import { PaymentScheduleApi } from "../services/api";
import GeneralIngoingInvoiceApi from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceApi";
import PartnerApi from "@/modules/partner/services/PartnerApi";

// Components
import PageListView from "@/components/PageListView.vue";
import PaymentScheduleView from "./PaymentScheduleView.vue";
import IconButton from "@/components/IconButton.vue";

interface Data {
  store: PaymentScheduleStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: { PageListView, PaymentScheduleView, IconButton },

  data: () => ({
    store,
  }),

  async beforeMount() {
    this.store.paymentModes = await GeneralIngoingInvoiceApi.getPaymentModes();
    this.store.users = await PaymentScheduleApi.getResponsibleUsers();
    await this.store.loadInvoiceDeadlines();
  },

  methods: {
    async reload() {
      await this.store.loadInvoiceDeadlines();
    },

    async loadPaymentSchedule(item: InvoiceDeadline) {
      if (!item.expanded) {
        item.partBanks = await PartnerApi.getPartBanksWithTech(item.partner);
      }

      item.expanded = !item.expanded;
    },

    async save(item: InvoiceDeadline) {
      await PaymentScheduleApi.managePaymentScheduleAndItems(item.prefix, item.paymentSchedule).then(() => {
        eventBus.$emit("success", "Sikeres Fizetési ütemezés frissítés!");
      });
    },
  },

  computed: {
    invoices() {
      return this.store.invoices;
    },

    columns: () => [
      { width: 55, cell: "detailsButtonTemplate", filterable: false, sortable: false },
      { field: "invoiceID", title: "Azonosító" },
      { field: "prefix", title: "Prefix" },
      { field: "invoiceNo", title: "Bizonylat szám" },
      { field: "bruttoDisplay", title: "Bruttó" },
      { field: "invoiceDateDisplay", title: "Számla dátuma" },
      { field: "deadlineDisplay", title: "Fizetési határidő" },
    ],
  },
});
