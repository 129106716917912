import Tax, { taxDefaultProps, taxTypeDefaultProps } from "../models/Tax";
import TaxApi from "./TaxApi";
import busyIndication from "../../../utils/busyIndication";
import { TaxType } from "@/modules/taxes/models/Tax";
import VatItem, { defaultVatItemProps } from "../models/VatItem";
import eventBus from "@/services/eventBus";

export class TaxStore {
  taxes: Tax[] = [];
  tax: Tax = { ...taxDefaultProps };
  taxTypes: TaxType[] = [];
  taxType: TaxType = { ...taxTypeDefaultProps };
  vatItem: VatItem = { ...defaultVatItemProps };
  vatItems: VatItem[] = [];
  createVatItm = false

  @busyIndication()
  async loadTaxes() {
    this.taxes = (await TaxApi.getAll()).map((i) => ({
      ...i,
    }));
  }
  @busyIndication()
  async loadTaxTypes() {
    this.taxTypes = (await TaxApi.getAllTaxType()).map((i) => ({
      ...i,
    }));
  }

  async loadTaxesWithoutBusy() {
    this.taxes = await TaxApi.getAll();
  }

  @busyIndication()
  async loadTax(taxId: number) {
    this.tax = taxId > 1 ? await TaxApi.get(taxId) : { ...taxDefaultProps };
  }

  async loadTaxWithoutBusy(taxId: number) {
    this.tax = taxId > 1 ? await TaxApi.get(taxId) : { ...taxDefaultProps };
  }

  @busyIndication()
  async loadVatItems(taxId: number) {
    this.vatItems = (await TaxApi.getVatItems(taxId)).map((i) => ({
      ...i, deleting: false
    }));
  }

  async deleteVatItem(vatItemId: number) {
    try {
      await TaxApi.deleteVatItem(vatItemId);
      this.vatItems = this.vatItems.filter((b) => b.id !== vatItemId);
      eventBus.$emit("success", "Az ÁFA kulcs bontás tétel törölve.");
    } catch (err) {
      // TODO: notification
      console.error(err);
    }
  }

  async save(tax: Tax) {
    try {
      if (!tax) return;
      {
        await this.updateTax();
        eventBus.$emit("success", "Az ÁFA kulcs sikeresen módosítva.");
      }
    } catch (err) {
      console.error(err);
      eventBus.$emit("error", err);
    }
  }

  private async updateTax() {
    try {
      await TaxApi.update(this.tax);
    } catch (err) {
      // TODO: notification
      console.error(err);
    }
  }

  setDeleting(item: VatItem) {
    item.deleting = !item.deleting;
    this.vatItems = [...this.vatItems];
  }

  async setVatItem(item: VatItem, taxId: number) {
    if (
      item.backRate == 0 &&
      item.name == "" &&
      item.foreign == "" &&
      item.english == ""
    ) {
      return;
    } else {
      TaxApi.setVatItem(item, taxId);
    }
  }

  
  async createVatItem(){
    await TaxApi.setVatItem(this.vatItem,this.tax.id);
    await this.loadVatItems(this.tax.id)
    this.createVatItm=false;
    eventBus.$emit("success","Az áfa kulcs bontás tétel sikeresen létrehozva!");
  }
}

const store = new TaxStore();
export default store;
