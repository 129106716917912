export default interface BankStatus {
    id: number;
    name: string;
    checked: boolean;
  }
  
  export const bankStatusDefaultProps: BankStatus = {
    id: 0,
    name: "Összes",
    checked: false,
  };