
















import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      internalValue: "0",
    }
  },

  props: {
    value: String,
    maxLength: Number,
    placeholder: String,
  },

  methods: {
    onKeyPress: function(evt: any) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) 
      {
        this.$emit("keypress", evt);
        evt.preventDefault();
      }
      else
      {
        this.$emit("keypress", evt);
      }
    },
  },

  watch: {
    internalValue(newValue: string) {
      this.$emit("input", this.internalValue);
      this.$emit("change", this.internalValue);
    }
  }
});
