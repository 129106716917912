var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('api-search-page',{key:_vm.limit,ref:"apiSearch",attrs:{"url":_vm.direction ? _vm.urlOutgoing : _vm.urlIncome,"columns":_vm.columns,"apiSearchExtraFilter":_vm.apiSearchExtraFilter,"create":"Kijelöltek hozzáadás a kivezetésekhez","refreshEvent":_vm.refreshEvent,"showDivider":false,"autoLoad":false},on:{"create":_vm.sendAllSelectedToApiAddInvoiceToWriteOffDebtTemp},scopedSlots:_vm._u([(_vm.limit)?{key:"componentNextToCreateButton",fn:function(ref){
var apiSearch = ref.apiSearch;
return [_c('WriteOffDebtLimitter',{staticStyle:{"margin-left":"20px"},attrs:{"apiSearch":apiSearch,"direction":_vm.direction},on:{"change":_vm.refresh}})]}}:null,{key:"selected",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"style:":"display: flex; justify-content: space-between"}},[_c('check-box',{staticStyle:{"border-radius":"6px !important","width":"30px","margin-right":"10px"},attrs:{"disabled":item.isLocked,"selected":item.isSelected},on:{"change":function (e) { return _vm.selected(e, item); }}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.isLocked)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v("fa fa-exclamation-circle")]):_vm._e()]}}],null,true)},[(item.locks.isItNotExistsCode)?_c('span',[_vm._v("Nem érvényes számla Prefix.")]):_vm._e(),(item.locks.isItNotExistsInvoice)?_c('span',[_vm._v("Nem létező számla szám.")]):_vm._e(),(item.locks.itHasPaymentBeignProcessed)?_c('span',[_vm._v("A számlához feldolgozás alatti fizetési tétel tartozik.")]):_vm._e(),(item.locks.isItIncludedInACompensation)?_c('span',[_vm._v("Folyószámlán még nem átvezett kompenzációban szerepel.")]):_vm._e(),(item.locks.isItAmongOpenIncomingAccounts)?_c('span',[_vm._v("Szerepel a nyitott (még nem fizetve állapotú) munkavállaló által kifizetett bejövő számlák között.")]):_vm._e(),(item.locks.isThereAPrintableVIROForTheInvoice)?_c('span',[_vm._v("Van a számlához nyomtatható technikai számla(VIRO).")]):_vm._e(),(item.locks.isItIncludedInAnOpenBankingPackage)?_c('span',[_vm._v("Nyitott banki csomagban szerepel.")]):_vm._e()])],1)]}},{key:"commandTemplate",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"style:":"display: flex; justify-content: space-between"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"background-color":"transparent"},attrs:{"disabled":item.isLocked,"color":"primary","icon":""},on:{"click":function($event){return _vm.sendToApiAddInvoiceToWriteOffDebtTemp(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Kivezetés")])])],1)]}},{key:"checkbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary","readonly":""},model:{value:(item.isStorno),callback:function ($$v) {_vm.$set(item, "isStorno", $$v)},expression:"item.isStorno"}})]}},{key:"rectifycheckbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary","readonly":""},model:{value:(item.isRectify),callback:function ($$v) {_vm.$set(item, "isRectify", $$v)},expression:"item.isRectify"}})]}},{key:"dpaymcheckbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary","readonly":""},model:{value:(item.isDpaym),callback:function ($$v) {_vm.$set(item, "isDpaym", $$v)},expression:"item.isDpaym"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }