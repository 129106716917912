


















// Models
import Vue from "vue";
import store, { CashStatementStore } from "../services/store";
import pStore, { PartnerStore } from "@/modules/partner/services/PartnerStore";
import CashStatement from "../models/CashStatement";

// Components
import CashStatementHeaders from "../components/CashStatementHeaders.vue";
import CashStatementCreate from "./CashStatementCreate.vue";
import CashStatementEdit from "./CashStatementEdit.vue";
import CashDocumentCreatePage from "./CashDocumentCreatePage.vue";
import CashStatementStepper from "../components/CashStatementStepper.vue";
import CashStatementAccounting from "./CashStatementAccounting.vue";

interface Data {
  loaded: boolean;
  store: CashStatementStore;
  pStore: PartnerStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    CashStatementHeaders,
    CashDocumentCreatePage,
    CashStatementEdit,
    CashStatementStepper,
    CashStatementAccounting,
  },

  data: () => ({
    loaded: false,
    store,
    pStore,
  }),

  async beforeMount() {
    this.store.cashStatement = new CashStatement({});
    this.store.cashDocuments = [];

    const cashStatementId = Number(this.$route.params.cashStatementId) || 0;
    let cashPointId = Number(this.$route.params.cashPointId) || 0;

    if (cashStatementId > 0) {
      await this.store.loadCashStatement(cashStatementId);
      await this.store.loadCashDocumentsByStatementId(cashStatementId);
      cashPointId = this.store.cashStatement.cashPoint;
    }

    await Promise.all([
      this.store.loadCurrentUser(),
      this.store.loadCashPointShort(cashPointId),
      this.store.loadCashiersByCashStatementRelation(cashStatementId),
      this.store.loadBankStatuses(),
      this.store.loadAccountStatuses(),
      this.store.loadSettings(),
    ]);

    const isNotHUF =
      this.store.cashStatement.currency.toUpperCase().trim() !== "HUF";
    this.store.isDifferentCurrency = isNotHUF;

    this.loaded = true;
  },

  computed: {
    current() {
      switch (this.store.step) {
        case 0:
          return Number(this.$route.params.cashStatementId) > 0
            ? CashStatementEdit
            : CashStatementCreate;
        case 1:
          return CashStatementAccounting;
        default:
          return Number(this.$route.params.cashStatementId) > 0
            ? CashStatementEdit
            : CashStatementCreate;
      }
    },
  },
});
