

















import Vue from 'vue'
export default Vue.extend({
    props: {
        data: {
            type: Array
        }
    }
})
