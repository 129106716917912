export default interface AccountStatus {
  id: number | null;
  name: string;
}

export const accountStatusDefaultProps: AccountStatus = {
  id: 0,
  name: "",
};

export const accountStatusDefaultFilterProps: AccountStatus = {
  id: 0,
  name: "Összes",
};
