





















































































































































































import Vue from "vue";
import store, { BankStatementStore } from "../services/BankStatementStore";
import BankStatementPict, { bankStatementPictDefaultProps } from "../models/BankStatementPict";
import BankStatementApi from "../services/BankStatementApi";
import http from "@/services/http";

interface Data {
  store: BankStatementStore;
  newDoc: boolean;

  newFile: BankStatementPict;
}

interface Methods {
  showFile(relation: BankStatementPict): void;
  deleteFile(relation: BankStatementPict): void;
  uploadFile(relation: BankStatementPict): void;
  uploadNewFile(relation: BankStatementPict): void;
  downloadFile(link: string): void;
  newDocument(): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  data() {
    return {
      store,
      newDoc: false,
      newFile: { ...bankStatementPictDefaultProps },
    };
  },

  async beforeMount() {
    await this.store.loadBankStatementPictByStatementIdWithoutBusy(Number(this.$route.params.id));
  },

  methods: {
    newDocument() {
      this.newDoc = !this.newDoc;
      this.newFile.relatedItemID = Number(this.$route.params.id);
    },

    showFile(relation: BankStatementPict) {
      relation.uploading = !relation.uploading;
    },

    downloadFile(link: any) {
      window.open(link + `?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    },

    async uploadNewFile(relation: BankStatementPict) {
      const file = (this.$refs.newFile as any).$refs.input.files[0];
      if (!file) return;

      await this.store
        .uploadBankStatementPict(relation.relatedItemID, relation.documentTypeId, file)
        .then((result: any) => {
          relation.id = result.id;
          relation.link = BankStatementApi.generateDocumentDownLoadLink(result.id);
          relation.hasFile = relation.link != "";
          relation.fileName = result.fileName + result.fileExtension;
        });

      this.newDoc = false;
      this.newFile = { ...bankStatementPictDefaultProps };

      await this.store.loadBankStatementPictByStatementIdWithoutBusy(Number(this.$route.params.id));
    },

    async uploadFile(relation: BankStatementPict) {
      const file = (this.$refs.file as any).find((x: any) => {
        if (x?.id == `file-${relation.id}-${relation.documentTypeId}`) return true;
        else return false;
      }).$refs.input.files[0];
      if (!file) return;

      // delete previous file
      if (relation.hasFile) this.deleteFile(relation);

      await this.store
        .uploadBankStatementPict(relation.relatedItemID, relation.documentTypeId, file)
        .then((result: any) => {
          relation.id = result.id;
          relation.link = BankStatementApi.generateDocumentDownLoadLink(result.id);
          relation.hasFile = relation.link != "";
          relation.fileName = result.fileName + result.fileExtension;
        });

      relation.uploading = false;
      await this.store.loadBankStatementPictByStatementIdWithoutBusy(Number(this.$route.params.id));
    },

    async deleteFile(relation: BankStatementPict) {
      if (relation.link == "" || !relation.hasFile || relation.fileName == "") return;

      await BankStatementApi.deletePreviousDocument(relation.id).then(() => {
        relation.link = "";
        relation.hasFile = false;
        relation.fileName = "";
      });

      this.store.bankStatementPict.filter((x) => x.id != relation.id);
    },
  },
});
