



















import Vue from "vue";
import { LetterOfCreditStore } from "./letterOfCreditApi";
import IconButton from "@/components/IconButton.vue";
import { dateFormatHU } from "@/filters/date-format";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import { LetterOfCreditDto } from "./letterOfCredit";

export default Vue.extend({
  filters: { dateFormatHU },
  components: { IconButton, ApiSearchPage },
  data() {
    return {
      store: new LetterOfCreditStore(),
    };
  },
  async beforeMount() {
    await this.store.set();
  },
  methods: {
    create() {
      this.$router.push(`/letters-of-credit/0`);
    },
    openEditor(item: LetterOfCreditDto) {
      this.$router.push(`/letters-of-credit/${item.id}`);
    },
    getDate(date: Date | null | undefined): string {
      if (typeof date != "undefined" && date != null) {
        return dateFormatHU(date.toString());
      } else {
        return "";
      }
    },
  },
  computed: {
    columns: (): ApiSearchColumn[] => [
      {
        title: "Id",
        field: "id",
      },
      {
        title: "Partner",
        field: "partnerName",
      },
      {
        title: "Bank neve",
        field: "bankName",
      },
      {
        title: "Összeg",
        field: "amount",
        type: ColumnType.Money,
      },
      {
        title: "Költség",
        field: "cost",
        type: ColumnType.Money,
      },
      {
        title: "Valuta",
        field: "currency",
      },
      {
        title: "Üzleti folyamat",
        field: "businessProcessName",
      },
      {
        title: "Benyújthatóság",
        field: "submitEventName",
      },
      {
        title: "Dokumentum követelmények",
        field: "submitRequirementName",
      },
      {
        title: "Dokumentumok benyújtva",
        field: "requirementOkDate",
        type: ColumnType.Date,
      },
      {
        title: "Fizetési határidő",
        field: "payTerm",
        type: ColumnType.Date,
      },
      {
        title: "Státusz",
        field: "statusName",
      },
      { cell: "command", filterable: false, sortable: false },
    ],
  },
});
