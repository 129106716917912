



































import Vue from "vue";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import { store, TechnicalInvoiceStore } from "../services/TechnicalInvoiceStore";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";

interface Data {
  loading: boolean;
  store: TechnicalInvoiceStore;
}

interface Methods {
  createTechnicalInvoice(): void;
  editTechnicalInvoice(data: any): void; // muszáj az any type, mert az ApiSearchból érkezik a data és nincs külön modell megírva neki - asszem
}

export default Vue.extend<Data, Methods, {}>({
  components: {
    ApiSearchPage,
  },

  data() {
    return {
      loading: false,
      store,
    };
  },

  methods: {
    createTechnicalInvoice() {
      this.$router.push(`/invoice-browser/technical-invoice/${this.$route.params.codeFilter}`);
    },

    async editTechnicalInvoice(invoiceId) {
      // console.log(invoiceId); // a hlaccinvoice_bg-bol kapott viri/viro szamlanak az id-ja
      this.$router.push(`/invoice-browser/technical-invoice/${this.$route.params.codeFilter}/edit/${invoiceId}`);
    },
  },

  computed: {
    createButtonText() {
      return "Új technikai számla felvétele";
    },

    titleText() {
      if (this.$route.params.codeFilter === "viro") return "Technikai kimenő számlák";
      if (this.$route.params.codeFilter === "viri") return "Technikai bejövő számlák";
      return `Számlák`;
    },

    // data a backendről jön az ApiSearch segítségével, csak az oszlopokat kell beállítani
    columns: (): ApiSearchColumn[] => [
      // {
      //   field: "id",
      //   title: "ID",
      //   filterable: false,
      // },
      {
        field: "invoice",
        title: "Azonosító",
      },
      {
        field: "invPartnerName",
        title: "Partner",
      },
      {
        field: "pmodeName",
        title: "Fizetési mód",
      },
      {
        field: "netto",
        title: "Nettó",
      },
      {
        field: "vat",
        title: "Áfa",
      },
      {
        field: "total",
        title: "Bruttó",
      },
      {
        field: "currency",
        title: "Devizanem",
      },
      {
        field: "invDate",
        title: "Számla dátuma",
        type: ColumnType.Date,
        filterable: false,
      },
      {
        field: "shipDate",
        title: "Teljesítés dátuma",
        type: ColumnType.Date,
        filterable: false,
      },
      { cell: "commandTemplate", filterable: false },
    ],
  },
});
