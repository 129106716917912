var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inv-group-table"},[_vm._m(0),_c('v-divider',{staticStyle:{"margin-top":"1em"}}),_c('div',{staticClass:"row"},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"table_font dimension-header"},[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"border-right":"thin solid #dddddd !important"},attrs:{"colspan":"3"}},[_vm._v(" Dimenzió megnevezése ")]),_c('th',{staticClass:"text-center",staticStyle:{"border-right":"thin solid #dddddd !important"},attrs:{"colspan":"2"}},[_vm._v(" Nettó ")]),_c('th',{attrs:{"colspan":"1"}})])]),_c('tbody',{staticClass:"table_font"},[_vm._l((_vm.dims),function(dim){return _c('tr',{key:dim.id,staticStyle:{"cursor":"default !important"}},[_c('td',{staticClass:"noWrap text-center",staticStyle:{"width":"10rem !important","color":"#a1a1a1","border-right":"thin solid #dddddd !important"},attrs:{"colspan":"3"}},[_c('ApiSearch',{attrs:{"disabled":dim.dimension>1,"url":"invoice-item-dimension/free-dimensions/search","getUrl":"invoice-item-dimension/free-dimension","columns":_vm.columns,"itemId":dim.dimension,"placeholder":"Szabad dimenzió név"},on:{"itemSelected":_vm.selected},model:{value:(dim.dimension),callback:function ($$v) {_vm.$set(dim, "dimension", $$v)},expression:"dim.dimension"}})],1),_c('td',{staticClass:"noWrap text-center",staticStyle:{"width":"10rem !important","color":"#a1a1a1","border-right":"thin solid #dddddd !important"},attrs:{"colspan":"2"}},[_c('MoneyInput',{attrs:{"disabled":dim.dimension>1},model:{value:(dim.amount),callback:function ($$v) {_vm.$set(dim, "amount", $$v)},expression:"dim.amount"}})],1),(dim.dimension > 1)?_c('td',[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"whiteBtn",attrs:{"width":"10","height":"10"},on:{"click":function($event){return _vm.removeDim(dim)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)],1):_vm._e()])}),_c('tr',{staticStyle:{"cursor":"default !important"}},[_c('td',{staticClass:"noWrap text-center",staticStyle:{"width":"10rem !important","color":"#a1a1a1","border-right":"thin solid #dddddd !important"},attrs:{"colspan":"3"}},[_c('ApiSearch',{attrs:{"url":"invoice-item-dimension/free-dimensions/search","columns":_vm.columns,"placeholder":"Szabad dimenzió név"},on:{"itemSelected":_vm.selected},model:{value:(_vm.newDim.dimension),callback:function ($$v) {_vm.$set(_vm.newDim, "dimension", $$v)},expression:"newDim.dimension"}})],1),_c('td',{staticClass:"noWrap text-center",staticStyle:{"width":"10rem !important","color":"#a1a1a1","border-right":"thin solid #dddddd !important"},attrs:{"colspan":"2"}},[_c('MoneyInput',{model:{value:(_vm.newDim.amount),callback:function ($$v) {_vm.$set(_vm.newDim, "amount", $$v)},expression:"newDim.amount"}})],1),_c('td',{staticClass:"noWrap",attrs:{"colspan":"1"}},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"whiteBtn",attrs:{"width":"10","height":"10"},on:{"click":_vm.saveDim}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-check")])],1)]}}])},[_c('span',[_vm._v("Mentés")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"whiteBtn_noleftBorder",attrs:{"width":"10","height":"10"},on:{"click":_vm.cancelUpdate}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-times")])],1)]}}])},[_c('span',[_vm._v("Mégse")])])],1)],1)])],2)]},proxy:true}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"text-center",staticStyle:{"font-weight":"500","font-size":"12px","margin-top":"1em"}},[_vm._v(" Szabad dimenzió ")])])}]

export { render, staticRenderFns }