export default interface InvoiceParams{
    id: number;
    name: string;
    foreign: string;
    english: string;
    code: string;
    prefix: string;
    isPrintNo: boolean;
    isPrintNoCount: boolean;
    prpageNo: number;
    printNumber: number;
    maxPrintNumber: number;
    remark1: string;
    remark2: string;
    remark3: string;
    isNoCuracc: boolean; 
    defNoCuracc: boolean;
    isInvpaym: boolean;
    vatAccCode: string;
    isAccCont: boolean;
    separAccCode: string;
    isMergeCont: boolean;
    notMor: boolean;
    morDay: number;
    scalaPrefix: string;
    scalaAccInvNo: number;
    promptPrint: boolean;
    needTaxNumber: boolean;
    traditionInvhead: boolean;
    dpaymAccgroup: string;
    invAccgroup: string;
    accCodeDiffInc: string;
    accCodeDiffExp: string;
    maxDiff: number;
    viroPrint: boolean;
    isElabeCont: boolean;
    autoBooking: boolean;
    invRepDepot: boolean;
    remark1For: string;
    remark2For: string;
    remark3For: string;
    remark1Eng: string;
    remark2Eng: string;
    remark3Eng: string;
    isAutStorno: boolean;
    otherCrAccCode: string;
    isAcceptor: boolean;
    isCashRemark: boolean;
    isCashreg: boolean;
    isNoCuraccOut: boolean;
    stPrintNumber: number;
    basetwelveHeader: boolean;
    isElektrInv: boolean;
    elektrInvPath: string;
    elektrMask: string;
    isSeqNo: boolean;
    isElektrSign: boolean;
    isExportXml: boolean;
    isSendInvEmail: boolean;
    isPrNotPrPay: boolean;
    isWebserviceSign: boolean;
    isShipDateC: boolean;
    isPartlyDpaym: boolean;
    partbankName1: string;
    partbankName2: string;
    partbankName3: string;
    isDefAccgroup: boolean;
    diffTAccCodeT: string;
    diffTAccCodeK: string;
    currBankAccno: boolean;
    einvSystem: string;
    isOnlineCashp: boolean;
    isTaxaccCheck: boolean;
    taxAbove: number;
    einvPrefixPart: boolean;
    defCurrency: string;
    vatAccCode0Eu: string;
    saveEInvPdf: boolean;
    deleted: boolean;
}


export const InvoiceParamsDefProps: InvoiceParams = {
    id: 0,
    name: "-",
    foreign:  "-",
    english:  "-",
    code:  "-",
    prefix: "-",
    isPrintNo: false,
    isPrintNoCount: false,
    prpageNo: 0,
    printNumber: 0,
    maxPrintNumber: 0,
    remark1: "-",
    remark2: "-",
    remark3: "-",
    isNoCuracc: true,  /* Fordítva van az értéke!!! */
    defNoCuracc: false,
    isInvpaym: false,
    vatAccCode: "-",
    isAccCont: false,
    separAccCode: "-",
    isMergeCont: false,
    notMor: false,
    morDay: 0,
    scalaPrefix: "-",
    scalaAccInvNo: 0,
    promptPrint: false,
    needTaxNumber: false,
    traditionInvhead: false,
    dpaymAccgroup: "-",
    invAccgroup: "-",
    accCodeDiffInc: "-",
    accCodeDiffExp: "-",
    maxDiff: 0,
    viroPrint: false,
    isElabeCont: false,
    autoBooking: false,
    invRepDepot: false,
    remark1For: "-",
    remark2For: "-",
    remark3For: "-",
    remark1Eng: "-",
    remark2Eng: "-",
    remark3Eng: "-",
    isAutStorno: false,
    otherCrAccCode: "-",
    isAcceptor: false,
    isCashRemark: false,
    isCashreg: false,
    isNoCuraccOut: false,
    stPrintNumber: 0,
    basetwelveHeader: false,
    isElektrInv: false,
    elektrInvPath: "-",
    elektrMask: "-",
    isSeqNo: false,
    isElektrSign: false,
    isExportXml: false,
    isSendInvEmail: false,
    isPrNotPrPay: false,
    isWebserviceSign: false,
    isShipDateC: false,
    isPartlyDpaym: false,
    partbankName1: "-",
    partbankName2: "-",
    partbankName3: "-",
    isDefAccgroup: false,
    diffTAccCodeT: "-",
    diffTAccCodeK: "-",
    currBankAccno: false,
    einvSystem: "-",
    isOnlineCashp: false,
    isTaxaccCheck: false,
    taxAbove: 0.0,
    einvPrefixPart: false,
    defCurrency: "-",
    vatAccCode0Eu: "-",
    saveEInvPdf: false,
    deleted: true, /* Fordítva van az érték!!! */
}