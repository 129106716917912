var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[[_c('v-card',[_c('v-card-title',[_vm._v(" Számlatípusok paraméterezése "),_c('div',{staticClass:"col",attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push("/invoice-parameter/0")}}},[_vm._v("Új létrehozása")])],1)]),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.invoiceParams,"columns":_vm.invoiceParamCols},scopedSlots:_vm._u([{key:"defNoCuraccTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.defNoCuracc),callback:function ($$v) {_vm.$set(props.dataItem, "defNoCuracc", $$v)},expression:"props.dataItem.defNoCuracc"}})],1)]}},{key:"isNoCuraccTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isNoCuracc),callback:function ($$v) {_vm.$set(props.dataItem, "isNoCuracc", $$v)},expression:"props.dataItem.isNoCuracc"}})],1)]}},{key:"isAccContTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"dense":"","readonly":""},model:{value:(props.dataItem.isAccCont),callback:function ($$v) {_vm.$set(props.dataItem, "isAccCont", $$v)},expression:"props.dataItem.isAccCont"}})],1)]}},{key:"remarkTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(props.dataItem.remark1)+" ")])]}},{key:"editTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.editInvoiceParam(props.dataItem.id)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa fa-edit")])],1)],1)]}},{key:"deletedTemp",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-switch',{attrs:{"dense":"","color":"primary","readonly":""},model:{value:(props.dataItem.deleted),callback:function ($$v) {_vm.$set(props.dataItem, "deleted", $$v)},expression:"props.dataItem.deleted"}})],1)]}}])})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }