import CurrentAccount from "@/modules/currentAccount/models/CurrentAccount";
import PossibleWriteOffDebt from "./PossibleWriteOffDebt";
import { substract } from "@/modules/currentAccount/models/CurrentAccount";
export default interface PartnerPossibleWriteOffData {
    partnerName: string;
    partnerId: string;
    partnerCity: string;
    partnerAddress: string;
    partnerTaxNo: string;
    currentAccount: CurrentAccount;
    expectedAccount: CurrentAccount;
    isDetailView: boolean;
    note: string;

    possibleWriteOffDebts: PossibleWriteOffDebt[];
}

// Debt = incomeItems.Sum(x => x.AbsTotal - x.HufSumPayment),
// OverpayedDebt = incomeItems.Where(x => x.HufSumPayment > x.AbsTotal).Sum(x => x.HufSumPayment - x.AbsTotal),
// Claim = claimItems.Sum(x => x.AbsTotal - x.HufSumPayment),
// OverpayedClaim = claimItems.Where(x => x.HufSumPayment > x.AbsTotal).Sum(x => x.HufSumPayment - x.AbsTotal),
/**
 * Kiszámolja a várható egyenleget a kiválaszott tételek alapján
 * 
 * A számítás menete:
 * Kiszámítjuk a kiválsztott tételek tartozásait és bevételeit
 * Ebből kijön hogy mennyi tartozást és követelést vezettünk ki az aktuális egyenlegből
 * Majd ezt kivonjuk belőle és ez lesz a várható egyenleg
 */
export function calcExpected(
    possibleWriteOffDebts: PossibleWriteOffDebt[], 
    currentAccount: CurrentAccount
    ): CurrentAccount
{
    const expectedDiff = new CurrentAccount();
    expectedDiff.debt = possibleWriteOffDebts
    .filter(x => x.isIncome && x.isSelected && x.code != "OVER")
    .reduce((partSum, x) => partSum + (Math.abs(x.total) - x.payed), 0);

    expectedDiff.overpayedDebt = possibleWriteOffDebts
    .filter(x => x.isIncome && x.isSelected
        && x.payed > Math.abs(x.total))
    .reduce((partSum, x) => partSum + (x.payed - Math.abs(x.total)), 0);

    expectedDiff.claim = possibleWriteOffDebts
    .filter(x => !x.isIncome && x.isSelected && x.code != "OVER")
    .reduce((partSum, x) => partSum + (Math.abs(x.total) - x.payed), 0);

    expectedDiff.overpayedClaim = possibleWriteOffDebts
    .filter(x => !x.isIncome && x.isSelected
        && x.payed > Math.abs(x.total))
    .reduce((partSum, x) => partSum + (x.payed - Math.abs(x.total)), 0);

    console.log(expectedDiff);

    const expectedAccount = Object.assign({}, currentAccount);
    //A substract újra is számolja az egyenleget
    substract(expectedAccount, expectedDiff);

    return expectedAccount;
}