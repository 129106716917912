import { EntityReference } from "@/models/EntityReference"
import InvoiceItemDimension from "@/modules/invoiceItemDimension/models/InvoiceItemDimension"
import store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";

export enum ApprovalRowType {
    IncomingInvoiceControl = 5,
    IncomingInvoiceApprove = 6,
    FullfilmentControl = 7,
    FullfilmentApprove = 8
}

export interface ApprovalMatrixRow {
    type: ApprovalRowType;
    invGroupID: number;
    invGroupName: string;
    currency: string;
    amount: number;
    isValueIndependent: boolean;
    isAccGroupIndependent: boolean;
    additionalApprovers: EntityReference[];
}

export interface ApprovalMatrixData {
    rows: ApprovalMatrixRow[];
}

export class ApprovalMatrix {
    constructor(private rows: ApprovalMatrixRow[]) {
    }

    get isValueIndependent() {
        return this.rows.length > 0 && this.rows.some(i => i.isValueIndependent)
    }

    canApproveInvGroup(dimension: InvoiceItemDimension) {
        const validationError = this.validateInvGroupApproval(dimension)
        return !validationError ? true :  false
    }

    validateInvGroupApproval(dimension: InvoiceItemDimension) {
        const approvalData =
            this.rows.filter(r => r.isAccGroupIndependent || r.invGroupID === dimension.invGroup || r.invGroupName === dimension.invGroupName)

        if (approvalData.length === 0) {
            return 'Nem jogosult a költséghely jóváhagyására!'
        }

        if (approvalData.some(i => i.isValueIndependent)) {
            return ''
        }

        if (approvalData.some(i => i.additionalApprovers.length > 0)) {
            return ''
        }

        const amount = approvalData.reduce((amount, i) => i.amount > amount ? i.amount : amount, 0.0);

        const currency = store.invoice.invCurrency;
        const rate = store.invoice.exchangeRate;
        
        if (currency !="HUF" && amount < dimension.invGroupNet*rate) {
            return `A következő költséghelyen: ${dimension.invGroupName}, maximum ${amount.toLocaleString('hu')} ${approvalData[0].currency}-ig hagyhat jóvá! A költséghely átszámított értéke ${(dimension.invGroupNet*rate).toLocaleString('hu')} ${approvalData[0].currency}.`;
        }

        if (amount < dimension.invGroupNet) {
            return `A következő költséghelyen: ${dimension.invGroupName}, maximum ${amount.toLocaleString('hu')} ${approvalData[0].currency}-ig hagyhat jóvá! A költséghely értéke ${dimension.invGroupNet} ${approvalData[0].currency}.`;
        }
        return '';
    }
}
