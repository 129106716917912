var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasData)?_c('div',[_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Cikkszám")]),_c('th',[_vm._v("Termék")]),_c('th',[_vm._v("Rendelés száma")]),_c('th',[_vm._v("Készlet ID")]),_c('th',[_vm._v("GENI kapcsolat")]),_c('th',[_vm._v("Súly")]),_c('th',[_vm._v("Nettó érték")]),_c('th',[_vm._v("Egységár")]),_c('th',[_vm._v("Mennyiség")]),_c('th'),_c('th')])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('fragment',{key:item.stockID},[(_vm.itemStates[item.stockID])?_c('tr',[_c('td',[(item.attributes.length > 0)?_c('icon-button',{attrs:{"icon":"fa fa-tag","tooltip":"Termék tulajdonságok"},on:{"click":function($event){return _vm.toggleAttributes(item)}}}):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.productCode))]),_c('td',[_c('div',[_vm._v(_vm._s(item.productName))]),(item.batchNumber)?_c('div',{staticClass:"grey--text"},[_vm._v("Batch: "+_vm._s(item.batchNumber))]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(item.orderNumber)+" ")]),_c('td',[_c('div',{staticClass:"d-flex flex-row"},[_vm._v(" "+_vm._s(item.stockID)+" "),((item.isInSupplierComplaint && item.supplierComplaintDetails != null))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"red--text ml-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" fa fa-registered ")])]}}],null,true)},[_c('span',[_vm._v("A készlethez szállítói reklamáció kapcsolódik: "+_vm._s(item.supplierComplaintDetails.id)+".")])]):_vm._e(),(item.hasCreditingStorein)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"orange--text ml-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" fa-info-circle ")])]}}],null,true)},[_c('span',[_vm._v("A készlethez jóváíró betár kapcsolódik: "+_vm._s(item.creditingStoreinId)+". ")])]):_vm._e()],1)]),_c('td',[_c('div',_vm._l((item.relatedInvoices),function(relation){return _c('invoice-relation-badge',{key:relation.relationID,attrs:{"relation":relation,"unit":item.unit}})}),1)]),_c('td',[_vm._v(_vm._s(_vm.getStockWeight(item).toLocaleString("hu"))+" KG")]),_c('td',[_c('money-display',{attrs:{"value":item.unitPrice * item.quantity,"currency":_vm.currency,"decimals":_vm.getGeniDigits(_vm.currency)}})],1),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('money-display',{attrs:{"value":item.unitPrice,"currency":_vm.currency,"decimals":_vm.getGeniUnitDigits(_vm.currency)}})],1)]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{attrs:{"name":"quantity","outlined":"","single-line":"","hide-details":"auto","disabled":item.isInSupplierComplaint && item.supplierComplaintDetails.quantity != 0},on:{"change":function (value) { return _vm.quantityChanged(item, value); }},model:{value:(_vm.itemStates[item.stockID].quantity),callback:function ($$v) {_vm.$set(_vm.itemStates[item.stockID], "quantity", _vm._n($$v))},expression:"itemStates[item.stockID].quantity"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.unit))])],1)]),_c('td',[_c('add-item-button',{attrs:{"item":item}},[_c('icon-button',{attrs:{"tooltip":"Készlet számlához rendelése","icon":"fa-plus"},on:{"click":function($event){return _vm.submitStock(item)}}})],1)],1),_c('td',[_c('v-btn',{staticClass:"mx-3",attrs:{"color":_vm.itemStates[item.stockID].selected ? 'primary' : 'grey lighten-2'},on:{"click":function($event){return _vm.toggleItemSelection(item)}}},[_c('v-icon',{staticClass:"white--text",attrs:{"x-small":""}},[_vm._v("fa-check")])],1)],1)]):_vm._e(),(
                _vm.itemStates[item.stockID] && _vm.itemStates[item.stockID].attributesVisible && item.attributes.length > 0
              )?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('attribute-list',{attrs:{"attributes":item.attributes}})],1)]):_vm._e(),(item.isInSupplierComplaint && !_vm.configuration.operel)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Reklamáció ID: "+_vm._s(item.supplierComplaintDetails.id))]),_c('td',{attrs:{"colspan":"3"}},[_vm._v("Terhelési értesítő: "+_vm._s(item.supplierComplaintDetails.debitId))]),_c('td',{attrs:{"colspan":"3"}},[_vm._v(" Helyesbítés összege: "+_vm._s(item.supplierComplaintDetails.debitAmount.toLocaleString("hu"))+" "+_vm._s(_vm.currency)+" ")])]):_vm._e()])}),1)]},proxy:true}],null,false,284777132)}),(_vm.hasSelectedItem)?_c('div',{staticClass:"d-flex my-3 justify-end"},[_c('v-btn',{staticClass:"ligthen-2",attrs:{"color":"primary"},on:{"click":_vm.addSelectedItems}},[_c('v-icon',{staticClass:"white--text",attrs:{"x-small":""}},[_vm._v("fa-plus")]),_c('span',{staticClass:"ml-2"},[_vm._v("Kijelöltek hozzáadása")])],1)],1):_vm._e()],1):_c('div',[_vm._v("Nincs készlet tétel")])])}
var staticRenderFns = []

export { render, staticRenderFns }