export default class StatusColorMap {
  map: Record<string, string> = { default: "primary" };

  constructor(map?: Record<string, string>) {
    for (const m in map) {
      this.map[m] = map[m];
    }
  }

  add(status: string, color: string) {
    this.map[status] = color;
    return this;
  }

  addMultiple(map: Record<string, string>) {
    for (const m in map) {
      this.map[m] = map[m];
    }
    return this;
  }

  get(status: string | number) {
    return this.map[status] || this.map.default;
  }
}
