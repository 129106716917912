var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"mt-2",staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v(" Gyártás keresése ")]),_c('br'),_c('div',{staticClass:"d-flex flex-row"},[_c('v-autocomplete',{staticClass:"mr-2",staticStyle:{"max-width":"230px !important"},attrs:{"dense":"","outlined":"","items":_vm.autocompleteItems,"item-text":"name","item-value":"name","value":_vm.selectedAutocompleteItem,"return-object":true,"hide-details":"auto"},on:{"change":_vm.autocompleteItemChanged}}),_c('v-text-field',{attrs:{"outlined":"","single-line":"","placeholder":"Munkalapszám, egyéb cikk keresése..."},on:{"keyup":_vm.search},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('icon-button',{attrs:{"tooltip":"Találatok törlése","icon":"fa-times"},on:{"click":_vm.clear}}),_c('icon-button',{attrs:{"tooltip":"Kijelöltek hozzáadása","icon":"fa-plus"},on:{"click":_vm.addItems}})],1),_c('grid',{attrs:{"data-items":_vm.newWorkSheetItemRelaions,"columns":_vm.newColumns},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('check-box',{attrs:{"selected":props.dataItem.isSelected},on:{"change":function($event){return _vm.selectItem(props.dataItem)}}})],1)]}},{key:"nettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.netto,"currency":props.dataItem.invCurrency}})],1)]}}])}),_c('h5',{staticClass:"mt-2",staticStyle:{"font-weight":"bold","color":"#394263"}},[_vm._v("Gyártás kapcsolat")]),_c('grid',{attrs:{"data-items":_vm.workSheetItemRelaions,"columns":_vm.columns},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.deleteItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Törlés")])])],1)]}},{key:"nettoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"value":props.dataItem.netto,"currency":props.dataItem.invCurrency}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }