



















































































































































































import Fragment from "@/components/Fragment.vue";
import configuration, { loadConfiguration } from "@/models/Configuration";
import eventBus from "@/services/eventBus";
import http, { checkResponse } from "@/services/http";
import Vue from "vue";
import CurrencyDigit from "../models/CurrencyDigit";

export default Vue.extend({
  components: { Fragment },
  data() {
    return {
      items: configuration.currencyDigits,
      config: configuration,
    };
  },

  methods: {
    headDigitsChanged(item: CurrencyDigit) {
      item.geniHeadDigits = item.headDigits;
    },

    unitDigitsChanged(item: CurrencyDigit) {
      item.geniUnitDigits = item.unitDigits;
    },

    digitsChange(item: CurrencyDigit) {
      item.geniDigits = item.digits;
    },

    async saveChanges() {
      eventBus.$emit("busy-message", true);
      try {
        await http
          .put(`configuration/currency-digits`, this.items)
          .then(checkResponse)
          .then(async (x) => {
            eventBus.$emit("busy-message", false);
            if (x) {
              eventBus.$emit("success", "Sikeres mentés!");
              await loadConfiguration();
            } else eventBus.$emit("error", "Nem sikerült a mentés!");
            return x;
          });
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("busy-message", false);
      }
    },
  },

  async beforeMount() {
    await loadConfiguration();
    console.log(this.config.getCurrDigits("BGN")?.geniHeadDigits)
  },
});
