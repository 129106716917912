export default interface DocumentType {
    id: number;
    name: string;
    foreign: string;
    english: string;
    isGenFolder: boolean;
    folderName: string;
    sysCode: string;
    isStayInDb: boolean;
    isMachineProgram: boolean;
    deleted: boolean;
    readonly: boolean;
}

export const documentTypeDefaultProps: DocumentType = {
    id: 0,
    name: "",
    foreign: "",
    english: "",
    isGenFolder: false,
    folderName: "",
    sysCode: "",
    isStayInDb: false,
    isMachineProgram: false,
    deleted: false,
    readonly: false,
}