export default interface SerCar {
    id: number;
    regNum: string;
    salePrice: number;
}

export const serCarDefaultProps: SerCar = {
    id: 0,   
    regNum: "",
    salePrice: 0,
}