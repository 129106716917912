import { render, staticRenderFns } from "./CreateInvoiceFromExperimentalView.vue?vue&type=template&id=134938d6&scoped=true&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D&"
import script from "./CreateInvoiceFromExperimentalView.vue?vue&type=script&lang=ts&"
export * from "./CreateInvoiceFromExperimentalView.vue?vue&type=script&lang=ts&"
import style0 from "./CreateInvoiceFromExperimentalView.vue?vue&type=style&index=0&id=134938d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134938d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VExpandTransition,VProgressCircular,VRow,VSelect,VSwitch,VTextField})
