















































import Vue from "vue";

import StoreInsTab from "../components/StoreInsTab.vue";
import CooperationOrderSearch from "../components/CooperationOrderSearch.vue";
import OtherItemsTab from "../components/OtherItemsTab.vue";
import FreeWordItemForm from "../components/FreeWordItemForm.vue";
import store, { InvoiceItemStore } from "../services/store";
import flowStore, {
  InvoiceFlowStore,
} from "../../invoiceFlow/services/InvoiceFlowStore";

import configuration, { AppConfiguration } from "../../../models/Configuration";
import Tabs from "../../../components/Tabs.vue";
import InvoiceFee from "../components/InvoiceFee.vue";
import SalesSearch from "../components/SalesSearch.vue";

interface Data {
  configuration: AppConfiguration;
  flowStore: InvoiceFlowStore;
  store: InvoiceItemStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  props: {
    isPageDisabled: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    StoreInsTab,
    CooperationOrderSearch,
    OtherItemsTab,
    FreeWordItemForm,
    Tabs,
    InvoiceFee,
    SalesSearch,
  },

  data: () => ({
    configuration,
    store,
    flowStore,
  }),
});
