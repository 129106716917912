








































import eventBus from "@/services/eventBus";
import Vue from "vue";
import VIRIApi from "../services/VIRIApi";
import cashStatementStore, {
  CashStatementStore,
} from "@/modules/cashStatement/services/store";
import { TechnicalInvoiceItemInfo } from "@/modules/technicalInvoice/models/TechnicalInvoiceItemInfoModel";

interface Methods {
    createVIROfromVIRI(): void;
    createCashDocument(): void;
    openOnNewPage(viroId: number): void;
}

interface Data {
    cashStatementStore: CashStatementStore;
    showQuestion: boolean;
    invoiceVIRIItems: TechnicalInvoiceItemInfo[];
}

interface Props {
    invoice: number;
    inStatement: boolean;
}

export default Vue.extend<Data,Methods,{},Props> ({
    props: {
        invoice: Number,
        inStatement: {type: Boolean, default: false}
    },
    data() {
        return {
            cashStatementStore: cashStatementStore,
            showQuestion: false,
            invoiceVIRIItems: []
        } 
    },
    methods: {

        async createVIROfromVIRI() {
          try {
            eventBus.$emit('busy-message', true)       
            await VIRIApi.createVIROfromVIRI(this.invoice);
            eventBus.$emit("success", "Számla sikeresen létrehozva.");
            eventBus.$emit("api-search-page:refresh");
            this.showQuestion = false;
            await this.openOnNewPage(this.invoice);
          }
          finally {
            eventBus.$emit('busy-message', false)
          }
        },

        async createCashDocument() {
          try {
            eventBus.$emit('busy-message', true)  
            await VIRIApi.createViroAndCashDoc(this.invoice, cashStatementStore.cashStatement.id);
            eventBus.$emit("success", "Számla sikeresen fizetve.");
            eventBus.$emit("cachdoccreatedfromviri", "");
            eventBus.$emit("virihcs:reload");
            this.showQuestion = false;
            await this.openOnNewPage(this.invoice);
          }
          finally {
            eventBus.$emit('busy-message', false)  
          }
        },

        async openOnNewPage(invoiceId: number) {
            eventBus.$emit("success", "Számla nyomtatása.");
            const b64encoded = await VIRIApi.getVIRIPdf(invoiceId);
            const pdfWindow = window.open("");
            if (pdfWindow) {
              pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                  encodeURI(b64encoded) +
                  "'></iframe>"
              );
            }
          // const b64encoded = await VIRIApi.getVIRIPdf(viroId);
          // const pdfWindow = window.open("");
          // if (pdfWindow) {
          //   const iframeContent = `<iframe src="data:application/pdf;base64,${b64encoded}" width='100%' height='100%'></iframe>`;
          //   pdfWindow.document.open();
          //   pdfWindow.document.write(iframeContent);
          //   pdfWindow.document.close();
          // }
        }
    },
    async beforeMount() {
      this.invoiceVIRIItems = await VIRIApi.getViriItemsInfo(this.invoice);
    },
    computed: {
      itemColumns: () => [
        { field: "partnerName", title: "Partner" },
        { field: "regNum", title: "Gépjármű", width: "100px" },
        { field: "paymode", title: "Fizetési mód" },
        { field: "checkTypeName", title: "Típus", width: "250px" },
        { field: "total", title: "Összeg", className: "right_text", width: "100px" },
        { field: "checkId", title: "Csekk azonosító" }
      ],
    }
})
