










































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import configuration, {AppConfiguration} from "@/models/Configuration";
import GeneralIngoingInvoiceApi from "../services/GeneralIngoingInvoiceApi";
import ApiSearchPage from "@/components/apiSearch/components/ApiSearchPage.vue";
import {
  ApiSearchColumn,
  ColumnType,
} from "@/components/apiSearch/models/ApiSearch";
import CheckBox from "@/components/CheckBox.vue";

interface Data {
  importMode: string;
  invoiceNo: string;
  taxNo: string;
  startDate: string;
  endDate: string;
  configuration: AppConfiguration;
  importItemsInTable: any;
  selectAll: boolean;
}

interface Methods {
  cancel(): void;
  startSync(): void;
  importItems(items: any): void;
  importInvoices(): void;
  refreshApiSearch(): void;
  selectItem(item: any): void;
}

interface Computed {
  syncValid: boolean;
  columns: ApiSearchColumn[];
}

export default Vue.extend<Data, Methods, Computed, {}>({
  data() {
    return {
      configuration,
      importMode: '',
      invoiceNo: '',
      taxNo: '',
      startDate: '',
      endDate: '',
      importItemsInTable: [],
      selectAll: false,
    };
  },

  components: {
    Page,
    ApiSearchPage,
    CheckBox
  },

  methods: {
    selectItem(item) {
        item.selected = !item.selected;
    },

    importInvoices(){
      const items = this.importItemsInTable.filter((x: any) => x.selected);

      if (items.length > 0){
        const ids = items.map((x: any) => x.importId);
        GeneralIngoingInvoiceApi.importInvoices(items.map((x: any) => x.importId)).then(() => { this.refreshApiSearch() });
      }
    },

    cancel() {
      this.$router.go(-1);
    },

    startSync() {
        if (this.importMode == 'invoiceNo'){
          GeneralIngoingInvoiceApi.syncInvoices(false, this.taxNo, this.invoiceNo, '', '').then(() => { this.refreshApiSearch() });
        }

        if (this.importMode == 'taxNo'){
          GeneralIngoingInvoiceApi.syncInvoices(false, this.taxNo, this.invoiceNo, '', '').then(() => { this.refreshApiSearch() });
        }

        if (this.importMode == 'term'){
          GeneralIngoingInvoiceApi.syncInvoices(false, '', '', this.startDate, this.endDate).then(() => { this.refreshApiSearch() });
        }

        if (this.importMode == 'all'){
          GeneralIngoingInvoiceApi.syncInvoices(true, '', '', '', '').then(() => { this.refreshApiSearch() });
        }
    },

    importItems(items: any){
      this.importItemsInTable = items;
    },

    refreshApiSearch() {
      const childComponentInstance = this.$refs.asref;
      if (childComponentInstance) {
        (this.$refs.asref as any).refreshClick();
      }
    },
  },

  watch: {
    selectAll: function(){
      this.importItemsInTable.forEach((x: any) => x.selected = this.selectAll);
    }
  },

  computed: {
    syncValid() {
        switch(this.importMode){
          case 'invoiceNo':
            return !!this.invoiceNo && !!this.taxNo;
          case 'taxNo':
            return !!this.taxNo && !!this.invoiceNo;
          case 'term':
            return !!this.startDate && !!this.endDate;
          case 'all':
            return true;
        }

        return false;
    },

    columns: (): ApiSearchColumn[] => [
      {
        title: "",
        field: "selected",
        cell: "checkBox",
        filterable: false,
        sortable: false,
        header:"checkBoxHeader",
        
      },
      {
        title: "Számlaszám",
        field: "invoiceNo",
      },
      {
        title: "Hivatkozott számla száma",
        field: "originalInvoiceNo",
      },
      {
        title: "Számla dátuma",
        field: "invDate",
        type: ColumnType.Date
      },
      {
        title: "Teljesítés dátuma",
        field: "deliveryDate",
        type: ColumnType.Date
      },
      {
        title: "Fizetési mód",
        field: "paymentMode",
      },
      {
        title: "Fizetési határidő",
        field: "payDate",
        type: ColumnType.Date
      },
      {
        title: "Pénznem",
        field: "currency",
      },
      {
        title: "Szállító neve",
        field: "supplierName",
      },
      {
        title: "Szállító adószáma",
        field: "supplierTaxNo",
      },
      {
        title: "Nettó",
        field: "netto",
        type: ColumnType.Money,
        filterable: false,
        sortable: false,
      },
      {
        title: "ÁFA",
        field: "vat",
        type: ColumnType.Money,
        filterable: false,
        sortable: false,
      },
      {
        title: "Bruttó",
        field: "brutto",
        type: ColumnType.Money,
        filterable: false,
        sortable: false,
      },
      {
        title: "Hiba",
        field: "error",        
        filterable: false,
        sortable: false,
        cell: 'error'
      },
    ],
  },
});
