























import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: 'fa-check'
    },

    activeColor: {
      type: String,
      default: 'primary'
    },

    inactiveColor: {
      type: String,
      default: 'grey'
    }
  },

  methods: {
    handleClick() {
      this.$emit("clicked")
      this.$emit("input", !this.value);
    },
  }
});
