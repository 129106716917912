export default interface AccStatus {
  name: string;
  readOnly: boolean;
  deleted: boolean;
  id: number;
}

export const accStatusDefaultProps: AccStatus = {
  name: "Összes",
  readOnly: true,
  deleted: false,
  id: 1,
};
