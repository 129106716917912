var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[(!_vm.loading)?_c('ApiSearchPage',{attrs:{"title":"Banki csomagok","create":_vm.createButtonText,"url":"bank-remit/search","columns":_vm.columns,"detailsField":"expanded","delay":1000},on:{"create":function($event){return _vm.createBankRemit()}},scopedSlots:_vm._u([{key:"commandTemplate",fn:function(ref){
var item = ref.item;
return [(!_vm.isRiportSelect)?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])])],1):_vm._e()]}},{key:"exportTemplate",fn:function(ref){
var item = ref.item;
return [(!_vm.isRiportSelect)?_c('td',[(_vm.isExportEnabled(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.setExportExpanded(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-share-square-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Csomag exportálása")])]):_vm._e()],1):_vm._e()]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.exportExpanded)?_c('div',[_c('v-card',{staticStyle:{"border-top":"thin solid #f3f3f3 !important"}},[_c('v-card-text',[_c('BankRemitSelectExportType',{attrs:{"remit":item},on:{"reloadRemits":_vm.refresh}})],1)],1)],1):_vm._e(),(item.historyExpanded)?_c('div',{staticClass:"py-2",staticStyle:{"background":"white"}},[_c('v-card',{staticStyle:{"border-top":"thin solid #f3f3f3 !important"}},[_c('v-card-text',[_c('BankRemitHistory',{attrs:{"remit":item}})],1)],1)],1):_vm._e(),(item.showPartners)?_c('div',[_c('grid',{attrs:{"data-items":item.items,"columns":_vm.remitItemCols},scopedSlots:_vm._u([{key:"selectPartnerTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('icon-button',{attrs:{"tooltip":"Partner kiválasztása","icon":"fa fa-check"},on:{"click":function($event){return _vm.emitRemitAndPartner(props.dataItem, item)}}})],1)]}}],null,true)})],1):_vm._e()])]}},{key:"historyTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[(!_vm.isRiportSelect)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.setHistoryExpanded(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-solid fa-bars ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Banki csomag története ")])]):(_vm.isRiportSelect && !_vm.isPartnerSelect)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('remitId', item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-solid fa-file ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Banki csomag riport ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.setShowPartners(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-solid fa-address-book ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Banki csomag partnerei ")])])],1)]}},{key:"amountTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('money-display',{attrs:{"value":item.amount,"currency":item.invCurrency}})],1)]}},{key:"dueAmountTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('money-display',{attrs:{"value":item.dueAmount,"currency":item.invCurrency}})],1)]}}],null,false,1413879766)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }