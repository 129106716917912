






















































































































































import Vue from "vue";

import StoreInsTab from "../components/StoreInsTab.vue";
import ExtOrdersTab from "../components/ExtOrdersTab.vue";
import OtherItemsTab from "../components/OtherItemsTab.vue";
import store, { InvoiceItemStore } from "../services/store";
import flowStore, {
  InvoiceFlowStore,
} from "../../invoiceFlow/services/InvoiceFlowStore";

import configuration, { AppConfiguration } from "../../../models/Configuration";
import InvoiceRectTable from "../components/InvoiceRectTable.vue";
import GeneralRectItemTable from "../components/GeneralRectItemTable.vue";
import CarRectItemTable from "../components/CarRectItemTable.vue";
import Tabs from "../../../components/Tabs.vue";
import ConsignerConsumptionSearch from "../components/ConsignerConsumptionSearch.vue";
import * as api from "@/modules/invoiceItems/services/api";

import InvoiceDebitTable from "../../debitPayment/views/InvoiceDebitTable.vue";
import NewCarsTab from "@/modules/canNet/views/NewCarsTab.vue";
import UsedCarsTab from "@/modules/canNet/views/UsedCarsTab.vue";
import WscontItemRectifyTable from "@/modules/invoiceItems/components/WscontItemRectifyTable.vue";
import ContractorItemsTab from "@/modules/invoiceItems/components/ContractorItemsTab.vue";

interface Data {
  configuration: AppConfiguration;
  flowStore: InvoiceFlowStore;
  store: InvoiceItemStore;
  optionalComponents: Set<string>;
}

interface Props {
  isPageDisabled: boolean;
  supplierID: number;
}

export default Vue.extend<Data, {}, {}, Props>({
  props: {
    isPageDisabled: {
      type: Boolean,
      default: true,
    },

    supplierID: {
      type: Number,
    },
  },

  components: {
    StoreInsTab,
    ExtOrdersTab,
    OtherItemsTab,
    InvoiceRectTable,
    ConsignerConsumptionSearch,
    Tabs,
    InvoiceDebitTable,
    NewCarsTab,
    UsedCarsTab,
    GeneralRectItemTable,
    CarRectItemTable,
    WscontItemRectifyTable,
    ContractorItemsTab
  },

  data: () => ({
    configuration,
    store,
    flowStore,
    optionalComponents: new Set<string>(),
  }),

  async mounted() {
    const consignerData = await api.searchConsignerConsumptions(
      this.supplierID
    );

    if (consignerData.length > 0) {
      this.optionalComponents = new Set<string>(["ConsignerConsumption"]);
    }

    if (
      this.flowStore.invoice.type == 2 ||
      this.flowStore.invoice.type == 3 ||
      this.flowStore.invoice.type == 7 ||
      this.flowStore.invoice.type == 8
    ) {
      this.flowStore.isCar = true;
      this.flowStore.isComponent = false;
      this.flowStore.isSubcontractor = false;
    }
  },

  computed: {
    isInvoiceApproved(){
      return this.flowStore.invoice.preStatus == 8;
    }
  },

  watch: {
    relationTab() {
      this.store.targetItem = null;
    },
  },
});
