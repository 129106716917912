export default interface Notice {
    id: number;
    partner: number;
    paymentMode: number;
    invDate: Date | string;
    invTime: Date;
    shipDate: Date | string;
    paymentTerm: Date | string;
    netto: number;
    total: number;
    vat: number;
    storno: boolean;
    invoiceSt: number | null | undefined;
    status: number;
    currency: string;
    remark1: string;
    remark2: string;
    invType: number;
    userId: number;
    accStatus: number;
    printNo: number;
    bankAccount: number;
    bankAccountNo: string;
    partnerRel: number;
    divItem: number;
    language: number;
    nOrder: number;
    sOrder: number;
    workSheet: number;
    custOrder: number;
    accGroup: number;
    car: number;
    genoInvType: number;
    rectify: boolean;
    invoiceRect: number | null | undefined;
    withVat: boolean;
    remark3: string;
    invCurrency: string;
    currNetto: number;
    currTotal: number;
    currVat: number;
    exchangeRate: number;
    rateDate: Date | string;
}

export const noticeDefaultProps: Notice = {
    id: 0,
    partner: 1,
    paymentMode: 1,
    invDate: new Date(),
    invTime: new Date(),
    shipDate: new Date(),
    paymentTerm: new Date(),
    netto: 0,
    total: 0,
    vat: 0,
    storno: false,
    invoiceSt: null,
    status: 1,
    currency: 'HUF',
    remark1: '',
    remark2: '',
    invType: 1,
    userId: 1,
    accStatus: 2,
    printNo: 0,
    bankAccount: 1,
    bankAccountNo: '',
    partnerRel: 2,
    divItem: 1,
    language: 1,
    nOrder: 1,
    sOrder: 1,
    workSheet: 1,
    custOrder: 1,
    accGroup: 1,
    car: 1,
    genoInvType: 1,
    rectify: false,
    invoiceRect: null,
    withVat: true,
    remark3: '',
    invCurrency: '',
    currNetto: 0,
    currTotal: 0,
    currVat: 0,
    exchangeRate: 1,
    rateDate: new Date()
}
