


















import ApiSearch from "@/components/apiSearch/components/ApiSearch.vue";

import Vue from "vue";
import { ApiSearchColumn, ColumnType } from "@/components/apiSearch/models/ApiSearch";
import BankInViewDTO from "../models/BankInViewDTO";
import { FilteringModel } from "@/services/filtering";

export default Vue.extend({
  components: { ApiSearch },

  props: {
    extraFilter: { type: Object as () => FilteringModel },
  },

  data: () => ({
    filter: new FilteringModel(),
  }),

  beforeMount() {
    this.filter = new FilteringModel();
    if (this.extraFilter) this.filter = this.filter.fromFilter(this.extraFilter.toObject());
  },

  methods: {
    selected(item: BankInViewDTO) {
      this.$emit("selected", item);
    },
  },

  computed: {
    columns: (): ApiSearchColumn[] => [
      { field: "prefix", title: "Prefix" },
      { field: "invoiceID", title: "Számlaszám" },
      { field: "invoiceNo", title: "Külső számlaszám" },
      { cell: "total", field: "total", title: "Összeg", type: ColumnType.Money },
      { field: "currency", title: "Pénznem" },
      { field: "partner", title: "Partner" },
      { field: "status", title: "Állapot", type: ColumnType.Center },
      { field: "remark", title: "Megjegyzés" },
    ],
  },
});
