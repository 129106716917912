<template>
  <div class="money-input" :class="{ flex: currency !== null, 'flex-right': currency !== null && alignRight }">
    <currency-input
      onclick="this.select()"
      style="padding-right: 1em !important;"
      :class="'text-' + textAlign"
      :disabled="$attrs.disabled"
      :precision="precision"
      :locale="locale"
      :currency="null"
      ref="currinput"
      :distraction-free="true"
      :value="$attrs.value"
      @input="onInput"
      @change="lazyInput"
      :valueRange="valueRange"
      :tabindex="tabidx"
    />
    <input v-if="currencyVisible" disabled :value="currency" class="currency-inp text-center" />
    <div v-else-if="undecoratedCurrencyVisible" class="d-block mt-2 ml-1">
      {{ currency }}
    </div>
  </div>
</template>

<script>
import { CurrencyInput, getValue, setValue } from "vue-currency-input";
import configuration, { loadConfiguration } from "@/models/Configuration";
import { ValueRange } from "@/models/ValueRange";

export default {
  data() {
    return {
      dataPrecision: this.precision ?? 0,
      value: this.$attrs.value,
    };
  },

  async beforeMount() {
    let currdig = null;
    if (!configuration.currencyDigits || configuration.currencyDigits.length < 1) {
      await loadConfiguration();
      if (configuration.currencyDigits) {
        currdig = configuration.currencyDigits.find((x) => x.currency == this.$props.currency);
      }
    }

    if (currdig && this.precision === undefined) {
      this.dataPrecision = currdig.digits;
    }
  },

  watch: {
    precision() {
      this.dataPrecision = this.precision;
    },
  },

  components: { CurrencyInput },

  props: {
    currency: { type: String, default: "HUF" },
    precision: { type: Number, default: undefined },
    locale: { type: String, default: "sv" },
    textAlign: { type: String, default: "right" },
    round: { type: Boolean, default: false },
    currencyVisible: { type: Boolean, default: true },
    undecoratedCurrencyVisible: { type: Boolean, default: false },
    valueRange: { type: ValueRange, default: undefined },
    tabidx: { type: Number, default: undefined },
    alignRight: { type: Boolean, default: false },
  },

  methods: {
    onInput() {
      const value = getValue(this.$el.children[0]);

      this.$emit("input", value);
    },

    lazyInput(value) {
      if (!value || value.length < 1) {
        setValue(this.$el.children[0], 0);
        return;
      }

      if (this.$props.round) {
        setValue(this.$el.children[0], Math.round(value / 5) * 5);
      }

      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid lightgrey;
  box-shadow: inset 0 0 0.5px 0.5px rgba(0, 0, 0, 0.1);
  height: 31px;
  margin-bottom: 0.5em;
  max-width: 103px;
  width: fit-content;
  background: white;
  font-size: 12px;
  background-color: white;
}

input:disabled {
  background: #efefef;
}

input:focus {
  outline: 1px solid lightgrey;
  border: 1px solid lightgrey;
  box-shadow: inset 0 0 1px 0.5px lightgrey;
}

.currency-inp {
  max-width: 43px;
  min-width: 43px;
  pointer-events: none;
  display: block;
  position: relative;
  border-left: none;
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex-right {
  place-content: flex-end;
}
</style>
