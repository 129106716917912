





















































































































import Vue from "vue";
import Store, { InvoiceFlowStore } from "../services/InvoiceFlowStore";
import api from "../services/InvoiceFlowApi";
import VatDivisionDisplay from "../models/VatDivisionDisplay";
import VatDivision from "../models/VatDivision";
import Tax, { taxDefaultProps } from "@/modules/generalIngoingInvoice/models/Tax";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import VatAnaliticsDetails from "./VatAnaliticsDetails.vue";

interface Data {
  geni: number;
  store: InvoiceFlowStore;
}

interface Methods {
  findTax(x: VatDivision): Tax;
  emit(x: VatDivisionDisplay): void;
  buildVatDivisionDisplays(): void;
  setStore(): void;
  showDetails(item: VatDivisionDisplay): void;
  substractableChanged(x: VatDivisionDisplay): void;
}

interface Props {
  disabled: boolean;
  isSubtractableEdit: boolean;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: {
    MoneyDisplay,
    VatAnaliticsDetails,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isSubtractableEdit: {
      type: Boolean,
      default: false,
    }
  },

  data(): Data {
    return {
      geni: 0,
      store: Store,
    };
  },

  computed: {
    columns: () => [
      {
        width: 50,
        cell: "detailsButtonTemplate",
      },
      {
        field: "taxName",
        title: "Áfa megnevezés",
      },
      {
        field: "taxDegree",
        title: "Áfa %",
      },
      {
        field: "netto",
        title: "Nettó",
        cell: "nettoTemplate",
      },
      {
        field: "brutto",
        title: "Bruttó",
        cell: "bruttoTemplate",
      },
      {
        field: "vat",
        title: "Áfa",
        cell: "vatTemplate",
      },
      {
        field: "currency",
        title: "Pénznem",
      },
      {
        field: "substractable",
        title: "Levonható?",
        editor: "boolean",
        cell: "substractTemplate",
      },
      {
        field: "isInvest",
        title: "Beruházás?",
        editor: "boolean",
        cell: "investTemplate",
      },
      {
        field: "backDemand",
        title: "Visszaigénylendő?",
        editor: "boolean",
        cell: "backDemandTemplate",
      },
      {
        field: "isService",
        title: "Szolgáltatás?",
        editor: "boolean",
        cell: "serviceTemplate",
      },
      {
        width: 0,
      },
    ],
    items() {
      return this.store.vatDivisionsDisplay;
    },
  },

  async beforeMount() {
    this.setStore();
  },

  methods: {
    substractableChanged(x: VatDivisionDisplay){
      this.emit(x);

      const uniqueItems = this.store.vatDivisionsDisplay.filter(y => 
        y.taxId == x.taxId &&
        y.substractable == x.substractable &&
        y.isInvest == x.isInvest &&
        y.backDemand == x.backDemand &&
        y.isService == x.isService &&
        y.tax2 == x.tax2 &&
        y.id != x.id
      );

      uniqueItems.forEach(y => {
        y.substractable = !x.substractable;
        this.emit(y);
      });
    },

    async setStore() {
      this.store.vatDivisionsDisplay = [];
      this.geni = Number(this.$route.params.invoiceId);
      this.store.vatDivisions = await api.getVatDivisionEditModels(this.geni);
      this.buildVatDivisionDisplays();
    },

    buildVatDivisionDisplays() {
      this.store.vatDivisions.forEach((x) => {
        this.store.vatDivisionsDisplay.push({
          id: x.id,
          taxName: this.findTax(x).name,
          taxDegree: this.findTax(x).degree,
          brutto: x.brutto,
          netto: x.netto,
          backDemand: x.backDemand,
          isInvest: x.isInvest,
          isService: x.isService,
          vat: x.vat,
          currency: x.currency,
          invBrutto: x.invBrutto,
          invNetto: x.invNetto,
          invVat: x.invVat,
          invCurrency: x.invCurrency,
          substractable: x.substractable,
          selected: false,
          items: x.items,
          taxId: x.tax,
          tax2: x.tax2,
        });
      });
    },

    findTax(x: VatDivision) {
      const d = Store.taxes.find((y) => y.id == x.tax) ?? taxDefaultProps;
      return d;
    },

    emit(x: VatDivisionDisplay) {
      this.$emit("itemChanged", x);
    },

    showDetails(item: VatDivisionDisplay) {
      item.selected = !item.selected;
    },
  },
});
