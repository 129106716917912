var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'list-group-item': true,
    'status-progress': _vm.item.incomingInvoice.status == 1 || _vm.item.incomingInvoice.status == 2,
    'status-issued': _vm.item.incomingInvoice.status == 3,
    'status-partially': _vm.item.incomingInvoice.status == 4,
    'status-paid': _vm.item.incomingInvoice.status == 5 || _vm.item.incomingInvoice.status == 7,
    'status-overpaid': _vm.item.incomingInvoice.status == 6,
  }},[_c('div',{staticClass:"list-group-item-heading"},[_c('div',{staticStyle:{"padding-top":"5px"}},[_c('h5',[_c('a',{on:{"click":function($event){_vm.item.isItemsVisible = !_vm.item.isItemsVisible}}},[_vm._v(_vm._s(_vm.item.incomingInvoice.invoiceNo))]),(_vm.item.invoiceStatusName)?_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(_vm._s(_vm.item.invoiceStatusName))]):_vm._e()],1)]),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateInvoice(_vm.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}])},[_c('span',[_vm._v("Adatok módosítása")])])],1)]),_c('div',{staticClass:"list-group-item-text"},[_c('v-row',[_c('v-col',{staticClass:"address",attrs:{"sm":"3"}},[_c('a',{on:{"click":function($event){return _vm.partnerEdit(_vm.item)}}},[_c('strong',[_vm._v(_vm._s(_vm.item.partnerName))])]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.item.partnerCity))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.item.partnerAddress))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.item.partnerTaxNo))])]),_c('v-col',{attrs:{"sm":"8"}},[_c('grid',{staticClass:"list-group-table border-top",staticStyle:{"padding-top":"10px","width":"100%"},attrs:{"data-items":[_vm.item],"columns":[
            {
              field: 'invoiceDate',
              title: 'Vámhatározat dátuma',
              className: 'center_text',
              headerClassName: 'center_text',
            },
            {
              field: 'paymentDate',
              title: 'Vámhatározat pénzügyi esedékessége',
              className: 'center_text',
              headerClassName: 'center_text',
            },
            {
              field: 'incomingInvoice.netto',
              title: 'Kivetett vám',
              format: '{0:n}',
              className: 'right_text',
              headerClassName: 'right_text',
            },
            {
              field: 'incomingInvoice.vat',
              title: 'Kivetett vám ÁFA',
              format: '{0:n}',
              className: 'right_text',
              headerClassName: 'right_text',
            },
            {
              field: 'incomingInvoice.total',
              title: 'Vámhatározat összege',
              format: '{0:n}',
              className: 'right_text',
              headerClassName: 'right_text',
            } ],"scrollable":"none"}})],1)],1),_c('v-row',[_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.isItemsVisible),expression:"item.isItemsVisible"}],staticStyle:{"width":"100%"}},[_c('div',{staticClass:"title-bar"},[_c('a',{staticClass:"title a"},[_vm._v("Tételek")])]),_c('div',{staticClass:"pt-4"},[_c('h5',{on:{"click":function($event){_vm.item.isDutyItemsVisible = !_vm.item.isDutyItemsVisible}}},[_vm._v(" Vám tételek "),_c('span',[_c('a',[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.isDutyItemsVisible),expression:"!item.isDutyItemsVisible"}],attrs:{"small":""}},[_vm._v("fa-angle-double-down")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.isDutyItemsVisible),expression:"item.isDutyItemsVisible"}],attrs:{"small":""}},[_vm._v("fa-angle-double-up")])],1)])])]),_c('div',{staticClass:"py-2"},[_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.isDutyItemsVisible),expression:"item.isDutyItemsVisible"}]},[_c('grid',{staticClass:"items-grid list-group-table",attrs:{"data-items":_vm.dutyItems,"columns":[
                    { field: 'incomingInvoiceItem.statCode', title: 'Vámtarifaszám' },
                    {
                      field: 'incomingInvoiceItem.dutyBase',
                      title: 'Kivetett vám alapja',
                      format: '{0:n}',
                      className: 'right_text',
                      headerClassName: 'right_text',
                    },
                    {
                      field: 'incomingInvoiceItem.netto',
                      title: 'Kivetett vám összege',
                      format: '{0:n}',
                      className: 'right_text',
                      headerClassName: 'right_text',
                    } ],"scrollable":"none"}})],1)])],1),_c('div',{staticClass:"pt-4"},[_c('h5',{on:{"click":function($event){_vm.item.isVatItemsVisible = !_vm.item.isVatItemsVisible}}},[_vm._v(" ÁFA tétel "),_c('span',[_c('a',[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.isVatItemsVisible),expression:"!item.isVatItemsVisible"}],attrs:{"small":""}},[_vm._v("fa-angle-double-down")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.isVatItemsVisible),expression:"item.isVatItemsVisible"}],attrs:{"small":""}},[_vm._v("fa-angle-double-up")])],1)])])]),_c('div',{staticClass:"py-2"},[_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.isVatItemsVisible),expression:"item.isVatItemsVisible"}]},[_c('grid',{staticClass:"items-grid list-group-table",attrs:{"data-items":_vm.item.items.filter(function (x) { return x.isTax; }),"columns":[
                    { field: 'taxName', title: 'ÁFA kulcs' },
                    {
                      field: 'incomingInvoiceItem.vatBase',
                      title: 'Kivetett ÁFA alapja',
                      format: '{0:n}',
                      className: 'right_text',
                      headerClassName: 'right_text',
                    },
                    {
                      field: 'incomingInvoiceItem.vat',
                      title: 'Kivetett vám ÁFÁ-ja',
                      format: '{0:n}',
                      className: 'right_text',
                      headerClassName: 'right_text',
                    } ],"scrollable":"none"}})],1)])],1)])])],1)],1),_c('v-chip',{staticStyle:{"color":"white"},attrs:{"x-small":"","label":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.item.typeName)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }