













import PartnerSearch from "@/modules/partner/views/PartnerSearch.vue";
import Partner from "../../partner/models/Partner";
import Vue from "vue";
import store, { DownPaymentStore } from "../services/store";
import { DownPayment, DownPaymentItem } from "../models";
import taxStore, { TaxStore } from "@/modules/taxes/services/TaxStore";

interface Data {
  store: DownPaymentStore;
  taxStore: TaxStore;
}

export default Vue.extend({
  components: {
    PartnerSearch,
  },

  data: (): Data => ({
    store,
    taxStore,
  }),

  async beforeMount() {
    if (taxStore.taxes.length < 1) {
      await Promise.all([this.taxStore.loadTaxes(), this.store.loadUnits()]);
    }
  },

  methods: {
    partnerSelected(partner: Partner) {
      this.store.init();

      this.store.downPayment = new DownPayment({
        ...this.store.downPayment,
        partner: partner.id,
        partnerName: partner.name,
      });

      this.$router.push(`/down-payment/create/${partner.id}`);
    },

    cancel() {
      this.$router.push(`/down-payment`);
    },
  },
});
