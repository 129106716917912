var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:this.$route.params.typeFilter},[_c('InvoiceStockModfierGenisView'),(!_vm.loading)?_c('ApiSearchPage',{ref:"asref",attrs:{"title":"Számla böngésző " + _vm.typeLabel,"url":'invoice-browser/' + this.$route.params.typeFilter,"columns":_vm.columns,"delay":1000,"detailsField":"expanded","sortable":true,"showFilter":true,"apiSearchExtraFilter":_vm.store2.eFilters,"showExtraDateFilter":true,"resetSort":false,"create":_vm.viewButtonText},on:{"efilter-toggle":_vm.handleExtraFilterToggle,"reset-filtering":_vm.handleResetFiltering,"create":function($event){return _vm.viewButtonClick()}},scopedSlots:_vm._u([{key:"extraDateFilter",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"src-btn",staticStyle:{"height":"31px !important","width":"31px !important"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-clock-o")])],1)]}}],null,false,17930812)},[_c('span',[_vm._v("A számlák dátuma későbbi, mint az alábbi dátum")])]),_c('date-input',{staticStyle:{"height":"31px !important"},model:{value:(_vm.store2.eFilters.filters.invDateFrom.value),callback:function ($$v) {_vm.$set(_vm.store2.eFilters.filters.invDateFrom, "value", $$v)},expression:"store2.eFilters.filters.invDateFrom.value"}})]},proxy:true},{key:"filters",fn:function(ref){
var apiSearch = ref.apiSearch;
return [_c('InvoiceBrowserExtraFilter',{staticStyle:{"width":"100%","margin":"20px 0 0 0"},attrs:{"apiSearch":apiSearch}})]}},{key:"invoiceNoTemplate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(item.invoiceNo))])])]}},{key:"invoiceDetailsTemplate",fn:function(ref){
var item = ref.item;
return [(item.prefix === 'GENI')?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{
                selected: item.selected,
              },attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-bars")])],1)]}}],null,true)},[_c('span',[_vm._v("Workflow információk")])])],1):_vm._e()]}},{key:"invoiceInvImageCommandTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{
                selected: item.selected,
              },attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showInvImage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-file-pdf-o")])],1)]}}],null,true)},[_c('span',[_vm._v("Számlakép megnyitása")])])],1)]}},{key:"details",fn:function(ref){
              var item = ref.item;
return [_c('FlowPaymentInfoView',{attrs:{"invoice":item}})]}},{key:"IDTemplate",fn:function(ref){
              var item = ref.item;
return [(item.prefix === 'GENI' || 'NOTO')?_c('div',[_c('td',{staticClass:"geni",class:item.className},[(item.isStockSelected)?_c('div',[_c('tooltip',{attrs:{"tooltip":"Készletkapcsolatos"},scopedSlots:_vm._u([{key:"activator",fn:function(slotProps){return [_c('label',_vm._g({staticStyle:{"cursor":"pointer !important","color":"var(--primaryColor) !important"},on:{"click":function($event){return _vm.editInvoice(item)}}},slotProps.on),[_vm._v(" "+_vm._s(item.id)+" ")]),_c('v-icon',_vm._g({staticStyle:{"vertical-align":"baseline"},attrs:{"color":"black","x-small":""}},slotProps.on),[_vm._v(" fa-archive ")])]}}],null,true)})],1):_c('div',[_c('label',{staticStyle:{"cursor":"pointer !important","color":"var(--primaryColor) !important"},on:{"click":function($event){return _vm.editInvoice(item)}}},[_vm._v(" "+_vm._s(item.id)+" ")])])])]):_c('div',[_c('label',[_vm._v(_vm._s(item.id))])])]}},{key:"prefixTemplate",fn:function(ref){
              var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.prefix))])]}},{key:"dpaymTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',{class:item.className},[_c('div',[(item.dpaymInv && item.dpaymInv.length > 0)?_c('label',{staticStyle:{"cursor":"pointer !important","color":"var(--primaryColor) !important"}},_vm._l((item.dpaymInv),function(inv,i){return _c('div',{key:i},[_c('span',{on:{"click":function($event){return _vm.editDpaymInvoice(inv, item.partner)}}},[_vm._v(_vm._s(inv + (item.dpaymInv.length - 1 > i ? ", " : ""))+" ")])])}),0):_c('label',[_vm._v("-")])])])]}},{key:"preDpaymTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',{class:item.className},[_c('div',[(item.PreDpaymInv && item.preDpaymInv.length > 0)?_c('label',{staticStyle:{"cursor":"pointer !important","color":"var(--primaryColor) !important"}},_vm._l((item.preDpaymInv),function(inv,i){return _c('div',{key:i},[_c('span',{on:{"click":function($event){return _vm.editPreDpaymInvoice(inv)}}},[_vm._v(" "+_vm._s(inv + (item.preDpaymInv.length - 1 > i ? ", " : ""))+" ")])])}),0):_c('label',[_vm._v("-")])])])]}},{key:"invoiceDateTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm.convertToDate(item.invoiceDate)))])])]}},{key:"payTermTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm.convertToDate(item.payTerm)))])])]}},{key:"bruttoTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('money-display',{attrs:{"value":item.currTotal,"currency":'',"textalign":"d-flex justify-center text-right"}})],1)]}},{key:"sumpayTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('money-display',{attrs:{"value":item.currPaymSum,"currency":'',"textalign":"d-flex justify-center text-right"}})],1)]}},{key:"currencyTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(item.invCurrency))])])]}},{key:"shipDateTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(_vm.convertToDate(item.shipDate)))])])]}},{key:"regDateTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(item.regDate !== "1900-01-01T00:00:00" ? _vm.convertToDate(item.regDate) : "-"))])])]}},{key:"flowModDateTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('label',[_vm._v(_vm._s(item.flowModDate ? _vm.convertToDate(item.flowModDate) : "-"))])])]}},{key:"accStatusTemplate",fn:function(ref){
              var item = ref.item;
return [_c('td',{class:item.className},[_c('span',{class:{
            toAccounting: item.accStatusName === 'Fők. rendsz. feladva',
          }},[_vm._v(" "+_vm._s(item.accStatusName)+" ")])])]}},{key:"preStatusNameTemplate",fn:function(ref){
          var item = ref.item;
return [_c('td',{class:item.className},[(item.preStatus <= 2)?_c('span',[_vm._v(" "+_vm._s(item.preStatusName)+" ")]):_c('span',{staticClass:"badge round-sm justify-center px-2",class:{
            orange: item.preStatus === 3,
            'deep-orange': item.preStatus === 4,
            green: item.preStatus === 5 || item.preStatus === 8,
            'red darken-2': item.preStatus === 6 || item.preStatus === 7,
          }},[_vm._v(" "+_vm._s(item.preStatusName)+" ")])])]}},{key:"flowStatusTemplate",fn:function(ref){
          var item = ref.item;
return [(item.flowStatus !== 0)?_c('td',{class:item.className},[_c('span',{staticClass:"badge round-sm justify-center px-2",class:{
            iktatas: item.flowStatus === 2,
            penzugy: item.flowStatus === 3,
            keszlet: item.flowStatus === 4,
            ellenorzes: item.flowStatus === 5,
            konyveles: item.flowStatus === 6,
          }},[_vm._v(" "+_vm._s(item.flowStatusName)+" ")])]):_c('td',[_vm._v(" "+_vm._s(item.flowStatusName)+" ")])]}},{key:"daysSpentTemplate",fn:function(ref){
          var item = ref.item;
return [_c('label',[_vm._v(" "+_vm._s(item.daysSpentInStatus >= 0 ? ((item.daysSpentInStatus) + " nap") : "-"))])]}},{key:"isIndirectNoserv",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('CheckBox',{attrs:{"readonly":true,"selected":item.isIndirectNoserv}})],1)]}},{key:"isIntrastat",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('CheckBox',{attrs:{"readonly":true,"selected":item.isIntrastat}})],1)]}},{key:"intrastatIds",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_vm._v(" "+_vm._s(item.intrastatIds)+" ")])])]}}],null,false,3690978727)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }