import http, { handleApiResponse, checkResponse } from '@/services/http'
import ApiResponse from '@/models/ApiResponse'
import Part from "../models/Part";

export default class PartApi{
    static getAll(): Promise<Part[]> {
        return http
            .get<ApiResponse<Part[]>>(`gparts`)
            .then(handleApiResponse)
    }

    static getAllMin(name: string, invGroupId: number): Promise<Part[]> {
        return http
            .get<ApiResponse<Part[]>>(`gparts/min`, {
                params: {
                    name: name,
                    invGroupId: invGroupId
                }
            })
            .then(handleApiResponse)
    }
}