var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApiSearchPage',_vm._b({attrs:{"url":_vm.url,"columns":_vm.columns,"itemText":"combined","search":false,"refresh":false,"resetSort":false,"resetFilter":false,"detailsField":"selected","title":'Partner egyenlegközlő napló',"name":"partner-balance-email-log"},scopedSlots:_vm._u([{key:"success",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":true},model:{value:(item.success),callback:function ($$v) {_vm.$set(item, "success", $$v)},expression:"item.success"}})]}},{key:"error",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.error}},[_vm._v(_vm._s(item.error))])]}},{key:"statementDetailsButtonTemplate",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"infoButton",class:{
            selected: item.selected,
          },attrs:{"outlined":"","color":"primary"},on:{"click":function($event){item.selected = !item.selected}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-bars")])],1)]}}],null,true)},[_c('span',[_vm._v("További információk")])])]}},{key:"details",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"padding":"20px","padding-top":"0px"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Prefix")]),_c('th',[_vm._v("Számlaszám")]),_c('th',[_vm._v("Számla dátuma")]),_c('th',[_vm._v("Fizetési határidő")]),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v("Nettó")]),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v("Bruttó")]),_c('th',[_vm._v("Pénznem")])])]),_c('tbody',_vm._l((item.items),function(data,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(data.code))]),_c('td',[_vm._v(_vm._s(data.invoice))]),_c('td',[_vm._v(_vm._s(data.invoiceDate))]),_c('td',[_vm._v(_vm._s(data.payDate))]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("moneyFormat")(data.amount,data.currency)))]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm._f("moneyFormat")(data.total,data.currency)))]),_c('td',[_vm._v(_vm._s(data.currency))])])}),0)])],1)]}}])},'ApiSearchPage',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }