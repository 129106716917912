











































































import Vue from "vue";

import IconButton from "../../../components/IconButton.vue";
import CheckBox from "../../../components/CheckBox.vue";
import Fragment from "../../../components/Fragment.vue";
import OtherItemRelations from "./OtherItemRelations.vue";
import store, { InvoiceItemStore } from "../services/store";
import { OtherItem } from "../models";
import configuration from "../../../models/Configuration";
import MoneyInput from "../../../components/MoneyInput.vue";

interface Data {
  store: InvoiceItemStore;
}

interface Props {
  items: OtherItem[];
  showPostCalcWarning: boolean;
}

interface Computed {
  hasData: boolean;
}

interface Methods {
  toggleItem(item: OtherItem): void;
  isValid(item: OtherItem): boolean;
  addOtherItem(item: OtherItem): void;
  getGeniUnitDigits(currency: string): void;
  increasesStockPriceChange(item: OtherItem): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { IconButton, Fragment, OtherItemRelations, MoneyInput, CheckBox },

  data: () => ({
    store,
    configuration,
  }),

  props: {
    items: {
      type: Array as () => OtherItem[],
    },
    showPostCalcWarning : { type: Boolean, default: false },
  },

  mounted() {
    console.log(this.items);
  },

  computed: {
    hasData() {
      return this.items && this.items.length > 0;
    },
  },

  methods: {
    increasesStockPriceChange(item: OtherItem) {
      item.increasesStockPrice = !item.increasesStockPrice;
    },

    getGeniUnitDigits(currency) {
      return configuration.operel
        ? 2
        : configuration.getCurrDigits(currency) !== undefined
        ? configuration.getCurrDigits(currency)?.geniUnitDigits ?? 4
        : 4;
    },

    toggleItem(item: OtherItem) {
      item.stockRelated = !item.stockRelated;
      if (item.stockRelated) {
        this.store.toggleOtherItem(item);
      }
    },

    isValid(item: OtherItem) {
      return item.quantity > 0;
    },

    async addOtherItem(item: OtherItem) {
      await this.store.addOtherItem(item);
      await this.store.save();
    },
  },
});
