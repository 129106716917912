























































































































































































































import { ConfirmationMixin } from "@/mixins/confirmation";
import YellowConfirmation from "@/components/YellowConfirmation.vue";
import IconButton from "@/components/IconButton.vue";
import Fragment from "../../../components/Fragment.vue";
import Tabs from "@/components/Tabs.vue";
import FixAssetInvItemRelationsTab from "./FixAssetInvItemRelationsTab.vue";

import eventBus from "@/services/eventBus";
import store, { FixAssetGroupStore } from "@/modules/fixAssetGroup/services/FixAssetGroupStore";
import { toBase64 } from "@/utils/fileFunctions";
import flowStore, { InvoiceFlowStore } from "@/modules/invoiceFlow/services/InvoiceFlowStore";
import itemStore, { InvoiceItemStore } from "../services/store";
import User from "@/modules/partner/models/User";
import InvoiceFlowApi from "@/modules/invoiceFlow/services/InvoiceFlowApi";
import { InvoiceItem, InvoiceRelationType, Stock } from "../models";
import { FixAssetData, FixAssetDocumentData, FixAssetType } from "@/modules/invoiceFlow/models/FixAsset";
import FixAssetMainGroup from "@/modules/fixAssetGroup/models/FixAssetMainGroup";
import FixAssetGroup from "@/modules/fixAssetGroup/models/FixAssetGroup";
import http from "@/services/http";
import FixAssetGroupApi from "@/modules/fixAssetGroup/services/FixAssetGroupApi";

interface Data {
  store: FixAssetGroupStore;
  itemStore: InvoiceItemStore;
  flowStore: InvoiceFlowStore;
  stockID: number;
  assetType: FixAssetType | undefined;
  responsibleUser: User | undefined;
  stockResponsibleUser: User | undefined;
  stockMainGroup: FixAssetMainGroup | undefined;
  stockGroup: FixAssetGroup | undefined;
  savingVisible: boolean;
  mainGroups: FixAssetMainGroup[];
  subGroups: FixAssetGroup[];
}

interface Methods {
  load(): void;
  initialize(fixAsset: FixAssetData): void;
  getStockRelation(): Stock | null;
  save(): void;
  create(): void;
  deleteFixAsset(): void;
  addDocument(): void;
  openDocument(doc: FixAssetDocumentData): void;
  removeDocument(doc: FixAssetDocumentData): void;
  convertFileToBase64(doc: FixAssetDocumentData): void;
  onStockMainGroupChange(f: number): void;
  onStockGroupChange(f: number): void;
}

interface Props {
  item: InvoiceItem;
}

function findUser(id: number) {
  return flowStore.users.find((u) => u.id == id);
}

function findMainGroup(id: number) {
  return store.fixAssetMainGroups.find((g) => g.id == id);
}

function findGroup(id: number) {
  return store.fixAssetGroups.find((g) => g.id == id);
}

export default ConfirmationMixin.extend<Data, Methods, {}, Props>({
  components: {
    IconButton,
    Fragment,
    YellowConfirmation,
    Tabs,
    FixAssetInvItemRelationsTab,
  },

  props: {
    item: Object as () => InvoiceItem,
  },

  mixins: [ConfirmationMixin],

  data: () => ({
    store,
    itemStore,
    flowStore,
    stockID: 0,
    assetType: new FixAssetType(),
    responsibleUser: undefined,
    stockResponsibleUser: undefined,
    stockMainGroup: undefined,
    stockGroup: undefined,
    savingVisible: true,
    mainGroups: [],
    subGroups: []
  }),

  async beforeMount() {
    const relation = this.getStockRelation();
    this.stockID = relation && relation.stockID > 1 ? relation.stockID : 0;

    if (this.flowStore.fixAssetTypes.length == 0) await this.flowStore.loadFixAssetTypes();

    await this.store.loadFixAssetMainGroups();
    this.mainGroups = this.store.fixAssetMainGroups;
    await this.store.loadFixAssetGroupsByMainGroup(this.item.fixAsset?.mainGroup ?? 0);
    this.subGroups = this.store.fixAssetGroups;
  },

  created() {
    eventBus.$on("invoice-item:save", this.load);
  },

  beforeDestroy() {
    eventBus.$off("invoice-item:save", this.load);
  },

  methods: {
    async load() {
      if (!this.item.fixAsset) return;
      const fixAsset = await InvoiceFlowApi.getFixAssetByStock(this.stockID);
      /* this.initialize(fixAsset); */
    },

    initialize(fixAsset) {
      this.item.fixAsset = FixAssetData.CreateByStockID(this.stockID);
      this.item.fixAsset.type = this.flowStore.fixAssetTypes.find((t: FixAssetType) => t.id == (this.item.fixAsset?.type ?? 1))?.id??1;
      this.item.fixAsset.responsibleUser = findUser(this.item.fixAsset?.responsibleUser ?? 1)?.id??1;
      this.item.fixAsset.stockResponsibleUser = findUser(this.item.fixAsset?.stockResponsibleUser ?? 1)?.id??1;
      this.item.fixAsset.mainGroup = findMainGroup(this.item.fixAsset?.mainGroup ?? 1)?.id??1;
      this.item.fixAsset.group = findGroup(this.item.fixAsset?.group ?? 1)?.id??1;
    },

    getStockRelation() {
      const relation = this.item.relations.find((r) => r.type === InvoiceRelationType.Stock);
      return relation ? (relation.relatedItem as Stock) : null;
    },

    async save() {
      console.log(this.item.fixAsset)
      this.$props.item.fixAsset.originalStock = this.stockID;
      if (!this.item.fixAsset) return;

      const converts = this.item.fixAsset.documents.filter((d) => !!d.file).map((d) => this.convertFileToBase64(d));
      await Promise.all(converts);

      await this.itemStore.save().then(() => eventBus.$emit("success", "Sikeres tárgyi eszköz adat frissítés!"));
    },

    create() {
      this.initialize(new FixAssetData());
    },

    async deleteFixAsset() {
      if (!this.item.fixAsset) return;

      if (this.item.fixAsset.id == 0) {
        this.item.fixAsset = null;
        return;
      }

      const confirmed = await this.confirm("Biztosan törli a tételhez tartozó tárgyi eszközt?");
      if (!confirmed) {
        return;
      }

      await InvoiceFlowApi.deleteFixAsset(this.item.fixAsset.id).then(() => {
        this.item.fixAsset = null;
        eventBus.$emit("success", "Sikeres tárgyi eszköz törlés!");
      });
    },

    async onStockMainGroupChange(mainGroup) {
      if (!this.item.fixAsset) return;
      this.subGroups = await FixAssetGroupApi.getAllByMainGroup(mainGroup)
      console.log(this.subGroups)
    },

    async onStockGroupChange(group) {
      await this.store.createDefaultDocuments(group, this?.item?.fixAsset?.id ?? 1);
      if (!this.item.fixAsset) return;
      this.item.fixAsset.group = group;
    },

    addDocument() {
      if (!this.item.fixAsset) return;
      this.item.fixAsset.documents.push(new FixAssetDocumentData());
    },

    openDocument(doc) {
      if (!doc.hasDocument) return;
      window.open(doc.link + `?connectionKey=${http.defaults.headers["X-TruFinance-ConnectionID"]}`);
    },

    removeDocument(doc) {
      if (!this.item.fixAsset) return;
      this.item.fixAsset.documents = this.item.fixAsset.documents.filter((d) => d != doc);
    },

    async convertFileToBase64(doc: FixAssetDocumentData) {
      if (!doc || !doc?.file) return;
      doc.document = await toBase64(doc.file);
      const nameSplit = doc.file.name.split(".");
      doc.extension = nameSplit[nameSplit.length - 1].toUpperCase() ?? null;
    },
  },

  computed: {
    hasData(): boolean {
      return this.item.fixAsset != null;
    },

    hasDocument(): boolean {
      return !!this.item.fixAsset?.documents && this.item.fixAsset?.documents?.length > 0;
    },

    disabled(): boolean {
      return false; // this.item.hasFixAssetRelation;
    },
  },
});
