


























































































































































































































































import Vue from "vue";
import store, { BankStatementStore } from "../services/BankStatementStore";
import { bankStatementDefaultProps } from "../models/BankStatement";
import bStore, { BankStore } from "../../bank/services/BankStore";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import MoneyInput from "@/components/MoneyInput.vue";
import BankStatementPicturePage from "./BankStatementPicture.vue";
import dayjs from "dayjs";
import configuration, { AppConfiguration } from "@/models/Configuration";
import { ValidationFunc } from "@/models/Validation";
import DateInput from "@/components/DateInput.vue";
import BankStatementApi from "../services/BankStatementApi";
import ConfirmationBox from "@/components/ConfirmationBox.vue";

interface Data {
  store: BankStatementStore;
  bStore: BankStore;

  configuration: AppConfiguration;
  dateRule: Array<ValidationFunc>;
  valid: boolean;
  confirmation: boolean;
}

interface Methods {
  save(): void;
  cancel(): void;
  bankCodeChanged(): void;
  onInput(value: Date): void;
  loadCloseRate(): void;
  loadLastBalance(): void;
  close(): void;
  closeInner(updateBalance: boolean): void;
}

interface Computed {
  statusName: string;
  accountingStatusName: string;
  bankName: string;
  isDisabled: boolean;
  balanceAll: number;
  defaultCurrency: string;
  edateStatement: Date;
}

export default Vue.extend<Data, Methods, Computed, {}>({
  async beforeMount() {
    store.loadBankStatuses();
    store.loadBankAccountStatuses();

    if (this.store.bankStatement.id > 1){
      await this.store.loadBankDocumentsByStatementId(
        this.store.bankStatement.id
      );

      if (this.store.bankStatement.openRate == 0){
        this.loadCloseRate();
      }

      console.log(this.store.bankDocuments);
    }
  },

  components: {
    NumericTextBox,
    MoneyInput,
    BankStatementPicturePage,
    DateInput,
    ConfirmationBox
  },

  data() {
    return {
      edate: new Date(),
      store,
      bStore,
      configuration,
      dateRule: [
        (v: Date | string) => !!v || "Kötelező kitölteni!",
        (v: Date | string) =>
          (v && dayjs(v).toDate() >= dayjs("2016-06-15").toDate()) ||
          "Nem lehet régebbi a dátum, mint 2016-06-15",
        (v: Date | string) =>
          (v && dayjs(v).toDate().getDate() >= dayjs().toDate().getDate()) ||
          "Nem lehet a mai napnál későbbi dátum!",
      ],
      valid: false,
      confirmation: false,
    };
  },

  computed: {
    defaultCurrency(){
      return (configuration.sysParams.defaultCurrency as any).value;
    },

    balanceAll(){
      if (this.store.bankStatement.currency != this.defaultCurrency){
        return this.store.bankDocuments.filter(x => x.status.toLocaleLowerCase() != "rontott").map(x => x.bankStatementItem.currAmount * (x.bankStatementItem.crediting ? 1 : -1)).reduce((sum, x) => sum += x, 0) + this.store.bankStatement.openBalance;
      }

      return this.store.bankDocuments.filter(x => x.status.toLocaleLowerCase() != "rontott").map(x => x.bankStatementItem.amount * (x.bankStatementItem.crediting ? 1 : -1)).reduce((sum, x) => sum += x, 0) + this.store.bankStatement.openBalance;
    },

    isDisabled(){
      return this.store.bankStatement.status == 3 || this.store.bankStatement.isReopen;
    },

    statusName() {
      return this.store.bankStatuses.find(x => x.id == this.store.bankStatement.status)?.name ?? "";
    },
    accountingStatusName() {
      return this.store.bankAccountStatuses.find(x => x.id == this.store.bankStatement.accountStatus)?.name ?? "";
    },
    bankName() {
     return this.store.partBanks.find(
          (x) => x.id == this.store.bankStatement.bank
        )?.bank ?? "";
    },
    edateStatement(){
      return this.store.bankStatement.edate;
    }
  },

  methods: {
    async closeInner(updateBalance){
      this.confirmation = false;
      await this.store.closeBankStatement(updateBalance);
    },

    async close(){
      if (this.balanceAll != this.store.bankStatement.closeBalance){
        this.confirmation = true;
      } else {
        this.closeInner(false);
      }
    },

    async loadCloseRate(){
      if (this.store.bankStatement.bank > 1 && this.store.bankStatement.currency != 'HUF') {
        this.store.bankStatement.openRate = await BankStatementApi.getLastStatementRate(this.store.bankStatement.bank, this.store.bankStatement.id, this.store.bankStatement.edate);
      } else {
        this.store.bankStatement.openRate = 1;
      }
    },

    async loadLastBalance(){
      if (this.store.bankStatement.bank > 1){
        this.store.bankStatement.openBalance = await BankStatementApi.getLastBalance(this.store.bankStatement.bank, this.store.bankStatement.id, this.store.bankStatement.edate);
      }
    },

    onInput(value: Date) {
      if (value) {
        this.store.bankStatement.edate = value;
      } else {
        this.store.bankStatement.edate = new Date;
      }
    },

    cancel() {
      this.store.bankStatement = { ...bankStatementDefaultProps };
      this.$router.push(`/bank-statement`);
    },

    async save() {
      const id = Number(await this.store.save());
      if (id > 1) {
        this.$router.push(`/bank-statement/create/${id}/head`); //todo
      }
    },

    bankCodeChanged() {
      this.store.bankStatement.currency =
        this.store.partBanks.find(
          (x) => x.id == this.store.bankStatement.bank
        )?.currency ?? "-";

      this.store.bankStatement.bank =
        this.store.partBanks.find(
          (x) => x.id == this.store.bankStatement.bank
        )?.id ?? 1;

      this.loadCloseRate();
      this.loadLastBalance();
    },
  },

  watch:{
    edateStatement: function(){
      this.loadCloseRate();
      this.loadLastBalance();
    }
  },
});
