

























































































































































































































































































































































































































































































import Vue from "vue";
import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import dayjs from "dayjs";
import Glim, { DefaultPropsGlim } from "../models/GeneralLedgerInvoiceMirror";
import store, { GeneralLedgerStore } from "../services/store";

interface Data {
  store: GeneralLedgerStore;
  bookRadio: string;
  tempCreateDate: string;
  item: Glim;
}

export default Vue.extend({
  props: ["itemId"],

  data(): Data {
    return {
      store: store,
      bookRadio: "isFromDms",
      tempCreateDate: "",
      item: DefaultPropsGlim,
    };
  },

  methods: {
    doneEdit() {
      this.$emit("doneEdit");
    },
    saveEdit() {
      this.store.saveGlimEdits(this.item);
      this.$emit("saveEdit");
    },

    toDate(item: string) {
      return dayjs(item)
        .format("YYYY-MM-DD")
        .replaceAll("-", ".");
    },

    toTime(item: string) {
      return dayjs(item)
        .format("HH:mm")
        .replaceAll("-", ".");
    },

    async createInvMirror() {
      await this.store.CreateInvMirror(this.item);
      this.item = { ...DefaultPropsGlim };
      this.$emit("saveEdit");
    },
  },

  watch: {
    bookRadio() {
      if (this.bookRadio === "isFromDms") {
        this.item.isFromDms = true;
        this.item.isManualAcc = false;
      } else {
        this.item.isManualAcc = true;
        this.item.isFromDms = false;
      }
    },
  },

  async beforeUpdate() {
    this.store.setStatuses();
    this.store.setDimActivities();
  },
  async beforeMount() {
    if (this.itemId > 1) {
      this.tempCreateDate = dayjs(this.item.insDate)
        .format("YYYY-MM-DD")
        .replaceAll("-", ".");
      this.item = await http
        .get<ApiResponse<Glim>>(`generalledger/invoicemirror/${this.itemId}`)
        .then(handleApiResponse);
    } else {
      this.item = { ...DefaultPropsGlim };
    }
  },
});
