import GeneralIngoingInvoice, {
  generalIngoingInvoiceDefaultProps,
} from "@/modules/generalIngoingInvoice/models/GeneralIngoinInvoice";

export default interface PaymentInfoDisplay {
  id: number;
  invoice: GeneralIngoingInvoice;
  flowUserName: string;
  flowStatus: number;
  flowStatusName: string;
  insDate: string | null;
  insTime: string | null;
  modDate: string | null;
  modTime: string | null;
  // Számla státusza
  status: string;
  // Számla típusa
  type: string;
  // Számla altípusa
  subType: string;
  // Számla dátuma
  invoiceDate: string | null;
  // Telj. dátuma (áfa)
  shipDate: string | null;
  // Fizetési határidő
  payTerm: string | null;
  // Iktatás dátuma
  regDate: string | null;
  // Érkezés dátuma
  arrivDate: string | null;
  // Foly. teljesítésű
  shipDateC: boolean;
  // Telj. dátuma (-tól, számv.)
  accDateStart: string | null;
  // Telj. dátuma (-ig, számv.)
  accDate: string | null;
  // Fizetve
  isPayed: boolean;
  // Fizetés dátuma
  payDate: string | null;
  // Fizetési mód
  paymode: string;
  // Bankszámla
  bankAccount: string;
  // Pénznem
  invCurrency: string;
  exchangeRate: number;
  // Nettó
  currNetto: number;
  // kiválasztott ÁFA %
  tax: number;
  // ÁFA érték + devizában
  currVat: number;
  invVat: number;
  // Bruttó
  currTotal: number;
  // contrPers: string;
  // acceptor: string;
  // partnerTax: string;
  // specTaxType: string;
  //flow státuszhoz tartozó megjegyzés
  statusRemark: string;
}

export const paymentInfoDisplayDefaultProps: PaymentInfoDisplay = {
  id: 0,
  flowUserName: "",
  flowStatus: 0,
  flowStatusName: "",
  insDate: null,
  insTime: null,
  modDate: null,
  modTime: null,
  invoice: { ...generalIngoingInvoiceDefaultProps },
  status: "",
  type: "",
  subType: "",
  invoiceDate: null,
  shipDate: null,
  payTerm: null,
  regDate: null,
  arrivDate: null,
  shipDateC: false,
  accDateStart: null,
  accDate: null,
  isPayed: false,
  payDate: null,
  paymode: "",
  bankAccount: "",
  invCurrency: "",
  exchangeRate: 0,
  currNetto: 0,
  tax: 0,
  currVat: 0,
  invVat: 0,
  currTotal: 0,
  statusRemark: "",
};
