var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" Könyvelési főkönyvi számok ")])],1),_c('v-card-text',[_c('grid',{attrs:{"data-items":_vm.result,"columns":_vm.columns,"pageable":_vm.pageable,"skip":_vm.skip,"take":_vm.take,"total":_vm.total,"filterable":true,"filter":_vm.filter,"sortable":true,"sort":_vm.sort,"edit-field":'inEdit',"scrollable":"none"},on:{"pagechange":_vm.pageChangeHandler,"filterchange":_vm.filterChange,"sortchange":_vm.sortChange,"itemchange":_vm.itemChange},scopedSlots:_vm._u([{key:"valueNatTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('label',[_vm._v(_vm._s(props.dataItem.valueNat))])])]}},{key:"displayTableNameTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('label',[_vm._v(_vm._s(props.dataItem.displayTableName))])])]}},{key:"largeTextTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[_c('label',[_vm._v(_vm._s(props.dataItem.largeText))])])]}},{key:"commandTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',[(!props.dataItem.inEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.editItem(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok módosítása")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.cancelEdit(props.dataItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Adatok mentése")])])],1)]}}])})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }