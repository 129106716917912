import ApiResponse from "@/models/ApiResponse";
import http, { checkResponse, handleApiResponse } from "@/services/http";
import { withBusyIndication } from "@/utils/busyIndication";
import CarItem from "../models/CarItem";
import CarItemRelation from "../models/CarItemRelation";

export const searchNewCars = withBusyIndication(
  (searchText: string, partnerId: number): Promise<CarItem[]> =>
    http
      .get<ApiResponse<CarItem[]>>(
        `car/search/new`,
        {
          params: {
            q: searchText,
            partnerId: partnerId
          },
        }
      )
      .then(handleApiResponse)
);

export const searchUsedCars = withBusyIndication(
  (searchText: string): Promise<CarItem[]> =>
    http
      .get<ApiResponse<CarItem[]>>(
        `car/search/used`,
        {
          params: {
            q: searchText,
          },
        }
      )
      .then(handleApiResponse)
);

export const getCurrentCarItemRelations = withBusyIndication(
  (invItemId: number): Promise<CarItemRelation[]> =>
    http
      .get<ApiResponse<CarItemRelation[]>>(
        `car/car-item-relations/${invItemId}`
      )
      .then(handleApiResponse)
);

export const getCurrentCarRectItemRelation = withBusyIndication(
  (invItemId: number): Promise<any> =>
    http
      .get<ApiResponse<any>>(
        `car/car-rect-item-relation/${invItemId}`
      )
      .then(handleApiResponse)
);

export const searchCarRectItemRelations = withBusyIndication(
  (searchText: string, partner: number): Promise<CarItem[]> =>
    http
      .get<ApiResponse<CarItem[]>>(
        `car/car-rect-item-relations/${partner}`, { params: { searchText: searchText } }
      )
      .then(handleApiResponse)
);

export const searchCarItemRelations = withBusyIndication(
  (searchText: string, partner: number): Promise<CarItem[]> =>
    http
      .get<ApiResponse<CarItem[]>>(
        `car/new-car-item-relations/${partner}`, { params: { searchText: searchText } }
      )
      .then(handleApiResponse)
);

export const deleteCarItemRelation = (
  relationId: number,
): Promise<boolean> =>
  http
    .post(
      `car/car-item-relations/delete/${relationId}`
    )
    .then(checkResponse);

export const deleteCarRectItemRelation = (
  invItemId: number,
): Promise<boolean> =>
  http
    .put(
      `car/car-rect-item-relation/delete/${invItemId}`
    )
    .then(checkResponse);

export const updateCarItemRelation = (
  relation: CarItemRelation,
): Promise<boolean> =>
  http
    .put(
      `car/car-item-relations/update/${relation.id}`, relation
    )
    .then(checkResponse);

export const addCarItemRelation = (
  selectedItems: CarItem[], invoiceId: number, invoiceItemId: number
): Promise<boolean> =>
  http
    .post(
      `car/car-item-relations/add/${invoiceId}/${invoiceItemId}`, selectedItems
    )
    .then(checkResponse);

export const addCarRectItemRelation = (
  selectedItems: any, invoiceId: number, invoiceItemId: number
): Promise<boolean> =>
  http
    .post(
      `car/car-rect-item-relations/add/${invoiceId}/${invoiceItemId}`, selectedItems
    )
    .then(checkResponse);

export const searchPostCalcsByCar = withBusyIndication(
  (carId: number): Promise<any> =>
    http
      .get<ApiResponse<any>>(
        `car/car-post-calcs/${carId}`
      )
      .then(handleApiResponse)
);