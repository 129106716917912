
















































































































import Vue from 'vue';
import auth from "@/services/auth";
import { ColumnType } from '@/components/apiSearch/models/ApiSearch';
import { FilteringModel } from '@/services/filtering';
import ApiSearchPage from '@/components/apiSearch/components/ApiSearchPage.vue';
import IconButton from "@/components/IconButton.vue";
import KanriInvoiceItemsView from "./KanriInvoiceItemsView.vue";
import MoneyDisplay from "@/components/MoneyDisplay.vue";
import store, { KanriInvoiceStore } from "../services/KanriInvoiceStore";
import { ValidationRules } from '@/models/Validation';
import { rules } from "../models/GenoExperimentalInvoice";
import DateInput from "../../../components/DateInput.vue";
import ApiResponse from '@/models/ApiResponse';
import http, { handleApiResponse } from '@/services/http';
import base64toUInt8Array from '@/services/fileConvert';
import PdfViewer from "@/components/PdfViewer.vue";
import eventBus from '@/services/eventBus';
import configuration from '@/models/Configuration';

interface Data {
  url: string;
  filter: FilteringModel;
  store: KanriInvoiceStore;

  isEinvoice: boolean;
  shipDate: string;
  remark: string;
  rules: ValidationRules;
  importInProgress: boolean;
  finalReportSrc: Uint8Array | null;
  columns: any[];
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    KanriInvoiceItemsView,
    ApiSearchPage,
    IconButton,
    MoneyDisplay,
    DateInput,
    PdfViewer
  },

  data() {
    return {
      url: `kanri/invoices/${this.$route.name == "KanriInvoicesSale" ? "sale" : "purchase"}`,
      filter: new FilteringModel(),
      store,

      isEinvoice: false,
      shipDate: "",
      remark: "",
      rules,
      importInProgress: false,
      finalReportSrc: null,
      columns: [
        {
          field: "objectId",
          title: "Kanri ID",
        },
        {
          field: "invoiceNo",
          title: "Számlaszám",
        },
        {
          field: "partnerName",
          title: "Partner",
        },
        {
          field: "typeName",
          title: "Számla típusa",
        },
        {
          field: "approvalDate",
          title: "Jóváhagyás dátuma",
          type: ColumnType.Date,
        },
        {
          field: "status",
          title: "Státusz",
        },
        {
          field: "netto",
          title: "Nettó",
          cell: "nettoTemplate",
          type: ColumnType.Right,
          filterable: false
        },
        {
          field: "vat",
          title: "ÁFA",
          cell: "vatTemplate",
          type: ColumnType.Right,
          filterable: false
        },
        {
          field: "total",
          title: "Bruttó",
          cell: "totalTemplate",
          type: ColumnType.Right,
          filterable: false
        },
        {
          field: "procStatus",
          title: "Feldolgozás státusz",
        },
        //{
        //  title: "Beemelt számla",
        //  cell: "importedTemplate",
        //  filterable: false
        //},
        {
          filterable: false,
          cell: "importCell"
        },
      ]
    }
  },

  beforeMount() {
    const id = Number(this.$route.params.id);
    if (id > 1) {
      this.url += `/${id}`
    }
  },

  mounted() {
    if (this.$route.name == "KanriInvoicesPurchase") {
      this.columns = this.columns.filter((x) => x.field != "typeName")
    }
  },

  methods: {
    checkPermission(name: string): boolean {
      const menuPerm = auth.menuPermissions.find(x => x.name == name);
      return menuPerm?.isGranted ?? true;
    },

    async showDetails(item: any) {
      this.isEinvoice = false;
      this.shipDate = new Date().toDateString();
      this.remark = "";
      //this.finalReportSrc = null;
      item.selected = !item.selected;

      if (item.genoId > 1) {
        eventBus.$emit("kanriInvoices:reload");
      }
    },

    async importKanri(head: any){
      this.importInProgress = true;

      if (this.$route.name == "KanriInvoicesPurchase") {
        const success = (await this.store.refreshInvoice(Number(head.objectId), "PURCHASE"));
        if (!success) {
          return;
        }
      }
      else if (this.$route.name == "KanriInvoicesSale") {
        const success = (await this.store.refreshInvoice(Number(head.objectId), "SALE"));
        if (!success) {
          return;
        }
      }

      //await this.store.downSyncPartner(Number(head.objectId));
      const payload = {
        isEinvoice: this.isEinvoice,
        shipDate: this.shipDate,
        remark: this.remark,
      };
      
      const ret = (await this.store.importKanri(Number(head.objectId), payload));
      
      if (ret && (ret as any).id > 1 && ret.errorList.length == 0) {
        eventBus.$emit("success", "Sikeres számla beemelés!");
        if ((ret as any).isFinalGeno) {
          //eventBus.$emit("success", "Sikeres végszámla generálás!");
          const pict = await http.get<ApiResponse<string>>(`experimental-invoices/report/final-invoice/geno/${(ret as any).proform}/GENO/${true}`).then(handleApiResponse);
          this.finalReportSrc = base64toUInt8Array(pict);
          // head.preGenId = (ret as any).id;
          // head.genoId = (ret as any).proform;
        }
        if ((ret as any).retType == "geno") {
          this.$router.push(`/geno-experimental-invoices/${(ret as any).id}`);
        }
        else if((ret as any).retType == "noto") {
          this.$router.push(`/noto/${(ret as any).id}`);
        }
        else if((ret as any).retType == "geni") {
          if (configuration.invoiceFlow.enabled) {
            this.$router.push(`/general-incoming-invoice/flow/edit/${(ret as any).id}/3`);
          }
          else {
            this.$router.push(`/general-incoming-invoice/edit/${(ret as any).partnerId}/${(ret as any).id}`);
          }
        }
      }
      else {
        eventBus.$error(ret.errorList.join("\n"));
      }
      this.importInProgress = false;
    }
  },

  computed: {
    valid(): boolean {
      if (!this.shipDate) {
        return true;
      }
      return false;
    }
  },
})
