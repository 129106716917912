























// Components
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import IncomingInvoiceSubtype from "@/modules/generalIngoingInvoice/models/IncomingInvoiceSubtype";
import eventBus from "@/services/eventBus";

// Models
import Vue from "vue";
import UninvoicedStockCorrectionApi from "../services/api";
import store, { UninvoicedStockCorrectionStore } from "../services/store";
import MainCode from "../models/MainCode";
import { debounce } from "debounce";

interface Data {
  store: UninvoicedStockCorrectionStore;
}

interface Methods {
  generate(): void;
}

interface Props {
  invoiceID: number;
  partnerID: number;
  subtype: IncomingInvoiceSubtype;
  invoiceNo: string;
}

export default Vue.extend<Data, Methods, {}, Props>({
  props: {
    invoiceID: { type: Number },
    partnerID: { type: Number },
    subtype: { type: Object as () => IncomingInvoiceSubtype },
    invoiceNo: { type: String },
  },

  components: { IconButton, Tooltip },

  created() {
    this.generate = debounce(this.generate, 150);
    eventBus.$on("partner-changed", this.generate);
  },

  beforeDestroy() {
    eventBus.$off("partner-changed", this.generate);
  },

  data: () => ({
    store,
  }),

  methods: {
    async generate() {
      await UninvoicedStockCorrectionApi.generateMainCode(this.invoiceID, this.partnerID, this.subtype.id).then(
        (result) => {
          this.store.mainCode = new MainCode(result);
        }
      );
      this.$emit("generated", this.store.mainCode.generated);
    },
  },

  computed: {
    warningMessage() {
      if (!this.store.mainCode.regex?.test(this.invoiceNo)) {
        return (
          "A Bizonlyat szám eltér a kiválasztott indoklás Formairat azonosító mintátjától: " +
          this.store.mainCode.generated
        );
      }

      return "";
    },
  },
});
