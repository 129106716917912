
























































































































































































































































































































































































































































































































































































import Vue from "vue";
import Page from "../../../components/Page.vue";
import store, { IncomingInvoiceTypeStore } from "../services/IncomingInvoiceTypeStore";
import InvoiceGroupType from "@/modules/invoiceGroup/models/InvoiceGroupType";
import AccountNoDetailedType from "@/modules/cashPoint/models/AccountNoDetailedType";
import eventBus from "@/services/eventBus";

interface Data {
  store: IncomingInvoiceTypeStore;
  selectedInvoiceGroup: InvoiceGroupType | undefined;
  selectedDetailedType: AccountNoDetailedType | undefined;
  isReadOnly: boolean;
  isOutlined: boolean;
  showSubtypesTableValue: boolean;
  showPartnerRelationTableValue: boolean;
  isDefault: boolean;
}

interface Methods {
  cancel(): void;
  save(store: IncomingInvoiceTypeStore): void;
  selectedInvoiceGroupChanged(): void;
  selectedDetailedTypeChanged(): void;
  showSubtypesTable(): void;
  showPartnerRelationTable(): void;
  checkSubtypeDefaultChange(itemIndex: number, itemValue: boolean): void;
  checkPartnerRelationDefaultChange(itemIndex: number, itemValue: boolean): void;
  checkDefaultChange(itemIndex: number, itemValue: boolean, storeItem: any, checkIfDefaultExists?: boolean): void;
  isAliveToDeleted(item: any): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    Page,
  },

  data(): Data {
    return {
      store,
      selectedInvoiceGroup: undefined,
      selectedDetailedType: undefined,
      isDefault: false,
      isReadOnly: true,
      isOutlined: true,
      showSubtypesTableValue: true,
      showPartnerRelationTableValue: false,
    };
  },

  async beforeMount() {
    const typeId = Number(this.$route.params.typeId);
    await Promise.all([
      this.store.loadInvoiceGroups(),
      this.store.loadAccountNoDetailedTypes(),
      this.store.loadInvoiceType(typeId),
      this.store.loadInvoiceSubtypesByType(typeId),
      this.store.loadPartnerRelationsByInvoiceType(typeId),
    ]);

    this.isReadOnly = this.store.invoiceType?.readOnly ?? false;

    this.selectedInvoiceGroup = this.store.invoiceGroups.find((x) => {
      return x.id === this.store.invoiceType.defAccgroup;
    });

    this.selectedDetailedType = this.store.accountDetailedTypes.find((x) => {
      return x.id === this.store.invoiceType.detailedType;
    });

    this.isDefault = this.store.invoiceType.isDefault;
  },

  methods: {
    isAliveToDeleted(item: any) {
      item.deleted = !item.isAlive;
    },

    cancel() {
      this.$router.push(`/incoming-invoice-type`);
    },

    async save(store: IncomingInvoiceTypeStore) {
      const invType = store.invoiceType;
      const partRelations = store.partnerRelationsByType;
      const subtypes = store.subtypesByType;
      await this.store.save(invType, partRelations, subtypes);
      this.$router.push(`/incoming-invoice-type`);
    },

    // Költséghely
    selectedInvoiceGroupChanged() {
      this.store.invoiceType.defAccgroup = this.selectedInvoiceGroup?.id ?? 0;
    },

    // Főkönyvi tétel típusok
    selectedDetailedTypeChanged() {
      this.store.invoiceType.detailedType = this.selectedDetailedType?.id ?? 0;
    },

    showPartnerRelationTable() {
      this.isOutlined = false;
      this.showSubtypesTableValue = false;
      this.showPartnerRelationTableValue = true;
    },

    showSubtypesTable() {
      this.isOutlined = true;
      this.showSubtypesTableValue = true;
      this.showPartnerRelationTableValue = false;
    },

    checkSubtypeDefaultChange(itemIndex: number, itemValue: boolean) {
      this.checkDefaultChange(itemIndex, itemValue, this.store.subtypesByType);
    },

    checkPartnerRelationDefaultChange(itemIndex: number, itemValue: boolean) {
      this.checkDefaultChange(itemIndex, itemValue, this.store.partnerRelationsByType, true);
    },

    checkDefaultChange(itemIndex: number, itemValue: boolean, storeItem: any, checkIfDefaultExists?: boolean) {
      const index = itemIndex - 1;

      if (!itemValue && checkIfDefaultExists) {
        eventBus.$emit("error", "Egy alapértelmezettnek lennie kell.");
        this.$nextTick(() => {
          storeItem[index].isDefault = true;
        });
        return;
      } else if (storeItem.length == 0) {
        return;
      } else if (itemValue) {
        storeItem[index].isSelected = true;

        storeItem.forEach((item: any) => {
          if (storeItem[index] != item) {
            item.isDefault = false;
          }
        });
      }
    },
  },

  computed: {
    subtypeColumns: () => [
      { field: "name", title: "Altípus" },
      {
        width: 100,
        field: "isDefault",
        title: "Alapértelmezett",
        cell: "subtype_isDefaultTemplate",
      },
      {
        width: 75,
        field: "isAlive",
        title: "Élő",
        cell: "subtype_isAliveTemplate",
      },
      {
        field: "owingVatAccCode",
        title: "Fiz. áfa fők. sz",
        cell: "subtype_owingVatAccCodeTemplate",
      },
      {
        field: "technAccVatCode",
        title: "Tecnh. áfa fők. sz",
        cell: "technVatAccCodeTemplate",
      },
      {
        field: "vatAccCode",
        title: "Áfa fők. szám",
        cell: "vatAccCodeTemplate",
      },
      {
        field: "expAccCode",
        title: "Ktg. fők. szám",
        cell: "expAccCodeTemplate",
      },
      {
        field: "postCalc",
        title: "Utókal. szerepel",
        cell: "subtype_postCalcTemplate",
      },
      {
        field: "isMustAccGroup",
        title: "Köt. költséghely",
        cell: "subtype_isMustAccGroupTemplate",
      },
      {
        field: "isInvPermit",
        title: "Utalványozható?",
        cell: "subtype_isInvPermitTemplate",
      },
      {
        field: "isWorksheet",
        title: "Munkalap kapcs?",
        cell: "subtype_isWorksheetTemplate",
      },
      {
        field: "isStock",
        title: "Gj. készl. ért.-be sz.",
        cell: "subtype_isStockTemplate",
      },
      {
        field: "stockOnAccept",
        title: "Szla tét készl kap",
        cell: "subtype_stockOnAcceptTemplate",
      },
    ],

    subtypeItems() {
      return this.store.subtypesByType;
    },

    partnerRelationColumns: () => [
      {
        field: "isSelected",
        title: "Van Reláció?",
        cell: "partnerRelation_partnerRelTemplate",
      },
      { field: "name", title: "Reláció megnevezése" },
      {
        field: "isDefault",
        title: "Alapértelmezett",
        cell: "partnerRelation_isDefaultTemplate",
      },
    ],

    partnerRelationItems() {
      return this.store.partnerRelationsByType;
    },
  },
});
