import BankExtractImportAccountType from "../models/BankExtractImportAccountType";
import BankExtractImportAccountTypeApi from "./BankExtractImportAccountTypeApi";
import Bank from "@/modules/bank/models/Bank";
import BankApi from "@/modules/bank/services/BankApi";
import AccountType from "@/modules/accountType/models/AccountType";
import AccountSubtype from "@/modules/accountType/models/AccountSubtype";
import AccountTypeApi from "@/modules/accountType/services/AccountTypeApi";

export class BankExtractImportAccountTypeStore {
  items: BankExtractImportAccountType[] = [];
  banks: Bank[] = [];
  types: AccountType[] = [];
  subtypes: AccountSubtype[] = [];

  async load() {
    this.items = await BankExtractImportAccountTypeApi.getAll();
  }

  async loadBanks() {
    this.banks = await BankApi.getAll();
  }

  async loadAccTypes() {
    this.types = await AccountTypeApi.getAll(false);
  }

  async loadAccSubtypes() {
    this.subtypes = await AccountTypeApi.getSubtypes(false);
  }

  async save() {
    await BankExtractImportAccountTypeApi.put(this.items);
  }
}

const store = new BankExtractImportAccountTypeStore();
export default store;
