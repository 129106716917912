

























































import Vue from "vue";

import OtherCostInvoiceSearch from "./OtherCostInvoiceSearch.vue";
import OtherCostOrderSearch from "./OtherCostOrderSearch.vue";
import OtherCostPartnerSearch from "./OtherCostPartnerSearch.vue";
import InvoiceItemRelationsTable from "./InvoiceItemRelationsTable.vue";
import store, { InvoiceItemStore } from "../services/store";
import { InvoiceItemType, OtherCostShareOption, OtherItem, otherItemDefaultProps } from "../models";
import configuration from "../../../models/Configuration";
import eventBus from "@/services/eventBus";

interface Data {
  localOtherItem: OtherItem;
  store: InvoiceItemStore;
}

interface Methods {
  updateLocalUnitPrice(e: number): void;
  calculateDivisionValues(): void;
  addOtherItem(item: OtherItem): void;
  otherCostShareOptionsChanged(value: OtherCostShareOption): void;
  isReadonly(_: OtherItem): void;
}

export default Vue.extend<Data, Methods, {}, {}>({
  components: {
    OtherCostInvoiceSearch,
    OtherCostOrderSearch,
    InvoiceItemRelationsTable,
    OtherCostPartnerSearch,
  },

  data: () => ({
    localOtherItem: {...otherItemDefaultProps},
    tab: null,
    store,
    configuration,
    radioGroup: 0,
    otherCostShareOptions: [
      OtherCostShareOption.ToCurrentInvoice,
      OtherCostShareOption.ToOtherInvoice,
      OtherCostShareOption.ToConsignerConsumption,
      OtherCostShareOption.ToOtherPartner,
    ],
  }),

  props: {
    otherItem: {
      type: Object as () => OtherItem,
    },

    buttonVisible: { type: Boolean, default: false },
  },

  beforeMount() {
    console.log(this.$props.otherItem);
    
    this.localOtherItem = this.$props.otherItem;
  },

  computed: {
    targetItemHasRelation() {
      return (this.$props.otherItem?.relations?.length ?? 0) > 0;
    },
  },

  methods: {
    updateLocalUnitPrice(e: number){
      console.log(e);
      (this.store.targetItem as any) = {...this.store.targetItem, unitPrice: e }
      this.localOtherItem = { ...this.localOtherItem, unitPrice: e };
    },

    calculateDivisionValues(){
      this.store.calculateStockDivisionValues(this.localOtherItem);
      
      this.$nextTick(() => {
        this.localOtherItem = { ...this.localOtherItem }
        this.localOtherItem.relations = [ ...this.localOtherItem.relations ];
        eventBus.$emit("InvoiceItemRelationsTable:refreshLocalRelations", this.localOtherItem.relations);
      })
    },

    async addOtherItem(item: OtherItem) {
      this.store.itemList.items = this.store.itemList.items.filter(
        (x) => JSON.stringify(x.rawOtherItem) != JSON.stringify(item)
      );
      this.store.addOtherItem(item);
      setTimeout(async () => {
        if (this.$props.buttonVisible) {
          await this.store.save();
        }
      }, 1);
    },

    otherCostShareOptionsChanged(value: OtherCostShareOption) {
      if (value === OtherCostShareOption.ToCurrentInvoice) {
        const relationProps = { id: 0, invoiceItemID: 0 };

        this.$props.otherItem.relations = this.store.itemList.items
          .filter((i) => i.type === InvoiceItemType.Stocked)
          .flatMap((i) => i.relations)
          .map((r) => ({ ...r, ...relationProps }));

        return;
      }

      this.$props.otherItem.relations = [];
    },

    isReadonly(_: OtherItem) {
      return !configuration.incomingInvoice.otherItemStockRelationsEnabled;
    },
  },

  beforeDestroy() {
    eventBus.$off("otherItemRelations:unitPrice", this.updateLocalUnitPrice);
  },

  created() {
    eventBus.$on("otherItemRelations:unitPrice", this.updateLocalUnitPrice);
  },
});
