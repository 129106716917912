var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expand-transition',[(_vm.creatingCashDocByReceipts)?_c('CashDocByReceipts',{on:{"cancel":function($event){_vm.creatingCashDocByReceipts = false}}}):_vm._e()],1),_c('v-card',[_c('v-card-title',{staticClass:"mb-0"},[_c('v-row',{staticClass:"pb-4"},[_vm._v(" Bizonylatok "),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"primary","disabled":!_vm.isStatementCashierTheCurrentUser || _vm.disabled},on:{"click":function($event){_vm.creatingCashDocByReceipts = !_vm.creatingCashDocByReceipts}}},[_vm._v(" Bizonylat rögzítés számlákból ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"primary","disabled":!_vm.isStatementCashierTheCurrentUser || _vm.disabled},on:{"click":function($event){return _vm.newDocument()}}},[_vm._v(" Új bizonylat ")])],1)],1),_c('v-card-text',[_c('Paginator',{attrs:{"items":_vm.items},on:{"change":_vm.pagerChanged}}),_c('v-row',[_c('grid',{attrs:{"data-items":_vm.pager.sliced,"columns":_vm.columns,"loader":_vm.loader},scopedSlots:_vm._u([{key:"uniqueNoTemplate",fn:function(ref){
var props = ref.props;
return [_c('td',{class:{
                income: props.dataItem.income,
                expense: !props.dataItem.income,
              }},[_vm._v(" "+_vm._s(props.dataItem.uniqueNo)+" ")])]}},{key:"amountTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',[_c('money-display',{attrs:{"currency":props.dataItem.invCurrency},model:{value:(props.dataItem.currAmount),callback:function ($$v) {_vm.$set(props.dataItem, "currAmount", _vm._n($$v))},expression:"props.dataItem.currAmount"}})],1)]}},{key:"commandTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:{
                income: props.dataItem.income,
                expense: !props.dataItem.income,
              }},[_c('icon-button',{attrs:{"icon":"fa-edit","tooltip":props.dataItem.document.status == 2
                    ? 'Bizonylat szerkesztése'
                    : 'Bizonylat megtekintése'},on:{"click":function($event){return _vm.editItem(props.dataItem)}}})],1)]}},{key:"loader",fn:function(){return [_c('div',{staticClass:"k-loader-container k-loader-container-md k-loader-top"},[_c('div',{staticClass:"k-loader-container-overlay k-overlay-dark"}),_c('div',{staticClass:"k-loader-container-inner"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }