import PartBank from "@/modules/cashPoint/models/PartBank";
import http, { handleApiResponse } from "@/services/http";
import ApiResponse from "@/models/ApiResponse";

export default class PartBankApi
{
    static getPartBanks(): Promise<PartBank[]> {
        return http
            .get<ApiResponse<PartBank[]>>(`partBank/${true}`)
            .then(handleApiResponse)
    }

    static getPartBanksByPartner(partnerId: number): Promise<PartBank[]> {
        return http
            .get<ApiResponse<PartBank[]>>(`partBank/${true}/${partnerId}`)
            .then(handleApiResponse)
    }

    static getMyOwnPartBanks(): Promise<PartBank[]> {
        return http
            .get<ApiResponse<PartBank[]>>(`partBank/ownBanks`)
            .then(handleApiResponse)
    }
}