

















































































































import Vue from "vue";
import PartSupplier, { partSupplierDefaultProps } from "../models/PartSupplier";
import store from "../services/PartnerStore";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import ValidationError from "@/modules/downPayment/components/ValidationError.vue";

export default Vue.extend({
  components: {
    NumericTextBox,
    ValidationError,
  },

  data() {
    return {
      store,
    };
  },

  async beforeMount() {
    await Promise.all([this.store.loadCurrencies(), this.store.loadSuplTypes(), this.store.loadPayModes()]);
    store.partSupplier = { ...partSupplierDefaultProps };
    store.partSupplier.partner = store.partner.id;
  },

  methods: {
    async savePartSupplier(item: PartSupplier) {
      await this.store.createPartSupplier(item);
      this.$emit("partSupplierCreating", false);
      await this.store.loadPartSuppliers(store.partner.id);
      // this.$router.push(`/partners/${store.partner.id}/${this.$route.params.source}`);
    },

    cancelCreatePartSupplier() {
      store.partSupplier = { ...partSupplierDefaultProps };
      this.$emit("partSupplierCreating", false);
      // this.$router.push(`/partners/${store.partner.id}/${this.$route.params.source}`);
    },
  },
});
