













































import Vue from "vue";
import store, { DebitPaymentStore } from "../services/DebitPaymentStore";
import MoneyDisplay from "../../../components/MoneyDisplay.vue";

interface Data {
  store: DebitPaymentStore;
}

export default Vue.extend<Data, {}, {}, {}>({
  components: {
    MoneyDisplay,
  },

  data() {
    return {
      store,
    };
  },

  computed: {
    columns: () => [
      { field: "certifNo", title: "Szállítólevél", headerClassName: "center_text", className: "center_text" },
      { field: "partsName", title: "Cikk", headerClassName: "center_text", className: "center_text" },
      { cell: "isRetTemplate", title: "Visszáru", headerClassName: "center_text" },
      { cell: "originalPriceTemplate", title: "Eredeti ár", headerClassName: "right_text" },
      {
        cell: "modPriceTemplate",
        title: "Helyesbítés értéke",
        headerClassName: "right_text",
      },
      { cell: "debitPriceTemplate", title: "Módosult ár", headerClassName: "right_text" },
      { field: "reQuantity", title: "Viszáruzott mennyiség", headerClassName: "right_text", className: "right_text" },
      {
        field: "fixedQuantity",
        title: "Helyesbített mennyiség",
        headerClassName: "right_text",
        className: "right_text",
      },
      { field: "statusName", title: "Státusz", headerClassName: "center_text", className: "center_text" },
      { field: "insUser", title: "Rögzítő", headerClassName: "center_text", className: "center_text" },
      { cell: "descriptionTemplate", title: "Megjegyzés", headerClassName: "center_text" },
      { width: 0, filterable: false },
    ],
  },
});
