import User from "@/modules/invoiceGroup/models/User";
import PartBank from "@/modules/cashPoint/models/PartBank";
import PaymentMode from "../../paymentMode/models/PaymentModeDisplay";
import { PaymentSchedule } from "./../models/PaymentSchedule";
import { InvoiceDeadline } from "./../models/InvoiceDeadline";
import { PaymentScheduleApi } from "./api";
import GeneralIngoingInvoiceApi from "@/modules/generalIngoingInvoice/services/GeneralIngoingInvoiceApi";

export class PaymentScheduleStore {
  invoices: InvoiceDeadline[] = [];
  paymentModes: PaymentMode[] = [];
  users: User[] = [];

  async loadPaymentModes() {
    this.paymentModes = await GeneralIngoingInvoiceApi.getPaymentModes();
  }

  async loadResponsibleUser() {
    this.users = await PaymentScheduleApi.getResponsibleUsers();
  }

  async loadInvoiceDeadlines() {
    await PaymentScheduleApi.getInvoiceDeadlines().then((result) => {
      this.invoices = result.map((x) => new InvoiceDeadline(x));
    });
  }

  async loadInvoicePaymentSchedule(prefix: string, invoiceID: number) {
    return await PaymentScheduleApi.getPaymentScheduleResponse(prefix, invoiceID);
  }

  async loadPaymentScheduleAndItems(
    invoiceId: number,
    partBanks: PartBank[],
    paymentModes: PaymentMode[],
    users: User[],
    prefix = "GENI"
  ) {
    return await PaymentScheduleApi.getPaymentScheduleResponse(prefix, invoiceId).then((result) =>
      PaymentScheduleApi.setPaymentSchedule(result, partBanks, paymentModes, users)
    );
  }

  async managePaymentScheduleAndItems(
    paymentSchedule: PaymentSchedule,
    partBanks: PartBank[],
    paymentModes: PaymentMode[],
    users: User[],
    prefix = "GENI"
  ) {
    if (!paymentSchedule || (paymentSchedule.id == 0 && paymentSchedule.items.length == 0)) return;

    return await PaymentScheduleApi.managePaymentScheduleAndItems(prefix, paymentSchedule).then((result) =>
      PaymentScheduleApi.setPaymentSchedule(result, partBanks, paymentModes, users)
    );
  }
}

const store = new PaymentScheduleStore();
export default store;
