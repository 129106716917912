<template>
  <v-col style="padding: 0px !important">
    <v-btn
      v-if="icon != undefined"
      outlined
      class="src-btn"
      style="height: 31px !important"
    >
      <v-icon x-small>{{ icon }}</v-icon>
    </v-btn>
    <v-select
      v-model="inputValue"
      :items="items"
      no-data-text="Nincs adat"
      :placeholder="placeholder"
      :item-value="itemValue"
      :item-text="itemText"
      :return-object="returnObject"
      multiple
      outlined
      dense
    >
      <template v-slot:selection="{ item, index }">
        <div style="font-size: .7em;">
          <span v-if="index < 1"> {{ item[itemText] }}</span>
          <span v-if="index === 0" class="grey--text caption">
            (+{{ inputValue.length - 1 }} kiválasztva)</span
          >
        </div>
      </template>
    </v-select>
  </v-col>
</template>

<script>
export default {
  props: [
    "value",
    "items",
    "icon",
    "itemValue",
    "itemText",
    "returnObject",
    "placeholder",
  ],
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
        this.$emit("change", newValue);
      },
    },
  },
};
</script>

<style>
::v-deep .v-text-field--outlined > .v-input__append-inner {
  margin-top: 8px !important;
}
</style>
