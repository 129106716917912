import ApiResponse from "@/models/ApiResponse";
import http, { handleApiResponse } from "@/services/http";
import CarPostCalc from "../models/CarPostCalc";

export default class CarPostCalcApi {
    
  //postCalc
  static getCarPostCalcs(invNo: number): Promise<CarPostCalc[]> {
    return http.get<ApiResponse<CarPostCalc[]>>(`car-post-calc/${invNo}`).then(handleApiResponse);
  }

  static generateCarPostCalc(invNo: number): Promise<boolean> {
    return http.post<ApiResponse<boolean>>(`car-post-calc/generate/${invNo}`).then(handleApiResponse);
  }
}