import CashPointEdit from "@/modules/cashPoint/models/CashPointEdit";
import { newDate, trimDate, trimTime } from "@/utils/formatDates";
import CashDocument from "./CashDocument";

export interface CashStatementData {
  id: number;
  lastIncome: CashDocument | null;
  lastExpense: CashDocument | null;
  cashPoint: number;
  extractDate: string;
  extractTime: string;
  note: string | null;
  cashier: number;
  ownIdentifier: string;
  status: number;
  accNo: number;
  accStatus: number;
  currency: string;
  openBal: number;
  closeBal: number;
  openRate: number;
  closeRate: number;

  extractDateTime: string;
}

export default class CashStatement implements CashStatementData {
  id = 0;
  lastIncome: CashDocument | null = null;
  lastExpense: CashDocument | null = null;
  cashPoint = 1;
  extractDate = "";
  extractTime = "";
  note: string | null = "";
  cashier = 1;
  ownIdentifier = "";
  status = 2;
  accNo = 0;
  accStatus = 2;
  currency = "HUF";
  openBal = 0;
  closeBal = 0;
  openRate = 1;
  closeRate = 1;

  extractDateTime = "";

  constructor(params?: Partial<CashStatementData>) {
    const data = { ...params };
    Object.assign(this, data);
    if (this.extractDate && this.extractTime)
      this.extractDateTime = trimDate(this.extractDate) + "T" + trimTime(this.extractTime);
  }

  createFrom(cashPoint: CashPointEdit) {
    const newAccNo = cashPoint.accNo + 1;
    const today = new Date();
    const lastCloseBal = cashPoint?.lastStatement?.closeBal ?? 0;
    const isHUF = cashPoint?.currency.toUpperCase().trim() === "HUF";
    const rate = cashPoint?.lastStatement?.closeRate ?? 1;

    this.cashPoint = cashPoint.id;
    this.accNo = newAccNo;
    this.ownIdentifier = newAccNo.toString();
    this.extractDate = newDate(today);
    this.extractTime = newDate(today);
    this.extractDateTime = trimDate(this.extractDate) + "T" + trimTime(this.extractTime);
    this.currency = cashPoint.currency;
    this.openBal = lastCloseBal;
    this.closeBal = lastCloseBal;
    this.openRate = !isHUF ? rate : 1;
    this.closeRate = !isHUF ? rate : 1;
  }
}
